import React, { useState, useEffect, useRef } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Slider from "react-slick";
import { toast } from "react-toastify";
import CancelModal from "./components/products/CancelModal";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
  Icon,
  Block,
  UserAvatar,
  PreviewCard,
  ReactDataTable,
  RSelect,
  TooltipComponent,
} from "../../components/Component";
import {
  Badge,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import {
  SlickArrowLeft,
  SlickArrowRight,
} from "../../components/partials/slick/SlickComponents";
import { dataTableColumnsActivities } from "../components/table/TableData";
import { fetchAmenitiesList, fetchProperty } from "../../redux/actions/property";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  serverAPI,
  ListingType,
  formatValue,
  Agent,
  Status,
  getImage,
  propActiveStatus,
  uploadManyToS3,
  deleteFromS3,
  deleteManyFromS3,
  newRoles,
  newPropStatus,
  webURL,
  TicketTypes,
  TicketStatuses,
  HttpStatus,
  PropertyListStatus,
} from "../../utils/envConfig";
import { Markup } from "react-render-markup";
import { format } from "date-fns";
import { fetchAmenities } from "../../redux/actions/property";
import LocationInfo from "./components/LocationInfo";
import Cheques from "./components/Cheques";
import Fees from "./components/Fees";
import PropImgSlider from "./components/PropImgSlider";
import DraggableList from "./components/AmenityList";
import DragDrop from "./components/forms/DragDrop";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { TagsInput } from "react-tag-input-component";
import { QuillComponentEdit } from "../../components/partials/rich-editor/QuillComponent";
import ConfirmModal from "../../components/miscellaneous/ConfirmModal";
import { fetchAgents } from "../../redux/actions/user";
import LocationEdit from "./components/LocationEdit";
import InfoEdit from "./components/InfoEdit";
import PricingEdit from "./components/PricingEdit";
import TypeEdit from "./components/TypeEdit";
import ImageUpload from "./components/ImageUpload";
import AmenityModal from "./components/AmenityModal";
import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { showToast } from "../../utils/toast/toast";
import { ErrorNameSpaces, ErrorTypes } from "../../utils/error-types";
import S3Image from "./components/S3Image";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
const sliderSettings = {
  className: "slider-init row",
  slidesToShow: 2,
  centerMode: false,
  slidesToScroll: 1,
  infinite: false,
  prevArrow: <SlickArrowLeft />,
  nextArrow: <SlickArrowRight />,
  responsive: [
    { breakpoint: 3000, settings: { slidesToShow: 4 } },
    { breakpoint: 1540, settings: { slidesToShow: 3 } },
    { breakpoint: 992, settings: { slidesToShow: 2 } },
    { breakpoint: 576, settings: { slidesToShow: 1 } },
  ],
};

const ProductDetails = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();

  const authuser = useSelector((state) => state?.user?.loggedInUser);

  const property = useSelector((state) => state?.property?.property);
  const agent = useSelector((state) => state?.user?.agents);
  const amenity = useSelector((state) => state?.property?.amenities);
  const user = useSelector((state) => state?.user?.loggedInUser);

  const propertyEditable =
    (authuser?.role && authuser?.role !== newRoles?.Agent) ||
    (authuser?.role &&
      authuser?.role === newRoles?.Agent &&
      property?.createdBy === authuser?.id);

  const { access_token } = user || "";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };
  const [relatedProperties, setRelatedProperties] = useState(null);

  const [sliderData, setSliderData] = useState({ slider: [] });
  const [currentSlide, setCurrentSlide] = useState({});
  const [colorSector, setColorSelector] = useState(1);
  const [sizeSelector, setSizeSelector] = useState(1);
  const [counter, setCounter] = useState(1);
  const [images, setImages] = useState([]);

  const [propImages, setPropImages] = useState([]);
  const [propImagesUrl, setPropImagesUrl] = useState([]);
  const [imgLoading, setImgLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [index, setIndex] = useState(false);
  const [price, setPrice] = useState(0);
  const [monthPrice, setMonthPrice] = useState(0);
  const [imgAgentUrl, setAgentImgUrl] = useState(null);
  const [imgAgentLoading, setAgentImgLoading] = useState(true);
  const [imgOwnerUrl, setOwnerImgUrl] = useState(null);
  const [imgOwnerLoading, setOwnerImgLoading] = useState(true);
  const [optEditAgents, setOptEditAgents] = useState([]);
  const [optDefaultEditAgent, setOptDefaultEditAgent] = useState({
    label: "Select Agent",
    value: null,
  });
  const [modalMessage, setModalMessage] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);

  const [propAgent, setPropAgent] = useState(undefined);
  const [toggleName, setToggleName] = useState(null);
  const [featured, setFeatured] = useState(null);
  const [dealOfWeek, setDealOfWeek] = useState(null);
  const [exclusive, setExclusive] = useState(null);
  const [vacantOnTransfer, setVacantOnTransfer] = useState(null);
  const [distress, setDistress] = useState(null);
  const [luxury, setLuxury] = useState(null);
  const [premium, setPremium] = useState(null);
  const [amenities, setAmenities] = useState([]);
  const [propStatus, setPropStatus] = useState([]);

  const [tags, setTags] = useState(null);
  const [metaDesc, setMetaDesc] = useState(null);
  const [ptitle, setPTitle] = useState(null);
  const [desc, setDesc] = useState(null);

  const [editTitle, setEditTitle] = useState(false);
  const [editMeta, setEditMeta] = useState(false);
  const [editMetaDesc, setEditMetaDesc] = useState(false);
  const [editDesc, setEditDesc] = useState(false);
  const [editLocation, setEditLocation] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [editPricing, setEditPricing] = useState(false);
  const [editCheque, setEditCheque] = useState(false);
  const [editType, setEditType] = useState(false);

  const [tasks, setTasks] = useState([]);
  const [taskLoading, setTaskLoading] = useState(false);
  const [tickets, settickets] = useState(null)
  const [unResolvedTicket, setunResolvedTicket] = useState(null);
  const fetchTickets = async (listingAcquiredId) => {
    try {
      const { data } = await axios.get(`/supportticket?listingAcquiredId=${listingAcquiredId}&type=${TicketTypes.MasetrTicket}`);
      settickets(data?.tickets)

    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    dispatch(fetchProperty(id, axios));

    // dispatch(fetchAllProperties("", axios));
  }, [dispatch, id]);

  useEffect(() => {
    if (tickets?.length)
      setunResolvedTicket(c =>
        tickets?.find(ticket => ticket?.status !== TicketStatuses.Resolved))


  }, [tickets])
  const getRelatedProperties = async () => {
    try {
      const { data } = await axios.get("/filter/propList?page=1");
      data?.findPropListsOutput?.length &&
        setRelatedProperties(data?.findPropListsOutput);
    } catch (err) {
      // console.log( err );
    }
  };
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const propertyUpdate = async (propertyData, msg = "") => {
    // Checking permit number uniqueness
    if (propertyData.status === "Active" && propertyData.permitNo) {
      let link = `/filter/propList?status=Active&permitNo=${propertyData.permitNo}&listingType=${propertyData.listingType}`;
      const count = await fetchPropertiesByPermitNo(link);
      if (count > 0) {
        toast.error('Permit number should be unique');
        return false;
      }
    }

    try { 
      setIsUpdateLoading(true);
      console.log(propertyData);
      const res = await axios.patch(`/propList/${id}`, propertyData);
      
      if ( res.status === HttpStatus.OK) {
        showToast("Property Updated", 'success');

        if (propertyData && propertyData?.status === PropertyListStatus.Published) {
          // try to publish to property finder
          const pfRes = await axios.post(`/propList/pf-draft/${id}`);
          if ( pfRes.status === HttpStatus.CREATED ) {
            showToast('Successfully published the draft on PropertyFinder.', 'success');
          }
        } else if (
          propertyData 
          && (propertyData?.status === PropertyListStatus.Unpublished ||
              propertyData?.statusArray?.includes(PropertyListStatus.Cancelled))
        ) {
          const pfRes = await axios.delete(`/propList/pf-cancel/${id}`);
          if ( pfRes.status === HttpStatus.NO_CONTENT ) {
            showToast('Removed from PropertyFinder listings.', 'warning');
          }
        }
      }
    } catch (err) {
      console.log(JSON.stringify(err?.response, null, 2));
      const serverErrorMessage = err?.response?.data?.data?.messages ?? err?.response?.data?.message;
      const serverErrorType = err.response?.data?.name ?? err.response?.data?.type;
      
      if (serverErrorMessage) {
        const msg = [ErrorNameSpaces.PropertyFinder, ErrorTypes.PropertyFinderError].includes(serverErrorType ?? '') ? `PF Error: ${serverErrorMessage}` : serverErrorMessage;
        showToast(msg, 'error');
      } else {
        showToast('Property Finder : An error occurred.', 'error');
      }
    } finally {
      setIsUpdateLoading(false);
      handleCloseModal();
      if (showCancelModal) {
        setShowCancelModal(false);
      }
      dispatch(fetchProperty(id, axios));
    }
  };

  const fetchPropertiesByPermitNo = async (link) => {
    try {
      const res = await axios.get(link);
      return res?.data?.filteredCount;
    } catch (err) {
      toast.error('Permit No Validation failed');
    }
  };

  const [publishLoading, setPublishLoading] = useState(false);
  const publishOnPropertyFinder = async () => {
    try {
      setPublishLoading(true);
      const res = await axios.post(`/propList/pf-draft/${id}`);

      if ( res.status === HttpStatus.CREATED ) {
        showToast('Successfully published the draft on PropertyFinder.', 'success');
      }
    } catch (error) {
      if (error?.response?.status === HttpStatus.BAD_REQUEST &&
          error?.response?.data?.message === 'Property is already up on PropertyFinder.'
      ) {
        showToast('Listing is already on Property Finder, data updated.', 'warning');
        return;
      }

      if (error?.response?.data?.name === ErrorNameSpaces.PropertyFinder) {
        const responseMessages = error?.response?.data?.data?.messages;
        let errorMessage = '';
        if (typeof responseMessages === 'string') {
          errorMessage = responseMessages
        } else {
          const errorObj = responseMessages?.property;
          const messages = [];
          for (const key in errorObj) {
            const message = Object.values(errorObj[key])[0];
            messages.push(`${key}: ${message}`)
          }
          errorMessage = messages.join(' | ')
        }

        showToast(errorMessage, 'error');
      }
    } finally {
      setPublishLoading(false);
      togglePublishModal();
      dispatch(fetchProperty(id, axios));
    }
  }
  
  useEffect(() => {
    getRelatedProperties();
    setFeatured(property?.featured);
    setPremium(property?.premium);
    setExclusive(property?.exclusive);
    setVacantOnTransfer(property?.vacantOnTransfer);
    setDealOfWeek(property?.dealOfWeek);
    setTags(property?.metaTags);
    setMetaDesc(property?.metaDesc);
    setPTitle(property?.propertyTitle);
    setDesc(property?.propertyDesc);
    setPropStatus(property?.status);
  }, [property]);

  const handleEditStatus = (e) => {
    if (authuser?.role && authuser?.role === newRoles?.Agent) {
      return;
    }


    e.preventDefault();
    setModalMessage(`${propStatus === Status[0] ? "Unpublish " : "Publish "}`);
    setConfirmModal("Status");
    setShowModal(true);
  };
  const handleRemoveImage = (e, ind) => {
    setIndex(ind);
    setModalMessage(`Delete image of `);
    setConfirmModal("Image");
    setShowModal(true);
  };
  const handleRemoveAll = (e) => {
    setModalMessage(`Delete all images of `);
    setConfirmModal(`DeleteAll`);
    setShowModal(true);
  };
  const [open, setOpen] = useState(false);


  const convertTasks = (tasks) => {
    return tasks.map((task) => ({
      id: task.id,
      type: `${task.type}`,
      agentName: "",
      date: formatDate(task?.date),
      completedDate:
        task?.completed_date && formatDate(new Date(task?.completed_date)),
      overdue: !task?.completed_date && getDaysAgo(task.date),
      feedback: `${task.description}`,
    }));
  };
  const handleUpload = async (file) => {
    let data = [
      { file: file.imgfile, type: "image/*", fileName: file.fileName },
      {
        file: file.thumbnailFile,
        type: "image/*",
        fileName: file.thumbnailName,
      },
    ];

    await uploadManyToS3(data, axios); // Uploading thumbnail and original of the image to s3
  };

  const imageUpload = async (e, images) => {
    e.preventDefault();

    if (images.length !== 0) {
      setImgLoading(true);
      for (let i = 0; i < images.length; i++) {
        await handleUpload(images[i]);
      }
      let newImgArray = images.map((e) => ({
        original: e.fileName,
        thumbnail: e.thumbnailName,
      }));
      let newImgArray2 = [...propImages, ...newImgArray];
      const imgDta = JSON.stringify({ images: newImgArray2 });

      try {
        await axios.patch(`${serverAPI}/propList/${property.id}`, imgDta, {
          headers,
        });
        toast.success("Images uploaded");
        setImgLoading(false);
        navigate(0);
      } catch (err) {
        toast.error("Server error. Try again!");
      }
    }
  };

  const getDaysAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const daysAgo = Math.floor((now - date) / (1000 * 60 * 60 * 24));
    return `${Math.max(daysAgo, 0)} days`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  useEffect(() => {
    if (amenity.length > 0 && property?.amenityIds !== undefined) {
      let fil2 = property?.amenityObjs
        ?.map((am) => {
          const foundAmenity = amenity.find((ele) => am.amenityId === ele.id);
          if (foundAmenity) {
            return {
              id: foundAmenity.id,
              amenity: foundAmenity.amenity,
              quantity: am.quantity,
              priority: am.priority,
            };
          }
          return null;
        })
        .filter(Boolean);
      setAmenities(fil2);
    }
  }, [amenity, property]);

  useEffect(() => {
    dispatch(fetchAmenitiesList(axios));
    dispatch(fetchAgents(axios));
  }, [dispatch]);

  useEffect(() => {
    let options = [];
    let result =
      agent &&
      agent?.length > 0 &&
      agent
        ?.filter((agents) => agents?.user?.active === true)
        ?.map((a) =>
          options.push({
            value: a?.id,
            label: a?.user?.first_name + " " + a?.user?.last_name,
            image: a?.image,
          })
        );
    setOptEditAgents(options);
  }, [agent]);

  const getUrl = async (file, i) => {
    const resData = await getImage(file, axios);
    const s3url = resData.signedRequest;
    if (s3url) {
      setPropImagesUrl((prevImages) => [
        ...prevImages,
        { id: prevImages.length, img: s3url },
      ]);
      if (i === 0) {
        setCurrentSlide({ id: 0, img: resData.signedRequest }); // set the current slide to the first image
      }
    }
  };

  useEffect(() => {
    setPropImagesUrl([]);
    setCurrentSlide({});
    // clearCache(); // this function clears the cache
    if (property?.images && property.images.length > 0) {
      let imgs = property.images;
      if (imgs.length) {
        setPropImages(imgs);
        for (let i = 0; i < imgs.length; i++) {
          getUrl(imgs[i]?.thumbnail, i);
        }
      }
    } else {
      setImgLoading(false);
    }
    if (property?.agent_id) {
      let arr = [];
      if (property?.agent?.user?.photo) {
        setAgentImgLoading(true);
        let promise = getImage(property?.agent?.user?.photo, axios);
        promise
          .then((url) => setAgentImgUrl(url.signedRequest))
          .finally(() => setAgentImgLoading(false));
      } else {
        setAgentImgUrl("../img/profile.png");
        setAgentImgLoading(false);
      }
      arr.push({
        value: property?.agent?.user?.id,
        label:
          "" +
          property?.agent?.user?.first_name +
          " " +
          property?.agent?.user?.last_name,
      });
      setPropAgent(arr);
      setOptDefaultEditAgent(arr);
    } else {
      setAgentImgLoading(false);
      setOptDefaultEditAgent(null);
    }
    if (property?.property_owner_id) {
      if (property?.propertyOwner?.photo) {
        setOwnerImgLoading(true);
        let promise = getImage(property?.propertyOwner?.photo, axios);
        promise
          .then((url) => setOwnerImgUrl(url))
          .finally(() => setOwnerImgLoading(false));
      } else {
        setOwnerImgUrl("./img/profile.png");
        setOwnerImgLoading(false);
      }
    } else {
      setAgentImgUrl("./img/profile.png");
      setOwnerImgLoading(false);
    }

    if (
      property?.listingType === ListingType[0] ||
      property?.listingType === ListingType[2]
    ) {
      setPrice(property?.askingPrice);
      setMonthPrice(property?.askingPrice / 12);
    } else {
      setPrice(property?.oneCheqPrice);
      setMonthPrice(property?.emi);
    }
    async function fetchTasks() {
      try {
        setTaskLoading(true);
        await axios
          .get(`${serverAPI}/activities/property/${property.id}`, {
            headers,
          })
          .then((result) => {
            // setTasks(result.data);
            setTasks(convertTasks(result.data));
            setTaskLoading(false);
          })
          .catch((err, res) => {
            // console.log(err)
          });
      } catch (err) {
        // console.log(err)
      }
    }
    fetchTasks();
    fetchTickets(property?.listingAcquiredId)
  }, [property]);








  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => {
    setEditModal(!editModal);
  };



  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const closeModal = () => {
    setModalMessage(null);
    setShowModal(false);
    setOptDefaultEditAgent(propAgent ? propAgent : optDefaultEditAgent);
  };

  const validate = (agent) => {
    if (!agent) {
      toast.error("Select Agent");
      return false;
    } else {
      return true;
    }
  };

  const submitConfirm = async () => {
    let msg = null;
    if (confirmModal === "Status") {
      if (unResolvedTicket) return toast.error(`${unResolvedTicket?.requestType} Ticket Not Resolved`)
      if (
        property?.status !== propActiveStatus[0] &&
        property?.images.length <= 0
      ) {
        showToast("Missing Images for the Property !", 'error');
        return;
      }
      const newStatus = newPropStatus[5]; // The value you want to add or remove

      // Check if newStatus exists in the array
      const index = property?.statusArray.indexOf(newStatus);
      let updatedStatusArray = property?.statusArray;
      if (index !== -1) {
        updatedStatusArray = property?.statusArray.filter(
          (item) => item !== newStatus
        );
        // Now, updatedStatusArray doesn't include the newStatus
      }
      propertyUpdate({
        status: propStatus === Status[0] ? Status[1] : Status[0],
        statusArray: updatedStatusArray,
        permitNo: property?.permitNo,
        listingType: property?.listingType
      });
    }
    if (confirmModal === "Image") {
      let rmImg = propImages[index];
      let newImages = [...propImages];
      let urls = [...propImagesUrl];
      if (index > -1) {
        newImages.splice(index, 1);
        urls.splice(index, 1);
      }
      try {
        setDeleting(true);
        //Deleting the original and thumbnail image from s3
        const imgDta = JSON.stringify({ images: newImages });
        await deleteFromS3([rmImg?.original, rmImg?.thumbnail]).then(
          await axios
            .patch(`/propList/${property.id}`, imgDta, {
              headers,
            })
            .then((result) => {
              setPropImages(result.data?.images);
              setPropImagesUrl(urls);
              setDeleting(false);
              handleCloseModal();
            })
        );
      } catch (err) {
        toast.error("Server error. Try Again!");
      } finally {
        // setDeleting(false)
      }
    }
    if (confirmModal === "DeleteAll") {
      setDeleting(true);
      await Promise.all(
        propImages.map(async function (file) {
          await deleteManyFromS3([file?.original, file?.thumbnail]); //Deleting the original and thumbnail image from s3
        })
      ).then(
        await axios.patch(
          `${serverAPI}/propList/${property.id}`,
          JSON.stringify({ images: [] }),
          { headers }
        )
      );
      setPropImages([]);
      setPropImagesUrl([]);
      setSliderData({ slider: [] });
      setDeleting(false);
      dispatch(fetchProperty(id, axios));
      handleCloseModal();
      toggleEditModal();
    }
    if (confirmModal === "Sold") {
      msg = `Marked as sold`;
      const newStatus =
        property?.listingType === ListingType[0]
          ? newPropStatus[0]
          : newPropStatus[1];
      let updatedSoldOrReserved = newStatus;
      let updatedSoldDate = new Date();
      // Check if newStatus exists in the string
      if (property?.soldOrReserved === newStatus) {
        // Value exists, so remove it
        updatedSoldOrReserved = "";
        updatedSoldDate = null;
        // Now, updatedSoldOrReserved doesn't include the newStatus
      } else {
        // Value doesn't exist, so add it
        updatedSoldOrReserved = `${newStatus}`.trim();
        // Now, updatedSoldOrReserved includes the newStatus
      }

      propertyUpdate(
        {
          soldOrReserved: updatedSoldOrReserved,
          soldDate: updatedSoldDate,
        },
        msg
      );
    }
    if (confirmModal === "Reserved") {
      msg = `Marked as reserved`;
      const newStatus = newPropStatus[2];
      let updatedReservedDate = new Date();
      let updatedSoldOrReserved;
      // Check if newStatus exists in the string
      if (property?.soldOrReserved === newStatus) {
        // Value exists, so remove it
        updatedSoldOrReserved = "";
        updatedReservedDate = undefined;
        // Now, updatedSoldOrReserved doesn't include the newStatus
      } else {
        // Value doesn't exist, so add it
        updatedSoldOrReserved = `${newStatus}`.trim();
        // Now, updatedSoldOrReserved includes the newStatus
      }
      propertyUpdate(
        {
          soldOrReserved: updatedSoldOrReserved,
          reservedDate: updatedReservedDate,
        },
        msg
      );
    }
    if (confirmModal === "Private") {
      msg = `Property Status updated`;
      const newStatus = newPropStatus[3]; // The value you want to add or remove

      // Check if newStatus exists in the array
      const index = property?.statusArray.indexOf(newStatus);
      let updatedStatusArray = property?.statusArray;
      if (index !== -1) {
        // Value exists, so remove it
        updatedStatusArray = property?.statusArray.filter(
          (item) => item !== newStatus
        );
        // Now, updatedStatusArray doesn't include the newStatus
      } else {
        // Value doesn't exist, so add it
        updatedStatusArray = [...property?.statusArray, newStatus];
        // Now, updatedStatusArray includes the newStatus
      }

      propertyUpdate(
        {
          statusArray: updatedStatusArray,
          status: Status[1],
        },
        msg
      );
    }
    if (confirmModal === "Pocket") {
      msg = `Property Status updated`;
      const newStatus = newPropStatus[6]; // The value you want to add or remove

      // Check if newStatus exists in the array
      const index = property?.statusArray.indexOf(newStatus);
      let updatedStatusArray = property?.statusArray;
      if (index !== -1) {
        // Value exists, so remove it
        updatedStatusArray = property?.statusArray.filter(
          (item) => item !== newStatus
        );
        // Now, updatedStatusArray doesn't include the newStatus
      } else {
        // Value doesn't exist, so add it
        updatedStatusArray = [...property?.statusArray, newStatus];
        // Now, updatedStatusArray includes the newStatus
      }

      propertyUpdate(
        {
          statusArray: updatedStatusArray,
          status: Status[1],
        },
        msg
      );
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenAmenities, setModalOpenAmenities] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [assignedAgent, setAssignedAgent] = useState(null);

  const [showCancelModal, setShowCancelModal] = useState(false);

  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const togglePublishModal = () => {
    setIsPublishModalOpen(!isPublishModalOpen);
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const toggleModalAmenities = () => {
    console.log(amenities);
    setModalOpenAmenities(!modalOpenAmenities);
  };

  const handleAgentSelect = (selectedOption) => {
    setSelectedAgent(selectedOption);
    setOptDefaultEditAgent(selectedOption);
  };

  const handleAssignAgent = () => {
    if (selectedAgent) {
      setAssignedAgent(selectedAgent);
      if (validate(selectedAgent)) {
        const agentData = {
          agentStatus: Agent[0],
          agent_id: selectedAgent.value,
        };
        propertyUpdate(agentData);
      }
    } else {
      setAssignedAgent(null);
    }
    setModalOpen(false);
  };

  const handleEditStatusAll = (e, name) => {
    e.preventDefault();
    setModalMessage(`Update the status of `);
    setConfirmModal(name);
    setShowModal(true);
  };
  const handleConfirmCancel = async (note) => {
    const newStatus = newPropStatus[4]; // The value you want to add or remove

    // Check if newStatus exists in the array
    const index = property?.statusArray.indexOf(newStatus);
    let updatedStatusArray = property?.statusArray;

    if (index !== -1) {
      // Value exists, so remove it
      return;
      // Now, updatedStatusArray doesn't include the newStatus
    } else {
      // Value doesn't exist, so add it
      updatedStatusArray = [...property?.statusArray, newStatus];
      // Now, updatedStatusArray includes the newStatus
    }
    propertyUpdate({
      statusArray: updatedStatusArray,
      status: Status[1],
      cancelNote: note,
    });
  };

  const isAdmin =
    authuser &&
    (authuser.roles?.includes("MasterAdmin") ||
      authuser.roles?.includes("Admin"));

 const permitNumberRef=useRef(null)

const copyPermitNo=async(ref)=>{
  try {
    const text=ref.current?.textContent
    await navigator.clipboard.writeText(text)
  } catch (error) {
    console.log(error);
  }
}

  return (
    <React.Fragment>
      <Head title="Property Detail"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="g-3">
            <BlockHeadContent>
              <Link>
                <Button
                  onClick={() => navigate(-1)}
                  color="light"
                  outline
                  className="d-sm-inline-flex bg-white mb-4 d-none"
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
              </Link>
              <BlockTitle>Property Details</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Link to={`AED{ process.env.PUBLIC_URL }/product-card`}>
                <Button
                  color="light"
                  outline
                  className="d-inline-flex bg-white d-sm-none btn-icon"
                >
                  <Icon name="arrow-left"></Icon>
                </Button>
              </Link>
            </BlockHeadContent>
          </BlockBetween>
          {property?.status === Status[0] && (
            <BlockBetween className="d-flex justify-content-end">
              <div className="border me-2">
              <a
                  href={`https://alba.homes/download/${property?.referNo}`}
                  className="btn btn-secondary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View as PDF
                </a>
              </div>

              {!property?.portals.length && isAdmin && (
                <div className="border">
                  <Button color="primary" onClick={togglePublishModal}>
                    Draft it on Property Finder
                  </Button>
                </div>
              )}
            </BlockBetween>
          )}


        </BlockHead>

        <Block>
          <Card>
            <div className="card-inner">
              <Row className="pb-5">
                {property && (
                  <Col lg={6}>
                    <div
                      className="me-xl-1 me-xxl-5 product-gallery"
                      style={{ position: 'relative' }}
                    >
                      <div
                        className="edit-icon"
                        style={{
                          position: 'absolute',
                          top: '0',
                          right: '0',
                          paddingLeft: '0.2rem',
                          paddingRight: '0.2rem',
                          paddingTop: '0.25rem',
                          backgroundColor: '#fff',
                          zIndex: '100',
                          borderRadius: '0 0 0 0.25rem'
                        }}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleEditModal();
                        }}
                      >
                        {propertyEditable && (
                          <Icon
                            name="edit"
                            className="text-primary"
                            style={{
                              fontSize: '1.7rem',
                              cursor: 'pointer'
                            }}
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggleEditModal();
                            }}
                          ></Icon>
                        )}
                      </div>
                      {propImagesUrl && (
                        <PropImgSlider
                          propImagesUrl={propImagesUrl}
                          currentSlide={currentSlide}
                          setCurrentSlide={setCurrentSlide}
                        />
                      )}
                      <Modal show={showModal} handleClose={handleCloseModal}>
                        asdad
                      </Modal>
                    </div>
                  </Col>
                )}
                {property && (
                  <Col lg={6}>
                    <div className="mt-1 me-xxl-5 product-info">
                      <h4 className="text-primary product-price">
                        AED {price && formatValue(Math.round(price))}{' '}
                        {property?.listingType === ListingType[1]
                          ? 'per year'
                          : ''}
                        <small className="ml-2 text-muted fs-14px">
                          {property?.listingType === ListingType[1] &&
                            `AED ${
                              monthPrice && formatValue(Math.round(monthPrice))
                            } per month`}
                        </small>
                      </h4>
                      {!editTitle && (
                        <h2
                          onClick={() => {
                            if (propertyEditable) {
                              setEditTitle(true);
                            }
                          }}
                          className="product-title"
                          style={{
                            cursor: 'pointer',
                            height: '6rem',
                            maxHeight: '6rem',
                            overflowX: 'hidden'
                          }}
                        >
                          {property?.propertyTitle}
                        </h2>
                      )}
                      {editTitle && (
                        <div className="d-flex">
                          <div className="input-group">
                            <div className="input-group-pend">
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  propertyUpdate({
                                    propertyTitle: ptitle
                                  }) && setEditTitle(false);
                                }}
                                color="success"
                                className=""
                                style={{
                                  position: 'absolute',
                                  marginTop: '1rem',
                                  marginBottom: '1rem',
                                  right: '2.6rem',
                                  height: '1.5rem',
                                  zIndex: '10',
                                  top: '0.15rem'
                                }}
                              >
                                {'Save'}
                              </Button>
                            </div>
                            <div>
                              <Button
                                className="close-btn"
                                onClick={() => setEditTitle(false)}
                                style={{
                                  position: 'absolute',
                                  marginTop: '1rem',
                                  marginBottom: '1rem',
                                  right: '-10px',
                                  height: '1.5rem',
                                  zIndex: '10',
                                  top: '0.15rem'
                                }}
                              >
                                <Icon
                                  name="cross"
                                  style={{
                                    fontSize: '1rem',
                                    backgroundColor: '#fff',
                                    color: 'red',
                                    border: 'solid 1px #dbdfea',
                                    borderRadius: '0.2rem',
                                    padding: '0.09rem 0.3rem 0.09rem 0.3rem'
                                  }}
                                />
                              </Button>
                            </div>
                            <Input
                              type="text"
                              id="title"
                              className="form-control mt-2 mb-2"
                              name="firstName"
                              value={ptitle}
                              onChange={(e) => setPTitle(e.target.value)}
                              style={{
                                cursor: 'pointer',
                                paddingRight: '8rem'
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <div className="product-rating">
                        <Icon name="location"></Icon>{' '}
                        <span className="ml-2">
                          {property?.emirate}, {property?.community?.community}{' '}
                          , {property?.buildingName}
                        </span>
                      </div>
                      <div className="mt-3">
                        {!editType ? (
                          <>
                            <ul
                              onClick={() => {
                                if (propertyEditable) {
                                  setEditType(true);
                                }
                              }}
                              className="d-flex flex-wrap g-3 gx-5"
                            >
                              <li
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                <div className="text-muted fs-14px">Type</div>
                                <div className="fw-bold text-secondary fs-16px">
                                  {property?.propertyType.propertyType}
                                </div>
                              </li>
                              <li
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                <div className="text-muted fs-14px">
                                  Purpose
                                </div>
                                <div className="fw-bold text-secondary fs-16px">
                                  {property?.listingType}
                                </div>
                              </li>
                              <li
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                <div className="text-muted fs-14px">Beds</div>
                                <div className="fw-bold text-secondary fs-16px">
                                  {' '}
                                  {property?.beds}
                                </div>
                              </li>
                              <li
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                <div className="text-muted fs-14px">Baths</div>
                                <div className="fw-bold text-secondary fs-16px">
                                  {' '}
                                  {property?.baths}
                                </div>
                              </li>
                              <li
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                <div className="text-muted fs-14px">Area</div>
                                <div className="fw-bold text-secondary fs-16px">
                                  {' '}
                                  {property.size} sqft
                                </div>
                              </li>
                              <li
                                style={{
                                  cursor: 'pointer'
                                }}
                                className="d-xxl-inline-block"
                              >
                                <div className="text-muted fs-14px">ID</div>
                                <div className="fw-bold text-secondary fs-16px">
                                  {property?.referNo}
                                </div>
                              </li>
                              <li
                                style={{
                                  cursor: 'pointer'
                                }}
                                className="d-xxl-inline-block"
                              >
                                <div className="text-muted fs-14px">
                                  Furnishing
                                </div>
                                <div className="fw-bold text-secondary fs-16px">
                                  {property?.furnishing ?? 'N/A'}
                                </div>
                              </li>
                            </ul>
                          </>
                        ) : (
                          <TypeEdit
                            property={property}
                            propertyUpdate={propertyUpdate}
                            setEditType={setEditType}
                          />
                        )}
                      </div>
                      {!editDesc ? (
                        <div
                          onClick={() => {
                            if (propertyEditable) {
                              setEditDesc(true);
                            }
                          }}
                          className="mt-4 product-excerpt-desc pe-2 product-excerpt"
                          style={{
                            maxHeight: '18rem',
                            overflow: 'auto',
                            cursor: 'pointer'
                          }}
                        >
                          <p className="fs-16px">
                            <Markup markup={property?.propertyDesc} />
                          </p>
                        </div>
                      ) : (
                        <Card className="mt-4">
                          <div className="card-inner">
                            <div className="d-flex justify-content-end align-items-end">
                              <button
                                className="mb-2 close-btn"
                                onClick={() => setEditDesc(false)}
                                style={{
                                  top: '1.5rem',
                                  right: '1rem',
                                  border: 'none',
                                  backgroundColor: 'transparent',
                                  cursor: 'pointer'
                                }}
                              >
                                <Icon
                                  name="cross"
                                  style={{
                                    fontSize: '1rem',
                                    backgroundColor: '#fff',
                                    color: 'red',
                                    border: 'solid 1px #dbdfea',
                                    borderRadius: '0.2rem',
                                    padding: '0.09rem 0.3rem '
                                  }}
                                />
                              </button>
                            </div>
                            <QuillComponentEdit
                              data={property?.propertyDesc}
                              setDesc={setDesc}
                            ></QuillComponentEdit>
                            <div className="d-flex justify-content-end align-items-end mt-4">
                              <Button
                                onClick={(e) =>
                                  propertyUpdate({
                                    propertyDesc: desc
                                  }) && setEditDesc(false)
                                }
                                color="success"
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </Card>
                      )}
                    </div>
                  </Col>
                )}
              </Row>

              <div className="divider" />
              {property && (
                <>
                  <Row className="flex-lg-row-reverse pt-2 g-gs">
                    <Col sm="12" lg="7">
                      {!editInfo ? (
                        <>
                          <BlockHead>
                            <BlockHeadContent>
                              <BlockTitle tag="h5">
                                Property Info
                                <Button
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    if (propertyEditable) {
                                      setEditInfo(true);
                                    }
                                  }}
                                  color="transparent"
                                  className="text-primary mx-0 px-0"
                                >
                                  <TooltipComponent
                                    containerClassName={'bg-transparent'}
                                    direction={'top'}
                                    icon={'edit'}
                                    id={`edit-property-${property?.id}-info`}
                                    text={'Edit Property info'}
                                  />
                                </Button>
                              </BlockTitle>
                            </BlockHeadContent>
                          </BlockHead>
                          <Card className="card-bordered">
                            <ul className="data-list is-compact">
                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">
                                    Permit Number (trakheesi)
                                  </div>
                                  <div className="data-value d-flex justify-center align-center">
                                    <p className="m-0 mx-1" ref={permitNumberRef}>
                                      {property?.permitNo}{' '}
                                    </p>
                                    {property?.permitNo?<Button className={"m-0 p-0"} onClick={()=>copyPermitNo(permitNumberRef)}>
                                      <TooltipComponent
                                        icon={'copy'}
                                        id={'copy-permitNo'}
                                        direction={'top'}
                                        text={'copy'}
                                      />
                                    </Button>:null}
                                  </div>
                                </div>
                              </li>
                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">Contract A</div>
                                  <div className="data-value">
                                    {property?.contractA ?? 'N/A'}
                                  </div>
                                </div>
                              </li>
                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">
                                    QR Code Image
                                  </div>
                                  <div className="data-value">
                                    {property?.qrCodeUrl && (
                                      <div
                                        style={{
                                          width: '50px',
                                          height: '50px',
                                          marginBottom: '10px'
                                        }}
                                      >
                                        <S3Image url={property?.qrCodeUrl} />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </li>

                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">
                                    Property Owner{' '}
                                  </div>
                                  <div className="data-value">
                                    {`${property?.propertyOwner?.first_name} ${property?.propertyOwner?.last_name}`}
                                  </div>
                                </div>
                              </li>
                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">Category</div>
                                  <div className="data-value">
                                    {property?.category?.category}
                                  </div>
                                </div>
                              </li>
                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">Market Type</div>
                                  <div className="data-value">
                                    {property?.purposeOfMarket}
                                  </div>
                                </div>
                              </li>
                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">Unit Status</div>
                                  <div className="data-value">
                                    {property?.unitStatus}
                                  </div>
                                </div>
                              </li>
                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">Condition</div>
                                  <div className="data-value">
                                    {property?.conditions}
                                  </div>
                                </div>
                              </li>
                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">
                                    Completion Date
                                  </div>
                                  <div className="data-value">
                                    {property?.completionDate}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </Card>
                        </>
                      ) : (
                        <InfoEdit
                          property={property}
                          propertyUpdate={propertyUpdate}
                          setEditInfo={setEditInfo}
                        />
                      )}
                      {!editPricing ? (
                        <>
                          <BlockHead>
                            <BlockHeadContent>
                              <BlockTitle tag="h5">
                                Pricing Info
                                <Button
                                  onClick={(ev) => {
                                    ev.preventDefault();

                                    if (propertyEditable) {
                                      setEditPricing(true);
                                    }
                                  }}
                                  color="transparent"
                                  className="text-primary mx-0 px-0"
                                >
                                  <TooltipComponent
                                    containerClassName={'bg-transparent'}
                                    direction={'top'}
                                    icon={'edit'}
                                    id={`edit-property-${property?.id}-price`}
                                    text={'Edit Price info'}
                                  />
                                </Button>
                              </BlockTitle>
                            </BlockHeadContent>
                          </BlockHead>
                          <Card className="card-bordered">
                            <ul className="data-list is-compact">
                              {property?.listingType === ListingType[0] && (
                                <li className="data-item">
                                  <div className="data-col">
                                    <div className="data-label">
                                      Asking Price
                                    </div>
                                    <div className="data-value">
                                      AED{' '}
                                      {property?.askingPrice &&
                                        formatValue(property?.askingPrice)}
                                    </div>
                                  </div>
                                </li>
                              )}
                                {property?.listingType === ListingType[0] && (
                              <li className="data-item">
                                  <div className="data-col">
                                    <div className="data-label">
                                      Our Valution
                                    </div>
                                    <div className="data-value">
                                      {property?.priceEvaluation ?? "N/A"}
                                    </div>
                                  </div>
                              </li>)}
                              {property?.listingType === ListingType[0] && (
                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">
                                    Is an Off-Plan Resale?
                                  </div>
                                  <div className="data-value">
                                    {property?.isOffPlanResale == undefined
                                      ? 'N/A'
                                      : property?.isOffPlanResale
                                      ? 'Yes'
                                      : 'No'}
                                  </div>
                                </div>
                              </li>)}
                              {property?.isOffPlanResale ? (
                                <li className="data-item">
                                  <div className="data-col">
                                    <div className="data-label">
                                      Amount Already Paid
                                    </div>
                                    <div className="data-value">
                                      AED{' '}
                                      {property?.amountAlreadyPaid &&
                                        formatValue(
                                          property?.amountAlreadyPaid
                                        )}
                                    </div>
                                  </div>
                                </li>
                              ) : null}
                              {property?.listingType === ListingType[0] && (
                                <li className="data-item">
                                  <div className="data-col">
                                    <div className="data-label">Price Drop</div>
                                    <div className="data-value">
                                      {property?.pricedrop
                                        ? property?.pricedrop + ' % '
                                        : ''}
                                      {property?.pricedrop &&
                                        '( AED ' +
                                          formatValue(
                                            Math.round(
                                              price -
                                                (price *
                                                  Number(property?.pricedrop)) /
                                                  100
                                            )
                                          ) +
                                          ' )'}
                                    </div>
                                  </div>
                                </li>
                              )}

                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">
                                    Contract Validity
                                  </div>
                                  <div className="data-value">
                                    {property?.contractValidity}
                                  </div>
                                </div>
                              </li>
                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">Commision</div>
                                  <div className="data-value">
                                    {property?.commission} % ( {'AED'}{' '}
                                    {formatValue(
                                      Math.round(
                                        (price * Number(property?.commission)) /
                                          100
                                      )
                                    )}
                                    )
                                  </div>
                                </div>
                              </li>

                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">Video Link</div>
                                  <div className="data-value">
                                    {property?.videos &&
                                      property?.videos.map(
                                        (vid, index) => vid + ' '
                                      )}
                                  </div>
                                </div>
                              </li>
                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">
                                    Matterport Link
                                  </div>
                                  <div className="data-value">
                                    {property?.matterportLink?.[0]
                                      ? property?.matterportLink?.[0] + ' '
                                      : ''}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <Cheques
                                  property={property}
                                  setEditCheque={setEditCheque}
                                />
                              </li>
                            </ul>
                          </Card>
                        </>
                      ) : (
                        <PricingEdit
                          property={property}
                          propertyUpdate={propertyUpdate}
                          setEditPricing={setEditPricing}
                          setEditCheque={setEditCheque}
                        />
                      )}
                    </Col>

                    <Col sm="12" lg="5">
                      <BlockHead>
                        <BlockHeadContent>
                          <BlockTitle tag="h5">System Info</BlockTitle>
                          <p>Creation date, Modification date, status etc.</p>
                        </BlockHeadContent>
                      </BlockHead>
                      <Card className="card-bordered">
                        <ul className="data-list is-compact">
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Agent Status</div>
                              <div
                                onClick={
                                  authuser?.role &&
                                  authuser?.role !== newRoles?.Agent
                                    ? toggleModal
                                    : null
                                }
                                className="data-col"
                              >
                                {property?.agent ? (
                                  <>
                                    <Badge
                                      size="sm"
                                      color="success"
                                      className="me-2"
                                    >
                                      Assigned
                                    </Badge>
                                    <UserAvatar
                                      image={imgAgentUrl}
                                      className="d-inline-block bg-blue-dim sm"
                                      style={{
                                        marginLeft: '1rem'
                                      }}
                                    />
                                    <span className="ps-1">
                                      {property?.agent?.user?.first_name +
                                        ' ' +
                                        property?.agent?.user?.last_name}
                                    </span>
                                  </>
                                ) : (
                                  <Badge className="bg-danger">
                                    Unassigned
                                  </Badge>
                                )}
                              </div>
                            </div>
                          </li>
                          <Modal
                            isOpen={modalOpen}
                            toggle={toggleModal}
                            backdrop="static"
                          >
                            <ModalHeader
                              toggle={toggleModal}
                              close={
                                <button className="close" onClick={toggleModal}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Change Assigned Agent
                            </ModalHeader>
                            <ModalBody>
                              <RSelect
                                value={optDefaultEditAgent}
                                onChange={handleAgentSelect}
                                options={optEditAgents}
                                isSearchable
                              />
                            </ModalBody>
                            <ModalFooter className="border-0">
                              <Button
                                className="bg-primary text-white"
                                onClick={handleAssignAgent}
                              >
                                Assign
                              </Button>
                            </ModalFooter>
                          </Modal>

                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Created By</div>
                              <div className="data-value">
                                {property?.user &&
                                  `${property?.user?.first_name} ${property?.user?.last_name}`}
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Created At</div>
                              <div className="data-value">
                                {property?.createdAt &&
                                  format(
                                    new Date(property?.createdAt),
                                    'dd/MM/yyyy hh:mm a'
                                  )}
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Status</div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  width: '100%'
                                }}
                              >
                                <div className="data-value">
                                  {property?.statusArray?.includes(
                                    newPropStatus[4]
                                  ) ? (
                                    <Badge color="danger" className="ms-1">
                                      {newPropStatus[4]}
                                    </Badge>
                                  ) : property?.statusArray?.includes(
                                      newPropStatus[6]
                                    ) ? (
                                    <Badge color="danger" className="ms-1">
                                      {newPropStatus[6]}
                                    </Badge>
                                  ) : property?.statusArray?.includes(
                                      newPropStatus[3]
                                    ) ? (
                                    <Badge color="danger" className="ms-1">
                                      {newPropStatus[3]}
                                    </Badge>
                                  ) : property?.status === Status[0] ? (
                                    <Badge
                                      onClick={(e) => {
                                        if (propertyEditable) {
                                          handleEditStatus(e, '');
                                        }
                                      }}
                                      size="sm"
                                      color="outline-success"
                                      className="badge-dim"
                                    >
                                      {propActiveStatus[0]}
                                    </Badge>
                                  ) : (
                                    <Badge
                                      onClick={(e) => {
                                        if (propertyEditable) {
                                          handleEditStatus(e, '');
                                        }
                                      }}
                                      size="sm"
                                      color="outline-danger"
                                      className="badge-dim"
                                    >
                                      {propActiveStatus[1]}
                                    </Badge>
                                  )}
                                </div>
                                {!property?.statusArray?.includes(
                                  newPropStatus[4]
                                ) && (
                                  <div>
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        tag="a"
                                        href="toggle"
                                        onClick={(ev) => ev.preventDefault()}
                                        className="btn btn-icon btn-sm btn-trigger dropdown-toggle me-n1"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </DropdownToggle>
                                      {authuser?.role &&
                                        authuser?.role !== newRoles?.Agent && (
                                          <DropdownMenu end>
                                            <ul className="link-list-opt no-bdr">
                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#item"
                                                  onClick={(e) =>
                                                    handleEditStatusAll(
                                                      e,
                                                      'Sold'
                                                    )
                                                  }
                                                >
                                                  <span>
                                                    {property?.listingType ===
                                                    ListingType[0]
                                                      ? 'Mark Sold'
                                                      : 'Mark Rented'}{' '}
                                                  </span>
                                                  {(property?.soldOrReserved ===
                                                    newPropStatus[0] ||
                                                    property?.soldOrReserved ===
                                                      newPropStatus[1]) && (
                                                    <Icon name="check-round-cut"></Icon>
                                                  )}
                                                </DropdownItem>
                                              </li>
                                              {!property?.soldDate && (
                                                <li>
                                                  <DropdownItem
                                                    tag="a"
                                                    href="#item"
                                                    onClick={(e) =>
                                                      handleEditStatusAll(
                                                        e,
                                                        'Reserved'
                                                      )
                                                    }
                                                  >
                                                    <span>
                                                      {'Mark Reserved'}
                                                      {property?.soldOrReserved ===
                                                        newPropStatus[2] && (
                                                        <Icon name="check-round-cut"></Icon>
                                                      )}
                                                    </span>
                                                  </DropdownItem>
                                                </li>
                                              )}
                                              {!property?.soldDate &&
                                                !property?.statusArray?.includes(
                                                  newPropStatus[3]
                                                ) &&
                                                !property?.statusArray?.includes(
                                                  newPropStatus[6]
                                                ) && (
                                                  <li>
                                                    <DropdownItem
                                                      tag="a"
                                                      href="#item"
                                                      onClick={(e) =>
                                                        handleEditStatus(e, '')
                                                      }
                                                    >
                                                      <span>
                                                        {property?.status ===
                                                        Status[0]
                                                          ? 'Unpublish'
                                                          : 'Publish'}{' '}
                                                      </span>
                                                    </DropdownItem>
                                                  </li>
                                                )}
                                              {!property?.statusArray?.includes(
                                                newPropStatus[6]
                                              ) && (
                                                <li>
                                                  <DropdownItem
                                                    tag="a"
                                                    href="#item"
                                                    onClick={(e) =>
                                                      handleEditStatusAll(
                                                        e,
                                                        'Private'
                                                      )
                                                    }
                                                  >
                                                    <span>{'Private'} </span>
                                                    {property?.statusArray?.includes(
                                                      newPropStatus[3]
                                                    ) && (
                                                      <Icon name="check-round-cut"></Icon>
                                                    )}
                                                  </DropdownItem>
                                                </li>
                                              )}

                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#item"
                                                  onClick={(e) =>
                                                    setShowCancelModal(true)
                                                  }
                                                >
                                                  <span>{'Cancel'} </span>
                                                </DropdownItem>
                                              </li>
                                            </ul>
                                          </DropdownMenu>
                                        )}
                                    </UncontrolledDropdown>
                                  </div>
                                )}
                              </div>
                            </div>
                          </li>
                          {
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">
                                  Property Finder Listings
                                </div>
                                <div className="d-flex g-2 flex-wrap data-value">
                                  {property?.portals?.length ? (
                                    property?.portals?.map((portal, idx) => (
                                      <div key={`portal-${idx}`} className="">
                                        <PropertyFinderListingPill
                                          portalInfo={portal}
                                          idx={idx}
                                        />
                                      </div>
                                    ))
                                  ) : (
                                    <span className="border-0 bg-secondary-dim text-dark badge badge-sm">
                                      N/A
                                    </span>
                                  )}
                                </div>
                              </div>
                            </li>
                          }
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Modified By</div>
                              <div className="data-value">
                                {property?.modifiedBy &&
                                  `${property?.modifiedBy?.first_name} ${property?.modifiedBy?.last_name}`}
                              </div>
                            </div>
                          </li>

                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Modified At</div>
                              <div className="data-value">
                                {property?.updatedAt &&
                                  format(
                                    new Date(property?.updatedAt),
                                    'dd/MM/yyyy hh:mm a'
                                  )}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Card>
                      <BlockHead>
                        <BlockHeadContent>
                          <BlockTitle tag="h5">Promotion</BlockTitle>
                        </BlockHeadContent>
                      </BlockHead>
                      <Card className="card-bordered">
                        <ul className="data-list is-compact">
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Meta Tags</div>
                              {/* Show existing tags */}
                              {!editMeta &&
                                property?.metaTags.map((tag) => (
                                  <div
                                    onClick={() => setEditMeta(true)}
                                    className="data-value"
                                  >
                                    <Badge
                                      size="sm"
                                      color="outline-danger"
                                      className="badge-dim me-1"
                                    >
                                      {tag}
                                    </Badge>
                                  </div>
                                ))}

                              {/* Show tags input when editing */}
                              {editMeta && (
                                <div className="d-flex justify-content-center align-items-center">
                                  <TagsInput
                                    value={property?.metaTags}
                                    onChange={(newTags) => setTags(newTags)}
                                    name="Tags"
                                    placeHolder="Enter Tags then press enter"
                                  />
                                  <div className="d-flex justify-content-center align-items-center">
                                    <Button
                                      onClick={(e) =>
                                        propertyUpdate({
                                          metaTags: tags,
                                          metaDesc: metaDesc
                                        }) && setEditMeta(false)
                                      }
                                      color="success"
                                      className="d-flex justify-content-center align-items-center ms-2"
                                    >
                                      Save
                                    </Button>
                                    <button
                                      className="close-btn"
                                      onClick={() => setEditMeta(false)}
                                      style={{
                                        position: 'relative',
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                        cursor: 'pointer'
                                      }}
                                    >
                                      <Icon
                                        name="cross"
                                        style={{
                                          fontSize: '1rem',
                                          backgroundColor: '#fff',
                                          color: 'red',
                                          border: 'solid 1px #dbdfea',
                                          borderRadius: '0.2rem',
                                          paddingLeft: '0.3rem ',
                                          paddingRight: '0.3rem'
                                        }}
                                      />
                                    </button>
                                  </div>
                                </div>
                              )}

                              {/* Show add tags button if there are no tags */}
                              {!editMeta && property?.metaTags.length === 0 && (
                                <button
                                  onClick={() => {
                                    if (propertyEditable) {
                                      setEditMeta(true);
                                    }
                                  }}
                                  className="btn btn-primary btn-sm"
                                >
                                  Add Tags
                                </button>
                              )}
                            </div>

                            {/* Save and Cancel buttons */}
                          </li>

                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Meta Description</div>
                              {!editMetaDesc && (
                                <div
                                  className="data-value"
                                  onClick={() => {
                                    if (propertyEditable) {
                                      setEditMetaDesc(true);
                                    }
                                  }}
                                  style={{
                                    width: '13rem',
                                    height: '5rem',
                                    maxHeight: '4rem',
                                    paddingRight: '1rem',
                                    overflowX: 'hidden'
                                  }}
                                >
                                  {property?.metaDesc}
                                </div>
                              )}

                              {editMetaDesc && (
                                <textarea
                                  type="textarea"
                                  className="form-control"
                                  rows="5"
                                  name="metaDesc"
                                  id="metaDesc"
                                  value={metaDesc}
                                  onChange={(e) => setMetaDesc(e.target.value)}
                                />
                              )}
                              {editMetaDesc && (
                                <div className="d-flex justify-content-center align-items-center">
                                  <Button
                                    onClick={(e) =>
                                      propertyUpdate({
                                        metaDesc: metaDesc
                                      }) && setEditMetaDesc(false)
                                    }
                                    color="success"
                                    className="d-flex justify-content-center align-items-center mt-2 ms-2"
                                  >
                                    {'Save'}
                                  </Button>
                                  <button
                                    className="d-flex justify-content-center align-items-center mt-2 close-btn"
                                    onClick={() => setEditMetaDesc(false)}
                                    style={{
                                      top: '1.5rem',
                                      right: '1rem',
                                      border: 'none',
                                      backgroundColor: '#fff',
                                      cursor: 'pointer'
                                    }}
                                  >
                                    <Icon
                                      name="cross"
                                      style={{
                                        fontSize: '1rem',
                                        backgroundColor: '#fff',
                                        color: 'red',
                                        border: 'solid 1px #dbdfea',
                                        borderRadius: '0.2rem',
                                        paddingLeft: '0.3rem ',
                                        paddingRight: '0.3rem'
                                      }}
                                    />
                                  </button>
                                </div>
                              )}
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Featured</div>
                              <div className="data-value">
                                <div className="custom-control custom-switch">
                                  <input
                                    value={featured}
                                    type="checkbox"
                                    checked={featured}
                                    className="custom-control-input"
                                    id="customSwitch2"
                                    onClick={(e) => {
                                      if (propertyEditable) {
                                        propertyUpdate({
                                          featured: !featured
                                        });
                                        setFeatured(!featured);
                                      }
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customSwitch2"
                                  >
                                    {/* Since 30 Days ago */}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Deal of the week</div>
                              <div className="data-value">
                                {' '}
                                <div className="custom-control custom-switch">
                                  <input
                                    value={dealOfWeek}
                                    type="checkbox"
                                    checked={dealOfWeek}
                                    className="custom-control-input"
                                    id="customSwitch3"
                                    onClick={(e) => {
                                      if (propertyEditable) {
                                        propertyUpdate({
                                          dealOfWeek: !dealOfWeek
                                        });
                                        setDealOfWeek(!dealOfWeek);
                                      }
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customSwitch3"
                                  >
                                    {/* Since 2 Days ago */}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Premium</div>
                              <div className="data-value">
                                {' '}
                                <div className="custom-control custom-switch">
                                  <input
                                    value={premium}
                                    type="checkbox"
                                    checked={premium}
                                    className="custom-control-input"
                                    id="customSwitch4"
                                    onClick={(e) => {
                                      if (propertyEditable) {
                                        propertyUpdate({
                                          premium: !premium
                                        });
                                        setPremium(!premium);
                                      }
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customSwitch4"
                                  >
                                    {/* Since 1 Day ago */}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Exclusive</div>
                              <div className="data-value">
                                {' '}
                                <div className="custom-control custom-switch">
                                  <input
                                    value={exclusive}
                                    type="checkbox"
                                    checked={exclusive}
                                    className="custom-control-input"
                                    id="customSwitch5"
                                    onClick={(e) => {
                                      if (propertyEditable) {
                                        propertyUpdate({
                                          exclusive: !exclusive
                                        });
                                        setExclusive(!exclusive);
                                      }
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customSwitch5"
                                  >
                                    {/* Since 5 Days ago */}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">
                                Vacant on Transfer
                              </div>
                              <div className="data-value">
                                {' '}
                                <div className="custom-control custom-switch">
                                  <input
                                    value={vacantOnTransfer}
                                    type="checkbox"
                                    checked={vacantOnTransfer}
                                    className="custom-control-input"
                                    id="customSwitch6"
                                    onClick={(e) => {
                                      if (propertyEditable) {
                                        propertyUpdate({
                                          vacantOnTransfer: !vacantOnTransfer
                                        });
                                        setVacantOnTransfer(!vacantOnTransfer);
                                      }
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customSwitch6"
                                  >
                                    {/* Since 5 Days ago */}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">
                                Distress
                              </div>
                              <div className="data-value">
                                {" "}
                                <div className="custom-control custom-switch">
                                  <input
                                    value={distress}
                                    type="checkbox"
                                    checked={distress}
                                    className="custom-control-input"
                                    id="customSwitch7"
                                    onClick={(e) => {
                                      if (propertyEditable) {
                                        propertyUpdate({
                                          isDistress: !distress,
                                        });
                                        setDistress(!distress);
                                      }
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customSwitch7"
                                  >
                                    {/* Since 5 Days ago */}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">
                                Luxury
                              </div>
                              <div className="data-value">
                                {" "}
                                <div className="custom-control custom-switch">
                                  <input
                                    value={luxury}
                                    type="checkbox"
                                    checked={luxury}
                                    className="custom-control-input"
                                    id="customSwitch8"
                                    onClick={(e) => {
                                      if (propertyEditable) {
                                        propertyUpdate({
                                          isLuxury: !luxury,
                                        });
                                        setLuxury(!luxury);
                                      }
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customSwitch8"
                                  >
                                    {/* Since 5 Days ago */}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="flex-lg-row-reverse pt-4 g-gs">
                    <Col lg="12">
                      {!editLocation ? (
                        <LocationInfo
                          property={property}
                          setEditLocation={setEditLocation}
                        />
                      ) : (
                        <LocationEdit
                          property={property}
                          propertyUpdate={propertyUpdate}
                          setEditLocation={setEditLocation}
                        />
                      )}
                    </Col>

                    <Col lg="7">
                      <Fees
                        property={property}
                        propertyUpdate={propertyUpdate}
                      />
                    </Col>
                    <Col lg="5">
                      <BlockHead>
                        <BlockHeadContent>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <a>
                                <BlockTitle tag="h5">Amenities</BlockTitle>
                              </a>
                            </div>
                            {propertyEditable && (
                              <div>
                                <Button
                                  className="bg-primary text-white"
                                  onClick={
                                    propertyEditable
                                      ? toggleModalAmenities
                                      : null
                                  }
                                >
                                  <Icon name="edit" className="me-1"></Icon>{' '}
                                  Edit Amenities
                                </Button>
                              </div>
                            )}
                          </div>
                        </BlockHeadContent>
                      </BlockHead>
                      <Card className="card-bordered">
                        <DraggableList
                          amenities={amenities}
                          setAmenities={setAmenities}
                          propertyUpdate={propertyUpdate}
                        />
                      </Card>
                    </Col>
                    <Modal
                      isOpen={modalOpenAmenities}
                      toggle={toggleModalAmenities}
                      backdrop="static"
                      size="lg"
                    >
                      <ModalHeader
                        toggle={toggleModalAmenities}
                        close={
                          <button
                            className="close"
                            onClick={toggleModalAmenities}
                          >
                            <Icon name="cross" />
                          </button>
                        }
                      >
                        Add New Amenity
                      </ModalHeader>

                      {property && (
                        <AmenityModal
                          values={property}
                          propertyUpdate={propertyUpdate}
                          toggleModal={toggleModalAmenities}
                        />
                      )}
                    </Modal>
                  </Row>

                  <Row>
                    <Col lg="12" className="mt-2">
                      <BlockHead>
                        <BlockHeadContent>
                          <BlockHeadContent>
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <BlockTitle tag="h5" className="mt-4">
                                  Property Activities
                                </BlockTitle>
                              </div>
                            </div>
                          </BlockHeadContent>
                        </BlockHeadContent>
                      </BlockHead>
                      <Card className="card-bordered">
                        <PreviewCard>
                          {tasks && tasks?.length > 0 && (
                            <ReactDataTable
                              data={tasks}
                              columns={dataTableColumnsActivities}
                              expandableRows
                              pagination
                              actions
                            />
                          )}
                          {tasks && tasks?.length === 0 && 'No activity yet'}
                        </PreviewCard>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Card>
        </Block>
        {relatedProperties && relatedProperties?.length && (
          <Block size="lg">
            <BlockHead>
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle>Related Properties</BlockTitle>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <Slider {...sliderSettings}>
              {relatedProperties
                ?.filter((item) => item?.id !== property?.id)
                ?.map((item) => {
                  return <RelatedPropertyCard property={item} key={item?.id} />;
                })}
            </Slider>
          </Block>
        )}
      </Content>
      {property && propImages && (
        <ImageUpload
          handleRemoveAll={handleRemoveAll}
          toggleEditModal={toggleEditModal}
          property={property}
          propImages={propImages}
          editModal={editModal}
          propertyUpdate={propertyUpdate}
        />
      )}
      <Modal size="xl" isOpen={false} toggle={toggleEditModal}>
        <Card style={{}}>
          <button
            className="close-btn"
            onClick={toggleEditModal}
            style={{
              position: 'absolute',
              top: '1.5rem',
              right: '1rem',
              border: 'none',
              backgroundColor: 'transparent',
              cursor: 'pointer'
            }}
          >
            <Icon name="cross" style={{ fontSize: '1.5rem' }} />
          </button>
          {propImages?.length !== 0 && (
            <Button
              style={{
                position: 'absolute',
                top: '1.5rem',
                left: '4rem',
                cursor: 'pointer'
              }}
              onClick={(e) => handleRemoveAll(e)}
              color="danger"
              className="d-flex justify-content-center align-items-center mb-5 w-15"
            >
              Delete all
            </Button>
          )}
          <Row className="d-flex justify-content-center">
            <Col sm="6">
              <Button
                color=""
                className="d-flex justify-content-center align-items-center mt-4 w-100 text-center"
                type="button"
              >
                <div className="relative nk-upload-form">
                  <h5 className="mb-3 title">Upload Images</h5>

                  <DragDrop
                    propId={property?.id}
                    fieldName="images"
                    images={images}
                    setImages={setImages}
                  />
                </div>
              </Button>
            </Col>
          </Row>
          <div
            className="mb-xl-1 mb-xxl-5 me-xl-1 me-xxl-5 ms-xl-1 ms-xxl-5"
            style={{ position: 'relative' }}
          >
            <Row>
              <Col sm="12">
                <Row className="me-2 ms-2">
                  {propImagesUrl?.map((item, ind) => (
                    <Col sm="3" key={ind} style={{ position: 'relative' }}>
                      <div style={{ position: 'relative' }}>
                        <div
                          style={{
                            position: 'relative',
                            height: '10rem'
                          }}
                          className="mb-2"
                        >
                          <img
                            src={item.img}
                            alt=""
                            style={{
                              position: 'absolute',
                              width: '100%',
                              height: '100%',
                              objectFit: 'fill',
                              objectPosition: 'center'
                            }}
                          />
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            zIndex: '1',
                            padding: '0.1rem',
                            backgroundColor: '#fceceb',
                            borderRadius: '10%',
                            cursor: 'pointer'
                          }}
                          onClick={(e) => handleRemoveImage(e, ind)}
                        >
                          <Icon
                            name="trash"
                            style={{
                              color: '#e85347',
                              fontSize: '1.3rem'
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              onClick={(e) => imageUpload(e, images)}
              color="success"
              className="d-flex justify-content-center align-items-center mb-5 w-15"
            >
              {imgLoading ? (
                <Spinner size="sm" color="light" />
              ) : (
                'Save changes'
              )}
            </Button>
          </div>
        </Card>
      </Modal>
      {/* ****** Modal Start ****** */}
      {showModal ? (
        <ConfirmModal
          msg={modalMessage}
          name={'Property ID:' + property?.referNo}
          open={showModal}
          handleClick={handleModal}
          handleConfirm={submitConfirm}
          handlecloseModal={closeModal}
          loading={deleting || isUpdateLoading}
          action={
            confirmModal === 'Image' || confirmModal === 'DeleteAll'
              ? 'Delete'
              : 'Update'
          }
        />
      ) : null}
      {/* ****** Modal End ****** */}
      {showCancelModal ? (
        <CancelModal
          propId={property?.id}
          open={showCancelModal}
          handlecloseModal={setShowCancelModal}
          handleConfirm={handleConfirmCancel}
          loading={isUpdateLoading}
        />
      ) : null}
      <ConfirmationModal
        isOpen={isPublishModalOpen}
        toggleFn={togglePublishModal}
        title={'Confirm making a draft on Property Finder'}
        loadingState={publishLoading}
        actionFn={publishOnPropertyFinder}
      >
        <p className="fw-bold">
          This will create a draft of this property on Property Finder, or
          update tha page with portal information if a listing with the same
          permit number is already found on the portal.
        </p>
        <p>Do you want to continue?</p>
      </ConfirmationModal>
    </React.Fragment>
  );
};
export default ProductDetails;

const RelatedPropertyCard = ({ property }) => {
  const [image, setImage] = useState(null);

  const axios = useAxiosPrivate();

  const fetchImage = async (images) => {
    try {
      if(!images[0]?.original) return
      const image = await getImage(images[0]?.original, axios).catch(err=>toast.error("Error Getting Images"))
      image && setImage(image?.signedRequest);
    } catch (err) {
      // console.log( err );
    }
  };

  useEffect(() => {
     fetchImage(property?.images);
  }, []);

  return (
    <Col>
      <Card className="card-bordered me-3 ms-3 product-card">
        <div className="product-thumb">
          <Link
            to={`/property-list/${property?.id}`}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <div className="position-relative" style={{ height: "11rem" }}>
              <img
                className="card-img-top position-absolute"
                style={{
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                src={image ?? "/img/private-property.svg"}
                alt=""
              />
            </div>
          </Link>
          <ul className="product-badges">
            {property?.premium && (
              <li>
                <Badge color="info">Premium</Badge>
              </li>
            )}
            {property?.featured && (
              <li>
                <Badge color="danger">Featured</Badge>
              </li>
            )}
            {property?.exclusive && (
              <li>
                <Badge color="secondary">Exclusive</Badge>
              </li>
            )}
            <li>
              <Badge color="primary">{property?.listingType}</Badge>
            </li>
          </ul>
        </div>
        <div className="text-center card-inner">
          <h5
            className="product-title"
            style={{
              height: "3rem",
              maxHeight: "3rem",
              overflowY: "hidden",
            }}
          >
            <Link
              to={`/property-list/${property?.id}`}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              {property?.propertyTitle}
            </Link>
          </h5>
          <div
            className="mb-2"
            style={{
              height: "1rem",
              maxHeight: "3rem",
              overflowY: "hidden",
            }}
          >
            {property?.emirate}, {property?.community?.community} ,{" "}
            {property?.buildingName}
          </div>
          <div className="text-primary h5 product-price">
            AED{" "}
            {formatValue(
              property?.askingPrice ??
              property?.oneCheqPrice ??
              property?.twoCheqPrice ??
              property?.fourCheqprice ??
              property?.sixCheqPrice ??
              property?.twelveCheqPrice ??
              0
            )}
          </div>
        </div>
      </Card>
    </Col>
  );
};

const PropertyFinderListingPill = ({ portalInfo, idx }) => {
  const getStateStyling = (state) => {
    if (state === "approved") {
      return "bg-info-dim text-info";
    } else if (state === "draft" || state === "pending") {
      return "bg-warning-dim text-warning";
    } else if (state === "refused" || state === "archived") {
      return "bg-danger-dim text-danger";
    } else {
      return "bg-secondary-dim text-dark";
    }
  };

  return (
    <>
      <a
        id={`portal-${idx}`}
        className={`border-0 badge py-1 px-2 ${getStateStyling(portalInfo?.state)}`}
        href={`https://expert.propertyfinder.ae/app#properties/${portalInfo?.id}?tab=property-details`}
        target="_blank"
        rel="noreferrer"
      >
        {portalInfo?.reference}
      </a>
      <UncontrolledTooltip
        autohide={false}
        placement={"top"}
        target={`portal-${idx}`}
      >
        {portalInfo?.state} - click to view <br />
        {portalInfo?.createdByName ? `Published By ${portalInfo?.createdByName}` : null}
      </UncontrolledTooltip>
    </>
  );
}