import React, { useEffect, useState } from "react";
import { Block } from "../../../components/Component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Button, Spinner } from "reactstrap";
import Head from "../../../layout/head/Head";
import { useForm } from "react-hook-form";
import InputFile from "./InputFile";
import useDragAndDrop from "../../../utils/hooks/useDragAndDrop";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DnDKitDemo from "./DnDKitDemo";
import { deleteFromS3, uploadToS3 } from "../../../utils/envConfig";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const AddOffPlanStepFourEdit = (props) => {
  let {
    development,
    setDevelopment,
    setFilesToUpload,
    setFinalPlanDetails,
    developmentsFilesDev,
    offPlanUpdate,
    edit = false,
  } = props;

  const axios =useAxiosPrivate();
  const { errors, register, handleSubmit } = useForm();
  const {
    active: activeDevelopments,
    errorMessage: developmentsStatus,
    dragenter: developmentsDragEnter,
    dragleave: developmentsDragLeave,
    drop: developmentsDropVideo,
    handleFileChange: developmentsFileChange,
    files: developmentsFiles,
    setFiles: setDevelopmentsFiles,
    removeImage: removeDevelopmentImage,
  } = useDragAndDrop({
    multiple: true,
    inputType: "image",
    maxFilesLength: 10,
    minFilesLength: 8,
    labelName: "label",
  });
  useEffect(() => {
    setDevelopmentsFiles(developmentsFilesDev);
  }, [developmentsFilesDev]);
  const [toBeUpdated, setToBeUpdated] = useState({});

  const [toBeDeleted, setToBeDeleted] = useState({
      amenities: [],
      developmentPhotos: [],
      others: [],
      developmentLogo: "",
  });

  const [disabled, setDisabled] = useState(false);

  const addPhotosToFinal = async () => {
    if (developmentsFiles.length < 8)
        return toast.error("Please add at least 8 photos");
    if (developmentsFiles.length > 10)
        return toast.error("Cannot add more than 10 photos");

    const nullLabels = developmentsFiles.find(
        (file) => file?.label === "" || !file.label
    );

    if (nullLabels) return toast.error("All files must have a label.");

    const filesToUpload = [];
    const filesToDelete = [];

    const photos = developmentsFiles.map((file) => {
        if (typeof file?.file === "string") {
            return file;
        }

        const fileName = `offPlans/photos/${uuid()}.${file?.file?.name
            ?.split(".")
            ?.pop()}`;

        filesToDelete.push(file?.prevPhoto);

        filesToUpload.push({
            file: file?.file,
            type: "image/*",
            fileName,
        });

        return {
            ...file,
            fileName,
            image: URL.createObjectURL(file.file),
        };
    });

    filesToUpload.length &&
        setFilesToUpload((prev) => ({
            ...prev,
            developmentPhotos: filesToUpload,
        }));

    filesToDelete.length &&
        setToBeDeleted((prev) => ({
            ...prev,
            developmentPhotos: filesToDelete,
        }));

    setToBeUpdated((prev) => ({
        ...prev,
        developmentPhotos: photos?.map((item) => ({
            file:
                typeof item?.file === "string"
                    ? item?.file
                    : item?.fileName,
            label: item?.label,
        })),
    }));
    await saveAndExit();
};
const saveAndExit = async () => {
 
      if (developmentsFiles.length < 8)
          return toast.error(
              "Please add at least 8 photos"
          );
      if (developmentsFiles.length > 10)
          return toast.error(
              "Cannot add more than 10 photos"
          );

      const nullLabels = developmentsFiles.find(
          (file) =>
              file?.label === "" || !file.label
      );
      if (nullLabels)
      return toast.error(
          "All files must have a label."
      );
      try {
        setDisabled(true);
      let filesToDelete = [];

      const photos = await Promise.all(
          developmentsFiles.map(async (file) => {
              if (
                  typeof file?.file === "string"
              ) {
                  return file;
              }

              filesToDelete.push(
                  file?.prevPhoto
              );


              const fileName = `offPlans/photos/${uuid()}.${file?.file?.name
                  ?.split(".")
                  ?.pop()}`;

              await uploadToS3(
                  file.file,
                  fileName,
                  "image/*",
                  axios
              );

              return {
                  ...file,
                  file: fileName,
                  image: URL.createObjectURL(
                      file.file
                  ),
              };
          })
      );

     await offPlanUpdate({
          developmentPhotos: photos?.map(
              (item) => ({
                  file: item?.prevPhoto?item?.prevPhoto:item?.file,
                  label: item?.label,
              })
          ),
      });

      if (filesToDelete.length) {
        await Promise.all(
            filesToDelete.map(
                async (item) =>
                    await deleteFromS3(item,axios)
            )
        );
    }
    setDisabled(false);
    props.next();
  

  } catch (err) {
      // console.log(err);
      toast.error(err.message);
  } finally {
      setDisabled(false);
  }
}

  const submitForm = (data) => {
    // props.next();
    addPhotosToFinal();
  };

  return (
    <React.Fragment>
      <Head title="Off Plan"></Head>
      <Block>
        <form id="add-development-form" onSubmit={handleSubmit(submitForm)}>
          <p className="font-bold text-2xl text-center pb-2">
            Select development photos (minimum 8)
          </p>
          <InputFile
            active={activeDevelopments}
            dragenter={developmentsDragEnter}
            dragleave={developmentsDragLeave}
            drop={developmentsDropVideo}
            errorMessage={developmentsStatus}
            files={developmentsFiles}
            handleFileChange={developmentsFileChange}
            inputType="image"
            removeImage={removeDevelopmentImage}
            multiple
            inputProps={{
              accept: "image/*",
            }}
          />

          {developmentsFiles && developmentsFiles.length > 0 && (
            <DnDKitDemo
              images={developmentsFiles}
              setImages={setDevelopmentsFiles}
              removeImage={removeDevelopmentImage}
              desc={false}
              handlePropertyPhotosLabelChange
            />
          )}
          <div className="actions clearfix">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-between">
              <li>
                <Button color="primary" type="submit">
                 
                { disabled ? (
                              <Spinner size="sm" color="light" />
                                    ) : (
                                        "Save & Next"
                                    )}
                </Button>
              </li>
              <li>
                <Button color="light" onClick={props.prev} disabled>
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </Block>
    </React.Fragment>
  );
};

export default AddOffPlanStepFourEdit;
