import React, { useState, useEffect, useRef } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import ReactToPrint from "react-to-print";
import {
  Block,
  BlockHead,
  BlockTitle,
  BlockBetween,
  BlockHeadContent,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTable,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  PaginationComponent,
} from "../../components/Component";
import {
  Form,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Badge,
  Spinner,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { categoryOptions } from "./components/products/ProductData";
import SimpleBar from "simplebar-react";
import { useForm } from "react-hook-form";
import Dropzone from "react-dropzone";
import { Modal, ModalBody } from "reactstrap";
import { RSelect } from "../../components/Component";
import makeAnimated from "react-select/animated";
import { colourData } from "../components/forms/SelectData";
import { PreviewAltCard, PreviewCard } from "../../components/preview/Preview";
import {
  fetchCommunitiesList,
  fetchPropTypesList,
  fetchBuildings,
  fetchAmenitiesList,
} from "../../redux/actions/property";
import {
  propActiveStatus,
  ListingType,
  Status,
  BathRooms,
  BedRooms,
  newRoles,
} from "../../utils/envConfig";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import PropertyCard from "./components/products/PropertyCard";
import ProductDataTableRow from "./components/PropertyDataTableRow";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { fetchAgents } from "../../redux/actions/user";
import Skeleton from "react-loading-skeleton";

const ProductList = () => {
  const dispatch = useDispatch();
  const printRef = useRef();

  const axios = useAxiosPrivate();

  useEffect(() => {
    dispatch(fetchPropTypesList(axios));
    dispatch(fetchCommunitiesList(axios));
    dispatch(fetchBuildings(axios));
    dispatch(fetchAmenitiesList(axios));
  }, [axios, dispatch]);

  const authuser = useSelector((state) => state?.user?.loggedInUser);

  const community = useSelector((state) => state.property?.communities);
  const { buildings, buildingsLoading } = useSelector((state) => ({
    buildings: state.property?.buildings,
    buildingsLoading: state.property?.buildingsLoading,
  }));
  const amenitiesList = useSelector((state) => state.property?.amenities);
  const properties = useSelector((state) => state.property?.properties);
  const [propertiesActiveCount, setPropertiesActiveCount] = useState("");
  const [propertiesInactiveCount, setPropertiesInactiveCount] = useState("");
  const [propertiesCancelledCount, setPropertiesCancelledCount] = useState("");
  const [propertiesDraftCount, setPropertiesDraftCount] = useState("");
  const propType = useSelector((state) => state.property?.propertyTypes);
  const [props, setProps] = useState(properties);
  const [page, setPage] = useState(1);
  const [filCount, setFilCount] = useState(0);
  const [resPerPage, setResPerPage] = useState(0);
  const [totpages, setTotpages] = useState(0);
  const [flStatus, setFlStatus] = useState("");
  const [flArrayStatus, setFlArrayStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [flPropType, setFlPropType] = useState("");
  const [flType, setFlType] = useState("");
  const [flPID, setFlPID] = useState("");
  const [flSearchName, setFlSearchName] = useState("");
  const [flSearch, setFlSearch] = useState("");
  const [flCommunity, setFlCommunity] = useState("");
  const [flBuilding, setFlBuilding] = useState("");
  const filteredListingType = ListingType.filter(
    (type) => type !== "Sell" && type !== "Lease"
  );
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [selectedBuildings, setSelectedBuildings] = useState([]);
  const [selectedAmenties, setSelectedAmenties] = useState([]);
  const commOptions = community?.map((comm) => ({
    label: comm?.community,
    value: comm?.id,
  }));

  let buildingOptions = buildings?.length ? buildings : [];
  if (selectedCommunities?.length) {
    buildingOptions = buildingOptions?.filter((building) =>
      selectedCommunities?.map(comm => comm?.value)?.includes(building?.communityId)
    );
  }

  const buildingFilterOptions = buildingOptions?.map((building) => ({
    ...building,
    label: building?.name,
    value: building?.id ?? building?._id,
  }));

  const amentiesOptions = amenitiesList?.map((amenities) => ({
    label: amenities?.amenity,
    value: amenities?.id,
  }));

  const [flMinPrice, setFlMinPrice] = useState("");
  const [filMaxPrice, setFlMaxPrice] = useState("");
  const [filNoBedroom, setFilNoBedroom] = useState("");
  const [filNoBathroom, setFilNoBathroom] = useState("");
  const [flAmenity, setFlAmenity] = useState("");

  const [sortOption, setSortOption] = useState("");

  const [optAgents, setOptAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [filterAgent, setFilterAgent] = useState(null);

  const [sortByTitle, setSortByTitle] = useState("");
  const [sortByOrder, setSortByOrder] = useState("");

  useEffect(() => {
    const sortedUsers = [...props];

    sortedUsers.sort((a, b) => {
      const titleA = a[sortByTitle];
      const titleB = b[sortByTitle];
      return sortByOrder === "asc"
        ? titleA?.localeCompare(titleB)
        : titleB?.localeCompare(titleA);
    });

    setProps(sortedUsers);
  }, [sortByTitle, sortByOrder]);

  const { id } = useSelector((state) => state?.user?.loggedInUser);

  useEffect(() => {
    dispatch(fetchAgents(axios));
  }, []);

  const agent = useSelector((state) => state.user?.agents);

  useEffect(() => {
    let options = [];
    let result =
      agent &&
      agent?.length > 0 &&
      agent
        ?.filter((agents) => agents?.user?.active)
        ?.map((a) =>
          options.push({
            value: a?.id,
            label: a?.user?.first_name + " " + a?.user?.last_name,
          })
        );
    setOptAgents(options);
  }, [agent]);

  useEffect(() => {
    if (selectedCommunities.length > 0) {
      let idArr = [];
      selectedCommunities.forEach(function (obj) {
        idArr.push(obj.value);
      });
      setFlCommunity("community[]=" + idArr.join("&community[]="));
    } else {
      setFlCommunity("");
    }
  }, [selectedCommunities]);

  useEffect(() => {
    if (selectedBuildings.length > 0) {
      let idArr = [];
      selectedBuildings.forEach(function (obj) {
        idArr.push(obj.value);
      });
      setFlCommunity("building[]=" + idArr.join("&building[]="));
    } else {
      setFlCommunity("");
    }
  }, [selectedBuildings]);

  useEffect(() => {
    if (selectedAmenties.length > 0) {
      let idArr = [];
      selectedAmenties.forEach(function (obj) {
        idArr.push(obj.value);
      });
      setFlAmenity("amenity[]=" + idArr.join("&amenity[]="));
    } else {
      setFlAmenity("");
    }
  }, [selectedAmenties]);
  

  const [loading, setLoading] = useState(false)
  async function fetchProperties() {
    try {
      setLoading(true)
      let link = `/filter/propList?page=${page}`;
      if (flArrayStatus) link = link.concat(`&statusArray=${flArrayStatus}`);
      if (flStatus) link = link.concat(`&status=${flStatus}`);
      if (flType) link = link.concat(`&listingType=${flType}`);
      if (flPropType) link = link.concat(`&propertyType=${flPropType}`);
      if (flCommunity) link = link.concat(`&${flCommunity}`);
      if (flBuilding) link = link.concat(`&${flBuilding}`);
      if (flSearch) link = link.concat(`&search=${flPID}`);
      if (flSearchName) link = link.concat(`&search=${flSearchName}`);
      if (flMinPrice) {
        let cleanedMinPrice = flMinPrice.replace(/,/g, "");
        link = link.concat(`&minPrice=${cleanedMinPrice}`);
      }
      if (filMaxPrice) {
        let cleanedMaxPrice = filMaxPrice.replace(/,/g, "");
        link = link.concat(`&maxPrice=${cleanedMaxPrice}`);
      }
      if (filNoBedroom) link = link.concat(`&beds=${filNoBedroom}`);
      if (filNoBathroom) link = link.concat(`&baths=${filNoBathroom}`);
      if (flAmenity) link = link.concat(`&${flAmenity}`);
      if (filterAgent) {
        link = link.concat(`&agent_id=${filterAgent}`);
      } else if (authuser.role === newRoles.Agent) {
        let matchedId = agent
          .filter((obj) => obj.userId === id)
          .map((obj) => obj.id);
        setFilterAgent(matchedId[0]);

        link = link.concat(`&agent_id=${matchedId[0]}`);
      } else {
      }

      const res = await axios.get(link);
      if (sortOption === "asc") {
        res.data.findPropListsOutput = res.data.findPropListsOutput.sort(
          (a, b) => (a?.askingPrice ?? a?.emi) - (b?.askingPrice ?? b?.emi)
        );
      } else if (sortOption === "desc") {
        res.data.findPropListsOutput = res.data.findPropListsOutput.sort(
          (a, b) => (b?.askingPrice ?? b?.emi) - (a?.askingPrice ?? a?.emi)
        );
      } else if (sortOption === "oldest") {
        res.data.findPropListsOutput = res.data.findPropListsOutput.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      } else {
        res.data.findPropListsOutput = res.data.findPropListsOutput.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      }
      setProps(res.data.findPropListsOutput);
      // dispatch( fetchAllProperties( res.data.findPropListsOutput, axios ) );
      setFilCount(res.data.filteredCount);
      setResPerPage(res.data.resPerPage);
      setTotpages(
        Number(Math.ceil(res.data.filteredCount / res.data.resPerPage))
      );
    } catch (err) {
      // console.log( err );
    }finally{
      setLoading(false)
    }
  }

  async function fetchPropertiesPublishedCount() {
    try {
      let link = `/filter/propList?status=Active`;
      if (filterAgent) {
        link = link.concat(`&agent_id=${filterAgent}`);
      } else if (authuser.role === newRoles.Agent) {
        let matchedId = agent
          .filter((obj) => obj.userId === id)
          .map((obj) => obj.id);
        setFilterAgent(matchedId[0]);

        link = link.concat(`&agent_id=${matchedId[0]}`);
      } else {
      }

      const res = await axios.get(link);
      
      setPropertiesActiveCount(res?.data?.filteredCount)
    } catch (err) {
      // console.log( err );
    }
  }

  async function fetchPropertiesUnpublishedCount() {
    try {
      let link = `/filter/propList?status=Inactive`;
      if (filterAgent) {
        link = link.concat(`&agent_id=${filterAgent}`);
      } else if (authuser.role === newRoles.Agent) {
        let matchedId = agent
          .filter((obj) => obj.userId === id)
          .map((obj) => obj.id);
        setFilterAgent(matchedId[0]);

        link = link.concat(`&agent_id=${matchedId[0]}`);
      } else {
      }

      const res = await axios.get(link);

      setPropertiesInactiveCount(res?.data?.filteredCount)
    } catch (err) {
      // console.log( err );
    }
  }

  async function fetchPropertiesCancelledCount() {
    try {
      let link = `/filter/propList?statusArray=Cancelled`;
      if (filterAgent) {
        link = link.concat(`&agent_id=${filterAgent}`);
      } else if (authuser.role === newRoles.Agent) {
        let matchedId = agent
          .filter((obj) => obj.userId === id)
          .map((obj) => obj.id);
        setFilterAgent(matchedId[0]);

        link = link.concat(`&agent_id=${matchedId[0]}`);
      } else {
      }

      const res = await axios.get(link);
      
      setPropertiesCancelledCount(res?.data?.filteredCount)
    } catch (err) {
      // console.log( err );
    }
  }

  async function fetchPropertiesDraftCount() {
    try {
      let link = `/filter/propList?statusArray=Draft`;
      if (flArrayStatus) link = link.concat(`&statusArray=${flArrayStatus}`);
      if (flStatus) link = link.concat(`&status=${flStatus}`);
      if (flType) link = link.concat(`&listingType=${flType}`);
      if (flPropType) link = link.concat(`&propertyType=${flPropType}`);
      if (flCommunity) link = link.concat(`&${flCommunity}`);
      if (flBuilding) link = link.concat(`&${flBuilding}`);
      if (flSearch) link = link.concat(`&search=${flPID}`);
      if (flSearchName) link = link.concat(`&search=${flSearchName}`);
      if (flMinPrice) {
        let cleanedMinPrice = flMinPrice.replace(/,/g, "");
        link = link.concat(`&minPrice=${cleanedMinPrice}`);
      }
      if (filMaxPrice) {
        let cleanedMaxPrice = filMaxPrice.replace(/,/g, "");
        link = link.concat(`&maxPrice=${cleanedMaxPrice}`);
      }
      if (filNoBedroom) link = link.concat(`&beds=${filNoBedroom}`);
      if (filNoBathroom) link = link.concat(`&baths=${filNoBathroom}`);
      if (flAmenity) link = link.concat(`&${flAmenity}`);
      if (filterAgent) {
        link = link.concat(`&agent_id=${filterAgent}`);
      } else if (authuser.role === newRoles.Agent) {
        let matchedId = agent
          .filter((obj) => obj.userId === id)
          .map((obj) => obj.id);
        setFilterAgent(matchedId[0]);

        link = link.concat(`&agent_id=${matchedId[0]}`);
      } else {
      }

      const res = await axios.get(link);
      
      setPropertiesDraftCount(res?.data?.filteredCount)
    } catch (err) {
      // console.log( err );
    }
  }

  useEffect(() => {
    fetchPropertiesPublishedCount();
    fetchPropertiesUnpublishedCount();
    fetchPropertiesCancelledCount();
    fetchPropertiesDraftCount();
  }, [
    flStatus,
    flArrayStatus,
    flType,
    flSearchName,
    flPropType,
    flCommunity,
    flSearch,
    sortOption,
    flMinPrice,
    filMaxPrice,
    filNoBedroom,
    filNoBathroom,
    flAmenity,
    filterAgent]);

  const { isLoading, isError, error, data, isFetching } = useQuery(
    [
      "event",
      page,
      flStatus,
      flArrayStatus,
      flType,
      flSearchName,
      flPropType,
      flCommunity,
      flSearch,
      sortOption,
      flMinPrice,
      filMaxPrice,
      filNoBedroom,
      filNoBathroom,
      flAmenity,
      filterAgent,
    ],
    (keys) =>
      fetchProperties(keys[1], keys[2], keys[3], keys[5], keys[6], keys[7]),
    { keepPreviousData: true }
  );

  const options = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
    { value: "desc", label: "Price (High to Low)" },
    { value: "asc", label: "Price (Low to High)" },
  ];

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const submitIdSearch = async (e) => {
    e.preventDefault();
    setFlSearch(flPID);
  };

  const submitMinPriceSearch = async (e) => {
    e.preventDefault();
    if (!flMinPrice) {
      toast.error("Enter Minimum Price !");
    }
    setFlMinPrice(flMinPrice);
  };

  const submitMaxPriceSearch = async (e) => {
    e.preventDefault();
    if (!filMaxPrice) {
      toast.error("Enter Maximum Price !");
    }
    setFlMaxPrice(filMaxPrice);
  };

  const submitSearch = async (e) => {
    e.preventDefault();
    if (!flSearchName) {
      toast.error("Enter the text !");
    }
    setFlSearchName(flSearchName);
  };

  const handleClear = () => {
    setPage(1);
    setFlStatus("");
    setFlArrayStatus("");
    setFlPID("")
    setFlSearch("")
    setFlPropType("");
    setFlType("");
    setSelectedStatus("");
    setSelectedCommunities([]);
  };

  const [sm, updateSm] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    img: null,
    sku: "",
    price: 0,
    stock: 0,
    category: [],
    fav: false,
    check: false,
  });

  const [view, setView] = useState({
    edit: false,
    add: false,
    details: false,
  });

  const [files, setFiles] = useState([]);

  // OnChange function to get the input data
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // category change
  const onCategoryChange = (value) => {
    setFormData({ ...formData, category: value });
  };

  // handles ondrop function of dropzone
  const handleDropChange = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

  // unselects the data on mount
  useEffect(() => {
    let newData;
    newData = props.map((item) => {
      item.checked = false;
      return item;
    });
    setProps([...newData]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = props.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setProps([...filteredObject]);
    } else {
      setProps([...props]);
    }
  }, [onSearchText, props, setProps]);

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      balance: "",
      phone: "",
      status: "Active",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // submit function to update a new item
  const onEditSubmit = (submitData) => {
    const { name, email, phone } = submitData;
    let submittedData;
    let newitems = data;

    
    setModal({ edit: false });
    resetForm();
  };

  // function that loads the want to editted data
  const onEditClick = (e, id) => {
    e.preventDefault();
    setFormData({
      name: "",
      email: "item.email",
      status: "item.status",
      phone: "item.phone",
      balance: "item.balance",
    });
    setModal({ edit: true }, { add: false });
    setEditedId(id);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
 

  const [open, setOpen] = useState(false);
  const { errors, register, handleSubmit, reset } = useForm();
  const animatedComponents = makeAnimated();
  const toggleModal = () => {
    setOpen(!open);
  };
  const [viewType, setViewType] = useState("normal");
  function handleResetStatus() {
    setFlStatus(Status[""]);
    setFlArrayStatus("");
    setSelectedStatus(false);
  }
  return (
    <React.Fragment>
      <Head title="Property List"></Head>
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>All Properties</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    updateSm(!sm);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
              </div>
            </BlockHeadContent>
          </BlockBetween>
          {authuser?.role && authuser?.role !== newRoles?.Agent && (
            <BlockBetween>
              <BlockHeadContent>
                <span>Live: {propertiesActiveCount??0}</span>
              </BlockHeadContent>
            </BlockBetween>
          )}
          {authuser?.role && authuser?.role !== newRoles?.Agent && (
            <BlockBetween>
              <BlockHeadContent>
                <span>Unpublished: {propertiesInactiveCount??0}</span>
              </BlockHeadContent>
            </BlockBetween>
          )}
          {authuser?.role && authuser?.role !== newRoles?.Agent && (
            <BlockBetween>
              <BlockHeadContent>
                <span>Cancelled: {propertiesCancelledCount??0}</span>
              </BlockHeadContent>
            </BlockBetween> 
          )}
          <BlockBetween>
              <BlockHeadContent>
                <span>Result: {filCount}</span>
              </BlockHeadContent>
            </BlockBetween> 
          <div className="d-flex justify-content-end align-items-end">
            <div className="toggle-wrap nk-block-tools-toggle mt-4">
              <a
                href="#more"
                className="btn btn-icon btn-trigger toggle-expand me-n1"
                onClick={(ev) => {
                  ev.preventDefault();
                  updateSm(!sm);
                }}
              >
                <Icon name="more-v"></Icon>
              </a>
              <div
                className="toggle-expand-content"
                style={{ display: sm ? "block" : "none" }}
              >
                <ul className="nk-block-tools g-3">
                  <li>
                    <div className="form-control-wrap">
                      <div className="form-icon form-icon-right">
                        <Button
                          onClick={(e) => submitMinPriceSearch(e)}
                        ></Button>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="default-04"
                        placeholder="Min Price"
                        onChange={(e) => setFlMinPrice(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            submitMinPriceSearch(e);
                          }
                        }}
                      />
                    </div>

                    <span class="input-group-text">To</span>

                    <div className="form-control-wrap">
                      <div className="form-icon form-icon-right">
                        <Button
                          onClick={(e) => submitMaxPriceSearch(e)}
                        ></Button>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="default-04"
                        placeholder="Max Price"
                        onChange={(e) => setFlMaxPrice(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            submitMaxPriceSearch(e);
                          }
                        }}
                      />
                    </div>
                  </li>

                  <li>
                    <div className="form-group">
                      <RSelect
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        defaultData={[]}
                        isMulti
                        options={commOptions}
                        placeholder="Choose Community"
                        className="rselect-propertyListing"
                        value={selectedCommunities}
                        onChange={(e) => {
                          setSelectedBuildings([]);
                          setSelectedCommunities(e);
                        }}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <RSelect
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        defaultData={[]}
                        isMulti
                        options={amentiesOptions}
                        placeholder="Choose Amenities"
                        className="rselect-propertyListing"
                        value={selectedAmenties}
                        onChange={(e) => setSelectedAmenties(e)}
                      />
                    </div>
                  </li>
                </ul>

                <div className="d-flex justify-content-end align-items-end mt-2">
                  <ul className="nk-block-tools g-3">
                    {authuser?.role && authuser?.role !== newRoles?.Agent && (
                      <li>
                        <div className="status-div">
                          <RSelect
                            options={[
                              { value: "all", label: "All" },
                              ...optAgents,
                            ]}
                            value={selectedAgent}
                            placeholder="Filter By Agent"
                            onChange={(selectedOption) => {
                              if (selectedOption.value === "all") {
                                setFilterAgent("");
                                setSelectedAgent({
                                  value: "",
                                  label: "Filter By Agent",
                                });
                              } else {
                                setFilterAgent(selectedOption.value);
                                setSelectedAgent(selectedOption);
                              }
                            }}
                          />
                        </div>
                      </li>
                    )}
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {filNoBedroom ? filNoBedroom : "Beds"}
                        </DropdownToggle>
                        <DropdownMenu start>
                          <ul className="link-list-opt no-bdr">
                            {BedRooms &&
                              BedRooms.map((bed, idx) => (
                                <li key={`bed-${idx}`}>
                                  <DropdownItem
                                    onClick={() => setFilNoBedroom(bed)}
                                  >
                                    <span>{bed}</span>
                                  </DropdownItem>
                                </li>
                              ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {filNoBathroom ? filNoBathroom : "Baths"}
                        </DropdownToggle>
                        <DropdownMenu start>
                          <ul className="link-list-opt no-bdr">
                            {BathRooms &&
                              BathRooms.map((bath, idx) => (
                                <li key={`bath-${idx}`}>
                                  <DropdownItem
                                    onClick={() => setFilNoBathroom(bath)}
                                  >
                                    <span>{bath}</span>
                                  </DropdownItem>
                                </li>
                              ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {selectedStatus ? selectedStatus : "Status"}
                        </DropdownToggle>
                        <DropdownMenu start>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                onClick={() => {
                                  setFlStatus(Status[0]);
                                  setFlArrayStatus('')
                                  setSelectedStatus(propActiveStatus[0]);
                                }}
                              >
                                <span>{propActiveStatus[0]}</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                onClick={() => {
                                  setFlStatus(Status[1]);
                                  setFlArrayStatus('')
                                  setSelectedStatus(propActiveStatus[1]);
                                }}
                              >
                                <span>{propActiveStatus[1]}</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                onClick={() => {
                                  setFlStatus(Status[""]);
                                  setFlArrayStatus('Private')
                                  setSelectedStatus(propActiveStatus[4]);
                                }}
                              >
                                <span>Private</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                onClick={() => {
                                  setFlStatus(Status[""]);
                                  setFlArrayStatus('Draft')
                                  setSelectedStatus(propActiveStatus[3]);
                                }}
                              >
                                <span>Draft</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                onClick={() => {
                                  setFlStatus(Status[""]);
                                  setFlArrayStatus('Cancelled')
                                  setSelectedStatus(propActiveStatus[2]);
                                }}
                              >
                                <span>Cancelled</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {flType ? flType : "Purpose"}
                        </DropdownToggle>
                        <DropdownMenu start>
                          <ul className="link-list-opt no-bdr">
                            {filteredListingType.map((x, y) => (
                              <li>
                                <DropdownItem
                                  onClick={(ev) => {
                                    setFlType(x);
                                  }}
                                >
                                  <span>{x}</span>
                                </DropdownItem>
                              </li>
                            ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {flPropType
                            ? propType?.map((type) =>
                              type.id === flPropType
                                ? type?.propertyType
                                : null
                            )
                            : "Type"}
                        </DropdownToggle>
                        <DropdownMenu start>
                          <ul className="link-list-opt no-bdr">
                            {propType &&
                              propType.map((type) => (
                                <li>
                                  <DropdownItem
                                    onClick={() => setFlPropType(type.id)}
                                  >
                                    <span>{type?.propertyType}</span>
                                  </DropdownItem>
                                </li>
                              ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {sortOption
                            ? options?.map((type) =>
                              type.value === sortOption ? type?.label : ""
                            )
                            : "Sort By"}
                        </DropdownToggle>
                        <DropdownMenu start>
                          <ul className="link-list-opt no-bdr">
                            {options.map((x) => (
                              <li>
                                <DropdownItem
                                  onClick={() => setSortOption(x.value)}
                                >
                                  <span>{x.label}</span>
                                </DropdownItem>
                              </li>
                            ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div>
                <div className="d-flex flex-md-row flex-column-reverse justify-content-end align-items-md-center align-items-end mt-2" style={{ gap: "1rem" }}>
                  <div className="form-control-wrap align-self-stretch">
                      <div className="form-icon form-icon-right">
                        <Button onClick={(e) => submitIdSearch(e)}>
                          <Icon name="search"></Icon>
                        </Button>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="default-04"
                        placeholder="Search by ID or Permit No"
                        onChange={(e) => setFlPID(e.target.value)}
                        value={flPID}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            submitIdSearch(e);
                          }
                        }}
                      />
                  </div>
                  <RSelect
                    placeholder="Choose Building"
                    className="status-div building-width-custom"
                    isMulti
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    defaultData={[]}
                    isLoading={buildingsLoading}
                    loadingMessage={() => <Spinner />}
                    options={buildingFilterOptions}
                    value={selectedBuildings}
                    onChange={(e) => setSelectedBuildings(e)}
                />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "end",
                  }}
                >
                  <div className="row mt-3 gx-1">
                    {selectedStatus && (
                      <div className="col">
                        <Badge color="primary">
                          {selectedStatus}
                          <Icon
                            name="cross-sm"
                            onClick={handleResetStatus}
                          ></Icon>{" "}
                        </Badge>
                      </div>
                    )}
                    
                    {flPID&& (
                      <div className="col">
                        <Badge color="primary">
                          search:{flPID}
                          <Icon
                            name="cross-sm"
                            onClick={(e)=>{
                              setFlPID("")
                              setFlSearch("")
                            }}
                          ></Icon>{" "}
                        </Badge>
                      </div>
                    )}


                    {sortOption && (
                      <div className="col">
                        <Badge
                          label={`${sortOption
                            ? options?.map((type) =>
                              type.value === sortOption ? type?.label : ""
                            )
                            : "Sort By"
                            }`}
                          color="gray"
                        >
                          {sortOption
                            ? options?.map((type) =>
                              type.value === sortOption ? type?.label : ""
                            )
                            : ""}
                          <Icon
                            name="cross-sm"
                            onClick={() => setSortOption("")}
                          ></Icon>{" "}
                        </Badge>
                      </div>
                    )}

                    {flType && (
                      <div className="col">
                        <Badge label={flType}>
                          {flType}
                          <Icon
                            name="cross-sm"
                            onClick={() => setFlType("")}
                          ></Icon>{" "}
                        </Badge>
                      </div>
                    )}

                    {filNoBedroom && (
                      <div className="col">
                        <Badge label={`Beds: ${filNoBedroom}`} color="info">
                          {`Beds: ${filNoBedroom}`}
                          <Icon
                            name="cross-sm"
                            onClick={() => setFilNoBedroom("")}
                          ></Icon>{" "}
                        </Badge>
                      </div>
                    )}

                    {filNoBathroom && (
                      <div className="col">
                        <Badge
                          label={`Baths: ${filNoBathroom}`}
                          color="warning"
                        >
                          {`Baths: ${filNoBathroom}`}
                          <Icon
                            name="cross-sm"
                            onClick={() => setFilNoBathroom("")}
                          ></Icon>{" "}
                        </Badge>
                      </div>
                    )}

                    {propType ?
                      propType.map((type) =>
                        type.id === flPropType ? (
                          <div className="col" key={type?.id}>
                            <Badge color="danger">
                              {type?.propertyType}
                              <Icon
                                name="cross-sm"
                                onClick={() => setFlPropType("")}
                              ></Icon>
                            </Badge>
                          </div>
                        ) : null
                      ):null}

                    {filterAgent &&
                      authuser?.role &&
                      authuser?.role !== newRoles?.Agent && (
                        <div className="col">
                          <Badge label={selectedAgent.label} color="black">
                            {"Agent: "}
                            {selectedAgent.label}
                            <Icon
                              name="cross-sm"
                              onClick={() => {
                                setFilterAgent("");
                                setSelectedAgent({
                                  value: "",
                                  label: "Filter By Agent",
                                });
                              }}
                            ></Icon>{" "}
                          </Badge>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BlockHead>
        <Block size="lg">
          <PreviewCard>
            <div className="d-flex justify-content-end align-items-end">
              <ul className="nk-block-tools g-3 mb-2 pe-2 ">
                <Button
                  onClick={() =>
                    setViewType(viewType === "normal" ? "list" : "normal")
                  }
                  color={"primary"}
                >
                  <Icon name={"list"} className={"pe-1 "}></Icon>
                  {viewType === "normal" ? "List View" : "Grid View"}
                </Button>
              </ul>
            </div>

            {viewType === "normal" ? (
              <Row>
              {loading?new Array(3).fill(0).map((x,i)=>(
                <Col
                      md="6"
                      className="col-xl-4 col-xxxl-3 px-4 my-3"
                      key={x+i}
                    >
                    <Card className="p-0">
                     <CardHeader className="p-1">
                     <Skeleton height={200} />
                     </CardHeader>
                       <CardBody className="bg-white">
                       <Skeleton className="w-50 py-2" />
                       <Skeleton className="w-90 py-3 my-1" />
                       <Skeleton count={2} className="w-40 my-2 py-1" />
                       <Skeleton count={3} className="w-30  my-2" />
                       </CardBody>
                    </Card>
                    </Col>
              )):null}
                {!isFetching && !isLoading  && props?.length > 0  ?
                  props?.map((property, i) => (
                    <Col
                      md="6"
                      className="col-xl-4 col-xxxl-3 "
                      key={property?.id}
                    >
                      <PropertyCard property={property} index={i} />
                    </Col>
                  )):null}
                {/* Pagination start */}
                {!isFetching && !isLoading && props && props?.length > 0 ? (
                  <div className="mt-3">
                    <PaginationComponent
                      currentPage={page}
                      paginate={setPage}
                      itemPerPage={resPerPage}
                      totalItems={filCount}
                    />
                  </div>
                ) : (
                  <div className="text-center">
                   {!loading&& <span className="text-silent">No Properties Found</span>}
                  </div>
                )}
                {/* Pagination end */}
              </Row>
            ) : (
              <div>
              <BlockHead size="sm">
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockDes className="">
                          <p>You have total {filCount} properties.</p>
                        </BlockDes>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <div className="toggle-wrap nk-block-tools-toggle">
                          <Button
                            className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""
                              }`}
                            onClick={() => updateSm(!sm)}
                          >
                            <Icon name="menu-alt-r"></Icon>
                          </Button>
                          <div
                            className="toggle-expand-content"
                            style={{
                              display: sm ? "block" : "none",
                            }}
                          >
                            <ul className="nk-block-tools g-3">
                              <li>
                                <ReactToPrint
                                  content={() => printRef.current}
                                  documentTitle="Properties"
                                  removeAfterPrint
                                  bodyClass="p-4"
                                  trigger={() => (
                                    <Button
                                      color="light"
                                      outline
                                      className="btn-white"
                                    >
                                      <Icon name="download-cloud"></Icon>
                                      <span>Print</span>
                                    </Button>
                                  )}
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Block>
                    <DataTable className="card-stretch">
                      <div className="card-inner position-relative card-tools-toggle userRegularList-innercard">
                        <div className="card-title-group">
                          <div className="card-tools">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color="transparent"
                                className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                              >
                                {selectedStatus ? selectedStatus : "Status"}
                              </DropdownToggle>
                              <DropdownMenu start>
                                <ul className="link-list-opt no-bdr">
                                  <li>
                                    <DropdownItem
                                      onClick={() => {
                                        setFlStatus(Status[0]);
                                        setSelectedStatus(propActiveStatus[0]);
                                      }}
                                    >
                                      <span>{propActiveStatus[0]}</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      onClick={() => {
                                        setFlStatus(Status[1]);
                                        setSelectedStatus(propActiveStatus[1]);
                                      }}
                                    >
                                      <span>{propActiveStatus[1]}</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                           
                          </div>
                          <div className="card-tools me-n1">
                            <ul className="btn-toolbar gx-1">
                              <li>
                                <a
                                  href="#search"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    toggle();
                                  }}
                                  className="btn btn-icon search-toggle toggle-search"
                                >
                                  <Icon name="search"></Icon>
                                </a>
                              </li>
                              <li className="btn-toolbar-sep"></li>
                              <li>
                                <div className="toggle-wrap">
                                  <Button
                                    className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""
                                      }`}
                                    onClick={() => updateTableSm(true)}
                                  >
                                    <Icon name="menu-right"></Icon>
                                  </Button>
                                  <div
                                    className={`toggle-content ${tablesm ? "content-active" : ""
                                      }`}
                                  >
                                    <ul className="btn-toolbar gx-1">
                                      <li className="toggle-close">
                                        <Button
                                          className="btn-icon btn-trigger toggle"
                                          onClick={() => updateTableSm(false)}
                                        >
                                          <Icon name="arrow-left"></Icon>
                                        </Button>
                                      </li>
                                      <li>
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            tag="a"
                                            className="btn btn-trigger btn-icon dropdown-toggle"
                                          >
                                            <div className="dot dot-primary"></div>
                                            <Icon name="filter-alt"></Icon>
                                          </DropdownToggle>
                                          <DropdownMenu
                                            start
                                            className="filter-wg dropdown-menu-xl"
                                            style={{
                                              overflow: "visible",
                                            }}
                                          >
                                            <div className="dropdown-head">
                                              <span className="sub-title dropdown-title">
                                                Filter Properties
                                              </span>
                                              <div className="dropdown">
                                                <a
                                                  href="#more"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                  }}
                                                  className="btn btn-sm btn-icon"
                                                >
                                                  <Icon name="more-h"></Icon>
                                                </a>
                                              </div>
                                            </div>
                                            <div className="dropdown-body dropdown-body-rg">
                                              <Row className="gx-6 gy-3">
                                                <Col size="6">
                                                  <div className="form-group">
                                                    <UncontrolledDropdown>
                                                      <DropdownToggle
                                                        color="transparent"
                                                        className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                                                      >
                                                        {flPropType
                                                          ? propType?.map(
                                                            (type) =>
                                                              type.id ===
                                                                flPropType
                                                                ? type?.propertyType
                                                                : null
                                                          )
                                                          : "Type"}
                                                      </DropdownToggle>
                                                      <DropdownMenu start>
                                                        <ul className="link-list-opt no-bdr">
                                                          {propType?
                                                            propType.map(
                                                              (type) => (
                                                                <li key={type?.id}>
                                                                  <DropdownItem
                                                                    onClick={() =>
                                                                      setFlPropType(
                                                                        type.id
                                                                      )
                                                                    }
                                                                  >
                                                                    <span>
                                                                      {
                                                                        type?.propertyType
                                                                      }
                                                                    </span>
                                                                  </DropdownItem>
                                                                </li>
                                                              )
                                                            ):null}
                                                        </ul>
                                                      </DropdownMenu>
                                                    </UncontrolledDropdown>

                                                  </div>
                                                </Col>
                                                <Col size="6">
                                                  <div className="form-group">
                                                    <UncontrolledDropdown>
                                                      <DropdownToggle
                                                        color="transparent"
                                                        className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                                                      >
                                                        {selectedStatus?? "Status"}
                                                      </DropdownToggle>
                                                      <DropdownMenu start>
                                                        <ul className="link-list-opt no-bdr">
                                                          <li>
                                                            <DropdownItem
                                                              onClick={() => {
                                                                setFlStatus(
                                                                  Status[0]
                                                                );
                                                                setSelectedStatus(
                                                                  propActiveStatus[0]
                                                                );
                                                              }}
                                                            >
                                                              <span>
                                                                {
                                                                  propActiveStatus[0]
                                                                }
                                                              </span>
                                                            </DropdownItem>
                                                          </li>
                                                          <li>
                                                            <DropdownItem
                                                              onClick={() => {
                                                                setFlStatus(
                                                                  Status[1]
                                                                );
                                                                setSelectedStatus(
                                                                  propActiveStatus[1]
                                                                );
                                                              }}
                                                            >
                                                              <span>
                                                                {
                                                                  propActiveStatus[1]
                                                                }
                                                              </span>
                                                            </DropdownItem>
                                                          </li>
                                                        </ul>
                                                      </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                 
                                                  </div>
                                                </Col>
                                                <Col size="6">
                                                  <div className="form-group">
                                                    <UncontrolledDropdown>
                                                      <DropdownToggle
                                                        color="transparent"
                                                        className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                                                      >
                                                        {flType?? "Purpose"}
                                                      </DropdownToggle>
                                                      <DropdownMenu start>
                                                        <ul className="link-list-opt no-bdr">
                                                          {filteredListingType.map(
                                                            (x, y) => (
                                                              <li key={x}>
                                                                <DropdownItem
                                                                  onClick={(
                                                                    ev
                                                                  ) => {
                                                                    setFlType(
                                                                      x
                                                                    );
                                                                  }}
                                                                >
                                                                  <span>
                                                                    {x}
                                                                  </span>
                                                                </DropdownItem>
                                                              </li>
                                                            )
                                                          )}
                                                        </ul>
                                                      </DropdownMenu>
                                                    </UncontrolledDropdown>
                                              
                                                  </div>
                                                </Col>
                                                <Col size="6">
                                                  <div className="form-group">
                                                    <RSelect
                                                      closeMenuOnSelect={false}
                                                      components={
                                                        animatedComponents
                                                      }
                                                      defaultData={[]}
                                                      isMulti
                                                      options={commOptions}
                                                      placeholder="Community"
                                                      value={
                                                        selectedCommunities
                                                      }
                                                      // className="rselect-propertyListing"
                                                      onChange={(e) =>
                                                        setSelectedCommunities(
                                                          e
                                                        )
                                                      }
                                                    />
                                                 
                                                  </div>
                                                </Col>
                                               
                                              </Row>
                                            </div>
                                            <div className="dropdown-foot between">
                                              <a
                                                href="#reset"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  handleClear();
                                                }}
                                                className="clickable"
                                              >
                                                Reset Filter
                                              </a>
                                              <a
                                                href="#save"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                }}
                                              >
                                                Save Filter
                                              </a>
                                            </div>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </li>
                                      <li>
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            color="tranparent"
                                            className="btn btn-trigger btn-icon dropdown-toggle"
                                          >
                                            <Icon name="setting"></Icon>
                                          </DropdownToggle>
                                          <DropdownMenu
                                            start
                                            className="dropdown-menu-md"
                                          >
                                            <ul className="link-check">
                                              <li>
                                                <span>Show</span>
                                              </li>
                                              <li
                                                className={
                                                  itemPerPage === 10
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                <DropdownItem
                                                  tag="a"
                                                  href="#dropdownitem"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setItemPerPage(10);
                                                  }}
                                                >
                                                  10
                                                </DropdownItem>
                                              </li>
                                              <li
                                                className={
                                                  itemPerPage === 15
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                <DropdownItem
                                                  tag="a"
                                                  href="#dropdownitem"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setItemPerPage(15);
                                                  }}
                                                >
                                                  15
                                                </DropdownItem>
                                              </li>
                                            </ul>
                                            <ul className="link-check">
                                              <li>
                                                <span>Sort By</span>
                                              </li>
                                              <li
                                                className={
                                                  sort === "newest"
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                <DropdownItem
                                                  value="newest"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    handleSortChange(ev);
                                                    // setSortState( "dsc" );
                                                    // sortFunc( "dsc" );
                                                  }}
                                                >
                                                  Newest
                                                </DropdownItem>
                                              </li>
                                              <li
                                                className={
                                                  sort === "oldest"
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                <DropdownItem
                                                  // tag="a"
                                                  // href="#dropdownitem"
                                                  value="oldest"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    handleSortChange(ev);
                                                    // setSortState( "asc" );
                                                    // sortFunc( "asc" );
                                                  }}
                                                >
                                                  Oldest
                                                </DropdownItem>
                                              </li>
                                              <li
                                                className={
                                                  sort === "asc" ? "active" : ""
                                                }
                                              >
                                                <DropdownItem
                                                  // tag="a"
                                                  // href="#dropdownitem"
                                                  value={"asc"}
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    handleSortChange(ev);
                                                    // sortFunc( "asc" );
                                                  }}
                                                >
                                                  Price (low to high)
                                                </DropdownItem>
                                              </li>
                                              <li
                                                className={
                                                  sort === "asc" ? "active" : ""
                                                }
                                              >
                                                <DropdownItem
                                                  // tag="a"
                                                  // href="#dropdownitem"
                                                  value={"desc"}
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    handleSortChange(ev);
                                                    // sortFunc( "asc" );
                                                  }}
                                                >
                                                  Price (high to low)
                                                </DropdownItem>
                                              </li>
                                             
                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#dropdownitem"
                                                >
                                                  Last Activity (DESC)
                                                </DropdownItem>
                                              </li>
                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#dropdownitem"
                                                >
                                                  Last Activity (ASC)
                                                </DropdownItem>
                                              </li>
                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#dropdownitem"
                                                >
                                                  Last Modified (DESC)
                                                </DropdownItem>
                                              </li>
                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#dropdownitem"
                                                >
                                                  Last Modified (ASC)
                                                </DropdownItem>
                                              </li>
                                            </ul>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          className={`card-search search-wrap ${!onSearch && "active"
                            }`}
                        >
                          <div className="card-body">
                            <div className="search-content">
                              <Button
                                className="search-back btn-icon toggle-search active"
                                onClick={() => {
                                  setFlSearchName("");
                                  toggle();
                                }}
                              >
                                <Icon name="arrow-left"></Icon>
                              </Button>
                              <input
                                type="text"
                                className="border-transparent form-focus-none form-control"
                                placeholder="Search by user or email"
                                value={flSearchName}
                                onChange={(e) =>
                                  setFlSearchName(e.target.value)
                                }
                              />
                              <Button
                                className="search-submit btn-icon"
                                onClick={(e) => submitSearch(e)}
                              >
                                <Icon name="search"></Icon>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div ref={printRef} id="printableId">
                        <DataTableBody>
                          <DataTableHead>
                            <DataTableRow className="nk-tb-col-check">
                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  // onChange={ ( e ) => selectorCheck( e ) }
                                  id="uid"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="uid"
                                ></label>
                              </div>
                            </DataTableRow>
                            <DataTableRow>
                              <div
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSortByTitle("referNo");
                                  setSortByOrder(
                                    sortByOrder === "asc" ? "desc" : "asc"
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="sub-text">
                                  ID
                                  <Icon className="ms-1">
                                    <span>&darr;</span>
                                    <span>&uarr;</span>
                                  </Icon>
                                </span>
                              </div>
                            </DataTableRow>
                            <DataTableRow className="d-sm-none d-xxl-table-cell">
                              <span className="sub-text d-sm-none d-xxl-inline-block">
                                Agent
                              </span>
                            </DataTableRow>
                            <DataTableRow size="mb" className="w-20">
                              <div
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSortByTitle("propertyTitle");
                                  setSortByOrder(
                                    sortByOrder === "asc" ? "desc" : "asc"
                                  );
                                  // sortFunc( "asc" );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="sub-text">
                                  Title
                                  <Icon className="ms-1">
                                    <span>&darr;</span>
                                    <span>&uarr;</span>
                                  </Icon>
                                </span>
                              </div>
                            </DataTableRow>

                            <DataTableRow size="md">
                              <div
                                onClick={(ev) => {
                                  ev.preventDefault();

                                  setSortOption(
                                    sortOption === "asc" ? "desc" : "asc"
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="sub-text">
                                  Price
                                  <Icon className="ms-1">
                                    <span>&darr;</span>
                                    <span>&uarr;</span>
                                  </Icon>
                                </span>
                              </div>
                            </DataTableRow>
                            <DataTableRow size="lg">
                              <div
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSortByTitle("listingType");
                                  setSortByOrder(
                                    sortByOrder === "asc" ? "desc" : "asc"
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="sub-text">
                                  Purpose
                                  <Icon className="ms-1">
                                    <span>&darr;</span>
                                    <span>&uarr;</span>
                                  </Icon>
                                </span>
                              </div>
                            </DataTableRow>
                            <DataTableRow size="lg">
                              <span className="sub-text">Owner Name</span>
                            </DataTableRow>
                            <DataTableRow size="md">
                              <span className="sub-text">Owner Phone</span>
                            </DataTableRow>
                            <DataTableRow size="md">
                              <div
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSortByTitle("status");
                                  setSortByOrder(
                                    sortByOrder === "asc" ? "desc" : "asc"
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="sub-text">
                                  Status
                                  <Icon className="ms-1">
                                    <span>&darr;</span>
                                    <span>&uarr;</span>
                                  </Icon>
                                </span>
                              </div>
                            </DataTableRow>
                            <DataTableRow
                              size="md"
                              className="d-sm-none d-xxl-inline-block"
                            >
                              <span className="sub-text d-sm-none d-xxl-inline-block">
                                last Activity
                              </span>
                            </DataTableRow>
                            <DataTableRow
                              size="md"
                              className="d-sm-none d-xxl-inline-block"
                            >
                              <span className="sub-text d-sm-none d-xxl-inline-block">
                                Last Modified
                              </span>
                            </DataTableRow>
                            <DataTableRow
                              size="md"
                              className="d-sm-none d-xxl-inline-block"
                            >
                              <div
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSortByTitle("createdAt");
                                  setSortByOrder(
                                    sortByOrder === "asc" ? "desc" : "asc"
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="sub-text d-sm-none d-xxl-inline-block">
                                  Time in Stock
                                  <Icon className="ms-1">
                                    <span>&darr;</span>
                                    <span>&uarr;</span>
                                  </Icon>
                                </span>
                              </div>
                            </DataTableRow>
                          </DataTableHead>
                          {/*Head*/}
                          {props &&
                            props?.length > 0 &&
                            props?.map((item) => {
                              return (
                                <ProductDataTableRow
                                  property={item}
                                  onEditClick={onEditClick}
                                />
                              );
                            })}
                        </DataTableBody>
                      </div>
                    </DataTable>

                    {/* Pagination start */}
                    <PreviewAltCard>
                      {!isFetching &&
                        !isLoading &&
                        props &&
                        props?.length > 0 ? (
                        <div className="mt-3">
                          <PaginationComponent
                            currentPage={page}
                            paginate={setPage}
                            itemPerPage={resPerPage}
                            totalItems={filCount}
                          />
                        </div>
                      ) : (
                        <div className="text-center">
                          <span className="text-silent">
                            No Properties Found
                          </span>
                        </div>
                      )}
                    </PreviewAltCard>
                    {/* Pagination end */}
                  </Block>
              </div>
            )}
          </PreviewCard>
        </Block>



        <Modal
          // isOpen={modal.edit}
          isOpen={false}
          toggle={() => setModal({ edit: false })}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update Property</h5>
              <div className="mt-4">
                <Form
                  className="row gy-4"
                  onSubmit={handleSubmit(onEditSubmit)}
                >
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Assigned Agent</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({
                          required: "This field is required",
                        })}
                      />
                      {errors.name && (
                        <span className="invalid">{errors.name.message}</span>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Owner Email</label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Enter email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="invalid">{errors.email.message}</span>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Price</label>
                      <input
                        className="form-control"
                        type="number"
                        name="balance"
                        disabled
                        // defaultValue={ parseFloat( formData.balance.replace( /,/g, "" ) ) }
                        placeholder="Balance"
                        ref={register({
                          required: "This field is required",
                        })}
                      />
                      {errors.balance && (
                        <span className="invalid">
                          {errors.balance.message}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Price Drop</label>
                      <input
                        className="form-control"
                        type="number"
                        name="balance"
                        disabled
                        // defaultValue={ parseFloat( formData.balance.replace( /,/g, "" ) ) }
                        placeholder="Balance"
                        ref={register({
                          required: "This field is required",
                        })}
                      />
                      {errors.balance && (
                        <span className="invalid">
                          {errors.balance.message}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="phone"
                        defaultValue={Number(formData.phone)}
                        ref={register({
                          required: "This field is required",
                        })}
                      />
                      {errors.phone && (
                        <span className="invalid">{errors.phone.message}</span>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Status</label>
                      <div className="form-control-wrap">
                        {/* <RSelect
                          options={ filterStatus }
                          defaultValue={ {
                            value: formData.status,
                            label: formData.status,
                          } }
                          onChange={ ( e ) => setFormData( { ...formData, status: e.value } ) }
                        /> */}
                      </div>
                    </div>
                  </Col>

                  <Col size={6}>
                    <div className="data-label">Exclusive</div>
                    <div className="data-value">
                      {" "}
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked
                          id="customSwitch4"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitch4"
                        >
                          Since 1 Day ago
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col size={6}>
                    <div className="data-label">Deal of the Week</div>
                    <div className="data-value">
                      {" "}
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked
                          id="customSwitch4"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitch4"
                        >
                          Since 1 Day ago
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col size={6}>
                    <div className="data-label">Premium</div>
                    <div className="data-value">
                      {" "}
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked
                          id="customSwitch4"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitch4"
                        >
                          Since 1 Day ago
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col size={6}>
                    <div className="data-label">Featured</div>
                    <div className="data-value">
                      {" "}
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          defaultChecked
                          id="customSwitch4"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitch4"
                        >
                          Since 1 Day ago
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="success" size="md" type="submit">
                          Save Changes{" "}
                        </Button>
                      </li>
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Property Details{" "}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-danger"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={view.details}
          toggle={() => onFormCancel()}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a href="#cancel" className="close">
              {" "}
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="nk-modal-head">
              <h4 className="nk-modal-title title">
                Product <small className="text-primary">#{formData.sku}</small>
              </h4>
              <img src={formData.img} alt="" />
            </div>
            <div className="nk-tnx-details mt-sm-3">
              <Row className="gy-3">
                <Col lg={6}>
                  <span className="sub-text">Product Name</span>
                  <span className="caption-text">{formData.name}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Product Price</span>
                  <span className="caption-text">AED {formData.price}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Product Category</span>
                  {/* <span className="caption-text">
                    { formData.category.map( ( item, index ) => (
                      <Badge key={ index } className="me-1" color="secondary">
                        { item.value }
                      </Badge>
                    ) ) }
                  </span> */}
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Stock</span>
                  <span className="caption-text"> {formData.stock}</span>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>

        <SimpleBar
          className={`nk-add-product toggle-slide toggle-slide-right toggle-screen-any AED{ view.add ? "content-active" : ""
            }`}
        >
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h5">Add Product</BlockTitle>
              <BlockDes>
                <p>Add information or update product.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>
          <Block>
            <form onSubmit={null}>
              <Row className="g-3">
                <Col size="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="product-title">
                      Product Title
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        onChange={(e) => onInputChange(e)}
                        ref={register({
                          required: "This field is required",
                        })}
                        defaultValue={formData.name}
                      />
                      {errors.title && (
                        <span className="invalid">{errors.title.message}</span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="regular-price">
                      Regular Price
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        name="price"
                        ref={register({
                          required: "This is required",
                        })}
                        onChange={(e) => onInputChange(e)}
                        className="form-control"
                        defaultValue={formData.price}
                      />
                      {errors.price && (
                        <span className="invalid">{errors.price.message}</span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="sale-price">
                      Sale Price
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        className="form-control"
                        name="salePrice"
                        onChange={(e) => onInputChange(e)}
                        ref={register({
                          required: "This is required",
                        })}
                        defaultValue={formData.price}
                      />
                      {errors.salePrice && (
                        <span className="invalid">
                          {errors.salePrice.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="stock">
                      Stock
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        className="form-control"
                        name="stock"
                        onChange={(e) => onInputChange(e)}
                        ref={register({
                          required: "This is required",
                        })}
                        defaultValue={formData.stock}
                      />
                      {errors.stock && (
                        <span className="invalid">{errors.stock.message}</span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="SKU">
                      SKU
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control"
                        name="sku"
                        onChange={(e) => onInputChange(e)}
                        ref={register({
                          required: "This is required",
                        })}
                        defaultValue={formData.sku}
                      />
                      {errors.sku && (
                        <span className="invalid">{errors.sku.message}</span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col size="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="category">
                      Category
                    </label>
                    <div className="form-control-wrap">
                      <RSelect
                        name="category"
                        isMulti
                        options={categoryOptions}
                        onChange={onCategoryChange}
                        value={formData.category}
                      //ref={register({ required: "This is required" })}
                      />
                      {errors.category && (
                        <span className="invalid">
                          {errors.category.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col size="12">
                  <Dropzone
                    onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                        >
                          <input {...getInputProps()} />
                          {files.length === 0 && (
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          )}
                          {files.map((file) => (
                            <div
                              key={file.name}
                              className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                            >
                              <div className="dz-image">
                                <img src={file.preview} alt="preview" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Col>

                <Col size="12">
                  <Button color="primary" type="submit">
                    <Icon className="plus"></Icon>
                    <span>Add Product</span>
                  </Button>
                </Col>
              </Row>
            </form>
          </Block>
        </SimpleBar>

        {view.add && <div className="toggle-overlay" onClick={toggle}></div>}
      </Content>
      <Modal size="lg" isOpen={open} toggle={toggleModal}>
        <div toggle={toggleModal}>
          <RSelect
            closeMenuOnSelect={false}
            components={animatedComponents}
            defaultData={[colourData[0], colourData[1]]}
            isMulti
            options={colourData}
            placeholder="Choose Agent"
            className="rselect-propertyListing"
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ProductList;
