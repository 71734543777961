import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form, Spinner, Alert } from 'reactstrap';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import {
    Block,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockTitle,
    Button,
    Icon,
    PreviewCard
} from '../../components/Component';

import Logo from '../../assets/images/albahomes-logo.png';

import PageContainer from '../../layout/page-container/PageContainer';
import Head from '../../layout/head/Head';

import AuthFooter from './AuthFooter';

import {
    newRoles,
    serverAPI,
    validateEmail,
    validatePasswordNew
} from '../../utils/envConfig';

import { USER_LOGIN } from '../../redux/types';
import { useSelector } from 'react-redux';
import { fetchLoggedInAgent } from '../../redux/actions/user';
import { useQueryClient } from 'react-query';

const Login = () => {
    const location = useLocation();

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const loggedInUser = useSelector((state) => state?.user?.loggedInUser);

    const fromRoute = location?.state?.from?.pathname ?? '/';

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [passState, setPassState] = useState(false);
    const [errorVal, setError] = useState(false);

    const [loginData, setLoginData] = useState({ email: '', passcode: '' });

    const handleChange = (e) => {
        const { value, name } = e?.target;

        setLoginData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const onFormSubmit = async (formData) => {
        const userData = {
            email: formData?.email,
            password: formData?.passcode
        };

        try {
            setLoading(true);

            const { data } = await axios.post(
                `${serverAPI}/auth/login`,
                userData,
                {
                    withCredentials: true
                }
            );

            dispatch({ type: USER_LOGIN, payload: data });
            if (data && data.role === newRoles.Agent) {
                dispatch(fetchLoggedInAgent(axios, data.id, `${serverAPI}/agent/user/${data.id}`));
            }

            // Invalidate all react-query queries, refetch everything
            await queryClient.invalidateQueries();

            navigate(fromRoute, { replace: true });
        } catch (err) {
            console.log(err);
            setError(
                err?.response?.data?.message ??
                    'Unable to login with credentials'
            );
            setTimeout(() => {
                setError(false);
            }, 5000);
            setLoading(false);
        }
    };

    const { errors, register, handleSubmit } = useForm();

    // if lgged in user redirect to home page
    if (loggedInUser && loggedInUser?.access_token)
        return navigate(fromRoute, { replace: true });

    return (
        <React.Fragment>
            <Head title="Login" />
            <PageContainer>
                <Block className="nk-block-middle nk-auth-body  wide-xs">
                    <div className="brand-logo pb-4 text-center">
                        <div className="logo-link">
                            <img
                                className="logo-light logo-img logo-img-lg"
                                src={Logo}
                                alt="logo"
                            />
                            <img
                                className="logo-dark logo-img logo-img-lg"
                                src={Logo}
                                alt="logo-dark"
                            />
                        </div>
                    </div>

                    <PreviewCard
                        className="card-bordered"
                        bodyClass="card-inner-lg"
                    >
                        <BlockHead>
                            <BlockContent>
                                <BlockTitle tag="h4">Sign-In</BlockTitle>
                                <BlockDes>
                                    <p>
                                        Login to Alba Homes admin panel using
                                        your email and password.
                                    </p>
                                </BlockDes>
                            </BlockContent>
                        </BlockHead>
                        {errorVal && (
                            <div className="mb-3">
                                <Alert color="danger" className="alert-icon">
                                    {' '}
                                    <Icon name="alert-circle" /> {errorVal}
                                </Alert>
                            </div>
                        )}
                        <Form
                            className="is-alter"
                            onSubmit={handleSubmit(onFormSubmit)}
                        >
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label
                                        className="form-label"
                                        htmlFor="default-01"
                                    >
                                        Email or Username
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        id="default-01"
                                        name="email"
                                        ref={register({
                                            required: 'This field is required',
                                            validate: (value) =>
                                                validateEmail(value) ||
                                                'Please enter a valid email'
                                        })}
                                        onChange={handleChange}
                                        defaultValue={loginData?.email}
                                        value={loginData?.email}
                                        placeholder="Enter your email address or username"
                                        className="form-control-lg form-control"
                                    />
                                    {errors.email && (
                                        <span className="invalid">
                                            {errors.email.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label
                                        className="form-label"
                                        htmlFor="password"
                                    >
                                        Passcode
                                    </label>
                                    <Link
                                        className="link link-primary link-sm"
                                        to={'/reset-password'}
                                        state={{
                                            email: loginData?.email
                                        }}
                                    >
                                        Forgot Code?
                                    </Link>
                                </div>
                                <div className="form-control-wrap">
                                    <a
                                        href="#password"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setPassState(!passState);
                                        }}
                                        className={`form-icon lg form-icon-right passcode-switch ${
                                            passState ? 'is-hidden' : 'is-shown'
                                        }`}
                                    >
                                        <Icon
                                            name="eye"
                                            className="passcode-icon icon-show"
                                        ></Icon>

                                        <Icon
                                            name="eye-off"
                                            className="passcode-icon icon-hide"
                                        ></Icon>
                                    </a>
                                    <input
                                        type={passState ? 'text' : 'password'}
                                        id="password"
                                        name="passcode"
                                        defaultValue={loginData?.passcode}
                                        ref={register({
                                            required: 'This field is required',
                                            validate: (value) =>
                                                validatePasswordNew(value) ||
                                                'Invalid password'
                                        })}
                                        onChange={handleChange}
                                        placeholder="Enter your passcode"
                                        className={`form-control-lg form-control ${
                                            passState ? 'is-hidden' : 'is-shown'
                                        }`}
                                    />
                                    {errors.passcode && (
                                        <span className="invalid">
                                            {errors.passcode.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form-group">
                                <Button
                                    size="lg"
                                    className="btn-block"
                                    type="submit"
                                    color="primary"
                                    disabled={
                                        errors?.passcode?.message ||
                                        errors?.email?.message ||
                                        loading
                                    }
                                >
                                    {loading ? (
                                        <Spinner size="sm" color="light" />
                                    ) : (
                                        'Sign in'
                                    )}
                                </Button>
                            </div>
                        </Form>
                        {/* <div className="form-note-s2 text-center pt-4">
                            { " " }
                            New on our platform?{ " " }
                            <Link
                                to={ `/auth-register` }
                            >
                                Create an account
                            </Link>
                        </div> */}
                    </PreviewCard>
                </Block>
                <AuthFooter />
            </PageContainer>
        </React.Fragment>
    );
};
export default Login;
