import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import _, { debounce } from 'lodash';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import {
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalBody,
    ModalFooter,
    Collapse,
    Badge,
} from 'reactstrap';
import {
    Block,
    BlockHead,
    BlockBetween,
    BlockHeadContent,
    BlockTitle,
    BlockDes,
    Icon,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    PaginationComponent
} from '../../../components/Component';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { fetchAllPropertyCards } from '../../../redux/actions/property-cards';
import {
    PROPERTY_LIST_FIELDS,
    LocalStorageKeys,
    BEDS_OPTIONS,
    DEFAULT_SIZE_RANGE
} from '../constats';
import { getLocalStorageObj } from '../utils';
import { HttpStatus, newRoles } from '../../../utils/envConfig';
import LoadingComponent from '../../components/loader/tableLoader';
import RandomCheckoutModal from './modals/RandomCheckoutModal';
import AgentCard from './AgentCard';
import { showToast } from '../../../utils/toast/toast';
import RangeFilter from '../../../components/input/range-input/RangeFilter';
import useScreenSize from '../../../hooks/useScreenSize';
import ExplorerCommunityFilter from '../../components/filter-components/explorer-specific/ExplorerCommunityFilter';
import ExplorerPropTypesFilter from '../../components/filter-components/explorer-specific/ExplorerPropTypesFilter';
import BuildingFilter from '../../components/filter-components/BuildingFilter';
import { loggedInAgentSelector, loggedInUserSelector } from '../../../redux/selectors/user';
import { propertyCardsSelector } from '../../../redux/selectors/property-cards';
import BlinkingBadge from '../../../components/miscellaneous/BlinkingBadge';

const PropertyTableItem = ({
    propertyCard,
    index,
    setSelectedItems,
    selectorFn,
    toggleCheckoutModal,
    toggleRefresh
}) => {
    const axios = useAxiosPrivate();
    const authUser = useSelector(loggedInUserSelector);
    const authAgent = useSelector(loggedInAgentSelector);

    const isAdmin =
        authUser.role === newRoles.Admin ||
        authUser.role === newRoles.MasterAdmin;

    const isCheckedOutByAuthAgent =
        authAgent &&
        (authAgent.id ?? authAgent._id)?.toString() ===
            (
                propertyCard?.currentAgent?.id ??
                propertyCard?.currentAgent?._id
            )?.toString();

    // Loading state
    const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);

    const checkoutPropertyCard = async (payload) => {
        try {
            setIsCheckoutLoading(true);
            const res = await axios.post(
                '/property-cards/checkout-leads',
                payload
            );
            if (res?.status === HttpStatus.OK) {
                setSelectedItems([
                    {
                        id: propertyCard?.id,
                        leadsCount: propertyCard?.leadsCount ?? 0
                    }
                ]);
                toggleCheckoutModal();
                toggleRefresh();
            }
        } catch (error) {
            console.log(error);
            if (error.response.status === HttpStatus.FORBIDDEN) {
                showToast('Only Agents can checkout a property card.', 'error');
            }
            if (error.response.status === HttpStatus.TOO_MANY_REQUESTS) {
                showToast(
                    'Too many requests from this IP, please try again in a bit.',
                    'error'
                );
            }
            const serverErrorMessage = error.response?.data?.message;
            if (serverErrorMessage) {
                showToast(serverErrorMessage, 'error');
            } else {
                showToast('An error occurred.', 'error');
            }
        } finally {
            setIsCheckoutLoading(false);
            setSelectedItems([]);
        }
    };

    return (
        <DataTableItem key={index}>
            <DataTableRow className="nk-tb-col-check">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        disabled={!propertyCard?.availableForCheckout}
                        defaultChecked={propertyCard?.checked}
                        id={index + 'oId-all'}
                        key={Math.random()}
                        onChange={(e) => selectorFn(e, propertyCard)}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor={index + 'oId-all'}
                    ></label>
                </div>
            </DataTableRow>
            <DataTableRow className="text-center">
                {isAdmin ||
                isCheckedOutByAuthAgent ||
                propertyCard?.availableForCheckout ? (
                  <Link to={`/property-detail/${propertyCard?.id}`}>
                      {propertyCard?.referenceNumber}
                  </Link>
                ) : (
                    <span>{propertyCard?.referenceNumber}</span>
                )}
                {propertyCard?.isRecent ? <BlinkingBadge text={'Recent'} className="ms-2" /> : null}
            </DataTableRow>
            <DataTableRow className="text-center">
                <span>{propertyCard?.propertyType}</span>
            </DataTableRow>
            <DataTableRow className="text-center">
                <span>{propertyCard?.community?.community}</span>
            </DataTableRow>
            <DataTableRow className="text-center">
                <span>{propertyCard?.cluster ?? '-'}</span>
            </DataTableRow>
            <DataTableRow className="text-center">
                <span>
                    {propertyCard?.building
                        ? propertyCard?.building?.name
                        : 'Not Available'}
                </span>
            </DataTableRow>
            <DataTableRow className="text-center">
                <span>{propertyCard?.size}</span>
            </DataTableRow>
            <DataTableRow className="text-center">
                <span>
                    {propertyCard?.beds === 0 ? '-' : propertyCard?.beds}
                </span>
            </DataTableRow>
            <DataTableRow className="text-center">
                <span>
                    {propertyCard?.baths === 0 ? '-' : propertyCard?.baths}
                </span>
            </DataTableRow>
            <DataTableRow className="text-center">
                <span>{propertyCard?.leadsCount ?? '-'}</span>
            </DataTableRow>
            <DataTableRow className="text-center">
                {Boolean(propertyCard?.availableForCheckout) ? (
                    <div className="tb-odr-btns d-flex justify-content-center align-items-center">
                        <Button
                            color={
                                !propertyCard?.availableForCheckout
                                    ? 'light'
                                    : 'primary'
                            }
                            size="sm"
                            style={{ width: '6.5rem' }}
                            className="btn btn-dim me-1"
                            disabled={
                                isAdmin ||
                                !propertyCard?.availableForCheckout ||
                                propertyCard?.leadsCount === 0 ||
                                isCheckoutLoading
                            }
                            onClick={() =>
                                checkoutPropertyCard({
                                    cards: [propertyCard?.id]
                                })
                            }
                        >
                            {isCheckoutLoading ? 'Loading...' : 'Assign to Me'}
                        </Button>
                    </div>
                ) : (
                    <span>
                        <AgentCard agent={propertyCard?.currentAgent} />
                    </span>
                )}
            </DataTableRow>
            <DataTableRow className="text-center"></DataTableRow>
        </DataTableItem>
    );
};

const ListPropertyCardsUI = ({ url = '/list-property-cards' }) => {
    const [tablesm, setTableSm] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const toggleRefresh = useCallback(() => setRefresh(!refresh), [refresh]);
    const [selectedItems, setSelectedItems] = useState([]); // this is for use with checkboxes
    const [propertyCardsWithChecks, setPropertyCardsWithChecks] = useState([]); // this will contain all property cards with a "checked" property
    const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
    const [isRandomCheckoutModalOpen, setIsRandomCheckoutModalOpen] =
        useState(false);
    const [onlyAvailable, setOnlyAvailable] = useState(true);
    const [onlyUnavailable, setOnlyUnavailable] = useState(false);
    const [showRecent, setShowRecent] = useState(false);
    const [checkedOutLeadsCount, setCheckedOutLeadsCount] = useState(0);
    const [checkedOutPropertyCardsCount, setCheckedOutPropertyCardsCount] =
        useState(0);
    const [sizeRange, setSizeRange] = useState([
        DEFAULT_SIZE_RANGE.min,
        DEFAULT_SIZE_RANGE.max
    ]);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const axios = useAxiosPrivate();
    const query = queryString.parse(location.search);
    const screenSize = useScreenSize();

    // REDUX STATE
    const authUser = useSelector(loggedInUserSelector);

    const isAdmin =
        authUser.role === newRoles.Admin ||
        authUser.role === newRoles.MasterAdmin;
    const [agentInfo, setAgentInfo] = useState(null);
    const SORTOPTIONS = [
        { value: 'createdAt', sort: 1, label: 'Date Created' },
        { value: 'updatedAt', sort: 1, label: 'Date Updated' }
    ];
    const [sortOption, setSortOption] = useState(SORTOPTIONS[0]);
    const handleSort = (option) => {
        if (sortOption.value === option.value) {
            return setSortOption((c) => ({
                ...c,
                sort: c.sort * -1
            }));
        }
        setSortOption((c) => ({
            value: option.value,
            sort: Number(option.sort) * -1
        }));
    };
    const { propertyCards, filteredCount, loading, error } = useSelector(propertyCardsSelector);

    // const filteredCount = useSelector(
    //     (state) => state.propertyCard?.propertyCards?.filteredCount
    // );

    const [isSearchClosed, setIsSearchClosed] = useState(
        query?.search ? false : true
    );
    const [searchText, setSearchText] = useState(query?.search ?? '');
    const [wantedNumOfLeads, setWantedNumOfLeads] = useState(null);

    const [page, setPage] = useState(
        localStorage.getItem(LocalStorageKeys.CurrentPage)
            ? Number(localStorage.getItem(LocalStorageKeys.CurrentPage))
            : query?.page
            ? Number(query?.page)
            : 1
    );
    // eslint-disable-next-line no-unused-vars
    const [perPage, setPerPage] = useState(
        query?.per_page ? Number(query?.per_page) : 50
    );

    const [selectedCommunities, setSelectedCommunities] = useState(
        getLocalStorageObj(LocalStorageKeys.SelectedCommunities) ?? []
    );

    const [selectedBuildings, setSelectedBuildings] = useState(
        getLocalStorageObj(LocalStorageKeys.SelectedBuildings) ?? []
    );

    const [selectedBedsCount, setSelectedBedsCount] = useState(
        localStorage.getItem(LocalStorageKeys.SelectedNumberOfBeds) ?? ''
    );

    const [selectedBathsCount, setSelectedBathsCount] = useState(
        localStorage.getItem(LocalStorageKeys.SelectedNumberOfBaths) ?? ''
    );

    const [selectedPropertyTypes, setSelectedPropertyTypes] = useState(
        getLocalStorageObj(LocalStorageKeys.SelectedPropertyType) ?? []
    );
    const queryPropertyCards = useCallback(
        (params, updateRoute = true) => {
            const sort = {};
            if (sortOption.value && sortOption.sort) {
                sort[`sort[${sortOption.value}]`] = sortOption.sort;
            }
            const propertyCardParams = {
                limit: params?.perPage ?? query?.limit,
                page: params?.page,
                search: params?.search ?? query?.search,
                fromDate: params?.fromDate ?? query?.from_date,
                toDate: params?.toDate ?? query?.to_date,
                communities: params?.communities ?? null,
                buildings: params?.buildings ?? null,
                beds: params?.beds ?? null,
                minBaths: params?.minBaths ?? null,
                maxBaths: params?.maxBaths ?? null,
                minSize: params?.minSize ?? null,
                maxSize: params?.maxSize ?? null,
                propertyTypes: params?.propertyTypes ?? null,
                partyType: params?.partyType ?? null,
                withLeadsCount: true,
                availableForCheckout: params?.availableForCheckout ?? null,
                recent: params?.recent ?? null,
                ...sort
            };
            
            const queryParams = new URLSearchParams();

            Object.keys(propertyCardParams).forEach((key) => {
                const value = propertyCardParams[key];
                if (Array.isArray(value) && value.length > 0) {
                    value.forEach((subValue) =>
                        queryParams.append(key, subValue)
                    );
                    // eslint-disable-next-line eqeqeq
                } else if (
                    value != undefined &&
                    (value !== 'null' || value !== '')
                ) {
                    queryParams.append(key, value);
                }
            });

            const queryString = queryParams.toString();
            if (updateRoute) {
                const navigateParams = {};

                if (location.search.length) {
                    navigateParams.replace = true;
                }

                navigate(
                    `${url ?? '/list-property-cards'}?${queryString}`,
                    navigateParams
                );
            }
            return queryString;
        },
        [
            location.search.length,
            navigate,
            query?.from_date,
            sortOption.sort,
            sortOption.value,
            query?.limit,
            query?.search,
            query?.to_date,
            url
        ]
    );

    const buildQueryString = useCallback(() => {
        const queryString = queryPropertyCards(
            {
                perPage,
                page,
                beds: selectedBedsCount?.length ? selectedBedsCount : null,
                minBaths: selectedBathsCount?.length
                    ? selectedBathsCount
                    : null,
                maxBaths: selectedBathsCount?.length
                    ? selectedBathsCount
                    : null,
                minSize: sizeRange[0] ?? DEFAULT_SIZE_RANGE.min,
                maxSize: sizeRange[1] ?? DEFAULT_SIZE_RANGE.max,
                communities:
                    selectedCommunities?.map((item) => item?.value) ?? [],
                buildings:
                    selectedBuildings?.map(
                        (buildingOption) => buildingOption?.value
                    ) ?? [],
                propertyTypes:
                    selectedPropertyTypes.map(
                        (typeOption) => typeOption?.value
                    ) ?? [],
                availableForCheckout:
                    onlyAvailable === true
                        ? 'true'
                        : onlyUnavailable === true
                        ? 'false'
                        : null,
                recent: showRecent ? 'true' : null,
            },
            false
        );
        return queryString;
    }, [
        onlyAvailable,
        onlyUnavailable,
        page,
        perPage,
        queryPropertyCards,
        selectedBathsCount,
        selectedBedsCount,
        selectedBuildings,
        selectedCommunities,
        selectedPropertyTypes,
        sizeRange,
        showRecent
    ]);

    const isAnyFilterSet =
        searchText.length ||
        selectedCommunities.length ||
        selectedBuildings.length ||
        selectedBathsCount ||
        selectedBedsCount ||
        selectedPropertyTypes.length ||
        sizeRange[0] > DEFAULT_SIZE_RANGE.min ||
        sizeRange[1] !== DEFAULT_SIZE_RANGE.max;

    useEffect(() => {
        if (!isAnyFilterSet && isAdmin) {
            return;
        }
        const queryString = buildQueryString();

        dispatch(fetchAllPropertyCards(axios, `?${queryString}`));
    }, [
        refresh,
        location.search,
        page,
        perPage,
        buildQueryString,
        dispatch,
        axios,
        isAnyFilterSet,
        isAdmin
    ]);

    useEffect(() => {
        const fetchAgentData = async () => {
            // fetch the agent information of the current logged-in user
            const res = await axios.get(`/agent/user/${authUser?.id}`);
            res?.status === HttpStatus.OK && setAgentInfo(res?.data);
        };
        if (authUser && authUser.role === newRoles.Agent) {
            fetchAgentData();
        }
    }, [
        authUser,
        axios,
        refresh,
        dispatch
    ]);

    useEffect(() => {
        // this will run when the redux dispatch fetches the list of property cards
        // we save it as state to add a "checked" property later
        // this is used for the checkbox logic
        setPropertyCardsWithChecks(propertyCards);
    }, [propertyCards]);

    const handleSearchSubmit = (event) => {
        setSearchText(event.target.value);
        debounceSearchPropertyCards({ search: event.target.value });
    };

    // debounce update query
    const updateSearchQuery = useCallback(queryPropertyCards, [
        queryPropertyCards
    ]);

    const debounceSearchPropertyCards = useMemo(() => {
        return debounce(updateSearchQuery, 1000);
    }, [updateSearchQuery]);
    // end debouncing

    // Modal toggles
    const toggleCheckoutModal = useCallback(() => {
        setIsCheckoutModalOpen((prevIsCheckoutModalOpen) => {
            if (prevIsCheckoutModalOpen && selectedItems.length) {
                setSelectedItems([]);
            }
            return !prevIsCheckoutModalOpen;
        });
    }, [selectedItems.length]);

    const toggleRandomCheckoutModal = () =>
        setIsRandomCheckoutModalOpen(!isRandomCheckoutModalOpen);

    // Checkbox logic
    const selectAllPropertyCards = (event) => {
        // Global checkbox
        const isChecked = event.target.checked;
        const checkedItems = propertyCardsWithChecks
            .filter((item) => item.availableForCheckout)
            .map((item) => {
                item.checked = isChecked;
                return item;
            });

        const selectedItems = checkedItems
            .filter((item) => item.checked)
            .map((item) => ({
                id: item.id,
                leadsCount: item.leadsCount ?? 0,
                ...item
            }));

        setSelectedItems(selectedItems);
    };

    const selectItem = (event, item) => {
        let propertyCardsCopy = propertyCards;
        let selectedIndex = propertyCardsCopy.findIndex(
            (x) => x?.id === item?.id
        );
        const isChecked = event.target.checked;
        propertyCardsCopy[selectedIndex].checked = isChecked;
        setPropertyCardsWithChecks([...propertyCardsCopy]);

        setSelectedItems((currentSelectedItems) => {
            if (isChecked) {
                // Add itemId to selectedItems if it's checked and not already present
                return [
                    ...currentSelectedItems,
                    { id: item?.id, leadsCount: item.leadsCount ?? 0, ...item }
                ];
            } else {
                // Remove itemId from selectedItems if it's unchecked
                return currentSelectedItems.filter((x) => x?.id !== item?.id);
            }
        });
    };

    // --------------- FILTER HANDLERS ----------------
    const clearAllFilters = () => {
        // Reset filter states
        setSelectedCommunities([]);
        setSelectedBuildings([]);
        setSelectedPropertyTypes([]);
        setSelectedBedsCount('');
        setSelectedBathsCount('');
        setSearchText('');
        setSizeRange((_) => [DEFAULT_SIZE_RANGE.min, DEFAULT_SIZE_RANGE.max]);
        setIsSearchClosed(true);
        setOnlyAvailable(true);
        queryPropertyCards({ search: '' });

        // de-select any selected items
        setSelectedItems([]);

        // Clear localStorage or any other side effects if you're using them
        localStorage.removeItem(LocalStorageKeys.SelectedCommunities);
        localStorage.removeItem(LocalStorageKeys.SelectedBuildings);
        localStorage.removeItem(LocalStorageKeys.SelectedNumberOfBeds);
        localStorage.removeItem(LocalStorageKeys.SelectedNumberOfBaths);
        localStorage.removeItem(LocalStorageKeys.SelectedPropertyType);
        localStorage.setItem(LocalStorageKeys.CurrentPage, 1);

        // re-fetch
        setRefresh(!refresh);
    };

    const resetPage = () => {
        setPage(1);
        localStorage.setItem(LocalStorageKeys.CurrentPage, 1);
        setRefresh(!refresh);
    };

    const handleBuildingFilterChange = (selectedOptions) => {
        if (selectedOptions.some((option) => option.value === 'all')) {
            setSelectedBuildings([]);
            localStorage.removeItem(LocalStorageKeys.SelectedBuildings);
        } else {
            const communitiesToAdd = selectedOptions?.map(option => ({
                label: option?.community?.community,
                value: option?.community?.id ?? option?.community?._id,
                ...option?.community
            }));
            let newCommunities = selectedCommunities?.concat(communitiesToAdd);
            newCommunities = _.uniqBy(newCommunities, 'value');
            
            setSelectedBuildings(_ => selectedOptions);
            setSelectedCommunities(_ => newCommunities);
            
            localStorage.setItem(
                LocalStorageKeys.SelectedBuildings,
                JSON.stringify(selectedOptions)
            );

            localStorage.setItem(
                LocalStorageKeys.SelectedCommunities,
                JSON.stringify(newCommunities)
            );
        }
        resetPage();
    };

    const handleCommunitiesFilterChange = (selectedOptions) => {
        // clear buildings filter when changing the communities filter
        setSelectedBuildings([]);

        if (selectedOptions.some((option) => option.value === 'all')) {
            setSelectedCommunities([]);
            localStorage.removeItem(LocalStorageKeys.SelectedCommunities);
        } else {
            const selectedCommunityIDs = selectedOptions?.map(option => option.value) ?? [];
            const buildingsLeft = selectedBuildings.filter((building) => selectedCommunityIDs.includes(building?.communityId));
            
            setSelectedCommunities(_ => selectedOptions);
            setSelectedBuildings(_ => buildingsLeft);

            localStorage.setItem(
                LocalStorageKeys.SelectedCommunities,
                JSON.stringify(selectedOptions)
            );

            localStorage.setItem(
                LocalStorageKeys.SelectedBuildings,
                JSON.stringify(buildingsLeft)
            );
        }
        resetPage();
    };

    const handlePropertyTypesFilterChange = (selectedOptions) => {
        if (selectedOptions.some((option) => option.value === 'all')) {
            setSelectedPropertyTypes([]);
            localStorage.removeItem(LocalStorageKeys.SelectedPropertyType);
        } else {
            setSelectedPropertyTypes(selectedOptions);
            localStorage.setItem(
                LocalStorageKeys.SelectedPropertyType,
                JSON.stringify(selectedOptions)
            );
        }
        resetPage();
    };

    const handleSizeFilterChange = (min, max) => {
        setSizeRange((_) => {
            return [
                isNaN(parseInt(min)) ? DEFAULT_SIZE_RANGE.min : parseInt(min),
                isNaN(parseInt(max)) ? DEFAULT_SIZE_RANGE.max : parseInt(max)
            ];
        });
        resetPage();
    };

    // --------------- END OF FILTER HANDLERS ----------------

    // --------------- REQUEST HANDLERS ---------------------
    const [isBulkLoading, setIsBulkLoading] = useState(false);
    const checkoutPropertyCards = async () => {
        if (!selectedItems.length) {
            showToast('Select some cards first!', 'error');
            return;
        }
        try {
            setIsBulkLoading(true);
            const payload = {
                cards: selectedItems.map((item) => item?.id?.toString())
            };
            const res = await axios.post(
                '/property-cards/checkout-leads',
                payload
            );

            if (res?.status === HttpStatus.OK) {
                setRefresh(!refresh);
                setCheckedOutLeadsCount(res?.data?.leadsCount ?? 0);
                setCheckedOutPropertyCardsCount(
                    res?.data?.propertyCardsCount ?? 0
                );
                toggleCheckoutModal();
            }
        } catch (error) {
            console.log(error);
            const serverErrorMessage = error.response?.data?.message;
            if (serverErrorMessage) {
                showToast(serverErrorMessage, 'error');
            } else {
                showToast('An error occurred.', 'error');
            }
        } finally {
            setPropertyCardsWithChecks(
                propertyCardsWithChecks.map((item) => ({
                    ...item,
                    checked: false
                }))
            );
            setSelectedItems([]);
            setIsBulkLoading(false);
        }
    };

    const [isRandomLoading, setIsRandomLoading] = useState(false);
    const randomlyCheckoutLeads = async () => {
        setIsRandomLoading(true);
        try {
            if (
                !wantedNumOfLeads ||
                isNaN(wantedNumOfLeads) ||
                wantedNumOfLeads <= 0 ||
                wantedNumOfLeads > 100
            ) {
                showToast('Please enter a valid number of leads.', 'warning');
                return;
            }
            const queryString = buildQueryString();
            const payload = { numberOfLeads: wantedNumOfLeads ?? 0 };
            const res = await axios.post(
                `property-cards/checkout-random-leads?${queryString}`,
                payload
            );
            if (res?.status === HttpStatus.OK) {
                showToast(
                    `Successfully assigned ${res?.data?.total} lead(s), and ${res?.data?.numberOfPropertyCards} property card(s) to you.`,
                    'success'
                );
                setRefresh(!refresh);
            }
        } catch (error) {
            console.log(error);
            const serverErrorMessage = error.response?.data?.message;
            if (serverErrorMessage) {
                showToast(serverErrorMessage, 'error');
            } else {
                showToast('An error occurred.', 'error');
            }
        } finally {
            toggleRandomCheckoutModal();
            setIsRandomLoading(false);
        }
    };
    // --------------- END OF REQUEST HANDLERS ---------------------

    return (
        <React.Fragment>
            <Head title="Properties"></Head>
            <Content>
                {/* ================ PAGE HEADER =================== */}
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Landlord Explorer
                            </BlockTitle>
                            {!loading ? (
                                <BlockDes className="text-soft">
                                    <p>
                                        Found {filteredCount ?? 0} Property
                                        Card(s).
                                    </p>
                                </BlockDes>
                            ) : null}
                            <BlockDes className="fs-6">
                                {agentInfo ? (
                                    <p>
                                        You have{' '}
                                        <strong className="text-primary">
                                            {agentInfo?.creditsBalance <= 0
                                                ? 0
                                                : agentInfo?.creditsBalance ??
                                                  '-'}
                                        </strong>{' '}
                                        credits left (Out of{' '}
                                        <strong className="text-primary">
                                            {agentInfo?.creditsLimit ?? '-'}
                                        </strong>
                                        )
                                    </p>
                                ) : null}
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                    <div
                        className="mt-2 mb-2 d-flex justify-content-end"
                        style={{ gap: '0.2rem' }}
                    >
                        <UncontrolledDropdown>
                            <DropdownToggle
                                style={{ color: 'inherit' }}
                                className="bg-white p-0 px-2 border-0 "
                            >
                                <Icon className={'fs-5'} name={'sort-v'} />
                                <span>Sort By</span>
                                <DropdownMenu className="p-2">
                                    {SORTOPTIONS.map((option) => (
                                        <DropdownItem
                                            onClick={(e) => handleSort(option)}
                                            className="my-2"
                                        >
                                            {sortOption.value ===
                                            option.value ? (
                                                <div>
                                                    {sortOption.sort < 1 ? (
                                                        <Icon
                                                            name={'sort-up'}
                                                        />
                                                    ) : (
                                                        <Icon
                                                            name={'sort-down'}
                                                        />
                                                    )}
                                                    <span>{option.label}</span>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Icon name={'sort-up'} />
                                                    <span>{option.label}</span>
                                                </div>
                                            )}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </DropdownToggle>
                        </UncontrolledDropdown>
                        <Button
                            color={showRecent ? 'secondary' : 'white'}
                            className="ms-2"
                            onClick={() => {
                                setOnlyAvailable(false);
                                setShowRecent(!showRecent)
                                setPage(1);
                                localStorage.setItem(LocalStorageKeys.CurrentPage, 1);
                                toggleRefresh();
                            }}
                        >
                            <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ gap: '0.5rem' }}
                            >
                                <small>
                                    <Icon name={showRecent ? 'check-c' : 'square-c'} />
                                </small>
                                Recent
                            </div>
                        </Button>
                        <Button
                            color={onlyUnavailable ? 'secondary' : 'white'}
                            className="mx-2"
                            onClick={() => {
                                setOnlyAvailable(false);
                                setOnlyUnavailable(!onlyUnavailable);
                                setPage(1);
                                localStorage.setItem(
                                    LocalStorageKeys.CurrentPage,
                                    1
                                );
                                toggleRefresh();
                            }}
                        >
                            <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ gap: '0.5rem' }}
                            >
                                <small>
                                    <Icon
                                        name={
                                            onlyUnavailable
                                                ? 'check-c'
                                                : 'square-c'
                                        }
                                    />
                                </small>
                                Show Unavailable Only
                            </div>
                        </Button>
                        <Button
                            color={onlyAvailable ? 'secondary' : 'white'}
                            onClick={() => {
                                setOnlyAvailable(!onlyAvailable);
                                setOnlyUnavailable(false);
                                setPage(1);
                                localStorage.setItem(
                                    LocalStorageKeys.CurrentPage,
                                    1
                                );
                                toggleRefresh();
                            }}
                        >
                            <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ gap: '0.5rem' }}
                            >
                                <small>
                                    <Icon
                                        name={
                                            onlyAvailable
                                                ? 'check-c'
                                                : 'square-c'
                                        }
                                    />
                                </small>
                                Show Available Only
                            </div>
                        </Button>
                    </div>
                    <div className="d-flex justify-content-end">
                        {/* */}
                        {/* flex-column-reverse gy-1 gy-md-0 flex-md-row-reverse flex-wrap align-items-md-center align-items-end */}
                        <ul className="nk-block-tools d-flex g-3 flex-row flex-wrap justify-content-end">
                            <li className="">
                                <ExplorerPropTypesFilter
                                  axiosInstance={axios}
                                  dispatch={dispatch}
                                  multiSelect
                                  onChangeCallback={handlePropertyTypesFilterChange}
                                  selected={selectedPropertyTypes}
                                />
                            </li>
                            <li className="order-first">
                                <ExplorerCommunityFilter
                                  axiosInstance={axios}
                                  dispatch={dispatch}
                                  multiSelect
                                  onChangeCallback={handleCommunitiesFilterChange}
                                  selected={selectedCommunities}
                                />
                            </li>
                            <li className="">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        color="transparent"
                                        className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                                    >
                                        {selectedBedsCount &&
                                        selectedBedsCount !== ''
                                            ? selectedBedsCount === '7'
                                                ? selectedBedsCount + '+'
                                                : selectedBedsCount
                                            : 'Beds'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <ul className="link-list-opt no-bdr">
                                            {BEDS_OPTIONS.map((option, idx) => (
                                                <li key={`bed-option-${idx}`}>
                                                    <DropdownItem
                                                        onClick={() => {
                                                            setRefresh(
                                                                !refresh
                                                            );
                                                            if (
                                                                option === 'All'
                                                            ) {
                                                                setSelectedBedsCount(
                                                                    ''
                                                                );
                                                            } else {
                                                                setSelectedBedsCount(
                                                                    option
                                                                );
                                                            }
                                                            setPage(1);
                                                            localStorage.setItem(
                                                                LocalStorageKeys.CurrentPage,
                                                                1
                                                            );
                                                        }}
                                                    >
                                                        <span>{option}</span>
                                                    </DropdownItem>
                                                </li>
                                            ))}
                                        </ul>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                            <li>
                                <RangeFilter
                                    min={sizeRange[0]}
                                    max={sizeRange[1]}
                                    onSubmit={({ min, max }) =>
                                        handleSizeFilterChange(min, max)
                                    }
                                    placeholder={'Size'}
                                />
                            </li>
                        </ul>
                    </div>
                    <div
                        className={`d-flex justify-content-end ${
                            screenSize.width < 1430 ? 'mt-3' : 'mt-2'
                        }`}
                        style={{ gap: '0.5rem' }}
                    >
                        <BuildingFilter
                          axiosInstance={axios}
                          dispatch={dispatch}
                          onChangeCallback={handleBuildingFilterChange}
                          selected={selectedBuildings}
                          labelFormatString={"$COMMUNITY - $BUILDING"}
                          selectedCommunities={selectedCommunities}
                          multiSelect
                          wide
                        />
                    </div>
                    <div
                        className="mt-2 d-flex justify-content-end"
                        style={{ gap: '0.2rem' }}
                    >
                        {selectedBedsCount ? (
                            <Badge color="secondary" className="cursor-pointer">
                                <span className="d-flex align-items-center">
                                    Beds: {selectedBedsCount}{' '}
                                    <Icon
                                        name={'cross'}
                                        onClick={() => {
                                            setSelectedBedsCount('');
                                            localStorage.removeItem(
                                                LocalStorageKeys.SelectedNumberOfBeds
                                            );
                                            setPage(1);
                                            localStorage.setItem(
                                                LocalStorageKeys.CurrentPage,
                                                1
                                            );
                                            setRefresh(!refresh);
                                        }}
                                    />
                                </span>
                            </Badge>
                        ) : null}
                        {selectedBathsCount.length ? (
                            <Badge color="warning" className="cursor-pointer">
                                <span className="d-flex align-items-center">
                                    Baths: {selectedBathsCount}{' '}
                                    <Icon
                                        name={'cross'}
                                        onClick={() => {
                                            setSelectedBathsCount('');
                                            localStorage.removeItem(
                                                LocalStorageKeys.SelectedNumberOfBaths
                                            );
                                            setPage(1);
                                            localStorage.setItem(
                                                LocalStorageKeys.CurrentPage,
                                                1
                                            );
                                            setRefresh(!refresh);
                                        }}
                                    />
                                </span>
                            </Badge>
                        ) : null}
                        {selectedBuildings.length
                            ? selectedBuildings.map((item, idx) => (
                                  <Badge
                                      key={`building-badge-${idx}`}
                                      color="secondary"
                                      className="cursor-pointer"
                                  >
                                      <span className="d-flex align-items-center">
                                          Building: {item.label}{' '}
                                          <Icon
                                              name={'cross'}
                                              onClick={() =>
                                                  handleBuildingFilterChange(
                                                      selectedBuildings.filter(
                                                          (x) =>
                                                              x.value !==
                                                              item.value
                                                      )
                                                  )
                                              }
                                          />
                                      </span>
                                  </Badge>
                              ))
                            : null}
                        {selectedCommunities.length
                            ? selectedCommunities.map((item, idx) => (
                                  <Badge
                                      key={`building-badge-${idx}`}
                                      color="primary"
                                      className="cursor-pointer"
                                  >
                                      <span className="d-flex align-items-center">
                                          {item.label}{' '}
                                          <Icon
                                              name={'cross'}
                                              onClick={() =>
                                                  handleCommunitiesFilterChange(
                                                      selectedCommunities.filter(
                                                          (x) =>
                                                              x.value !==
                                                              item.value
                                                      )
                                                  )
                                              }
                                          />
                                      </span>
                                  </Badge>
                              ))
                            : null}
                        {selectedPropertyTypes.length
                            ? selectedPropertyTypes.map((item, idx) => (
                                  <Badge
                                      key={`building-badge-${idx}`}
                                      color="info"
                                      className="cursor-pointer"
                                  >
                                      <span className="d-flex align-items-center">
                                          {item.label}{' '}
                                          <Icon
                                              name={'cross'}
                                              onClick={() =>
                                                  handlePropertyTypesFilterChange(
                                                      selectedPropertyTypes.filter(
                                                          (x) =>
                                                              x.value !==
                                                              item.value
                                                      )
                                                  )
                                              }
                                          />
                                      </span>
                                  </Badge>
                              ))
                            : null}
                        {isAnyFilterSet ? (
                            <Badge
                                color="danger"
                                className="cursor-pointer"
                                onClick={clearAllFilters}
                            >
                                Clear All
                            </Badge>
                        ) : null}
                    </div>
                    <Collapse isOpen={selectedItems.length !== 0}>
                        <div className="pt-3 d-flex justify-content-start align-items-center">
                            <Button
                                color="primary"
                                disabled={isBulkLoading || isAdmin}
                                onClick={() => {
                                    checkoutPropertyCards(selectedItems);
                                }}
                            >
                                {isBulkLoading
                                    ? 'Loading...'
                                    : `Assign All to Me (${selectedItems?.length})`}
                            </Button>
                        </div>
                    </Collapse>
                </BlockHead>
                {/* ================ END OF PAGE HEADER =================== */}

                {/* ================ MAIN BLOCK =================== */}
                <Block>
                    <DataTable className="card-stretch">
                        {/* ============= SEARCH CARD ================ */}
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setIsSearchClosed(
                                                        !isSearchClosed
                                                    );
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${
                                                        tablesm ? 'active' : ''
                                                    }`}
                                                    onClick={() =>
                                                        setTableSm(true)
                                                    }
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div
                                                    className={`toggle-content ${
                                                        tablesm
                                                            ? 'content-active'
                                                            : ''
                                                    }`}
                                                >
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() =>
                                                                    setTableSm(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                <Icon className="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className={`card-search search-wrap ${
                                    !isSearchClosed && 'active'
                                }`}
                            >
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active bg-transparent text-secondary"
                                            onClick={() => {
                                                setSearchText('');
                                                setIsSearchClosed(
                                                    !isSearchClosed
                                                );
                                                queryPropertyCards({
                                                    search: ''
                                                });
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control ps-5  bg-transparent text-secondary"
                                            placeholder="Search property by Reference Number or Cluster Name"
                                            value={searchText}
                                            onChange={handleSearchSubmit}
                                        />
                                        <Button className="search-submit btn-icon bg-transparent text-secondary border-0">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ============= END OF SEARCH CARD ================ */}

                        {/* ============= DATA TABLE ================ */}
                        {!isAnyFilterSet && isAdmin ? (
                            <div
                                className="text-center p-4"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <span
                                    className="text-silent"
                                    style={{
                                        margin: '8px',
                                        fontSize: 18,
                                        fontWeight: 700,
                                        color: 'light'
                                    }}
                                >
                                    Please select community!
                                </span>
                                <span
                                    className="text-silent"
                                    style={{
                                        margin: '8px',
                                        width: '500px',
                                        paddingBottom: '8px'
                                    }}
                                >
                                    By enabling the community filter you can
                                    unlock an explorer's treasure! Get ready to
                                    explore and engage ! Happy searching!
                                </span>

                                <ExplorerCommunityFilter
                                  axiosInstance={axios}
                                  dispatch={dispatch}
                                  multiSelect
                                  onChangeCallback={handleCommunitiesFilterChange}
                                  selected={selectedCommunities}
                                />
                            </div>
                        ) : (
                            <DataTableBody>
                                {/* ============= HEADERS (SELECTED FIELDS TO DISPLAY) ================ */}
                                <DataTableHead className="nk-tb-item">
                                    <DataTableRow className="nk-tb-col-check">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="check-all"
                                                onChange={(e) =>
                                                    selectAllPropertyCards(e)
                                                }
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="check-all"
                                            ></label>
                                        </div>
                                    </DataTableRow>

                                    {PROPERTY_LIST_FIELDS.map((item, idx) => (
                                        <DataTableRow
                                            className="text-center"
                                            key={idx}
                                        >
                                            <span className="">{item}</span>
                                        </DataTableRow>
                                    ))}
                                    <DataTableRow className="nk-tb-col-tools">
                                        <ul className="nk-tb-actions gx-1 my-n1"></ul>
                                    </DataTableRow>
                                </DataTableHead>
                                {/* ============= END OF HEADERS (SELECTED FIELDS TO DISPLAY) ================ */}
                                {loading ? (
                                    <LoadingComponent />
                                ) : (
                                    propertyCardsWithChecks &&
                                    propertyCardsWithChecks.length > 0 &&
                                    propertyCardsWithChecks.map(
                                        (property, idx) => (
                                            <PropertyTableItem
                                                key={`item-${idx}`}
                                                propertyCard={property}
                                                selectorFn={selectItem}
                                                setSelectedItems={
                                                    setSelectedItems
                                                }
                                                toggleCheckoutModal={
                                                    toggleCheckoutModal
                                                }
                                                toggleRefresh={toggleRefresh}
                                                index={idx}
                                            />
                                        )
                                    )
                                )}
                            </DataTableBody>
                        )}
                        {loading || (!isAnyFilterSet && isAdmin) ? null : (
                            <div className="card-inner">
                                {propertyCards?.length > 0 ? (
                                    <PaginationComponent
                                        noDown
                                        currentPage={page ?? 1}
                                        paginate={(selectedPage) => {
                                            if (selectedPage === page) {
                                                return;
                                            }
                                            setRefresh(!refresh);
                                            setPage(selectedPage);
                                            localStorage.setItem(
                                                LocalStorageKeys.CurrentPage,
                                                selectedPage
                                            );
                                        }}
                                        itemPerPage={perPage ?? 50}
                                        totalItems={filteredCount}
                                    />
                                ) : error ? (
                                    <div className="text-center">
                                        <span className="text-danger">
                                            Error loading data: {error.message}
                                        </span>
                                    </div>
                                ) : (
                                    <div className="text-center">
                                        <span className="text-silent">
                                            No data found
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                    </DataTable>
                </Block>
                {/* ================ END OF MAIN BLOCK =================== */}
                <Modal
                    isOpen={isCheckoutModalOpen}
                    toggle={toggleCheckoutModal}
                >
                    <button className="close" onClick={toggleCheckoutModal}>
                        <Icon name="cross" />
                    </button>
                    <ModalBody className="text-center p-5">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                        <h5 className="caption-text mt-4">
                            You have successfully assigned Leads to yourself.
                            <br />
                            Check out your assigned Property Cards page for
                            more.
                            <br />
                            <br />
                            <p className="fw-bold">
                                Please hold on for a bit before making another
                                assignment or try using bulk assignment, thank
                                you.
                            </p>
                        </h5>
                    </ModalBody>
                    <ModalFooter className="d-flex justify-content-center align-items-center">
                        <div>
                            <Button
                                color="primary"
                                onClick={() => {
                                    navigate('/list-checked-out', {
                                        state: selectedItems.map(
                                            (item) => item?.id
                                        )
                                    });
                                }}
                            >
                                Show Properties assigned to me now
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </Content>
        </React.Fragment>
    );
};

export default ListPropertyCardsUI;
