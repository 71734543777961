import { useState } from "react";

export const useConfirm = () => {
    const [confirm, setConfirm] = useState({
        prompt: "",
        isOpen: false,
        proceed: null,
        cancel: null,
    });

    const isConfirmed = (prompt) => {
        const promise = new Promise((resolve, reject) => {
            setConfirm({
                prompt,
                isOpen: true,
                proceed: resolve,
                cancel: reject,
            });
        });
        return promise.then(
            (result) => {
                setConfirm({ ...confirm, isOpen: false });
                return result ?? true;
            },
            (result) => {
                setConfirm({ ...confirm, isOpen: false });
                return result ?? false;
            }
        );
    };

    return {
        ...confirm,
        isConfirmed,
    };
};
