import React, { useState, useEffect, useRef } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  Spinner,
} from "reactstrap";

import Icon from "../../../../components/icon/Icon";
import data from "./NotificationData";
import { useSelector } from "react-redux";
import { newRoles } from "../../../../utils/envConfig";
import { formatDateTodays } from "../../../../utils/Utils";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchNotifications } from "../../../../redux/actions/notifications";

const NotificationItem = (props) => {
  const { icon, iconStyle, text, time, id, title, link } = props;
  const navigate = useNavigate();

  return (
    <Link
      to={link ? link : `#${id}`}
      className="nk-notification-item cursor-pointer"
      key={id}
      id={id}
    >
      <div className="nk-notification-icon">
        <Icon
          name={icon}
          className={[`icon-circle ${iconStyle ? " " + iconStyle : ""}`]}
        />
      </div>
      <div className="nk-notification-content">
        <p>{title}</p>
        <div className="nk-notification-text">{text}</div>
        <div className="nk-notification-time">{time}</div>
      </div>
    </Link>
  );
};

const Notification = () => {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const notifications = useSelector((state) => state.notification);
  const authUser = useSelector((state) => state.user.loggedInUser);
  const notifs = notifications?.notifications;
  const loading = notifications?.loading;

  const [fetch, setFetch] = useState(false);
  useEffect(() => {
    dispatch(fetchNotifications(axios, authUser?.id ?? authUser?._id));
  }, [fetch]);

  //notifications

  return (
    <UncontrolledDropdown className="user-dropdown">
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <div
          onClick={(e) => setFetch((c) => !c)}
          className="icon-status icon-status-info"
        >
          <Icon name="bell" />
        </div>
      </DropdownToggle>

      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        {notifs && (
          <>
            <div className="dropdown-head">
              <span className="sub-title nk-dropdown-title">{data.title}</span>
              {
                <a href="#markasread" onClick={(ev) => ev.preventDefault()}>
                  {/* Mark All as Read */ `(${notifs?.length})`}
                </a>
              }
            </div>
            <div className="dropdown-body">
              <div className="nk-notification">
                {notifs &&
                  notifs?.map((item) => {
                    return (
                      <NotificationItem
                        key={item?.id}
                        id={item?.id}
                        icon={"arrow-up-left"}
                        iconStyle={"bg-success-dim"}
                        text={item?.message}
                        time={
                          item?.updatedAt && formatDateTodays(item?.updatedAt)
                        }
                        title={item?.title}
                        link={item?.link}
                      />
                    );
                  })}
              </div>
            </div>
            {notifs?.length > 2 && (
              <div className="dropdown-foot center">
                <a href="#viewall">{notifs?.length > 20 && " View All"}</a>
              </div>
            )}
          </>
        )}
        {loading && (
          <div className="p-4  d-flex align-items-center justify-content-center w-100">
            <Spinner size={"sm"} color="primary" /> Loading Notifications
          </div>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default Notification;
