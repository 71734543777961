import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { getImage } from "../../../../../utils/envConfig";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
} from "../../../../../components/Component";
import SkeletonImage from "./SkeletonImage";

const PocketListingPhotoList = ({ propertyCard }) => {
  const axios = useAxiosPrivate();

  const [isLoading, setIsLoading] = useState(false);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    if (propertyCard && (propertyCard.id ?? propertyCard._id)) {
      fetchPocketListingPhotos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyCard]);

  const fetchPocketListingPhotos = async () => {
    if (!propertyCard?.photos?.length) {
        return;
    }
    try {
      setIsLoading(true);
      const res = propertyCard?.photos?.map(async (photo) => await getImage(photo.original, axios));
      const foundImages = await Promise.all(res);
      setPhotos(foundImages);
    } catch (error) {
      console.log(error);
      toast.error("Couldn't get the Pocket Listing's photos.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <BlockHead>
        <BlockBetween>
          <BlockHeadContent>
            <h5
              className="nk-block-title title"
              id="notes"
              onClick={() => console.log(photos)}
            >
              Photos
            </h5>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {photos.length ?
        <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
          {photos.map((photo, index) => (
            <SkeletonImage
              src={`${photo.signedRequest}`}
              alt={`alt-${index}`}
              key={`image-${index}`}
              width={450}
              height={400}
              className="rounded shadow-sm"
            />
          ))}
        </div>
      : <div>There are no photos for this Property Card.</div>}
    </>
  );
};

export default PocketListingPhotoList;
