import React from 'react';
import { Link } from 'react-router-dom';
import { DealCategory, formatValue, newRoles } from '../utils/envConfig';
import { Badge } from 'reactstrap';

export default function DealCard({ deal }) {
    //   console.log(deal);
    return (
        <div className="border-bottom pb-2 pt-1">
            <Link
                to={`/deal-details/${deal?.id ?? deal?._id}`}
                style={{ color: 'black' }}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        <span className="ff-base fw-medium">Deal ID:</span>{' '}
                        {deal?.referenceNumber ?? deal?.reference_number}
                    </div>
                    <div>
                        <span className="ff-base fw-medium">
                            {deal?.category === DealCategory.SecondaryProperty
                                ? 'Buyer'
                                : 'Client'}
                            :{' '}
                        </span>
                        {deal?.category === DealCategory.SecondaryProperty
                            ? `${
                                  deal?.buyerInternalUser?.firstName ??
                                  deal?.buyer_internal_user?.first_name ??
                                  'N/A'
                              } ${
                                  deal?.buyerInternalUser?.lastName ??
                                  deal?.buyer_internal_user?.last_name ??
                                  'N/A'
                              }`
                            : `${
                                  deal?.user?.firstName ??
                                  deal?.user?.first_name ??
                                  'N/A'
                              } ${
                                  deal?.user?.lastName ??
                                  deal?.user?.last_name ??
                                  'N/A'
                              }`}
                    </div>
                    <div className="ml-auto">
                        <Badge
                            className="badge text-primary"
                            color="primary-dim"
                        >
                            {deal?.status}
                        </Badge>
                    </div>
                </div>
                <div className="d-flex justify-content-between pt-1">
                    <div>
                        <span className="ff-base fw-medium">
                            Category: {''}
                        </span>
                        <span>{deal?.category}</span>
                    </div>
                    {deal?.category === DealCategory.SecondaryProperty && (
                        <div>
                            <span className="ff-base fw-medium">Seller: </span>
                            {`${
                                deal?.sellerInternalUser?.firstName ??
                                deal?.seller_internal_user?.first_name ??
                                'N/A'
                            } ${
                                deal?.sellerInternalUser?.lastName ??
                                deal?.seller_internal_user?.last_name ??
                                'N/A'
                            }`}
                        </div>
                    )}
                    <div className="ml-auto">
                        <span className="ff-base fw-medium">Value: </span>
                        <span>
                            AED{' '}
                            {deal?.agreedSalePrice || deal?.agreed_salePrice
                                ? formatValue(
                                      deal?.agreedSalePrice ??
                                          deal?.agreed_salePrice
                                  )
                                : 'N/A'}
                        </span>
                    </div>
                </div>
            </Link>
        </div>
    );
}
