import { useMemo, useState } from "react";
import FinancesView from "./FinancesView";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useQuery } from "react-query";
import { removeNulls } from "../deal-commissions/utils";

const FIFTEEN_MINUTES = 15 * 60 * 1000;
const THIRTY_MINUTES = 30 * 60 * 1000;

const FinancesViewContainer = () => {
  const axios = useAxiosPrivate();

  const initialQueryParams = useMemo(
      () => ({
      fromDate: null,
      toDate: null,
      transactions: {
        fromPaymentDate: null,
        toPaymentDate: null,
      },
    }),
    []
  );

  const [queryParams, setQueryParams] = useState(initialQueryParams);

  const { data, isError, error, isLoading } = useQuery(
    ["finances", queryParams],
    async () => {
      const res = await axios.get("/deals/finances", {
        params: removeNulls(queryParams),
      });
      return res?.data;
    },
    {
      staleTime: FIFTEEN_MINUTES,
      refetchOnWindowFocus: false,
      refetchInterval: THIRTY_MINUTES,
    }
  );

  return (
    <FinancesView
      finances={{ data, isError, error, isLoading }}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
    />
  );
};

export default FinancesViewContainer;
