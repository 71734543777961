import { useEffect, useState } from 'react';
import { getImage, imageUrlRegex, pdfUrlRegex } from '../../../utils/envConfig';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Card, Collapse } from 'reactstrap';
import { Icon } from '../../../components/Component';

// rendering pdf
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Link } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function MyCollapsibleCard({ id, title, content, imagePath }) {
    const isImage = imageUrlRegex.test(imagePath);

    const isPdf = pdfUrlRegex.test(imagePath);

    const fileExtension = imagePath?.split('.')?.slice(-1)[0];

    const [isOpen, setIsOpen] = useState(false);

    const [image, setImage] = useState(null);

    const [numPages, setNumPages] = useState(null);

    const axios = useAxiosPrivate();

    const fetchImage = async () => {
        try {
            if (!imagePath) {
                return;
            }

            const image = await getImage(imagePath, axios);
            setImage(image?.signedRequest);
        } catch (err) {}
    };

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        imagePath && fetchImage();
    }, []);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <Card className="bg-light p-3 mb-2">
            <div
                onClick={toggleCollapse}
                className="d-flex justify-content-between align-items-center"
            >
                <span className="document-name">{title}</span>
                <span className="document-icons">
                    <Icon
                        name={isOpen ? 'minus' : 'plus'}
                        className="me-1"
                    ></Icon>
                </span>
            </div>
            <Collapse isOpen={isOpen}>
                <div className="p-3">
                    <p>{content}</p>
                    {isImage ? (
                        <div style={{ position: 'relative' }}>
                        <img src={image} />
                        <a href={image} download={"download"} className="btn btn-primary" style={{ position: 'absolute', top: '5px', right: '5px', zIndex: '1' }}>
                        <em className="icon ni ni-download"></em>
                        <span>Download</span>
                        </a>  
                    </div>                     
                    ) : isPdf ? (
                        <div style={{ position: 'relative' }}>
                            
                        <Document
                            file={image}
                            onError={(err) => console.log(err)}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                />
                            ))}
                        </Document>
                        <a href={image} download={"download"} className="btn btn-primary" style={{ position: 'absolute', top: '5px', right: '5px', zIndex: '1' }}>
                        <em className="icon ni ni-download"></em>
                        <span>Download</span>
                        </a>
                    </div>
                    ) : (
                        <>
                            <p>
                                {title}.{fileExtension}
                            </p>
                        </>
                    )}
                </div>
            </Collapse>
        </Card>
    );
}
