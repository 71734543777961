import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../../components/Component";
import EditableBorderedListCard from "../../../../components/cards/EditableBorderedListCard";

const PropertyInfoView = ({ shownFieldsData, toggleEdit }) => {
  return (
    <>
      <BlockHead>
        <BlockBetween>
          <BlockHeadContent>
              <h5 className="nk-block-title title">Property Info</h5>
          </BlockHeadContent>
          <BlockHeadContent>
            <BlockTitle>
              <span className="fs-6 cursor-pointer" onClick={toggleEdit}>
                <Icon name="edit" />
              </span>
            </BlockTitle>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <EditableBorderedListCard
        data={shownFieldsData}
        toggleEdit={toggleEdit}
      />
    </>
  );
};

export default PropertyInfoView;
