import React, { useEffect, useState } from 'react';
import {
    ActivityStatus,
    ActivityTypes,
    DealCategory,
    DealStatus,
    formatDate,
    formatValue
} from '../../utils/envConfig';

import { LeadStatus } from '../../utils/envConfig';
import DatePicker from 'react-datepicker';
import MonthPicker from 'react-month-picker-input';
import 'react-month-picker-input/dist/react-month-picker-input.css';
import {
    addDays,
    differenceInDays,
    endOfMonth,
    endOfWeek,
    isValid,
    startOfMonth,
    startOfWeek,
    sub,
    subDays,
    subMonths
} from 'date-fns';
import { getDay } from 'date-fns';
import Content from '../../layout/content/Content';
import Head from '../../layout/head/Head';
import {
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon
} from '../../components/Component';
import { Col, Row, Spinner } from 'reactstrap';
import DataCard from '../../components/partials/default/DataCard';
import '../../App.css';
import ClientsAcquired from '../../components/ClientsAcquired';
import DealsClosedGrid from '../../components/DealsClosedGrid';
import DealsPendingGrid from '../../components/DealsPendingGrid';
import { useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { DefaultOrderChart } from '../../components/partials/charts/default/DefaultCharts';
import DealsStatistics from '../../components/DealsStatistics';
import AgentsProductivity from './components/AgentsProductivity';
import RenderChart from './components/RenderChart';
import MultiBarsBarChart from './components/MultibarsBarChart';
import { fetchAgents } from '../../redux/actions/user';
import { useDispatch } from 'react-redux';

const defaultLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

export default function MainDashboard() {
    const axios = useAxiosPrivate();
    const dispatch = useDispatch();

    const agents = useSelector((state) => state?.user?.agents);

    const [windowWidth, setWindowWidth] = useState(null);

    const [smBtn, setSmBtn] = useState(false);

    // fresh leads
    const [leadsDoneToday, setLeadsDoneToday] = useState(null);
    const [leadsDoneTodayPercent, setLeadsDoneTodayPercent] = useState(null);
    const [dailyLeadsDone, setDailyLeadsDone] = useState(null);

    const [freshLeadsPending, setFreshLeadsPending] = useState(null);
    const [freshLeadsPendingPercent, setFreshLeadsPendingPercent] =
        useState(null);

    const [dailyleadsCreated, setDailyLeadsCreated] = useState(null);

    // follow-ups
    const [followUpsDone, setFollowUpsDone] = useState(null);
    const [followUpsPending, setFollowUpsPending] = useState(null);
    const [followUpsDonePercent, setFollowUpsDonePercent] = useState(null);
    const [followUpsPendingPercent, setFollowUpsPendingPercent] =
        useState(null);
    const [dailyFollowUps, setDailyFollowUps] = useState(null);

    // deals
    const [dealsClosed, setDealsClosed] = useState(null);
    const [dealsPendingThisMonth, setDealsPendingThisMonth] = useState();
    const [dailyDeals, setDailyDeals] = useState(null);

    const [dealsClosedThisWeek, setDealsClosedThisWeek] = useState(null);
    const [dealsClosedThisWeekPercent, setDealsClosedThisWeekPercent] =
        useState(null);

    const [dealsClosedThisMonth, setDealsClosedThisMonth] = useState(null);
    const [dealsClosedThisMonthPercent, setDealsClosedThisMonthPercent] =
        useState(null);

    // activities
    const [tasksCreatedToday, setTasksCreatedToday] = useState(null);
    const [tasksCreatedTodayPercent, setTasksCreatedTodayPercent] =
        useState(null);
    const [tasksCreatedThisWeek, setTasksCreatedThisWeek] = useState(null);
    const [tasksCreatedThisWeekPercent, setTasksCreatedThisWeekPercent] =
        useState(null);

    // clients
    const [clientsThisMonth, setClientsThisMonth] = useState(null);
    const [leadsCreatedToday, setLeadsCreatedToday] = useState(null);
    const [leadsCreatedTodayPercent, setLeadsCreatedTodayPercent] =
        useState(null);
    const [leadsCreatedThisWeek, setLeadsCreatedThisWeek] = useState(null);
    const [leadsCreatedThisWeekPercent, setLeadsCreatedThisWeekPercent] =
        useState(null);

    // listings
    const [listingsAcquiredToday, setListingsAcquiredToday] = useState(null);
    const [listingsAcquiredTodayPercent, setListingsAcquiredTodayPercent] =
        useState(null);

    const [
        listingsAcquiredThisWeekPercent,
        setListingsAcquiredThisWeekPercent
    ] = useState(null);
    const [listingsAcquiredThisWeek, setListingsAcquiredThisWeek] =
        useState(null);

    const [dailyListings, setDailyListings] = useState(null);

    // overdue tasks
    const [dailyOverdueTasks, setDailyOverdueTasks] = useState(null);

    // others
    const today = new Date();

    const [dateError, setDateError] = useState('');

    const [dayStart, setDayStart] = useState(today);
    const [dayEnd, setDayEnd] = useState(today);

    const [weekStart, setWeekStart] = useState(startOfWeek(today));

    const [weekEnd, setWeekEnd] = useState(endOfWeek(today));

    const [monthStart, setMonthStart] = useState(startOfMonth(today));

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        !agents?.length && dispatch(fetchAgents(axios));
        return () => {};
    }, []);

    useEffect(() => {
        const timeout = dateError
            ? setTimeout(() => {
                  setDateError('');
              }, 3000)
            : undefined;

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [dateError]);

    const updateLabels = () => {
        const dayOfWeek = getDay(weekStart);

        switch (dayOfWeek) {
            case 0:
                return defaultLabels;
            case 1:
                return ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];
            case 2:
                return ['Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun', 'Mon'];
            case 3:
                return ['Wed', 'Thur', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue'];
            case 4:
                return ['Thur', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed'];
            case 5:
                return ['Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thur'];
            case 6:
                return ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri'];

            default:
                return defaultLabels;
        }
    };

    const updateFetchingStatus = () => {
        setLeadsDoneToday(null);
        setFreshLeadsPending(null);
        setFollowUpsDone(null);
        setFollowUpsPending(null);
        setDealsClosed(null);
        setDealsPendingThisMonth(null);
        // setDealsClosedThisWeek(null);
        setDealsClosedThisMonth(null);
        setTasksCreatedToday(null);
        setTasksCreatedThisWeek(null);
        setClientsThisMonth(null);
        setLeadsCreatedToday(null);
        setLeadsCreatedThisWeek(null);
        // setListingsAcquiredToday(null);
        setListingsAcquiredThisWeek(null);
    };

    const getDayName = (date, locale = 'en-US') => {
        if (!isValid(date)) {
            console.log('Invalid date instance');
            throw new Error('Please pass valid date instance');
        }

        return date.toLocaleDateString(locale, {
            month: 'short',
            day: '2-digit'
        });
    };

    const getListingsAcquired = async () => {
        try {

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(today);

            const toDate = formatDate(today);

            const fromWeek = formatDate(
                startOfWeek(subDays(startOfWeek(today), 1))
            );

            const toWeek = formatDate(subDays(startOfWeek(today), 1));

            const listingsReq = axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&category[]=${DealCategory.ListingAcquired}&status[]=${DealStatus.Completed}`
            );

            const listingsComReq = axios.get(
                `/reports/deals?from_date=${fromWeek}&to_date=${toWeek}&category[]=${DealCategory.ListingAcquired}&status[]=${DealStatus.Completed}`
            );

            const [{ data: listingsAcquired }, { data: listingsAcquiredCom }] =
                await Promise.all([listingsReq, listingsComReq]);

            const listingsCount = listingsAcquired?.count;
            const listingsComCount = listingsAcquiredCom?.count;

            const comparisonBase = listingsComCount??0 / 7 ;

            const percent =
                !comparisonBase && !listingsCount
                    ? 0
                    : !comparisonBase && listingsCount
                    ? 100
                    : Math.round(
                          ((listingsCount - comparisonBase) / comparisonBase) *
                              100
                      );

            setListingsAcquiredToday(formatValue(listingsCount ?? 0));
            setListingsAcquiredTodayPercent(
                isNaN(Number(percent)) ? '0' : percent?.toString()
            );
        } catch (err) {
            console.log(err);
            setListingsAcquiredToday('0');
            setListingsAcquiredTodayPercent('0');
        }
    };

    const getListingsAcquiredThisWeek = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart
            );

            const toDate = formatDate(
                dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd
            );

            const fromWeek = formatDate(
                dayDifference <= 0
                    ? startOfWeek(subDays(weekStart, 1))
                    : weekStart
            );

            const toWeek = formatDate(
                dayDifference <= 0 ? subDays(weekStart, 1) : weekEnd
            );

            const listingsReq = axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&category[]=${DealCategory.ListingAcquired}&status[]=${DealStatus.Completed}&with_users=true`
            );

            const listingsComReq = axios.get(
                `/reports/deals?from_date=${fromWeek}&to_date=${toWeek}&category[]=${DealCategory.ListingAcquired}&status[]=${DealStatus.Completed}`
            );

            const [{ data: listingsAcquired }, { data: listingsAcquiredCom }] =
                await Promise.all([listingsReq, listingsComReq]);

            const percent =
                listingsAcquiredCom?.length === 0 &&
                listingsAcquired?.length > 0
                    ? 100
                    : listingsAcquiredCom?.length === 0 &&
                      listingsAcquired?.length === 0
                    ? 0
                    : Math.round(
                          ((listingsAcquired?.length -
                              listingsAcquiredCom?.length) /
                              listingsAcquiredCom?.length) *
                              100
                      );

            setListingsAcquiredThisWeek(listingsAcquired);
            setListingsAcquiredThisWeekPercent(
                isNaN(Number(percent)) ? '0' : percent?.toString()
            );
        } catch (err) {
            console.log(err);
            setListingsAcquiredThisWeek([]);
            setListingsAcquiredThisWeekPercent('0');
        }
    };

    const getDailyListingsAcquired = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart
            );

            const toDate = formatDate(
                dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd
            );

            const { data: listingsAcquired } = await axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&category[]=${DealCategory.ListingAcquired}&status[]=${DealStatus.Completed}&daily_data=true`
            );

            const listings = listingsAcquired?.sort(
                (a, b) => new Date(a?.date) - new Date(b?.date)
            );

            let dateArray = [];
            let currentDate =
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart;

            const stopDate = dayDifference <= 0 ? endOfWeek(dayStart) : dayEnd;

            while (currentDate <= stopDate) {
                dateArray.push(currentDate);
                currentDate = addDays(new Date(currentDate), 1);
            }

            const dailyListiings = dateArray?.map((date) => {
                const fromattedDate = formatDate(date);

                const data = listings?.find(
                    (item) => item?.date === fromattedDate
                );

                return {
                    date: getDayName(new Date(data?.date ?? fromattedDate)),
                    count: data?.count ?? 0
                };
            });

            setDailyListings(dailyListiings);
        } catch (err) {
            console.log(err);
        }
    };

    const getDealsClosedThisWeek = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(startOfWeek(today));

            const toDate = formatDate(endOfWeek(today));

            const fromWeek = formatDate(
                startOfWeek(subDays(startOfWeek(today), 1))
            );

            const toWeek = formatDate(subDays(startOfWeek(today), 1));

            const dealsReq = axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&category[]=${DealCategory.SecondaryProperty}&category[]=${DealCategory.PrimaryOffplan}&status[]=${DealStatus.Completed}`
            );

            const dealsComReq = axios.get(
                `/reports/deals?from_date=${fromWeek}&to_date=${toWeek}&category[]=${DealCategory.SecondaryProperty}&category[]=${DealCategory.PrimaryOffplan}&status[]=${DealStatus.Completed}`
            );

            const [{ data: dealsClosed }, { data: dealsClosedCom }] =
                await Promise.all([dealsReq, dealsComReq]);

            const percent =
                dealsClosedCom?.length === 0 && dealsClosed?.length > 0
                    ? 100
                    : dealsClosedCom?.length === 0 && dealsClosed?.length === 0
                    ? 0
                    : Math.round(
                          ((dealsClosed?.length - dealsClosedCom?.length) /
                              dealsClosedCom?.length) *
                              100
                      );

            setDealsClosedThisWeek(formatValue(dealsClosed?.length ?? 0));
            setDealsClosedThisWeekPercent(
                isNaN(Number(percent)) ? '0' : percent?.toString()
            );
        } catch (err) {
            console.log(err);
            setDealsClosedThisWeek('0');
            setDealsClosedThisWeekPercent('0');
        }
    };

    const getDailyDealsClosed = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart
            );

            const toDate = formatDate(
                dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd
            );

            const { data: dealsClosed } = await axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&category[]=${DealCategory.SecondaryProperty}&category[]=${DealCategory.PrimaryOffplan}&status[]=${DealStatus.Completed}&daily_data=true`
            );

            const deals = dealsClosed?.sort(
                (a, b) => new Date(a?.date) - new Date(b?.date)
            );

            if (deals?.length) {
                let dateArray = [];
                let currentDate =
                    dayDifference <= 0 ? startOfWeek(dayStart) : dayStart;

                const stopDate =
                    dayDifference <= 0 ? endOfWeek(dayStart) : dayEnd;

                while (currentDate <= stopDate) {
                    dateArray.push(currentDate);
                    currentDate = addDays(new Date(currentDate), 1);
                }

                const dailyDeals = dateArray?.map((date) => {
                    const fromattedDate = formatDate(date);

                    const data = deals?.find(
                        (item) => item?.date === fromattedDate
                    );

                    return {
                        date: getDayName(new Date(data?.date ?? fromattedDate)),
                        count: data?.count ?? 0
                    };
                });

                setDailyDeals(dailyDeals);
                return;
            }
            setDailyDeals([]);
        } catch (err) {
            console.log(err);
            setDailyDeals([]);
        }
    };

    const getDealsClosedThisMonth = async () => {
        try {
            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(monthStart);

            const toDate = formatDate(endOfMonth(monthStart));

            const lastMonthEnd = formatDate(
                subDays(startOfMonth(monthStart), 1)
            );

            const lastMonthStart = formatDate(
                startOfMonth(new Date(lastMonthEnd))
            );

            const dealsReq = axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&category[]=${DealCategory.SecondaryProperty}&category[]=${DealCategory.PrimaryOffplan}&status[]=${DealStatus.Completed}&with_users=true`
            );

            const dealsComReq = axios.get(
                `/reports/deals?from_date=${lastMonthStart}&to_date=${lastMonthEnd}&category[]=${DealCategory.SecondaryProperty}&category[]=${DealCategory.PrimaryOffplan}&status[]=${DealStatus.Completed}`
            );

            const [{ data: dealsClosed }, { data: dealsClosedCom }] =
                await Promise.all([dealsReq, dealsComReq]);

            const percent =
                dealsClosedCom?.length === 0 && dealsClosed?.length > 0
                    ? 100
                    : dealsClosedCom?.length === 0 && dealsClosed?.length === 0
                    ? 0
                    : Math.round(
                          ((dealsClosed?.length - dealsClosedCom?.length) /
                              dealsClosedCom?.length) *
                              100
                      );

            setDealsClosedThisMonth(formatValue(dealsClosed?.length ?? 0));
            setDealsClosedThisMonthPercent(
                isNaN(Number(percent)) ? '0' : percent?.toString()
            );

            setDealsClosed(dealsClosed);
        } catch (err) {
            console.log(err);
            setDealsClosedThisMonth('0');
            setDealsClosedThisMonthPercent('0');
            setDealsClosed([]);
        }
    };

    const getDealsPendingThisMonth = async () => {
        try {
            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(monthStart);

            const toDate = formatDate(endOfMonth(monthStart));

            const dealStatusQuery = Object.values(DealStatus)
                .filter(
                    (stat) =>
                        stat !== DealStatus.Completed &&
                        stat !== DealStatus.Approved &&
                        stat !== DealStatus.Rejected &&
                        stat !== DealStatus.Canceled
                )
                .map((item) => `status[]=${item}`)
                .join('&');

            const { data: dealsPending } = await axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&category[]=${DealCategory.SecondaryProperty}&category[]=${DealCategory.PrimaryOffplan}&${dealStatusQuery}&with_users=true`
            );

            setDealsPendingThisMonth(dealsPending);
        } catch (err) {
            console.log(err);
            setDealsPendingThisMonth([]);
        }
    };

    const getFollowUpsDone = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(dayStart);
            const toDate = formatDate(dayEnd);

            // Structuring request dates for comparison data
            // if dayStart - dayEnd <=0 then we want to fetch comparison data for start of week of dayStart or dayEnd (same result since both dates are same) else we want to fetch data for the previous 7 days (weekStart & weekeend) so we can effectively compare the data with results
            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(subDays(weekStart, 1)))
                    : formatDate(weekStart);

            const toWeek =
                dayDifference <= 0
                    ? formatDate(subDays(weekStart, 1))
                    : formatDate(weekEnd);

            const activityTypesQuery = Object.values(ActivityTypes)
                .filter(
                    (value) =>
                        value !== ActivityTypes.Viewing &&
                        value !== ActivityTypes.Assign
                )
                .map((item) => `activity_type[]=${item}`)
                .join('&');

            const followUpsDoneReq = axios.get(
                `/reports/follow-ups?from_date=${fromDate}&to_date=${toDate}&${activityTypesQuery}&activity_status[]=${ActivityStatus.Complete}`
            );

            const followUpsDoneComReq = axios.get(
                `/reports/follow-ups?from_date=${fromWeek}&to_date=${toWeek}&${activityTypesQuery}&activity_status[]=${ActivityStatus.Complete}`
            );

            const [{ data: followUpsDone }, { data: followUpsDoneCom }] =
                await Promise.all([followUpsDoneReq, followUpsDoneComReq]);

            const followUpsDoneCount = followUpsDone?.count;

            const comparisonBase =
                dayDifference <= 0
                    ? followUpsDoneCom?.count / 7
                    : followUpsDoneCom?.count;

            const followUpsDonePercent =
                !comparisonBase && !followUpsDoneCount
                    ? 0
                    : !comparisonBase && followUpsDoneCount
                    ? 100
                    : ((followUpsDoneCount - comparisonBase) / comparisonBase) *
                      100;

            setFollowUpsDonePercent(
                isNaN(followUpsDonePercent)
                    ? '0'
                    : Math.round(followUpsDonePercent).toString()
            );
            setFollowUpsDone(followUpsDoneCount?.toString());
        } catch (err) {
            console.log(err);
            setFollowUpsDonePercent('0');
            setFollowUpsDone('0');
        }
    };

    const getFollowUpsPending = async () => {
        try {
            // follow ups pending is weekly. Thus when fetching daily data, we need to fetch weekly data for followups pending.
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);
            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayStart))
                    : formatDate(dayEnd);

            // Structuring request dates for comparison data
            // if dayStart - dayEnd <=0 then we want to fetch comparison data for start of week of dayStart or dayEnd (same result since both dates are same) else we want to fetch data for the previous 7 days (weekStart & weekeend) so we can effectively compare the data with results

            const lastWeekEnd =
                dayDifference <= 0
                    ? subDays(startOfWeek(dayStart), 1)
                    : weekEnd;

            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(subDays(weekStart, 1)))
                    : formatDate(weekStart);

            const toWeek =
                dayDifference <= 0
                    ? formatDate(subDays(weekStart, 1))
                    : formatDate(weekEnd);

            const activityTypesQuery = Object.values(ActivityTypes)
                .filter(
                    (value) =>
                        value !== ActivityTypes.Viewing &&
                        value !== ActivityTypes.Assign
                )
                .map((item) => `activity_type[]=${item}`)
                .join('&');

            const followUpsPendingReq = axios.get(
                `/reports/follow-ups?from_date=${fromDate}&to_date=${toDate}&${activityTypesQuery}&activity_status[]=${ActivityStatus.Overdue}&activity_status[]=${ActivityStatus.Pending}`
            );

            const followUpsPendingComReq = axios.get(
                `/reports/follow-ups?from_date=${fromWeek}&to_date=${toWeek}&${activityTypesQuery}&activity_status[]=${ActivityStatus.Overdue}&activity_status[]=${ActivityStatus.Pending}`
            );

            const [{ data: followUpsPending }, { data: followUpsPendingCom }] =
                await Promise.all([
                    followUpsPendingReq,
                    followUpsPendingComReq
                ]);

            const followUpsPendingCount = followUpsPending?.count;

            const pendingComparisonBase = followUpsPendingCom?.count;

            const followUpsPendingPercent =
                !pendingComparisonBase && !followUpsPendingCount
                    ? 0
                    : !pendingComparisonBase && followUpsPendingCount
                    ? 100
                    : ((followUpsPendingCount - pendingComparisonBase) /
                          pendingComparisonBase) *
                      100;

            setFollowUpsPendingPercent(
                isNaN(followUpsPendingPercent)
                    ? '0'
                    : Math.round(followUpsPendingPercent).toString()
            );
            setFollowUpsPending(followUpsPendingCount?.toString());
        } catch (err) {
            console.log(err);
            setFollowUpsPendingPercent('0');
            setFollowUpsPending('0');
        }
    };

    const getDailyFollowUps = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);
            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayStart))
                    : formatDate(dayEnd);

            const activityTypesQuery = Object.values(ActivityTypes)
                .filter(
                    (value) =>
                        value !== ActivityTypes.Viewing &&
                        value !== ActivityTypes.Assign
                )
                .map((item) => `activity_type[]=${item}`)
                .join('&');

            const { data: dailyFollowUps } = await axios.get(
                `/reports/follow-ups?from_date=${fromDate}&to_date=${toDate}&${activityTypesQuery}&activity_status[]=${ActivityStatus.Complete}&daily_data=true`
            );

            const followUpsData = dailyFollowUps?.data?.length
                ? dailyFollowUps?.data?.sort(
                      (a, b) => new Date(a?.date) - new Date(b?.date)
                  )
                : [];

            let dateArray = [];
            let currentDate =
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart;

            const stopDate = dayDifference <= 0 ? endOfWeek(dayStart) : dayEnd;

            while (currentDate <= stopDate) {
                dateArray.push(currentDate);
                currentDate = addDays(new Date(currentDate), 1);
            }

            const dailyFollowUpsData = dateArray?.map((date) => {
                const fromattedDate = formatDate(date);

                const data = followUpsData?.find(
                    (item) => item?.date === fromattedDate
                );

                return {
                    date: getDayName(new Date(data?.date ?? fromattedDate)),
                    count: data?.count ?? 0
                };
            });

            setDailyFollowUps(dailyFollowUpsData);
        } catch (err) {
            console.log(err);
            setDailyFollowUps([]);
        }
    };

    const getDailyOverdueTasks = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);
            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayStart))
                    : formatDate(dayEnd);

            const { data: dailyOverdueTasks } = await axios.get(
                `/reports/activities?from_date=${fromDate}&to_date=${toDate}&status[]=${ActivityStatus.Overdue}&daily=true&date_filter=true`
            );

            const overdueData = dailyOverdueTasks?.length
                ? dailyOverdueTasks?.sort(
                      (a, b) => new Date(a?.date) - new Date(b?.date)
                  )
                : [];

            let dateArray = [];
            let currentDate =
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart;

            const stopDate = dayDifference <= 0 ? endOfWeek(dayStart) : dayEnd;

            while (currentDate <= stopDate) {
                dateArray.push(currentDate);
                currentDate = addDays(new Date(currentDate), 1);
            }

            const dailyOverdueData = dateArray?.map((date) => {
                const fromattedDate = formatDate(date);

                const data = overdueData?.find(
                    (item) => item?.date === fromattedDate
                );

                return {
                    date: getDayName(new Date(data?.date ?? fromattedDate)),
                    count: data?.count ?? 0
                };
            });

            setDailyOverdueTasks(dailyOverdueData);
        } catch (err) {
            console.log(err);
            setDailyOverdueTasks([]);
        }
    };

    const getTasksToday = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(dayStart);

            const toDate = formatDate(dayEnd);

            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(subDays(weekStart, 1)))
                    : formatDate(weekStart);

            const toWeek =
                dayDifference <= 0
                    ? formatDate(subDays(weekStart, 1))
                    : formatDate(weekEnd);

            const tasksReq = axios.get(
                `/reports/activities?from_date=${fromDate}&to_date=${toDate}`
            );

            const tasksComReq = axios.get(
                `/reports/activities?from_date=${fromWeek}&to_date=${toWeek}`
            );

            const [{ data: tasks }, { data: tasksCom }] = await Promise.all([
                tasksReq,
                tasksComReq
            ]);

            const comparisonBase =
                dayDifference <= 0 ? tasksCom?.count / 7 : tasksCom?.count;
            const tasksCount = tasks?.count;

            const percent =
                !comparisonBase && !tasksCount
                    ? 0
                    : !comparisonBase && tasksCount
                    ? 100
                    : Math.round(
                          ((tasksCount - comparisonBase) / comparisonBase) * 100
                      );

            setTasksCreatedToday(formatValue(tasksCount));

            setTasksCreatedTodayPercent(
                isNaN(percent) ? '0' : percent.toString()
            );
        } catch (err) {
            console.log(err);
            setTasksCreatedToday('0');

            setTasksCreatedTodayPercent('0');
        }
    };

    const getTasksThisWeek = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);

            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayEnd))
                    : formatDate(dayEnd);

            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(subDays(weekEnd, 1)))
                    : formatDate(weekStart);

            const toWeek =
                dayDifference <= 0
                    ? formatDate(subDays(weekEnd, 1))
                    : formatDate(weekEnd);

            const tasksReq = axios.get(
                `/reports/activities?from_date=${fromDate}&to_date=${toDate}`
            );

            const tasksComReq = axios.get(
                `/reports/activities?from_date=${fromWeek}&to_date=${toWeek}`
            );

            const [{ data: tasks }, { data: tasksCom }] = await Promise.all([
                tasksReq,
                tasksComReq
            ]);

            const tasksCount = tasks?.count;
            const tasksComCount = tasksCom?.count;

            const percent =
                !tasksComCount && !tasksCount
                    ? 0
                    : !tasksComCount && tasksCount
                    ? 100
                    : Math.round(
                          ((tasksCount - tasksComCount) / tasksComCount) * 100
                      );

            setTasksCreatedThisWeek(formatValue(tasks?.count));

            setTasksCreatedThisWeekPercent(
                isNaN(percent) ? '0' : percent.toString()
            );
        } catch (err) {
            console.log(err);
            setTasksCreatedThisWeek('0');

            setTasksCreatedThisWeekPercent('0');
        }
    };

    const getLeadsDone = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(dayStart);
            const toDate = formatDate(dayEnd);

            // Structuring request dates for comparison data
            // if dayStart - dayEnd <=0 then we want to fetch comparison data for start of week of dayStart or dayEnd (same result since both dates are same) else we want to fetch data for the previous 7 days (weekStart & weekeend) so we can effectively compare the data with results
            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(subDays(weekStart, 1)))
                    : formatDate(weekStart);

            const toWeek =
                dayDifference <= 0
                    ? formatDate(subDays(weekStart, 1))
                    : formatDate(weekEnd);

            const activityTypesQuery = Object.values(ActivityTypes)
                .filter((value) => value !== ActivityTypes.Assign)
                .map((item) => `activity_type[]=${item}`)
                .join('&');

            const leadsDoneReq = axios.get(
                `/reports/follow-ups?from_date=${fromDate}&to_date=${toDate}&${activityTypesQuery}&activity_status[]=${ActivityStatus.Complete}`
            );

            const leadsDoneComReq = axios.get(
                `/reports/follow-ups?from_date=${fromWeek}&to_date=${toWeek}&${activityTypesQuery}&activity_status[]=${ActivityStatus.Complete}`
            );

            const [{ data: leadsDone }, { data: leadsDoneCom }] =
                await Promise.all([leadsDoneReq, leadsDoneComReq]);

            const leadsDoneCount = leadsDone?.count;

            const comparisonBase =
                dayDifference <= 0
                    ? leadsDoneCom?.count / 7
                    : leadsDoneCom?.count;

            const percent =
                !comparisonBase && !leadsDoneCount
                    ? 0
                    : !comparisonBase && leadsDoneCount
                    ? 100
                    : Math.round(
                          ((leadsDoneCount - comparisonBase) / comparisonBase) *
                              100
                      );

            setLeadsDoneToday(formatValue(leadsDoneCount ?? 0));
            setLeadsDoneTodayPercent(
                isNaN(percent) ? '0' : percent?.toString()
            );
        } catch (err) {
            console.log(err);
            setLeadsDoneToday('0');
            setLeadsDoneTodayPercent('0');
        }
    };

    const getFreshLeadsPending = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);
            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayEnd))
                    : formatDate(dayEnd);

            // since we're starting by fetching data of the week, we want to compare with data of last week
            // last weekend is same as subtracting 1 day from start of this week
            // variable naming might be confusing. dayStart and dayEnd holds the the dates that are displayed on screen. Data for these dates are displayed on screen (basically from date and to date). while weekStart and weekEnd holds the dates which we use to do comparisons.
            const lastWeekEnd =
                dayDifference <= 0 ? subDays(weekStart, 1) : weekEnd;

            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(lastWeekEnd))
                    : formatDate(weekStart);

            const toWeek = formatDate(lastWeekEnd);

            const freshLeadsReq = axios.get(
                `/reports/leads?from_date=${fromDate}&to_date=${toDate}&lead_status=${LeadStatus.Fresh}`
            );

            const freshLeadsComReq = axios.get(
                `/reports/leads?from_date=${fromWeek}&to_date=${toWeek}&lead_status=${LeadStatus.Fresh}`
            );

            const [{ data: freshLeads }, { data: freshLeadsCom }] =
                await Promise.all([freshLeadsReq, freshLeadsComReq]);

            const leadsCount = freshLeads?.count;
            const leadsComCount = freshLeadsCom?.count;

            const percent = Math.round(
                ((leadsCount - leadsComCount) / leadsComCount) * 100
            );

            setFreshLeadsPending(formatValue(leadsCount));
            setFreshLeadsPendingPercent(
                isNaN(percent) ? '0' : percent?.toString()
            );
        } catch (err) {
            console.log(err);
            setFreshLeadsPending('0');
            setFreshLeadsPendingPercent('0');
        }
    };

    const getDailyFreshLeadsCreated = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);
            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayEnd))
                    : formatDate(dayEnd);

            const { data: leads } = await axios.get(
                `/reports/leads?from_date=${fromDate}&to_date=${toDate}&daily=true`
            );

            let dateArray = [];
            let currentDate = new Date(fromDate);

            const stopDate = new Date(toDate);

            while (currentDate <= stopDate) {
                dateArray.push(currentDate);
                currentDate = addDays(new Date(currentDate), 1);
            }

            const dailyLeads = dateArray?.map((date) => {
                const fromattedDate = formatDate(date);

                const data = leads?.find(
                    (item) => item?.date === fromattedDate
                );

                return {
                    date: getDayName(new Date(data?.date ?? fromattedDate)),
                    count: data?.count ?? 0
                };
            });

            setDailyLeadsCreated(dailyLeads);
        } catch (err) {
            console.log(err);
            setDailyLeadsCreated([]);
        }
    };

    const getLeadsCreatedToday = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(dayStart);
            const toDate = formatDate(dayEnd);

            // since we're starting by fetching data of the week, we want to compare with data of last week
            // variable naming might be confusing. dayStart and dayEnd holds the the dates that are displayed on screen. Data for these dates are displayed on screen (basically from date and to date). while weekStart and weekEnd holds the dates which we use to do comparisons.

            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(subDays(weekStart, 1)))
                    : formatDate(weekStart);

            const toWeek =
                dayDifference <= 0
                    ? formatDate(subDays(weekStart, 1))
                    : formatDate(weekEnd);

            const createdByQuery = agents
                ?.map(
                    (agent) =>
                        `created_by[]=${agent?.userId ?? agent?.user?.id}`
                )
                ?.join('&');

            const leadsReq = axios.get(
                `/reports/leads?from_date=${fromDate}&to_date=${toDate}&${createdByQuery}`
            );

            const leadsComReq = axios.get(
                `/reports/leads?from_date=${fromWeek}&to_date=${toWeek}&${createdByQuery}`
            );

            const [{ data: leads }, { data: leadsCom }] = await Promise.all([
                leadsReq,
                leadsComReq
            ]);

            const leadsCount = leads?.count;
            const leadsComCount = leadsCom?.count;

            const comparisonBase =
                dayDifference <= 0 ? leadsComCount / 7 : leadsComCount;

            const percent =
                !comparisonBase && !leadsCount
                    ? 0
                    : !comparisonBase && leadsCount
                    ? 100
                    : Math.round(
                          ((leadsCount - comparisonBase) / comparisonBase) * 100
                      );

            setLeadsCreatedToday(formatValue(leadsCount));
            setLeadsCreatedTodayPercent(
                isNaN(percent) ? '0' : percent?.toString()
            );
        } catch (err) {
            console.log(err);
            setLeadsCreatedToday('0');
            setLeadsCreatedTodayPercent('0');
        }
    };

    const getLeadsCreatedThisWeek = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);
            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayEnd))
                    : formatDate(dayEnd);

            // since we're starting by fetching data of the week, we want to compare with data of last week
            // last weekend is same as subtracting 1 day from start of this week
            // variable naming might be confusing. dayStart and dayEnd holds the the dates that are displayed on screen. Data for these dates are displayed on screen (basically from date and to date). while weekStart and weekEnd holds the dates which we use to do comparisons.
            const lastWeekEnd =
                dayDifference <= 0 ? subDays(weekStart, 1) : weekEnd;

            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(lastWeekEnd))
                    : formatDate(weekStart);

            const toWeek = formatDate(lastWeekEnd);

            const createdByQuery = agents
                ?.map(
                    (agent) =>
                        `created_by[]=${agent?.userId ?? agent?.user?.id}`
                )
                ?.join('&');

            const leadsReq = axios.get(
                `/reports/leads?from_date=${fromDate}&to_date=${toDate}&${createdByQuery}`
            );

            const leadsComReq = axios.get(
                `/reports/leads?from_date=${fromWeek}&to_date=${toWeek}&${createdByQuery}`
            );

            const [{ data: leads }, { data: leadsCom }] = await Promise.all([
                leadsReq,
                leadsComReq
            ]);

            const leadsCount = leads?.count;
            const leadsComCount = leadsCom?.count;

            const percent = Math.round(
                ((leadsCount - leadsComCount) / leadsComCount) * 100
            );

            setLeadsCreatedThisWeek(formatValue(leadsCount));
            setLeadsCreatedThisWeekPercent(
                isNaN(percent) ? '0' : percent?.toString()
            );
        } catch (err) {
            console.log(err);
            setLeadsCreatedThisWeek('0');
            setLeadsCreatedThisWeekPercent('0');
        }
    };

    const getDailyLeadsDone = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);
            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayStart))
                    : formatDate(dayEnd);

            const activityTypesQuery = Object.values(ActivityTypes)
                .filter((value) => value !== ActivityTypes.Assign)
                .map((item) => `activity_type[]=${item}`)
                .join('&');

            const { data: dailyLeadsDone } = await axios.get(
                `/reports/follow-ups?from_date=${fromDate}&to_date=${toDate}&${activityTypesQuery}&activity_status[]=${ActivityStatus.Complete}&daily_data=true`
            );

            const leadsDoneData = dailyLeadsDone?.data?.length
                ? dailyLeadsDone?.data?.sort(
                      (a, b) => new Date(a?.date) - new Date(b?.date)
                  )
                : [];

            let dateArray = [];
            let currentDate =
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart;

            const stopDate = dayDifference <= 0 ? endOfWeek(dayStart) : dayEnd;

            while (currentDate <= stopDate) {
                dateArray.push(currentDate);
                currentDate = addDays(new Date(currentDate), 1);
            }

            const dailyLeadsDoneData = dateArray?.map((date) => {
                const fromattedDate = formatDate(date);

                const data = leadsDoneData?.find(
                    (item) => item?.date === fromattedDate
                );

                return {
                    date: getDayName(new Date(data?.date ?? fromattedDate)),
                    count: data?.count ?? 0
                };
            });

            setDailyLeadsDone(dailyLeadsDoneData);
        } catch (err) {
            console.log(err);
            setDailyLeadsDone([]);
        }
    };

    const getClientsCreatedThisMonth = async () => {
        try {
            const dayDifference = dayEnd - dayStart;
            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(monthStart);

            const toDate = formatDate(endOfMonth(monthStart));

            const { data: clients } = await axios.get(
                `/reports/deals/clients-created?from_date=${fromDate}&to_date=${toDate}`
            );

            setClientsThisMonth(clients?.data);
        } catch (err) {
            console.log(err);
            setClientsThisMonth([]);
        }
    };

    useEffect(() => {
        getFollowUpsDone();
        getFollowUpsPending();
        getDailyFollowUps();
        getDailyOverdueTasks();
        getTasksToday();
        getTasksThisWeek();
        // getListingsAcquired();
        getListingsAcquiredThisWeek();
        // getDealsClosedThisWeek();
        getDailyDealsClosed();
        getDailyListingsAcquired();
        getLeadsDone();
        getDailyLeadsDone();
        getFreshLeadsPending();
        getLeadsCreatedToday();
        getLeadsCreatedThisWeek();
        getDailyFreshLeadsCreated();
    }, [dayStart, agents]);

    useEffect(() => {
        updateFetchingStatus(); // lets user knows wer're fetching data
    }, [dayStart]);

    useEffect(() => {
        getListingsAcquired(); // only fetch once page loads
        getDealsClosedThisWeek(); // only fetch once page loads
    }, []);

    useEffect(() => {
        getDealsClosedThisMonth();
        getDealsPendingThisMonth();
        getClientsCreatedThisMonth();
    }, [monthStart]);

    return (
        <React.Fragment>
            <Head title="Dashboard | Admin" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page className="mb-3">
                                Admin Dashboard
                            </BlockTitle>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <a
                                    href="#toggle"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        setSmBtn(!smBtn);
                                    }}
                                    className="btn btn-icon btn-trigger toggle-expand me-n1"
                                >
                                    <Icon name="menu-alt-r"></Icon>
                                </a>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                    <div className="d-flex justify-content-end align-items-end">
                        <ul className="nk-block-tools g-3">
                            <li>
                                <div className="form-control-wrap">
                                    <button
                                        className="btn bg-primary text-white"
                                        onClick={() => {
                                            setDayStart(startOfWeek(today));
                                            setDayEnd(endOfWeek(today));

                                            const weekend = subDays(
                                                startOfWeek(today),
                                                1
                                            );

                                            setWeekStart(startOfWeek(weekend));
                                            setWeekEnd(weekend);
                                            setMonthStart(startOfMonth(today));
                                        }}
                                    >
                                        Current Week
                                    </button>
                                </div>
                            </li>
                            <li>
                                <button
                                    className="btn bg-secondary text-white "
                                    onClick={async () => {
                                        const lastWeekEnd = subDays(
                                            startOfWeek(today),
                                            1
                                        );

                                        const lastWeekStart =
                                            startOfWeek(lastWeekEnd);

                                        const endOf2WeeksAgo = subDays(
                                            lastWeekStart,
                                            1
                                        );
                                        const startOf2WeeksAgo =
                                            startOfWeek(endOf2WeeksAgo);

                                        setDayStart(lastWeekStart);
                                        setDayEnd(lastWeekEnd);

                                        setWeekStart(startOf2WeeksAgo);
                                        setWeekEnd(endOf2WeeksAgo);
                                        setMonthStart(
                                            startOfMonth(lastWeekStart)
                                        );
                                    }}
                                >
                                    Last Week
                                </button>
                            </li>
                            <li>
                                <div className="form-group">
                                    <div className="form-control-wrap position-relative">
                                        <div className="text-danger fs-sm text-center position-absolute start-50 bottom-100 translate-middle-x w-100">
                                            {dateError}
                                        </div>
                                        <div className="input-daterange date-picker-range input-group">
                                            <DatePicker
                                                selected={dayStart}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }} // prevent user from typing in date
                                                onChange={(date, e) => {
                                                    if (date > dayEnd) {
                                                        setDateError(
                                                            'Cannot be greater than end date'
                                                        );
                                                        return;
                                                    }

                                                    const interval =
                                                        differenceInDays(
                                                            dayEnd,
                                                            date
                                                        );

                                                    if (interval >= 7) {
                                                        setDateError(
                                                            'Interval cannot be more than 7 days'
                                                        );
                                                        return;
                                                    }

                                                    setDayStart(date);
                                                    // setDayEnd(addDays(date, 6));
                                                    const weekEnd = subDays(
                                                        date,
                                                        1
                                                    );
                                                    const weekStart = subDays(
                                                        weekEnd,
                                                        interval
                                                    );

                                                    setWeekStart(weekStart);
                                                    setWeekEnd(weekEnd);
                                                    setMonthStart(
                                                        startOfMonth(date)
                                                    );
                                                }}
                                                selectsStart
                                                startDate={dayStart}
                                                endDate={dayEnd}
                                                maxDate={dayEnd}
                                                wrapperClassName="start-m"
                                                className="form-control"
                                            />
                                            <div className="input-group-addon">
                                                TO
                                            </div>
                                            <DatePicker
                                                selected={dayEnd}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }} // prevent user from typing in date
                                                onChange={(date, e) => {
                                                    const interval =
                                                        differenceInDays(
                                                            date,
                                                            dayStart
                                                        );

                                                    if (interval >= 7) {
                                                        setDateError(
                                                            'Interval cannot be more than 7 days'
                                                        );
                                                        return;
                                                    }

                                                    // const daystart = subDays(
                                                    //     date,
                                                    //     interval
                                                    // );
                                                    setDayEnd(date);
                                                    // setDayStart(daystart);

                                                    const weekEnd = subDays(
                                                        dayStart,
                                                        1
                                                    );
                                                    const weekStart = subDays(
                                                        weekEnd,
                                                        interval
                                                    );

                                                    setWeekStart(weekStart);
                                                    setWeekEnd(weekEnd);
                                                    setMonthStart(
                                                        startOfMonth(date)
                                                    );
                                                }}
                                                startDate={dayStart}
                                                endDate={dayEnd}
                                                selectsEnd
                                                minDate={dayStart}
                                                wrapperClassName="end-m"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="card-inner"></div> */}
                </BlockHead>

                <>
                    {/* Agents productivity report */}
                    <Row className="g-gs">
                        <Col md="4" xxl="3" sm="6">
                            <DataCard
                                title={
                                    dayEnd - dayStart <= 0
                                        ? 'Leads Done Today'
                                        : 'Leads Done This Period'
                                }
                                amount={
                                    leadsDoneToday ?? <Spinner size={'sm'} />
                                }
                                chart={<DefaultOrderChart />}
                                percentChange={
                                    leadsDoneTodayPercent ?? (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                up={Number(leadsDoneTodayPercent) >= 0}
                                comparisonText={
                                    dayEnd - dayStart <= 0
                                        ? 'avg last week'
                                        : 'last week'
                                }
                            />
                        </Col>
                        <Col md="4" xxl="3" sm="6">
                            <DataCard
                                title={
                                    dayStart - dayEnd <= 0
                                        ? 'Fresh Leads Pending This Week'
                                        : 'Fresh Leads Pending This Period'
                                }
                                amount={
                                    freshLeadsPending ?? <Spinner size={'sm'} />
                                }
                                percentChange={
                                    freshLeadsPendingPercent ?? (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                up={Number(freshLeadsPendingPercent) >= 0}
                                chart={<DefaultOrderChart />}
                                comparisonText={'last week'}
                            />
                        </Col>
                        <Col md="4" xxl="3" sm="6">
                            <DataCard
                                title={
                                    dayEnd - dayStart <= 0
                                        ? 'Follow-ups Done Today'
                                        : 'Follow-ups Done This Period'
                                }
                                amount={
                                    followUpsDone ? (
                                        formatValue(followUpsDone ?? 0)
                                    ) : (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                percentChange={
                                    followUpsDonePercent ?? (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                up={Number(followUpsDonePercent) >= 0}
                                chart={<DefaultOrderChart />}
                                comparisonText={
                                    dayEnd - dayStart <= 0
                                        ? 'avg last week'
                                        : 'last week'
                                }
                            />
                        </Col>
                        <Col md="4" xxl="3" sm="6">
                            <DataCard
                                title={
                                    dayEnd - dayStart <= 0
                                        ? 'Follow-ups Pending This Week'
                                        : 'Follow-ups Pending This Period'
                                }
                                amount={
                                    followUpsPending ? (
                                        formatValue(
                                            Number(followUpsPending) ?? 0
                                        )
                                    ) : (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                chart={<DefaultOrderChart />}
                                percentChange={
                                    followUpsPendingPercent ? (
                                        followUpsPendingPercent
                                    ) : (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                up={followUpsPendingPercent >= 0}
                                comparisonText={'last week'}
                            />
                        </Col>

                        <Col md="4" xxl="3" sm="6">
                            <DataCard
                                title={`Listings Acquired Today`}
                                amount={
                                    listingsAcquiredToday ?? (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                chart={<DefaultOrderChart />}
                                percentChange={
                                    listingsAcquiredTodayPercent ?? (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                up={Number(listingsAcquiredTodayPercent) >= 0}
                                comparisonText={'avg. last week'}
                            />
                        </Col>
                        <Col md="4" xxl="3" sm="6">
                            <DataCard
                                title={`Listings Acquired ${
                                    dayEnd - dayStart <= 0
                                        ? 'This Week'
                                        : 'This  Period'
                                }`}
                                amount={
                                    listingsAcquiredThisWeek ? (
                                        listingsAcquiredThisWeek?.length
                                    ) : (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                chart={<DefaultOrderChart />}
                                percentChange={
                                    listingsAcquiredThisWeekPercent ?? (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                up={
                                    Number(listingsAcquiredThisWeekPercent) >= 0
                                }
                                comparisonText="last week"
                            />
                        </Col>
                        <Col xxl="3" sm="6">
                            <DataCard
                                title={
                                    dayEnd - dayStart <= 0
                                        ? 'Leads Created By Agents Today'
                                        : 'Leads Created By Agents This Period'
                                }
                                amount={
                                    leadsCreatedToday ?? <Spinner size={'sm'} />
                                }
                                chart={<DefaultOrderChart />}
                                percentChange={
                                    leadsCreatedTodayPercent ?? (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                up={Number(leadsCreatedTodayPercent) >= 0}
                                comparisonText={
                                    dayEnd - dayStart <= 0
                                        ? 'avg last week'
                                        : 'last week'
                                }
                            />
                        </Col>
                        <Col xxl="3" sm="6">
                            <DataCard
                                title={
                                    dayEnd - dayStart <= 0
                                        ? 'Leads Created By Agents This Week'
                                        : 'Leads Created By Agents This Period'
                                }
                                amount={
                                    leadsCreatedThisWeek ?? (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                chart={<DefaultOrderChart />}
                                percentChange={
                                    leadsCreatedThisWeekPercent ?? (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                up={Number(leadsCreatedThisWeekPercent) >= 0}
                                comparisonText={'last week'}
                            />
                        </Col>

                        <Col xxl="6">
                            <AgentsProductivity />
                        </Col>

                        <Col xxl="3" md="6">
                            <DataCard
                                title={
                                    dayEnd - dayStart <= 0
                                        ? 'Tasks Created Today'
                                        : 'Tasks Created This Period'
                                }
                                percentChange={
                                    tasksCreatedTodayPercent ?? (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                amount={
                                    tasksCreatedToday ?? <Spinner size={'sm'} />
                                }
                                chart={<DefaultOrderChart />}
                                up={Number(tasksCreatedTodayPercent) >= 0}
                                comparisonText={
                                    dayEnd - dayStart <= 0
                                        ? 'avg last week'
                                        : 'last week'
                                }
                            />

                            <div className="mt-4">
                                <DataCard
                                    title={
                                        dayEnd - dayStart <= 0
                                            ? 'Tasks Created This Week'
                                            : 'Tasks Created This Period'
                                    }
                                    amount={
                                        tasksCreatedThisWeek ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    chart={<DefaultOrderChart />}
                                    percentChange={
                                        tasksCreatedThisWeekPercent ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    up={
                                        Number(tasksCreatedThisWeekPercent) >= 0
                                    }
                                    comparisonText="last week"
                                />
                            </div>
                        </Col>
                        <Col xxl="3" md="6">
                            <DataCard
                                title={'Deals Closed This Week'}
                                amount={
                                    dealsClosedThisWeek ?? (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                chart={<DefaultOrderChart />}
                                percentChange={
                                    dealsClosedThisWeekPercent ?? (
                                        <Spinner size={'sm'} />
                                    )
                                }
                                up={Number(dealsClosedThisWeekPercent) >= 0}
                                comparisonText="last week"
                            />
                            <div className="mt-4">
                                <DataCard
                                    title={'Deals Closed This Month'}
                                    amount={
                                        dealsClosedThisMonth ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    chart={<DefaultOrderChart />}
                                    percentChange={
                                        dealsClosedThisMonthPercent ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    up={
                                        Number(dealsClosedThisMonthPercent) >= 0
                                    }
                                    comparisonText="last month"
                                />
                            </div>
                        </Col>

                        {/* PERFORMANCE METRICS */}
                        <h3>Performance Metrics</h3>
                        <Col sm="6">
                            <RenderChart
                                chartTitle={'Leads Created'}
                                chart={
                                    <MultiBarsBarChart
                                        data={{
                                            labels:
                                                dailyleadsCreated &&
                                                dailyleadsCreated?.length
                                                    ? dailyleadsCreated?.map(
                                                          (item) => item?.date
                                                      )
                                                    : updateLabels(),
                                            datasets: [
                                                {
                                                    data: dailyleadsCreated?.map(
                                                        (item) => item?.count
                                                    ),
                                                    borderColor:
                                                        'rgb(25, 250, 133)',
                                                    backgroundColor:
                                                        'rgb(25, 250, 133)'
                                                }
                                            ]
                                        }}
                                    />
                                }
                                chartLabelTitles={
                                    <>
                                        <li>
                                            <div className="title">
                                                <span
                                                    className="dot dot-lg sq"
                                                    style={{
                                                        background:
                                                            'rgb(25, 250, 133)'
                                                    }}
                                                ></span>
                                                <span>
                                                    Leads Created{' '}
                                                    {dayEnd - dayStart <= 0
                                                        ? 'This Week'
                                                        : 'This Period'}
                                                </span>
                                            </div>
                                        </li>
                                    </>
                                }
                                // chartFooter={
                                //     <span className="text-center w-100">
                                //         {`${getDayName(startOfWeek(dayStart))} >>>>>
                                //         ${getDayName(endOfWeek(dayEnd))}`}
                                //     </span>
                                // }
                            />
                        </Col>
                        <Col sm="6">
                            <RenderChart
                                chartTitle={'Leads Done'}
                                chart={
                                    <MultiBarsBarChart
                                        data={{
                                            labels:
                                                dailyLeadsDone &&
                                                dailyLeadsDone?.length
                                                    ? dailyLeadsDone?.map(
                                                          (item) => item?.date
                                                      )
                                                    : updateLabels(),
                                            datasets: [
                                                {
                                                    data: dailyLeadsDone?.map(
                                                        (item) => item?.count
                                                    ),
                                                    borderColor: '#9d72ff',
                                                    backgroundColor: '#9472ff'
                                                }
                                            ]
                                        }}
                                    />
                                }
                                chartLabelTitles={
                                    <>
                                        <li>
                                            <div className="title">
                                                <span
                                                    className="dot dot-lg sq"
                                                    style={{
                                                        background: '#9d72ff'
                                                    }}
                                                ></span>
                                                <span>
                                                    Leads Done{' '}
                                                    {dayEnd - dayStart <= 0
                                                        ? 'This Week'
                                                        : 'This Period'}
                                                </span>
                                            </div>
                                        </li>
                                    </>
                                }
                                // chartFooter={
                                //     <span className="text-center w-100">
                                //         {`${getDayName(startOfWeek(dayStart))} >>>>>
                                //         ${getDayName(endOfWeek(dayEnd))}`}
                                //     </span>
                                // }
                            />
                        </Col>
                        <Col sm="6">
                            <RenderChart
                                chartTitle={'Listings Acquired / Deals Closed'}
                                chart={
                                    <MultiBarsBarChart
                                        data={{
                                            labels:
                                                dailyDeals && dailyDeals?.length
                                                    ? dailyDeals?.map(
                                                          (item) => item?.date
                                                      )
                                                    : dailyListings &&
                                                      dailyListings?.length
                                                    ? dailyListings?.map(
                                                          (item) => item?.date
                                                      )
                                                    : updateLabels(),
                                            datasets: [
                                                {
                                                    data: dailyDeals?.map(
                                                        (item) => item?.count
                                                    ),
                                                    borderColor: '#9BA98E',
                                                    backgroundColor: '#9BA98E'
                                                },
                                                {
                                                    data: dailyListings?.map(
                                                        (item) => item?.count
                                                    ),
                                                    borderColor: '#89B9E0',
                                                    backgroundColor: '#89B9E0'
                                                }
                                            ]
                                        }}
                                    />
                                }
                                chartLabelTitles={
                                    <>
                                        <li>
                                            <div className="title">
                                                <span
                                                    className="dot dot-lg sq"
                                                    style={{
                                                        background: '#9BA98E'
                                                    }}
                                                ></span>
                                                <span>Deals Closed</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="title">
                                                <span
                                                    className="dot dot-lg sq"
                                                    style={{
                                                        background: '#89B9E0'
                                                    }}
                                                ></span>
                                                <span>Listings Acquired</span>
                                            </div>
                                        </li>
                                    </>
                                }
                                // chartFooter={
                                //     <span className="text-center w-100">
                                //         {`${getDayName(startOfWeek(dayStart))} >>>>>
                                //         ${getDayName(endOfWeek(dayEnd))}`}
                                //     </span>
                                // }
                            />
                        </Col>
                        <Col sm="6">
                            <RenderChart
                                chartTitle={'Follow-ups Done / Overdue Tasks'}
                                chart={
                                    <MultiBarsBarChart
                                        data={{
                                            labels:
                                                dailyFollowUps &&
                                                dailyFollowUps?.length
                                                    ? dailyFollowUps?.map(
                                                          (item) => item?.date
                                                      )
                                                    : dailyOverdueTasks &&
                                                      dailyOverdueTasks?.length
                                                    ? dailyOverdueTasks?.map(
                                                          (item) => item?.date
                                                      )
                                                    : updateLabels(),
                                            datasets: [
                                                {
                                                    data: dailyFollowUps?.map(
                                                        (item) => item?.count
                                                    ),
                                                    borderColor: '#9d72ff',
                                                    backgroundColor: '#9472ff'
                                                },
                                                {
                                                    data: dailyOverdueTasks?.map(
                                                        (item) => item?.count
                                                    ),
                                                    borderColor: '#eb6459',
                                                    backgroundColor: '#eb6459'
                                                }
                                            ]
                                        }}
                                    />
                                }
                                chartLabelTitles={
                                    <>
                                        <li>
                                            <div className="title">
                                                <span
                                                    className="dot dot-lg sq"
                                                    style={{
                                                        background: '#9d72ff'
                                                    }}
                                                ></span>
                                                <span>Follow-ups Done</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="title">
                                                <span
                                                    className="dot dot-lg sq"
                                                    style={{
                                                        background: '#eb6459'
                                                    }}
                                                ></span>
                                                <span>Overdue Tasks</span>
                                            </div>
                                        </li>
                                    </>
                                }
                                // chartFooter={
                                //     <span className="text-center w-100">
                                //         {`${getDayName(startOfWeek(dayStart))} >>>>>
                                //         ${getDayName(endOfWeek(dayEnd))}`}
                                //     </span>
                                // }
                            />
                        </Col>
                        <Col md="6" xl="8">
                            <DealsClosedGrid
                                title="Listings Acquired This Week"
                                deals={listingsAcquiredThisWeek}
                                fetching={!listingsAcquiredThisWeek}
                                noDataText="No recently acquired listings"
                                link="/list-deals"
                                linkText="View Listings"
                            />
                        </Col>

                        <Col md="6" xl="4">
                            <DealsStatistics
                                dealsClosed={dealsClosed
                                    ?.map((item) => item?.agreed_salePrice)
                                    ?.reduce((a, b) => a + b, 0)}
                                commissionsEarned={dealsClosed
                                    ?.map((deal) => {
                                        const sellerComm =
                                            ((deal?.agreed_salePrice ?? 0) *
                                                (deal?.seller_agreedCommission ??
                                                    0)) /
                                            100;

                                        const buyerComm =
                                            ((deal?.agreed_salePrice ?? 0) *
                                                (deal?.buyer_agreedCommission ??
                                                    0)) /
                                            100;

                                        const comm =
                                            ((deal?.agreed_salePrice ?? 0) *
                                                (deal?.agreed_commission ??
                                                    0)) /
                                            100;

                                        const commission =
                                            sellerComm + buyerComm + comm;

                                        return commission;
                                    })
                                    ?.reduce((a, b) => a + b, 0)}
                                numberOfDealsClosed={dealsClosed?.length ?? 0}
                                dealsInNegotiation={dealsPendingThisMonth
                                    ?.map((item) => item?.agreed_salePrice ?? 0)
                                    ?.reduce(
                                        (a, b) => Number(a) + Number(b),
                                        0
                                    )}
                                numberOfDealsInNegotiation={
                                    dealsPendingThisMonth?.length ?? 0
                                }
                            />
                        </Col>

                        <div className="filters month-filters">
                            <button
                                className="text-white p-1 bg-primary"
                                onClick={() => {
                                    // setMonthEnd(endOfMonth(today));
                                    setMonthStart(startOfMonth(today));
                                    // setCurrentMonth(true)
                                }}
                            >
                                Current Month
                            </button>
                            <button
                                className="text-white p-1 bg-secondary"
                                onClick={() => {
                                    // setMonthEnd(
                                    //     endOfMonth(subMonths(today, 1))
                                    // );
                                    setMonthStart(
                                        startOfMonth(subMonths(today, 1))
                                    );
                                    // setCurrentMonth()
                                }}
                            >
                                Last Month
                            </button>
                            <MonthPicker
                                year={monthStart?.getFullYear()}
                                month={monthStart.getMonth()}
                                onChange={function (
                                    maskedValue,
                                    selectedYear,
                                    selectedMonth
                                ) {
                                    const monthStart = new Date(
                                        `${selectedYear}-${
                                            selectedMonth + 1 > 9
                                                ? selectedMonth + 1
                                                : `0${selectedMonth + 1}`
                                        }-01`
                                    );

                                    setMonthStart(monthStart);
                                    // setMonthEnd(endOfMonth(monthStart));
                                }}
                                mode="calendarOnly"
                                closeOnSelect
                                inputProps={{
                                    className: 'month-picker p-1'
                                }}
                            />
                        </div>

                        <Col sm="12" md="6">
                            <DealsClosedGrid
                                fetching={!dealsClosed}
                                deals={dealsClosed}
                            />
                        </Col>
                        <Col sm="12" md="6">
                            <DealsPendingGrid
                                deals={dealsPendingThisMonth ?? []}
                                fetching={!dealsPendingThisMonth}
                            />
                        </Col>

                        <Col sm="12">
                            <ClientsAcquired
                                clients={
                                    clientsThisMonth?.map(
                                        (item) => item?.user
                                    ) ?? []
                                }
                                fetching={!clientsThisMonth}
                                title="Clients Acquired This Month"
                            />
                        </Col>
                    </Row>

                    {/* End agents productivity report */}

                    {/* <Row className="g-gs"></Row> */}
                </>
            </Content>
        </React.Fragment>
    );
}
