import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import DatePicker from "react-datepicker";
import {
  Modal,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Row,
  Label,
  Col,
} from "reactstrap";
import {
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Block,
  RSelect,
} from "../../components/Component";
import {
  KanbanBoardForm,
  KanbanTaskForm,
  NewKanbanTaskForm,
} from "../components/kanban/KanbanForms";
import { MyKanbanCard } from "../components/kanban/KanbanPartials";
import { fetchAgents } from "../../redux/actions/user";
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import { toast } from "react-toastify";
import {
  ActivityStatus,
  LeadStatus,
  newRoles,
  LeadLabels,
} from "../../utils/envConfig";
import { endOfDay, startOfDay, subDays } from "date-fns";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { interactionSettingsStore } from "@fullcalendar/core";
const StatusBoard = () => {
  const dispatch = useDispatch();
  const agent = useSelector((state) => state.user?.agents);

  const navigate = useNavigate();
  const authuser = useSelector((state) => state?.user?.loggedInUser);
  // localstorage
  const startDateGet = localStorage.getItem("leadBoardStartDateGet") || "";
  const endDateGet = localStorage.getItem("leadBoardEndDateGet") || "";
  const filterLeadSourceGet =
    localStorage.getItem("leadBoardFilterLeadSourceGetValue") || "";
  const leadsAgentGet = localStorage.getItem("leadsBoardAgentGetValue") || "";
  const leadsAgentGetObj = localStorage.getItem("leadsBoardAgentGetObj") || "";
  const leadsLabelGetObj = localStorage.getItem("leadsBoardLabelGetObj") || "";
  const leadsLabelGetOption =
    localStorage.getItem("leadsBoardLabelGetOption") || "";

  //   const [ columns, setColumns ] = useState( columnData );
  const [boardModal, setBoardModal] = useState(false);
  const [smBtn, setSmBtn] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  // Constants
  // Validate User Token

  const axios = useAxiosPrivate();

  const { access_token } =
  useSelector((state) => state?.user?.loggedInUser) || "";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };

  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [agentId, setAgentId] = useState(null);
  const [optAgents, setOptAgents] = useState([]);
  const [optDefaultAgent, setOptDefaultAgent] = useState({
    label: "Select Agent",
    value: null,
  });
  const [filterAgent, setFilterAgent] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);
  const [search, setSearch] = useState(null);
  const [filterSearch, setFilterSearch] = useState(null);
  const [filterLeadLabel, setFilterLeadLabel] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [labelUnassigned, setLabelUnassigned] = useState([]);
  const [labelFresh, setLabelFresh] = useState([]);
  const [labelFollowUp, setLabelFollowUp] = useState([]);
  const [labelProspect, setLabelProspect] = useState([]);
  const [labelForListing, setLabelForListing] = useState([]);
  const [labelAppointment, setLabelAppointment] = useState([]);
  const [labelViewing, setLabelViewing] = useState([]);
  const [labelNegotiating, setLabelNegotiating] = useState([]);
  const [labelDeal, setLabelDeal] = useState([]);
  const [labelWon, setLabelWon] = useState([]);
  const [labelLost, setLabelLost] = useState([]);
  const [labelDisqualified, setLabelDisqualified] = useState([]);
  const [countUnassigned, setCountUnassigned] = useState(0);
  const [countFresh, setCountFresh] = useState(0);
  const [countFollowUp, setCountFollowUp] = useState(0);
  const [countProspect, setCountProspect] = useState(0);
  const [countForListing, setCountForListing] = useState(0);
  const [countAppointment, setCountAppointment] = useState(0);
  const [countViewing, setCountViewing] = useState(0);
  const [countNegotiating, setCountNegotiating] = useState(0);
  const [countDeal, setCountDeal] = useState(0);
  const [countWon, setCountWon] = useState(0);
  const [countLost, setCountLost] = useState(0);
  const [countDisqualified, setCountDisqualified] = useState(0);
  const [scrollLabel, setScrollLabel] = useState(null);
  const [dateValue, setDateValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [rangeStart, setRangeStart] = useState();
  const [rangeEnd, setRangeEnd] = useState();
  const [leadSource, setLeadSource] = useState(null);
  const [optLeadSource, setOptLeadSource] = useState(null);
  const [selectedOptLeadSource, setSelectedOptLeadSource] = useState(null);
  const [filterLeadSource, setFilterLeadSource] = useState(null);
  const [filterLabel, setFilterLabel] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [temporaryLabel, setTemporaryLabel] = useState(null);

  const initialSortOrders = {
    Unassigned: "",
    Fresh: "",
    Followup: "",
    ForListing: "",
    Prospect: "",
    Appointment: "",
    Viewing: "",
    Negotiating: "",
    Deal: "",
    Won: "",
    Lost: "",
    Disqualified: "",
  };

  const [sortOrders, setSortOrders] = useState(initialSortOrders);

  const sortByOverDue = (col) => {
    const formattedCol = col.replace(/\s/g, "");
    setSortOrders((prevSortOrders) => ({
      ...prevSortOrders,
      [formattedCol]: (prevSortOrders[formattedCol] = "desc"),
    }));
  };

  const sortByMostRecent = (col) => {
    const formattedCol = col.replace(/\s/g, "");
    setSortOrders((prevSortOrders) => ({
      ...prevSortOrders,
      [formattedCol]: (prevSortOrders[formattedCol] = "asc"),
    }));
  };

  // const sortByOverDue = (col) =>{
  //   console.log(col);
  //   if(col==="Unassigned"){
  //     setSortOrderUnassigned("desc");
  //   }else if(col==="Fresh"){
  //     setSortOrderFresh("desc");
  //   }
  //   };

  useEffect(() => {
    setLabelUnassigned([]);
    setLabelFresh([]);
    setLabelFollowUp([]);
    setLabelForListing([]);
    setLabelAppointment([]);
    setLabelViewing([]);
    setLabelNegotiating([]);
    setLabelDeal([]);
    setLabelWon([]);
    setLabelLost([]);
    setLabelDisqualified([]);
    setCountUnassigned(0);
    setCountFresh(0);
    setCountFollowUp(0);
    setCountForListing(0);
    setCountAppointment(0);
    setCountViewing(0);
    setCountNegotiating(0);
    setCountDeal(0);
    setCountWon(0);
    setCountLost(0);
    setCountDisqualified(0);
    setCurrentPage(1);
    setDateValue({
      startDate: rangeStart?.toISOString(),
      endDate: rangeEnd?.toISOString(),
    });
  }, [rangeStart, rangeEnd]);
  // Fetch User
  async function fetchUser(heading) {
    try {
      const today = new Date();
      const perPage = 10;

      let hotLeadSourceNames = await getHotLeads();
      let link = `/search/kanban?roles[]=${newRoles.User}&roles[]=${newRoles.Owner}&page=${currentPage}&per_page=${perPage}&lead_status=${heading}&withAgent=true&withActivities=true&active=true`;

      if (dateValue.startDate && dateValue.endDate) {
        const fromDate = startOfDay(
          new Date(dateValue.startDate)
        ).toISOString();
        const toDate = endOfDay(new Date(dateValue.endDate)).toISOString();
        link += `&from_date=${fromDate}&to_date=${toDate}`;
      } else if (startDateGet && endDateGet) {
        const fromDate = startOfDay(new Date(startDateGet)).toISOString();
        const toDate = endOfDay(new Date(endDateGet)).toISOString();
        link += `&from_date=${fromDate}&to_date=${toDate}`;
      } else {
        const fromDate = startOfDay(new Date(subDays(today, 15))).toISOString();
        const toDate = endOfDay(new Date(today)).toISOString();
        link += `&from_date=${fromDate}&to_date=${toDate}`;
      }

      if (filterAgent) {
        link = link.concat(`&agent_id=${filterAgent}`);
      } else if (leadsAgentGet) {
        link = link.concat(`&agent_id=${leadsAgentGet}`);
      }

      if (filterSearch)
        link = link.concat(
          `&search=${encodeURIComponent(filterSearch.trim())}`
        );

      if (filterLeadSource) {
        link = link.concat(`&lead_source_many=${filterLeadSource?.join()}`);
      } else if (!filterLeadSource) {
        if (filterLeadSourceGet !== "") {
          const leadSourceArray = JSON.parse(filterLeadSourceGet);

          leadSourceArray?.forEach((lead) => {
            link = link.concat(`&lead_source_many=${lead.label}`);
          });

          setSelectedOptions(leadSourceArray);
        } else if (leadsLabelGetObj !== "") {
          link = link.concat(`&lead_source_many=${leadsLabelGetObj}`);
        }
      }

      if (filterLeadLabel === LeadLabels.Interested) {
        link = link.concat(`&withIsInterested=true`);
      }

      // let leadSourceQuery =
      //   filterLeadSource && filterLeadSource[0]?.map(({ label }) => label);
      // if (filterLeadSource)
      //   link = link.concat(`&lead_source_many=${leadSourceQuery?.join()}`);

      // Filter by Hot Lead and Cold Lead
      if (filterLabel === LeadLabels.HotLead) console.log("--H--");

      // Filter by Cold Lead
      if (filterLabel === LeadLabels.ColdLead) console.log("--C--");

      // Filter by Interested
      if (filterLabel === LeadLabels.Interested) console.log("--I--");
      // link = link.concat(`&is_interested=true`);

      const res = await axios.get(link);

      let result = {};
      result.findUsersOutput = res.data.findUsersOutput;
      result.filteredCount = res.data.filteredCount;
      result.searchPerPage = res.data.searchPerPage;
      result.ListingCount = res.data.ListingCount;

      //Sorting by hot leads first
      // result.findUsersOutput.sort( ( a, b ) =>
      // {
      //   if (
      //     hotLeadSourceNames?.indexOf( a?.lead_source ) >= 0 &&
      //     hotLeadSourceNames?.indexOf( b?.lead_source ) < 0
      //   )
      //   {
      //     return -1;
      //   } else if (
      //     hotLeadSourceNames?.indexOf( a?.lead_source ) < 0 &&
      //     hotLeadSourceNames?.indexOf( b?.lead_source ) >= 0
      //   )
      //   {
      //     return 1;
      //   } else
      //   {
      //     return 0;
      //   }
      // } );

      //Sorting in order of activity status
      // const statusOrder = [
      //   ActivityStatus.Overdue,
      //   ActivityStatus.Pending,
      //   ActivityStatus.Complete,
      // ];

      // result.findUsersOutput.sort( ( a, b ) =>
      // {
      //   const aStatus = statusOrder.indexOf( a.activities[ 0 ].status );
      //   const bStatus = statusOrder.indexOf( b.activities[ 0 ].status );
      //   return aStatus - bStatus;
      // } );

      return result;
    } catch (error) {
      // console.log(err)
      throw error;
    }
  }

  // Get User
  async function getUser(heading) {
    if (!heading) return;
    try {
      if (heading === LeadStatus.Unassigned) {
        const result1 = await fetchUser(heading);
        setLabelUnassigned([...labelUnassigned, ...result1.findUsersOutput]);
        setCountUnassigned(result1.filteredCount);
        setItemsPerPage(result1.searchPerPage);
      }
      if (heading === LeadStatus.Fresh) {
        const result2 = await fetchUser(heading);
        setLabelFresh([...labelFresh, ...result2.findUsersOutput]);
        setCountFresh(result2.filteredCount);
        setItemsPerPage(result2.searchPerPage);
      }
      if (heading === LeadStatus.FollowUp) {
        const result3 = await fetchUser(heading);
        setLabelFollowUp([...labelFollowUp, ...result3.findUsersOutput]);
        setCountFollowUp(result3.filteredCount);
        setItemsPerPage(result3.searchPerPage);
      }
      if (heading === LeadStatus.Prospect) {
        const result11 = await fetchUser(heading);
        setLabelProspect([...labelProspect, ...result11.findUsersOutput]);
        setCountProspect(result11.filteredCount);
        setItemsPerPage(result11.searchPerPage);
      }
      if (heading === LeadStatus.ForListing) {
        const result10 = await fetchUser(heading);
        setLabelForListing([...labelForListing, ...result10.findUsersOutput]);
        setCountForListing(result10.filteredCount);
        setItemsPerPage(result10.searchPerPage);
      }
      if (heading === LeadStatus.Appointment) {
        const result4 = await fetchUser(heading);
        setLabelAppointment([...labelAppointment, ...result4.findUsersOutput]);
        setCountAppointment(result4.filteredCount);
        setItemsPerPage(result4.searchPerPage);
      }
      if (heading === LeadStatus.Viewing) {
        const result5 = await fetchUser(heading);
        setLabelViewing([...labelViewing, ...result5.findUsersOutput]);
        setCountViewing(result5.filteredCount);
        setItemsPerPage(result5.searchPerPage);
      }
      if (heading === LeadStatus.Negotiating) {
        const result12 = await fetchUser(heading);
        setLabelNegotiating([...labelNegotiating, ...result12.findUsersOutput]);
        setCountNegotiating(result12.filteredCount);
        setItemsPerPage(result12.searchPerPage);
      }
      if (heading === LeadStatus.Deal) {
        const result6 = await fetchUser(heading);
        setLabelDeal([...labelDeal, ...result6.findUsersOutput]);
        setCountDeal(result6.filteredCount);
        setItemsPerPage(result6.searchPerPage);
      }
      if (heading === LeadStatus.Won) {
        const result9 = await fetchUser(heading);
        setLabelWon([...labelLost, ...result9.findUsersOutput]);
        setCountWon(result9.filteredCount);
        setItemsPerPage(result9.searchPerPage);
      }
      if (heading === LeadStatus.Lost) {
        const result7 = await fetchUser(heading);
        setLabelLost([...labelLost, ...result7.findUsersOutput]);
        setCountLost(result7.filteredCount);
        setItemsPerPage(result7.searchPerPage);
      }
      if (heading === LeadStatus.Disqualified) {
        const result8 = await fetchUser(heading);
        setLabelDisqualified([
          ...labelDisqualified,
          ...result8.findUsersOutput,
        ]);
        setCountDisqualified(result8.filteredCount);
        setItemsPerPage(result8.searchPerPage);
      }
    } catch (err) {
      // console.log(err)
      // toast.error( "Server error. Try Again !" );
      console.log(err);
    }
  }

  // Get User Filtered
  async function getUserFiltered(heading) {
    if (!heading) return;
    try {
      if (heading === LeadStatus.Unassigned) {
        const result1 = await fetchUser(heading);
        setLabelUnassigned([...labelUnassigned, ...result1.findUsersOutput]);
        setCountUnassigned(result1.filteredCount);
        setItemsPerPage(result1.searchPerPage);
      }
      if (heading === LeadStatus.Fresh) {
        const result2 = await fetchUser(heading);
        setLabelFresh([...labelFresh, ...result2.findUsersOutput]);
        setCountFresh(result2.filteredCount);
        setItemsPerPage(result2.searchPerPage);
      }
      if (heading === LeadStatus.FollowUp) {
        const result3 = await fetchUser(heading);
        setLabelFollowUp([...labelFollowUp, ...result3.findUsersOutput]);
        setCountFollowUp(result3.filteredCount);
        setItemsPerPage(result3.searchPerPage);
      }
      if (heading === LeadStatus.Prospect) {
        const result11 = await fetchUser(heading);
        setLabelProspect([...labelProspect, ...result11.findUsersOutput]);
        setCountProspect(result11.filteredCount);
        setItemsPerPage(result11.searchPerPage);
      }
      if (heading === LeadStatus.ForListing) {
        const result10 = await fetchUser(heading);
        setLabelForListing([...labelForListing, ...result10.findUsersOutput]);
        setCountForListing(result10.filteredCount);
        setItemsPerPage(result10.searchPerPage);
      }
      if (heading === LeadStatus.Appointment) {
        const result4 = await fetchUser(heading);
        setLabelAppointment([...labelAppointment, ...result4.findUsersOutput]);
        setCountAppointment(result4.filteredCount);
        setItemsPerPage(result4.searchPerPage);
      }
      if (heading === LeadStatus.Viewing) {
        const result5 = await fetchUser(heading);
        setLabelViewing([...labelViewing, ...result5.findUsersOutput]);
        setCountViewing(result5.filteredCount);
        setItemsPerPage(result5.searchPerPage);
      }
      if (heading === LeadStatus.Negotiating) {
        const result12 = await fetchUser(heading);
        setLabelNegotiating([...labelNegotiating, ...result12.findUsersOutput]);
        setCountNegotiating(result12.filteredCount);
        setItemsPerPage(result12.searchPerPage);
      }
      if (heading === LeadStatus.Deal) {
        const result6 = await fetchUser(heading);
        setLabelDeal([...labelDeal, ...result6.findUsersOutput]);
        setCountDeal(result6.filteredCount);
        setItemsPerPage(result6.searchPerPage);
      }
      if (heading === LeadStatus.Won) {
        const result9 = await fetchUser(heading);
        setLabelWon([...labelLost, ...result9.findUsersOutput]);
        setCountWon(result9.filteredCount);
        setItemsPerPage(result9.searchPerPage);
      }
      if (heading === LeadStatus.Lost) {
        const result7 = await fetchUser(heading);
        setLabelLost([...labelLost, ...result7.findUsersOutput]);
        setCountLost(result7.filteredCount);
        setItemsPerPage(result7.searchPerPage);
      }
      if (heading === LeadStatus.Disqualified) {
        const result8 = await fetchUser(heading);
        setLabelDisqualified([
          ...labelDisqualified,
          ...result8.findUsersOutput,
        ]);
        setCountDisqualified(result8.filteredCount);
        setItemsPerPage(result8.searchPerPage);
      }
    } catch (err) {
      console.log(err);
      // toast.error( "Server error. Try Again !" );
    }
  }

  const getHotLeads = async () => {
    let link = `/leadSources/hotleads`;
    const res = await axios.get(link);
    const result = res?.data?.data;
    let names = result.map((item) => item.name);
    return names;
  };

  useEffect(() => {
    setLoading(false);
    const boardStatus = [];
    LeadStatus &&
      Object.keys(LeadStatus).map((a, index) =>
        boardStatus.push(LeadStatus[a])
      );
    setColumns(boardStatus);
    setLoading(true);
  }, []);

  useEffect(() => {
    setLoading(false);

    if (!isFiltering) {
      getUser(LeadStatus.Unassigned);
      getUser(LeadStatus.Fresh);
      getUser(LeadStatus.FollowUp);
      getUser(LeadStatus.Prospect);
      getUser(LeadStatus.ForListing);
      getUser(LeadStatus.Appointment);
      getUser(LeadStatus.Viewing);
      getUser(LeadStatus.Negotiating);
      getUser(LeadStatus.Deal);
      getUser(LeadStatus.Won);
      getUser(LeadStatus.Lost);
      getUser(LeadStatus.Disqualified);
    } else {
      getUserFiltered(LeadStatus.Unassigned);
      getUserFiltered(LeadStatus.Fresh);
      getUserFiltered(LeadStatus.FollowUp);
      getUserFiltered(LeadStatus.Prospect);
      getUserFiltered(LeadStatus.ForListing);
      getUserFiltered(LeadStatus.Appointment);
      getUserFiltered(LeadStatus.Viewing);
      getUserFiltered(LeadStatus.Negotiating);
      getUserFiltered(LeadStatus.Deal);
      getUserFiltered(LeadStatus.Won);
      getUserFiltered(LeadStatus.Lost);
      getUserFiltered(LeadStatus.Disqualified);
    }
    setLoading(true);
  }, [
    filterSearch,
    filterLeadSource,
    filterAgent,
    filterLabel,
    isFiltering,
    dateValue,
  ]);

  useEffect(() => {
    if (!itemsPerPage) {
      return;
    }
    setLoading(false);
    if (scrollLabel === LeadStatus.Unassigned) {
      if (!isFiltering) {
        getUser(LeadStatus.Unassigned);
      } else {
        getUserFiltered(LeadStatus.Unassigned);
      }
    }
    if (scrollLabel === LeadStatus.Fresh) {
      if (!isFiltering) {
        getUser(LeadStatus.Fresh);
      } else {
        getUserFiltered(LeadStatus.Fresh);
      }
    }
    if (scrollLabel === LeadStatus.FollowUp) {
      if (!isFiltering) {
        getUser(LeadStatus.FollowUp);
      } else {
        getUserFiltered(LeadStatus.FollowUp);
      }
    }
    if (scrollLabel === LeadStatus.ForListing) {
      if (!isFiltering) {
        getUser(LeadStatus.ForListing);
      } else {
        getUserFiltered(LeadStatus.ForListing);
      }
    }
    if (scrollLabel === LeadStatus.Prospect) {
      if (!isFiltering) {
        getUser(LeadStatus.Prospect);
      } else {
        getUserFiltered(LeadStatus.Prospect);
      }
    }
    if (scrollLabel === LeadStatus.Appointment) {
      if (!isFiltering) {
        getUser(LeadStatus.Appointment);
      } else {
        getUserFiltered(LeadStatus.Appointment);
      }
    }
    if (scrollLabel === LeadStatus.Viewing) {
      if (!isFiltering) {
        getUser(LeadStatus.Viewing);
      } else {
        getUserFiltered(LeadStatus.Viewing);
      }
    }
    if (scrollLabel === LeadStatus.Negotiating) {
      if (!isFiltering) {
        getUser(LeadStatus.Negotiating);
      } else {
        getUserFiltered(LeadStatus.Negotiating);
      }
    }
    if (scrollLabel === LeadStatus.Deal) {
      if (!isFiltering) {
        getUser(LeadStatus.Deal);
      } else {
        getUserFiltered(LeadStatus.Deal);
      }
    }
    if (scrollLabel === LeadStatus.Won) {
      if (!isFiltering) {
        getUser(LeadStatus.Won);
      } else {
        getUserFiltered(LeadStatus.Won);
      }
    }
    if (scrollLabel === LeadStatus.Lost) {
      if (!isFiltering) {
        getUser(LeadStatus.Lost);
      } else {
        getUserFiltered(LeadStatus.Lost);
      }
    }
    if (scrollLabel === LeadStatus.Disqualified) {
      if (!isFiltering) {
        getUser(LeadStatus.Disqualified);
      } else {
        getUserFiltered(LeadStatus.Disqualified);
      }
    }
    setLoading(true);
  }, [currentPage]);
  useEffect(() => {
    let options = [];
    let result =
      agent &&
      agent?.length > 0 &&
      agent
        ?.filter((agents) => agents?.user?.active === true)
        ?.map((a) =>
          options.push({
            value: a?.id,
            label: a?.user?.first_name + " " + a?.user?.last_name,
          })
        );
    setOptAgents(options);
  }, [agent]);

  useEffect(() => {
    async function fetchLeadSource() {
      try {
        const res = await axios.get(`/leadSources`);
        const result = res.data;
        setLeadSource(result);

        let opt = [];
        result &&
          result.map((source) =>
            opt.push({
              value: source.id,
              label: source.name,
            })
          );
        setOptLeadSource(opt);
      } catch (err) {
        //console.log( err );
      }
    }
    fetchLeadSource();
  }, []);

  const clearFilters = () => {
    setLabelUnassigned([]);
    setLabelFresh([]);
    setLabelFollowUp([]);
    setLabelProspect([]);
    setLabelForListing([]);
    setLabelAppointment([]);
    setLabelViewing([]);
    setLabelNegotiating([]);
    setLabelDeal([]);
    setLabelWon([]);
    setLabelLost([]);
    setLabelDisqualified([]);
    setCountUnassigned(0);
    setCountFresh(0);
    setCountFollowUp(0);
    setCountProspect(0);
    setCountForListing(0);
    setCountAppointment(0);
    setCountViewing(0);
    setCountNegotiating(0);
    setCountDeal(0);
    setCountWon(0);
    setCountLost(0);
    setCountDisqualified(0);
    setCurrentPage(1);
  };
  const handleBadgeRemoveAndLeadSource = (option) => {
    const updatedOptions = selectedOptions.filter(
      (o) => o.value !== option.value
    );
    setSelectedOptions(updatedOptions);

    // Filter data
    clearFilters();
    if (updatedOptions) {
      let lg = [];
      lg.push(updatedOptions);
      let lgFilter = lg && lg[0]?.map(({ label }) => label);
      setFilterLeadSource(lgFilter);

      const lgString = JSON.stringify(lg[0]);
      localStorage.setItem("leadsBoardSourceGetValue", lgString);
      localStorage.setItem("leadBoardFilterLeadSourceGetValue", lgString);
    } else {
      if (filterSearch) {
        setIsFiltering(!!filterSearch);
      } else {
        setIsFiltering(false);
      }
      setOptLeadSource({
        label: "Select ",
        value: null,
      });
      setFilterLeadSource(null);
    }
  };

  const handleLeadSource = (e) => {
    setSelectedOptions(e);
    setSelectedOptLeadSource(e);
    clearFilters();
    if (e) {
      let lg = [];
      lg.push(e);
      let lgFilter = lg && lg[0]?.map(({ label }) => label);
      setFilterLeadSource(lgFilter);

      const lgString = JSON.stringify(lg[0]);
      localStorage.setItem("leadsBoardSourceGetValue", lgString);
      localStorage.setItem("leadBoardFilterLeadSourceGetValue", lgString);
    } else {
      setFilterLeadSource(null);
    }
    setTemporaryLabel("Filter by Label");
    localStorage.setItem("leadsBoardLabelGetObj", "");
    localStorage.setItem("leadsBoardLabelGetOption", "");
  };

  const CustomMultiValueLabel = ({ data, ...props }) => (
    <div className="selected-option" {...props}>
      {data.label}
    </div>
  );
  const handleAgentOption = (e) => {
    clearFilters();
    if (e) {
      let lg = [];
      lg.push(e);
      setOptDefaultAgent(lg);
      setFilterAgent(e.value);
      setIsFiltering(!!e.value);
    } else {
      if (filterSearch) {
        setIsFiltering(!!filterSearch);
      } else {
        setIsFiltering(false);
      }
      setOptDefaultAgent({
        label: "Select Agent",
        value: null,
      });
      setFilterAgent(null);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (search !== filterSearch) {
      clearFilters();
      if (search) {
        setFilterSearch(search);
        setIsFiltering(!!search);
      } else {
        if (filterAgent) {
          setIsFiltering(!!filterAgent);
        } else {
          setIsFiltering(false);
        }
        setFilterSearch(null);
        setSearch(null);
      }
    }
  };

  const onChangeSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const handleColumnScroll = (e, col) => {
    e.preventDefault();
    const element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setCurrentPage((prevState) => prevState + 1);
      setScrollLabel(col);
    }
  };
  const loadMore = (e, col) => {
    e.preventDefault();
    setCurrentPage((prevState) => prevState + 1);
    setScrollLabel(col);
  };

  const handleDateValueChange = (newValue) => {
    clearFilters();

    setDateValue(newValue);
    // setIsFiltering(!!newValue.startDate || !!newValue.endDate)
  };

  const handleLeadLabelOption1 = async (leadLabel) => {
    clearFilters();


    let filterLeadSource = [];
    let localStorageLabelKey = "leadsBoardLabelGetObj";

    switch (leadLabel) {
      case "ColdLead":
        const coldLeadRes = await axios.get(`/leadSources/coldleads`, headers);
        filterLeadSource = coldLeadRes?.data?.data?.map((source) => source.name);
        break;

      case "HotLead":
        const hotLeadRes = await axios.get(`/leadSources/hotleads`, headers);
        filterLeadSource = hotLeadRes?.data?.data?.map((source) => source.name);
        break;

      default:
        break;
    }

    setSelectedOptions(null);
    setFilterLeadSource(filterLeadSource);
    setTemporaryLabel(null);
    setFilterLeadLabel(leadLabel);
    localStorage.setItem("leadsBoardLabelGetOption", leadLabel);
    localStorage.setItem("leadsBoardSourceGetValue", "");
    localStorage.setItem("leadBoardFilterLeadSourceGetValue", "");
    localStorage.setItem(localStorageLabelKey, filterLeadSource);
  };

  const handleLeadLabelOption = (e, x) => {
    e.preventDefault();

    clearFilters();

    if (x) {
      setFilterLabel(x);
      setIsFiltering(!!x);
    } else {
      setFilterLabel(null);
    }
  };

  useEffect(() => {
    dispatch(fetchAgents(axios));
  }, [dispatch]);

  const toggleBoardModal = () => {
    setBoardModal(!boardModal);
  };

  const toggleTaskModal = () => {
    setTaskModal(!taskModal);
  };
  const animatedComponents = makeAnimated();

  let clearBadge =
    startDateGet != "" ||
    endDateGet != "" ||
    selectedOptions != "" ||
    leadsAgentGetObj != "" ||
    leadsLabelGetOption != "";
  const [maxHeight, setMaxHeight] = useState(""); // Initial value

  useEffect(() => {
    // Update maxHeight based on half the screen height
    const updateMaxHeight = () => {
      const screenHeight = window.innerHeight;
      setMaxHeight(`${(screenHeight * 2) / 3}px`);
    };

    // Initial update and attach resize event listener
    updateMaxHeight();
    window.addEventListener("resize", updateMaxHeight);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateMaxHeight);
    };
  }, []);

  return (
    <React.Fragment>
      <Head title="Leads Board"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Leads Board</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#toggle"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmBtn(!smBtn);
                  }}
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                >
                  <Icon name="menu-alt-r"></Icon>
                </a>
                <div
                  className={`toggle-expand-content ${smBtn ? "expanded" : ""}`}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div className="form-control-wrap">
                        <div
                          className="form-icon form-icon-right"
                          onClick={(e) => handleSearch(e)}
                        >
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control search-input-leads-board"
                          id="default-04"
                          placeholder="Search by Name, Phone, Email"
                          value={search}
                          onChange={onChangeSearch}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSearch(e);
                            }
                          }}
                        />
                      </div>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn  btn-primary text-white"
                        >
                          <span>Add</span>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="/CreateLead"
                                // onClick={(ev) => ev.preventDefault()}
                              >
                                <Icon name="user" />
                                <span>Add Lead </span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="/add-deal"
                                // onClick={(ev) => ev.preventDefault()}
                              >
                                <Icon name="task" /> <span>Add Deal</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
          <Row>
            <div className="mt-4">
              <Row className="align-items-end">
                <Col xs="12" className="d-flex justify-content-end">
                  <ul className="nk-block-tools g-3">
                    {authuser?.role && authuser?.role !== newRoles?.Agent && (
                      <li>
                        <div className="status-div">
                          <RSelect
                            options={[
                              { value: "all", label: "All" },
                              ...optAgents,
                            ]}
                            value={
                              leadsAgentGetObj
                                ? JSON.parse(leadsAgentGetObj)
                                : selectedAgent.value === "all"
                                ? ""
                                : selectedAgent
                            }
                            placeholder="Filter By Agent"
                            className="status-div"
                            onChange={(selectedOption) => {
                              if (selectedOption.value === "all") {
                                setFilterAgent("");
                                localStorage.setItem(
                                  "leadsBoardAgentGetValue",
                                  ""
                                );
                                setSelectedAgent({
                                  value: "",
                                  label: "Filter By Agent",
                                });
                                localStorage.removeItem(
                                  "leadsBoardAgentGetObj"
                                );
                              } else {
                                setFilterAgent(selectedOption.value);
                                localStorage.setItem(
                                  "leadsBoardAgentGetValue",
                                  selectedOption.value
                                );
                                setSelectedAgent(selectedOption);
                                const selectedOptionString =
                                  JSON.stringify(selectedOption);
                                localStorage.setItem(
                                  "leadsBoardAgentGetObj",
                                  selectedOptionString
                                );
                                handleAgentOption(selectedOption);
                              }
                            }}
                          />
                        </div>
                      </li>
                    )}
                    <li style={{ listStyleType: "none" }}>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {leadsLabelGetOption ||
                            temporaryLabel ||
                            filterLeadLabel ||
                            "Filter by Label"}
                        </DropdownToggle>
                        <DropdownMenu start>
                          <ul className="link-list-opt no-bdr">
                            {filterLeadLabel && (
                              <li>
                                <DropdownItem
                                  onClick={(ev) => {
                                    handleLeadLabelOption1("");
                                  }}
                                >
                                  <span>All</span>
                                </DropdownItem>
                              </li>
                            )}
                            {Object.keys(LeadLabels).map((label) => (
                              <li key={label}>
                                <DropdownItem
                                  onClick={() => handleLeadLabelOption1(label)}
                                >
                                  <span>{LeadLabels[label]}</span>
                                </DropdownItem>
                              </li>
                            ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      {optLeadSource && (
                        <div className="status-div">
                          <RSelect
                            placeholder="Filter By Leadsource"
                            options={optLeadSource}
                            isMulti
                            // defaultValue={selectedOptLeadSource}
                            value={selectedOptions}
                            onChange={handleLeadSource}
                            className="status-div-rselect"
                            components={{
                              MultiValueLabel: CustomMultiValueLabel,
                            }}
                          />
                        </div>
                      )}
                    </li>

                    <li className="">
                      <div className="form-group datepicker-zindex">
                        <div className="form-control-wrap datepicker-zindex">
                          <div className="input-daterange date-picker-range input-group datepicker-zindex">
                            <DatePicker
                              selected={
                                startDateGet
                                  ? new Date(startDateGet)
                                  : rangeStart
                              }
                              onChange={(date) => {
                                setRangeStart(date);
                                localStorage.setItem(
                                  "leadBoardStartDateGet",
                                  date ? date?.toISOString() : ""
                                );
                                localStorage.setItem(
                                  "leadBoardEndDateGet",
                                  rangeEnd ? rangeEnd.toISOString() : ""
                                );
                              }}
                              selectsStart
                              startDate={
                                startDateGet
                                  ? new Date(startDateGet)
                                  : rangeStart
                              }
                              endDate={
                                endDateGet ? new Date(endDateGet) : rangeEnd
                              }
                              wrapperClassName="start-m"
                              className="form-control datepicker-zindex"
                              placeholderText="From Date"
                            />{" "}
                            <div className="input-group-addon">TO</div>
                            <DatePicker
                              selected={
                                endDateGet ? new Date(endDateGet) : rangeEnd
                              }
                              onChange={(date) => {
                                setRangeEnd(date);
                                localStorage.setItem(
                                  "leadBoardStartDateGet",
                                  rangeStart ? rangeStart.toISOString() : ""
                                );
                                localStorage.setItem(
                                  "leadBoardEndDateGet",
                                  date ? date?.toISOString() : ""
                                );
                              }}
                              startDate={
                                startDateGet
                                  ? new Date(startDateGet)
                                  : rangeStart
                              }
                              endDate={
                                endDateGet ? new Date(endDateGet) : rangeEnd
                              }
                              selectsEnd
                              minDate={
                                startDateGet
                                  ? new Date(startDateGet)
                                  : rangeStart
                              }
                              wrapperClassName="end-m"
                              className="form-control datepicker-zindex"
                              placeholderText="Date"
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col xs="12" className="mt-2 d-flex justify-content-end pe-4">
                  {selectedOptions && (
                    <div className="selected-options d-flex flex-wrap">
                      {selectedOptions.map((option, index) => (
                        <Badge
                          className="selected-option bg-primary me-1"
                          key={index}
                        >
                          {option.label}{" "}
                          <Icon
                            name="cross-sm"
                            style={{ cursor: "pointer" }}
                            className="ms-1"
                            onClick={() =>
                              handleBadgeRemoveAndLeadSource(option)
                            }
                          />
                        </Badge>
                      ))}
                    </div>
                  )}

                  {leadsLabelGetOption && (
                    <div className="selected-options d-flex flex-wrap">
                      <Badge className="selected-option bg-primary me-1">
                        {leadsLabelGetOption}
                        <Icon
                          name="cross-sm"
                          className="ms-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleLeadLabelOption1("");
                            setFilterLeadSource("");
                            setSelectedOptions("");
                          }}
                        />
                      </Badge>
                    </div>
                  )}

                  {leadsAgentGetObj && (
                    <div className="selected-options d-flex flex-wrap">
                      <Badge
                        className="selected-option bg-primary me-1"
                        color="primary"
                      >
                        {"Agent: "}
                        {JSON.parse(leadsAgentGetObj).label}
                        <Icon
                          name="cross-sm"
                          className="ms-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setFilterAgent("");
                            localStorage.setItem("leadsBoardAgentGetValue", "");
                            setSelectedAgent({
                              value: "",
                              label: "Filter By Agent",
                            });
                            localStorage.removeItem("leadsBoardAgentGetObj");
                          }}
                        />
                      </Badge>
                    </div>
                  )}

                  {clearBadge && (
                    <Badge
                      className="selected-option bg-primary me-1"
                      style={{ cursor: "pointer" }}
                      color="danger"
                      onClick={() => {
                        localStorage.setItem("leadsBoardAgentGetValue", "");
                        localStorage.removeItem("leadsBoardAgentGetObj");
                        localStorage.setItem(
                          "leadBoardFilterLeadSourceGetValue",
                          ""
                        );
                        localStorage.setItem("leadBoardStartDateGet", "");
                        localStorage.setItem("leadBoardEndDateGet", "");

                        setFilterAgent("");
                        setSelectedAgent({
                          value: "",
                          label: "Filter By Agent",
                        });
                        handleLeadLabelOption1("");
                        setSelectedOptions("");
                        setFilterLeadSource("");
                        setRangeStart();
                        setRangeEnd();
                      }}
                    >
                      {"Clear All"}
                    </Badge>
                  )}
                </Col>
              </Row>
            </div>
          </Row>
        </BlockHead>
        <Block>
          <div
            className="nk-kanban"
            style={{ maxHeight: maxHeight, overflowX: "scroll" }}
          >
            <div
              className="kanban-container"
              id="kanban-container"
              style={{ width: `${columns.length * 320}px` }}
            >
              <>
                {columns &&
                  columns.map((col, k) => {
                    switch (col) {
                      case LeadStatus.Unassigned:
                        return renderKanbanColumn(
                          col,
                          labelUnassigned,
                          countUnassigned,
                          "light",
                          handleColumnScroll,
                          loadMore,
                          initialSortOrders.Unassigned
                        );
                      case LeadStatus.Fresh:
                        return renderKanbanColumn(
                          col,
                          labelFresh,
                          countFresh,
                          "primary",
                          handleColumnScroll,
                          loadMore,
                          initialSortOrders.Fresh
                        );
                      case LeadStatus.FollowUp:
                        return renderKanbanColumn(
                          col,
                          labelFollowUp,
                          countFollowUp,
                          "warning",
                          handleColumnScroll,
                          loadMore,
                          initialSortOrders.Followup
                        );
                      case LeadStatus.Prospect:
                        return renderKanbanColumn(
                          col,
                          labelProspect,
                          countProspect,
                          "danger",
                          handleColumnScroll,
                          loadMore,
                          initialSortOrders.Prospect
                        );
                      case LeadStatus.ForListing:
                        return renderKanbanColumn(
                          col,
                          labelForListing,
                          countForListing,
                          "success",
                          handleColumnScroll,
                          loadMore,
                          initialSortOrders.ForListing
                        );
                      case LeadStatus.Appointment:
                        return renderKanbanColumn(
                          col,
                          labelAppointment,
                          countAppointment,
                          "primary",
                          handleColumnScroll,
                          loadMore,
                          initialSortOrders.Appointment
                        );
                      case LeadStatus.Viewing:
                        return renderKanbanColumn(
                          col,
                          labelViewing,
                          countViewing,
                          "info",
                          handleColumnScroll,
                          loadMore,
                          initialSortOrders.Viewing
                        );
                      case LeadStatus.Negotiating:
                        return renderKanbanColumn(
                          col,
                          labelNegotiating,
                          countNegotiating,
                          "primary",
                          handleColumnScroll,
                          loadMore,
                          initialSortOrders.Negotiating
                        );
                      case LeadStatus.Deal:
                        return renderKanbanColumn(
                          col,
                          labelDeal,
                          countDeal,
                          "dark",
                          handleColumnScroll,
                          loadMore,
                          initialSortOrders.Deal
                        );
                      case LeadStatus.Won:
                        return renderKanbanColumn(
                          col,
                          labelWon,
                          countWon,
                          "success",
                          handleColumnScroll,
                          loadMore,
                          initialSortOrders.Won
                        );
                      case LeadStatus.Lost:
                        return renderKanbanColumn(
                          col,
                          labelLost,
                          countLost,
                          "dark",
                          handleColumnScroll,
                          loadMore,
                          initialSortOrders.Lost
                        );
                      case LeadStatus.Disqualified:
                        return renderKanbanColumn(
                          col,
                          labelDisqualified,
                          countDisqualified,
                          "light",
                          handleColumnScroll,
                          loadMore,
                          initialSortOrders.Disqualified
                        );

                      default:
                        return null;
                    }
                  })}
              </>
            </div>
          </div>
        </Block>
        <Modal size="lg" isOpen={boardModal} toggle={toggleBoardModal}>
          <KanbanBoardForm
            toggle={toggleBoardModal}
            data={columns}
            setData={setColumns}
          />
        </Modal>

        <Modal size="lg" isOpen={taskModal} toggle={toggleTaskModal}>
          <KanbanTaskForm
            toggle={toggleTaskModal}
            data={columns}
            setData={setColumns}
          />
        </Modal>
      </Content>
    </React.Fragment>
  );

  function KanbanColumnHeader({ theme, col, count }) {
    const [open, setOpen] = useState(false);
    const toggleModal = () => {
      setOpen(!open);
    };

    return (
      <>
        <div className={`kanban-board-header kanban-${theme}`}>
          <div className="kanban-title-board">
            <div className="kanban-title-content">
              <h6 className="title">{col}</h6>
              <Badge className="text-dark" pill color="outline-light">
                {count}
              </Badge>
            </div>
            <div className="kanban-title-content">
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="a"
                  href="toggle"
                  onClick={(ev) => ev.preventDefault()}
                  className="dropdown-toggle btn btn-sm btn-icon btn-trigger me-n1"
                >
                  <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu end>
                  <ul className="link-list-opt no-bdr">
                    <li>
                      <DropdownItem tag="a" href="#item">
                        <Icon name="search"></Icon>
                        <input
                          className="form-control border-transparent form-focus-none"
                          type="text"
                          placeholder="Search On Column"
                        />
                      </DropdownItem>
                    </li>

                    {/* <li>
                    <DropdownItem tag="a" href="#item">
                      <Icon name="plus-sm"></Icon>
                      <span>Add New Lead</span>
                    </DropdownItem>
                  </li> */}
                    {/* <li>
                    <DropdownItem
                      tag="a"
                      href="#item"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleModal();
                      }}
                    >
                      <Icon name="task"></Icon>
                      <span>Add New Task</span>
                    </DropdownItem>
                  </li> */}
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#item"
                        onClick={(ev) => {
                          ev.preventDefault();
                          sortByMostRecent(col);
                          //   toggleModal();
                        }}
                      >
                        <Icon name="sort"></Icon>
                        <span>Sort By Most Recent</span>
                      </DropdownItem>
                    </li>
                    <li>
                      <DropdownItem tag="a" href="#item">
                        <Icon name="calendar"></Icon>
                        <span>Sort By Date Of Next Action </span>
                      </DropdownItem>
                    </li>
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#item"
                        onClick={(ev) => {
                          ev.preventDefault();
                          sortByOverDue(col);
                          //   toggleModal();
                        }}
                      >
                        <Icon name="sort"></Icon>
                        <span>Sort By Overdue </span>
                      </DropdownItem>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </div>
        <Modal size="lg" isOpen={open} toggle={toggleModal}>
          <NewKanbanTaskForm toggle={toggleModal} data={null} />
        </Modal>
      </>
    );
  }

  function renderKanbanColumn(
    col,
    label,
    count,
    theme,
    handleColumnScroll,
    loadMore,
    sortOrder
  ) {
    const formattedCol = col.replace(/\s/g, "");

    if (sortOrders[formattedCol] === "asc") {
      label = label.sort(
        (a, b) =>
          new Date(b.last_activity_date) - new Date(a.last_activity_date)
      );
    } else if (sortOrders[formattedCol] === "desc") {
      label = label.sort(
        (a, b) =>
          new Date(a.last_activity_date) - new Date(b.last_activity_date)
      );
    }

    return (
      <div>
        <div className="kanban-board" key={col}>
          <KanbanColumnHeader col={col} theme={theme} count={count} />
          <div
            onScroll={(e) => {
              handleColumnScroll(e, col);
            }}
          >
            {label &&
              label.map((user, i) => <MyKanbanCard user={user} col={col} />)}
          </div>
          {label && label?.length !== count && (
            <button
              className="kanban-add-task mt-2 btn btn-block"
              onClick={(e) => loadMore(e, col)}
            >
              <Icon name="loader"></Icon>
              <span>Load More</span>
            </button>
          )}
        </div>{" "}
      </div>
    );
  }
};

export default StatusBoard;
