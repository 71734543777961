import { Modal, ModalBody, Spinner } from 'reactstrap';
import { Button, Icon, Col, Row } from '../../../../components/Component';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

function CancellationModal({ open, handlecloseModal, handleConfirm, loading }) {
  const [notes, setNotes] = useState('');

  const { errors, register } = useForm();

  const onCancelClick = (e) => {
    e.preventDefault();
    handlecloseModal(false);
  };

  const onUpdateClick = (e) => {
    e.preventDefault();

    if (notes.length >= 5) {
      handleConfirm(notes);
    } else {
      // Show an error message or take appropriate action
      toast.error('Note should be at least 5 characters');
    }
  };

  return (
    <Modal
      isOpen={open}
      toggle={() => handlecloseModal(false)}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a href="#cancel" className="close" onClick={onCancelClick}>
          <Icon name="cross-sm" />
        </a>
        <form>
          <div className="nk-tnx-details mt-sm-3">
            <div className="nk-modal-head mb-3">
              <h5 className="title">Confirm</h5>
            </div>
            <Row className="gy-3">
              <Col size="12">
                <p className='fs-6'>
                  Are you sure you want to <b>Cancel the property</b>? This action
                  cannot be undone.
                </p>
                <div className='d-flex align-items-center bg-danger text-white p-2 rounded' style={{ gap: "0.4rem" }}>
                  <Icon name={'alert'} className={'fs-6 ms-1'} />
                  <p className='ms-1 '>
                    Any property with the same <b>Permit Number</b> will be <span>removed</span> from PropertyFinder's listings
                  </p>
                </div>
              </Col>
              <Col className="col-12">
                <div className="form-group">
                  <label className="form-label">
                    Note (at least 5 characters)
                  </label>
                  <textarea
                  maxLength={280}
                    name="notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    className="form-control no-resize"
                    ref={register({
                      required: 'This field is required',
                      minLength: 20,
                    })}
                  />
                  {errors.notes && (
                    <span className="invalid">{errors.notes.message}</span>
                  )}
                </div>
              </Col>
              <Col size="12">
                {!loading && (
                  <Button
                    onClick={onCancelClick}
                    className="text-center"
                    color="light"
                    style={{ marginRight: '10px' }}
                  >
                    <Icon className="plus" />
                    Cancel
                  </Button>
                )}
                <Button
                  onClick={(e) => onUpdateClick(e)}
                  color="primary"
                  type="button"
                  disabled={loading}
                >
                  {!loading ? (
                    <>
                      <Icon className="plus" />
                      Update
                    </>
                  ) : (
                    <Spinner size="sm" color="light" />
                  )}
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default CancellationModal;
