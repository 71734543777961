import {
    Button,
    Card,
    Col,
    Collapse,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner
} from 'reactstrap';
import { Icon, PreviewCard, RSelect } from '../../../components/Component';
import { useEffect, useMemo, useState } from 'react';
// import PhoneInput from 'react-phone-number-input';
import Form from '../../properties/components/forms/Form';

import {
    BathRooms,
    BedRooms,
    DealCategory,
    DealClientType,
    DealPurpose,
    DealRoleType,
    formatValue,
    formatValueToDecimal,
    newRoles,
    propertyTypes,
    validateEmail
} from '../../../utils/envConfig';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import {
    fetchBuildings,
    fetchCommunitiesList,
    fetchOwnerProperties,
    fetchPropertiesAdmin
} from '../../../redux/actions/property';
import { toast } from 'react-toastify';
import { fetchAgencies } from '../../../redux/actions/agencies';
import AddNewAgency from './AddNewAgency';
import SearchClientSelect from '../../../components/SearchClientSelect';
import { fetchAgents } from '../../../redux/actions/user';
import { useConfirm } from '../../../hooks/useConfirm';
import ConfirmModal from './ConfirmModal';
import AddLeadStepOne from '../../leads/components/AddLeadStepOne';
import PhoneInput from '../../../components/input/contact-input/PhoneInput';
import { debounce } from 'lodash';

const SecondaryMarketProperty = ({ dealType, users = [], next = () => {} }) => {
    const axios = useAxiosPrivate();
    const dispatch = useDispatch();

    const {
        isConfirmed,
        prompt = '',
        isOpen = false,
        proceed,
        cancel
    } = useConfirm();

    const {
        isConfirmed: confirmCommissonInput,
        prompt: commissionPrompt = '',
        isOpen: commissionIsOpen = false,
        proceed: commisionProceed,
        cancel: commissionCancel
    } = useConfirm();

    const {
        isConfirmed: isConfirmed2,
        prompt: prompt2 = '',
        isOpen: isOpen2 = false,
        proceed: proceed2,
        cancel: cancel2
    } = useConfirm();

    const ownerProperties = useSelector(
        (state) => state?.property?.ownerProperties
    );

    const agents = useSelector((state) => state?.user?.agents);

    const communities = useSelector((state) => state?.property?.communities);

    const buildings = useSelector((state) => state?.property?.buildings);

    const agencies = useSelector((state) => state?.agencies?.agencies);

    useEffect(() => {
        dispatch(fetchPropertiesAdmin(axios));
        dispatch(fetchCommunitiesList(axios));
        dispatch(fetchBuildings(axios));
        dispatch(fetchAgencies(axios));

        !agents?.length && dispatch(fetchAgents(axios));
    }, []);

    const property = {
        id: null,
        listingType: '',
        categoryId: '',
        propertyTypeId: '',
        propertyTitle: '',
        propertyDesc: '',
        featured: false,
        dealOfWeek: false,
        premium: false,
        exclusive: false,
        completionStatus: '',
        completionDate: '',
        buildingName: '',
        street: '',
        communityId: '',
        communityName: '',
        subCommunity: '',
        emirate: '',
        pinlocation: '',
        size: null,
        villaSize: '',
        beds: '',
        baths: '',
        amenityIds: [],
        amenityObjs: [],
        image: null,
        customField: [],
        rentalPeriod: '',
        rentFinance: '',
        unitStatus: '',
        unitReraNo: '',
        makaniNo: '',
        videos: [],
        matterportLink: [],
        agentStatus: '',
        agent_id: null,
        metaDesc: '',
        metaTags: [],
        commission: '',
        securityDeposit: '',
        dewaNo: '',
        askingPrice: null,
        pricedrop: '',
        oneCheqPrice: null,
        twoCheqPrice: null,
        fourCheqPrice: null,
        sixCheqPrice: null,
        twelveCheqprice: null,
        fees: [],
        property_owner_id: ''
    };

    const Purposes = Object.values(DealPurpose).map((item) => ({
        value: item,
        label: item
    }));

    const ClientType = Object.values(DealClientType).map((item) => ({
        value: item,
        label: item
    }));

    const Agencies =
        agencies && agencies?.length
            ? agencies?.map((agency) => ({
                  ...agency,
                  value: agency?.agency_name ?? agency?.first_name,
                  label: agency?.agency_name ?? agency?.first_name
              }))
            : [];

    const [isLoading, setIsLoading] = useState(false);

    const [selectedBuyerType, setSelectedBuyerType] = useState(null);
    const [selectedBuyer, setSelectedBuyer] = useState(null);
    const [buyerAgreedComm, setBuyerAgreedComm] = useState('2');
    const [buyerAssignedAgent, setBuyerAssignedAgent] = useState();
    const [isAssignedAgentBuyer, setIsAssignedAgentBuyer] = useState(null);

    console.log(buyerAssignedAgent);

    const [formattedbuyerAgreedComm, setFormattedbuyerAgreedComm] =
        useState('');

    const [buyerAgreedCommValue, setBuyerAgreedCommValue] = useState('');

    const [newBuyerDetails, setNewBuyerDetails] = useState(null);

    const [selectedPurpose, setSelectedPurpose] = useState(null);

    const [selectedSellerType, setselectedSellerType] = useState(null);
    const [selectedSeller, setSelectedSeller] = useState(null);
    const [sellerAgreedComm, setSellerAgreedComm] = useState('');
    const [sellerAgreedCommValue, setSellerAgreedCommValue] = useState('');
    const [sellerAgreedSalePrice, setSellerAgreedSalePrice] = useState('');
    const [sellerAssignedAgent, setSellerAssignedAgent] = useState(null);
    const [isAssignedAgentSeller, setIsAssignedAgentSeller] = useState(null);
    const [selectedAgencySeller, setSelectedAgencySeller] = useState(null);
    const [sellerAgency, setSellerAgency] = useState(null);

    const [formattedsellerAgreedComm, setFormattedsellerAgreedComm] =
        useState('');

    const [selectedAgencyBuyer, setSelectedAgencyBuyer] = useState(null);
    const [buyerAgency, setBuyerAgency] = useState(null);

    const [propertyDetails, setPropertyDetails] = useState(null);

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const [openAddClient, setopenAddClient] = useState(false);
    const [clientToAddType, setClientToAddType] = useState(false);

    const [openAddAgency, setopenAddAgency] = useState(false);
    const [agencyAddType, setAgencyAddType] = useState('');
    const [openAddProperty, setopenAddProperty] = useState(false);

    const [selectedProperty, setSelectedProperty] = useState(null);
    const [searchPropertyText, setSearchPropertyText] = useState('');
    const [isSearchingProperty, setIsSearchingProperty] = useState(false);
    const [isMortgage, setIsMortgage] = useState(false);
    const [mortgageProvider, setMortgageProvider]=useState('');
    const [isSellerMortgage, setIsSellerMortgage] = useState(false);
    const [sellerMortgageProvider, setSellerMortgageProvider] = useState('');

    const toggleModalAddClient = () => {
        setopenAddClient(!openAddClient);
    };

    const toggleModalAddAgency = () => {
        setopenAddAgency(!openAddAgency);
    };

    useEffect(() => {
        dispatch(
            fetchOwnerProperties(
                axios,
                selectedSeller?.id ?? selectedSeller?._id
            )
        );
    }, [selectedSeller]);

    const toggleModalAddProperty = () => {
        setopenAddProperty(!openAddProperty);
    };

    const refetchOwnerproperties = () => {
        if (!selectedSeller?.id || !selectedSeller?._id) return;

        try {
            dispatch(
                fetchOwnerProperties(
                    axios,
                    selectedSeller?.id ?? selectedSeller?._id
                )
            );
        } catch (err) {
        } finally {
            setIsSearchingProperty(false);
        }
    };

    // memoize the debounce call with useMemo
    const debouncedSearchProperty = useMemo(() => {
        return debounce(
            () =>
                dispatch(
                    fetchOwnerProperties(
                        axios,
                        selectedSeller?.id ?? selectedSeller?._id
                    )
                ),
            1000
        );
    }, [fetchOwnerProperties]);

    const getPropertyAgent = async (id) => {
        try {
            const { data } = await axios.get(`/agent/${id}`);
            return data;
        } catch (err) {
            console.log(err);
        }
    };

    const handleAssignAgentToProperty = async () => {
        try {
            // assign property to agent
            const selectedPropertyId =
                selectedProperty?.id ?? selectedProperty?._id;

            const assignedAgent =
                sellerAssignedAgent?.id ?? sellerAssignedAgent?._id;

            const params = {
                agentStatus: 'assigned',
                agent_id: assignedAgent
            };

            const { data } = await axios.patch(
                `/propList/${selectedPropertyId}`,
                params
            );
            return data;
        } catch (err) {
            throw { ...err, message: 'failed to assign property' };
            // return null;
        }
    };

    // const handleUnAssignPropertyAgent = async () => {
    //     try {
    //         // assign property to agent
    //         const selectedPropertyId =
    //             selectedProperty?.id ?? selectedProperty?._id;

    //         const selectedPropertyAgentId = selectedProperty?.agent?.id;

    //         const params = {
    //             agentStatus: selectedPropertyAgentId
    //                 ? "assigned"
    //                 : "unassigned",
    //             agent_id: selectedPropertyAgentId ?? null,
    //         };

    //         const { data } = await axios.patch(
    //             `/propList/${selectedPropertyId}`,
    //             params
    //         );
    //         return data;
    //     } catch (err) {
    //         throw { ...err, message: "failed to reassign property" };
    //         // return null;
    //     }
    // };

    // const handleAssignAgentToPropertyOwner = async () => {
    //     try {
    //         // assign property owner to agent
    //         const propertyOwnerId = selectedSeller?.id ?? selectedSeller?._id;

    //         const assignedAgent =
    //             sellerAssignedAgent?.id ?? sellerAssignedAgent?._id;

    //         const userAgentParams = {
    //             user_id: propertyOwnerId,
    //             agent_id: assignedAgent,
    //         };

    //         const { data } = await axios.post("/usersAgents", userAgentParams);
    //         return data;
    //     } catch (err) {
    //         throw { ...err, message: "failed to assign owner" };
    //         // return null;
    //     }
    // };

    const handleSellerTypeChange = (selectedOption) => {
        setselectedSellerType(selectedOption);
        setErrors((prev) => ({ ...prev, sellerType: null }));
    };

    const handleBuyerTypeChange = (selectedOption) => {
        setSelectedBuyerType(selectedOption);
        setErrors((prev) => ({ ...prev, buyerType: null }));
    };

    const handleNewBuyerChange = (e) => {
        const { name, value } = e?.target;
        setNewBuyerDetails((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const validateNewUserDetails = () => {
        const errors = {};

        if (
            !newBuyerDetails?.firstName ||
            newBuyerDetails.firstName?.length < 2
        ) {
            errors.newBuyerDetailsFirstName = 'Name too short';
        }

        if (!newBuyerDetails?.firstName) {
            errors.newBuyerDetailsFirstName = 'First name required';
        }

        if (
            !newBuyerDetails?.lastName ||
            newBuyerDetails.lastName?.length < 2
        ) {
            errors.newBuyerDetailsLastName = 'Name too short';
        }

        if (!newBuyerDetails?.lastName) {
            errors.newBuyerDetailsLastName = 'Last name required';
        }

        const validEmail = validateEmail(newBuyerDetails?.email?.trim());

        if (!newBuyerDetails?.email?.trim() || !validEmail) {
            errors.newBuyerDetailsEmail = 'Invalid email';
        }

        if (newBuyerDetails?.phone?.trim().length < 4) {
            errors.newBuyerDetailsPhone = 'Invalide phone number';
        }

        if (
            !newBuyerDetails?.phone?.trim() ||
            newBuyerDetails?.phone?.trim()?.length === 0
        ) {
            errors.newBuyerDetailsPhone = 'Phone number required';
        }

        if (
            !newBuyerDetails?.selectedAgent ||
            (!newBuyerDetails?.selectedAgent?.id &&
                !newBuyerDetails?.selectedAgent?._id)
        ) {
            errors.newBuyerDetailsSelectedAgent = 'Please select an agent';
        }

        return Object.keys(errors).length ? errors : null;
    };

    const validateBuyerOrTenant = (errors) => {
        if (
            (selectedBuyerType && selectedBuyerType?.value) ===
            DealClientType.External
        ) {
            if (!selectedAgencyBuyer || !selectedAgencyBuyer?.id) {
                errors.selectedAgencyBuyer = 'Select a valid agency';
            }
        } else {
            if (
                !ClientType.find(
                    (item) => item?.value === selectedBuyerType?.value
                )
            ) {
                errors.buyerType = 'Invalid buyer type';
            }

            if (!selectedBuyerType?.value) {
                errors.buyerType = 'Buyer type is required';
            }

            if (!selectedBuyer?.id && !selectedBuyer?._id) {
                errors.selectedBuyer = 'Select a valid client';
            }

            if (+buyerAgreedComm > 100) {
                errors.buyerAgreedComm = 'Cannot be more than 100%';
            }

            if (
                !buyerAssignedAgent ||
                (!buyerAssignedAgent?.id && !buyerAssignedAgent?._id)
            ) {
                errors.buyerAssignedAgent = 'Please select an agent';
            }
        }
    };

    const validatSellerOrLandlord = (errors) => {
        if (
            (selectedSellerType && selectedSellerType?.value) ===
            DealClientType.External
        ) {
            if (!selectedAgencySeller || !selectedAgencySeller?.id) {
                errors.selectedAgencySeller = 'Select a valid agency';
            }
        } else {
            if (
                !ClientType.find(
                    (item) => item?.value === selectedSellerType?.value
                )
            ) {
                errors.sellerType = 'Invalid seller type';
            }

            if (!selectedSellerType?.value) {
                errors.sellerType = 'Seller type is required';
            }

            if (!selectedSeller?.id && !selectedSeller?._id) {
                errors.selectedSeller = 'Select a valid client';
            }

            if (!selectedProperty?.id && !selectedProperty?._id) {
                errors.property = 'Select a valid property';
            }

            if (!sellerAssignedAgent?.id && !sellerAssignedAgent?._id) {
                errors.sellerAssignedAgent = 'Add a property agent';
            }
        }

        if (
            !sellerAgreedSalePrice ||
            Number(sellerAgreedSalePrice?.trim()?.replaceAll(',', '')) <= 0
        ) {
            errors.sellerAgreedSalePrice =
                'Cannot be less than or equal to zero.';
        }
    };

    const validateDealInfos = () => {
        const errors = {};

        if (dealType !== DealCategory?.SecondaryProperty) {
            errors.dealType = 'Deal type must be a secondary market';
        }

        if (!Purposes.find((item) => item?.value === selectedPurpose?.value)) {
            errors.purpose = 'Invalid deal purpose';
        }

        if (!selectedPurpose?.value) {
            errors.purpose = 'Deal purpose is required.';
        }

        validateBuyerOrTenant(errors);
        validatSellerOrLandlord(errors);

        return Object.keys(errors).length ? errors : false;
    };

    const validatePropertyDetails = () => {
        const errors = {};

        if (!propertyDetails?.propertyType) {
            errors.propertyType = 'Select property type';
        }

        if (!propertyDetails?.beds?.value) {
            errors.beds = 'Select number of beds';
        }

        if (!propertyDetails.baths?.value) {
            errors.baths = 'Select number of baths';
        }

        if (
            !propertyDetails.community?.id &&
            !propertyDetails?.community?._id
        ) {
            errors.community = 'Select community';
        }

        if (!propertyDetails.building?.id && !propertyDetails?.building?._id) {
            errors.buildiing = 'Select building';
        }

        if (
            !sellerAgreedSalePrice ||
            !+sellerAgreedSalePrice?.trim()?.replaceAll(',', '')
        ) {
            errors.sellerAgreedSalePrice = 'Sale price required';
        }

        if (Number(sellerAgreedComm) > 100) {
            errors.sellerAgreedComm = 'Cannot be more than 100%';
        }

        return Object.keys(errors).length ? errors : false;
    };

    const createNewExternalProperty = async () => {
        try {
            const invalid = validatePropertyDetails();

            if (invalid) {
                setErrors((prev) => ({ ...prev, ...invalid }));
                return null;
            }

            const data = {
                propertyType: propertyDetails?.propertyType?.value,
                beds: propertyDetails?.beds?.value,
                baths: propertyDetails?.baths?.value,
                size: propertyDetails?.size,
                community_id: propertyDetails?.community?.id,
                subCommunity: propertyDetails?.subCommunity,
                building_id: propertyDetails?.buildiing?.id,
                building_name: propertyDetails?.buildiing?.name,
                agreedSalesPrice: +sellerAgreedSalePrice
                    ?.trim()
                    ?.replaceAll(',', ''),
                agreedCommission: sellerAgreedComm,
                agency_id: selectedAgencySeller?.id ?? selectedAgencySeller?._id
            };

            // creates a new property (/propList)
            const { data: property } = await axios.post(
                '/propList/listingProperty',
                data
            );

            return property;
        } catch (err) {
            console.log(err);
            return null;
        }
    };

    const addNewDeal = async () => {
        const dealInfos = {
            category: dealType,
            purpose: selectedPurpose?.value,
            buyerClientType: selectedBuyerType?.value,
            buyerInternalUserId: selectedBuyer?.id ?? selectedBuyer?._id,
            sellerClientType: selectedSellerType?.value,
            sellerInternalUserId: selectedSeller?.id ?? selectedSeller?._id,
            property_id: selectedProperty?.id ?? selectedProperty?._id,
            roleType:
                selectedPurpose?.value === DealPurpose?.Rent
                    ? [DealRoleType.Tenant, DealRoleType.Landlord]
                    : selectedPurpose?.value === DealPurpose?.Sale
                    ? [DealRoleType.Buyer, DealRoleType.Seller]
                    : null, // depends on the purpose:::: purpose = Rent ? [Tenant, Landlord] : status === Sale ? [Buyer, Seller]
            // when buyer/tenant is external
            buyerExternalUserId:
                selectedAgencyBuyer?.id ?? selectedAgencyBuyer?._id,
            buyerExternalAgentName: buyerAgency?.agent,
            buyerExternalAgentPhone: buyerAgency?.agentPhone
                ?.trim()
                ?.replaceAll(' ', ''),
            buyerExternalClientName: buyerAgency?.clientName,
            buyerExternalClientPhone: buyerAgency?.clientPhone
                ?.trim()
                ?.replaceAll(' ', ''),
            // when seller/landlord is external
            sellerExternalUserId:
                selectedAgencySeller?.id ?? selectedAgencySeller?._id,
            sellerExternalAgentName: sellerAgency?.agentName,
            sellerExternalAgentPhone: sellerAgency?.agentPhone
                ?.trim()
                ?.replaceAll(' ', ''),
            sellerExternalClientName: sellerAgency?.clientName,
            sellerExternalClientPhone: sellerAgency?.clientPhone
                ?.trim()
                ?.replaceAll(' ', ''),
            external_listing_property_id: null,
            sellerAgreedComm: +sellerAgreedComm,
            agreedSalePrice: +sellerAgreedSalePrice
                ?.trim()
                ?.replaceAll(',', ''),
            sellerAssignedAgent:
                selectedSellerType?.value === DealClientType.Alba
                    ? sellerAssignedAgent?.id ?? sellerAssignedAgent?._id
                    : null,
            buyerAgreedComm: +buyerAgreedComm,
            buyerAssignedAgent:
                selectedBuyerType?.value === DealClientType.Alba
                    ? buyerAssignedAgent?.id ?? buyerAssignedAgent?._id
                    : null,
            isMortgage: isMortgage ?? false,
            mortgageProvider: mortgageProvider ?? null,
            isSellerMortgage: isSellerMortgage ?? false,
            sellerMortgageProvider: sellerMortgageProvider ?? null,
        };

        try {
            setLoading(true);
            const invalid = validateDealInfos();

            if (invalid) {
                setErrors(invalid);
                return;
            }

            if (selectedSellerType?.value === DealClientType.Alba) {
                if (selectedProperty?.id || selectedProperty?._id) {
                    // if a property is selected, then we want to check if the selected property's agent is thesame as sellerAssignedAgent
                    // if sellerAssignedAgent is not equal to property's agent, then we want to display an alert for the user to choose between assigning selected agent to property or continue the deal creation without assigning the agent or cancelling deal creation
                    // if clicked on assign agent, we want to assign selected agent to the selected property then continue deal creation
                    // if clicked on continue deal then we want to continue deal creation without assigning agent to property
                    // if clicked on cancel, then we want to cancel the deal creation process.

                    if (
                        selectedProperty?.agent?.id !==
                            sellerAssignedAgent?.id ||
                        (!isAssignedAgentSeller && sellerAssignedAgent?.id)
                    ) {
                        // here selected agent is not property's assigned agent or the selected agent has not been assigned to the property yet. Thus we prompt for a confirmation to continue or cancel the deal

                        const confirmed = await isConfirmed(
                            <>
                                <h6 className="text-center mb-1">
                                    <b>
                                        Selected agent not same as property
                                        assigned agent.
                                    </b>
                                </h6>
                                <div>
                                    By clicking <b>Continue</b>, you accept to
                                    assign this property and the owner to
                                    selected agent.
                                </div>
                            </>
                        );

                        if (!confirmed) {
                            return; // very important since it'll end the function without creating a deal
                        } else if (confirmed) {
                            // this means we want to assign agent to property
                            // this functionality is already handle when user clicks on continue from the popup
                        }

                        //if both conditions above didn't match, this means the user decided to create deal irregardless of the agent being assigned to property. no additional functionality needed
                        //return;
                        // console.log("creating deal without assigned agent");
                    }

                    // if condition above doesn't hold, that means agent is correctly assigned to property.
                    // thus we continue the process
                    // console.log("agent well assigned");
                    //return; //to be uncommented
                }
                // here shows client is not even internal
                // return; //to be uncommented
            }

            if (selectedBuyerType?.value === DealClientType.Alba) {
                // we want to confirm if selectedBuyer's agent is thesame as selected agent.
                // if yes, then we continue to deal creation
                // else we want to display a popup for the user to either assign agent or continue deal without assigning agent or cancel deal creation

                if (selectedBuyer?.agent?.id === buyerAssignedAgent?.id) {
                } else {
                    // in this case selected agent is not the agent assigned to the client, thus we want to display pop up for user to decide which action

                    const confirmed = await isConfirmed(
                        <>
                            <h6 className="text-center mb-1">
                                <b>
                                    Selected agent not same as client assigned
                                    agent.
                                </b>
                            </h6>
                            <div>
                                By clicking <b>Continue</b>, you accept to
                                assign this client to selected agent.
                            </div>
                        </>
                    );

                    if (!confirmed) {
                        // we want to discontinue creation of deal
                        return; // very important since it'll end the function without creating a deal
                    } else if (confirmed) {
                        // this means we want to assign agent to client
                        // handle functionality below

                        const selectedClientId =
                            selectedBuyer?.id ?? selectedBuyer?._id;

                        const assignedAgent =
                            buyerAssignedAgent?.id ?? buyerAssignedAgent?._id;

                        const params = {
                            user_id: selectedClientId,
                            agent_id: assignedAgent
                        };

                        const { data } = await axios.patch(
                            `/users/${params?.user_id}`,
                            {
                                currentAgent: params?.agent_id
                            }
                        );

                        await axios.post('/usersAgents', params);

                        if (!data) {
                            throw new Error('Failed to assign agent to user');
                        }
                        // return; // needs to be uncommented so that functions below can be runned regardless
                    }

                    //if both conditions above didn't match, this means the user decided to create deal irregardless of the agent being assigned to property. no additional functionality needed
                    //return;
                    // console.log("creating deal without assigned agent");
                }

                // if condition above doesn't hold, that means agent is correctly assigned to property.
                // thus we continue the process
                // console.log("agent well assigned");
                //return; //to be uncommented
            }

            if (selectedSellerType?.value === DealClientType.External) {
                const property = await createNewExternalProperty();

                if (!property) {
                    return;
                }

                dealInfos.external_listing_property_id =
                    property?.id ?? property?._id;
            }

            const { data } = await axios.post('/deals', dealInfos);
            // save deal id to localStorage to refetch data when we progress deal
            localStorage.setItem('new_deal', JSON.stringify(data));
            // call next function to move to next step
            next();
            return;
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const renderBuyersRows = () => {
        if (
            selectedBuyerType &&
            selectedBuyerType.value === DealClientType.Alba
        ) {
            return (
                <PreviewCard className="bg-lighter">
                    <Row>
                        <Label>Choose from Our Clients</Label>
                        <Col sm="10">
                            <SearchClientSelect
                                placeholder="Search clients by name or phone"
                                onChange={(client) => {
                                    setSelectedBuyer({
                                        ...client,
                                        agent: client?.currentAgent
                                    });

                                    setSelectedAgencyBuyer(null);
                                    setErrors((prev) => ({
                                        ...prev,
                                        selectedBuyer: null
                                    }));

                                    if (client?.currentAgent?.id) {
                                        setBuyerAssignedAgent({
                                            ...client?.currentAgent,
                                            label: `${client?.currentAgent?.userId?.first_name} ${client?.currentAgent?.userId?.last_name ?? ''}`,
                                            value: `${client?.currentAgent?.userId?.first_name} ${client?.currentAgent?.userId?.last_name ?? ''}`
                                        });
                                        setIsAssignedAgentBuyer(true);
                                    } else {
                                        setIsAssignedAgentBuyer(false);
                                    }
                                }}
                                value={selectedBuyer}
                                excludeClient={selectedSeller?.id} // Exclude the selected seller from this list
                                className={
                                    errors &&
                                    errors?.selectedBuyer &&
                                    'border border-danger'
                                }
                            />

                            {errors && errors?.selectedBuyer && (
                                <small className="ff-italic text-danger">
                                    {errors?.selectedBuyer}
                                </small>
                            )}
                        </Col>

                        <Col>
                            <Button
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    toggleModalAddClient();
                                    setClientToAddType('buyer');
                                }}
                            >
                                <Icon name="plus"></Icon>
                            </Button>
                        </Col>
                        {selectedBuyer && (
                            <>
                                <Label className="mt-2">Assigned Agent</Label>
                                <Col sm="10">
                                    <RSelect
                                        options={
                                            agents && agents?.length
                                                ? agents?.map((agent) => ({
                                                      ...agent,
                                                      ...agent,
                                                      value: `${agent?.user?.first_name} ${agent?.user?.last_name}`,
                                                      label: `${agent?.user?.first_name} ${agent?.user?.last_name}`
                                                  }))
                                                : []
                                        }
                                        placeholder="Select Agent"
                                        onChange={(agent) => {
                                            setBuyerAssignedAgent(agent);
                                            setErrors((prev) => ({
                                                ...prev,
                                                buyerAssignedAgent: null
                                            }));

                                            if (
                                                agent?.id ===
                                                selectedBuyer?.agent?.id
                                            ) {
                                                setIsAssignedAgentBuyer(true);
                                                return;
                                            }
                                            setIsAssignedAgentBuyer(false);
                                        }}
                                        value={buyerAssignedAgent}
                                        className={
                                            errors &&
                                            errors?.buyerAssignedAgent &&
                                            'border border-danger'
                                        }
                                    />
                                    {errors && errors?.buyerAssignedAgent && (
                                        <small className="ff-italic text-danger">
                                            {errors?.buyerAssignedAgent}
                                        </small>
                                    )}
                                </Col>
                                {!isAssignedAgentBuyer && (
                                    <Col>
                                        <Button
                                            onClick={async (e) => {
                                                try {
                                                    const params = {
                                                        user_id:
                                                            selectedBuyer?.id ??
                                                            selectedBuyer?._id,
                                                        agent_id:
                                                            buyerAssignedAgent?.id ??
                                                            buyerAssignedAgent?._id
                                                    };

                                                    const { data } =
                                                        await axios.patch(
                                                            `/users/${
                                                                selectedBuyer?.id ??
                                                                selectedBuyer?._id
                                                            }`,
                                                            {
                                                                currentAgent:
                                                                    buyerAssignedAgent?.id ??
                                                                    buyerAssignedAgent?._id
                                                            }
                                                        );

                                                    await axios.post(
                                                        '/usersAgents',
                                                        params
                                                    );

                                                    if (!data) {
                                                        throw new Error(
                                                            'Failed to assign agent'
                                                        );
                                                    }

                                                    setIsAssignedAgentBuyer(
                                                        true
                                                    );
                                                    setSelectedBuyer({
                                                        ...selectedBuyer,
                                                        agent: buyerAssignedAgent
                                                    });
                                                } catch (err) {
                                                    console.log(err);
                                                    toast.error(
                                                        'Unexpected, failed to assign property to agent.'
                                                    );
                                                    setIsAssignedAgentBuyer(
                                                        false
                                                    );
                                                }
                                            }}
                                        >
                                            Assign
                                        </Button>
                                    </Col>
                                )}
                            </>
                        )}

                        <Label className="mt-2">Agreed Commission</Label>

                        <Col sm="5">
                            <div className="form-control-wrap">
                                <div
                                    className={`input-group ${
                                        errors &&
                                        errors?.buyerAgreedComm &&
                                        'border border-danger'
                                    }`}
                                >
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            %
                                        </span>
                                    </div>
                                    <Input
                                        max={100}
                                        placeholder="Enter Agreed Commission (%)"
                                        value={formattedbuyerAgreedComm}
                                        type="number"
                                        onKeyDown={(e) => {
                                            const notAllowedKeys = [
                                                'e',
                                                'E',
                                                '+',
                                                '-'
                                            ];

                                            if (
                                                notAllowedKeys.includes(e.key)
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setBuyerAgreedComm(e.target.value);
                                            setFormattedbuyerAgreedComm(
                                                e.target.value
                                            );
                                            setErrors((prev) => ({
                                                ...prev,
                                                buyerAgreedComm: ''
                                            }));
                                        }}
                                        onBlur={async (e) => {
                                            const value = +e.target.value;

                                            const trimmedPrice =
                                                +sellerAgreedSalePrice
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                            const agreedSellingPrice = isNaN(
                                                trimmedPrice
                                            )
                                                ? 0
                                                : trimmedPrice;

                                            if (value < 0) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedComm: '0% min'
                                                }));
                                            }

                                            if (value > 100) {
                                                const confirmBox =
                                                    await confirmCommissonInput(
                                                        <>
                                                            <p className="fw-bold">
                                                                Rate greater
                                                                than 100%!
                                                            </p>
                                                            <p>
                                                                Is this the
                                                                commission
                                                                value?
                                                            </p>
                                                        </>
                                                    );

                                                if (confirmBox) {
                                                    const commRate =
                                                        (value /
                                                            agreedSellingPrice) *
                                                        100;

                                                    setBuyerAgreedCommValue(
                                                        formatValue(value)
                                                    );

                                                    setBuyerAgreedComm(
                                                        commRate
                                                    );
                                                    setFormattedbuyerAgreedComm(
                                                        formatValueToDecimal(
                                                            commRate
                                                        )
                                                    );
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        buyerAgreedComm:
                                                            value >
                                                            agreedSellingPrice
                                                                ? 'Cannot be more than 100%'
                                                                : '',
                                                        buyerAgreedCommValue:
                                                            value >
                                                            agreedSellingPrice
                                                                ? 'Cannot be more than sale price'
                                                                : ''
                                                    }));
                                                    return;
                                                } else {
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        buyerAgreedComm:
                                                            'Cannot be more than 100%'
                                                    }));

                                                    return;
                                                }
                                            }

                                            const agreedCommissionValue =
                                                (agreedSellingPrice * value) /
                                                100;

                                            setBuyerAgreedCommValue(
                                                formatValue(
                                                    agreedCommissionValue
                                                )
                                            );

                                            setErrors((prev) => ({
                                                ...prev,
                                                buyerAgreedComm: null,
                                                buyerAgreedCommValue: null
                                            }));
                                        }}
                                    />
                                </div>
                                {errors && errors?.buyerAgreedComm && (
                                    <small className="ff-italic text-danger">
                                        {errors?.buyerAgreedComm}
                                    </small>
                                )}
                            </div>
                        </Col>
                        <Col sm="5">
                            <div className="form-control-wrap">
                                <div
                                    className={`input-group ${
                                        errors &&
                                        errors?.buyerAgreedCommValue &&
                                        'border border-danger'
                                    }`}
                                >
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            AED
                                        </span>
                                    </div>
                                    <Input
                                        placeholder="Enter Agreed Commission (AED)"
                                        value={buyerAgreedCommValue}
                                        type="text"
                                        onChange={(e) => {
                                            const value =
                                                +e.target.value?.replaceAll(
                                                    ',',
                                                    ''
                                                );

                                            if (value > 0) {
                                                setBuyerAgreedCommValue(
                                                    formatValue(value)
                                                );

                                                setErrors((prev) => ({
                                                    ...prev,
                                                    buyerAgreedComm: '',
                                                    buyerAgreedCommValue: ''
                                                }));

                                                return;
                                            }

                                            setBuyerAgreedCommValue(
                                                e.target.value
                                            );
                                        }}
                                        onBlur={(e) => {
                                            const value = +e.target.value
                                                ?.trim()
                                                ?.replaceAll(',', '');

                                            const trimmedPrice =
                                                +sellerAgreedSalePrice
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                            const agreedSellingPrice = isNaN(
                                                trimmedPrice
                                            )
                                                ? 0
                                                : trimmedPrice;

                                            if (value > agreedSellingPrice) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    buyerAgreedCommValue:
                                                        'Cannot be more than sale price'
                                                }));
                                                return;
                                            }

                                            const commRate =
                                                (value / agreedSellingPrice) *
                                                100;
                                            setBuyerAgreedComm(commRate);
                                            setFormattedbuyerAgreedComm(
                                                formatValueToDecimal(commRate)
                                            );
                                        }}
                                    />
                                </div>

                                {errors && errors?.buyerAgreedCommValue && (
                                    <small className="ff-italic text-danger">
                                        {errors?.buyerAgreedCommValue}
                                    </small>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Modal
                        size="lg"
                        isOpen={openAddClient}
                        toggle={toggleModalAddClient}
                        backdrop="static"
                    >
                        <ModalHeader
                            toggle={toggleModalAddClient}
                            close={
                                <button
                                    className="close"
                                    onClick={toggleModalAddClient}
                                >
                                    <Icon name="cross" />
                                </button>
                            }
                        >
                            Add New Client
                        </ModalHeader>
                        <ModalBody>
                            <AddLeadStepOne
                                callback={async (data) => {
                                    const type = clientToAddType;

                                    try {
                                        if (data?.currentAgent) {
                                            setBuyerAssignedAgent({
                                                ...data?.currentAgent,
                                                label: `${
                                                    data?.currentAgent?.user
                                                        ?.first_name ?? ''
                                                } ${
                                                    data?.currentAgent?.user
                                                        ?.last_name ?? ''
                                                }`,
                                                value: `${
                                                    data?.currentAgent?.user
                                                        ?.first_name ?? ''
                                                } ${
                                                    data?.currentAgent?.user
                                                        ?.last_name ?? ''
                                                }`
                                            });
                                        }
                                    } catch (err) {
                                        console.log(err);
                                    } finally {
                                        if (type === 'buyer') {
                                            setSelectedBuyer({
                                                ...data,
                                                value: `${
                                                    data?.first_name ?? ''
                                                } ${data?.last_name ?? ''}`,
                                                label: `${
                                                    data?.first_name ?? ''
                                                } ${data?.last_name ?? ''}`
                                            });

                                            setIsAssignedAgentBuyer(true);
                                            setNewBuyerDetails({});

                                            setopenAddClient(!openAddClient);

                                            return;
                                        }

                                        if (type === 'seller') {
                                            const { data: updatedUser } =
                                                await axios.patch(
                                                    `/users/${
                                                        data?.id ?? data?._id
                                                    }`,
                                                    { role: newRoles.Owner }
                                                );

                                            setSelectedSeller({
                                                ...updatedUser,
                                                value: `${updatedUser?.first_name} ${updatedUser?.last_name}`,
                                                label: `${updatedUser?.first_name} ${updatedUser?.last_name}`
                                            });

                                            dispatch(
                                                fetchOwnerProperties(
                                                    axios,
                                                    updatedUser?.id ??
                                                        updatedUser?._id
                                                )
                                            );

                                            setopenAddClient(!openAddClient);

                                            return;
                                        }
                                    }
                                }}
                            />
                        </ModalBody>
                    </Modal>
                </PreviewCard>
            );
        } else if (
            selectedBuyerType &&
            selectedBuyerType.value === DealClientType.External
        ) {
            return (
                <PreviewCard className="bg-lighter">
                    <Row className="gy-2">
                        <Label>Choose Agency *</Label>
                        <Col sm="10">
                            <RSelect
                                options={Agencies}
                                placeholder="Choose Agency"
                                onChange={(data) => {
                                    setSelectedAgencyBuyer(data);
                                    setSelectedBuyer(null);
                                    setErrors((prev) => ({
                                        ...prev,
                                        selectedAgencyBuyer: null
                                    }));
                                }}
                                value={selectedAgencyBuyer}
                                className={
                                    errors &&
                                    errors?.selectedAgencyBuyer &&
                                    'border border-danger'
                                }
                            />

                            {errors && errors?.selectedAgencyBuyer && (
                                <small className="text-danger ff-italic">
                                    {errors?.selectedAgencyBuyer}
                                </small>
                            )}
                        </Col>
                        <Col>
                            <Button
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    toggleModalAddAgency();
                                    setAgencyAddType('buyer');
                                }}
                            >
                                <Icon name="plus"></Icon>
                            </Button>
                        </Col>
                        <Label>Agent Name</Label>
                        <Col sm="11">
                            <Input
                                className={
                                    errors.buyerAgencyAgentName &&
                                    'border border-danger'
                                }
                                placeholder="Enter Agent Name"
                                name="agent"
                                value={buyerAgency?.agent}
                                onChange={(e) => {
                                    const { value } = e.target;

                                    setBuyerAgency((prev) => ({
                                        ...prev,
                                        agent: value
                                    }));

                                    setErrors((prev) => ({
                                        ...prev,
                                        buyerAgencyAgentName:
                                            value?.length > 100
                                                ? 'Name too large. Max of 100 characters allowed'
                                                : ''
                                    }));
                                }}
                            />
                            {errors?.buyerAgencyAgentName && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.buyerAgencyAgentName}
                                    </small>
                                </div>
                            )}
                        </Col>
                        <Label>Agent Phone Number</Label>
                        <Col sm="11">
                            <PhoneInput
                                className={`phoneInput ${
                                    errors?.buyerAgencyAgentPhone &&
                                    'border border-danger'
                                } `}
                                defaultCountry="AE"
                                name="phone"
                                placeholder="Enter Agent Phone Number"
                                onChange={(phone) => {
                                    setBuyerAgency((prev) => ({
                                        ...prev,
                                        agentPhone: phone
                                    }));

                                    setErrors((prev) => ({
                                        ...prev
                                    }));
                                }}
                                onError={(err) => {
                                    setErrors((prev) => ({
                                        ...prev,
                                        buyerAgencyAgentPhone: err
                                    }));
                                }}
                                value={buyerAgency?.agentPhone}
                            />
                            {errors?.buyerAgencyAgentPhone && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.buyerAgencyAgentPhone}
                                    </small>
                                </div>
                            )}
                        </Col>
                        <Label>Client Name</Label>
                        <Col sm="11">
                            <Input
                                className={
                                    errors?.buyerAgencyClientName &&
                                    'border border-danger'
                                }
                                placeholder="Enter Client Name "
                                name="clientName"
                                value={buyerAgency?.clientName}
                                onChange={(e) => {
                                    const { value } = e.target;

                                    setBuyerAgency((prev) => ({
                                        ...prev,
                                        clientName: value
                                    }));

                                    setErrors((prev) => ({
                                        ...prev,
                                        buyerAgencyClientName:
                                            value?.length > 100
                                                ? 'Name too large. Max of 100 characters allowed'
                                                : ''
                                    }));
                                }}
                            />
                            {errors?.buyerAgencyAgentName && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.buyerAgencyClientName}
                                    </small>
                                </div>
                            )}
                        </Col>
                        <Label>Client Phone Number</Label>
                        <Col sm="11">
                            <PhoneInput
                                className={`phoneInput ${
                                    errors?.buyerAgencyClientPhone &&
                                    'border border-danger'
                                } `}
                                defaultCountry="AE"
                                name="phone"
                                placeholder="Enter Client Phone Number"
                                value={buyerAgency?.clientPhone}
                                onChange={(phone) =>
                                    setBuyerAgency((prev) => ({
                                        ...prev,
                                        clientPhone: phone
                                    }))
                                }
                                onError={(err) => {
                                    setErrors((prev) => ({
                                        ...prev,
                                        buyerAgencyClientPhone: err
                                    }));
                                }}
                            />
                            {errors?.buyerAgencyClientPhone && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.buyerAgencyClientPhone}
                                    </small>
                                </div>
                            )}
                        </Col>

                        <Label className="mt-2">Agreed Commission</Label>

                        <Col sm="5">
                            <div className="form-control-wrap">
                                <div
                                    className={`input-group ${
                                        errors &&
                                        errors?.buyerAgreedComm &&
                                        'border border-danger'
                                    }`}
                                >
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            %
                                        </span>
                                    </div>
                                    <Input
                                        max={100}
                                        placeholder="Enter Agreed Commission (%)"
                                        value={formattedbuyerAgreedComm}
                                        type="number"
                                        onKeyDown={(e) => {
                                            const notAllowedKeys = [
                                                'e',
                                                'E',
                                                '+',
                                                '-'
                                            ];

                                            if (
                                                notAllowedKeys.includes(e.key)
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setBuyerAgreedComm(e.target.value);
                                            setFormattedbuyerAgreedComm(
                                                e.target.value
                                            );
                                            setErrors((prev) => ({
                                                ...prev,
                                                buyerAgreedComm: ''
                                            }));
                                        }}
                                        onBlur={async (e) => {
                                            const value = +e.target.value;

                                            const trimmedPrice =
                                                +sellerAgreedSalePrice
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                            const agreedSellingPrice = isNaN(
                                                trimmedPrice
                                            )
                                                ? 0
                                                : trimmedPrice;

                                            if (value < 0) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedComm: '0% min'
                                                }));
                                            }

                                            if (value > 100) {
                                                const confirmBox =
                                                    await confirmCommissonInput(
                                                        <>
                                                            <p className="fw-bold">
                                                                Rate greater
                                                                than 100%!
                                                            </p>
                                                            <p>
                                                                Is this the
                                                                commission
                                                                value?
                                                            </p>
                                                        </>
                                                    );

                                                if (confirmBox) {
                                                    const commRate =
                                                        (value /
                                                            agreedSellingPrice) *
                                                        100;

                                                    setBuyerAgreedCommValue(
                                                        formatValue(value)
                                                    );

                                                    setBuyerAgreedComm(
                                                        commRate
                                                    );
                                                    setFormattedbuyerAgreedComm(
                                                        formatValueToDecimal(
                                                            commRate
                                                        )
                                                    );
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        buyerAgreedComm:
                                                            value >
                                                            agreedSellingPrice
                                                                ? 'Cannot be more than 100%'
                                                                : '',
                                                        buyerAgreedCommValue:
                                                            value >
                                                            agreedSellingPrice
                                                                ? 'Cannot be more than sale price'
                                                                : ''
                                                    }));
                                                    return;
                                                } else {
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        buyerAgreedComm:
                                                            'Cannot be more than 100%'
                                                    }));

                                                    return;
                                                }
                                            }

                                            const agreedCommissionValue =
                                                (agreedSellingPrice * value) /
                                                100;

                                            setBuyerAgreedCommValue(
                                                formatValue(
                                                    agreedCommissionValue
                                                )
                                            );

                                            setErrors((prev) => ({
                                                ...prev,
                                                buyerAgreedComm: null,
                                                buyerAgreedCommValue: null
                                            }));
                                        }}
                                    />
                                </div>
                                {errors && errors?.buyerAgreedComm && (
                                    <small className="ff-italic text-danger">
                                        {errors?.buyerAgreedComm}
                                    </small>
                                )}
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="form-control-wrap">
                                <div
                                    className={`input-group ${
                                        errors &&
                                        errors?.buyerAgreedCommValue &&
                                        'border border-danger'
                                    }`}
                                >
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            AED
                                        </span>
                                    </div>
                                    <Input
                                        placeholder="Enter Agreed Commission (AED)"
                                        value={buyerAgreedCommValue}
                                        type="text"
                                        onChange={(e) => {
                                            const value =
                                                +e.target.value?.replaceAll(
                                                    ',',
                                                    ''
                                                );

                                            if (value > 0) {
                                                setBuyerAgreedCommValue(
                                                    formatValue(value)
                                                );

                                                setErrors((prev) => ({
                                                    ...prev,
                                                    buyerAgreedComm: '',
                                                    buyerAgreedCommValue: ''
                                                }));

                                                return;
                                            }

                                            setBuyerAgreedCommValue(
                                                e.target.value
                                            );
                                        }}
                                        onBlur={(e) => {
                                            const value = +e.target.value
                                                ?.trim()
                                                ?.replaceAll(',', '');

                                            const trimmedPrice =
                                                +sellerAgreedSalePrice
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                            const agreedSellingPrice = isNaN(
                                                trimmedPrice
                                            )
                                                ? 0
                                                : trimmedPrice;

                                            if (value > agreedSellingPrice) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    buyerAgreedCommValue:
                                                        'Cannot be more than sale price'
                                                }));
                                                return;
                                            }

                                            const commRate =
                                                (value / agreedSellingPrice) *
                                                100;
                                            setBuyerAgreedComm(commRate);
                                            setFormattedbuyerAgreedComm(
                                                formatValueToDecimal(commRate)
                                            );
                                        }}
                                    />
                                </div>

                                {errors && errors?.buyerAgreedCommValue && (
                                    <small className="ff-italic text-danger">
                                        {errors?.buyerAgreedCommValue}
                                    </small>
                                )}
                            </div>
                        </Col>
                    </Row>
                </PreviewCard>
            );
        }
        return null;
    };

    const renderSellersRows = () => {
        if (
            selectedSellerType &&
            selectedSellerType.value === DealClientType.Alba
        ) {
            return (
                <PreviewCard className="bg-lighter">
                    <Row className="gy-1">
                        <Label>Choose from Our Clients</Label>
                        <Col sm="10">
                            <SearchClientSelect
                                onlyOwners
                                placeholder="Search clients by name or phone"
                                onChange={(seller) => {
                                    setSelectedSeller(seller);
                                    setSelectedAgencySeller(null);
                                    setSelectedProperty(null);
                                    setErrors((prev) => ({
                                        ...prev,
                                        selectedSeller: null
                                    }));
                                }}
                                value={selectedSeller}
                                excludeClient={selectedBuyer?.id} // Exclude the selected buyer from this list
                                className={
                                    errors &&
                                    errors?.selectedSeller &&
                                    'border border-danger'
                                }
                            />
                            {errors && errors?.selectedSeller && (
                                <small className="ff-italic text-danger">
                                    {errors?.selectedSeller}
                                </small>
                            )}
                        </Col>
                        <Col>
                            <Button
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    toggleModalAddClient();
                                    setClientToAddType('seller');
                                }}
                            >
                                <Icon name="plus"></Icon>
                            </Button>{' '}
                        </Col>
                        <Label>Choose Property</Label>
                        <Col sm="10">
                            <RSelect
                                options={
                                    ownerProperties[
                                        selectedSeller?.id ??
                                            selectedSeller?._id
                                    ]
                                        ? ownerProperties[
                                              selectedSeller?.id ??
                                                  selectedSeller?._id
                                          ]?.map((prop) => ({
                                              ...prop,
                                              label: `${prop?.referNo} - ${
                                                  prop?.beds
                                              } ${
                                                  !isNaN(Number(prop?.beds))
                                                      ? 'BR'
                                                      : ''
                                              } - ${prop?.propertyTitle} - ${
                                                  prop?.communityName
                                              }`
                                          }))
                                        : []
                                }
                                placeholder="Choose Property"
                                onChange={async (prop) => {
                                    const propertyAgent =
                                        await getPropertyAgent(prop?.agent_id);

                                    setSelectedProperty({
                                        ...prop,
                                        agent: propertyAgent
                                    });

                                    const propertyValue =
                                        prop?.askingPrice ??
                                        prop?.oneCheqPrice ??
                                        prop?.twoCheqPrice ??
                                        prop?.fourCheqPrice ??
                                        prop?.sixCheqPrice ??
                                        prop?.twelveCheqprice ??
                                        0;

                                    setSellerAgreedSalePrice(
                                        propertyValue
                                            ? formatValue(propertyValue)
                                            : null
                                    );

                                    setErrors((prev) => ({
                                        ...prev,
                                        property: null
                                    }));

                                    prop?.commission &&
                                        setSellerAgreedComm(prop?.commission);
                                    prop?.commission &&
                                        setFormattedsellerAgreedComm(
                                            prop?.commission
                                        );

                                    prop?.commission &&
                                        propertyValue &&
                                        setSellerAgreedCommValue(
                                            formatValue(
                                                Math.round(
                                                    (propertyValue *
                                                        Number(
                                                            prop?.commission
                                                        )) /
                                                        100
                                                )
                                            )
                                        );

                                    prop?.commission &&
                                        propertyValue &&
                                        Number(buyerAgreedComm) &&
                                        !isNaN(Number(buyerAgreedComm)) &&
                                        setBuyerAgreedCommValue(
                                            formatValue(
                                                Math.round(
                                                    (propertyValue *
                                                        Number(
                                                            buyerAgreedComm
                                                        )) /
                                                        100
                                                )
                                            )
                                        );

                                    setSellerAssignedAgent(
                                        propertyAgent
                                            ? {
                                                  ...propertyAgent,
                                                  value: `${propertyAgent?.user?.first_name} ${propertyAgent?.user?.last_name}`,
                                                  label: `${propertyAgent?.user?.first_name} ${propertyAgent?.user?.last_name}`
                                              }
                                            : null
                                    );
                                    setIsAssignedAgentSeller(
                                        propertyAgent ? true : false
                                    );
                                }}
                                value={selectedProperty}
                                className={
                                    errors &&
                                    errors?.property &&
                                    'border border-danger'
                                }
                                menuPlacement="auto"
                                // inputValue={searchPropertyText}
                                // isLoading={isSearchingProperty}
                                // menuIsOpen={searchPropertyText?.length}
                                // loadingMessage={() => (
                                //     <Spinner size="sm" color="light" />
                                // )}
                                onInputChange={(txt) => {
                                    setIsSearchingProperty(true);
                                    debouncedSearchProperty(txt);
                                    setSearchPropertyText(txt);
                                }}
                                // noOptionsMessage={() =>
                                //   !selectedSeller?.id && !selectedSeller?._id
                                //     ? 'Please select a Seller/Landlord'
                                //     : 'No properties'
                                // }
                            />
                            {errors && errors?.property && (
                                <small className="ff-italic text-danger">
                                    {errors?.property}
                                </small>
                            )}
                        </Col>
                        <Col>
                            <Button
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    toggleModalAddProperty();
                                }}
                            >
                                <Icon name="plus"></Icon>
                            </Button>
                        </Col>

                        {selectedProperty && (
                            <>
                                <Label>Assigned Agent</Label>
                                <Col sm="10">
                                    <RSelect
                                        options={
                                            agents && agents?.length
                                                ? agents?.map((agent) => ({
                                                      ...agent,
                                                      ...agent,
                                                      value: `${agent?.user?.first_name} ${agent?.user?.last_name}`,
                                                      label: `${agent?.user?.first_name} ${agent?.user?.last_name}`
                                                  }))
                                                : []
                                        }
                                        placeholder="Select Agent"
                                        onChange={(agent) => {
                                            setSellerAssignedAgent(agent);

                                            if (
                                                agent?.id ===
                                                selectedProperty?.agent?.id
                                            ) {
                                                setIsAssignedAgentSeller(true);
                                                return;
                                            }
                                            setIsAssignedAgentSeller(false);
                                        }}
                                        value={sellerAssignedAgent}
                                        className={
                                            errors &&
                                            errors?.sellerAssignedAgent &&
                                            'border border-danger'
                                        }
                                    />
                                    {errors && errors?.sellerAssignedAgent && (
                                        <small className="ff-italic text-danger">
                                            {errors?.sellerAssignedAgent}
                                        </small>
                                    )}
                                </Col>
                                {!isAssignedAgentSeller && (
                                    <Col>
                                        <Button
                                            onClick={async (e) => {
                                                try {
                                                    const isConfirm =
                                                        await isConfirmed2(
                                                            <>
                                                                <h6 className="text-center mb-1">
                                                                    <b>
                                                                        Property
                                                                        not
                                                                        assigned
                                                                        to
                                                                        selected
                                                                        agent
                                                                    </b>
                                                                </h6>
                                                                <div>
                                                                    By clicking{' '}
                                                                    <b>
                                                                        Continue
                                                                    </b>
                                                                    , you agree
                                                                    to assign
                                                                    selected
                                                                    property and
                                                                    selected
                                                                    property
                                                                    owner to
                                                                    selected
                                                                    agent.
                                                                </div>
                                                            </>
                                                        );

                                                    if (!isConfirm) {
                                                        return;
                                                    }

                                                    setIsLoading(true);

                                                    const assignedProperty =
                                                        await handleAssignAgentToProperty();

                                                    // const assignedPropertyOwner =
                                                    //     await handleAssignAgentToPropertyOwner();

                                                    setIsAssignedAgentSeller(
                                                        true
                                                    );
                                                    setSelectedProperty({
                                                        ...assignedProperty,
                                                        agent: sellerAssignedAgent,
                                                        label: assignedProperty?.propertyTitle
                                                    });
                                                } catch (err) {
                                                    console.log(err?.message);
                                                    // if err.message === failed to assign owner ? that means it failed to assign agent to owner, thus we want to reassign property back to previously assigned agent (if any) or null
                                                    // if (
                                                    //     err?.message ===
                                                    //     "failed to assign owner"
                                                    // ) {
                                                    //     await handleUnAssignPropertyAgent();
                                                    // }

                                                    toast.error(
                                                        'Unexpected, failed to assign property to agent.'
                                                    );
                                                    setIsAssignedAgentSeller(
                                                        false
                                                    );
                                                } finally {
                                                    setIsLoading(false);
                                                }
                                            }}
                                        >
                                            {isLoading ? (
                                                <>
                                                    <Spinner /> Please Wait
                                                </>
                                            ) : (
                                                'Assign'
                                            )}
                                        </Button>
                                    </Col>
                                )}
                            </>
                        )}

                        <Col sm="10" className="mt-1">
                            <Label>Agreed Sale Price</Label>
                            <div className="form-control-wrap">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            AED
                                        </span>
                                    </div>
                                    <Input
                                        className={
                                            errors?.sellerAgreedSalePrice &&
                                            'border border-danger'
                                        }
                                        placeholder="Enter Agreed Sale Price"
                                        value={sellerAgreedSalePrice}
                                        type="text"
                                        onChange={(e) => {
                                            const value =
                                                +e.target.value?.replaceAll(
                                                    ',',
                                                    ''
                                                );

                                            // if (
                                            //     isNaN( Number( e.target.value ) ) ||
                                            //     value <= 0
                                            // )
                                            // {
                                            //     setErrors( ( prv ) => ( {
                                            //         ...prv,
                                            //         sellerAgreedSalePrice:
                                            //             "Value cannot be less than or equal to zero",
                                            //     } ) );
                                            // }

                                            if (value > 0) {
                                                setErrors((prv) => ({
                                                    ...prv,
                                                    sellerAgreedSalePrice: null
                                                }));

                                                setSellerAgreedSalePrice(
                                                    formatValue(value)
                                                );
                                                return;
                                            }

                                            setSellerAgreedSalePrice(
                                                e.target.value
                                            );
                                        }}
                                        onBlur={(e) => {
                                            const value = +e.target.value
                                                ?.replaceAll(',', '')
                                                ?.trim();

                                            if (isNaN(value) || value <= 0) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedSalePrice:
                                                        value <= 0
                                                            ? 'Sale price cannot be less than zero or empty or zero'
                                                            : 'Invalid sale price'
                                                }));
                                                return;
                                            }

                                            // if sale price is greater than biggest integer, we throw error
                                            if (
                                                value > Number.MAX_SAFE_INTEGER
                                            ) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedSalePrice:
                                                        'Sale price is too large'
                                                }));
                                                return;
                                            }

                                            const buyerAgreedCommRate =
                                                +buyerAgreedComm;

                                            const sellerAgreedCommValue =
                                                Math.round(
                                                    (value *
                                                        Number(
                                                            sellerAgreedComm
                                                        )) /
                                                        100
                                                );

                                            if (
                                                buyerAgreedCommRate &&
                                                buyerAgreedCommRate > 0 &&
                                                buyerAgreedCommRate <= 100
                                            ) {
                                                setBuyerAgreedCommValue(
                                                    formatValue(
                                                        Math.round(
                                                            (value *
                                                                buyerAgreedCommRate) /
                                                                100
                                                        )
                                                    )
                                                );
                                            }

                                            if (
                                                +sellerAgreedComm &&
                                                +sellerAgreedComm > 0 &&
                                                +sellerAgreedComm <= 100
                                            ) {
                                                setSellerAgreedCommValue(
                                                    formatValue(
                                                        sellerAgreedCommValue
                                                    )
                                                );
                                            }
                                        }}
                                    />
                                </div>
                                {errors?.sellerAgreedSalePrice && (
                                    <small className="ff-italic text-danger">
                                        {errors.sellerAgreedSalePrice}
                                    </small>
                                )}
                            </div>
                        </Col>

                        <Col sm="12" className="mt-1">
                            <Label>Agreed Commission</Label>
                        </Col>

                        <Col sm="5">
                            <div className="form-control-wrap">
                                <div
                                    className={`input-group ${
                                        errors?.sellerAgreedComm &&
                                        'border border-danger'
                                    }`}
                                >
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            %
                                        </span>
                                    </div>
                                    <Input
                                        max={100}
                                        placeholder="Enter Agreed Commission (%)"
                                        value={formattedsellerAgreedComm}
                                        type="number"
                                        onKeyDown={(e) => {
                                            const notAllowedKeys = [
                                                'e',
                                                'E',
                                                '+',
                                                '-'
                                            ];

                                            if (
                                                notAllowedKeys.includes(e.key)
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSellerAgreedComm(e.target.value);
                                            setFormattedsellerAgreedComm(
                                                e.target.value
                                            );
                                            setErrors((prev) => ({
                                                ...prev,
                                                sellerAgreedComm: null
                                            }));
                                        }}
                                        onBlur={async (e) => {
                                            const value = +e.target.value;

                                            const trimmedPrice =
                                                +sellerAgreedSalePrice
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                            const agreedSellingPrice = isNaN(
                                                trimmedPrice
                                            )
                                                ? 0
                                                : trimmedPrice;

                                            if (value < 0) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedComm: '0% min'
                                                }));
                                            }

                                            if (value > 100) {
                                                const confirmBox =
                                                    await confirmCommissonInput(
                                                        <>
                                                            <p className="fw-bold">
                                                                Rate greater
                                                                than 100%!
                                                            </p>
                                                            <p>
                                                                Is this the
                                                                commission
                                                                value?
                                                            </p>
                                                        </>
                                                    );

                                                if (confirmBox) {
                                                    const commRate =
                                                        (value /
                                                            agreedSellingPrice) *
                                                        100;

                                                    setSellerAgreedCommValue(
                                                        formatValue(value)
                                                    );

                                                    setSellerAgreedComm(
                                                        commRate
                                                    );
                                                    setFormattedsellerAgreedComm(
                                                        formatValueToDecimal(
                                                            commRate
                                                        )
                                                    );
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        sellerAgreedComm: null,
                                                        sellerAgreedCommValue:
                                                            null
                                                    }));
                                                    return;
                                                } else {
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        sellerAgreedComm:
                                                            'Cannot be more than 100%'
                                                    }));

                                                    return;
                                                }
                                            }

                                            const agreedCommissionValue =
                                                (agreedSellingPrice * value) /
                                                100;

                                            setSellerAgreedCommValue(
                                                formatValue(
                                                    agreedCommissionValue
                                                )
                                            );

                                            setErrors((prev) => ({
                                                ...prev,
                                                sellerAgreedComm: null,
                                                sellerAgreedCommValue: null
                                            }));
                                        }}
                                    />
                                </div>
                                {errors && errors?.sellerAgreedComm && (
                                    <small className="ff-italic text-danger">
                                        {errors?.sellerAgreedComm}
                                    </small>
                                )}
                            </div>
                        </Col>

                        <Col sm="5">
                            <div className="form-control-wrap">
                                <div
                                    className={`input-group ${
                                        errors?.sellerAgreedCommValue &&
                                        'border border-danger'
                                    }`}
                                >
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            AED
                                        </span>
                                    </div>
                                    <Input
                                        placeholder="Enter Agreed Commission (AED)"
                                        value={sellerAgreedCommValue}
                                        type="text"
                                        onChange={(e) => {
                                            const value =
                                                +e.target.value?.replaceAll(
                                                    ',',
                                                    ''
                                                );

                                            if (value > 0) {
                                                setSellerAgreedCommValue(
                                                    formatValue(value)
                                                );
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedComm: null,
                                                    sellerAgreedCommValue: null
                                                }));
                                                return;
                                            }

                                            setSellerAgreedCommValue(
                                                e.target.value
                                            );
                                        }}
                                        onBlur={(e) => {
                                            const value = +e.target.value
                                                ?.trim()
                                                ?.replaceAll(',', '');

                                            const trimmedPrice =
                                                +sellerAgreedSalePrice
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                            const agreedSellingPrice = isNaN(
                                                trimmedPrice
                                            )
                                                ? 0
                                                : trimmedPrice;

                                            if (value > agreedSellingPrice) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedCommValue:
                                                        'Cannot be more than sale price'
                                                }));
                                                return;
                                            }

                                            const sellerAgreedComm =
                                                (value / agreedSellingPrice ??
                                                    1) * 100;

                                            setSellerAgreedComm(
                                                sellerAgreedComm
                                            );
                                            setFormattedsellerAgreedComm(
                                                formatValueToDecimal(
                                                    sellerAgreedComm
                                                )
                                            );
                                        }}
                                    />
                                </div>
                                {errors && errors?.sellerAgreedCommValue && (
                                    <small className="ff-italic text-danger">
                                        {errors?.sellerAgreedCommValue}
                                    </small>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Modal
                        size="lg"
                        isOpen={openAddClient}
                        toggle={toggleModalAddClient}
                        backdrop="static"
                    >
                        <ModalHeader
                            toggle={toggleModalAddClient}
                            close={
                                <button
                                    className="close"
                                    onClick={toggleModalAddClient}
                                >
                                    <Icon name="cross" />
                                </button>
                            }
                        >
                            Add New Client
                        </ModalHeader>
                        <ModalBody>
                            <AddLeadStepOne
                                callback={async (data) => {
                                    const type = clientToAddType;

                                    try {
                                        if (data?.currentAgent) {
                                            setBuyerAssignedAgent({
                                                ...data?.currentAgent,
                                                label: `${
                                                    data?.currentAgent?.user
                                                        ?.first_name ?? ''
                                                } ${
                                                    data?.currentAgent?.user
                                                        ?.last_name ?? ''
                                                }`,
                                                value: `${
                                                    data?.currentAgent?.user
                                                        ?.first_name ?? ''
                                                } ${
                                                    data?.currentAgent?.user
                                                        ?.last_name ?? ''
                                                }`
                                            });
                                        }
                                    } catch (err) {
                                        console.log(err);
                                    } finally {
                                        if (type === 'buyer') {
                                            setSelectedBuyer({
                                                ...data,
                                                value: `${
                                                    data?.first_name ?? ''
                                                } ${data?.last_name ?? ''}`,
                                                label: `${
                                                    data?.first_name ?? ''
                                                } ${data?.last_name ?? ''}`
                                            });

                                            setIsAssignedAgentBuyer(true);
                                            setNewBuyerDetails({});

                                            setopenAddClient(!openAddClient);

                                            return;
                                        }

                                        if (type === 'seller') {
                                            const { data: updatedUser } =
                                                await axios.patch(
                                                    `/users/${
                                                        data?.id ?? data?._id
                                                    }`,
                                                    { role: newRoles.Owner }
                                                );

                                            setSelectedSeller({
                                                ...updatedUser,
                                                value: `${updatedUser?.first_name} ${updatedUser?.last_name}`,
                                                label: `${updatedUser?.first_name} ${updatedUser?.last_name}`
                                            });

                                            dispatch(
                                                fetchOwnerProperties(
                                                    axios,
                                                    updatedUser?.id ??
                                                        updatedUser?._id
                                                )
                                            );

                                            setopenAddClient(!openAddClient);

                                            return;
                                        }
                                    }
                                }}
                            />
                        </ModalBody>
                    </Modal>
                    <Modal
                        size="xl"
                        isOpen={openAddProperty}
                        toggle={toggleModalAddProperty}
                        backdrop="static"
                    >
                        <ModalHeader
                            toggle={toggleModalAddProperty}
                            close={
                                <button
                                    className="close"
                                    onClick={toggleModalAddProperty}
                                >
                                    <Icon name="cross" />
                                </button>
                            }
                        >
                            Add New Property
                        </ModalHeader>
                        <ModalBody>
                            <Card className="card-bordered">
                                <PreviewCard>
                                    <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                                        <Form property={property} type="add" />
                                    </div>
                                </PreviewCard>
                            </Card>
                        </ModalBody>
                    </Modal>
                </PreviewCard>
            );
        } else if (
            selectedSellerType &&
            selectedSellerType.value === DealClientType.External
        ) {
            return (
                <PreviewCard className="bg-lighter">
                    <Row className="gy-2">
                        <Label>Choose Agency *</Label>
                        <Col sm="10">
                            <RSelect
                                options={Agencies}
                                placeholder="Choose Agency"
                                value={selectedAgencySeller}
                                onChange={(agent) => {
                                    setSelectedAgencySeller(agent);
                                    setSelectedSeller(null);
                                    setErrors((prev) => ({
                                        ...prev,
                                        selectedAgencySeller: null
                                    }));
                                }}
                                className={
                                    errors &&
                                    errors?.selectedAgencySeller &&
                                    'border border-danger'
                                }
                            />
                            {errors && errors?.selectedAgencySeller && (
                                <small className="text-danger ff-italic">
                                    {errors?.selectedAgencySeller}
                                </small>
                            )}
                        </Col>
                        <Col>
                            <Button
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    toggleModalAddAgency();
                                    setAgencyAddType('seller');
                                }}
                            >
                                <Icon name="plus"></Icon>
                            </Button>
                        </Col>
                        <Label>Agent Name</Label>
                        <Col sm="11">
                            <Input
                                className={
                                    errors?.sellerAgencyAgentName &&
                                    'border border-danger'
                                }
                                placeholder="Enter Agent Name"
                                value={sellerAgency?.agentName}
                                onChange={(e) => {
                                    const value = e.target.value;

                                    setSellerAgency((prev) => ({
                                        ...prev,
                                        agentName: value
                                    }));

                                    setErrors((prev) => ({
                                        ...prev,
                                        sellerAgencyAgentName:
                                            value?.length > 100
                                                ? 'Name too large. Max of 100 characters allowed'
                                                : ''
                                    }));
                                }}
                            />
                            {errors?.sellerAgencyAgentName && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.sellerAgencyAgentName}
                                    </small>
                                </div>
                            )}
                        </Col>
                        <Label>Agent Phone Number</Label>
                        <Col sm="11">
                            <PhoneInput
                                className={`phoneInput ${
                                    errors?.sellerAgencyAgentPhone &&
                                    'border border-danger'
                                } `}
                                defaultCountry="AE"
                                name="phone"
                                placeholder="Enter Agent Phone Number"
                                value={sellerAgency?.agentPhone}
                                onChange={(phone) =>
                                    setSellerAgency((prev) => ({
                                        ...prev,
                                        agentPhone: phone
                                    }))
                                }
                                onError={(err) => {
                                    setErrors((prev) => ({
                                        ...prev,
                                        sellerAgencyAgentPhone: err
                                    }));
                                }}
                            />
                            {errors?.sellerAgencyAgentPhone && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.sellerAgencyAgentPhone}
                                    </small>
                                </div>
                            )}
                        </Col>
                        <Label>Client Name</Label>
                        <Col sm="11">
                            <Input
                                className={
                                    errors?.sellerAgencyClientName &&
                                    'border border-danger'
                                }
                                placeholder="Enter Client Name "
                                value={sellerAgency?.clientName}
                                onChange={(e) => {
                                    const { value } = e.target;

                                    setSellerAgency((prev) => ({
                                        ...prev,
                                        clientName: value
                                    }));

                                    setErrors((prev) => ({
                                        ...prev,
                                        sellerAgencyClientName:
                                            value?.length > 100
                                                ? 'Name too large. Max of 100 characters allowed'
                                                : ''
                                    }));
                                }}
                            />
                            {errors?.sellerAgencyClientName && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.sellerAgencyClientName}
                                    </small>
                                </div>
                            )}
                        </Col>
                        <Label>Client Phone Number</Label>
                        <Col sm="11">
                            <PhoneInput
                                className={`phoneInput ${
                                    errors?.sellerAgencyClientPhone &&
                                    'border border-danger'
                                } `}
                                defaultCountry="AE"
                                name="phone"
                                placeholder="Enter Client Phone Number"
                                value={sellerAgency?.clientPhone}
                                onChange={(phone) =>
                                    setSellerAgency((prev) => ({
                                        ...prev,
                                        clientPhone: phone
                                    }))
                                }
                                onError={(err) => {
                                    setErrors((prev) => ({
                                        ...prev,
                                        sellerAgencyClientPhone: err
                                    }));
                                }}
                            />
                            {errors?.sellerAgencyClientPhone && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.sellerAgencyClientPhone}
                                    </small>
                                </div>
                            )}
                        </Col>
                        <Col
                            sm="12"
                            className="bg-secondary mt-4 mb-3 pt-2 pb-2 rounded"
                        >
                            <h6 className="text-white">Property Details</h6>{' '}
                        </Col>
                        <Col sm="11">
                            <Label>Type of Property</Label>
                            <RSelect
                                options={propertyTypes?.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                value={propertyDetails?.propertyType}
                                onChange={(property) => {
                                    setPropertyDetails((prev) => ({
                                        ...prev,
                                        propertyType: property
                                    }));
                                    setErrors((prev) => ({
                                        ...prev,
                                        propertyType: null
                                    }));
                                }}
                                className={
                                    errors &&
                                    errors?.propertyType &&
                                    'border border-danger'
                                }
                            />
                        </Col>
                        <Col sm="11">
                            <Label>Beds</Label>
                            <RSelect
                                options={BedRooms?.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                value={propertyDetails?.beds}
                                onChange={(bed) => {
                                    setPropertyDetails((prev) => ({
                                        ...prev,
                                        beds: bed
                                    }));
                                    setErrors((prev) => ({
                                        ...prev,
                                        beds: null
                                    }));
                                }}
                                className={
                                    errors &&
                                    errors?.beds &&
                                    'border border-danger'
                                }
                            ></RSelect>
                        </Col>
                        <Col sm="11">
                            <Label>Baths</Label>
                            <RSelect
                                options={BathRooms?.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                value={propertyDetails?.baths}
                                onChange={(bath) => {
                                    setPropertyDetails((prev) => ({
                                        ...prev,
                                        baths: bath
                                    }));
                                    setErrors((prev) => ({
                                        ...prev,
                                        baths: null
                                    }));
                                }}
                                className={
                                    errors &&
                                    errors?.baths &&
                                    'border border-danger'
                                }
                            ></RSelect>
                        </Col>
                        <Col sm="11">
                            <Label>Size</Label>
                            <div className="form-control-wrap">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            Sqft
                                        </span>
                                    </div>
                                    <Input
                                        type="number"
                                        placeholder="Enter Size"
                                        value={propertyDetails?.size}
                                        onKeyDown={(e) => {
                                            const notAllowedKeys = [
                                                'e',
                                                'E',
                                                '+',
                                                '-'
                                            ];

                                            if (
                                                notAllowedKeys.includes(e.key)
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            const isNumber = !isNaN(
                                                Number(value)
                                            );

                                            setPropertyDetails((prev) => ({
                                                ...prev,
                                                size: e?.target?.value
                                            }));

                                            setErrors((prev) => ({
                                                ...prev,
                                                size:
                                                    value?.length > 10 ||
                                                    !isNumber ||
                                                    (+value <= 0 &&
                                                        value?.length > 0)
                                                        ? 'Invalid size'
                                                        : ''
                                            }));
                                        }}
                                        className={
                                            errors &&
                                            errors.size &&
                                            'border border-danger'
                                        }
                                    />
                                </div>
                                {errors && errors?.size && (
                                    <small className="text-danger ff-italic">
                                        {errors?.size}
                                    </small>
                                )}
                            </div>
                        </Col>
                        <Col sm="11">
                            <Label>Community</Label>
                            <RSelect
                                options={
                                    communities && communities?.length
                                        ? communities?.map((comm) => ({
                                              ...comm,
                                              value: comm?.community,
                                              label: comm?.community
                                          }))
                                        : []
                                }
                                value={propertyDetails?.community}
                                onChange={(community) => {
                                    setPropertyDetails((prev) => ({
                                        ...prev,
                                        community: community
                                    }));
                                    setErrors((prev) => ({
                                        ...prev,
                                        community: null
                                    }));
                                }}
                                className={
                                    errors &&
                                    errors?.community &&
                                    'border border-danger'
                                }
                            ></RSelect>
                        </Col>
                        <Col sm="11">
                            <Label>Sub Community</Label>
                            <Input
                                className={
                                    errors.subCommunity &&
                                    'border border-danger'
                                }
                                placeholder="Enter Sub Community"
                                value={propertyDetails?.sub_community}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    setPropertyDetails((prev) => ({
                                        ...prev,
                                        sub_community: value
                                    }));

                                    setErrors((prev) => ({
                                        ...prev,
                                        subCommunity:
                                            value?.length > 100
                                                ? 'Max of 100 characters allowed'
                                                : ''
                                    }));
                                }}
                            />
                            {errors.subCommunity && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.subCommunity}
                                    </small>
                                </div>
                            )}
                        </Col>
                        <Col sm="11">
                            <Label>Building Name</Label>
                            <RSelect
                                options={
                                    buildings && buildings?.length
                                        ? buildings?.map((bui) => ({
                                              ...bui,
                                              label: bui?.name,
                                              value: bui?.name
                                          }))
                                        : []
                                }
                                onChange={(building) =>
                                    setPropertyDetails((prev) => ({
                                        ...prev,
                                        building: building
                                    }))
                                }
                            ></RSelect>
                        </Col>

                        <Col sm="10" className="mt-1">
                            <Label>Agreed Sale Price</Label>
                            <div className="form-control-wrap">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            AED
                                        </span>
                                    </div>
                                    <Input
                                        className={
                                            errors?.sellerAgreedSalePrice &&
                                            'border border-danger'
                                        }
                                        placeholder="Enter Agreed Sale Price"
                                        value={sellerAgreedSalePrice}
                                        type="text"
                                        onChange={(e) => {
                                            const value =
                                                +e.target.value?.replaceAll(
                                                    ',',
                                                    ''
                                                );

                                            // if (
                                            //     isNaN( Number( e.target.value ) ) ||
                                            //     value <= 0
                                            // )
                                            // {
                                            //     setErrors( ( prv ) => ( {
                                            //         ...prv,
                                            //         sellerAgreedSalePrice:
                                            //             "Value cannot be less than or equal to zero",
                                            //     } ) );
                                            // }

                                            if (value > 0) {
                                                setErrors((prv) => ({
                                                    ...prv,
                                                    sellerAgreedSalePrice: null
                                                }));

                                                setSellerAgreedSalePrice(
                                                    formatValue(value)
                                                );
                                                return;
                                            }

                                            setSellerAgreedSalePrice(
                                                e.target.value
                                            );
                                        }}
                                        onBlur={(e) => {
                                            const value = +e.target.value
                                                ?.replaceAll(',', '')
                                                ?.trim();

                                            if (isNaN(value) || value <= 0) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedSalePrice:
                                                        value <= 0
                                                            ? 'Sale price cannot be less than zero or empty or zero'
                                                            : 'Invalid sale price'
                                                }));
                                                return;
                                            }

                                            // if sale price is greater than biggest integer, we throw error
                                            if (
                                                value > Number.MAX_SAFE_INTEGER
                                            ) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedSalePrice:
                                                        'Sale price is too large'
                                                }));
                                                return;
                                            }

                                            const buyerAgreedCommRate =
                                                +buyerAgreedComm;

                                            const sellerAgreedCommValue =
                                                Math.round(
                                                    (value *
                                                        Number(
                                                            sellerAgreedComm
                                                        )) /
                                                        100
                                                );

                                            if (
                                                buyerAgreedCommRate &&
                                                buyerAgreedCommRate > 0 &&
                                                buyerAgreedCommRate <= 100
                                            ) {
                                                setBuyerAgreedCommValue(
                                                    formatValue(
                                                        Math.round(
                                                            (value *
                                                                buyerAgreedCommRate) /
                                                                100
                                                        )
                                                    )
                                                );
                                            }

                                            if (
                                                +sellerAgreedComm &&
                                                +sellerAgreedComm > 0 &&
                                                +sellerAgreedComm <= 100
                                            ) {
                                                setSellerAgreedCommValue(
                                                    formatValue(
                                                        sellerAgreedCommValue
                                                    )
                                                );
                                            }
                                        }}
                                    />
                                </div>
                                {errors?.sellerAgreedSalePrice && (
                                    <small className="ff-italic text-danger">
                                        {errors.sellerAgreedSalePrice}
                                    </small>
                                )}
                            </div>
                        </Col>

                        <Col sm="11" className="mt-1">
                            <Label>Agreed Commission</Label>
                        </Col>

                        <Col sm="5">
                            <div className="form-control-wrap">
                                <div
                                    className={`input-group ${
                                        errors?.sellerAgreedComm &&
                                        'border border-danger'
                                    }`}
                                >
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            %
                                        </span>
                                    </div>
                                    <Input
                                        max={100}
                                        placeholder="Enter Agreed Commission (%)"
                                        value={formattedsellerAgreedComm}
                                        type="number"
                                        onKeyDown={(e) => {
                                            const notAllowedKeys = [
                                                'e',
                                                'E',
                                                '+',
                                                '-'
                                            ];

                                            if (
                                                notAllowedKeys.includes(e.key)
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSellerAgreedComm(e.target.value);
                                            setFormattedsellerAgreedComm(
                                                e.target.value
                                            );
                                            setErrors((prev) => ({
                                                ...prev,
                                                sellerAgreedComm: null
                                            }));
                                        }}
                                        onBlur={async (e) => {
                                            const value = +e.target.value;

                                            const trimmedPrice =
                                                +sellerAgreedSalePrice
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                            const agreedSellingPrice = isNaN(
                                                trimmedPrice
                                            )
                                                ? 0
                                                : trimmedPrice;

                                            if (value < 0) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedComm: '0% min'
                                                }));
                                            }

                                            if (value > 100) {
                                                const confirmBox =
                                                    await confirmCommissonInput(
                                                        <>
                                                            <p className="fw-bold">
                                                                Rate greater
                                                                than 100%!
                                                            </p>
                                                            <p>
                                                                Is this the
                                                                commission
                                                                value?
                                                            </p>
                                                        </>
                                                    );

                                                if (confirmBox) {
                                                    const commRate =
                                                        (value /
                                                            agreedSellingPrice) *
                                                        100;

                                                    setSellerAgreedCommValue(
                                                        formatValue(value)
                                                    );

                                                    setSellerAgreedComm(
                                                        commRate
                                                    );
                                                    setFormattedsellerAgreedComm(
                                                        formatValueToDecimal(
                                                            commRate
                                                        )
                                                    );
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        sellerAgreedComm: null,
                                                        sellerAgreedCommValue:
                                                            null
                                                    }));
                                                    return;
                                                } else {
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        sellerAgreedComm:
                                                            'Cannot be more than 100%'
                                                    }));

                                                    return;
                                                }
                                            }

                                            const agreedCommissionValue =
                                                (agreedSellingPrice * value) /
                                                100;

                                            setSellerAgreedCommValue(
                                                formatValue(
                                                    agreedCommissionValue
                                                )
                                            );

                                            setErrors((prev) => ({
                                                ...prev,
                                                sellerAgreedComm: null,
                                                sellerAgreedCommValue: null
                                            }));
                                        }}
                                    />
                                </div>
                                {errors && errors?.sellerAgreedComm && (
                                    <small className="ff-italic text-danger">
                                        {errors?.sellerAgreedComm}
                                    </small>
                                )}
                            </div>
                        </Col>

                        <Col sm="6">
                            <div className="form-control-wrap">
                                <div
                                    className={`input-group ${
                                        errors?.sellerAgreedCommValue &&
                                        'border border-danger'
                                    }`}
                                >
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            AED
                                        </span>
                                    </div>
                                    <Input
                                        placeholder="Enter Agreed Commission (AED)"
                                        value={sellerAgreedCommValue}
                                        type="text"
                                        onChange={(e) => {
                                            const value =
                                                +e.target.value?.replaceAll(
                                                    ',',
                                                    ''
                                                );

                                            if (value > 0) {
                                                setSellerAgreedCommValue(
                                                    formatValue(value)
                                                );
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedComm: null,
                                                    sellerAgreedCommValue: null
                                                }));
                                                return;
                                            }

                                            setSellerAgreedCommValue(
                                                e.target.value
                                            );
                                        }}
                                        onBlur={(e) => {
                                            const value = +e.target.value
                                                ?.trim()
                                                ?.replaceAll(',', '');

                                            const trimmedPrice =
                                                +sellerAgreedSalePrice
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                            const agreedSellingPrice = isNaN(
                                                trimmedPrice
                                            )
                                                ? 0
                                                : trimmedPrice;

                                            if (value > agreedSellingPrice) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedCommValue:
                                                        'Cannot be more than sale price'
                                                }));
                                                return;
                                            }

                                            const sellerAgreedComm =
                                                (value / agreedSellingPrice ??
                                                    1) * 100;

                                            setSellerAgreedComm(
                                                sellerAgreedComm
                                            );
                                            setFormattedsellerAgreedComm(
                                                formatValueToDecimal(
                                                    sellerAgreedComm
                                                )
                                            );
                                        }}
                                    />
                                </div>
                                {errors && errors?.sellerAgreedCommValue && (
                                    <small className="ff-italic text-danger">
                                        {errors?.sellerAgreedCommValue}
                                    </small>
                                )}
                            </div>
                        </Col>
                    </Row>
                </PreviewCard>
            );
        }
        return null;
    };

    return (
        <>
            <Row className="gy-4">
                <Col sm="12">
                    <div className="form-group">
                        <Label className="form-label">
                            <h6>Choose Purpose</h6>
                        </Label>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <RSelect
                                    options={Purposes}
                                    onChange={(purpose) => {
                                        setSelectedPurpose(purpose);
                                        setErrors((prev) => ({
                                            ...prev,
                                            purpose: null
                                        }));
                                    }}
                                    value={selectedPurpose}
                                    className={
                                        errors &&
                                        errors?.purpose &&
                                        'border border-danger'
                                    }
                                />
                                {errors && errors.purpose && (
                                    <small className="text-danger ff-italic">
                                        {errors?.purpose}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="12">
                    <div className="form-group">
                        <label className="form-label">
                            <h6>Choose Seller/Landlord</h6>
                        </label>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <RSelect
                                    options={ClientType}
                                    onChange={handleSellerTypeChange}
                                    className={
                                        errors &&
                                        errors.sellerType &&
                                        'border border-danger'
                                    }
                                />
                                {errors && errors.sellerType && (
                                    <small className="text-danger ff-italic">
                                        {errors?.sellerType}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
                {selectedSellerType && <Col sm="12">{renderSellersRows()}</Col>}

                <Col sm={12}>
                    <div className="form-group">
                        <label className="form-label">
                            <h6>Choose Buyer/Tenant</h6>
                        </label>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <RSelect
                                    options={ClientType}
                                    onChange={handleBuyerTypeChange}
                                    className={
                                        errors &&
                                        errors?.buyerType &&
                                        'border border-danger'
                                    }
                                />
                                {errors && errors.buyerType && (
                                    <small className="text-danger ff-italic">
                                        {errors?.buyerType}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
                {selectedBuyerType && <Col sm="12">{renderBuyersRows()}</Col>}
                <Col
                    sm="12"
                    className="bg-secondary mt-4 mb-3 pt-2 pb-2 rounded"
                >
                    <h6 className="text-white">Mortgage Details</h6>
                </Col>
                
                <Col
                sm="3"
                className={`d-flex align-items-center ${errors?.vacancy ? "align-self-center" : "align-self-end mb-2"}`}
                style={{ gap: "1.5rem" }}
                >
                <div className="custom-control custom-switch" style={{ flexGrow: 2 }}>
                    <input
                    type="checkbox"
                    className={`custom-control-input`}
                    id="customSwitch1"
                    name="isMortgage"
                    onChange={(e) => {
                        setIsMortgage(e.target.checked);
                    }}
                    />
                    <label className="custom-control-label" for="customSwitch1">
                    Buyer Mortgage
                    </label>
                </div>
                </Col>
                <Col sm="9">
                <Collapse isOpen={isMortgage}>
                    <Label>Buyer Mortgage Provider</Label>
                    <Input
                        className={`${
                            errors?.unitId && `border border-danger`
                        }`}
                        placeholder="Mortgage Provider"
                        onChange={(e) => {
                            setMortgageProvider(e.target.value);
                        }}
                    />
                </Collapse>
                </Col>

                <Col
                sm="3"
                className={`d-flex align-items-center ${errors?.isSellerMortgage ? "align-self-center" : "align-self-end mb-2"}`}
                style={{ gap: "1.5rem" }}
                >
                <div className="custom-control custom-switch" style={{ flexGrow: 2 }}>
                    <input
                    type="checkbox"
                    className={`custom-control-input`}
                    id="customSwitch2"
                    name="isSellerMortgage"
                    onChange={(e) => {
                        setIsSellerMortgage(e.target.checked);
                        setErrors((prev) => ({
                        ...prev,
                        isSellerMortgage: null,
                        }));
                    }}
                    />
                    <label className="custom-control-label" for="customSwitch2">
                    Seller Mortgage
                    </label>
                </div>
                </Col>
                <Col sm="9">
                <Collapse isOpen={isSellerMortgage}>
                    <Label>Seller Mortgage Provider</Label>
                    <Input
                        className={`${
                            errors?.sellerMortgageProvider && `border border-danger`
                        }`}
                        placeholder="Mortgage Provider"
                        value={sellerMortgageProvider}
                        onChange={(e) => {
                            setSellerMortgageProvider(e.target.value);
                        }}
                    />
                </Collapse>
                </Col>

                <div className="clearfix">
                    <ul>
                        <li>
                            <Button
                                color="primary"
                                type="button"
                                onClick={addNewDeal}
                                disabled={
                                    Object.entries(errors).find(
                                        ([key, value]) =>
                                            value || value?.length > 0
                                    ) || loading
                                }
                            >
                                {loading ? (
                                    <>
                                        <Spinner size="sm" color="light" />
                                        Please Wait
                                    </>
                                ) : (
                                    'Next'
                                )}
                            </Button>
                        </li>
                    </ul>
                </div>
                <ConfirmModal
                    isOpen={isOpen}
                    cancel={cancel}
                    proceed={async () => {
                        try {
                            setIsLoading(true);
                            const assignedProperty =
                                await handleAssignAgentToProperty();

                            // const assignedPropertyOwner = await handleAssignAgentToPropertyOwner()

                            return proceed(true);
                        } catch (err) {
                            // if assigning agent to owner fails, then we need to rever the assignment of property assigned

                            // if (err?.message === "failed to assign owner") {
                            //     await handleUnAssignPropertyAgent()
                            // }
                            return cancel(false);
                        } finally {
                            setIsLoading(false);
                        }
                    }}
                    prompt={prompt}
                    cancelColor="primary"
                    cancelText="Cancel"
                    proceedColor="danger"
                    proceedText="Continue"
                    isLoading={isLoading}
                />

                <ConfirmModal
                    isOpen={isOpen2}
                    cancel={() => cancel2(false)}
                    proceed={() => proceed2(true)}
                    prompt={prompt2}
                    cancelColor="primary"
                    cancelText="Cancel"
                    proceedColor="danger"
                    proceedText="Continue"
                    isLoading={isLoading}
                />

                <ConfirmModal
                    isOpen={commissionIsOpen}
                    cancel={() => commissionCancel(false)}
                    proceed={() => commisionProceed(true)}
                    prompt={commissionPrompt}
                    cancelColor="secondary"
                    cancelText="Cancel"
                    proceedColor="primary"
                    proceedText="Yes"
                    // isLoading={tre}
                />
            </Row>
            <AddNewAgency
                isOpen={openAddAgency}
                toggle={toggleModalAddAgency}
                callback={(agency) => {
                    if (agencyAddType === 'buyer') {
                        setSelectedAgencyBuyer({
                            ...agency,
                            value:
                                agency?.agency_name ??
                                `${agency?.first_name ?? ''} ${
                                    agency?.last_name ?? ''
                                }`,
                            label:
                                agency?.agency_name ??
                                `${agency?.first_name ?? ''} ${
                                    agency?.last_name ?? ''
                                }`
                        });
                    } else if (agencyAddType === 'seller') {
                        setSelectedAgencySeller({
                            ...agency,
                            value:
                                agency?.agency_name ??
                                `${agency?.first_name ?? ''} ${
                                    agency?.last_name ?? ''
                                }`,
                            label:
                                agency?.agency_name ??
                                `${agency?.first_name ?? ''} ${
                                    agency?.last_name ?? ''
                                }`
                        });
                    }
                }}
            />
        </>
    );
};
export default SecondaryMarketProperty;
