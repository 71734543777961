/**
 * @typedef {Object} CardsTableRowProps
 * @property {string} [className] - Additional CSS class names to apply.
 * @property {React.CSSProperties} [style] - Inline styles to apply.
 * @property {React.ReactNode | null} [children] - The children to render inside the column.
 * @property {function(MouseEvent): void} [onClick] - The click event handler.
 */

/**
 * CardsTableRow component renders a table row with various styling options.
 * 
 * @param {CardsTableRowProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered table row.
 */
const CardsTableRow = ({ className, style, onClick, isHover, ...props }) => {
  return (
    <>
    <div
      className={`${isHover ? 'nk-tb-item' : 'd-table-row bg-white'} ${className ? className : ""}`}
      style={style ?? {}}
      onClick={onClick}
    >
      {props?.children ?? null}
    </div>
    </>
  );
};

export default CardsTableRow;
