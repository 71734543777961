import React, { useEffect, useState } from 'react';
import { Col, Icon, Row, RSelect } from '../../../components/Component';
import DatePicker from 'react-datepicker';
import {
    scheduleHoliday,
    updatedScheduledHoliday
} from '../../../redux/actions/staffHolidays';
import { toast } from 'react-toastify';
import { format, subDays, addDays } from 'date-fns';
import { useDispatch } from 'react-redux';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { fetchAgents } from '../../../redux/actions/user';
import { useSelector } from 'react-redux';
import { Button, Spinner } from 'reactstrap';

export default function EditOrAddSchedule({
    editData,
    onCancel = (e) => {},
    loading
}) {
    const dispatch = useDispatch();
    const axios = useAxiosPrivate();
    const today = new Date();

    const isEdit = editData && typeof editData === 'object';

    const [startDate, setStartDate] = useState(
        isEdit ? new Date(editData?.startDate) : today
    );
    const [endDate, setEndDate] = useState(
        isEdit ? new Date(editData?.endDate) : null
    );
    const [selectedAgent, setSelectedAgent] = useState(
        isEdit
            ? {
                  ...editData?.agent,
                  label: `${editData?.agent?.user?.first_name} ${editData?.agent?.user?.last_name}`,
                  value: editData?.agent?.id ?? editData?.agent?._id
              }
            : null
    );

    const optAgents = useSelector((state) =>
        state.user?.agents
            ?.filter((agent) => agent?.user?.active)
            ?.map((agent) => ({
                ...agent,
                value: agent.id,
                label: agent.user.first_name + ' ' + agent.user.last_name
            }))
    );

    useEffect(() => {
        !optAgents?.length && dispatch(fetchAgents(axios));
    }, []);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!selectedAgent?.id || !startDate || !endDate) {
            toast.error('Please fill all fields');
            return;
        }

        try {
            const data = {
                agent: selectedAgent,
                start: format(startDate, 'MM-dd-yyyy'),
                end: format(endDate, 'MM-dd-yyyy')
            };

            if (editData) {
                const formattedStart = format(
                    new Date(editData?.startDate),
                    'MM-dd-yyyy'
                );
                const formattedEnd = format(
                    new Date(editData?.endDate),
                    'MM-dd-yyyy'
                );

                if (
                    data.start === formattedStart &&
                    data.end === formattedEnd
                ) {
                    onCancel(event);
                    return;
                }

                dispatch(
                    updatedScheduledHoliday(
                        axios,
                        editData.id ?? editData?._id,
                        data
                    )
                );
                return;
            }

            dispatch(scheduleHoliday(axios, data));
        } catch (error) {
            console.log(error);
        }
    };

    const handleCancel = (e) => {
        if (onCancel && typeof onCancel === 'function') {
            onCancel(e);
        }
        setSelectedAgent(null);
        setEndDate(null);
        setStartDate(today);
    };

    return (
        <>
            <a href="#cancel" className="close">
                <Icon
                    name="cross-sm"
                    onClick={(ev) => {
                        ev.preventDefault();
                        handleCancel();
                    }}
                ></Icon>
            </a>
            <div className="p-2">
                <h5 className="title">Add Agent Vacation Details</h5>
                <div className="mt-4">
                    {/* <form onSubmit={ handleSubmit( onFormSubmit ) }> */}
                    <form onSubmit={handleSubmit}>
                        <Row className="">
                            <Col sm="12">
                                <div className="form-group my-2">
                                    <label
                                        className="form-label"
                                        htmlFor="selectAgent"
                                    >
                                        Choose Agent
                                    </label>
                                    <RSelect
                                        id="selectAgent"
                                        options={isEdit ? [] : optAgents}
                                        value={selectedAgent}
                                        placeholder="Choose Agent"
                                        onChange={(selectedOption) => {
                                            setSelectedAgent(selectedOption);
                                        }}
                                        disabled={isEdit}
                                    />
                                </div>
                            </Col>

                            <Col sm="6" className="mt-2">
                                <div className="form-group">
                                    <label
                                        className="form-label"
                                        htmlFor="customer"
                                    >
                                        Vacation Start Date
                                    </label>
                                    <div className="form-control-wrap">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={handleStartDateChange}
                                            maxDate={subDays(endDate, 1)}
                                            minDate={today}
                                            value={format(
                                                startDate,
                                                'MM/dd/yyyy'
                                            )}
                                            className="form-control"
                                            name="endDate"
                                            dateFormat="MM/dd/yyyy"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col sm="6" className="mt-2">
                                <div className="form-group">
                                    <label
                                        className="form-label"
                                        htmlFor="customer"
                                    >
                                        Resumption Date
                                    </label>
                                    <div className="form-control-wrap has-timepicker focused">
                                        <DatePicker
                                            selected={endDate}
                                            onChange={handleEndDateChange}
                                            value={
                                                endDate
                                                    ? format(
                                                          endDate,
                                                          'MM/dd/yyyy'
                                                      )
                                                    : null
                                            }
                                            className="form-control"
                                            name="endDate"
                                            dateFormat="MM/dd/yyyy"
                                            minDate={addDays(startDate, 1)}
                                            startDate={startDate}
                                            disabled={!startDate}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col
                                size="12"
                                className="d-flex justify-content-end mt-3"
                            >
                                <Button
                                    color="dark"
                                    type="button"
                                    className="me-2"
                                    onClick={handleCancel}
                                    disabled={loading}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    color="primary"
                                    type="submit"
                                    className="ms-2"
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <Spinner size="sm" />
                                    ) : (
                                        <span>Submit</span>
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
        </>
    );
}
