import React from 'react';
import { dndAllowedStatuses } from '../../../utils/envConfig';

const DndFlagComponent = ({ lead_status, phoneNumber ,...props}) => {
  let isStatusAllowed = dndAllowedStatuses.includes(lead_status);

  return (
    <div {...props} >
      <span className="badge badge-dim bg-danger">
        <em className="icon ni ni-alert-fill"></em> &nbsp; DND Number 
        <span type="button" className="" style={{ color: 'indigo' }} data-bs-toggle="tooltip" data-bs-placement="top" title="This number is registered on a Do Not Disturb (DND) list. This number cannot be called for sales purposes.">
          <em className="icon ni ni-help-fill"></em>
        </span>
      </span> 
      {isStatusAllowed && (
        <span
          href="#id"
          onClick={(ev) => ev.preventDefault()}
        >
          {phoneNumber}
        </span>
      )}
    </div>
  );
};

export default DndFlagComponent;
