
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { toast } from "react-toastify";
import
{
    Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    DataTable,
    UserAvatar,
    Icon,
    PaginationComponent,
    PreviewCard,
    BlockDes
} from "../../../components/Component";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import AddDeveloperModal from "../../components/popup/AddDeveloperModal";
import { getImage } from "../../../utils/envConfig";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { format } from "date-fns";
import EditDeveloperModal from "../../components/popup/EditDeveloperModal";
import { showToast } from "../../../utils/toast/toast";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { debounce } from "lodash";
import LoadingComponent from "../../components/loader/tableLoader";

const currentItems = [
    { developer: 'Damac', createdAt: '2023-07-10', modifiedAt: '2023-07-11' },
    { developer: 'Emmar', createdAt: '2023-07-12', modifiedAt: '2023-07-13' },
];


const AllDevelopers = () =>
{

    const axios = useAxiosPrivate();
    const [developers, setDevelopers] = useState([]);
    const [developersLoading, setDevelopersLoading] = useState([]);
    const [total, setTotal] = useState(0);
    const [developer, setDeveloper] = useState([]);
    const [edit, setEdit] = useState(false);
    const location = useLocation();
    const query = queryString.parse(location.search);
    const [ searchText, setSearchText ] = useState( query?.search ?? "" );
    let navigate = useNavigate();

    const buildQuery = (params, updateRoute = true) => {
        const developerParams = {
            limit: params?.perPage ?? query?.limit,
            page: params?.page ?? query?.page,
            search: params?.search ?? query?.search,
        };

        const queryParams = new URLSearchParams();

        Object.keys(developerParams).forEach((key) => {
            const value = developerParams[key];
            if (Array.isArray(value)) {
                value.forEach((subValue) => queryParams.append(key, subValue));
                // eslint-disable-next-line eqeqeq
            } else if (value != undefined && value !== "null") {
                queryParams.append(key, value);
            }
        });

        const queryString = queryParams.toString();

        if (updateRoute) {
            const navigateParams = {};
            if (location.search.length) {
                navigateParams.replace = true;
            }

            navigate(
                `${"/all-developers"}?${queryString}`,
                navigateParams
            );
        }
        return queryString;
    }

    // debounce update query
    const updateSearchQuery = useCallback(buildQuery, [location.search.length, navigate, query?.limit, query?.page, query?.search]);
    
    const debounceSearchDevelopers = useMemo(() => {
        return debounce(updateSearchQuery, 500);
    }, [updateSearchQuery]);
    
    const handleSearchSubmit = (event) => {
        setCurrentPage(1);
        if (!event) {
            setSearchText('');
            debounceSearchDevelopers({ search: '' });
            return;
        }
        setSearchText(event.target.value);
        debounceSearchDevelopers({ search: event.target.value });
    };

    const fetchDevelopers = useCallback(async (queryString) => {
        try {
            setDevelopersLoading(true);
            const url = queryString ? `off-plan/plan-developer/filter?${queryString}` : `off-plan/plan-developer/filter`;
            const { data } = await axios.get(url);

            const developers = await Promise.all(
                data?.results?.map(async (item) => {
                    const image = await getImage(
                        item?.backgroundImage ?? item?.logo,axios
                    );

                    return {
                        ...item,
                        image: image?.signedRequest,
                    };
                })
            );

            setDevelopers(developers);
            setTotal(data?.found);
        } catch (err) {
            // console.log(err);
            showToast(err?.response?.data?.message ?? 'Failed to fetch developers', 'error');
        } finally {
            setDevelopersLoading(false);
        }
    }, [axios]);

    const [ itemsPerPage, setItemsPerPage ] = useState( 50 );
    const [ currentPage, setCurrentPage ] = useState( 1 );

    useEffect(() => {
        fetchDevelopers();
    }, [fetchDevelopers]);

    useEffect(() => {
        const queryString = updateSearchQuery({
            perPage: itemsPerPage,
            page: currentPage,
            search: query?.search ?? null,
        });

        fetchDevelopers(queryString);
        
    }, [location.search, currentPage, itemsPerPage, query?.search, axios, updateSearchQuery, fetchDevelopers, searchText])

    

    const handleDeleteDeveloper = async () => {
        try {
            const { data } = await axios.delete(
                `$off-plan/plan-developer/${developer?._id}`
            );
            setDevelopers(
                developers?.filter((dev) => dev?._id !== developer?._id)
            );
            setDeveloper({});

            toast.success("Deleted successfully");
        } catch (err) {
            // console.log(err);
            toast.error(err?.response?.data?.message);
            setDeveloper({});
        }
       
    };
    const [ onSearch, setonSearch ] = useState( true );
    const toggleSearch = () => setonSearch( !onSearch );
    const [ tablesm, updateTableSm ] = useState( false );
    const [ sort, setSortState ] = useState( "DESC" );
    const [ sortBy, setSorBy ] = useState( "createdAt" );
    const paginate = ( pageNumber ) => setCurrentPage( pageNumber );
    const [ filCount, setFilCount ] = useState( 0 );
    const onFilterChange = ( e ) =>
    {
        setSearchText( e.target.value );
    };
    const [ isOpen, setIsOpen ] = useState( false );

    const toggleModal = () =>
    {
        setIsOpen( !isOpen );
    };

    return (
        <React.Fragment>
            <Head title="Off Plan"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle>All Developers</BlockTitle>
                            <BlockDes>
                                {developersLoading ? <p>Loading...</p> : <p>Found { total } developers</p>}
                            </BlockDes>
                        </BlockHeadContent>
                        <div className="toggle-expand-content" style={ { display: "block" } }>

                            <Button onClick={ toggleModal } className="bg-primary">
                                <Icon name="plus" /><span>Add Developer</span></Button>

                            <AddDeveloperModal isOpen={ isOpen } toggle={ toggleModal } />

                        </div>
                    </BlockBetween>
                </BlockHead>
                <Block>

                    <DataTable data={ currentItems } >
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                                <div className="card-tools">
                                    <div className="form-inline flex-nowrap gx-3">

                                    </div>
                                </div>
                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={ ( ev ) =>
                                                {
                                                    ev.preventDefault();
                                                    toggleSearch();
                                                } }
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={ `btn-icon btn-trigger toggle ${ tablesm ? "active" : "" }` }
                                                    onClick={ () => updateTableSm( true ) }
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={ `toggle-content ${ tablesm ? "content-active" : "" }` }>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button className="btn-icon btn-trigger toggle" onClick={ () => updateTableSm( false ) }>
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>

                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        <li className={ itemsPerPage === 10 ? "active" : "" }>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={ ( ev ) =>
                                                                                {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage( 1 );
                                                                                    setItemsPerPage( 10 );
                                                                                } }
                                                                            >
                                                                                10
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={ itemsPerPage === 15 ? "active" : "" }>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={ ( ev ) =>
                                                                                {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage( 1 );
                                                                                    setItemsPerPage( 15 );
                                                                                } }
                                                                            >
                                                                                15
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={ itemsPerPage === 50 ? "active" : "" }>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={ ( ev ) =>
                                                                                {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage( 1 );
                                                                                    setItemsPerPage( 50 );
                                                                                } }
                                                                            >
                                                                                50
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={ itemsPerPage === 100 ? "active" : "" }>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={ ( ev ) =>
                                                                                {
                                                                                    ev.preventDefault();
                                                                                    setCurrentPage( 1 );
                                                                                    setItemsPerPage( 100 );
                                                                                } }
                                                                            >
                                                                                100
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                    {/* <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={ sort === "DESC" ? "active" : "" }>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={ ( ev ) =>
                                                                                {
                                                                                    ev.preventDefault();
                                                                                    setSorBy( "createdAt" );
                                                                                    setSortState( "DESC" );
                                                                                    // sortFunc( "dsc" );
                                                                                } }
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={ sort === "ASC" ? "active" : "" }>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={ ( ev ) =>
                                                                                {
                                                                                    ev.preventDefault();
                                                                                    setSorBy( "createdAt" );
                                                                                    setSortState( "ASC" );
                                                                                    // sortFunc( "asc" );
                                                                                } }
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul> */}
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={ `card-search search-wrap ${ !onSearch && "active" }` }>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active bg-transparent text-secondary"
                                            onClick={ () =>
                                            {
                                                handleSearchSubmit(null);
                                                toggleSearch();
                                            } }
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control ps-5  bg-transparent text-secondary"
                                            placeholder="Search Developer by name "
                                            value={ searchText }
                                            onChange={ ( e ) => handleSearchSubmit( e ) }
                                        />
                                        <Button
                                            className="search-submit btn-icon  bg-transparent text-secondary border-0">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTableBody >
                            <DataTableHead>
                                <DataTableRow>
                                    <span>Developer</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>Created At</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>Modified At</span>
                                </DataTableRow>
                                <DataTableRow></DataTableRow>
                            </DataTableHead>
                            { developersLoading ? <LoadingComponent /> : developers?.length>0 ? (
                                developers?.map( ( item, index ) => (
                                    <DataTableItem key={ index } >
                                        <DataTableRow>
                                            <div style={ { display: 'flex', alignItems: 'center' } }>
                                                <UserAvatar 
                                                // image={ "https://manage.goldpillars.ae/Developer/Developer_Logo/13/Thumb/13.webp" } 
                                                image={ item?.image}
                                                theme="lighter" size="md" />                                                
                                                <span style={ { marginLeft: '0.5rem' } }> 
                                                    {item?.name}</span>
                                            </div>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <span>{format(new Date( item?.createdAt ),"dd/MM/yyyy hh:mm a" ) }</span>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <span>{format(new Date( item?.createdAt ),"dd/MM/yyyy hh:mm a" ) }</span>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <div style={ { display: 'flex', alignItems: 'center' } }>
                                                <Icon name="trash" className="text-danger" style={ { fontSize: "1.2rem !important" } }>
                                                </Icon>
                                                <Icon 
                                                onClick={()=>
                                                   { 
                                                    setDeveloper(item);
                                                    setEdit(true);
                                                }
                                                }
                                                name="edit" className="ms-2" style={ { fontSize: "1.2rem !important" } }>
                                                </Icon>
                                            </div>
                                        </DataTableRow>
                                    </DataTableItem>

                                ) )
                            ) : (
                                <DataTableItem>
                                    <DataTableRow>
                                        <span>No records to display</span>
                                    </DataTableRow>
                                </DataTableItem>
                            ) }

                        </DataTableBody>
                        {developersLoading ? null : <PreviewCard>
                            <PaginationComponent
                                itemPerPage={ itemsPerPage }
                                totalItems={ total }
                                paginate={ paginate }
                                currentPage={ currentPage }
                            />
                        </PreviewCard>}
                    </DataTable>
                    {edit &&<EditDeveloperModal isOpen={edit} toggle={setEdit} developer={developer}/>}

                </Block>
            </Content>
        </React.Fragment >
    );
};
export default AllDevelopers;