const TEAM_FIELDS_TO_DISPLAY = [
  "Name",
  "Members",
  "Communities",
];
const DEFAULT_COMMUNITY_IMAGE =
  "https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

const SPECIAL_TEAMS = {
  OffPlan: 'Off-Plan'
}
export { TEAM_FIELDS_TO_DISPLAY, DEFAULT_COMMUNITY_IMAGE, SPECIAL_TEAMS };