import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import queryString from "query-string";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Spinner,
  Row,
  Col,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  PaginationComponent,
} from "../../../components/Component";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { fetchAllPropertyCards } from "../../../redux/actions/property-cards";
import {
  fetchBuildings,
  fetchCommunitiesList,
  fetchPropTypesList
} from "../../../redux/actions/property";
import {
  PROPERTY_LIST_FIELDS_POCKETLIST,
  LocalStorageKeys,
  BEDS_OPTIONS,
  BATHS_OPTIONS,
  PURPOSE_OPTIONS,
  defaultMinPrice,
  defaultMaxPrice,
  ALLOWED_PROPERTY_TYPES,
  PROPERTY_TYPES,
} from "../constats";
import { getLocalStorageObj, getPropTypeRegex } from "../utils";
import {
  PropertyCardStatus, ScreenBreakPoints, newRoles,
} from "../../../utils/envConfig";
import ErrorModal from "../../../components/modals/ErrorModal";
import AgentCard from "./AgentCard";
import LoadingComponent from "../../components/loader/tableLoader";
import { debounce } from "lodash";
import { fetchAgentsList } from "../../../redux/actions/user";
import RangeFilter from "../../../components/input/range-input/RangeFilter";
import useScreenSize from "../../../hooks/useScreenSize";

const ListPocketListingUI = ({ url = "/list-pocket-listing" }) => {
  const [tablesm, setTableSm] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const toggleRefresh = () => setRefresh(!refresh);
  // eslint-disable-next-line no-unused-vars
  const [selectedItems, setSelectedItems] = useState([]); // this is for use with checkboxes
  const [propertyCardsWithChecks, setPropertyCardsWithChecks] = useState([]); // this will contain all property cards with a "checked" property

  const screenSize = useScreenSize();

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const toggleErrorModal = () => setIsErrorModalOpen(!isErrorModalOpen);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const query = queryString.parse(location.search);

  // REDUX STATE
  // eslint-disable-next-line no-unused-vars
  const authUser = useSelector((state) => state?.user?.loggedInUser);
  const authAgent = useSelector((state) => state?.user?.loggedInAgent);

  const { propertyCards, found, loading, error } = useSelector((state) => ({
    propertyCards: state.propertyCard?.propertyCards?.data,
    found: state.propertyCard?.propertyCards?.filteredCount,
    loading: state.propertyCard.loading,
    error: state.propertyCard.error,
  }));

  const filteredCount = useSelector(
    (state) => state.propertyCard?.propertyCards?.filteredCount
  );

  const { communities, communitiesLoading } = useSelector((state) => ({
    communities: state.property?.communities,
    communitiesLoading: state.property?.communitiesLoading,
  }));
  const { buildings, buildingsLoading } = useSelector((state) => ({
    buildings: state.property?.buildings,
    buildingsLoading: state.property?.buildingsLoading,
  }));
  const { propertyTypes, propTypesLoading } = useSelector((state) => ({
    propertyTypes: state.property?.propertyTypes,
    propTypesLoading: state.property?.propTypesLoading,
  }));

  const { agents, agentsLoading } = useSelector((state) => ({
    agents: state.user?.agents,
    agentsLoading: state.user?.loading,
  }));


  // FILTER OPTIONS

  const communityFilterOptions = Object.values(communities).map((item) => ({
    ...item,
    label: item.community,
    value: item?.id,
  }));

  const agentFilterOptions = agents?.map((agent) => ({
    ...agent,
    label: `${agent?.user?.first_name} ${agent?.user?.last_name}`,
    value: agent?.id ?? agent?._id,
  }));


  const [buildingFilterOptions, setBuildingFilterOptions] = useState([]);
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);

  const [isSearchClosed, setIsSearchClosed] = useState(
    query?.search ? false : true
  );
  const [searchText, setSearchText] = useState(query?.search ?? "");

  const [page, setPage] = useState(
    localStorage.getItem(LocalStorageKeys.PLCurrentPage) ?
      Number(localStorage.getItem(LocalStorageKeys.PLCurrentPage)) :
      query?.page ? Number(query?.page) : 1
  );
  // eslint-disable-next-line no-unused-vars
  const [perPage, setPerPage] = useState(
    query?.per_page ? Number(query?.per_page) : 10
  );

  const [selectedCommunities, setSelectedCommunities] = useState(
    getLocalStorageObj(LocalStorageKeys.PLSelectedCommunities) ?? []
  );

  const [selectedBuildings, setSelectedBuildings] = useState(
    getLocalStorageObj(LocalStorageKeys.PLSelectedBuildings) ?? []
  );

  const [selectedAgent, setSelectedAgent] = useState(null);

  const [selectedBedsCount, setSelectedBedsCount] = useState(
    localStorage.getItem(LocalStorageKeys.PLSelectedNumberOfBeds) ?? ""
  );

  const [selectedBathsCount, setSelectedBathsCount] = useState(
    localStorage.getItem(LocalStorageKeys.PLSelectedNumberOfBaths) ?? ""
  );
  //hereqqq
  const [selectedPurpose, setSelectedPurpose] = useState(
    localStorage.getItem(LocalStorageKeys.PLSelectedPurpose) ?? ""
  );

  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState(
    getLocalStorageObj(LocalStorageKeys.PLSelectedPropertyType) ?? []
  );

  const [selectedTimeFilter, setSelectedTimeFilter] = useState("");
  const [priceRange,setPriceRange]=useState(
    getLocalStorageObj(LocalStorageKeys.PLPriceRange)??[0, Number.MAX_SAFE_INTEGER]
  )
  const [showMyPocketsFilter, setShowMyPocketsFilter] = useState(false);

  const handleShowMyPocketsFilter=async()=>{
    try {
      if (!showMyPocketsFilter) {
        setSelectedAgent({
          ...authAgent,
          label: `${authAgent?.user?.first_name} ${authAgent?.user?.last_name}`,
          value: authAgent?.id ?? authAgent?._id
        });
      } else {
        setSelectedAgent(null);
      }
      setShowMyPocketsFilter(c => !c);
      resetPage();
    } catch (error) {
      console.log(error);
    }
  }

  const queryPropertyCards = useCallback((params, updateRoute = true) => {

    const propertyCardParams = {
      // always set status to only fetch Pocket Listings
      status: [PropertyCardStatus.PocketListing, PropertyCardStatus.Sold],
      limit: params?.perPage ?? query?.limit,
      page: params?.page ?? query.page,
      search: params.search ?? query.search, // Use undefined to exclude if not provided
      fromDate: params.fromDate || null,
      toDate: params.toDate || null,
      communities: params?.communities ?? null,
      buildings: params?.buildings ?? null,
      beds: params?.beds ?? null,
      minBaths: params?.minBaths ?? null,
      maxBaths: params?.maxBaths ?? null,
      propertyTypes: params.propertyTypes || null,
      partyType: params.partyType || null,
      purpose: params.purpose ?? null,
      withLeadsCount: true,
      timeFilter: params?.timeFilter ?? null,
      minPrice: params?.minPrice,
      maxPrice: params?.maxPrice,
      currentAgent: params?.currentAgent
    };

    const queryParams = new URLSearchParams();

    // Filter out undefined properties
    Object.keys(propertyCardParams).forEach((key) => {
      const value = propertyCardParams[key];
      if (Array.isArray(value) && value.length > 0) {
        value.forEach((subValue) => queryParams.append(key, subValue));
        // eslint-disable-next-line eqeqeq
      } else if (value != undefined && (value !== "null" || value !== '')) {
        queryParams.append(key, value);
      }
    });

    const queryString = queryParams.toString();

    if (updateRoute) {
      const navigateParams = {};
      if (location.search.length) {
        navigateParams.replace = true;
      }
      navigate(`${url}?${queryString}`, navigateParams);
    }
    return queryString;
  }, [location.search.length, navigate, query?.limit, query.page, query.search, url]);


  useEffect(() => {
    !agents?.length && dispatch(fetchAgentsList(axios));
    !communities?.length && dispatch(fetchCommunitiesList(axios));
    !buildings?.length && dispatch(fetchBuildings(axios));
    !propertyTypes?.length && dispatch(fetchPropTypesList(axios));
  }, [axios, dispatch, buildings?.length, communities?.length, propertyTypes?.length, agents?.length]);

  useEffect(() => {
    // This will run whenever a community filter is chosen
    // to keep buildings that are part of selected communities only in the buildings filter
    if (propertyTypes && propertyTypes?.length) {
      setPropertyTypeOptions((_) => {
        const options = propertyTypes
        .filter((propType) =>
          ALLOWED_PROPERTY_TYPES.includes(propType?.propertyType)
        )
        .map((propType) => ({
          ...propType,
          label: propType?.propertyType,
          value: getPropTypeRegex(propType?.propertyType),
        }));
        return [
          {
            label: PROPERTY_TYPES.Commercial,
            value: getPropTypeRegex(PROPERTY_TYPES.Commercial),
          },
          ...options,
          { label: "Other...", value: getPropTypeRegex("Other") },
        ];
      }
    );
    }


    if (!selectedCommunities.length) {
      // this happens at the first render of the page, fetch all buildings
      setBuildingFilterOptions(
        Object.values(buildings).map((item) => ({
          label: item.name,
          value: item?.id,
        }))
      );
      return;
    }

    const selectedCommunityIds = selectedCommunities?.map(
      (community) => community.value
    );

    const filteredBuildingSet = buildings.filter(
      (building) =>
        building?.communityId != null &&
        selectedCommunityIds.includes(building?.communityId)
    );
    setBuildingFilterOptions(
      Object.values(filteredBuildingSet).map((item) => ({
        label: item.name,
        value: item?.id,
      }))
    );
  }, [buildings, propertyTypes, selectedCommunities]);

  useEffect(() => {
    const queryString = queryPropertyCards(
      {
        perPage,
        page,
        beds: selectedBedsCount?.length ? selectedBedsCount : null,
        minBaths: selectedBathsCount?.length ? selectedBathsCount : null,
        maxBaths: selectedBathsCount?.length ? selectedBathsCount : null,
        purpose: selectedPurpose?.length ? selectedPurpose : null,
        communities:
          selectedCommunities?.map(
            (communityOption) => communityOption?.value
          ) ?? [],
        buildings:
          selectedBuildings?.map((buildingOption) => buildingOption?.value) ??
          [],
        priceRange,
        propertyTypes:
          selectedPropertyTypes.map((typeOption) => typeOption?.value) ?? [],
        timeFilter: selectedTimeFilter ?? null, // Use selectedTimeFilter directly,
        minPrice: priceRange[0] ?? defaultMinPrice,
        maxPrice: priceRange[1] ?? defaultMaxPrice,
        currentAgent: showMyPocketsFilter ? authAgent?.id : selectedAgent?.value,
      },
      false
    );
    dispatch(fetchAllPropertyCards(axios, `?${queryString}`));
  }, [
    refresh,
    page,
    perPage,
    location.search,
    queryPropertyCards,
    selectedBedsCount,
    selectedBathsCount,
    selectedPurpose,
    selectedCommunities,
    selectedBuildings,
    selectedPropertyTypes,
    selectedTimeFilter,
    dispatch,
    axios,
    priceRange,
    showMyPocketsFilter,
    selectedAgent,
    authAgent?.id,
  ]);

  useEffect(() => {
    // this will run when the redux dispatch fetches the list of property cards
    // we save it as state to add a "checked" property later
    // this is used for the checkbox logic
    setPropertyCardsWithChecks(propertyCards);
  }, [propertyCards]);

  const handleButtonClick = (filter) => {
    setPage(1);
    setRefresh(!refresh);
    setSelectedTimeFilter((previous) => {
      if (previous === filter) {
        return "";
      }
      return filter;
    });
  };

  const handleSearchSubmit = (event) => {
    // Get the search text from the input
    const searchTerm = event.target.value;
    setSearchText(searchTerm);
    debounceSearchPropertyCards({ search: event.target.value });
  };

  // debounce update query
  const updateSearchQuery = useCallback(queryPropertyCards,
    [queryPropertyCards]
  );

  const debounceSearchPropertyCards = useMemo(() => {
    return debounce(updateSearchQuery, 400);
  }, [updateSearchQuery]);
  // end debouncing

  // Checkbox logic
  const selectAllPropertyCards = (event) => {
    // Global checkbox
    const isChecked = event.target.checked;
    const checkedItems = propertyCardsWithChecks.map((item) => {
      item.checked = isChecked;
      return item;
    });

    const selectedItems = checkedItems
      .filter((item) => item.checked)
      .map((item) => ({ id: item?.id, leadsCount: item.leadsCount ?? 0 }));

    setSelectedItems(selectedItems);
  };

  const selectItem = (event, item) => {
    let propertyCardsCopy = propertyCards;
    let selectedIndex = propertyCardsCopy.findIndex((x) => x?.id === item?.id);
    const isChecked = event.target.checked;
    propertyCardsCopy[selectedIndex].checked = isChecked;
    setPropertyCardsWithChecks([...propertyCardsCopy]);

    setSelectedItems((currentSelectedItems) => {
      if (isChecked) {
        // Add itemId to selectedItems if it's checked and not already present
        return [
          ...currentSelectedItems,
          { id: item?.id, leadsCount: item.leadsCount ?? 0 },
        ];
      } else {
        // Remove itemId from selectedItems if it's unchecked
        return currentSelectedItems.filter((id) => id !== item?.id);
      }
    });
  };

  const resetPage = () => {
    setPage(1);
    localStorage.setItem(LocalStorageKeys.PLCurrentPage, 1);
    setRefresh(!refresh);
  }

  const handleBuildingFilterChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "all")) {
      setSelectedBuildings([]);
      localStorage.removeItem(LocalStorageKeys.PLSelectedBuildings);
    } else {
      setSelectedBuildings(selectedOptions);
      localStorage.setItem(
        LocalStorageKeys.PLSelectedBuildings,
        JSON.stringify(selectedOptions)
      );
    }
    resetPage();
  };

  const handleAgentsFilterChange = (selectedOption) => {
    if (selectedOption === "all") {
      setSelectedAgent(null);
    } else {
      setSelectedAgent(selectedOption);
    }
    resetPage();
  };

  const handleCommunitiesFilterChange = (selectedOptions) => {
    setSelectedBuildings([]);
    if (selectedOptions.some((option) => option.value === "all")) {
      setSelectedCommunities([]);
      localStorage.removeItem(LocalStorageKeys.PLSelectedCommunities);
    } else {
      setSelectedCommunities(selectedOptions);
      localStorage.setItem(
        LocalStorageKeys.PLSelectedCommunities,
        JSON.stringify(selectedOptions)
      );
    }
    resetPage();
  };

  const handlePropertyTypesFilterChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "all")) {
      setSelectedPropertyTypes([]);
      localStorage.removeItem(LocalStorageKeys.PLSelectedPropertyType);
    } else {
      setSelectedPropertyTypes(selectedOptions);
      localStorage.setItem(
        LocalStorageKeys.PLSelectedPropertyType,
        JSON.stringify(selectedOptions)
      );
    }
    resetPage();
  };
  const handlePriceFilter = useCallback((min, max) => {
    setPriceRange(_ => [
      isNaN(parseInt(min)) ? 0 : parseInt(min),
      isNaN(parseInt(max)) ? Number.MAX_SAFE_INTEGER : parseInt(max)
    ]);
    setPage(1);
    localStorage.setItem(
      LocalStorageKeys.PLPriceRange,
      JSON.stringify([min,max])
    );
    localStorage.setItem(
      LocalStorageKeys.PLCurrentPage,
      1
    );
  }, []);

  const clearAllFilters = () => {
    // Reset filter states
    setSelectedCommunities([]);
    setSelectedBuildings([]);
    setSelectedAgent(null);
    setSelectedBedsCount("");
    setSelectedBathsCount("");
    setSelectedPurpose("");
    setSelectedPropertyTypes([]);
    setPriceRange([0,0])
    // Clear localStorage or any other side effects if you're using them
    localStorage.removeItem(LocalStorageKeys.PLSelectedCommunities);
    localStorage.removeItem(LocalStorageKeys.PLSelectedBuildings);
    localStorage.removeItem(LocalStorageKeys.PLSelectedNumberOfBeds);
    localStorage.removeItem(LocalStorageKeys.PLSelectedNumberOfBaths);
    localStorage.removeItem(LocalStorageKeys.PLSelectedPropertyType);
    localStorage.removeItem(LocalStorageKeys.PLSelectedPurpose);
    localStorage.removeItem(ListPocketListingUI.PLPriceRange)
    
    resetPage();
  };

  const isAnyFilterSet =
    selectedCommunities.length ||
    selectedBuildings.length ||
    selectedBathsCount ||
    selectedBedsCount ||
    selectedPurpose ||
    selectedAgent ||
    selectedPropertyTypes.length;

  const isAdmin =
    authUser.role === newRoles.Admin || authUser.role === newRoles.MasterAdmin;
  const PropertyTableItem = ({
    propertyCard,
    index,
  }) => {
    const isCheckedOutByAuthAgent =
      authAgent &&
      (authAgent?.id ?? authAgent?._id)?.toString() ===
      (
        propertyCard?.currentAgent?.id ?? propertyCard?.currentAgent?._id
      )?.toString();
 
    return (
      <DataTableItem key={index}>
        <DataTableRow className="nk-tb-col-check">
          <div className="custom-control custom-control-sm custom-checkbox notext">
            <input
              type="checkbox"
              className="custom-control-input"
              defaultChecked={propertyCard?.checked}
              id={index + "oId-all"}
              key={Math.random()}
              onChange={(e) => selectItem(e, propertyCard)}
            />
            <label
              className="custom-control-label"
              htmlFor={index + "oId-all"}
            ></label>
          </div>
        </DataTableRow>
        <DataTableRow className="text-center">
         {<> {isAdmin || isCheckedOutByAuthAgent || propertyCard?.availableForCheckout ? (
            <Link to={`/property-detail/${propertyCard?.id}`}>
              {propertyCard?.referenceNumber}
            </Link>
          ) : (
            <span>{propertyCard?.referenceNumber}</span>
          )}</>}
        </DataTableRow>
        <DataTableRow className="text-center">
          {propertyCard?.propertyType}
        </DataTableRow>
        <DataTableRow className="text-center">
          {propertyCard?.purpose}
        </DataTableRow>
        <DataTableRow className="text-center">
          {propertyCard?.askingPrice ?? '-'}
        </DataTableRow>
        <DataTableRow className="text-center">
          <span>{propertyCard?.community?.community}</span>
        </DataTableRow>
        <DataTableRow className="text-center">
          <span>
            {propertyCard?.building
              ? propertyCard?.building?.name
              : "-"}
          </span>
        </DataTableRow>
        <DataTableRow className="text-center">
          <span>{propertyCard?.size}</span>
        </DataTableRow>
        <DataTableRow className="text-center">
          <span>{propertyCard?.beds === 0 ? "-" : propertyCard?.beds}</span>
        </DataTableRow>
        <DataTableRow className="text-center">
          <span>{propertyCard?.baths === 0 ? "-" : propertyCard?.baths}</span>
        </DataTableRow>
        <DataTableRow className="text-center">
          {propertyCard?.status === PropertyCardStatus.Sold ?
            <span className="tb-status text-danger">Sold</span> :
            <span className="tb-status text-success">Available</span>}
        </DataTableRow>
        <DataTableRow className="text-center">
          {Boolean(propertyCard?.availableForCheckout) ? (
          <div className="tb-odr-btns d-flex justify-content-center align-items-center">
              <Badge color={'success'} size="sm">
                Unassigned
              </Badge>
            </div>
          ) : (
            <span>
              <AgentCard agent={propertyCard?.currentAgent} />
            </span>
          )}
        </DataTableRow>
        {screenSize.width <= ScreenBreakPoints.laptop ? null : <DataTableRow className="text-center"></DataTableRow>}
      </DataTableItem>
    );
  };
  return (
    <React.Fragment>
      <Head title="Properties"></Head>
      <Content>
        {/* ================ PAGE HEADER =================== */}
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Pocket Listings
              </BlockTitle>
              {!loading ? (
                <BlockDes className="text-soft">
                  <p>There are a total of {found ?? 0} Pocket Listings.</p>
                </BlockDes>
              ) : (
                <BlockDes className="text-soft">
                  <p>Loading...</p>
                </BlockDes>
              )}
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="primary"
                onClick={() => navigate("/add-pocket-listing")}
              >
                <div className="d-flex" style={{ gap: "0.5rem" }}>
                  <Icon name="plus" /> Add Pocket Listing
                </div>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
          <div
            className="d-flex flex-wrap flex-row-reverse mt-1"
            style={{ gap: "1rem" }}
          >
            {/* <PriceFilter
              min={priceRange[0]}
              max={priceRange[1]}
              placeHolder={'Price'}
              key={"price-filter"}
              onMaxChange={(max) => handlePriceFilter(priceRange[0], max)}
              onMinChange={(min) => handlePriceFilter(min, priceRange[1])}
            /> */}

            <div className="d-flex align-self-md-center align-self-end">
              {["This Week", "This Month", "Old"].map((btnOption, idx) => (
                <Button
                  key={`button-option-${idx}`}
                  className={idx === 1 ? "mx-2 border mt-3" : "border mt-3"}
                  color={selectedTimeFilter === btnOption ? "primary" : "white"}
                  onClick={() => handleButtonClick(btnOption)}
                >
                  <div className="d-flex" style={{ gap: "0.5rem" }}>
                    <small>
                      <Icon
                        name={
                          selectedTimeFilter === btnOption
                            ? "check-c"
                            : "square-c"
                        }
                      />
                    </small>
                    {btnOption}
                  </div>
                </Button>
              ))}
            </div>
            {/* {authAgent ? (
                <div className="d-flex mt-1" style={{ gap: ".5rem" }}>
                  <ButtonGroup
                    filterValue={showMyPocketsFilter}
                    handlerFn={handleShowMyPocketsFilter}
                  />
                </div>
              ) : null } */}
          </div>
          <div className="d-flex p-2 mt-3">
            <ul 
              className="d-flex g-3 flex-row flex-wrap justify-content-end"
            >
              <li className="order-first">
                <RSelect
                  placeholder="Filter By Community"
                  className="status-div"
                  isMulti
                  isLoading={communitiesLoading}
                  loadingMessage={() => <Spinner />}
                  value={selectedCommunities?.length ? selectedCommunities : []}
                  options={communityFilterOptions}
                  onChange={(selectedOptions) =>
                    handleCommunitiesFilterChange(selectedOptions)
                  }
                  styles={{
                    option: (provided) => ({
                      ...provided,
                      whiteSpace: "normal",
                      "&:hover": {
                        whiteSpace: "normal",
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                      whiteSpace: "normal",
                    }),
                  }}
                />
              </li>

              <li className="">
                <RSelect
                  placeholder="Property Type"
                  className="status-div"
                  isMulti
                  isLoading={propTypesLoading}
                  loadingMessage={() => <Spinner />}
                  value={selectedPropertyTypes ? selectedPropertyTypes : []}
                  options={propertyTypeOptions}
                  onChange={(selectedOptions) =>
                    handlePropertyTypesFilterChange(selectedOptions)
                  }
                />
              </li>
              <li>
                <UncontrolledDropdown>
                  <DropdownToggle
                    color="transparent"
                    className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                  >
                    {selectedBedsCount && selectedBedsCount !== ""
                      ? selectedBedsCount === "7"
                        ? selectedBedsCount + "+"
                        : selectedBedsCount
                      : "Beds"}
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="link-list-opt no-bdr">
                      {BEDS_OPTIONS.map((option, idx) => (
                        <li key={`bed-option-${idx}`}>
                          <DropdownItem
                            onClick={() => {
                              setRefresh(!refresh);
                              if (option === "All") {
                                setSelectedBedsCount("");
                              } else {
                                setSelectedBedsCount(option);
                              }
                              setPage(1);
                              localStorage.setItem(
                                LocalStorageKeys.PLCurrentPage,
                                1
                              );
                            }}
                          >
                            <span>{option}</span>
                          </DropdownItem>
                        </li>
                      ))}
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
              <li>
                <UncontrolledDropdown>
                  <DropdownToggle
                    color="transparent"
                    className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                  >
                    {selectedBathsCount && selectedBathsCount !== ""
                      ? selectedBathsCount === "7"
                        ? selectedBathsCount + "+"
                        : selectedBathsCount
                      : "Baths"}
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="link-list-opt no-bdr">
                      {BATHS_OPTIONS.map((option, idx) => (
                        <li key={`bath-option-${idx}`}>
                          <DropdownItem
                            onClick={() => {
                              setRefresh(!refresh);
                              if (option === "All") {
                                setSelectedBathsCount("");
                              } else {
                                setSelectedBathsCount(option);
                              }
                              setPage(1);
                              localStorage.setItem(
                                LocalStorageKeys.PLCurrentPage,
                                1
                              );
                            }}
                          >
                            <span>{option}</span>
                          </DropdownItem>
                        </li>
                      ))}
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
              <li>
                <UncontrolledDropdown>
                  <DropdownToggle
                    color="transparent"
                    className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                  >
                    {selectedPurpose.length ? selectedPurpose : "Purpose"}
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="link-list-opt no-bdr">
                      {PURPOSE_OPTIONS.map((option, idx) => (
                        <li key={`bath-option-${idx}`}>
                          <DropdownItem
                            onClick={() => {
                              if (option === "All") {
                                setSelectedPurpose("");
                                localStorage.removeItem(
                                  LocalStorageKeys.PLSelectedPurpose
                                );
                              } else {
                                setSelectedPurpose(option);
                                localStorage.setItem(
                                  LocalStorageKeys.PLSelectedPurpose,
                                  option
                                );
                              }
                              resetPage();
                            }}
                          >
                            <span>{option}</span>
                          </DropdownItem>
                        </li>
                      ))}
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
              <li>
                <div className="">
                  <RSelect
                    placeholder="Filter By Agent"
                    className="status-div"
                    isLoading={agentsLoading}
                    loadingMessage={() => <Spinner />}
                    value={selectedAgent}
                    options={agentFilterOptions}
                    onChange={(selectedOption) =>
                      handleAgentsFilterChange(selectedOption)
                    }
                  />
                </div>
              </li>
              <li className="order-last">
                <RSelect
                  placeholder="Filter By Building"
                  className="status-div building-width-custom"
                  isMulti
                  isLoading={buildingsLoading}
                  loadingMessage={() => <Spinner />}
                  value={selectedBuildings ? selectedBuildings : []}
                  options={buildingFilterOptions}
                  onChange={(selectedOptions) =>
                    handleBuildingFilterChange(selectedOptions)
                  }
                  styles={{
                    option: (provided) => ({
                      ...provided,
                      whiteSpace: "normal",
                      "&:hover": {
                        whiteSpace: "normal",
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                      whiteSpace: "normal",
                    }),
                  }}
                />
              </li>
              <li>
                <RangeFilter
                  min={priceRange[0]}
                  max={priceRange[1]}
                  onSubmit={({ min, max }) => handlePriceFilter(min, max)}
                  placeholder={"Price"}
                />
              </li>
            </ul>
          </div>
          {/* <div className="w-100 d-flex justify-content-end ">
            <PriceFilter
              min={10}
              max={100000}
              step={2}
              key={"price"}
              onMaxChange={(max) => handlePriceFilter(priceRange[0],max)}
              onMinChange={(min) => handlePriceFilter(min,priceRange[1])}
            />
          </div> */}
          <div
            className="d-flex justify-content-end pt-2"
            style={{ gap: "0.2rem" }}
          >
            {selectedBedsCount ? (
              <Badge color="secondary" className="cursor-pointer">
                <span className="d-flex align-items-center">
                  Beds: {selectedBedsCount}{" "}
                  <Icon
                    name={"cross"}
                    onClick={() => {
                      setSelectedBedsCount("");
                      localStorage.removeItem(
                        LocalStorageKeys.SelectedNumberOfBeds
                      );
                      setPage(1);
                      localStorage.setItem(LocalStorageKeys.PLCurrentPage, 1);
                      setRefresh(!refresh);
                    }}
                  />
                </span>
              </Badge>
            ) : null}
            {selectedBathsCount.length ? (
              <Badge color="warning" className="cursor-pointer">
                <span className="d-flex align-items-center">
                  Baths: {selectedBathsCount}{" "}
                  <Icon
                    name={"cross"}
                    onClick={() => {
                      setSelectedBathsCount("");
                      localStorage.removeItem(
                        LocalStorageKeys.SelectedNumberOfBaths
                      );
                      setPage(1);
                      localStorage.setItem(LocalStorageKeys.PLCurrentPage, 1);
                      setRefresh(!refresh);
                    }}
                  />
                </span>
              </Badge>
            ) : null}
            {selectedBuildings.length
              ? selectedBuildings.map((item, idx) => (
                  <Badge
                    key={`building-badge-${idx}`}
                    color="secondary"
                    className="cursor-pointer"
                  >
                    <span className="d-flex align-items-center">
                      Building: {item.label}{" "}
                      <Icon
                        name={"cross"}
                        onClick={() =>
                          handleBuildingFilterChange(
                            selectedBuildings.filter(
                              (x) => x.value !== item.value
                            )
                          )
                        }
                      />
                    </span>
                  </Badge>
                ))
              : null}
            {selectedCommunities.length
              ? selectedCommunities.map((item, idx) => (
                  <Badge
                    key={`building-badge-${idx}`}
                    color="primary"
                    className="cursor-pointer"
                  >
                    <span className="d-flex align-items-center">
                      {item.label}{" "}
                      <Icon
                        name={"cross"}
                        onClick={() =>
                          handleCommunitiesFilterChange(
                            selectedCommunities.filter(
                              (x) => x.value !== item.value
                            )
                          )
                        }
                      />
                    </span>
                  </Badge>
                ))
              : null}
            {selectedAgent ? (
              <Badge color="primary" className="cursor-pointer">
                <span className="d-flex align-items-center">
                  {selectedAgent.label}{" "}
                  <Icon
                    name={"cross"}
                    onClick={() => handleAgentsFilterChange("all")}
                  />
                </span>
              </Badge>
            ) : null}
            {selectedPropertyTypes.length
              ? selectedPropertyTypes.map((item, idx) => (
                  <Badge
                    key={`building-badge-${idx}`}
                    color="info"
                    className="cursor-pointer"
                  >
                    <span className="d-flex align-items-center">
                      {item.label}{" "}
                      <Icon
                        name={"cross"}
                        onClick={() =>
                          handlePropertyTypesFilterChange(
                            selectedPropertyTypes.filter(
                              (x) => x.value !== item.value
                            )
                          )
                        }
                      />
                    </span>
                  </Badge>
                ))
              : null}
            {priceRange[0] || priceRange[1] < Number.MAX_SAFE_INTEGER ? (
              <Badge color="info" className="cursor-pointer">
                <span className="d-flex align-items-center">
                  Price:{priceRange[0]?.toLocaleString()} AED -{" "}
                  {priceRange[1] < Number.MAX_SAFE_INTEGER
                    ? `${priceRange[1]?.toLocaleString()} AED`
                    : "MAX"}
                  <Icon
                    name={"cross"}
                    onClick={() => {
                      setPriceRange((c) => [0, Number.MAX_SAFE_INTEGER]);
                      localStorage.removeItem(LocalStorageKeys.PLPriceRange);
                      resetPage();
                    }}
                  />
                </span>
              </Badge>
            ) : null}
            {isAnyFilterSet ? (
              <Badge
                color="danger"
                className="cursor-pointer"
                onClick={clearAllFilters}
              >
                Clear All
              </Badge>
            ) : null}
          </div>
        </BlockHead>
        {/* ================ END OF PAGE HEADER =================== */}

        {/* ================ MAIN BLOCK =================== */}
        <Block>
          <DataTable className="card-stretch">
            {/* ============= SEARCH CARD ================ */}
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setIsSearchClosed(!isSearchClosed);
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${
                            tablesm ? "active" : ""
                          }`}
                          onClick={() => setTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div
                          className={`toggle-content ${
                            tablesm ? "content-active" : ""
                          }`}
                        >
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button
                                className="btn-icon btn-trigger toggle"
                                onClick={() => setTableSm(false)}
                              >
                                <Icon className="arrow-left"></Icon>
                              </Button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                {authAgent ? (
                  <div>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="btn btn-trigger btn-icon dropdown-toggle"
                      >
                        <Icon name="filter-alt"></Icon>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-md">
                        <div className="dropdown-head">
                          <span className="sub-title dropdown-title">
                            Filter Pocket Listings
                          </span>
                        </div>
                        <div className="dropdown-body dropdown-body-rg">
                          <Row>
                            <Col sm="6">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  name="allListings"
                                  id="allListings"
                                  checked={!showMyPocketsFilter}
                                  onChange={handleShowMyPocketsFilter}
                                  className="custom-control-input"
                                />
                                <label
                                  class="custom-control-label"
                                  for="allListings"
                                >
                                  All
                                </label>
                              </div>
                            </Col>
                            <Col sm="6">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  name="myListings"
                                  id="myListings"
                                  checked={showMyPocketsFilter}
                                  onChange={handleShowMyPocketsFilter}
                                  className="custom-control-input"
                                />
                                <label
                                  class="custom-control-label"
                                  for="myListings"
                                >
                                  Mine only
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                ) : null}
              </div>
              <div
                className={`card-search search-wrap ${
                  !isSearchClosed && "active"
                }`}
              >
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active bg-transparent text-secondary"
                      onClick={() => {
                        setSearchText("");
                        setIsSearchClosed(!isSearchClosed);
                        queryPropertyCards({ search: "" });
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control ps-5 bg-transparent text-secondary"
                      placeholder={"Search by Reference ID..."}
                      value={searchText}
                      onChange={handleSearchSubmit} // Call the search function on change
                    />
                    <Button className="search-submit btn-icon bg-transparent text-secondary border-0">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {/* ============= END OF SEARCH CARD ================ */}

            {/* ============= DATA TABLE ================ */}
            <DataTableBody>
              {/* ============= HEADERS (SELECTED FIELDS TO DISPLAY) ================ */}
              <DataTableHead className="nk-tb-item">
                <DataTableRow className="nk-tb-col-check">
                  <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="check-all"
                      onChange={(e) => selectAllPropertyCards(e)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="check-all"
                    ></label>
                  </div>
                </DataTableRow>

                {PROPERTY_LIST_FIELDS_POCKETLIST.map((item, idx) => (
                  <DataTableRow className="text-center" key={idx}>
                    <span className="sub-text mx-2 ">{item}</span>
                  </DataTableRow>
                ))}
                {screenSize.width <= ScreenBreakPoints.laptop ? null 
                : <DataTableRow className="nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1 my-n1"></ul>
                  </DataTableRow>}
              </DataTableHead>
              {/* ============= END OF HEADERS (SELECTED FIELDS TO DISPLAY) ================ */}
              {loading ? (
                <LoadingComponent />
              ) : (
                propertyCardsWithChecks &&
                propertyCardsWithChecks.length > 0 &&
                propertyCardsWithChecks.map((property, idx) => (
                  <PropertyTableItem
                    key={`item-${idx}`}
                    propertyCard={property}
                    index={idx}
                  />
                ))
              )}
            </DataTableBody>
            {/* ============= END OF DATA TABLE ================ */}
            {loading ? null : (
              <div className="card-inner">
                {propertyCards?.length > 0 ? (
                  <PaginationComponent
                    noDown
                    currentPage={page ?? 1}
                    paginate={(selectedPage) => {
                      if (selectedPage === page) {
                        return;
                      }
                      setRefresh(!refresh);
                      setPage(selectedPage);
                      localStorage.setItem(
                        LocalStorageKeys.PLCurrentPage,
                        selectedPage
                      );
                    }}
                    itemPerPage={perPage ?? 10}
                    totalItems={filteredCount}
                  />
                ) : error ? (
                  <div className="text-center">
                    <span className="text-danger">
                      Error loading data: {error.message}
                    </span>
                  </div>
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            )}
          </DataTable>
        </Block>
        {/* ================ END OF MAIN BLOCK =================== */}
        <ErrorModal
          isOpen={isErrorModalOpen}
          toggleFn={toggleErrorModal}
          header={"Cannot create Pocket Listing"}
        >
          <p className="fs-6 text-center" style={{ maxWidth: "40ch" }}>
            The property card you are trying to create is already assigned to
            another agent.
          </p>
          <p className="text-soft">Please contact administration for help.</p>
          <Button
            className="btn-mw btn-light"
            size="lg"
            onClick={toggleErrorModal}
          >
            OK
          </Button>
        </ErrorModal>
      </Content>
    </React.Fragment>
  );
};

export default ListPocketListingUI;

const ButtonGroup = ({ filterValue, handlerFn }) => {
  return (
    <>
      <Button
        color={!filterValue ? "secondary" : "white"}
        onClick={() => handlerFn()}
      >
        <div className="d-flex" style={{ gap: "0.5rem" }}>
          <small>
            <Icon
              name={!filterValue ? "check-c" : "square-c"}
            />
          </small>
          All Pocket Listings
        </div>
      </Button>
      <Button
        className={"border"}
        color={filterValue ? "secondary" : "white"}
        onClick={() => handlerFn()}
      >
        <div className="d-flex" style={{ gap: "0.5rem" }}>
          <small>
            <Icon
              name={filterValue ? "check-c" : "square-c"}
            />
          </small>
          My Pocket Listings
        </div>
      </Button>
    </>
  )
}