import React, { useState, useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchCategory,
    fetchPropertiesAdmin,
} from "../../redux/actions/property";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Button, Modal, ModalBody, Input } from "reactstrap";
import {
    Block,
    BlockHeadContent,
    BlockTitle,
    BlockBetween,
    BlockHead,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    Row,
    Col,
    TooltipComponent,
} from "../../components/Component";
import {  actionS3Objects, uploadToS3 } from "../../utils/envConfig";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ConfirmModal from "../../components/miscellaneous/ConfirmModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const CategoryList = () => {
    let navigate = useNavigate();

    const dispatch = useDispatch();

    const axios = useAxiosPrivate();

    const folderName = "Category";
    const Category = useSelector((state) => state.property?.categories);
    const property = useSelector((state) => state.property?.propertiesAdmin);
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const user = useSelector((state) => state?.user?.loggedInUser);
    const { access_token } = user || "";
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    // State Variables
    const [showModal, setShowModal] = useState(false);
    const [categoryId, setCategoryId] = useState("");
    const [categoryname, setCategoryName] = useState("");
    const [check, setCheck] = useState([]);
    const createdBy = user?.id;
    const firstName = user?.first_name;
    const lastName = user?.last_name;
    const createdName = `${firstName} ${lastName}`;
    const [category, setCategory] = useState("");
    const [avatar, setAvatar] = useState("");
    const [displayAvatar, setDisplayAvatar] = useState("");
    const [categoryIconPath, setCategoryIconPath] = useState("");
    const [loading, setLoading] = useState(false);

    // Input Validation
    const validate = (category, img) => {
        if (category.length < 2) {
            toast.error("Enter valid Category");
            return false;
        } else if (/^\d+$/.test(category)) {
            toast.error("Enter valid Category");
            return false;
        }
        if (!img) {
            toast.error("Upload Icon for Category");
            return false;
        } else {
            return true;
        }
    };
    const validateEdit = (category, img) => {
        if (category.length < 2) {
            toast.error("Enter valid Category");
            return false;
        } else {
            return true;
        }
    };
    const [errorMessage, setErrorMessage] = useState("");

    const handleImage = async (e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            const allowedTypes = [
                "image/jpeg",
                "image/png",
                "image/gif",
                "image/jpg",
                "image/svg",
                "image/webp",
            ];

            if (allowedTypes.includes(file.type)) {
                let url = URL.createObjectURL(file);
                setDisplayAvatar(url);
                setAvatar(file);
                setErrorMessage("");
            } else {
                setErrorMessage(
                    "Please upload a valid image file (JPEG, PNG, GIF, JPG, SVG, WEBP)"
                );
            }
        }
    };

    const handleRemove = (e) => {
        e.preventDefault();
        setDisplayAvatar("");
        setAvatar(null);
        setErrorMessage("");
        // Clear the value of the file input
        const fileInput = document.querySelector('input[name="image"]');
        if (fileInput) {
            fileInput.value = ""; // Reset the input field value
        }
    };
    const handleUpload = async (file, fileName) => {
        const uploadData = JSON.stringify({
            action: `${actionS3Objects[1]}`,
            fileName: fileName,
        });
        const resp = await axios.post(`/upload`, uploadData);
        const s3url = resp.data.signedRequest;
        if (s3url) {
            try {
                const myHeaders = new Headers({ "Content-Type": "image/*" });
                await Axios.put(s3url, file, { myHeaders });
            } catch (err) {
                // Handle Error Here
                toast.error("Server error. Try Again!");
            }
        }
    };
    const handleEdit = (e, item) => {
        e.preventDefault();
        setOpen(true);
        setEdit(true);
        setCategoryId(item.id);
        setCategory(item.category);
        setDisplayAvatar(item.image);
        setCategoryIconPath(item.icon);
    };
    const resetForm = () => {
        setOpen(false);
        setEdit(false);
        setCategoryId(null);
        setCategory(null);
        setDisplayAvatar(null);
        setCategoryIconPath("");
    };
    const handleModal = (e, id, name) => {
        e.preventDefault();
        setCategoryId(id);
        setCategoryName(name);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        resetForm();
    };
    // Add Category Info in DB
    const addCategory = async (e) => {
        e.preventDefault();
        if (validate(category, avatar)) {
            let fileName = "";
            if (avatar.name) {
                fileName = `${folderName}/${uuidv4()}.${avatar.name
                    .split(".")
                    .pop()}`;
                handleUpload(avatar, fileName);
            }
            const formData = {
                category: category,
                icon: fileName,
                createdBy: createdBy,
            };
            try {
                await axios
                    .post(`/category`, formData)
                    .then((category) => {
                        navigate(0);
                    })
                    .catch((err) => {
                        toast.error("Category already exists!");
                    });
            } catch (err) {
                // console.log(err)
                toast.error("Server error. Try Again !");
            }
        }
    };
    const editCategory= async (e) => {
        e.preventDefault();

        if (validateEdit(category)) {
            let fileName = categoryIconPath ? categoryIconPath : "";
            if (avatar && avatar.name) {
                fileName = `${folderName}/${uuidv4()}.${avatar.name
                    .split(".")
                    .pop()}`;
                fileName && (await uploadToS3(avatar, fileName,"image/*",axios));
            }

            const formData = {
                category,
                icon: fileName,
            };

            try {
                setLoading(true);
                const res = await axios
                    .patch(`/category/${categoryId}`, formData, { headers })
                    .then((community) => {
                        navigate(0);
                    })
                    .catch((err) => {
                        toast.error("Category not modified !");
                    });
            } catch (err) {
                // console.log(err)
                toast.error("Server error. Try Again !");
                setLoading(false);
            }
        }
    };
    useEffect(() => {
        dispatch(fetchCategory(axios));
        dispatch(fetchPropertiesAdmin(axios));
    }, [dispatch]);

    const submitDel = async (e) => {
        e.preventDefault();
        try {
            await axios
                .delete(`/category/${categoryId}`)
                .then((category) => {
                    navigate(0);
                })
                .catch((err) => {
                    if (err.response.data.message === "LinkedtoListing") {
                        toast.error(
                            "Category cannot be removed as it is linked to a Property Listing"
                        );
                    } else {
                        toast.error("Category not removed !");
                    }
                });
        } catch (err) {
            // console.log(err)
            toast.error("Server error. Try Again !");
        }
        setShowModal(false);
    };

    useEffect(() => {
        if (Category?.length > 0 && property?.length > 0) {
            let arr = Category.map((item, index) =>
                property.map((p) => p.categoryId === item.id)
            );
            let empty = [];
            arr.forEach((element) => {
                empty.push(element.reduce((x, y) => x || y, false));
            });
            setCheck(empty);
        }
    }, [Category, property]);

    return (
        <>
            <React.Fragment>
                <Head title="Category"></Head>
                <Content>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle>Categories</BlockTitle>
                            </BlockHeadContent>
                            <BlockHeadContent>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <div className="toggle-expand-content">
                                        <ul className="nk-block-tools g-3">
                                            <li className="nk-block-tools-opt">
                                                <Button
                                                    className="toggle btn-icon d-md-none"
                                                    color="primary"
                                                >
                                                    <Icon name="plus"></Icon>
                                                </Button>
                                                <Button
                                                    className="toggle d-none d-md-inline-flex"
                                                    color="primary"
                                                    onClick={() => {
                                                        setOpen(true);
                                                    }}
                                                >
                                                    <Icon name="plus"></Icon>
                                                    <span>Add Category</span>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>

                    <Block>
                        {Category && (
                            <div className="nk-tb-list is-separate is-medium mb-3">
                                <DataTableHead className="nk-tb-item">
                                    {/* <DataTableRow className="nk-tb-col-check">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="pid-all"
                                                // onChange={ ( e ) => selectorCheck( e ) }
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="pid-all"
                                            ></label>
                                        </div>
                                    </DataTableRow> */}
                                    <DataTableRow>
                                        <span className="sub-text">
                                            Category
                                        </span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Edit</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Delete</span>
                                    </DataTableRow>
                                </DataTableHead>
                                {Category &&
                                    [...Category].map((item, index) => (
                                        <DataTableItem key={index}>
                                            {/* <DataTableRow className="nk-tb-col-check">
                                                <div className="custom-control custom-control-sm custom-checkbox notext">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        defaultChecked={
                                                            item.check
                                                        }
                                                        id={index + "oId-all"}
                                                        key={Math.random()}
                                                        // onChange={ ( e ) => onSelectChange( e, index ) }
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={
                                                            index + "oId-all"
                                                        }
                                                    ></label>
                                                </div>
                                            </DataTableRow> */}
                                            <DataTableRow>
                                                <a
                                                    href="#id"
                                                    onClick={(ev) =>
                                                        ev.preventDefault()
                                                    }
                                                >
                                                    {item?.category}
                                                </a>
                                            </DataTableRow>
                                            <DataTableRow>
                                            <a
                                                    href="#"
                                                    onClick={(ev) =>
                                                        handleEdit(ev, item)
                                                    }
                                                >
                                                    <Icon
                                                        name="edit"
                                                        style={{
                                                            fontSize: "1.3rem",
                                                        }}
                                                    ></Icon>
                                                </a>
                                            </DataTableRow>
                                            <DataTableRow>
                                                {check.length > 0 &&
                                                check[index] === true ? (
                                                    <>
                                                        <TooltipComponent
                                                            tag="a"
                                                            containerClassName="btn btn-icon"
                                                            id={"delete"}
                                                            icon="trash"
                                                            direction="top"
                                                            text={
                                                                "Category can't be removed as it is linked to a Property Listing"
                                                            }
                                                        />
                                                    </>
                                                ) : (
                                                    <a
                                                        onClick={(e) =>
                                                            handleModal(
                                                                e,
                                                                item.id,
                                                                item.category
                                                            )
                                                        }
                                                        className="btn btn-icon"
                                                    >
                                                        <Icon
                                                            name="trash"
                                                            style={{
                                                                color: "#e85347",
                                                                fontSize:
                                                                    "1.3rem",
                                                            }}
                                                        />
                                                    </a>
                                                )}
                                            </DataTableRow>
                                        </DataTableItem>
                                    ))}
                            </div>
                        )}
                    </Block>
                    {/* ****** Modal Start ****** */}
                    {showModal ? (
                        <ConfirmModal
                            msg={"Delete "}
                            name={"Category: " + categoryname}
                            open={showModal}
                            handleClick={handleModal}
                            handleConfirm={submitDel}
                            handlecloseModal={closeModal}
                        />
                    ) : null}
                    {/* ****** Modal End ****** */}
                    <Modal
                        isOpen={open}
                        toggle={() => setOpen(false)}
                        className="modal-dialog-centered"
                        size="lg"
                    >
                        <ModalBody>
                            <a href="#cancel" className="close">
                                {" "}
                                <Icon
                                    name="cross-sm"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        setOpen(false);
                                    }}
                                ></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title">{edit?"Edit":"Add"} Category</h5>
                                <div className="mt-4">
                                    {/* <form onSubmit={ handleSubmit( onFormSubmit ) }> */}
                                    <form
                                        onSubmit={(e) =>
                                            edit
                                                ? editCategory(e)
                                                : addCategory(e)
                                        }
                                    >
                                        <Row className="g-3">
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        Category
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="category"
                                                            value={category}
                                                            onChange={(e) =>
                                                                setCategory(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col sm="6">
                                                <div className="form-group">
                                                    <label className="form-label">
                                                        Icon
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        <div className="form-file">
                                                            <Input
                                                                type="file"
                                                                id="customFile"
                                                                accept="image/*"
                                                                onChange={(e) =>
                                                                    handleImage(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorMessage && (
                                                        <div className="text-danger">
                                                            {errorMessage}
                                                        </div>
                                                    )}
                                                </div>
                                                {displayAvatar && (
                                                    <div>
                                                        <p>Selected Image:</p>
                                                        <div className="position-relative">
                                                            <img
                                                                src={
                                                                    displayAvatar
                                                                }
                                                                alt="Selected"
                                                                style={{
                                                                    height: "15rem",
                                                                }}
                                                            />
                                                            <button
                                                                className="btn btn-danger position-absolute top-0 start-0"
                                                                onClick={
                                                                    handleRemove
                                                                }
                                                            >
                                                                <Icon name="trash"></Icon>
                                                            </button>
                                                        </div>{" "}
                                                    </div>
                                                )}
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        Created By
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        {createdName}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col size="12" className="d-flex justify-content-end">
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                >
                                                    {/* <Icon className="plus"></Icon> */}
                                                    <span>
                                                    {loading
                                                            ? "Loading.."
                                                            : edit
                                                            ? "Save"
                                                            : "Add Category"}
                                                    </span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </Content>
            </React.Fragment>
        </>
    );
};

export default CategoryList;
