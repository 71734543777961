import imageCompression from "browser-image-compression";
import { options as globalConfig } from "./config";

export default async function compressImage(image, options) {
  try {
    const compressedFile = await imageCompression(image, options ?? globalConfig);
    return compressedFile;
  } catch (error) {
    console.log(error);
  }
};