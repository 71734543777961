import { ScreenBreakPoints } from "../../../../utils/envConfig";

export const getCollapseLineHeight = (itemsCount) => {
    if (itemsCount <= 0) {
      return "0";
    } else if (itemsCount === 1) {
      return "4rem";
    } else if (itemsCount === 2) {
      return "9rem";
    } else {
      return "13rem";
    }
};

export const getNameColumnMinWidth = (screenWidth = 0) => {
  if (screenWidth > ScreenBreakPoints.xxxl) {
    return '34rem';
  } else if (screenWidth >= ScreenBreakPoints.xxl) {
    return '30rem';
  } else if (screenWidth > ScreenBreakPoints.lg) {
    return '19rem';
  } else {
    return '';
  }
}