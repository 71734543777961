import { useMutation } from "react-query";

/**
 * @typedef {Object} MutationQueryResult
 * @property {function(any, { onSuccess, onError, onSettled }): void} mutate - the mutation function to call
 * @property {function(any, { onSuccess, onError, onSettled }): void} mutateAsync - the mutation function to call, but async which returns an awaitable promise
 * @property {"error" | "idle" | "loading" | "success"} status - The status of the mutation
 * @property {unknown | undefined} data - The last successfully resolved data for the query.
 * @property {unknown | undefined} error - The error object for the query, if an error was encountered.
 * @property {() => void} reset - A function to clean the mutation internal state (i.e., it resets the mutation to its initial state).
 */

/**
 * @typedef {Object} MutationFunctionParams
 * @property {import("axios").AxiosInstance} Axios - An Axios instance
 * @property {unknown | undefined} payload - The payload sent with the mutation request (POST, PUT, PATCH, or DELETE)
 * @property {import("axios").AxiosRequestConfig | undefined} AxiosRequestConfig - Axios configuration options, including query params etc...
 */

/**
 * @typedef {Object} MutationFunctionReturnType
 * @property {import("axios").AxiosResponse} AxiosResponse - Axios HTTP response wrapper
 */

/**
 * The options used to configure the mutation query, there must be a `queryKey` provided, in case of dependencies,
 * use `queryKeyName` for the name, and `queryKeyDeps` as an array of dependencies
 * @typedef {Object} useMutationOptions
 * @property {string | undefined} mutationKey - A mutation key can be set to inherit defaults set with queryClient.setMutationDefaults or to identify the mutation in the devtools.
 * @property {(variables: import('react-query').TVariables) => Promise<TContext | void> | TContext | void} onMutate - This function will fire before the mutation function is fired and is passed the same variables the mutation function would receive. Useful to perform optimistic updates to a resource in hopes that the mutation succeeds
 * @property {(data: import('react-query').TData, variables: import('react-query').TVariables, context?: import('react-query').TContext) => Promise<unknown> | void} onSuccess - This function will fire when the mutation is successful and will be passed the mutation's result.
 * @property {(err: import('react-query').TError, variables: import('react-query').TVariables, context?: import('react-query').TContext) => Promise<unknown> | void} onError - This function will fire if the mutation encounters an error and will be passed the error.
 * @property {(data: import('react-query').TData, error: import('react-query').TError, variables: import('react-query').TVariables, context?: import('react-query').TContext) => Promise<unknown> | void} onSettled - This function will fire when the mutation is either successfully fetched or encounters an error and be passed either the data or error
 * @property {boolean} retryOnError - Specifies if the error should be retried. Defaults to false
 * @property {boolean | number | (failureCount: number, error: TError) => boolean} retry - Defaults to 0. If false, failed mutations will not retry. If true, failed mutations will retry infinitely. If set to an number, e.g. 3, failed mutations will retry until the failed mutations count meets that number.
 * @property {number | (retryAttempt: number, error: import('react-query').TError) => number} retryDelay - This function receives a `retryAtt
 */


/**
 * @description A general purpose fetch query hook, with pre-fetch for paginated queries, and refetching on window focus, all configurable
 * @param {function(MutationFunctionParams): MutationFunctionReturnType} mutationFn
 * @param {useMutationOptions} options - Additional options for configuring the fetch behavior.
 * @returns {MutationQueryResult} - Returns the data fetching hook result.
 */
const useMutationQuery = (
  mutationFn,
  options
) => {
  const mutation = useMutation(mutationFn, { 
    ...options,
    retry: options?.retryOnError ? options?.retry : 0,
    onError: (error, variables, context) => {
        if (options.onError && typeof options.onError === 'function') {
          options.onError(error, variables, context);
        } else {
          console.error('Error during mutation:', error);
        }
    },
  });

  return mutation;
}

export default useMutationQuery;