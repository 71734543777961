import * as types from "../types";

const initialState = {
  globalSettings: {},
  loading: true,
  error: null,
};

export const settings = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_GLOBAL_SETTINGS_STARTED:
      return { ...state, loading: true };
    case types.GET_GLOBAL_SETTINGS:
      return { ...state, loading: false, globalSettings: action.payload };
    case types.GET_GLOBAL_SETTINGS_FAILED:
      return { ...state, loading: false, error: action.error };
    case types.GET_GLOBAL_SETTINGS_FINISHED:
        return { ...state, loading: false };
    // update cases
    case types.UPDATE_GLOBAL_SETTINGS_STARTED:
        return { ...state, loading: true };
    case types.UPDATE_GLOBAL_SETTINGS:
        return { ...state, globalSettings: action.payload };
    case types.UPDATE_GLOBAL_SETTINGS_FAILED:
        return { ...state, error: action.error };
    case types.UPDATE_GLOBAL_SETTINGS_FINISHED:
        return { ...state, loading: false };
    default:
      return state;
  }
};
