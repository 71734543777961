import React from "react";
import { Button, Spinner } from "reactstrap";
import { Icon } from "../../components/Component";

function SaveButton({ loading = false }) {
  // JSX template
  return (
    <Button color="primary" type="submit">
      <Icon className="plus"></Icon>
      <span>
        {loading ? (
          <Spinner style={{ width: "1rem", height: "1rem" }} children={false} />
        ) : (
          "Save"
        )}
      </span>
    </Button>
  );
}

export default SaveButton;
