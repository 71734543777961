import { createSelector } from "@reduxjs/toolkit";

const selectPropertyCards = (state) => state.propertyCard;

export const propertyCardsSelector = createSelector(
  [selectPropertyCards],
  (propertyCardState) => ({
    propertyCards: propertyCardState.propertyCards?.data,
    filteredCount: propertyCardState.propertyCards?.filteredCount,
    loading: propertyCardState.loading,
    error: propertyCardState.error,
  })
);
