import React, { useState } from "react";
import
{
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { Icon } from '../Component';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import
{
    HttpStatus,
    newRoles,
} from '../../utils/envConfig';
import { humanizeDate } from '../../pages/users/components/property-cards/utils';
import { formatValue } from '../../utils/envConfig';
import ConfirmationModal from "../modals/ConfirmationModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useMutation, useQueryClient } from "react-query";

const PropertyCard = ( { data, lead } ) =>
{
    const navigate = useNavigate();
    const authAgent = useSelector( ( state ) => state?.user?.loggedInAgent );
    const authUser = useSelector( ( state ) => state?.user?.loggedInUser );
    const axios = useAxiosPrivate();

    const isAdmin =
        authUser &&
        ( authUser.role === newRoles.Admin ||
            authUser.role === newRoles.MasterAdmin );


    const [ isConfirmModalOpen, setIsConfirmModalOpen ] = useState( false );
    const queryClient = useQueryClient();
    const toggleUnlinkConfirmModal = () =>
        setIsConfirmModalOpen( !isConfirmModalOpen );

    const unlinkLeadMutation = useMutation(
        async ( id ) =>
        {
            const res = await axios.delete( `/property-cards/lead-to-card/${ id }` );
            if ( res.status !== HttpStatus.OK )
            {
                throw new Error( 'Failed to unlink lead' );
            }
            return res;
        },
        {
            onSuccess: () =>
            {
                queryClient.invalidateQueries( [ "propertyCards", lead.id ] );
                toast.success( 'Unlinked lead' );
            },
            onError: ( error ) =>
            {
                toast.error( 'An error occurred.' );
                console.log( error );
            },
            onSettled: () =>
            {
                toggleUnlinkConfirmModal();
            }
        }
    );

    const unlinkLead = () =>
    {
        unlinkLeadMutation.mutate( data?._id ?? data?.id );
    };

    return (
        <div className="card card-inner mt-5">
            <div className="d-flex align-center justify-between">
                <div className="">
                    <span className="fw-bold ">Ref No: </span>
                    <span className="">
                        { data?.propertyCard?.referenceNumber ?? '?' }{ ' ' }
                    </span>
                </div>
                <UncontrolledDropdown>
                    <DropdownToggle
                        tag="a"
                        href="#"
                        onClick={ ( ev ) => ev.preventDefault() }
                        className="dropdown-toggle btn btn-sm btn-icon btn-trigger me-n1"
                    >
                        <Icon name="more-v"></Icon>
                    </DropdownToggle>
                    <DropdownMenu>
                        <ul className="link-list-opt no-bdr">
                            { isAdmin ||
                                ( data?.propertyCard?.currentAgent &&
                                    data?.propertyCard?.currentAgent ===
                                    authAgent?.id ) ? (
                                <li>
                                    <DropdownItem
                                        tag="a"
                                        onClick={ ( e ) =>
                                        {
                                            navigate(
                                                `/property-detail/${ data?.propertyCard?.id ??
                                                data?.propertyCard?._id
                                                }`
                                            );
                                        } }
                                    >
                                        <Icon name="eye"></Icon>
                                        <span>View Property Card</span>
                                    </DropdownItem>
                                </li>
                            ) : null }

                            <li>
                                <DropdownItem
                                    tag="a"
                                    href="#dropdownuunlink"
                                    onClick={ toggleUnlinkConfirmModal }
                                >
                                    <Icon name="unlink-alt"></Icon>
                                    <span>Unlink</span>
                                </DropdownItem>
                            </li>
                        </ul>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
            <div>
                <h6 className="my-2" style={ { lineClamp: "2", height: "2rem", maxHeight: "4em" } }>
                    { `${ data?.propertyCard?.community?.community
                        }, ${ data?.propertyCard?.building?.name ?? 'Unknown building'
                        }` }

                </h6>
            </div>
            <div className="">
                <div>
                    <span className="fw-bold text-dark">
                        Beds:{ ' ' }
                    </span>
                    <span>
                        { data.propertyCard?.beds === undefined
                            ? '?'
                            : data.propertyCard?.beds }
                    </span>
                </div>
            </div>
            <div>
                <div>
                    <span className="fw-bold text-dark">
                        Area:{ ' ' }
                    </span>
                    <span>
                        { Number( data?.propertyCard?.size ).toFixed( 2 ) }{ ' ' }
                        sqft.
                    </span>
                </div>
                <div>
                    <span className="fw-bold text-dark">
                        Amount:{ ' ' }
                    </span>
                    <span>{ formatValue( data?.amount ?? '?' ) }AED</span>
                </div>
            </div>
            <div>
                <span className="fw-bold text-dark">
                    Deal Date:{ ' ' }
                </span>
                <span>
                    { data?.date
                        ? humanizeDate( data?.date, {
                            addTime: false
                        } )
                        : 'N/A' }
                </span>
            </div>
            <div className="d-flex my-2">
                <div className="badge badge-dim bg-primary-dim text-primary me-2">
                    { data?.propertyCard?.propertyType }
                </div>
                <div className="badge badge-dim bg-gray-dim text-dark">
                    { data?.propertyCard?.status }
                </div>
            </div>

            <ConfirmationModal
                isDeletion={ true }
                isOpen={ isConfirmModalOpen }
                toggleFn={ toggleUnlinkConfirmModal }
                title={ 'Confirm Unlink' }
                actionFn={ unlinkLead }
            >
                <p>Are you sure you want to unlink?</p>
            </ConfirmationModal>
        </div>
    );
};

export default PropertyCard;