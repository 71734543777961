import React, { useEffect, useState } from 'react'
import { getImage } from '../../../utils/envConfig';

export default function S3Image({ url }) {

    const [s3Url, setS3Url] = useState();
    useEffect(() => {
        const fetch = async () => {
            let data = await getImage(url)
            setS3Url(data?.signedRequest)
        }
        fetch()
    }, [url])
    return (
        <img src={s3Url} alt='' />
    )
}
