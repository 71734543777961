import * as types from "../types";

const initialState = {
  favourites: [],
  loggedInUser: {},
  loggedInAgent: null,
  user: {},
  agent: null,
  owners : {},
  onlyAgents : {},
  onlyUsers : {},
  clients: [],
  agents: [],
  userAgents: [],
  myClient: null,
  isLoggedIn: false,
  loading: true,
  leadsLoading: true,
  error: null,
  paginatedAgents:{},
  agent:{}
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_LOGIN:
      return {
        ...state,
        loggedInUser: action.payload,
        isLoggedIn: true,
        loading: false,
        error: null,
      };
    case types.GET_USERS_STARTED:
      return {
        ...state,
        leadsLoading: true,
        error: null,
      };
    case types.GET_USERS:
      return {
        ...state,
        users: action.payload,
        leadsLoading: false,
        error: null,
      };
    case types.GET_USERS_FAILED:
      return {
        ...state,
        leadsLoading: false,
        error: action.error,
      };
    case types.UPDATE_USER:
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null,
      };
    case types.GET_AGENTS:
      return {
        ...state,
        agents: action.payload,
        loading: false,
        error: null,
      };
    case types.GET_AGENTS_LIST_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_AGENTS_PAGINATED_STARTED:
      return {
        ...state,
        paginatedAgents: {},
        loading: true,
        error: null,
      };
    case types.GET_AGENTS_PAGINATED:
      return {
        ...state,
        paginatedAgents: action.payload,
        agents: action.payload?.agents,
        loading: action.loading,
        error: null,
      };

    case types.GET_AGENTS_PAGINATED_ERR:
      return {
        ...state,
        paginatedAgents: {},
        loading: false,
        error: action.error,
      };
    case types.GET_AGENTS_LIST:
      return {
        ...state,
        loading: false,
        error: null,
        agents: action.payload,
      };
    case types.GET_AGENTS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    // GET AGENT BY USER ID STATES
    case types.GET_AUTH_AGENT_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_AUTH_AGENT:
      return {
        ...state,
        loggedInAgent: action.payload,
        loading: false,
      };
    case types.GET_AUTH_AGENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.GET_AUTH_AGENT_FINISHED:
      return {
        ...state,
        loading: false,
      };
    case types.GET_AGENT_BY_USER_ID_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_AGENT_BY_USER_ID:
      return {
        ...state,
        agent: action.payload,
        error: null,
        loading: false,
      };
    case types.GET_AGENT_BY_USER_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.GET_USER_AGENTS:
      return {
        ...state,
        userAgents: action.payload,
        loading: false,
        error: null,
      };
    case types.REFRESH_TOKEN:
      return {
        ...state,
        loggedInUser: {
          ...state?.loggedInUser,
          access_token: action?.payload?.access_token,
        },
      };

    case types.GET_OWNERS:
      return {
        ...state,
        owners: action?.payload,
      };

    case types.GET_ONLY_AGENTS:
      return {
        ...state,
        onlyAgents: action?.payload,
      };

    case types.GET_ONLY_USERS:
      return {
        ...state,
        onlyUsers: action?.payload,
      };
    case types.LOGOUT:
      return {};
    default:
      return state;
  }
};
