import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonImage = ({ src, alt, width, height, className, style = {} }) => {
  const [loading, setLoading] = useState(true);

  return (
    <div style={{ width, height, position: "relative" }}>
      {loading && (
        <Skeleton
          height={height}
          width={width}
          style={{ position: "absolute" }}
        />
      )}
      <img
        src={src}
        alt={alt}
        width={width}
        height={height}
        className={className}
        style={{
          ...style,
          display: loading ? "none" : "block",
          position: "absolute",
        }}
        onLoad={() => setLoading(false)}
        onError={() => setLoading(false)}
      />
    </div>
  );
};

export default SkeletonImage;
