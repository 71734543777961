import React, { useEffect, useState} from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import
{
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    PreviewCard,
} from "../../components/Component";
import { Step, Steps } from "react-step-builder";
import AddOffPlanStepOne from "./components/AddOffPlanStepOne";
import AddOffPlanStepTwoEdit from "./components/AddOffPlanStepTwoEdit";
import AddOffPlanStepThreeEdit from "./components/AddOffPlanStepThreeEdit";
import AddOffPlanStepFourEdit from "./components/AddOffPlanStepFourEdit";
import AddOffPlanStepFiveEdit from "./components/AddOffPlanStepFiveEdit";
import { useParams } from "react-router-dom";
import { getImage } from "../../utils/envConfig";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { da } from "date-fns/locale";

const EditDevelopment = () =>
{
    const { id } = useParams();
    const axios = useAxiosPrivate();
    const [propertyTypeList, setPropertyTypeList] = useState(null);
    const [amenitiesFilesDev, setAmenitiesFilesDev] = useState(null);
    const [developmentsFilesDev, setDevelopmentsFilesDev] = useState(null);

    const [headerFilesDev, setHeaderFilesDev] = useState(null);
    const [headerVideoFilesDev, setHeaderVideoFilesDev] = useState(null);
    const [brochureFilesDev, setBrochureFilesDev] = useState(null);
    const [priceFilesDev, setPriceFilesDev] = useState(null);

      const [toBeUpdated, setToBeUpdated] = useState({});
      const [optDefaultDeveloper, setOptDefaultDeveloper] = useState(null);
      const [optDefaultFooter, setOptDefaultFooter] = useState(null);
      const [developmentLogoFilesDev, setDevelopmentLogoFilesDev] = useState(null);

      
    const [development, setDevelopment] = useState({
        _id: "",
        developmentName: "",
        emirate: "",
        community: {},
        slug: "",
        downpayment: "",
        completionDate: "",
        developmentDescription: "",
        propertyType: "",
        beds: [],
        amenities: [],
        developer: {},
        footer: {},
        developmentPhotos: [],
        video: "",
        brochure: "",
        headerImage: "",
        topImage: "",
        map: "",
        priceImage: "",
        keywords:[],
        metaDescription: "",
        metaTitle: "",
    });
    const offPlanUpdate = async (offPlanData) => {
        try {
            await axios.put(
                `/off-plan/${id}`,
                offPlanData
            );
            toast.success("Updated successfully");
        } catch (err) {
            // console.log(err)
            // toast.error("Server error. Try Again!");
        }
    };
    const getDevelopment = async () => {
        try {
            const { data } = await axios.get(
                `/off-plan/${id}`
            );

            const videoIMage = await getImage(data?.video,axios);
            const headerIMage = await getImage(data?.headerImage,axios);
            const brochure = await getImage(data?.brochure,axios);
            const priceImage = await getImage(data?.priceImage,axios);
            const developmentLogoImage = await getImage(data?.developmentLogo,axios);
            const amenities = await Promise.all(
                data?.amenities?.map(async (item,index) => {
                    const image = await getImage(item?.photo,axios);

                    return {
                        ...item,
                        id:item?._id,
                        image: image?.signedRequest,
                        prevPhoto: item?.photo,
                        file: image?.signedRequest,
                        index:index,
                    };
                })
            );

            setAmenitiesFilesDev(amenities);

            const devFiles = await Promise.all(
                data?.developmentPhotos?.map(async (item,index) => {
                    const image = await getImage(item?.file,axios);
                    return {
                        ...item,
                        image: image?.signedRequest,
                        file: image?.signedRequest,
                        prevPhoto: item?.file,
                        id:item?._id,
                        index:index,
                    };
                })
            );
            setDevelopmentsFilesDev(devFiles);
            setDevelopment({
                developmentName: data.developmentName,
                emirate: data.emirate,
                community: data.community,
                startingPrice: data.startingPrice,
                downpayment: data.downpayment,
                completionDate: data.completionDate,
                propertyType: data.propertyType,
                beds: data.beds,
                developmentDesc: data.developmentDescription,
                keywords: data?.keywords,
                metaDescription: data.metaDescription,
                slug: data?.slug,
                _id: data?._id,
                templateVersion: data?.templateVersion ?? "1",
                metaTitle: data?.metaTitle ?? "",
                paymentPlan:data?.paymentPlan,
                paymentScheme:data?.paymentScheme
            });

            setPropertyTypeList(
                data.propertyTypes?.length
                    ? data?.propertyTypes
                    : [
                          {
                              propertyType: null,
                              beds: [],
                          },
                      ]
            );

            setDevelopmentLogoFilesDev([
                {
                    file: developmentLogoImage?.signedRequest ?? "",
                    label: null,
                    image: developmentLogoImage?.signedRequest ?? null,
                    prevFileName: data?.developmentLogo,
                },
            ]);

            setAmenitiesFilesDev(amenities);

           setOptDefaultDeveloper( {label: data?.developer?.name,
            value: data?.developer?._id,
          });
          setOptDefaultFooter( {label: data?.footer?.name,
            value: data?.footer?._id,
          });

          setHeaderVideoFilesDev([
            {
                label: "",
                file: videoIMage?.signedRequest,
                image: videoIMage?.signedRequest,
                prevFileName: data.video,
            },
        ]);

        setHeaderFilesDev([
            {
                label: "",
                file: headerIMage?.signedRequest,
                image: headerIMage?.signedRequest,
                prevFileName: data.headerImage,
            },
        ]);
        setPriceFilesDev([
            {
                label: "",
                file: priceImage?.signedRequest,
                image: priceImage?.signedRequest,
                prevFileName: data?.priceImage,
            },
        ]);

        setBrochureFilesDev([
            {
                label: "",
                file: brochure?.signedRequest,
                image: brochure?.signedRequest,
                prevFileName: data?.brochure,
            },
        ]);

        } catch (err) {
            // console.log(err);
        }
    };
    // State Variables

    useEffect(() => {
        getDevelopment();
    }, []);

    const [finalePlanDetails, setFinalPlanDetails] = useState({});

    const [filesToUpload, setFilesToUpload] = useState({
        amenities: [],
        devPhotos: [],
        others: [],
        devLogo: null,
    });


    
    const Header = ( props ) =>
    {
        return (
            <div className="steps clearfix">
                <ul>
                    <li className={ props.current >= 1 ? "first done" : "first" }>
                        <a
                            href="#wizard-01-h-0"
                            onClick={ ( ev ) => ev.preventDefault() }
                        >
                            <span className="number">01</span>{ " " }
                            <h5>General Info</h5>
                        </a>
                    </li>
                    <li className={ props.current >= 2 ? "done" : "" }>
                        <a
                            href="#wizard-01-h-1"
                            onClick={ ( ev ) => 
                                { ev.preventDefault()
                                props.jump(2);}
                            }
                        >
                            <span className="number">02</span>{ " " }
                            <h5>Amenities</h5>
                        </a>
                    </li>
                    <li className={ props.current >= 3 ? "done" : "" }>
                        <a
                            href="#wizard-01-h-2"
                            onClick={ ( ev ) => 
                                { ev.preventDefault()
                                props.jump(3);}
                            }
                        >
                            <span className="current-info audible">
                                current step:{ " " }
                            </span>
                            <span className="number">03</span>
                            <h5>Developer and Footer Template</h5>
                        </a>
                    </li>
                    <li className={ props.current >= 4 ? "done" : "" }>
                        <a
                            href="#wizard-01-h-2"
                            onClick={ ( ev ) => 
                                { ev.preventDefault()
                                props.jump(4);}
                            }
                        >
                            <span className="current-info audible">
                                current step:{ " " }
                            </span>
                            <span className="number">04</span>
                            <h5>Development Images</h5>
                        </a>
                    </li>
                    <li className={ props.current === 5 ? "last done" : "last" }>
                        <a
                            href="#wizard-01-h-2"
                            onClick={ ( ev ) => 
                                { ev.preventDefault()
                                props.jump(5);}
                            }
                        >
                            <span className="current-info audible">
                                current step:{ " " }
                            </span>
                            <span className="number">05</span>
                            <h5>Files</h5>
                        </a>
                    </li>

                </ul>
            </div>
        );
    };
    const config = {
        before: Header,
    };
    return (
        <React.Fragment>
            <Head title="Off Plan"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle>Edit Off Plan</BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <PreviewCard>
                        <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                            <Steps config={ config }>
                            { propertyTypeList && 
                            <Step component={ AddOffPlanStepOne } offPlanUpdate={offPlanUpdate} edit={true} propertyTypeListDev={propertyTypeList} setFinalPlanDetails={setFinalPlanDetails} setFilesToUpload={setFilesToUpload} development={development} setDevelopment={setDevelopment}/>}
                                <Step component={ AddOffPlanStepTwoEdit } edit={true} offPlanUpdate={offPlanUpdate} amenitiesFilesDev={amenitiesFilesDev} setFinalPlanDetails={setFinalPlanDetails} setFilesToUpload={setFilesToUpload} development={development} setDevelopment={setDevelopment}/>
                                <Step component={ AddOffPlanStepThreeEdit } offPlanUpdate={offPlanUpdate}  developmentLogoFilesDev={developmentLogoFilesDev} optDefaultDeveloper={optDefaultDeveloper} optDefaultFooter={optDefaultFooter} edit={true} setFinalPlanDetails={setFinalPlanDetails} setFilesToUpload={setFilesToUpload} development={development} setDevelopment={setDevelopment}/>
                                <Step component={ AddOffPlanStepFourEdit } developmentsFilesDev={developmentsFilesDev} offPlanUpdate={offPlanUpdate} edit={true} setFinalPlanDetails={setFinalPlanDetails} setFilesToUpload={setFilesToUpload} development={development} setDevelopment={setDevelopment}/>
                                <Step component={ AddOffPlanStepFiveEdit }
                                    headerVideoFilesDev={headerVideoFilesDev}
                                    headerFilesDev={headerFilesDev}
                                    brochureFilesDev={brochureFilesDev}
                                    priceFilesDev={priceFilesDev}
                                    offPlanUpdate={offPlanUpdate}
                                 edit={true} finalePlanDetails={finalePlanDetails} setFinalPlanDetails={setFinalPlanDetails} filesToUpload={filesToUpload} setFilesToUpload={setFilesToUpload} development={development} setDevelopment={setDevelopment}/>
                            </Steps>
                        </div>
                    </PreviewCard>
                </Block>
            </Content >
        </React.Fragment> );
};

export default EditDevelopment;