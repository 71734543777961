import React, { useEffect, useState } from "react";
import { Button, Card, Col } from "reactstrap";
import { getImage } from "../../../utils/envConfig";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

import { defaultUserImage as defaultImage } from "../../../utils/envConfig";
import { Icon } from "../../../components/Component";

export default function SelectAgentCard ( {
    photo,
    subText,
    title,
    onClickSelect = ( e ) => { },
    phone
} )
{
    const [ image, setImage ] = useState( defaultImage );

    const axios = useAxiosPrivate();

    const fetchAgentImage = async () =>
    {
        if ( !photo )
        {
            return;
        }
        try
        {
            const image = await getImage( photo, axios );
            setImage( image?.signedRequest );
        } catch ( err )
        {
            console.log( err );
        }
    };

    useEffect( () =>
    {
        photo && fetchAgentImage();
    }, [] );
    return (
        <Col xxl={ 3 } lg={ 4 } sm={ 6 }>
            <Card className="product-card p-2">
                <div className="product-thumb postion-relative" style={ { height: "22.5rem" } }>
                    <div>
                        <img className="card-img-top position-absolute h-100 "
                            style={ { objectFit: "fill", objectPosition: "center" } } src={ image } alt="" />
                    </div>
                </div>
                <div className="">
                    <h5 className="mt-3">
                        <div>{ title }</div>
                    </h5>
                    <a style={ { cursor: "pointer" } } href={ `mailto:${ subText }` } className="agent-a-custom">{ subText }</a>
                    <div className="d-flex justify-content-start align-items-center mt-1 mb-1">
                        <a href={ `whatsapp://send?phone=${ phone }` }>
                            <Icon name="whatsapp" className="me-1" style={ { cursor: "pointer" } }>
                            </Icon>
                        </a>
                        <a href={ `tel:${ phone }` }>
                            <Icon name="call" className="me-1" style={ { cursor: "pointer" } }>
                            </Icon>
                        </a>
                        <a href={ `mailto:${ subText }` }>
                            <Icon name="mail" className="me-1" style={ { cursor: "pointer" } }>
                            </Icon>
                        </a>
                    </div>
                    <hr></hr>
                    <div className="d-flex justify-content-end align-items-end" >
                        <Button
                            className="bg-primary"
                            onClick={ onClickSelect }
                        >
                            Select
                        </Button>
                    </div>
                </div>
            </Card>
        </Col>
    );
}
