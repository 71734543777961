import React, { useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchPropertyXml } from "../../redux/actions/property";
import XMLViewer from "react-xml-viewer";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Button, Card } from "reactstrap";
import { Icon } from "../../components/Component";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const PropertyDetailsXml = () => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const xmlStr = useSelector((state) => state.property.xmlString);

  useEffect(() => {
    dispatch(fetchPropertyXml(axios, id));
  }, [axios, dispatch, id]);

  const theme = {
    tagColor: "#9D72FF",
    cdataColor: "#212121",
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(xmlStr);
      toast.success("XML copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <React.Fragment>
      <Head title="Properties XML"></Head>
      <Content>
        <Card className="p-4">
          <div className="d-flex justify-content-between">
            <h4>XML of Property Listing</h4>
            <Button outline color="primary" onClick={copyToClipboard}>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ gap: "0.5rem" }}
              >
                <Icon name={"clipboard"} />
                Copy to Clipboard
              </div>
            </Button>
          </div>
          <div className="border p-2 mt-2">
            <XMLViewer xml={xmlStr} theme={theme} />
          </div>
        </Card>
        <Button
          className="btn btn-icon"
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "100px",
            zIndex: 1000,
            borderRadius: "50%",
          }}
        >
          <Icon name="arrow-up" />
        </Button>
      </Content>
    </React.Fragment>
  );
};

export default PropertyDetailsXml;
