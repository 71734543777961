
import LeadsListTable from "../components/table/LeadsListTable";

const LeadsLists = () => {
    return (
        <>
            <LeadsListTable title={"Leads List"} name={"Lead"} role={null} />
        </>
    );
};

export default LeadsLists;
