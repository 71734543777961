import classNames from "classnames";

/**
 * @typedef {Object} CheckboxButtonProps
 * @property {boolean} [checked] - Boolean state to determine if the component is checked or not.
 * @property {string} [id] - the id used for the checkbox button and its label (must be provided for the label to trigger the button).
 * @property {string} [name] - Name given to the checkbox button (must be provided for a group of buttons meant to refer to the same thing).
 * @property {function} [onChange] - callback function to call when the checkbox button's state changes
 * @property {string} [labelText] - The text to display on the label
 * @property {string} [containerClassName] - Additional CSS class names to apply to the container.
 * @property {string} [inputClassName] - Additional CSS class names to apply to the input element.
 * @property {string} [labelClassName] - Additional CSS class names to apply to the label.
 * @property {React.CSSProperties} [style] - Inline styles to apply to the container.
 */

/**
 * CheckboxButton component renders a checkbox button with its label.
 *
 * @param {CheckboxButtonProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered checkbox button with its label.
 */
const CheckboxButton = ({
    id, 
    name, 
    checked, 
    onChange, 
    labelText, 
    labelClassName, 
    containerClassName, 
    inputClassName, 
    style 
}) => {
  const containerClasses = classNames({
    "custom-control": true,
    "custom-checkbox": true,
    [`${containerClassName}`]: containerClassName,
  });
  const labelClasses = classNames({
    "custom-control-label": true,
    [`${labelClassName}`]: labelClassName,
  });
  const inputClasses = classNames({
    "custom-control-input": true,
    [`${inputClassName}`]: inputClassName,
  });

  return (
    <div className={containerClasses} style={style ?? {}}>
      <input
        type='checkbox'
        checked={checked}
        id={id}
        name={name}
        className={inputClasses}
        onChange={onChange}
      />
      <label className={labelClasses} htmlFor={id}>
        {labelText ?? 'Option'}
      </label>
    </div>
  );
};

export default CheckboxButton;
