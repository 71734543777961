import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import DatePicker from 'react-datepicker';
import {
    differenceInDays,
    endOfMonth,
    startOfMonth,
    subDays,
    subMonths
} from 'date-fns';
import {
    DealCategory,
    DealStatus,
    MarketTypes,
    formatDate,
    formatValue
} from '../../utils/envConfig';
import CardShimmer from '../../components/CardShimmer';
import { PieChart } from '../../components/charts/Chart';
import DealClosedByAgentCard from './components/DealClosedByAgentCard';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import {
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle
} from '../../components/Component';
import {
    Badge,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import DealsClosedGrid from '../../components/DealsClosedGrid';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchAgents } from '../../redux/actions/user';

export default function DealsClosedAnalysis() {
    const printRef = useRef();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const agents = useSelector((state) => state?.user?.agents);

    // dates
    const today = new Date();

    const [fetching, setFetching] = useState(false);

    // dates

    const [smBtn, setSmBtn] = useState();

    const [market, setMarket] = useState(null);

    const [dateError, setDateError] = useState('');

    const [monthEnd, setMonthEnd] = useState(endOfMonth(today));
    const [monthStart, setMonthStart] = useState(startOfMonth(today)); //getting data for the past 30 days

    // deals
    const [dealsClosedByLeadSource, setDealsClosedByLeadSource] =
        useState(null);
    const [agentsLeaderboard, setAgentsLeaderboard] = useState(null);
    const [latestDealsClosed, setLatestDealsClosed] = useState(null);
    const [latestDealsCreated, setLatestDealsCreated] = useState(null);

    const [dealsClosedByAgeGroup, setDealsClosedByAgeGroup] = useState(null);
    const [dealsClosedByGender, setDealsClosedByGender] = useState(null);
    const [dealsClosedByNationality, setDealsClosedByNationality] =
        useState(null);

    const axios = useAxiosPrivate();

    const getDealsClosedByLeadSource = async () => {
        try {
            // const dayDifference = dayEnd - dayStart;

            const fromDate = `${formatDate(monthStart)}T00:00:00.000Z`;
            const toDate = `${formatDate(monthEnd)}T23:59:59.999Z`;

            const { data } = await axios.get(
                `/reports/marketing/dealsPerLeadSource?from_date=${fromDate}&to_date=${toDate}&status=${
                    DealStatus.Completed
                }${
                    !market
                        ? ''
                        : market === MarketTypes.Offplan
                        ? `&category[]=${DealCategory.PrimaryOffplan}`
                        : market === MarketTypes.Listing
                        ? `&category[]=${DealCategory.ListingAcquired}&category[]=${DealCategory.SecondaryProperty}`
                        : ''
                }`
            );

            setDealsClosedByLeadSource(data);
        } catch (err) {
            console.log(err);
        }
    };

    const agentsLeaderboardByDealsClosed = async () => {
        try {
            const fromDate = `${formatDate(monthStart)}T00:00:00.000Z`;
            const toDate = `${formatDate(monthEnd)}T23:59:59.999Z`;

            const agentsQuery = agents
                ?.map((agent) => `agents[]=${agent?.id ?? agent?._id}`)
                ?.join('&');

            const categoryQuery = !market
                ? ''
                : market === MarketTypes.Offplan
                ? `&category[]=${DealCategory.PrimaryOffplan}`
                : market === MarketTypes.Listing
                ? `&category[]=${DealCategory.ListingAcquired}&category[]=${DealCategory.SecondaryProperty}`
                : '';

            const { data } = await axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&status[]=${DealStatus.Completed}&${agentsQuery}${categoryQuery}`
            );

            const agentsLeaderboard = agents
                ?.map((agent) => {
                    const agentDeals = data?.filter(
                        (deal) =>
                            deal?.assigned_agent === agent?.id ||
                            deal?.buyer_internal_agent_id === agent?.id ||
                            deal?.seller_internal_agent_id === agent?.id
                    );
                    return {
                        ...agent,
                        dealsCount: agentDeals?.length ?? 0,
                        totalAmount: agentDeals
                            ?.map((deal) => deal?.agreed_salePrice ?? 0)
                            ?.reduce((a, b) => a + b, 0)
                    };
                })
                ?.filter((agent) => agent?.dealsCount > 0)
                ?.sort((a, b) => b?.dealsCount - a?.dealsCount);

            setAgentsLeaderboard(agentsLeaderboard);
        } catch (err) {
            console.log(err);
            setAgentsLeaderboard([]);
        }
    };

    const getLatestDeals = async () => {
        try {
            const fromDate = `${formatDate(monthStart)}T00:00:00.000Z`;
            const toDate = `${formatDate(monthEnd)}T23:59:59.999Z`;

            const dealStatusQuery = Object.values(DealStatus)
                .filter((item) => item !== DealStatus.Completed)
                .map((item) => `status[]=${item}`)
                .join('&');

            const categoryQuery = !market
                ? ''
                : market === MarketTypes.Offplan
                ? `&category[]=${DealCategory.PrimaryOffplan}`
                : market === MarketTypes.Listing
                ? `&category[]=${DealCategory.ListingAcquired}&category[]=${DealCategory.SecondaryProperty}`
                : '';

            const { data: dealsClosed } = await axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&status[]=${DealStatus.Completed}&with_users=true${categoryQuery}`
            );
            const { data: dealsCreated } = await axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&${dealStatusQuery}&with_users=true${categoryQuery}`
            );

            setLatestDealsClosed(dealsClosed);
            setLatestDealsCreated(dealsCreated);
        } catch (err) {
            console.log(err);
            setLatestDealsClosed([]);
            setLatestDealsCreated([]);
        }
    };

    const getDealsClosedByAgeGroup = async () => {
        try {
            const categoryQuery = !market
                ? ''
                : market === MarketTypes.Offplan
                ? `&category[]=${DealCategory.PrimaryOffplan}`
                : market === MarketTypes.Listing
                ? `&category[]=${DealCategory.ListingAcquired}&category[]=${DealCategory.SecondaryProperty}`
                : '';

            const { data } = await axios.get(
                `/reports/main/deals/dealsClosedByAgeGroup?from_date=${monthStart}&to_date=${monthEnd}${categoryQuery}`
            );
            setDealsClosedByAgeGroup(data);
        } catch (err) {
            console.log(err);
            setDealsClosedByAgeGroup([]);
        }
    };

    const getDealsClosedByGender = async () => {
        try {
            const categoryQuery = !market
                ? ''
                : market === MarketTypes.Offplan
                ? `&category[]=${DealCategory.PrimaryOffplan}`
                : market === MarketTypes.Listing
                ? `&category[]=${DealCategory.ListingAcquired}&category[]=${DealCategory.SecondaryProperty}`
                : '';

            const { data } = await axios.get(
                `/reports/main/deals/dealsClosedByGender?from_date=${monthStart}&to_date=${monthEnd}${categoryQuery}`
            );
            setDealsClosedByGender(data);
        } catch (err) {
            console.log(err);
            setDealsClosedByGender([]);
        }
    };

    const getDealsClosedByNationality = async () => {
        try {
            const categoryQuery = !market
                ? ''
                : market === MarketTypes.Offplan
                ? `&category[]=${DealCategory.PrimaryOffplan}`
                : market === MarketTypes.Listing
                ? `&category[]=${DealCategory.ListingAcquired}&category[]=${DealCategory.SecondaryProperty}`
                : '';

            const { data } = await axios.get(
                `/reports/main/deals/dealsClosedByNationality?from_date=${monthStart}&to_date=${monthEnd}${categoryQuery}`
            );
            setDealsClosedByNationality(data);
        } catch (err) {
            console.log(err);
            setDealsClosedByNationality([]);
        }
    };

    useEffect(() => {
        getDealsClosedByAgeGroup();
        getDealsClosedByGender();
        getDealsClosedByNationality();
        getDealsClosedByLeadSource();
        getLatestDeals();
        agentsLeaderboardByDealsClosed();
    }, [monthStart, market, monthEnd]);

    useEffect(() => {
        !agents?.length && dispatch(fetchAgents(axios));
    }, []);

    useEffect(() => {
        const timeout = dateError
            ? setTimeout(() => {
                  setDateError('');
              }, 3000)
            : undefined;

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [dateError]);

    const exportPDF = () => {
        const doc = printRef.current;

        html2canvas(doc).then((canvas) => {
            const data = canvas.toDataURL('application/pdf');
            const pdf = new jsPDF();
            pdf.addImage(data, 'pdf', 0, 0);

            pdf.save('test.pdf');
        });
    };

    return (
        <>
            <Head title="Deals Closed Analysis" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page className="mb-3">
                                Deals Closed Analysis
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                    <div className="d-flex justify-content-end align-items-end">
                        <div className="toggle-wrap nk-block-tools-toggle">
                            <div
                                className={`toggle-expand-content ${
                                    smBtn ? 'expanded' : ''
                                }`}
                            >
                                <ul className="nk-block-tools g-1">
                                    <li>
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                color="transparent"
                                                className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                                            >
                                                {market ?? 'All Markets'}
                                            </DropdownToggle>
                                            <DropdownMenu start>
                                                <ul className="link-list-opt no-bdr">
                                                    <li>
                                                        <DropdownItem
                                                            onClick={() =>
                                                                setMarket(null)
                                                            }
                                                        >
                                                            <span>
                                                                All Markets
                                                            </span>
                                                        </DropdownItem>
                                                    </li>
                                                    <li>
                                                        <DropdownItem
                                                            onClick={() =>
                                                                setMarket(
                                                                    MarketTypes.Offplan
                                                                )
                                                            }
                                                        >
                                                            <span>
                                                                Primary Market
                                                            </span>
                                                        </DropdownItem>
                                                    </li>
                                                    <li>
                                                        <DropdownItem
                                                            onClick={() =>
                                                                setMarket(
                                                                    MarketTypes.Listing
                                                                )
                                                            }
                                                        >
                                                            <span>
                                                                Secondary Market
                                                            </span>
                                                        </DropdownItem>
                                                    </li>
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </li>
                                    <li>
                                        <button
                                            className="btn bg-primary text-white"
                                            onClick={() => {
                                                setMonthStart(
                                                    startOfMonth(today)
                                                );
                                                setMonthEnd(endOfMonth(today));
                                                // setCurrentDate(today);
                                            }}
                                        >
                                            Current Month
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className="btn bg-secondary text-white"
                                            onClick={() => {
                                                setMonthEnd(
                                                    endOfMonth(
                                                        subMonths(today, 1)
                                                    )
                                                );
                                                setMonthStart(
                                                    startOfMonth(
                                                        subMonths(today, 1)
                                                    )
                                                );
                                            }}
                                        >
                                            Last Month
                                        </button>
                                    </li>
                                    <li>
                                        <div className="form-group">
                                            <div className="form-control-wrap position-relative">
                                                <div className="text-danger fs-sm text-center position-absolute start-50 bottom-100 translate-middle-x w-100">
                                                    {dateError}
                                                </div>
                                                <div className="input-daterange date-picker-range input-group">
                                                    <DatePicker
                                                        selected={monthStart}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }} // prevent user from typing in date
                                                        onChange={(date) => {
                                                            const interval =
                                                                differenceInDays(
                                                                    monthEnd,
                                                                    date
                                                                );

                                                            if (
                                                                interval >= 31
                                                            ) {
                                                                setDateError(
                                                                    'Interval cannot be more than 31 days'
                                                                );
                                                                return;
                                                            }
                                                            setMonthStart(date);
                                                        }}
                                                        selectsStart
                                                        startDate={monthStart}
                                                        endDate={monthEnd}
                                                        maxDate={monthEnd}
                                                        wrapperClassName="start-m"
                                                        className="form-control"
                                                    />
                                                    <div className="input-group-addon">
                                                        TO
                                                    </div>
                                                    <DatePicker
                                                        selected={monthEnd}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }} // prevent user from typing in date
                                                        onChange={(date) => {
                                                            const interval =
                                                                differenceInDays(
                                                                    date,
                                                                    monthStart
                                                                );

                                                            if (
                                                                interval >= 31
                                                            ) {
                                                                setDateError(
                                                                    'Interval cannot be more than 31 days'
                                                                );
                                                                return;
                                                            }

                                                            setMonthEnd(date);
                                                        }}
                                                        startDate={monthStart}
                                                        endDate={monthEnd}
                                                        selectsEnd
                                                        minDate={monthStart}
                                                        wrapperClassName="end-m"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </BlockHead>

                <Row className="g-gs">
                    <Col md="6" xxl="3">
                        <div className="deal-item-grid">
                            <div className="card-inner">
                                <div className="kanban-title-content mb-2">
                                    <h6 className="title">
                                        Deals Closed Per Lead Source
                                    </h6>
                                    <Badge
                                        className="text-dark"
                                        pill
                                        color="outline-light"
                                    >
                                        {dealsClosedByLeadSource &&
                                        dealsClosedByLeadSource?.length
                                            ? formatValue(
                                                  dealsClosedByLeadSource
                                                      ?.slice(0, 10)
                                                      ?.map((item) =>
                                                          Number(item?.count)
                                                      )
                                                      ?.reduce(
                                                          (a, b) => a + b,
                                                          0
                                                      )
                                              )
                                            : 0}
                                    </Badge>
                                </div>
                                {!dealsClosedByLeadSource ? (
                                    <div className="listing-item-grid">
                                        <CardShimmer
                                            numberOfCards={3}
                                            cardSize={40}
                                        />
                                    </div>
                                ) : dealsClosedByLeadSource &&
                                  dealsClosedByLeadSource?.length ? (
                                    dealsClosedByLeadSource
                                        ?.slice(0, 10)
                                        ?.sort((a, b) => b?.count - a?.count)
                                        ?.map((deal, index) => (
                                            <div
                                                key={
                                                    deal?._id +
                                                    deal?.count +
                                                    index
                                                }
                                                className="deal-by-leadsource-card"
                                            >
                                                <div>
                                                    <span className="me-2">
                                                        {index + 1}.
                                                    </span>
                                                    <span>{deal?._id}</span>
                                                </div>
                                                <span>{deal?.count}</span>
                                            </div>
                                        ))
                                ) : (
                                    <div className="text-center p-2">
                                        No deals closed for selected duration
                                    </div>
                                )}{' '}
                            </div>
                        </div>
                    </Col>
                    <Col md="6" xxl="3">
                        <div className="deal-item-grid">
                            <div className="card-inner">
                                <div className="kanban-title-content mb-2">
                                    <h6 className="title">
                                        Deals Closed By Age Group
                                    </h6>
                                    <Badge
                                        className="text-dark"
                                        pill
                                        color="outline-light"
                                    >
                                        {dealsClosedByAgeGroup &&
                                        dealsClosedByAgeGroup?.length
                                            ? formatValue(
                                                  dealsClosedByAgeGroup
                                                      ?.slice(0, 10)
                                                      ?.map((item) =>
                                                          Number(item?.count)
                                                      )
                                                      ?.reduce(
                                                          (a, b) => a + b,
                                                          0
                                                      )
                                              )
                                            : 0}
                                    </Badge>
                                </div>

                                {dealsClosedByAgeGroup ? (
                                    dealsClosedByAgeGroup?.length ? (
                                        <PieChart
                                            data={{
                                                labels: dealsClosedByAgeGroup?.map(
                                                    (item) => item?._id
                                                ),
                                                datasets: [
                                                    {
                                                        data: dealsClosedByAgeGroup?.map(
                                                            (item) =>
                                                                item?.count
                                                        ),
                                                        label: '# of deals',
                                                        backgroundColor:
                                                            dealsClosedByAgeGroup?.map(
                                                                (item, i) =>
                                                                    `rgba(${
                                                                        (i +
                                                                            item?.count) *
                                                                        100
                                                                    }, ${
                                                                        (i +
                                                                            item?.count) *
                                                                        30
                                                                    }, ${
                                                                        (i +
                                                                            item?.count) *
                                                                        50
                                                                    }, 1)`
                                                            )
                                                    }
                                                ]
                                            }}
                                        />
                                    ) : (
                                        <div className="text-center p-2">
                                            No data to display
                                        </div>
                                    )
                                ) : (
                                    <CardShimmer numberOfCards={3} />
                                )}
                                <div></div>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" xxl="3">
                        <div className=" deal-item-grid">
                            <div className="card-inner">
                                <div className="kanban-title-content mb-2">
                                    <h6 className="title ">
                                        Deals Closed By Gender
                                    </h6>
                                    <Badge
                                        className="text-dark"
                                        pill
                                        color="outline-light"
                                    >
                                        {dealsClosedByGender &&
                                        dealsClosedByGender?.length
                                            ? formatValue(
                                                  dealsClosedByGender
                                                      ?.slice(0, 10)
                                                      ?.map((item) =>
                                                          Number(item?.count)
                                                      )
                                                      ?.reduce(
                                                          (a, b) => a + b,
                                                          0
                                                      )
                                              )
                                            : 0}
                                    </Badge>
                                </div>
                                {dealsClosedByGender ? (
                                    dealsClosedByGender?.length ? (
                                        <PieChart
                                            data={{
                                                labels: dealsClosedByGender?.map(
                                                    (item) => item?._id
                                                ),
                                                datasets: [
                                                    {
                                                        data: dealsClosedByGender?.map(
                                                            (item) =>
                                                                item?.count
                                                        ),
                                                        label: '# of deals',
                                                        borderColor: [
                                                            '#798bff',
                                                            '#35A2EB'
                                                        ],
                                                        backgroundColor: [
                                                            '#798bff',
                                                            '#35A2EB'
                                                        ]
                                                    }
                                                ]
                                            }}
                                        />
                                    ) : (
                                        <div className="text-center p-2">
                                            No data to display
                                        </div>
                                    )
                                ) : (
                                    <CardShimmer numberOfCards={3} />
                                )}
                                <div></div>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" xxl="3">
                        <div className="deal-item-grid">
                            <div className="card-inner">
                                <div className="kanban-title-content mb-2">
                                    <h6 className="title">
                                        Deals Closed By Nationality
                                    </h6>
                                    <Badge
                                        className="text-dark"
                                        pill
                                        color="outline-light"
                                    >
                                        {dealsClosedByNationality &&
                                        dealsClosedByNationality?.length
                                            ? formatValue(
                                                  dealsClosedByNationality
                                                      ?.slice(0, 10)
                                                      ?.map((item) =>
                                                          Number(item?.count)
                                                      )
                                                      ?.reduce(
                                                          (a, b) => a + b,
                                                          0
                                                      )
                                              )
                                            : 0}
                                    </Badge>
                                </div>
                                {dealsClosedByNationality ? (
                                    dealsClosedByNationality &&
                                    dealsClosedByNationality?.length ? (
                                        <PieChart
                                            data={{
                                                labels: dealsClosedByNationality
                                                    ?.slice(0, 5)
                                                    ?.map((item) => item?._id),
                                                datasets: [
                                                    {
                                                        data: dealsClosedByNationality
                                                            ?.slice(0, 5)
                                                            ?.map(
                                                                (item) =>
                                                                    item?.count
                                                            ),
                                                        label: '# of deals',
                                                        backgroundColor: [
                                                            '#854fff',
                                                            '#364a63',
                                                            '#bea2ff',
                                                            '#502BB8',
                                                            '#2F2F2F'
                                                        ]
                                                    }
                                                ]
                                            }}
                                        />
                                    ) : (
                                        <div className="text-center p-2">
                                            No data to display
                                        </div>
                                    )
                                ) : (
                                    <CardShimmer numberOfCards={5} />
                                )}
                                <div></div>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" xxl="4">
                        <div className="deal-item-grid">
                            <div className="card-inner">
                                <div className="kanban-title-content mb-2">
                                    <h6 className="title">
                                        Deals Closed Per Agent
                                    </h6>
                                    <Badge
                                        className="text-dark"
                                        pill
                                        color="outline-light"
                                    >
                                        {agentsLeaderboard &&
                                        agentsLeaderboard?.length
                                            ? formatValue(
                                                  agentsLeaderboard
                                                      ?.map((item) =>
                                                          Number(
                                                              item?.dealsCount
                                                          )
                                                      )
                                                      ?.reduce(
                                                          (a, b) => a + b,
                                                          0
                                                      )
                                              )
                                            : 0}
                                    </Badge>
                                </div>

                                <div>
                                    {agentsLeaderboard ? (
                                        agentsLeaderboard?.length ? (
                                            agentsLeaderboard?.map(
                                                (item, index) => (
                                                    <DealClosedByAgentCard
                                                        index={index + 1}
                                                        photo={
                                                            item?.user?.photo
                                                        }
                                                        amount={
                                                            'AED ' +
                                                            (item?.totalAmount
                                                                ? formatValue(
                                                                      item?.totalAmount ??
                                                                          0
                                                                  )
                                                                : 0)
                                                        }
                                                        count={
                                                            item?.dealsCount ??
                                                            0
                                                        }
                                                        countText={
                                                            item?.dealsCount ===
                                                            1
                                                                ? 'Deal'
                                                                : 'Deals'
                                                        }
                                                        title={`${item?.user?.first_name} ${item?.user?.last_name}`}
                                                        subText={
                                                            item?.user?.email
                                                        }
                                                        key={
                                                            item?.userId ??
                                                            item?.user?.id
                                                        }
                                                        onClick={(e) => {
                                                            navigate(
                                                                `/user-profile/${
                                                                    item?.userId ??
                                                                    item?.user
                                                                        ?.id
                                                                }/user-profile`
                                                            );
                                                        }}
                                                    />
                                                )
                                            )
                                        ) : (
                                            <div className="text-center">
                                                No Data to display
                                            </div>
                                        )
                                    ) : (
                                        <CardShimmer numberOfCards={3} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" xxl="4">
                        <DealsClosedGrid
                            deals={latestDealsClosed}
                            fetching={!latestDealsClosed}
                            title="Latest Deals Closed"
                        />
                        {/* <div className=" deal-item-grid">
              <div className="card-inner">
                <div className="kanban-title-content mb-2">
                  <h6 className="title">Latest Deals Closed</h6>
                  <Badge className="text-dark" pill color="outline-light">
                    {latestDealsClosed?.length ?? 0}
                  </Badge>
                </div>
                <div>
                  {!fetching ? (
                    latestDealsClosed && latestDealsClosed?.length ? (
                      latestDealsClosed?.map((item) => (
                        <DealClosedByAgentCard
                          key={item?._id}
                          count={item?.type}
                          amount={
                            'AED ' +
                            (item?.new_listing_request_id
                              ? formatValue(
                                  item?.new_listing_request_id?.price ?? 0
                                )
                              : item?.property_list_id
                              ? formatValue(
                                  item?.property_list_id?.askingPrice ?? 0
                                )
                              : 0)
                          }
                          photo={item?.created_by_id?.photo}
                          title={`${item?.created_by_id?.first_name} ${item?.created_by_id?.last_name}`}
                          subText={`${item?.created_by_id?.email}`}
                          onClick={(e) => {
                            navigate(
                              `/user-profile/${item?.created_by_id?._id}/user-profile`
                            );
                          }}
                        />
                      ))
                    ) : (
                      <div className="text-center">No Data to display</div>
                    )
                  ) : (
                    <CardShimmer numberOfCards={3} />
                  )}
                </div>
              </div>
            </div> */}
                    </Col>
                    <Col md="12" xxl="4">
                        <DealsClosedGrid
                            title="Latest Deals Created"
                            deals={latestDealsCreated}
                            fetching={!latestDealsCreated}
                            noDataText="No deals!"
                            linkText="View Deals"
                        />
                        {/* <div className="deal-item-grid">
                            <div className="card-inner">
                                <div className="kanban-title-content mb-2">
                                    <h6 className="title">
                                        Latest Deals Created
                                    </h6>
                                    <Badge
                                        className="text-dark"
                                        pill
                                        color="outline-light"
                                    >
                                        {latestDealsCreated?.length ?? 0}
                                    </Badge>
                                </div>
                                <div>
                                    {!fetching ? (
                                        latestDealsCreated &&
                                        latestDealsCreated?.length ? (
                                            latestDealsCreated?.map((item) => (
                                                <DealClosedByAgentCard
                                                    key={item?._id}
                                                    count={item?.type}
                                                    amount={item?.status}
                                                    photo={
                                                        item?.created_by_id
                                                            ?.photo
                                                    }
                                                    title={`${item?.created_by_id?.first_name} ${item?.created_by_id?.last_name}`}
                                                    subText={`${item?.created_by_id?.email}`}
                                                    onClick={(e) => {
                                                        navigate(
                                                            `/user-profile/${item?.created_by_id?._id}/user-profile`
                                                        );
                                                    }}
                                                />
                                            ))
                                        ) : (
                                            <div className="text-center">
                                                No Data to display
                                            </div>
                                        )
                                    ) : (
                                        <CardShimmer numberOfCards={3} />
                                    )}
                                </div>
                            </div>
                        </div> */}
                    </Col>
                </Row>
            </Content>
        </>
    );
}
