import classNames from "classnames";
import React from "react";

/**
 * A reusable component for rendering the inner content of a card.
 * 
 * @param {object} props - The component's props.
 * @param {"sm" | "md" | "lg" | "xl"} [props.size="md"] - The size of the card inner.
 * @param {string} [props.id] - The id attribute of the card inner element.
 * @param {string} [props.className] - Additional CSS classes to apply to the card inner element.
 * @param {React.CSSProperties} [props.style] - Inline styles to apply to the card inner element.
 * 
 * @example
 * <CardInner size="lg" className="custom-class" style={{ backgroundColor: 'red' }}>
 *   Card content goes here
 * </CardInner>
 * 
 * @returns {JSX.Element} - The rendered card inner component.
 */
const CardInner = ({ size = "md", id, className, style, ...props }) => {
  const cardClass = classNames({
    [`${className}`]: className,
    "card-inner": true,
    [`card-inner-${size}`]: true,
  });
  
  return <div className={cardClass} id={id} style={style} {...props}></div>;
};
export default CardInner;
