import { toast } from "react-toastify";

const validate = (teamName, members, communities,selectedTeamLeader) => {
  let error = false;
  if (teamName?.length < 3) {
    toast.error("Team Name has to be at least 3 characters long.");
    error = true;
  }
  if (members?.length === 0) {
    toast.error("The team must have members.");
    error = true;
  }

  if (communities?.length === 0) {
    toast.error("The team must be assigned to some communities.");
    error = true;
  }
  return !error;
};

function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}


export { validate, deepEqual };
