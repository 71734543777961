const FINANCES_HEADER_FIELDS = [
  {
    name: "Deal ID",
    breakpoint: null,
    isActions: false,
    isCheckbox: false,
    first: true,
  },
  {
    name: "Category",
    breakpoint: 'md',
    isActions: false,
    isCheckbox: false,
  },
  {
    name: "Deal Date",
    breakpoint: 'md',
    isActions: false,
    isCheckbox: false,
  },
  {
    name: "Agent",
    breakpoint: "lg",
    isActions: false,
    isCheckbox: false,
  },
  {
    name: "Property Type",
    breakpoint: "lg",
    isActions: false,
    isCheckbox: false,
  },
  {
    name: "Purpose",
    breakpoint: 'md',
    isActions: false,
    isCheckbox: false,
  },
  // {
  //   name: "Closed On",
  //   breakpoint: null,
  //   isActions: false,
  //   isCheckbox: false,
  // },
  { name: "Total Amount", breakpoint: 'lg', isActions: false, isCheckbox: false },
  { name: "Commission (+ %5 tax)", breakpoint: null, isActions: false, isCheckbox: false },
  { name: "Received", breakpoint: null, isActions: false, isCheckbox: false },
  { name: "Balance", breakpoint: 'md', isActions: false, isCheckbox: false },
];

export const SORT_FIELD_OPTIONS = {
  DealId: 'reference',
  DealDate: 'creation_date'
};

export const SORT_NAME_MAPPING = {
  'reference': 'Deal ID',
  'creation_date': 'Deal Date',
}

export { FINANCES_HEADER_FIELDS };
