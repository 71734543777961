import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockDes,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  PreviewAltCard,
  Row,
  DataTable,
  DataTableBody,
} from "../../../components/Component";
import {
  Button,
  Modal,
  ModalBody,
  Badge,
  ModalHeader,
  Card,
  CardBody,
  Collapse
} from "reactstrap";
import { useForm } from "react-hook-form";
import ReactTooltip from "react-tooltip";

import { toast } from "react-toastify";
import { format } from "date-fns";
import "react-phone-number-input/style.css";
import { useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import _ from "lodash";

import FormField from './drag-and-drop/FormField';
import DraggableCard from "./drag-and-drop/DraggableCard";
import { FIELDS, FIELD_DATA } from "../constats";
import { findDuplicatePairsInArray } from "../utils";

const ImportDataUi = ({
  title,
  initialData = [],
  name,
  role = null,
  hide = false,
}) => {
  // State Variables
  const [leadSource, setLeadSource] = useState(null);
  const [users, setusers] = useState(initialData);
  const [user, setUser] = useState(null);
  const [userId, setuserId] = useState("");

  const [totpages, setTotpages] = useState(0);

  const [resPerPage, setResPerPage] = useState(0);
  const [filCount, setFilCount] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [filterSearch, setFilterSearch] = useState(null);
  const [filterRole, setFilterRole] = useState(role);
  const [filterStatus, setFilterStatus] = useState(null);
  const [filterLeadSource, setFilterLeadSource] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);
  const [dateValue, setDateValue] = useState({ startDate: "", endDate: "" });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [errors, setErrors] = useState({});
  const { myerrors, register, handleSubmit } = useForm();
  const [rangeStart, setRangeStart] = useState();
  const [rangeEnd, setRangeEnd] = useState(new Date());
  const [value, setValue] = useState();
  const [errorX, setErrorX] = useState(null);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [sort, setSortState] = useState("DESC");
  const [csvFileName, setCsvFileName] = useState("");
  const [fileContents, setFileContents] = useState("");
  const [fileHeaders, setFileHeaders] = useState("");
  const [uploadedFields, setUploadedFields] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [uploadLog, setUploadLog] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [array, setArray] = useState([]);
  const [importLoading, setImportLoading] = useState(false);
  const [state, setState] = useState({
    csvfile: null,
  });
  const [tag, setTag] = useState("Seller");

  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [createdUsersCount, setCreatedUsersCount] = useState(0);
  const [updatedUsersCount, setUpdatedUsersCount] = useState(0);
  const [skippedCount, setSkippedCount] = useState(0);
  const [notUpdatedCount, setNotUpdatedCount] = useState(0);
  const [updatedLeads, setUpdatedLeads] = useState([]);
  const [changedFields, setChangedFields] = useState([]);


  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const indexOfLastItemLog = currentPage * itemsPerPage;
  const indexOfFirstItemLog = indexOfLastItemLog - itemsPerPage;

  const currentItems = fileData
    .slice(1)
    .slice(indexOfFirstItem, indexOfLastItem);

  const [clickLoadPreview, setClickLoadPreview] = useState(false);
  // Constants
  // Validate User Token
  const { access_token } =
    useSelector((state) => state?.user?.loggedInUser) || "";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };

  useEffect(() => {
    setLoading(false);
    setLoading(true);
  }, [
    filterSearch,
    filterRole,
    filterStatus,
    filterLeadSource,
    isFiltering,
    dateValue,
    itemsPerPage,
    sort,
  ]);

  useEffect(() => {
    fetchUploadLog();
  }, []);

  useEffect(() => {
    if (!itemsPerPage) {
      return;
    }
    setLoading(false);
    setLoading(true);
  }, [currentPage]);

  useEffect(() => {
    setDateValue({
      startDate: rangeStart?.toISOString(),
      endDate: rangeEnd?.toISOString(),
    });
  }, [rangeStart, rangeEnd]);
  const handleSearch = async (e) => {
    e.preventDefault();
    if (search !== filterSearch) {
      setusers(null);
      setCurrentPage(1);
      if (search) {
        setFilterSearch(search);
        setIsFiltering(!!search);
      } else {
        if (filterRole) {
          setIsFiltering(!!filterRole);
        } else {
          setIsFiltering(false);
        }
        setFilterSearch(null);
        setSearch(null);
      }
    }
  };

  const handleStatusOption = (status) => {
    setusers(null);
    setCurrentPage(1);
    if (status) {
      setFilterStatus(status);
      setIsFiltering(!!status);
    } else {
      if (filterSearch) {
        setIsFiltering(!!filterSearch);
      } else {
        setIsFiltering(false);
      }
      setFilterStatus(null);
    }
  };

  // Change Page

  const toggleSearch = () => setonSearch(!onSearch);

  const axios = useAxiosPrivate();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      toast.error("Please choose a file.");
      return;
    }

    setFileData([]);
    setFileContents([]);
    setCsvFileName("");
    setFilCount(0);

    if (file.type !== "text/csv") {
      toast.error("Only CSV files are accepted.");
      return;
    }

    const reader = new FileReader();
    const fileName = file.name;
    setCsvFileName(fileName);

    reader.onload = (e) => {
      const contents = e.target.result;
      const rows = contents.split("\n");
      const data = rows.map((row) => row.split(","));

      setFileContents(contents);
      setFileHeaders(rows[0]);
      setUploadedFields(rows[0].split(","));
    };
    reader.readAsText(file);
  };

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleConfirmUpload = () => {
    const uploadedHeaders = fileHeaders
      .split(",")
      .map((h) => h.replace(/\r/g, ""));
    setChangedFields(new Array(uploadedHeaders.length).fill(false));
    // const changedFields = new Array(uploadedHeaders.length).fill(false);
    let changedFieldsCopy = changedFields;

    FIELDS.forEach((item) => {
      const field = document.querySelector(
        `input#${item.name.replace(/\s+/g, "-")}`
      );
      const i = uploadedHeaders.indexOf(field.value);
      if (i !== -1) {
        uploadedHeaders[i] = field.name;
        changedFieldsCopy[i] = true;
      }
    });
    setChangedFields(changedFieldsCopy);
    setFileHeaders(uploadedHeaders.join(","));
    setOpenMapFields(false);
    setHasSubmitted(true);
    toast.success(
      `Fields updated. Mapped ${changedFields.filter((f) => f).length} fields.`
    );
  };

  const handleViewData = () => {
    setOpenPreview(true);
    const rows = fileContents.split("\n");
    rows[0] = fileHeaders;
    const data = rows.map((row) => row.split(",").filter(x => x !== '\r'));
    // data.pop(); // remove weird empty array at the end

    // handle duplicate columns
    let columnToRemove;
    const duplicateColumnsIndices = findDuplicatePairsInArray(data[0]);
    for (let pair of duplicateColumnsIndices) {
      // check which column has been changed by the user
      // changedFields holds true for the indices of changed columns
      // we keep the column they uploaded
      columnToRemove = changedFields[pair[0]] ? pair[1] : pair[0];
    }
    
    // eslint-disable-next-line eqeqeq
    if (columnToRemove != undefined) {
      for (let row of data) {
        row.splice(columnToRemove, 1);
      }
    }

    setFileData(data);
    setFilCount(data.slice(1).length);
    setClickLoadPreview(!clickLoadPreview);
    toggleMapFields();
  };

  const handleClearData = () => {
    setFileData([]);
    setFileContents([]);
    setCsvFileName("");
    setFilCount(0);
    document.getElementById("fileInput").value = null;
    setFileHeaders([]);
    setOpenPreview(false);
    setHasSubmitted(false);
    setOpenMapFields(false);
  };

  const [openAgent, setOpenAgent] = useState(false);
  const toggleModalAgent = () => {
    setOpenAgent(!openAgent);
  };

  const [openMapFields, setOpenMapFields] = useState(false);
  const toggleMapFields = () => {
    setOpenMapFields(!openMapFields);
  };

  const [openPreview, setOpenPreview] = useState(false);
  const togglePreview = () => {
    setOpenPreview(!openPreview);
  };

  const [openUpdateUsres, setOpenUpdateUsres] = useState(false);
  const toggleUpdateUsres = () => {
    setOpenUpdateUsres(!openUpdateUsres);
  };

  const clearInputFields = () => {
    FIELDS.forEach((field) => {
      const inputElement = document.getElementById(
        field.name.replace(/\s+/g, "-")
      );

      if (inputElement) {
        inputElement.value = "";
      }
    });
    // setTimeout(() => window.location.reload(), 2000);
  };

  const validateRequiredFields = (...fields) => {
    for (const field of fields) {
      // eslint-disable-next-line eqeqeq
      if (field == undefined) return false;
    }
    return true;
  };

  const handleOnSubmit = async (e) => {
    setSaveLoading(false);
    e.preventDefault();

    // let leadsData = result.data;

    const dataHeaders = fileData[0];

    const findIndexByItem = (header, item) => {
      const sanitizedItems = header.map((h) => h.replace(/[\n\r]/g, ""));
      return sanitizedItems.indexOf(item);
    };

    const leadNameIndex = findIndexByItem(dataHeaders, FIELD_DATA.Name.label);
    const leadMobileIndex = findIndexByItem(
      dataHeaders,
      FIELD_DATA.Phone.label
    );
    const unitNumberIndex = findIndexByItem(
      dataHeaders,
      FIELD_DATA.UnitNumber.label
    );
    const projectIndex = findIndexByItem(dataHeaders, FIELD_DATA.Project.label);
    const propertyTypeIndex = findIndexByItem(
      dataHeaders,
      FIELD_DATA.Type.label
    );
    const buildingNameIndex = findIndexByItem(
      dataHeaders,
      FIELD_DATA.BuildingName.label
    );
    const buildingNumberIndex = findIndexByItem(
      dataHeaders,
      FIELD_DATA.BuildingNumber.label
    );
    const communityIndex = findIndexByItem(
      dataHeaders,
      FIELD_DATA.Community.label
    );
    const agentIndex = findIndexByItem(dataHeaders, FIELD_DATA.Agent.label);
    const leadSourceIndex = findIndexByItem(
      dataHeaders,
      FIELD_DATA.LeadSource.label
    );
    const bedsIndex = findIndexByItem(dataHeaders, FIELD_DATA.Beds.label);
    const bathsIndex = findIndexByItem(dataHeaders, FIELD_DATA.Baths.label);
    const sizeIndex = findIndexByItem(dataHeaders, FIELD_DATA.Size.label);
    const amountIndex = findIndexByItem(dataHeaders, FIELD_DATA.Amount.label);
    const dealDateIndex = findIndexByItem(
      dataHeaders,
      FIELD_DATA.DealDate.label
    );
    const partyTypeIndex = findIndexByItem(
      dataHeaders,
      FIELD_DATA.PartyType.label
    );
    const notesIndex = findIndexByItem(dataHeaders, FIELD_DATA.Notes.label);

    const fileDatas = fileData.slice(1, fileData.length);
    _.map(fileDatas, function (entry) {
      if (
        validateRequiredFields(entry[leadMobileIndex], entry[leadNameIndex])
      ) {
        let submittedData = {
          dealDate: entry[dealDateIndex]
            ? entry[dealDateIndex]?.replace(/[\n\r]/g, "").trim()
            : undefined,
          amount: entry[amountIndex]
            ? entry[amountIndex]?.replace(/[\n\r]/g, "").trim()
            : undefined,
          leadName: entry[leadNameIndex]
            ? entry[leadNameIndex]?.replace(/[\n\r]/g, "").trim()
            : undefined,
          leadMobile: entry[leadMobileIndex]
            ? "+" + entry[leadMobileIndex]?.replace(/[\n\r\s]/g, "")
            : "",
          agent: entry[agentIndex]?.replace(/[\n\r]/g, "") || undefined,
          leadSource: entry[leadSourceIndex]?.replace(/[\n\r]/g, "") || "",
          community: entry[communityIndex]?.replace(/[\n\r]/g, "") || undefined,
          project: entry[projectIndex]?.replace(/[\n\r]/g, "") || undefined,
          buildingName:
            entry[buildingNameIndex]?.replace(/[\n\r]/g, "") || undefined,
          buildingNumber:
            entry[buildingNumberIndex]?.replace(/[\n\r]/g, "") || undefined,
          propertyType:
            entry[propertyTypeIndex]?.replace(/[\n\r]/g, "") || undefined,
          unitNumber: entry[unitNumberIndex]?.replace(/[\n\r]/g, "") || undefined,
          beds: entry[bedsIndex]?.replace(/[\n\r]/g, "") || undefined,
          baths: entry[bathsIndex]?.replace(/[\n\r]/g, "") || undefined,
          size: entry[sizeIndex]?.replace(/[\n\r]/g, "") || undefined,
          transactionType:
            entry[partyTypeIndex]?.replace(/[\n\r]/g, "") || undefined,
          notes: entry[notesIndex]?.replace(/[\n\r]/g, "") || undefined,
        };

        array.push(submittedData);
      }
    });
    if (array.length > 0) {
      try {
        setSaveLoading(true);
        // eslint-disable-next-line no-unused-vars
        const res = await axios
          .post(
            `/property-cards/dld-data/upload`,
            { data: array },
            {
              headers,
            }
          )
          .then((res) => {
            toast.success(res?.data?.message);
            setArray([]);
            setSaveLoading(false);
            setFileData([]);
            setFileContents([]);
            setFileHeaders([]);
            setCsvFileName("");
            setFilCount(0);
            document.getElementById("fileInput").value = null;
            setImportLoading(false);
            setState({
              csvfile: null,
            });
            clearInputFields();
            fetchUploadLog();
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            setArray([]);
            setSaveLoading(false);
            setImportLoading(false);
            setState({
              csvfile: null,
            });
            // navigate(0); );
          });
      } catch (err) {
        setSaveLoading(false);
        toast.error("Server Error");
      }
    } else {
      toast.error("Please import a valid CSV file or map the required fields.");
    }
  };

  const url = "uploadlog";
  const fetchUploadLog = async () => {
    try {
      let link = `/users/${url}`;
      const res = await axios.get(link, { headers });
      const result = res.data;
      setUploadLog(result);
    } catch (err) {
    }
  };

  const logItems = uploadLog.slice(indexOfFirstItemLog, indexOfLastItemLog);

  return (
    <React.Fragment>
      <Head title={title}></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {title}
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card>
            <CardBody>
              <div className="row mt-3 mb-3 justify-content-end">
                <div className="col-md-8 justify-content-end">
                  <div className="d-flex">
                    <Button color="light">
                      <input
                        type="file"
                        id="fileInput"
                        onChange={(event) => {
                          handleFileUpload(event);
                          // toggleMapFields();
                        }}
                      ></input>
                    </Button>
                    <Button
                      outline={fileContents.length > 0}
                      color="danger"
                      className="ml-2"
                      onClick={handleClearData}
                      disabled={fileContents.length === 0}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
                <div className="col-md-4 justify-content-end d-flex">
                  <Button
                    outline
                    color="secondary"
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggleModalAgent();
                      fetchUploadLog();
                    }}
                  >
                    <Icon name="eye" />
                    View Upload Log
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Block>

        {fileContents.length === 0 ? (
          <Block>
            <Card>
              <div className="text-center mt-3 mb-3">
                <span className="text-silent">
                  Upload CSV file to see the data
                </span>
              </div>
            </Card>{" "}
          </Block>
        ) :
          <Collapse isOpen={!openMapFields}>
            <Block>
              <DndProvider backend={HTML5Backend}>
                <Card>
                  <CardBody>
                    <div className="mb-3 d-flex justify-content-end">
                      <Button
                        outline={fileContents.length > 0}
                        color="secondary"
                        className="ml-2"
                        onClick={handleViewData}
                        disabled={fileContents.length === 0}
                      >
                        Load & Preview
                      </Button>
                      <Button
                        className="ml-2"
                        color="primary"
                        onClick={handleConfirmUpload}
                      >
                        <span>Save Mapping</span>
                      </Button>
                    </div>
                    <div style={{ display: "flex", gap: "1rem" }}>
                      <div className="row mt-3" style={{ flex: 1 }}>
                        <div>
                          <h5 className="title">Excel Sheet Fields</h5>
                          <div>
                            {_.split(fileHeaders, ",").map((item, idx) => (
                              <DraggableCard key={`${item}-${idx}`} text={item} />
                            ))}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          height: "42rem",
                          width: "0.1rem",
                          border: "1px solid lightgray",
                        }}
                      ></div>
                      <div
                        className="row mt-3 mb-3 justify-content-end"
                        style={{ flex: 3 }}
                      >
                        <div className="col-md-12 justify-content-end">
                          <h5 className="title">System Fields</h5>
                          <div className="row">
                            <div className="col-md-6" style={{ padding: "20px" }}>
                              {FIELDS.slice(0, Math.ceil(FIELDS.length / 2)).map(
                                (item, idx) => (
                                  <FormField
                                    key={`${item}-${idx}`}
                                    name={item.name}
                                    required={item.required}
                                    fileHeaders={fileHeaders}
                                    setFileHeaders={setFileHeaders}
                                  />
                                )
                              )}
                            </div>
                            <div className="col-md-6" style={{ padding: "20px" }}>
                              {FIELDS.slice(Math.ceil(FIELDS.length / 2)).map(
                                (item, idx) => (
                                  // Inside the main component
                                  <FormField
                                    key={`${item}-${idx}`}
                                    name={item.name}
                                    required={item.required}
                                    fileHeaders={fileHeaders}
                                    setFileHeaders={setFileHeaders} // Pass the setFileHeaders function
                                  />
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </DndProvider>
            </Block>
          </Collapse>
        }
        {openPreview ? <Block>
          <BlockDes className="text-soft">
            <div className="row mt-3 d-flex align-items-center">
              <div className="col-md-6">
                <p>
                  You have a total of {filCount} {name + "(s)"}.
                </p>
              </div>
              <div className="col-md-6 d-flex justify-content-end" style={{gap: "1rem"}}>
                {openMapFields && <Button
                  color="secondary"
                  size="lg"
                  className=""
                  onClick={() => {toggleMapFields(); setOpenPreview(false);}}
                >
                  Reopen Mapping
                </Button>}
                <Button
                  color="primary"
                  size="lg"
                  onClick={handleOnSubmit}
                  disabled={fileData.length === 0 || saveLoading}
                >
                  {saveLoading ? "Uploading..." : "Upload"}
                </Button>
              </div>
            </div>
          </BlockDes>

          <DataTable className="card-stretch mt-3">
            <DataTableBody compact>
              <DataTableHead className="nk-tb-item">
                {fileData.length > 0 &&
                  fileData[0].map((header, index) => (
                    <DataTableRow key={index}>
                      <span className="sub-text">{header}</span>
                    </DataTableRow>
                  ))}
              </DataTableHead>

              {currentItems.map((row, rowIndex) => (
                <DataTableItem key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <DataTableRow key={cellIndex}>
                      <span href="#id" onClick={(ev) => ev.preventDefault()}>
                        {cell}
                      </span>
                    </DataTableRow>
                  ))}
                </DataTableItem>
              ))}
            </DataTableBody>
            <PreviewAltCard>
              {fileData.slice(1)?.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemsPerPage}
                  totalItems={fileData.slice(1).length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">
                    Upload CSV file to see the data
                  </span>
                </div>
              )}
            </PreviewAltCard>
          </DataTable>
        </Block> : null}

        <Modal
          size="xl"
          isOpen={openAgent}
          toggle={toggleModalAgent}
          backdrop="static"
          className="w-80"
        >
          <ModalHeader
            close={
              <button className="close" onClick={toggleModalAgent}>
                <Icon name="cross" />
              </button>
            }
          >
            Upload Log
          </ModalHeader>

          <ModalBody>
            <Row>
              <DataTable className="card-stretch mt-3">
                <DataTableBody compact>
                  <DataTableHead className="nk-tb-item">
                    <DataTableRow>
                      <span className="sub-text">FILE NAME</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">UPLOAD DATE</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">TAG</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">TOTAL COUNT</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">NEW</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">SKIPPED</span>
                    </DataTableRow>
                  </DataTableHead>

                  {logItems?.map((item, idx) => {
                    const skipped =
                      item?.total_count - item?.new_leads - item?.updated_leads;
                    const tooltipContent = `Updated Leads: ${item?.updated_leads} , Not Modified Leads: ${skipped}`;
                    return (
                      <DataTableItem key={idx}>
                        <DataTableRow>
                          <span className="sub-text">
                            <Badge
                              size="sm"
                              color="outline-danger"
                              className="badge-dim me-1"
                            >
                              {item?.file_name}
                            </Badge>
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">
                            {" "}
                            {item?.upload_date &&
                              format(
                                new Date(item?.upload_date),
                                "dd/MM/yyyy hh:mm a"
                              )}
                          </span>
                        </DataTableRow>
                        <DataTableRow> {item?.tag}</DataTableRow>
                        <DataTableRow>
                          <Badge color="info"> {item?.total_count}</Badge>
                        </DataTableRow>
                        <DataTableRow>
                          <Badge color="success"> {item?.new_leads}</Badge>
                        </DataTableRow>
                        <DataTableRow>
                          <Badge color="danger" data-tip={tooltipContent}>
                            {" "}
                            {item?.total_count - item?.new_leads}
                          </Badge>
                          <ReactTooltip />
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })}
                </DataTableBody>
              </DataTable>
              <PreviewAltCard>
                {uploadLog.slice(1)?.length > 0 ? (
                  <PaginationComponent
                    itemPerPage={itemsPerPage}
                    totalItems={uploadLog.slice(1).length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No Log Found</span>
                  </div>
                )}
              </PreviewAltCard>
            </Row>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default ImportDataUi;
