import React, { useState } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const PBXLogin = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const axios = useAxiosPrivate();  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post('/yeastarPBX/get-login-signature', {
        username: email,
      });

      const { data } = response.data;
      const secret = data.data.sign;

      onLoginSuccess(email, secret);
    } catch (error) {
      setError('Failed to retrieve login signature. Please try again.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <button type="submit" disabled={loading}>
        {loading ? 'Loading...' : 'Submit'}
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </form>
  );
};

export default PBXLogin;
