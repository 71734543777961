import NewLeadsTable from "../components/table/NewLeadsTable";

const NewLeadsUpload = () => {
    return (
        <>
            <NewLeadsTable title={"Upload Leads"} name={"Lead"} role={null} />
        </>
    );
};

export default NewLeadsUpload;
