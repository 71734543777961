/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import ErrorHandler from "../error-handlers/axios/defaultErrorHandler";

/**
 * @description Fetches all commission transactions for a specific deal
 * @param {Axios} axios - The Axios instance to use for making requests.
 * @param {string} id - The ID of the user/agent to fetch deals for.
 * @param {?import("axios").AxiosRequestConfig} [options] - Optional query options (including params).
 */
export const fetchRecentDeals = async (axiosInstance, id, options) => {
  try {
    const res = await axiosInstance.get(`/deals/user/mostRecent/${id}`, options);
    return res?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};
