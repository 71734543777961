import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import DropdownBody from "../../../../components/drop-downs/DropdownBody";
import DropdownSectionTitle from "../../../../components/drop-downs/DropdownSectionTitle";
import RadioButton from "../../../../components/input/radio/RadioButton";
import { CommissionStatus, DealCategory } from "../../../../utils/envConfig";
import DropdownHead from "../../../../components/drop-downs/DropdownHead";
import { Icon } from "../../../../components/Component";
import { SORT_FIELD_OPTIONS } from "../constants";
import CheckboxButton from "../../../../components/input/checkbox/CheckboxButton";

/**
 * @typedef {Object} FilterMenuProps
 * @property {import('../types').DealTransactionQueryState} queryParams
 * @property {React.Dispatch<React.SetStateAction<import('../types').DealTransactionQueryState>>} setQueryParams
 */

/**
 * FilterMenu component that renders a dropdown menu to display filters.
 *
 * @param {FilterMenuProps} props - The properties for the component.
 * @returns {JSX.Element}
 */
const FilterMenu = ({ queryParams, setQueryParams }) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="a"
        className="btn btn-trigger btn-icon dropdown-toggle"
      >
        <Icon name="setting"></Icon>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-xl">
        <DropdownHead>
          <span className="sub-title dropdown-title">More Options</span>
        </DropdownHead>
        <DropdownBody className="px-4 py-3">
          <DropdownSectionTitle
            isClearable
            title="Payment Status"
            clearFn={(e) => {
              setQueryParams((prev) => ({
                ...prev,
                commissionStatus: [],
                page: 1,
              }));
            }}
          />
          <div className="d-flex justify-content-between mt-2 border rounded p-2">
            <CheckboxButton
              labelText="Pending"
              id="pendingPayment"
              name="commissionStatus"
              checked={
                queryParams?.commissionStatus?.includes(CommissionStatus.Pending)
              }
              onChange={() => {
                const updatedCommissionStatus = queryParams?.commissionStatus?.includes(CommissionStatus.Pending)
                  ? queryParams.commissionStatus.filter(status => status !== CommissionStatus.Pending)
                  : [...(queryParams?.commissionStatus ?? []), CommissionStatus.Pending];

                setQueryParams((prev) => ({
                  ...prev,
                  commissionStatus: updatedCommissionStatus,
                  page: 1,
                }));
              }}
            />
            <CheckboxButton
              labelText="Partially"
              id="partiallyPaid"
              name="commissionStatus"
              checked={
                queryParams?.commissionStatus?.includes(
                  CommissionStatus.PartiallyPaid
                )
              }
              onChange={(e) => {
                const updatedCommissionStatus = queryParams?.commissionStatus?.includes(CommissionStatus.PartiallyPaid)
                  ? queryParams.commissionStatus.filter(status => status !== CommissionStatus.PartiallyPaid)
                  : [...(queryParams?.commissionStatus ?? []), CommissionStatus.PartiallyPaid]; 

                setQueryParams((prev) => ({
                  ...prev,
                  commissionStatus: updatedCommissionStatus,
                  page: 1,
                }));
              }}
            />
            <CheckboxButton
              labelText="Fully"
              id="fullyPaid"
              name="commissionStatus"
              checked={
                queryParams?.commissionStatus?.includes(
                  CommissionStatus.FullyPaid
                )
              }
              onChange={(e) => {
                const updatedCommissionStatus = queryParams?.commissionStatus?.includes(CommissionStatus.FullyPaid)
                  ? queryParams.commissionStatus.filter(status => status !== CommissionStatus.FullyPaid)
                  : [...(queryParams?.commissionStatus ?? []), CommissionStatus.FullyPaid];

                setQueryParams((prev) => ({
                  ...prev,
                  commissionStatus: updatedCommissionStatus,
                  page: 1,
                }));
              }}
            />
          </div>
        </DropdownBody>
        <DropdownBody className="px-4 py-3">
          <DropdownSectionTitle
            isClearable
            title="Deal Type"
            clearFn={(e) => {
              e.preventDefault();
              setQueryParams((prev) => ({
                ...prev,
                category: null,
                page: 1,
              }));
            }}
          />
          <div
            className="d-flex justify-content-between mt-2 border rounded p-2"
            style={{ gap: ".5rem" }}
          >
            <RadioButton
              labelText="Primary Off Plan"
              id={`${DealCategory.PrimaryOffplan}-radio`}
              name="category"
              checked={queryParams?.category === DealCategory.PrimaryOffplan}
              onChange={() => {
                setQueryParams((prev) => ({
                  ...prev,
                  category: DealCategory.PrimaryOffplan,
                  page: 1,
                }));
              }}
            />
            <RadioButton
              labelText="Secondary Market"
              id={`${DealCategory.SecondaryProperty}-radio`}
              name="category"
              checked={queryParams?.category === DealCategory.SecondaryProperty}
              onChange={() => {
                setQueryParams((prev) => ({
                  ...prev,
                  category: DealCategory.SecondaryProperty,
                  page: 1,
                }));
              }}
            />
          </div>
        </DropdownBody>
        <DropdownBody className="px-4 py-3">
          <DropdownSectionTitle
            isClearable
            title="Sort by"
            clearFn={(e) => {
              e.preventDefault();
              setQueryParams((prev) => ({
                ...prev,
                sort: { name: null, order: "DESC" },
                page: 1,
              }));
            }}
          />
          <div className="d-flex justify-content-between mt-2 p-2 border rounded p-2">
            <RadioButton
              labelText="Deal ID"
              id="dealId"
              name="sortField"
              checked={queryParams?.sort?.name === SORT_FIELD_OPTIONS.DealId}
              onChange={() => {
                setQueryParams((prev) => ({
                  ...prev,
                  sort: {
                    name: SORT_FIELD_OPTIONS.DealId,
                    order: queryParams?.sort?.order ?? "DESC",
                  },
                  page: 1,
                }));
              }}
            />
            <RadioButton
              labelText="Deal Date"
              id="dealDate"
              name="sortField"
              checked={queryParams?.sort?.name === SORT_FIELD_OPTIONS.DealDate}
              onChange={() => {
                setQueryParams((prev) => ({
                  ...prev,
                  sort: {
                    name: SORT_FIELD_OPTIONS.DealDate,
                    order: queryParams?.sort?.order ?? "DESC",
                  },
                  page: 1,
                }));
              }}
            />
          </div>
        </DropdownBody>
        <DropdownBody className="px-4 pb-3">
          <DropdownSectionTitle
            title="Sort Order"
            clearFn={(e) => {
              e.preventDefault();
              setQueryParams((prev) => ({
                ...prev,
                category: null,
                page: 1,
              }));
            }}
          />
          <div
            className="d-flex justify-content-between mt-2 p-2 border rounded"
            style={{ gap: ".5rem" }}
          >
            <RadioButton
              labelText="Descending"
              id={`desc-radio`}
              name="sortOrder"
              checked={queryParams?.sort?.order === "DESC"}
              onChange={() => {
                setQueryParams((prev) => ({
                  ...prev,
                  sort: {
                    name: queryParams?.sort?.name ?? null,
                    order: "DESC",
                  },
                  page: 1,
                }));
              }}
            />
            <RadioButton
              labelText="Ascending"
              id={`asc-radio`}
              name="sortOrder"
              checked={queryParams?.sort?.order === "ASC"}
              onChange={() => {
                setQueryParams((prev) => ({
                  ...prev,
                  sort: { name: queryParams?.sort?.name ?? null, order: "ASC" },
                  page: 1,
                }));
              }}
            />
          </div>
        </DropdownBody>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
export default FilterMenu;
