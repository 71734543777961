import React from "react";
import { Card } from "reactstrap";
import { Icon } from "./Component";
import { formatValue } from "../utils/envConfig";

const DealsStatistics = ( {
    dealsInNegotiation = 0,
    numberOfDealsInNegotiation = 0,
    commissionsEarned = 0,
    dealsClosed = 0,
    numberOfDealsClosed = 0
} ) =>
{
    return (
        <Card className="h-100">
            <div className="card-inner">
                <div className="card-title-group mb-2">
                    <div className="card-title">
                        <h6 className="title">Deals Statistics</h6>
                    </div>
                </div>
                <ul className="nk-store-statistics">
                    <li className="item">
                        <div className="info">
                            <div className="title">In Negotiation</div>
                            <div className="count">
                                AED { formatValue( dealsInNegotiation ) }{ " " }
                                <small className="small-text">
                                    ({ formatValue( numberOfDealsInNegotiation ) }{ " " }
                                    deals)
                                </small>
                            </div>
                        </div>
                        <Icon name="bag" className="bg-primary-dim"></Icon>
                    </li>
                    <li className="item">
                        <div className="info">
                            <div className="title">Commission Earned</div>
                            <div className="count">
                                AED { formatValue( commissionsEarned ) }{ " " }
                                <small className="small-text">
                                    (
                                    { formatValue( numberOfDealsClosed ) }{ " " }
                                    deals)
                                </small>
                            </div>
                        </div>
                        <Icon name="users" className="bg-info-dim"></Icon>
                    </li>
                    <li className="item">
                        <div className="info">
                            <div className="title">Deals Closed</div>
                            <div className="count">
                                AED { formatValue( dealsClosed ) }{ " " }
                                <small className="small-text">
                                    (
                                    { formatValue( numberOfDealsClosed ) }{ " " }
                                    deals)
                                </small>
                            </div>
                        </div>
                        <Icon name="box" className="bg-pink-dim"></Icon>
                    </li>
                    {/* <li className="item">
                        <div className="info">
                            <div className="title">Categories</div>
                            <div className="count">68</div>
                        </div>
                        <Icon name="server" className="bg-purple-dim"></Icon>
                    </li> */}
                </ul>
            </div>
        </Card>
    );
};
export default DealsStatistics;
