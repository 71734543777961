import React, { useState } from "react";
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { Icon, RSelect } from "../../../components/Component";
import { useForm } from "react-hook-form";
import useDragAndDrop from "../../../utils/hooks/useDragAndDrop";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { footerTypes, uploadToS3 } from "../../../utils/envConfig";
import uuid from "react-uuid";
import InputFile from "./InputFile";

const AddFooterTemplate = ( { isOpen, toggle, callback, props } ) =>
{
    const axios = useAxiosPrivate();
    const [ errorMessage, setErrorMessage ] = useState( '' );
    const [ selectedVideo, setSelectedVideo ] = useState( null );
    const handleVideoChange = ( event ) =>
    {
        const file = event.target.files[ 0 ];
        setSelectedVideo( URL.createObjectURL( file ) );
    };

    const { errors, register, handleSubmit } = useForm();
    const submitForm = ( data ) =>
    {
        props.next();
    };
    const [newFooter, setNewFooter] = useState({
        name: null,
        desc: "",
        logo: null,
        type: null,
    });
    const [submitting, setSubmitting] = useState(false);

    const {
        active: newFooterActive,
        handleFileChange: newFooterFileChange,
        files: newFooterFiles,
        dragenter: newFooterDragEnter,
        dragleave: newFooterDragLeave,
        drop: newFooterDrop,
        errorMessage: newFooterStatus,
        removeImage,
        setFiles,
    } = useDragAndDrop({
        inputType: "video",
    });
    const handleNewFooterChange = (e) => {
        const { name, value } = e.target;
        setNewFooter((prev) => ({ ...prev, [name]: value }));
    };

    const handleAddNewFooter = async (e) => {
        e.preventDefault();
        try {
            if (
                !newFooterFiles[0]?.file?.name ||
                !newFooter.name ||
                !newFooter.desc ||
                !newFooter.type
            )
                return toast.error("Please fill all fields");

            setSubmitting(true);

            const fileName = `offPlans/footerTemplate/${uuid()}.${newFooterFiles[0]?.file?.name
                ?.split(".")
                ?.pop()}`;

            const s3res = await uploadToS3(
                newFooterFiles[0]?.file,
                fileName,
                "video/*",
                axios
            );

            if (s3res) {
                const payloadData = {
                    ...newFooter,
                    logo: fileName,
                    description: newFooter.desc,
                };

                const { data } = await axios.post(
                    `off-plan/footer-template`,
                    payloadData
                );

                // data && setSelectedFooter({ ...data, logo: s3res });
                // data && setFooterTemplates((prev) => [...prev, data]);
                console.log(typeof callback)

                if (callback && typeof callback === 'function') {
                    callback(data);
                }
                setNewFooter({ name: null, desc: null, logo: null });
                toast.success("Added footer successfully");
                toggle();
                
                
            }
        } catch (err) {
            // console.log(err);
            toast.error("Unexpected error. Failed to create footer");
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Modal isOpen={ isOpen } toggle={ toggle } size="lg" backdrop="static">
            <ModalHeader
                toggle={ toggle }
                close={
                    <button
                        className="close"
                        onClick={ ( ev ) =>
                        {
                            ev.preventDefault();
                            toggle();
                        } }
                    >
                        <Icon name="cross" />
                    </button>
                }
            >
                Add New Footer Template
            </ModalHeader>
            <ModalBody>
                <form id='add-footer-form'
                    onSubmit={ (e)=>handleAddNewFooter(e) }>
                    <Row className="g-3">
                        <Col sm="12">
                            <Label>
                                Footer Name
                            </Label>
                            <Input 
                            placeholder="Footer Name"
                            value = {newFooter.name}
                            onChange = {handleNewFooterChange}
                            name="name"
                            id="name"
                            >

                            </Input>
                        </Col>
                        <Col sm="12">
                            <Label>
                                Footer Type
                            </Label>
                            <RSelect
                                  options={footerTypes.map((foo)=>({
                                    value: foo,
                                    label: foo,

                                  }))}
                                  placeholder="Choose Footer Type"
                                  value={newFooter?.type}
                                  onChange={(item) => {            
                                    setNewFooter((prev) => ({
                                        ...prev,
                                        type: item.value,
                                    }));
                                  }}
                             />
                        </Col>
                        <Col sm="12">
                            <div className="form-group">
                                <Label className="form-label" htmlFor="fv-message">
                                    Description
                                </Label>
                                <div className="form-control-wrap">
                                    <textarea
                                        type="textarea"
                                        className="form-control form-control-sm"
                                        rows="5"
                                        placeholder="Describe Footer Template"
                                        name="desc"
                                        id="desc"
                                        defaultValue = {newFooter.desc}
                                        onChange = {handleNewFooterChange}

                                    />
                                </div>
                            </div>
                        </Col>
                        <Col sm="12 ">
                            <div className="form-group mb-5">
                                <label className="form-label">Video</label>
                                <div className="form-control-wrap">
                                    <div className="form-file">
                                    <InputFile
                        active={newFooterActive}
                        label={"Select Template video"}
                        errorMessage={newFooterStatus}
                        files={newFooterFiles}
                        handleFileChange={newFooterFileChange}
                        inputType="video"
                        dragenter={newFooterDragEnter}
                        dragleave={newFooterDragLeave}
                        drop={newFooterDrop}
                        inputProps={{
                            accept: "video/*",
                        }}
                    />
                                        {/* <Input 
                                        type="file" 
                                        id="customFile"
                                         accept="video/*" 
                                         name="video" 
                                       handleFileChange={newFooterFileChange}
                                         /> */}
                                    </div>
                                    { errorMessage && <div className="text-danger">{ errorMessage }</div> }
                                </div>

                            </div>
                            { selectedVideo && (
                                <div className="position-relative">
                                    <video src={ selectedVideo } controls style={ { width: "15rem" } } />
                                    <Button className="bg-danger position-absolute" style={ { top: "0", left: "0" } }>
                                        <Icon name="trash"></Icon>
                                    </Button>
                                </div>
                            ) }
                        </Col>


                    </Row>
                    <div className="actions clearfix">
                        <ul>
                            <li>
                                <Button 
                                 disabled={submitting}
                                color="secondary" type="submit">
                                   { !submitting ? (
                                    <>
                                    <Icon name="plus" className="me-1"></Icon>
                                        Add Footer Template
                                     </>
                                    ) : (
                                    <Spinner size="sm" color="light" />
                                    ) }
                                   
                                </Button>
                            </li>
                        </ul>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};
export default AddFooterTemplate;