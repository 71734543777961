import React, { useEffect, useState } from "react";
import { Button, Col, ModalBody, ModalFooter, Row } from "reactstrap";
import { Icon, RSelect } from "../../../components/Component";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useRef } from "react";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const AmenityModal = ({ values, propertyUpdate = () => null, toggleModal }) => {
  const axios = useAxiosPrivate();
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [amenityOptions, setAmenityOptions] = useState([]);
  const [amenityFields, setAmenityFields] = useState(values.amenityObjs);
  const [amenityFieldIds, setAmenityFieldIds] = useState(values.amenityIds);
  const [amenities, setAmenities] = useState([]);

  const [formData, setFormData] = useState({
    amenityIds: [],
    amenityObjs: [],
  });

  useEffect(() => {
    if (amenities && amenities.length > 0) {
      let fil2 = values?.amenityObjs
        .map((ele) => {
          const foundAmenity = amenities.find((am) => ele.amenityId === am.id);
          if (foundAmenity) {
            return {
              label: foundAmenity.amenity,
              value: foundAmenity.id,
            };
          }
          return null;
        })
        .filter(Boolean);

      setSelectedAmenities(fil2);
    }
  }, [values.amenityIds, amenities]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      amenityObjs: amenityFields,
    }));
  }, [amenityFields]);

  useEffect(() => {
    selectedAmenities &&selectedAmenities.length>0 &&addAmenityListFields(selectedAmenities);
  }, [selectedAmenities]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      amenityIds: amenityFieldIds,
    }));
  }, [amenityFieldIds]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.amenityIds.length < 5) {
      toast.error("Please add at least 5 amenities");
    } else {
      // Uncomment the line below if needed to submit the form
      propertyUpdate(formData);
      toggleModal();
    }
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevAmenityFields = usePrevious(amenityFields);
  const addAmenityListFields = (values) => {
    // console.log(values)
    if (values !== undefined) {
      let newfield = [];
      values.map((v) => {
        if (prevAmenityFields.some((am) => v.value === am.amenityId)) {
          let xyz = prevAmenityFields.filter((am) => v.value === am.amenityId);
          newfield.push({
            amenityId: xyz[0].amenityId,
            priority: xyz[0].priority,
            quantity: xyz[0].quantity,
          });
        } else {
          newfield.push({ amenityId: v.value, priority: "0", quantity: "0" });
        }
      });

      // console.log(newfield)
      setAmenityFieldIds(values.map((v) => v.value));
      setAmenityFields(newfield);
    }
  };

  const handleQuantity = (index, event, input) => {
  
    setAmenityFields((prevAmenityFields) => {
      const newData = [...prevAmenityFields];
      const matchingItem = newData.find((am) => am.amenityId === input);
      if (matchingItem) {
        matchingItem.quantity = event.target.value;
      }
      return newData;
    });
  };
  const handleSelectAmenities = (selectedOptions) => {
    setSelectedAmenities(selectedOptions);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(selectedAmenities);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // console.log(items)
    setSelectedAmenities(items);
  };
  useEffect(() => {
    async function fetchAmenity() {
      try {
        await axios
          .get(`amenity`)
          .then((amenities) => {
            let options = [];
            amenities.data.forEach((ele) =>
              options.push({ label: ele.amenity, value: ele.id })
            );
            setAmenityOptions(options);
            let fil = [];
            amenities.data.forEach((ele) =>
              values.amenityIds.map((am) =>
                am === ele.id
                  ? fil.push({
                      label: ele.amenity,
                      value: ele.id,
                    })
                  : null
              )
            );
            setAmenityFieldIds(values.amenityIds);
            setAmenities(amenities.data);
            // setAmenity(options);
          })
          .catch((err) => {
            toast.error("Amenity not available !");
          });
      } catch (err) {
        // console.log(err)
        toast.error("Server error. Try Again !");
      }
    }
    fetchAmenity();
  }, []);
  return (
    <>
      <ModalBody>
        <Row className="gy-3">
          <Col sm="12">
            <div>
              <div className="form-group">
                <label className="form-label">Amenities*</label>
                <div className="d-flex align-items-center">
                  <div className="me-2" style={{ width: "100%" }}>
                    <RSelect
                      closeMenuOnSelect={false}
                      defaultData={[]}
                      isMulti
                      onChange={handleSelectAmenities}
                      options={amenityOptions}
                      className="w-100"
                      value={selectedAmenities}
                    />
                  </div>
                </div>
              </div>

              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="lines">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {selectedAmenities.map((amenity, index) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className="d-flex align-items-center mb-2"
                            >
                              <input
                                type="text"
                                value={amenity.label}
                                readOnly
                                className="me-2 form-control"
                              />
                              <div className="input-group me-2">
                                <input
                                  type="text"
                                  defaultValue={
                                    amenityFields.find(
                                      (am) => am.amenityId === amenity.value
                                    )?.quantity ?? "0"
                                  }
                                  className="form-control"
                                  onChange={(event) =>
                                    handleQuantity(index, event, amenity.value)
                                  }
                                />
                              </div>
                              <div className="input-group-append">
                                <span
                                  {...provided.dragHandleProps}
                                  className="input-group-text"
                                >
                                  <Icon name="move"></Icon>
                                </span>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="border-0">
        <Button
          onClick={async (e) => {
            handleSubmit(e);
          }}
          className="bg-primary text-white"
        >
          Save
        </Button>
      </ModalFooter>
    </>
  );
};

export default AmenityModal;
