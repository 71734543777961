import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Draggable, Droppable } from "react-beautiful-dnd";
import
  {
    Badge,
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    UncontrolledDropdown,
  } from "reactstrap";
import { Icon, UserAvatar } from "../../../components/Component";
import { findUpper } from "../../../utils/Utils";
import
  {
    KanbanTaskForm,
    NewKanbanTaskForm,
    DisQualifyForm,
    LostForm,
  } from "./KanbanForms";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import
  {
    serverAPI,
    actionS3Objects,
    ActivityTypes,
    LeadLabels,
    newRoles,
    LeadStatus,
  } from "../../../utils/envConfig";
import { useSelector } from "react-redux";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import DndFlagComponent from "../dnd/dndFlag";
import SendToPhone from "../../../components/SendToPhone";
const callcenter = [ "Call Center 1", "Call Center 2", "Call Center 3" ];

export const KanbanCard = ( { data, setData, card, index, column } ) =>
{
  const [ open, setOpen ] = useState( false );
  const [ taskModal, setTaskModal ] = useState( false );
  const [ warnImg, setWarnImg ] = useState( false );

  const toggleOpen = () =>
  {
    setOpen( !open );
  };
  const toggleTaskModal = () =>
  {
    setTaskModal( !taskModal );
  };
  const deleteTask = ( id ) =>
  {
    let defaultData = data;
    delete defaultData.task[ id ];

    defaultData.columns[ column.id ].tasks = defaultData.columns[
      column.id
    ].tasks.filter( ( item ) => item !== id );

    setData( { ...defaultData } );
  };
  const { id, title, desc, meta } = card;
  return (
    <React.Fragment>
      <Draggable draggableId={ id } key={ id } index={ index }>
        { ( provided ) => (
          <div
            ref={ provided.innerRef }
            { ...provided.draggableProps }
            { ...provided.dragHandleProps }
            className="mt-2"
          >
            <div className="kanban-item">
              <div className="kanban-item-title">
                <h6 className="title">{ title }</h6>
                <Dropdown isOpen={ open } toggle={ toggleOpen }>
                  <DropdownToggle
                    tag="a"
                    href="#toggle"
                    className="dropdown-toggle"
                    onClick={ ( ev ) => ev.preventDefault() }
                  >
                    <div className="user-avatar-group">
                      { meta.users.map( ( user, index ) => (
                        <UserAvatar
                          key={ index }
                          className="xs"
                          theme={ user.theme }
                          text={ user.value[ 0 ] }
                          image={
                            "https://images.unsplash.com/photo-1597223557154-721c1cecc4b0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8YmVhdXRpZnVsJTIwZmFjZXN8ZW58MHx8MHx8&w=1000&q=80"
                          }
                        ></UserAvatar>
                      ) ) }
                    </div>
                  </DropdownToggle>
                  <DropdownMenu end>
                    <ul className="link-list-opt no-bdr p-3 g-2">
                      { meta.users.map( ( user, index ) => (
                        <li key={ index }>
                          <div className="user-card" onClick={ toggleOpen }>
                            <UserAvatar
                              className="sm"
                              theme={ user.theme }
                              text={ findUpper( user.value ) }
                              image={
                                "https://images.unsplash.com/photo-1597223557154-721c1cecc4b0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8YmVhdXRpZnVsJTIwZmFjZXN8ZW58MHx8MHx8&w=1000&q=80"
                              }
                            ></UserAvatar>
                            <div className="user-name">
                              <span className="tb-lead">{ user.value }</span>
                            </div>
                          </div>
                        </li>
                      ) ) }
                    </ul>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="kanban-item-text">
                <p>{ desc }</p>
              </div>
              <ul className="kanban-item-tags">
                { meta.tags.map( ( tag, index ) => (
                  <li key={ index }>
                    <Badge color={ tag.theme }>{ tag.value }</Badge>
                  </li>
                ) ) }
              </ul>
              <div className="kanban-item-meta">
                <ul className="kanban-item-meta-list">
                  { meta.date ? (
                    <li>
                      <Icon name="calendar"></Icon>
                      <span>{ meta.date }</span>
                    </li>
                  ) : (
                    <li className={ Number( meta.due ) < 5 ? "text-danger" : "" }>
                      <Icon name="calendar"></Icon>
                      <span>{ meta.due }d Due</span>
                    </li>
                  ) }
                  <li>
                    <a href="https://alba.homes/">
                      <Icon name="call"></Icon>
                      <span>{ meta.phone }</span>
                    </a>
                  </li>
                </ul>
                <ul className="kanban-item-meta-list">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      tag="a"
                      href="toggle"
                      onClick={ ( ev ) => ev.preventDefault() }
                      className="dropdown-toggle btn btn-xs btn-icon btn-trigger me-n1"
                    >
                      <Icon name="more-v"></Icon>
                    </DropdownToggle>
                    <DropdownMenu end>
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#item"
                            onClick={ ( ev ) =>
                            {
                              ev.preventDefault();
                              toggleTaskModal();
                            } }
                          >
                            <Icon name="edit"></Icon>
                            <span>Edit Task</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#item"
                            onClick={ ( ev ) =>
                            {
                              ev.preventDefault();
                              deleteTask( card.id );
                            } }
                          >
                            <Icon name="trash"></Icon>
                            <span>Delete Task</span>
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </ul>
              </div>
            </div>
          </div>
        ) }
      </Draggable>
      <Modal size="lg" isOpen={ taskModal } toggle={ toggleTaskModal }>
        <KanbanTaskForm
          toggle={ toggleTaskModal }
          data={ data }
          setData={ setData }
          editTask={ card }
          taskToBoard={ column }
        />
      </Modal>
    </React.Fragment>
  );
};
export const KanbanCardList = ( { data, setData, tasks, column } ) =>
{
  return tasks.length > 0 ? (
    tasks.map( ( task, index ) =>
    {
      const card = data.task[ task ];
      return (
        <KanbanCard
          card={ card }
          data={ data }
          setData={ setData }
          key={ card.id }
          index={ index }
          column={ column }
        />
      );
    } )
  ) : (
    <div className="kanban-drag"></div>
  );
};
export const KanbanColumn = ( { data, setData, column, index } ) =>
{
  const [ open, setOpen ] = useState( false );
  const [ editModal, setEditModal ] = useState( false );

  const toggleModal = () =>
  {
    setOpen( !open );
  };

  const toggleEditModal = () =>
  {
    setEditModal( !editModal );
  };
  return (
    <React.Fragment>
      <Draggable draggableId={ column.id } key={ column.id } index={ index }>
        { ( provided ) => (
          <div
            className="kanban-board"
            ref={ provided.innerRef }
            { ...provided.draggableProps }
          >
            <div
              className={ `kanban-board-header kanban-${ column.theme }` }
              { ...provided.dragHandleProps }
            >
              <div className="kanban-title-board">
                <div className="kanban-title-content">
                  <h6 className="title">{ column.text }</h6>
                  <Badge className="text-dark" pill color="outline-light">
                    { column.tasks.length }
                  </Badge>
                </div>
                <div className="kanban-title-content">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      tag="a"
                      href="toggle"
                      onClick={ ( ev ) => ev.preventDefault() }
                      className="dropdown-toggle btn btn-sm btn-icon btn-trigger me-n1"
                    >
                      <Icon name="more-h"></Icon>
                    </DropdownToggle>
                    <DropdownMenu end>
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <DropdownItem tag="a" href="#item">
                            <Icon name="search"></Icon>
                            <input
                              className="form-control border-transparent form-focus-none"
                              type="text"
                              placeholder="Search On Column"
                            />
                          </DropdownItem>
                        </li>

                        <li>
                          <DropdownItem tag="a" href="#item">
                            <Icon name="plus-sm"></Icon>
                            <span>Add New Lead</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#item"
                            onClick={ ( ev ) =>
                            {
                              ev.preventDefault();
                              toggleModal();
                            } }
                          >
                            <Icon name="task"></Icon>
                            <span>Add New Task</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem tag="a" href="#item">
                            <Icon name="sort"></Icon>
                            <span>Sort By Most Recent</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem tag="a" href="#item">
                            <Icon name="calendar"></Icon>
                            <span>Sort By Date Of Next Action </span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#item"
                            onClick={ ( ev ) =>
                            {
                              ev.preventDefault();
                              toggleModal();
                            } }
                          >
                            <Icon name="sort"></Icon>
                            <span>Sort By Overdue </span>
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </div>
            <Droppable droppableId={ column.id } type="task">
              { ( provided ) => (
                <div
                  className="kanban-drag"
                  { ...provided.droppableProps }
                  ref={ provided.innerRef }
                >
                  <KanbanCardList
                    data={ data }
                    setData={ setData }
                    tasks={ column.tasks }
                    column={ column }
                  />
                  <button className="kanban-add-task mt-2 btn btn-block">
                    <Icon name="loader"></Icon>
                    <span>Load More</span>
                  </button>
                  { provided.placeholder }
                </div>
              ) }
            </Droppable>
          </div>
        ) }
      </Draggable>
      <Modal size="lg" isOpen={ open } toggle={ toggleModal }>
        <KanbanTaskForm
          toggle={ toggleModal }
          data={ data }
          setData={ setData }
          taskToBoard={ column }
        />
      </Modal>
    </React.Fragment>
  );
};


export const MyKanbanCard = ( { user, col } ) =>
{
  const axios = useAxiosPrivate();
  const [ open, setOpen ] = useState( false );
  const [ taskModal, setTaskModal ] = useState( false );
  const [ editTaskModal, setEditTaskModal ] = useState( false );
  const [ disQualifyModal, setDisQualifyModal ] = useState( false );
  const [ lostModal, setLostModal ] = useState( false );
  const [ hotLeadSourceNames, setHotLeadSourceNames ] = useState( [] );
  const [ finalTimeDiff, setFinalTimeDiff ] = useState( null );
  const [ warnImg, setWarnImg ] = useState( false );
  const [ warnImgOnehour, setWarnImgOnehour ] = useState( false );
  const [ dueImg, setDueImg ] = useState( false );
  const [ selectedUser, setSelectedUser ] = useState( false );
  const [ showModal, setShowModal ] = useState( false );
  const [ imgUserUrl, setUserImgUrl ] = useState( null );
  const [ imgUserLoading, setUserImgLoading ] = useState( true );
  const [ imgAgentUrl, setAgentImgUrl ] = useState( null );
  const [ imgAgentLoading, setAgentImgLoading ] = useState( true );
  const [isInterestedCount, setIsInterestedCount] = useState( null );
  const [dealsCountByUser, setDealsCountByUser] = useState( null );
  const [ activities, setActivities ] = useState( null );
  // Constants
  // Validate User Token
  const { access_token } =
    useSelector( ( state ) => state?.user?.loggedInUser ) ?? "";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ access_token }`,
  };
  const getUrl = async ( file ) =>
  {
    const uploadData = JSON.stringify( {
      action: `${ actionS3Objects[ 0 ] }`,
      fileName: file,
    } );
    const resp = await axios.post( `/upload`, uploadData, {
      headers,
    } );
    const s3url = resp.data.signedRequest;
    if ( s3url )
    {
      return s3url;
    }
  };

  const getHotLeads = async () =>
  {
    let link = `/leadSources/hotleads`;
    const res = await axios.get( link, { headers } );
    const result = res?.data?.data;
    let names = result.map( ( item ) => item.name );
    setHotLeadSourceNames( names );
  };

  useEffect( () =>
  {
   
    if ( user?.last_activity_date )
    {
      let readableTimeDiff = null;
      // Calculate the difference between the last_activity_date and the current datetime
      const currentDatetime = new Date();
      const apiDatetime = new Date( user?.last_activity_date );
      const differenceInMilliseconds =
        currentDatetime.getTime() - apiDatetime.getTime();
      if ( differenceInMilliseconds !== null )
      {
        const differenceInSeconds = differenceInMilliseconds / 1000;
        const differenceInMinutes = differenceInSeconds / 60;
        const differenceInHours = differenceInMinutes / 60;
        const differenceInDays = differenceInHours / 24;
        const differenceInMonths = differenceInDays / 30.44; // average number of days in a month
        const differenceInYears = differenceInMonths / 12;
        const dueTenMinutes = new Date(
          currentDatetime.getTime() + 10 * 60 * 1000
        );

        if ( !user?.last_activity_is_complete )
        {
          const currentTime = new Date( currentDatetime ).getTime(); // UTC - ServerTime
          const tenMinBFTime = new Date( apiDatetime - 10 * 60000 ).getTime();
          let DiffTenMin = tenMinBFTime - currentTime;
          if ( DiffTenMin > 0 )
          {
            setDueImg( true );
          }

          // if (apiDatetime >= dueTenMinutes) {
          //   console.log(apiDatetime, " ", dueTenMinutes);
          //   console.log("Due in 10 minutes or less");
          //   setDueImg(true);
          // }

          if ( differenceInYears >= 1 )
          {
            readableTimeDiff = `due ${ Math.floor( differenceInYears ) } year${ Math.floor( differenceInYears ) > 1 ? "s" : ""
              } ago`;
            setWarnImg( true );
          } else if ( differenceInMonths >= 1 )
          {
            readableTimeDiff = `due ${ Math.floor( differenceInMonths ) } month${ Math.floor( differenceInMonths ) > 1 ? "s" : ""
              } ago`;
            setWarnImg( true );
          } else if ( differenceInDays >= 1 )
          {
            readableTimeDiff = `due ${ Math.floor( differenceInDays ) } day${ Math.floor( differenceInDays ) > 1 ? "s" : ""
              } ago`;
            setWarnImg( true );
          } else if ( differenceInHours >= 1 )
          {
            readableTimeDiff = `due ${ Math.floor( differenceInHours ) } hour${ Math.floor( differenceInHours ) > 1 ? "s" : ""
              } ago`;
            setWarnImg( true );
          } else if ( differenceInMinutes >= 1 )
          {
            readableTimeDiff = `due ${ Math.floor( differenceInMinutes ) } minute${ Math.floor( differenceInMinutes ) > 1 ? "s" : ""
              } ago`;
            // setWarnImg(true);
            setWarnImgOnehour( true );
          } else if ( differenceInYears <= -1 )
          {
            readableTimeDiff = `due in ${ Math.floor(
              Math.abs( differenceInYears )
            ) } year${ Math.floor( Math.abs( differenceInYears ) ) > 1 ? "s" : ""
              } or less`;
          } else if ( differenceInMonths <= -1 )
          {
            readableTimeDiff = `due in ${ Math.floor(
              Math.abs( differenceInMonths )
            ) } month${ Math.floor( Math.abs( differenceInMonths ) ) > 1 ? "s" : ""
              } or less`;
          } else if ( differenceInDays <= -1 )
          {
            readableTimeDiff = `due in ${ Math.floor(
              Math.abs( differenceInDays )
            ) } day${ Math.floor( Math.abs( differenceInDays ) ) > 1 ? "s" : ""
              } or less`;
          } else if ( differenceInHours <= -1 )
          {
            readableTimeDiff = `due in ${ Math.floor(
              Math.abs( differenceInHours )
            ) } hour${ Math.floor( Math.abs( differenceInHours ) ) > 1 ? "s" : ""
              } or less`;
          } else if ( differenceInMinutes < 0 )
          {
            readableTimeDiff = `due in ${ Math.floor(
              Math.abs( differenceInMinutes )
            ) } minute${ Math.floor( Math.abs( differenceInMinutes ) ) > 1 ? "s" : ""
              } or less`;
          } else
          {
            readableTimeDiff = `now`;
            setDueImg( false );
          }
        }

        if ( user?.last_activity_is_complete )
        {
          // readableTimeDiff = `Completed on ${format(
          //   new Date(user?.last_activity_date),
          //   "EEE, MMM d, yyyy, hh:mm a"
          // )}`;
          if ( differenceInYears >= 1 )
          {
            readableTimeDiff = `Completed ${ Math.floor(
              differenceInYears
            ) } year${ Math.floor( differenceInYears ) > 1 ? "s" : "" } ago`;
          } else if ( differenceInMonths >= 1 )
          {
            readableTimeDiff = `Completed ${ Math.floor(
              differenceInMonths
            ) } month${ Math.floor( differenceInMonths ) > 1 ? "s" : "" } ago`;
          } else if ( differenceInDays >= 1 )
          {
            readableTimeDiff = `Completed ${ Math.floor( differenceInDays ) } day${ Math.floor( differenceInDays ) > 1 ? "s" : ""
              } ago`;
          } else if ( differenceInHours >= 1 )
          {
            readableTimeDiff = `Completed ${ Math.floor(
              differenceInHours
            ) } hour${ Math.floor( differenceInHours ) > 1 ? "s" : "" } ago`;
          } else if ( differenceInMinutes >= 1 )
          {
            readableTimeDiff = `Completed ${ Math.floor(
              differenceInMinutes
            ) } minute${ Math.floor( differenceInMinutes ) > 1 ? "s" : "" } ago`;
          }
        }
      }

      setFinalTimeDiff( readableTimeDiff );
    }

    if ( user?.photo )
    {
      setUserImgLoading( true );
      let promise = getUrl( user?.photo );
      promise
        .then( ( url ) => setUserImgUrl( url ) )
        .finally( () => setUserImgLoading( false ) );
    } else
    {
      setUserImgUrl( "./img/profile.png" );
      setUserImgLoading( false );
    }

    if ( user?.currentAgent && user?.currentAgent?.userId?.photo )
    {
      setAgentImgLoading( true );
      let promise = getUrl( user?.currentAgent?.userId?.photo );
      promise
        .then( ( url ) => setAgentImgUrl( url ) )
        .finally( () => setAgentImgLoading( false ) );
    } else
    {
      setAgentImgUrl( "./img/profile.png" );
      setAgentImgLoading( false );
    }
    async function fetchActivities ()
    {
        try
        {
          let link = `/activities/user/${ user?.id }?isInterested=true`;

          const res = await axios.get( link, {
            headers,
          } );
            setIsInterestedCount( res.data?.totalItems );

        } catch ( err )
        {
        }
    }
    fetchActivities();

    async function fetchDeals ()
    {
        try
        {
            let link = `/search/deal/filter?user=${ user?.id }`;
            const res = await axios.get( link, { headers } );
            setDealsCountByUser( res.data?.filteredCount );
        } catch ( err )
        {
            console.log( err );
        }
    }
    fetchDeals();

    
    fetchAllActivities();

  }, [ user ] );

  async function fetchAllActivities ()
    {
        try
        {
          let link = `/activities/user/${ user?.id }`;

          const res = await axios.get( link, {
            headers,
          } );
            setActivities( res.data?.activities );

        } catch ( err )
        {
        }
    }

  const [hide, setHide] = useState(false);
  useEffect( () =>
  {  
    if (
      activities &&
      activities.length ===1 &&
      activities[activities.length - 1].type === 'Assign' &&
      activities[activities.length - 1].status !== 'Complete'
    ) {
      setHide(true);
    }
    else if( activities &&
      activities.length > 0 &&
      activities[0].status !== 'Complete'){
        setHide(true);
    }
    else {
      setHide(false);
    }
  }, [activities] );

  useEffect( () =>
  {
    getHotLeads();
  }, [] );

  const handleModal = ( e, user ) =>
  {
    e.preventDefault();
    setShowModal( true );
    setSelectedUser( user );
  };
  const closeModal = () =>
  {
    setShowModal( false );
  };
  const toggleOpen = () =>
  {
    setOpen( !open );
  };
  const toggleTaskModal = ( e ) =>
  {
    setTaskModal( !taskModal );
  };
  const toggleEditTaskModal = ( e ) =>
  {
    setEditTaskModal( !editTaskModal );
  };
  const toggleDisQualifyModal = () =>
  {
    setDisQualifyModal( !disQualifyModal );
  };
  const toggleLostModal = () =>
  {
    setLostModal( !lostModal );
  };

  const deleteTask = ( id ) => { };
  // const { id, title, desc, meta } = card;
  return (
      <div className="kanban-item">
        <Link to={ `/user-profile/${ user?.id }/user-profile-setting` }>
          <div className="kanban-item-title">
            <h6 className="title">
              { !user?.last_activity_is_complete && warnImgOnehour && (
                <Badge
                  className="badge-sm badge-dot d-sm-inline-flex warning-badge"
                  color="warning"
                ></Badge>
              ) }
              { user?.first_name } { user?.last_name }
              { !user?.last_activity_is_complete && warnImg && (
                <Badge
                  className="badge-sm badge-dot d-sm-inline-flex"
                  color="danger"
                ></Badge>
              ) }
            </h6>
            { user?.currentAgent && Object.keys(user?.currentAgent).length ? (
              <Dropdown isOpen={ open } toggle={ toggleOpen }>
                <DropdownToggle
                  tag="a"
                  href="#toggle"
                  className="dropdown-toggle"
                  onClick={ ( ev ) => ev.preventDefault() }
                >
                  <div className="user-avatar-group">
                    <UserAvatar
                      key={ user?.id }
                      className="xs"
                      theme={ user.theme }
                      text={ `${user?.currentAgent?.userId?.first_name[0]} ${user?.currentAgent?.userId?.last_name[0] ?? ""}` }
                      image={ imgAgentUrl }
                    ></UserAvatar>
                  </div>
                </DropdownToggle>
                <DropdownMenu end>
                  <ul className="link-list-opt no-bdr p-3 g-2">
                    <li key={ user?.id }>
                      <div className="user-card" onClick={ toggleOpen }>
                        <UserAvatar
                          className="sm"
                          theme={ user.theme }
                          text={ `${user?.currentAgent?.userId?.first_name[0]} ${user?.currentAgent?.userId?.last_name[0] ?? ""}` }
                          image={ imgAgentUrl }
                        ></UserAvatar>
                        <div className="user-name">
                          <span className="tb-lead">
                            { user?.currentAgent?.userId?.first_name }
                            { user?.currentAgent?.userId?.last_name ?? "" }
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </DropdownMenu>
              </Dropdown>
            ) : null }
          </div>
          <div className="kanban-item-text">
            <p>{ user?.lead_source }</p>
          </div>
          <ul className="kanban-item-tags">
            { hotLeadSourceNames.includes( user?.lead_source ) ||
              callcenter.includes( user?.lead_source ) ? (
              <li key={ "1" }>
                <Badge color="danger">{ LeadLabels.HotLead }</Badge>
              </li>
            ) : (
              <li key={ "2" }>
                <Badge color="primary">{ LeadLabels.ColdLead }</Badge>
              </li>
            ) }
            { user?.activities?.is_interested && (
              <li key={ "3" }>
                <Badge color="success">{ LeadLabels.Interested }</Badge>
              </li>
            ) }
            <li key={ "4" }>
              <Badge color="light">
                { user?.last_activity_type &&
                  user?.last_activity_type === ActivityTypes.Assign
                  ? "Unassigned"
                  : user?.last_activity_type }
                :{ " " }
              </Badge>
            </li>
          </ul>
          <div className="kanban-item-meta">
            <ul className="kanban-item-meta-list">
              { true ? (
                <li>
                  <Icon name="calendar"></Icon>
                  <span> { user?.last_activity_date && finalTimeDiff }</span>
                </li>
              ) : (
                <li className={ Number( 34 ) < 5 ? "text-danger" : "" }>
                  <Icon name="calendar"></Icon>
                  <span>{ "desd" }d Due</span>
                </li>
              ) }
              <li>
                <a href="https://alba.homes/">
                 
                {user?.DndStatus ?
                  <DndFlagComponent className={"d-flex flex-column align-items-center justify-content-center p-2"} lead_status={user?.lead_status} phoneNumber={user?.phone} /> : <div style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
                     <Icon name="call"></Icon>
                    <span href="#id">
                      {user?.phone}
                    </span>
                    <SendToPhone leadId={user?.id}>
                      <Button outline size='xs'>Send to Phone</Button>
                    </SendToPhone>
                  </div>}
                </a>
              </li>
            </ul>
            <ul className="kanban-item-meta-list">
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="a"
                  href="toggle"
                  onClick={ ( ev ) => ev.preventDefault() }
                  className="dropdown-toggle btn btn-xs btn-icon btn-trigger me-n1"
                >
                  <Icon name="more-v"></Icon>
                </DropdownToggle>
                <DropdownMenu end>
                  <ul className="link-list-opt no-bdr">
                  { user && user?.role !== newRoles.Agent && user?.lead_status !== LeadStatus.Disqualified &&
                  user?.lead_status !== LeadStatus.Viewing && user?.lead_status !== LeadStatus.Deal &&
                  isInterestedCount <="0" && dealsCountByUser <="0" &&
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#item"
                        onClick={ ( ev ) =>
                        {
                          ev.preventDefault();
                          toggleDisQualifyModal();
                        } }
                      >
                        <Icon name="user-remove"></Icon>
                        <span>Disqualify</span>
                      </DropdownItem>
                    </li>
                  }
                  { user && user?.role !== newRoles.Agent && user?.lead_status !== LeadStatus.Disqualified &&
                  user?.lead_status !== LeadStatus.Viewing && user?.lead_status !== LeadStatus.Deal &&
                  isInterestedCount <="0" && dealsCountByUser <="0" &&
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#item"
                        onClick={ ( ev ) =>
                        {
                          ev.preventDefault();
                          toggleLostModal();
                        } }
                      >
                        <Icon name="user-remove"></Icon>
                        <span>Lost</span>
                      </DropdownItem>
                    </li>
                    }
                    {user && user.role !== 'Agent' && !hide &&
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#item"
                        onClick={ ( ev ) =>
                        {
                          ev.preventDefault();
                          toggleTaskModal();
                        } }
                      >
                        <Icon name="edit"></Icon>
                        <span>Add Task</span>
                      </DropdownItem>
                    </li>
                    }
                    {/* <li>
                      <DropdownItem
                        tag="a"
                        href="#item"
                        onClick={(ev) => {
                          ev.preventDefault();
                          // toggleEditTaskModal();
                        }}
                      >
                        <Icon name="edit"></Icon>
                        <span>Edit Task</span>
                      </DropdownItem>
                    </li> */}
                    {/* <li>
                      <DropdownItem
                        tag="a"
                        href="#item"
                        onClick={(ev) => {
                          ev.preventDefault();
                          // toggleTaskModal();
                        }}
                      >
                        <Icon name="edit"></Icon>
                        <span>Add Deal</span>
                      </DropdownItem>
                    </li> */}
                    {/* <li>
                    <DropdownItem
                      tag="a"
                      href="#item"
                      onClick={(ev) => {
                        ev.preventDefault();
                        // deleteTask( card.id );
                      }}
                    >
                      <Icon name="trash"></Icon>
                      <span>Delete Task</span>
                    </DropdownItem>
                  </li> */}
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ul>
            <Modal
              size="lg"
              isOpen={ taskModal }
              toggle={ toggleTaskModal }
              onClick={ ( e ) => e.stopPropagation() }
            >
              {/* <KanbanTaskForm toggle={ toggleTaskModal } data={ data } setData={ setData } editTask={ card } taskToBoard={ column } /> */ }
              <NewKanbanTaskForm
                toggle={ toggleTaskModal }
                data={ col }
                user={ user }
                taskToBoard={ user }
                taskOption={ "Add" }
                callback={fetchAllActivities}
              />
            </Modal>
            <Modal
              size="lg"
              isOpen={ editTaskModal }
              toggle={ toggleEditTaskModal }
            >
              <NewKanbanTaskForm
                toggle={ toggleEditTaskModal }
                data={ col }
                editTask={ user }
                taskToBoard={ user }
                taskOption={ "Update" }
              />
            </Modal>
            <Modal
              size="lg"
              isOpen={ disQualifyModal }
              toggle={ toggleDisQualifyModal }
            >
              <DisQualifyForm
                toggle={ toggleDisQualifyModal }
                data={ col }
                user={ user }
                
              />
            </Modal>
            <Modal size="lg" isOpen={ lostModal } toggle={ toggleLostModal }>
              <LostForm toggle={ toggleLostModal } data={ col } user={ user } />
            </Modal>
          </div>
        </Link>
      </div>
  );
};
