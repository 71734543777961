import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";

// const initialState = {}
const middleWare = [thunk];

export const store = createStore(
  rootReducer,
  // initialState,
  composeWithDevTools(applyMiddleware(...middleWare))
);
export const persistor = store
export default { store };
