import * as types from "../types";

const initialState = {
  dndNumbers: {}, 
  loading: true,
  error: null,
};

export const dnd = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DND:
      return {
        ...state,
        dndNumbers: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
