/* eslint-disable no-unused-vars */
import { Button } from "reactstrap";
import { Icon } from "../../../components/Component";
import { ViewportInfo } from '../types';


/**
 * @typedef {Object} ProfileContentProps
 * @property {ViewportInfo} viewportInfo - contains properties to render the view on smaller devices
 * @property {React.Dispatch<React.SetStateAction<ViewportInfo>>} setViewportInfo - contains properties to render the view on smaller devices
 * @property {React.ReactNode} [children] - The children to render in this component.
 * @property {any} [props] - Additional properties.
 */

/**
 * ProfileContent component renders a the main content container of the profile page.
 * 
 * @param {ProfileContentProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered table row.
 */
const ProfileContent = ({ viewportInfo, setViewportInfo, children, props }) => {
  return (
    <div className="card-inner card-inner-lg position-relative overflow-auto" {...props}>
      {viewportInfo?.isSm && viewportInfo?.mobileView && (
        <div
          className="toggle-overlay"
          onClick={() =>
            setViewportInfo((prev) => ({ ...prev, isSm: !viewportInfo?.isSm }))
          }
        ></div>
      )}
      <div
        className={`align-self-start d-lg-none d-flex justify-content-end ${
          viewportInfo?.mobileView ? "mb-2" : ""
        }`}
      >
        <Button
          className={`toggle btn btn-icon btn-trigger bg-transparent border-0 mt-n1 ${
            viewportInfo?.isSm ? "active" : ""
          }`}
          onClick={() =>
            setViewportInfo((prev) => ({ ...prev, isSm: !viewportInfo?.isSm }))
          }
        >
          <Icon name="menu-alt-r"></Icon>
        </Button>
      </div>
      {children ?? null}
    </div>
  );
};

export default ProfileContent;
