import React, { useState } from "react";
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { Icon } from "../../../../components/Component";

const GeneratePocketListingDataModal = ({
  isOpen,
  loading,
  toggle,
  onGenerate,
}) => {
  const [text, setText] = useState("");
  return (
    <Modal isOpen={isOpen} size="lg">
      <ModalHeader toggle={()=>{
        setText("")
        toggle()
    }}  color="white" className="bg-white">
        Generate Pocket Listing
      </ModalHeader>
      <ModalBody className="bg-white">
        <Card>
          <p className="text-primary">
            <Icon name={"info"} /> For Better Generation, Enter Detailed Information.
          </p>
          <Input
            value={text}
            onChange={(e) => setText(e.target.value)}
            type="textarea"
            placeholder="Enter Detailed Information About Pocket Listing"
            className="p-2"
          />
        </Card>
      </ModalBody>
      <ModalFooter className="bg-white">
        <Button
          disabled={loading}
          onClick={(e) => onGenerate(text)}
          color="primary"
        >
          Generate{loading && <Spinner size={"sm"} />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default GeneratePocketListingDataModal;
