import { Button, Input } from "reactstrap";
import {
  BlockHead,
  BlockHeadContent,
  RSelect,
} from "../../../../components/Component";
import { useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { HttpStatus, PropertyCardStatus } from "../../../../utils/envConfig";
import { toast } from "react-toastify";

const PropertyInfoEdit = ({
  propertyCardDetail,  
  toggleEdit,
  toggleRefresh,
}) => {
  
  const axios = useAxiosPrivate();
  const unitStatusOptions = [
    {label: "Vacant", value: "Vacant"},
    {label: "Reserved", value: "Reserved"},
    {label: "Sold", value: "Sold"},
    {label: "Tenanted", value: "Tenanted"},
  ];

  const categoryOptions = [
    {label: "Commercial", value: "Commercial"},
    {label: "Residential", value: "Residential"},
  ];

  const isPocketListing =
    propertyCardDetail?.status === PropertyCardStatus.PocketListing;

  const [unitStatus, setUnitStatus] = useState("");
  const [category, setCategory] = useState("");
  const [askingPrice, setAskingPrice] = useState(propertyCardDetail?.askingPrice ?? 0);
  const [agencyValuation, setAgencyValuation] = useState(propertyCardDetail?.agentValutionPrice ?? 0);

  const handleSubmit = async () => {
    const payload = {
      askingPrice: askingPrice ? Number(askingPrice) : propertyCardDetail?.askingPrice ?? null,
      agentValutionPrice: agencyValuation ? Number(agencyValuation) : propertyCardDetail?.agentValutionPrice ?? null,
      unitStatus: unitStatus.length ? unitStatus : propertyCardDetail?.unitStatus ?? null, 
      category: category.length ? category : propertyCardDetail?.category ?? null
    };
    try {
      const res = await axios.put(`/property-cards/${propertyCardDetail?.id}`, payload);
      if (res.status === HttpStatus.OK) {
        toast.success("Updated property info successfully!");
      }
    } catch (error) {
      toast.error("An error occurred, couldn't update");
      console.log(error);
    } finally {
      toggleEdit();
      toggleRefresh();
    }
  }

  return (
    <>
      <BlockHead>
        <BlockHeadContent>
            <h5 className="nk-block-title title">Editing Property Info</h5>
        </BlockHeadContent>
      </BlockHead>
      <div className="card card-bordered p-2">
        <ul className="data-list is-compact">
          {isPocketListing ? <li>
            <div className="p-2 mb-3 form-group">
              <label
                className="form-label"
                htmlFor="name"
                onClick={() => toggleEdit()}
              >
                <strong>Asking Price</strong>
              </label>
              <Input
                type="number"
                name="askingPrice"
                id="askingPrice"
                placeholder="Enter Asking Price"
                value={askingPrice}
                onChange={(e) => setAskingPrice(e.target.value)}
                />
            </div>
          </li> : null}
          {isPocketListing ? <li>
            <div className="p-2 mb-3 form-group">
              <label
                className="form-label"
                htmlFor="name"
                onClick={() => toggleEdit()}
              >
                <strong>Agency Valuation</strong>
              </label>
              <Input
                type="number"
                name="agencyValuation"
                id="agencyValuation"
                placeholder="Enter Agency Valuation Price"
                value={agencyValuation}
                onChange={(e) => setAgencyValuation(e.target.value)}
                />
            </div>
          </li> : null}
          <li>
            <div className="p-2 mb-3 form-group">
              <label
                className="form-label"
                htmlFor="name"
                onClick={() => toggleEdit()}
              >
                <strong>Category</strong>
              </label>
              <RSelect
                placeholder="Select Category"
                value={{label: propertyCardDetail?.category ?? category ?? null, value: propertyCardDetail?.category ?? category ?? null }}
                options={categoryOptions}
                onChange={(option) => setCategory(option.value)}
                />
            </div>
          </li>
          <li>
            <div className="p-2 mb-3 form-group">
              <label
                className="form-label"
                htmlFor="name"
                onClick={() => toggleEdit()}
              >
                <strong>Unit Status</strong>
              </label>
              <RSelect
                placeholder="Select Unit Status"
                value={{label: propertyCardDetail?.unitStatus ?? unitStatus ?? null, value: propertyCardDetail?.unitStatus ?? unitStatus ?? null }}
                options={unitStatusOptions}
                onChange={(option) => setUnitStatus(option.value)}
                />
            </div>
          </li>
        </ul>
        <div className="p-2 d-flex justify-content-end">
          <Button color="success" className="mx-2" onClick={handleSubmit}>Save</Button>
          <Button onClick={() => toggleEdit()} className="btn border-0 text-dark bg-gray-dim">Cancel</Button>
        </div>
      </div>
    </>
  );
};

export default PropertyInfoEdit;
