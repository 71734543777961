import { Badge } from "reactstrap";
import NewAgentAvatar from "../../users/components/NewAgentAvatar";

const AgentCard = ({ agent }) => {
    if (!agent) {
      return <Badge className="badge bg-danger">Not Available</Badge>
    }
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ gap: "0.8rem" }}>
        <div className="">
          <NewAgentAvatar size="sm" agent={agent} />
        </div>
        <div className="d-flex flex-column align-items-start">
          <span className="">{agent?.userId?.first_name ?? agent?.first_name ?? ""}</span>
          <span  style={{ minWidth: "10ch", textAlign: "left" }}>{agent?.userId?.phone ?? agent?.phone ?? ""}</span>
        </div>
      </div>
    );
};

export default AgentCard;