import React, { useEffect, useState } from 'react';
import { PieChart } from '../../components/charts/Chart';
import { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
    DealStatus,
    LeadSource,
    formatDate,
    formatValue,
    getDayName,
    newRoles
} from '../../utils/envConfig';
import {
    addDays,
    differenceInDays,
    endOfDay,
    endOfWeek,
    getDay,
    intervalToDuration,
    startOfDay,
    startOfWeek,
    subDays
} from 'date-fns';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import DatePicker from 'react-datepicker';
import {
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon
} from '../../components/Component';
import { Badge, Col, Row, Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import DataCard from '../../components/partials/default/DataCard';
import LineChart from '../../components/charts/LineChart';
import RenderChart from './components/RenderChart';

const defaultLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

const lineChartData = {
    labels: defaultLabels,
    datasets: [
        {
            data: [4, 6, 5, 9, 2, 8, 10],
            label: 'Label 1',
            color: '#9d72ff',
            borderWidth: 2,
            borderColor: '#9d72ff',
            fill: true,
            pointRadius: 0,
            lineTension: 0.4,
            backgroundColor: 'rgba(157, 114, 255, 0.15)',
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent',
            pointHoverBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 2,
            pointHitRadius: 4,
            pointHoverBorderColor: '#9d72ff'
        }
    ]
};

export default function MarketingDashboard() {
    const printRef = useRef();

    const axios = useAxiosPrivate();

    const authuser = useSelector((state) => state?.user?.loggedInUser);

    const headers = {
        Authorization: `Bearer ${authuser?.access_token}`
    };

    const today = new Date();

    const [dateError, setDateError] = useState('');

    const [smBtn, setSmBtn] = useState();

    const [dayStart, setDayStart] = useState(today);
    const [dayEnd, setDayEnd] = useState(today);

    const [weekStart, setWeekStart] = useState(startOfWeek(today));
    const [weekEnd, setWeekEnd] = useState(endOfWeek(today));

    // const [monthEnd, setMonthEnd] = useState(endOfMonth(today));
    const [windowWidth, setWindowWidth] = useState(null);

    // leads
    const [dailyLeads, setDailyLeads] = useState(null);

    const [leadsCreatedToday, setLeadsCreatedToday] = useState({
        hot: null,
        cold: null
    });
    const [leadsCreatedYesterday, setLeadsCreatedYesterday] = useState({
        hot: null,
        cold: null
    });

    const [usersByLeadSource, setUsersByLeadSource] = useState(null);

    // deals
    const [dailyDeals, setDailyDeals] = useState(null);
    const [dealsToday, setDealsToday] = useState(null);
    const [dealsClosedByLeadSource, setDealsClosedByLeadSource] =
        useState(null);

    // google analytics
    const [uniqueDailyVisitors, setUniqueDailyVisitors] = useState(null);

    const [pageViewsYesterday, setPageViewsYesterday] = useState(null);
    const [pageViewsToday, setPageViewsToday] = useState(null);

    const [uniqueViewsYesterday, setUniqueViewsYesterday] = useState(null);
    const [uniqueViewsToday, setUniqueViewsToday] = useState(null);

    const [bounceRate, setBounceRate] = useState(null);

    const [percentOfNewUSers, setPercentOfNewUSers] = useState(null);

    const [uniqueVisitorsByLeadSource, setUniqueVisistorsByLeadSource] =
        useState(null);

    const updateFetchingStatus = () => {
        setLeadsCreatedToday({ hot: null, cold: null });
        setLeadsCreatedYesterday({ hot: null, cold: null });
        setUsersByLeadSource(null);
        setDealsToday(null);
        setDealsClosedByLeadSource(null);
        setPageViewsYesterday(null);
        setPageViewsToday(null);
        setUniqueViewsYesterday(null);
        setUniqueViewsToday(null);
        setBounceRate(null);
        setPercentOfNewUSers(null);
        setUniqueVisistorsByLeadSource(null);
    };

    const getDailyLeadsCreatedThisWeek = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);
            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayEnd))
                    : formatDate(dayEnd);

            const { data: leads } = await axios.get(
                `/reports/leads?from_date=${fromDate}&to_date=${toDate}&daily=true`
            );

            let dateArray = [];
            let currentDate =
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart;

            const stopDate = dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd;

            while (currentDate <= stopDate) {
                dateArray.push(currentDate);
                currentDate = addDays(new Date(currentDate), 1);
            }

            const dailyLeads = dateArray?.map((date) => {
                const fromattedDate = formatDate(date);

                const data = leads?.find(
                    (item) => item?.date === fromattedDate
                );

                return {
                    date: getDayName(new Date(data?.date ?? fromattedDate)),
                    count: data?.count ?? 0
                };
            });

            setDailyLeads(dailyLeads);
        } catch (err) {
            console.log(err);
        }
    };

    const getLeadsCreatedToday = async () => {
        try {
            // calculate the difference in datys

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(dayStart);
            const toDate = formatDate(dayEnd);

            const hotLeadSourceQuery = Object.values(LeadSource)
                .filter((source) => source !== LeadSource.External)
                .map((src) => `lead_source[]=${src}`)
                .join('&');

            const coldLeadSourceQuery = Object.values(LeadSource)
                .filter((source) => source === LeadSource.External)
                .map((src) => `lead_source[]=${src}`)
                .join('&');

            const hotLeadsReq = axios.get(
                `/reports/leads?from_date=${fromDate}&to_date=${toDate}&${hotLeadSourceQuery}`
            );

            const coldLeadsReq = axios.get(
                `/reports/leads?from_date=${fromDate}&to_date=${toDate}&${coldLeadSourceQuery}`
            );

            const [{ data: hotLeads }, { data: coldLeads }] = await Promise.all(
                [hotLeadsReq, coldLeadsReq]
            );

            setLeadsCreatedToday({
                hot: hotLeads?.count?.toString() ?? '0',
                cold: coldLeads?.count?.toString() ?? '0'
            });
        } catch (err) {
            console.log(err);
            setLeadsCreatedToday({ hot: '0', cold: '0' });
        }
    };

    const getLeadsCreatedYesterday = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(
                dayDifference <= 0 ? subDays(dayStart, 1) : weekStart
            );
            const toDate = formatDate(
                dayDifference <= 0 ? subDays(dayEnd, 1) : weekEnd
            );

            const hotLeadSourceQuery = Object.values(LeadSource)
                .filter((source) => source !== LeadSource.External)
                .map((src) => `lead_source[]=${src}`)
                .join('&');

            const coldLeadSourceQuery = Object.values(LeadSource)
                .filter((source) => source === LeadSource.External)
                .map((src) => `lead_source[]=${src}`)
                .join('&');

            const hotLeadsReq = axios.get(
                `/reports/leads?from_date=${fromDate}&to_date=${toDate}&${hotLeadSourceQuery}`
            );

            const coldLeadsReq = axios.get(
                `/reports/leads?from_date=${fromDate}&to_date=${toDate}&${coldLeadSourceQuery}`
            );

            const [{ data: hotLeads }, { data: coldLeads }] = await Promise.all(
                [hotLeadsReq, coldLeadsReq]
            );

            setLeadsCreatedYesterday({
                hot: hotLeads?.count?.toString() ?? '0',
                cold: coldLeads?.count?.toString() ?? '0'
            });
        } catch (err) {
            console.log(err);
            setLeadsCreatedYesterday({ hot: '0', cold: '0' });
        }
    };

    const getUsersByLeadSource = async () => {
        try {
            const dayDifference = dayEnd - dayStart;

            const fromDate = `${formatDate(
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart
            )}T00:00:00.000Z`;

            const toDate = `${formatDate(
                dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd
            )}T23:59:59.999Z`;

            const { data } = await axios.get(
                `/reports/marketing/leadsPerLeadSource?from_date=${fromDate}&to_date=${toDate}`,
                { headers }
            );

            setUsersByLeadSource(data);
        } catch (err) {
            console.log(err);
        }
    };

    const getDealsClosedByLeadSource = async () => {
        try {
            const dayDifference = dayEnd - dayStart;

            const fromDate = `${formatDate(
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart
            )}T00:00:00.000Z`;
            const toDate = `${formatDate(
                dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd
            )}T23:59:59.999Z`;

            const { data } = await axios.get(
                `/reports/marketing/dealsPerLeadSource?from_date=${fromDate}&to_date=${toDate}&status=${DealStatus.Completed}`
            );

            setDealsClosedByLeadSource(data);
        } catch (err) {
            console.log(err);
        }
    };

    const getDailyDealsCreated = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart
            );

            const toDate = formatDate(
                dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd
            );

            const { data: dealsCreated } = await axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&daily_data=true`
            );

            const deals = dealsCreated?.sort(
                (a, b) => new Date(a?.date) - new Date(b?.date)
            );

            let dateArray = [];
            let currentDate =
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart;

            const stopDate = dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd;

            while (currentDate <= stopDate) {
                dateArray.push(currentDate);
                currentDate = addDays(new Date(currentDate), 1);
            }

            let dealsCreatedTodayCount;

            const dailyDeals = dateArray?.map((date) => {
                const fromattedDate = formatDate(date);

                const formattedToday = formatDate(today);

                const data = deals?.find(
                    (item) => item?.date === fromattedDate
                );

                const dealsCreatedToday = deals?.find(
                    (deal) => deal?.date === formattedToday
                );

                console.log(dealsCreatedToday);

                dealsCreatedTodayCount =
                    dayDifference <= 0
                        ? dealsCreatedToday
                            ? dealsCreatedToday?.count?.toString()
                            : '0'
                        : undefined;

                return {
                    date: getDayName(new Date(data?.date ?? fromattedDate)),
                    count: data?.count ?? 0
                };
            });

            setDealsToday(
                dealsCreatedTodayCount ??
                    dailyDeals
                        ?.map((item) => item?.count)
                        ?.reduce((a, b) => a + b, 0)
            );

            setDailyDeals(dailyDeals);
        } catch (err) {
            console.log(err);
            setDailyDeals([]);
        }
    };

    const getDeals = async () => {
        try {
            const fromDate = `${formatDate(weekStart)}T00:00:00.000Z`;
            const toDate = `${formatDate(weekEnd)}T23:59:59.999Z`;

            const { data } = await axios.get(
                `/reports/main/deals?from_date=${fromDate}&to_date=${toDate}`
            );

            const deals = data?.Deals;

            const dealsCreatedToday = deals?.filter((deal) => {
                const createdAt = new Date(deal?.createdAt);
                createdAt.setHours(createdAt.getHours() + 4);

                const startDay = startOfDay(today).getTime();
                const endDay = endOfDay(today).getTime();

                return (
                    createdAt.getTime() >= startDay &&
                    createdAt.getTime() <= endDay
                );
            });

            // const dailyDeals = Array(7)
            //     .fill(0)
            //     .map((item, i) => {
            //         const dayStart = addDays(weekStart, i);

            //         const dayEnd = endOfDay(dayStart);

            //         const dealsOfDay = data?.Deals?.filter((lead) => {
            //             const createdAt = new Date(lead?.createdAt);

            //             return (
            //                 createdAt.getTime() >= dayStart.getTime() &&
            //                 createdAt.getTime() <= dayEnd.getTime()
            //             );
            //         });

            //         return dealsOfDay?.length ?? 0;
            //     });

            // setDealsToday(dealsCreatedToday?.length ?? 0);
            // setDailyDeals(dailyDeals);
        } catch (err) {
            console.log(err);
        }
    };

    const updateLabels = () => {
        const dayOfWeek = getDay(weekStart);

        switch (dayOfWeek) {
            case 0:
                return defaultLabels;
            case 1:
                return ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];
            case 2:
                return ['Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun', 'Mon'];
            case 3:
                return ['Wed', 'Thur', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue'];
            case 4:
                return ['Thur', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed'];
            case 5:
                return ['Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thur'];
            case 6:
                return ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri'];

            default:
                return defaultLabels;
        }
    };

    const getUniqueDailyVisitors = async () => {
        try {
            const dayDifference = dayEnd - dayStart;

            const fromDate = `${formatDate(
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart
            )}T00:00:00.000Z`;

            const toDate = `${formatDate(
                dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd
            )}T23:59:59.999Z`;

            const { data } = await axios.get(
                `/reports/marketing/analyticsReport?from_date=${fromDate}&to_date=${toDate}&daily=true`,
                {
                    headers
                }
            );

            let dateArray = [];
            let currentDate =
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart;

            const stopDate = dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd;

            while (currentDate <= stopDate) {
                dateArray.push(currentDate);
                currentDate = addDays(new Date(currentDate), 1);
            }

            const dailyVisitors = dateArray?.map((date) => {
                const fromattedDate = formatDate(date);

                const founditem = data?.find(
                    (item) => item?.dimension === fromattedDate
                );

                return {
                    date: getDayName(
                        new Date(founditem?.dimension ?? fromattedDate)
                    ),
                    count: founditem?.metricValue ?? 0
                };
            });

            setUniqueDailyVisitors(dailyVisitors);
        } catch (err) {
            console.log(err);
            setUniqueDailyVisitors([]);
        }
    };

    const getVisitors = async () => {
        try {
            const dayDifference = dayEnd - dayStart;

            const fromDate = `${formatDate(dayStart)}T00:00:00.000Z`;
            const toDate = `${formatDate(dayEnd)}T23:59:59.999Z`;

            const prevStartDate = `${formatDate(
                dayDifference <= 0 ? subDays(dayStart, 1) : weekStart
            )}T00:00:00.000Z`;

            const prevEndDate = `${formatDate(
                dayDifference <= 0 ? subDays(dayEnd, 1) : weekEnd
            )}T23:59:59.999Z`;

            const pageViewsYesterdayReq = axios.get(
                `/reports/marketing/analyticsReport?from_date=${prevStartDate}&to_date=${prevEndDate}&metric=screenPageViews&dimension=date`
            );

            const pageViewsTodayReq = axios.get(
                `/reports/marketing/analyticsReport?from_date=${fromDate}&to_date=${toDate}&metric=screenPageViews&dimension=date`,
                { headers }
            );

            const uniqueViewsYesterdayReq = axios.get(
                `/reports/marketing/analyticsReport?from_date=${prevStartDate}&to_date=${prevEndDate}&dimension=date`,
                { headers }
            );

            const uniqueViewsTodayReq = axios.get(
                `/reports/marketing/analyticsReport?from_date=${fromDate}&to_date=${toDate}&dimension=date`,
                { headers }
            );

            const bounceRateReg = axios.get(
                `/reports/marketing/analyticsReport?from_date=${fromDate}&to_date=${toDate}&metric=bounceRate&dimension=date`,
                { headers }
            );

            const newUsersReq = axios.get(
                `/reports/marketing/analyticsReport?from_date=${fromDate}&to_date=${toDate}&metric=newUsers&dimension=date`,
                { headers }
            );

            const totalUsersReq = axios.get(
                `/reports/marketing/analyticsReport?from_date=${fromDate}&to_date=${toDate}&metric=totalUsers&dimension=date`,
                { headers }
            );

            const [
                {
                    data: [pageViewsYesterday]
                },
                {
                    data: [pageViewsToday]
                },
                {
                    data: [uniqueViewsYesterday]
                },
                {
                    data: [uniqueViewsToday]
                },
                {
                    data: [bounceRate]
                },
                {
                    data: [newUsers]
                },
                {
                    data: [totalUsers]
                }
            ] = await Promise.all([
                pageViewsYesterdayReq,
                pageViewsTodayReq,
                uniqueViewsYesterdayReq,
                uniqueViewsTodayReq,
                bounceRateReg,
                newUsersReq,
                totalUsersReq
            ]);

            const percentOfNewVisitors =
                newUsers?.metricValue > 0 && totalUsers?.metricValue > 0
                    ? (
                          (newUsers?.metricValue / totalUsers?.metricValue) *
                          100
                      ).toFixed(2) + '%'
                    : '0%';

            setPageViewsYesterday(pageViewsYesterday);
            setPageViewsToday(pageViewsToday);

            setUniqueViewsYesterday(uniqueViewsYesterday);
            setUniqueViewsToday(uniqueViewsToday);

            setBounceRate(bounceRate);
            setPercentOfNewUSers(percentOfNewVisitors);
        } catch (err) {
            console.log(err);
        }
    };

    const getUniqueVisitorsByLeadSource = async () => {
        try {
            const { data } = await axios.get(
                `/reports/marketing/analyticsReport?from_date=${weekStart}&to_date=${weekEnd}&dimension=sessionSource&metric=newUsers`,
                { headers }
            );
            setUniqueVisistorsByLeadSource(data);
        } catch (err) {
            console.log(err);
        }
    };

    const exportPDF = () => {
        const doc = printRef.current;

        html2canvas(doc).then((canvas) => {
            const data = canvas.toDataURL('application/pdf');
            const pdf = new jsPDF();
            pdf.addImage(data, 'pdf', 0, 0);

            pdf.save('test.pdf');
        });
    };

    //   const fetchData = () => {
    //     getDeals();
    //     getDailyDealsCreated();
    //     getUsersByLeadSource();
    //     getDealsClosedByLeadSource();
    //     getUniqueDailyVisitors();
    //     getUniqueVisitorsByLeadSource();
    //     // leads(new)
    //     getLeadsCreatedToday();
    //     getLeadsCreatedYesterday();
    //     getDailyLeadsCreatedThisWeek();
    //     getVisitors();
    //   };

    useEffect(() => {
        updateFetchingStatus(); // make users know we're fetching data

        getDeals();
        getDailyDealsCreated();
        getUsersByLeadSource();
        getDealsClosedByLeadSource();
        getUniqueDailyVisitors();
        getUniqueVisitorsByLeadSource();
        // leads(new)
        getLeadsCreatedToday();
        getLeadsCreatedYesterday();
        getDailyLeadsCreatedThisWeek();
        getVisitors();
    }, [dayStart, dayEnd]);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    useEffect(() => {
        const timeout = dateError
            ? setTimeout(() => {
                  setDateError('');
              }, 3000)
            : undefined;

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [dateError]);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    return (
        <>
            <Head title="Marketing Dashboard" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page className="mb-3">
                                Marketing Dashboard
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                    <div className="d-flex justify-content-end align-items-end">
                        <div className="toggle-wrap nk-block-tools-toggle">
                            <a
                                href="#toggle"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setSmBtn(!smBtn);
                                }}
                                className="btn btn-icon btn-trigger toggle-expand me-n1"
                            >
                                <Icon name="menu-alt-r"></Icon>
                            </a>
                            <div
                                className={`toggle-expand-content ${
                                    smBtn ? 'expanded' : ''
                                }`}
                            >
                                <ul className="nk-block-tools g-1">
                                    <li>
                                        <div className="form-control-wrap">
                                            <button
                                                className="btn bg-primary text-white"
                                                onClick={() => {
                                                    const currentWeekStart =
                                                        startOfWeek(today);

                                                    setDayStart(
                                                        currentWeekStart
                                                    );
                                                    setDayEnd(endOfWeek(today));

                                                    const weekend = subDays(
                                                        currentWeekStart,
                                                        1
                                                    );

                                                    setWeekStart(
                                                        startOfWeek(weekend)
                                                    );
                                                    setWeekEnd(weekend);
                                                }}
                                            >
                                                Current Week
                                            </button>
                                        </div>
                                    </li>
                                    <li>
                                        <button
                                            className="btn bg-secondary text-white "
                                            onClick={async () => {
                                                const lastWeekEnd = subDays(
                                                    startOfWeek(today),
                                                    1
                                                );

                                                const lastWeekStart =
                                                    startOfWeek(lastWeekEnd);

                                                const endOf2WeeksAgo = subDays(
                                                    lastWeekStart,
                                                    1
                                                );
                                                const startOf2WeeksAgo =
                                                    startOfWeek(endOf2WeeksAgo);

                                                setDayStart(lastWeekStart);
                                                setDayEnd(lastWeekEnd);

                                                setWeekStart(startOf2WeeksAgo);
                                                setWeekEnd(endOf2WeeksAgo);
                                            }}
                                        >
                                            Last Week
                                        </button>
                                    </li>
                                    <li>
                                        <div className="form-group">
                                            <div className="form-control-wrap position-relative">
                                                <div className="text-danger fs-sm text-center position-absolute start-50 bottom-100 translate-middle-x w-100">
                                                    {dateError}
                                                </div>
                                                <div className="input-daterange date-picker-range input-group">
                                                    <DatePicker
                                                        selected={dayStart}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }} // prevent user from typing in date
                                                        onChange={(date) => {
                                                            const interval =
                                                                differenceInDays(
                                                                    dayEnd,
                                                                    date
                                                                );

                                                            if (
                                                                interval >= 14
                                                            ) {
                                                                setDateError(
                                                                    'Interval cannot be more than 14 days'
                                                                );
                                                                return;
                                                            }

                                                            setDayStart(date);
                                                            // setDayEnd(
                                                            //     addDays(date, 6)
                                                            // );
                                                            const weekEnd =
                                                                subDays(
                                                                    date,
                                                                    1
                                                                );
                                                            const weekStart =
                                                                subDays(
                                                                    weekEnd,
                                                                    interval - 1
                                                                );

                                                            setWeekStart(
                                                                weekStart
                                                            );
                                                            setWeekEnd(weekEnd);
                                                        }}
                                                        selectsStart
                                                        startDate={dayStart}
                                                        endDate={dayEnd}
                                                        maxDate={dayEnd}
                                                        wrapperClassName="start-m"
                                                        className="form-control"
                                                    />
                                                    <div className="input-group-addon">
                                                        TO
                                                    </div>
                                                    <DatePicker
                                                        selected={dayEnd}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }} // prevent user from typing in date
                                                        onChange={(date) => {
                                                            const interval =
                                                                differenceInDays(
                                                                    date,
                                                                    dayStart
                                                                );

                                                            if (
                                                                interval >= 14
                                                            ) {
                                                                setDateError(
                                                                    'Interval cannot be more than 14 days'
                                                                );
                                                                return;
                                                            }

                                                            // const dayStart =
                                                            //     subDays(
                                                            //         date,
                                                            //         6
                                                            //     );
                                                            setDayEnd(date);
                                                            // setDayStart(
                                                            //     dayStart
                                                            // );

                                                            const weekEnd =
                                                                subDays(
                                                                    dayStart,
                                                                    1
                                                                );

                                                            const weekStart =
                                                                subDays(
                                                                    weekEnd,
                                                                    interval - 1
                                                                );

                                                            setWeekStart(
                                                                weekStart
                                                            );
                                                            setWeekEnd(weekEnd);
                                                        }}
                                                        startDate={dayStart}
                                                        endDate={dayEnd}
                                                        selectsEnd
                                                        minDate={dayStart}
                                                        wrapperClassName="end-m"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </BlockHead>

                <Row className="g-gs">
                    <Col sm="6" xxl="4">
                        <RenderChart
                            chart={
                                <LineChart
                                    data={{
                                        ...lineChartData,
                                        labels:
                                            !uniqueDailyVisitors ||
                                            !uniqueDailyVisitors?.length
                                                ? updateLabels()
                                                : uniqueDailyVisitors?.map(
                                                      (item) => item?.date
                                                  ),
                                        datasets: lineChartData?.datasets?.map(
                                            (item) => ({
                                                ...item,
                                                label: '',
                                                borderColor:
                                                    'rgb(255, 99, 132)',
                                                data: uniqueDailyVisitors?.map(
                                                    (item) => item?.count
                                                ),
                                                backgroundColor: 'transparent',
                                                fill: false
                                            })
                                        ),
                                        gridLines: {
                                            x: { color: 'transparent' }
                                        }
                                    }}
                                />
                            }
                            chartTitle={'Site Unique Visitors'}
                            chartLabelTitles={
                                <>
                                    <li>
                                        <div className="title">
                                            <span
                                                className="dot dot-lg sq"
                                                style={{
                                                    background:
                                                        'rgb(255, 99, 132)'
                                                }}
                                            ></span>
                                            <span>Unique visitors</span>
                                        </div>
                                    </li>
                                </>
                            }
                        />
                    </Col>
                    <Col sm="6" xxl="4">
                        <RenderChart
                            chartTitle={'Inbound Leads'}
                            chart={
                                <LineChart
                                    data={{
                                        ...lineChartData,
                                        labels:
                                            dailyLeads && dailyLeads?.length
                                                ? dailyLeads?.map(
                                                      (item) => item?.date
                                                  )
                                                : updateLabels(),
                                        datasets: lineChartData?.datasets?.map(
                                            (item) => ({
                                                ...item,
                                                label: '',
                                                data: dailyLeads
                                                    ? dailyLeads?.map(
                                                          (item) => item?.count
                                                      )
                                                    : [0, 0, 0, 0, 0, 0, 0]
                                            })
                                        ),
                                        gridLines: {
                                            x: { color: 'transparent' }
                                        }
                                    }}
                                />
                            }
                            chartLabelTitles={
                                <>
                                    <li>
                                        <div className="title">
                                            <span
                                                className="dot dot-lg sq"
                                                style={{
                                                    background: '#9d72ff'
                                                }}
                                            ></span>
                                            <span>Inbound Leads</span>
                                        </div>
                                    </li>
                                </>
                            }
                        />
                    </Col>
                    <Col sm="12" xxl="4">
                        <RenderChart
                            chart={
                                <LineChart
                                    data={{
                                        ...lineChartData,
                                        labels:
                                            dailyDeals && dailyDeals?.length
                                                ? dailyDeals?.map(
                                                      (item) => item?.date
                                                  )
                                                : updateLabels(),
                                        datasets: lineChartData?.datasets?.map(
                                            (item) => ({
                                                ...item,
                                                backgroundColor:
                                                    'rgba(255, 255, 255, 0)',
                                                borderColor:
                                                    'rgb(25, 250, 133)',
                                                label: '',
                                                data: dailyDeals?.map(
                                                    (item) => item?.count
                                                )
                                            })
                                        ),
                                        gridLines: {
                                            x: { color: 'transparent' }
                                        }
                                    }}
                                />
                            }
                            chartTitle={'Deals Created This Week'}
                            chartLabelTitles={
                                <>
                                    <li>
                                        <div className="title">
                                            <span
                                                className="dot dot-lg sq"
                                                style={{
                                                    background:
                                                        'rgb(25, 250, 133)'
                                                }}
                                            ></span>
                                            <span>Deals Created</span>
                                        </div>
                                    </li>
                                </>
                            }
                        />
                    </Col>
                    <Col sm="6" xxl="3">
                        <DataCard
                            title={`Leads created ${
                                dayEnd - dayStart <= 0 ? 'Today' : 'This Period'
                            }`}
                            amount={
                                <Row
                                    className="d-flex justify-content-center align-items-between"
                                    style={{ width: '15rem' }}
                                >
                                    <Col sm="6">
                                        <h6>
                                            <span className="text-danger">
                                                Hot{' '}
                                            </span>
                                            :{' '}
                                            {leadsCreatedToday?.hot ?? (
                                                <Spinner size={'sm'} />
                                            )}
                                        </h6>
                                    </Col>
                                    <Col sm="6">
                                        <h6>
                                            <span className="text-info">
                                                {' '}
                                                Cold{' '}
                                            </span>
                                            :{' '}
                                            {leadsCreatedToday?.cold ?? (
                                                <Spinner size={'sm'} />
                                            )}
                                        </h6>
                                    </Col>
                                </Row>
                            }
                            showComparison={false}
                        />
                    </Col>
                    <Col sm="6" xxl="3">
                        <DataCard
                            title={`Leads created ${
                                dayEnd - dayStart <= 0
                                    ? 'Yesterday'
                                    : 'Last Period'
                            }`}
                            amount={
                                <Row
                                    className="d-flex justify-content-center align-items-between"
                                    style={{ width: '15rem' }}
                                >
                                    <Col sm="6">
                                        <h6>
                                            <span className="text-danger">
                                                Hot
                                            </span>{' '}
                                            :{' '}
                                            {leadsCreatedYesterday?.hot ?? (
                                                <Spinner size={'sm'} />
                                            )}
                                        </h6>
                                    </Col>
                                    <Col sm="6">
                                        <h6>
                                            <span className="text-info">
                                                Cold
                                            </span>{' '}
                                            :{' '}
                                            {leadsCreatedYesterday?.cold ?? (
                                                <Spinner size={'sm'} />
                                            )}
                                        </h6>
                                    </Col>
                                </Row>
                            }
                            showComparison={false}
                        />
                    </Col>
                    <Col sm="6" xxl="3">
                        <DataCard
                            title={
                                dayEnd - dayStart <= 0
                                    ? 'New Leads Today'
                                    : 'New Leads This Period'
                            }
                            amount={
                                <h6>
                                    {leadsCreatedToday?.hot ||
                                    leadsCreatedToday?.cold ? (
                                        Number(leadsCreatedToday?.hot ?? 0) +
                                        Number(leadsCreatedToday?.cold ?? 0)
                                    ) : (
                                        <Spinner size={'sm'} />
                                    )}
                                </h6>
                            }
                            showComparison={false}
                        />
                    </Col>
                    <Col sm="6" xxl="3">
                        <DataCard
                            title={`Deals Created ${
                                dayEnd - dayStart <= 0 ? 'Today' : 'This Period'
                            }`}
                            amount={
                                <h6>{dealsToday ?? <Spinner size={'sm'} />}</h6>
                            }
                            showComparison={false}
                        />
                    </Col>
                    <Col sm="6" xxl="4">
                        <DataCard
                            title={`Unique Visitors ${
                                dayEnd - dayStart <= 0 ? 'Today' : 'This Period'
                            }`}
                            amount={
                                uniqueViewsToday?.metricValue ?? (
                                    <Spinner size={'sm'} />
                                )
                            }
                            showComparison={false}
                        />
                    </Col>
                    <Col sm="6" xxl="4">
                        <DataCard
                            title={`Unique Visitors ${
                                dayEnd - dayStart <= 0
                                    ? 'Yesterday'
                                    : 'Last Period'
                            }`}
                            amount={
                                uniqueViewsYesterday?.metricValue ?? (
                                    <Spinner size={'sm'} />
                                )
                            }
                            showComparison={false}
                        />
                    </Col>
                    <Col sm="6" xxl="4">
                        <DataCard
                            title={`Page Views ${
                                dayEnd - dayStart <= 0 ? 'Today' : 'This Period'
                            }`}
                            amount={
                                pageViewsToday?.metricValue ?? (
                                    <Spinner size={'sm'} />
                                )
                            }
                            showComparison={false}
                        />
                    </Col>
                    <Col sm="6" xxl="4">
                        <DataCard
                            title={`Page Views ${
                                dayEnd - dayStart <= 0
                                    ? 'Yesterday'
                                    : 'Last Period'
                            }`}
                            amount={
                                pageViewsYesterday?.metricValue ?? (
                                    <Spinner size={'sm'} />
                                )
                            }
                            showComparison={false}
                        />
                    </Col>
                    <Col sm="6" xxl="4">
                        <DataCard
                            title={`Bounce Rate ${
                                dayEnd - dayStart <= 0 ? 'Today' : 'This Period'
                            }`}
                            amount={
                                bounceRate?.metricValue
                                    ? (bounceRate?.metricValue * 100).toFixed(
                                          2
                                      ) + '%'
                                    : '0%'
                            }
                            showComparison={false}
                        />
                    </Col>
                    <Col sm="6" xxl="4">
                        <DataCard
                            title={`% Of New Visitors ${
                                dayEnd - dayStart <= 0 ? '' : 'This Period'
                            }`}
                            amount={percentOfNewUSers ?? 0}
                            showComparison={false}
                        />
                    </Col>
                    <Col sm="6" xxl="4">
                        <div className=" deal-item-grid">
                            <div className="card-inner">
                                <div className="kanban-title-content mb-3">
                                    <h6
                                        className="title text-secondary"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Unique Visitors Per Lead Source
                                    </h6>
                                    <Badge
                                        className="text-dark"
                                        pill
                                        color="outline-light"
                                    >
                                        {uniqueVisitorsByLeadSource &&
                                        uniqueVisitorsByLeadSource?.length
                                            ? formatValue(
                                                  uniqueVisitorsByLeadSource
                                                      ?.slice(0, 10)
                                                      ?.map((item) =>
                                                          Number(
                                                              item?.metricValue
                                                          )
                                                      )
                                                      ?.reduce(
                                                          (a, b) => a + b,
                                                          0
                                                      )
                                              )
                                            : 0}
                                    </Badge>
                                </div>
                                <div>
                                    {uniqueVisitorsByLeadSource &&
                                    uniqueVisitorsByLeadSource?.length ? (
                                        uniqueVisitorsByLeadSource
                                            ?.slice(0, 10)
                                            ?.map((item, index) => (
                                                <div
                                                    key={
                                                        item?.dimension +
                                                        item?.metricValue +
                                                        index
                                                    }
                                                    className="page-visitor"
                                                >
                                                    <span>
                                                        {item?.dimension}
                                                    </span>
                                                    <span>
                                                        {item?.metricValue}
                                                    </span>
                                                </div>
                                            ))
                                    ) : (
                                        <div className="text-center p-2">
                                            No data to display
                                        </div>
                                    )}
                                </div>
                            </div>{' '}
                        </div>
                    </Col>
                    <Col sm="6" xxl="4">
                        <div className="deal-item-grid">
                            <div className="card-inner">
                                <div className="kanban-title-content mb-3">
                                    <h6
                                        className="title text-secondary"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Deals Closed Per Lead Source
                                    </h6>
                                    <Badge
                                        className="text-dark"
                                        pill
                                        color="outline-light"
                                    >
                                        {dealsClosedByLeadSource &&
                                        dealsClosedByLeadSource?.length
                                            ? formatValue(
                                                  dealsClosedByLeadSource
                                                      ?.slice(0, 10)
                                                      ?.map((item) =>
                                                          Number(item?.count)
                                                      )
                                                      ?.reduce(
                                                          (a, b) => a + b,
                                                          0
                                                      )
                                              )
                                            : 0}
                                    </Badge>
                                </div>
                                {!dealsClosedByLeadSource ||
                                !dealsClosedByLeadSource?.length ? (
                                    <div className="sales-funnel-cont">
                                        No data to display
                                    </div>
                                ) : (
                                    <>
                                        <PieChart
                                            data={{
                                                labels: dealsClosedByLeadSource
                                                    ?.slice(0, 5)
                                                    ?.map((item) => item?._id),
                                                datasets: [
                                                    {
                                                        data: dealsClosedByLeadSource
                                                            ?.slice(0, 5)
                                                            ?.map(
                                                                (item) =>
                                                                    item?.count
                                                            ),
                                                        borderColor: '#fff',
                                                        backgroundColor: [
                                                            '#854fff',
                                                            '#364a63',
                                                            '#bea2ff',
                                                            '#502BB8',
                                                            '#2F2F2F'
                                                        ]
                                                    }
                                                ]
                                            }}
                                            className={'bg-white p-2'}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col sm="12" xxl="4">
                        <div className="deal-item-grid">
                            <div className="card-inner">
                                <div className="kanban-title-content mb-3">
                                    <h6
                                        className="title text-secondary"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Leads Per Lead Source
                                    </h6>
                                    <Badge
                                        className="text-dark"
                                        pill
                                        color="outline-light"
                                    >
                                        {usersByLeadSource &&
                                        usersByLeadSource?.length
                                            ? formatValue(
                                                  usersByLeadSource
                                                      ?.slice(0, 10)
                                                      ?.map((deal) =>
                                                          Number(deal?.count)
                                                      )
                                                      ?.reduce(
                                                          (a, b) => a + b,
                                                          0
                                                      )
                                              )
                                            : 0}
                                    </Badge>
                                </div>
                                <div>
                                    {usersByLeadSource &&
                                    usersByLeadSource?.length ? (
                                        usersByLeadSource
                                            ?.slice(0, 10)
                                            ?.sort(
                                                (a, b) => b?.count - a?.count
                                            )
                                            ?.map((deal, index) => (
                                                <div
                                                    key={
                                                        deal?._id +
                                                        deal?.count +
                                                        index
                                                    }
                                                    className="page-visitor"
                                                >
                                                    <span>{deal?._id}</span>
                                                    <span>{deal?.count}</span>
                                                </div>
                                            ))
                                    ) : (
                                        <div className="text-center p-2">
                                            No data to display
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Content>
        </>
    );
}
