import * as types from '../types/pbx';

export const fetchCallReportList = (axios, signal, query) => async (dispatch) => {
  try {
    dispatch({ type: types.GET_CALL_REPORT_LIST_STARTED });
    const url = query ? `/yeastarPBX/call-report-list?${query}` : `/yeastarPBX/call-report-list?type=extcallstatistics&ext_id_list=1`;
    const res = await axios.get(url, { signal });
    dispatch({
      type: types.GET_CALL_REPORT_LIST,
      payload: res.data
    });
  } catch (error) {
    dispatch({ 
      type: types.GET_CALL_REPORT_LIST_FAILED,
      error: error
    });
  }
}