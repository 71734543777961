import Skeleton from "react-loading-skeleton";
import { Modal, ModalBody } from "reactstrap";

/**
 * LoaderModal component displays a modal with skeleton loaders.
 * This is used as a loading state for lazy-loaded components or data fetching.
 *
 * @param {Object} props - The properties object.
 * @param {number} [props.rowCount=5] - The number of skeleton rows to display.
 * @returns {JSX.Element} The LoaderModal component.
 */
const LoaderModal = ({ rowCount = 5 }) => {
  return (
    <Modal isOpen={true} aria-labelledby="loader-modal-title" aria-describedby="loader-modal-description">
      <ModalBody>
        <div id="loader-modal-description">
          <Skeleton style={{ height: "8rem" }} className="mb-4" />
          {Array.from({ length: rowCount }).map((_, i) => (
            <Skeleton key={`loader-modal-row-${i}`} className="mb-2" />
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LoaderModal;
