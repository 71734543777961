import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import {
  getPublicDocumentUrl,
  imageUrlRegex,
  pdfUrlRegex
} from '../../../../../utils/envConfig';
import Skeleton from 'react-loading-skeleton';
import { Icon } from '../../../../../components/Component';

const PDFViewer = lazy(() => import('./PdfViewer'));

/**
 * @typedef {Object} Size
 * @property {string|number} maxWidth - The width of the image.
 * @property {string|number} maxHeight - The height of the image.
 */

/**
 * DocumentPreview component to render an image or PDF document preview.
 *
 * @param {Object} props - The properties object.
 * @param {string|number} props.width - The width of the container.
 * @param {string|number} props.height - The height of the container.
 * @param {'contain'|'cover'|'fill'|'scale'|'none'} [props.imageLayout='fill'] - Layout for the image.
 * @param {string} props.documentUrl - The URL of the document to be previewed.
 * @param {Size} props.allowedImageSize - The Maximum image size allowed (width and height).
 * @param {Size} props.allowedPdfSize - The Maximum image size allowed (width and height).
 * @param {boolean} [props.noDeleteOption = false] - If true, will disable the "delete" option.
 * @param {Function} props.deleteHandler - The function to call when the "delete" document is clicked.
 * @returns {JSX.Element} The DocumentPreview component.
 */
const DocumentPreview = ({
  width,
  height,
  imageLayout,
  documentUrl,
  allowedImageSize,
  allowedPdfSize,
  noDeleteOption,
  deleteHandler,
  ...props
}) => {
  const isImage = useMemo(() => imageUrlRegex.test(documentUrl), [documentUrl]);
  const isPdf = useMemo(() => pdfUrlRegex.test(documentUrl), [documentUrl]);

  const fileSrc = useMemo(
    () => getPublicDocumentUrl(documentUrl) ?? null,
    [documentUrl]
  );

  const [imageState, setImageState] = useState({
    loaded: false,
    error: false,
    size: null
  });

  const [visible, setVisible] = useState(true);

  const handleImageLoaded = useCallback(
    (img) => {
      setImageState({
        loaded: true,
        error: false,
        size: {
          height: Math.min(img.height, allowedImageSize?.maxHeight ?? Infinity),
          width: Math.min(img.width, allowedImageSize?.maxWidth ?? Infinity)
        }
      });
    },
    [allowedImageSize?.maxHeight, allowedImageSize?.maxWidth]
  );

  const setImageErrorTrue = useCallback(() => {
    setImageState(prevState => ({ ...prevState, error: true }));
  }, []);

  useEffect(() => {
    if (documentUrl && isImage) {
      const img = new Image();
      img.src = fileSrc;

      // In case imageSrc changed after loading or error
      setImageState(prevState => ({ ...prevState, loaded: false, error: false }));

      // Subscribing on image load and error
      img.addEventListener('load', () => handleImageLoaded(img));
      img.addEventListener('error', setImageErrorTrue);

      return () => {
        img.removeEventListener('load', handleImageLoaded);
        img.removeEventListener('error', setImageErrorTrue);
      };
    }
  }, [documentUrl, fileSrc, isImage, setImageErrorTrue, handleImageLoaded]);

  const handleDeleteDocument = useCallback((e) => {
    e.preventDefault();
    setVisible(false);
    if (deleteHandler && typeof deleteHandler === 'function') {
      deleteHandler();
    }
  }, [deleteHandler]);

  const renderImage = () => {
    if (!imageState.loaded && !imageState.error) {
      return <Skeleton width={width ?? 300} height={height ?? 200} className="d-flex" />
    }

    if (imageState.error) {
      return null;
    }

    return (
      <>
        <img
          src={fileSrc}
          alt="transaction-document"
          className={`object-fit-${imageLayout} rounded`}
        />
        {renderControls()}
      </>
    )
  };

  const renderControls = () => (
    <div
      className='d-flex'
      style={{
        gap: '.5rem',
        zIndex: 10,
        position: 'absolute',
        top: '10px',
        right: '5px'
      }}
    >
      <a href={fileSrc} download className="btn btn-primary">
        <Icon name="download" />
        <span>Download</span>
      </a>
      {!noDeleteOption && (
        <button className="btn btn-danger" onClick={handleDeleteDocument}>
          <Icon name="trash" />
          <span>Delete</span>
        </button>
      )}
    </div>
  );

  if (isImage) {
    return (
      <div
        style={{
          width: imageState?.size?.width,
          height: imageState?.size?.height,
          maxWidth: imageState?.size?.width ?? 500,
          maxHeight: imageState?.size?.height ?? 500,
          resize: 'both',
          overflow: 'auto',
          position: 'relative',
          display: visible ? 'block' : 'none'
        }}
        {...props}
      >
        {renderImage()}
      </div>
    );
  }

  if (isPdf) {
    return (
      <div
        style={{
          width: allowedPdfSize?.maxWidth ?? 500,
          height: allowedPdfSize?.maxHeight ?? 500,
          overflow: 'auto',
          position: 'relative',
          display: visible ? 'block' : 'none'
        }}
        {...props}
      >
        <Suspense fallback={<Skeleton width={width ?? 300} height={height ?? 200} className="d-flex" />}>
          <PDFViewer filePath={fileSrc} />
        </Suspense>
        {renderControls()}
      </div>
    );
  }
};

export default DocumentPreview;
