import React from "react";
import { Link } from "react-router-dom";
import DealCard from "./DealCard";
import CardShimmer from "./CardShimmer";
import { Badge } from "reactstrap";

export default function DealsClosedGrid({
    deals = [],
    fetching = false,
    title = "Deals Closed This Month",
}) {
    return (
        <div className="middle-section deal-item-grid pb-2">
            <div className="right-section bg-white card-inner h-full">
                <div className="kanban-title-content">
                    <h6 className="title">{title}</h6>
                    <Badge className="text-dark" pill color="outline-light">
                        {deals?.length ?? 0}
                    </Badge>
                </div>

                <div className="deal-card pt-3">
                    {fetching ? (
                        <CardShimmer numberOfCards={5} />
                    ) : deals?.length ? (
                        deals?.map((deal) => (
                            <DealCard key={deal?.id ?? deal?._id} deal={deal} />
                        ))
                    ) : (
                        <div className="flex-1 w-full flex gap-1 items-center justify-center text-neutral-500">
                            <p>No deals closed this month</p>
                            <Link
                                to={"/list-deals"}
                                className="underline text-sm text-blue-500"
                            >
                                Close A Deal
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
