import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockTitle,
  Icon,
  Sidebar,
  UserAvatar,
} from "../../../components/Component";
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchDeal } from "../../../redux/actions/deal";
import { useSelector } from "react-redux";
import {
  DealCategory,
  DealClientType,
  DealType,
  formatValue,
  getImage,
} from "../../../utils/envConfig";
import { calcTime, findUpper, getDateStructured } from "../../../utils/Utils";
import { intervalToDuration } from "date-fns";
import { toast } from "react-toastify";

const calculateDealAmount = (deal) => {
  switch (deal?.category) {
    case DealCategory.SecondaryProperty:
      const isInternal = deal?.sellerClientType === DealClientType.Alba;

      const property = deal?.property_list;
      const externalProperty = deal?.external_listing_property;

      return isInternal
        ? formatValue(
            property?.askingPrice ??
              property?.oneCheqPrice ??
              property?.twoCheqPrice ??
              property?.fourCheqPrice ??
              property?.sixCheqPrice ??
              property?.twelveCheqprice ??
              0
          )
        : formatValue(externalProperty?.agreedSalesPrice ?? 0);

    default:
      return 0;
  }
};
const calculateDealCommission = (deal) => {
  switch (deal?.category) {
    case DealCategory.SecondaryProperty:
      const isInternal = deal?.sellerClientType === DealClientType.Alba;

      const property = deal?.property_list;
      const externalProperty = deal?.external_listing_property;

      return isInternal
        ? property?.commission
          ? formatValue(
              Math.floor(
                ((property?.askingPrice ??
                  property?.oneCheqPrice ??
                  property?.twoCheqPrice ??
                  property?.fourCheqPrice ??
                  property?.sixCheqPrice ??
                  property?.twelveCheqprice ??
                  0) *
                  Number(property?.commission ?? "0")) /
                  100
              )
            )
          : 0
        : externalProperty?.agreedCommission
        ? formatValue(
            Math.floor(
              externalProperty?.agreedSalesPrice *
                ((Number(externalProperty?.agreedCommission) ?? 0) / 100)
            )
          )
        : 0;

    default:
      return 0;
  }
};
const AddDealStepThree = (props) => {
  const today = new Date();

  const [sideBar, setSidebar] = useState(false);

  const [creatorImage, setCreatorimage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [modalText, setModalText] = useState("");
  const [note, setNote] = useState("");

  const [openApproveModal, setOpendApproveModal] = useState(false);
  const [approve, setApprove] = useState(false);

  const newDeal = useSelector((state) => state?.deal?.deal);

  const axios = useAxiosPrivate();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const toggle = () => {
    setSidebar(!sideBar);
  };

  const items = [
    {
      title: "AED 4,000,000",
      date: "March 1, 2022",
      description:
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      title: "AED 6,000,000",
      date: "February 1, 2022",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      title: "AED 1,000,000",
      date: "January 1, 2022",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  ];

  const handleApprove = (approved) => async () => {
    try {
      setLoading(true);

      if (!approve && (!note.length || note.split(" ").length < 3)) {
        toast.error(
          note.split(" ").length < 3
            ? "Note too short. Minimum 3 words"
            : "Please add a reason for rejecting deal"
        );

        return;
      }

      if (approve && note.length > 0 && note.split(" ").length < 3) {
        toast.error("Note should be at least 3 words.");
        return;
      }

      const noteParams = {
        description: note,
        deal_id: newDeal?.id,
      };

      note &&
        note.length &&
        note.split(" ").length >= 10 &&
        (await axios.post("/notes", noteParams));

      const { data } = await axios.post(`/deals/progress/${newDeal?.id}`, {
        approved,
      });
      navigate(
        newDeal?.category === DealType.Listing
          ? `inventory-details/${newDeal.id}/view-inventory`
          : `deal-details/${newDeal.id}`
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchCreatorImage = async () => {
    try {
      if (!newDeal?.created_by?.photo) {
        return;
      }

      const image = await getImage(newDeal?.created_by?.photo, axios);
      setCreatorimage(image?.signedRequest ?? null);
    } catch (err) {}
  };

  useEffect(() => {
    const deal = localStorage.getItem("new_deal");

    if (deal) {
      const newDeal = JSON.parse(deal);

      newDeal?.created_by?.photo && fetchCreatorImage();

      dispatch(fetchDeal(axios, newDeal?.id));
    }
  }, []);

  const RenderBuyerAndSellerInfo = () => {
    const buyerClientType = newDeal?.buyerClientType;

    const sellerClientType = newDeal?.sellerclientType;

    return (
      <>
        {buyerClientType === DealClientType.Alba ? (
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">Buyer/Tenant Info</h6>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">First Name</span>
                  <span className="data-value">
                    {newDeal?.buyerInternalUser?.first_name ??
                      newDeal?.buyerInternalUser?.firstName ??
                      "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Last Name</span>
                  <span className="data-value">
                    {newDeal?.buyerInternalUser?.last_name ??
                      newDeal?.buyerInternalUser?.lastName ??
                      "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Phone</span>
                  <span className="data-value">
                    {newDeal?.buyerInternalUser?.phone ?? "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Email</span>
                  <span className="data-value">
                    {newDeal?.buyerInternalUser?.email ?? "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Lead Source</span>
                  <span className="data-value">
                    {newDeal?.buyerInternalUser?.lead_source ??
                      newDeal?.buyerInternalUser?.leadSource ??
                      "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>{" "}
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Status</span>
                  <span className="data-value">
                    <Badge className="bg-success">
                      {newDeal?.buyerInternalUser?.lead_status ??
                        newDeal?.buyerInternalUser?.leadStatus}
                    </Badge>
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col>
                <div className="data-col">
                  <span className="data-label">Client Type</span>
                  <span className="data-value">{buyerClientType ?? "N/A"}</span>
                </div>
                <div className="data-col data-col-end"></div>{" "}
              </Col>
            </div>
          </div>
        ) : buyerClientType === DealClientType.External ? (
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">Buyer/Tenant Info</h6>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Agency/Agent Name</span>
                  <span className="data-value">
                    {newDeal?.buyerExternalUser?.agency_name ??
                      newDeal?.buyerExternalAgentName ??
                      "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Client Name</span>
                  <span className="data-value">
                    {newDeal?.buyerExternalClientName ?? "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Agency/Agent Phone</span>
                  <span className="data-value">
                    {newDeal?.buyerExternalUser?.agencyPhone ??
                      newDeal?.buyerExternalAgentPhone ??
                      "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Email</span>
                  <span className="data-value">
                    {newDeal?.buyerExternalUser?.email ?? "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col>
                <div className="data-col">
                  <span className="data-label">Client Type</span>
                  <span className="data-value">{buyerClientType ?? "N/A"}</span>
                </div>
                <div className="data-col data-col-end"></div>{" "}
              </Col>
            </div>
          </div>
        ) : null}

        {sellerClientType === DealClientType.Alba ? (
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">Seller/Landlord Info</h6>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">First Name</span>
                  <span className="data-value">
                    {newDeal?.sellerInternalUser?.first_name ??
                      newDeal?.sellerInternalUser?.firstName ??
                      "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Last Name</span>
                  <span className="data-value">
                    {newDeal?.sellerInternalUser?.last_name ??
                      newDeal?.sellerInternalUser?.lastName ??
                      "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Phone</span>
                  <span className="data-value">
                    {newDeal?.sellerInternalUser?.phone ?? "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Email</span>
                  <span className="data-value">
                    {newDeal?.sellerInternalUser?.email ?? "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Lead Source</span>
                  <span className="data-value">
                    {newDeal?.sellerInternalUser?.lead_source ??
                      newDeal?.sellerInternalUser?.leadSource ??
                      "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>{" "}
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Status</span>
                  <span className="data-value">
                    <Badge className="bg-success">
                      {newDeal?.sellerInternalUser?.lead_status ??
                        newDeal?.sellerInternalUser?.leadStatus}
                    </Badge>
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col>
                <div className="data-col">
                  <span className="data-label">Client Type</span>
                  <span className="data-value">
                    {sellerClientType ?? "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>{" "}
              </Col>
            </div>
          </div>
        ) : sellerClientType === DealClientType.External ? (
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">Seller/Landlord Info</h6>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Agency/Agent Name</span>
                  <span className="data-value">
                    {newDeal?.sellerExternalUser?.agency_name ??
                      newDeal?.sellerExternalAgentName ??
                      "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Client Name</span>
                  <span className="data-value">
                    {newDeal?.sellerExternalClientName ?? "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Agency/Agent Phone</span>
                  <span className="data-value">
                    {newDeal?.sellerExternalUser?.agencyPhone ??
                      newDeal?.sellerExternalAgentPhone ??
                      "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Email</span>
                  <span className="data-value">
                    {newDeal?.sellerExternalUser?.email ?? "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col>
                <div className="data-col">
                  <span className="data-label">Client Type</span>
                  <span className="data-value">
                    {sellerClientType ?? "N/A"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>{" "}
              </Col>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const RenderClientInfo = () => {
    return (
      <>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Client Info</h6>
          </div>
          <div className="data-item">
            <Col sm="6">
              <div className="data-col">
                <span className="data-label">First Name</span>
                <span className="data-value">{newDeal?.user?.first_name}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </Col>
            <Col sm="6">
              <div className="data-col">
                <span className="data-label">Last Name</span>
                <span className="data-value">{newDeal?.user?.last_name}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </Col>
          </div>
          <div className="data-item">
            <Col sm="6">
              <div className="data-col">
                <span className="data-label">Phone</span>
                <span className="data-value">{newDeal?.user?.phone}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </Col>
            <Col sm="6">
              <div className="data-col">
                <span className="data-label">Email</span>
                <span className="data-value">{newDeal?.user?.email}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </Col>
          </div>
          <div className="data-item">
            <Col sm="6">
              <div className="data-col">
                <span className="data-label">Lead Source</span>
                <span className="data-value">{newDeal?.user?.lead_source}</span>
              </div>
              <div className="data-col data-col-end"></div>{" "}
            </Col>
            <Col sm="6">
              <div className="data-col">
                <span className="data-label">Status</span>
                <span className="data-value">
                  <Badge className="bg-success">
                    {newDeal?.user?.lead_status}
                  </Badge>
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </Col>
          </div>
        </div>
      </>
    );
  };

  const RenderSecondaryPropertyDetails = () => {
    const property = newDeal?.property_list;

    const externalProperty = newDeal?.external_listing_property;

    const [image, setImage] = useState("/img/private-property.svg");

    const fetchImage = async () => {
      try {
        const imagePath = property?.images?.length
          ? typeof property?.images[0] === "string"
            ? property?.images[0]
            : property?.images[0]?.original
          : null;

        if (!imagePath) {
          return;
        }

        const image = await getImage(imagePath, axios);
        setImage(image?.signedRequest);
      } catch (err) {
        console.log(err);
      }
    };

    useEffect(() => {
      fetchImage();

      return () => {};
    }, []);

    return newDeal?.sellerclientType === DealClientType.Alba ? (
      <Card className="card-bordered">
        <Row>
          <Col sm="3" className="relative">
            <CardImg
              src={image}
              alt=""
              width="100%"
              height="100%"
              className="absolute"
              style={{
                objectFit: "cover",
              }}
            />
          </Col>

          <Col sm="9">
            <CardBody className="card-inner">
              <CardTitle tag="h6">
                <span className="text-secondary">
                  {property?.propertyTitle ?? property?.property_title ?? "N/A"}
                </span>
              </CardTitle>
              <CardTitle tag="h6">
                <span className="text-primary">
                  AED{" "}
                  {formatValue(
                    property?.askingPrice ??
                      property?.oneCheqPrice ??
                      property?.twoCheqPrice ??
                      property?.fourCheqPrice ??
                      property?.sixCheqPrice ??
                      property?.twelveCheqprice ??
                      0
                  )}
                </span>
              </CardTitle>
              <CardTitle tag="h5"></CardTitle>
              <Icon name="location"></Icon>{" "}
              <span>
                {property?.communityName ??
                  property?.community?.community ??
                  "N/A"}
              </span>
              <CardText>
                <div className="product-meta">
                  <ul className="d-flex g-3 gx-5">
                    <li>
                      <div className="fs-14px text-muted">Type</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.propertyType?.propertyType ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">Listing Type</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.listingType ??
                          property?.listing_type ??
                          "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">Beds</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.beds ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">Baths</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.baths ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">Area</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.size ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">ID</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.referNo ?? property?.refer_no ?? "N/A"}
                      </div>
                    </li>
                  </ul>
                </div>
              </CardText>
            </CardBody>
          </Col>
        </Row>
      </Card>
    ) : newDeal?.sellerclientType === DealClientType.External ? (
      <Card className="card-bordered">
        <Row>
          <Col sm="3" className="relative">
            <CardImg
              src={image}
              alt=""
              width="100%"
              height="100%"
              className="absolute"
              style={{
                objectFit: "cover",
              }}
            />
          </Col>

          <Col sm="9">
            <CardBody className="card-inner">
              <CardTitle tag="h6">
                <span className="text-secondary">{"External Property"}</span>
              </CardTitle>
              <CardTitle tag="h6">
                <span className="text-primary">
                  AED {calculateDealAmount(newDeal)}
                </span>
              </CardTitle>
              <CardTitle tag="h5"></CardTitle>
              <Icon name="location"></Icon>{" "}
              <span>
                {externalProperty?.communityName ??
                  externalProperty?.community?.community ??
                  "N/A"}
              </span>
              <CardText>
                <div className="product-meta">
                  <ul className="d-flex g-3 gx-5">
                    <li>
                      <div className="fs-14px text-muted">Type</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {externalProperty?.propertyType ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">Beds</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {externalProperty?.beds ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">Baths</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {externalProperty?.baths ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">Area</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {externalProperty?.size ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">ID</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {externalProperty?.referNo ??
                          externalProperty?.refer_no ??
                          "N/A"}
                      </div>
                    </li>
                  </ul>
                </div>
              </CardText>
            </CardBody>
          </Col>
        </Row>
      </Card>
    ) : null;
  };

  const RenderListingPropertyDetails = () => {
    const property = newDeal?.new_listing_request;

    return (
      <Card className="card-bordered">
        <Row>
          <Col sm="3" className="relative">
            <CardImg
              src={"/img/private-property.svg"}
              alt=""
              width="100%"
              height="100%"
              className="absolute"
              style={{
                objectFit: "cover",
              }}
            />
          </Col>

          <Col sm="9">
            <CardBody className="card-inner">
              <CardTitle tag="h6">
                <span className="text-secondary">{"New Listing Request"}</span>
              </CardTitle>
              <CardTitle tag="h6">
                <span className="text-primary">
                  AED {formatValue(property?.price ?? 0)}
                </span>
              </CardTitle>
              <CardTitle tag="h5"></CardTitle>
              <Icon name="location"></Icon>{" "}
              <span>
                {property?.communityName ??
                  property?.community?.community ??
                  "N/A"}
              </span>
              <CardText>
                <div className="product-meta">
                  <ul className="d-flex g-3 gx-5">
                    <li>
                      <div className="fs-14px text-muted">Type</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.type ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">
                          Property Type
                      </div>
                      <div className="fs-16px fw-bold text-secondary">
                      {property?.property_type?.propertyType ?? "N/A"}
                      </div>
                  </li>
                    <li>
                      <div className="fs-14px text-muted">Listing Type</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.listingType ??
                          property?.listing_type ??
                          "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">Beds</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.beds ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">Baths</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.baths ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">Area</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.size ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">ID</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.referNo ?? property?.refer_no ?? "N/A"}
                      </div>
                    </li>
                  </ul>
                </div>
              </CardText>
            </CardBody>
          </Col>
        </Row>
      </Card>
    );
  };

  const RenderOffplanPropertyDetails = () => {
    const property = newDeal?.new_listing_offplan;

    return (
      <Card className="card-bordered">
        <Row>
          <Col sm="3" className="relative">
            <CardImg
              src={"/img/private-property.svg"}
              alt=""
              width="100%"
              height="100%"
              className="absolute"
              style={{
                objectFit: "cover",
              }}
            />
          </Col>

          <Col sm="9">
            <CardBody className="card-inner">
              <CardTitle tag="h6">
                <span className="text-secondary">{"New Listing Offplan"}</span>
              </CardTitle>
              <CardTitle tag="h6">
                <span className="text-primary">
                  AED {formatValue(property?.agreedSalesPrice ?? 0)}
                </span>
              </CardTitle>
              <CardTitle tag="h5"></CardTitle>
              <Icon name="location"></Icon>{" "}
              <span>
                {property?.communityName ??
                  property?.community?.community ??
                  "N/A"}
              </span>
              <CardText>
                <div className="product-meta">
                  <ul className="d-flex g-3 gx-5">
                    <li>
                      <div className="fs-14px text-muted">Type</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {"Off-Plan"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">Listing Type</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.propertyType}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">Beds</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.beds ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">Baths</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.baths ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">Area</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.size ?? "N/A"}
                      </div>
                    </li>
                    <li>
                      <div className="fs-14px text-muted">ID</div>
                      <div className="fs-16px fw-bold text-secondary">
                        {property?.unitId ?? "N/A"}
                      </div>
                    </li>
                  </ul>
                </div>
              </CardText>
            </CardBody>
          </Col>
        </Row>
      </Card>
    );
  };

  return (
    <div>
      <Row className="g-gs mb-4 ">
        <div className="d-flex justify-content-between align-items-end">
          <Col sm="12">
            <div className="d-flex justify-content-end">
              {" "}
              {/* Added container div with justify-content-end */}
              <Button
                className="bg-primary me-2"
                onClick={() => {
                  setModalText("Add a note.");
                  setApprove(true);
                  setOpendApproveModal(true);
                }}
              >
                Approve
              </Button>
              <Button
                className="bg-secondary"
                onClick={() => {
                  setModalText("Add reason for rejecting deal.");
                  setApprove(false);
                  setOpendApproveModal(true);
                }}
              >
                Reject
              </Button>
            </div>
          </Col>
        </div>
      </Row>
      <Block>
        <Card className="card-bordered">
          <div className="card-aside-wrap" id="">
            <div className="card-content">
              <div className="card-inner">
                {newDeal?.category === DealCategory.SecondaryProperty ? (
                  <RenderBuyerAndSellerInfo />
                ) : newDeal?.category === DealCategory.ListingAcquired ||
                  newDeal?.category === DealCategory?.PrimaryOffplan ? (
                  <RenderClientInfo />
                ) : null}

                <Block className="mt-5">
                  <BlockHead size="sm">
                    <BlockBetween>
                      <BlockTitle tag="h5">Property Details</BlockTitle>
                    </BlockBetween>
                  </BlockHead>
                </Block>

                {newDeal?.category === DealCategory.SecondaryProperty ? (
                  <RenderSecondaryPropertyDetails />
                ) : newDeal?.category === DealCategory.ListingAcquired ? (
                  <RenderListingPropertyDetails />
                ) : newDeal?.category === DealCategory.PrimaryOffplan ? (
                  <RenderOffplanPropertyDetails />
                ) : null}
              </div>
            </div>
            <Sidebar toggleState={sideBar}>
              <div className="card-inner">
                <div className="user-card user-card-s2 mt-5 mt-xxl-0">
                  <UserAvatar
                    className="lg"
                    theme="primary"
                    text={findUpper(
                      `${newDeal?.created_by?.first_name} ${newDeal?.created_by?.last_name}`
                    )}
                    image={creatorImage}
                  />
                  <div className="user-info">
                    <Badge
                      tag="div"
                      className="ucap"
                      pill
                      color="outline-light"
                    >
                      {newDeal?.created_by?.role}
                    </Badge>
                    <h5>{`${
                      newDeal?.created_by?.first_name ??
                      newDeal?.created_by?.firstName ??
                      ""
                    } ${
                      newDeal?.created_by?.last_name ??
                      newDeal?.created_by?.lastName ??
                      ""
                    }`}</h5>
                    <span className="sub-text">
                      {newDeal?.created_by?.email ?? ""}
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-inner card-inner-sm">
                <ul className="btn-toolbar justify-center gx-1">
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        window.open(`mailto:${newDeal?.created_by?.email}`);
                      }}
                      className="btn-trigger btn-icon bg-white border-0"
                    >
                      <Icon name="shield-off"></Icon>
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="#mail"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      className="btn-trigger btn-icon bg-white border-0"
                    >
                      <Icon name="mail"></Icon>
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="#download"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      className="btn-trigger btn-icon bg-white border-0"
                    >
                      <Icon name="download-cloud"></Icon>
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="#bookmark"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      className="btn-trigger btn-icon bg-white border-0"
                    >
                      <Icon name="bookmark"></Icon>
                    </Button>
                  </li>
                  <li>
                    <Button
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      className="btn-trigger btn-icon bg-white border-0"
                    >
                      <Icon name="na"></Icon>
                    </Button>
                  </li>
                </ul>
              </div>
              <div className="card-inner">
                <div className="overline-title-alt mb-2">In Account</div>
                <div className="profile-balance">
                  <div className="profile-balance-group gx-4">
                    <div className="profile-balance-sub">
                      <div className="profile-balance-amount">
                        <div className="number">
                          <asmall className="currency currency-usd">AED</asmall>{" "}
                          {calculateDealAmount(newDeal)}
                        </div>
                      </div>
                      <div className="profile-balance-subtitle">
                        Deal Amount
                      </div>
                    </div>
                    <div className="profile-balance-sub">
                      <span className="profile-balance-plus text-soft">
                        <Icon className="ni-plus"></Icon>
                      </span>
                      <div className="profile-balance-amount">
                        <div className="number">
                          {calculateDealCommission(newDeal)}
                        </div>
                      </div>
                      <div className="profile-balance-subtitle">
                        Commission Earned
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-inner">
                <Row className="text-center">
                  <Col size="4">
                    <div className="profile-stats">
                      <span className="amount">
                        {newDeal?.property_list
                          ? newDeal?.property_list?.createdAt
                            ? intervalToDuration({
                                start: new Date(
                                  newDeal?.property_list?.createdAt
                                ),
                                end: today,
                              }).days
                            : "N/A"
                          : 0}{" "}
                        days
                      </span>
                      <span className="sub-text">Property Time in Stock</span>
                    </div>
                  </Col>
                  <Col size="4">
                    <div className="profile-stats">
                      <span className="amount">
                        20 Days
                        {/* need to know criteria for calculating time to close deal */}
                      </span>
                      <span className="sub-text">Time to Close Deal</span>
                    </div>
                  </Col>
                  <Col size="4">
                    <div className="profile-stats">
                      <span className="amount">
                        {newDeal?.property_list
                          ? newDeal?.property_list?.commission ?? "N/A"
                          : newDeal?.external_listing_property
                          ? newDeal?.external_listing_property?.agreedCommission
                          : null}
                      </span>
                      <span className="sub-text">Commission Percentage</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="card-inner">
                <h6 className="overline-title-alt mb-2">Additional</h6>
                <Row className="g-3">
                  <Col sm="6">
                    <span className="sub-text">Assigned Agent</span>
                    <span>
                      {newDeal?.property_list
                        ? newDeal?.property_list?.agent
                          ? `${newDeal?.property_list?.agent?.user?.first_name} ${newDeal?.property_list?.agent?.user?.last_name}`
                          : "N/A"
                        : newDeal?.external_listing_property
                        ? newDeal?.sellerExternalAgentName
                        : null}
                    </span>
                  </Col>
                  <Col sm="6">
                    <span className="sub-text">Deal Last Modified:</span>
                    <span>
                      {getDateStructured(
                        new Date(newDeal?.updatedAt ?? newDeal?.creation_date)
                      )}{" "}
                      {calcTime(
                        new Date(newDeal?.updatedAt ?? newDeal?.creation_date)
                      )}
                    </span>
                  </Col>
                  <Col sm="6">
                    <span className="sub-text">Deal Status:</span>
                    <span>{newDeal?.status}</span>
                  </Col>
                  <Col sm="6">
                    <span className="sub-text">Deal Created At:</span>
                    <span>
                      {getDateStructured(new Date(newDeal?.creation_date))}{" "}
                      {calcTime(new Date(newDeal?.creation_date))}
                    </span>
                  </Col>
                </Row>
              </div>
              <React.Fragment>
                <div className="card-inner border-bottom">
                  <div className="card-title-group">
                    <CardTitle>
                      <h6 className="title">Prices Changes</h6>
                    </CardTitle>
                    <div className="card-tools">
                      <a
                        href="#viewall"
                        className="link"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        View All
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-inner">
                  <div className="timeline">
                    <ul className="timeline-list">
                      {items.map((item) => {
                        return (
                          <li className="timeline-item" key={item.title}>
                            <div className="timeline-status bg-primary"></div>
                            <div className="timeline-date">{item.date}</div>
                            <div className="timeline-data">
                              <h6 className="timeline-title">{item.title}</h6>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </React.Fragment>
            </Sidebar>
            {sideBar && (
              <div className="toggle-overlay" onClick={() => toggle()}></div>
            )}
          </div>
        </Card>

        <Modal
          isOpen={openApproveModal}
          toggle={(e) => setOpendApproveModal(false)}
        >
          <ModalHeader>
            {!approve && <Icon name={"alert-fill"} />}
            <span>{modalText}</span>
          </ModalHeader>
          <ModalBody>
            <Input
              type="textarea"
              value={note}
              onChange={(e) => setNote(e.target?.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              // color={'gr'}
              onClick={() => setOpendApproveModal(false)}
              className="ml-4"
            >
              Cancel
            </Button>
            <Button
              color={approve ? "primary" : "danger"}
              onClick={handleApprove(approve)}
            >
              {loading ? (
                <>
                  <Spinner /> Please Wait
                </>
              ) : approve ? (
                "Approve"
              ) : (
                "Reject"
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </Block>
    </div>
  );
};
export default AddDealStepThree;
