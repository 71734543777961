import React, { useEffect, useState } from "react";
import { getImage } from "../../../utils/envConfig";
import { Card, CardBody, CardImg, CardText } from "reactstrap";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

export default function ListingCard ( {
    amount,
    title,
    subText,
    amountText,
    onClick = ( e ) => { },
    image = "",
} )
{
    const [ listingImage, setListingImage ] = useState( "/img/bg1.png" );

    const axios = useAxiosPrivate();

    const fetchImage = async () =>
    {
        try
        {
            if ( !image )
            {
                return;
            }

            const data = await getImage( image, axios );

            setListingImage( data?.signedRequest );
        } catch ( err )
        {
            console.log( err );
        }
    };

    useEffect( () =>
    {
        image && fetchImage();
    }, [] );

    // const
    return (
        <div className="listing-card border-bottom pb-2">
            <Card className="listing-card-cont" onClick={ onClick }>
                <CardBody className="listing-card-body">
                    <div className="listing-data-group">
                        <div className="amount">{ amount }</div>
                        <span>{ amountText }</span>
                    </div>
                    <CardImg
                        src={ listingImage }
                    // style={{ width: 50, height: 50 }}
                    />
                    <CardText className="listing-card-content">
                        <div className="listing-title">{ title }</div>
                        <small>{ subText }</small>
                    </CardText>
                </CardBody>
            </Card>
        </div>
    );
}
