import { toast } from "react-toastify";
import * as types from "../types";
import { CloseButton } from "reactstrap";

export const getFeeds = (axios, params) => async (dispatch) => {
    try {
        const { data: feeds } = await axios.get(
            `/reports/main/live-feed?from_date=${params?.fromDate}&to_date=${
                params?.toDate
            }${params?.createdBy ? `&createdBy=${params?.createdBy}` : ""}`
        );

        dispatch({
            type: types.GET_FEEDS,
            payload: feeds,
        });
    } catch (err) {
        console.log(err);
    }
};

export const newFeed =
    ({ message, authuser }) =>
    (dispatch) => {
        // const notificationMessage =
        //     authuser && authuser?.id === message?.created_by?._id
        //         ? `You ${message?.message}`
        //         : `${message?.created_by?.first_name} ${message?.message}`;

        // authuser?.id !== message?.created_by?._id &&
        //     toast.info(notificationMessage, {
        //         position: "top-right",
        //         autoClose: true,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: false,
        //         closeButton: <CloseButton />,
        //     });

        dispatch({
            type: types.NEW_FEED,
            payload: message,
        });
    };
