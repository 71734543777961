import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Button,
    Col,
    Input,
    Label,
    Row,
    Spinner,
    Modal,
    ModalBody,
    ModalHeader,
    Card,
    Collapse
} from 'reactstrap';
import queryString from 'query-string';
import { PreviewCard, RSelect, Icon } from '../../../components/Component';
import Form from '../../properties/components/forms/Form';
import {
    BathRooms,
    BedRooms,
    DealCategory,
    DealClientType,
    DealPurpose,
    DealRoleType,
    formatValue,
    formatValueToDecimal,
    propertyTypes
} from '../../../utils/envConfig';
import { useSelector } from 'react-redux';
import SearchClientSelect from '../../../components/SearchClientSelect';
import { useDispatch } from 'react-redux';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import {
    fetchBuildings,
    fetchCommunitiesList,
    fetchOwnerProperties,
    fetchPropertiesAdmin
} from '../../../redux/actions/property';
import { fetchAgencies } from '../../../redux/actions/agencies';
import { fetchAgents } from '../../../redux/actions/user';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { useConfirm } from '../../../hooks/useConfirm';
import ConfirmModal from './ConfirmModal';
import PhoneInput from '../../../components/input/contact-input/PhoneInput';
import AddNewAgency from './AddNewAgency';
import AddLeadStepOne from '../../leads/components/AddLeadStepOne';

export default function SecondaryMarketAgent({
    dealType,
    next = () => {},
    users
}) {
    const loggedInUser = useSelector((state) => state?.user?.loggedInUser);
    const axios = useAxiosPrivate();
    const dispatch = useDispatch();

    const agents = useSelector((state) => state?.user?.agents);

    const {
        isConfirmed: confirmCommissonInput,
        prompt: commissionPrompt = '',
        isOpen: commissionIsOpen = false,
        proceed: commisionProceed,
        cancel: commissionCancel
    } = useConfirm();

    const AgentOptions =
        agents && agents?.length
            ? [
                  agents?.find((agent) => agent?.user?.id === loggedInUser?.id),
                  ...agents?.filter(
                      (agent) => agent?.user?.id !== loggedInUser?.id
                  )
              ]?.map((agent) => ({
                  ...agent,
                  label: `${agent?.user?.first_name ?? ''} ${
                      agent?.user?.last_name ?? ''
                  }`,
                  value: `${agent?.user?.first_name ?? ''} ${
                      agent?.user?.last_name ?? ''
                  }`
              }))
            : [];

    const communities = useSelector((state) => state?.property?.communities);

    const buildings = useSelector((state) => state?.property?.buildings);

    const agencies = useSelector((state) => state?.agencies?.agencies);

    const Purposes = Object.values(DealPurpose).map((item) => ({
        value: item,
        label: item
    }));

    const ClientType = Object.values(DealClientType).map((item) => ({
        value: item,
        label: item
    }));

    const Agencies =
        agencies && agencies?.length
            ? agencies?.map((agency) => ({
                  ...agency,
                  value: agency?.agency_name ?? agency?.first_name,
                  label: agency?.agency_name ?? agency?.first_name
              }))
            : [];

    const property = {
        id: null,
        listingType: '',
        categoryId: '',
        propertyTypeId: '',
        propertyTitle: '',
        propertyDesc: '',
        featured: false,
        dealOfWeek: false,
        premium: false,
        exclusive: false,
        completionStatus: '',
        completionDate: '',
        buildingName: '',
        street: '',
        communityId: '',
        communityName: '',
        subCommunity: '',
        emirate: '',
        pinlocation: '',
        size: null,
        villaSize: '',
        beds: '',
        baths: '',
        amenityIds: [],
        amenityObjs: [],
        image: null,
        customField: [],
        rentalPeriod: '',
        rentFinance: '',
        unitStatus: '',
        unitReraNo: '',
        makaniNo: '',
        videos: [],
        matterportLink: [],
        agentStatus: '',
        agent_id: null,
        metaDesc: '',
        metaTags: [],
        commission: '',
        securityDeposit: '',
        dewaNo: '',
        askingPrice: null,
        pricedrop: '',
        oneCheqPrice: null,
        twoCheqPrice: null,
        fourCheqPrice: null,
        sixCheqPrice: null,
        twelveCheqprice: null,
        fees: [],
        property_owner_id: ''
    };

    const [openAddAgency, setopenAddAgency] = useState(false);
    const [openAddProperty, setopenAddProperty] = useState(false);
    const [openAddClient, setopenAddClient] = useState(false);

    const [clientToAddType, setClientToAddType] = useState(null);

    const [defaultAgent, setDefaultAgent] = useState(null);

    const [selectedBuyerType, setSelectedBuyerType] = useState(null);
    const [selectedBuyer, setSelectedBuyer] = useState(null);
    const [buyerAgreedComm, setBuyerAgreedComm] = useState('2');
    const [buyerAgreedCommValue, setBuyerAgreedCommValue] = useState('');
    const [selectedAgentBuyer, setSelectedAgentBuyer] = useState(null);
    const [isMortgage, setIsMortgage] = useState(false);


    const [isSearchingClient, setIsSearchingClient] = useState(false);
    const [searchBuyerClientText, setSearchBuyerClientText] = useState('');
    const [mortgageProvider, setMortgageProvider]=useState('');
    const [isSellerMortgage, setIsSellerMortgage] = useState(false);
    const [sellerMortgageProvider, setSellerMortgageProvider] = useState('');

    const [formattedbuyerAgreedComm, setFormattedbuyerAgreedComm] =
        useState('');

    const [selectedPurpose, setSelectedPurpose] = useState(null);

    const [selectedSellerType, setselectedSellerType] = useState(null);
    const [selectedSeller, setSelectedSeller] = useState(null);
    const [sellerAgreedComm, setSellerAgreedComm] = useState('');
    const [sellerAgreedCommValue, setSellerAgreedCommValue] = useState('');
    const [sellerAgreedSalePrice, setSellerAgreedSalePrice] = useState('');
    const [selectedAgencySeller, setSelectedAgencySeller] = useState(null);
    const [selectedAgentSeller, setSelectedAgentSeller] = useState(null);
    const [selectedSellerPropOwner, setSelectedSellerPropOwner] =
        useState(null);

        // Update clientOptions to exclude the property owner

const [clientOptions, setClientOptions] = useState([]);

useEffect(() => {
    if (users && Array.isArray(users)) {
        const updatedClientOptions = users
            .filter((user) => user?.phone !== selectedSellerPropOwner?.phone) // Filter out by phone number
            .map((user) => ({
                ...user,
                label: `${user?.first_name ?? ''} ${user?.last_name ?? ''} ${
                    user?.phone ? `***${user?.phone.slice(-4)}` : ''
                }`,
                value: `${user?.first_name ?? ''} ${user?.last_name ?? ''}`
            }));
        
        setClientOptions(updatedClientOptions);
    }
}, [users, selectedSellerPropOwner]);


    const [formattedsellerAgreedComm, setFormattedsellerAgreedComm] =
        useState('');

    const [sellerAgency, setSellerAgency] = useState(null);

    const [selectedAgencyBuyer, setSelectedAgencyBuyer] = useState(null);
    const [buyerAgency, setBuyerAgency] = useState(null);

    const [propertyDetails, setPropertyDetails] = useState(null);

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const [selectedProperty, setSelectedProperty] = useState(null);

    const [agentAssignesdProperties, setAgentAssignesdProperties] =
        useState(null);

    const [isTyping, setIsTyping] = useState(false);

    useEffect(() => {
        dispatch(fetchPropertiesAdmin(axios));
        dispatch(fetchCommunitiesList(axios));
        dispatch(fetchBuildings(axios));
        dispatch(fetchAgencies(axios));

        !agents?.length && dispatch(fetchAgents(axios));
    }, []);

    useEffect(() => {
        if (defaultAgent?.id) {
            queryProperties(defaultAgent?.id, null).then((res) => {
                setAgentAssignesdProperties(res);
            });
        }
    }, [defaultAgent?.id]);

    useEffect(() => {
        dispatch(
            fetchOwnerProperties(
                axios,
                selectedSeller?.id ?? selectedSeller?._id
            )
        );
    }, [selectedSeller]);

    useEffect(() => {
        getDefaultAgent();
    }, []);

    const searchClients = useCallback(async (searchText) => {
        try {
            const query = queryString.stringify({
                search: searchText,
                agentUserId:
                    selectedAgentBuyer?.userId ?? selectedAgentBuyer?.user?.id
            });
    
            const url = `/search/user/filter?page=1&sort_by=created_at&sort_dir=DESC&${query}&active=true`;
    
            setIsSearchingClient(true);
    
            const { data } = await axios.get(url);
    
            // Exclude the `selectedSellerPropOwner` based on phone (or any other criteria)
            setClientOptions(
                data?.findUsersOutput
                    ?.filter(
                        (user) => user?.phone !== selectedSellerPropOwner?.phone
                    )
                    .map((user) => {
                        const phoneMasked = `***${user?.phone?.slice(-4)}` ?? '';
                        const fullName = `${user?.first_name ?? ''} ${user?.last_name ?? ''}`;
                        
                        return {
                            ...user,
                            label: user?.phone // Prioritize phone number
                                ? `${fullName} ${phoneMasked}`
                                : `${fullName}`,
                            value: `${user?.phone ?? ''} ${user?.email ?? ''}`
                        };
                    })
            );
        } catch (err) {
            console.log(err);
        } finally {
            setIsSearchingClient(false);
        }
    }, [selectedAgentBuyer, selectedSellerPropOwner]);    
    

    // memoize the debounce call to searchClients with useMemo
    const debounceSearchClients = useMemo(() => {
        return debounce(searchClients, 500);
    }, [searchClients]);

    const toggleModalAddProperty = () => {
        setopenAddProperty(!openAddProperty);
    };

    const toggleModalAddAgency = () => {
        setopenAddAgency(!openAddAgency);
    };
    const toggleModalAddClient = () => {
        setopenAddClient(!openAddClient);
    };

    const getDefaultAgent = async () => {
        try {
            const { data } = await axios.get(`/agent/user/${loggedInUser?.id}`);
            setDefaultAgent(data);
            setSelectedAgentBuyer({
                ...data,
                label: `${data?.user?.first_name} ${data?.user?.last_name}`,
                value: `${data?.user?.first_name} ${data?.user?.last_name}`
            });
            setSelectedAgentSeller({
                ...data,
                label: `${data?.user?.first_name} ${data?.user?.last_name}`,
                value: `${data?.user?.first_name} ${data?.user?.last_name}`
            });
        } catch (err) {
            console.log(err);
        }
    };

    const getPropertyOwner = async (id) => {
        try {
            const { data } = await axios.get(`/users/${id}`);

            return data;
        } catch (err) {
            console.log(err);
        }
    };

    const handleSellerTypeChange = (selectedOption) => {
        setselectedSellerType(selectedOption);
        setErrors((prev) => ({ ...prev, sellerType: null }));

        if (selectedOption?.value === DealClientType.External) {
            setSelectedAgentSeller({
                ...defaultAgent,
                label: `${defaultAgent?.user?.first_name} ${defaultAgent?.user?.last_name}`,
                value: `${defaultAgent?.user?.first_name} ${defaultAgent?.user?.last_name}`
            });
            setSelectedAgentBuyer({
                ...defaultAgent,
                label: `${defaultAgent?.user?.first_name} ${defaultAgent?.user?.last_name}`,
                value: `${defaultAgent?.user?.first_name} ${defaultAgent?.user?.last_name}`
            });
            setSelectedBuyerType({
                label: DealClientType.Alba,
                value: DealClientType.Alba
            });
        }
    };

    const handleBuyerTypeChange = (selectedOption) => {
        setSelectedBuyerType(selectedOption);
        setErrors((prev) => ({ ...prev, buyerType: null }));
    };

    // debounce functionality for property search

    const queryProperties = async (agentId, value) => {
        try {
            if (!agentId || !agentId.length) {
                console.log('invalid agent id');
                return [];
            }

            const url = `/propList/agent-properties/${agentId}${
                value && value?.length > 1 ? `?search=${value}` : ''
            }`;

            const { data } = await axios.get(url);

            return data;
        } catch (err) {
            console.log(err);
            return [];
        }
    };

    const sendRequest = useCallback(async (params) => {
        try {
            const { text: value, agentId } = params;

            if (!agentId) {
                console.log('Invalid agent id');
                return;
            }

            const properties = await queryProperties(agentId, value);

            setAgentAssignesdProperties(
                properties?.map((prop) => ({
                    ...prop,
                    label: prop?.propertyTitle,
                    value: prop?.propertyTitle
                }))
            );
        } catch (err) {
            console.log(err);
        } finally {
            setIsTyping(false);
        }
    }, []);

    // memoize the debounce call with useMemo
    const debouncedSendRequest = useMemo(() => {
        return debounce(sendRequest, 500);
    }, [sendRequest]);

    // end debounce for property search

    const validateBuyerOrTenant = (errors) => {
        if (
            (selectedBuyerType && selectedBuyerType?.value) ===
            DealClientType.External
        ) {
            if (!selectedAgencyBuyer || !selectedAgencyBuyer?.id) {
                errors.selectedAgencyBuyer = 'Select a valid agency';
            }
        } else {
            if (
                !ClientType.find(
                    (item) => item?.value === selectedBuyerType?.value
                )
            ) {
                errors.buyerType = 'Invalid buyer type';
            }

            if (!selectedBuyerType?.value) {
                errors.buyerType = 'Buyer type is required';
            }

            if (
                !selectedAgentBuyer ||
                (!selectedAgentBuyer?.id && !selectedAgentBuyer?._id)
            ) {
                errors.buyerAssignedAgent = 'Please select an agent';
            }

            if (selectedAgentBuyer?.user?.id === loggedInUser?.id) {
                if (!selectedBuyer?.id && !selectedBuyer?._id) {
                    errors.selectedBuyer = 'Select a valid client';
                }

                if (+buyerAgreedComm > 100) {
                    errors.buyerAgreedComm = 'Cannot be more than 100%';
                }
            }
        }
    };

    const validatSellerOrLandlord = (errors) => {
        if (
            (selectedSellerType && selectedSellerType?.value) ===
            DealClientType.External
        ) {
            if (!selectedAgencySeller || !selectedAgencySeller?.id) {
                errors.selectedAgencySeller = 'Select a valid agency';
            }
        } else {
            if (
                !ClientType.find(
                    (item) => item?.value === selectedSellerType?.value
                )
            ) {
                errors.sellerType = 'Invalid seller type';
            }

            if (!selectedSellerType?.value) {
                errors.sellerType = 'Seller type is required';
            }

            if (!selectedSellerPropOwner?.id && !selectedSellerPropOwner?._id) {
                errors.selectedSeller = 'Select a valid client';
            }

            if (!selectedProperty?.id && !selectedProperty?._id) {
                errors.property = 'Select a valid property';
            }

            if (!selectedAgentSeller?.id && !selectedAgentSeller?._id) {
                errors.sellerAssignedAgent = 'Select an agent';
            }
        }
        if (
            !sellerAgreedSalePrice ||
            Number(sellerAgreedSalePrice?.trim()?.replaceAll(',', '')) <= 0
        ) {
            errors.sellerAgreedSalePrice =
                'Cannot be less than or equal to zero.';
        }
    };

    const validateDealInfos = () => {
        const errors = {};

        if (dealType !== DealCategory?.SecondaryProperty) {
            errors.dealType = 'Deal type must be a secondary market';
        }

        if (!Purposes.find((item) => item?.value === selectedPurpose?.value)) {
            errors.purpose = 'Invalid deal purpose';
        }

        if (!selectedPurpose?.value) {
            errors.purpose = 'Deal purpose is required.';
        }

        validateBuyerOrTenant(errors);
        validatSellerOrLandlord(errors);

        return Object.keys(errors).length ? errors : false;
    };

    const validatePropertyDetails = () => {
        const errors = {};

        if (!propertyDetails?.propertyType) {
            errors.propertyType = 'Select property type';
        }

        if (!propertyDetails?.beds?.value) {
            errors.beds = 'Select number of beds';
        }

        if (!propertyDetails.baths?.value) {
            errors.baths = 'Select number of baths';
        }

        if (
            !propertyDetails.community?.id &&
            !propertyDetails?.community?._id
        ) {
            errors.community = 'Select community';
        }

        if (!propertyDetails.building?.id && !propertyDetails?.building?._id) {
            errors.buildiing = 'Select building';
        }

        if (
            !sellerAgreedSalePrice ||
            !+sellerAgreedSalePrice?.trim()?.replaceAll(',', '')
        ) {
            errors.sellerAgreedSalePrice = 'Sale price required';
        }

        if (Number(sellerAgreedComm) > 100) {
            errors.sellerAgreedComm = 'Cannot be more than 100%';
        }

        return Object.keys(errors).length ? errors : false;
    };

    const createNewExternalProperty = async () => {
        try {
            const invalid = validatePropertyDetails();

            if (invalid) {
                setErrors((prev) => ({ ...prev, ...invalid }));
                return null;
            }

            const data = {
                propertyType: propertyDetails?.propertyType?.value,
                beds: propertyDetails?.beds?.value,
                baths: propertyDetails?.baths?.value,
                size: propertyDetails?.size,
                community_id: propertyDetails?.community?.id,
                subCommunity: propertyDetails?.subCommunity,
                building_id: propertyDetails?.buildiing?.id,
                building_name: propertyDetails?.buildiing?.name,
                agreedSalesPrice: +sellerAgreedSalePrice
                    ?.trim()
                    ?.replaceAll(',', ''),
                agreedCommission: sellerAgreedComm,
                agency_id: selectedAgencySeller?.id ?? selectedAgencySeller?._id
            };

            // creates a new property (/propList)
            const { data: property } = await axios.post(
                '/propList/listingProperty',
                data
            );

            return property;
        } catch (err) {
            console.log(err);
            return null;
        }
    };

    const addNewDeal = async () => {
        let dealInfos = {
            category: dealType,
            purpose: selectedPurpose?.value,
            buyerClientType: selectedBuyerType?.value,
            buyerInternalUserId:
                selectedAgentBuyer?.user?.id === loggedInUser?.id
                    ? selectedBuyer?.id ?? selectedBuyer?._id
                    : null,
            sellerClientType: selectedSellerType?.value,
            sellerInternalUserId:
                selectedSellerPropOwner?.id ?? selectedSellerPropOwner?._id,
            property_id: selectedProperty?.id ?? selectedProperty?._id,
            roleType:
                selectedPurpose?.value === DealPurpose?.Rent
                    ? [DealRoleType.Tenant, DealRoleType.Landlord]
                    : selectedPurpose?.value === DealPurpose?.Sale
                    ? [DealRoleType.Buyer, DealRoleType.Seller]
                    : null, // depends on the purpose:::: purpose = Rent ? [Tenant, Landlord] : status === Sale ? [Buyer, Seller]
            // when buyer/tenant is external
            buyerExternalUserId:
                selectedAgencyBuyer?.id ?? selectedAgencyBuyer?._id,
            buyerExternalAgentName: buyerAgency?.agent,
            buyerExternalAgentPhone: buyerAgency?.agentPhone
                ?.trim()
                ?.replaceAll(' ', ''),
            buyerExternalClientName: buyerAgency?.clientName,
            buyerExternalClientPhone: buyerAgency?.clientPhone
                ?.trim()
                ?.replaceAll(' ', ''),
            // when seller/landlord is external
            sellerExternalUserId:
                selectedAgencySeller?.id ?? selectedAgencySeller?._id,
            sellerExternalAgentName: sellerAgency?.agentName,
            sellerExternalAgentPhone: sellerAgency?.agentPhone
                ?.trim()
                ?.replaceAll(' ', ''),
            sellerExternalClientName: sellerAgency?.clientName,
            sellerExternalClientPhone: sellerAgency?.clientPhone
                ?.trim()
                ?.replaceAll(' ', ''),
            external_listing_property_id: null,
            sellerAgreedComm:
                selectedAgentSeller?.user?.id === loggedInUser?.id
                    ? +sellerAgreedComm
                    : 0,
            agreedSalePrice: +sellerAgreedSalePrice
                ?.trim()
                ?.replaceAll(',', ''),
            buyerAgreedComm:
                selectedAgentBuyer?.user?.id === loggedInUser?.id
                    ? +buyerAgreedComm
                    : 0,
            sellerAssignedAgent:
                selectedSellerType?.value === DealClientType.Alba
                    ? selectedAgentSeller?.id ?? selectedAgentSeller?._id
                    : null,

            buyerAssignedAgent:
                selectedBuyerType?.value === DealClientType.Alba
                    ? selectedAgentBuyer?.id ?? selectedAgentBuyer?._id
                    : null,
            isMortgage: isMortgage ?? isMortgage,
            mortgageProvider: mortgageProvider ?? mortgageProvider,
            isSellerMortgage: isSellerMortgage ?? false,
            sellerMortgageProvider: sellerMortgageProvider ?? null,
        };

        try {
            const invalid = validateDealInfos();

            if (invalid) {
                setErrors(invalid);
                return;
            }

            if (
                selectedSellerType?.value === DealClientType.External &&
                selectedBuyerType?.value === DealClientType.External
            ) {
                toast.error('Buyer and seller cannot be external entities');
                return;
            }

            if (
                (selectedSellerType?.value === DealClientType.External &&
                    (!selectedAgentBuyer ||
                        selectedAgentBuyer?.user?.id !== loggedInUser?.id)) ||
                (selectedBuyerType?.value === DealClientType.External &&
                    (!selectedAgentSeller ||
                        selectedAgentSeller?.user?.id !== loggedInUser?.id))
            ) {
                toast.error(
                    'Selected agent must be assigned to either a seller/landlord or buyer/tenant'
                );
                return;
            }

            if (
                selectedAgentBuyer?.user?.id !== loggedInUser?.id &&
                selectedAgentSeller?.user?.id !== loggedInUser?.id
            ) {
                toast.error(
                    'Logged in agent must be assigned to either buyer or seller'
                );
                return;
            }

            if (
                selectedBuyerType?.value === DealClientType.Alba &&
                selectedBuyer?.agent?.id !== selectedAgentBuyer?.id &&
                selectedAgentBuyer?.user?.id === loggedInUser?.id
            ) {
                toast.error(
                    'Selected agent must to assigned to selected client'
                );
            }

            if (
                selectedSellerType?.value === DealClientType.Alba &&
                selectedProperty?.agent_id !== selectedAgentSeller?.id &&
                selectedAgentSeller?.user?.id === loggedInUser?.id
            ) {
                toast.error(
                    'Selected agent must to assigned to selected property'
                );
            }

            console.log('Ended validations');

            setLoading(true);

            if (selectedSellerType?.value === DealClientType.External) {
                const property = await createNewExternalProperty();

                if (!property) {
                    console.log('nothing more will run');
                    return;
                }

                dealInfos.external_listing_property_id =
                    property?.id ?? property?._id;
            }

            console.log(dealInfos);
            const { data } = await axios.post('/deals', dealInfos);
            // save deal id to localStorage to refetch data when we progress deal
            localStorage.setItem('new_deal', JSON.stringify(data));
            // call next function to move to next step
            next();
            return;
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const renderSellersRows = () => {
        if (
            selectedSellerType &&
            selectedSellerType.value === DealClientType.Alba
        ) {
            return (
                <PreviewCard className="bg-lighter">
                    <Row className="gy-1">
                        <Col sm="11">
                            <Label>Choose Agent Assigned To Property</Label>
                            <RSelect
                                options={AgentOptions}
                                value={selectedAgentSeller}
                                onChange={async (agent) => {
                                    setSelectedAgentSeller(agent);

                                    if (agent?.user?.id !== loggedInUser?.id) {
                                        setSelectedAgentBuyer(
                                            AgentOptions?.find(
                                                (agent) =>
                                                    agent?.user?.id ===
                                                    loggedInUser?.id
                                            )
                                        );
                                        setSelectedBuyerType({
                                            label: DealClientType.Alba,
                                            value: DealClientType.Alba
                                        });
                                    }

                                    const properties = await queryProperties(
                                        agent?.id,
                                        null
                                    );

                                    setAgentAssignesdProperties(properties);

                                    setSelectedProperty(null);
                                    setSelectedSellerPropOwner(null);
                                }}
                            />
                        </Col>

                        <Label>Choose Property</Label>
                        <Col sm="10">
                            <RSelect
                                options={
                                    agentAssignesdProperties &&
                                    agentAssignesdProperties?.length
                                        ? agentAssignesdProperties?.map(
                                              (prop) => ({
                                                  ...prop,
                                                  label: `${prop?.referNo} - ${
                                                      prop?.beds ?? ''
                                                  } ${
                                                      !isNaN(Number(prop?.beds))
                                                          ? 'BR'
                                                          : ''
                                                  } - ${
                                                      prop?.propertyTitle
                                                  } - ${prop?.communityName}`
                                              })
                                          )
                                        : []
                                }
                                placeholder="Choose Property"
                                onChange={async (prop) => {
                                    setSelectedProperty({
                                        ...prop
                                        // agent: propertyAgent,
                                    });

                                    const propertyValue =
                                        prop?.askingPrice ??
                                        prop?.oneCheqPrice ??
                                        prop?.twoCheqPrice ??
                                        prop?.fourCheqPrice ??
                                        prop?.sixCheqPrice ??
                                        prop?.twelveCheqprice ??
                                        0;

                                    setSellerAgreedSalePrice(
                                        propertyValue
                                            ? formatValue(propertyValue)
                                            : ''
                                    );

                                    setErrors((prev) => ({
                                        ...prev,
                                        property: null
                                    }));

                                    prop?.commission &&
                                        setSellerAgreedComm(prop?.commission);
                                    prop?.commission &&
                                        setFormattedsellerAgreedComm(
                                            prop?.commission
                                        );

                                    prop?.commission &&
                                        propertyValue &&
                                        setSellerAgreedCommValue(
                                            formatValue(
                                                Math.round(
                                                    (propertyValue *
                                                        Number(
                                                            prop?.commission
                                                        )) /
                                                        100
                                                )
                                            )
                                        );

                                    prop?.commission &&
                                        propertyValue &&
                                        Number(buyerAgreedComm) &&
                                        !isNaN(Number(buyerAgreedComm)) &&
                                        setBuyerAgreedCommValue(
                                            formatValue(
                                                Math.round(
                                                    (propertyValue *
                                                        Number(
                                                            buyerAgreedComm
                                                        )) /
                                                        100
                                                )
                                            )
                                        );

                                    const propertyOwner =
                                        prop?.property_owner_id
                                            ? await getPropertyOwner(
                                                  prop?.property_owner_id
                                              )
                                            : null;

                                    setSelectedSellerPropOwner({
                                        ...propertyOwner,
                                        label: `${
                                            propertyOwner?.first_name ?? ''
                                        } ${propertyOwner?.last_name ?? ''}`,
                                        value: `${
                                            propertyOwner?.first_name ?? ''
                                        } ${propertyOwner?.last_name ?? ''}`
                                    });
                                }}
                                onInputChange={(txt) => {
                                    setIsTyping(true);
                                    debouncedSendRequest({
                                        text: txt,
                                        agentId: selectedAgentSeller?.id
                                    });
                                }}
                                value={selectedProperty}
                                className={
                                    errors &&
                                    errors?.property &&
                                    'border border-danger'
                                }
                                noOptionsMessage={() =>
                                    isTyping ? (
                                        <Spinner size="sm" color="light" />
                                    ) : (
                                        'No properties'
                                    )
                                }
                            />
                            {errors && errors?.property && (
                                <small className="ff-italic text-danger">
                                    {errors?.property}
                                </small>
                            )}
                        </Col>
                        <Col>
                            <Button
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    toggleModalAddProperty();
                                }}
                            >
                                <Icon name="plus"></Icon>
                            </Button>
                        </Col>

                        <Col sm="11">
                            <Label>Property Owner</Label>
                            <Input
                                value={
                                    selectedSellerPropOwner?.value ??
                                    'No assigned owner'
                                }
                                disabled
                            />
                            {errors && errors?.selectedSeller && (
                                <small className="ff-italic text-danger">
                                    {errors?.selectedSeller}
                                </small>
                            )}
                        </Col>

                        <Col sm="11" className="mt-1">
                            <Label>Agreed Sale Price</Label>
                            <div className="form-control-wrap">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            AED
                                        </span>
                                    </div>
                                    <Input
                                        className={
                                            errors?.sellerAgreedSalePrice &&
                                            'border border-danger'
                                        }
                                        placeholder="Enter Agreed Sale Price"
                                        value={sellerAgreedSalePrice}
                                        type="text"
                                        onChange={(e) => {
                                            const value =
                                                +e.target.value?.replaceAll(
                                                    ',',
                                                    ''
                                                );

                                            // if (
                                            //     isNaN(Number(e.target.value)) ||
                                            //     value <= 0
                                            // ) {
                                            //     setErrors((prv) => ({
                                            //         ...prv,
                                            //         sellerAgreedSalePrice:
                                            //             'Value cannot be less than or equal to zero'
                                            //     }));
                                            // }

                                            if (value > 0) {
                                                setErrors((prv) => ({
                                                    ...prv,
                                                    sellerAgreedSalePrice: null
                                                }));

                                                setSellerAgreedSalePrice(
                                                    formatValue(value)
                                                );
                                                return;
                                            }

                                            setSellerAgreedSalePrice(
                                                e.target.value
                                            );
                                        }}
                                        onBlur={(e) => {
                                            const value = +e.target.value
                                                ?.replaceAll(',', '')
                                                ?.trim();

                                            if (isNaN(value) || value <= 0) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedSalePrice:
                                                        value <= 0
                                                            ? 'Sale price cannot be less than zero or empty or zero'
                                                            : 'Invalid sale price'
                                                }));
                                                return;
                                            }

                                            // if sale price is greater than biggest integer, we throw error
                                            if (
                                                value > Number.MAX_SAFE_INTEGER
                                            ) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedSalePrice:
                                                        'Sale price is too large'
                                                }));
                                                return;
                                            }

                                            const buyerAgreedCommRate =
                                                +buyerAgreedComm;

                                            const sellerAgreedCommValue =
                                                Math.round(
                                                    (value *
                                                        Number(
                                                            sellerAgreedComm
                                                        )) /
                                                        100
                                                );

                                            if (
                                                buyerAgreedCommRate &&
                                                buyerAgreedCommRate > 0 &&
                                                buyerAgreedCommRate <= 100
                                            ) {
                                                setBuyerAgreedCommValue(
                                                    formatValue(
                                                        Math.round(
                                                            (value *
                                                                buyerAgreedCommRate) /
                                                                100
                                                        )
                                                    )
                                                );
                                            }

                                            if (
                                                +sellerAgreedComm &&
                                                +sellerAgreedComm > 0 &&
                                                +sellerAgreedComm <= 100
                                            ) {
                                                setSellerAgreedCommValue(
                                                    formatValue(
                                                        sellerAgreedCommValue
                                                    )
                                                );
                                            }
                                        }}
                                    />
                                </div>
                                {errors?.sellerAgreedSalePrice && (
                                    <small className="ff-italic text-danger">
                                        {errors.sellerAgreedSalePrice}
                                    </small>
                                )}
                            </div>
                        </Col>

                        <>
                            <Col sm="11" className="mt-1">
                                <Label>Agreed Commission</Label>
                            </Col>

                            <Col sm="5">
                                <div className="form-control-wrap">
                                    <div
                                        className={`input-group ${
                                            errors?.sellerAgreedComm &&
                                            'border border-danger'
                                        }`}
                                    >
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                %
                                            </span>
                                        </div>
                                        <Input
                                            className={
                                                errors?.sellerAgreedComm &&
                                                'border border-danger'
                                            }
                                            max={'100'}
                                            placeholder="Enter Agreed Commission (%)"
                                            value={formattedsellerAgreedComm}
                                            type="number"
                                            onKeyDown={(e) => {
                                                const notAllowedKeys = [
                                                    'e',
                                                    'E',
                                                    '+',
                                                    '-'
                                                ];

                                                if (
                                                    notAllowedKeys.includes(
                                                        e.key
                                                    )
                                                ) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(e) => {
                                                setSellerAgreedComm(
                                                    e.target.value
                                                );
                                                setFormattedsellerAgreedComm(
                                                    e.target.value
                                                );
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedComm: null
                                                }));
                                            }}
                                            onBlur={async (e) => {
                                                const value = +e.target.value;

                                                const trimmedPrice =
                                                    +sellerAgreedSalePrice
                                                        ?.trim()
                                                        ?.replaceAll(',', '');

                                                const agreedSellingPrice =
                                                    isNaN(trimmedPrice)
                                                        ? 0
                                                        : trimmedPrice;

                                                if (value < 0) {
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        sellerAgreedComm:
                                                            '0% min'
                                                    }));
                                                }

                                                if (value > 100) {
                                                    const confirmBox =
                                                        await confirmCommissonInput(
                                                            <>
                                                                <p className="fw-bold">
                                                                    Rate greater
                                                                    than 100%!
                                                                </p>
                                                                <p>
                                                                    Is this the
                                                                    commission
                                                                    value?
                                                                </p>
                                                            </>
                                                        );

                                                    if (confirmBox) {
                                                        const commRate =
                                                            (value /
                                                                agreedSellingPrice) *
                                                            100;

                                                        setSellerAgreedCommValue(
                                                            formatValue(value)
                                                        );

                                                        setSellerAgreedComm(
                                                            commRate
                                                        );

                                                        setFormattedsellerAgreedComm(
                                                            formatValueToDecimal(
                                                                commRate
                                                            )
                                                        );
                                                        setErrors((prev) => ({
                                                            ...prev,
                                                            sellerAgreedComm:
                                                                null,
                                                            sellerAgreedCommValue:
                                                                null
                                                        }));
                                                        return;
                                                    } else {
                                                        setErrors((prev) => ({
                                                            ...prev,
                                                            sellerAgreedComm:
                                                                'Cannot be more than 100%'
                                                        }));

                                                        return;
                                                    }
                                                }

                                                const agreedCommissionValue =
                                                    (agreedSellingPrice *
                                                        value) /
                                                    100;

                                                setSellerAgreedCommValue(
                                                    formatValue(
                                                        agreedCommissionValue
                                                    )
                                                );

                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedComm: null,
                                                    sellerAgreedCommValue: null
                                                }));
                                            }}
                                        />
                                    </div>
                                    {errors && errors?.sellerAgreedComm && (
                                        <small className="ff-italic text-danger">
                                            {errors?.sellerAgreedComm}
                                        </small>
                                    )}
                                </div>
                            </Col>

                            <Col sm="6">
                                <div className="form-control-wrap">
                                    <div
                                        className={`input-group ${
                                            errors?.sellerAgreedCommValue &&
                                            'border border-danger'
                                        }`}
                                    >
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                AED
                                            </span>
                                        </div>
                                        <Input
                                            placeholder="Enter Agreed Commission (AED)"
                                            value={sellerAgreedCommValue}
                                            type="text"
                                            onChange={(e) => {
                                                const value =
                                                    +e.target.value?.replaceAll(
                                                        ',',
                                                        ''
                                                    );

                                                if (value > 0) {
                                                    setSellerAgreedCommValue(
                                                        formatValue(value)
                                                    );
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        sellerAgreedComm: '',
                                                        sellerAgreedCommValue:
                                                            ''
                                                    }));
                                                    return;
                                                }

                                                setSellerAgreedCommValue(
                                                    e.target.value
                                                );
                                            }}
                                            onBlur={(e) => {
                                                const value = +e.target.value
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                                const trimmedPrice =
                                                    +sellerAgreedSalePrice
                                                        ?.trim()
                                                        ?.replaceAll(',', '');

                                                const agreedSellingPrice =
                                                    isNaN(trimmedPrice)
                                                        ? 0
                                                        : trimmedPrice;

                                                if (
                                                    value > agreedSellingPrice
                                                ) {
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        sellerAgreedCommValue:
                                                            'Cannot be more than sale price'
                                                    }));
                                                    return;
                                                }

                                                const sellerAgreedComm =
                                                    (value /
                                                        agreedSellingPrice) *
                                                    100;

                                                setSellerAgreedComm(
                                                    sellerAgreedComm
                                                );
                                                setFormattedsellerAgreedComm(
                                                    formatValueToDecimal(
                                                        sellerAgreedComm
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                    {errors &&
                                        errors?.sellerAgreedCommValue && (
                                            <small className="ff-italic text-danger">
                                                {errors?.sellerAgreedCommValue}
                                            </small>
                                        )}
                                </div>
                            </Col>
                        </>
                    </Row>
                </PreviewCard>
            );
        } else if (
            selectedSellerType &&
            selectedSellerType.value === DealClientType.External
        ) {
            return (
                <PreviewCard className="bg-lighter">
                    <Row className="gy-2">
                        <Label>Choose Agency *</Label>
                        <Col sm="10">
                            <RSelect
                                options={Agencies}
                                placeholder="Choose Agency"
                                value={selectedAgencySeller}
                                onChange={(agent) => {
                                    setSelectedAgencySeller(agent);
                                    setSelectedSeller(null);
                                    setErrors((prev) => ({
                                        ...prev,
                                        selectedAgencySeller: null
                                    }));
                                }}
                                className={
                                    errors &&
                                    errors?.selectedAgencySeller &&
                                    'border border-danger'
                                }
                            />
                            {errors && errors?.selectedAgencySeller && (
                                <small className="text-danger ff-italic">
                                    {errors?.selectedAgencySeller}
                                </small>
                            )}
                        </Col>
                        <Col>
                            <Button
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    toggleModalAddAgency();
                                }}
                            >
                                <Icon name="plus"></Icon>
                            </Button>
                        </Col>

                        <Label>Agent Name</Label>
                        <Col sm="12">
                            <Input
                                placeholder="Enter Agent Name"
                                value={sellerAgency?.agentName}
                                onChange={(e) =>
                                    setSellerAgency((prev) => ({
                                        ...prev,
                                        agentName: e.target.value
                                    }))
                                }
                            />
                        </Col>
                        <Label>Agent Phone Number</Label>
                        <Col sm="12">
                            <PhoneInput
                                className={`phoneInput ${
                                    errors?.sellerAgencyAgentPhone &&
                                    'border border-danger'
                                } `}
                                defaultCountry="AE"
                                name="phone"
                                placeholder="Enter Agent Phone Number"
                                value={sellerAgency?.agentPhone}
                                onChange={(phone) => {
                                    setSellerAgency((prev) => ({
                                        ...prev,
                                        agentPhone: phone
                                    }));
                                }}
                                onError={(err) => {
                                    setErrors((prev) => ({
                                        ...prev,
                                        sellerAgencyAgentPhone: err
                                    }));
                                }}
                            />
                            {errors?.sellerAgencyAgentPhone && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.sellerAgencyAgentPhone}
                                    </small>
                                </div>
                            )}
                        </Col>
                        <Label>Client Name</Label>
                        <Col sm="12">
                            <Input
                                placeholder="Enter Client Name "
                                value={sellerAgency?.clientName}
                                onChange={(e) =>
                                    setSellerAgency((prev) => ({
                                        ...prev,
                                        clientName: e.target.value
                                    }))
                                }
                            />
                        </Col>
                        <Label>Client Phone Number</Label>
                        <Col sm="12">
                            <PhoneInput
                                className={`phoneInput ${
                                    errors?.sellerAgencyClientPhone &&
                                    'border border-danger'
                                } `}
                                defaultCountry="AE"
                                name="phone"
                                placeholder="Enter Client Phone Number"
                                value={sellerAgency?.clientPhone}
                                onChange={(phone) =>
                                    setSellerAgency((prev) => ({
                                        ...prev,
                                        clientPhone: phone
                                    }))
                                }
                                onError={(err) => {
                                    setErrors((prev) => ({
                                        ...prev,
                                        sellerAgencyClientPhone: err
                                    }));
                                }}
                            />
                            {errors?.sellerAgencyClientPhone && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.sellerAgencyClientPhone}
                                    </small>
                                </div>
                            )}
                        </Col>
                        <Col
                            sm="12"
                            className="bg-secondary mt-4 mb-3 pt-2 pb-2 rounded"
                        >
                            <h6 className="text-white">Property Details</h6>{' '}
                        </Col>
                        <Col sm="12">
                            <Label>Type of Property</Label>
                            <RSelect
                                options={propertyTypes?.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                value={propertyDetails?.propertyType}
                                onChange={(property) => {
                                    setPropertyDetails((prev) => ({
                                        ...prev,
                                        propertyType: property
                                    }));
                                    setErrors((prev) => ({
                                        ...prev,
                                        propertyType: null
                                    }));
                                }}
                                className={
                                    errors &&
                                    errors?.propertyType &&
                                    'border border-danger'
                                }
                            />
                        </Col>
                        <Col sm="12">
                            <Label>Beds</Label>
                            <RSelect
                                options={BedRooms?.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                value={propertyDetails?.beds}
                                onChange={(bed) => {
                                    setPropertyDetails((prev) => ({
                                        ...prev,
                                        beds: bed
                                    }));
                                    setErrors((prev) => ({
                                        ...prev,
                                        beds: null
                                    }));
                                }}
                                className={
                                    errors &&
                                    errors?.beds &&
                                    'border border-danger'
                                }
                            ></RSelect>
                        </Col>
                        <Col sm="12">
                            <Label>Baths</Label>
                            <RSelect
                                options={BathRooms?.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                value={propertyDetails?.baths}
                                onChange={(bath) => {
                                    setPropertyDetails((prev) => ({
                                        ...prev,
                                        baths: bath
                                    }));
                                    setErrors((prev) => ({
                                        ...prev,
                                        baths: null
                                    }));
                                }}
                                className={
                                    errors &&
                                    errors?.baths &&
                                    'border border-danger'
                                }
                            ></RSelect>
                        </Col>
                        <Col sm="12">
                            <Label>Size</Label>
                            <div className="form-control-wrap">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            Sqft
                                        </span>
                                    </div>
                                    <Input
                                        placeholder="Enter Size"
                                        value={propertyDetails?.size}
                                        onKeyDown={(e) => {
                                            const notAllowedKeys = [
                                                'e',
                                                'E',
                                                '+',
                                                '-'
                                            ];

                                            if (
                                                notAllowedKeys.includes(e.key)
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            const isNumber = !isNaN(
                                                Number(value)
                                            );

                                            setPropertyDetails((prev) => ({
                                                ...prev,
                                                size: e?.target?.value
                                            }));

                                            setErrors((prev) => ({
                                                ...prev,
                                                size:
                                                    value?.length > 10 ||
                                                    !isNumber ||
                                                    (+value <= 0 &&
                                                        value?.length > 0)
                                                        ? 'Invalid size'
                                                        : ''
                                            }));
                                        }}
                                        className={
                                            errors &&
                                            errors.size &&
                                            'border border-danger'
                                        }
                                    />
                                </div>
                                {errors && errors?.size && (
                                    <small className="text-danger ff-italic">
                                        {errors?.size}
                                    </small>
                                )}
                            </div>
                        </Col>
                        <Col sm="12">
                            <Label>Community</Label>
                            <RSelect
                                options={
                                    communities && communities?.length
                                        ? communities?.map((comm) => ({
                                              ...comm,
                                              value: comm?.community,
                                              label: comm?.community
                                          }))
                                        : []
                                }
                                value={propertyDetails?.community}
                                onChange={(community) => {
                                    setPropertyDetails((prev) => ({
                                        ...prev,
                                        community: community
                                    }));
                                    setErrors((prev) => ({
                                        ...prev,
                                        community: null
                                    }));
                                }}
                                className={
                                    errors &&
                                    errors?.community &&
                                    'border border-danger'
                                }
                            ></RSelect>
                        </Col>
                        <Col sm="12">
                            <Label>Sub Community</Label>
                            <Input
                                className={
                                    errors.subCommunity &&
                                    'border border-danger'
                                }
                                placeholder="Enter Sub Community"
                                value={propertyDetails?.sub_community}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    setPropertyDetails((prev) => ({
                                        ...prev,
                                        sub_community: value
                                    }));

                                    setErrors((prev) => ({
                                        ...prev,
                                        subCommunity:
                                            value?.length > 100
                                                ? 'Max of 100 characters allowed'
                                                : ''
                                    }));
                                }}
                            />
                            {errors.subCommunity && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.subCommunity}
                                    </small>
                                </div>
                            )}
                        </Col>
                        <Col sm="12">
                            <Label>Building Name</Label>
                            <RSelect
                                options={
                                    buildings && buildings?.length
                                        ? buildings?.map((bui) => ({
                                              ...bui,
                                              label: bui?.name,
                                              value: bui?.name
                                          }))
                                        : []
                                }
                                onChange={(building) =>
                                    setPropertyDetails((prev) => ({
                                        ...prev,
                                        building: building
                                    }))
                                }
                            ></RSelect>
                        </Col>

                        <Col sm="12" className="mt-1">
                            <Label>Agreed Sale Price</Label>
                            <div className="form-control-wrap">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            AED
                                        </span>
                                    </div>
                                    <Input
                                        className={
                                            errors?.sellerAgreedSalePrice &&
                                            'border border-danger'
                                        }
                                        placeholder="Enter Agreed Sale Price"
                                        value={sellerAgreedSalePrice}
                                        type="text"
                                        onChange={(e) => {
                                            const value =
                                                +e.target.value?.replaceAll(
                                                    ',',
                                                    ''
                                                );

                                            // if (
                                            //     isNaN( Number( e.target.value ) ) ||
                                            //     value <= 0
                                            // )
                                            // {
                                            //     setErrors( ( prv ) => ( {
                                            //         ...prv,
                                            //         sellerAgreedSalePrice:
                                            //             "Value cannot be less than or equal to zero",
                                            //     } ) );
                                            // }

                                            if (value > 0) {
                                                setErrors((prv) => ({
                                                    ...prv,
                                                    sellerAgreedSalePrice: null
                                                }));

                                                setSellerAgreedSalePrice(
                                                    formatValue(value)
                                                );
                                                return;
                                            }

                                            setSellerAgreedSalePrice(
                                                e.target.value
                                            );
                                        }}
                                        onBlur={(e) => {
                                            const value = +e.target.value
                                                ?.replaceAll(',', '')
                                                ?.trim();

                                            if (isNaN(value) || value <= 0) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedSalePrice:
                                                        value <= 0
                                                            ? 'Sale price cannot be less than zero or empty or zero'
                                                            : 'Invalid sale price'
                                                }));
                                                return;
                                            }

                                            // if sale price is greater than biggest integer, we throw error
                                            if (
                                                value > Number.MAX_SAFE_INTEGER
                                            ) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedSalePrice:
                                                        'Sale price is too large'
                                                }));
                                                return;
                                            }

                                            const buyerAgreedCommRate =
                                                +buyerAgreedComm;

                                            const sellerAgreedCommValue =
                                                Math.round(
                                                    (value *
                                                        Number(
                                                            sellerAgreedComm
                                                        )) /
                                                        100
                                                );

                                            if (
                                                buyerAgreedCommRate &&
                                                buyerAgreedCommRate > 0 &&
                                                buyerAgreedCommRate <= 100
                                            ) {
                                                setBuyerAgreedCommValue(
                                                    formatValue(
                                                        Math.round(
                                                            (value *
                                                                buyerAgreedCommRate) /
                                                                100
                                                        )
                                                    )
                                                );
                                            }

                                            if (
                                                +sellerAgreedComm &&
                                                +sellerAgreedComm > 0 &&
                                                +sellerAgreedComm <= 100
                                            ) {
                                                setSellerAgreedCommValue(
                                                    formatValue(
                                                        sellerAgreedCommValue
                                                    )
                                                );
                                            }
                                        }}
                                    />
                                </div>
                                {errors?.sellerAgreedSalePrice && (
                                    <small className="ff-italic text-danger">
                                        {errors.sellerAgreedSalePrice}
                                    </small>
                                )}
                            </div>
                        </Col>

                        <Col sm="12" className="mt-1">
                            <Label>Agreed Commission</Label>
                        </Col>

                        <Col sm="6">
                            <div className="form-control-wrap">
                                <div
                                    className={`input-group ${
                                        errors?.sellerAgreedComm &&
                                        'border border-danger'
                                    }`}
                                >
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            %
                                        </span>
                                    </div>
                                    <Input
                                        max={100}
                                        placeholder="Enter Agreed Commission (%)"
                                        value={formattedsellerAgreedComm}
                                        type="number"
                                        onKeyDown={(e) => {
                                            const notAllowedKeys = [
                                                'e',
                                                'E',
                                                '+',
                                                '-'
                                            ];

                                            if (
                                                notAllowedKeys.includes(e.key)
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setSellerAgreedComm(e.target.value);
                                            setFormattedsellerAgreedComm(
                                                e.target.value
                                            );
                                            setErrors((prev) => ({
                                                ...prev,
                                                sellerAgreedComm: null
                                            }));
                                        }}
                                        onBlur={async (e) => {
                                            const value = +e.target.value;

                                            const trimmedPrice =
                                                +sellerAgreedSalePrice
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                            const agreedSellingPrice = isNaN(
                                                trimmedPrice
                                            )
                                                ? 0
                                                : trimmedPrice;

                                            if (value < 0) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedComm: '0% min'
                                                }));
                                            }

                                            if (value > 100) {
                                                const confirmBox =
                                                    await confirmCommissonInput(
                                                        <>
                                                            <p className="fw-bold">
                                                                Rate greater
                                                                than 100%!
                                                            </p>
                                                            <p>
                                                                Is this the
                                                                commission
                                                                value?
                                                            </p>
                                                        </>
                                                    );

                                                if (confirmBox) {
                                                    const commRate =
                                                        (value /
                                                            agreedSellingPrice) *
                                                        100;

                                                    setSellerAgreedCommValue(
                                                        formatValue(value)
                                                    );

                                                    setSellerAgreedComm(
                                                        commRate
                                                    );
                                                    setFormattedsellerAgreedComm(
                                                        formatValueToDecimal(
                                                            commRate
                                                        )
                                                    );
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        sellerAgreedComm: null,
                                                        sellerAgreedCommValue:
                                                            null
                                                    }));
                                                    return;
                                                } else {
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        sellerAgreedComm:
                                                            'Cannot be more than 100%'
                                                    }));

                                                    return;
                                                }
                                            }

                                            const agreedCommissionValue =
                                                (agreedSellingPrice * value) /
                                                100;

                                            setSellerAgreedCommValue(
                                                formatValue(
                                                    agreedCommissionValue
                                                )
                                            );

                                            setErrors((prev) => ({
                                                ...prev,
                                                sellerAgreedComm: null,
                                                sellerAgreedCommValue: null
                                            }));
                                        }}
                                    />
                                </div>
                                {errors && errors?.sellerAgreedComm && (
                                    <small className="ff-italic text-danger">
                                        {errors?.sellerAgreedComm}
                                    </small>
                                )}
                            </div>
                        </Col>

                        <Col sm="6">
                            <div className="form-control-wrap">
                                <div
                                    className={`input-group ${
                                        errors?.sellerAgreedCommValue &&
                                        'border border-danger'
                                    }`}
                                >
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            AED
                                        </span>
                                    </div>
                                    <Input
                                        placeholder="Enter Agreed Commission (AED)"
                                        value={sellerAgreedCommValue}
                                        type="text"
                                        onChange={(e) => {
                                            const value =
                                                +e.target.value?.replaceAll(
                                                    ',',
                                                    ''
                                                );

                                            if (value > 0) {
                                                setSellerAgreedCommValue(
                                                    formatValue(value)
                                                );
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedComm: null,
                                                    sellerAgreedCommValue: null
                                                }));
                                                return;
                                            }

                                            setSellerAgreedCommValue(
                                                e.target.value
                                            );
                                        }}
                                        onBlur={(e) => {
                                            const value = +e.target.value
                                                ?.trim()
                                                ?.replaceAll(',', '');

                                            const trimmedPrice =
                                                +sellerAgreedSalePrice
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                            const agreedSellingPrice = isNaN(
                                                trimmedPrice
                                            )
                                                ? 0
                                                : trimmedPrice;

                                            if (value > agreedSellingPrice) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedCommValue:
                                                        'Cannot be more than sale price'
                                                }));
                                                return;
                                            }

                                            const sellerAgreedComm =
                                                (value / agreedSellingPrice ??
                                                    1) * 100;

                                            setSellerAgreedComm(
                                                sellerAgreedComm
                                            );
                                            setFormattedsellerAgreedComm(
                                                formatValueToDecimal(
                                                    sellerAgreedComm
                                                )
                                            );
                                        }}
                                    />
                                </div>
                                {errors && errors?.sellerAgreedCommValue && (
                                    <small className="ff-italic text-danger">
                                        {errors?.sellerAgreedCommValue}
                                    </small>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <AddNewAgency
                        isOpen={openAddAgency}
                        toggle={toggleModalAddAgency}
                        callback={(agency) => {
                            setSelectedAgencySeller({
                                ...agency,
                                value:
                                    agency?.agency_name ??
                                    `${agency?.first_name ?? ''} ${
                                        agency?.last_name ?? ''
                                    }`,
                                label:
                                    agency?.agency_name ??
                                    `${agency?.first_name ?? ''} ${
                                        agency?.last_name ?? ''
                                    }`
                            });
                        }}
                    />
                </PreviewCard>
            );
        }
        return null;
    };

    const renderBuyersRows = () => {
        if (
            selectedBuyerType &&
            selectedBuyerType.value === DealClientType.Alba
        ) {
            return (
                <PreviewCard className="bg-lighter">
                    <Row>
                        <Col sm="11" className="mb-2">
                            <div className="form-group">
                                <label className="form-label">
                                    <h6>Choose Agent Assigned To Client</h6>
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        {selectedSellerType?.value ===
                                            DealClientType?.Alba &&
                                        selectedAgentSeller &&
                                        selectedAgentSeller?.user?.id ===
                                            loggedInUser?.id ? (
                                            <RSelect
                                                options={AgentOptions}
                                                value={selectedAgentBuyer}
                                                onChange={(agent) => {
                                                    setSelectedAgentBuyer(
                                                        agent
                                                    );
                                                    setSelectedBuyer(null);

                                                    if (
                                                        agent?.id ===
                                                        defaultAgent?.id
                                                    ) {
                                                        setClientOptions(
                                                            users &&
                                                                users?.length
                                                                ? users?.map(
                                                                      (
                                                                          user
                                                                      ) => ({
                                                                          ...user,
                                                                          label: `${
                                                                              user?.first_name ??
                                                                              ''
                                                                          } ${
                                                                              user?.last_name ??
                                                                              ''
                                                                          } ${
                                                                              `***${user?.phone.slice(
                                                                                  -4
                                                                              )}` ??
                                                                              ''
                                                                          }`,
                                                                          value: `${
                                                                              user?.first_name ??
                                                                              ''
                                                                          } ${
                                                                              user?.last_name ??
                                                                              ''
                                                                          }`
                                                                      })
                                                                  )
                                                                : []
                                                        );
                                                    } else {
                                                        setClientOptions([]);
                                                    }
                                                }}
                                                isDisabled={
                                                    selectedSellerType?.value ===
                                                        DealClientType?.External ||
                                                    selectedAgentSeller?.user
                                                        ?.id !==
                                                        loggedInUser?.id
                                                }
                                                required
                                            />
                                        ) : (
                                            <Input
                                                value={
                                                    selectedAgentBuyer?.value
                                                }
                                                disabled
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Label>Choose Client</Label>
                        <Col sm="10">
                            <RSelect
                                options={clientOptions}
                                placeholder="Search clients by name or phone"
                                onChange={(client) => {
                                    setSelectedBuyer({
                                        ...client,
                                        agent: client?.currentAgent
                                    });

                                    setSelectedAgencyBuyer(null);
                                    setErrors((prev) => ({
                                        ...prev,
                                        selectedBuyer: null
                                    }));
                                }}
                                value={selectedBuyer}
                                className={
                                    errors && errors?.selectedBuyer && 'border border-danger'
                                }
                                menuPlacement="auto"
                                inputValue={searchBuyerClientText}
                                menuIsOpen={searchBuyerClientText?.length}
                                loadingMessage={() => <Spinner size="sm" color="light" />}
                                onInputChange={(txt) => {
                                    setSearchBuyerClientText(txt);
                                    debounceSearchClients(txt);
                                }}
                                required
                                isLoading={isSearchingClient}
                            />
                            {errors && errors.selectedBuyer && (
                                <small className="ff-italic text-danger">
                                    {errors?.selectedBuyer}
                                </small>
                            )}
                        </Col>
                        {selectedAgentBuyer &&
                            selectedAgentBuyer?.user?.id ===
                                loggedInUser?.id && (
                                <Col>
                                    <Button
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            toggleModalAddClient();
                                            setClientToAddType('buyer');
                                        }}
                                    >
                                        <Icon name="plus"></Icon>
                                    </Button>
                                </Col>
                            )}

                        <Label className="mt-2">Agreed Commission</Label>

                        <Col sm="5">
                            <div className="form-control-wrap">
                                <div
                                    className={`input-group ${
                                        errors &&
                                        errors?.buyerAgreedComm &&
                                        'border border-danger'
                                    }`}
                                >
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            %
                                        </span>
                                    </div>
                                    <Input
                                        placeholder="Enter Agreed Commission (%)"
                                        value={formattedbuyerAgreedComm}
                                        type="number"
                                        onKeyDown={(e) => {
                                            const notAllowedKeys = [
                                                'e',
                                                'E',
                                                '+',
                                                '-'
                                            ];

                                            if (
                                                notAllowedKeys.includes(e.key)
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setBuyerAgreedComm(e.target.value);
                                            setFormattedbuyerAgreedComm(
                                                e.target.value
                                            );
                                            setErrors((prev) => ({
                                                ...prev,
                                                buyerAgreedComm: ''
                                            }));
                                        }}
                                        onBlur={async (e) => {
                                            const value = +e.target.value;

                                            const trimmedPrice =
                                                +sellerAgreedSalePrice
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                            const agreedSellingPrice = isNaN(
                                                trimmedPrice
                                            )
                                                ? 0
                                                : trimmedPrice;

                                            if (value < 0) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedComm: '0% min'
                                                }));
                                            }

                                            if (value > 100) {
                                                const confirmBox =
                                                    await confirmCommissonInput(
                                                        <>
                                                            <p className="fw-bold">
                                                                Rate greater
                                                                than 100%!
                                                            </p>
                                                            <p>
                                                                Is this the
                                                                commission
                                                                value?
                                                            </p>
                                                        </>
                                                    );

                                                if (confirmBox) {
                                                    const commRate =
                                                        (value /
                                                            agreedSellingPrice) *
                                                        100;

                                                    setBuyerAgreedCommValue(
                                                        formatValue(value)
                                                    );

                                                    setBuyerAgreedComm(
                                                        commRate
                                                    );
                                                    setFormattedbuyerAgreedComm(
                                                        formatValueToDecimal(
                                                            commRate
                                                        )
                                                    );
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        buyerAgreedComm:
                                                            value >
                                                            agreedSellingPrice
                                                                ? 'Cannot be more than 100%'
                                                                : '',
                                                        buyerAgreedCommValue:
                                                            value >
                                                            agreedSellingPrice
                                                                ? 'Cannot be more than sale price'
                                                                : ''
                                                    }));
                                                    return;
                                                } else {
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        buyerAgreedComm:
                                                            'Cannot be more than 100%'
                                                    }));

                                                    return;
                                                }
                                            }

                                            const agreedCommissionValue =
                                                (agreedSellingPrice * value) /
                                                100;

                                            setBuyerAgreedCommValue(
                                                formatValue(
                                                    agreedCommissionValue
                                                )
                                            );

                                            setErrors((prev) => ({
                                                ...prev,
                                                buyerAgreedComm: null,
                                                buyerAgreedCommValue: null
                                            }));
                                        }}
                                    />
                                </div>
                                {errors && errors?.buyerAgreedComm && (
                                    <small className="ff-italic text-danger">
                                        {errors?.buyerAgreedComm}
                                    </small>
                                )}
                            </div>
                        </Col>
                        <Col sm="5">
                            <div className="form-control-wrap">
                                <div
                                    className={`input-group ${
                                        errors &&
                                        errors?.buyerAgreedCommValue &&
                                        'border border-danger'
                                    }`}
                                >
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            AED
                                        </span>
                                    </div>
                                    <Input
                                        placeholder="Enter Agreed Commission (AED)"
                                        value={buyerAgreedCommValue}
                                        type="text"
                                        onChange={(e) => {
                                            const value =
                                                +e.target.value?.replaceAll(
                                                    ',',
                                                    ''
                                                );

                                            if (value > 0) {
                                                setBuyerAgreedCommValue(
                                                    formatValue(value)
                                                );

                                                setErrors((prev) => ({
                                                    ...prev,
                                                    buyerAgreedComm: null,
                                                    buyerAgreedCommValue: null
                                                }));

                                                return;
                                            }

                                            setBuyerAgreedCommValue(
                                                e.target.value
                                            );
                                        }}
                                        onBlur={(e) => {
                                            const value = +e.target.value
                                                ?.trim()
                                                ?.replaceAll(',', '');

                                            const trimmedPrice =
                                                +sellerAgreedSalePrice
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                            const agreedSellingPrice = isNaN(
                                                trimmedPrice
                                            )
                                                ? 0
                                                : trimmedPrice;

                                            if (value > agreedSellingPrice) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    buyerAgreedCommValue:
                                                        'Cannot be more than sale price'
                                                }));
                                                return;
                                            }

                                            const commRate =
                                                (value / agreedSellingPrice) *
                                                100;
                                            setBuyerAgreedComm(commRate);
                                            setFormattedbuyerAgreedComm(
                                                formatValueToDecimal(commRate)
                                            );
                                        }}
                                    />
                                </div>

                                {errors && errors?.buyerAgreedCommValue && (
                                    <small className="ff-italic text-danger">
                                        {errors?.buyerAgreedCommValue}
                                    </small>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Modal
                        size="lg"
                        isOpen={openAddClient}
                        toggle={toggleModalAddClient}
                        backdrop="static"
                    >
                        <ModalHeader
                            toggle={toggleModalAddClient}
                            close={
                                <button
                                    className="close"
                                    onClick={toggleModalAddClient}
                                >
                                    <Icon name="cross" />
                                </button>
                            }
                        >
                            Add New Client
                        </ModalHeader>
                        <ModalBody>
                            <AddLeadStepOne
                                agent={defaultAgent?.id}
                                callback={async (data) => {
                                    const type = clientToAddType;

                                    try {
                                        setSelectedBuyer({
                                            ...data,
                                            value: `${data?.first_name ?? ''} ${
                                                data?.last_name ?? ''
                                            }`,
                                            label: `${data?.first_name ?? ''} ${
                                                data?.last_name ?? ''
                                            }`
                                        });

                                        setopenAddClient(!openAddClient);
                                    } catch (err) {
                                        console.log(err);
                                    }
                                }}
                            />
                        </ModalBody>
                    </Modal>
                </PreviewCard>
            );
        } else if (
            selectedBuyerType &&
            selectedBuyerType.value === DealClientType.External
        ) {
            return (
                <PreviewCard className="bg-lighter">
                    <Row className="gy-2">
                        <Label>Choose Agency *</Label>
                        <Col sm="10">
                            <RSelect
                                options={Agencies}
                                placeholder="Choose Agency"
                                onChange={(data) => {
                                    setSelectedAgencyBuyer(data);
                                    setSelectedBuyer(null);
                                    setErrors((prev) => ({
                                        ...prev,
                                        selectedAgencyBuyer: null
                                    }));
                                }}
                                value={selectedAgencyBuyer}
                                className={
                                    errors &&
                                    errors?.selectedAgencyBuyer &&
                                    'border border-danger'
                                }
                            />

                            {errors && errors?.selectedAgencyBuyer && (
                                <small className="text-danger ff-italic">
                                    {errors?.selectedAgencyBuyer}
                                </small>
                            )}
                        </Col>
                        <Col>
                            <Button
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    toggleModalAddAgency();
                                }}
                            >
                                <Icon name="plus"></Icon>
                            </Button>
                        </Col>

                        <Label>Agent Name</Label>
                        <Col sm="11">
                            <Input
                                className={
                                    errors.buyerAgencyAgentName &&
                                    'border border-danger'
                                }
                                placeholder="Enter Agent Name"
                                name="agent"
                                value={buyerAgency?.agent}
                                onChange={(e) => {
                                    const { value } = e.target;

                                    setBuyerAgency((prev) => ({
                                        ...prev,
                                        agent: value
                                    }));

                                    setErrors((prev) => ({
                                        ...prev,
                                        buyerAgencyAgentName:
                                            value?.length > 100
                                                ? 'Name too large. Max of 100 characters allowed'
                                                : ''
                                    }));
                                }}
                            />
                            {errors?.buyerAgencyAgentName && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.buyerAgencyAgentName}
                                    </small>
                                </div>
                            )}
                        </Col>
                        <Label>Agent Phone Number</Label>
                        <Col sm="11">
                            <PhoneInput
                                className={
                                    errors?.buyerAgencyAgentPhone &&
                                    'border border-danger'
                                }
                                defaultCountry="AE"
                                name="phone"
                                placeholder="Enter Agent Phone Number"
                                onChange={(phone) =>
                                    setBuyerAgency((prev) => ({
                                        ...prev,
                                        agentPhone: phone
                                    }))
                                }
                                value={buyerAgency?.agentPhone}
                                onError={(err) => {
                                    setErrors((prev) => ({
                                        ...prev,
                                        buyerAgencyAgentPhone: err
                                    }));
                                }}
                            />
                            {errors?.buyerAgencyAgentPhone && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.buyerAgencyAgentPhone}
                                    </small>
                                </div>
                            )}
                        </Col>
                        <Label>Client Name</Label>
                        <Col sm="11">
                            <Input
                                className={
                                    errors.buyerAgencyClientName &&
                                    'border border-danger'
                                }
                                placeholder="Enter Client Name "
                                name="clientName"
                                value={buyerAgency?.clientName}
                                onChange={(e) => {
                                    const { value } = e.target;

                                    setBuyerAgency((prev) => ({
                                        ...prev,
                                        clientName: value
                                    }));

                                    setErrors((prev) => ({
                                        ...prev,
                                        buyerAgencyClientName:
                                            value?.length > 100
                                                ? 'Name too large. Max of 100 characters allowed'
                                                : ''
                                    }));
                                }}
                            />
                            {errors?.buyerAgencyAgentName && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.buyerAgencyClientName}
                                    </small>
                                </div>
                            )}
                        </Col>
                        <Label>Client Phone Number</Label>
                        <Col sm="11">
                            <PhoneInput
                                className={`phoneInput ${
                                    errors?.buyerAgencyClientPhone &&
                                    'border border-danger'
                                } `}
                                defaultCountry="AE"
                                name="phone"
                                placeholder="Enter Client Phone Number"
                                value={buyerAgency?.clientPhone}
                                onChange={(phone) =>
                                    setBuyerAgency((prev) => ({
                                        ...prev,
                                        clientPhone: phone
                                    }))
                                }
                                onError={(err) => {
                                    setErrors((prev) => ({
                                        ...prev,
                                        buyerAgencyClientPhone: err
                                    }));
                                }}
                            />
                            {errors?.buyerAgencyClientPhone && (
                                <div>
                                    <small className="text-danger ff-italic">
                                        {errors?.buyerAgencyClientPhone}
                                    </small>
                                </div>
                            )}
                        </Col>

                        <Label className="mt-2">Agreed Commission</Label>

                        <Col sm="5">
                            <div className="form-control-wrap">
                                <div
                                    className={`input-group ${
                                        errors &&
                                        errors?.buyerAgreedComm &&
                                        'border border-danger'
                                    }`}
                                >
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            %
                                        </span>
                                    </div>
                                    <Input
                                        max={100}
                                        placeholder="Enter Agreed Commission (%)"
                                        value={formattedbuyerAgreedComm}
                                        type="number"
                                        onKeyDown={(e) => {
                                            const notAllowedKeys = [
                                                'e',
                                                'E',
                                                '+',
                                                '-'
                                            ];

                                            if (
                                                notAllowedKeys.includes(e.key)
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setBuyerAgreedComm(e.target.value);
                                            setFormattedbuyerAgreedComm(
                                                e.target.value
                                            );
                                            setErrors((prev) => ({
                                                ...prev,
                                                buyerAgreedComm: ''
                                            }));
                                        }}
                                        onBlur={async (e) => {
                                            const value = +e.target.value;

                                            const trimmedPrice =
                                                +sellerAgreedSalePrice
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                            const agreedSellingPrice = isNaN(
                                                trimmedPrice
                                            )
                                                ? 0
                                                : trimmedPrice;

                                            if (value < 0) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    sellerAgreedComm: '0% min'
                                                }));
                                            }

                                            if (value > 100) {
                                                const confirmBox =
                                                    await confirmCommissonInput(
                                                        <>
                                                            <p className="fw-bold">
                                                                Rate greater
                                                                than 100%!
                                                            </p>
                                                            <p>
                                                                Is this the
                                                                commission
                                                                value?
                                                            </p>
                                                        </>
                                                    );

                                                if (confirmBox) {
                                                    const commRate =
                                                        (value /
                                                            agreedSellingPrice) *
                                                        100;

                                                    setBuyerAgreedCommValue(
                                                        formatValue(value)
                                                    );

                                                    setBuyerAgreedComm(
                                                        commRate
                                                    );
                                                    setFormattedbuyerAgreedComm(
                                                        formatValueToDecimal(
                                                            commRate
                                                        )
                                                    );
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        buyerAgreedComm:
                                                            value >
                                                            agreedSellingPrice
                                                                ? 'Cannot be more than 100%'
                                                                : '',
                                                        buyerAgreedCommValue:
                                                            value >
                                                            agreedSellingPrice
                                                                ? 'Cannot be more than sale price'
                                                                : ''
                                                    }));
                                                    return;
                                                } else {
                                                    setErrors((prev) => ({
                                                        ...prev,
                                                        buyerAgreedComm:
                                                            'Cannot be more than 100%'
                                                    }));

                                                    return;
                                                }
                                            }

                                            const agreedCommissionValue =
                                                (agreedSellingPrice * value) /
                                                100;

                                            setBuyerAgreedCommValue(
                                                formatValue(
                                                    agreedCommissionValue
                                                )
                                            );

                                            setErrors((prev) => ({
                                                ...prev,
                                                buyerAgreedComm: null,
                                                buyerAgreedCommValue: null
                                            }));
                                        }}
                                    />
                                </div>
                                {errors && errors?.buyerAgreedComm && (
                                    <small className="ff-italic text-danger">
                                        {errors?.buyerAgreedComm}
                                    </small>
                                )}
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="form-control-wrap">
                                <div
                                    className={`input-group ${
                                        errors &&
                                        errors?.buyerAgreedCommValue &&
                                        'border border-danger'
                                    }`}
                                >
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            AED
                                        </span>
                                    </div>
                                    <Input
                                        placeholder="Enter Agreed Commission (AED)"
                                        value={buyerAgreedCommValue}
                                        type="text"
                                        onChange={(e) => {
                                            const value =
                                                +e.target.value?.replaceAll(
                                                    ',',
                                                    ''
                                                );

                                            if (value > 0) {
                                                setBuyerAgreedCommValue(
                                                    formatValue(value)
                                                );

                                                setErrors((prev) => ({
                                                    ...prev,
                                                    buyerAgreedComm: '',
                                                    buyerAgreedCommValue: ''
                                                }));

                                                return;
                                            }

                                            setBuyerAgreedCommValue(
                                                e.target.value
                                            );
                                        }}
                                        onBlur={(e) => {
                                            const value = +e.target.value
                                                ?.trim()
                                                ?.replaceAll(',', '');

                                            const trimmedPrice =
                                                +sellerAgreedSalePrice
                                                    ?.trim()
                                                    ?.replaceAll(',', '');

                                            const agreedSellingPrice = isNaN(
                                                trimmedPrice
                                            )
                                                ? 0
                                                : trimmedPrice;

                                            if (value > agreedSellingPrice) {
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    buyerAgreedCommValue:
                                                        'Cannot be more than sale price'
                                                }));
                                                return;
                                            }

                                            const commRate =
                                                (value / agreedSellingPrice) *
                                                100;
                                            setBuyerAgreedComm(commRate);
                                            setFormattedbuyerAgreedComm(
                                                formatValueToDecimal(commRate)
                                            );
                                        }}
                                    />
                                </div>

                                {errors && errors?.buyerAgreedCommValue && (
                                    <small className="ff-italic text-danger">
                                        {errors?.buyerAgreedCommValue}
                                    </small>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <AddNewAgency
                        isOpen={openAddAgency}
                        toggle={toggleModalAddAgency}
                        callback={(agency) => {
                            setSelectedAgencyBuyer({
                                ...agency,
                                value:
                                    agency?.agency_name ??
                                    `${agency?.first_name ?? ''} ${
                                        agency?.last_name ?? ''
                                    }`,
                                label:
                                    agency?.agency_name ??
                                    `${agency?.first_name ?? ''} ${
                                        agency?.last_name ?? ''
                                    }`
                            });
                        }}
                    />
                </PreviewCard>
            );
        }
        return null;
    };

    return (
        <Row className="gy-4">
            <Col sm="12">
                <div className="form-group">
                    <Label className="form-label">
                        <h6>Choose Purpose</h6>
                    </Label>
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <RSelect
                                options={Purposes}
                                onChange={(purpose) => {
                                    setSelectedPurpose(purpose);
                                    setErrors((prev) => ({
                                        ...prev,
                                        purpose: null
                                    }));
                                }}
                                value={selectedPurpose}
                                className={
                                    errors &&
                                    errors?.purpose &&
                                    'border border-danger'
                                }
                            />
                            {errors && errors.purpose && (
                                <small className="text-danger ff-italic">
                                    {errors?.purpose}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm="12">
                <div className="form-group">
                    <label className="form-label">
                        <h6>Choose Seller/Landlord</h6>
                    </label>
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <RSelect
                                options={ClientType}
                                onChange={handleSellerTypeChange}
                                value={selectedSellerType}
                                className={
                                    errors &&
                                    errors.sellerType &&
                                    'border border-danger'
                                }
                            />
                            {errors && errors.sellerType && (
                                <small className="text-danger ff-italic">
                                    {errors?.sellerType}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
            </Col>
            {renderSellersRows()}

            {selectedAgentSeller && selectedSellerType?.value && (
                <>
                    <Col sm={12}>
                        <div className="form-group">
                            <label className="form-label">
                                <h6>Choose Buyer/Tenant</h6>
                            </label>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    {(selectedSellerType &&
                                        selectedSellerType?.value ===
                                            DealClientType.External) ||
                                    selectedAgentSeller?.user?.id !==
                                        loggedInUser?.id ? (
                                        <Input
                                            value={selectedBuyerType?.value}
                                            disabled
                                        />
                                    ) : (
                                        <RSelect
                                            options={ClientType}
                                            onChange={handleBuyerTypeChange}
                                            className={
                                                errors &&
                                                errors?.buyerType &&
                                                'border border-danger'
                                            }
                                            value={selectedBuyerType}
                                        />
                                    )}

                                    {errors && errors.buyerType && (
                                        <small className="text-danger ff-italic">
                                            {errors?.buyerType}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                    {renderBuyersRows()}
                    <Col
                    sm="12"
                    className="bg-secondary mt-4 mb-3 pt-2 pb-2 rounded"
                >
                    <h6 className="text-white">Mortgage Details</h6>
                </Col>
                
                <Col
                sm="3"
                className={`d-flex align-items-center ${errors?.vacancy ? "align-self-center" : "align-self-end mb-2"}`}
                style={{ gap: "1.5rem" }}
                >
                <div className="custom-control custom-switch" style={{ flexGrow: 2 }}>
                    <input
                    type="checkbox"
                    className={`custom-control-input`}
                    id="customSwitch1"
                    name="isMortgage"
                    onChange={(e) => {
                        setIsMortgage(e.target.checked);
                    }}
                    />
                    <label className="custom-control-label" for="customSwitch1">
                    Is Mortgage
                    </label>
                </div>
                </Col>
                <Col sm="9">
                <Collapse isOpen={isMortgage}>
                    <Label>Mortgage Provider</Label>
                    <Input
                        className={`${
                            errors?.unitId && `border border-danger`
                        }`}
                        placeholder="Mortgage Provider"
                        onChange={(e) => {
                            setMortgageProvider(e.target.value);
                        }}
                    />
                </Collapse>
                </Col>

                <Col
                sm="3"
                className={`d-flex align-items-center ${errors?.isSellerMortgage ? "align-self-center" : "align-self-end mb-2"}`}
                style={{ gap: "1.5rem" }}
                >
                <div className="custom-control custom-switch" style={{ flexGrow: 2 }}>
                    <input
                    type="checkbox"
                    className={`custom-control-input`}
                    id="customSwitch2"
                    name="isSellerMortgage"
                    onChange={(e) => {
                        setIsSellerMortgage(e.target.checked);
                        setErrors((prev) => ({
                        ...prev,
                        isSellerMortgage: null,
                        }));
                    }}
                    />
                    <label className="custom-control-label" for="customSwitch2">
                    Seller Mortgage
                    </label>
                </div>
                </Col>
                <Col sm="9">
                <Collapse isOpen={isSellerMortgage}>
                    <Label>Seller Mortgage Provider</Label>
                    <Input
                        className={`${
                            errors?.sellerMortgageProvider && `border border-danger`
                        }`}
                        placeholder="Mortgage Provider"
                        value={sellerMortgageProvider}
                        onChange={(e) => {
                            setSellerMortgageProvider(e.target.value);
                        }}
                    />
                </Collapse>
                </Col>
                
                    <div className="clearfix">
                        <ul>
                            <li>
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={addNewDeal}
                                    disabled={
                                        Object.entries(errors).find(
                                            ([key, value]) =>
                                                value || value?.length > 0
                                        ) || loading
                                    }
                                >
                                    {loading ? (
                                        <>
                                            <Spinner size="sm" color="light" />
                                            Please Wait
                                        </>
                                    ) : (
                                        'Next'
                                    )}
                                </Button>
                            </li>
                        </ul>
                    </div>
                </>
            )}
                
            <Modal
                size="xl"
                isOpen={openAddProperty}
                toggle={toggleModalAddProperty}
                backdrop="static"
            >
                <ModalHeader
                    toggle={toggleModalAddProperty}
                    close={
                        <button
                            className="close"
                            onClick={toggleModalAddProperty}
                        >
                            <Icon name="cross" />
                        </button>
                    }
                >
                    Add New Property
                </ModalHeader>
                <ModalBody>
                    <Card className="card-bordered">
                        <PreviewCard>
                            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                                <Form property={property} type="add" />
                            </div>
                        </PreviewCard>
                    </Card>
                </ModalBody>
            </Modal>

            <ConfirmModal
                isOpen={commissionIsOpen}
                cancel={() => commissionCancel(false)}
                proceed={() => commisionProceed(true)}
                prompt={commissionPrompt}
                cancelColor="secondary"
                cancelText="Cancel"
                proceedColor="primary"
                proceedText="Yes"
                // isLoading={tre}
            />
        </Row>
    );
}
