import React, { useEffect, useRef, useState } from "react";

import { formatDistance } from "date-fns";
import { defaultUserImage, getImage } from "../../../utils/envConfig";
import { useSelector } from "react-redux";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Button, Form, Input } from "reactstrap";
import { Icon } from "../../../components/Component";
import CommentCard from "./CommentCard";

export default function ActivityCard({ feed = {} }) {
    const formRef = useRef();

    const [image, setImage] = useState(defaultUserImage);

    const [date, setDate] = useState(new Date());

    const [commentText, setCommentText] = useState("");

    const [comments, setComments] = useState();

    const [showAllComments, setShowAllComments] = useState(false);

    const [commentDetails, setCommentDetails] = useState(null);

    const authuser = useSelector((state) => state?.user?.loggedInUser);

    const axios = useAxiosPrivate();

    const fetchImage = async () => {
        try {
            if (!feed?.created_by?.photo) {
                return;
            }

            const image = await getImage(feed?.created_by?.photo, axios);
            setImage(image?.signedRequest);
        } catch (err) {
            console.log(err);
        }
    };

    const getFeedDetails = async () => {
        try {
            const { data } = await axios.get(
                `/reports/main/live-feed/${feed?._id}/comments-details`
            );

            setCommentDetails(data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        feed?.created_by?.photo && fetchImage();

        getFeedDetails();

        const interval = setInterval(() => {
            setDate(new Date());
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    const submitComment = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axios.post(
                `/reports/main/live-feed/feed-comment`,
                {
                    comment: commentText,
                    feedId: feed?._id ?? feed?.id,
                }
            );

            setCommentDetails((prev) => ({
                totalComments: (prev?.totalComments ?? 0) + 1,
                lastComment: data,
            }));
        } catch (err) {
            console.log(err);
        } finally {
            setCommentText("");
        }
    };

    const getAllFeedComments = async () => {
        try {
            if (!comments && !comments?.length) {
                const { data } = await axios.get(
                    `/reports/main/live-feed/${feed?._id}/comments`
                );

                setComments(data);
            }

            setShowAllComments(true);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div
            to={feed?.page_url ?? null}
            className="activity-card-cont text-dark bg-white"
        >
            <div className="card-inner">
                <div className="content-wrapper">
                    <div className="img-cont">
                        <img
                            src={image}
                            alt="Activity creator"
                            className="img"
                        />
                    </div>
                    <div className="content">
                        <span className="creator">
                            {!feed?.created_by || !feed.created_by?.first_name
                                ? "System"
                                : feed?.created_by?._id === authuser?.id
                                ? "You"
                                : `${feed?.created_by?.first_name} ${feed?.created_by?.last_name}`}
                        </span>{" "}
                        <span>{feed?.message}</span>
                        <div>
                            <span
                                className="time-ago text-center lcap text-muted fw-light "
                                style={{ fontSize: "14px" }}
                            >
                                {feed?.createdAt &&
                                    formatDistance(
                                        new Date(feed?.createdAt),
                                        date,
                                        {
                                            includeSeconds: true,
                                            addSuffix: true,
                                        }
                                    )
                                        ?.replace("about ", "")
                                        ?.replace("minutes", " mins")
                                        ?.replace("minute", " min")
                                        ?.replace("seconds", " secs")
                                        ?.replace("hours", " hrs")
                                        ?.replace("hour", " hr")
                                        .replace("years", " yrs")
                                        .replace("year", " yr")
                                        .replace("months", " mths")
                                        .replace("month", " mth")
                                        .replace("weeks", " wks")
                                        .replace("week", " wk")
                                        ?.replace("less than ", "")}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="card-actions  border-top  border-light mt-4 pt-2 pb-2">
                    <Form
                        className="comment-form"
                        onSubmit={submitComment}
                        ref={formRef}
                    >
                        <Input
                            placeholder="Type your comment here."
                            className="pt-1 pb-1 ps-2 pe-2 border-0"
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                        />
                        {commentText && (
                            <Button className="bg-transparent border-0 comment-submit-btn">
                                <Icon
                                    name="send"
                                    className={"text-primary"}
                                    style={{ fontSize: 25 }}
                                />
                            </Button>
                        )}
                    </Form>

                    <span
                        onClick={
                            commentDetails?.totalComments > 1
                                ? getAllFeedComments
                                : (e) => {}
                        }
                        className={`time-ago text-center ${
                            commentDetails?.totalComments > 1 &&
                            "text-secondary"
                        }`}
                    >
                        {commentDetails?.totalComments ?? 0}{" "}
                        {commentDetails?.totalComments > 1
                            ? "Comments"
                            : "Comment"}
                    </span>
                </div>

                {showAllComments && comments && comments?.length ? (
                    <>
                        {comments?.map((comment) => (
                            <CommentCard comment={comment} />
                        ))}
                        <div className="text-end">
                            <small
                                onClick={() => setShowAllComments(false)}
                                className="text-primary"
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                            >
                                Show Less
                            </small>
                        </div>
                    </>
                ) : (
                    commentDetails?.lastComment && (
                        <>
                            <CommentCard
                                comment={commentDetails?.lastComment}
                            />
                            {commentDetails?.totalComments > 1 && (
                                <div className="text-end">
                                    <small
                                        onClick={getAllFeedComments}
                                        className="text-primary"
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        Show All
                                    </small>
                                </div>
                            )}
                        </>
                    )
                )}
            </div>
        </div>
    );
}
