import classNames from "classnames";
import React from "react";

/**
 * A reusable component for the tools section of a table (filters, search, etc).
 *
 * @param {object} props - The component's props.
 * @param {string} [props.id] - The id attribute of the card element.
 * @param {string} [props.className] - Additional CSS classes to apply to the card element.
 * @param {React.CSSProperties} [props.style] - Inline styles to apply to the card element.
 *
 * @example
 * <CardTools className="custom-class" style={{ backgroundColor: 'red' }}>
 *   Card content goes here
 * </CardTools>
 *
 * @returns {JSX.Element} - The rendered card tools component.
 */
const CardTools = ({ className, style, id, children }) => {
  const cardClass = classNames({
    [`${className}`]: className,
    "card-tools": true,
  });

  return <div className={cardClass} style={style ?? {}} id={id}>{children}</div>;
};

export default CardTools;
