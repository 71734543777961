import * as types from "../types/property-cards";
import { HttpStatus } from "../../utils/envConfig";
import { Axios } from "axios";

let abortController = null;

export const fetchAllPropertyCards = (axios, query) => async (dispatch, getState) => {
  const { loading } = getState().propertyCard;
  const url = query ? `/property-cards${query}` : "/property-cards";
  if (loading && abortController) {
    abortController.abort();
  }
  dispatch({ type: types.GET_PROPERTY_CARDS_STARTED }); // Start loading
  try {
    abortController = new AbortController();
    const res = await axios.get(url, { signal: abortController.signal });
    dispatch({
      type: types.GET_PROPERTY_CARDS,
      payload: res.data?.data,
    });
  } catch (error) {
    if (error.name === "AbortError") {
      console.log('Fetch aborted:', url);
    }
    dispatch({
      type: types.GET_PROPERTY_CARDS_FAILED,
      error: error,
    });
  } finally {
    dispatch({ type: types.GET_PROPERTY_CARDS_FINISHED });
  }
};

export const fetchCheckedOutPropCards = (axios, query) => async (dispatch) => {
  dispatch({ type: types.GET_CHECKEDOUT_PROPERTIES_STARTED });
  try {
    const url = query
      ? `/property-cards/checkout-leads?${query}`
      : `/property-cards/checkout-leads`;
    const res = await axios.get(url);
    dispatch({
      type: types.GET_CHECKEDOUT_PROPERTIES,
      payload: res.data?.data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_CHECKEDOUT_PROPERTIES_FAILED,
      error: error,
    });
  }
};

export const fetchLeadPropertyCards = (axios, leadId) => async (dispatch) => {
  /**
   * Fetch all the property cards associated with a certain lead
   */
  try {
    const url = `/property-cards/lead-to-card/?lead=${leadId}`;
    const res = await axios.get(url);
    dispatch({
      type: types.GET_LEAD_PROPERTY_CARDS,
      payload: res?.data?.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchLeadPropertyCardDetail =
  (axios, cardId, extraPopulate = false) =>
  async (dispatch) => {
    /**
     * Fetch a property card details, pass extraPopulate as true to populate fields that would
     * otherwise be IDs
     */
    try {
      const url = extraPopulate
        ? `/property-cards/${cardId}?extraPopulate=true`
        : `/property-cards/${cardId}`;
      const res = await axios.get(url);
      dispatch({
        type: types.GET_PROPERTY_CARD,
        payload: res?.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const fetchPropertyCardLeads = (axios, cardId) => async (dispatch) => {
  /**
   * Fetch all the leads associated with a certain property card
   */
  try {
    const url = `/property-cards/lead-to-card/?propertyCard=${cardId}`;
    const res = await axios.get(url);
    dispatch({
      type: types.GET_PROPERTY_CARD_LEADS,
      payload: res?.data?.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: types.GET_PROPERTY_CARD_LEADS,
      error: error,
    });
  }
};

export const updatePropertyCard = (axios, cardId, payload, callback, successCallback) => async (dispatch) => {
    dispatch({ type: types.UPDATE_PROPERTY_CARD_STARTED });
    try {
      const res = await axios.put(`property-cards/${cardId}`, payload);
      dispatch({ type: types.UPDATE_PROPERTY_CARD, payload: res.data });
      if (successCallback && typeof successCallback === "function") {
        successCallback();
      }
    } catch (error) {
      dispatch({
        type: types.UPDATE_PROPERTY_CARD_FAILED,
        error: error,
      });
    } finally {
      if (callback && typeof callback === "function") {
        callback();
      }
    }
  };

export const assignAgentToPropCards = (axios, agentId, cardIds, callback, successCallback) => async (dispatch) => {
  try {
    const payload = {
      data: {
        agent: agentId,
        cards: Array.isArray(cardIds) ? cardIds : [cardIds],
      }
    }
    const res = await axios.post(`property-cards/assign-new-leads`, payload);
    if (res.status === HttpStatus.OK && successCallback && typeof successCallback === "function") {
      successCallback();
    }
  } catch (error) {
    console.log(error);
  } finally {
    if (callback && typeof callback === "function") {
      callback();
    }
  }
}

export const linkLeadToPropCard = (axios, leadId, cardId, isOwner = false, successCallback) => async (dispatch) => {
  try {
    const payload = {
      leadId,
      propertyCardId: cardId,
      // isCurrentOwner: isOwner ? isOwner : null,
    };
    const res = await axios.post(`property-cards/lead-to-card`, payload);
    dispatch({ type: "LINK_LEAD_TO_CARD" });
    // check if property card is assigned to an agent, and if so, assign it to lead too
    const foundCard = await axios.get(`/property-cards/${cardId}`)?.data ?? null;
    if (foundCard && foundCard.currentAgent) {

    }

    if (
      res.status === HttpStatus.OK &&
      successCallback &&
      typeof successCallback === "function"
    ) {
      successCallback();
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * @description Fetching leads with property cards
 * @param {Axios} axios 
 * @param {import("axios").AxiosRequestConfig} query 
 * @returns 
 */
export const fetchLeadsWithPropertyCards = (axios, options={}) => async (dispatch) => {
  try {

    dispatch({
        type: types.GET_LEADS_WITH_CARDS,
        payload:{
          fetching: true
        }
    });

    const {data} = await axios.get(`property-cards/lead-with-cards`, {
        params: options?.params??{}
    });

    dispatch({
      type: types.GET_LEADS_WITH_CARDS,
      payload: {
        data,
        error: null,
        fetching: false
      }
    })

    
  } catch (error) {
    dispatch({
      type: types.GET_LEADS_WITH_CARDS,
      payload:{
        fetching: false,
        data: null,
        error
      }
    })
  }
}