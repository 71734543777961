import React from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  Icon,
  Button,
} from "../../components/Component";
import { Link } from "react-router-dom";
import { Step, Steps } from "react-step-builder";
import AddLeadStepOne from "./components/AddLeadStepOne";

const CreateLead = () => {
  return (
    <React.Fragment>
      <Head title="Form"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              {/* <Link>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <Button
                  color="light"
                  outline
                  className="btn-icon bg-white d-inline-flex d-sm-none "
                >
                  <Icon name="arrow-left"></Icon>
                </Button>
              </Link> */}
              <BlockTitle page className="mt-4">
                Add New Lead
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
              <Steps>
                <Step component={AddLeadStepOne} />
              </Steps>
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default CreateLead;
