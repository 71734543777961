/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import { AgentUserType } from "../global-types/types";
import ErrorHandler from "../error-handlers/axios/defaultErrorHandler";

/**
 * @typedef {Object} AgentResponse
 * @property {string} id - The unique identifier for the response.
 * @property {string} userId - The unique identifier for the user.
 * @property {Array.<string>} languages - The languages associated with the response.
 * @property {string} RERANo - The RERA number.
 * @property {string} DEDNo - The DED number.
 * @property {string} PERMITNo - The permit number.
 * @property {boolean} RICSCertified - Indicates if RICS certified.
 * @property {boolean} isLeader - Whether the agent is a team leader or not.
 * @property {?string} leaderId - The ID of the agent's leader.
 * @property {AgentUserType} user - The user details.
 * @property {number} creditsBalance - The credits balance.
 * @property {number} creditsLimit - The credits limit.
 * @property {?string} holidayState - The holiday state.
 */

/**
 * @description Fetches agent information specified by user ID
 * @param {Axios} axiosInstance - the axios instance for making the HTTP request.
 * @param {string} userId - the user ID used to fetch the agent.
 * @returns {Promise<AgentResponse>} API Response.
 */
export const fetchAgentByUserId = async (axiosInstance, userId) => {
    try {
      const res = await axiosInstance.get(`/agent/user/${userId}`); 
      return res?.data;
    } catch (error) {
      ErrorHandler.handle(error);
    }
}