//USER TYPES
export const USER_LOGIN = 'USER_LOGIN';
export const GET_USERS_STARTED = 'GET_USERS_STARTED';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_AGENTS = 'GET_AGENTS';
export const GET_AGENTS_LIST = 'GET_AGENTS_LIST';
export const GET_AGENTS_PAGINATED='GET_AGENTS_PAGINATED';
export const GET_AGENTS_PAGINATED_STARTED='GET_AGENTS_PAGINATED_STARTED';
export const GET_AGENTS_PAGINATED_ERR='GET_AGENTS_PAGINATED_ERR';
export const GET_AGENTS_LIST_STARTED = 'GET_AGENTS_LIST_STARTED';
export const GET_AGENTS_LIST_FAILED = 'GET_AGENTS_LIST_FAILED';
export const GET_ONLY_AGENTS = 'GET_ONLY_AGENTS';
export const GET_USER_AGENTS = 'GET_USER_AGENTS';
export const GET_OWNERS = 'GET_OWNERS';
export const GET_ONLY_USERS = 'GET_ONLY_USERS';
export const LOGOUT = 'LOGOUT';
//  GET AGENT BY USER
export const GET_AUTH_AGENT_STARTED = 'GET_AUTH_AGENT_STARTED';
export const GET_AUTH_AGENT_FINISHED = 'GET_AUTH_AGENT_FINISHED';
export const GET_AUTH_AGENT_FAILED = 'GET_AUTH_AGENT_FAILED';
export const GET_AUTH_AGENT = 'GET_AUTH_AGENT';

export const GET_AGENT_BY_USER_ID = 'GET_AGENT_BY_USER_ID';
export const GET_AGENT_BY_USER_ID_STARTED = 'GET_AGENT_BY_USER_ID_STARTED';
export const GET_AGENT_BY_USER_ID_FAILED = 'GET_AGENT_BY_USER_ID_FAILED';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';

// PROPERTY TYPES
export const GET_FEATURED_PROPERTIES = 'GET_FEATURED_PROPERTIES';
export const GET_DEAL_OF_THE_WEEK = 'GET_DEAL_OF_THE_WEEK';
export const GET_PROPERTIES = 'GET_PROPERTIES';
export const GET_PROPERTIES_ADMIN = 'GET_PROPERTIES_ADMIN';
export const GET_PROPERTY = 'GET_PROPERTY';
export const GET_AMENITIES = 'GET_AMENITIES';
export const GET_AMENITIES_LIST_STARTED = "GET_AMENITIES_LIST_STARTED";
export const GET_AMENITIES_LIST = "GET_AMENITIES_LIST";
export const GET_AMENITIES_LIST_FAILED = "GET_AMENITIES_LIST_FAILED";
export const GET_COMMUNITIES = 'GET_COMMUNITIES';
export const GET_COMMUNITIES_LIST_STARTED = 'GET_COMMUNITIES_LIST_STARTED';
export const GET_COMMUNITIES_LIST = 'GET_COMMUNITIES_LIST';
export const GET_COMMUNITIES_LIST_FAILED = 'GET_COMMUNITIES_LIST_FAILED';
export const GET_FILTERED_COMMUNITIES_STARTED =
    'GET_FILTERED_COMMUNITIES_STARTED';
export const GET_FILTERED_COMMUNITIES = 'GET_FILTERED_COMMUNITIES';
export const GET_FILTERED_COMMUNITIES_FAILED =
    'GET_FILTERED_COMMUNITIES_FAILED';
export const GET_FILTERED_AMENITIES_STARTED = 'GET_FILTERED_AMENITIES_STARTED';
export const GET_FILTERED_AMENITIES = 'GET_FILTERED_AMENITIES';
export const GET_FILTERED_AMENITIES_FAILED = 'GET_FILTERED_AMENITIES_FAILED';
export const GET_PROP_TYPES = 'GET_PROP_TYPES';
export const GET_PROP_TYPES_LIST = 'GET_PROP_TYPES_LIST';
export const GET_PROP_TYPES_STARTED = 'GET_PROP_TYPES_STARTED';
export const GET_PROP_TYPES_FAILED = 'GET_PROP_TYPES_FAILED';
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_AM_CATEGORY = 'GET_AM_CATEGORY';
export const GET_BUILDING = 'GET_BUILDING';
export const GET_BUILDING_STARTED = 'GET_BUILDING_STARTED';
export const GET_BUILDING_FAILED = 'GET_BUILDING_FAILED';
export const GET_BUILDINGS_STARTED = 'GET_BUILDINGS_STARTED';
export const GET_BUILDINGS = 'GET_BUILDINGS';
export const GET_BUILDINGS_FAILED = 'GET_BUILDINGS_FAILED';
export const CREATE_BUILDING = 'CREATE_BUILDING';
export const CREATE_BUILDING_FAILED = 'CREATE_BUILDING_FAILED';
export const CREATE_BUILDING_STARTED = 'CREATE_BUILDING_STARTED';
export const GET_OWNER_PROPERTIES = 'GET_OWNER_PROPERTIES';

// XML
export const GET_XML = 'GET_XML';
export const GET_XML_STARTED = 'GET_XML_STARTED';
export const GET_XML_FAILED = 'GET_XML_FAILED';

export const GET_SINGLE_XML = 'GET_SINGLE_XML';
export const GET_SINGLE_XML_STARTED = 'GET_SINGLE_XML_STARTED';
export const GET_SINGLE_XML_FAILED = 'GET_SINGLE_XML_FAILED';

// DEAL TYPES
export const GET_DEALS = 'GET_DEALS';
export const GET_DEAL = 'GET_DEAL';
export const ADD_DEAL = 'ADD_DEAL';
export const PROGRESS_DEAL = 'PROGRESS_DEAL';
export const DEAL_LOADING = 'DEAL_LOADING';

// FEED TYPES
export const GET_FEEDS = 'GET_FEEDS';
export const NEW_FEED = 'NEW_FEED';

// AGENCY TYPES
export const GET_AGENCIES = 'GET_AGENCIES';

// OFFPLAN TYPES
export const GET_OFFPLANS = 'GET_OFFPLANS';
export const GET_OFFPLANS_PAGINATED = 'GET_OFFPLANS_PAGINATED';
export const GET_OFFPLANS_LOADING = 'GET_OFFPLANS_LOADING';

// SETTINGS TYPES
export const GET_GLOBAL_SETTINGS = 'GET_GLOBAL_SETTINGS';
export const GET_GLOBAL_SETTINGS_STARTED = 'GET_GLOBAL_SETTINGS_STARTED';
export const GET_GLOBAL_SETTINGS_FAILED = 'GET_GLOBAL_SETTINGS_FAILED';
export const GET_GLOBAL_SETTINGS_FINISHED = 'GET_GLOBAL_SETTINGS_FINISHED';
//  update
export const UPDATE_GLOBAL_SETTINGS = 'UPDATE_GLOBAL_SETTINGS';
export const UPDATE_GLOBAL_SETTINGS_STARTED = 'UPDATE_GLOBAL_SETTINGS_STARTED';
export const UPDATE_GLOBAL_SETTINGS_FAILED = 'UPDATE_GLOBAL_SETTINGS_FAILED';
export const UPDATE_GLOBAL_SETTINGS_FINISHED =
    'UPDATE_GLOBAL_SETTINGS_FINISHED';
//Notifications
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

// STAFF HOLIDAYS
export const GET_STAFF_ON_HOLIDAYS = 'GET_STAFF_ON_HOLIDAYS';
export const GETTING_STAFF_ON_HOLIDAYS = 'GETTING_STAFF_ON_HOLIDAYS';
export const GETTING_STAFF_ON_HOLIDAYS_FAILED =
    'GETTING_STAFF_ON_HOLIDAYS_FAILED';
export const DELETE_STAFF_HOLIDAYS = 'DELETE_STAFF_HOLIDAYS';
export const UPDATE_STAFF_HOLIDAYS = 'UPDATE_STAFF_HOLIDAYS';
export const CREATE_STAFF_HOLIDAYS = 'CREATE_STAFF_HOLIDAYS';
export const CREATING_STAFF_HOLIDAYS = 'CREATING_STAFF_HOLIDAYS';
export const CREATE_STAFF_HOLIDAYS_FAILED = 'CREATE_STAFF_HOLIDAYS_FAILED';

//DND NUMBERS
export const GET_DND = 'GET_DND';

//LEAD SOURCES
export const GET_LEAD_SOURCE_STARTED = "GET_LEAD_SOURCE_STARTED";
export const GET_LEAD_SOURCE = "GET_LEAD_SOURCE";
export const GET_LEAD_SOURCE_FAILED = "GET_LEAD_SOURCE_FAILED";