import React, { useState, useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchAmenities,
    fetchAmenityCategory,
    fetchPropertiesAdmin,
} from "../../redux/actions/property";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Button, Modal, ModalBody } from "reactstrap";
import {
    Block,
    BlockHeadContent,
    BlockTitle,
    BlockBetween,
    BlockHead,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    Row,
    Col,
    TooltipComponent,
} from "../../components/Component";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/miscellaneous/ConfirmModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const AmenityCategoryList = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const AmenityCategory = useSelector(
        (state) => state.property?.amCategories
    );
    const Amenity = useSelector((state) => state.property?.amenities);

    const axios = useAxiosPrivate();
    // State Variables
    const [open, setOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [amenityCategoryId, setAmenityCategoryId] = useState("");
    const [amenityCategoryname, setAmenityCategoryName] = useState("");
    const [check, SetCheck] = useState([]);
    const [amenityCategory, setAmenityCategory] = useState([]);
    const [edit, setEdit] = useState(false);

    // Constants
    // Validate User Token
    const user = useSelector((state) => state?.user?.loggedInUser);
    const { access_token } = user || "";
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    const firstName = user?.first_name;
    const lastName = user?.last_name;
    const createdName = `${firstName} ${lastName}`;

    // Input Validation
    const validate = (amenityCategory) => {
        if (amenityCategory.length < 2) {
            toast.error("Enter valid Amenity Category");
            return false;
        } else if (/^\d+$/.test(amenityCategory)) {
            toast.error("Enter valid Amenity Category");
            return false;
        } else {
            return true;
        }
    };
    const handleEdit = (e, item) => {
        e.preventDefault();
        setOpen(true);
        setEdit(true);
        setAmenityCategoryId(item.id);
        setAmenityCategory(item.name);
    };
    const resetForm = () => {
        setOpen(false);
        setEdit(false);
        setAmenityCategoryId(null);
        setAmenityCategory(null);
    };

    // Add Amenity Category Info in DB
    const addAmenityCategory = async (e) => {
        e.preventDefault();
        if (validate(amenityCategory)) {
            const formData = {
                name: amenityCategory,
            };
            try {
                await axios
                    .post(`/amenityCategory`, formData)
                    .then((amenityCategory) => {
                        navigate(0);
                    })
                    .catch((err) => {
                        toast.error("Amenity Category already exists!");
                    });
            } catch (err) {
                // console.log(err)
                toast.error("Server error. Try Again !");
            }
        }
    };
    const editAmenityCategory = async (e) => {
        e.preventDefault();
        if (validate(amenityCategory)) {
          const formData = {
            name: amenityCategory,
          };
    
          try {
            const res = await axios
              .patch(
                `/amenityCategory/${amenityCategoryId}`,
                formData,
                { headers }
              )
              .then((amenityCategory) => {
                navigate(0);
              })
              .catch((err) => {
                toast.error("Amenity Category not modified !");
              });
          } catch (err) {
            // console.log(err)
            toast.error("Server error. Try Again !");
          }
        }
      };
    const handleModal = (e, id, name) => {
        e.preventDefault();
        setAmenityCategoryId(id);
        setAmenityCategoryName(name);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        resetForm();
    };

    const submitDel = async (e) => {
        e.preventDefault();
        try {
            await axios
                .delete(`/amenityCategory/${amenityCategoryId}`)
                .then((amenityCategory) => {
                    navigate(0);
                })
                .catch((err) => {
                    if (err.response.data.message === "LinkedtoAmenity") {
                        toast.error(
                            "Amenity Category cannot be removed as it is linked to a Amenity"
                        );
                    } else {
                        toast.error("Amenity Category not removed !");
                    }
                });
        } catch (err) {
            // console.log(err)
            toast.error("Server error. Try Again !");
        }
        setShowModal(false);
    };
    useEffect(() => {
        if (AmenityCategory.length > 0 && Amenity.length > 0) {
            let arr = AmenityCategory.map((item, index) =>
                Amenity.map((p) => p.amenityCategoryId === item.id)
            );
            let empty = [];
            arr.forEach((element) => {
                empty.push(element.reduce((x, y) => x || y, false));
            });
            SetCheck(empty);
        }
    }, [AmenityCategory, Amenity]);

    useEffect(() => {
        dispatch(fetchAmenities(axios));
        dispatch(fetchPropertiesAdmin(axios));
        dispatch(fetchAmenityCategory(axios));
    }, [dispatch]);

    return (
        <>
            <React.Fragment>
                <Head title="Amenity"></Head>
                <Content>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle>Amenity Category</BlockTitle>
                            </BlockHeadContent>
                            <BlockHeadContent>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <div className="toggle-expand-content">
                                        <ul className="nk-block-tools g-3">
                                            <li className="nk-block-tools-opt">
                                                <Button
                                                    className="toggle btn-icon d-md-none"
                                                    color="primary"
                                                >
                                                    <Icon name="plus"></Icon>
                                                </Button>
                                                <Button
                                                    className="toggle d-none d-md-inline-flex"
                                                    color="primary"
                                                    onClick={() => {
                                                        setOpen(true);
                                                    }}
                                                >
                                                    <Icon name="plus"></Icon>
                                                    <span>
                                                        Add Amenity Category
                                                    </span>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>

                    <Block>
                        {Amenity && (
                            <div className="nk-tb-list is-separate is-medium mb-3">
                                <DataTableHead className="nk-tb-item">
                                    {/* <DataTableRow className="nk-tb-col-check">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="pid-all"
                                                // onChange={ ( e ) => selectorCheck( e ) }
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="pid-all"
                                            ></label>
                                        </div>
                                    </DataTableRow> */}
                                    <DataTableRow>
                                        <span className="sub-text">
                                            Amenity Category
                                        </span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Edit</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Delete</span>
                                    </DataTableRow>
                                </DataTableHead>
                                {AmenityCategory &&
                                    [...AmenityCategory].map((item, index) => (
                                        <DataTableItem key={index}>
                                            {/* <DataTableRow className="nk-tb-col-check">
                                                <div className="custom-control custom-control-sm custom-checkbox notext">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        defaultChecked={
                                                            item.check
                                                        }
                                                        id={index + "oId-all"}
                                                        key={Math.random()}
                                                        // onChange={ ( e ) => onSelectChange( e, index ) }
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={
                                                            index + "oId-all"
                                                        }
                                                    ></label>
                                                </div>
                                            </DataTableRow> */}
                                            <DataTableRow>
                                                <a
                                                    href="#id"
                                                    onClick={(ev) =>
                                                        ev.preventDefault()
                                                    }
                                                >
                                                    {item?.name}
                                                </a>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <a
                                                    href="#"
                                                    onClick={(ev) =>
                                                        handleEdit(ev, item)
                                                    }
                                                >
                                                    <Icon
                                                        name="edit"
                                                        style={{
                                                            fontSize: "1.3rem",
                                                        }}
                                                    ></Icon>
                                                </a>
                                            </DataTableRow>
                                            <DataTableRow>
                                                {check.length > 0 &&
                                                check[index] === true ? (
                                                    <>
                                                        <TooltipComponent
                                                            tag="a"
                                                            containerClassName="btn btn-icon"
                                                            id={"delete"}
                                                            icon="trash"
                                                            direction="top"
                                                            text={
                                                                " Amenity Category can't be removed as it is linked to a Amenity"
                                                            }
                                                        />
                                                    </>
                                                ) : (
                                                    <a
                                                        onClick={(e) =>
                                                            handleModal(
                                                                e,
                                                                item.id,
                                                                item.amenity
                                                            )
                                                        }
                                                        className="btn btn-icon"
                                                    >
                                                        <Icon
                                                            name="trash"
                                                            style={{
                                                                color: "#e85347",
                                                                fontSize:
                                                                    "1.3rem",
                                                            }}
                                                        />
                                                    </a>
                                                )}
                                            </DataTableRow>
                                        </DataTableItem>
                                    ))}
                            </div>
                        )}
                    </Block>
                    {/* ****** Modal Start ****** */}
                    {showModal ? (
                        <ConfirmModal
                            msg={"Delete "}
                            name={"Amenity Category: " + amenityCategoryname}
                            open={showModal}
                            handleClick={handleModal}
                            handleConfirm={submitDel}
                            handlecloseModal={closeModal}
                        />
                    ) : null}
                    {/* ****** Modal End ****** */}
                    <Modal
                        isOpen={open}
                        toggle={() => setOpen(false)}
                        className="modal-dialog-centered"
                        size="lg"
                    >
                        <ModalBody>
                            <a href="#cancel" className="close">
                                {" "}
                                <Icon
                                    name="cross-sm"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        setOpen(false);
                                    }}
                                ></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title"> {edit?"Edit ":"Add "}Amenity Category</h5>
                                <div className="mt-4">
                                    {/* <form onSubmit={ handleSubmit( onFormSubmit ) }> */}
                                    <form
                                        onSubmit={(e) =>
                                            edit
                                                ? editAmenityCategory(e)
                                                : addAmenityCategory(e)
                                        }
                                    >
                                        <Row className="g-3">
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        Amenity Category
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="amenity"
                                                            value={
                                                                amenityCategory
                                                            }
                                                            onChange={(e) =>
                                                                setAmenityCategory(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col md="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        Created By
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        {createdName}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col size="12" className="d-flex justify-content-end">
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                >
                                                    <span>
                                                    {
                                                    edit
                                                            ? "Save"
                                                            : "Add Amenity Category"}
                                                    </span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </Content>
            </React.Fragment>
        </>
    );
};

export default AmenityCategoryList;
