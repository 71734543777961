import { newRoles } from '../../utils/envConfig';
import StaffTable from '../components/table/StaffTable';

const StaffList = () => {
  return (
    <>
      <StaffTable
        title={'List Staff'}
        name={'Staff'}
        role={null}
        // hide={true}
      />
    </>
  );
};

export default StaffList;
