
import DndLeadsListTable from "./components/DndLeadsListTable";

const DndLeadsList = () => {
    return (
        <>
            <DndLeadsListTable title={"DND List"} name={"DND"} role={null} />
        </>
    );
};

export default DndLeadsList;
