import ProductA from "../../../../assets/images/product/villa.JPG";
import ProductB from "../../../../assets/images/product/villa.JPG";
import ProductC from "../../../../assets/images/product/villa.JPG";
import ProductD from "../../../../assets/images/product/villa.JPG";
import ProductE from "../../../../assets/images/product/villa.JPG";
import ProductF from "../../../../assets/images/product/villa.JPG";
import ProductG from "../../../../assets/images/product/villa.JPG";
import ProductH from "../../../../assets/images/product/villa.JPG";
import ProductI from "../../../../assets/images/product/villa.JPG";
import ProductJ from "../../../../assets/images/product/villa.JPG";

import ProductLGA from "../../../../assets/images/product/villa.JPG";
import ProductLGB from "../../../../assets/images/product/villa.JPG";
import ProductLGC from "../../../../assets/images/product/villa.JPG";
import ProductLGD from "../../../../assets/images/product/villa.JPG";
import ProductLGE from "../../../../assets/images/product/villa.JPG";
import ProductLGF from "../../../../assets/images/product/villa.JPG";
import ProductLGG from "../../../../assets/images/product/villa.JPG";
import ProductLGH from "../../../../assets/images/product/villa.JPG";

export const productData = [
  {
    id: 1,
    name: "Dubai, JVC",
    img: ProductA,
    sku: "UY3749",
    price: "99.49",
    stock: 49,
    category: [
      { label: "Fitbit", value: "Fitbit" },
      { label: "Tracker", value: "Tracker" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 2,
    name: "Dubai, JVC",
    img: ProductB,
    sku: "UY3750",
    price: "89.49",
    stock: 103,
    category: [
      { label: "Fitbit", value: "Fitbit" },
      { label: "Gadgets", value: "Gadgets" },
      { label: "Tracker", value: "Tracker" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 3,
    name: "Dubai, JVC",
    img: ProductC,
    sku: "UY3751",
    price: "299.49",
    stock: 68,
    category: [
      { label: "Smartwatch", value: "Smartwatch" },
      { label: "Tracker", value: "Tracker" },
    ],
    fav: true,
    check: false,
  },
  {
    id: 4,
    name: "Black Dubai, JVC",
    img: ProductD,
    sku: "UY3752",
    price: "99.49",
    stock: 77,
    category: [
      { label: "Dubai, JVC", value: "Dubai, JVC" },
      { label: "Gadgets", value: "Gadgets" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 5,
    name: "Dubai, JVC",
    img: ProductE,
    sku: "UY3753",
    price: "199.49",
    stock: 81,
    category: [
      { label: "Dubai, JVC", value: "Dubai, JVC" },
      { label: "Gadgets", value: "Gadgets" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 6,
    name: "Purple Blue Gradient iDubai Case",
    img: ProductF,
    sku: "UY3754",
    price: "29.49",
    stock: 28,
    category: [
      { label: "Case", value: "Case" },
      { label: "Gadgets", value: "Gadgets" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 7,
    name: "Dubai, JVC",
    img: ProductG,
    sku: "UY3755",
    price: "19.49",
    stock: 37,
    category: [
      { label: "Case", value: "Case" },
      { label: "Gadgets", value: "Gadgets" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 8,
    name: "Wireless Waterproof Speaker",
    img: ProductH,
    sku: "UY3756",
    price: "59.49",
    stock: 37,
    category: [
      { label: "Speaker", value: "Speaker" },
      { label: "Gadgets", value: "Gadgets" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 9,
    name: "AliExpress Fitness Trackers",
    img: ProductI,
    sku: "UY3758",
    price: "35.49",
    stock: 145,
    category: [
      { label: "Fitbit", value: "Fitbit" },
      { label: "Tracker", value: "Tracker" },
    ],
    fav: false,
    check: false,
  },
  {
    id: 10,
    name: "Pool Party Drink Holder",
    img: ProductJ,
    sku: "UY3757",
    price: "9.49",
    stock: 73,
    category: [
      { label: "Men", value: "Men" },
      { label: "Holder", value: "Holder" },
    ],
    fav: false,
    check: false,
  },
];

export const productCardData = [
  {
    id: 0,
    img: ProductLGA,
    new: true,
    hot: false,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGA },
      { id: 1, img: ProductLGB },
      { id: 2, img: ProductLGC },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGF },
    ],
    name: "Dubai , JVC",
    title: "3BR Townhouse for Rent | Ready to Move in | Modern Style",
    type: "Townhouse",
    modelNumber: "",
    prevPrice: 13750,
    newPrice: 165000,
  },
  {
    id: 1,
    img: ProductLGB,
    new: false,
    hot: false,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGB },
      { id: 1, img: ProductLGA },
      { id: 2, img: ProductLGC },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGF },
      { id: 6, img: ProductLGF },
      { id: 7, img: ProductLGF },
      { id: 8, img: ProductLGF },
      { id: 9, img: ProductLGF },
    ],
    type: "Townhouse",
    modelNumber: "",
    name: "Dubai, JVC",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: 9000,
    newPrice: 2000000,
  },
  {
    id: 2,
    img: ProductLGC,
    new: false,
    hot: true,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGC },
      { id: 1, img: ProductLGA },
      { id: 2, img: ProductLGB },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGF },
    ],
    type: "Townhouse",
    modelNumber: "",
    name: "Dubai, JVC",
    title: "3BR Townhouse for Rent | Ready to Move in | Modern Style ",
    prevPrice: 119,
    newPrice: 89,
  },
  {
    id: 3,
    img: ProductLGD,
    new: false,
    hot: false,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGD },
      { id: 1, img: ProductLGA },
      { id: 2, img: ProductLGB },
      { id: 3, img: ProductLGC },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGF },
    ],
    type: "watch",
    modelNumber: "",
    name: "Dubai , JVC",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: 169,
    newPrice: 129,
  },
  {
    id: 4,
    img: ProductLGE,
    new: false,
    hot: false,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGE },
      { id: 1, img: ProductLGA },
      { id: 2, img: ProductLGB },
      { id: 3, img: ProductLGC },
      { id: 4, img: ProductLGD },
      { id: 5, img: ProductLGF },
    ],
    type: "Townhouse",
    modelNumber: "",
    name: "Townhouse",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: 109,
    newPrice: 78,
  },
  {
    id: 5,
    img: ProductLGF,
    new: false,
    hot: false,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGF },
      { id: 1, img: ProductLGA },
      { id: 2, img: ProductLGB },
      { id: 3, img: ProductLGC },
      { id: 4, img: ProductLGD },
      { id: 5, img: ProductLGE },
    ],
    type: "Dubai",
    modelNumber: "",
    name: "Dubai",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: null,
    newPrice: 329,
  },
  {
    id: 6,
    img: ProductLGG,
    new: false,
    hot: false,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGG },
      { id: 1, img: ProductLGB },
      { id: 2, img: ProductLGC },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGF },
    ],
    type: "watch",
    modelNumber: "",
    name: "Dubai , JVC",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: 200,
    newPrice: 179,
  },
  {
    id: 7,
    img: ProductLGH,
    new: false,
    hot: true,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGH },
      { id: 1, img: ProductLGB },
      { id: 2, img: ProductLGC },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGF },
    ],
    type: "Dubai",
    modelNumber: "",
    name: "Dubai",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: 609,
    newPrice: 412,
  },
  {
    id: 8,
    img: ProductLGA,
    new: true,
    hot: false,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGA },
      { id: 1, img: ProductLGB },
      { id: 2, img: ProductLGC },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGF },
    ],
    name: "Dubai, JVC",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    type: "watch",
    modelNumber: "",
    prevPrice: 350,
    newPrice: 324,
  },
  {
    id: 9,
    img: ProductLGB,
    new: false,
    hot: false,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGB },
      { id: 1, img: ProductLGA },
      { id: 2, img: ProductLGC },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGF },
    ],
    type: "Dubai",
    modelNumber: "",
    name: "Dubai",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: 209,
    newPrice: 119,
  },
  {
    id: 10,
    img: ProductLGF,
    new: false,
    hot: false,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGF },
      { id: 1, img: ProductLGB },
      { id: 2, img: ProductLGC },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGG },
    ],
    type: "Dubai",
    modelNumber: "",
    name: "Dubai",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: null,
    newPrice: 329,
  },
  {
    id: 11,
    img: ProductLGG,
    new: false,
    hot: false,
    like: false,
    cart: false,
    type: "watch",
    slider: [
      { id: 0, img: ProductLGG },
      { id: 1, img: ProductLGB },
      { id: 2, img: ProductLGC },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGF },
    ],
    modelNumber: "",
    name: "Dubai , JVC",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: 200,
    newPrice: 179,
  },
  {
    id: 12,
    img: ProductLGH,
    new: false,
    hot: true,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGH },
      { id: 1, img: ProductLGB },
      { id: 2, img: ProductLGC },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGF },
    ],
    type: "Dubai",
    modelNumber: "",
    name: "Quality Dubai",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: 609,
    newPrice: 412,
  },
  {
    id: 13,
    img: ProductLGC,
    new: false,
    hot: true,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGC },
      { id: 1, img: ProductLGB },
      { id: 2, img: ProductLGH },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGF },
    ],
    type: "Townhouse",
    modelNumber: "",
    name: "Dubai, JVC",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: 119,
    newPrice: 89,
  },
  {
    id: 14,
    img: ProductLGD,
    new: false,
    hot: false,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGD },
      { id: 1, img: ProductLGB },
      { id: 2, img: ProductLGC },
      { id: 3, img: ProductLGA },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGF },
    ],
    type: "watch",
    modelNumber: "",
    name: "Watch",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: 169,
    newPrice: 129,
  },
  {
    id: 15,
    img: ProductLGE,
    new: false,
    hot: false,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGE },
      { id: 1, img: ProductLGB },
      { id: 2, img: ProductLGC },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGH },
      { id: 5, img: ProductLGF },
    ],
    type: "Townhouse",
    modelNumber: "",
    name: "Townhouse",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: 109,
    newPrice: 78,
  },
  {
    id: 16,
    img: ProductLGF,
    new: false,
    hot: false,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGF },
      { id: 1, img: ProductLGB },
      { id: 2, img: ProductLGC },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGG },
    ],
    type: "Dubai",
    modelNumber: "",
    name: "Dubai",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: null,
    newPrice: 329,
  },
  {
    id: 17,
    img: ProductLGG,
    new: false,
    hot: false,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGG },
      { id: 1, img: ProductLGB },
      { id: 2, img: ProductLGC },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGF },
    ],
    type: "watch",
    modelNumber: "",
    name: "Dubai , JVC",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: 200,
    newPrice: 179,
  },
  {
    id: 18,
    img: ProductLGH,
    new: false,
    hot: true,
    like: false,
    cart: false,
    slider: [
      { id: 0, img: ProductLGH },
      { id: 1, img: ProductLGB },
      { id: 2, img: ProductLGC },
      { id: 3, img: ProductLGD },
      { id: 4, img: ProductLGE },
      { id: 5, img: ProductLGF },
    ],
    type: "Dubai",
    modelNumber: "",
    name: "Quality Dubai",
    title: "3 BR Townhouse for Rent | Ready to Move In | Modern Style",
    prevPrice: 609,
    newPrice: 412,
  },
];

export const categoryOptions = [
  {
    value: "Gadget",
    label: "Gadget",
  },
  {
    value: "Electronics",
    label: "Electronics",
  },
  {
    label: "Watch",
    value: "Watch",
  },
  {
    label: "Tracker",
    value: "Tracker",
  },
  {
    label: "Fitbit",
    value: "Fitbit",
  },
  {
    label: "Men",
    value: "Men",
  },
  {
    label: "Holder",
    value: "Holder",
  },
  {
    label: "Speaker",
    value: "Speaker",
  },
  {
    label: "Dubai, JVC",
    value: "Dubai, JVC",
  },
  {
    label: "Dubai",
    value: "Dubai",
  },
];
