/* eslint-disable no-unused-vars */
import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Content from "../../content/Content";
import { Badge, Button, Card, Col, Row } from "reactstrap";
import { ViewportInfo } from "../types";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../components/Component";
import { LeadStatus } from "../../../utils/envConfig";
import { getNextPossibleStatus } from "../utils";
import LoaderModal from "../../../components/modals/LoaderModal";
import { showToast } from "../../../utils/toast/toast";

const ConfirmationModal = lazy(() =>
  import("../../../components/modals/ConfirmationModal")
);

const invalidLeadStatuses = [
  LeadStatus.Deal,
  LeadStatus.Won,
  LeadStatus.Lost,
  LeadStatus.Disqualified,
  LeadStatus.Prospect,
  LeadStatus.ForListing,
  LeadStatus.Appointment,
];

/**
 * @typedef {Object} MutationQueryResult
 * @property {function(any, { onSuccess, onError, onSettled }): void} mutate - the mutation function to call
 * @property {function(any, { onSuccess, onError, onSettled }): void} mutateAsync - the mutation function to call, but async which returns an awaitable promise
 * @property {"error" | "idle" | "loading" | "success"} status - The status of the mutation
 * @property {unknown | undefined} data - The last successfully resolved data for the query.
 * @property {unknown | undefined} error - The error object for the query, if an error was encountered.
 * @property {() => void} reset - A function to clean the mutation internal state (i.e., it resets the mutation to its initial state).
 */

/**
 * @typedef {Object} Props
 * @property {User} user - The user information.
 * @property {boolean} isAgent - Whether the profile is for an agent or not.
 * @property {MutationQueryResult} userUpdate - The mutation object that can be used to update the user's information.
 * @property {React.Dispatch<React.SetStateAction<ViewportInfo>>} setViewportInfo - function to update the viewport information
 */

/**
 * General profile layout component that handles viewport information changes to manage responsive layouts
 * @param {Props} props
 * @returns {JSX.Element} The rendered component.
 */
const ProfileLayout = ({
  user,
  isAgent,
  userUpdate,
  setViewportInfo,
  children,
}) => {
  const navigate = useNavigate();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const toggleConfirmationModal = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  /**
   * Function to change the design view under 990 px
   */
  const viewChange = useCallback(() => {
    if (window.innerWidth < 990) {
      setViewportInfo((prev) => ({ ...prev, mobileView: true }));
    } else {
      setViewportInfo((prev) => ({ ...prev, mobileView: false, isSm: false }));
    }
  }, [setViewportInfo]);

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        setViewportInfo((prev) => ({ ...prev, isSm: false }));
      });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [setViewportInfo, viewChange]);

  const nextPossibleStatus = useMemo(
    () => getNextPossibleStatus(user?.lead_status ?? ""),
    [user]
  );

  const handleUpdateLeadStatus = async (event, newStatus) => {
    event.preventDefault();

    const payload = {
      lead_status: newStatus,
    };
    try {
      if (newStatus === LeadStatus.Deal) {
        navigate(`/add-deal`);
        return;
      }
      user &&
        userUpdate.mutate(
          { userId: user?.id, payload },
          {
            onSuccess: () => {
              showToast("Successfully updated user.", "success");
            },
          }
        );
    } catch (error) {
    } finally {
      toggleConfirmationModal();
    }
  };

  return (
    <React.Fragment>
      <Content>
        <Row>
          <Col md={6}>
            <Button
              color="light"
              outline
              className="bg-white mb-3"
              onClick={() => navigate(-1)}
            >
              <Icon name="arrow-left" />
              <span>Back</span>
            </Button>
          </Col>
          <Col
            sm={3}
            md={6}
            className="text-md-right"
            style={{ textAlign: "right" }}
          >
            {!isAgent &&
            !invalidLeadStatuses.includes(user?.lead_status ?? "") &&
            nextPossibleStatus?.length ? (
              <Button
                color="light"
                outline
                className="bg-light text-black"
                onClick={toggleConfirmationModal}
              >
                <Icon name="check-round-cut"></Icon>
                <span>Mark This as {nextPossibleStatus}</span>
              </Button>
            ) : null}
            {!isAgent && user && user?.lead_status === LeadStatus.Prospect ? (
              <div className="d-flex justify-content-end">
                <Badge className="bg-info-dim text-info border border-info d-flex justify-content-end py-2 px-4">
                  <Icon name={"check-thick"} />
                  <span className="fs-6">Prospect</span>
                </Badge>
              </div>
            ) : null}
          </Col>
        </Row>
        <Card className="card-bordered">
          <div className="card-aside-wrap">{children ?? null}</div>
        </Card>
      </Content>
      {isConfirmModalOpen && (
        <Suspense fallback={<LoaderModal />}>
          <ConfirmationModal
            isOpen={isConfirmModalOpen}
            toggleFn={toggleConfirmationModal}
            title={`Confirm ${nextPossibleStatus}`}
            loadingState={userUpdate.status === "loading"}
            actionFn={(e) => handleUpdateLeadStatus(e, nextPossibleStatus)}
          >
            <p style={{ fontSize: "105%" }}>
              Are you sure you want to mark this lead as {nextPossibleStatus}?
            </p>
          </ConfirmationModal>
        </Suspense>
      )}
    </React.Fragment>
  );
};

export default ProfileLayout;
