import React, { useEffect, useState } from "react";
import CardShimmer from "../../../components/CardShimmer";
import { Badge, Col, Row } from "reactstrap";
import ListingCard from "./ListingCard";
import { formatValue } from "../../../utils/envConfig";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

export default function ListingPerformaceSecMarket ( { monthStart, monthEnd } )
{
    const axios = useAxiosPrivate();

    const navigate = useNavigate();

    //getting data for the past 30 days

    // views
    const [ mostNumberOfViews, setMostNumberOfViews ] = useState( null );
    const [ mostNumberOfViewings, setMostNumberOfViewings ] = useState( null );
    const [ mostNumberOfInquieries, setMostNumberOfInquiries ] = useState( null );

    // others
    const [ fetchingViews, setFetchingViews ] = useState( false );
    const [ fetchingViewings, setFetchingViewings ] = useState( false );
    const [ fetchingInquiries, setFetchingInquiries ] = useState( false );

    const getMostNumberOfViews = async () =>
    {
        try
        {
            setFetchingViews( true );

            const { data } = await axios.get(
                `/reports/properties?from_date=${ monthStart }&to_date=${ monthEnd }&propertyType=listing`
            );

            setMostNumberOfViews( data );
        } catch ( err )
        {
            console.log( err );
        } finally
        {
            setFetchingViews( false );
        }
    };

    const getMostNumberOfViewings = async () =>
    {
        try
        {
            setFetchingViewings( true );

            const { data } = await axios.get(
                `/reports/propertyPerformance/propertiesByViewings?from_date=${ monthStart }&to_date=${ monthEnd }&propertyType=listing`
            );

            setMostNumberOfViewings( data );
        } catch ( err )
        {
            console.log( err );
        } finally
        {
            setFetchingViewings( false );
        }
    };

    const getMostNumberOfInquiries = async () =>
    {
        try
        {
            setFetchingInquiries( true );

            const { data } = await axios.get(
                `/reports/propertyPerformance/propertiesByInquiries?from_date=${ monthStart }&to_date=${ monthEnd }&propertyType=listing`
            );

            setMostNumberOfInquiries( data );
        } catch ( err )
        {
            console.log( err );
        } finally
        {
            setFetchingInquiries( false );
        }
    };

    useEffect( () =>
    {
        getMostNumberOfViews();
        getMostNumberOfViewings();
        getMostNumberOfInquiries();
    }, [ monthStart, monthEnd ] );

    return (
        <>
            <Row className="g-gs">
                <Col sm="6" xxl="4">
                    <div className="listing-item-grid">
                        <div className="card-inner">
                            <div className="kanban-title-content pb-3">
                                <h6 className="title">Most number of views</h6>
                                <Badge
                                    className="text-dark"
                                    pill
                                    color="outline-light"
                                >
                                    { mostNumberOfViews && mostNumberOfViews?.length
                                        ? formatValue(
                                            mostNumberOfViews
                                                ?.map( ( item ) =>
                                                    Number(
                                                        item?.metric?.metricValue
                                                    )
                                                )
                                                ?.reduce( ( a, b ) => a + b, 0 )
                                        )
                                        : 0 }
                                </Badge>
                            </div>
                            <div className="listing-items-cont">

                                { !fetchingViews ? (
                                    mostNumberOfViews &&
                                        mostNumberOfViews?.length ? (
                                        mostNumberOfViews?.map( ( item ) => (


                                            <ListingCard
                                                onClick={ ( e ) =>
                                                {
                                                    navigate(
                                                        `/property-list/${ item?.property?._id ??
                                                        item?.property?.id
                                                        }`
                                                    );
                                                } }
                                                amount={ formatValue(
                                                    Number(
                                                        item?.metric?.metricValue
                                                    )
                                                ) }
                                                amountText={
                                                    Number(
                                                        item?.metric?.metricValue
                                                    ) === 1
                                                        ? "view"
                                                        : "views"
                                                }
                                                subText={
                                                    item?.property?.communityName
                                                }
                                                title={
                                                    item?.property?.propertyTitle
                                                }
                                                image={
                                                    item?.property?.images?.length
                                                        ? typeof item?.property
                                                            ?.images[ 0 ] ===
                                                            "object"
                                                            ? item?.property
                                                                ?.images[ 0 ]
                                                                ?.thumbnail
                                                            : item?.property
                                                                ?.images[ 0 ]
                                                        : null
                                                }
                                                key={
                                                    item?.property?._id ??
                                                    item?.property?.id
                                                }
                                            />
                                        ) )
                                    ) : (
                                        <div className="text-center">
                                            No properties to show
                                        </div>
                                    )
                                ) : (
                                    <CardShimmer
                                        numberOfCards={
                                            mostNumberOfViews?.length ?? 10
                                        }
                                    />
                                ) }
                            </div>
                        </div></div>
                </Col>
                <Col sm="6" xxl="4">
                    <div className="listing-item-grid">
                        <div className="card-inner">
                            <div className="kanban-title-content pb-3">
                                <h6 className="title">Most number of viewings</h6>
                                <Badge
                                    className="text-dark"
                                    pill
                                    color="outline-light"
                                >
                                    { mostNumberOfViewings &&
                                        mostNumberOfViewings?.length
                                        ? formatValue(
                                            mostNumberOfViewings
                                                ?.map( ( item ) =>
                                                    Number( item?.count )
                                                )
                                                ?.reduce( ( a, b ) => a + b, 0 )
                                        )
                                        : 0 }
                                </Badge>
                            </div>

                            <div className="listing-items-cont">
                                { !fetchingViewings ? (
                                    mostNumberOfViewings &&
                                        mostNumberOfViewings?.length ? (
                                        mostNumberOfViewings?.map( ( item ) => (
                                            <ListingCard
                                                onClick={ ( e ) =>
                                                {
                                                    navigate(
                                                        `/property-list/${ item?.property?._id ??
                                                        item?.property?.id
                                                        }`
                                                    );
                                                } }
                                                amount={ formatValue(
                                                    Number( item?.count )
                                                ) }
                                                amountText={
                                                    Number( item?.count ) === 1
                                                        ? "viewing"
                                                        : "viewings"
                                                }
                                                subText={
                                                    item?.property?.communityName
                                                }
                                                title={
                                                    item?.property?.propertyTitle
                                                }
                                                image={
                                                    item?.property?.images?.length
                                                        ? typeof item?.property
                                                            ?.images[ 0 ] ===
                                                            "object"
                                                            ? item?.property
                                                                ?.images[ 0 ]
                                                                ?.thumbnail
                                                            : item?.property
                                                                ?.images[ 0 ]
                                                        : null
                                                }
                                                key={
                                                    item?.property?._id ??
                                                    item?.property?.id
                                                }
                                            />
                                        ) )
                                    ) : (
                                        <div className="text-center">
                                            No properties to show
                                        </div>
                                    )
                                ) : (
                                    <CardShimmer
                                        numberOfCards={
                                            mostNumberOfViewings?.length ?? 10
                                        }
                                    />
                                ) }
                            </div></div>
                    </div>
                </Col>
                <Col sm="12" xxl="4">
                    <div className="listing-item-grid">
                        <div className="card-inner">
                            <div className="kanban-title-content pb-3">
                                <h6 className="title">Most number of inquiries</h6>
                                <Badge
                                    className="text-dark"
                                    pill
                                    color="outline-light"
                                >
                                    { mostNumberOfInquieries &&
                                        mostNumberOfInquieries?.length
                                        ? formatValue(
                                            mostNumberOfInquieries
                                                ?.map( ( item ) =>
                                                    Number( item?.count )
                                                )
                                                ?.reduce( ( a, b ) => a + b, 0 )
                                        )
                                        : 0 }
                                </Badge>
                            </div>
                            <div className="listing-items-cont">
                                { !fetchingInquiries ? (
                                    mostNumberOfInquieries &&
                                        mostNumberOfInquieries?.length ? (
                                        mostNumberOfInquieries?.map( ( item ) => (
                                            <ListingCard
                                                onClick={ ( e ) =>
                                                {
                                                    navigate(
                                                        `/property-list/${ item?.property?._id ??
                                                        item?.property?.id
                                                        }`
                                                    );
                                                } }
                                                amount={
                                                    !isNaN( item?.count )
                                                        ? formatValue(
                                                            Number( item?.count )
                                                        )
                                                        : 0
                                                }
                                                amountText={
                                                    Number( item?.count ) === 1
                                                        ? "inquiry"
                                                        : "inquiries"
                                                }
                                                subText={
                                                    item?.property?.communityName
                                                }
                                                title={
                                                    item?.property?.propertyTitle
                                                }
                                                image={
                                                    item?.property?.images?.length
                                                        ? typeof item?.property
                                                            ?.images[ 0 ] ===
                                                            "object"
                                                            ? item?.property
                                                                ?.images[ 0 ]
                                                                ?.thumbnail
                                                            : item?.property
                                                                ?.images[ 0 ]
                                                        : null
                                                }
                                                key={
                                                    item?.property?._id ??
                                                    item?.property?.id
                                                }
                                            />
                                        ) )
                                    ) : (
                                        <div className="text-center">
                                            No properties to show
                                        </div>
                                    )
                                ) : (
                                    <CardShimmer
                                        numberOfCards={
                                            mostNumberOfInquieries?.length ?? 10
                                        }
                                    />
                                ) }
                            </div></div>
                    </div>
                </Col>
            </Row>
        </>
    );
}
