import React, { useEffect, useState } from "react";
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { Icon } from "../../../components/Component";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useDragAndDrop from "../../../utils/hooks/useDragAndDrop";
import uuid from "react-uuid";
import { deleteFromS3, uploadToS3 } from "../../../utils/envConfig";


const EditDeveloperModal = ( { isOpen, toggle, developer } ) =>
{
    const axios = useAxiosPrivate();
    const [ errorMessage, setErrorMessage ] = useState( '' );
    const [ selectedImage, setSelectedImage ] = useState( null );
    const [ selectedBackgroundImage, setSelectedBackgroundImage ] = useState( null );
    const [toBeUpdated, settoBeUpdated] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [ loading, setLoading ] = useState( false );

    const { errors, register, handleSubmit } = useForm();

    const [updateDeveloper, setUpdateDeveloper] = useState(developer);

    useEffect(() => {
        setUpdateDeveloper(developer);
    }, [developer]);

    const {
        active: newDeveloperActive,
        handleFileChange: newDevPhotoChange,
        files: newDevFiles,
    } = useDragAndDrop({
        inputType: "image",
    });

    const {
        active: backgroundImageActive,
        handleFileChange: backgroundImageChange,
        files: backgroundImageFiles,
    } = useDragAndDrop({
        inputType: "image",
    });

    const handleUpdateDevDetails = (e) => {
        const { name, value } = e.target;
        setUpdateDeveloper((prev) => ({ ...prev, [name]: value }));
        settoBeUpdated((prev) => ({ ...prev, [name]: value }));
    };

    const handleUpdateDeveloper = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            setDisabled(true);
            const payload = {
                ...toBeUpdated,
            };

            const logoName = `offPlans/developer/${uuid()}.${newDevFiles[0]?.file?.name
                ?.split(".")
                ?.pop()}`;

            const backgroundImageName = `offPlans/developer/${uuid()}.${backgroundImageFiles[0]?.file?.name
                ?.split(".")
                ?.pop()}`;

            if (newDevFiles[0]?.file) {
                await uploadToS3(newDevFiles[0]?.file, logoName, "image/*",axios);

                await deleteFromS3(developer?.logo,axios);

                payload.logo = logoName;
            }

            if (backgroundImageFiles[0]?.file) {
                await uploadToS3(
                    backgroundImageFiles[0]?.file,
                    backgroundImageName,
                    "image/*",
                    axios
                );

                await deleteFromS3(developer?.backgroundImage,axios);

                payload.backgroundImage = backgroundImageName;
            }

            if (!Object.keys(payload).length) {
                return alert("Please enter data to be updated");
            }

            await axios.put(
                `/off-plan/plan-developer/${updateDeveloper?._id}`,
                payload,
            );
            setLoading(false);
            toast.success("Updated successfully");
            toggle();
        } catch (err) {
            console.log(err);
            toast.error("Failed to update developer");
        } finally {
            setLoading(false);
            setDisabled(false);
        }
    };
    return (
        <Modal isOpen={ isOpen } toggle={ toggle } size="lg" backdrop="static">
            <ModalHeader
                toggle={ toggle }
                close={
                    <button
                        className="close"
                        onClick={ ( ev ) =>
                        {
                            ev.preventDefault();
                            toggle();
                        } }
                    >
                        <Icon name="cross" />
                    </button>
                }
            >
                Edit Developer
            </ModalHeader>
            <ModalBody>
                <form id='add-developer-form'
                    onSubmit={(e)=>handleUpdateDeveloper(e) }>
                    <Row className="g-3">
                        <Col sm="12">
                            <Label>
                                Developer Name
                            </Label>
                            <Input 
                              name= "name"
                              defaultValue= {developer?.name}
                            onChange={(e)=>handleUpdateDevDetails(e)}
                            placeholder="Enter Developer Name">
                            </Input>
                        </Col>
                        <Col sm="12">
                            <Label>
                                Short Header Title
                            </Label>
                            <Input 
                              name= "headerTitle"
                            defaultValue={ developer?.headerTitle}
                              onChange={(e)=>handleUpdateDevDetails(e)}
                            placeholder="Enter Short Header Title" />
                        </Col>
                        <Col sm="6">
                            <div className="form-group">
                                <label className="form-label">Developer Logo     </label>
                                <div className="form-control-wrap">
                                    <div className="form-file">
                                        <Input
                                         type="file" id="customFile" accept="image/*" name="image" 
                                         onChange={ newDevPhotoChange } 
                                         />
                                    </div>
                                    { errorMessage && <div className="text-danger">{ errorMessage }</div> }
                                </div>
                            </div>
                            { newDevFiles?.length>0 && (
                                <div className="position-relative">
                                    <img 
                                src={URL.createObjectURL(newDevFiles[0]?.file)}
                                     alt="Selected" />
                                    {/* <Button className="bg-danger position-absolute" style={ { top: "0", left: "0" } }>
                                        <Icon name="trash"></Icon>
                                    </Button> */}
                                </div>
                            ) }
                        </Col>
                        <Col sm="6">
                            <div className="form-group">
                                <label className="form-label">Background Image   </label>
                                <div className="form-control-wrap">
                                    <div className="form-file">
                                        <Input 
                                        type="file" id="customFile" accept="image/*" name="image" 
                                        onChange={ backgroundImageChange } 
                                        />
                                    </div>
                                    { errorMessage && <div className="text-danger">{ errorMessage }</div> }
                                </div>
                            </div>
                            { backgroundImageFiles?.length>0 &&  (
                                <div className="position-relative">
                                    <img
                                       src={URL.createObjectURL(backgroundImageFiles[0]?.file)}
                                     alt="Selected" />
                                    {/* <Button className="bg-danger position-absolute" style={ { top: "0", left: "0" } }>
                                        <Icon name="trash"></Icon>
                                    </Button> */}
                                </div>
                            ) }
                        </Col>
                        <Col sm="12">
                            <div className="form-group">
                                <Label className="form-label" htmlFor="fv-message">
                                    Description
                                </Label>
                                <div className="form-control-wrap">
                                    <textarea
                                        type="textarea"
                                        className="form-control form-control-sm"
                                        rows="5"
                                        placeholder="Describe Developer"
                                        defaultValue={developer?.description}
                                        name="description"
                                        id= "developerDesc"
                                        onChange={handleUpdateDevDetails}

                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="actions clearfix">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
                            <li>
                                
                                <Button color="secondary" type="submit">
                                { !loading ? (
                                    <>
                                   Save
                                     </>
                                    ) : (
                                    <Spinner size="sm" color="light" />
                                    ) }
                                </Button>
                            </li>
                        </ul>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};
export default EditDeveloperModal;