import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { axiosPrivate as axios } from '../../../utils/Utils';
import { Form, Spinner, Alert } from 'reactstrap';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import {
    Block,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockTitle,
    Button,
    Icon,
    PreviewCard
} from '../../../components/Component';

import Logo from '../../../assets/images/albahomes-logo.png';

import PageContainer from '../../../layout/page-container/PageContainer';
import Head from '../../../layout/head/Head';

import AuthFooter from '../AuthFooter';

import {
    comparePasswords,
    serverAPI,
    validatePasswordNew
} from '../../../utils/envConfig';

import { USER_LOGIN } from '../../../redux/types';
import { useSelector } from 'react-redux';

export default function ResetPasswordComponent({ token }) {
    const location = useLocation();

    const dispatch = useDispatch();

    const loggedInUser = useSelector((state) => state?.user?.loggedInUser);

    const fromRoute = location?.state?.from?.pathname ?? '/';

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [passState, setPassState] = useState(false);
    const [confirmPassState, setConfirmPassState] = useState(false);
    const [errorVal, setError] = useState(false);

    const [loginData, setLoginData] = useState({
        passcode: '',
        confirmPasscode: ''
    });

    const handleChange = (e) => {
        const { value, name } = e?.target;

        setLoginData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const onFormSubmit = async (formData) => {
        const userData = {
            password: formData?.passcode
        };

        try {
            setLoading(true);

            // submit form data to server to confirm user password reset
            const { data } = await axios.post(
                `/auth/reset-password`,
                userData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'reset-token': token
                    }
                }
            );

            console.log('Redirecting to login page...');

            // if successful, redirect to login page
            navigate('/auth-login', { replace: true });
        } catch (err) {
            console.log(err);
            setError(
                err?.response?.data?.message ??
                    'Unable to login with credentials'
            );
            setTimeout(() => {
                setError(false);
                // navigate('/auth-login', { replace: true });
            }, 5000);
        } finally {
            setLoading(false);
        }
    };

    const { errors, register, handleSubmit } = useForm();

    // if lgged in user redirect to home page
    if (loggedInUser && loggedInUser?.access_token)
        return navigate(fromRoute, { replace: true });

    if (!token) return navigate('/auth-login', { replace: true });

    return (
        <React.Fragment>
            <Head title="Login" />
            <PageContainer>
                <Block className="nk-block-middle nk-auth-body  wide-xs">
                    <div className="brand-logo pb-4 text-center">
                        <div className="logo-link">
                            <img
                                className="logo-light logo-img logo-img-lg"
                                src={Logo}
                                alt="logo"
                            />
                            <img
                                className="logo-dark logo-img logo-img-lg"
                                src={Logo}
                                alt="logo-dark"
                            />
                        </div>
                    </div>

                    <PreviewCard
                        className="card-bordered"
                        bodyClass="card-inner-lg"
                    >
                        <BlockHead>
                            <BlockContent>
                                <BlockTitle tag="h4">Reset Password</BlockTitle>
                                <BlockDes>
                                    <p>
                                        Enter your new password to reset your
                                        account.
                                    </p>
                                </BlockDes>
                            </BlockContent>
                        </BlockHead>
                        {errorVal && (
                            <div className="mb-3">
                                <Alert color="danger" className="alert-icon">
                                    {' '}
                                    <Icon name="alert-circle" /> {errorVal}
                                </Alert>
                            </div>
                        )}
                        <Form
                            className="is-alter"
                            onSubmit={handleSubmit(onFormSubmit)}
                        >
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label
                                        className="form-label"
                                        htmlFor="password"
                                    >
                                        New Passcode
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <a
                                        href="#password"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setPassState(!passState);
                                        }}
                                        className={`form-icon lg form-icon-right passcode-switch ${
                                            passState ? 'is-hidden' : 'is-shown'
                                        }`}
                                    >
                                        <Icon
                                            name="eye"
                                            className="passcode-icon icon-show"
                                        ></Icon>

                                        <Icon
                                            name="eye-off"
                                            className="passcode-icon icon-hide"
                                        ></Icon>
                                    </a>
                                    <input
                                        type={passState ? 'text' : 'password'}
                                        id="password"
                                        name="passcode"
                                        defaultValue={loginData?.passcode}
                                        ref={register({
                                            required: 'This field is required',
                                            validate: (value) =>
                                                validatePasswordNew(value) ||
                                                'Invalid password'
                                        })}
                                        onChange={handleChange}
                                        placeholder="Enter your passcode"
                                        className={`form-control-lg form-control ${
                                            passState ? 'is-hidden' : 'is-shown'
                                        }`}
                                    />
                                    {errors.passcode && (
                                        <span className="invalid">
                                            {errors.passcode.message}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="form-label-group">
                                    <label
                                        className="form-label"
                                        htmlFor="password"
                                    >
                                        Confirm New Passcode
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <a
                                        href="#password"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setConfirmPassState(
                                                !confirmPassState
                                            );
                                        }}
                                        className={`form-icon lg form-icon-right passcode-switch ${
                                            confirmPassState
                                                ? 'is-hidden'
                                                : 'is-shown'
                                        }`}
                                    >
                                        <Icon
                                            name="eye"
                                            className="passcode-icon icon-show"
                                        ></Icon>

                                        <Icon
                                            name="eye-off"
                                            className="passcode-icon icon-hide"
                                        ></Icon>
                                    </a>
                                    <input
                                        type={
                                            confirmPassState
                                                ? 'text'
                                                : 'password'
                                        }
                                        id="confirmPasscode"
                                        name="confirmPasscode"
                                        defaultValue={
                                            loginData?.confirmPasscode
                                        }
                                        ref={register({
                                            required: 'This field is required',
                                            validate: (value) =>
                                                !validatePasswordNew(value)
                                                    ? 'Invalid password'
                                                    : !comparePasswords(
                                                          value,
                                                          loginData?.passcode
                                                      )
                                                    ? "Passwords don't match"
                                                    : true
                                        })}
                                        onChange={handleChange}
                                        placeholder="Confirm your passcode"
                                        className={`form-control-lg form-control ${
                                            confirmPassState
                                                ? 'is-hidden'
                                                : 'is-shown'
                                        }`}
                                    />
                                    {errors?.confirmPasscode && (
                                        <span className="invalid">
                                            {errors.confirmPasscode.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form-group">
                                <Button
                                    size="lg"
                                    className="btn-block"
                                    type="submit"
                                    color="primary"
                                    disabled={
                                        errors?.passcode?.message ||
                                        errors?.confirmPasscode?.message ||
                                        loading
                                    }
                                >
                                    {loading ? (
                                        <Spinner size="sm" color="light" />
                                    ) : (
                                        'Reset Password'
                                    )}
                                </Button>
                            </div>
                        </Form>
                        {/* <div className="form-note-s2 text-center pt-4">
                            { " " }
                            New on our platform?{ " " }
                            <Link
                                to={ `/auth-register` }
                            >
                                Create an account
                            </Link>
                        </div> */}
                    </PreviewCard>
                </Block>
                <AuthFooter />
            </PageContainer>
        </React.Fragment>
    );
}
