import { Badge } from "reactstrap";

const CommunitiesList = ({ communities }) => {
  const styling = {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))', // Adjust the minmax values as needed
        gap: '1rem',
    };
  return (
    <>
      <div
        className="d-flex flex-column align-items-center flex-wrap"
        style={{ gap: ".5rem" }}
      >
        {communities.map((community, idx) => (
          <Badge key={`community-card-${idx}`} color="light" pill>
            {community.community}
          </Badge>
        ))}
        {/* <div className="border border-success d-flex flex-wrap justify-content-end" >
        </div> */}
      </div>
    </>
  );
};

export default CommunitiesList;
