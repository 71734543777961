/* eslint-disable jsx-a11y/anchor-is-valid */
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import FluidBlockContainer from "../../components/containers/FluidBlockContainer";
import SideBar from "./components/sidebar/Sidebar";
import ExplorerSettings from "./components/settings-components/explorer-settings/ExplorerSettings";
import { NavMenuNames } from "./components/sidebar/constants";
import LeadSettings from "./components/settings-components/leads-settings/LeadSettings";
import { useState } from "react";

const AppSettings = () => {
  // State to hold the current settings page name
  const [currentSettings, setCurrentSettings] = useState(NavMenuNames.Explorer);

  // Function to render the appropriate settings page based on the current settings
  const renderSettingsPage = (name) => {
    switch (name) {
      case NavMenuNames.Explorer:
        return <ExplorerSettings />;
      case NavMenuNames.Leads:
        return <LeadSettings />;
      default:
        return <ExplorerSettings />;
    }
  };

  return (
    <>
      <Head title="Dashboard Settings" />
      <Content>
        <FluidBlockContainer>
          <div className="card">
            <div className="card-aside-wrap">
              {/* RIGHT SECTION */}
              {renderSettingsPage(currentSettings)}
              {/* END OF RIGHT SECTION */}
              <SideBar setCurrentSettings={setCurrentSettings} />
            </div>
          </div>
        </FluidBlockContainer>
      </Content>
    </>
  );
};

export default AppSettings;
