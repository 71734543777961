import React, { useState, useEffect, useRef } from 'react';
// import axios from "axios";
import {
    serverAPI,
    Finance,
    RentalPeriod,
    Agent,
    ListingType,
    Status
} from '../../../../utils/envConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Icon,
    Row,
    Col,
    RSelect
} from '../../../../components/Component';
import { fetchAgents } from '../../../../redux/actions/user';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

const StepThree = (props) => {
    let { handleFormData, values, setFormData, type } = props;

    // State Variables
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const agents = useSelector((state) => state.user?.agents);
    const axios = useAxiosPrivate();
    const [selected, setSelected] = useState([]);
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [error, setError] = useState(false);
    const [amenityOptions, setAmenityOptions] = useState([]);
    const [amenityFields, setAmenityFields] = useState(values.amenityObjs);
    const [amenityFieldIds, setAmenityFieldIds] = useState(values.amenityIds);
    const [amenityFilters, setAmenityFilters] = useState([]);
    const [amenities, setAmenities] = useState([]);

    const prevAmenityFields = usePrevious(amenityFields);
    const [inputFields, setInputFields] = useState(values.customField);
    const [feeFields, setFeeFields] = useState(values.fees);
    const [videosLink, setVideosLink] = useState(values.videos);
    const [mLinks, setMLinks] = useState(values.matterportLink);
    const [optDefaultAgent, setOptDefaultAgent] = useState();

    // Constants
    // Validate User Token
    const { access_token } = useSelector((state) => state?.user?.loggedInUser);
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`
    };

    useEffect(() => {
        if (amenities && amenities.length > 0) {
            let fil2 = values?.amenityIds
                .map((ele) => {
                    const foundAmenity = amenities.find((am) => am.id === ele);
                    if (foundAmenity) {
                        return {
                            label: foundAmenity.amenity,
                            value: foundAmenity.id
                        };
                    }
                    return null;
                })
                .filter(Boolean);

            setSelectedAmenities(fil2);
        }
    }, [values.amenityIds, amenities]);

    // useEffect(() => {
    //   dispatch(fetchAgents(axios));
    // }, [dispatch]);

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
    };

    const handleSelectAmenity = (e) => {
        setSelected(e);
        addAmenityListFields(e);
    };
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const addAmenityListFields = (values) => {
        if (values !== undefined) {
            let newfield = [];
            values.map((v) => {
                if (prevAmenityFields.some((am) => v.value === am.amenityId)) {
                    let xyz = prevAmenityFields.filter(
                        (am) =>
                            v.value === am.amenityId && {
                                am,
                                priority: am.priority,
                                quantity: am.quantity
                            }
                    );
                    newfield.push(xyz[0]);
                } else {
                    newfield.push({
                        amenityId: v.value,
                        priority: '0',
                        quantity: '0'
                    });
                }
            });
            setAmenityFieldIds(values.map((v) => v.value));

            setAmenityFields(newfield);
        }
    };

    const handlePriority = (index, event, input) => {
        let data = [...amenityFields];
        data[index]['priority'] = event.target.value;
        setAmenityFields(data);
    };

    // const handleQuantity = ( index, event, input ) =>
    // {
    //   let data = [ ...amenityFields ];
    //   data[ index ][ "quantity" ] = event.target.value;
    //   setAmenityFields( data );
    // };
    const handleQuantity = (index, event, input) => {
        setAmenityFields((prevAmenityFields) => {
            const newData = [...prevAmenityFields];
            const matchingItem = newData.find((am) => am.amenityId === input);
            if (matchingItem) {
                matchingItem.quantity = event.target.value;
            }
            return newData;
        });
    };

    const addFields = (e) => {
        e.preventDefault();
        let newfield = { label: '', value: '' };
        setInputFields([...inputFields, newfield]);
    };

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };

    useEffect(() => {
        setFormData((prevState) => ({
            ...prevState,
            customField: inputFields
        }));
    }, [inputFields]);

    useEffect(() => {
        setFormData((prevState) => ({
            ...prevState,
            amenityObjs: amenityFields
        }));
    }, [amenityFields]);
    // console.log(values.amenityObjs);
    useEffect(() => {
        if (amenityFilters) {
            setSelected(amenityFilters);
        }
    }, [amenityFilters]);

    useEffect(() => {
        setFormData((prevState) => ({
            ...prevState,
            amenityIds: amenityFieldIds
        }));
    }, [amenityFieldIds]);

    const handleFeeFormChange = (index, event) => {
        let data = [...feeFields];
        if (event.target.id === 'value') {
            data[index][event.target.id] = Number(event.target.value);
        } else {
            data[index][event.target.id] = event.target.value;
        }

        setFeeFields(data);
    };
    const addFeeField = (e) => {
        e.preventDefault();
        let newfield = { name: '', value: '' };
        setFeeFields([...feeFields, newfield]);
    };
    const removeFeeField = (index) => {
        let data = [...feeFields];
        data.splice(index, 1);
        setFeeFields(data);
    };

    useEffect(() => {
        setFormData((prevState) => ({
            ...prevState,
            fees: feeFields
        }));
    }, [feeFields]);
    const findPercentage = (price, perc) => {
        let result = Math.round(parseInt(price) * (parseFloat(perc) / 100));
        return result;
    };
    useEffect(() => {
        let data = [...feeFields];
        let index = data.findIndex((element) => element.name === 'Commission');
        let price = values.askingPrice
            ? values.askingPrice
            : values.oneCheqPrice;
        if (values.commission !== '') {
            if (index === -1)
                data.push({
                    name: 'Commission',
                    value: findPercentage(price, values.commission)
                });
            else
                data.splice(index, 1, {
                    name: 'Commission',
                    value: findPercentage(price, values.commission)
                });
            setFeeFields(data);
        }
        if (values.commission === '') {
            if (index !== -1) data.splice(index, 1);
            setFeeFields(data);
        }
        let index2 = data.findIndex(
            (element) => element.name === 'Security Deposit'
        );
        if (values.securityDeposit !== '') {
            if (index2 === -1)
                data.push({
                    name: 'Security Deposit',
                    value: findPercentage(price, values.securityDeposit)
                });
            else
                data.splice(index2, 1, {
                    name: 'Security Deposit',
                    value: findPercentage(price, values.securityDeposit)
                });
            setFeeFields(data);
        }
        if (values.securityDeposit === '') {
            if (index2 !== -1) data.splice(index2, 1);
            setFeeFields(data);
        }
    }, [
        values.commission,
        values.securityDeposit,
        values.askingPrice,
        values.oneCheqPrice
    ]);

    useEffect(() => {
        if (values.listingType === ListingType[1]) {
            values.commission = '5';
        }
    }, []);

    const handleVideoChange = (event) => {
        let data = [...videosLink];
        data[0] = event.target.value;
        setVideosLink(data);
    };
    useEffect(() => {
        setFormData((prevState) => ({
            ...prevState,
            videos: videosLink
        }));
    }, [videosLink]);

    const handleMLinkChange = (event) => {
        let data = [...mLinks];
        data[0] = event.target.value;
        setMLinks(data);
    };
    useEffect(() => {
        setFormData((prevState) => ({
            ...prevState,
            matterportLink: mLinks
        }));
    }, [mLinks]);
    // Matterpot link end

    // after form submit validating the form data using validator
    const submitFormData = async (e) => {
        e.preventDefault();
        // checking if value of first name and last name is empty show error else take to next step
        if (values.agentStatus === Agent[0] && values.agent_id === null) {
            setError(true);
            toast.error('Please assign an Agent');
        } else if (!values.askingPrice && !values.oneCheqPrice) {
            setError(true);
            toast.error('Error in Price');
        } else if (amenityFieldIds.length < 5) {
            setError(true);
            toast.error('Please Add   at least 5 amenities');
        } else if (
            feeFields.length > 0 &&
            feeFields.filter(
                (input, index) => input.value === '' || input.name === ''
            ).length > 0
        ) {
            toast.error('Error in Fee Field');
        } else if (
            inputFields.length > 0 &&
            inputFields.filter(
                (input, index) => input.value === '' || input.name === ''
            ).length > 0
        ) {
            toast.error('Error in Custom Field');
        } else {
            if (type === 'add') await finalSubmit();
            if (type === 'edit') await finalEdit();
            // props.next();
        }
    };
    const finalSubmit = async () => {
      console.log('subitte')
        const {
            permitNo,
            permitNoDTCM,
            listingType,
            categoryId,
            propertyTypeId,
            propertyTitle,
            propertyDesc,
            featured,
            dealOfWeek,
            premium,
            exclusive,
            completionStatus,
            completionDate,
            metaDesc,
            metaTags,
            commission,
            securityDeposit,
            buildingName,
            street,
            communityId,
            communityName,
            subCommunity,
            emirate,
            pinlocation,
            size,
            villaSize,
            beds,
            baths,
            amenityIds,
            amenityObjs,
            image,
            customField,
            rentalPeriod,
            rentFinance,
            askingPrice,
            pricedrop,
            oneCheqPrice,
            twoCheqPrice,
            fourCheqPrice,
            sixCheqPrice,
            twelveCheqprice,
            fees,
            unitStatus,
            unitReraNo,
            makaniNo,
            dewaNo,
            videos,
            matterportLink,
            agentStatus,
            agent_id,
            lng,
            lat,
            property_owner_id,
            furnishing,
            vacantOnTransfer,
            numberOfCheques,
            contractValidity,
            contractA,
            isOffPlanResale,
            amountAlreadyPaid,
            priceEvaluation,
        } = values;
        const propertyData = {
            permitNo,
            permitNoDTCM,
            listingType,
            categoryId,
            propertyTypeId,
            propertyTitle,
            propertyDesc,
            featured,
            dealOfWeek,
            premium,
            exclusive,
            completionStatus,
            completionDate,
            metaDesc,
            metaTags,
            commission,
            securityDeposit,
            buildingName,
            street,
            communityId,
            communityName,
            subCommunity,
            emirate,
            pinlocation,
            size,
            villaSize,
            beds,
            baths,
            amenityIds,
            amenityObjs,
            image,
            customField,
            rentalPeriod,
            rentFinance,
            askingPrice,
            pricedrop,
            oneCheqPrice,
            twoCheqPrice,
            fourCheqPrice,
            sixCheqPrice,
            twelveCheqprice,
            fees,
            unitStatus,
            unitReraNo,
            makaniNo,
            dewaNo,
            videos,
            matterportLink,
            agentStatus,
            status: Status[1],
            agent_id,
            lng,
            lat,
            property_owner_id: property_owner_id ? property_owner_id : null,
            furnishing,
            contractA,
            vacantOnTransfer,
            numberOfCheques: isNaN(Number(numberOfCheques)) ? 0 : Number(numberOfCheques),
            contractValidity,
            isOffPlanResale: isOffPlanResale?.value === 'Yes',
            amountAlreadyPaid,
            priceEvaluation
        };
        try {
            console.log('sent from front-end: ', propertyData);
            await axios
                .post(`/propList`, propertyData)
                .then((res) => {
                    setFormData((prevState) => ({
                        ...prevState,
                        id: res.data.id
                    }));
                    toast.success('Property added! Please upload images');
                    props.next();
                })
                .catch((err) => {
                    // console.log(err)
                    toast.error(err.response.data.message);
                });
        } catch (err) {
            // console.log(err)
            toast.error('Server error. Try Again!');
        }
    };
    const finalEdit = async () => {
        const {
            permitNo,
            permitNoDTCM,
            listingType,
            categoryId,
            propertyTypeId,
            propertyTitle,
            propertyDesc,
            featured,
            dealOfWeek,
            premium,
            exclusive,
            completionStatus,
            completionDate,
            metaDesc,
            metaTags,
            commission,
            securityDeposit,
            buildingName,
            street,
            communityId,
            communityName,
            subCommunity,
            emirate,
            pinlocation,
            size,
            villaSize,
            beds,
            baths,
            amenityIds,
            amenityObjs,
            image,
            customField,
            rentalPeriod,
            rentFinance,
            askingPrice,
            pricedrop,
            oneCheqPrice,
            twoCheqPrice,
            fourCheqPrice,
            sixCheqPrice,
            twelveCheqprice,
            fees,
            unitStatus,
            unitReraNo,
            makaniNo,
            dewaNo,
            videos,
            matterportLink,
            agentStatus,
            agent_id,
            lng,
            lat,
            property_owner_id,
            priceEvaluation
        } = values;
        const propId = values?.id;
        const propertyData = {
            permitNo,
            permitNoDTCM,
            listingType,
            categoryId,
            propertyTypeId,
            propertyTitle,
            propertyDesc,
            featured,
            dealOfWeek,
            premium,
            exclusive,
            completionStatus,
            completionDate,
            metaDesc,
            metaTags,
            commission,
            securityDeposit,
            buildingName,
            street,
            communityId,
            communityName,
            subCommunity,
            emirate,
            pinlocation,
            size,
            villaSize,
            beds,
            baths,
            amenityIds,
            amenityObjs,
            image,
            customField,
            rentalPeriod,
            rentFinance,
            askingPrice,
            pricedrop,
            oneCheqPrice,
            twoCheqPrice,
            fourCheqPrice,
            sixCheqPrice,
            twelveCheqprice,
            fees,
            unitStatus,
            unitReraNo,
            makaniNo,
            dewaNo,
            videos,
            matterportLink,
            agentStatus,
            agent_id,
            lng,
            lat,
            property_owner_id,
            priceEvaluation
        };

        try {
            await axios
                .patch(`/propList/${propId}`, propertyData)
                .then((property) => {
                    navigate(`/property-list`);
                    toast.success('Property Edited');
                })
                .catch((err) => {
                    // console.log(err)
                    toast.error('Property not Edited!');
                });
        } catch (err) {
            // console.log(err)
            toast.error('Server error. Try Again!');
        }
    };

    useEffect(() => {
        async function fetchAmenity() {
            try {
                await axios
                    .get(`/amenity`)
                    .then((amenities) => {
                        let options = [];
                        amenities.data.forEach((ele) =>
                            options.push({ label: ele.amenity, value: ele.id })
                        );
                        setAmenityOptions(options);
                        let fil = [];
                        amenities.data.forEach((ele) =>
                            values.amenityIds.map((am) =>
                                am === ele.id
                                    ? fil.push({
                                          label: ele.amenity,
                                          value: ele.id
                                      })
                                    : null
                            )
                        );
                        setAmenityFilters(fil);
                        setAmenityFieldIds(values.amenityIds);
                        setAmenities(amenities.data);
                        // setAmenity(options);
                    })
                    .catch((err) => {
                        toast.error('Amenity not available !');
                    });
            } catch (err) {
                // console.log(err)
                toast.error('Server error. Try Again !');
            }
        }
        fetchAmenity();
    }, []);

    useEffect(() => {
        if (values.agentStatus === Agent[1]) {
            setFormData((prevState) => ({
                ...prevState,
                agent_id: null
            }));
        }
    }, [values.agentStatus]);

    const handleSelectAmenities = (selectedOptions) => {
        setSelectedAmenities(selectedOptions);
        addAmenityListFields(selectedOptions);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(selectedAmenities);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setSelectedAmenities(items);
    };
    return (
        <>
            <Row className="gy-3">
                {values.listingType === ListingType[0] && (
                    <>
                        <Col md="6">
                            <div className="form-group">
                                <label
                                    className="form-label"
                                    htmlFor="first-name"
                                >
                                    Asking Price *
                                </label>
                                <div className="form-control-wrap">
                                    <div className="input-container">
                                        <input
                                            type="text"
                                            id="first-name"
                                            className="form-control"
                                            name="askingPrice"
                                            onInput={(e) => {
                                                e.target.value =
                                                    e.target.value.replace(
                                                        /[^0-9.]/g,
                                                        ''
                                                    ); // Allow only numbers and .
                                            }}
                                            maxLength="12" // Limit total characters
                                            value={values.askingPrice}
                                            onChange={handleFormData(
                                                'askingPrice'
                                            )}
                                        />
                                        <span
                                            className="input-text"
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)'
                                            }}
                                        >
                                            AED
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="form-group">
                                <label
                                    className="form-label"
                                    htmlFor="agency-valuation"
                                >
                                    Our Valuation 
                                </label>
                                <div className="form-control-wrap">
                                    <div className="input-container">
                                        <input
                                            type="text"
                                            id="agency-valuation"
                                            className="form-control"
                                            name="priceEvaluation"
                                            onInput={(e) => {
                                                e.target.value =
                                                    e.target.value.replace(
                                                        /[^0-9.]/g,
                                                        ''
                                                    ); // Allow only numbers and .
                                            }}
                                            maxLength="12" // Limit total characters
                                            value={values.priceEvaluation}
                                            onChange={handleFormData(
                                                'priceEvaluation'
                                            )}
                                        />
                                        <span
                                            className="input-text"
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)'
                                            }}
                                        >
                                            AED
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </>
                )}

                {values.listingType === ListingType[0] && (
                    <>
                        <Col md="6">
                            <div className="form-group">
                                <label
                                    className="form-label"
                                    htmlFor="first-name"
                                >
                                    Price Drop
                                </label>
                                <div className="form-control-wrap">
                                    <div className="input-container">
                                        <input
                                            type="text"
                                            id="first-name"
                                            onInput={(e) => {
                                                let newValue =
                                                    e.target.value.replace(
                                                        /[^0-9.]/g,
                                                        ''
                                                    ); // Allow only numbers and .
                                                if (newValue.length > 2) {
                                                    // Limit length to 2 characters
                                                    newValue = newValue.slice(
                                                        0,
                                                        2
                                                    );
                                                }
                                                e.target.value = newValue;
                                            }}
                                            className="form-control"
                                            name="pricedrop"
                                            value={values.pricedrop}
                                            onChange={handleFormData(
                                                'pricedrop'
                                            )}
                                        />
                                        <span
                                            className="input-text"
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)'
                                            }}
                                        >
                                            %
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </>
                )}

                {values.listingType === ListingType[1] && (
                    <>
                        <Col md="3">
                            <div className="form-group">
                                <label
                                    className="form-label"
                                    htmlFor="first-name"
                                >
                                    Rental Period
                                </label>
                                <div className="form-control-wrap">
                                    <RSelect
                                        onChange={(e) => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                rentalPeriod: e.value
                                            }));
                                        }}
                                        options={RentalPeriod?.map((e) => ({
                                            label: e,
                                            value: e
                                        }))}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md="3">
                            <div className="form-group">
                                <label
                                    className="form-label"
                                    htmlFor="numberOfCheques"
                                >
                                    Number of Cheques
                                </label>
                                <div className="form-control-wrap">
                                    <input
                                        className='form-control'
                                        placeholder='Enter number of cheques (1-12)'
                                        onChange={(e) => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                numberOfCheques: e.target.value
                                            }));
                                        }}
                                        type='number'
                                        name='numberOfCheques'
                                        id='numberOfCheques'
                                        min={1}
                                        max={12}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="form-group">
                                <label
                                    className="form-label"
                                    htmlFor="first-name"
                                >
                                    1 Cheque Price *
                                </label>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        id="first-name"
                                        onInput={(e) => {
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^0-9.]/g,
                                                    ''
                                                ); // Allow only numbers and .
                                        }}
                                        maxLength="12" // Limit total characters
                                        className="form-control"
                                        name="oneCheqPrice"
                                        value={values.oneCheqPrice}
                                        onChange={handleFormData(
                                            'oneCheqPrice'
                                        )}
                                    />
                                    <span
                                        className="input-text"
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)'
                                        }}
                                    >
                                        AED
                                    </span>
                                </div>
                            </div>
                        </Col>
                        {values.rentalPeriod === RentalPeriod[0] && (
                            <>
                                <Col md="6">
                                    <div className="form-group">
                                        <label
                                            className="form-label"
                                            htmlFor="first-name"
                                        >
                                            2 Cheque Price
                                        </label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                id="first-name"
                                                onInput={(e) => {
                                                    e.target.value =
                                                        e.target.value.replace(
                                                            /[^0-9.]/g,
                                                            ''
                                                        ); // Allow only numbers and .
                                                }}
                                                maxLength="12" // Limit total characters
                                                className="form-control"
                                                name="firstName"
                                                value={values.twoCheqPrice}
                                                onChange={handleFormData(
                                                    'twoCheqPrice'
                                                )}
                                            />
                                            <span
                                                className="input-text"
                                                style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform:
                                                        'translateY(-50%)'
                                                }}
                                            >
                                                AED
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <label
                                            className="form-label"
                                            htmlFor="first-name"
                                        >
                                            4 Cheque Price
                                        </label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                id="first-name"
                                                onInput={(e) => {
                                                    e.target.value =
                                                        e.target.value.replace(
                                                            /[^0-9.]/g,
                                                            ''
                                                        ); // Allow only numbers and .
                                                }}
                                                maxLength="12" // Limit total characters
                                                className="form-control"
                                                name="fourCheqPrice"
                                                value={values.fourCheqPrice}
                                                onChange={handleFormData(
                                                    'fourCheqPrice'
                                                )}
                                            />
                                            <span
                                                className="input-text"
                                                style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform:
                                                        'translateY(-50%)'
                                                }}
                                            >
                                                AED
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <label
                                            className="form-label"
                                            htmlFor="first-name"
                                        >
                                            6 Cheque Price
                                        </label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                id="first-name"
                                                onInput={(e) => {
                                                    e.target.value =
                                                        e.target.value.replace(
                                                            /[^0-9.]/g,
                                                            ''
                                                        ); // Allow only numbers and .
                                                }}
                                                maxLength="12" // Limit total characters
                                                className="form-control"
                                                name="sixCheqPrice"
                                                value={values.sixCheqPrice}
                                                onChange={handleFormData(
                                                    'sixCheqPrice'
                                                )}
                                            />
                                            <span
                                                className="input-text"
                                                style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform:
                                                        'translateY(-50%)'
                                                }}
                                            >
                                                AED
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <label
                                            className="form-label"
                                            htmlFor="first-name"
                                        >
                                            12 Cheque Price
                                        </label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                id="first-name"
                                                onInput={(e) => {
                                                    e.target.value =
                                                        e.target.value.replace(
                                                            /[^0-9.]/g,
                                                            ''
                                                        ); // Allow only numbers and .
                                                }}
                                                maxLength="12" // Limit total characters
                                                className="form-control"
                                                name="twelveCheqprice"
                                                value={values.twelveCheqprice}
                                                onChange={handleFormData(
                                                    'twelveCheqprice'
                                                )}
                                            />
                                            <span
                                                className="input-text"
                                                style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform:
                                                        'translateY(-50%)'
                                                }}
                                            >
                                                AED
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </>
                        )}
                    </>
                )}
                <Col md="6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="first-name">
                            Commission
                        </label>
                        <div className="form-control-wrap">
                            <div className="input-container">
                            <input
                                name="commission"
                                id="commission"
                                onInput={(e) => {
                                let newValue = e.target.value
                                    .replace(/[^0-9.]/g, '') 
                                    .replace(/^(\d*\.\d{3})\d*$/, '$1'); 

                                if (newValue.includes('.')) {
                                    const [integerPart, decimalPart] = newValue.split('.');
                                    if (integerPart.length > 2) {
                                    newValue = integerPart.slice(0, 2) + '.' + decimalPart;
                                    }
                                    if (decimalPart.length > 3) {
                                    newValue = integerPart + '.' + decimalPart.slice(0, 3);
                                    }
                                } else if (newValue.length > 2) {
                                    newValue = newValue.slice(0, 2);
                                }

                                e.target.value = newValue;
                                }}
                                className="form-control"
                                defaultValue={values.commission}
                                onChange={handleFormData("commission")}
                            />
                                <span
                                    className="input-text"
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)'
                                    }}
                                >
                                    %
                                </span>
                            </div>
                        </div>
                    </div>
                </Col>
                {values.listingType === ListingType[1] && (
                    <Col md="6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="first-name">
                                Security Deposit
                            </label>
                            <div className="form-control-wrap">
                                <input
                                    type="number"
                                    name="securityDeposit"
                                    onInput={(e) => {
                                        let newValue = e.target.value.replace(
                                            /[^0-9.]/g,
                                            ''
                                        ); // Allow only numbers and .
                                        if (newValue.length > 2) {
                                            // Limit length to 2 characters
                                            newValue = newValue.slice(0, 2);
                                        }
                                        e.target.value = newValue;
                                    }}
                                    id="securityDeposit"
                                    className="form-control"
                                    value={values.securityDeposit}
                                    onChange={handleFormData('securityDeposit')}
                                />
                                <span
                                    className="input-text"
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)'
                                    }}
                                >
                                    %
                                </span>
                            </div>
                        </div>
                    </Col>
                )}

                <Col sm={6}>
                    <div className="form-group">
                        <label className="form-label">Financing</label>
                        <RSelect
                            onChange={(e) => {
                                setFormData((prevState) => ({
                                    ...prevState,
                                    rentFinance: e.value
                                }));
                            }}
                            options={Finance?.map((e) => ({
                                label: e,
                                value: e
                            }))}
                        />
                    </div>
                </Col>

                <Col sm={6}>
                    <div className="form-group">
                        <div className="d-flex justify-content-between align-items-center">
                            <label className="form-label">Fees</label>
                            {feeFields?.length === 0 && (
                                <Button
                                    color="success"
                                    onClick={(e) => addFeeField(e)}
                                    type="button"
                                >
                                    <Icon name="plus"></Icon>
                                </Button>
                            )}
                        </div>

                        {feeFields &&
                            feeFields.map((input, index) => {
                                return (
                                    <div className="d-flex align-items-center mb-2">
                                        <div
                                            className="me-2"
                                            style={{ width: '21rem' }}
                                        >
                                            <input
                                                type="text"
                                                className="form-control "
                                                style={{ width: '21rem' }}
                                                name="name"
                                                id="name"
                                                placeholder="Name"
                                                value={input.name}
                                                onChange={(event) =>
                                                    handleFeeFormChange(
                                                        index,
                                                        event
                                                    )
                                                }
                                            />
                                        </div>
                                        <input
                                            type="number"
                                            id="value"
                                            className="form-control me-2"
                                            style={{ width: '21rem' }}
                                            placeholder="Fee Value"
                                            name="value"
                                            value={input.value}
                                            onChange={(event) =>
                                                handleFeeFormChange(
                                                    index,
                                                    event
                                                )
                                            }
                                        />
                                        <Button
                                            onClick={() =>
                                                removeFeeField(index)
                                            }
                                            color="danger"
                                            type="button"
                                            className="me-2"
                                        >
                                            <Icon name="trash"></Icon>
                                        </Button>

                                        <Button
                                            onClick={(e) => addFeeField(e)}
                                            color="success"
                                            type="button"
                                        >
                                            <Icon name="plus"></Icon>
                                        </Button>
                                    </div>
                                );
                            })}
                    </div>
                </Col>
            </Row>
            <div className="actions clearfix">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-between">
                    <li>
                        <Button
                            onClick={(e) => submitFormData(e)}
                            // onClick={ () => props.next() }
                            color="primary"
                            type="submit"
                        >
                            Submit
                        </Button>
                    </li>
                    <li>
                        <Button color="primary" onClick={() => props.prev()}>
                            Previous
                        </Button>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default StepThree;
