import { useLocation, useNavigate } from "react-router-dom";

import * as types from "../redux/types";

import { useDispatch } from "react-redux";
import { axiosPrivate } from "../utils/Utils";
import { newRoles } from "../utils/envConfig";
import { fetchLoggedInAgent } from "../redux/actions/user";
import { useQueryClient } from "react-query";

export default function useRefresh() {
    const dispatch = useDispatch();

    const location = useLocation();

    const currentPage = location?.pathname ?? "/";

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const refresh = async () => {
        try {
            const { data } = await axiosPrivate.get(
                `/auth/refresh-token`
            );

            // Update axios instance with the new token
            axiosPrivate.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;

            // update access token of authenticated user

            dispatch({
                type: types.USER_LOGIN,
                payload: data,
            });
            if (data && data.role === newRoles.Agent) {
                dispatch(fetchLoggedInAgent(axiosPrivate, data.id));
            }

            // Invalidate all react-query queries
            await queryClient.invalidateQueries();

            return data;
        } catch (err) {
            if (err.response.status === 401) {
                dispatch( { type: types.LOGOUT, payload: {} } );
                navigate("/auth-login", {
                    state: {
                        from: {
                            pathname: currentPage,
                        },
                    },
                    replace: true,
                });
                localStorage.removeItem( "persist:root" );
                localStorage.removeItem( "authuser" );
            }
            return null;
        }
    };
    return refresh;
}
