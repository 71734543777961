/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import { fetchUserActivities } from "../api/activities/fetchUserActivities";
/**
 * An object containing queries related to user activities.
 *
 * @namespace activityQueries
 */
const activityQueries = {
  /**
   * Fetches activities for a specific user.
   *
   * @param {Axios} axios - The Axios instance to use for making requests.
   * @param {string | number} userId - The ID of the user whose activities to fetch.
   * @param {import("axios").AxiosRequestConfig} [options] - Optional parameters for the query.
   * @returns {import('react-query').QueryConfig<any, Error, any>} - A React Query configuration object.
   */
  getByUser: (axios, userId, options) => ({
    queryKey: [`userActivities`, userId, options?.params],
    queryFn: () => fetchUserActivities(axios, userId, options),
  })
};

export default activityQueries;