import ImportDataUi from "./components/ImportDataUi";

const ImportData = () => {
    return (
        <>
            <ImportDataUi title={"Import Data"} name={"Lead"} role={null} />
        </>
    );
};

export default ImportData;
