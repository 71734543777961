import * as types from "../types/community-teams";

const initialState = {
  communityTeams: [],
  communityTeam: null,
  loading: true,
  error: null,
  status: null,
};

export const communityTeam = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COMMUNITY_TEAMS_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_COMMUNITY_TEAMS:
      return {
        ...state,
        communityTeams: action.payload,
        loading: false,
        error: null,
      };
    case types.GET_COMMUNITY_TEAMS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.GET_COMMUNITY_TEAM_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_COMMUNITY_TEAM:
      return {
        ...state,
        communityTeam: action.payload,
        loading: false,
        error: null,
      };
    case types.GET_COMMUNITY_TEAM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ADD_COMMUNITY_TEAM:
      return {
        ...state,
        communityTeam: action.payload,
        status: action.status,
        loading: false,
        error: null,
      };
    case types.ADD_COMMUNITY_TEAM_STARTED:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_COMMUNITY_TEAM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.DELETE_COMMUNITY_TEAM:
      return {
        ...state,
        status: action.status,
        loading: false,
      };
    case types.DELETE_COMMUNITY_TEAM_STARTED:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_COMMUNITY_TEAM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.UPDATE_COMMUNITY_TEAM:
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };
    case types.UPDATE_COMMUNITY_TEAM_STARTED:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_COMMUNITY_TEAM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ADD_TEAM_MEMBER_STARTED:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_TEAM_MEMBER:
      return {
        ...state,
        loading: false,
        error: null,
        status: action.status,
      };
    case types.ADD_TEAM_MEMBER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ADD_TEAM_COMMUNITY_STARTED:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_TEAM_COMMUNITY:
      return {
        ...state,
        loading: false,
        error: null,
        status: action.status,
      };
    case types.ADD_TEAM_COMMUNITY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.REMOVE_TEAM_MEMBER_STARTED:
      return {
        ...state,
        loading: true,
      };
    case types.REMOVE_TEAM_MEMBER:
      return {
        ...state,
        loading: false,
        error: null,
        status: action.status,
      };
    case types.REMOVE_TEAM_MEMBER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.REMOVE_TEAM_COMMUNITY_STARTED:
      return {
        ...state,
        loading: true,
      };
    case types.REMOVE_TEAM_COMMUNITY:
      return {
        ...state,
        loading: false,
        error: null,
        status: action.status,
      };
    case types.REMOVE_TEAM_COMMUNITY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
