import { Spinner } from "reactstrap";

const LoadingFallback = ({ message = 'Loading Deals Board...' }) => (
  <div className="kanban-loading-fallback">
    <div className="kanban-loading-content">
      <Spinner color="primary" />
      <p>{message}</p>
    </div>
    <style jsx="true">{`
      .kanban-loading-fallback {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
        background-color: #f5f6fa;
        border-radius: 4px;
      }
      .kanban-loading-content {
        text-align: center;
      }
      .kanban-loading-content p {
        margin-top: 10px;
        color: #6e82a5;
      }
    `}</style>
  </div>
);

export default LoadingFallback;

