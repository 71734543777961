/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";

const RangeFilter = ({
  min,
  max,
  placeholder,
  onSubmit,
  defaultMin = 0,
  defaultMax = Number.MAX_SAFE_INTEGER,
  isCurrency = false,
}) => {
  const [minValue, setMinValue] = useState(min != undefined ? min : "");
  const [maxValue, setMaxValue] = useState(max != undefined ? max : "");

  useEffect(() => {
    setMaxValue((_) => max);
  }, [max]);
  
  useEffect(() => {
    if (!min) {
      setMinValue((_) => 0);
    }
    setMinValue((_) => min);
  }, [min]);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      min: parseInt(minValue),
      max: parseInt(maxValue)
    });
  };

  const formatter = new Intl.NumberFormat('en-US');
  const allowedCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter'];
  return (
    <form action="" onSubmit={handleSubmit}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <Input
            value={minValue !== defaultMin ? minValue : ''}
            style={{ borderRadius: "4px 0 0 4px", height: "25px" }}
            onChange={(e) => setMinValue((c) => (e.target.value))}
            onBlur={(e) => (isCurrency ? setMinValue(formatter.format(e.target.value)) : null)}
            onKeyDown={(e) => {
                if (!allowedCharacters.includes(e.key)) {
                    e.preventDefault();
                }
            }}
            type="text"
            placeholder={`Min ${placeholder}`}
            className="form-control"
          />
          <Input
            style={{ borderRadius: "0", height: "25px", width: "40px" }}
            placeholder="TO"
            disabled={true}
            className="input-group-addon text-center"
          ></Input>
          <Input
            value={maxValue !== defaultMax ? maxValue : ''}
            onKeyDown={(e) => {
                if (!allowedCharacters.includes(e.key)) {
                    e.preventDefault();
                }
            }}
            onChange={(e) => setMaxValue((c) => e.target.value)}
            onBlur={(e) => (isCurrency ? setMaxValue(formatter.format(e.target.value)) : null)}
            style={{ borderRadius: "0 4px 4px 0", height: "25px" }}
            type="text"
            placeholder={`Max ${placeholder}`}
            className="form-control"
          />
          <Button type="submit" className="d-none">Submit</Button>
        </div>
    </form>
  );
};

export default React.memo(RangeFilter);
