/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchBuildingsList,
    fetchCommunitiesList,
    fetchPropertiesAdmin,
} from "../../redux/actions/property";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Button, Input, Modal, ModalBody,  } from "reactstrap";
import {
    Block,
    BlockHeadContent,
    BlockTitle,
    BlockBetween,
    BlockHead,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    Row,
    Col,
    RSelect,
    TooltipComponent,
    PaginationComponent,
    BlockDes,
} from "../../components/Component";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/miscellaneous/ConfirmModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { serverAPI } from "../../utils/envConfig";
import queryString from "query-string";
import LoadingComponent from "../components/loader/tableLoader";
import { debounce } from "lodash";


const BuildingList = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const community = useSelector((state) => state.property?.communities);
    const property = useSelector((state) => state.property?.propertiesAdmin);
    const { buildings, buildingsCount, buildingsLoading } = useSelector((state) => ({
        buildings: state.property?.buildings.results,
        buildingsCount: state.property?.buildings.found,
        buildingsLoading: state.property?.loading,
        
    }));

    
    const axios = useAxiosPrivate();
    
    // State Variables
    const [open, setOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [buildingId, setBuildingId] = useState("");
    const [buildingName, setBuildingName] = useState("");
    const [check, setCheck] = useState([]);
    const location = useLocation();
    const query = queryString.parse(location.search);
    
    // pagination state
    const [page, setPage] = useState(query?.page ? Number(query?.page) : 1);
    const [perPage, setPerPage] = useState(
      query?.limit ? Number(query?.limit) : 20
    );
    const [refresh, setRefresh] = useState(false);
    const [searchText, setSearchText] = useState(query?.search ?? "");


    const defaultPlaceDistances = [
        {
            place: "Metro/Bus Stop",
            distance: 0,
        },
        {
            place: "Shopping",
            distance: 0,
        },
        {
            place: "School",
            distance: 0,
        },
        {
            place: "Beach",
            distance: 0,
        },
        {
            place: "Mosques",
            distance: 0,
        },
        {
            place: "Supermarkets",
            distance: 0,
        },
        {
            place: "Pharmacy",
            distance: 0,
        },
        {
            place: "Park",
            distance: 0,
        },
    ];

    // State Variables
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState("");
    const [distances, setDistances] = useState(
        defaultPlaceDistances.map((obj) => "")
    );
    const [communityId, setCommunityId] = useState("");
    const [communityName, setCommunityName] = useState("");
    const [building, setBuilding] = useState({});
    const [edit, setEdit] = useState(false);

    // Constants
    // Validate User Token
    const { access_token } = useSelector((state) => state?.user?.loggedInUser);
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    const handleModal = (e, id, name) => {
        e.preventDefault();
        setBuildingId(id);
        setBuildingName(name);
        setShowModal(true);
    };

    const validate = () => {
        if (!communityId) {
            toast.error("Select Community");
            return false;
        }

        if (!name) {
            toast.error("Enter valid Name");
            return false;
        }

        if (distances.length === 0) {
            toast.error("Enter valid Distances");
            return false;
        }

        return true;
    };

    const add = async (e) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true);
            const formData = {
                name,
                communityId,
                distances: defaultPlaceDistances.map((obj, index) => ({
                    ...obj,
                    distance: parseInt(distances[index]),
                })),
            };
            //   console.log(formData)
            try {
                await axios.post(`/buildings`, formData);
                navigate(0);
            } catch (err) {
              toast.error(err.response?.data?.message ?? "Server error. Try Again !");
            }
            setLoading(false);
        }
    };
    const handleEdit = (e, building) => {
        e.preventDefault();
        setOpen(true);
        setEdit(true);
        setBuilding(building);
        setName(building.name);
        setCommunityId(building.communityId);
        setCommunityName(building?.community?.community);
        setDistances(building.distances.map((obj) => obj.distance));
    };

    const resetForm = () => {
        setOpen(false);
        setEdit(false);
        setBuilding(null);
        setName(null);
        setCommunityId(null);
        setCommunityName(null);
        setDistances(defaultPlaceDistances.map((obj) => ""));
    };
    const editBuilding = async (e) => {
        e.preventDefault();
        if (validate()) {
          setLoading(true);
          const formData = {
            name,
            communityId,
            distances: building.distances.map((obj, index) => ({
              ...obj,
              distance: parseInt(distances[index]),
            })),
          };
          try {
            await axios.patch(`${serverAPI}/buildings/${building.id}`, formData, {
              headers,
            });
            navigate(0);
          } catch (err) {
            toast.error(err.response?.data?.message ?? "Server error. Try Again !");

          }
          setLoading(false);
        }
      };

    const closeModal = () => {
        setShowModal(false);
    };
    const queryBuildings = (params, updateRoute = true) => {
      const buildingsParams = {
        limit: params?.perPage ?? query?.limit,
        page: params?.page ?? query?.page,
        search: params?.search ?? query?.search,
      };

      const queryParams = new URLSearchParams();

    Object.keys(buildingsParams).forEach((key) => {
      const value = buildingsParams[key];
      if (Array.isArray(value)) {
        value.forEach((subValue) => queryParams.append(key, subValue));
        // eslint-disable-next-line eqeqeq
      } else if (value != undefined && value !== "null") {
        queryParams.append(key, value);
      }
    });

    const queryString = queryParams.toString();

    if (updateRoute) {
      const navigateParams = {};

      if (location.search.length) {
        navigateParams.replace = true;
      }

      navigate(
        `${"/building-list"}?${queryString}`,
        navigateParams
      );
    }
    return queryString;
    }

    const handleSearchSubmit = (event) => {
      setSearchText(event.target.value);
      debounceSearchBuildings({ search: event.target.value });
    };

    // debounce update query
    const updateSearchQuery = useCallback(queryBuildings, [location.search.length, navigate, query?.limit, query?.page, query?.search]);

    const debounceSearchBuildings = useMemo(() => {
      return debounce(updateSearchQuery, 500);
    }, [updateSearchQuery]);
    // end debouncing

    useEffect(() => {
      dispatch(fetchCommunitiesList(axios));
      dispatch(fetchPropertiesAdmin(axios));
      dispatch(fetchBuildingsList(axios));
  }, [axios, dispatch]);

    useEffect(() => {
      const queryString = queryBuildings({
        perPage,
        page,
        search: query?.search ?? null,
      });

      dispatch(fetchBuildingsList(axios, `?${queryString}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh, location.search, page, perPage])

    const submitDel = async (e) => {
        e.preventDefault();
        try {
            await axios.delete(`/buildings/${buildingId}`);
            navigate(0);
        } catch (err) {
            // console.log(err)
            toast.error(err.response?.data?.message ?? "Server error. Try Again !");
        }
        setShowModal(false);
    };

    useEffect(() => {
        if (buildings && buildings.length > 0 && property.length > 0) {
            let arr = buildings.map((item, index) =>
                property.map((p) => p.building_id === item.id)
            );
            let propCheck = [];
            arr.forEach((element) => {
                propCheck.push(element.reduce((x, y) => x || y, false));
            });
            const result = propCheck;
            setCheck(result);
        }
    }, [buildings, property]);

    return (
      <>
        <React.Fragment>
          <Head title="Amenity"></Head>
          <Content>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle>Buildings</BlockTitle>
                  <BlockDes className="text-soft">
                    <p>Found {buildingsCount ?? 0} Building(s).</p>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <div className="toggle-expand-content">
                      <ul className="nk-block-tools g-3">
                        <li className="nk-block-tools-opt">
                          <Button
                            className="toggle btn-icon d-md-none"
                            color="primary"
                          >
                            <Icon name="plus"></Icon>
                          </Button>
                          <Button
                            className="toggle d-none d-md-inline-flex"
                            color="primary"
                            onClick={() => {
                              setOpen(true);
                            }}
                          >
                            <Icon name="plus"></Icon>
                            <span>Add Building</span>
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <div className="my-3 d-flex flex-row-reverse align-items-center justify-content-between">
              {/* Entries per page config */}
              <div className="d-flex align-items-center">
                <span className="d-none d-sm-inline-block me-2">Show</span>
                <div className="form-control-select">
                  <select
                    name="DataTables_Table_0_length"
                    className="custom-select custom-select-sm form-control form-control-sm"
                    onChange={(e) => setPerPage(e.target.value)}
                    value={perPage}
                  >
                    <option value="1">1</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                </div>
              </div>
              {/* Search bar */}
              <div className="d-flex justify-content-center align-items-center">
                <Input placeholder="Search Buildings..." value={searchText} type="text" name="search" onChange={handleSearchSubmit} style={{ minWidth: "20rem" }} />
              </div>
            </div>
            <Block>
              {!buildingsLoading && buildings ? (
                <div className="nk-tb-list is-separate is-medium mb-3">
                  <DataTableHead className="nk-tb-item">
                    <DataTableRow>
                      <span className="sub-text">Building Name </span>
                    </DataTableRow>

                    <DataTableRow>
                      <span className="sub-text">Edit</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Delete</span>
                    </DataTableRow>
                  </DataTableHead>
                  {buildings &&
                    [...buildings].map((item, index) => (
                      <DataTableItem key={index}>
                        <DataTableRow>
                          <a href="#id" onClick={(ev) => ev.preventDefault()}>
                            {item.name}
                          </a>
                        </DataTableRow>

                        <DataTableRow>
                          <a href="#" onClick={(ev) => handleEdit(ev, item)}>
                            <Icon
                              name="edit"
                              style={{
                                fontSize: "1.3rem",
                              }}
                            ></Icon>
                          </a>
                        </DataTableRow>
                        <DataTableRow>
                          {check.length > 0 && check[index] === true ? (
                            <>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-icon"
                                id={"delete"}
                                icon="trash"
                                direction="top"
                                text={
                                  "Building can't be removed as it is linked to a Property Listing"
                                }
                              />
                            </>
                          ) : (
                            <a
                              onClick={(e) =>
                                handleModal(e, item.id, item.name)
                              }
                              className="btn btn-icon"
                            >
                              <Icon
                                name="trash"
                                style={{
                                  color: "#e85347",
                                  fontSize: "1.3rem",
                                }}
                              />
                            </a>
                          )}
                        </DataTableRow>
                      </DataTableItem>
                    ))}
                </div>
              ) : <LoadingComponent />}
              {!buildingsLoading ? <PaginationComponent
                noDown
                currentPage={page ?? 1}
                paginate={(page) => {
                  setRefresh(!refresh);
                  setPage(page);
                  queryBuildings({ perPage, page: page }, true);
                }}
                itemPerPage={perPage ?? 10}
                totalItems={buildingsCount}
              /> : null}
            </Block>
            {/* ****** Modal Start ****** */}
            {showModal ? (
              <ConfirmModal
                msg={"Delete "}
                name={"Building "}
                open={showModal}
                handleClick={handleModal}
                handleConfirm={submitDel}
                handlecloseModal={closeModal}
                action="Delete"
              />
            ) : null}
            {/* ****** Modal End ****** */}
            <Modal
              isOpen={open}
              toggle={() => setOpen(false)}
              className="modal-dialog-centered"
              size="lg"
            >
              <ModalBody>
                <a href="#cancel" className="close">
                  {" "}
                  <Icon
                    name="cross-sm"
                    onClick={(ev) => {
                      ev.preventDefault();
                      resetForm();
                      setOpen(false);
                    }}
                  ></Icon>
                </a>
                <div className="p-2">
                  <h5 className="title">{edit ? "Edit" : "Add"} Building</h5>
                  <div className="mt-4">
                    {/* <form onSubmit={ handleSubmit( onFormSubmit ) }> */}
                    <form onSubmit={(e) => (edit ? editBuilding(e) : add(e))}>
                      <Row className="g-3">
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="customer">
                              Building Name
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="amenity"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col size="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="customer">
                              Community
                            </label>
                            <RSelect
                              onChange={(e) => setCommunityId(e.value)}
                              options={community && Array.isArray(community) ? community?.map((c) => ({
                                label: c.community,
                                value: c.id,
                              })) : []}
                              defaultValue={{
                                label: communityName,
                                value: communityId,
                              }}
                              placeholder="Community"
                            />
                          </div>
                        </Col>

                        <Col sm={12}>
                          <div>
                            <div className="form-group">
                              <label className="form-label">Distances*</label>
                              <div className="d-flex align-items-center">
                                <div
                                  className="me-2"
                                  style={{
                                    width: "100%",
                                  }}
                                ></div>
                              </div>
                            </div>
                            {defaultPlaceDistances.map((item, index) => (
                              <div
                                key={index}
                                className="d-flex align-items-between mb-2"
                              >
                                <input
                                  type="text"
                                  value={item.place}
                                  readOnly
                                  className="me-2 form-control"
                                />
                                <div className="input-group me-2">
                                  <div className="input-group">
                                    <input
                                      type="number"
                                      // max={1500}
                                      name={item.place}
                                      id={item.place}
                                      autoComplete={item.place}
                                      required
                                      value={distances[index]}
                                      onChange={(e) =>
                                        setDistances(
                                          distances?.map((distance, i) =>
                                            i === index
                                              ? parseInt(e.target.value)
                                              : parseInt(distance)
                                          )
                                        )
                                      }
                                      className="form-control"
                                    />
                                    <div className="input-group-append">
                                      <span className="input-group-text">
                                        m
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Col>

                        <Col size="12" className="d-flex justify-content-end">
                          <Button
                            color="primary"
                            type="submit"
                            disabled={loading}
                          >
                            {loading
                              ? "Loading.."
                              : edit
                              ? "Save"
                              : "Add Buidling"}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </Content>
        </React.Fragment>
      </>
    );
};

export default BuildingList;
