/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import ErrorHandler from "../../error-handlers/axios/defaultErrorHandler";
import '../../global-types/deal-transactions';
import qs from 'qs';

/**
 * @description Fetches all commission transactions for a specific deal
 * @param {Axios} axiosInstance - the axios instance for making the HTTP request.
 * @param {import("axios").AxiosRequestConfig?} options
 * @returns {Promise<import('../../global-types/deal-transactions').DealTransactionsResponse>} API Response.
 */
export const fetchAllTransactions = async (axiosInstance, options) => {
    try {
      const queryParams = options?.params ?? {};
      if (!queryParams?.sort?.name) {
        // specify default sort
        queryParams.sort = {
          name: 'creation_date',
          order: 'DESC'
        }
      }
      const parsedQueryString = qs.stringify(queryParams, { arrayFormat: 'brackets' });
      const res = await axiosInstance.get(`/deals/transactions?${parsedQueryString}`);
      return res?.data;
    } catch (error) {
      ErrorHandler.handle(error);
    }
}
