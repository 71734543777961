// agent productivity chart

import { Line } from 'react-chartjs-2';

export const ProductivityChart = ({
    data = { labels: [], datasets: [] },
    agents = []
}) => {
    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.color = 'white';
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .3s ease';

            const table = document.createElement('table');
            table.style.margin = '0px';

            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set Text
        if (tooltip.body) {
            const tooltipLabel =
                tooltip?._active[0]?.element['$context']?.dataset?.label;

            const labelAgent = agents?.find(
                (agent) =>
                    agent?.user?.first_name?.toLowerCase() ===
                    tooltipLabel?.toLowerCase()
            );

            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map((b) => b.lines);

            const tableHead = document.createElement('thead');

            titleLines?.forEach((title) => {
                const tr = document.createElement('tr');
                tr.style.borderWidth = 0;

                const th = document.createElement('th');
                th.style.borderWidth = 0;
                const text = document.createTextNode(title);

                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
            });

            const tableBody = document.createElement('tbody');

            bodyLines?.forEach((body, i) => {
                const agentImage = labelAgent?.image;

                const colors = tooltip.labelColors[i];

                const span = document.createElement('span');

                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = '2px';
                span.style.marginRight = '10px';
                span.style.height = '20px';
                span.style.width = '20px';
                span.style.display = 'flex';
                span.style.alignItems = 'center';
                span.style.borderRadius = '50%';
                span.style.overflow = 'hidden';

                const image = document.createElement('img');

                image.src = agentImage;
                image.alt = labelAgent?.user?.first_name;

                span.appendChild(image);

                const text = document.createTextNode(body);

                const _2ndSpan = document.createElement('span');

                _2ndSpan.appendChild(text);

                const contDiv = document.createElement('div');

                contDiv.style.display = 'flex';
                contDiv.style.alignItems = 'center';
                contDiv.style.justifyContent = 'start';
                contDiv.style.fontSize = '12px';

                contDiv.append(span);
                contDiv.append(_2ndSpan);

                const tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;

                const td = document.createElement('td');
                td.style.borderWidth = 0;

                td.appendChild(contDiv);
                // td.appendChild(text);
                tr.appendChild(td);
                tableBody.appendChild(tr);
            });

            const tableRoot = tooltipEl.querySelector('table');

            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.appendChild(tableHead);
            tableRoot.appendChild(tableBody);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding =
            tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    };

    return (
        <Line
            className="ecommerce-line-s4"
            data={{
                labels: data?.labels,
                datasets: data?.datasets?.map((item, i) => ({
                    ...item,
                    data: item?.data,
                    label: item?.label,
                    color: item?.color,
                    borderWidth: 2,
                    borderColor: item?.color,
                    fill: true,
                    pointRadius: 0,
                    lineTension: 0.4,
                    backgroundColor: item?.backgroundColor,
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: 'transparent',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: item?.color,
                    pointBorderWidth: 2,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 2,
                    pointHitRadius: 4
                }))
            }}
            options={{
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false,
                        position: 'nearest',
                        external: externalTooltipHandler
                    }
                },
                maintainAspectRatio: false,
                // tooltips: {
                //     enabled: true,
                //     callbacks: {
                //         title: function (tooltipItem, data) {
                //             return data['labels'][tooltipItem[0]['index']];
                //         },
                //         label: function (tooltipItem, data) {
                //             return data.datasets[tooltipItem.datasetIndex][
                //                 'data'
                //             ][tooltipItem['index']];
                //         }
                //     },
                //     backgroundColor: '#1c2b46',
                //     titleFontSize: 13,
                //     titleFontColor: '#fff',
                //     titleMarginBottom: 6,
                //     bodyFontColor: '#fff',
                //     bodyFontSize: 12,
                //     bodySpacing: 4,
                //     yPadding: 10,
                //     xPadding: 10,
                //     footerMarginTop: 0,
                //     displayColors: false
                // },
                scales: {
                    x: {
                        axis: 'x',
                        grid: {
                            // display: false,
                            color: 'transparent',
                            tickLength: 0
                        },
                        ticks: {
                            font: {
                                style: '#9eaecf',
                                size: 9
                            },
                            padding: 10,
                            stepSize: 3000,
                            source: 'auto',
                            color: '#9eaecf'
                        }
                    },
                    y: {
                        axis: 'y',
                        ticks: {
                            // beginAtZero: true,
                            font: {
                                size: 11,
                                style: '#9eaecf'
                            },
                            color: '#9eaecf',
                            // padding: 5,
                            callback: function (value, index, values) {
                                return value;
                            },
                            min: 0,
                            stepSize: 4000
                        }
                    }
                }
            }}
        />
    );
};
