/**
 * SidebarCard component that renders a card with customizable className, style, and children.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.className - A custom class name for the card.
 * @param {React.CSSProperties} [props.style] - An optional inline style object for the card.
 * @param {React.ReactNode} [props.children] - The content to be displayed inside the card.
 * @returns {JSX.Element} The rendered SidebarCard component.
 */
const SidebarCard = ({ className, style, children }) => {
  return (
    <div className={`card-inner ${className ?? ''}`} style={style ?? {}}>
      {children ?? null}
    </div>
  )
};

export default SidebarCard;