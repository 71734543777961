import { useEffect, useState } from "react";
import { findUpper } from "../../../utils/Utils";
import { getImage } from "../../../utils/envConfig";
import { UserAvatar } from "../../../components/Component";
import { Spinner } from "reactstrap";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const AgentAvatar = ({ agent }) => {
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const axios = useAxiosPrivate();

  useEffect(() => {
    async function fetchImage() {
      try {
        setLoading(true);
        const resData = await getImage(agent?.user?.photo, axios);
        const s3url = resData.signedRequest;
        if (s3url) {
          setAvatar(s3url);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
    agent?.user?.photo && fetchImage();
  }, [agent]);

  return (
    <>
      {!loading ? (
        <UserAvatar
          className="xs"
          text={findUpper(agent?.user?.first_name ?? "Agent")}
          image={avatar}
        ></UserAvatar>
      ) : (
        <Spinner style={{ width: "1rem", height: "1rem" }} />
      )}
    </>
  );
};

export default AgentAvatar;
