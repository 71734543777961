/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import ErrorHandler from "../error-handlers/axios/defaultErrorHandler";

/**
 * Represents the type of activity.
 * @typedef {('Assign'|'Appointment'|'Viewing'|'Ask a Question'|'Call'|'WhatsApp'|'SMSMessage'|'Email'|'Get Matched Assistance'|'New Listing'|'Off-Plan'|'Disqualified'|'Lost'|'Won'|'Created Deal'|'Unassigned'|'Checkin'|'Value My Property')} ActivityType
 */

/**
 * Represents an activity in the system.
 * @typedef {Object} Activity
 * @property {string} userId - The ID of the user associated with the activity.
 * @property {string} [propertyListId] - The ID of the property list associated with the activity.
 * @property {string} createdBy - The ID of the user who created the activity.
 * @property {ActivityType} type - The type of activity.
 * @property {string} description - A description of the activity.
 * @property {Date} date - The date of the activity.
 * @property {Date} [completedDate] - The date the activity was completed.
 * @property {string} [completedBy] - The ID of the user who completed the activity.
 * @property {boolean} isScheduled - Whether the activity is scheduled.
 * @property {'Pending'|'Overdue'|'Complete'|'Skipped'} status - The status of the activity.
 * @property {User} [user] - The user associated with the activity.
 * @property {import("../global-types/property-list-types").PropertyListingType|string} [propertyList] - The property list associated with the activity, or the id of the property list.
 * @property {string[]} preferredLanguages - An array of preferred languages for the activity.
 * @property {string} notes - Notes related to the activity.
 * @property {string[]} tags - An array of tags associated with the activity.
 * @property {boolean} [is_interested] - Whether the user is interested in the activity.
 * @property {string} [offplanId] - The ID of the offplan property associated with the activity.
 * @property {string} [propertyType] - The type of property associated with the activity.
 * @property {boolean} [isCheckedOut] - Whether the activity is checked out.
 * @property {string} [propertyCard] - The ID of the property card associated with the activity.
 */

// TODO update return type 
/**
 * @description Create a user activity
 * @param {Axios} axiosInstance
 * @param {Partial<Activity>} payload
 * @param {import("axios").AxiosRequestConfig?} options 
 * @returns {Promise<unknown>} apiResponse
 */
export const createActivity = async (axiosInstance, payload, options) => {
  try {
    const res = await axiosInstance.post(
      `/activities/`,
      payload,
      options
    );
    return res?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
}