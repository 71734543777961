/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import ErrorHandler from "../error-handlers/axios/defaultErrorHandler";


/**
 * @description Fetching all leads related to a property card
 * @param {Axios} axiosInstance
 * @param {string} propertyCardId - the property card to get the leads for
 * @param {import("axios").AxiosRequestConfig} options 
 * @returns {Promise<import("./types").ResponseData>} apiResponse
 */
export const fetchPropertyCardMappings = async (axiosInstance, propertyCardId, options) => {
  try {
    
    const res = await axiosInstance.get(`/property-cards/mappings/`, {
        ...options,
        params: {
            ...options.params,
            propertyCard: propertyCardId,
        }
    });

    /**
     * @type import("./types").ResponseData
     */
    const data = res?.data?.data?.filter((mapping) => mapping?.lead);

    return { data, found: res?.data?.found };
  } catch (error) {
    ErrorHandler.handle(error);
  }
}