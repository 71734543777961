/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from "react-redux";
import {
  HttpStatus,
  LeadStatus,
  PropertyCardStatus,
  TransactionType,
  newRoles,
} from "../../../../utils/envConfig";
import {
  Badge,
  Button,
  Card,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { TooltipComponent } from "../../../../components/Component";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { boxStyling, tickStyling } from "./card-styles";
import { DarkenedOverlay, GrayOutOverlay, GreenOverlay } from "./overlays";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import NewAgentAvatar from "../../../users/components/NewAgentAvatar";
import { showToast } from "../../../../utils/toast/toast";
import uuid from "react-uuid";
import DndFlagComponent from "../../../components/dnd/dndFlag";
import SendToPhone from "../../../../components/SendToPhone";
import { useQueryClient } from "react-query";
import { PROP_CARDS_KEYS } from "../../../../queries/propertyCardQueries";

const LeadCard = ({ leadToPropCardMapping, refreshFn, isCheckedOut }) => {
  const lead = leadToPropCardMapping?.lead;
  const authUser = useSelector((state) => state?.user?.loggedInUser);
  const authAgent = useSelector((state) => state?.user?.loggedInAgent);
  const axios = useAxiosPrivate();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [confirmActionsState, setConfirmActionsState] = useState({
    verifyOwner: false,
    markAsPastOwner: false,
    unlink: false,
  });

  const toggleVerifyModal = () => setConfirmActionsState(
    _ => ({ verifyOwner: !confirmActionsState.verifyOwner, markAsPastOwner: false, unlink: false })
  );
  const togglePastOwnerModal = () => setConfirmActionsState(
    _ => ({ verifyOwner: false, markAsPastOwner: !confirmActionsState.markAsPastOwner, unlink: false })
  );
  const toggleUnlinkModal = () => setConfirmActionsState(
    _ => ({ verifyOwner: false, markAsPastOwner: false, unlink: !confirmActionsState.unlink })
  );

  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const verifyAsCurrentPropertyOwner = async (propCardId, leadId) => {
    try {
      setIsVerifyLoading(true);
      const payload = { currentOwner: leadId ?? null };
      const res = await axios.put(
        `/property-cards/${propCardId ?? ""}`,
        payload
      );
      const propertyCardStatus = res?.data?.status;
      if (res.status === HttpStatus.OK) {
        queryClient.invalidateQueries(PROP_CARDS_KEYS.mappings);
        showToast("Set as owner successfully!", "success");
        if (
          propertyCardStatus &&
          propertyCardStatus === PropertyCardStatus.Unverified
        ) {
          const verifyRes = await axios.put(`/property-cards/${propCardId}`, {
            status: PropertyCardStatus.Verified,
            purpose: null,
          });
          verifyRes.status === HttpStatus.OK &&
            showToast("Verified!", "success");
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === HttpStatus.TOO_MANY_REQUESTS) {
        showToast("Too many requests. Please try again in a bit.", "error");
        return;
      }
      const serverErrorMessage = error.response.data.message;
      if (serverErrorMessage) {
        showToast(serverErrorMessage, "error");
      } else {
        showToast("An error occurred.", "error");
      }
    } finally {
      setIsVerifyLoading(false);
      toggleVerifyModal();
      refreshFn && refreshFn();
    }
  };

  const [isPastOwnerLoading, setIsPastOwnerLoading] = useState(false);
  const verifyAsPastPropertyOwner = async (propCardId, leadId) => {
    setIsPastOwnerLoading(true);
    try {
      const payload = {
        propertyCardId: propCardId,
        leadId,
      };
      const res = await axios.patch(`/property-cards/set-past-owner`, payload);
      if (res.status === HttpStatus.OK) {
        queryClient.invalidateQueries(PROP_CARDS_KEYS.mappings);
        showToast("Set as past owner successfully!", "success");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === HttpStatus.TOO_MANY_REQUESTS) {
        showToast("Too many requests. Please try again in a bit.", "error");
        return;
      }
      const serverErrorMessage = error.response.data.message;
      if (serverErrorMessage) {
        showToast(serverErrorMessage, "error");
      } else {
        showToast("An error occurred.", "error");
      }
    } finally {
      setIsPastOwnerLoading(false);
      togglePastOwnerModal();
      refreshFn && refreshFn();
    }
  };

  const [isUnlinkLoading, setIsUnlinkLoading] = useState(false);
  const unlinkLead = async () => {
    try {
      setIsUnlinkLoading(true);
      const res = await axios.delete(
        `/property-cards/lead-to-card/${leadToPropCardMapping?._id ?? leadToPropCardMapping?.id
        }`
      );
      if (res.status === HttpStatus.OK) {
        showToast("Unlinked lead", "success");
        refreshFn();
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === HttpStatus.TOO_MANY_REQUESTS) {
        showToast("Too many requests. Please try again in a bit.", "error");
        return;
      }
      const serverErrorMessage = error.response.data.message;
      if (serverErrorMessage) {
        showToast(serverErrorMessage, "error");
      } else {
        showToast("An error occurred.", "error");
      }
    } finally {
      toggleUnlinkModal();
      setIsUnlinkLoading(false);
      refreshFn();
    }
  };

  const currentOwnerId = useMemo(() => (
    (
      leadToPropCardMapping?.propertyCard?.currentOwner?.id ??
      leadToPropCardMapping?.propertyCard?.currentOwner?._id
    )?.toString()
  ), [leadToPropCardMapping?.propertyCard?.currentOwner?._id, leadToPropCardMapping?.propertyCard?.currentOwner?.id])

  const isPropertyOwner = currentOwnerId === leadToPropCardMapping?.lead?.id?.toString();

  // check if the logged in agent is assigned to this property card
  const isCardAssignedToAgent =
    authUser && authUser.role === newRoles.Agent && isCheckedOut;

  // check if the logged in agent is assigned to this lead
  const isLeadAssignedToAgent =
    authAgent &&
    (lead?.currentAgent?.id ?? lead?.currentAgent?._id)?.toString() ===
    (authAgent?.id ?? authAgent?._id)?.toString();

  const isAdmin =
    authUser.role === newRoles.Admin || authUser.role === newRoles.MasterAdmin;

  // This applies a grayscale filter to a component if the lead was a past owner
  const grayOut = () =>
    (!isPropertyOwner && leadToPropCardMapping?.wasOwner) ||
      leadToPropCardMapping?.lead?.lead_status === LeadStatus.Disqualified
      ? {
        filter: "grayscale(1)",
      }
      : null;

  const formatTransactionType = (type) => {
    switch (type) {
      case TransactionType.Sell:
        return "Sold ";
      case TransactionType.Buy:
        return "Bought ";
      default:
        return "Done ";
    }
  };

  const IconsComponent = () => {
    const toolTipId = "a" + uuid()?.replace(/[^\w\s]/g, "");
    const secondToolTip = "a" + uuid()?.replace(/[^\w\s]/g, "");
    const thirdtoolTipId = "a" + uuid()?.replace(/[^\w\s]/g, "");
    const fourthToolTip = "a" + uuid()?.replace(/[^\w\s]/g, "");

    return leadToPropCardMapping?.wasOwner ? (
      <div className="d-flex mt-2">
        <div
          style={{ cursor: "pointer" }}
          className="badge badge-dim bg-danger  p-2 me-2"
          onClick={toggleUnlinkModal}
        >
          <TooltipComponent
            icon="cross-sm"
            direction="top"
            id={fourthToolTip}
            text="Unlink from Property"
          ></TooltipComponent>
        </div>
      </div>
    ) : (
      <div className="d-flex mt-2">
        {isAdmin || isCardAssignedToAgent ? (
          <div
            style={{ cursor: "pointer" }}
            className="badge badge-dim bg-primary  p-2 me-2"
            onClick={(e) => {
              e.preventDefault();
              navigate(
                `/user-profile/${leadToPropCardMapping?.lead?.id}/user-profile-setting`
              );
            }}
          >
            <TooltipComponent
              icon="eye"
              direction="top"
              id={toolTipId}
              text="View Lead"
            ></TooltipComponent>
          </div>
        ) : null}
        <div
          style={{ cursor: "pointer" }}
          className="badge badge-dim bg-success  p-2 me-2"
          onClick={toggleVerifyModal}
        >
          <TooltipComponent
            icon="check"
            direction="top"
            id={secondToolTip}
            text="Verify as Current Owner"
          ></TooltipComponent>
        </div>
        <div
          style={{ cursor: "pointer" }}
          className="badge badge-dim bg-warning  p-2 me-2"
          onClick={togglePastOwnerModal}
        >
          <TooltipComponent
            icon="clock"
            direction="top"
            id={thirdtoolTipId}
            text="Mark as Past Owner"
          ></TooltipComponent>
        </div>
        <div
          className="badge badge-dim bg-danger  p-2 me-2"
          onClick={toggleUnlinkModal}
          style={{ cursor: "pointer" }}
        >
          <TooltipComponent
            icon="cross-sm"
            direction="top"
            id={fourthToolTip}
            text="Unlink from Property"
          ></TooltipComponent>
        </div>
      </div>
    );
  };

  return (
    <>
      <Card style={boxStyling}>
        {!isPropertyOwner && leadToPropCardMapping?.wasOwner ? (
          <GrayOutOverlay />
        ) : null}
        {isPropertyOwner ? <GreenOverlay /> : null}
        {leadToPropCardMapping?.lead?.lead_status === LeadStatus.Disqualified ||
          !leadToPropCardMapping?.lead?.active ? (
          <DarkenedOverlay />
        ) : null}

        <div
          className="d-flex flex-column justify-content-between h-100 kanban-item"
          style={{ cursor: "default" }}
        >
          {/* ================= CARD TITLE SECTION ==================== */}
          <div className={`kanban-item-title`}>
            <h6 className={`title`}>
              <div
                className="d-flex flex-wrap align-items-center"
                style={{ gap: "0.3rem" }}
              >
                {isPropertyOwner ? (
                  <span className="bg-success text-white" style={tickStyling}>
                    <TooltipComponent
                      id={`owns-${leadToPropCardMapping?.propertyCard?.id ??
                        leadToPropCardMapping?.propertyCard?._id
                        }`}
                      icon="check-thick"
                      direction="top"
                      text="Owner of this property"
                    />
                  </span>
                ) : null}
                {isAdmin ||
                  (isCardAssignedToAgent &&
                    isLeadAssignedToAgent &&
                    leadToPropCardMapping?.lead?.active) ? (
                  <Link
                    style={!isAdmin ? grayOut() : {}}
                    className="p-1"
                    to={`/user-profile/${leadToPropCardMapping?.lead?.id}/user-profile-setting`}
                  >{`${leadToPropCardMapping?.lead?.first_name} ${leadToPropCardMapping?.lead?.last_name ?? ""
                    }`}</Link>
                ) : (
                  <span className="d-block py-2">{`${leadToPropCardMapping?.lead?.first_name
                    } ${leadToPropCardMapping?.lead?.last_name ?? ""}`}</span>
                )}
              </div>
            </h6>
            {leadToPropCardMapping?.lead?.currentAgent ? (
              <UncontrolledDropdown>
                <DropdownToggle
                  tag={"a"}
                  href="toggle"
                  onClick={(e) => e.preventDefault()}
                >
                  <NewAgentAvatar
                    agent={leadToPropCardMapping?.lead?.currentAgent}
                    size="sm"
                    style={grayOut()}
                  />
                </DropdownToggle>
                <DropdownMenu>
                  <ul className="p-3 link-list-opt g-2 no-bdr">
                    <li>
                      <div className="user-card">
                        <div className="user-name">
                          {isAdmin ||
                            (isCardAssignedToAgent &&
                              isLeadAssignedToAgent &&
                              !leadToPropCardMapping?.wasOwner) ? (
                            <Link
                              className="p-1 text-nowrap text-primary fs-6 tb-lead"
                              to={`/user-profile/${leadToPropCardMapping?.lead?.currentAgent?.userId?.id ?? leadToPropCardMapping?.lead?.currentAgent?.user?.id}/user-profile-setting`}
                            >
                              {leadToPropCardMapping?.lead?.currentAgent?.userId
                                ?.first_name ?? leadToPropCardMapping?.lead?.currentAgent?.user
                                  ?.first_name ?? ""}{" "}
                              {leadToPropCardMapping?.lead?.currentAgent?.userId
                                ?.last_name ?? leadToPropCardMapping?.lead?.currentAgent?.user
                                  ?.last_name ?? ""}
                            </Link>
                          ) : (
                            <span className="p-2 text-nowrap fs-6 tb-lead">
                              {leadToPropCardMapping?.lead?.currentAgent?.userId
                                ?.first_name ?? leadToPropCardMapping?.lead?.currentAgent?.user
                                  ?.first_name ?? ""}{" "}
                              {leadToPropCardMapping?.lead?.currentAgent?.userId
                                ?.last_name ?? leadToPropCardMapping?.lead?.currentAgent?.user
                                  ?.last_name ?? ""}
                            </span>
                          )}
                        </div>
                      </div>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : null}
          </div>
          {/* ================= END OF CARD TITLE SECTION ==================== */}
          {(isCardAssignedToAgent &&
            isLeadAssignedToAgent &&
            !leadToPropCardMapping?.wasOwner) ||
            isAdmin ? (
            <div className="kanban-item-text">
              {leadToPropCardMapping?.lead?.active ? (
                <div className="">
                  <span
                    className="fw-bold text-secondary"
                    onClick={() => console.log(leadToPropCardMapping?.lead)}
                  >
                    Phone number:{" "}
                  </span>
                  {leadToPropCardMapping?.lead?.DndStatus ? (
                    <DndFlagComponent
                      lead_status={leadToPropCardMapping?.lead?.lead_status}
                      phoneNumber={leadToPropCardMapping?.lead?.phone}
                    />
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
                      <span>
                        {leadToPropCardMapping?.lead?.phone}
                      </span>
                      <SendToPhone leadId={leadToPropCardMapping?.lead?.id}>
                        <Button outline size='xs'>Send to Phone</Button>
                      </SendToPhone>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          ) : null}
          {leadToPropCardMapping?.lead?.active ? (
            <ul className="kanban-item-tags">
              <li>
                <Badge
                  color={
                    leadToPropCardMapping?.lead?.lead_status ===
                      LeadStatus.Disqualified
                      ? `danger`
                      : `secondary`
                  }
                >
                  {leadToPropCardMapping?.lead?.lead_status}
                </Badge>
              </li>
            </ul>
          ) : null}
          <div className="kanban-item-meta">
            {leadToPropCardMapping?.lead?.active ? (
              <ul className="kanban-item-meta-list">
                <li>
                  <span className="text-wrap">
                    <span className="fw-bold">
                      {formatTransactionType(
                        leadToPropCardMapping?.transactionType
                      )}
                    </span>{" "}
                    on{"  "}
                    {leadToPropCardMapping?.date ? (
                      <span className="fw-bold">
                        {format(
                          new Date(leadToPropCardMapping?.date),
                          "dd/MM/yyyy"
                        )}
                      </span>
                    ) : (
                      <span className="fw-bold">Unkown Date</span>
                    )}{" "}
                    for{" "}
                    <span className="fw-bold">
                      {leadToPropCardMapping?.amount
                        ? new Intl.NumberFormat().format(
                          leadToPropCardMapping?.amount
                        )
                        : "?"}{" "}
                      AED
                    </span>
                  </span>
                </li>
              </ul>
            ) : null}
          </div>
          {(isCardAssignedToAgent &&
            isLeadAssignedToAgent &&
            leadToPropCardMapping?.lead?.active) ||
            isAdmin ? (
            <>
              {!leadToPropCardMapping?.lead?.active && (
                <div className="">
                  <Badge
                    className="badge bg-danger-dim text-danger border border-danger p-2 fs-6 fw-bold"
                    style={{ zIndex: 10 }}
                  >
                    Inactive Lead
                  </Badge>
                </div>
              )}
              <IconsComponent />
            </>
          ) : !leadToPropCardMapping?.lead?.active ? (
            <div className="">
              <Badge
                className="badge bg-danger-dim text-danger border border-danger p-2 fs-6 fw-bold"
                style={{ zIndex: 10 }}
              >
                Inactive Lead
              </Badge>
            </div>
          ) : null}
        </div>
      </Card>
      <ConfirmationModal
        title={"Confirm Unlinking"}
        isOpen={confirmActionsState?.unlink ?? false}
        toggleFn={toggleUnlinkModal}
        actionFn={unlinkLead}
        loadingState={isUnlinkLoading}
        isDeletion
      >
        <p>
          Are you sure you want to unlink{" "}
          <span className="fw-bold">
            {leadToPropCardMapping?.lead?.first_name}
          </span>{" "}
          from this property card?
        </p>
      </ConfirmationModal>
      <ConfirmationModal
        title={"Verify As Current Owner?"}
        isOpen={confirmActionsState?.verifyOwner ?? false}
        toggleFn={toggleVerifyModal}
        loadingState={isVerifyLoading}
        actionFn={() => verifyAsCurrentPropertyOwner(
          leadToPropCardMapping?.propertyCard?.id ?? leadToPropCardMapping?.propertyCard?._id,
          leadToPropCardMapping?.lead?.id
        )}
      >
        <p>
          Are you sure you want to verify{" "}
          <span className="fw-bold">
            {leadToPropCardMapping?.lead?.first_name}
          </span>{" "}
          as the current property owner?
        </p>
      </ConfirmationModal>
      <ConfirmationModal
        title={"Mark As a Past Owner?"}
        isOpen={confirmActionsState?.markAsPastOwner ?? false}
        toggleFn={togglePastOwnerModal}
        loadingState={isPastOwnerLoading}
        actionFn={() => verifyAsPastPropertyOwner(
          leadToPropCardMapping?.propertyCard?.id ?? leadToPropCardMapping?.propertyCard?._id,
          leadToPropCardMapping?.lead?.id
        )}
      >
        <p>
          Are you sure that{" "}
          <span className="fw-bold">
            {leadToPropCardMapping?.lead?.first_name}
          </span>{" "}
          is a previous owner?
        </p>
      </ConfirmationModal>
    </>
  );
};

export default LeadCard;
