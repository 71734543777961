// components/PropertiesCards-view/PropertiesCardsHeader.js
import React, { lazy, Suspense, useState } from "react";
import { Button } from "reactstrap";
import
{
    Block,
    BlockHead,
    BlockHeadContent,
    BlockBetween,
    BlockDes,
    BlockTitle,
    Icon,
} from "../Component";
import LoaderModal from "../modals/LoaderModal";

const AddPropertyCardModal = lazy(() => import("../../pages/users/components/property-cards/AddPropertyCardModal"));

const PropertiesCardsHeader = ( { lead } ) =>
{
    const [ isCreateModalOpen, setIsCreateModalOpen ] = useState( false );
    const [ payload, setPayload ] = useState( null );
    const [ isConfirmModalOpen, setIsConfirmModalOpen ] = useState( false );
    const [ refreshView, setRefreshView ] = useState( false );

    const toggleRefresh = () => setRefreshView( !refreshView );

    const [ foundCard, setFoundCard ] = useState( null );

    const toggleCreationModal = () =>
    {
        setIsCreateModalOpen( !isCreateModalOpen );
    };

    const toggleConfirmModal = ( newPayload, foundPropCard ) =>
    {
        setFoundCard( foundPropCard );
        setPayload( newPayload );
        setIsConfirmModalOpen( !isConfirmModalOpen );
    };


    return (
        <div>
            <Block>
                <BlockHead>
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h4">Property Cards</BlockTitle>
                            <BlockDes>
                                <p>Property Cards associated with this Lead</p>
                            </BlockDes>
                        </BlockHeadContent>

                        <BlockHeadContent>
                            <Button
                                color="primary"
                                onClick={ ( ev ) =>
                                {
                                    ev.preventDefault();
                                    toggleCreationModal();
                                } }
                            >
                                <Icon name={ "plus" } />
                                <span className="d-none d-sm-block">Add Property Card</span>
                            </Button>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
            </Block>
            {isCreateModalOpen && <Suspense fallback={<LoaderModal />}>
                <AddPropertyCardModal
                    lead={ lead }
                    isOpen={ isCreateModalOpen }
                    toggleFn={ toggleCreationModal }
                    toggleConfirmFn={ toggleConfirmModal }
                    refreshFn={ toggleRefresh }
                />
            </Suspense>}
        </div>
    );
};

export default PropertiesCardsHeader;
