import {
  Badge,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  PaginationComponent,
} from "../../../../components/Component";
import { humanizeDate } from "../../../users/components/property-cards/utils";
import { useEffect, useMemo, useState } from "react";
import { PropertyCardLogTypes } from "../../../../utils/envConfig";
import _ from "lodash";
import LoadingComponent from "../../../components/loader/tableLoader";
import DatePicker from "react-datepicker";

const ActivityTableView = ({ logsData, isLogsLoading }) => {
  
  const logs = useMemo(() => logsData?.data?.data ?? [], [logsData]);

  const DISPLAY_FIELDS = ["Type", "Message", "Done By", "Done At"];
  const TYPE_FIELDS = ["All Types", ...Object.keys(PropertyCardLogTypes)];

  // const [logData, setLogData] = useState(logs?.length ? logs : []);
  const [filteredLogs, setFilteredLogs] = useState(logs?.length ? logs : []);

  // pagination state
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  // filters state
  const [searchText, setSearchText] = useState("");
  const [selectedType, setSelectedType] = useState("All Types");
  const [rangeStart, setRangeStart] = useState(null);
  const [rangeEnd, setRangeEnd] = useState(null);

  const getStylingForLogType = (type) => {
    switch (type) {
      case PropertyCardLogTypes.Assign:
      case PropertyCardLogTypes.Checkout:
      case PropertyCardLogTypes.Created:
      case PropertyCardLogTypes.NewLead:
        return "bg-success-dim text-teal border-0 p-1 px-2";
      case PropertyCardLogTypes.Checkin:
      case PropertyCardLogTypes.UnverifiedLead:
      case PropertyCardLogTypes.UnAssign:
        return "bg-danger-dim text-danger border-0 p-1 px-2";
      case PropertyCardLogTypes.StatusChanged:
        return "bg-info-dim text-info border-0 p-1 px-2";
      default:
        return "bg-light text-dark border-0 p-1 px-2";
    }
  };

  useEffect(() => {
    filterLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, selectedType, rangeStart, rangeEnd, logs]);

  const filterLogs = () => {
    let result = logs;
    if (searchText) {
      result = _.filter(result, (log) => {
        const messageTypeMatch = log.message
          ?.toLowerCase()
          .includes(searchText.toLowerCase());

        return messageTypeMatch;
      });
    }
    if (selectedType !== "All Types") {
      result = _.filter(
        result,
        (log) => log?.type === PropertyCardLogTypes[selectedType]
      );
    }

    if (rangeStart) {
      result = _.filter(
        result,
        (log) => new Date(log.createdAt) >= new Date(rangeStart)
      );
    }

    if (rangeEnd) {
      result = _.filter(
        result,
        (log) => new Date(log.createdAt) <= new Date(rangeEnd)
      );
    }

    setFilteredLogs(result);
    setPage(1);
  };

  const indexOfLastLog = page * perPage;
  const indexOfFirstLog = indexOfLastLog - perPage;
  const currentLogs = filteredLogs && Array.isArray(filteredLogs) && filteredLogs?.slice(indexOfFirstLog, indexOfLastLog);

  if (isLogsLoading) {
    return <LoadingComponent />;
  }

  if (logs?.length === 0) {
    if (searchText === "" || selectedType === "All Types") {
      // No logs are available at all
      return (
        <div>
          <h6 className="sub-text p-3">
            No log history for this property card.
          </h6>
        </div>
      );
    }
  }

  return (
    <Card className="p-4 position-relative">
      {/* <div className="position-absolute top-0" style={{zIndex: 1, left: "73%"}}>Log Date</div> */}
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-grow-1">
          <Input
            className="w-20 mb-3 border"
            type="text"
            placeholder="Search by message..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <UncontrolledDropdown className="ml-2 mb-3">
            <DropdownToggle
              color="transparent"
              className="dropdown-toggle dropdown-indicator btn btn-light"
            >
              {selectedType ? selectedType : "Filter by Type"}
            </DropdownToggle>
            <DropdownMenu>
              <ul className="link-list-opt no-bdr">
                {TYPE_FIELDS.map((option, idx) => (
                  <li key={`bed-option-${idx}`}>
                    <DropdownItem onClick={() => setSelectedType(option)}>
                      <span>{option}</span>
                    </DropdownItem>
                  </li>
                ))}
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        <div className="text-center d-flex align-items-center mb-3">
          <div className="d-flex align-items-center justify-content-end">
            <span className="d-none d-sm-inline-block me-2">Show</span>
            <div className="form-control-select">
              <select
                name="DataTables_Table_0_length"
                className="custom-select custom-select-sm form-control form-control-sm"
                onChange={(e) => setPerPage(e.target.value)}
                value={perPage}
              >
                <option value="1">1</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="form-group position-relative ml-2">
            <div className="form-control-wrap">
              <div className="input-daterange date-picker-range input-group datepicker-zindex">
                <DatePicker
                  placeholderText="Starting date"
                  selected={rangeStart}
                  onChange={(date) => setRangeStart(date)}
                  selectsStart
                  startDate={rangeStart}
                  endDate={rangeEnd}
                  maxDate={rangeEnd}
                  wrapperClassName="start-m"
                  className="form-control"
                />
                <div className="input-group-addon">TO</div>
                <DatePicker
                  placeholderText="Ending date"
                  selected={rangeEnd}
                  onChange={(date) => setRangeEnd(date)}
                  startDate={rangeStart}
                  endDate={rangeEnd}
                  selectsEnd
                  minDate={rangeStart}
                  wrapperClassName="end-m"
                  className="form-control datepicker-zindex"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DataTable className="position-relative ">
        <DataTableBody compact>
          {/* =================== HEADERS ==================== */}
          <DataTableHead className="nk-tb-item">
            {DISPLAY_FIELDS.map((field, idx) => (
              <DataTableRow
                className={"text-center"}
                size={"md"}
                key={`columnd-${idx}`}
              >
                <span className="sub-text">{field}</span>
              </DataTableRow>
            ))}
          </DataTableHead>
          {/* =================== END OF HEADERS ==================== */}

          {/* =================== DATA LIST ==================== */}
          {logs?.length ? (
            currentLogs.map((log, idx) => (
              <DataTableItem key={`row-${idx}`}>
                <DataTableRow className={"text-center"} size={"md"}>
                  <Badge className={getStylingForLogType(log?.type ?? "")} pill>
                    {log?.type ?? "Unknown"}
                  </Badge>
                </DataTableRow>
                <DataTableRow className={"text-center"} size={"md"}>
                  <span>{log?.message}</span>
                </DataTableRow>
                <DataTableRow className={"text-center"} size={"md"}>
                  <span>{`${log?.createdBy?.first_name ?? 'Unknown'} ${
                    log?.createdBy?.last_name ?? ""
                  }`}</span>
                </DataTableRow>
                <DataTableRow className={"text-center"} size={"md"}>
                  <span>{humanizeDate(log?.createdAt, { addTime: true })}</span>
                </DataTableRow>
              </DataTableItem>
            ))
          ) : (
            <div>
              <h6 className="sub-text p-3">
                No results found for "{searchText}"
              </h6>
            </div>
          )}
          {/* =================== END OF DATA LIST ==================== */}
        </DataTableBody>
        <div className="px-2 py-3">
          <PaginationComponent
            currentPage={page}
            totalItems={filteredLogs?.length ?? 0}
            itemPerPage={perPage}
            paginate={(page) => {
              setPage(page);
            }}
          />
        </div>
      </DataTable>
    </Card>
  );
};

export default ActivityTableView;
