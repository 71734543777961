import React from "react";
import { Spinner } from "reactstrap";
import { RSelect } from "../../../../components/Component";

const PropTypesFilterBase = ({ multiSelect, onChangeCallback, selected, propTypesFilterOptions, propTypesLoading, ...props }) => {
  return (
    <RSelect
      placeholder="Property Type"
      className="status-div"
      isMulti={multiSelect}
      isLoading={propTypesLoading}
      loadingMessage={() => <Spinner />}
      value={selected}
      isClearable
      options={propTypesFilterOptions}
      onChange={(option) => onChangeCallback(option)}
      {...props}
    />
  );
};

export default PropTypesFilterBase;