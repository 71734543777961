export const defaultOptions = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export const colourData = [
  { value: "Al Aweer", label: "Al Aweer" },
  { value: "Al Barsha 1", label: "Al Barsha 1" },
  { value: "Al Furjan", label: "Al Furjan" },
  { value: "Al Jaddaf", label: "Al Jaddaf" },
  { value: "Al Kifaf", label: "Al Kifaf" },
];

export const groupedData = [
  {
    label: "Flavours",
    options: [
      { value: "chocolate", label: "Chocolate" },
      { value: "strawberry", label: "Strawberry" },
      { value: "vanilla", label: "Vanilla" },
    ],
  },
  {
    label: "Colors",
    options: [
      { value: "red", label: "Red" },
      { value: "blue", label: "Blue" },
      { value: "green", label: "Green" },
    ],
  },
  { label: "UnGrouped", value: "value_3" },
];
