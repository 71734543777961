/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import ErrorHandler from "../error-handlers/axios/defaultErrorHandler";
import '../global-types/types';


// TODO update return type 
/**
 * @description Update a user
 * @param {Axios} axiosInstance
 * @param {string} userId
 * @param {Partial<User>} payload
 * @param {import("axios").AxiosRequestConfig?} options 
 * @returns {Promise<unknown>} apiResponse
 */
export const updateUser = async (axiosInstance, userId, payload, options) => {
  try {
    const res = await axiosInstance.patch(
      `/users/${userId}`,
      payload,
      options
    );
    return res?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
}