import { axiosPrivate } from "../utils/Utils";
import { useEffect } from "react";
import useRefresh from "./useRefresh";
import { useSelector } from "react-redux";

export default function useAxiosPrivate() {
    const refresh = useRefresh();

    const auth = useSelector(state => state?.user)
    
    const accessToken = auth?.loggedInUser?.access_token;
    

    useEffect(() => {
        const requestInterceptor = axiosPrivate.interceptors.request.use(
            (config) => {
                if (!config.headers.Authorization) {
                    config.headers.Authorization = `Bearer ${accessToken}`;
                }

                return config;
            },
            (err) => Promise.reject(err)
        );

        const responseInterceptor = axiosPrivate.interceptors.response.use(
            (res) => res,
            async (err) => {
                const prevReq = err?.config;
                
                if (err?.response?.status === 403 && !prevReq.sent) {
                    prevReq.sent = true;

                    const newAcessToken = await refresh();

                    prevReq.headers[
                        "Authorization"
                    ] = `Bearer ${newAcessToken?.access_token}`;

                    return axiosPrivate(prevReq);
                }
                return Promise.reject(err);
            }
        );

        return () => {
            axiosPrivate.interceptors.response.eject(responseInterceptor);
            axiosPrivate.interceptors.request.eject(requestInterceptor);
        };
    }, [auth, refresh]);

    return axiosPrivate;
}
