/* eslint-disable no-unused-vars */
import { useEffect, useCallback, useState, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";

/**
 * @typedef {Object} useFetchReturnType
 * @property {unknown | undefined} data - The returned data from the query.
 * @property {boolean} isError - Whether the query is in an error state.
 * @property {boolean} isLoading - Whether the query is in loading state.
 * @property {unknown} error - The error object if the query is in an error state.
 * @property {boolean} isFetching - Whether the query is currently fetching (re-fetching or validating cache too).
 * @property {"error" | "idle" | "loading" | "success"} status - The status of the query.
 */

// Utility function to get the current time in milliseconds
const getCurrentTime = () => new Date().getTime();

/**
 * The options used to configure the query fetching, there must be a `queryKey` provided, in case of dependencies,
 * use `queryKeyName` for the name, and `queryKeyDeps` as an array of dependencies
 * @typedef {Object} useFetchOptions
 * @property {number} timeBeforeRefetchOnFocus - The time before refetching the query on window focus, in milliseconds.
 * @property {number} prefetchCount - The number of pages to prefetch, by default 2.
 * @property {number} staleTime - Specifies how long will the queries be cached, in milliseconds.
 * @property {boolean} isPaginated - Specifies whether the query result is paginated or not.
 * @property {number} retryCount - Specifies the maximum number of query retries.
 * @property {number} retryDelay - Specifies the time between retries, in milliseconds.
 * @property {boolean | undefined} queryCondition - The condition on which to run the query.
 * @property {string | undefined} queryKey - The key for the query cache
 * @property {string | undefined} queryKeyName - The key name for the query cache. If using `queryKey`, this is not needed
 * @property {Array<any> | undefined} queryKeyDeps - The key object (dependencies) for the query cache. If using `queryKey`, this is not needed
 * @property {function(FetchFunctionParams): FetchFunctionReturnType} fetchFn - The function responsible for fetching data.
 * @property {unknown} customArgs - Custom arguments for the fetch function. Must be set if no `queryConfig` is not provided.
 * @property {import("axios").AxiosRequestConfig} queryConfig - Configuration options for the Axios request. Pass query parameters within the `params` property of this object.
 * @property {undefined | function(unknown): any} errorHandler - Optional error handler function.
 */

/**
 * @typedef {Object} FetchFunctionParams
 * @property {import("axios").AxiosInstance} Axios - An Axios instance
 * @property {import("axios").AxiosRequestConfig | undefined} AxiosRequestConfig - Axios configuration options, including query params etc...
 */

/**
 * @typedef {Object} FetchFunctionReturnType
 * @property {import("axios").AxiosResponse} AxiosResponse - Axios HTTP response wrapper
 */

/**
 * @description A general purpose fetch query hook, with pre-fetch for paginated queries, and refetching on window focus, all configurable
 * @param {Axios} axios
 * @param {useFetchOptions} options - Additional options for configuring the fetch behavior.
 * @returns {useFetchReturnType} - Returns the data fetching hook result.
 */

const useFetchQuery = (
  axios,
  options = {
    queryConfig: null,
    errorHandler: null,
    staleTime: 1000 * 60 * 10,
    prefetchCount: 2,
    timeBeforeRefetchOnFocus: 1000 * 60 * 30,
    isPaginated: false,
    retryCount: 1,
    retryDelay: 1000,
    includePageInformation: false,
  }
) => {
  const [lastFocusTime, setLastFocusTime] = useState(getCurrentTime());

  const key = useMemo(
    () => (options?.queryKey ? options?.queryKey : [options?.queryKeyName, ...(options?.queryKeyDeps ?? [])]),
    [options?.queryKey, options?.queryKeyName, options?.queryKeyDeps]
  );

  const queryClient = useQueryClient();

  const refetchOnWindowFocus = useCallback(() => {
    const now = getCurrentTime();
    const timeElapsed = now - lastFocusTime;

    const threshold = options.timeBeforeRefetchOnFocus;

    if (timeElapsed > threshold) {
      queryClient.refetchQueries(key);
    }

    setLastFocusTime(now);
  }, [key, lastFocusTime, options.timeBeforeRefetchOnFocus, queryClient]);

  useEffect(() => {
    window.addEventListener("focus", refetchOnWindowFocus);

    return () => {
      window.removeEventListener("focus", refetchOnWindowFocus);
    };
  }, [refetchOnWindowFocus]);

  const { data, isError, isLoading, error, isFetching, status } = useQuery(
    key,
    () => {
      try {
          return options.queryConfig ? options.fetchFn(axios, options.queryConfig) : options.fetchFn(axios, ...options.customArgs);
      } catch (error) {
          throw new Error(`Failed to fetch data: ${error.message}`);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: options?.staleTime,
      retry: options?.retryCount,
      retryDelay: options?.retryDelay,
      enabled: options?.queryCondition ?? true,
      onError: (error) => {
        if (options.errorHandler && options.errorHandler instanceof Function) {
          options.errorHandler(error);
        } else {
          console.error("Error fetching data:", error);
        }
      },
    }
  );

  useEffect(() => {
    const preFetchPages = async () => {
      if (options.queryConfig?.params?.page && options.queryKeyDeps?.page) {
        for (let i = 1; i <= options.prefetchCount; i++) {
          const nextPage = options.queryConfig?.params?.page + i;

          await queryClient.prefetchQuery(
            [options.queryKeyName, { ...options.queryKeyDeps, page: nextPage }],
            () =>
              options.fetchFn(axios, {
                ...options.queryConfig,
                params: {
                  ...options.queryConfig.params,
                  page: nextPage,
                },
              }),
            { refetchOnWindowFocus: false, staleTime: options?.staleTime }
          );
        }
      }
    };
    if (options.isPaginated) {
      preFetchPages();
    }
  }, [
    axios,
    options.isPaginated,
    options.prefetchCount,
    options?.staleTime,
    queryClient,
    options,
  ]);

  return { data, isError, isLoading, error, isFetching, status };
};

export default useFetchQuery;
