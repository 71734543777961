import React, { Component } from 'react'
import { Button } from 'reactstrap'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { showToast } from '../utils/toast/toast'



export default function SendToPhone({ leadId, children }) {
    const axios = useAxiosPrivate()
    const sendNotificationToAgentApp = async (e) => {
        e.preventDefault()

        try {
            await axios.post('/agent/click-to-call', {
                lead_id: leadId
            })
            showToast('Successfully send notification', 'success')
        } catch (error) {
            showToast(error?.response?.data?.error ?? 'Failed to send', 'error')
        }

    }
    return (
        <div
            style={{
                cursor: 'pointer'
            }}
            title="click this button to send contact notification to agent app"
            onClick={sendNotificationToAgentApp}
        >{children}</div>
    )
}
