import React, { useState, useEffect, useCallback } from "react";
import
{
  Button,
  Col,
  Row,
  Icon,
} from "../../../components/Component";
import { Card, Modal, Spinner } from "reactstrap";
import DragDrop from "../components/forms/DragDrop";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { deleteManyFromS3, getImage, uploadManyToS3 } from "../../../utils/envConfig";
import cuid from "cuid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import DnDKitProperty from "../../off-plans/components/DnDKitProperty";
import useDragAndDrop from "../../../utils/hooks/useDragAndDropImage";
import InputFile from "../../off-plans/components/InputFile";



const ImageUpload = ( props ) =>
{

  const { editModal, toggleEditModal, property, propertyUpdate, handleRemoveAll } = props;
  const axios = useAxiosPrivate();

  const [ imgLoading, setImgLoading ] = useState( false );
  const [ loading, setLoading ] = useState( true );
  const [ tobeDeleted, setToBeDeleted ] = useState( [] );
  const [ tobeAdded, setToBeAdded ] = useState( [] );

  const {
    active: activeImages,
    errorMessage: ImagesStatus,
    dragenter: ImagesDragEnter,
    dragleave: ImagesDragLeave,
    drop: ImagesDrop,
    handleFileChange: ImagesFileChange,
    files: imageFiles ,
    setFiles: setImageFiles,
    removeImage: removeImage,
} = useDragAndDrop({
    multiple: true,
    inputType: "image",
    maxFilesLength: 50,
    minFilesLength: 8,
    labelName: "title",
    id: property.id
});

  const handleUpload = async ( file ) =>
  {
    let data = [
      { file: file.imgfile, type: "image/*", fileName: file.fileName },
      {
        file: file.thumbnailFile,
        type: "image/*",
        fileName: file.thumbnailName,
      },
    ];

    await uploadManyToS3( data, axios ); // Uploading thumbnail and original of the image to s3
  };


  const imageUploadNew = async ( images ) =>
  {
    if ( images.length !== 0 )
    {
      setImgLoading( true );
      for ( let i = 0; i < images.length; i++ )
      {
        await handleUpload( images[ i ] );
      }

    }
  };
  useEffect(() => {
    const newArray = property.images?.filter((propImage) => {
      return !imageFiles.some((orderImage) => {
          return propImage.original === orderImage.original;
      });
  });
  setToBeDeleted(newArray);
  const newImgArray2 = imageFiles.filter((orderImage) => {
    return orderImage.hasOwnProperty("imgfile");


});
setToBeAdded(newImgArray2)

  },[imageFiles,property.images])


  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      setImgLoading(true);
  
      const newImgArray = imageFiles?.map((e) => ({
        original: e.original ? e.original : e.fileName,
        thumbnail: e.thumbnail ? e.thumbnail : e.thumbnailName,
      }));
  
      const imgDta = JSON.stringify({ images: newImgArray });

      await updatePropertyImages(tobeDeleted, tobeAdded, imgDta);
      // toast.success("Property images updated successfully.");
   
    } catch (error) {
      // console.error("Error occurred:", error);
      toast.error("Server error. Try Again!");
    } finally {
      setImgLoading(false);
      toggleEditModal();
    }
  };
  
  async function updatePropertyImages(tobeDeleted, tobeAdded, imgDta) {
    if (tobeDeleted) {
      await handleDelete();
    }
  
    if (tobeAdded) {
      await imageUploadNew(tobeAdded);
    }
  
    await propertyUpdate(imgDta);
  }
  

  const handleDelete = async () =>
  {
    await Promise.all(
      tobeDeleted.map( async function ( file )
      {
        await deleteManyFromS3( [ file?.original, file?.thumbnail ] ); //Deleting the original and thumbnail image from s3
      } )
    );
  };
  useEffect(() => {
    async function fetchImages() {
      try {

        // if (property?.images?.length > 0) {
        //   const imagePromises = property.images.map((image) => {
        //     return getImage(image.thumbnail, axios).then((resData) => {
        //       const s3url = resData.signedRequest;
        //       return {
        //         id: cuid(),
        //         src: s3url,
        //         fileName: image.original,
        //         ...image,
        //       };
        //     });
        //   });
        
        //   Promise.all(imagePromises)
        //     .then((imageObjects) => {
        //       setOrderImages((prevState) => [...prevState, ...imageObjects]);
        //       setLoading(false);
        //     })
        //     .catch((err) => {
        //       setLoading(false);
        //       toast.error("Server error. Try Again!");
        //     });
        // }
        const amenities = await Promise.all(
          property?.images?.map(async (item, index) => {
            const image = await getImage(item?.thumbnail, axios); 
            return {
              ...item,
              id: index,
              image: image?.signedRequest,
              prevPhoto: item?.thumbnail,
              file: image?.signedRequest,
              index: index,
              
            };
          })
        );
        

      setImageFiles(amenities);
  
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error("Server error. Try Again!");
      }
    }
  
    fetchImages();
  }, [property?.images]);


  return (
    <Modal size="xl" isOpen={ editModal } toggle={ toggleEditModal } backdrop="static">
      <Card style={ {} }>
        <button
          className="close-btn"
          onClick={ toggleEditModal }
          style={ {
            position: "absolute",
            top: "1.5rem",
            right: "1rem",
            border: "none",
            backgroundColor: "transparent",
            cursor: "pointer",
          } }
        >
          <Icon name="cross" style={ { fontSize: "1.5rem" } } />
        </button>

        <Row className="d-flex justify-content-center">
          <Col sm="6">
            <Button
              color=""
              className="w-100  mt-4 text-center d-flex 
            justify-content-center align-items-center"
              type="button"
            >
              <div className="nk-upload-form relative">
                <h5 className="title mb-3">Upload Images</h5>
                <p>Note: The file size should be at least 800 x 600 px</p>
                <InputFile
                                active={activeImages}
                                dragenter={ImagesDragEnter}
                                dragleave={ImagesDragLeave}
                                drop={ImagesDrop}
                                errorMessage={ImagesStatus}
                                files={imageFiles}
                                handleFileChange={ImagesFileChange}
                                inputType="image"
                                removeImage={removeImage}
                                multiple
                                inputProps={{
                                    accept: "image/*",
                                }}
                            />
    
       
              </div>
            </Button>
            {imageFiles && imageFiles.length>0 &&
                    <DnDKitProperty images={imageFiles} setImages={setImageFiles} removeImage={removeImage}/>
                    }
          </Col>
        </Row>

        <div className="d-flex justify-content-center align-items-center mt-4">
          <Button
            onClick={ ( e ) => handleSubmit( e ) }
            color="success"
            className="d-flex mb-5
            justify-content-center align-items-center"
          >
            { imgLoading ? (
              <Spinner size="sm" color="light" />
            ) : (
              "Save changes"
            ) }
          </Button>
          { imageFiles?.length !== 0 && (
            <Button
              style={ {

                cursor: "pointer",
              } }
              onClick={ ( e ) => handleRemoveAll( e ) }
              color="danger"
              className="d-flex mb-5 ms-3
            justify-content-center align-items-center"
            >
              Delete all
            </Button>
          ) }
        </div>
      </Card>
    </Modal>
  );
};

export default ImageUpload;
