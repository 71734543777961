import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Card } from "reactstrap";
import {
    Button,
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Row,
    Col,
    PreviewCard,
} from "../../components/Component";
import { Link } from "react-router-dom";
import Form from "./components/forms/Form";

const AddProperty = () => {
    const property = {
        id: null,
        listingType: "",
        categoryId: "",
        propertyTypeId: "",
        propertyType: "",
        propertyTitle: "",
        propertyDesc: "",
        featured: false,
        dealOfWeek: false,
        premium: false,
        exclusive: false,
        completionStatus: "",
        completionDate: "",
        buildingName: "",
        street: "",
        communityId: "",
        communityName: "",
        subCommunity: "",
        emirate: "",
        pinlocation: "",
        size: null,
        villaSize: "",
        beds: "",
        baths: "",
        amenityIds: [],
        amenityObjs: [],
        image: null,
        customField: [],
        rentalPeriod: "",
        rentFinance: "",
        unitStatus: "",
        unitReraNo: "",
        makaniNo: "",
        videos: [],
        matterportLink: [],
        agentStatus: "",
        agent_id: null,
        agent: null,
        metaDesc: "",
        metaTags: [],
        commission: "",
        securityDeposit: "",
        dewaNo: "",
        askingPrice: null,
        pricedrop: "",
        oneCheqPrice: null,
        twoCheqPrice: null,
        fourCheqPrice: null,
        sixCheqPrice: null,
        twelveCheqprice: null,
        fees: [],
        property_owner_id: "",
        propertyOwner: null,
        furnishing: '',
        contractValidity: '',
        isOffPlanResale: null,
        amountAlreadyPaid: null,
    };

    return (
        <React.Fragment>
            <Head title="Add Property"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween className="g-3">
                        <BlockHeadContent>
                            <Link to={"/property-list"}>
                                <Button
                                    color="light"
                                    outline
                                    className="bg-white d-none d-sm-inline-flex"
                                >
                                    <Icon name="arrow-left"></Icon>
                                    <span>Back</span>
                                </Button>
                                <Button
                                    color="light"
                                    outline
                                    className="btn-icon bg-white d-inline-flex d-sm-none "
                                >
                                    <Icon name="arrow-left"></Icon>
                                </Button>
                            </Link>
                            <BlockTitle page className="mt-4">
                                Add New Property
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Row className="gy-5">
                        <Col lg="12">
                            <Card className="card-bordered">
                                <PreviewCard>
                                    <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                                        <Form property={property} type="add" />
                                    </div>
                                </PreviewCard>
                            </Card>
                        </Col>
                    </Row>
                </Block>
            </Content>
        </React.Fragment>
    );
};
export default AddProperty;
