import * as types from "../types";

export const getOffplans = (axios) => async (dispatch) => {
    try {
        if (axios && axios.get) {
            const { data } = await axios.get("/off-plan");

            dispatch({
                type: types.GET_OFFPLANS,
                payload: data,
            });
        }
        throw new Error("Please pass valid axios instance");
    } catch (err) {
        console.log(err);
    }
};
export const fetchOffplan= (axios, link) => async (dispatch) => {
    try {
        dispatch({
            type:types.GET_OFFPLANS_LOADING,
            payload:true
        })
        const url =
            link ??
            `/off-plan?page=1&sort_by=createdAt&per_page=10&sort_dir=DESC`;
        await axios
            .get(url)
            .then((res) => {
                dispatch({
                    type: types.GET_OFFPLANS_PAGINATED,
                    payload: res.data ?? {},
                });
            })
            .catch((error) => {
                console.log(error);
            });
    } catch (error) {
        console.log(error);
    }finally{
        dispatch({
            type:types.GET_OFFPLANS_LOADING,
            payload:false
        })
    }
};