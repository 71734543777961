import { useState, useEffect } from "react";
import { useDrop } from "react-dnd";
import { CloseButton } from "reactstrap";

// STYLES
const inputStyling = {
  border: "0.1px solid #F0F0F0",
  borderLeft: "none",
  padding: "0.7rem",
  minWidth: "100px",
  backgroundColor: "white",
};

const closeButtonStyling = {
  position: "absolute",
  right: 10,
  top: "50%",
  transform: "translateY(-50%)",
};

const labelStyling = {
  maxWidth: "300px",
  borderRight: "none",
  background: "#f5f6fa",
  border: "#f5f6fa",
};

const FormField = ({ name, required, fileHeaders, setFileHeaders }) => {
  const [formValue, setFormValue] = useState("");
  const [deletedValues, setDeletedValues] = useState([]);

  useEffect(() => {
    if (deletedValues.includes(formValue)) {
      setFormValue("");
    }
  }, [deletedValues, formValue]);

  const [, drop] = useDrop({
    accept: "CARD",
    drop: (item) => {
      setFormValue(item.text);
      removeDeletedValue(item.text);
    },
  });

  const handleInputChange = (event) => {
    setFormValue(event.target.value);
    removeDeletedValue(event.target.value);
  };

  const handleDelete = (placeholder) => {
    setDeletedValues([...deletedValues, formValue]);
    setFormValue("");
    const updatedHeaders = fileHeaders
      ? `${fileHeaders},${placeholder}`
      : placeholder;
    setFileHeaders(updatedHeaders);
  };

  const removeDeletedValue = (value) => {
    setDeletedValues(
      deletedValues.filter((deletedValue) => deletedValue !== value)
    );
  };

  return (
    <div className="" style={{ display: "flex", marginBottom: "1.2rem" }}>
      <span className="input-group-text " style={labelStyling}>
        {`${name}`}{" "}
        {required ? (
          <span style={{ color: "red", fontSize: "200%" }}> * </span>
        ) : null}
      </span>
      <div style={{ position: "relative", display: "flex", flex: 1 }}>
        <input
          id={name.replace(/\s+/g, "-")}
          name={`${name}`}
          className="form-control"
          type="text"
          disabled
          value={formValue}
          onChange={handleInputChange}
          onClick={(e) => console.log(e.target.value)}
          ref={drop}
          placeholder={`${name}...`}
          style={inputStyling}
        />
        {formValue !== "" && (
          <CloseButton
            onClick={() => handleDelete(`${formValue}`)}
            style={closeButtonStyling}
          />
        )}
      </div>
    </div>
  );
};

export default FormField;
