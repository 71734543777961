import { useMemo, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useQuery } from "react-query";
import { agentQueries } from "../../queries/agentQueries";
import AgentTeamView from "./AgentTeamView";
/**
 * @typedef {Object} Props
 * @property {import("../../api/agents/fetchAgentByUserId").AgentResponse} agent - The user information to fetch deals for.
 */

const THIRTY_MINUTES = 30 * 60 * 1000;

/**
 * Container component for managing an agent team members state for the profile page.
 * @param {Props} props - The properties object.
 * @returns {JSX.Element} - The rendered container component.
 */
const AgentTeamContainer = ({ agent }) => {
  const axios = useAxiosPrivate();
  const agentId = useMemo(() => agent?.id ?? agent?._id, [agent]);

  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 5,
  });

  const { queryKey, queryFn: getMembers } = agentQueries.getTeamMembers(
    axios,
    agent?.isLeader ? agent?.userId : agent?.leaderId,
    {
      params: {
        page: queryParams?.page,
        limit: queryParams?.limit,
      },
    }
  );

  const { data, error, isLoading, isError } = useQuery(queryKey, getMembers, {
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    refetchOnWindowFocus: false,
    enabled: !!agentId,
  });

  return (
    <AgentTeamView 
      queryParams={queryParams} 
      setQueryParams={setQueryParams} 
      agent={agent} 
      membersData={{ data, error, isLoading, isError }} 
    />
  );
};

export default AgentTeamContainer;
