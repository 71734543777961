import React, { lazy, Suspense, useMemo, useState } from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  PreviewAltCard,
  TooltipComponent,
} from "../Component";
import { HEADER_FIELDS, SHOW_ACTIVITIES_OPTIONS } from "./constants";
import {
  Badge,
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Tooltip,
  UncontrolledDropdown,
} from "reactstrap";
import { getActivityBadgeColor } from "./utils";
import CardInner from "../cards/CardInner";
import CardTitleGroup from "../cards/CardTitleGroup";
import CardTools from "../cards/CardTools";
import ActivitySkeletonLoader from "./components/ActivitySkeletonLoader";
import { format } from "date-fns";
import {
  ActivityStatus,
  ActivityTypes,
  LeadStatus,
  ScreenBreakPoints,
} from "../../utils/envConfig";
import "../../api/global-types/types";
import ActivityCreationForm from "./forms/ActivityCreationForm";
import {
  ActivityProvider,
  useActivityFormState,
} from "./context/ActivityProvider";
import { createActivity } from "../../api/activities/createActivity";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { showToast } from "../../utils/toast/toast";
import useMutationQuery from "../../hooks/useMutationQuery";
import LoaderModal from "../modals/LoaderModal";
import useScreenSize from "../../hooks/useScreenSize";

const ActivityEditModal = lazy(() => import("./modals/ActivityEditModal"));
const ActivityCompletionModal = lazy(() =>
  import("./modals/ActivityCompletionModal")
);

/**
 * @typedef {Object} ActivitiesState
 * @property {Array<UserActivity>} activities - The array of activities data.
 * @property {boolean} isLoading - Whether the activities are currently being loaded.
 * @property {Error} error - Any error that occurred during data fetching.
 */

/**
 * A table component for displaying user activities.
 *
 * @param {Object} props - The props for the component.
 * @param {ActivitiesState} props.activitiesState - The activities state.
 * @param {User} props.user - The user associated with the activities
 * @param {QueryState} props.queryParams - The query params state
 * @param {import("react").Dispatch<import("react").SetStateAction<QueryState>>} props.setQueryParams - The React setter function for the query params state
 * @returns {JSX.Element} - The rendered table component.
 */
const ActivitiesTableView = ({
  activitiesState,
  user,
  queryParams,
  setQueryParams,
}) => {
  const axios = useAxiosPrivate();
  const { ROW_HEIGHT } = useActivityFormState();
  const { width: screenWidth } = useScreenSize();
  const [searchText, setSearchText] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [adminAddTaskModal, setAdminAddTaskModal] = useState(false);
  const toggleAdminAddTaskModal = () => {
    setAdminAddTaskModal(!adminAddTaskModal);
  };

  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const toggleCompleteModal = () => {
    setIsCompleteModalOpen(!isCompleteModalOpen);
  };
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };
  const [activityToComplete, setActivityToComplete] = useState(null);
  const [activityToEdit, setActivityToEdit] = useState(null);
  const [activityToView, setActivityToView] = useState(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const { userStatus } = useMemo(
    () => ({
      userStatus: user?.lead_status ?? "",
    }),
    [user]
  );
  const { hasPendingOrOverdue } = useMemo(
    () => ({
      hasPendingOrOverdue: activitiesState?.activities?.some((activity) =>
        [ActivityStatus.Overdue, ActivityStatus.Pending].includes(
          activity?.status ?? ""
        )
      ),
    }),
    [activitiesState?.activities]
  );

  const activityCreate = useMutationQuery(
    (payload) => createActivity(axios, payload),
    {
      onError: (error) => {
        showToast("Failed to create task.", "error");
        console.log(error);
      },
    }
  );

  if (activitiesState.error) {
    return <div>Error: {activitiesState.error.message}</div>;
  }

  const isRendered = (header = { label: "", breakpoint: "" }) => {
    if (
      (screenWidth <= ScreenBreakPoints.xl && header.label === "Description") ||
      (screenWidth <= ScreenBreakPoints.xxl && header.label === "Property") ||
      (screenWidth < ScreenBreakPoints.md &&
        ["Notes", "Date", "Completed Date"].includes(header.label))
    ) {
      return false;
    }
    return true;
  };

  return (
    <React.Fragment>
      <BlockHead id={'activities'}>
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Activities</BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent>
            {userStatus !== LeadStatus.Disqualified ? (
              <>
                <Button
                  className="bg-primary text-white"
                  onClick={(ev) => {
                    ev.preventDefault();
                    if (hasPendingOrOverdue) {
                      return;
                    }
                    toggleAdminAddTaskModal();
                  }}
                  disabled={hasPendingOrOverdue}
                  id="addTaskButton"
                >
                  <Icon name={"plus"} className={"me-1"} />
                  Add Task
                </Button>
                <Tooltip
                  isOpen={tooltipOpen}
                  target="addTaskButton"
                  toggle={() => setTooltipOpen((prev) => !prev)}
                >
                  {hasPendingOrOverdue
                    ? "Please complete pending tasks"
                    : "Add a new task for this lead"}
                </Tooltip>
              </>
            ) : null}
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className={"card-stretch mt-2 mx-1 mx-sm-0"}>
          <CardInner className="position-relative card-tools-toggle py-4">
            <CardTitleGroup>
              <CardTools>
                <div className="form-inline flex-nowrap gx-3"></div>
              </CardTools>

              <CardTools className="me-n1">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleSearch();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>

                  <li className="btn-toolbar-sep"></li>

                  <li>
                    <div className="toggle-wrap">
                      <div className={`toggle-content`}>
                        <ul className="btn-toolbar gx-1">
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag="span"
                                className="btn btn-trigger btn-icon dropdown-toggle"
                              >
                                <Icon name="setting"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end className="dropdown-menu-xs">
                                <ul className="link-check">
                                  <li>
                                    <span>Show</span>
                                  </li>
                                  {SHOW_ACTIVITIES_OPTIONS.map(
                                    (option, idx) => (
                                      <li
                                        className={
                                          queryParams?.pageSize === option
                                            ? "active"
                                            : ""
                                        }
                                        key={`act-show-option-${idx}`}
                                      >
                                        <DropdownItem
                                          tag="span"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setQueryParams((prev) => ({
                                              ...prev,
                                              pageSize: option,
                                              page: 1,
                                            }));
                                          }}
                                        >
                                          {option}
                                        </DropdownItem>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </CardTools>
            </CardTitleGroup>
            <div
              className={`card-search search-wrap ${isSearchOpen && "active"}`}
            >
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active bg-transparent text-secondary"
                    onClick={() => {
                      setQueryParams((prev) => ({
                        ...prev,
                        type: "",
                      }));
                      setSearchText("");
                      toggleSearch();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control ps-5  bg-transparent text-secondary"
                    placeholder="Search Activity by Type"
                    value={searchText}
                    onChange={(e) => {
                      setQueryParams((prev) => ({
                        ...prev,
                        type: e.target.value,
                        page: 1,
                      }));
                      setSearchText(e.target.value);
                    }}
                  />
                  <Button className="search-submit btn-icon  bg-transparent text-secondary border-0">
                    <Icon name="search"></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </CardInner>

          <DataTableBody compact>
            <DataTableHead className="nk-tb-item">
              {HEADER_FIELDS.map((header, idx) =>
                isRendered(header) ? (
                  <DataTableRow
                    size={header?.breakpoint}
                    className={"text-center"}
                    key={`activity-header-${idx}`}
                  >
                    <span className="sub-text">{header?.label}</span>
                  </DataTableRow>
                ) : null
              )}
            </DataTableHead>

            {activitiesState?.isLoading ? (
              <ActivitySkeletonLoader />
            ) : (
              activitiesState?.activities?.map((activity, idx) => (
                <DataTableItem
                  key={`activity-row-${idx}`}
                  style={{ height: ROW_HEIGHT }}
                >
                  <DataTableRow className={"text-center"}>
                    <span>{activity?.type || "-"}</span>
                  </DataTableRow>
                  <DataTableRow className={"text-center"}>
                    <Badge
                      color={getActivityBadgeColor(activity?.status)}
                      className="mx-auto"
                    >
                      {activity?.status}
                    </Badge>
                  </DataTableRow>
                  {screenWidth >= ScreenBreakPoints.md && (
                    <>
                      <DataTableRow className="text-center">
                        <span>
                          {format(
                            new Date(activity?.date),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        </span>
                      </DataTableRow>
                      <DataTableRow className="text-center">
                        <span>
                          {activity?.completed_date && activity?.completed_date
                            ? format(
                                new Date(activity?.completed_date),
                                "dd/MM/yyyy hh:mm a"
                              )
                            : ""}
                        </span>
                      </DataTableRow>
                    </>
                  )}
                  {/* Only show on large screens */}
                  {screenWidth >= ScreenBreakPoints.md && (
                    <DataTableRow className="text-center">
                      <p
                        style={{
                          maxWidth:
                            screenWidth >= ScreenBreakPoints.xxl
                              ? "30ch"
                              : "15ch",
                          textWrap: "wrap",
                        }}
                      >
                        {activity?.notes || "-"}
                      </p>
                    </DataTableRow>
                  )}
                  {screenWidth > ScreenBreakPoints.xl && (
                    <DataTableRow className="text-center">
                      <p
                        className="mx-auto"
                        style={{ maxWidth: "15ch", textWrap: "wrap" }}
                      >
                        {activity.type ===
                        ActivityTypes.GetMatchedAssistance ? (
                          <pre>{activity?.description || "-"}</pre>
                        ) : (
                          <>{activity?.description || "-"}</>
                        )}
                      </p>
                    </DataTableRow>
                  )}
                  {screenWidth > ScreenBreakPoints.xxl && (
                    <DataTableRow>
                      <p
                        style={{ maxWidth: "30ch", textWrap: "wrap" }}
                        className="text-center"
                      >
                        {activity.property_list && (
                          <>
                            {"Reference: " + activity.property_list.referNo}{" "}
                            <br />
                            {"Title: " +
                              activity.property_list.propertyTitle}{" "}
                            <br />
                            {"Type: " + activity.property_list.listingType}{" "}
                            <br />
                            {"Building Name: " +
                              activity.property_list.buildingName}{" "}
                            <br />
                            {"Location: " +
                              activity.property_list.communityName}{" "}
                            <br />
                            {"Emirate: " + activity.property_list.emirate}{" "}
                            <br />
                          </>
                        )}
                        {!activity.property_list && "-"}
                      </p>
                    </DataTableRow>
                  )}
                  <DataTableRow className="nk-tb-col-tools">
                    {true && (
                      <ul className="nk-tb-actions gx-1" style={{ fontSize: '.9rem' }}>
                        {activity?.status === "Pending" ||
                        activity?.status === "Overdue" ? (
                          <>
                            <li
                              className=""
                              onClick={() => {
                                toggleCompleteModal();
                                setActivityToComplete(activity);
                              }}
                            >
                              <TooltipComponent
                                icon={"check-c"}
                                direction={"top"}
                                text={"Complete Task"}
                                iconClass={" text-success cursor-pointer"}
                                id={"complete-task"}
                                containerClassName={"cursor-pointer"}
                              />
                            </li>
                            <li
                              className=""
                              onClick={() => {
                                toggleEditModal();
                                setActivityToEdit((_) => activity);
                              }}
                            >
                              <Icon name="edit"></Icon>
                            </li>
                          </>
                        ) : null}
                        <li
                          className=""
                          onClick={() => {
                            toggleViewModal();
                            setActivityToView((_) => activity);
                          }}
                        >
                          <Icon name="eye"></Icon>
                        </li>
                      </ul>
                    )}
                  </DataTableRow>
                </DataTableItem>
              ))
            )}
          </DataTableBody>
          <PreviewAltCard>
            {activitiesState?.error ? (
              <p className="text-center text-danger fs-6">
                Failed to fetch activities.
                <br />
                {activitiesState?.error?.response?.data?.message ?? null}
              </p>
            ) : null}
            {activitiesState?.activities?.length ? (
              <PaginationComponent
                itemPerPage={queryParams?.pageSize}
                totalItems={activitiesState?.totalActivities}
                paginate={(page) =>
                  setQueryParams((prev) => ({ ...prev, page }))
                }
                currentPage={queryParams?.page}
              />
            ) : (
              <p className="text-center fw-bold fs-6">
                No Activities found.
                <br />
              </p>
            )}
          </PreviewAltCard>
        </DataTable>
      </Block>
      <ActivityProvider>
        <Modal
          size="lg"
          isOpen={adminAddTaskModal}
          toggle={toggleAdminAddTaskModal}
          onClick={(e) => e.stopPropagation()}
        >
          <ModalBody>
            <span
              onClick={(ev) => {
                ev.preventDefault();
                toggleAdminAddTaskModal();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </span>
            <ActivityCreationForm
              user={user}
              toggleFn={toggleAdminAddTaskModal}
              mutations={{ createActivityFn: activityCreate }}
              showTitle
            />
          </ModalBody>
        </Modal>
        {isViewModalOpen && (
          <Suspense fallback={<LoaderModal />}>
            <Modal
              isOpen={isViewModalOpen}
              toggle={toggleViewModal}
              size="lg"
              onClick={(e) => e.stopPropagation()}
            >
              <ModalHeader>
                <h5>Activity Details</h5>
              </ModalHeader>
              <ModalBody>
                <div className="d-flex flex-column" style={{ gap: "1rem" }}>
                  <div>
                    <h6>Description</h6>
                    <p
                      style={{
                        maxWidth:
                          screenWidth < ScreenBreakPoints.md ? "10ch" : "75ch",
                        textWrap: "wrap",
                      }}
                    >
                      {activityToView.type ===
                      ActivityTypes.GetMatchedAssistance ? (
                        <pre>{activityToView?.description || <span className="text-muted">No description.</span>}</pre>
                      ) : (
                        <>{activityToView?.description || <span className="text-muted">No description.</span>}</>
                      )}
                    </p>
                  </div>
                  <div>
                    <h6>Property</h6>
                    {activityToView.property_list && (
                      <p
                        style={{
                          maxWidth:
                            screenWidth < ScreenBreakPoints.md
                              ? "10ch"
                              : "75ch",
                          textWrap: "wrap",
                        }}
                      >
                        {"Reference: " + activityToView.property_list.referNo}{" "}
                        <br />
                        {"Title: " +
                          activityToView.property_list.propertyTitle}{" "}
                        <br />
                        {"Type: " +
                          activityToView.property_list.listingType}{" "}
                        <br />
                        {"Building Name: " +
                          activityToView.property_list.buildingName}{" "}
                        <br />
                        {"Location: " +
                          activityToView.property_list.communityName}{" "}
                        <br />
                        {"Emirate: " +
                          activityToView.property_list.emirate}{" "}
                        <br />
                      </p>
                    )}
                    {!activityToView.property_list && (
                      <span className="text-muted">No info available</span>
                    )}
                  </div>
                  <div>
                    <h6>Notes</h6>
                    <p
                      style={{
                        maxWidth:
                          screenWidth < ScreenBreakPoints.md
                            ? "10ch"
                            : "75ch",
                        textWrap: "wrap",
                      }}
                    >
                      {activityToView?.notes || <span className="text-muted">No note provided.</span>}
                    </p>
                  </div>
                  <Row>
                    <Col>
                      <h6>Scheduled Date</h6>
                      <p>
                        {format(
                          new Date(activityToView?.date),
                          "dd/MM/yyyy hh:mm a"
                        )}
                      </p>
                    </Col>
                    <Col>
                      <h6>Completed Date</h6>
                      <p>
                        {activityToView?.completed_date &&
                        activityToView?.completed_date
                          ? format(
                              new Date(activityToView?.completed_date),
                              "dd/MM/yyyy hh:mm a"
                            )
                          : <span className="text-muted">Not completed yet</span>}
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <Button onClick={toggleViewModal}>OK</Button>
                </div>
              </ModalBody>
            </Modal>
          </Suspense>
        )}
        {isCompleteModalOpen && (
          <Suspense fallback={<LoaderModal />}>
            <ActivityCompletionModal
              isOpen={isCompleteModalOpen}
              toggleModal={toggleCompleteModal}
              user={user}
              completedActivity={activityToComplete}
            />
          </Suspense>
        )}
        {isEditModalOpen && (
          <Suspense fallback={<LoaderModal />}>
            <ActivityEditModal
              isOpen={isEditModalOpen}
              toggleFn={toggleEditModal}
              completedActivity={activityToEdit}
            />
          </Suspense>
        )}
      </ActivityProvider>
    </React.Fragment>
  );
};

export default ActivitiesTableView;
