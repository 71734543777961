import { useLocation } from "react-router";
import CheckedOutList from "./components/CheckedOutList";
import { useState } from "react";

const ListCheckedOutProperties = () => {
  const { state } = useLocation();
  // Pass the state to the child component to render newly-checkout
  // cards differently
  // eslint-disable-next-line no-unused-vars
  const [navigationState, setNavigationState] = useState(state);

  return (
    <>
      <CheckedOutList navigationState={navigationState} />
    </>
  );
};

export default ListCheckedOutProperties;
