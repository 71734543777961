import React, { useState, useEffect } from "react";
// import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Markup } from "react-render-markup";
import { Badge, Card } from "reactstrap";
import
{
  HttpStatus,
  ListingType,
  PropertyListStatus,
  Status,
  formatValue,
  getImage,
  newPropStatus,
  newRoles,
  serverAPI
} from "../../../../utils/envConfig";
import
{
  Button,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
} from "../../../../components/Component";
import PropertyListInfo from "../PropertyInfoList";
import { fetchAmenities, fetchProperty } from "../../../../redux/actions/property";
import LocationInfo from "../LocationInfo";
import Cheques from "../Cheques";
import PropertyMeta from "../PropertyMeta";
import Fees from "../Fees";
import PropImgSlider from "../PropImgSlider";
import ConfirmModal from "../../../../components/miscellaneous/ConfirmModal";
import { useNavigate } from "react-router-dom";
import DraggableList from "../AmenityList";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import ChequesPreview from "../ChequesPreview";
import { showToast } from "../../../../utils/toast/toast";
import { ErrorNameSpaces, ErrorTypes } from "../../../../utils/error-types";


const StepFive = (props) => {
  const dispatch = useDispatch()
  const navigate= useNavigate()
  const propId = props.values.id
  const axios = useAxiosPrivate();
  
  const [ propImagesUrl, setPropImagesUrl ] = useState( [] );
  const [ currentSlide, setCurrentSlide ] = useState( {} );
  const [ imgLoading, setImgLoading ] = useState( [] );
  const [price, setPrice] = useState(0);
  const [monthPrice, setMonthPrice] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const amenity = useSelector((state) => state.property?.amenities);
  const property= useSelector( ( state ) => state.property?.property );
  const [featured, setFeatured] = useState(false);
  const [dealOfWeek, setDealOfWeek] = useState(false);
  const [exclusive, setExclusive] = useState(false);
  const [premium, setPremium] = useState(false);
  const [distress, setDistress] = useState(false);
  const [luxury, setLuxury] = useState(false);
  const [amenities, setAmenities] = useState([]);

  const { role } = useSelector(state => state?.user?.loggedInUser);
  const handleModal = (e) => {
    e.preventDefault();
    if (
      (property?.status !== Status[0] && property?.images.length <= 0) ||
      property?.property_owner_id === undefined ||
      property?.property_owner_id === null ||
      property?.property_owner_id === ""
    ) {
      toast.error("Missing Owner or Images for the Property !");
    } else {
      setShowModal(true);
    }
  };
  useEffect( () =>
  {
    dispatch( fetchProperty(propId,axios) );
    dispatch(fetchAmenities(axios));
  }, [ dispatch,propId ] );

  useEffect(() => {
    let options = [];
    if (amenity.length > 0 && property?.amenityObjs !== undefined) {
      amenity.map((ele) =>
        property?.amenityObjs?.map((am) =>
          am.amenityId === ele.id
            ? options.push({
                amenity: ele.amenity,
                quantity: am.quantity,
                category: ele.amenityCategory.name,
                priority: am.priority,
                icon:ele.image
              })
            : ""
        )
      );
      setAmenities(options);
    }
  }, [amenity, property]);
  const getUrl = async ( file ,i) =>
  {
    const resData = await getImage( file, axios );
    const s3url = resData.signedRequest;
    if ( s3url )
    {
      setPropImagesUrl( ( prevImages ) => [
        ...prevImages,
        { id: prevImages.length, img: s3url }
      ]);
      if (i === 0) {
        setCurrentSlide({ id: 0, img: resData.signedRequest }); // set the current slide to the first image
      }
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const handleStatus = async (e) => {
    e.preventDefault();
    let newStatus = property.status === Status[0] ? Status[1] : Status[0];
    const newStatusArr = newPropStatus[5]; // The value you want to add or remove

    // Check if newStatus exists in the array
    const index = property?.statusArray.indexOf(newStatusArr);
    let updatedStatusArray =property?.statusArray;
    if (index !== -1) {
      updatedStatusArray = property?.statusArray.filter(item => item !== newStatusArr);
      // Now, updatedStatusArray doesn't include the newStatus
    } 

    const data = JSON.stringify({ 
      status: newStatus,
      statusArray:updatedStatusArray,
      dealOfWeek,featured,premium,exclusive, isDistress: distress ?? false, isLuxury: luxury ?? false });

    // Checking permit number
    if (!property.permitNo) {
      toast.error('Please enter permit number');
      return false;
    }

    
    // Checking permit number uniqueness
    if (property.permitNo) {
      let link = `/filter/propList?status=Active&permitNo=${property.permitNo}&listingType=${property.listingType}`;
      const count = await fetchPropertiesByPermitNo(link);
      if (count > 0) {
        toast.error('Permit number should be unique');
        return false;
      }
    }

    try {
      setIsUpdateLoading(true);
      const res = await axios.patch(`/propList/${property.id}`, data);
      
      if ( res.status === HttpStatus.OK) {
        showToast("Property Updated", 'success');

        if (data && newStatus === PropertyListStatus.Published) {
          // try to publish to property finder
          const pfRes = await axios.post(`/propList/pf-draft/${property.id}`);
          if ( pfRes.status === HttpStatus.CREATED ) {
            showToast('Successfully published the draft on PropertyFinder.', 'success');
          }
        } else if (
          data 
          && (newStatus === PropertyListStatus.Unpublished ||
              data?.statusArray?.includes(PropertyListStatus.Cancelled))
        ) {
          const pfRes = await axios.delete(`/propList/pf-cancel/${property.id}`);
          if ( pfRes.status === HttpStatus.NO_CONTENT ) {
            showToast('Removed from PropertyFinder listings.', 'warning');
          }
        }
      }
      // await axios
      //   .patch(`/propList/${property.id}`, data)
      //   .then((property) => {
      //     toast.success("Status updated");
      //     setShowModal(false);
      //     navigate('/property-list')
      //   })
      //   .catch((err) => {
      //     toast.error("Error!");
      //   });
    } catch (err) {
      console.log(err);
      const serverErrorMessage = err?.response?.data?.data?.messages ?? err?.response?.data?.message;
      const serverErrorType = err.response?.data?.name ?? err.response?.data?.type;
      
      if (serverErrorMessage) {
        const msg = [ErrorNameSpaces.PropertyFinder, ErrorTypes.PropertyFinderError].includes(serverErrorType ?? '') ? `PF Error: ${serverErrorMessage}` : serverErrorMessage;
        showToast(msg, 'error');
      } else {
        showToast('An error occurred.', 'error');
      }
    } finally {
      setIsUpdateLoading(false);
      setShowModal(false);
      navigate('/property-list');
    }
  };

  const fetchPropertiesByPermitNo = async (link) => {
    try {
      const res = await axios.get(link);
      return res?.data?.filteredCount;
    } catch (err) {
      toast.error('Permit No Validation failed');
    }
  };

  useEffect( () =>
  {
    setPropImagesUrl([]);
    setCurrentSlide({});
    if ( property?.images && property.images.length > 0 )
    {
      setImgLoading( true );
      let imgs = property.images;
      if ( imgs.length )
      {
        for ( let i = 0; i < imgs.length; i++ )
        {
          getUrl( imgs[ i ]?.thumbnail,i );
        }
      }
    } else
    {
      setImgLoading( false );
    }

    if (
      property?.listingType === ListingType[0] ||
      property?.listingType === ListingType[2]
    ) {
      setPrice(property.askingPrice);
      setMonthPrice(property.askingPrice / 12);
    } else {
      setPrice(property?.oneCheqPrice);
      setMonthPrice(property?.emi);
    }
    if(property)  setFeatured(property.featured)
    if(property)  setDealOfWeek(property.dealOfWeek)
    if(property)  setExclusive(property.exclusive)
    if(property)  setPremium(property.premium)
  },[property])


  const [ dragging, setDragging ] = useState( false );
  const [ draggedIndex, setDraggedIndex ] = useState( null );
  const [ listItems, setListItems ] = useState( [
    {
      name: "Balcony",
      icon: "move",
      value: "Quantity: 2"
    },
    {
      name: "Central AC",
      icon: "move",
      value: ""
    },
    {
      name: "Private Garden",
      icon: "move",
      value: ""
    }
  ] );
  const handleDragStart = ( e, index ) =>
  {
    setDragging( true );
    setDraggedIndex( index );
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData( "text/html", e.target.parentNode );
    e.dataTransfer.setDragImage( e.target.parentNode, 20, 20 );
  };
  const handleDragOver = ( e, index ) =>
  {
    e.preventDefault();
    if ( index !== draggedIndex )
    {
      const newList = [ ...listItems ];
      const [ removed ] = newList.splice( draggedIndex, 1 );
      newList.splice( index, 0, removed );
      setListItems( newList );
      setDraggedIndex( index );
    }
  };
  const handleDragEnd = () =>
  {
    setDragging( false );
    setDraggedIndex( null );
  };
  const handleDealOfWeekChange = () => {
    setDealOfWeek((prevDealOfWeek) => !prevDealOfWeek);
  };

  const handleExclusiveChange = () => {
    setExclusive((prevExclusive) => !prevExclusive);
  };

  const handleFeaturedChange = () => {
    setFeatured((prevFeatured) => !prevFeatured);
  };

  const handlePremiumChange = () => {
    setPremium((prevPremium) => !prevPremium);
  };

  const handleDistressChange = () => {
    setDistress((prev) => !prev);
  };

  const handleLuxuryChange = () => {
    setLuxury((prev) => !prev);
  };

  return (
    <>
   {property &&
      <div className="card-inner">
        <Row className="border border-primary rounded">
          <div className="card-inner d-flex justify-content-between align-items-center">
            <Col sm="7">
              <ul className="d-flex g-3 gx-5">
                <li style={ { width: "10rem" } }>
                  <div className="fs-14px text-muted mb-2">Deal of the week</div>
                  <div className="fs-16px fw-bold text-secondary">     <div className="data-value">
                    <div className="custom-control custom-switch">
                      <input
                      value={dealOfWeek}
                        type="checkbox"
                        checked={dealOfWeek}
                        className="custom-control-input"
                        id="customSwitch1"
                        onChange={handleDealOfWeekChange}
                        
                      />
                      <label className="custom-control-label" htmlFor="customSwitch1">
                      </label>
                    </div></div></div>
                </li>
                <li style={ { width: "10rem" } }>
                  <div className="fs-14px text-muted mb-2">Exclusive</div>
                  <div className="fs-16px fw-bold text-secondary">     <div className="data-value">
                    <div className="custom-control custom-switch">
                      <input
                      value={exclusive}
                      checked={exclusive}
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch2"
                        onChange={handleExclusiveChange}
                      />
                      <label className="custom-control-label" htmlFor="customSwitch2">
                      </label>
                    </div></div></div>
                </li>
                <li style={ { width: "10rem" } }>
                  <div className="fs-14px text-muted mb-2">Featured</div>
                  <div className="fs-16px fw-bold text-secondary">     <div className="data-value">
                    <div className="custom-control custom-switch">
                      <input
                      value={featured}
                      checked={featured}
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch3"
                        onChange={handleFeaturedChange}
                      />
                      <label className="custom-control-label" htmlFor="customSwitch3">
                      </label>
                    </div></div></div>
                </li>
                <li style={ { width: "10rem" } }>
                  <div className="fs-14px text-muted mb-2">Premium</div>
                  <div className="fs-16px fw-bold text-secondary">     <div className="data-value">
                    <div className="custom-control custom-switch">
                      <input
                      value={premium}
                      checked={premium}
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch4"
                        onChange={handlePremiumChange}
                      />
                      <label className="custom-control-label" htmlFor="customSwitch4">
                      </label>
                    </div></div></div>
                </li>
                <li style={ { width: "10rem" } }>
                  <div className="fs-14px text-muted mb-2">Distress</div>
                  <div className="fs-16px fw-bold text-secondary">     <div className="data-value">
                    <div className="custom-control custom-switch">
                      <input
                      value={distress}
                      checked={distress}
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch5"
                        onChange={handleDistressChange}
                      />
                      <label className="custom-control-label" htmlFor="customSwitch5">
                      </label>
                    </div></div></div>
                </li>
                <li style={ { width: "10rem" } }>
                  <div className="fs-14px text-muted mb-2">Luxury</div>
                  <div className="fs-16px fw-bold text-secondary">     <div className="data-value">
                    <div className="custom-control custom-switch">
                      <input
                      value={luxury}
                      checked={luxury}
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch6"
                        onChange={handleLuxuryChange}
                      />
                      <label className="custom-control-label" htmlFor="customSwitch6">
                      </label>
                    </div></div></div>
                </li>
              </ul>
            </Col>
            {role && newRoles &&
                (role === newRoles.MasterAdmin ||
                  role === newRoles.Admin ||
                  role === newRoles.SalesManager)  &&(
            <Col sm="5">
              <div className="d-flex justify-content-end align-items-center">
                <Button 
                onClick={(e) => handleModal(e)}
                 className="bg-primary text-white">{property.status === Status[1]?"Save and Publish":"UnPublish"}</Button>
              </div>
            </Col>
                  )}
          </div>
        </Row>
        <Row className="g-gs flex-lg-row-reverse ">
          <Col sm="6" className="p-5">
            <h6 className="text-primary">
              AED {price &&formatValue(Math.round(price))} per year
              {monthPrice && " | AED"+formatValue(Math.round(monthPrice))+ " per month"}            
            </h6>
            <h3 className="pt-2">
              { property?.propertyTitle }
            </h3>
            <div>
            <Icon name="location"></Icon>   <span className="ml-2">{ property?.emirate }, { property?.community?.community } , { property?.buildingName }</span>
            </div>
            <div className="pt-2 pb-2 ">
              {/* "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." */}
              <Markup markup={ property?.propertyDesc } />
            </div>
        <PropertyMeta property={property}/>
          </Col>
          <Col sm="6" >
          <div className="product-gallery me-xl-1 me-xxl-5" style={ { position: "relative" } } >
                        </div>
            <div style={ { position: "relative"}}>
            {propImagesUrl&& < PropImgSlider
                        propImagesUrl={propImagesUrl} 
                        currentSlide={currentSlide}
                        setCurrentSlide={setCurrentSlide}
                        />}
             

            </div>
          </Col>

          <Col sm="7">
        <PropertyListInfo property={property}/>
          </Col>
          <Col sm="5" >
            <BlockHead>
              <BlockHeadContent>
                <BlockTitle tag="h5">Promotion</BlockTitle>
              </BlockHeadContent>
            </BlockHead>
            <Card className="card-bordered">
              <ul className="data-list is-compact">
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Meta Tags</div>
                    <div className="data-value">
                    { property?.metaTags.map( tag =>
                                  <div className="data-value">
                                    <Badge
                                      size="sm"
                                      color="outline-danger"
                                      className="badge-dim me-1"

                                    >
                                      { tag }
                                    </Badge>
                                  </div>
                                ) }
                    </div>

                  </div>
                </li>
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Meta Description</div>
                    <div className="data-value">
                    { property?.metaDesc }
                    </div>
                  </div>
                </li>
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Video Links</div>
                    <div className="data-value">
                    {property?.videos?.length > 0 && property?.videos[0]}
                    </div>
                  </div>
                </li>
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Matterport Link</div>
                    <div className="data-value">
                    {property?.mLinks?.length > 0 && property?.mLinks?.[0]}
                    </div>
                  </div>
                </li>
              </ul>
            </Card>
          </Col>
          <Col sm="7">
            <BlockHead>
              <BlockHeadContent>
                <BlockTitle tag="h5">Pricing Info</BlockTitle>

              </BlockHeadContent>
            </BlockHead>

            <Card className="card-bordered">
              <ul className="data-list is-compact">
              { property?.listingType === ListingType[ 0 ] && (
                <>
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Asking Price</div>
                    <div className="data-value">
                    AED { property?.askingPrice &&formatValue( property?.askingPrice ) }
                    </div>
                  </div>
                </li>
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Price Drop</div>
                    <div className="data-value">{ property?.pricedrop } %</div>
                  </div>
                </li>
                </>
              )}

                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Commision</div>
                    <div className="data-value">{ property?.commission } %</div>
                  </div>
                </li>
                {/* <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">Revenue</div>
                    <div className="data-value">200,000,000</div>
                  </div>
                </li> */}

              </ul>
            </Card>
            <Fees property={property}/>
          </Col>
          <Col sm="5">           
              <ChequesPreview property={property}/>
          </Col>
          <Col lg="8">
      <LocationInfo property={property}/>
          </Col>
          <Col lg="4">
            <BlockHead>
              <BlockHeadContent>
                <BlockTitle tag="h5">Amenities</BlockTitle>

              </BlockHeadContent>
            </BlockHead>
            <Card className="card-bordered">
            <DraggableList amenities={amenities} setAmenities={setAmenities}/>
            </Card>


          </Col>
        </Row>
              {/* ****** Modal Start ****** */}
              {showModal ? (                   
            <ConfirmModal
              msg={"Update the Status of this property"}
              name={"" }
              open={showModal}
              handleClick={handleModal}
              handleConfirm={handleStatus}
              handlecloseModal={closeModal}
              loading={isUpdateLoading}
            />
          ) : null}
          {/* ****** Modal End ****** */}
      </div>
     }
  </>
  );
};

export default StepFive;
