import React, { useState, useEffect, useMemo, useCallback } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchAmenitiesList,
    fetchFilteredAmenities,
    fetchPropertiesAdmin,
} from "../../redux/actions/property";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Button, Modal, ModalBody, Input } from "reactstrap";
import {
    Block,
    BlockHeadContent,
    BlockTitle,
    BlockBetween,
    BlockHead,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    Row,
    Col,
    RSelect,
    TooltipComponent,
    BlockDes,
    PaginationComponent,
} from "../../components/Component";
import {actionS3Objects, uploadToS3 } from "../../utils/envConfig";
import { useLocation, useNavigate } from "react-router-dom";
import Compressor from "compressorjs";
import { v4 as uuidv4 } from "uuid";
import ConfirmModal from "../../components/miscellaneous/ConfirmModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import queryString from "query-string";
import { debounce } from "lodash";
import LoadingComponent from "../components/loader/tableLoader";

function compress(file) {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.6,
            success(result) {
                resolve(result);
            },
            error(e) {
                reject(e);
            },
        });
    });
}

const AmenityList = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const axios = useAxiosPrivate();
    const location = useLocation();
    const query = queryString.parse(location.search);

    const { Amenity, amenitiesCount, amenitiesLoading } = useSelector((state) => ({
        Amenity: state.property?.amenities.results,
        amenitiesCount: state.property?.amenities.found,
        amenitiesLoading: state.property?.amenitiesLoading,

    }));

    const property = useSelector((state) => state.property?.propertiesAdmin);

    // State Variables
    const [open, setOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [amenityId, setAmenityId] = useState("");
    const [Amenityname, setAmenityName] = useState("");
    const [check, SetCheck] = useState([]);
    const [amenity, setAmenity] = useState("");
    const [amenityCategory, setAmenityCategory] = useState([]);
    const [amenityCategoryId, setAmenityCategoryId] = useState("");
    const [avatar, setAvatar] = useState("");
    const [displayAvatar, setDisplayAvatar] = useState("");
    const [avatarFile, setAvatarFile] = useState("");
    const [amenityIconPath, setAmenityIconPath] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState(query?.search ?? "");
    // pagination state
    const [page, setPage] = useState(query?.page ? Number(query?.page) : 1);
    const [perPage, setPerPage] = useState(
        query?.limit ? Number(query?.limit) : 20
    );
    const [refresh, setRefresh] = useState(false);

    // Constants
    // Validate User Token
    const user = useSelector((state) => state?.user?.loggedInUser);
    const [edit, setEdit] = useState(false);
    const { access_token } = user || "";
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };

    const firstName = user?.first_name;
    const lastName = user?.last_name;
    const createdName = `${firstName} ${lastName}`;

    const folderName = "Amenity";
    // Onload fetch Amenity Categories
    useEffect(() => {
        async function fetchAmenityCategory() {
            try {
                const res = await axios
                    .get(`/amenityCategory`)
                    .then((cat) => {
                        setAmenityCategory(
                            cat.data.map((am) => ({
                                value: am.id,
                                label: am.name,
                            }))
                        );
                    })
                    .catch((err) => {
                        toast.error("Amenity Category not available !");
                    });
            } catch (err) {
                // console.log(err)
                toast.error("Server error. Try Again !");
            }
        }
        fetchAmenityCategory();
    }, []);

    // Input Validation
    const validate = (amenity, amenityCategoryId, amenityImg) => {
        if (amenity?.length < 2) {
            toast.error("Enter valid Amenity");
            return false;
        }
        if (!amenityCategoryId) {
            toast.error("Select Amenity Category");
            return false;
        }
        // if (!amenityImg) {
        //     toast.error("Upload Icon for Amenity");
        //     return false;
        // }
        else if (/^\d+$/.test(amenity)) {
            toast.error("Enter valid Amenity");
            return false;
        } else {
            return true;
        }
    };
    const handleEdit = (e, item) => {
        e.preventDefault();
        setOpen(true);
        setEdit(true);
        setAmenityId(item.id);
        setAmenity(item.amenity);
        setSelectedImage(item.image);
        setAmenityIconPath(item.icon);
        setAmenityCategoryId(item.amenityCategoryId);
        
    };
    const resetForm = () => {
        setOpen(false);
        setEdit(false);
        setAmenityId(null);
        setAmenity(null);
        setSelectedImage(null);
        setAmenityIconPath("");
    };
    const [selectedImage, setSelectedImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const handleImage = async (e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            const allowedTypes = [
                "image/jpeg",
                "image/png",
                "image/gif",
                "image/jpg",
                "image/svg",
                "image/webp",
            ];

            if (allowedTypes.includes(file.type)) {
                let result = await compress(file);
                let url = URL.createObjectURL(file);
                const fileName = `${result.name}`;
                setSelectedImage(url);
                setAvatar(result);
                setAvatarFile(fileName);
                setErrorMessage("");
            } else {
                setErrorMessage(
                    "Please upload a valid image file (JPEG, PNG, GIF, JPG, SVG, WEBP)"
                );
            }
        }
    };

    const handleRemove = (e) => {
        e.preventDefault();
        setSelectedImage(null);
        setAvatar(null);
        setAvatarFile("");
        setErrorMessage("");

        // Reset the input field value
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) {
            fileInput.value = "";
        }
    };

    const handleUpload = async (file, fileName) => {
        const uploadData = JSON.stringify({
            action: `${actionS3Objects[1]}`,
            fileName: fileName,
        });
        const resp = await axios.post(`/upload`, uploadData);
        const s3url = resp.data.signedRequest;
        if (s3url) {
            try {
                const myHeaders = new Headers({ "Content-Type": "image/*" });
                await Axios.put(s3url, file, { myHeaders });
            } catch (err) {
                // Handle Error Here
                toast.error("Server error. Try Again!");
            }
        }
    };
    // Add Amenity Info in DB
    const addAmenity = async (e) => {
        e.preventDefault();
        if (validate(amenity, amenityCategoryId, avatar)) {
            let fileName = "";
            if (avatar.name) {
                fileName = `${folderName}/${uuidv4()}.${avatar.name
                    .split(".")
                    .pop()}`;
                handleUpload(avatar, fileName);
            }
            const formData = {
                amenity,
                icon: fileName,
                amenityCategoryId,
            };
            // console.log(formData)
            try {
                const res = await axios
                    .post(`/amenity`, formData)
                    .then((amenity) => {
                        navigate(0);
                    })
                    .catch((err) => {
                        toast.error("Amenity already exists!");
                    });
            } catch (err) {
                // console.log(err)
                toast.error("Server error. Try Again !");
            }
        }
    };

    const handleModal = (e, id, name) => {
        e.preventDefault();
        setAmenityId(id);
        setAmenityName(name);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        resetForm();
    };
    const validateEdit = (amenity) => {
        if (amenity.length < 2) {
          toast.error("Enter valid Amenity");
          return false;
        } else {
          return true;
        }
      };
    const editAmenity= async (e) => {
        e.preventDefault();

        if (validateEdit(amenity)) {
            let fileName = amenityIconPath ? amenityIconPath : "";
            if (avatar && avatar.name) {
                fileName = `${folderName}/${uuidv4()}.${avatar.name
                    .split(".")
                    .pop()}`;
                fileName && (await uploadToS3(avatar, fileName,"image/*",axios));
            }
            const formData = {
                amenity,
                icon: fileName,
                amenityCategoryId,
            };

            try {
                setLoading(true);
                const res = await axios
                    .patch(`/amenity/${amenityId}`, formData, { headers })
                    .then((amenity) => {
                        navigate(0);
                    })
                    .catch((err) => {
                        toast.error("Amenity not modified !");
                    });
            } catch (err) {
                // console.log(err)
                toast.error("Server error. Try Again !");
                setLoading(false);
            }
        }
    };
    const queryAmenities = (params, updateRoute = true) => {
        const amenitiesParams = {
            limit: params?.perPage ?? query?.limit,
            page: params?.page ?? query?.page,
            search: params?.search ?? query?.search,
        };

        const queryParams = new URLSearchParams();

        Object.keys(amenitiesParams).forEach((key) => {
            const value = amenitiesParams[key];
            if (Array.isArray(value)) {
                value.forEach((subValue) => queryParams.append(key, subValue));
                // eslint-disable-next-line eqeqeq
            } else if (value != undefined && value !== "null") {
                queryParams.append(key, value);
            }
        });

        const queryString = queryParams.toString();

        if (updateRoute) {
            const navigateParams = {};

            if (location.search.length) {
                navigateParams.replace = true;
            }

            navigate(
                `${"/amenity-list"}?${queryString}`,
                navigateParams
            );
        }
        return queryString;
    }

    const handleSearchSubmit = (event) => {
        setSearchText(event.target.value);
        debounceSearchAmenities({ search: event.target.value });
    };

    // debounce update query
    const updateSearchQuery = useCallback(queryAmenities, [location.search.length, navigate, query?.limit, query?.page, query?.search]);

    const debounceSearchAmenities = useMemo(() => {
        return debounce(updateSearchQuery, 500);
    }, [updateSearchQuery]);
    // end debouncing

    useEffect(() => {
        dispatch(fetchFilteredAmenities(axios));
        dispatch(fetchPropertiesAdmin(axios));
    }, [axios, dispatch]);

    useEffect(() => {
        const queryString = updateSearchQuery({
            perPage,
            page,
            search: query?.search ?? null,
        });

        dispatch(fetchFilteredAmenities(axios, `?${queryString}`));
        
    }, [refresh, location.search, page, perPage, query?.search, dispatch, axios, updateSearchQuery])

    const submitDel = async (e) => {
        e.preventDefault();
        try {
            await axios
                .delete(`/amenity/${amenityId}`, { headers })
                .then((Amenity) => {
                    navigate(0);
                })
                .catch((err) => {
                    if (err.response.data.message === "LinkedtoListing") {
                        toast.error(
                            "Amenity cannot be removed as it is linked to a Property Listing"
                        );
                    } else {
                        toast.error("Amenity not removed !");
                    }
                });
        } catch (err) {
            // console.log(err)
            toast.error("Server error. Try Again !");
        }
        setShowModal(false);
    };

    useEffect(() => {
        let amIds = property.map((p) => p.amenityIds.map((x) => x.id));
        if (Amenity?.length > 0 && property?.length > 0) {
            let arr = Amenity.map((item, index) =>
                amIds.map((am) => am.includes(item.id))
            );
            let empty = [];
            arr.forEach((element) => {
                empty.push(element.reduce((x, y) => x || y, false));
            });
            SetCheck(empty);
        }
    }, [Amenity, property]);

    return (
        <>
            <React.Fragment>
                <Head title="Amenity"></Head>
                <Content>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle>Amenities</BlockTitle>
                                <BlockDes className="text-soft">
                                    <p>Found {amenitiesCount ?? 0} Amenity(s).</p>
                                </BlockDes>
                            </BlockHeadContent>
                            <BlockHeadContent>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <div className="toggle-expand-content">
                                        <ul className="nk-block-tools g-3">
                                            <li className="nk-block-tools-opt">
                                                <Button
                                                    className="toggle btn-icon d-md-none"
                                                    color="primary"
                                                >
                                                    <Icon name="plus"></Icon>
                                                </Button>
                                                <Button
                                                    className="toggle d-none d-md-inline-flex"
                                                    color="primary"
                                                    onClick={() => {
                                                        setOpen(true);
                                                    }}
                                                >
                                                    <Icon name="plus"></Icon>
                                                    <span>Add Amenity</span>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>

                    <div className="my-3 d-flex flex-row-reverse align-items-center justify-content-between">
                        {/* Entries per page config */}
                        <div className="d-flex align-items-center">
                            <span className="d-none d-sm-inline-block me-2">Show</span>
                            <div className="form-control-select">
                                <select
                                    name="DataTables_Table_0_length"
                                    className="custom-select custom-select-sm form-control form-control-sm"
                                    onChange={(e) => setPerPage(e.target.value)}
                                    value={perPage}
                                >
                                    <option value="1">1</option>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                        </div>
                        {/* Search bar */}
                        <div className="d-flex justify-content-center align-items-center">
                            <Input placeholder="Search Amenities..." value={searchText} type="text" name="search" onChange={handleSearchSubmit} style={{ minWidth: "20rem" }} />
                        </div>
                    </div>

                    <Block>
                        {Amenity && (
                            <div className="nk-tb-list is-separate is-medium mb-3">
                                <DataTableHead className="nk-tb-item">
                                    {/* <DataTableRow className="nk-tb-col-check">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="pid-all"
                                                // onChange={ ( e ) => selectorCheck( e ) }
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="pid-all"
                                            ></label>
                                        </div>
                                    </DataTableRow> */}
                                    <DataTableRow>
                                        <span className="sub-text">
                                            Amenity
                                        </span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">
                                            Amenity Category
                                        </span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Edit</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Delete</span>
                                    </DataTableRow>
                                </DataTableHead>
                                {!amenitiesLoading && Amenity ?
                                    [...Amenity].map((item, index) => (
                                        <DataTableItem key={index}>
                                            {/* <DataTableRow className="nk-tb-col-check">
                                                <div className="custom-control custom-control-sm custom-checkbox notext">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        defaultChecked={
                                                            item.check
                                                        }
                                                        id={index + "oId-all"}
                                                        key={Math.random()}
                                                        // onChange={ ( e ) => onSelectChange( e, index ) }
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={
                                                            index + "oId-all"
                                                        }
                                                    ></label>
                                                </div>
                                            </DataTableRow> */}
                                            <DataTableRow>
                                                <a
                                                    href="#id"
                                                    onClick={(ev) =>
                                                        ev.preventDefault()
                                                    }
                                                >
                                                    {item?.amenity}
                                                </a>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <a
                                                    href="#id"
                                                    onClick={(ev) =>
                                                        ev.preventDefault()
                                                    }
                                                >
                                                    {item.amenityCategory &&
                                                        item.amenityCategory
                                                            .name}
                                                </a>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <a
                                                    href="#"
                                                    onClick={(ev) =>
                                                        handleEdit(ev, item)
                                                    }
                                                >
                                                    <Icon
                                                        name="edit"
                                                        style={{
                                                            fontSize: "1.3rem",
                                                        }}
                                                    ></Icon>
                                                </a>
                                            </DataTableRow>
                                            <DataTableRow>
                                                {check.length > 0 &&
                                                check[index] === true ? (
                                                    <>
                                                        <TooltipComponent
                                                            tag="a"
                                                            containerClassName="btn btn-icon"
                                                            id={"delete"}
                                                            icon="trash"
                                                            direction="top"
                                                            text={
                                                                "Amenity can't be removed as it is linked to a Property Listing"
                                                            }
                                                        />
                                                    </>
                                                ) : (
                                                    <a
                                                        onClick={(e) =>
                                                            handleModal(
                                                                e,
                                                                item.id,
                                                                item.amenity
                                                            )
                                                        }
                                                        className="btn btn-icon"
                                                    >
                                                        <Icon
                                                            name="trash"
                                                            style={{
                                                                color: "#e85347",
                                                                fontSize:
                                                                    "1.3rem",
                                                            }}
                                                        />
                                                    </a>
                                                )}
                                            </DataTableRow>
                                        </DataTableItem>
                                    )) : <LoadingComponent />}
                                    {!amenitiesLoading ? 
                                    <PaginationComponent
                                        noDown
                                        currentPage={page ?? 1}
                                        paginate={(page) => {
                                            setRefresh(!refresh);
                                            setPage(page);
                                            updateSearchQuery({ perPage, page: page }, true);
                                        }}
                                        itemPerPage={perPage ?? 10}
                                        totalItems={amenitiesCount}
                                    /> : null}
                            </div>
                        )}
                    </Block>
                    {/* ****** Modal Start ****** */}
                    {showModal ? (
                        <ConfirmModal
                            msg={"Delete "}
                            name={"Amenity: " + Amenityname}
                            open={showModal}
                            handleClick={handleModal}
                            handleConfirm={submitDel}
                            handlecloseModal={closeModal}
                        />
                    ) : null}
                    {/* ****** Modal End ****** */}
                    <Modal
                        isOpen={open}
                        toggle={() => setOpen(false)}
                        className="modal-dialog-centered"
                        size="lg"
                    >
                        <ModalBody>
                            <a href="#cancel" className="close">
                                {" "}
                                <Icon
                                    name="cross-sm"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        setOpen(false);
                                        resetForm();
                                    }}
                                ></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title">{edit?"Edit":"Add"} Amenity</h5>
                                <div className="mt-4">
                                    {/* <form onSubmit={ handleSubmit( onFormSubmit ) }> */}
                                    <form
                                        onSubmit={(e) =>
                                            edit
                                                ? editAmenity(e)
                                                : addAmenity(e)
                                        }
                                    >
                                        <Row className="g-3">
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        Amenity
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="amenity"
                                                            value={amenity}
                                                            onChange={(e) =>
                                                                setAmenity(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col size="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        Category
                                                    </label>
                                                    <RSelect
                                                        onChange={(e) =>
                                                            setAmenityCategoryId(
                                                                e.value
                                                            )
                                                        }
                                                        options={
                                                            amenityCategory
                                                        }
                                                        placeholder="Category"
                                                    />
                                                </div>
                                            </Col>

                                            <Col sm="6">
                                                <div className="form-group">
                                                    <label className="form-label">
                                                        Icon
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        <div className="form-file">
                                                            <Input
                                                                type="file"
                                                                accept="image/*"
                                                                id="customFile"
                                                                onChange={(e) =>
                                                                    handleImage(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorMessage && (
                                                        <div className="text-danger">
                                                            {errorMessage}
                                                        </div>
                                                    )}
                                                </div>
                                                {selectedImage && (
                                                    <div>
                                                        <p>Selected Image:</p>
                                                        <div className="position-relative">
                                                            <img
                                                                src={
                                                                    selectedImage
                                                                }
                                                                alt="Selected"
                                                                style={{
                                                                    height: "15rem",
                                                                }}
                                                            />
                                                            <button
                                                                className="btn btn-danger position-absolute top-0 start-0"
                                                                onClick={
                                                                    handleRemove
                                                                }
                                                            >
                                                                <Icon name="trash" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        Created By
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        {createdName}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col size="12" className="d-flex justify-content-end">
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                >
                                                    {/* <Icon className="plus"></Icon> */}
                                                    <span>
                                                    {loading
                                                            ? "Loading.."
                                                            : edit
                                                            ? "Save"
                                                            : "Add Amenity"}
                                                    </span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </Content>
            </React.Fragment>
        </>
    );
};

export default AmenityList;
