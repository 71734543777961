import { Card } from "reactstrap";

/**
 * @typedef {Object} CardsTableProps
 * @property {string} [className] - Additional CSS class names to apply.
 * @property {React.CSSProperties} [style] - Inline styles to apply.
 * @property {boolean} [isSeparate] - Whether to separate the table rows.
 * @property {React.ReactNode} [children] - The content to render within the table.
 */

/**
 * CardsTable component renders a table with various styling options.
 * 
 * @param {CardsTableProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered table.
 */
const CardsTable = ({ className, isSeparate, style, ...props }) => {
  return (
    <div
      className={`nk-tb-list ${isSeparate ? "is-separate" : ""} ${
        className ? className : ""
      }`}
      style={style ?? {}}
    >
      {props?.children ?? <Card></Card>}
    </div>
  );
};

export default CardsTable;
