import React, { createContext, useContext, useState } from "react";
import { ActivityStatus } from "../../../utils/envConfig";
import "../../../api/global-types/types";

/**
 * @typedef {Object} ActivityCompletionPayload
 * @property {string} notes
 * @property {ActivityStatus} status
 */

const ActivityContext = createContext();

export const useActivityFormState = () => useContext( ActivityContext );

export const ActivityProvider = ( { children } ) =>
{
  /**
   * @type {[number, React.Dispatch<React.SetStateAction<number>>]}
   */
  const [ activeTab, setActiveTab ] = useState( 0 );

  const [ formErrors, setFormErrors ] = useState( {
    notes: null,
  } );
  const ROW_HEIGHT = '4rem';
  /**
   * @type {[ActivityCompletionPayload, React.Dispatch<React.SetStateAction<ActivityCompletionPayload>>]}
   */
  const [ activityCompletionPayload, setActivityCompletionPayload ] = useState( {
    notes: "",
    status: ActivityStatus.Complete,
  } );

  /**
   * @type {[Partial<User>, React.Dispatch<React.SetStateAction<Partial<User>>>]}
   */
  const [ activityCreationPayload, setActivityCreationPayload ] = useState( {
    type: null,
    date: null,
    property_list_id: null,
    user_id: null,
    description: null,
  } );

  /**
   * Function to switch the tab of the modal.
   *
   * @param {number} tab - the tab to switch to.
   */
  const switchTab = ( tab ) =>
  {
    if ( activeTab !== tab )
    {
      setActiveTab( tab );
    }
  };
  return (
    <ActivityContext.Provider
      value={ {
        activeTab,
        switchTab,
        activityCompletionPayload,
        setActivityCompletionPayload,
        activityCreationPayload,
        setActivityCreationPayload,
        formErrors,
        setFormErrors,
        ROW_HEIGHT
      } }
    >
      { children }
    </ActivityContext.Provider>
  );
};
