import React from 'react';
import { Link } from 'react-router-dom';
import DealCard from './DealCard';
import CardShimmer from './CardShimmer';
import { Badge } from 'reactstrap';

export default function DealsClosedGrid({
    deals = [],
    fetching = false,
    title = 'Deals Closed This Month',
    noDataText = 'No deals closed this month',
    link = '/list-deal',
    linkText = 'Close A Deal'
}) {
    return (
        <div className="middle-section deal-item-grid ">
            <div className="right-section bg-white h-full card-inner">
                <div className="kanban-title-content">
                    <div className="card-title">
                        <h6 className="title">{title}</h6>
                    </div>

                    <Badge className="text-dark" pill color="outline-light">
                        {deals?.length ?? 0}
                    </Badge>
                </div>

                <div className="deal-card pt-3">
                    {fetching ? (
                        <CardShimmer numberOfCards={5} />
                    ) : deals && deals?.length ? (
                        deals?.map((deal) => (
                            <DealCard key={deal?.id ?? deal?._id} deal={deal} />
                        ))
                    ) : (
                        <div className="flex-1 w-full flex gap-1 items-center justify-center text-neutral-500">
                            <p>{noDataText}</p>
                            <Link
                                to={link}
                                className="underline text-sm text-blue-500"
                            >
                                {linkText}
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
