import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
    UncontrolledDropdown
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import queryString from 'query-string';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';

import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import {
    BlockHeadContent,
    BlockTitle,
    BlockBetween,
    BlockHead,
    Icon,
    Block,
    RSelect,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    DataTableBody,
    PaginationComponent,
    TooltipComponent
} from '../../components/Component';
import { fetchAgents } from '../../redux/actions/user';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
    deleteScheduledHoliday,
    fetchStaffHolidays
} from '../../redux/actions/staffHolidays';
import Loader from '../components/loader/tableLoader';
import { HolidayStates } from '../../utils/envConfig';
import EditOrAddSchedule from './components/EditOrAddSchedule';

const Vacation = () => {
    const axios = useAxiosPrivate();

    const {
        data,
        fetching: loading,
        updating,
        deleting,
        creating,
        error
    } = useSelector((state) => state?.staffHolidays);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Search by agent
    const optAgents = useSelector((state) =>
        state.user?.agents
            ?.filter((agent) => agent?.user?.active)
            ?.map((agent) => ({
                ...agent,
                value: agent.id,
                label: agent.user.first_name + ' ' + agent.user.last_name
            }))
    );

    // Query
    const searchQ = queryString.parse(location?.search);
    const [query, setQuery] = useState({
        ...searchQ,
        limit: searchQ?.limit ?? '10'
    });
    const [vacation, setVacation] = useState(
        query?.vacation
            ? query?.vacation?.map(
                  (item) => new Date(item?.replace(/\./g, '-'))
              )
            : [null, null]
    );

    const [vacationStart, vacationEnd] = vacation;

    const [resumption, setResumption] = useState(
        query?.resumption
            ? query?.resumption?.map(
                  (item) => new Date(item?.replace(/\./g, '-'))
              )
            : [null, null]
    );
    const [resumptionStart, resumptionEnd] = resumption;

    const [selectedAgents, setSelectedAgents] = useState(null);

    // status options
    const statusOptions = Object.values(HolidayStates);

    // Edit and delete
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [editData, setEditData] = useState(null);
    const [selectedCard, setSelectedCard] = useState(null);

    useEffect(() => {
        !loading && !data && dispatch(fetchStaffHolidays(axios));
        !optAgents?.length && dispatch(fetchAgents(axios));

        if (query?.agents) {
            if (Array.isArray(query?.agents)) {
                const selected = query?.agents?.map((item) => {
                    return optAgents?.find(
                        (item) => (item?.id ?? item?._id) === item
                    );
                });

                setSelectedAgents(selected);
            } else {
                const found = optAgents?.find(
                    (item) => (item?.id ?? item?._id) === query?.agents
                );

                found && setSelectedAgents([found]);
            }
        }
    }, []);

    useEffect(() => {
        !error && setOpen(creating);
    }, [creating]);

    useEffect(() => {
        !error && setOpen(updating);
    }, [updating]);

    useEffect(() => {
        !error && setOpenDelete(deleting);
    }, [deleting]);

    useEffect(() => {
        dispatch(fetchStaffHolidays(axios, query));
        navigate(`${location.pathname}?${queryString.stringify(query)}`);
    }, [query]);

    const handleDeleteSchedule = () => {
        if (!selectedCard) {
            return;
        }

        dispatch(deleteScheduledHoliday(axios, selectedCard));
    };

    return (
       
            <React.Fragment>
                <Head title="Vacation Details"></Head>
                <Content>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle>Vacation Information</BlockTitle>
                            </BlockHeadContent>
                            <BlockHeadContent>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <div className="toggle-expand-content">
                                        <ul className="nk-block-tools g-3">
                                            <li className="nk-block-tools-opt">
                                                <Button
                                                    className="toggle btn-icon d-md-none"
                                                    color="primary"
                                                    onClick={() => {
                                                        setEditData(null);
                                                        setOpen(true);
                                                    }}
                                                >
                                                    <Icon name="plus"></Icon>
                                                </Button>
                                                <Button
                                                    className="toggle d-none d-md-inline-flex"
                                                    color="primary"
                                                    onClick={() => {
                                                        setEditData(null);
                                                        setOpen(true);
                                                    }}
                                                >
                                                    <Icon name="plus"></Icon>
                                                    <span>
                                                        Schedule Holiday
                                                    </span>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>
                    <Row className="align-items-center mb-3">
                        <Col sm="4">
                            <RSelect
                                placeholder="Choose agents..."
                                value={selectedAgents}
                                isMulti
                                options={optAgents}
                                onChange={(value) => {
                                    setSelectedAgents(value);
                                    setQuery((prev) => ({
                                        ...prev,
                                        agents: value?.map(
                                            (value) => value?.id ?? value?._id
                                        )
                                    }));
                                }}
                            />
                        </Col>
                        <Col sm="4">
                            <Row>
                                <Col sm="6">
                                    <DatePicker
                                        placeholderText="Select Vacation Range"
                                        startDate={vacationStart}
                                        endDate={vacationEnd}
                                        selectsRange
                                        isClearable
                                        onChange={(dates) => {
                                            const [start, end] = dates;
                                            setVacation(dates);

                                            if (!start && !end) {
                                                setQuery((prev) => ({
                                                    ...prev,
                                                    vacation: undefined
                                                }));
                                            }

                                            if (start && end) {
                                                setQuery((prev) => ({
                                                    ...prev,
                                                    vacation: dates.map(
                                                        (date) =>
                                                            format(
                                                                date,
                                                                'MM.dd.yyyy'
                                                            )
                                                    )
                                                }));
                                            }
                                        }}
                                        className="form-control"
                                        dateFormat="MM/dd/yyyy"
                                    />
                                </Col>
                                <Col sm="6">
                                    <DatePicker
                                        placeholderText="Select Resumption Range"
                                        startDate={resumptionStart}
                                        endDate={resumptionEnd}
                                        selectsRange
                                        isClearable
                                        onChange={(dates) => {
                                            const [start, end] = dates;
                                            setResumption(dates);

                                            if (!start && !end) {
                                                setQuery((prev) => ({
                                                    ...prev,
                                                    resumption: undefined
                                                }));
                                            }

                                            if (start && end) {
                                                setQuery((prev) => ({
                                                    ...prev,
                                                    resumption: dates.map(
                                                        (date) =>
                                                            format(
                                                                date,
                                                                'MM.dd.yyyy'
                                                            )
                                                    )
                                                }));
                                            }
                                        }}
                                        className="form-control"
                                        dateFormat="MM/dd/yyyy"
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col sm="2">
                            <RSelect
                                placeholder="Select Vaction Status"
                                isMulti
                                options={statusOptions?.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                onChange={(value) => {
                                    setQuery((prev) => ({
                                        ...prev,
                                        status: value?.map(
                                            (item) => item?.value
                                        )
                                    }));
                                }}
                            />
                        </Col>
                        <Col sm="2">
                            <div className="d-flex flex-row-reverse align-items-center justify-content-between">
                                {/* Entries per page config */}
                                <div className="d-flex align-items-center">
                                    <span className="d-none d-sm-inline-block me-2">
                                        Show
                                    </span>
                                    <div className="form-control-select">
                                        <select
                                            name="DataTables_Table_0_length"
                                            className="custom-select custom-select-sm form-control form-control-sm"
                                            value={query?.limit}
                                            onChange={(e) =>
                                                setQuery((prev) => ({
                                                    ...prev,
                                                    limit: e.target.value
                                                }))
                                            }
                                        >
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                        </select>
                                    </div>
                                </div>
                                {/* Search bar */}
                            </div>
                        </Col>
                    </Row>
                    <Block>
                        <div className="nk-tb-list is-separate is-medium mb-3">
                            <DataTableBody compact>
                                <DataTableHead className="nk-tb-item">
                                    <DataTableRow>
                                        <span className="sub-text">
                                            Agent Name
                                        </span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">
                                            Vacation Start Date
                                        </span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">
                                            Resumption Date
                                        </span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Status</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">
                                            Options
                                        </span>
                                    </DataTableRow>
                                </DataTableHead>
                                {loading ? (
                                    <Loader />
                                ) : (
                                    <>
                                        {data?.result?.map((item) => (
                                            <DataTableItem
                                                key={item?.id ?? item?._id}
                                            >
                                                <DataTableRow>
                                                    {`${item?.agent?.user?.first_name}  ${item?.agent?.user?.last_name}`}
                                                </DataTableRow>
                                                <DataTableRow>
                                                    {format(
                                                        new Date(
                                                            item?.startDate
                                                        ),
                                                        'dd MMM yyyy'
                                                    )}
                                                </DataTableRow>
                                                <DataTableRow>
                                                    {format(
                                                        new Date(item?.endDate),
                                                        'dd MMM yyyy'
                                                    )}
                                                </DataTableRow>
                                                <DataTableRow>
                                                    {item?.state}
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <UncontrolledDropdown
                                                        disabled={
                                                            item?.state ===
                                                                HolidayStates.EXPIRED ||
                                                            item?.state ===
                                                                HolidayStates.ON_HOLIDAY
                                                        }
                                                    >
                                                        <DropdownToggle
                                                            tag="a"
                                                            className="dropdown-toggle btn btn-icon btn-trigger"
                                                        >
                                                            {item?.state ===
                                                                HolidayStates.EXPIRED ||
                                                            item?.state ===
                                                                HolidayStates.ON_HOLIDAY ? (
                                                                <TooltipComponent
                                                                    tag="span"
                                                                    containerClassName="px-2"
                                                                    id={
                                                                        'vacation' +
                                                                        (item?.id ??
                                                                            item?._id)
                                                                    }
                                                                    icon="more-h"
                                                                    direction="top"
                                                                    text={`Cannot edit/delete item on status: ${item?.state}`}
                                                                />
                                                            ) : (
                                                                <Icon
                                                                    name="more-h"
                                                                    style={{
                                                                        fontSize:
                                                                            '1.3rem'
                                                                    }}
                                                                />
                                                            )}
                                                        </DropdownToggle>
                                                        <DropdownMenu center="true">
                                                            <ul className="link-check">
                                                                <li>
                                                                    <DropdownItem
                                                                        tag="a"
                                                                        href="#dropdownitem"
                                                                        onClick={(
                                                                            ev
                                                                        ) => {
                                                                            ev.preventDefault();
                                                                            setEditData(
                                                                                item
                                                                            );
                                                                            setOpen(
                                                                                (
                                                                                    prev
                                                                                ) =>
                                                                                    true
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name="edit"
                                                                            className={
                                                                                'text-primary'
                                                                            }
                                                                        />
                                                                        <span>
                                                                            Edit
                                                                        </span>
                                                                    </DropdownItem>
                                                                </li>
                                                                <li>
                                                                    <DropdownItem
                                                                        tag="a"
                                                                        href="#dropdownitem"
                                                                        onClick={(
                                                                            ev
                                                                        ) => {
                                                                            ev.preventDefault();
                                                                            setOpenDelete(
                                                                                (
                                                                                    prev
                                                                                ) =>
                                                                                    true
                                                                            );
                                                                            setSelectedCard(
                                                                                item
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name="trash"
                                                                            className="text-danger"
                                                                        />
                                                                        <span>
                                                                            Delete
                                                                        </span>
                                                                    </DropdownItem>
                                                                </li>
                                                            </ul>
                                                            {/* <ul className="link-check">
                                                            <li>
                                                                <span>
                                                                    Order
                                                                </span>
                                                            </li>
                                                        </ul> */}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </DataTableRow>
                                            </DataTableItem>
                                        ))}
                                    </>
                                )}
                            </DataTableBody>

                            {!loading && data?.filteredCount ? (
                                <div className="card-inner">
                                    {data?.filteredCount >
                                        data?.itemsPerPage && (
                                        <PaginationComponent
                                            itemPerPage={data?.itemsPerPage}
                                            totalItems={data?.filteredCount}
                                            paginate={(pgNum) => {
                                                setQuery((prev) => ({
                                                    ...prev,
                                                    page: pgNum
                                                }));
                                            }}
                                            currentPage={data?.pageNumber}
                                        />
                                    )}
                                </div>
                            ) : null}
                            {!loading && !data?.filteredCount && (
                                <div className="text-center p-3 bg-white">
                                    <span className="text-silent">
                                        No data found
                                    </span>
                                </div>
                            )}
                        </div>
                    </Block>
                    <Modal
                        isOpen={open}
                        toggle={() => setOpen((prev) => !prev)}
                        className="modal-dialog-centered"
                        backdrop="static"
                        // size="lg"
                    >
                        <ModalBody>
                            <EditOrAddSchedule
                                onCancel={(e) => {
                                    setOpen((prev) => !prev);
                                    setEditData(null);
                                }}
                                loading={
                                    (open && creating) || (open && updating)
                                }
                                editData={editData}
                            />
                        </ModalBody>
                    </Modal>

                    <Modal
                        isOpen={openDelete}
                        toggle={() => setOpenDelete((prev) => !prev)}
                        className="modal-dialog-centered"
                        backdrop="static"
                        // size="lg"
                    >
                        <ModalHeader>
                            Delete{' '}
                            <span className="text-decoration-underline">
                                {selectedCard?.agent?.user?.first_name}
                            </span>
                            's Schedule?
                        </ModalHeader>
                        <ModalBody>
                            <p>
                                Are you sure you want to delete this schedule?
                            </p>
                            <Col
                                size="12"
                                className="d-flex justify-content-end mt-3"
                            >
                                <Button
                                    color="dark"
                                    type="button"
                                    className="me-2"
                                    onClick={() => {
                                        setOpenDelete((prev) => !prev);
                                        setSelectedCard(null);
                                    }}
                                    disabled={deleting}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    color="danger"
                                    className="ms-2"
                                    disabled={deleting}
                                    onClick={handleDeleteSchedule}
                                >
                                    {deleting ? (
                                        <Spinner size="sm" />
                                    ) : (
                                        <span>Yes. Delete</span>
                                    )}
                                </Button>
                            </Col>
                        </ModalBody>
                    </Modal>
                </Content>
            </React.Fragment>
        
    );
};

export default Vacation;
