import axios from 'axios';
import * as types from '../types';

export const fetchLeadSource = (data) => ({
    type: types.GET_LEAD_SOURCE,
    payload: data,
});

export const fetchLeadSourceData = ({ page, limit, search }) => async (dispatch) => {
    try {
        const url = `/leadsource/`;
        const params = {
            page,
            limit,
            search,
        };

        const res = await axios.get(url, { params });
        dispatch(fetchLeadSource(res.data || {}));
    } catch (error) {
        console.error("Error fetching leadsource data:", error);
    }
};
