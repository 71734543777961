import * as types from '../types/property-cards';

const initialState = {
    propertyCards: [],
    propertyCard: null,
    checkedoutCards: [],
    cardLeads: [],
    loading: false,
    checkedoutLoading: false,
    error: null,
    checkedoutError: null,
    leadsWithCards: {
        leadsWithCards: null,
        fetchingLeadsWithCards: false,
        error: null
    }
};

export const propertyCard = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PROPERTY_CARDS_STARTED:
            return {
                ...state,
                loading: true,
                error: null
            };
        case types.GET_PROPERTY_CARDS:
            return {
                ...state,
                propertyCards: action.payload,
                loading: false
            };
        case types.GET_PROPERTY_CARDS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case types.GET_PROPERTY_CARDS_FINISHED:
            return {
                ...state,
                loading: false
            };
        case types.GET_LEAD_PROPERTY_CARDS:
            return {
                ...state,
                propertyCards: action.payload,
                loading: false,
                error: null
            };
        case types.GET_PROPERTY_CARD:
            return {
                ...state,
                propertyCards: action.payload,
                loading: false,
                error: null
            };
        case types.GET_PROPERTY_CARD_LEADS:
            return {
                ...state,
                cardLeads: action.payload,
                loading: false,
                error: action.error
            };
        case types.GET_CHECKEDOUT_PROPERTIES_STARTED:
            return {
                ...state,
                checkedoutLoading: true
            };
        case types.GET_CHECKEDOUT_PROPERTIES:
            return {
                ...state,
                checkedoutLoading: false,
                error: null,
                checkedoutCards: action.payload
            };
        case types.GET_CHECKEDOUT_PROPERTIES_FAILED:
            return {
                ...state,
                checkedoutError: action.error,
                checkedoutLoading: false
            };
        case types.UPDATE_PROPERTY_CARD_STARTED:
            return {
                ...state,
                loading: true,
                error: null
            };
        case types.UPDATE_PROPERTY_CARD:
            return {
                ...state,
                loading: false,
                error: null,
                propertyCard: action.payload,
                status: action.status
            };
        case types.UPDATE_PROPERTY_CARD_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        // fetching cards with leads
        case types.GET_LEADS_WITH_CARDS:
            const { data, error, fetching } = action?.payload;
            
            if (fetching) {
                return {
                    ...state,
                    leadsWithCards: {
                        ...state.leadsWithCards,
                        fetchingLeadsWithCards: true
                    }
                };
            } else if (error) {
                return {
                    ...state,
                    leadsWithCards: {
                        ...state.leadsWithCards,
                        error,
                        fetchingLeadsWithCards: false
                    }
                };
            } else if (data) {
                return {
                    ...state,
                    leadsWithCards: {
                        ...state.leadsWithCards,
                        error: null,
                        fetchingLeadsWithCards: false,
                        leadsWithCards: data
                    }
                };
            }
        default:
            return state;
    }
};
