import React, { useState } from "react";
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { Icon, RSelect } from "../../../components/Component";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useDragAndDrop from "../../../utils/hooks/useDragAndDrop";
import uuid from "react-uuid";
import { uploadManyToS3 } from "../../../utils/envConfig";
import { useNavigate } from "react-router-dom";

const AddDeveloperModal = ( { isOpen, toggle=()=>{}, callback, props  } ) =>
{
    const axios = useAxiosPrivate();
    const navigate = useNavigate();

    const [ errorMessage, setErrorMessage ] = useState( '' );
    const [ selectedImage, setSelectedImage ] = useState( null );
    const [ selectedBackgroundImage, setSelectedBackgroundImage ] = useState( null );
    const [ loading, setLoading ] = useState( false );
    const handleImageChange = ( event ) =>
    {
        const file = event.target.files[ 0 ];
        setSelectedImage( URL.createObjectURL( file ) );
    };
    const handleBackgroundImageChange = ( event ) =>
    {
        const file = event.target.files[ 0 ];
        setSelectedBackgroundImage( URL.createObjectURL( file ) );
    };
    const { errors, register, handleSubmit } = useForm();

    const [newDeveloper, setNewDeveloper] = useState({});

    const {
        active: newDeveloperActive,
        handleFileChange: newDeveloperFileChange,
        files: newDeveloperFiles,
    } = useDragAndDrop({
        inputType: "image",
    });

    const {
        active: backgroundImageActive,
        handleFileChange: backgroundImageFileChange,
        files: backgroundImageFiles,
    } = useDragAndDrop({
        inputType: "image",
    });

    const handleDeveloperChange = (e) => {
        const { name, value } = e.target;
        setNewDeveloper((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleAddNewDeveloper = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            if (
                !newDeveloper.description ||
                !newDeveloper.headerTitle ||
                !newDeveloperFiles[0]?.file?.name ||
                !backgroundImageFiles[0]?.file?.name ||
                !newDeveloper.name
            )
                return toast.error("Please fill all fields");

            const logoName = `offPlans/developer/${uuid()}.${newDeveloperFiles[0]?.file?.name
                ?.split(".")
                ?.pop()}`;

            const bgImageName = `offPlans/developer/${uuid()}.${backgroundImageFiles[0]?.file?.name
                ?.split(".")
                ?.pop()}`;

            const payload = [
                {
                    file: newDeveloperFiles[0]?.file,
                    type: "image/*",
                    fileName: logoName,
                },
                {
                    file: backgroundImageFiles[0]?.file,
                    type: "image/*",
                    fileName: bgImageName,
                },
            ];

            const s3res = await uploadManyToS3(payload,axios);

            if (s3res) {
                const uploadData = {
                    name: newDeveloper.name,
                    description: newDeveloper.description,
                    headerTitle: newDeveloper.headerTitle,
                    logo: logoName,
                    backgroundImage: bgImageName,
                };

               const {data} = await axios.post(
                    `off-plan/plan-developer`,
                    uploadData
                );
                
                setLoading(false)
                
                if (callback && typeof callback === 'function') {
                    callback(data);
                } else {
                    navigate(0);
                }

                toast.success("Added developer successfully");
                toggle();
                
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            toast.error("Unexpected error. Failed to add developer");
        }
      
    };
    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop="static">
            <ModalHeader
                toggle={toggle}
                close={
                    <button
                        className="close"
                        onClick={(ev) => {
                            ev.preventDefault();
                            toggle();
                        }}
                    >
                        <Icon name="cross" />
                    </button>
                }
            >
                Add New Developer
            </ModalHeader>
            <ModalBody>
                <form id="add-developer-form">
                    <Row className="g-3">
                        <Col sm="12">
                            <Label>Developer Name</Label>
                            <Input
                                name="name"
                                value={newDeveloper.name}
                                onChange={(e) => handleDeveloperChange(e)}
                                placeholder="Enter Developer Name"
                            ></Input>
                        </Col>
                        <Col sm="12">
                            <Label>Short Header Title</Label>
                            <Input
                                name="headerTitle"
                                value={newDeveloper.headerTitle}
                                onChange={(e) => handleDeveloperChange(e)}
                                placeholder="Enter Short Header Title"
                            />
                        </Col>
                        <Col sm="6">
                            <div className="form-group">
                                <label className="form-label">
                                    Developer Logo{' '}
                                </label>
                                <div className="form-control-wrap">
                                    <div className="form-file">
                                        <Input
                                            type="file"
                                            id="customFile"
                                            accept="image/*"
                                            name="image"
                                            onChange={newDeveloperFileChange}
                                        />
                                    </div>
                                    {errorMessage && (
                                        <div className="text-danger">
                                            {errorMessage}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {newDeveloperFiles?.length > 0 && (
                                <div className="position-relative">
                                    <img
                                        src={URL.createObjectURL(
                                            newDeveloperFiles[0]?.file
                                        )}
                                        alt="Selected"
                                    />
                                    {/* <Button className="bg-danger position-absolute" style={ { top: "0", left: "0" } }>
                                        <Icon name="trash"></Icon>
                                    </Button> */}
                                </div>
                            )}
                        </Col>
                        <Col sm="6">
                            <div className="form-group">
                                <label className="form-label">
                                    Background Image{' '}
                                </label>
                                <div className="form-control-wrap">
                                    <div className="form-file">
                                        <Input
                                            type="file"
                                            id="customFile"
                                            accept="image/*"
                                            name="image"
                                            onChange={backgroundImageFileChange}
                                        />
                                    </div>
                                    {errorMessage && (
                                        <div className="text-danger">
                                            {errorMessage}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {backgroundImageFiles?.length > 0 && (
                                <div className="position-relative">
                                    <img
                                        src={URL.createObjectURL(
                                            backgroundImageFiles[0]?.file
                                        )}
                                        alt="Selected"
                                    />
                                    {/* <Button className="bg-danger position-absolute" style={ { top: "0", left: "0" } }>
                                        <Icon name="trash"></Icon>
                                    </Button> */}
                                </div>
                            )}
                        </Col>
                        <Col sm="12">
                            <div className="form-group">
                                <Label
                                    className="form-label"
                                    htmlFor="fv-message"
                                >
                                    Description
                                </Label>
                                <div className="form-control-wrap">
                                    <textarea
                                        type="textarea"
                                        className="form-control form-control-sm"
                                        rows="5"
                                        placeholder="Describe Developer"
                                        name="description"
                                        id="developerDesc"
                                        onChange={handleDeveloperChange}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="actions clearfix">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
                            <li>
                                <Button
                                    color="secondary"
                                    type="button"
                                    onClick={handleAddNewDeveloper}
                                >
                                    {!loading ? (
                                        <>
                                            <Icon
                                                name="plus"
                                                className="me-1"
                                            ></Icon>
                                            Add Developer
                                        </>
                                    ) : (
                                        <Spinner size="sm" color="light" />
                                    )}
                                </Button>
                            </li>
                        </ul>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};
export default AddDeveloperModal;