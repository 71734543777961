// PROPERTY CARDS TYPES
export const GET_PROPERTY_CARDS = 'GET_PROPERTY_CARDS';
export const GET_PROPERTY_CARDS_STARTED = 'GET_PROPERTY_CARDS_STARTED';
export const GET_PROPERTY_CARDS_FAILED = 'GET_PROPERTY_CARDS_FAILED';
export const GET_PROPERTY_CARDS_FINISHED = 'GET_PROPERTY_CARDS_FINISHED';

export const GET_LEAD_PROPERTY_CARDS = 'GET_LEAD_PROPERTY_CARDS';

export const GET_PROPERTY_CARD = 'GET_PROPERTY_CARD';

export const GET_PROPERTY_CARD_LEADS = 'GET_PROPERTY_CARD_LEADS';

export const GET_CHECKEDOUT_PROPERTIES = 'GET_CHECKEDOUT_PROPERTIES';
export const GET_CHECKEDOUT_PROPERTIES_STARTED =
    'GET_CHECKEDOUT_PROPERTIES_STARTED';
export const GET_CHECKEDOUT_PROPERTIES_FAILED =
    'GET_CHECKEDOUT_PROPERTIES_FAILED';

export const UPDATE_PROPERTY_CARD_STARTED = 'UPDATE_PROPERTY_CARD_STARTED';
export const UPDATE_PROPERTY_CARD = 'UPDATE_PROPERTY_CARD_FINISHED';
export const UPDATE_PROPERTY_CARD_FAILED = 'UPDATE_PROPERTY_CARD_FAILED';

export const GET_LEADS_WITH_CARDS = 'GET_LEADS_WITH_CARDS';
