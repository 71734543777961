import React from "react";

const EditableBorderedListCard = ({ data = [[]], toggleEdit }) => {
  /**
   * renders a bordered list of items in a card, with a toggle function to 
   * allow a different edit view to be rendered when neeeded
   * data is an array of arrays, each entry has two fields, label and value
   */
  return (
    <div className="card card-bordered">
      <ul className="data-list is-compact">
        {data.map(([key, value], idx) => (
          <li className="data-item" key={`card-item-${idx}`}>
            <div className="data-col">
              <div className="data-label" onDoubleClick={() => toggleEdit()}>{key}</div>
              <div className="data-value" onDoubleClick={() => toggleEdit()}>{value}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EditableBorderedListCard;
