import classNames from "classnames";

/**
 * @typedef {Object} DropdownSectionTitleProps
 * @property {string} [title] - The section's title.
 * @property {boolean} [isClearable] - Whether the section should have a "clear" option or not.
 * @property {function} [clearFn] - The function to run on "clear".
 * @property {string?} [customClearText] - Text to optionally use instead of "clear".
 * @property {string} [className] - Additional CSS class names to apply.
 * @property {React.CSSProperties} [style] - Inline styles to apply.
 */

/**
 * DropdownSectionTitle component a title (and optional "clear" option for a dropdown section).
 *
 * @param {DropdownSectionTitleProps} props - The properties for the component.
 * @returns {JSX.Element}
 */
const DropdownSectionTitle = ({ 
    title, 
    isClearable, 
    clearFn,
    customClearText,
    className, 
    style, 
}) => {
  const parsedClassName = classNames({
    "d-flex": true,
    "justify-content-between": true,
    [`${className}`]: className,
  });
  return (
    <div className={parsedClassName} style={style ?? {}}>
      <span className="sub-title dropdown-title">{title ?? "Section"}</span>
      {isClearable ? <span
        className="text-muted cursor-pointer"
        onClick={clearFn && typeof clearFn === 'function' ? clearFn : () => {}}
      >
        {customClearText ?? 'Clear'}
      </span> : null}
    </div>
  );
};

export default DropdownSectionTitle;
