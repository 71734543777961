import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";

const ConfirmationModal = ({
  title,
  isOpen,
  toggleFn,
  actionFn,
  isDeletion = false,
  dimButtons = false,
  loadingState = false,
  disableConfirm = false,
  size = "md",
  children,
  confirmBtnLabel="Yes",
  cancelBtnLabel="No"
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleFn} size={size}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button className={`btn btn-dim bg-gray ${dimButtons ? 'border-dark' : 'border-dim'}`} onClick={toggleFn}>
          {cancelBtnLabel}
        </Button>
        <Button
          color={`${isDeletion ? "danger" : "primary"}`}
          className={`${dimButtons ? 'btn btn-dim' : 'btn'} ${isDeletion ? "border border-danger" : "border border-primary"}`}
          onClick={actionFn}
          disabled={loadingState || disableConfirm}
        >
          {loadingState ? <Spinner size={'sm'} /> : confirmBtnLabel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
