import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { ProductivityChart } from './ProductivityChart';
import {
    Alert,
    Button,
    Card,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledDropdown
} from 'reactstrap';

import {
    addDays,
    addMonths,
    differenceInDays,
    endOfWeek,
    getDay,
    getMonth,
    getYear,
    isValid,
    startOfWeek,
    subDays,
    subMonths
} from 'date-fns';

import { Icon, UserAvatar } from '../../../components/Component';
import { findUpper } from '../../../utils/Utils';
import DatePicker from 'react-multi-date-picker';
import ReactDatePicker from 'react-datepicker';
import { formatDate, getImage } from '../../../utils/envConfig';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { fetchAgents } from '../../../redux/actions/user';
import { debounce } from 'lodash';

const defaultLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

const monthsArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec'
];

const graphColors = [
    {
        color: '#9d72ff',
        backgroundColor: 'rgba(157, 114, 255, 0.15)'
    },
    {
        color: '#eb6459',
        backgroundColor: 'rgba(235, 100, 89, 0.3)'
    },
    {
        color: 'rgb(25, 250, 133)',
        backgroundColor: 'rgba(23, 250, 133, 0.3)'
    }
    // {
    //     color: '#FF9900',
    //     backgroundColor: 'rgba(255, 153, 0, 0.2)'
    // },
    // {
    //     color: '#311CAC',
    //     backgroundColor: 'rgba(49, 28, 172, 0.2)'
    // }
];

const MAX_AGENTS = 3;

export default function AgentsProductivity() {
    const dispatch = useDispatch();
    const axios = useAxiosPrivate();

    const agents = useSelector((state) => state?.user?.agents);

    const [data, setData] = useState('Current  Week');

    const today = new Date();

    const [showModal, setShowModal] = useState(false);

    const [selectionError, setSelectionError] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const [selectedAgents, setSelectedAgents] = useState(agents?.slice(0, 3));
    const [appliedAgents, setAppliedAgents] = useState(agents?.slice(0, 3));

    const [allAgents, setAllAgents] = useState(agents);

    const [searchAgentText, setSearchAgentText] = useState('');

    const [showSelectDate, setShowSelectDate] = useState(false);

    const [weekStart, setWeekStart] = useState(startOfWeek(today));
    const [weekEnd, setWeekEnd] = useState(endOfWeek(today));
    const [weeks, setWeeks] = useState();

    const [months, setMonths] = useState(null);

    // productivity data
    const [agentsProductivity, setAgentsProductivity] = useState(null);

    const [isWeekly, setisWeekly] = useState(
        true /*Might want to change in future so we load monthly data instead of daily data */
    );

    const filteredAgents = allAgents?.filter((agent) => {
        const isName =
            agent?.user?.first_name
                ?.toLowerCase()
                ?.includes(searchAgentText?.toLowerCase()) ||
            agent?.user?.last_name
                ?.toLowerCase()
                ?.includes(searchAgentText?.toLowerCase());

        const isPhone = agent?.user?.phone
            ?.toLowerCase()
            ?.includes(searchAgentText?.toLowerCase());

        const isEmail = agent?.user?.phone
            ?.toLowerCase()
            ?.includes(searchAgentText?.toLowerCase());

        return isName || isEmail || isPhone;
    });

    const sendRequest = useCallback(async (value) => {
        try {
            if (filteredAgents?.length) {
                // if filtered agents length, then we still have agent options
                // or still we can make the function fetch from db if we have a certain number of options only (eg: 2)
                // we're limiting to only make calls to the backend if we don't have options in the frontend
                return;
            }
        } catch (err) {
            console.log(err);
        } finally {
            // setIsTyping(false);
        }
    }, []);

    // memoize the debounce call with useMemo
    const debouncedSendRequest = useMemo(() => {
        return debounce(sendRequest, 1000);
    }, [sendRequest]);

    const updateLabels = () => {
        const dayOfWeek = getDay(weekStart);

        switch (dayOfWeek) {
            case 0:
                return defaultLabels;
            case 1:
                return ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];
            case 2:
                return ['Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun', 'Mon'];
            case 3:
                return ['Wed', 'Thur', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue'];
            case 4:
                return ['Thur', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed'];
            case 5:
                return ['Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thur'];
            case 6:
                return ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri'];

            default:
                return defaultLabels;
        }
    };

    function randomIntFromInterval(min, max) {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const toggleSelectDate = () => {
        setShowSelectDate((val) => (val === true ? false : true));
    };

    // get default agents (if any)
    const getDefaulAgents = () => {
        try {
            const defaultAgents = localStorage.getItem(
                'default_agent_productivity'
            );

            if (defaultAgents) {
                const parsedAgents = JSON.parse(defaultAgents);
                setSelectedAgents(parsedAgents);
                setAppliedAgents(parsedAgents);

                return;
            }
            agents?.length && setSelectedAgents(agents?.slice(0, 3));
            agents?.length && setAppliedAgents(agents?.slice(0, 3));
        } catch (err) {
            console.log(err);
        }
    };

    // get productivity data
    const getProductivityData = async () => {
        try {
            const startDate = formatDate(weekStart);
            const endDate = formatDate(weekEnd);

            const agentsQuery = appliedAgents
                ?.map((agent) => `agents[]=${agent?.id}`)
                ?.join('&');

            const { data } = await axios.get(
                `/reports/agents-productivity?report_type=${
                    isWeekly ? 'weekly' : 'monthly'
                }&${
                    isWeekly
                        ? `from_date=${startDate}&to_date=${endDate}`
                        : `${months
                              ?.map((month) => `months[]=${month}`)
                              ?.join('&')}`
                }&${agentsQuery}`
            );

            setAgentsProductivity(
                isWeekly
                    ? data
                    : data?.map((data) => {
                          const sorted = data?.data?.sort(
                              (a, b) => a?.date?.year - b?.date?.year
                          );

                          return {
                              ...data,
                              data: sorted
                          };
                      })
            );
        } catch (err) {
            console.log(err);
        }
    };

    const getDayName = (date, locale = 'en-US') => {
        if (!isValid(date)) {
            console.log('Invalid date instance');
            throw new Error('Please pass valid date instance');
        }

        return date.toLocaleDateString(locale, {
            month: 'short',
            day: '2-digit'
        });
    };

    useEffect(() => {
        appliedAgents?.length && getProductivityData();
    }, [appliedAgents, weekStart, months]);

    useEffect(() => {
        !agents?.length && dispatch(fetchAgents(axios));
    }, []);

    useEffect(() => {
        getDefaulAgents();
        setAllAgents(agents);
    }, [agents]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSelectionError('');
        }, 3000);

        return () => {
            clearTimeout(timeout);
        };
    }, [selectionError]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAlertMessage('');
        }, 3000);

        return () => {
            clearTimeout(timeout);
        };
    }, [alertMessage]);

    return (
        <Card>
            <div className="nk-ecwg nk-ecwg8 h-100">
                <div className="card-inner">
                    <div className="card-title-group mb-3">
                        <div className="card-title">
                            <h6 className="title">Agents Productivity</h6>
                        </div>
                        <div className="card-title-group">
                            <span
                                className="me-3 link link-light link-sm"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setShowModal(true)}
                            >
                                Select Agent
                            </span>
                            <div className="card-tools">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        tag="a"
                                        href="#toggle"
                                        onClick={(ev) => ev.preventDefault()}
                                        className="link link-light link-sm dropdown-indicator"
                                    >
                                        {data}
                                    </DropdownToggle>
                                    <DropdownMenu
                                        end
                                        className="dropdown-menu-sm"
                                    >
                                        <ul className="link-list-opt no-bdr link-check">
                                            <li className="text-center">
                                                <span>Daily</span>
                                            </li>

                                            <li>
                                                <DropdownItem
                                                    tag="a"
                                                    href="#dropdown"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        if (
                                                            data ===
                                                            'Current Week'
                                                        )
                                                            return;
                                                        setData('Current Week');
                                                        // set dates
                                                        setWeekStart(
                                                            startOfWeek(today)
                                                        );
                                                        setWeekEnd(
                                                            endOfWeek(today)
                                                        );
                                                        setisWeekly(true);
                                                    }}
                                                    className={
                                                        data === 'Current Week'
                                                            ? 'active'
                                                            : ''
                                                    }
                                                >
                                                    <span>Current Week</span>
                                                </DropdownItem>
                                            </li>
                                            <li>
                                                <DropdownItem
                                                    tag="a"
                                                    href="#dropdown"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        if (
                                                            data === 'Last Week'
                                                        )
                                                            return;
                                                        setData('Last Week');
                                                        // set dates
                                                        const lastWeekEnd =
                                                            subDays(
                                                                startOfWeek(
                                                                    today
                                                                ),
                                                                1
                                                            );

                                                        setWeekStart(
                                                            startOfWeek(
                                                                lastWeekEnd
                                                            )
                                                        );
                                                        setWeekEnd(
                                                            endOfWeek(
                                                                lastWeekEnd
                                                            )
                                                        );
                                                        setisWeekly(true);
                                                    }}
                                                    className={
                                                        data === 'Last Week'
                                                            ? 'active'
                                                            : ''
                                                    }
                                                >
                                                    <span>Last Week</span>
                                                </DropdownItem>
                                            </li>
                                            <li className="text-center">
                                                <span>Monthly</span>
                                            </li>
                                            <li>
                                                <DropdownItem
                                                    tag="a"
                                                    href="#dropdown"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        if (
                                                            data ===
                                                            'Last 6 Months'
                                                        ) {
                                                            return;
                                                        }

                                                        setData(
                                                            'Last 6 Months'
                                                        );
                                                        // set dates
                                                        let startMonth =
                                                            subMonths(today, 5);
                                                        const endMonth = today;

                                                        let monthsArray = [];

                                                        while (
                                                            getMonth(
                                                                endMonth
                                                            ) >=
                                                            getMonth(startMonth)
                                                        ) {
                                                            const month =
                                                                getMonth(
                                                                    startMonth
                                                                ) + 1;

                                                            monthsArray.push(
                                                                `${
                                                                    month >= 10
                                                                        ? month
                                                                        : `0${month}`
                                                                }-01-${getYear(
                                                                    startMonth
                                                                )}`
                                                            );

                                                            startMonth =
                                                                addMonths(
                                                                    startMonth,
                                                                    1
                                                                );
                                                        }

                                                        setMonths(monthsArray);
                                                        setisWeekly(false);
                                                    }}
                                                    className={
                                                        data === 'Last 6 Months'
                                                            ? 'active'
                                                            : ''
                                                    }
                                                >
                                                    <span>Last 6 Months</span>
                                                </DropdownItem>
                                            </li>
                                            <li className="text-center">
                                                <span>Customize</span>
                                            </li>
                                            <li>
                                                <DropdownItem
                                                    tag="a"
                                                    href="#dropdown"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        toggleSelectDate();
                                                        // we want to open modal for date/month selection
                                                    }}
                                                    className={
                                                        data === 'Custom'
                                                            ? 'active'
                                                            : ''
                                                    }
                                                >
                                                    <span>Custom</span>
                                                </DropdownItem>
                                            </li>
                                        </ul>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </div>
                    </div>
                    <ul className="nk-ecwg8-legends">
                        {appliedAgents
                            ?.slice(0, MAX_AGENTS)
                            ?.map((agent, i) => (
                                <li key={agent?.id + `${i}`}>
                                    <div className="title ccap">
                                        <span
                                            className="dot dot-lg sq"
                                            style={{
                                                background:
                                                    graphColors[i]?.color
                                            }}
                                        ></span>
                                        <span>
                                            {agent?.user?.first_name}{' '}
                                            {agent?.user?.last_name}
                                        </span>
                                    </div>
                                </li>
                            ))}
                    </ul>
                    <div className="nk-ecwg8-ck">
                        <ProductivityChart
                            data={{
                                labels: agentsProductivity
                                    ? agentsProductivity[0]?.data?.map((item) =>
                                          isWeekly
                                              ? isValid(new Date(item?.date))
                                                  ? getDayName(
                                                        new Date(item?.date)
                                                    )
                                                  : item?.date
                                              : `${
                                                    monthsArray[
                                                        item?.date?.month
                                                    ]
                                                }-${item?.date?.year}`
                                      )
                                    : updateLabels(),
                                datasets: agentsProductivity
                                    ? agentsProductivity
                                          ?.slice(0, MAX_AGENTS)
                                          ?.map((agent, i) => {
                                              const foundAgent =
                                                  appliedAgents?.find(
                                                      (agnt) =>
                                                          agnt?.id ===
                                                          agent?.agent
                                                  );

                                              return {
                                                  label: foundAgent?.user
                                                      ?.first_name,
                                                  data:
                                                      agent?.data?.map(
                                                          (item) => item?.count
                                                      ) ??
                                                      defaultLabels.map(() =>
                                                          randomIntFromInterval(
                                                              10000,
                                                              50000
                                                          )
                                                      ),
                                                  color: graphColors[i]?.color,
                                                  backgroundColor:
                                                      graphColors[i]
                                                          ?.backgroundColor
                                              };
                                          })
                                    : []
                            }}
                            agents={appliedAgents}
                        />
                    </div>
                    {/* <div className="chart-label-group ps-5">
                        <div className="chart-label">01 Jul, 2020</div>
                        <div className="chart-label">
                            {data === "Daily"
                                ? "02"
                                : data === "Weekly"
                                ? "07"
                                : "30"}{" "}
                            Jul, 2020
                        </div>
                    </div> */}
                </div>
            </div>

            <Modal
                isOpen={showModal}
                toggle={() => setShowModal(!showModal)}
                className="position-relative"
                backdrop="static"
            >
                <ModalHeader
                    toggle={() => setShowModal(!showModal)}
                    close={
                        <button
                            className="close"
                            onClick={() => setShowModal(!showModal)}
                        >
                            <Icon name="cross" />
                        </button>
                    }
                    className="position-relative"
                >
                    {alertMessage && (
                        <Alert
                            color="danger"
                            className="text-center position-absolute start-50  translate-middle fs-14px w-100 fw-light text-danger"
                        >
                            {alertMessage}
                        </Alert>
                    )}
                </ModalHeader>
                <ModalBody>
                    <div className="text-end text-danger">
                        <Icon
                            name={'trash'}
                            className={'cursor-pointer'}
                            onClick={() => {
                                setSelectedAgents([]);
                            }}
                        />
                        <span
                            className="cursor-pointer"
                            onClick={() => {
                                setSelectedAgents([]);
                            }}
                        >
                            Deselect All
                        </span>
                    </div>
                    <div className="py-1">
                        <Input
                            type="text"
                            placeholder="Find an agent"
                            className="fs-14px"
                            value={searchAgentText}
                            onChange={(e) =>
                                setSearchAgentText(e?.target?.value)
                            }
                        />
                    </div>
                    <div className="d-flex flex-column h-400px overflow-scroll">
                        {filteredAgents?.map((agent, index) => {
                            const isAdded = selectedAgents?.find(
                                (agnt) => agnt?.id === agent?.id
                            );

                            return (
                                <div
                                    key={`${agent?.id}-${index}`}
                                    className={`d-flex align-items-center gap-3 border shadow-sm px-3 py-5 my-1 rounded cursor-pointer ${
                                        isAdded
                                            ? 'bg-light texwhite'
                                            : 'bg-white'
                                    }`}
                                    onClick={() => {
                                        const isAdded = selectedAgents?.find(
                                            (agnt) => agnt?.id === agent?.id
                                        );

                                        if (isAdded) {
                                            setSelectedAgents(
                                                selectedAgents?.filter(
                                                    (agnt) =>
                                                        agnt?.id !== isAdded?.id
                                                )
                                            );
                                            return;
                                        }

                                        // if not already added, then check if already selected 3. if already 3, then alert
                                        if (
                                            selectedAgents?.length >= MAX_AGENTS
                                        ) {
                                            setAlertMessage(
                                                'Cannot add more than 3 agents!'
                                            );
                                            return;
                                        }

                                        setSelectedAgents(
                                            isAdded
                                                ? selectedAgents?.filter(
                                                      (agnt) =>
                                                          agnt?.id !==
                                                          isAdded?.id
                                                  )
                                                : [...selectedAgents, agent]
                                        );
                                    }}
                                >
                                    <RenderAgentImage agent={agent} />
                                    <div className="flex-fill">
                                        <div className="ccap fw-bold">
                                            {agent?.user?.first_name ?? ''}{' '}
                                            {agent?.user?.last_name ?? ''}
                                        </div>
                                        <div>{agent?.user?.email}</div>
                                        <div>{agent?.user?.phone}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="pb-2 pt-4 d-flex justify-end align-items-center gap-3 border-top">
                        <Button onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                        <Button
                            className="bg-primary"
                            onClick={() => {
                                if (selectedAgents?.length > 3) {
                                    setAlertMessage(
                                        'Cannot add more than 5 agents!'
                                    );
                                    return;
                                }

                                if (!selectedAgents?.length) {
                                    setAlertMessage('Please select agents!');
                                    return;
                                }

                                setAppliedAgents(selectedAgents);
                                setShowModal(false);
                            }}
                        >
                            Apply Selection
                        </Button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                isOpen={showSelectDate}
                toggle={toggleSelectDate}
                backdrop="static"
            >
                <ModalHeader
                    toggle={toggleSelectDate}
                    close={
                        <button className="close" onClick={toggleSelectDate}>
                            <Icon name="cross" />
                        </button>
                    }
                    className="position-relative"
                >
                    {selectionError && (
                        <Alert
                            color="danger"
                            className="text-center position-absolute start-50  translate-middle fs-14px w-100 fw-light text-danger"
                        >
                            {selectionError}
                        </Alert>
                    )}
                </ModalHeader>
                <ModalBody className="position-relative">
                    {/* {
                        <Col className="text-danger text-center position-absolute top-0 start-50 translate-middle">
                            {selectionError}
                        </Col>
                    } */}
                    <div>
                        <RenderDateSelection
                            isWeekly={isWeekly}
                            callback={(type, data) => {
                                if (type === 'week') {
                                    const sortedWeeks = data?.sort(
                                        (a, b) => new Date(a) - new Date(b)
                                    );

                                    setWeeks(data);
                                    setWeekStart(new Date(sortedWeeks[0]));
                                    setWeekEnd(
                                        new Date(
                                            sortedWeeks[sortedWeeks.length - 1]
                                        )
                                    );
                                    setisWeekly(true);
                                } else if (type === 'month') {
                                    setMonths(data);
                                    setisWeekly(false);
                                }
                                setData('Custom');
                            }}
                            onError={(errMsg) => setSelectionError(errMsg)}
                            startWeek={weekStart}
                            endWeek={weekEnd}
                            selectedMonths={months}
                            closeModal={toggleSelectDate}
                        />
                    </div>
                </ModalBody>
            </Modal>
        </Card>
    );
}

const RenderDateSelection = ({
    isWeekly,
    callback = (type, data) => {},
    onError = (err) => {},
    closeModal = () => {},
    selectedMonths = [],
    startWeek,
    endWeek
}) => {
    const monthSelectorRef = useRef();
    const weekSelectorRef = useRef();
    const portalRef = useRef();

    const [selectOption, setSelectOption] = useState(
        isWeekly ? 'custom' : 'month'
    );

    const [weekStart, setWeekStart] = useState(startWeek);
    const [weekEnd, setWeekEnd] = useState(endWeek);

    const [months, setMonths] = useState(
        selectedMonths?.map((month) => new Date(month))
    );

    const CustomerSelectorFooter = ({
        DatePicker,
        onCancel = () => {},
        onApply = () => {}
    }) => {
        return (
            <>
                <Row className="p-1">
                    <Col sm="6">
                        <Button
                            className="bg-secondary"
                            onClick={() => {
                                onCancel();
                                DatePicker.closeCalendar();
                            }}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col sm="6">
                        <Button
                            className="bg-primary"
                            onClick={() => {
                                onApply();
                                DatePicker.closeCalendar();
                            }}
                        >
                            Apply
                        </Button>
                    </Col>
                </Row>
            </>
        );
    };

    const handleClickOusideMonthPicker = (e) => {
        const { current } = monthSelectorRef;
        if (current && !current.contains(e?.target)) {
            current?.closeCalendar();
        }
    };

    const handleClickOusideWeekPicker = (e) => {
        const { current } = weekSelectorRef;
        if (current && !current.contains(e?.target)) {
            current?.closeCalendar();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOusideMonthPicker);
        document.addEventListener('mousedown', handleClickOusideWeekPicker);

        return () => {
            document.removeEventListener(
                'mousedown',
                handleClickOusideMonthPicker
            );
            document.removeEventListener(
                'mousedown',
                handleClickOusideWeekPicker
            );
        };
    }, []);

    return (
        <>
            <div
                className="d-flex justify-content-between gap-4 m4"
                ref={portalRef}
            >
                <Button
                    className={`border text-center bg-transparent text-secondary fs-16px p-3 pt-4 pb-4 flex-fill ${
                        selectOption === 'custom' && 'border-primary border-2'
                    }`}
                    onClick={() => {
                        setSelectOption('custom');
                        setMonths(null);
                    }}
                >
                    Select Date Range
                </Button>
                {/* <div
          className={`border p-0 ${
            selectOption === 'week' && 'border-primary border-2'
          }`}
          onClick={() => setSelectOption('week')}
        >
          <DatePicker
            ref={weekSelectorRef}
            weekPicker
            inputClass="p-1 border-0 w-100 text-secondary"
            containerClassName="w-100"
            plugins={[
              <CustomerSelectorFooter
                position="bottom"
                onCancel={() => {
                  console.log('Cancel week');
                }}
                onApply={() => {
                  console.log('Apply weeks');
                }}
              />,
            ]}
            showOtherDays
            placeholder="Select Week"
            // value={week}
            onChange={(dates) => {
              console.log(dates);
              // const elements = dates?.map((item) => {
              //     const month = item?.month?.number; // use index if u want the months's index (0-11)
              //     const year = item?.year;

              //     return new Date(
              //         `${
              //             month >= 10 ? month : `0${month}`
              //         }-01-${year}`
              //     );
              // });
              // if (dates?.length > 6) {
              //     console.log('first');
              //     onError('Cannot select more than 6 months');
              //     setMonths(elements?.slice(0, 6));
              //     return;
              // }

              // console.log('second');

              // setMonths(elements);
            }}
            onOpen={() => {
              setSelectOption('week');
              // return false;
            }}
          />
        </div> */}
                <div className="flex-fill">
                    <DatePicker
                        multiple
                        onlyMonthPicker
                        inputClass={`p-2 fs-16px border w-100 text-secondary cursor-pointer date-picker-input position-relative rounded-lg ${
                            selectOption === 'month' &&
                            'border-primary border-2 rounded-md'
                        }`}
                        containerClassName="w-100"
                        ref={monthSelectorRef}
                        // plugins={[
                        //   <CustomerSelectorFooter
                        //     position="bottom"
                        //     onCancel={() => {
                        //       setMonths(selectedMonths?.map((month) => new Date(month)));
                        //     }}
                        //     onApply={() => {
                        //       if (!months?.length) {
                        //         onError('Please Select months');
                        //         return;
                        //       }
                        //       if (months?.length > 6) {
                        //         onError('Cannot select more than 6 months');
                        //         return;
                        //       }
                        //       callback(
                        //         'months',
                        //         months?.map((month) => formatDate(month))
                        //       );
                        //     }}
                        //   />,
                        // ]}
                        placeholder="Select Months"
                        value={months}
                        onChange={(dates) => {
                            const elements = dates?.map((item) => {
                                const month = item?.month?.number; // use index if u want the months's index (0-11)
                                const year = item?.year;

                                return new Date(
                                    `${
                                        month >= 10 ? month : `0${month}`
                                    }-01-${year}`
                                );
                            });

                            setMonths(elements);
                        }}
                        onOpen={() => {
                            setSelectOption('month');
                            setMonths(
                                selectedMonths?.map((month) => new Date(month))
                            );
                        }}
                    />
                </div>
            </div>

            <div className="mt-4">
                <Label className="mt-2"></Label>
                <div className="input-daterange date-picker-range input-group mt-">
                    <ReactDatePicker
                        disabled={selectOption === 'month'}
                        selected={selectOption !== 'month' && weekStart}
                        onChange={(date) => {
                            setWeekStart(date);
                        }}
                        selectsStart
                        startDate={weekStart}
                        endDate={weekEnd}
                        maxDate={weekEnd}
                        wrapperClassName="start-m"
                        className="form-control"
                    />
                    <div className="input-group-addon">TO</div>
                    <ReactDatePicker
                        disabled={selectOption === 'month'}
                        selected={selectOption !== 'month' && weekEnd}
                        onChange={(date) => {
                            setWeekEnd(date);
                        }}
                        startDate={weekStart}
                        endDate={weekEnd}
                        selectsEnd
                        minDate={weekStart}
                        wrapperClassName="end-m"
                        className="form-control"
                    />
                </div>

                <div className="p-1 mt-2 gap-4 d-flex justify-content-start">
                    <Button
                        className="bg-secondary ps-2 pe-2 pt-3 pb-3"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>

                    <Button
                        className="bg-primary ps-2 pe-2 pt-3 pb-3"
                        onClick={() => {
                            if (selectOption === 'custom') {
                                const interval = differenceInDays(
                                    weekEnd,
                                    weekStart
                                );

                                if (interval >= 7) {
                                    onError(
                                        'Interval cannot be more than 1 week!'
                                    );
                                    return;
                                }

                                let dateArray = [];
                                let currentDate = weekStart;

                                const stopDate = weekEnd;

                                while (currentDate <= stopDate) {
                                    dateArray.push(currentDate);
                                    currentDate = addDays(currentDate, 1);
                                }

                                callback(
                                    'week',
                                    dateArray?.map((date) => formatDate(date))
                                );
                            } else if (selectOption === 'month') {
                                if (!months.length) {
                                    onError('Please select months!');
                                    return;
                                }

                                if (months.length > 6) {
                                    onError('Cannot add more than 6 months!');
                                    return;
                                }

                                const dates = months.map((date) =>
                                    formatDate(date)
                                );

                                callback('month', dates);
                            }
                            closeModal();
                        }}
                    >
                        Apply Selection
                    </Button>
                </div>
            </div>
        </>
    );
};

const RenderAgentImage = ({ agent }) => {
    const [image, setImage] = useState(null);

    const userNameCapitalised =
        (agent?.user?.first_name[0] ?? '')?.toUpperCase() +
        (agent?.user?.first_name ?? '')?.substr(1) +
        ' ' +
        (agent?.user?.last_name[0] ?? '')?.toUpperCase() +
        (agent?.user?.last_name ?? '')?.substr(1);

    const fetchImage = async () => {
        if (!agent?.user?.photo) {
            return;
        }

        try {
            const image = await getImage(agent?.user?.photo);
            setImage(image?.signedRequest);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        agent?.user?.photo && fetchImage();
    }, []);

    return (
        <UserAvatar
            image={image}
            text={findUpper(userNameCapitalised)}
            size={'sm'}
        />
    );
};
