const HEADER_FIELDS = [
  "Payee Name",
  "Amount",
  "Payment Date",
  "Payee Type",
  "Payment Method",
  "Note",
  "Balance",
];

const BREAKPOINT_HEADERS = {
  'Note': true,
  'Payment Date': true,
}

const PER_PAGE_OPTIONS = [1, 2, 5, 10, 15];


export { HEADER_FIELDS, BREAKPOINT_HEADERS, PER_PAGE_OPTIONS };