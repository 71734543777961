import React, { useEffect, useRef, useState } from 'react';
import {
    differenceInDays,
    endOfDay,
    endOfWeek,
    startOfDay,
    startOfWeek,
    subDays
} from 'date-fns';
import DatePicker from 'react-datepicker';

import { DealStatus, formatDate, newRoles } from '../../utils/envConfig';
import { useSelector } from 'react-redux';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import {
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon
} from '../../components/Component';
import { Button, Col, Label, Row } from 'reactstrap';
import ActivityCard from './components/ActivityCard';
import { getFeeds } from '../../redux/actions/feeds';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useDispatch } from 'react-redux';
import { fetchAgents } from '../../redux/actions/user';
import DealClosedByAgentCard from './components/DealClosedByAgentCard';
import DealsClosedGrid from '../../components/partials/default/DealsClosedGrid';

const ListAgents = ({ agents, selectedAgent, onClickAgent = (e) => {} }) => {
    return (
        <div className="feed-agents">
            {agents?.length ? (
                agents?.map((agent) => (
                    <div
                        className={
                            selectedAgent?.id === agent?.id &&
                            'bg-secondary text-white'
                        }
                        onClick={() => onClickAgent(agent)}
                    >
                        <DealClosedByAgentCard
                            title={`${agent?.user?.first_name} ${agent?.user?.last_name}`}
                            subText={agent?.user?.email}
                            photo={agent?.user?.photo}
                        />
                    </div>
                ))
            ) : (
                <div>No agents available</div>
            )}
        </div>
    );
};

export default function LiveFeed() {
    const printRef = useRef();

    const today = new Date();

    const axios = useAxiosPrivate();

    const dispatch = useDispatch();

    const [smBtn, setSmBtn] = useState(false);

    const loggedInUser = useSelector((st) => st?.user?.loggedInUser);

    const agents = useSelector((state) => state?.user?.agents);

    const [dealsClosedThisWeek, setDealsClosedThisWeek] = useState();

    const activeAgents = agents?.filter((agent) => agent?.user?.active);

    const [selectedAgent, setSelectedAgent] = useState(
        loggedInUser && loggedInUser.role === newRoles.Agent
            ? loggedInUser
            : null
    );

    const feeds = useSelector((state) => state?.feeds);

    const [dateError, setDateError] = useState('');

    const [dayStart, setDayStart] = useState(startOfDay(today));
    const [dayEnd, setDayEnd] = useState(endOfDay(today));

    useEffect(() => {
        dispatch(
            getFeeds(axios, {
                fromDate: dayStart,
                toDate: dayEnd,
                createdBy: selectedAgent?.user?.id
            })
        );
    }, [dayEnd, dayStart, selectedAgent, dispatch]);

    useEffect(() => {
        const timeout = dateError
            ? setTimeout(() => {
                  setDateError('');
              }, 3000)
            : undefined;

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [dateError]);

    const getDealsClosedThisWeek = async () => {
        const fromDate = `${formatDate(startOfWeek(today))}T00:00:00.000Z`;
        const toDate = `${formatDate(endOfWeek(today))}T23:59:59.999Z`;

        try {
            const { data: deals } = await axios.get(
                `/reports/main/deals?from_date=${fromDate}&to_date=${toDate}&status=${DealStatus.Completed}`
            );
            setDealsClosedThisWeek(deals);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        dispatch(fetchAgents(axios));
        getDealsClosedThisWeek();
    }, []);
    return (
        <>
            <Head title="Live Feed" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page className="mb-3">
                                Live Feed Of Activities
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                    <BlockHeadContent>
                        <div className="d-flex justify-content-end align-items-end">
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <a
                                    href="#toggle"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        setSmBtn(!smBtn);
                                    }}
                                    className="btn btn-icon btn-trigger toggle-expand me-n1"
                                >
                                    <Icon name="menu-alt-r"></Icon>
                                </a>
                                <div
                                    className={`toggle-expand-content ${
                                        smBtn ? 'expanded' : ''
                                    }`}
                                >
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <Button
                                                className="btn bg-primary text-white "
                                                onClick={() => {
                                                    setDayStart(
                                                        startOfDay(today)
                                                    );
                                                    setDayEnd(endOfDay(today));
                                                }}
                                            >
                                                Today
                                            </Button>
                                        </li>
                                        <li>
                                            <Button
                                                className="btn bg-gray text-white "
                                                onClick={() => {
                                                    setDayStart(
                                                        startOfDay(
                                                            subDays(today, 1)
                                                        )
                                                    );
                                                    setDayEnd(
                                                        endOfDay(
                                                            subDays(today, 1)
                                                        )
                                                    );
                                                }}
                                            >
                                                Yesterday
                                            </Button>
                                        </li>

                                        <li>
                                            <div className="form-group">
                                                <div className="form-control-wrap position-relative">
                                                    <div className="text-danger fs-sm text-center position-absolute start-50 bottom-100 translate-middle-x w-100">
                                                        {dateError}
                                                    </div>
                                                    <div className="input-daterange date-picker-range input-group">
                                                        <DatePicker
                                                            selected={dayStart}
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }} // prevent user from typing in date
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                const interval =
                                                                    differenceInDays(
                                                                        dayEnd,
                                                                        date
                                                                    );

                                                                if (
                                                                    interval >=
                                                                    7
                                                                ) {
                                                                    setDateError(
                                                                        'Interval cannot be more than 7 days'
                                                                    );
                                                                    return;
                                                                }

                                                                setDayStart(
                                                                    date
                                                                );
                                                            }}
                                                            selectsStart
                                                            startDate={dayStart}
                                                            endDate={dayEnd}
                                                            maxDate={dayEnd}
                                                            wrapperClassName="start-m"
                                                            className="form-control"
                                                        />{' '}
                                                        <div className="input-group-addon">
                                                            TO
                                                        </div>
                                                        <DatePicker
                                                            selected={dayEnd}
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }} // prevent user from typing in date
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                const interval =
                                                                    differenceInDays(
                                                                        date,
                                                                        dayStart
                                                                    );

                                                                if (
                                                                    interval >=
                                                                    7
                                                                ) {
                                                                    setDateError(
                                                                        'Interval cannot be more than 7 days'
                                                                    );
                                                                    return;
                                                                }

                                                                setDayEnd(date);
                                                            }}
                                                            startDate={dayStart}
                                                            endDate={dayEnd}
                                                            selectsEnd
                                                            minDate={dayStart}
                                                            wrapperClassName="end-m"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </BlockHeadContent>
                </BlockHead>

                <Row className="g-gs">
                    <Col
                        md="8"
                        style={{
                            maxHeight: 780,
                            overflowY: 'auto'
                        }}
                    >
                        <div className="live-activities">
                            {feeds && feeds?.length ? (
                                feeds?.map((feed) => (
                                    <ActivityCard
                                        key={feed?.id ?? feed?._id}
                                        feed={feed}
                                    />
                                ))
                            ) : (
                                <div className="text-center">
                                    No feeds to display
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col md="4" className="">
                        {loggedInUser &&
                            loggedInUser?.role !== newRoles.Agent && (
                                <div className="">
                                    <div
                                        className="bg-white mb-2 card-inner-sm rounded"
                                        style={{
                                            height: '30rem',
                                            maxHeight: '100%',
                                            overflowX: 'hidden'
                                        }}
                                    >
                                        <Label className="text-secondary ps-2 pe-2 border-secondary">
                                            <h6 className="p-2 card-title ">
                                                Filter Activities By Agent
                                            </h6>
                                        </Label>

                                        <ListAgents
                                            agents={activeAgents}
                                            selectedAgent={selectedAgent}
                                            onClickAgent={(agent) =>
                                                setSelectedAgent(
                                                    selectedAgent?.id ===
                                                        agent?.id
                                                        ? {}
                                                        : agent
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        <div>
                            <DealsClosedGrid
                                deals={dealsClosedThisWeek?.Deals}
                                title="Deals Closed This Week"
                            />
                        </div>
                    </Col>
                </Row>
            </Content>
        </>
    );
}
