import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Icon } from '../../../components/Component';

import AddAgency from '../../components/forms/AddAgency';

export default function AddNewAgency({
    isOpen = false,
    toggle = () => {},
    callback = (agency) => {}
}) {
    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle} backdrop="static">
            <ModalHeader
                toggle={toggle}
                close={
                    <button className="close" onClick={toggle}>
                        <Icon name="cross" />
                    </button>
                }
            >
                Add New Agency
            </ModalHeader>
            <ModalBody>
                <AddAgency callback={callback} toggle={toggle} />
            </ModalBody>
        </Modal>
    );
}
