import React from 'react';
import { Line } from 'react-chartjs-2';

export default function LineChart({
    data = {
        labels: [],
        datasets: [],
        gridLines: {
            x: { color: 'transparent', tickLength: 0, display: false },
            y: { display: false }
        }
    }
}) {
    return (
        <Line
            className="ecommerce-line-s4"
            data={{
                labels: data?.labels,
                datasets: data?.datasets?.length
                    ? data?.datasets
                    : [
                          {
                              data: [4, 6, 5, 9, 2, 8, 10],
                              label: 'Label 1',
                              color: '#9d72ff',
                              borderWidth: 2,
                              borderColor: '#9d72ff',
                              fill: true,
                              pointRadius: 0,
                              lineTension: 0.4,
                              backgroundColor: 'rgba(157, 114, 255, 0.15)',
                              pointBorderColor: 'transparent',
                              pointBackgroundColor: 'transparent',
                              pointHoverBackgroundColor: '#fff',
                              pointBorderWidth: 2,
                              pointHoverRadius: 4,
                              pointHoverBorderWidth: 2,
                              pointHitRadius: 4,
                              pointHoverBorderColor: '#9d72ff'
                          }
                      ]
            }}
            options={{
                plugins: {
                    legend: {
                        display: false
                    }
                },
                maintainAspectRatio: false,
                scales: {
                    x: {
                        axis: 'x',
                        grid: {
                            ...data?.gridLines?.x
                        },
                        ticks: {
                            font: {
                                style: '#9eaecf',
                                size: 10
                            },
                            padding: 10,
                            stepSize: 3000,
                            source: 'auto',
                            color: '#9eaecf'
                        }
                    },
                    y: {
                        axis: 'y',
                        grid: {
                            ...data?.gridLines?.y
                        },
                        ticks: {
                            // beginAtZero: true,
                            font: {
                                size: 11,
                                style: '#9eaecf'
                            },
                            color: '#9eaecf',
                            // padding: 5,
                            callback: function (value, index, values) {
                                return value;
                            },
                            min: 0

                            // stepSize: 4000
                        }
                    }
                }
            }}
        />
    );
}
