import TableLayout from "../components/table/TableLayout";

const UsersList = () =>
{
  return (
    <>
      <TableLayout title={ "List All Users" } name={ "User" } role={ null } />
    </>
  );
};

export default UsersList;
