import { Button, Col, Modal, ModalBody, Row, Spinner } from "reactstrap";
import { Icon, RSelect } from "../../../components/Component";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { validate } from "../utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateCommunityTeam } from "../../../redux/actions/community-teams";
import { SPECIAL_TEAMS } from "../constants";
import { fetchCommunitiesList } from "../../../redux/actions/property";
import { fetchAgentsList } from "../../../redux/actions/user";

const TeamEditModal = ({
  communityTeam,
  isOpen,
  toggleFn,
  refreshFn,
}) => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const [memberOptions, setMemberOptions] = useState([]);
  const [communityOptions, setCommunityOptions] = useState([]);
  const [selectedTeamName, setSelectedTeamName] = useState(
    communityTeam?.teamName ?? ""
  );
  const [selectedMembers, setSelectedMembers] = useState(
    communityTeam?.members?.map((m) => ({
      label: `${m?.first_name} ${m?.last_name ?? ""}`,
      value: m?.id ?? m?._id,
      ...m,
    })) ?? []
  );
  const [selectedCommunities, setSelectedCommunities] = useState(
    communityTeam?.communities?.map((c) => ({
      ...c,
      label: c?.community,
      value: c?.id ?? c?._id,
    })) ?? []
  );
  const { loading, error } = useSelector((state) => state.communityTeam);

  const { communities, communitiesLoading } = useSelector((state) => ({
    communities: state.property?.communities,
    communitiesLoading: state.property?.communitiesLoading,
  }));

  const { agents, agentsLoading } = useSelector((state) => ({
    agents: state.user?.agents,
    agentsLoading: state.user?.loading,
  }));

  useEffect(() => {
    dispatch(fetchCommunitiesList(axios));
    dispatch(fetchAgentsList(axios));
  }, [axios, dispatch]);

  useEffect(() => {
    if (communities?.length) {
      setCommunityOptions((_) =>
        communities?.filter((c) => !c?.team_id)?.map((c) => ({
          ...c,
          label: c?.community,
          value: c?.id ?? c?._id,
        }))
      );
    }
    if (agents?.length) {
      setMemberOptions(_ => 
        agents?.map((agent) => ({
          ...agent,
          label: `${agent?.user?.first_name} ${agent?.user?.last_name}`,
          value: agent?.userId
        }))
      )
    }
  }, [agents, communities]);

  useEffect(() => {
    if (error) {
      toast.error(error.message ?? "Error occurred while editing team.");
    }
  }, [error]);

  const handleEditFormSubmit = async (formEvent) => {
    formEvent.preventDefault();
    const updatedMembers = selectedMembers.map((mem) => mem.value);
    const updatedCommunities = selectedCommunities.map((com) => com.value);
    if (
      !validate(
        selectedTeamName,
        updatedMembers,
      )
    ) {
      return;
    }
    const payload = {
      teamName: selectedTeamName,
      members: updatedMembers,
      communities: updatedCommunities,
    };
    const teamId = communityTeam?.id;

    dispatch(
      updateCommunityTeam(
        axios,
        teamId,
        payload,
        () => toggleFn(), // run this callback in all cases
        () => {
          toast.success("Team edited successfully!");
          refreshFn();
        } // this callback runs only in success case
      )
    );
  };

  const handleMembersChange = (e) => {
    let values = selectedMembers
      ? selectedMembers
          // eslint-disable-next-line eqeqeq
          .filter((x) => x?.value != undefined)
          .map((x) => x?.value)
      : [];
    values = [...new Set(e)];
    setSelectedMembers(values);
  };

  const handleCommunitiesChange = (e) => {
    let values = selectedCommunities
      ? selectedCommunities
          // eslint-disable-next-line eqeqeq
          .filter((x) => x?.value != undefined)
          .map((x) => x?.value)
      : [];
    values = [...new Set(e)];
    setSelectedCommunities(values);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleFn}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <div className="nk-tnx-details mt-sm-3">
          <a href="#cancel" className="close">
            {" "}
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                toggleFn();
              }}
            ></Icon>
          </a>
          <div className="nk-modal-head mb-3">
            <h5 className="title">Edit Community Team</h5>
          </div>
        </div>
        <div className="p-2">
          <form onSubmit={handleEditFormSubmit}>
            <Row className="g-3">
              <Col
                md={`${
                  communityTeam?.teamName === SPECIAL_TEAMS.OffPlan ? "12" : "4"
                }`}
              >
                <div className="form-group">
                  <label className="form-label" htmlFor="teamName">
                    Team Name *
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      name="teamName"
                      required
                      defaultValue={selectedTeamName}
                      onChange={(e) => setSelectedTeamName(e.target.value)}
                    />
                  </div>
                </div>
              </Col>
              <Col
                md={`${
                  communityTeam?.teamName === SPECIAL_TEAMS.OffPlan ? "12" : "8"
                }`}
              >
                <div className="form-group">
                  <label className="form-label" htmlFor="teamMembers">
                    Team Members *
                  </label>
                  <div className="form-control-wrap">
                    <RSelect
                      name="teamMembers"
                      placeholder="Select members..."
                      isMulti
                      required
                      isLoading={agentsLoading}
                      loadingMessage={() => <Spinner size={'sm'} />}
                      value={selectedMembers.length && selectedMembers}
                      options={memberOptions}
                      onChange={(e) => handleMembersChange(e)}
                    />
                  </div>
                </div>
              </Col>

              {communityTeam?.teamName === SPECIAL_TEAMS.OffPlan ? null : (
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="teamCommunities">
                      Communities *
                    </label>
                    <div className="form-control-wrap">
                      <RSelect
                        name="teamCommunities"
                        isLoading={communitiesLoading}
                        loadingMessage={() => <Spinner size={'sm'} />}
                        placeholder="Select team communities..."
                        isMulti
                        value={selectedCommunities}
                        options={communityOptions}
                        onChange={(e) => handleCommunitiesChange(e)}
                      />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
            <Col size="12" className="text-end mt-4">
              <Button color="primary" size="lg" disabled={loading}>
                {loading ? "Editing..." : "Edit Team"}
              </Button>
            </Col>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TeamEditModal;
