import React, { useEffect, useState } from 'react';
import {
  Card,
  Modal,
  ModalBody,
  Badge,
  CardTitle,
  CardImg,
  CardBody,
  CardText,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Spinner,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  Collapse,
} from 'reactstrap';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  Row,
  Sidebar,
  UserAvatar,
  RSelect,
  TooltipComponent,
} from '../../components/Component';

import Content from '../../layout/content/Content';
import Head from '../../layout/head/Head';
import { calcTime, getDateStructured } from '../../utils/Utils';
import {
  formatValue,
  DocumentTypes,
  getImage,
  DealClientType,
  DealCategory,
  AgencyType,
  uploadToS3,
  getDealDocuments,
  DealPurpose,
  DealStatus,
  uploadManyToS3,
  imageUrlRegex,
  newRoles,
  formatValueToDecimal,
  ListingType,
  NewBedRooms,
  NewBathRooms,
  FurnishingTypes,
  formatDecimalValue,
} from '../../utils/envConfig';
import { useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useDispatch } from 'react-redux';
import { fetchDeal } from '../../redux/actions/deal';
import { format, intervalToDuration } from 'date-fns';
import uuid from 'react-uuid';
import MyCollapsibleCard from './components/MyCollapsibleCard';
import { useConfirm } from '../../hooks/useConfirm';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import DndFlagComponent from '../components/dnd/dndFlag';
import CardInner from '../../components/cards/CardInner';
import DealTransactionsContainer from './components/transactions/DealTransactionsContainer';
import { fetchAmenitiesList } from '../../redux/actions/property';
import classNames from 'classnames';
import { showToast } from '../../utils/toast/toast';

const calculateDealAmount = (deal) => {
  if (deal?.agreedSalePrice) {
    return formatDecimalValue(deal?.agreedSalePrice);
  }

  switch (deal?.category) {
    case DealCategory.SecondaryProperty:
      const isInternal = deal?.sellerClientType === DealClientType.Alba;

      const property = deal?.property_list;
      const externalProperty = deal?.external_listing_property;

      return isInternal
        ? formatValue(
            property?.askingPrice ??
              property?.oneCheqPrice ??
              property?.twoCheqPrice ??
              property?.fourCheqPrice ??
              property?.sixCheqPrice ??
              property?.twelveCheqprice ??
              0
          )
        : formatValue(externalProperty?.agreedSalesPrice ?? 0);
    case DealCategory.PrimaryOffplan:
      return formatValue(deal?.new_listing_offplan?.agreedSalesPrice ?? 0);
    case DealCategory.ListingAcquired:
      return formatValue(deal?.new_listing_request?.agreedSalesPrice ?? 0);

    default:
      return 0;
  }
};
const getDealCommission = (deal, { withTax = false }) => {
  let totalCommission = 0;
  if (deal?.buyerAgreedCommission||deal?.sellerAgreedCommission) {
  
    totalCommission += ((deal?.sellerAgreedCommission ?? 0) + (deal?.buyerAgreedCommission ?? 0))
  } else {
    totalCommission += (deal?.agreedCommission ?? 0)
  }

  return withTax ? Number((totalCommission + (0.05 * totalCommission)).toFixed(4)) : Number(totalCommission.toFixed(4));
}

const calculateDealCommission = (deal) => {
  const agreedSalePrice = deal?.agreedSalePrice;
  const commission=getDealCommission(deal,{withTax:false})
 return  Number(((agreedSalePrice * Number(commission)) / 100)?.toFixed(4))
};

const DealDetails = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const today = new Date();

  const axios = useAxiosPrivate();

  const deal = useSelector((state) => state?.deal?.deal);
console.log(deal);
  const dispatch = useDispatch();

  const { cancel, isConfirmed, isOpen, proceed, prompt } = useConfirm();
  const [sideBar, setSidebar] = useState(false);
  const [noteData, setNoteData] = useState();
  const [creatingNote, setCreatingNote] = useState(false);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [addNoteText, setAddNoteText] = useState('');
  const [tasks, setTasks] = useState([]);
  const [activeTab, setActiveTab] = useState(
    location.hash?.substring(1)?.length
      ? location.hash?.substring(1)
      : 'personal'
  );
  const [openCardId, setOpenCardId] = useState(null);
  const [deletingNote, setDeletingNote] = useState(null);

  const [otherDocuments, setOtherDocuments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [editedPrice, setEditedPrice] = useState(0);
  const [dealDocs, setDealDocs] = useState();

  const [assignedAgentImage, setAssignedAgentImage] = useState(null);
  const [fetchingAgentImage, setFetchingAgentImage] = useState(null);

  // progress Deal to next stage
  const [submitting, setSubmitting] = useState(false);

  const handleSubmitForApproval = async () => {
    try {
      setSubmitting(true);
      const { data } = await axios.post(`/deals/progress/${id}`, {});
      toast.success('Submitted for approval');

      dispatch(fetchDeal(axios, id));
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };
  const authuser = useSelector((state) => state?.user?.loggedInUser);

  const getAssignedAgentImage = async () => {
    if (!deal?.assignedAgent || !deal?.assignedAgent?.user?.photo) {
      return;
    }

    try {
      setFetchingAgentImage(true);
      const image = await getImage(deal?.assignedAgent?.user?.photo, axios);

      setAssignedAgentImage(image?.signedRequest ?? null);
    } catch (err) {
      console.log(err);
    } finally {
      setFetchingAgentImage(null);
    }
  };

  const getDealNotes = async () => {
    try {
      const { data } = await axios.get(`/notes/deal/${id}`);
      setNoteData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const [refresh, setRefresh] = useState(false);
  const toggleRefresh = () => setRefresh(!refresh);

  useEffect(() => {
    if (id) {
      dispatch(fetchDeal(axios, id));
      getDealNotes();
    }
  }, [id, refresh]);

  useEffect(() => {
    if (deal?.assignedAgent && deal?.assignedAgent?.user?.photo) {
      getAssignedAgentImage();
    }
  }, [deal?.assignedAgent?.id]);

  const toggle = () => {
    setSidebar(!sideBar);
  };

  // delete a note
  const deleteNote = async (id) => {
    const confirmed = await isConfirmed(
      <>
        <p>Are you sure you want to delete this note?</p>
        <p>Action cannot be reverted</p>
      </>
    );

    if (confirmed) {
      try {
        setDeletingNote(id);
        // delete the note
        const { data } = await axios.delete(`/notes/${id}`);
        setNoteData((prev) => prev?.filter((note) => note?.id !== id));
      } catch (err) {
        console.log(err);
        toast.error('Unexpected error while deleting note');
      }
    }
  };

  const submitNote = async () => {
    try {
      setCreatingNote(true);

      const submitData = {
        deal_id: deal?.id,
        description: addNoteText,
      };

      if (!addNoteText.length || addNoteText.split(' ').length < 3) {
        toast.error(
          addNoteText.split(' ').length < 3
            ? 'Note too short. Minimum 3 words'
            : 'Please add note text.'
        );

        return;
      }

      const { data } = await axios.post('/notes', submitData);

      setNoteData((prev) => [...prev, data]);

      setAddNoteModal(false);

      setAddNoteText('');
    } catch (err) {
      console.log(err);
      toast.error('Unexpected error. Failed to add note.');
    } finally {
      setCreatingNote(false);
    }
  };

  const items = [
    {
      title: 'AED 4,000,000',
      date: 'March 1, 2022',
      description:
        'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      title: 'AED 6,000,000',
      date: 'February 1, 2022',
      description:
        'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      title: 'AED 1,000,000',
      date: 'January 1, 2022',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
  ];

  const handleTabClick = (ev, tab) => {
    // ev.preventDefault();
    setActiveTab(tab);
  };

  const toggleCollapse = (id) => {
    setOpenCardId(openCardId === id ? null : id);
  };

  const fetchDealDocuments = async () => {
    try {
      const docs = await getDealDocuments(axios, id);

      setDealDocs(docs);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDealDocuments();
  }, []);

  const handleDropOtherDocuments = (acceptedFiles) => {
    const files = [...acceptedFiles];

    setOtherDocuments(files);
  };

  const handleDeleteOtherDocuments = (i) => {
    const filteredDocs = otherDocuments?.filter((item, ind) => ind !== i);

    setOtherDocuments(filteredDocs);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (otherDocuments && otherDocuments?.length) {
        const files = otherDocuments?.map((doc) => ({
          file: doc,
          fileName: `/deals/other-${uuid()}.${doc?.name?.split('.')?.pop()}`,
          type: doc?.type ?? 'image/*',
          name: doc?.name,
        }));

        const docParams = {
          user_id: deal?.buyerInternalUserId ?? deal?.sellerInternalUserId,
          path: 'null',
          deal_id: deal?.id,
          type: DocumentTypes.Other,
          documents: files?.map((file) => ({
            name: `${DocumentTypes.Other}-${file?.name?.split('.')[0]}`,
            value: file?.fileName,
          })),
        };

        await uploadManyToS3(files, axios);

        await axios.post('/documents', docParams);
      }
      toast.success('Uploaded other documents successfully');
      fetchDealDocuments();
      setOtherDocuments(null);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitPrice = () => {
    // You can implement your logic to update the price here
    // For now, let's just hide the popup
    setShowPopup(false);
  };

  const RenderBuyerAndSellerInfo = () => {
    const buyerClientType = deal?.buyerClientType;

    const sellerClientType = deal?.sellerclientType;

    return (
      <>
        {buyerClientType === DealClientType.Alba ? (
          <div className="data-list nk-data">
            <div className="data-head">
              <h6 className="overline-title">Buyer/Tenant Info</h6>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">First Name</span>
                  <span className="data-value">
                    {deal?.buyerInternalUser?.first_name ??
                      deal?.buyerInternalUser?.firstName ??
                      'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Last Name</span>
                  <span className="data-value">
                    {deal?.buyerInternalUser?.last_name ??
                      deal?.buyerInternalUser?.lastName ??
                      'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Phone</span>
                  <span className="data-value">
                    {deal?.buyerInternalUser?.DndStatus ? (
                      <DndFlagComponent
                        className={
                          'd-flex flex-column align-items-center justify-content-center p-2'
                        }
                        lead_status={deal?.buyerInternalUser?.lead_status}
                        phoneNumber={deal?.buyerInternalUser?.phone}
                      />
                    ) : (
                      <span>{deal?.buyerInternalUser?.phone ?? ''}</span>
                    )}
                    {deal?.buyerInternalUser?.phone ?? 'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Email</span>
                  <span className="data-value">
                    {deal?.buyerInternalUser?.email ?? 'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Lead Source</span>
                  <span className="data-value">
                    {deal?.buyerInternalUser?.lead_source ??
                      deal?.buyerInternalUser?.leadSource ??
                      'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>{' '}
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Status</span>
                  <span className="data-value">
                    <Badge className="bg-success">
                      {deal?.buyerInternalUser?.lead_status ??
                        deal?.buyerInternalUser?.leadStatus}
                    </Badge>
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Client Type</span>
                  <span className="data-value">{buyerClientType ?? 'N/A'}</span>
                </div>
                <div className="data-col-end data-col"></div>{' '}
              </Col>
            </div>
          </div>
        ) : buyerClientType === DealClientType.External ? (
          <div className="data-list nk-data">
            <div className="data-head">
              <h6 className="overline-title">Buyer/Tenant Info</h6>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Agency/Agent Name</span>
                  <span className="data-value">
                    {deal?.buyerExternalUser?.agency_name ??
                      deal?.buyerExternalAgentName ??
                      'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Client Name</span>
                  <span className="data-value">
                    {deal?.buyerExternalUser?.first_name ?? 'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Agency/Agent Phone</span>
                  <span className="data-value">
                    {deal?.buyerExternalUser?.agency_phone ??
                      deal?.buyerExternalAgentPhone ??
                      'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Email</span>
                  <span className="data-value">
                    {deal?.buyerExternalUser?.email ?? 'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Client Type</span>
                  <span className="data-value">{buyerClientType ?? 'N/A'}</span>
                </div>
                <div className="data-col-end data-col"></div>{' '}
              </Col>
            </div>
          </div>
        ) : null}

        {sellerClientType === DealClientType.Alba ? (
          <div className="data-list nk-data">
            <div className="data-head">
              <h6 className="overline-title">Seller/Landlord Info</h6>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">First Name</span>
                  <span className="data-value">
                    {deal?.sellerInternalUser?.first_name ??
                      deal?.sellerInternalUser?.firstName ??
                      'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Last Name</span>
                  <span className="data-value">
                    {deal?.sellerInternalUser?.last_name ??
                      deal?.sellerInternalUser?.lastName ??
                      'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Phone</span>
                  <span className="data-value">
                    {deal?.sellerInternalUser?.DndStatus ? (
                      <DndFlagComponent
                        className={
                          'd-flex flex-column align-items-center justify-content-center p-2'
                        }
                        lead_status={deal?.sellerInternalUser?.lead_status}
                        phoneNumber={deal?.sellerInternalUser?.phone}
                      />
                    ) : (
                      <span>{deal?.sellerInternalUser?.phone ?? 'N/A'}</span>
                    )}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Email</span>
                  <span className="data-value">
                    {deal?.sellerInternalUser?.email ?? 'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Lead Source</span>
                  <span className="data-value">
                    {deal?.sellerInternalUser?.lead_source ??
                      deal?.sellerInternalUser?.leadSource ??
                      'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>{' '}
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Status</span>
                  <span className="data-value">
                    <Badge className="bg-success">
                      {deal?.sellerInternalUser?.lead_status ??
                        deal?.sellerInternalUser?.leadStatus}
                    </Badge>
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Client Type</span>
                  <span className="data-value">
                    {sellerClientType ?? 'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>{' '}
              </Col>
            </div>
          </div>
        ) : sellerClientType === DealClientType.External ? (
          <div className="data-list nk-data">
            <div className="data-head">
              <h6 className="overline-title">Seller/Landlord Info</h6>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Agency/Agent Name</span>
                  <span className="data-value">
                    {deal?.sellerExternalUser?.agency_name ??
                      deal?.sellerExternalAgentName ??
                      'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Client Name</span>
                  <span className="data-value">
                    {deal?.sellerExternalUser?.first_name ?? 'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Agency/Agent Phone</span>
                  <span className="data-value">
                    {deal?.sellerExternalUser?.agency_phone ??
                      deal?.sellerExternalAgentPhone ??
                      'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Email</span>
                  <span className="data-value">
                    {deal?.sellerExternalUser?.email ?? 'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
            </div>
            <div className="data-item">
              <Col sm="6">
                <div className="data-col">
                  <span className="data-label">Client Type</span>
                  <span className="data-value">
                    {sellerClientType ?? 'N/A'}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>{' '}
              </Col>
            </div>
          </div>
        ) : null}

          {/* <div className="data-list nk-data">
            <div className="data-head">
              <h6 className="overline-title">Mortgage Info</h6>
            </div>
            <div className="data-item">
              <Col sm="3">
                <div className="data-col">
                  <span className="data-label">Is Mortgage</span>
                  <span className="data-value">
                    {deal?.isMortgage ? "Yes" : "No"}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col>
              {deal?.mortgageProvider &&
              <Col sm="9">
                <div className="data-col">
                  <span className="data-label">Mortgage Provider</span>
                  <span className="data-value">
                    {deal?.mortgageProvider ?? "N/A"}
                  </span>
                </div>
                <div className="data-col-end data-col"></div>
              </Col> }
            </div>
          </div> */}
      </>
    );
  };

  const RenderClientInfo = () => {
    return (
      <>
        <div className="data-list nk-data">
          <div className="data-head">
            <h6 className="overline-title">Client Info</h6>
          </div>
          <div className="data-item">
            <Col sm="6">
              <div className="data-col">
                <span className="data-label">First Name</span>
                <span className="data-value">{deal?.user?.first_name}</span>
              </div>
              <div className="data-col-end data-col"></div>
            </Col>
            <Col sm="6">
              <div className="data-col">
                <span className="data-label">Last Name</span>
                <span className="data-value">{deal?.user?.last_name}</span>
              </div>
              <div className="data-col-end data-col"></div>
            </Col>
          </div>
          <div className="data-item">
            <Col sm="6">
              <div className="data-col">
                <span className="data-label">Phone</span>
                <div className="data-value">
                  {deal?.user?.DndStatus ? (
                    <DndFlagComponent
                      className={
                        'd-flex flex-column align-items-center justify-content-center p-2'
                      }
                      lead_status={deal?.user?.lead_status}
                      phoneNumber={deal?.user?.phone}
                    />
                  ) : (
                    <span>{deal?.user?.phone ?? 'N/A'}</span>
                  )}
                </div>
              </div>
              <div className="data-col-end data-col"></div>
            </Col>
            <Col sm="6">
              <div className="data-col">
                <span className="data-label">Email</span>
                <span className="data-value">{deal?.user?.email}</span>
              </div>
              <div className="data-col-end data-col"></div>
            </Col>
          </div>
          <div className="data-item">
            <Col sm="6">
              <div className="data-col">
                <span className="data-label">Lead Source</span>
                <span className="data-value">{deal?.user?.lead_source}</span>
              </div>
              <div className="data-col-end data-col"></div>{' '}
            </Col>
            <Col sm="6">
              <div className="data-col">
                <span className="data-label">Status</span>
                <span className="data-value">
                  <Badge className="bg-success">
                    {deal?.user?.lead_status}
                  </Badge>
                </span>
              </div>
              <div className="data-col-end data-col"></div>
            </Col>
          </div>
        </div>
      </>
    );
  };

  const RenderSecondaryPropertyDetails = () => {
    const property = deal?.property_list;

    const externalProperty = deal?.external_listing_property;

    const [image, setImage] = useState('/img/private-property.svg');

    const fetchImage = async () => {
      try {
        const imagePath = property?.images?.length
          ? typeof property?.images[0] === 'string'
            ? property?.images[0]
            : property?.images[0]?.original
          : null;

        if (!imagePath) {
          return;
        }

        const image = await getImage(imagePath, axios);
        setImage(image?.signedRequest);
      } catch (err) {
        console.log(err);
      }
    };

    useEffect(() => {
      fetchImage();

      return () => {};
    }, []);

    return deal?.sellerclientType === DealClientType.Alba ? (
      <Link to={`/property-list/${property?.id}`}>
        <Card className="card-bordered">
          <Row>
            <Col sm="3" className="relative">
              <CardImg
                src={image}
                alt=""
                width="100%"
                height="100%"
                className="absolute"
                style={{
                  objectFit: 'cover',
                }}
              />
            </Col>

            <Col sm="9">
              <CardBody className="card-inner">
                <CardTitle tag="h6">
                  <span className="text-secondary">
                    {property?.propertyTitle ??
                      property?.property_title ??
                      'N/A'}
                  </span>
                </CardTitle>
                <CardTitle tag="h6">
                  <span className="text-primary">
                    AED{' '}
                    {formatValue(
                      property?.askingPrice ??
                        property?.oneCheqPrice ??
                        property?.twoCheqPrice ??
                        property?.fourCheqPrice ??
                        property?.sixCheqPrice ??
                        property?.twelveCheqprice ??
                        0
                    )}
                  </span>
                </CardTitle>
                <CardTitle tag="h5"></CardTitle>
                <Icon name="location"></Icon>{' '}
                <span>
                  {property?.communityName ??
                    property?.community?.community ??
                    'N/A'}
                </span>
                <CardText>
                  <div className="product-meta">
                    <ul className="d-flex g-3 gx-5">
                      <li>
                        <div className="text-muted fs-14px">Type</div>
                        <div className="fw-bold text-secondary fs-16px">
                          {property?.propertyType?.propertyType ?? 'N/A'}
                        </div>
                      </li>
                      <li>
                        <div className="text-muted fs-14px">Listing Type</div>
                        <div className="fw-bold text-secondary fs-16px">
                          {property?.listingType ??
                            property?.listing_type ??
                            'N/A'}
                        </div>
                      </li>
                      <li>
                        <div className="text-muted fs-14px">Beds</div>
                        <div className="fw-bold text-secondary fs-16px">
                          {property?.beds ?? 'N/A'}
                        </div>
                      </li>
                      <li>
                        <div className="text-muted fs-14px">Baths</div>
                        <div className="fw-bold text-secondary fs-16px">
                          {property?.baths ?? 'N/A'}
                        </div>
                      </li>
                      <li>
                        <div className="text-muted fs-14px">Area</div>
                        <div className="fw-bold text-secondary fs-16px">
                          {property?.size ?? 'N/A'}
                        </div>
                      </li>
                      <li>
                        <div className="text-muted fs-14px">ID</div>
                        <div className="fw-bold text-secondary fs-16px">
                          {property?.referNo ?? property?.refer_no ?? 'N/A'}
                        </div>
                      </li>
                    </ul>
                  </div>
                </CardText>
              </CardBody>
            </Col>
          </Row>
        </Card>
      </Link>
    ) : deal?.sellerclientType === DealClientType.External ? (
      <Card className="card-bordered">
        <Row>
          <Col sm="3" className="relative">
            <CardImg
              src={image}
              alt=""
              width="100%"
              height="100%"
              className="absolute"
              style={{
                objectFit: 'cover',
              }}
            />
          </Col>

          <Col sm="9">
            <CardBody className="card-inner">
              <CardTitle tag="h6">
                <span className="text-secondary">{'External Property'}</span>
              </CardTitle>
              <CardTitle tag="h6">
                <span className="text-primary">
                  AED {calculateDealAmount(deal)}
                </span>
              </CardTitle>
              <CardTitle tag="h5"></CardTitle>
              <Icon name="location"></Icon>{' '}
              <span>
                {externalProperty?.communityName ??
                  externalProperty?.community?.community ??
                  'N/A'}
              </span>
              <CardText>
                <div className="product-meta">
                  <ul className="d-flex g-3 gx-5">
                    <li>
                      <div className="text-muted fs-14px">Type</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {externalProperty?.propertyType ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">Beds</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {externalProperty?.beds ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">Baths</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {externalProperty?.baths ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">Area</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {externalProperty?.size ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">ID</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {externalProperty?.referNo ??
                          externalProperty?.refer_no ??
                          'N/A'}
                      </div>
                    </li>
                  </ul>
                </div>
              </CardText>
            </CardBody>
          </Col>
        </Row>
      </Card>
    ) : null;
  };

  const RenderListingPropertyDetails = () => {
    const property = deal?.new_listing_request;

    return (
      <Card className="card-bordered">
        <Row>
          <Col sm="3" className="relative">
            <CardImg
              src={'/img/private-property.svg'}
              alt=""
              width="100%"
              height="100%"
              className="absolute"
              style={{
                objectFit: 'cover',
              }}
            />
          </Col>

          <Col sm="9">
            <CardBody className="card-inner">
              <CardTitle tag="h6" className="d-flex justify-content-between">
                <span className="text-secondary">{'New Listing Request'}</span>
                <div className="d-flex flex-column flex-md-row">
                  {property?.exclusive != undefined ? (
                    property?.exclusive ? (
                      <Badge className="d-sm-inline-flex border-0 bg-info text-info badge badge-dot badge-md d-none has-bg">
                        Exclusive
                      </Badge>
                    ) : (
                      <Badge className="d-sm-inline-flex border-0 bg-warning text-warning badge badge-dot badge-md d-none has-bg">
                        Non-Exclusive
                      </Badge>
                    )
                  ) : null}
                  {property?.isOffPlanResale ? (
                    <Badge
                      pill
                      color="primary-dim"
                      className="mt-2 mt-md-0 d-sm-inline-flex badge text-primary border-0 badge-md d-none"
                    >
                      Off-Plan Resale
                    </Badge>
                  ) : null}
                </div>
              </CardTitle>
              <CardTitle tag="h6">
                <span className="text-primary">
                  AED {formatValue(property?.price ?? 0)}
                </span>
              </CardTitle>
              <CardTitle tag="h5"></CardTitle>
              <Icon name="location"></Icon>{' '}
              <span>
                {property?.communityName ??
                  property?.community?.community ??
                  'N/A'}
              </span>
              <CardText>
                <div className="product-meta">
                  <ul
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(5, 1fr)',
                      gap: '1rem',
                    }}
                  >
                    <li>
                      <div className="text-muted fs-14px">Type</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.type ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">Property Type</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.property_type?.propertyType ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">Listing Type</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.type ?? 'N/A'}
                      </div>
                    </li>
                    {property?.isOffPlanResale &&
                    property?.amountAlreadyPaid ? (
                      <li>
                        <div className="text-muted fs-14px">
                          Amount Already Paid
                        </div>
                        <div className="fw-bold text-secondary fs-16px">
                          {formatValue(property?.amountAlreadyPaid) ?? 'N/A'}
                        </div>
                      </li>
                    ) : null}
                    <li>
                      <div className="text-muted fs-14px">Beds</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.beds ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">Baths</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.baths ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">Area</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.size ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">ID</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.referNo ?? property?.refer_no ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">Furnishing</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.furnishing ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">Vacancy</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.vacancy ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">
                        Contract Validity
                      </div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.contractValidity ?? 'N/A'}
                      </div>
                    </li>
                    {property?.type === 'Rent' && (
                      <li>
                        <div className="text-muted fs-14px">
                          Number of Cheques
                        </div>
                        <div className="fw-bold text-secondary fs-16px">
                          {property?.numberOfCheques ?? 'N/A'}
                        </div>
                      </li>
                    )}
                    <li>
                      <div className="text-muted fs-14px">
                        Vacant on Transfer?
                      </div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.vacantOnTransfer !== undefined
                          ? property.vacantOnTransfer
                            ? 'Yes'
                            : 'No'
                          : 'N/A'}
                      </div>
                    </li>
                    {property?.exclusive == undefined ? (
                      <li>
                        <div className="text-muted fs-14px">Exclusive</div>
                        <div className="fw-bold text-secondary fs-16px">
                          {'N/A'}
                        </div>
                      </li>
                    ) : null}
                    {property?.isOffPlanResale == undefined ? (
                      <li>
                        <div className="text-muted fs-14px">
                          Off-Plan Resale?
                        </div>
                        <div className="fw-bold text-secondary fs-16px">
                          {'N/A'}
                        </div>
                      </li>
                    ) : null}
                  </ul>
                  <hr />
                  <span className="fw-bold">Amenities</span>
                  <div
                    className="d-flex flex-wrap mt-2"
                    style={{ gap: '1rem' }}
                  >
                    {property?.amenities?.map((amenity) => (
                      <Badge
                        key={`listing-amenity-${amenity?.id}`}
                        className="border-0 bg-gray-dim text-dark badge badge-md has-bg"
                        color="gray"
                      >
                        {amenity?.amenity}
                      </Badge>
                    ))}
                  </div>
                  <hr />
                  <span className="fw-bold">
                    Related Details about property
                  </span>
                  <p>{property?.relatedInfo ? property.relatedInfo : 'N/A'}</p>
                </div>
              </CardText>
            </CardBody>
          </Col>
        </Row>
        {/* Popup for editing price */}
        <Modal isOpen={showPopup} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a href="#cancel" className="close">
              <Icon name="cross-sm" onClick={() => setShowPopup(false)}></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Edit User</h5>
              <div className="mt-4">
                {/* <form onSubmit={ handleSubmit( onFormSubmit ) }> */}
                <form onSubmit={(e) => handleSubmitPrice(e)}>
                  <Row className="g-3">
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      defaultValue={editedPrice}
                      onChange={(e) =>
                        setEditedPrice(parseFloat(e.target.value))
                      }
                    />
                  </Row>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Card>
    );
  };

  const RenderOffplanPropertyDetails = () => {
    const property = deal?.new_listing_offplan;
    return (
      <Card className="card-bordered">
        <Row>
          <Col sm="3" className="relative">
            <CardImg
              src={'/img/private-property.svg'}
              alt=""
              width="100%"
              height="100%"
              className="absolute"
              style={{
                objectFit: 'cover',
              }}
            />
          </Col>

          <Col sm="9">
            <CardBody className="card-inner">
              <CardTitle tag="h6">
                <span className="text-secondary">{'New Listing Offplan'}</span>
              </CardTitle>
              <CardTitle tag="h6">
                <span className="text-primary">
                  AED {formatDecimalValue(property?.agreedSalesPrice ?? 0)}
                </span>
              </CardTitle>
              <CardTitle tag="h5"></CardTitle>
              <Icon name="location"></Icon>{' '}
              <span>
                {property?.communityName ??
                  property?.community?.community ??
                  'N/A'}
              </span>
              <CardText>
                <div className="product-meta">
                  <ul className="d-flex g-3 gx-5">
                    <li>
                      <div className="text-muted fs-14px">Type</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {'Off-Plan'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">Listing Type</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.propertyType}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">Beds</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.beds ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">Baths</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.baths ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">Area</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.size ?? 'N/A'}
                      </div>
                    </li>
                    <li>
                      <div className="text-muted fs-14px">ID</div>
                      <div className="fw-bold text-secondary fs-16px">
                        {property?.unitId ?? 'N/A'}
                      </div>
                    </li>
                  </ul>
                </div>
              </CardText>
            </CardBody>
          </Col>
        </Row>
      </Card>
    );
  };

  const RenderClientDocuments = () => {
    const clientId = deal?.user_id;

    const [selectedEid, setSelectedEid] = useState(null);
    const [selectedPassport, setSelectedPassport] = useState(null);
    const [displayEid, setDisplayEid] = useState(null);
    const [displayPassport, setDisplayPassport] = useState(null);
    const [clientDocs, setClientDocs] = useState(
      dealDocs?.filter((doc) =>
        [DocumentTypes.EID, DocumentTypes.Passport].includes(doc?.type)
      )
    );
    const [openModal, setOpenModal] = useState(false);

    const [uploading, setUploading] = useState(false);

    const handleUploadDocuments = async () => {
      if (!selectedEid && !selectedPassport) {
        toast.error('Please Select documents to upload');
        return;
      }
      try {
        setUploading(true);
        // upload documents to s3 and upload to documents db
        if (selectedEid) {
          // if there is any old document, we have to delete
          const found = clientDocs?.find(
            (doc) => doc?.type === DocumentTypes.EID
          );
          const filePath = `/user/eid-${uuid()}.${selectedEid?.name
            ?.split('.')
            ?.pop()}`;

          const docParams = {
            user_id: clientId,
            path: filePath,
            // deal_id: deal?.id,
            type: DocumentTypes.EID,
          };

          await uploadToS3(
            selectedEid,
            filePath,
            selectedEid?.type ?? 'image/*',
            axios
          );

          await axios.post('/documents', docParams);

          if (found) {
            try {
              await axios.delete(`/documents/${found?.id ?? found?._id}`);
            } catch (err) {}
          }
        }

        if (selectedPassport) {
          const found = clientDocs?.find(
            (doc) => doc?.type === DocumentTypes.Passport
          );

          const filePath = `/user/eid-${uuid()}.${selectedPassport?.name
            ?.split('.')
            ?.pop()}`;

          const docParams = {
            user_id: clientId,
            path: filePath,
            // deal_id: deal?.id,
            type: DocumentTypes.Passport,
          };

          await uploadToS3(
            selectedPassport,
            filePath,
            selectedPassport?.type ?? 'image/*',
            axios
          );

          await axios.post('/documents', docParams);

          if (found) {
            try {
              await axios.delete(`/documents/${found?.id ?? found?._id}`);
            } catch (err) {}
          }
        }

        // if selectedEid or selectedpassport, we want to call progress deal so that the status of the deal can be updaated
        if (selectedEid || selectedPassport) {
          // refetch client docs when done uploading
          await fetchDealDocuments();

          setOpenModal(false);
          dispatch(fetchDeal(axios, id));
        }
      } catch (err) {
        console.log(err);
        toast.error('Unexpected server error.');
      } finally {
        setUploading(false);
      }
    };

    return (
      <Card className="mb-3 p-4 text-primary">
        <BlockHead size="sm">
          <BlockBetween>
            <BlockTitle tag="h5">Client Documents</BlockTitle>
          </BlockBetween>
        </BlockHead>
        {clientDocs && clientDocs?.length
          ? clientDocs?.map((doc, i) => (
              <React.Fragment key={(doc?.id ?? doc?._id) + i}>
                <MyCollapsibleCard
                  key={doc?.id}
                  id={doc?.id}
                  title={
                    doc?.type === DocumentTypes.EID ? 'Emirates ID' : doc?.type
                  }
                  content={doc?.content}
                  isOpen={openCardId === doc?.id}
                  toggleCollapse={() => toggleCollapse(doc?.id)}
                  isImage={doc?.path}
                  imagePath={doc?.path}
                />
              </React.Fragment>
            ))
          : null}

        <p className="position-relative text-center">
          {!clientDocs?.length ? 'No documents to show ' : 'Edit Documents '}
          <span
            className="fw-medium text-decoration-underline"
            role="button"
            onClick={(e) => {
              setOpenModal(true);
            }}
          >
            Upload
          </span>
        </p>

        <Modal
          backdrop="static"
          isOpen={openModal}
          toggle={() => setOpenModal(false)}
        >
          <ModalHeader>Upload Client Documents</ModalHeader>
          <ModalBody>
            <Row className="g-gs">
              <Col sm="6">
                <div className="nk-upload-form">
                  <Label>
                    <h6>Emirates ID</h6>
                  </Label>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      const file = acceptedFiles[0];
                      if (!file) {
                        return;
                      }
                      const urlObject = URL.createObjectURL(file);

                      setSelectedEid(file);
                      setDisplayEid(urlObject);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          className="dz-clickable bg-lighter my-2 dropzone small upload-zone"
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message">
                            <span className="dz-message-text">
                              <span>Drag and drop</span> image file here or{' '}
                              <span>browse</span>
                            </span>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                {displayEid && (
                  <div
                    style={{
                      border: '2px dashed #000;',
                      height: '15rem',
                    }}
                    className="position-relative"
                  >
                    <img
                      src={displayEid}
                      alt="Selected"
                      className="position-absolute h-100"
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                    <Button
                      type="button"
                      onClick={() => {
                        setSelectedEid(null);
                        setDisplayEid(null);
                      }}
                      className="top-0 left-0 position-absolute bg-danger"
                    >
                      <Icon name="trash"></Icon>
                    </Button>
                  </div>
                )}
              </Col>
              <Col sm="6">
                <div className="nk-upload-form">
                  <Label>
                    <h6>Passport</h6>
                  </Label>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      const file = acceptedFiles[0];
                      if (!file) {
                        return;
                      }
                      const urlObject = URL.createObjectURL(file);

                      setSelectedPassport(file);
                      setDisplayPassport(urlObject);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          className="dz-clickable bg-lighter my-2 dropzone small upload-zone"
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message">
                            <span className="dz-message-text">
                              <span>Drag and drop</span> image file here or{' '}
                              <span>browse</span>
                            </span>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                {displayPassport && (
                  <div
                    style={{
                      border: '2px dashed #000;',
                      height: '15rem',
                    }}
                    className="position-relative"
                  >
                    <img
                      src={displayPassport}
                      alt="Selected"
                      className="position-absolute h-100"
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                    <Button
                      type="button"
                      onClick={() => {
                        setSelectedPassport(null);
                        setDisplayPassport(null);
                      }}
                      className="top-0 left-0 position-absolute bg-danger"
                    >
                      <Icon name="trash"></Icon>
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color={'secondary'}
              onClick={() => {
                setSelectedEid(null);
                setSelectedPassport(null);
                setDisplayEid(null);
                setDisplayPassport(null);
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color={'primary'}
              onClick={handleUploadDocuments}
              className="ml-4"
              disabled={uploading}
            >
              {uploading ? (
                <>
                  <Spinner size={'sm'} /> Please Wait
                </>
              ) : (
                'Upload'
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </Card>
    );
  };

  const RenderExternalSellerDocs = () => {
    const agencyType = deal?.sellerExternalUser?.type;

    const [file, setFile] = useState(null);
    const [uploadText, setUploadText] = useState('Upload');

    const [fileType, setFileType] = useState(
      agencyType === AgencyType.RealEstateAgency
        ? {
            label: DocumentTypes.TradeLicense,
            value: DocumentTypes.TradeLicense,
          }
        : agencyType === AgencyType.Freelancer
        ? {
            label: DocumentTypes.RERARegistration,
            value: DocumentTypes.RERARegistration,
          }
        : {
            label: DocumentTypes.EID,
            value: DocumentTypes.EID,
          }
    );

    const fileTypeOptions = [
      {
        label: DocumentTypes.TradeLicense,
        value: DocumentTypes.TradeLicense,
      },
      {
        label: DocumentTypes.RERARegistration,
        value: DocumentTypes.RERARegistration,
      },
      {
        label: DocumentTypes.EID,
        value: DocumentTypes.EID,
      },
    ];

    const handleFileChangeFile = (e) => {
      const file = e?.target?.files[0];

      if (file && file?.type?.includes('image/')) {
        setFile(file);
      }
    };

    const handleUploadDocument = async () => {
      try {
        setUploadText('Please Wait...');

        const filePath = `agencies/rera-${uuid()}.${file?.name
          ?.split('.')
          ?.pop()}`;

        const params = {
          documentType: fileType?.value ?? fileType?.label,
          document: filePath,
        };

        await uploadToS3(file, filePath, 'image/*', axios);

        const { data } = await axios.patch(
          `/agency/${deal?.sellerExternalUserId}`,
          params
        );

        dispatch(fetchDeal(axios, id ?? deal?.id));

        setUploadText('Uploaded');
      } catch (err) {
        setUploadText('Try Again');
      }
    };

    return (
      <>
        {deal?.sellerExternalUser?.document ? (
          <div>
            <MyCollapsibleCard
              title={
                deal?.sellerExternalUser?.documentType === DocumentTypes.EID
                  ? 'Emirates ID'
                  : deal?.sellerExternalUser?.documentType
              }
              isImage={true}
              imagePath={deal?.sellerExternalUser?.document}
            />
          </div>
        ) : (
          <>
            <div className="pb-3 text-center">
              <span>No documents available.</span>
              <span
                style={{
                  position: 'relative',
                }}
              >
                <input
                  type="file"
                  id="inputExternalBuyer"
                  style={{
                    position: 'absolute',
                    visibility: 'hidden',
                    zIndex: -1,
                  }}
                  accept="image/*"
                  onChange={handleFileChangeFile}
                />{' '}
                <label
                  htmlFor="inputExternalBuyer"
                  className="fw-medium text-decoration-underline"
                >
                  Click to upload
                </label>
              </span>
            </div>
            {file && (
              <div>
                <img
                  src={URL.createObjectURL(file)}
                  alt=""
                  style={{ maxHeight: 200 }}
                />

                <div className="d-flex justify-content-end pb-3">
                  <div style={{ flex: '1' }} className="me-2">
                    <RSelect
                      value={fileType ?? 'Hello options'}
                      options={fileTypeOptions}
                      onChange={(value) => setFileType(value)}
                      className="flex-1"
                      style={{ flex: 1 }}
                    />
                  </div>
                  <Button
                    className="bg-primary text-white"
                    disabled={uploadText === 'Please Wait...'}
                    onClick={handleUploadDocument}
                  >
                    {uploadText}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const RenderInternalSellerDocs = () => {
    const [docs, setDocs] = useState(
      dealDocs?.filter(
        (doc) =>
          [DocumentTypes.EID, DocumentTypes.Passport].includes(doc?.type) &&
          doc?.user_id === deal?.sellerInternalUserId
      )
    );

    const [selectedEid, setSelectedEid] = useState(null);
    const [selectedPassport, setSelectedPassport] = useState(null);
    const [displayEid, setDisplayEid] = useState(null);
    const [displayPassport, setDisplayPassport] = useState(null);

    const [openModal, setOpenModal] = useState(false);

    const [uploading, setUploading] = useState(false);

    const handleUploadDocuments = async () => {
      if (!selectedEid && !selectedPassport) {
        toast.error('Please Select documents to upload');
        return;
      }
      try {
        setUploading(true);
        // upload documents to s3 and upload to documents db
        if (selectedEid) {
          const found = docs?.find((doc) => doc?.type === DocumentTypes.EID);

          const filePath = `/user/eid-${uuid()}.${selectedEid?.name
            ?.split('.')
            ?.pop()}`;

          const docParams = {
            user_id: deal?.sellerInternalUserId,
            path: filePath,
            // deal_id: deal?.id,
            type: DocumentTypes.EID,
          };

          await uploadToS3(
            selectedEid,
            filePath,
            selectedEid?.type ?? 'image/*',
            axios
          );

          await axios.post('/documents', docParams);

          if (found) {
            try {
              await axios.delete(`/documents/${found?.id ?? found?._id}`);
            } catch (err) {}
          }
        }

        if (selectedPassport) {
          const found = docs?.find(
            (doc) => doc?.type === DocumentTypes.Passport
          );

          const filePath = `/user/eid-${uuid()}.${selectedPassport?.name
            ?.split('.')
            ?.pop()}`;

          const docParams = {
            user_id: deal?.sellerInternalUserId,
            path: filePath,
            // deal_id: deal?.id,
            type: DocumentTypes.Passport,
          };

          await uploadToS3(
            selectedPassport,
            filePath,
            selectedPassport?.type ?? 'image/*',
            axios
          );

          await axios.post('/documents', docParams);

          if (found) {
            try {
              await axios.delete(`/documents/${found?.id ?? found?._id}`);
            } catch (err) {}
          }
        }

        // refetch client docs when done uploading
        await fetchDealDocuments();
        // console.log("Uploading documents");
        setOpenModal(false);
      } catch (err) {
        console.log(err);
        toast.error('Unexpected server error.');
      } finally {
        setUploading(false);
      }
    };

    return (
      <>
        {docs?.length ? (
          <>
            {docs?.map((doc) => (
              <MyCollapsibleCard
                title={
                  doc?.type === DocumentTypes.EID ? 'Emirates ID' : doc?.type
                }
                isImage={true}
                imagePath={doc?.path}
                key={doc?.id ?? doc?._id}
              />
            ))}
          </>
        ) : (
          <div>
            No documents available{' '}
            <span
              className="fw-medium text-decoration-underline"
              role="button"
              onClick={(e) => {
                setOpenModal(true);
              }}
            >
              Upload
            </span>
          </div>
        )}

        <Modal
          backdrop="static"
          isOpen={openModal}
          toggle={() => setOpenModal(false)}
        >
          <ModalHeader>Upload Seller/Landlord Documents</ModalHeader>
          <ModalBody>
            <Row className="g-gs">
              <Col sm="6">
                <div className="nk-upload-form">
                  <Label>
                    <h6>Emirates ID</h6>
                  </Label>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      const file = acceptedFiles[0];
                      if (!file) {
                        return;
                      }
                      const urlObject = URL.createObjectURL(file);

                      setSelectedEid(file);
                      setDisplayEid(urlObject);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          className="dz-clickable bg-lighter my-2 dropzone small upload-zone"
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message">
                            <span className="dz-message-text">
                              <span>Drag and drop</span> image file here or{' '}
                              <span>browse</span>
                            </span>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                {displayEid && (
                  <div
                    style={{
                      border: '2px dashed #000;',
                      height: '15rem',
                    }}
                    className="position-relative"
                  >
                    <img
                      src={displayEid}
                      alt="Selected"
                      className="position-absolute h-100"
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                    <Button
                      type="button"
                      onClick={() => {
                        setSelectedEid(null);
                        setDisplayEid(null);
                      }}
                      className="top-0 left-0 position-absolute bg-danger"
                    >
                      <Icon name="trash"></Icon>
                    </Button>
                  </div>
                )}
              </Col>
              <Col sm="6">
                <div className="nk-upload-form">
                  <Label>
                    <h6>Passport</h6>
                  </Label>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      const file = acceptedFiles[0];
                      if (!file) {
                        return;
                      }
                      const urlObject = URL.createObjectURL(file);

                      setSelectedPassport(file);
                      setDisplayPassport(urlObject);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          className="dz-clickable bg-lighter my-2 dropzone small upload-zone"
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message">
                            <span className="dz-message-text">
                              <span>Drag and drop</span> image file here or{' '}
                              <span>browse</span>
                            </span>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                {displayPassport && (
                  <div
                    style={{
                      border: '2px dashed #000;',
                      height: '15rem',
                    }}
                    className="position-relative"
                  >
                    <img
                      src={displayPassport}
                      alt="Selected"
                      className="position-absolute h-100"
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                    <Button
                      type="button"
                      onClick={() => {
                        setSelectedPassport(null);
                        setDisplayPassport(null);
                      }}
                      className="top-0 left-0 position-absolute bg-danger"
                    >
                      <Icon name="trash"></Icon>
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color={'secondary'}
              onClick={() => {
                setSelectedEid(null);
                setSelectedPassport(null);
                setDisplayEid(null);
                setDisplayPassport(null);
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color={'primary'}
              onClick={handleUploadDocuments}
              className="ml-4"
              disabled={uploading}
            >
              {uploading ? (
                <>
                  <Spinner size={'sm'} /> Please Wait
                </>
              ) : (
                'Upload'
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  };

  const RenderInternalBuyerDocs = () => {
    const [docs, setDocs] = useState(
      dealDocs?.filter(
        (doc) =>
          [DocumentTypes.EID, DocumentTypes.Passport].includes(doc?.type) &&
          doc?.user_id === deal?.buyerInternalUserId
      )
    );

    const [selectedEid, setSelectedEid] = useState(null);
    const [selectedPassport, setSelectedPassport] = useState(null);
    const [displayEid, setDisplayEid] = useState(null);
    const [displayPassport, setDisplayPassport] = useState(null);

    const [openModal, setOpenModal] = useState(false);

    const [uploading, setUploading] = useState(false);

    const handleUploadDocuments = async () => {
      if (!selectedEid && !selectedPassport) {
        toast.error('Please Select documents to upload');
        return;
      }
      try {
        setUploading(true);
        // upload documents to s3 and upload to documents db
        if (selectedEid) {
          const found = docs?.find((doc) => doc?.type === DocumentTypes.EID);

          const filePath = `/user/eid-${uuid()}.${selectedEid?.name
            ?.split('.')
            ?.pop()}`;

          const docParams = {
            user_id: deal?.buyerInternalUserId,
            path: filePath,
            // deal_id: deal?.id,
            type: DocumentTypes.EID,
          };

          await uploadToS3(
            selectedEid,
            filePath,
            selectedEid?.type ?? 'image/*',
            axios
          );

          await axios.post('/documents', docParams);
          if (found) {
            try {
              await axios.delete(`/documents/${found?.id ?? found?._id}`);
            } catch (err) {}
          }
        }

        if (selectedPassport) {
          const found = docs?.find(
            (doc) => doc?.type === DocumentTypes.Passport
          );

          const filePath = `/user/eid-${uuid()}.${selectedPassport?.name
            ?.split('.')
            ?.pop()}`;

          const docParams = {
            user_id: deal?.buyerInternalUserId,
            path: filePath,
            // deal_id: deal?.id,
            type: DocumentTypes.Passport,
          };

          await uploadToS3(
            selectedPassport,
            filePath,
            selectedPassport?.type ?? 'image/*',
            axios
          );

          await axios.post('/documents', docParams);

          if (found) {
            try {
              await axios.delete(`/documents/${found?.id ?? found?._id}`);
            } catch (err) {}
          }
        }

        // refetch client docs when done uploading
        await fetchDealDocuments();
        // console.log("Uploading documents");
        setOpenModal(false);
      } catch (err) {
        console.log(err);
        toast.error('Unexpected server error.');
      } finally {
        setUploading(false);
      }
    };

    // const getDocs = async () => {
    //   try {
    //     if (!deal?.buyerInternalUserId) {
    //       return;
    //     }

    //     const docs = await getClientDocuments(axios, deal?.buyerInternalUserId);

    //     setDocs(
    //       docs?.filter(
    //         (doc) =>
    //           doc?.type === DocumentTypes.EID ||
    //           doc?.type === DocumentTypes.Passport
    //       )
    //     );
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };

    // useEffect(() => {
    //   deal?.buyerInternalUserId && getDocs();
    // }, []);

    return (
      <>
        {docs?.length ? (
          <>
            {docs?.map((doc) => (
              <MyCollapsibleCard
                title={
                  doc?.type === DocumentTypes.EID ? 'Emirates ID' : doc?.type
                }
                isImage={true}
                imagePath={doc?.path}
                key={doc?.id ?? doc?._id}
              />
            ))}
          </>
        ) : (
          <div>
            No documents available{' '}
            <span
              className="fw-medium text-decoration-underline"
              role="button"
              onClick={(e) => {
                setOpenModal(true);
              }}
            >
              Upload
            </span>
          </div>
        )}

        <Modal
          backdrop="static"
          isOpen={openModal}
          toggle={() => setOpenModal(false)}
        >
          <ModalHeader>Upload Buyer/Tenant Documents</ModalHeader>
          <ModalBody>
            <Row className="g-gs">
              <Col sm="6">
                <div className="nk-upload-form">
                  <Label>
                    <h6>Emirates ID</h6>
                  </Label>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      const file = acceptedFiles[0];
                      if (!file) {
                        return;
                      }
                      const urlObject = URL.createObjectURL(file);

                      setSelectedEid(file);
                      setDisplayEid(urlObject);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          className="dz-clickable bg-lighter my-2 dropzone small upload-zone"
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message">
                            <span className="dz-message-text">
                              <span>Drag and drop</span> image file here or{' '}
                              <span>browse</span>
                            </span>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                {displayEid && (
                  <div
                    style={{
                      border: '2px dashed #000;',
                      height: '15rem',
                    }}
                    className="position-relative"
                  >
                    <img
                      src={displayEid}
                      alt="Selected"
                      className="position-absolute h-100"
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                    <Button
                      type="button"
                      onClick={() => {
                        setSelectedEid(null);
                        setDisplayEid(null);
                      }}
                      className="top-0 left-0 position-absolute bg-danger"
                    >
                      <Icon name="trash"></Icon>
                    </Button>
                  </div>
                )}
              </Col>
              <Col sm="6">
                <div className="nk-upload-form">
                  <Label>
                    <h6>Passport</h6>
                  </Label>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      const file = acceptedFiles[0];
                      if (!file) {
                        return;
                      }
                      const urlObject = URL.createObjectURL(file);

                      setSelectedPassport(file);
                      setDisplayPassport(urlObject);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          className="dz-clickable bg-lighter my-2 dropzone small upload-zone"
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message">
                            <span className="dz-message-text">
                              <span>Drag and drop</span> image file here or{' '}
                              <span>browse</span>
                            </span>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                {displayPassport && (
                  <div
                    style={{
                      border: '2px dashed #000;',
                      height: '15rem',
                    }}
                    className="position-relative"
                  >
                    <img
                      src={displayPassport}
                      alt="Selected"
                      className="position-absolute h-100"
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                    <Button
                      type="button"
                      onClick={() => {
                        setSelectedPassport(null);
                        setDisplayPassport(null);
                      }}
                      className="top-0 left-0 position-absolute bg-danger"
                    >
                      <Icon name="trash"></Icon>
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color={'secondary'}
              onClick={() => {
                setSelectedEid(null);
                setSelectedPassport(null);
                setDisplayEid(null);
                setDisplayPassport(null);
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color={'primary'}
              onClick={handleUploadDocuments}
              className="ml-4"
              disabled={uploading}
            >
              {uploading ? (
                <>
                  <Spinner size={'sm'} /> Please Wait
                </>
              ) : (
                'Upload'
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  };

  const RenderExternalBuyerDocs = () => {
    const agencyType = deal?.buyerExternalUser?.type;

    const [file, setFile] = useState(null);
    const [uploadText, setUploadText] = useState('Upload');

    const [fileType, setFileType] = useState(
      agencyType === AgencyType.RealEstateAgency
        ? {
            label: DocumentTypes.TradeLicense,
            value: DocumentTypes.TradeLicense,
          }
        : agencyType === AgencyType.Freelancer
        ? {
            label: DocumentTypes.RERARegistration,
            value: DocumentTypes.RERARegistration,
          }
        : {
            label: DocumentTypes.EID,
            value: DocumentTypes.EID,
          }
    );

    const fileTypeOptions = [
      {
        label: DocumentTypes.TradeLicense,
        value: DocumentTypes.TradeLicense,
      },
      {
        label: DocumentTypes.RERARegistration,
        value: DocumentTypes.RERARegistration,
      },
      {
        label: DocumentTypes.EID,
        value: DocumentTypes.EID,
      },
    ];

    const handleFileChangeFile = (e) => {
      const file = e?.target?.files[0];

      if (file && file?.type?.includes('image/')) {
        setFile(file);
      }
    };

    const handleUploadDocument = async () => {
      try {
        setUploadText('Please Wait...');

        const filePath = `agencies/rera-${uuid()}.${file?.name
          ?.split('.')
          ?.pop()}`;

        const params = {
          documentType: fileType?.value ?? fileType?.label,
          document: filePath,
        };

        await uploadToS3(file, filePath, 'image/*', axios);

        const { data } = await axios.patch(
          `/agency/${deal?.buyerExternalUserId}`,
          params
        );

        dispatch(fetchDeal(axios, id ?? deal?.id));

        setUploadText('Uploaded');
      } catch (err) {
        setUploadText('Try Again');
      }
    };

    return (
      <>
        {deal?.buyerExternalUser?.document ? (
          <div>
            <MyCollapsibleCard
              title={
                deal?.buyerExternalUser?.documentType === DocumentTypes.EID
                  ? 'Emirates ID'
                  : deal?.buyerExternalUser?.documentType
              }
              isImage={true}
              imagePath={deal?.buyerExternalUser?.document}
            />
          </div>
        ) : (
          <>
            <div className="pb-3 text-center">
              <span>No documents available.</span>
              <span
                style={{
                  position: 'relative',
                }}
              >
                <input
                  type="file"
                  id="inputExternalBuyer"
                  style={{
                    position: 'absolute',
                    visibility: 'hidden',
                    zIndex: -1,
                  }}
                  accept="image/*"
                  onChange={handleFileChangeFile}
                />{' '}
                <label
                  htmlFor="inputExternalBuyer"
                  className="fw-medium text-decoration-underline"
                >
                  Click to upload
                </label>
              </span>
            </div>
            {file && (
              <div>
                <img
                  src={URL.createObjectURL(file)}
                  alt=""
                  style={{ maxHeight: 200 }}
                />

                <div className="d-flex justify-content-end pb-3">
                  <div style={{ flex: '1' }} className="me-2">
                    <RSelect
                      value={fileType ?? 'Hello options'}
                      options={fileTypeOptions}
                      onChange={(value) => setFileType(value)}
                      className="flex-1"
                      style={{ flex: 1 }}
                    />
                  </div>
                  <Button
                    className="bg-primary text-white"
                    disabled={uploadText === 'Please Wait...'}
                    onClick={handleUploadDocument}
                  >
                    {uploadText}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const RenderSecPorpertyClientsDocs = () => {
    const sellerClientType = deal?.sellerclientType;
    const buyerClientType = deal?.buyerClientType;

    return (
      <>
        <Card className="mb-3 p-4 text-primary">
          <BlockHead size="sm">
            <BlockBetween>
              <BlockTitle tag="h5">Clients Documents</BlockTitle>
            </BlockBetween>
          </BlockHead>
          <div className="border-primary bg-white mt-3 mb-1 border data-head">
            <h6 className="text-primary overline-title">
              Buyer/Tenant Documents
            </h6>
          </div>
          {buyerClientType === DealClientType.Alba ? (
            <RenderInternalBuyerDocs />
          ) : buyerClientType === DealClientType.External ? (
            <RenderExternalBuyerDocs />
          ) : null}

          <div className="border-primary bg-white mt-3 mb-1 border data-head">
            <h6 className="text-primary overline-title">
              Seller/Landlord Documents
            </h6>
          </div>
          {sellerClientType === DealClientType?.Alba ? (
            <RenderInternalSellerDocs />
          ) : sellerClientType === DealClientType?.External ? (
            <RenderExternalSellerDocs />
          ) : null}
        </Card>
      </>
    );
  };

  const RenderDealDocuments = () => {
    const [selectedTitleDeed, setSelectedTitleDeed] = useState(null);
    const [selectedEjari, setSelectedEjari] = useState(null);
    const [displayTitleDeed, setDisplayTitleDeed] = useState(null);
    const [displayEjari, setDisplayEjari] = useState(null);
    const [selectedReceipt, setSelectedReceipt] = useState(null);
    const [selectedReservation, setSelectedReservation] = useState(null);
    const [displayReceipt, setDisplayReceipt] = useState(null);
    const [displayReservation, setDisplayReservation] = useState(null);

    const dealDocsLength = dealDocs?.filter(
      (doc) => doc?.type !== DocumentTypes.Other
    )?.length;

    const [openModal, setOpenModal] = useState(false);

    const [uploading, setUploading] = useState(false);

    const handleUploadDocuments = async () => {
      if (
        !selectedTitleDeed &&
        !selectedEjari &&
        !selectedReceipt &&
        !selectedReservation
      ) {
        toast.error('Please Select documents to upload');
        return;
      }
      try {
        setUploading(true);
        // upload documents to s3 and upload to documents db
        if (selectedTitleDeed) {
          // if there is older title deed, we want to delete this file
          const found = dealDocs?.find(
            (doc) => doc?.type === DocumentTypes.TitleDeed
          );

          const filePath = `/deals/title-deed-${uuid()}.${selectedTitleDeed?.name
            ?.split('.')
            ?.pop()}`;

          const docParams = {
            user_id:
              deal?.buyerInternalUserId ??
              deal?.sellerInternalUserId ??
              deal?.user_id,
            path: filePath,
            deal_id: deal?.id,
            type: DocumentTypes.TitleDeed,
          };

          await uploadToS3(
            selectedTitleDeed,
            filePath,
            selectedTitleDeed?.type ?? 'image/*',
            axios
          );

          await axios.post('/documents', docParams);

          if (found) {
            try {
              await axios.delete(`/documents/${found?.id ?? found?._id}`);
            } catch (err) {}
          }
        }

        if (selectedEjari) {
          // if there is older ejari, we want to delete this file
          const found = dealDocs?.find(
            (doc) => doc?.type === DocumentTypes.Ejari
          );

          const filePath = `/deals/ejari-${uuid()}.${selectedEjari?.name
            ?.split('.')
            ?.pop()}`;

          const docParams = {
            user_id:
              deal?.buyerInternalUserId ??
              deal?.sellerInternalUserId ??
              deal?.user_id,
            path: filePath,
            deal_id: deal?.id,
            type: DocumentTypes.Ejari,
          };

          await uploadToS3(
            selectedEjari,
            filePath,
            selectedEjari?.type ?? 'image/*',
            axios
          );

          await axios.post('/documents', docParams);
          if (found) {
            try {
              await axios.delete(`/documents/${found?.id ?? found?._id}`);
            } catch (err) {}
          }
        }

        if (selectedReceipt) {
          // if there is older receipt, we want to delete this file
          const found = dealDocs?.find(
            (doc) => doc?.type === DocumentTypes.Receipt
          );

          const filePath = `/deals/receipt-${uuid()}.${selectedReceipt?.name
            ?.split('.')
            ?.pop()}`;

          const docParams = {
            user_id:
              deal?.buyerInternalUserId ??
              deal?.sellerInternalUserId ??
              deal?.user_id,
            path: filePath,
            deal_id: deal?.id,
            type: DocumentTypes.Receipt,
          };

          await uploadToS3(
            selectedReceipt,
            filePath,
            selectedReceipt?.type ?? 'image/*',
            axios
          );

          await axios.post('/documents', docParams);

          if (found) {
            try {
              await axios.delete(`/documents/${found?.id ?? found?._id}`);
            } catch (err) {}
          }
        }

        if (selectedReservation) {
          // if there is older reservation, we want to delete this file
          const found = dealDocs?.find(
            (doc) => doc?.type === DocumentTypes.Reservation
          );
          const filePath = `/deals/reservation-${uuid()}.${selectedReservation?.name
            ?.split('.')
            ?.pop()}`;

          const docParams = {
            user_id:
              deal?.buyerInternalUserId ??
              deal?.sellerInternalUserId ??
              deal?.user_id,
            path: filePath,
            deal_id: deal?.id,
            type: DocumentTypes.Reservation,
          };

          await uploadToS3(
            selectedReservation,
            filePath,
            selectedReservation?.type ?? 'image/*',
            axios
          );

          await axios.post('/documents', docParams);
          if (found) {
            try {
              await axios.delete(`/documents/${found?.id ?? found?._id}`);
            } catch (err) {}
          }
        }

        if (
          ((selectedReceipt && selectedReservation) ||
            (selectedEjari && selectedTitleDeed)) &&
          deal?.status === DealStatus.CollectingDocuments
        ) {
          dispatch(fetchDeal(axios, deal?.id));
        }

        // refetch client docs when done uploading
        await fetchDealDocuments();
        // console.log("Uploading documents");
        setOpenModal(false);
      } catch (err) {
        console.log(err);
        toast.error('Unexpected server error.');
      } finally {
        setUploading(false);
      }
    };

    return (
      <Card className="mb-3 p-4 text-primary">
        <BlockHead size="sm">
          <BlockBetween>
            <BlockTitle tag="h5">Deal Documents</BlockTitle>
          </BlockBetween>
        </BlockHead>
        <>
          {dealDocs && dealDocs?.length
            ? dealDocs
                ?.filter(
                  (doc) =>
                    doc?.type !== DocumentTypes.Other &&
                    doc?.type !== DocumentTypes.EID &&
                    doc?.type !== DocumentTypes.Passport
                )
                ?.map((doc) => (
                  <div key={doc?.id ?? doc?._id}>
                    <MyCollapsibleCard
                      title={doc?.type}
                      isImage={true}
                      imagePath={doc?.path}
                      key={doc?.id ?? doc?._id}
                    />
                  </div>
                ))
            : null}
          <div>
            <p className="position-relative text-center">
              {!dealDocsLength ? 'No documents available ' : 'Edit Documents '}
              <span
                className="fw-medium text-decoration-underline"
                role="button"
                onClick={(e) => {
                  setOpenModal(true);
                }}
              >
                Upload
              </span>
            </p>
          </div>
        </>

        {/* (
            {/* (
          <div>
            <p className="position-relative text-center">
            No documents available{" "}
            <span
              className="fw-medium text-decoration-underline"
              role="button"
              onClick={(e) => {
                setOpenModal(true);
              }}
            >
              Upload
            </span>
            </p>
          </div>
        ) */}

        <Modal
          backdrop="static"
          isOpen={openModal}
          toggle={() => setOpenModal(false)}
        >
          <ModalHeader>Upload Deal Documents</ModalHeader>
          <ModalBody>
            <Row className="g-gs">
              {deal?.category === DealCategory.SecondaryProperty ? (
                <>
                  {deal?.purpose === DealPurpose.Rent ? (
                    <>
                      <Col sm="6">
                        <div className="nk-upload-form">
                          <Label>
                            <h6>Receipt</h6>
                          </Label>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              const file = acceptedFiles[0];
                              if (!file) {
                                return;
                              }
                              const urlObject = URL.createObjectURL(file);

                              setSelectedReceipt(file);
                              setDisplayReceipt(urlObject);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div
                                  {...getRootProps()}
                                  className="dz-clickable bg-lighter my-2 dropzone small upload-zone"
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message">
                                    <span className="dz-message-text">
                                      <span>Drag and drop</span> image file here
                                      or <span>browse</span>
                                    </span>
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                        {displayReceipt && (
                          <div
                            style={{
                              border: '2px dashed #000;',
                              height: '15rem',
                            }}
                            className="position-relative"
                          >
                            <img
                              src={displayReceipt}
                              alt="Selected"
                              className="position-absolute h-100"
                              style={{
                                objectFit: 'cover',
                                objectPosition: 'center',
                              }}
                            />
                            <Button
                              type="button"
                              onClick={() => {
                                setSelectedReceipt(null);
                                setDisplayReceipt(null);
                              }}
                              className="top-0 left-0 position-absolute bg-danger"
                            >
                              <Icon name="trash"></Icon>
                            </Button>
                          </div>
                        )}
                      </Col>
                      <Col sm="6">
                        <div className="nk-upload-form">
                          <Label>
                            <h6>Reservation</h6>
                          </Label>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              const file = acceptedFiles[0];
                              if (!file) {
                                return;
                              }
                              const urlObject = URL.createObjectURL(file);

                              setSelectedReservation(file);
                              setDisplayReservation(urlObject);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div
                                  {...getRootProps()}
                                  className="dz-clickable bg-lighter my-2 dropzone small upload-zone"
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message">
                                    <span className="dz-message-text">
                                      <span>Drag and drop</span> image file here
                                      or <span>browse</span>
                                    </span>
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                        {displayReservation && (
                          <div
                            style={{
                              border: '2px dashed #000;',
                              height: '15rem',
                            }}
                            className="position-relative"
                          >
                            <img
                              src={displayReservation}
                              alt="Selected"
                              className="position-absolute h-100"
                              style={{
                                objectFit: 'cover',
                                objectPosition: 'center',
                              }}
                            />
                            <Button
                              type="button"
                              onClick={() => {
                                setSelectedReservation(null);
                                setDisplayReservation(null);
                              }}
                              className="top-0 left-0 position-absolute bg-danger"
                            >
                              <Icon name="trash"></Icon>
                            </Button>
                          </div>
                        )}
                      </Col>
                    </>
                  ) : deal?.purpose === DealPurpose.Sale ? (
                    <>
                      <Col sm="6">
                        <div className="nk-upload-form">
                          <Label>
                            <h6>Title Deed</h6>
                          </Label>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              const file = acceptedFiles[0];
                              if (!file) {
                                return;
                              }
                              const urlObject = URL.createObjectURL(file);

                              setSelectedTitleDeed(file);
                              setDisplayTitleDeed(urlObject);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div
                                  {...getRootProps()}
                                  className="dz-clickable bg-lighter my-2 dropzone small upload-zone"
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message">
                                    <span className="dz-message-text">
                                      <span>Drag and drop</span> image file here
                                      or <span>browse</span>
                                    </span>
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                        {displayTitleDeed && (
                          <div
                            style={{
                              border: '2px dashed #000;',
                              height: '15rem',
                            }}
                            className="position-relative"
                          >
                            <img
                              src={displayTitleDeed}
                              alt="Selected"
                              className="position-absolute h-100"
                              style={{
                                objectFit: 'cover',
                                objectPosition: 'center',
                              }}
                            />
                            <Button
                              type="button"
                              onClick={() => {
                                setSelectedTitleDeed(null);
                                setDisplayTitleDeed(null);
                              }}
                              className="top-0 left-0 position-absolute bg-danger"
                            >
                              <Icon name="trash"></Icon>
                            </Button>
                          </div>
                        )}
                      </Col>
                      <Col sm="6">
                        <div className="nk-upload-form">
                          <Label>
                            <h6>Ejari</h6>
                          </Label>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              const file = acceptedFiles[0];
                              if (!file) {
                                return;
                              }
                              const urlObject = URL.createObjectURL(file);

                              setSelectedEjari(file);
                              setDisplayEjari(urlObject);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div
                                  {...getRootProps()}
                                  className="dz-clickable bg-lighter my-2 dropzone small upload-zone"
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message">
                                    <span className="dz-message-text">
                                      <span>Drag and drop</span> image file here
                                      or <span>browse</span>
                                    </span>
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                        {displayEjari && (
                          <div
                            style={{
                              border: '2px dashed #000;',
                              height: '15rem',
                            }}
                            className="position-relative"
                          >
                            <img
                              src={displayEjari}
                              alt="Selected"
                              className="position-absolute h-100"
                              style={{
                                objectFit: 'cover',
                                objectPosition: 'center',
                              }}
                            />
                            <Button
                              type="button"
                              onClick={() => {
                                setSelectedEjari(null);
                                setDisplayEjari(null);
                              }}
                              className="top-0 left-0 position-absolute bg-danger"
                            >
                              <Icon name="trash"></Icon>
                            </Button>
                          </div>
                        )}
                      </Col>
                    </>
                  ) : null}
                </>
              ) : deal?.category === DealCategory.ListingAcquired ||
                deal?.category === DealCategory.PrimaryOffplan ? (
                <>
                  <Col sm="6">
                    <div className="nk-upload-form">
                      <Label>
                        <h6>Title Deed</h6>
                      </Label>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          const file = acceptedFiles[0];
                          if (!file) {
                            return;
                          }
                          const urlObject = URL.createObjectURL(file);

                          setSelectedTitleDeed(file);
                          setDisplayTitleDeed(urlObject);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              className="dz-clickable bg-lighter my-2 dropzone small upload-zone"
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message">
                                <span className="dz-message-text">
                                  <span>Drag and drop</span> image file here or{' '}
                                  <span>browse</span>
                                </span>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    {displayTitleDeed && (
                      <div
                        style={{
                          border: '2px dashed #000;',
                          height: '15rem',
                        }}
                        className="position-relative"
                      >
                        <img
                          src={displayTitleDeed}
                          alt="Selected"
                          className="position-absolute h-100"
                          style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                          }}
                        />
                        <Button
                          type="button"
                          onClick={() => {
                            setSelectedTitleDeed(null);
                            setDisplayTitleDeed(null);
                          }}
                          className="top-0 left-0 position-absolute bg-danger"
                        >
                          <Icon name="trash"></Icon>
                        </Button>
                      </div>
                    )}
                  </Col>
                  <Col sm="6">
                    <div className="nk-upload-form">
                      <Label>
                        <h6>Ejari</h6>
                      </Label>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          const file = acceptedFiles[0];
                          if (!file) {
                            return;
                          }
                          const urlObject = URL.createObjectURL(file);

                          setSelectedEjari(file);
                          setDisplayEjari(urlObject);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              className="dz-clickable bg-lighter my-2 dropzone small upload-zone"
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message">
                                <span className="dz-message-text">
                                  <span>Drag and drop</span> image file here or{' '}
                                  <span>browse</span>
                                </span>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    {displayEjari && (
                      <div
                        style={{
                          border: '2px dashed #000;',
                          height: '15rem',
                        }}
                        className="position-relative"
                      >
                        <img
                          src={displayEjari}
                          alt="Selected"
                          className="position-absolute h-100"
                          style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                          }}
                        />
                        <Button
                          type="button"
                          onClick={() => {
                            setSelectedEjari(null);
                            setDisplayEjari(null);
                          }}
                          className="top-0 left-0 position-absolute bg-danger"
                        >
                          <Icon name="trash"></Icon>
                        </Button>
                      </div>
                    )}
                  </Col>
                </>
              ) : null}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color={'secondary'}
              onClick={() => {
                setSelectedTitleDeed(null);
                setSelectedEjari(null);
                setDisplayTitleDeed(null);
                setDisplayEjari(null);
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color={'primary'}
              onClick={handleUploadDocuments}
              className="ml-4"
              disabled={uploading}
            >
              {uploading ? (
                <>
                  <Spinner size={'sm'} /> Please Wait
                </>
              ) : (
                'Upload'
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </Card>
    );
  };

  const RenderOtherDealDocuments = () => {
    const otherDocs = dealDocs
      ?.filter((doc) => doc?.type === DocumentTypes.Other)
      ?.map((item) => item?.documents)
      ?.flat();

    return (
      <Card className="mb-3 p-4 text-primary">
        <BlockHead size="sm">
          <BlockBetween>
            <BlockTitle tag="h5">Other Deal Documents</BlockTitle>
          </BlockBetween>
        </BlockHead>
        {otherDocs &&
          otherDocs.length > 0 &&
          otherDocs.map((doc, i) => (
            <MyCollapsibleCard
              title={doc?.name}
              imagePath={doc?.value}
              key={dealDocs[i] ?? 'deal-' + `${i}`}
            />
          ))}
        {authuser &&
          (authuser.role === newRoles.Admin ||
            authuser.role === newRoles.MasterAdmin ||
            (authuser.role === newRoles.Agent &&
              deal?.status !== DealStatus.Completed)) && (
            <div className="nk-upload-form">
              <Dropzone
                onDrop={(acceptedFiles) =>
                  handleDropOtherDocuments(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      className="dz-clickable bg-lighter my-2 dropzone small upload-zone"
                    >
                      <input {...getInputProps()} />
                      <div className="dz-message">
                        <span className="dz-message-text">
                          <span>Drag and drop</span> image files here or{' '}
                          <span>browse</span>
                        </span>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          )}
        <Row className="g-gs">
          {otherDocuments && otherDocuments?.length
            ? otherDocuments?.map((file, i) => (
                <Col key={`other-doc-${i}`} md="6" lg="4" xxl="3">
                  <div
                    style={{
                      border: '2px dashed #000;',
                      height: '15rem',
                    }}
                    className="position-relative"
                  >
                    {imageUrlRegex.test(file?.name) ? (
                      <img
                        src={URL.createObjectURL(file)}
                        alt="Selected"
                        className="position-absolute h-100"
                        style={{
                          objectFit: 'cover',
                          objectPosition: 'center',
                        }}
                      />
                    ) : (
                      <p>{file?.name}</p>
                    )}
                    <Button
                      onClick={() => handleDeleteOtherDocuments(i)}
                      type="button"
                      className="top-0 left-0 position-absolute bg-danger"
                    >
                      <Icon name="trash"></Icon>
                    </Button>
                  </div>
                </Col>
              ))
            : null}
        </Row>
        {otherDocuments && otherDocuments.length > 0 && (
          <div className="clearfix actions">
            <ul>
              <li>
                <Button
                  color="primary"
                  type="button"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Spinner size="sm" color="light" />
                      Please Wait
                    </>
                  ) : (
                    'Upload'
                  )}
                </Button>
              </li>
            </ul>
          </div>
        )}
      </Card>
    );
  };

  const showEditBtn = (deal) => {
    if (authuser?.role === newRoles.MasterAdmin) return true;
    switch (deal?.status) {
      case DealStatus.Approved:
      case DealStatus.Canceled:
      case DealStatus.Completed:
      case DealStatus.Rejected:
        return false;
      default:
        return true;
    }
  };
  const [edit, setEdit] = useState({ priceInfo: false, property: false });
  const [editing, setEditing] = useState(false);
  const handleEdit = async (payload) => {
    try {
      setEditing((c) => ({
        ...c,
        priceInfo: true,
      }));
      await axios.patch(`/deals/${id}`, payload);
      dispatch(fetchDeal(axios, id));
      toast.success('Deal Updated');
    } catch (error) {
      console.log(error);
    } finally {
      setEditing(false);
      setEdit((c) => ({
        ...c,
        priceInfo: false,
      }));
    }
  };

  const getDealCommission = (deal, { withTax = false }) => {
    let totalCommission = 0;
    if (deal?.buyerAgreedCommission||deal?.sellerAgreedCommission) {
    
      totalCommission += ((deal?.sellerAgreedCommission ?? 0) + (deal?.buyerAgreedCommission ?? 0))
    } else {
      totalCommission += (deal?.agreedCommission ?? 0)
    }

    return withTax ? Number((totalCommission + (0.05 * totalCommission)).toFixed(6)) : Number(totalCommission.toFixed(6));
  }

  const [isMortgageEditing, setIsMortgageEditing] = useState(false);
  const [isMortgageUpdateLoading, setIsMortgageUpdateLoading] = useState(false);

  const handleToggleEdit = () => {
    setIsMortgageEditing(!isMortgageEditing);
  };

  const handleMortgageSave = async (updatedValues) => {    
    setIsMortgageUpdateLoading(true);
    try {
      await axios.patch(`/deals/${id}`,updatedValues)
      dispatch(fetchDeal(axios, id))
      showToast("Mortgage Updated", 'success')
      } catch (error) {
        showToast("Server Error", 'error')
      }finally{
        setIsMortgageEditing(false);
        setIsMortgageUpdateLoading(false);
      }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <React.Fragment>
      {/* <ScrollToTop></ScrollToTop> */}
      <Head title="User Details - Regular"></Head>
      {deal && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <Button
                  color="light"
                  outline
                  className="d-sm-inline-flex bg-white d-none mb-4"
                  onClick={() => navigate(-1)}
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <a
                  href="#back"
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(-1);
                  }}
                  className="d-inline-flex bg-white d-sm-none btn btn-icon btn-outline-light mb-4"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
                <BlockTitle tag="h3" page>
                  {deal?.category === DealCategory.ListingAcquired
                    ? 'List Acquired'
                    : 'Deal'}{' '}
                  /{' '}
                  <strong className="text-primary small">
                    {deal?.reference_number}
                  </strong>
                </BlockTitle>

                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      {deal?.category === DealCategory.ListingAcquired
                        ? 'List Acquired'
                        : 'Deal'}{' '}
                      ID:{' '}
                      <span className="text-base">
                        {deal?.reference_number}
                      </span>
                    </li>
                    <li>
                      Created At:{' '}
                      <span className="text-base">
                        {getDateStructured(new Date(deal?.createdAt))}{' '}
                        {calcTime(new Date(deal?.createdAt))}
                      </span>
                    </li>
                    <li>
                      Last Modified:{' '}
                      <span className="text-base">
                        {getDateStructured(
                          new Date(deal?.updatedAt ?? deal?.creation_date)
                        )}{' '}
                        {calcTime(
                          new Date(deal?.updatedAt ?? deal?.creation_date)
                        )}
                      </span>
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <ul className="nav-tabs-mb-icon nav nav-tabs nav-tabs-card">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === 'personal' ? 'active' : ''
                        }`}
                        href="#personal"
                        onClick={(ev) => handleTabClick(ev, 'personal')}
                      >
                        <Icon name="user-circle"></Icon>
                        <span>
                          {deal?.category === DealCategory.ListingAcquired
                            ? 'List Acquired'
                            : 'Deal'}{' '}
                          Info
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={classNames({
                          'nav-link': true,
                          active: activeTab === 'transactions',
                          disabled:
                            deal?.category === DealCategory.ListingAcquired,
                        })}
                        href="#transactions"
                        onClick={(ev) => handleTabClick(ev, 'transactions')}
                      >
                        <Icon name="repeat"></Icon>
                        <span>Transactions</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === 'documents' ? 'active' : ''
                        }`}
                        href="#documents"
                        onClick={(ev) => handleTabClick(ev, 'documents')}
                      >
                        <Icon name="file-text"></Icon>
                        <span>Documents</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === 'activities' ? 'active' : ''
                        }`}
                        href="#activities"
                        onClick={(ev) => handleTabClick(ev, 'activities')}
                      >
                        <Icon name="activity"></Icon>
                        <span>Activities</span>
                      </a>
                    </li>
                    <li className="nav-item-trigger d-xxl-none nav-item">
                      <Button
                        className={`toggle btn-icon btn-trigger ${
                          sideBar && 'active'
                        }`}
                        onClick={toggle}
                      >
                        <Icon name="user-list-fill"></Icon>
                      </Button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className={`tab-pane fade ${
                        activeTab === 'personal' ? 'show active' : ''
                      }`}
                      id="personal"
                    >
                      <div className="card-inner">
                        <Block>
                          <BlockHead>
                            <BlockTitle tag="h5">
                              {deal?.category === DealCategory.ListingAcquired
                                ? 'List Acquired'
                                : 'Deal'}{' '}
                              Details
                            </BlockTitle>
                            <p>
                              user info, Agent Info,
                              {deal?.category === DealCategory.ListingAcquired
                                ? 'List Acquired'
                                : 'Deal'}{' '}
                              Status
                            </p>
                          </BlockHead>
                          <div className="data-list nk-data">
                            <div className="data-head">
                              <h6 className="overline-title">
                                {deal?.category === DealCategory.ListingAcquired
                                  ? 'List Acquired'
                                  : 'Deal'}{' '}
                                Information
                              </h6>
                            </div>
                            <div className="data-item">
                              <Col sm="6">
                                <div className="data-col">
                                  <span className="data-label">
                                    Ownership of Listing
                                  </span>
                                  <span className="data-value">
                                    {deal?.category ===
                                    DealCategory.PrimaryOffplan
                                      ? deal?.new_listing_offplan?.offplan
                                          ?.developmentName +
                                        ', ' +
                                        deal?.new_listing_offplan?.offplan
                                          ?.emirate
                                      : deal?.category ===
                                          DealCategory.SecondaryProperty &&
                                        deal?.sellerclientType}
                                  </span>
                                </div>
                                <div className="data-col-end data-col"></div>
                              </Col>
                            </div>
                            <div className="data-item">
                              <Col sm="6">
                                <div className="data-col">
                                  <span className="data-label">
                                    {deal?.category ===
                                    DealCategory.ListingAcquired
                                      ? 'List Acquired'
                                      : 'Deal'}{' '}
                                    Purpose
                                  </span>
                                  <span className="data-value">
                                    {deal?.category ===
                                    DealCategory.PrimaryOffplan
                                      ? 'Sale'
                                      : deal?.purpose}
                                  </span>
                                </div>
                                <div className="data-col-end data-col"></div>
                              </Col>
                            </div>
                          </div>
                          {deal?.category === DealCategory.SecondaryProperty ? (
                            <RenderBuyerAndSellerInfo />
                          ) : deal?.category === DealCategory.ListingAcquired ||
                            deal?.category === DealCategory?.PrimaryOffplan ? (
                            <RenderClientInfo />
                          ) : null}
                        </Block>
                        {(deal?.category === DealCategory.SecondaryProperty ||
                            deal?.category === DealCategory?.PrimaryOffplan) &&
                            <Block>
                              {!isMortgageEditing ? (
                                <MortgageDetailsView deal={deal} onEdit={handleToggleEdit} />
                              ) : (
                                <MortgageDetailsEdit
                                  deal={deal}
                                  onSave={handleMortgageSave}
                                  onCancel={handleToggleEdit}
                                  isLoading={isMortgageUpdateLoading}
                                />
                              )}
                            </Block>
                          }
                          
                        <div className="divider md nk-divider"></div>
                        <Block    className={"position-relative"}>
                          <BlockHead size="sm">
                            <BlockBetween>
                              <BlockTitle tag="h5">
                                {edit.property
                                  ? 'Edit Property Details'
                                  : 'Property Details'}
                                  {!edit.property&&<Button onClick={()=>setEdit(c=>({...c,property:true}))} color={"transparent"} className={"text-primary fs-5"}><Icon name={"edit"}/></Button>}
                              </BlockTitle>
                            </BlockBetween>
                          </BlockHead>
                          {!edit.property ? (
                            <div
                            
                            >
                              
                              {deal?.category ===
                              DealCategory.SecondaryProperty ? (
                                <RenderSecondaryPropertyDetails />
                              ) : deal?.category ===
                                DealCategory.ListingAcquired ? (
                                <RenderListingPropertyDetails />
                              ) : deal?.category ===
                                DealCategory.PrimaryOffplan ? (
                                <RenderOffplanPropertyDetails />
                              ) : null}
                            </div>
                          ) : null}
                          <EditNewListing
                            newListing={deal?.new_listing_request}
                            isOpen={edit.property}
                            toggle={(e) =>
                              setEdit((c) => ({ ...c, property: false }))
                            }
                            dealId={id}
                          />
                        </Block>
                        <div className="divider md nk-divider"></div>
                        <Block>
                          <BlockHead size="sm">
                            <BlockBetween>
                              <BlockTitle tag="h5">Admin Note</BlockTitle>
                              <a
                                href="#addnote"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setAddNoteModal(true);
                                }}
                                className="link link-sm"
                              >
                                + Add Note
                              </a>
                            </BlockBetween>
                          </BlockHead>
                          <div className="bq-note">
                            {noteData?.map((item) => (
                              <div className="bq-note-item" key={item.id}>
                                <div className="bq-note-text">
                                  <p>{item.description}</p>
                                </div>
                                <div className="bq-note-meta">
                                  <span className="bq-note-added">
                                    Added on{' '}
                                    <span className="date">
                                      {item?.createdAt &&
                                        format(
                                          new Date(item?.createdAt),
                                          'dd/MM/yyyy hh:mm a'
                                        )}
                                    </span>{' '}
                                    at <span className="time">{item.time}</span>
                                  </span>
                                  <span className="bq-note-sep sep">|</span>
                                  <span className="bq-note-by">
                                    By{' '}
                                    <span>
                                      {`${item.created_by?.first_name} ${item.created_by?.last_name}`}
                                    </span>
                                  </span>
                                  {deal?.rejection?.some(
                                    (dl) => dl.note === item.id
                                  ) ? (
                                    <a
                                      href="#deletenote"
                                      disabled
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                      className="link link-gray link-sm"
                                      style={{
                                        pointerEvents: 'none',
                                      }}
                                    >
                                      Delete Note
                                    </a>
                                  ) : (
                                    <a
                                      href="#deletenote"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        deleteNote(item.id);
                                      }}
                                      className="link link-danger link-sm"
                                    >
                                      {deletingNote === item?.id
                                        ? 'Deleting'
                                        : 'Delete Note'}
                                    </a>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </Block>
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === 'transactions' ? 'show active' : ''
                      }`}
                      id="transactions"
                    >
                      <CardInner>
                        <Block>
                          <BlockHead>
                            {/* <BlockTitle tag="h5">Transactions</BlockTitle>
                            <p className="mb-4">
                              All{" "}
                              {deal?.category === DealCategory.ListingAcquired
                                ? " List Acquired"
                                : "Deal"}{" "}
                              that are associated with this client.
                            </p> */}
                          </BlockHead>
                        </Block>
                        {deal?.category !== DealCategory.ListingAcquired && (
                          <DealTransactionsContainer
                            deal={deal}
                            refetchFn={toggleRefresh}
                          />
                        )}
                      </CardInner>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === 'documents' ? 'show active' : ''
                      }`}
                      id="documents"
                    >
                      {deal?.status === DealStatus.CollectingDocuments &&
                      dealDocs?.length ? (
                        <div className="flex justify-end">
                          <Button
                            color="primary"
                            className="me-4"
                            disabled={submitting}
                            onClick={handleSubmitForApproval}
                          >
                            {submitting ? (
                              <>
                                Please Wait <Spinner size={'sm'} />
                              </>
                            ) : (
                              'Submit For Approval'
                            )}
                          </Button>
                        </div>
                      ) : null}
                      <Block></Block>
                      <div className="card-inner">
                        {deal?.category === DealCategory.SecondaryProperty ? (
                          <RenderSecPorpertyClientsDocs />
                        ) : (
                          <RenderClientDocuments />
                        )}
                        <RenderDealDocuments />
                        <RenderOtherDealDocuments />
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === 'photos' ? 'show active' : ''
                      }`}
                      id="photos"
                    >
                      <Block></Block>
                      <div className="card-inner">
                        {deal?.category === DealCategory.SecondaryProperty ? (
                          <RenderSecPorpertyClientsDocs />
                        ) : (
                          <RenderClientDocuments />
                        )}
                        <RenderDealDocuments />
                        <RenderOtherDealDocuments />
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === 'activities' ? 'show active' : ''
                      }`}
                      id="activities"
                    >
                      <div className="card-inner">
                        <Block>
                          <BlockHead>
                            <BlockTitle tag="h5">Activities</BlockTitle>
                            <p className="mb-4">
                              All actions that relate to a specific property
                            </p>
                          </BlockHead>
                        </Block>
                        <div className="timeline">
                          <ul className="timeline-list">
                            {tasks?.map((item, index) => {
                              const isDateLeft = index % 2 === 0;
                              const isPriceLeft = !isDateLeft;
                              return (
                                <li className="timeline-item" key={item.title}>
                                  <div
                                    className={`timeline-status bg-primary ${
                                      isPriceLeft ? 'left' : 'right'
                                    }`}
                                  >
                                    <span className="timeline-price">
                                      {item.price}
                                    </span>
                                  </div>

                                  <div
                                    className={`timeline-date ${
                                      isDateLeft ? 'left' : 'right'
                                    }`}
                                    style={{
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {formatDate(item?.date)}
                                  </div>
                                  <div
                                    className={`timeline-title ${
                                      isDateLeft ? 'right' : 'left'
                                    }`}
                                  >
                                    <Card className="ms-5">
                                      <CardHeader className="bg-light text-primary">
                                        {item?.type}{' '}
                                      </CardHeader>
                                      <CardText className="p-2">
                                        {item?.description}
                                      </CardText>
                                      <Icon name="ok"></Icon>
                                    </Card>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal
                  isOpen={addNoteModal}
                  toggle={() => setAddNoteModal(false)}
                  className="modal-dialog-centered"
                  size="lg"
                >
                  <ModalBody>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setAddNoteModal(false);
                        setAddNoteText('');
                      }}
                      className="close"
                    >
                      <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                      <h5 className="title">Add Admin Note</h5>
                      <div className="mt-4 mb-4">
                        <textarea
                          defaultValue={addNoteText}
                          className="form-control no-resize"
                          onChange={(e) => setAddNoteText(e.target.value)}
                        />
                      </div>
                      <ul className="flex-wrap flex-sm-nowrap align-center gx-4 gy-2">
                        <li>
                          <Button
                            color="primary"
                            size="md"
                            type="submit"
                            onClick={submitNote}
                            disabled={creatingNote}
                          >
                            {creatingNote ? (
                              <>
                                <Spinner size={'sm'} /> Please Wait
                              </>
                            ) : (
                              'Add Note'
                            )}
                          </Button>
                        </li>
                        <li>
                          <Button
                            onClick={() => {
                              setAddNoteModal(false);
                              setAddNoteText('');
                            }}
                            className="link link-light"
                          >
                            Cancel
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </ModalBody>
                </Modal>

                <Sidebar toggleState={sideBar} className="sidebar-cus-width">
                  {deal?.category === DealCategory.SecondaryProperty ? (
                    <>
                      <RenderAgentCardMini
                        text={
                          deal?.buyerClientType === DealClientType.Alba
                            ? `${deal?.buyerAssignedAgent?.user?.first_name} ${deal?.buyerAssignedAgent?.user?.last_name}`
                                .split(' ')
                                ?.map((word) => word.charAt(0).toUpperCase())
                                .slice(0, 2)
                                .join('')
                            : deal?.buyerExternalUser?.agency_name
                                ?.split(' ')
                                ?.map((word) => word?.charAt(0).toUpperCase())
                                ?.slice(0, 2)
                                ?.join('')
                        }
                        titleText={
                          deal?.buyerClientType === DealClientType.Alba
                            ? `${deal?.buyerAssignedAgent?.user?.first_name} ${deal?.buyerAssignedAgent?.user?.last_name}`
                            : deal?.buyerExternalUser?.agency_name
                        }
                        imagePath={
                          deal?.buyerClientType === DealClientType.Alba
                            ? deal?.buyerAssignedAgent?.user?.image
                            : deal?.buyerExternalUser?.photo
                        }
                        subTitle={
                          deal?.buyerClientType === DealClientType.Alba
                            ? deal?.buyerAssignedAgent?.user?.email
                            : deal?.buyerExternalUser?.email
                        }
                      />
                      <RenderAgentCardMini
                        text={
                          deal?.sellerclientType === DealClientType.Alba
                            ? `${deal?.sellerAssignedAgent?.user?.first_name} ${deal?.sellerAssignedAgent?.user?.last_name}`
                                .split(' ')
                                ?.map((word) => word.charAt(0).toUpperCase())
                                .slice(0, 2)
                                .join('')
                            : deal?.sellerExternalUser?.agency_name
                                ?.split(' ')
                                ?.map((word) => word?.charAt(0).toUpperCase())
                                ?.slice(0, 2)
                                ?.join('')
                        }
                        titleText={
                          deal?.sellerclientType === DealClientType.Alba
                            ? `${deal?.sellerAssignedAgent?.user?.first_name} ${deal?.sellerAssignedAgent?.user?.last_name}`
                            : deal?.sellerExternalUser?.agency_name
                        }
                        imagePath={
                          deal?.sellerclientType === DealClientType.Alba
                            ? deal?.sellerAssignedAgent?.user?.image
                            : deal?.sellerExternalUser?.photo
                        }
                        subTitle={
                          deal?.sellerclientType === DealClientType.Alba
                            ? deal?.sellerAssignedAgent?.user?.email
                            : deal?.sellerExternalUser?.email
                        }
                      />
                    </>
                  ) : (
                    <>
                      <div className="card-inner">
                        <div className="mt-5 mt-xxl-0 user-card user-card-s2">
                          <UserAvatar
                            className="lg"
                            theme="primary"
                            text={`${deal?.assignedAgent?.user?.first_name} ${deal?.assignedAgent?.user?.last_name}`
                              ?.split(' ')
                              ?.map((word) => word?.charAt(0)?.toUpperCase())
                              ?.slice(0, 2)
                              ?.join('')}
                            image={assignedAgentImage}
                          />
                          <div className="user-info">
                            <Badge
                              tag="div"
                              className="ucap"
                              pill
                              color="outline-light"
                            >
                              {deal?.assignedAgent?.user?.role}
                            </Badge>
                            <h5>{`${
                              deal?.assignedAgent?.user?.first_name ??
                              deal?.assignedAgent?.user?.firstName ??
                              ''
                            } ${
                              deal?.assignedAgent?.user?.last_name ??
                              deal?.assignedAgent?.user?.lastName ??
                              ''
                            }`}</h5>
                            <span className="sub-text">
                              {deal?.assignedAgent?.user?.email ?? ''}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card-inner card-inner-sm">
                        <ul className="justify-center btn-toolbar gx-1">
                          <li>
                            <Button
                              onClick={(ev) => {
                                ev.preventDefault();
                                window.open(
                                  `mailto:${deal?.created_by?.email}`
                                );
                              }}
                              className="border-0 bg-white btn-icon btn-trigger"
                            >
                              <Icon name="shield-off"></Icon>
                            </Button>
                          </li>
                          <li>
                            <Button
                              href="#mail"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                              className="border-0 bg-white btn-icon btn-trigger"
                            >
                              <Icon name="mail"></Icon>
                            </Button>
                          </li>
                          <li>
                            <Button
                              href="#download"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                              className="border-0 bg-white btn-icon btn-trigger"
                            >
                              <Icon name="download-cloud"></Icon>
                            </Button>
                          </li>
                          <li>
                            <Button
                              href="#bookmark"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                              className="border-0 bg-white btn-icon btn-trigger"
                            >
                              <Icon name="bookmark"></Icon>
                            </Button>
                          </li>
                          <li>
                            <Button
                              href="#cancel"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                              className="border-0 bg-white btn-icon btn-trigger"
                            >
                              <Icon name="na"></Icon>
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </>
                  )}

                  <div className="card-inner position-relative">
                    {showEditBtn(deal) ? (
                      <div
                        onClick={(e) =>
                          setEdit((c) => ({
                            ...c,
                            priceInfo: true,
                          }))
                        }
                        className="text-primary position-absolute top-0 end-0 p-2 cursor-pointer "
                      >
                        <TooltipComponent
                          direction={'top'}
                          icon={'edit'}
                          id={'edit-price-details'}
                          text={'Edit Details'}
                        />
                      </div>
                    ) : null}
                    <div className="mb-2 overline-title-alt">In Account</div>
                    <div className="profile-balance">
                      <div className="gx-4 profile-balance-group  d-flex justify-content-between">
                        <div className="profile-balance-sub">
                          <div className="profile-balance-amount">
                            <div className="number">
                              <asmall className="currency currency-usd">
                                AED
                              </asmall>{' '}
                              {formatDecimalValue(deal?.agreedSalePrice)}
                            </div>
                          </div>
                          <div className="profile-balance-subtitle">
                            {deal?.category === DealCategory.ListingAcquired
                              ? 'List Acquired'
                              : 'Deal'}{' '}
                            Amount
                          </div>
                        </div>

                        <div className="profile-balance-sub">
                          <div className="profile-balance-amount">
                            <div className="number">
                              AED {formatDecimalValue(Number(calculateDealCommission(deal)))}
                            </div>
                          </div>
                          <div
                            className="profile-balance-subtitle"
                           
                          >
                            Commission Earned (
                            {deal?.agreedCommission ??
                              (deal?.buyerAgreedCommission ?? 0) +
                                (deal?.sellerAgreedCommission ?? 0)}
                            %)
                          </div>
                        </div>
                      </div>
                    </div>
                    {(deal?.agreedCommission ||
                    deal?.buyerAgreedCommission ||
                    deal?.sellerAgreedCommission )? (
                      <Col size={'12'} className={'mt-3 d-flex'}>
                        <div className="profile-balance-sub mt-3 d-flex align-items-center flex-column w-100">
                          <div className="profile-balance-amount">
                            <div className="number">
                              AED {formatDecimalValue(Number(vat((getDealCommission(deal,{withTax:false})*deal?.agreedSalePrice/100),deal?.agreedSalePrice)))}
                            </div>
                          </div>
                          <div className="profile-balance-subtitle">
                            Commission + Tax (5%)
                          </div>
                        </div>
                      </Col>
                    ) : null}
                  </div>
                  <div className="card-inner">
                    <Row className="text-center d-flex justify-content-between">
                      <Col size="6">
                        <div className="profile-stats">
                          <span className="amount">
                            {deal?.property_list
                              ? deal?.property_list?.createdAt
                                ? intervalToDuration({
                                    start: new Date(
                                      deal?.property_list?.createdAt
                                    ),
                                    end: today,
                                  }).days
                                : 'N/A'
                              : 0}{' '}
                            days
                          </span>
                          <span className="sub-text">
                            Property Time in Stock
                          </span>
                        </div>
                      </Col>
                      <Col size="6">
                        <div className="profile-stats">
                          <span className="amount">
                            {deal?.creation_date
                              ? intervalToDuration({
                                  start: new Date(deal?.creation_date),
                                  end: today,
                                }).days + ' days'
                              : deal?.createdAt
                              ? intervalToDuration({
                                  start: new Date(deal?.createdAt),
                                  end: today,
                                })?.days + ' days'
                              : 'N/A'}
                          </span>
                          <span className="sub-text">Time to Close Deal</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <h6 className="mb-2 overline-title-alt">Additional</h6>
                    <Row className="g-3">
                      <Col sm="6">
                        <span className="sub-text">Created By:</span>
                        <span>
                          {`${deal?.created_by?.first_name} ${deal?.created_by?.last_name}`}
                        </span>
                      </Col>
                      <Col sm="6">
                        <span className="sub-text">Deal Last Modified:</span>
                        <span>
                          {getDateStructured(
                            new Date(deal?.updatedAt ?? deal?.creation_date)
                          )}{' '}
                          {calcTime(
                            new Date(deal?.updatedAt ?? deal?.creation_date)
                          )}
                        </span>
                      </Col>
                      <Col sm="6">
                        <span className="sub-text">Deal Status:</span>
                        <span>{deal?.status}</span>
                      </Col>
                      <Col sm="6">
                        <span className="sub-text">Deal Created At:</span>
                        <span>
                          {getDateStructured(new Date(deal?.creation_date))}{' '}
                          {calcTime(new Date(deal?.creation_date))}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Sidebar>
                {sideBar && (
                  <div
                    className="toggle-overlay"
                    onClick={() => toggle()}
                  ></div>
                )}
              </div>
            </Card>
          </Block>
          <ConfirmModal
            cancel={cancel}
            isOpen={isOpen}
            proceed={proceed}
            prompt={prompt}
          />
          <EditModal
            deal={deal}
            isOpen={edit.priceInfo}
            loading={editing}
            onCancel={() =>
              setEdit((c) => ({
                ...c,
                priceInfo: false,
              }))
            }
            onSubmit={(data) => handleEdit(data)}
            toggle={() =>
              setEdit((c) => ({
                ...c,
                priceInfo: false,
              }))
            }
          />
        </Content>
      )}
    </React.Fragment>
  );
};
export default DealDetails;

const RenderAgentCardMini = ({
  text,
  imagePath,
  badgeText,
  titleText,
  subTitle,
}) => {
  const axios = useAxiosPrivate();

  const [image, setImage] = useState(null);

  const getAgentImage = async () => {
    const image = await getImage(imagePath, axios);

    image && setImage(image?.signedRequest);
  };

  useEffect(() => {
    imagePath && typeof imagePath === 'string' && getAgentImage();
  }, []);
  return (
    <Row className="align-items-center card-inner g-gs">
      <Col sm="3">
        <UserAvatar className="md" theme="primary" text={text} image={image} />
      </Col>
      <Col sm="7">
        <div className="user-info">
          <div>
            <h5>{titleText}</h5>
          </div>
          <span className="sub-text">{subTitle}</span>
        </div>
      </Col>
      <Col sm="2">
        <UncontrolledDropdown>
          <DropdownToggle color="transparent">
            <Button className="border-0 bg-white mr-1 btn-icon btn-trigger">
              <Icon name="more-h" />
            </Button>
          </DropdownToggle>
          <DropdownMenu>
            <div
              className="d-flex"
              style={{
                width: '15rem',
              }}
            >
              <ul className="d-flex justify-center list-unstyled btn-toolbar gx-1">
                <li>
                  <Button
                    onClick={(ev) => {
                      ev.preventDefault();
                      window.open();
                    }}
                    className="d-inline-flex border-0 bg-white btn-icon btn-trigger"
                  >
                    <Icon name="shield-off"></Icon>
                  </Button>
                </li>
                <li>
                  <Button
                    href="#mail"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="d-inline-flex border-0 bg-white btn-icon btn-trigger"
                  >
                    <Icon name="mail"></Icon>
                  </Button>
                </li>
                <li>
                  <Button
                    href="#download"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="d-inline-flex border-0 bg-white btn-icon btn-trigger"
                  >
                    <Icon name="download-cloud"></Icon>
                  </Button>
                </li>
                <li>
                  <Button
                    href="#bookmark"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="d-inline-flex border-0 bg-white btn-icon btn-trigger"
                  >
                    <Icon name="bookmark"></Icon>
                  </Button>
                </li>
                <li>
                  <Button
                    href="#cancel"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="d-inline-flex border-0 bg-white btn-icon btn-trigger"
                  >
                    <Icon name="na"></Icon>
                  </Button>
                </li>
              </ul>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Col>
    </Row>
  );
};

const ConfirmModal = ({ isOpen, proceed, prompt, cancel }) => {
  return (
    <Modal isOpen={isOpen} fade>
      <ModalHeader>
        <Icon name={'alert-fill'} />
        <span>Confirm</span>
      </ModalHeader>
      <ModalBody>{prompt}</ModalBody>
      <ModalFooter>
        <Button color={'secondary'} onClick={() => cancel(false)}>
          Cancel
        </Button>
        <Button color={'danger'} onClick={() => proceed(true)} className="ml-4">
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
const EditNewListing = ({ newListing, isOpen = false, toggle, dealId }) => {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const { amenities } = useSelector((state) => state.property);
  const amenityOptions =
    amenities
      ?.map((a) => ({
        ...a,
        label: a?.amenity,
        value: a?.id ?? a?._id,
      }))
      ?.filter((x) => x && x) ?? [];
  const bedOptions = NewBedRooms?.map((x) => ({
    label: x,
    value: x,
  }));
  const bathOptions = NewBathRooms?.map((x) => ({
    label: x,
    value: x,
  }));
  const furnishingOptions = Object.values(FurnishingTypes).map((x) => ({
    label: x,
    value: x,
  }));
  const purposeOptions = ListingType.map((c) => ({ label: c, value: c }));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    beds: newListing?.beds,
    baths: newListing?.beds,
    furnishing: newListing?.furnishing,
    size: newListing?.size,
    type: newListing?.type,
    price: newListing?.price,
    amenities: [],
  });
  useEffect(() => {
    dispatch(fetchAmenitiesList(axios));
  }, []);
  useEffect(() => {
    setData({
      ...(newListing ?? {}),
      amenities:
        newListing?.amenities
          ?.map((a) => ({
            ...a,
            label: a?.amenity,
            value: a?.id ?? a?._id,
          }))
          ?.filter((x) => x && x) ?? [],
      beds: bedOptions?.find(
        (x) => x.value?.toString() === newListing?.beds?.toString()
      ),
      baths: bathOptions?.find(
        (x) => x.value?.toString() === newListing?.baths?.toString()
      ),
      furnishing: furnishingOptions?.find(
        (x) => x.value?.toString() === newListing?.furnishing?.toString()
      ),
      purpose: purposeOptions.find((x) => x?.value === newListing?.type),
    });
  }, [newListing]);
  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      const payLoad = {
        amenityIds: data?.amenities?.length
          ? data?.amenities?.map((x) => x?.value)?.filter((x) => x && x)
          : newListing?.amenities?.map((x) => x?.id),
        propertyTypeId: data?.propertyType?.value,
        beds: data?.beds?.value,
        baths: data?.baths?.value,
        furnishing: data?.furnishing?.value,
        type: data?.purpose?.value,
        size: data?.size,
      };
      await axios.patch(
        `/propList/request/${newListing?.id ?? newListing?._id}`,
        payLoad
      );
      dispatch(fetchDeal(axios, dealId));
      toggle();
      toast.success('Property Updated');
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };
  return isOpen ? (
    <>
      <Card className="card-bordered">
        <div className="card-inner">
          <Row className="gy-2">
            <div className="d-flex justify-content-end">
              <button
                onClick={(e) => toggle()}
                className="close-btn"
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                }}
              >
                <Icon
                  name="cross"
                  style={{
                    fontSize: '1.5rem',
                    backgroundColor: '#fff',
                    color: 'red',
                    borderRadius: '0.2rem',
                  }}
                />
              </button>
            </div>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Purpose</label>
                <RSelect
                  onChange={(purpose) => setData((c) => ({ ...c, purpose }))}
                  value={data.purpose}
                  options={purposeOptions}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Beds</label>
                <RSelect
                  value={data.beds}
                  onChange={(beds) => setData((c) => ({ ...c, beds }))}
                  options={bedOptions}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Baths </label>
                <RSelect
                  value={data.baths}
                  onChange={(baths) => setData((c) => ({ ...c, baths }))}
                  options={bathOptions}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Size </label>
                <div className="form-control-wrap">
                  <div className="input-container">
                    <input
                      required
                      type="text"
                      id="first-name"
                      className="form-control"
                      name="size"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9.]/g, '');
                      }}
                      maxLength="10"
                      defaultValue={data.size}
                      onChange={(e) =>
                        setData((c) => ({ ...c, size: e.target.value }))
                      }
                      style={{ paddingRight: '60px' }}
                    />
                    <span
                      className="input-text"
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      sqft
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Furnishing </label>
                <div className="form-control-wrap">
                  <div className="input-container">
                    <RSelect
                      value={data.furnishing}
                      onChange={(furnishing) =>
                        setData((c) => ({ ...c, furnishing }))
                      }
                      options={furnishingOptions}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Amenities</label>
                <div className="form-control-wrap">
                  <div className="input-container">
                    <RSelect
                      value={data.amenities}
                      onChange={(amenities) =>
                        setData((c) => ({ ...c, amenities }))
                      }
                      options={amenityOptions}
                      isMulti
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="form-group d-flex justify-content-end mt-4">
                <Button onClick={(e) => handleSubmit(e)} color="success">
                  Save {loading && <Spinner size={'sm'} />}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  ) : null;
};

const EditModal = ({ isOpen, toggle, onSubmit, onCancel, loading, deal }) => {
  const commVal = (agreedCommission, price) => {
    return ((agreedCommission * price) / 100).toFixed(4);
  };
  const sellerComVal = (sellerAgreedCommission, price) => {
    return ((Number(sellerAgreedCommission) * price) / 100).toFixed(4);
  };
  const buyerComVal = (buyerAgreedCommission, price) => {
    return ((Number(buyerAgreedCommission )* Number(price)) / 100);
  };


  const [data, setData] = useState({
    agreedCommissionValue: 0,
    agreedCommission: 0,
    agreedSalePrice: 0,
    sellerAgreedCommission: 0,
    buyerAgreedCommission: 0,
    vat: 0,
    totalCommission: 0,
    sellerComValue: 0,
    buyerComValue: 0,
  });

  useEffect(() => {
    reset(deal,data,setData,commVal)
  }, [deal]);

  const handleCommisionChange = (e) => {
    setData((c) => {
      let value = e.target.value;
      return {
        ...c,
        agreedCommissionValue: formatValueToDecimal(
          commVal(value, data.agreedSalePrice)
        ),
        agreedCommission: value,
        vat: formatValueToDecimal(vat(commVal(value, data.agreedSalePrice))),
      };
    });
  };
  const handleCommissionValueChange = (e) => {
    setData((c) => {
      let value = e.target.value;
      return {
        ...c,
        agreedCommissionValue: Number(e.target.value),
        agreedCommission: (
          (Number(e.target.value) / data.agreedSalePrice) *
          100
        ).toFixed(6),
        vat: (Number(value) * 1.05).toFixed(4),
      };
    });
  };
 
  useEffect(() => {
    if (deal?.buyerAgreedCommission || deal?.sellerAgreedCommission) {
    } else {
      setData((c) => ({
        ...c,
        vat: (data.agreedCommissionValue * 1.05).toFixed(2),
      }));
    }
  }, [data.agreedCommissionValue, data.totalCommission]);

  return (
    <Modal isOpen={isOpen} size="lg">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (deal?.buyerAgreedCommission || deal?.sellerAgreedCommission) {
            delete data?.agreedCommission;
          }
          onSubmit(data);
        }}
      >
        <ModalHeader
          toggle={() => {
            if (deal?.buyerAgreedCommission || deal?.sellerAgreedCommission) {
              setData({
                agreedCommissionValue: commVal(
                  deal?.sellerAgreedCommission + deal?.buyerAgreedCommission,
                  deal?.agreedSalePrice
                ),
                totalCommission:
                  deal?.sellerAgreedCommission + deal?.buyerAgreedCommission,
                agreedSalePrice: deal?.agreedSalePrice,
                vat: vat(
                  commVal(
                    deal?.sellerAgreedCommission + deal?.buyerAgreedCommission,
                    deal?.agreedSalePrice
                  )
                ),
                sellerAgreedCommission: deal?.sellerAgreedCommission,
                buyerAgreedCommission: deal?.buyerAgreedCommission,
                buyerComValue: buyerComVal(
                  deal?.buyerAgreedCommission ?? 0,
                  deal?.agreedSalePrice
                ),
                sellerComValue: sellerComVal(
                  deal?.sellerAgreedCommission ?? 0,
                  deal?.agreedSalePrice
                ),
              });
            } else {
              setData({
                agreedCommissionValue: commVal(
                  deal?.agreedCommission,
                  deal?.agreedSalePrice
                ),
                agreedCommission: deal?.agreedCommission,
                agreedSalePrice: deal?.agreedSalePrice,
                vat: vat(
                  commVal(deal?.agreedCommission, deal?.agreedSalePrice)
                ),
              });
            }
            toggle();
          }}
        >
          <h6 className="title">Edit Deal Details</h6>
        </ModalHeader>
        <ModalBody className="py-2">
          {!(deal?.buyerAgreedCommission || deal?.sellerAgreedCommission) ? (
            <Row>
              <Col size={'12'} className={'my-2'}>
                <Label for="price">Listing Price</Label>

                <div className="form-control-wrap">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">AED</span>
                    </div>
                    <Input
                      id="price"
                      type="number"
                      required={true}
                      value={data?.agreedSalePrice}
                      onChange={(e) => {
                        setData((c) => ({
                          ...c,
                          agreedCommission: 0,
                          agreedCommissionValue: 0,
                          buyerAgreedCommission: 0,
                          sellerComValue: 0,
                          totalCommission: 0,
                          buyerComValue: 0,
                          vat: 0,
                          sellerAgreedCommission: 0,
                          agreedSalePrice: e.target.value,
                        }));
                      }}
                      placeholder="Enter Listing Price"
                    />
                  </div>
                </div>
              </Col>
              <Col
                size={ '8'
                }
                className={'my-2'}
              >
                <Label for="commission">Agreed Commission Value</Label>

                <div className="form-control-wrap">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">AED</span>
                    </div>
                    <Input
                      id="commission"
                      
                      type="number"
                      max={data?.agreedSalePrice}
                      required={true}
                      value={data?.agreedCommissionValue
                      }
                      onChange={handleCommissionValueChange}
                      placeholder="Agreed Commission"
                    />
                  </div>
                </div>
              </Col>
             
                <Col size={'4'} className={'my-2'}>
                  <Label for="commission-percent">Agreed Commission (%)</Label>

                  <div className="form-control-wrap">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">%</span>
                      </div>
                      <Input
                        max={100}
                        id="commission-percent"
                        type="number"
                        required={true}
                        value={data?.agreedCommission}
                        onChange={handleCommisionChange}
                        placeholder="Enter Agreed Commission (%)"
                      />
                    </div>
                  </div>
                </Col>
            
              <Col size={'12'} className={'my-2'}>
                <Label for="commission-percent">Commission + Tax (5%)</Label>

                <div className="form-control-wrap">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">AED</span>
                    </div>
                    <Input
                      disabled
                      id="commission-percent"
                      type="text"
                      required={true}
                      value={data.vat}
                      placeholder="VAT"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <SecondaryEdit
              agreedSalePrice={data.agreedSalePrice}
              buyerAgreedCommission={data?.buyerAgreedCommission??0}
              data={data}
              sellerAgreedCommission={data?.sellerAgreedCommission??0}
              setData={setData}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loading}
            color={'gray'}
            size={'md'}
            type={'button'}
            onClick={(e) => {
              reset(deal,data,setData,commVal)
              onCancel(e);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            color={'primary'}
            type={'submit'}
            size={'md'}
          >
            {loading ? (
              <>
                Saving <Spinner size={'sm'} />
              </>
            ) : (
              'Save'
            )}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
const SecondaryEdit = ({
  buyerAgreedCommission,
  sellerAgreedCommission,
  agreedSalePrice,
  data,
  setData,
}) => {
  const [totalComValue, settotalComValue] = useState(0 );
  const [totalCom, settotalCom] = useState(0);
  const [Vat, setVat] = useState(0);
  useEffect(()=>{
   settotalComValue(
   Number( commissionValue(buyerAgreedCommission, agreedSalePrice) )+
     Number( commissionValue(sellerAgreedCommission, agreedSalePrice)))
      setVat( vat(
        Number(commissionValue(buyerAgreedCommission, agreedSalePrice)) +
          Number(commissionValue(sellerAgreedCommission, agreedSalePrice))
      ))

  },[ buyerAgreedCommission,
    sellerAgreedCommission])
  useEffect(() => {
    const seller = commissionPercent(
      data.sellerAgreedCommissionVal,
      data.agreedSalePrice
    );
    const buyer = commissionPercent(
      data.buyerAgreedCommissionVal,
      data.agreedSalePrice
    );

    settotalComValue(
      Number(data.buyerAgreedCommissionVal) +
        Number(data.sellerAgreedCommissionVal)
    );
    setData((c) => ({
      ...c,
      sellerAgreedCommission: Number(seller),
      buyerAgreedCommission: Number(buyer),
    }));
  }, [data.sellerAgreedCommissionVal, data.buyerAgreedCommissionVal]);
  useEffect(() => {
    setVat((c) => vat(totalComValue));
    settotalCom(
      (c) =>
        Number(data.buyerAgreedCommission) + Number(data.sellerAgreedCommission)
    );
  }, [totalComValue]);
  useEffect(() => {
    settotalCom((c) =>
      (
        Number(data?.sellerAgreedCommission) +
        Number(data?.buyerAgreedCommission)
      ).toFixed(4)
    );
  }, [data?.sellerAgreedCommission, data?.buyerAgreedCommission]);
  return (
    <Row>
      <Col>
        <Label>Listing Price</Label>
        <div className="form-control-wrap">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">AED</span>
            </div>
            <Input
              type="number"
              value={data.agreedSalePrice}
              onChange={(e) =>
                setData((c) => ({
                  ...c,
                  agreedSalePrice: Number(e.target.value),
                  buyerAgreedCommissionVal: 0,
                  sellerAgreedCommissionVal: 0,
                }))
              }
            />
          </div>
        </div>
      </Col>
      <Col md={'7'}>
        <Label>Seller Agreed Commission Value</Label>
        <div className="form-control-wrap">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">AED</span>
            </div>
            <Input
              type="number"
              value={data.sellerAgreedCommissionVal??0}
              onChange={(e) =>
                setData((c) => ({
                  ...c,
                  sellerAgreedCommissionVal: Number(e.target.value),
                }))
              }
            />
          </div>
        </div>
      </Col>
      <Col md={'5'}>
        <Label>Seller Agreed Commission</Label>
        <div className="form-control-wrap">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">%</span>
            </div>
            <Input type="number" value={data.sellerAgreedCommission??0} disabled />
          </div>
        </div>
      </Col>

      <Col md={'7'}>
        <Label>Buyer Agreed Commission Value</Label>
        <div className="form-control-wrap">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">AED</span>
            </div>
            <Input
              type="number"
              value={data.buyerAgreedCommissionVal??0}
              onChange={(e) =>
                setData((c) => ({
                  ...c,
                  buyerAgreedCommissionVal: Number(e.target.value),
                }))
              }
            />
          </div>
        </div>
      </Col>
      <Col md={'5'}>
        <Label>Buyer Agreed Commission</Label>
        <div className="form-control-wrap">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">%</span>
            </div>
            <Input type="number" value={data.buyerAgreedCommission??0} disabled />
          </div>
        </div>
      </Col>

      <Col md={'7'}>
        <Label>Total Commission Value</Label>
        <div className="form-control-wrap">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">AED</span>
            </div>
            <Input
              disabled
              type="number"
              value={Number(totalComValue)}
            />
          </div>
        </div>
      </Col>
      <Col md={'5'}>
        <Label>Total Commission</Label>
        <div className="form-control-wrap">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">%</span>
            </div>
            <Input type="number" value={totalCom??0} disabled />
          </div>
        </div>
      </Col>
      <Col>
        <Label>Commission + Tax(5%)</Label>
        <div className="form-control-wrap">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">AED</span>
            </div>
            <Input type="number" value={Vat??0} disabled />
          </div>
        </div>
      </Col>
    </Row>
  );
};

const vat = (commVal) => {
  const val = !isNaN(Number(commVal)) ? Number(commVal) : 0;
  return (val * 1.05).toFixed(4);
};
const commissionValue = (comPercent, price) => {
  const percent = !isNaN(Number(comPercent)) ? Number(comPercent) : 0;
  const totprice = !isNaN(Number(price)) ? Number(price) : 0;
  return ((percent * totprice) / 100).toFixed(6);
};
const commissionPercent = (comvalue, price) => {
  const value = !isNaN(Number(comvalue)) ? Number(comvalue) : 0;
  const totprice = !isNaN(Number(price)) ? Number(price) : 0;
  return ((value / totprice) * 100).toFixed(6);
};
const reset=(deal,data,setData,commVal)=>{
  if (deal?.buyerAgreedCommission || deal?.sellerAgreedCommission) {
    setData(c=>({
     agreedSalePrice:deal?.agreedSalePrice,
     buyerAgreedCommission:deal?.buyerAgreedCommission??0,
     sellerAgreedCommission:deal?.sellerAgreedCommission??0,
     buyerAgreedCommissionVal:(Number(deal?.buyerAgreedCommission??0)*Number(deal?.agreedSalePrice)/100).toFixed(2),
     sellerAgreedCommissionVal:(Number(deal?.sellerAgreedCommission??0)*Number(deal?.agreedSalePrice)/100).toFixed(2)
    }))
  } else {
    setData({
      agreedCommissionValue: commVal(
        deal?.agreedCommission,
        deal?.agreedSalePrice
      ),
      agreedCommission: deal?.agreedCommission,
      agreedSalePrice: deal?.agreedSalePrice,
      vat: vat(commVal(deal?.agreedCommission, deal?.agreedSalePrice)),
    });
  }
}


const MortgageDetailsView = ({ deal, onEdit }) => (
  <div className="data-list nk-data">
    <div className="data-head">
    <h6 className="overline-title" onClick={onEdit} style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}>
        Mortgage Details
        <Icon
          name="edit"
          className={
              'text-primary'
          }
      />
      </h6>
    </div>
    <div className="data-item">
      <Col sm="3">
        <div className="data-col">
          <span className="data-label">Buyer Mortgage</span>
          <span className="data-value">
            {deal?.isMortgage ? "Yes" : "No"}
          </span>
        </div>
        <div className="data-col-end data-col"></div>
      </Col>
      {deal?.isMortgage && (
        <Col sm="9">
          <div className="data-col">
            <span className="data-label">Buyer Mortgage Provider</span>
            <span className="data-value">
              {deal?.mortgageProvider ? deal?.mortgageProvider : "N/A"}
            </span>
          </div>
          <div className="data-col-end data-col"></div>
        </Col>
      )}
    </div>
    <div className="data-item">
      <Col sm="3">
        <div className="data-col">
          <span className="data-label">Seller Mortgage</span>
          <span className="data-value text-primary">
            {deal?.isSellerMortgage ? "Yes" : "No"}
          </span>
        </div>
        <div className="data-col-end data-col"></div>
      </Col>
      {deal?.isSellerMortgage && (
      <Col sm="9">
        <div className="data-col">
          <span className="data-label">Seller Mortgage Provider</span>
          <span className="data-value">
            {deal?.sellerMortgageProvider ? deal?.sellerMortgageProvider : "N/A"}
          </span>
        </div>
        <div className="data-col-end data-col"></div>
      </Col>
      )}
    </div>
  </div>
);

const MortgageDetailsEdit = ({ deal, onSave, onCancel, isLoading }) => {
  const [isBuyerMortgage, setIsBuyerMortgage] = useState(deal?.isMortgage || false);
  const [buyerMortgageProvider, setBuyerMortgageProvider] = useState(deal?.mortgageProvider || '');
  const [isSellerMortgage, setIsSellerMortgage] = useState(deal?.isSellerMortgage || false);
  const [sellerMortgageProvider, setSellerMortgageProvider] = useState(deal?.sellerMortgageProvider || '');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Call onSave with updated values
    onSave({ 
      isMortgage: isBuyerMortgage,
      mortgageProvider: isBuyerMortgage ? buyerMortgageProvider : null,
      isSellerMortgage,
      sellerMortgageProvider: isSellerMortgage ? sellerMortgageProvider : null 
    });
  };

  return (
    <Card className="card-bordered">
      <div className="data-head">
        <h6 className="overline-title">Edit Mortgage Details</h6>
      </div>
      <div className="card-inner">
        <Row className="gy-2">
          <div className="d-flex justify-content-end">
            <button
            onClick={onCancel}
              className="close-btn"
              style={ {
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
              } }
            >
              <Icon
                name="cross"
                style={ {
                  fontSize: "1.5rem",
                  backgroundColor: "#fff",
                  color: "red",
                  borderRadius: "0.2rem",
                } }
              />
            </button>
          </div>
          <Col
            sm="3"
            className="d-flex align-items-center mt-4"
            style={{ gap: '1.5rem' }}
            >
            <div className="custom-control custom-switch" style={{ flexGrow: 2 }}>
              <input
              type="checkbox"
              className="custom-control-input"
              id="customSwitch1"
              name="isMortgage"
              checked={isBuyerMortgage}
              onChange={(e) => setIsBuyerMortgage(e.target.checked)}
              />
              <label className="custom-control-label" htmlFor="customSwitch1">
              Buyer Mortgage
              </label>
            </div>
          </Col>
          <Col sm="9">
            <Collapse isOpen={isBuyerMortgage}>
                <Label>Buyer Mortgage Provider</Label>
                <Input
                    placeholder="Mortgage Provider"
                    value={buyerMortgageProvider}
                    onChange={(e) => setBuyerMortgageProvider(e.target.value)}
                />
            </Collapse>
          </Col>
        </Row>
        <Row>
        <Col
            sm="3"
            className="d-flex align-items-center mt-5"
            style={{ gap: '1.5rem' }}
            >
            <div className="custom-control custom-switch" style={{ flexGrow: 2 }}>
              <input
              type="checkbox"
              className="custom-control-input"
              id="customSwitch2"
              name="isSellerMortgage"
              checked={isSellerMortgage}
              onChange={(e) => setIsSellerMortgage(e.target.checked)}
              />
              <label className="custom-control-label" htmlFor="customSwitch2">
              Seller Mortgage
              </label>
            </div>
          </Col>
          <Col sm="9">
            <Collapse isOpen={isSellerMortgage}>
                <Label className='mt-2'>Seller Mortgage Provider</Label>
                <Input
                    placeholder="Mortgage Provider"
                    value={sellerMortgageProvider}
                    onChange={(e) => setSellerMortgageProvider(e.target.value)}
                />
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="form-group d-flex justify-content-end mt-4">
              <Button
                onClick={ ( e ) => handleSubmit(e)}
                color="success"
                disabled={isLoading}
              >
                Save {isLoading && <Spinner size={"sm"}/>} 
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
