import fetchAgentTeamMembers from "../api/agents/fetchAgentTeamMembers";

export const AGENT_KEYS = {
  all: ["agents"],
  base: "agents",
  single: "agent",
  teamMembers: "agentTeam",
};

export const agentQueries = {
  /**
   * Fetches the other agents that are a part of a specific agent's team.
   *
   * @param {Axios} axios - The Axios instance to use for making requests.
   * @param {string} leaderId - The ID of the team leader (the profile's agent or their leader if they are not a leader).
   * @param {?import("axios").AxiosRequestConfig} [options] - Optional query options (including params).
   * @returns {import('react-query').QueryConfig<any, Error, any>} - A React Query configuration object.
   */
  getTeamMembers: (axios, leaderId, options) => ({
    queryKey: [AGENT_KEYS.teamMembers, leaderId, options?.params],
    queryFn: () => fetchAgentTeamMembers(axios, leaderId, options?.params)
  }),
};
