import React, { useEffect, useState, useLayoutEffect } from "react";
import Pages from "../route/Index";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import { Link } from "react-router-dom";
// import Footer from "./footer/Footer";
import classNames from "classnames";
import { HttpStatus, SocketEventType, serverAPI } from "../utils/envConfig";
// import { toast } from "react-toastify";
import { newFeed } from "../redux/actions/feeds";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import {} from "reactstrap";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { showToast } from "../utils/toast/toast";
const Layout = () => {
  //Sidebar
  const [mobileView, setMobileView] = useState();
  const [visibility, setVisibility] = useState(false);
  const [themeState] = useState({
    main: "default",
    sidebar: "white",
    header: "white",
    skin: "light",
  });

  useEffect(() => {
    document.body.className = `nk-body bg-lighter npc-default has-sidebar no-touch nk-nio-theme ${
      themeState.skin === "dark" ? "dark-mode" : ""
    }`;
  }, [themeState.skin]);

  useEffect(() => {
    viewChange();
  }, []);

  // Stops scrolling on overlay
  useLayoutEffect(() => {
    if (visibility) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!visibility) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [visibility]);

  // function to toggle sidebar
  const toggleSidebar = (e) => {
    e.preventDefault();
    if (visibility === false) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  };

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 1200) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setVisibility(false);
    }
  };
  window.addEventListener("load", viewChange);
  window.addEventListener("resize", viewChange);

  const sidebarClass = classNames({
    "nk-sidebar-mobile": mobileView,
    "nk-sidebar-active": visibility && mobileView,
  });

  const dispatch = useDispatch();
  const axios = useAxiosPrivate();

  const user = useSelector((state) => state.user);

  const authuser = user?.loggedInUser;

  const [notification, setNotification] = useState(null);
  const [showNots, setshowNots] = useState(false);
  const connectIO = () => {
    try {
      const apiUrl = serverAPI?.replace("/api/v1", "");

      if (!apiUrl) {
        throw new Error("No api url");
      }

      const socket = io(apiUrl, {
        path: '/api/v1/socket',
        query: { id: authuser?.id ?? authuser?._id },
        auth: {
          token: authuser?.access_token,
        },
      });

      socket.on("connection", (res) => {
        console.log(res);
        console.log("connected");
      });

      socket.on(SocketEventType.ConnectionCreated, (msg) => {
        console.log(msg);
      });

      socket.on(SocketEventType.Message, (msg) => {
        const message = JSON.parse(msg);

        dispatch(newFeed({ message, authuser }));
      });

      let isListenerRegistered = false;

      // Check if listener is already registered before adding
      if (!isListenerRegistered) {
        socket.on(`${SocketEventType.CsvGenerated}_${authuser?.id ?? authuser?._id}`, async (data) => {
          // Set isListenerRegistered to false to allow re-registration
          isListenerRegistered = false;

          // Listener logic here
          const { filePath } = data;
          try {
            const res = await axios.get(
              `/deals/transactions/csv-download?filePath=${filePath}`,
              {
                responseType: "blob",
              }
            );
            const blobUrl = window.URL.createObjectURL(new Blob([res?.data]));
            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = "transactions.csv";
            document.body.appendChild(a);
            a.click();
            a.remove();

            // clean up the Object URL created
            window.URL.revokeObjectURL(blobUrl);

            res?.status === HttpStatus.OK &&
              showToast("CSV File downloaded!", "success");
          } catch (error) {
            console.error("Error downloading file:", error);
          }
        });

        socket.on(`${SocketEventType.DealCsv}_${authuser?.id ?? authuser?._id}`, async (data) => {
          // Set isListenerRegistered to false to allow re-registration
          isListenerRegistered = false;

          // Listener logic here
          const { filePath } = data;
          try {
            const res = await axios.get(
              `/deals/transactions/csv-download?filePath=${filePath}`,
              {
                responseType: "blob",
              }
            );
            const blobUrl = window.URL.createObjectURL(new Blob([res?.data]));
            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = "deals.csv";
            document.body.appendChild(a);
            a.click();
            a.remove();

            // clean up the Object URL created
            window.URL.revokeObjectURL(blobUrl);

            res?.status === HttpStatus.OK &&
              showToast("CSV File downloaded!", "success");
          } catch (error) {
            console.error("Error downloading file:", error);
          }
        });

        isListenerRegistered = true;
      }

      socket.on(SocketEventType.User, (msg) => {
        const message = JSON.parse(msg);

        dispatch(newFeed({ message, authuser }));
      });

      socket.on(SocketEventType.Activity, (msg) => {
        const message = JSON.parse(msg);

        // we want to dispatctch a redux event to populate live feeds
        // we want to toast a new message (notification sort of)

        dispatch(newFeed({ message, authuser }));
      });

      socket.on(SocketEventType.Deal, (msg) => {
        const message = JSON.parse(msg);

        dispatch(newFeed({ message, authuser }));
      });
      socket.on(SocketEventType.createTicket, (data) => {
        setNotification(JSON.parse(data));
        setshowNots(true);
      });

      socket.on("disconnect", () => {
        console.log("We got disconnected from server");
      });
      return socket;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  useEffect(() => {
    const connectedSocket = authuser?.access_token ? connectIO() : null;

    return () => {
      // cleanup socket connection
      connectedSocket && connectedSocket.disconnect();
    };
  }, [authuser]);

  return (
    <React.Fragment>
      <Head title="Loading" />
      <div className="nk-app-root">
        <div className="nk-main">
          <Sidebar
            sidebarToggle={toggleSidebar}
            fixed
            mobileView={mobileView}
            theme={themeState.sidebar}
            className={sidebarClass}
          />
          {visibility && mobileView && (
            <div className="nk-sidebar-overlay" onClick={toggleSidebar}></div>
          )}
          <div className="nk-wrap">
            <Header
              sidebarToggle={toggleSidebar}
              fixed
              setVisibility={setVisibility}
              theme={themeState.header}
            />
            <Pages />
            {/* <Footer /> */}
          </div>
        </div>
      </div>
      <ModalComponent
        notification={notification}
        onClose={(e) => {
          setshowNots(false);
          setNotification(null);
        }}
        showModal={showNots}
      />
    </React.Fragment>
  );
};
export default Layout;

const ModalComponent = ({ showModal, notification, onClose }) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary d-none"
        onClick={(e) => onClose(e)}
      >
        Modal Default
      </button>

      {showModal && (
        <div
          className="modal fade show"
          tabIndex="-1"
          style={{ display: "block" }}
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <a
                href="#"
                className="close"
                onClick={(e) => onClose(e)}
                aria-label="Close"
              >
                <em className="icon ni ni-cross"></em>
              </a>
              <div className="modal-header">
                <h5 className="modal-title">{notification?.title}</h5>
              </div>
              <div className="modal-body">
                <p>{notification?.message}</p>
              </div>
              <div className="modal-footer bg-light">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    onClose(e);
                  }}
                  className="p-2 bg-primary text-white rounded-md"
                  to={
                    notification?.link
                      ? notification?.link
                      : `#${notification?.id ?? "notification"}`
                  }
                >
                  View Ticket
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};
