/* eslint-disable no-unused-vars */
import { ViewportInfo } from '../types';

/**
 * @typedef {Object} ProfileSidebarProps
 * @property {ViewportInfo} viewportInfo - contains properties to render the view on smaller devices
 * @property {React.ReactNode} [children] - The children to render in this component.
 * @property {any} [props] - Additional properties.
 */

/**
 * ProfileSidebar component renders a sidebar for a profile page.
 * 
 * @param {ProfileSidebarProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered table row.
 */
const ProfileSidebar = ({ viewportInfo, children, ...props }) => {
  return (
    <div
      className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
        viewportInfo?.isSm ? "content-active" : ""
      }`}
      {...props}
    >
      <div className="card-inner-group" style={{ position: 'sticky', top: 60 }}>
        {children ?? null}
      </div>
    </div>
  );
};

export default ProfileSidebar;
