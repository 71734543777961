import { useSelector } from "react-redux";
import { Icon } from "../../../../components/Component";
import { PropertyCardStatus, newRoles } from "../../../../utils/envConfig";

const FieldDisplayView = ( { propertyCardDetail, toggleEdit } ) =>
{
  const authUser = useSelector( ( state ) => state?.user?.loggedInUser );
  const authAgent = useSelector( ( state ) => state?.user?.loggedInAgent );

  const isCheckedOutByAuthAgent =
    authAgent &&
    propertyCardDetail?.currentAgent != null &&
    ( authAgent.id ?? authAgent._id )?.toString() ===
    (
      propertyCardDetail?.currentAgent?.id ??
      propertyCardDetail?.currentAgent?._id
    )?.toString();

  const isAdmin =
    authUser.role === newRoles.Admin || authUser.role === newRoles.MasterAdmin;

  return (
    <div className="mt-1 me-xxl-5 product-info">
      <h4 className="product-title">
        { propertyCardDetail?.building?.name
          ? propertyCardDetail?.building?.name
          : "Unknown building name" }
      </h4>
      <Icon name="location" />{ " " }
      <span className="ml-2">
        { propertyCardDetail?.community?.community }{ " " }
        { propertyCardDetail?.emirate ? `, ${ propertyCardDetail?.emirate }` : "" }
      </span>
      <div className="position-relative flex mt-3">
        <div className="d-flex flex-wrap">


          <ul className="d-flex flex-wrap g-3 gx-5">
            <li style={ { cursor: "pointer" } } onDoubleClick={ () => toggleEdit() }>
              <div className="text-muted fs-14px">Type</div>
              <div className="fw-bold text-secondary fs-16px">
                { propertyCardDetail?.propertyType
                  ? propertyCardDetail?.propertyType
                  : "?" }
              </div>
            </li>
            { ( isCheckedOutByAuthAgent || isAdmin ) &&
              propertyCardDetail?.unitId ? (
              <li
                style={ { cursor: "pointer" } }
                onDoubleClick={ () => toggleEdit() }
              >
                <div className="text-muted fs-14px">Unit ID</div>
                <div className="fw-bold text-secondary fs-16px">
                  { propertyCardDetail?.unitId }
                </div>
              </li>
            ) : null }
            { propertyCardDetail?.status === PropertyCardStatus.PocketListing &&
              propertyCardDetail?.purpose ? (
              <li
                style={ { cursor: "pointer" } }
                onDoubleClick={ () => toggleEdit() }
              >
                <div className="text-muted fs-14px">Purpose</div>
                <div className="fw-bold text-secondary fs-16px">
                  { propertyCardDetail?.purpose ?? "Not a Pocket Listing" }
                </div>
              </li>
            ) : null }
            <li style={ { cursor: "pointer" } } onDoubleClick={ () => toggleEdit() }>
              <div className="text-muted fs-14px">Beds</div>
              <div className="fw-bold text-secondary fs-16px">
                { propertyCardDetail?.beds ? propertyCardDetail?.beds : " ?" }{ " " }
              </div>
            </li>
            <li style={ { cursor: "pointer" } } onDoubleClick={ () => toggleEdit() }>
              <div className="text-muted fs-14px">Baths</div>
              <div className="fw-bold text-secondary fs-16px">
                { propertyCardDetail?.baths ? propertyCardDetail?.baths : " ?" }{ " " }
              </div>
            </li>
            <li style={ { cursor: "pointer" } } onDoubleClick={ () => toggleEdit() }>
              <div className="text-muted fs-14px">Area</div>
              <div className="fw-bold text-secondary fs-16px">
                { propertyCardDetail?.size ? propertyCardDetail?.size : "?" } sqft
              </div>
            </li>
          </ul>
          <div
            className="d-lg-block  d-none ms-4"

          >
            <span className="cursor-pointer fs-6" onClick={ toggleEdit }>
              <Icon name="edit" />
            </span>
          </div>     </div>
      </div>
    </div>
  );
};

export default FieldDisplayView;
