import React, { useCallback,useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import cuid from "cuid";
import Dropzone from "react-dropzone";
import ImgeList from "./ImgeList";
import { v4 as uuidv4 } from "uuid";
import "react-toastify/dist/ReactToastify.css";
import Compressor from "compressorjs";
import { toast } from "react-toastify";

const backendForDND = HTML5Backend;

function DragDrop({ propId, fieldName, images, setImages,propImages=null,setToBeDeleted=()=>null ,setToBeAdded=()=>null}) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = async function (e) {
        reader.fileName = file.name;
        reader.file = file;

        const img = new Image();
        img.src = e.target.result;

        img.onload = async function () {
          const width = this.width;
          const height = this.height;
          //   console.log(width, height);

          if (width < 800 || height < 600) {
            return toast.error("File size too small");
          }

          if (height > width) {
            toast.error("Invalid file dimension");
            return;
          }

          let compressedFile = await compress(file);
          let uid = uuidv4();
          setImages((prevState) =>
            [
              ...prevState,
              {
                id: cuid(),
                src: e.target.result,
                imgfile: file,
                thumbnailFile: compressedFile,
                fileName:
                  `${propId}/${fieldName}/` +
                  uid +
                  "-original" +
                  "." +
                  `${e.target.fileName.split(".").pop()}`,
                thumbnailName:
                  `${propId}/${fieldName}/` +
                  uid +
                  "-thumbnail" +
                  "." +
                  `${e.target.fileName.split(".").pop()}`,
                realName: file.name,
              },
            ]
          );
        };
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

    useEffect(() => {
      const newArray = propImages?.filter((propImage) => {
        return !images.some((orderImage) => {
            return propImage.original === orderImage.original;
        });
    });
    setToBeDeleted(newArray);
    const newImgArray2 = images.filter((orderImage) => {
      return orderImage.hasOwnProperty("imgfile");
  

  });
  setToBeAdded(newImgArray2)
 
    },[images,propImages])

  const moveImage = (dragIndex, hoverIndex) => {
    const draggedImage = images[dragIndex];
    setImages((images) => {
      const coppiedStateArray = [...images];

      // remove item by "hoverIndex" and put "dragItem" instead
      const prevItem = coppiedStateArray.splice(hoverIndex, 1, draggedImage);

      // remove item by "dragIndex" and put "prevItem" instead
      coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

      return coppiedStateArray;
    });
  };
  // const moveImage = (dragIndex, hoverIndex) => {
  //   setImages((images) => {
  //     const copiedStateArray = [...images];
  //     const draggedImage = copiedStateArray[dragIndex];
  //     copiedStateArray.splice(dragIndex, 1);
  //     copiedStateArray.splice(hoverIndex, 0, draggedImage);
  
  //     // Update the order property sequentially
  //     const updatedArray = copiedStateArray.map((image, index) => ({
  //       ...image,
  //       order: index,
  //     }));
  
  //     return updatedArray;
  //   });
  // };
  
  
  const deleteImage = (e, index) => {
    e.preventDefault();
    let newimages = [...images];
    if (index > -1) {
      newimages.splice(index, 1);
    }
    setImages(newimages);
  };

  function compress(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        width: 500,
        height: 375,
        success(result) {
          resolve(result);
        },
        error(e) {
          reject(e);
        },
      });
    });
  }

  return (
    <Dropzone onDrop={onDrop} accept={"image/*"}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div
            {...getRootProps()}
            className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
          >
            <input {...getInputProps()} />
            <div className="dz-message">
              <span className="dz-message-text">
                <span>Drag and drop</span> only Images here or{" "}
                <span>browse</span>
              </span>
            </div>
          </div>
          <DndProvider backend={backendForDND}>
            <ImgeList
              images={images}
              moveImage={moveImage}
              deleteImage={deleteImage}
            />
          </DndProvider>
        </section>
      )}
    </Dropzone>
  );
}
export default DragDrop;
