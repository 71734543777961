/* eslint-disable no-unused-vars */
import { Axios } from "axios";
import ErrorHandler from "../../error-handlers/axios/defaultErrorHandler";

/**
 * @description Fetches all commission transactions for a specific deal
 * @param {Axios} axiosInstance - the axios instance for making the HTTP request.
 * @param {string} dealId - the deal ID to create the transaction for.
 * @param {import('../../global-types/deal-transactions').DealTransactionDTO} payload - the create payload sent.
 * @returns {Promise<import('../../global-types/deal-transactions').TransactionData>} API Response.
 */
export const createDealTransaction = async (axiosInstance, dealId, payload) => {
    try {
      const res = await axiosInstance.post(`/deals/${dealId}/transactions`, payload);
      return res?.data;
    } catch (error) {
      ErrorHandler.handle(error);
    }
}
