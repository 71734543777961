const humanizeDate = (dateStr, { addTime = false }) => {
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    
    if (addTime) {
      options = {
        ...options,
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        // hour12: true, // Use 12-hour clock (set to false for 24-hour format)
      }
    }
    
    const currentLocale = Intl.DateTimeFormat().resolvedOptions().locale;
    return new Intl.DateTimeFormat(currentLocale, options).format(
      new Date(dateStr)
    );
  };

  export { humanizeDate }