import React, { useState, useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import
{
    fetchCategory,
    fetchPropTypesList,
    fetchPropertiesAdmin,
} from "../../redux/actions/property";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Button, Modal, ModalBody, Input } from "reactstrap";

import
{
    Block,
    BlockHeadContent,
    BlockTitle,
    BlockBetween,
    BlockHead,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    Row,
    Col,
    RSelect,
    TooltipComponent,
} from "../../components/Component";
import { actionS3Objects, uploadToS3 } from "../../utils/envConfig";
import { useNavigate } from "react-router-dom";
import Compressor from "compressorjs";
import { v4 as uuidv4 } from "uuid";
import ConfirmModal from "../../components/miscellaneous/ConfirmModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function compress ( file )
{
    return new Promise( ( resolve, reject ) =>
    {
        new Compressor( file, {
            quality: 0.6,
            success ( result )
            {
                resolve( result );
            },
            error ( e )
            {
                reject( e );
            },
        } );
    } );
}

const PropertyTypeList = () =>
{
    let navigate = useNavigate();

    const axios = useAxiosPrivate();

    const dispatch = useDispatch();
    const [ open, setOpen ] = useState( false );

    const PropertyType = useSelector( ( state ) => state.property?.propertyTypes );
    const property = useSelector( ( state ) => state.property?.propertiesAdmin );
    const category = useSelector( ( state ) => state.property?.categories );
    const categoryOptions= category?.map(
        ( c ) => ( {
            ...c,
            label: c?.category,
            value: c?.id,
        } )??[]
    ) 
    // State Variables
    const [ showModal, setShowModal ] = useState( false );
    const [ PropertyTypeId, setPropertyTypeId ] = useState( "" );
    const [ PropertyTypename, setPropertyTypeName ] = useState( "" );
    const [ check, SetCheck ] = useState( [] );
    const [ propertyType, setPropertyType ] = useState( "" );
    const [ icon, setIcon ] = useState( "" );

    const [ CategoryId, setCategoryId ] = useState( "" );
    const [ avatar, setAvatar ] = useState( "" );
    const [ displayAvatar, setDisplayAvatar ] = useState( "" );
    const [ avatarFile, setAvatarFile ] = useState( "" );
    const [ edit, setEdit ] = useState( false );
    const [ propTypeIconPath, setPropTypeIconPath ] = useState( "" );
    const [ loading, setLoading ] = useState( false );

    // Navigation URL
    const url = "/propType-list";
    // Constants
    const user = useSelector( ( state ) => state?.user?.loggedInUser );

    const { access_token } = user || "";
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ access_token }`,
    };
    // const createdBy =JSON.parse(localStorage.getItem("authuser")).id
    const firstName = user?.first_name;
    const lastName = user?.last_name;
    const createdName = `${ firstName } ${ lastName }`;

    const folderName = "PropertyType";

    // Input Validation
    const validate = ( propertyType, CategoryId, amenityImg ) =>
    {
        if ( propertyType?.length < 2 )
        {
            toast.error( "Enter valid PropertyType" );
            return false;
        }
        if ( !CategoryId )
        {
            toast.error( "Select PropertyType Category" );
            return false;
        }
        if ( !amenityImg )
        {
            toast.error( "Upload Icon for PropertyType" );
            return false;
        } else if ( /^\d+$/.test( propertyType ) )
        {
            toast.error( "Enter valid PropertyType" );
            return false;
        } else
        {
            return true;
        }
    };
    const validateEdit = ( category ) =>
    {
        if ( category.length < 2 )
        {
            toast.error( "Enter valid Property Type" );
            return false;
        } else
        {
            return true;
        }
    };
    const [ errorMessage, setErrorMessage ] = useState( "" );

    const handleImage = async ( e ) =>
    {
        if ( e.target.files )
        {
            const file = e.target.files[ 0 ];
            const allowedTypes = [
                "image/jpeg",
                "image/png",
                "image/gif",
                "image/jpg",
                "image/svg",
                "image/webp",
            ];

            if ( allowedTypes.includes( file.type ) )
            {
                let result = await compress( file );
                let url = URL.createObjectURL( file );
                const fileName = `${ result.name }`;
                setDisplayAvatar( url );
                setAvatar( result );
                setAvatarFile( fileName );
                setErrorMessage( "" );
            } else
            {
                setErrorMessage(
                    "Please upload a valid image file (JPEG, PNG, GIF, JPG, SVG, WEBP)"
                );
            }
        }
    };

    const handleRemove = ( e ) =>
    {
        e.preventDefault();
        setDisplayAvatar( "" );
        setAvatar( null );
        setAvatarFile( "" );
        setErrorMessage( "" );

        // Reset the input field value
        const fileInput = document.querySelector( 'input[type="file"]' );
        if ( fileInput )
        {
            fileInput.value = "";
        }
    };

    const handleUpload = async ( file, fileName ) =>
    {
        const uploadData = JSON.stringify( {
            action: `${ actionS3Objects[ 1 ] }`,
            fileName: fileName,
        } );
        const resp = await axios.post( `/upload`, uploadData );
        const s3url = resp.data.signedRequest;
        if ( s3url )
        {
            try
            {
                const myHeaders = new Headers( { "Content-Type": "image/*" } );
                await Axios.put( s3url, file, { myHeaders } );
            } catch ( err )
            {
                // Handle Error Here
                toast.error( "Server error. Try Again!" );
            }
        }
    };
    // Add PropertyType Info in DB
    const addPropertyType = async ( e ) =>
    {
        e.preventDefault();
        if ( validate( propertyType, CategoryId, avatar ) )
        {
            let fileName = "";
            if ( avatar.name )
            {
                fileName = `${ folderName }/${ uuidv4() }.${ avatar.name
                    .split( "." )
                    .pop() }`;
                handleUpload( avatar, fileName );
            }
            const formData = {
                propertyType,
                icon: fileName,
                categoryId: CategoryId,
            };
            // console.log(formData)
            try
            {
                const res = await axios
                    .post( `/propType`, formData )
                    .then( ( propertyType ) =>
                    {
                        navigate( 0 );
                    } )
                    .catch( ( err ) =>
                    {
                        toast.error( "PropertyType already exists!" );
                    } );
            } catch ( err )
            {
                // console.log(err)
                toast.error( "Server error. Try Again !" );
            }
        }
    };
    const editPropType = async ( e ) =>
    {
        e.preventDefault();
        if ( validateEdit( propertyType ) )
        {
            let fileName = propTypeIconPath ? propTypeIconPath : "";
            if ( avatar && avatar.name )
            {
                fileName = `${ folderName }/${ uuidv4() }.${ avatar.name
                    .split( "." )
                    .pop() }`;
                fileName && ( await uploadToS3( avatar, fileName, "image/*", axios ) );
            }
            const formData = {
                propertyType,
                icon: fileName,
                categoryId: CategoryId
            };
            try
            {
                setLoading( true );
                const res = await axios
                    .patch( `/propType/${ PropertyTypeId }`, formData, { headers } )
                    .then( ( propType ) =>
                    {
                        navigate( 0 );
                    } )
                    .catch( ( err ) =>
                    {
                        toast.error( "Property Type not modified !" );
                    } )
                    .finally( () => setLoading( false ) );
            } catch ( err )
            {
                // console.log(err)
                toast.error( "Server error. Try Again !" );
            }
        }
    };
    const handleEdit = ( e, item ) =>
    {
        e.preventDefault();
        setOpen( true );
        setEdit( true );
        setPropertyTypeId( item.id );
        setPropertyType( item.propertyType );
        setDisplayAvatar( item.image );
        setPropTypeIconPath( item.icon );
        setCategoryId( item.categoryId );
    };
    const resetForm = () =>
    {
        setOpen( false );
        setEdit( false );
        setPropertyTypeId( null );
        setPropertyType( null );
        setDisplayAvatar( null );
        setPropTypeIconPath( "" );
        setCategoryId( null );
    };
    const handleModal = ( e, id, name ) =>
    {
        e.preventDefault();
        setPropertyTypeId( id );
        setPropertyTypeName( name );
        setShowModal( true );
    };

    const closeModal = () =>
    {
        setShowModal( false );
        resetForm();
    };
    useEffect( () =>
    {
        dispatch( fetchPropTypesList( axios ) );
        dispatch( fetchPropertiesAdmin( axios ) );
        dispatch( fetchCategory( axios ) );
    }, [axios, dispatch] );

    const submitDel = async ( e ) =>
    {
        e.preventDefault();
        try
        {
            await axios
                .delete( `/propType/${ PropertyTypeId }` )
                .then( ( PropertyType ) =>
                {
                    navigate( 0 );
                } )
                .catch( ( err ) =>
                {
                    if ( err.response.data.message === "LinkedtoListing" )
                    {
                        toast.error(
                            "PropertyType cannot be removed as it is linked to a Property Listing"
                        );
                    } else
                    {
                        toast.error( "PropertyType not removed !" );
                    }
                } );
        } catch ( err )
        {
            // console.log(err)
            toast.error( "Server error. Try Again !" );
        }
        setShowModal( false );
    };

    useEffect( () =>
    {
        if ( PropertyType?.length > 0 && property?.length > 0 )
        {
            let arr = PropertyType.map( ( item, index ) =>
                property.map( ( p ) => p.propertyTypeId === item.id )
            );
            let empty = [];
            arr.forEach( ( element ) =>
            {
                empty.push( element.reduce( ( x, y ) => x || y, false ) );
            } );
            SetCheck( empty );
        }
    }, [ PropertyType, property ] );
    return (
        <>
            <React.Fragment>
                <Head title="Property Type"></Head>
                <Content>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle>Property Types</BlockTitle>
                            </BlockHeadContent>
                            <BlockHeadContent>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <div className="toggle-expand-content">
                                        <ul className="nk-block-tools g-3">
                                            <li className="nk-block-tools-opt">
                                                <Button
                                                    className="toggle btn-icon d-md-none"
                                                    color="primary"
                                                >
                                                    <Icon name="plus"></Icon>
                                                </Button>
                                                <Button
                                                    className="toggle d-none d-md-inline-flex"
                                                    color="primary"
                                                    onClick={ () =>
                                                    {
                                                        setOpen( true );
                                                    } }
                                                >
                                                    <Icon name="plus"></Icon>
                                                    <span>
                                                        Add Property Type
                                                    </span>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>
                    <Block>
                        { PropertyType && (
                            <div className="nk-tb-list is-separate is-medium mb-3">
                                <DataTableHead className="nk-tb-item">
                                    {/* <DataTableRow className="nk-tb-col-check">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="pid-all"
                                                // onChange={ ( e ) => selectorCheck( e ) }
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="pid-all"
                                            ></label>
                                        </div>
                                    </DataTableRow> */}
                                    <DataTableRow>
                                        <span className="sub-text">
                                            PropertyType
                                        </span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">
                                            Category
                                        </span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Edit</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Delete</span>
                                    </DataTableRow>
                                </DataTableHead>
                                { PropertyType &&
                                    [ ...PropertyType ].map( ( item, index ) => (
                                        <DataTableItem key={ index }>
                                            {/* <DataTableRow className="nk-tb-col-check">
                                                <div className="custom-control custom-control-sm custom-checkbox notext">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        defaultChecked={
                                                            item.check
                                                        }
                                                        id={index + "oId-all"}
                                                        key={Math.random()}
                                                        // onChange={ ( e ) => onSelectChange( e, index ) }
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={
                                                            index + "oId-all"
                                                        }
                                                    ></label>
                                                </div>
                                            </DataTableRow> */}
                                            <DataTableRow>
                                                <a
                                                    href="#id"
                                                    onClick={ ( ev ) =>
                                                        ev.preventDefault()
                                                    }
                                                >
                                                    { item?.propertyType }
                                                </a>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <a
                                                    href="#category"
                                                    onClick={ ( ev ) =>
                                                        ev.preventDefault()
                                                    }
                                                >
                                                    { category?.find(cat=>cat?.id===item?.categoryId)?.category??"N/A"}
                                                    
                                                </a>
                                            </DataTableRow>

                                            <DataTableRow>
                                                <a
                                                    href="#"
                                                    onClick={ ( ev ) =>
                                                        handleEdit( ev, item )
                                                    }
                                                >
                                                    <Icon
                                                        name="edit"
                                                        style={ {
                                                            fontSize: "1.3rem",
                                                        } }
                                                    ></Icon>
                                                </a>
                                            </DataTableRow>
                                            <DataTableRow>
                                                { check.length > 0 &&
                                                    check[ index ] === true ? (
                                                    <>
                                                        <TooltipComponent
                                                            tag="a"
                                                            containerClassName="btn btn-icon"
                                                            id={ "delete" }
                                                            icon="trash"
                                                            direction="top"
                                                            text={
                                                                "Property Type can't be removed as it is linked to a Property Listing"
                                                            }
                                                        />
                                                    </>
                                                ) : (
                                                    <a
                                                        onClick={ ( e ) =>
                                                            handleModal(
                                                                e,
                                                                item.id,
                                                                item.propertyType
                                                            )
                                                        }
                                                        className="btn btn-icon"
                                                    >
                                                        <Icon
                                                            name="trash"
                                                            style={ {
                                                                color: "#e85347",
                                                                fontSize:
                                                                    "1.3rem",
                                                            } }
                                                        />
                                                    </a>
                                                ) }
                                            </DataTableRow>
                                        </DataTableItem>
                                    ) ) }
                            </div>
                        ) }
                    </Block>
                    {/* ****** Modal Start ****** */ }
                    { showModal ? (
                        <ConfirmModal
                            msg={ "Delete " }
                            name={ "Property Type: " + PropertyTypename }
                            open={ showModal }
                            handleClick={ handleModal }
                            handleConfirm={ submitDel }
                            handlecloseModal={ closeModal }
                        />
                    ) : null }
                    {/* ****** Modal End ****** */ }
                    <Modal
                        isOpen={ open }
                        toggle={ () => setOpen( false ) }
                        className="modal-dialog-centered"
                        size="lg"
                    >
                        <ModalBody>
                            <a href="#cancel" className="close">
                                { " " }
                                <Icon
                                    name="cross-sm"
                                    onClick={ ( ev ) =>
                                    {
                                        ev.preventDefault();
                                        setOpen( false );
                                    } }
                                ></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title">{ edit ? "Edit" : "Add" } PropertyType</h5>
                                <div className="mt-4">
                                    {/* <form onSubmit={ handleSubmit( onFormSubmit ) }> */ }
                                    <form
                                        onSubmit={ ( e ) =>
                                            edit
                                                ? editPropType( e )
                                                : addPropertyType( e )
                                        }
                                    >
                                        <Row className="g-3">
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        PropertyType
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="propertyType"
                                                            value={ propertyType }
                                                            onChange={ ( e ) =>
                                                                setPropertyType(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col size="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        Category
                                                    </label>
                                                    <RSelect
                                                        onChange={ ( e ) =>
                                                            setCategoryId(
                                                                e.value
                                                            )
                                                        }
                                                        options={categoryOptions}
                                                        value={categoryOptions?.find(x=>x?.id===CategoryId)}
                                                        placeholder="Category"
                                                    />
                                                </div>
                                            </Col>

                                            <Col sm="6">
                                                <div className="form-group">
                                                    <label className="form-label">
                                                        Image
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        <div className="form-file">
                                                            <Input
                                                                type="file"
                                                                id="customFile"
                                                                accept="image/*"
                                                                onChange={ ( e ) =>
                                                                    handleImage(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    { errorMessage && (
                                                        <div className="text-danger">
                                                            { errorMessage }
                                                        </div>
                                                    ) }
                                                </div>
                                                { displayAvatar && (
                                                    <div>
                                                        <p>Selected Image:</p>
                                                        <div className="position-relative">
                                                            <img
                                                                src={
                                                                    displayAvatar
                                                                }
                                                                alt="Selected"
                                                                style={ {
                                                                    height: "15rem",
                                                                } }
                                                            />
                                                            <button
                                                                className="btn btn-danger position-absolute top-0 start-0 p-1"
                                                                onClick={
                                                                    handleRemove
                                                                }
                                                            >
                                                                <Icon name="trash" />
                                                            </button>
                                                        </div>{ " " }
                                                    </div>
                                                ) }
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        Created By
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        { createdName }
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col size="12" className="d-flex justify-content-end">
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                >
                                                    {/* <Icon className="plus"></Icon> */}
                                                    <span>
                                                        { loading
                                                            ? "Loading.."
                                                            : edit
                                                                ? "Save"
                                                                : "Add Property Type" }
                                                    </span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </Content>
            </React.Fragment>
        </>
    );
};

export default PropertyTypeList;
