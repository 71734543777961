import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Steps, Step } from "react-step-builder";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import { useSelector } from "react-redux";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { Agent, newRoles } from "../../../../utils/envConfig";

function Form ( {
  property,
  type,
  design,
  innerForm = false,
  lockType = false,
  callback=(prop)=>{}
} )
{
    const [step, setStep] = useState(0); // Initial step is 0
    const [formData, setFormData] = useState({ ...property });

    const authUser = useSelector((state) => state?.user?.loggedInUser);
    const axios = useAxiosPrivate();

    // if authuser is an agent then we need his agentId
    useEffect(() => {
        if (authUser && authUser?.roles?.includes(newRoles.Agent)) {
            const getAgent = async () => {
                try {
                    const { data } = await axios.get(
                        `/agent/user/${authUser?.id}`
                    );

                    if (data?.id) {
                        setFormData(prev => ({
                          ...prev,
                          agent_id: data?.id,
                          agentStatus: Agent[0],
                          isAgent: true
                        }))
                    }
                } catch (err) {
                    console.log(err);
                }
            };

            getAgent();
        }
    }, []);

    // handling form input data by taking onchange value and updating our previous form data state
    const handleInputData = (input) => (e) => {
        // input value from the form
        const { value } = e.target;

        // updating formData state
        setFormData((prevState) => ({
            ...prevState,
            [input]: value
        }));
    };

    const prevStep = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const handleNextStep = () => {
        setStep((prevStep) => prevStep + 1);
    };
    const Header = (props) => {
        return (
            <div className="steps clearfix">
                <ul>
                    <li className={props.current >= 1 ? 'first done' : 'first'}>
                        <a
                            href="#wizard-01-h-0"
                            onClick={
                                type === 'edit' ||
                                (props.current >= 2 && props.current < 3)
                                    ? (ev) => {
                                          ev.preventDefault();
                                          props.jump(1);
                                          setStep(1);
                                      }
                                    : (ev) => ev.preventDefault()
                            }
                        >
                            <span className="number">01</span>{' '}
                            <h5>Property Details</h5>
                        </a>
                    </li>
                    <li className={props.current >= 2 ? 'done' : ''}>
                        <a
                            href="#wizard-01-h-1"
                            onClick={
                                type === 'edit' ||
                                (props.current >= 3 && props.current < 3)
                                    ? (ev) => {
                                          ev.preventDefault();
                                          props.jump(2);
                                          setStep(2);
                                      }
                                    : (ev) => ev.preventDefault()
                            }
                        >
                            <span className="number">02</span> <h5>Pricing</h5>
                        </a>
                    </li>
                    <li className={props.current >= 3 ? 'done' : ''}>
                        <a
                            href="#wizard-01-h-2"
                            onClick={
                                type === 'edit'
                                    ? (ev) => {
                                          ev.preventDefault();
                                          // props.jump( 3 );
                                          // setStep( 3 );
                                      }
                                    : (ev) => ev.preventDefault()
                            }
                        >
                            <span className="number">03</span> <h5>Images</h5>
                        </a>
                    </li>
                    <li className={props.current >= 4 ? 'last-done' : 'last'}>
                        <a
                            href="#wizard-01-h-2"
                            onClick={
                                type === 'edit'
                                    ? (ev) => {
                                          ev.preventDefault();
                                          // props.jump( 4 );
                                          // setStep( 4 );
                                      }
                                    : (ev) => ev.preventDefault()
                            }
                        >
                            <span className="current-info audible">
                                current step:{' '}
                            </span>
                            <span className="number">04</span> <h5>Preview</h5>
                        </a>
                    </li>
                </ul>
            </div>
        );
    };
    const config = {
        before: Header
    };

    return (
        <div>
            <Steps activeStep={step} config={config}>
                <Step
                    locktype={lockType}
                    component={StepOne}
                    type={type}
                    handleNextStep={handleNextStep}
                    setFormData={setFormData}
                    values={formData}
                    handleFormData={handleInputData}
                />
                <Step
                    component={StepThree}
                    type={type}
                    setFormData={setFormData}
                    values={formData}
                    handleFormData={handleInputData}
                    handleNextStep={handleNextStep}
                    handleCallback={callback}
                />
                <Step
                    component={StepFour}
                    type={type}
                    setFormData={setFormData}
                    values={formData}
                    handleFormData={handleInputData}
                    handleNextStep={handleNextStep}
                />
                <Step
                    component={StepFive}
                    type={type}
                    setFormData={setFormData}
                    values={formData}
                    handleFormData={handleInputData}
                    handleNextStep={callback}
                />
            </Steps>
        </div>
    );
}

export default Form;
