import { createSelector } from "@reduxjs/toolkit";

const selectUserState = (state) => state.user;

export const loggedInUserSelector = createSelector(
  [selectUserState],
  (userState) => userState.loggedInUser
);

export const loggedInAgentSelector = createSelector(
  [selectUserState],
  (userState) => userState.loggedInAgent
);

export const loggedInSelector = createSelector(
  [selectUserState],
  (userState) => ({
    authUser: userState.loggedInUser,
    authAgent: userState.loggedInAgent,
  })
);
