import * as types from "../types";

const initialState = {
  deals: {},
  deal: {},
  loading: true,
  error: null,
};

export const deal = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DEALS:
      return {
        ...state,
        deals: action.payload,
        loading: false,
        error: null,
      };
    case types.GET_DEAL:
      return {
        ...state,
        deal: action.payload,
        loading: false,
        error: null,
      };
    case types.ADD_DEAL:
      return {
        ...state,
        deal: action.payload,
        loading: false,
        error: null,
      };
    case types.PROGRESS_DEAL:
      return {
        ...state,
        deal: action.payload,
        loading: false,
        error: null,
      };

    case types.DEAL_LOADING:
      return {
        ...state,
        loading : action.payload
      }
    default:
      return state;
  }
};
