/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import { fetchAllProperties } from "../api/property-lists/fetchAllProperties";
/**
 * An object containing queries related to Property Lists.
 *
 * @namespace propertyQueries
 */
const propertyQueries = {
  /**
   * Fetches all property listings.
   *
   * @param {Axios} axios - The Axios instance to use for making requests.
   * @param {?import("axios").AxiosRequestConfig} [options] - Optional parameters for the query.
   * @returns {import('react-query').QueryConfig<any, Error, any>} - A React Query configuration object.
   */
  getAll: (axios, options) => ({
    queryKey: [`allProperties`],
    queryFn: () => fetchAllProperties(axios, options),
  })
};

export default propertyQueries;