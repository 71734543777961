import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Form, Spinner } from 'reactstrap';
import queryString from 'query-string';
import {
    Block,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockTitle,
    PreviewCard,
    Icon,
    Button
} from '../../components/Component';
import PageContainer from '../../layout/page-container/PageContainer';
import Head from '../../layout/head/Head';

import { axiosPrivate as axios } from '../../utils/Utils';

import Logo from '../../assets/images/albahomes-logo.png';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { USER_LOGIN } from '../../redux/types';
import { useForm } from 'react-hook-form';
import { validateEmail, validatePassword } from '../../utils/envConfig';
import AuthFooter from './AuthFooter';
import ResetPasswordComponent from './components/ResetPasswordComponent';

export default function ResetPassword() {
    const location = useLocation();

    const dispatch = useDispatch();

    const query = queryString.parse(location.search);

    const loggedInUser = useSelector((state) => state?.user?.loggedInUser);

    const fromRoute = location?.state?.from?.pathname ?? '/';

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [errorVal, setError] = useState(false);

    const [email, setEmail] = useState(location?.state?.email ?? '');

    const handleChange = (e) => {
        const { value } = e?.target;

        setEmail(value);
    };

    const onFormSubmit = async (formData) => {
        const userData = {
            email: formData?.email
        };

        try {
            setLoading(true);
            const response = await axios.post(
                `/auth/reset-password-request`,
                userData
            );

            setEmailSent(true);
        } catch (err) {
            console.log(err);
            setError(
                err?.response?.data?.message ??
                    'Unexpected error occurred, please try again later.'
            );
            setTimeout(() => {
                setError(false);
            }, 5000);
            setLoading(false);
        }
    };

    const { errors, register, handleSubmit } = useForm();

    // if lgged in user redirect to home page
    if (loggedInUser && loggedInUser?.access_token)
        return navigate(fromRoute, { replace: true });

    if (query?.token) {
        return (
            <React.Fragment>
                <ResetPasswordComponent token={query?.token} />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Head title="Reset Password" />
            <PageContainer>
                <Block className="nk-block-middle nk-auth-body  wide-xs">
                    <div className="brand-logo pb-4 text-center">
                        <div className="logo-link">
                            <img
                                className="logo-light logo-img logo-img-lg"
                                src={Logo}
                                alt="logo"
                            />
                            <img
                                className="logo-dark logo-img logo-img-lg"
                                src={Logo}
                                alt="logo-dark"
                            />
                        </div>
                    </div>

                    <PreviewCard
                        className="card-bordered"
                        bodyClass="card-inner-lg"
                    >
                        <BlockHead>
                            <BlockContent>
                                <BlockTitle tag="h4">Reset Password</BlockTitle>
                                <BlockDes>
                                    <p>
                                        Did you forget your password? Please
                                        enter your email address or username.
                                        You will receive a link to create a new
                                        password via email.
                                    </p>
                                </BlockDes>
                            </BlockContent>
                        </BlockHead>
                        {errorVal && (
                            <div className="mb-3">
                                <Alert color="danger" className="alert-icon">
                                    {' '}
                                    <Icon name="alert-circle" /> {errorVal}
                                </Alert>
                            </div>
                        )}
                        <Form
                            className="is-alter"
                            onSubmit={handleSubmit(onFormSubmit)}
                        >
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label
                                        className="form-label"
                                        htmlFor="default-01"
                                    >
                                        Email or Username
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        id="default-01"
                                        name="email"
                                        ref={register({
                                            required: 'This field is required',
                                            validate: (value) =>
                                                validateEmail(value) ||
                                                'Please enter a valid email'
                                        })}
                                        onChange={handleChange}
                                        defaultValue={email}
                                        value={email}
                                        placeholder="Enter your email address or username"
                                        className="form-control-lg form-control"
                                    />
                                    {errors.email && (
                                        <span className="invalid">
                                            {errors.email.message}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="form-group">
                                <Button
                                    size="lg"
                                    className="btn-block"
                                    type="submit"
                                    color="primary"
                                    disabled={
                                        errors?.passcode?.message ||
                                        errors?.email?.message ||
                                        loading
                                    }
                                >
                                    {loading ? (
                                        <Spinner size="sm" color="light" />
                                    ) : (
                                        'Reset Password'
                                    )}
                                </Button>
                            </div>
                        </Form>
                        {emailSent && (
                            <div className="form-note-s2 text-center pt-4">
                                <div>
                                    Check your email for the passcode. If you
                                    don't see it, check your spam folder. If you
                                    still don't see it, click the button below
                                    to open Gmail.
                                </div>
                                <div className="d-flex justify-content-center">
                                    <Link
                                        to={`https://accounts.google.com/ServiceLogin?service=mail`}
                                        target="_blank"
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div>
                                            <img
                                                src="/img/gmail_icon.webp"
                                                className="w-90px"
                                            />
                                        </div>
                                    </Link>
                                    <Link
                                        to={`https://accounts.google.com/ServiceLogin?service=mail`}
                                        target="_blank"
                                        className="ml-2"
                                    >
                                        Open Gmail
                                    </Link>
                                </div>
                            </div>
                        )}
                    </PreviewCard>
                </Block>
                <AuthFooter />
            </PageContainer>
        </React.Fragment>
    );
}
