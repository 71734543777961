import React, { useState, useEffect } from "react";
import { Badge } from "reactstrap";
import { intervalToDuration, formatDuration } from "date-fns";

const getHoursAsTimestamp = (hours) => hours * 60 * 60 * 1000;
const getMinutesAsTimestamp = (minutes) => minutes * 60 * 1000;
const getDaysAsTimestamp = (days) => days * 24 * 60 * 60 * 1000;

/**
 * Component that displays a badge indicating the time left until a specified date.
 * The badge dynamically updates to show the remaining time in a human-readable format (e.g., "2 days", "3 hours"),
 * and changes color based on the urgency (more than 5 hours left, less than 5 hours, or expired).
 *
 * The component uses `date-fns` library functions for date manipulation and formatting.
 * It recalculates the time left at different intervals depending on how much time is remaining until the target date:
 * - Every 12 hours if more than a day is left
 * - Every hour if more than 5 hours are left
 * - Every 30 minutes if between 5 hours and 30 minutes are left
 * - Every 5 minutes if less than 30 minutes but more than 5 minutes are left
 * - Every second if less than a minute is left
 *
 * This component is useful for displaying countdowns, deadlines, or the time left for event start times.
 *
 * Props:
 * - date (Date|string): The target date/time for the countdown. The component calculates the time left relative to this date.
 *
 * Example Usage:
 * ```jsx
 * <LiveTimeLeftBadge date="2023-12-31T23:59:59" />
 * ```
 *
 * Note: The component expects the `date` prop to be a valid Date object or a date string that can be parsed into a Date object.
 * If the `date` prop is not provided or invalid, the badge will display "Unknown" with default styling.
 */
const LiveTimeLeftBadge = ({ date, onStatusChange }) => {
  /**
   * Calcualte how much time is left after provided interval
   * @param date The date to display
   * @returns {timeLeftString, timeLeftMillis} human-readable string representing how much time is left, alongside the time left in milliseconds
   */
  const calculateTimeLeft = () => {
    if (!date) {
      return { timeLeftString: "Unknown", timeLeftMillis: 0 };
    }
    const dateAfterInterval = new Date(date).getTime();
    const now = new Date();

    if (now >= dateAfterInterval) {
      return { timeLeftString: "Expired", timeLeftMillis: 0 };
    }

    const duration = intervalToDuration({ start: now, end: dateAfterInterval });
    const timeLeftMillis = dateAfterInterval - now.getTime();
    const timeLeftString = formatDuration({
      years: duration.years,
      months: duration.months,
      weeks: duration.weeks,
      days: duration.days,
      hours: duration.hours,
      minutes: duration.minutes,
      seconds:
        duration.hours === 0 && duration.minutes <= 15 ? duration.seconds : 0,
    });

    return { timeLeftString, timeLeftMillis };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft().timeLeftString);
  // eslint-disable-next-line no-unused-vars
  const [timeLeftMillis, setTimeLeftMillis] = useState(
    calculateTimeLeft().timeLeftMillis
  );

  useEffect(() => {
    // This useEffect will update the badge every 1 hour if there's more than 5 hours left
    // every 10 minutes if less than 5 hours and every one second if there's less than a minute left

    const update = () => {
      const { timeLeftString, timeLeftMillis } = calculateTimeLeft();
      setTimeLeft(timeLeftString);
      setTimeLeftMillis(timeLeftMillis);

      if (
        timeLeftString === "Expired" &&
        onStatusChange &&
        typeof onStatusChange === "function"
      ) {
        onStatusChange();
      }
    };

    let intervalDuration;

    if (timeLeftMillis > getDaysAsTimestamp(1)) {
      // more than one day left
      intervalDuration = getHoursAsTimestamp(12); // update every 12 hours
    } else if (timeLeftMillis > getHoursAsTimestamp(5)) {
      // more than five hours left
      intervalDuration = getHoursAsTimestamp(1); // update every hour
    } else if (
      timeLeftMillis <= getHoursAsTimestamp(5) &&
      timeLeftMillis > getMinutesAsTimestamp(30)
    ) {
      // less than five hours left and more than 30 minutes
      intervalDuration = getMinutesAsTimestamp(30); // update every 30 minutes
    } else if (
      timeLeftMillis <= getMinutesAsTimestamp(30) &&
      timeLeftMillis >= getMinutesAsTimestamp(5)
    ) {
      // less than than 30 minutes and more than 5 minutes left
      intervalDuration = getMinutesAsTimestamp(5); // update every 5 minutes
    } else {
      // less than a minute left
      intervalDuration = 1000; // update every second
    }

    update(); // Initial update

    if (timeLeft !== "Expired") {
      const timer = setInterval(update, intervalDuration);
      return () => clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, timeLeft, timeLeftMillis]);

  const getStyle = () => {
    let style = { bg: "", border: "" };

    if (timeLeft === "Expired") {
      style.bg = "secondary-dim";
      style.border = "border-light";
    } else if (timeLeftMillis <= getHoursAsTimestamp(5)) {
      style.bg = "danger-dim";
      style.border = "border-danger";
    } else {
      style.bg = "success-dim";
      style.border = "border-success";
    }
    return style;
  };

  return timeLeft === "Expired" || timeLeft === "Unknown" ? <span>-</span> : (
    <Badge
      pill
      color={getStyle().bg}
      className={`text-dark ${getStyle().border}`}
    >
      {timeLeft}
    </Badge>
  );
};

export default LiveTimeLeftBadge;
