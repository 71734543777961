const HEADER_FIELDS = [
  { label: 'Type', breakpoint: 'md' },
  { label: 'Status', breakpoint: 'md' },
  { label: 'Scheduled Date', breakpoint: 'md' },
  { label: 'Completed Date', breakpoint: 'md' },
  { label: 'Notes', breakpoint: 'md' },
  { label: 'Description', breakpoint: 'md' },
  { label: 'Property', breakpoint: 'md' },
  { label: '', breakpoint: 'md' },
];

const SHOW_ACTIVITIES_OPTIONS = [5, 10, 20, 25, 50, 100]

// To be used within a React-select component
const ActivityTaskTypeOptions = [
  { label: 'Appointment', value: 'Appointment' },
  { label: 'Viewing', value: 'Viewing' },
  { label: 'Call', value: 'Call' },
  { label: 'WhatsApp', value: 'WhatsApp' },
  { label: 'SMSMessage', value: 'SMSMessage' },
  { label: 'Email', value: 'Email' },
];

const AllowedTaskTypes = [
  'Appointment',
  'Viewing',
  'Call',
  'WhatsApp',
  'SMSMessage',
  'Email',
];



export { HEADER_FIELDS, SHOW_ACTIVITIES_OPTIONS, ActivityTaskTypeOptions, AllowedTaskTypes  };