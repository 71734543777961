import React, { useState } from "react";
import { Card, Input } from "reactstrap";
import
{
  Button,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
} from "../../../components/Component";
import { formatValue, newRoles } from "../../../utils/envConfig";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Fees = ( { property, propertyUpdate = () => null } ) =>
{
  const [ newFees, setNewFees ] = useState( [] );
  const [ newFeeName, setNewFeeName ] = useState( "" );
  const [ newFeeValue, setNewFeeValue ] = useState( "" );
  const [ newFeeType, setNewFeeType ] = useState( "" );

  const authuser = useSelector( ( state ) => state?.user?.loggedInUser );

  // const addNewFee = () =>
  // {
  //   const newFee = {
  //     name: newFeeName || newFeeType,
  //     value: parseFloat( newFeeValue ),
  //   };

  //   // Add the new fee to the list of new fees
  //   setNewFees( [ ...newFees, newFee ] );

  //   // Reset the input fields
  //   setNewFeeName( "" );
  //   setNewFeeValue( "" );
  //   setNewFeeType( "" );
  // };
  const addNewFee = () =>
  {
    if ( newFeeName.trim() !== "" && newFeeValue.trim() !== "" )
    {
      const newFee = {
        name: newFeeName,
        value: parseInt( newFeeValue ), // Convert to an integer
      };
      setFees( [ ...fees, newFee ] );
      propertyUpdate( { fees: [ ...fees, newFee ] } );
      setNewFeeName( "" );
      setNewFeeValue( "" );
    } else
    {
      toast.error( "Name and Value must not be empty." );
    }
  };


  const deleteFee = ( index ) =>
  {
    const updatedFees = [ ...fees ];
    // Remove the fee at the specified index
    updatedFees.splice( index, 1 );
    // Update the state with the updated fees array
    setFees( updatedFees );
    propertyUpdate( { fees: updatedFees } );

  };

  // Assuming you already have fees state from the previous implementation
  const [ fees, setFees ] = useState( property?.fees || [] );

  return (
    <>
      {/* Existing code */ }
      <BlockHead>
        <BlockHeadContent>
          <Row>
            <Col sm="10">
              <BlockTitle tag="h5">Fees</BlockTitle>

            </Col>
            {/*             
            {fees?.length===0 &&
            <Col sm="2">
                     <Button
                     onClick={ addNewFee }
                     className="d-flex align-items-center ms-2"
                     style={ {
                       border: "1px solid #854fff",
                       borderRadius: "0.2rem",
                       height: "2.25rem",
                       paddingLeft: "0.3rem",
                       paddingRight: "0.3rem",
                     } }
                   >
                     <Icon name="plus" className="text-primary"></Icon>
                   </Button>
                   </Col>
          } */}

          </Row>

        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered">
        <ul className="data-list is-compact">

          { fees?.map( ( fee, index ) => (
            <li className="data-item" key={ index }>
              <div className="data-col">
                <div className="data-label">{ fee?.name }</div>
                <div className="data-value">AED { formatValue( fee?.value ) }</div>
              </div>
              { authuser?.role && (authuser?.role !== newRoles?.Agent) &&
              <Button onClick={ () => deleteFee( index ) }>Delete</Button>
              }
            </li>
          ) ) }
          {/* { newFees.map( ( fee, index ) => (
            <li className="data-item" key={ index }>
              <div className="data-col">
                <div className="data-label">{ fee?.name }</div>
                <div className="data-value">AED { formatValue( fee?.value ) }</div>
              </div>
              <Button onClick={ () => deleteFee( index + fees.length ) }>Delete</Button>
            </li>
          ) ) } */}
          { authuser?.role && (authuser?.role !== newRoles?.Agent) &&
          <li className="data-item">
            <div className="data-col d-flex justify-content-center align-items-center">
              {/* <Input
                type="select"
                value={ newFeeType }
                onChange={ ( e ) => setNewFeeType( e.target.value ) }
                className="me-2"
              >
                <option value="">Choose a type</option>
                <option value="Fee Type 1">Fee Type 1</option>
                <option value="Fee Type 2">Fee Type 2</option>
              </Input> */}
              <Input
                type="text"
                value={ newFeeName }
                onChange={ ( e ) => setNewFeeName( e.target.value ) }
                placeholder="Fee Name"
                className="me-2"
              />
              <Input
                type="number"
                value={ newFeeValue }
                onChange={ ( e ) => setNewFeeValue( e.target.value ) }
                placeholder="Fee Value"
              />
              <Button
                onClick={ addNewFee }
                className="d-flex align-items-center ms-2"
                style={ {
                  border: "1px solid #854fff",
                  borderRadius: "0.2rem",
                  height: "2.25rem",
                  paddingLeft: "0.3rem",
                  paddingRight: "0.3rem",
                } }
              >
                <Icon name="plus" className="text-primary"></Icon>
              </Button>
            </div>
          </li>
          }
        </ul>
      </Card>
    </>
  );
};

export default Fees;    
