import React from 'react';
import { Bar } from 'react-chartjs-2';

export default function MultiBarsBarChart({ data }) {
  return (
    <Bar
      className="ecommerce-line"
      data={{
        labels: data?.labels ?? [
          'Sun',
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
        ],
        datasets: data?.datasets ?? [
          {
            data: [1, 2, 3, 4, 5, 6, 7],
            //   borderWidth: 1,
            borderColor: '#9d72ff',
            backgroundColor: '#9472ff',
            //   label : {}
            //   pointBorderColor: 'transparent',
            //   pointBackgroundColor: 'transparent',
            //   pointHoverBackgroundColor: '#fff'
            //   : i === 1
            //   ? '#eb6459'
            //   : 'rgb(25, 250, 133)'
          },
          {
            data: [1, 2, 3, 4, 5, 6, 7],
            //   borderWidth: 1,
            borderColor: '#eb6459',
            backgroundColor: '#eb6459',
            //   pointBorderColor: 'transparent',
            //   pointBackgroundColor: 'transparent',
            //   pointHoverBackgroundColor: '#fff'
            //   : i === 1
            //   ? '#eb6459'
            //   : 'rgb(25, 250, 133)'
          },
        ],
        xLabels: 'Days',
        yLabels: 'Follow up / Overdue Tasks',
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            axis: 'x',
            grid: {
              // display: false,
              color: 'transparent',
              tickLength: 0,
            },
            ticks: {
              font: {
                style: '#9eaecf',
                size: 11,
              },
              padding: 10,
              //   stepSize: 3000,
              source: 'auto',
              color: '#9eaecf',
            },
          },
          y: {
            axis: 'y',
            ticks: {
              // beginAtZero: true,
              font: {
                size: 11,
                style: '#9eaecf',
              },
              color: '#9eaecf',
              // padding: 5,
              callback: function (value, index, values) {
                return value;
              },
              min: 0,
            },
          },
        },
      }}
    />
  );
}
