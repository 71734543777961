
import { newRoles } from "../../utils/envConfig";
import TenantsTable from "../components/table/TenantsTable";

const TenantsList = () => {
    return (
        <>
            <TenantsTable
                title={"List Tenants"}
                name={"Tenant"}
                role={newRoles.User}
                hide={true}
            />
        </>
    );
};

export default TenantsList;
