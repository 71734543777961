import LeadsTable from "../components/table/LeadsTable";

const LeadsUpload = () => {
    return (
        <>
            <LeadsTable title={"Upload Leads"} name={"Lead"} role={null} />
        </>
    );
};

export default LeadsUpload;
