import React, { lazy, Suspense, useMemo, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PaginationComponent,
  RSelect,
} from "../../../components/Component";
import {
  Button,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import CardsTable from "../../../components/table/card-table/CardsTable";
import CardsTableHeader from "../../../components/table/card-table/CardsTableHeader";
import { FINANCES_HEADER_FIELDS } from "./constants";
import CardsTableColumn from "../../../components/table/card-table/CardsTableColumn";
import CardsTableRow from "../../../components/table/card-table/CardsTableRow";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import { DealCategory, ScreenBreakPoints } from "../../../utils/envConfig";
import { currencyFormatter } from "../../explorer/utils";
import DatePicker from "react-datepicker";
import SelectAgent from "../../../components/SelectAgent";
import FilterMenu from "./components/FilterMenu";
import SearchComponent from "./components/SearchComponent";
import LoaderModal from "../../../components/modals/LoaderModal";
import { endOfDay, format, startOfDay } from "date-fns";
import useScreenSize from "../../../hooks/useScreenSize";
import FilterPills from "./components/FilterPills";

const TransactionCreationModal = lazy(() => import('../../deals/components/transactions/components/TransactionCreationModal'));
const ConfirmationModal = lazy(() => import('../../../components/modals/ConfirmationModal'));

/**
 * @typedef {Object} ViewProps
 * @property {unknown} queryData
 * @property {import('./types').DealTransactionQueryState} queryParams
 * @property {React.Dispatch<React.SetStateAction<import('./types').DealTransactionQueryState>>} setQueryParams
 * @property {unknown} requestCsvMutation
 */

const FIVE_PERCENT = 0.05;
const calculateCommission = (price = 0, percentage = 0) => {
  const commission = (percentage / 100) * price;
  // there is a five percent tax on commission
  const taxedCommission = commission + (FIVE_PERCENT * commission);

  return taxedCommission;
};

/**
 * DealCommissionsView component renders a table for all deals and their transaction information.
 *
 * @param {ViewProps} props - The properties for the component.
 * @returns {JSX.Element}
 */
const DealCommissionsView = ({ queryData, queryParams, setQueryParams, requestCsvMutation }) => {
  const pageCount = Math.ceil(
    (queryData?.data?.found ?? 0) / queryParams?.pageSize
  );

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const toggleConfirm = () => setIsConfirmOpen(!isConfirmOpen);

  const handleFromDateChange = (date) => {
    setQueryParams((prev) => ({
      ...prev,
      transactions: {
        ...prev.transactions,
        fromPaymentDate: date ? startOfDay(date).toISOString() : null,
      },
      page: 1
    }));
  };

  const handleToDateChange = (date) => {
    if (date) {
      setQueryParams((prev) => ({
        ...prev,
        transactions: {
          ...prev.transactions,
          toPaymentDate: endOfDay(date).toISOString(),
        },
        page: 1
      }));
    } else {
      setQueryParams((prev) => ({
        ...prev,
        transactions: { ...prev.transactions, toPaymentDate: null },
        page: 1
      }));
    }
  };

  const handleExportAsCsv = () => {
    requestCsvMutation && requestCsvMutation.mutate(null, {
      onSettled: () => {
        toggleConfirm();
      }
    });
  }

  const parsedTransactionParams = useMemo(() => queryParams?.transactions ?? {}, [queryParams?.transactions]);
  const screenSize = useScreenSize();

  const handleMortgageSelect = (isMortgage) => {
    setQueryParams((prev) => ({
      ...prev,
      isMortgage,
      page: 1,
    }));
  };

  const getDropdownLabel = () => {
    if (queryParams.isMortgage === true) {
      return 'Yes';
    } else if (queryParams.isMortgage === false) {
      return 'No';
    } else {
      return 'Mortgage';
    }
  };

  return (
    <React.Fragment>
      <Head title="Finances - Commissions"></Head>
      <Content>
        <BlockHead className={'pb-2'}>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>
                Transactions
                {queryData.isLoading ? (
                  <Spinner
                    size={"lg"}
                    type="grow"
                    className="ms-4 fw-light"
                    color="primary"
                  />
                ) : null}
              </BlockTitle>
              <BlockDes>
                {!queryData.isLoading ? (
                  <>
                  <p>
                    Found <span className="text-primary">{queryData?.data?.found ?? 'No'}</span> Transaction(s) <br />
                    {queryData?.data?.found ?
                    <span className="fw-bold">
                      {`Total of All Commissions Earned: ${currencyFormatter({ isDecimal: true }).format(queryData?.data?.totalCommissions)} (Without taxes)`}
                    </span> : null}
                  </p>
                  </>
                ) : (
                  <p className="invisible">Loading...</p>
                )}
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
          <div className="d-flex justify-content-end">
            <div className={"d-flex flex-wrap"}>
            <div className="align-self-end me-3 my-2 my-lg-0">
            <UncontrolledDropdown>
              <DropdownToggle
                color="transparent"
                className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
              >
                {getDropdownLabel()}
              </DropdownToggle>
              <DropdownMenu start>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      onClick={() => handleMortgageSelect(true)}
                      className={queryParams.isMortgage === true ? 'selected' : ''}
                    >
                      <span>Yes</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      onClick={() => handleMortgageSelect(false)}
                      className={queryParams.isMortgage === false ? 'selected' : ''}
                    >
                      <span>No</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      onClick={() => handleMortgageSelect(null)}
                      className={queryParams.isMortgage === null ? 'selected' : ''}
                    >
                      <span>Clear</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
              </div>
              <div className="align-self-end me-3 my-2 my-lg-0">
                <SelectAgent
                  isClearable
                  selectedAgent={queryParams?.agent}
                  onSelectAgent={(agent) => {
                    setQueryParams((prev) => ({
                      ...prev,
                      agent,
                      page: 1
                    }));
                  }}
                />
              </div>
              <div className="form-group">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="form-label mt-2">
                    Payment Date
                  </label>
                  <div className="form-control-wrap datepicker-zindex mb-2 mb-lg-0">
                    <div className="input-daterange date-picker-range input-group datepicker-zindex">
                      <DatePicker
                        selected={
                          Object.keys(parsedTransactionParams).length &&
                          parsedTransactionParams?.fromPaymentDate
                            ? new Date(
                               parsedTransactionParams?.fromPaymentDate
                              )
                            : null
                        }
                        dateFormat="dd/MM/yyyy"
                        selectsStart
                        startDate={
                          Object.keys(parsedTransactionParams).length &&
                          parsedTransactionParams?.fromPaymentDate
                            ? new Date(
                               parsedTransactionParams?.fromPaymentDate
                              )
                            : null
                        }
                        endDate={
                          Object.keys(parsedTransactionParams).length &&
                          parsedTransactionParams?.toPaymentDate
                            ? new Date(
                               parsedTransactionParams?.toPaymentDate
                              )
                            : null
                        }
                        onChange={handleFromDateChange}
                        isClearable
                        wrapperClassName="start-m"
                        className="form-control datepicker-zindex"
                      />
                      <div className="input-group-addon">TO</div>
                      <DatePicker
                        selected={
                          Object.keys(parsedTransactionParams).length &&
                          parsedTransactionParams?.toPaymentDate
                            ? new Date(
                               parsedTransactionParams?.toPaymentDate
                              )
                            : null
                        }
                        selectsEnd
                        dateFormat="dd/MM/yyyy"
                        startDate={
                          Object.keys(parsedTransactionParams).length &&
                          parsedTransactionParams?.fromPaymentDate
                            ? new Date(
                               parsedTransactionParams?.fromPaymentDate
                              )
                            : null
                        }
                        endDate={
                          Object.keys(parsedTransactionParams).length &&
                          parsedTransactionParams?.toPaymentDate
                            ? new Date(
                               parsedTransactionParams?.toPaymentDate
                              )
                            : null
                        }
                        isClearable
                        minDate={
                          Object.keys(parsedTransactionParams).length &&
                          parsedTransactionParams?.fromPaymentDate
                            ? new Date(
                               parsedTransactionParams?.fromPaymentDate
                              )
                            : null
                        }
                        onChange={handleToDateChange}
                        wrapperClassName="start-m"
                        className="form-control datepicker-zindex"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <FilterPills queryParams={queryParams} setQueryParams={setQueryParams} />

          <BlockBetween className={'mt-2'}>
            <div className="align-self-end me-3 d-flex">
              <SearchComponent queryParams={queryParams} setQueryParams={setQueryParams} />
              <Button className="ms-2" color="primary" onClick={toggleConfirm} disabled={!queryData?.data?.found}>
                {screenSize?.width < ScreenBreakPoints.md ? "CSV" : "Export as CSV"}
              </Button>
            </div>
          </BlockBetween>
        </BlockHead>
        <Block>
          <CardsTable className="mb-2" isSeparate>
            <CardsTableHeader className="bg-white">
              {FINANCES_HEADER_FIELDS.map((header, idx) => (
                  (header?.name === "Total Amount" || header?.name === "Deal Date") &&
                  screenSize?.width >= ScreenBreakPoints.laptop &&
                  screenSize?.width <= ScreenBreakPoints.xl ? null :
                  <CardsTableColumn
                    key={`transactions-column-${idx}`}
                    breakpoint={header?.breakpoint}
                    isActions={header?.isActions}
                    isCheckbox={header?.isCheckbox}
                    className={
                      header?.isActions
                        ? "text-center"
                        : header?.first
                        ? "text-start"
                        : "text-center"
                    }
                  >
                    <span>{header?.name}</span>
                  </CardsTableColumn>
              ))}
              <CardsTableColumn>
                {!queryData.isLoading ? <FilterMenu queryParams={queryParams} setQueryParams={setQueryParams} /> : " "}
              </CardsTableColumn>
            </CardsTableHeader>

            {queryData.isLoading
              ? new Array(queryParams?.pageSize).fill(0).map((_, idx1) => (
                  <CardsTableRow key={`fin-skeleton-row-${idx1}`}>
                    {new Array(FINANCES_HEADER_FIELDS.length + 1).fill(0)
                      .map((_, idx2) => (
                        <CardsTableColumn
                          key={`fin-skeleton-col-${idx1}-${idx2}`}
                        >
                          <Skeleton style={{ width: idx2 === FINANCES_HEADER_FIELDS.length ? '4rem' : '8rem' }} />
                        </CardsTableColumn>
                      ))}
                  </CardsTableRow>
                ))
              : null}

            {queryData?.data?.data?.map((item, idx) => (
              <DealTableItem key={`fin-table-item-${idx}`} dealInfo={item} screenSize={screenSize} />
            ))}
          </CardsTable>

          {queryData?.isError ? (
            <Card>
              <div className="card-inner">
                <p className="text-center text-danger fs-6">
                  Failed to fetch data.
                  <br />
                  {queryData?.error?.response?.data?.message ?? null}
                </p>
              </div>
            </Card>
          ) : null}

          {queryData?.data?.data &&
          !queryData?.isLoading &&
          !queryData?.isError ? (
            <div className="card-inner bg-white">
              <BlockBetween>
                {queryData?.data?.found ? (
                  <div className="g">
                    <PaginationComponent
                      currentPage={queryParams?.page ?? 1}
                      itemPerPage={queryParams?.pageSize}
                      paginate={(pageNum) => {
                        setQueryParams((prev) => ({ ...prev, page: pageNum }));
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                      totalItems={queryData?.data?.found ?? 0}
                    />
                  </div>
                ) : (
                  <p className="lead-text">No results found</p>
                )}
                {queryData?.data?.found ? (
                  <div className="g">
                    <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                      <div>Page</div>
                      <div style={{ minWidth: "5rem" }}>
                        <RSelect
                          options={new Array(pageCount).fill(0).map((_, i) => ({
                            label: (i + 1).toString(),
                            value: i + 1,
                          }))}
                          value={{
                            label: queryParams?.page,
                            value: queryParams?.page,
                          }}
                          onChange={(val) => {
                            setQueryParams((prev) => ({
                              ...prev,
                              page: val.value,
                            }));
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        />
                      </div>
                      <div>of {pageCount}</div>
                    </div>
                  </div>
                ) : null}
              </BlockBetween>
            </div>
          ) : null}
        </Block>
      </Content>
      {isConfirmOpen && <Suspense fallback={<LoaderModal />}>
        <ConfirmationModal
          isOpen={isConfirmOpen}
          toggleFn={toggleConfirm}
          title={'Confirm CSV Export'}
          confirmBtnLabel="Export & Download"
          cancelBtnLabel="Cancel"
          dimButtons
          actionFn={handleExportAsCsv}
          loadingState={requestCsvMutation.status === 'loading'}
        >
          <p>
            Export the data as a CSV File? <br />
            <span className="text-soft">Any filters you selected will be applied.</span>
          </p>
        </ConfirmationModal>
      </Suspense>}
      
    </React.Fragment>
  );
};

const DealTableItem = ({ dealInfo, screenSize }) => {
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);
  const toggleCreationModal = () =>
    setIsCreationModalOpen(!isCreationModalOpen);

  const navigate = useNavigate();

  const totalPaid = useMemo(
    () =>
      dealInfo?.transactions?.reduce(
        (sum, transaction) => sum + transaction?.amount,
        0
      ),
    [dealInfo]
  );


  const commissionPercentage = useMemo(() => {
    // Add %5 of tax to the commission percentage
    if (dealInfo?.category === DealCategory.PrimaryOffplan) {
      return Number(dealInfo?.agreed_commission ?? 0) + Number(FIVE_PERCENT * Number(dealInfo?.agreed_commission ?? 0));
    } else if (dealInfo?.category === DealCategory.SecondaryProperty) {
      const totalCommission = dealInfo?.agreed_commission
       ? Number(dealInfo?.agreed_commission ?? 0)
       : Number(dealInfo?.seller_agreedCommission ?? 0) + Number(dealInfo?.buyer_agreedCommission ?? 0);

      return (
        totalCommission + Number(FIVE_PERCENT * totalCommission)
      );
    }
  }, [dealInfo]);

  const dealCommission = useMemo(() => {
    if (dealInfo?.category === DealCategory.PrimaryOffplan) {
      return calculateCommission(
        dealInfo?.agreed_salePrice ?? 0,
        Number(dealInfo?.agreed_commission ?? 0)
      );
    } else if (dealInfo?.category === DealCategory.SecondaryProperty) {
      const commission = dealInfo?.agreed_commission ? Number(dealInfo?.agreed_commission ?? 0) :
      Number(dealInfo?.seller_agreedCommission ?? 0) +
        Number(dealInfo?.buyer_agreedCommission ?? 0);
      return calculateCommission(dealInfo?.agreed_salePrice ?? 0, commission);
    }
  }, [dealInfo]);

  const remainingBalance = useMemo(
    () => dealInfo?.transactions?.length === 0 ? dealCommission: Number(dealInfo?.transactions[0]?.balance ?? 0),
    [dealCommission, dealInfo?.transactions]
  );

  return (
    <React.Fragment>
      <CardsTableRow>
        <CardsTableColumn>
          <Link to={`/deal-details/${dealInfo?._id ?? dealInfo?.id}`}>
            {dealInfo?.reference_number}
          </Link>
        </CardsTableColumn>
        <CardsTableColumn breakpoint={'md'}>
          <span>{dealInfo?.category}</span>
        </CardsTableColumn>
        {screenSize?.width >= ScreenBreakPoints.laptop &&
        screenSize?.width <= ScreenBreakPoints.xl ? null : (
          <CardsTableColumn breakpoint={'md'}>
            <span>
              {dealInfo?.creation_date
                ? format(new Date(dealInfo?.creation_date), "dd/MM/yyyy")
                : "Unknown"}
            </span>
          </CardsTableColumn>
        )}
        {dealInfo?.category === DealCategory.PrimaryOffplan ? (
          <CardsTableColumn breakpoint={"lg"} className="text-center">
            <span>
              {dealInfo?.assigned_agent?.user?.first_name ?? "Unknown"}
            </span>
          </CardsTableColumn>
        ) : null}
        {dealInfo?.category === DealCategory.SecondaryProperty ? (
          <CardsTableColumn breakpoint={"lg"} className="text-center">
            <div className="d-flex flex-column">
              <span>{`Buyer: ${
                dealInfo?.buyer_internal_agent?.user?.first_name ?? "Agency"
              }`}</span>
              <span>{`Seller: ${
                dealInfo?.seller_internal_agent?.user?.first_name ?? "Agency"
              }`}</span>
            </div>
          </CardsTableColumn>
        ) : null}
        <CardsTableColumn breakpoint={"lg"} className="text-center">
          {dealInfo?.category === DealCategory.SecondaryProperty ? (
            <span>
              {dealInfo?.propertyList?.propertyType?.propertyType ?? "Unknown"}
            </span>
          ) : null}
          {dealInfo?.category === DealCategory.PrimaryOffplan ? (
            <span>
              {dealInfo?.new_listing_offplan?.propertyType ?? "Unknown"}
            </span>
          ) : null}
        </CardsTableColumn>
        <CardsTableColumn breakpoint={'md'}>
          <span>
            {dealInfo?.category === DealCategory.PrimaryOffplan
              ? "For Sale"
              : dealInfo?.purpose}
          </span>
        </CardsTableColumn>
        {screenSize?.width >= ScreenBreakPoints.laptop &&
        screenSize?.width <= ScreenBreakPoints.xl ? null : (
          <CardsTableColumn breakpoint={"lg"} className="text-center">
            <span>
              {dealInfo?.agreed_salePrice
                ? currencyFormatter({ isDecimal: false }).format(
                    dealInfo?.agreed_salePrice
                  )
                : "Unknown"}
            </span>
          </CardsTableColumn>
        )}
        <CardsTableColumn>
          <>
            <span>
              {currencyFormatter({ isDecimal: true }).format(dealCommission)}
            </span>
            <span className="fw-bold">{` (${(commissionPercentage ?? 0).toFixed(
              2
            )}%)`}</span>
          </>
        </CardsTableColumn>
        <CardsTableColumn>
          <>
            <span>
              {currencyFormatter({ isDecimal: true }).format(totalPaid)}
            </span>
            <span
              className="fw-bold"
              onClick={() => console.log(dealCommission, commissionPercentage)}
            >
              {` (${(
                (Number(totalPaid) /
                  (dealCommission === 0 ? 1 : dealCommission)) *
                100
              ).toFixed(2)}%)`}
            </span>
          </>
        </CardsTableColumn>
        <CardsTableColumn breakpoint={'md'}>
          <>
            <span>
              {currencyFormatter({ isDecimal: true }).format(remainingBalance)}
            </span>
            <span className="fw-bold">
              {` (${(
                (Number(dealCommission - totalPaid) /
                  (dealCommission === 0 ? 1 : dealCommission)) *
                100
              ).toFixed(2)}%)`}
            </span>
          </>
        </CardsTableColumn>
        <CardsTableColumn>
          <UncontrolledDropdown>
            <DropdownToggle
              tag="span"
              className="btn btn-trigger btn-icon dropdown-toggle"
            >
              <Icon name="more-h"></Icon>
            </DropdownToggle>
            <DropdownMenu>
              <ul className="link-check">
                <li>
                  <DropdownItem
                    className="cursor-pointer"
                    tag="a"
                    onClick={(ev) => {
                      ev.preventDefault();
                      navigate(
                        `/deal-details/${
                          dealInfo?.id ?? dealInfo?._id
                        }#transactions`
                      );
                    }}
                  >
                    <Icon name={"eye"} />
                    {`View Deal Transactions`}
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </CardsTableColumn>
      </CardsTableRow>
      {isCreationModalOpen && (
        <Suspense fallback={<LoaderModal />}>
          <TransactionCreationModal
            deal={dealInfo}
            isOpen={isCreationModalOpen}
            toggleFn={toggleCreationModal}
          />
        </Suspense>
      )}
    </React.Fragment>
  );
};

export default DealCommissionsView;
