import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import DatePicker from "react-datepicker";
import {
  Block,
  BlockDes,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  TooltipComponent,
  PaginationComponent,
  PreviewAltCard,
  Row,
  Col,
  RSelect,
  DataTable,
  DataTableBody,
} from "../../../components/Component";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  Modal,
  ModalBody,
  Badge,
} from "reactstrap";
import { useForm } from "react-hook-form";
import {
  activeStatus,
  LeadStatus,
  newRoles,
  LeadLabels,
  LeadStatusUncheckedReturn,
} from "../../../utils/envConfig";
import { toast } from "react-toastify";
import { endOfDay, format, startOfDay } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useRef } from "react";
import {
  fetchUsers,
  fetchAgents
} from '../../../redux/actions/user';
import { useDispatch } from 'react-redux';
import LoadingComponent from '../loader/tableLoader';
import NewAgentAvatar from '../../users/components/NewAgentAvatar';
import useMutationQuery from "../../../hooks/useMutationQuery";
import { returnLeads } from "../../../api/property-cards/returnLeads";
import { useQueryClient } from "react-query";
import { showToast } from "../../../utils/toast/toast";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import DndFlagComponent from '../dnd/dndFlag'
import NewLeadFlagComponent from '../new-lead-flag/newLeadFlag'
import { fetchGlobalSettings } from '../../../redux/actions/settings';
import SendToPhone from "../../../components/SendToPhone";
const LeadsListTable = ({
  title,
  initialData = [],
  name,
  role = null,
  hide = false,
}) => {

  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const authuser = useSelector((state) => state?.user?.loggedInUser);

  // localstorage
  const roleGet = localStorage.getItem("leadRoleGetValue") || "";
  const statusGet = localStorage.getItem("leadStatusGetValue") || "";
  const startDateGet = localStorage.getItem("leadStartDateGet") || "";
  const endDateGet = localStorage.getItem("leadEndDateGet") || "";
  const filterLeadSourceGet =
    localStorage.getItem("leadFilterLeadSourceGetValue") || "";
  const leadLastStatusGet =
    localStorage.getItem("leadLastStatusGetValue") || "";
  const leadsAgentGet = localStorage.getItem("leadsAgentGetValue") || "";
  const leadsAgentGetObj = localStorage.getItem("leadsAgentGetObj") || "";
  const leadsLabelGetObj = localStorage.getItem("leadsLabelGetObj") || "";
  const leadsLabelGetOption = localStorage.getItem("leadsLabelGetOption") || "";

  // State Variables
  const [leadSource, setLeadSource] = useState(null);
  const [usersSelected, setusers] = useState(initialData);
  const [user, setUser] = useState(null);
  const [userId, setuserId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totpages, setTotpages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [resPerPage, setResPerPage] = useState(0);
  const [filCount, setFilCount] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [filterSearch, setFilterSearch] = useState(null);
  const [filterRole, setFilterRole] = useState(role);
  const [filterStatus, setFilterStatus] = useState(null);
  const [newLeadsSort, setNewLeadsSort] = useState(false);
  const [filterLeadStatus, setFilterLeadStatus] = useState(null);
  const [filterLeadLabel, setFilterLeadLabel] = useState(null);
  const [filterLeadSource, setFilterLeadSource] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);
  const [dateValue, setDateValue] = useState({ startDate: "", endDate: "" });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [errors, setErrors] = useState({});
  const { myerrors, register, handleSubmit } = useForm();
  const [rangeStart, setRangeStart] = useState();
  const [rangeEnd, setRangeEnd] = useState(new Date());
  const [value, setValue] = useState();
  const [errorX, setErrorX] = useState(null);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [sort, setSortState] = useState("DESC");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optLeadSource, setOptLeadSource] = useState(null);
  const [selectedOptLeadSource, setSelectedOptLeadSource] = useState(null);
  const [filterLabel, setFilterLabel] = useState(null);
  const [filterAgent, setFilterAgent] = useState(null);
  const [optAgents, setOptAgents] = useState([]);
  const [actionText, setActionText] = useState("");
  const dispatch = useDispatch();
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [sortBy, setSorBy] = useState("createdAt");
  const [temporaryLabel, setTemporaryLabel] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reAssignmentReason, setReAssignmentReason] = useState('');

  const allowedRoles = ["User", "Owner", "Investor"];

  const queryClient = useQueryClient();

  useEffect(() => {
    dispatch(fetchGlobalSettings(axios));
  }, [dispatch, axios]);

  const settingsState = useSelector((state) => ({
    globalSettings: state.settings.globalSettings,
    loading: state.settings.loading,
    error: state.settings.error,
  }));
  console.log(settingsState?.globalSettings?.global?.newLeadFlagDateLimit);

  const returnMutation = useMutationQuery(
    (payload) => returnLeads(axios, payload),
    {
      mutationKey: 'returnLeads',
      onSettled: () => {
        queryClient.invalidateQueries('leadsWithPropCards');
        queryClient.invalidateQueries('authAgentData');
      },
      onError: (error) => {
        console.error(error);
        showToast(error?.message ?? 'Returning leads failed', 'error');
      },
      onSuccess: (data) => {
        if (data && data?.length >= 0) {
          showToast(`Successfully returned ${data?.length} lead(s).`, 'success');
        } else {
          showToast('Successfully returned leads.', 'success');
        }
        setTimeout(() => navigate(0), 1500);
      }
    }
  );

  const [returnModalState, setReturnModalState] = useState({
    isModalOpen: false,
    selectedLead: null
  });
  const toggleReturnConfirm = (selectedLeadId) => setReturnModalState(prev => ({
    isModalOpen: !returnModalState.isModalOpen,
    selectedLead: selectedLeadId
  }));

  const [isBulkReturnConfirmOpen, setIsBulkReturnConfirmOpen] = useState(false);
  const toggleBulkReturnConfirm = () => setIsBulkReturnConfirmOpen(!isBulkReturnConfirmOpen);


  useEffect(() => {
    dispatch(fetchAgents(axios));
  }, [axios, dispatch]);

  const agent = useSelector((state) => state.user?.agents);
  useEffect(() => {
    let options = [];
    let result =
      agent &&
      agent.length > 0 &&
      agent
        .filter((agents) => agents?.user?.active === true)
        .map((a) =>
          options.push({
            value: a?.id,
            label: a?.user?.first_name + " " + a?.user?.last_name,
          })
        );
    setOptAgents(options);
  }, [agent]);

  const handleAgentOption = (e) => {
    setFilterAgent(e.value);
  };

  const bulkActionOptions = [
    { value: "assign", label: "Assign to agent", roles: [newRoles.Admin, newRoles.MasterAdmin] },
    { value: "return", label: "Return Leads", roles: [newRoles.Agent] },
    // { value: "status", label: "Change Status" },
    // { value: "disqualify", label: "Disqualify" },
  ];

  // const filteredOptions = bulkActionOptions.filter(
  //   (option) => option.value !== "assign" || filterLeadStatus !== "Disqualified"
  // );
  const filteredOptions = bulkActionOptions.filter(
    (option) => option.roles.some((allowedRole) => authuser?.roles?.includes(allowedRole))
  );

  const onActionText = (e) => {
    setActionText(e.value);
  };

  const validate = (selectedAgent) => {

    if (!selectedAgent.length || selectedAgent === "Select") {
      toast.error("Select Agent");
      return false;
    }else if (!reAssignmentReason.trim()) {
      toast.error("Please provide a reason for reassignment.");
      return false;
    } else {
      return true;
    }
  };
  const onActionClick = async (e) => {
    setSaveLoading(false);
    const selectedIdsReassign = selectedItems.map(item => item.id);
    e.preventDefault();
    if (validate(selectedAgent)) {
      const formData = {
        agent_id: selectedAgent.toString(),
        user_ids: selectedIdsReassign, 
        notes: reAssignmentReason
      };

      try {
        setSaveLoading(true);
        const _res = await axios.post("/usersAgents/assign", formData, {
          headers,
        });

        toast.success("Agent assigned successfully!");
        setLoading(!loading);
        setSaveLoading(false);
      } catch (err) {
        setSaveLoading(false);
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Users not added to Agent!");
        }
      } finally {
        setSelectedItems([]);
      }
    }
  };

  const handleRoleOption = (e) => {
    setusers(null);
    setCurrentPage(1);
    if (e) {
      setFilterRole(e);
      //setIsFiltering( !!e );
      localStorage.setItem("leadRoleGetValue", e);
    } else {
      if (filterSearch) {
        setIsFiltering(!!filterSearch);
      } else {
        setIsFiltering(false);
      }
      localStorage.setItem("leadRoleGetValue", "");
      setFilterRole("");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    let link = `/search/user/filter?page=${currentPage}&sort_by=${sortBy}&sort_dir=${sort}&per_page=${itemsPerPage}`;

    const leadStatusValues = Object.values(LeadStatus).filter(
      (status) => status !== LeadStatus.Disqualified
    );

    if(newLeadsSort){
      link = link.concat(`&newLeads=${newLeadsSort}`);
    }

    if (filterLeadStatus) {
      link = link.concat(`&lead_status=${filterLeadStatus}`);
    } else if (leadLastStatusGet) {
      link = link.concat(`&lead_status=${leadLastStatusGet}`);
    }

    if (dateValue.startDate && dateValue.endDate) {
      const fromDate = startOfDay(new Date(dateValue.startDate)).toISOString();
      const toDate = endOfDay(new Date(dateValue.endDate)).toISOString();
      link += `&from_date=${fromDate}&to_date=${toDate}`;
    } else if (startDateGet && endDateGet) {
      const fromDate = startOfDay(new Date(startDateGet)).toISOString();
      const toDate = endOfDay(new Date(endDateGet)).toISOString();
      link += `&from_date=${fromDate}&to_date=${toDate}`;
    } else {
    }

    if (filterAgent) {
      link = link.concat(`&agent_id=${filterAgent}`);
    } else if (leadsAgentGet) {
      link = link.concat(`&agent_id=${leadsAgentGet}`);
    }

    // Filter only allowed Roles
    if (!roleGet) {
      const filteredRoles = Object.values(newRoles)
        .filter((role) => allowedRoles.includes(role))
        .map((role) => `roles[]=${role}`)
        .join("&");
      link = link.concat(`&${filteredRoles}`);
    }

    const roleValue =
      filterRole || (roleGet !== "null" && roleGet !== "" && roleGet !== null)
        ? filterRole || roleGet
        : "";
    if (roleValue) {
      link = link.concat(`&roles[]=${roleValue}`);
    }

    if (filterSearch)
      link = link.concat(`&search=${encodeURIComponent(filterSearch.trim())}`);
    if (filterRole) link = link.concat(`&roles[]=${filterRole}`);

    const activeValue =
      filterStatus || statusGet
        ? filterStatus === activeStatus[0] || statusGet === activeStatus[0]
        : true;
    link = link.concat(`&active=${activeValue}`);

    if (filterLeadSource) {
      link = link.concat(`&lead_source_many=${filterLeadSource?.join()}`);
    } else if (!filterLeadSource) {
      if (filterLeadSourceGet !== "") {
        const leadSourceArray = JSON.parse(filterLeadSourceGet);

        leadSourceArray?.forEach((lead) => {
          link = link.concat(`&lead_source_many=${lead.label}`);
        });

        setSelectedOptions(leadSourceArray);
      } else if (leadsLabelGetObj !== "") {
        link = link.concat(`&lead_source_many=${leadsLabelGetObj}`);
      }
    }

    // if(filterLeadLabel === "Interested"){
    //   link = link.concat( `&withIsInterested=true` );
    // }
    dispatch(fetchUsers(axios, link));
    setGetUserAgents([]);
  }, [
    dispatch,
    filterSearch,
    filterRole,
    filterStatus,
    filterLeadSource,
    dateValue,
    itemsPerPage,
    sort,
    currentPage,
    filterAgent,
    loading,
    filterLeadStatus,
    filterLeadLabel,
    newLeadsSort
  ]);

  const usersList = useSelector((state) => state?.user?.users);
  const [users, setUserFilter] = useState([]);

  const userResult = usersList?.findUsersOutput;
  const filteredTotalCount = usersList?.filteredCount;
  const totalDataCount = usersList?.ListingCount;
  const perPageLength = usersList?.searchPerPage;

  const [sortByTitle, setSortByTitle] = useState("");
  const [sortByOrder, setSortByOrder] = useState("");

  useEffect(() => {
    const sortedUsers = [...users];

    sortedUsers.sort((a, b) => {
      const titleA = a[sortByTitle];
      const titleB = b[sortByTitle];
      return sortByOrder === "asc"
        ? titleA?.localeCompare(titleB)
        : titleB?.localeCompare(titleA);
    });

    setUserFilter(sortedUsers);
  }, [sortByTitle, sortByOrder]);

  useEffect(() => {
    setUserFilter(userResult);
    setFilCount(filteredTotalCount);
    settotalCount(totalDataCount);
    setResPerPage(perPageLength);
    setTotpages(Number(Math.ceil(filteredTotalCount / perPageLength)));
    setIsLoading(false);
    setLoading(true);
  }, [usersList?.findUsersOutput]);

  async function fetchOneUser(userId) {
    try {
      await axios
        .get(`/users/${userId}`, { headers })
        .then((user) => {
          setUser(user.data);
          setFormData({
            ...user?.data,
            firstName: user.data?.first_name,
            lastName: user.data?.last_name,
            email: user.data?.email,
            phone: user.data?.phone,
            active: user.data?.active ? activeStatus[0] : activeStatus[1],
            role: user.data?.role,
          });
        })
        .catch((err) => {
          toast.error("User not available !");
        });
    } catch (err) {
      toast.error("Server error. Try Again !");
    }
  }

  //dispatch(UpdateUser(axios, userId, formData))

  // Constants
  // Validate User Token
  const { access_token } =
    useSelector((state) => state?.user?.loggedInUser) || "";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };

  const [getUserAgents, setGetUserAgents] = useState({});

  useEffect(() => {
    const fetchAgentsTest = async (id) => {
      try {
        const res = await axios.get(`/usersAgents/user/${id}`, { headers });

        setGetUserAgents((prevState) => ({
          ...prevState,
          [id]: res?.data,
        }));
      } catch (err) {
        // console.error(err);
        // toast.error('Server error. Please try again!');
      }
    };

    if (users && users.length) {
      users.forEach((item) => {
        fetchAgentsTest(item?.id);
      });
    }
  }, [dispatch, users, loading]);

  const handleLeadSource = (e) => {
    setSelectedOptions(e);
    setCurrentPage(1);
    if (e) {
      let lg = [];
      lg.push(e);
      let lgFilter = lg && lg[0]?.map(({ label }) => label);
      setFilterLeadSource(lgFilter);

      const lgString = JSON.stringify(lg[0]);
      localStorage.setItem("leadsSourceGetValue", lgString);
      localStorage.setItem("leadFilterLeadSourceGetValue", lgString);
    } else {
      setFilterLeadSource(null);
    }
    setTemporaryLabel("Filter by Label");
    localStorage.setItem("leadsLabelGetObj", "");
    localStorage.setItem("leadsLabelGetOption", "");
  };

  const CustomMultiValueLabel = ({ data, ...props }) => (
    <div className="selected-option" {...props}>
      {data.label}
    </div>
  );

  const handleBadgeRemoveAndLeadSource = (option) => {
    const updatedOptions = selectedOptions.filter(
      (o) => o.value !== option.value
    );
    setSelectedOptions(updatedOptions);

    if (updatedOptions) {
      let lg = [];
      lg.push(updatedOptions);
      let lgFilter = lg && lg[0]?.map(({ label }) => label);
      setFilterLeadSource(lgFilter);

      const lgString = JSON.stringify(lg[0]);
      localStorage.setItem("leadsSourceGetValue", lgString);
      localStorage.setItem("leadFilterLeadSourceGetValue", lgString);
    } else {
      setFilterLeadSource(null);
    }
  };

  const selectRef = useRef(null);



  const handleLeadLabelOption = async (leadLabel) => {
  
    let filterLeadSource = [];
    let localStorageLabelKey = "leadsLabelGetObj";
  
    try {
      switch (leadLabel) {
        case "ColdLead":
          const coldLeadRes = await axios.get(`/leadSources/coldleads`, headers);
          filterLeadSource = coldLeadRes?.data?.data?.map((source) => source.name);
          break;
  
        case "HotLead":
          const hotLeadRes = await axios.get(`/leadSources/hotleads`, headers);
          filterLeadSource = hotLeadRes?.data?.data?.map((source) => source.name);
          break;
  
        default:
          break;
      }
  
      setSelectedOptions(null);
      setFilterLeadSource(filterLeadSource);
      setTemporaryLabel(null);
      setFilterLeadLabel(leadLabel);
      localStorage.setItem("leadsLabelGetOption", leadLabel);
      localStorage.setItem("leadsSourceGetValue", "");
      localStorage.setItem("leadFilterLeadSourceGetValue", "");
      localStorage.setItem(localStorageLabelKey, filterLeadSource);
    } catch (error) {
      console.error("Failed to fetch lead sources:", error);
    }
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const onSelectChange = (event, itemId,itemStatus) => {
    let newData = users;
    let index = newData.findIndex((item) => item.id === itemId);
    newData[index].check = event.currentTarget.checked;
    setusers([...newData]);

    setSelectedItems((currentSelectedItems) => {
      
      if (event.target.checked) {
        // Add itemId to selectedItems if it's checked and not already present
        return [
          ...currentSelectedItems,
          { id: itemId, status: itemStatus }
        ];
      } else {
        // Remove itemId from selectedItems if it's unchecked
        return currentSelectedItems.filter((item) => item.id !== itemId);
      }
    });
  };

  useEffect(() => {
    setLoading(false);
    // fetchUser();
    setLoading(true);
  }, [
    filterSearch,
    filterRole,
    filterStatus,
    filterLeadSource,
    isFiltering,
    dateValue,
    itemsPerPage,
    sort,
  ]);

  useEffect(() => {
    async function fetchLeadSource() {
      try {
        const res = await axios.get(`/leadSources`, headers);
        const result = res?.data?.data;
        setLeadSource(result);

        let opt = [];
        result &&
          result.map((source) =>
            opt.push({
              value: source.id,
              label: source.name,
            })
          );
        setOptLeadSource(opt);
      } catch (err) {
        //console.log( err );
      }
    }
    fetchLeadSource();
  }, []);

  useEffect(() => {
    if (!itemsPerPage) {
      return;
    }
    setLoading(false);
    //fetchUser();
    setLoading(true);
  }, [currentPage]);

  useEffect(() => {
    setDateValue({
      startDate: rangeStart?.toISOString(),
      endDate: rangeEnd?.toISOString(),
    });
  }, [rangeStart, rangeEnd]);
  const handleSearch = async (e) => {
    e.preventDefault();
    if (search !== filterSearch) {
      setusers(null);
      setCurrentPage(1);
      if (search) {
        setFilterSearch(search);
        setIsFiltering(!!search);
      } else {
        if (filterRole) {
          setIsFiltering(!!filterRole);
        } else {
          setIsFiltering(false);
        }
        setFilterSearch(null);
        setSearch(null);
      }
    }
  };

  const handleStatusOption = (status) => {
    // setusers(null);
    setCurrentPage(1);
    if (status == "Active") {
      setFilterStatus(status);
      setIsFiltering(!filterStatus);
      localStorage.setItem("leadStatusGetValue", status);
    } else {
      if (filterSearch) {
        setIsFiltering(!!filterSearch);
      } else {
        setIsFiltering(false);
      }
      setFilterStatus(status);
      localStorage.setItem("leadStatusGetValue", status);
    }
  };

  const handleNewLeadsSort = () => {
    setNewLeadsSort((prev) => !prev);
  };

  const handleLeadStatusOption = (leadStatus) => {
    setFilterLeadStatus(leadStatus);
    localStorage.setItem("leadLastStatusGetValue", leadStatus);
    setActionText("");
  };

  const validateEdit = (formData) => {
    let isError = false;

    if (formData.hasOwnProperty("first_name")) {
      if (!formData.first_name || formData.first_name.length < 2) {
        toast.error("Enter a valid First Name");
        isError = true;
      }
    }

    if (!formData.phone || !formData.phone.trim()) {
      isError = true;
      toast.error("Enter valid phone number");
      const isPossiblePhone = isPossiblePhoneNumber(formData.phone, "AE");

      if (isPossiblePhone) {
        isError = true;
        const isValidNum = isValidPhoneNumber(formData.phone, "AE");

        if (isValidNum) {
          isError = false;
        }
      } else {
        isError = true;
        toast.error("Phone number is not possible");
      }
    }

    if (!formData.role) {
      toast.error("Select the Role");
      return false;
    }
    // console.log( formData );

    if (isError) {
      toast.error("Invalid fields. Please check your inputs.");
    }

    return isError ? false : true;
  };

  const editMember = async (e) => {
    e.preventDefault();

    if (!validateEdit(formData)) {
      return;
    }

    try {
      const newFormData = {
        ...formData,
        phone: formData?.phone?.trim()?.replaceAll(" ", ""),
        active: formData.active === activeStatus[0],
      };

      const userResponse = await axios.patch(`/users/${userId}`, newFormData, {
        headers,
      });

      if (formData.role === newRoles.Agent) {
        try {
          const agentUserResponse = await axios.get(
            `/users/useragent/${userId}`,
            { headers }
          );

          const formDataNew = {
            userId: agentUserResponse.data.id,
          };

          await axios.post(`/agent`, formDataNew, { headers });
          //navigate( "/" );
        } catch (err) {
          toast.error("User not assigned Agent Role / Inactive User !");
        }
      }

      toast.success("User modified with changes!");
      //fetchUser();
      onFormCancel();
      //navigate( "/" );
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.message.includes("duplicate key")
      ) {
        toast.error("User not added. Email already exists!");
      } else {
        toast.error("User not modified with changes!");
      }
    }
  };

  const handleEditModal = (e, id) => {
    e.preventDefault();

    setuserId(id);
    fetchOneUser(id);
    toggle("edit");
  };

  // const sortFunc = ( params ) =>
  // {
  //   let defaultData = users;
  //   if ( params === "asc" )
  //   {
  //     let sortedData = defaultData.sort( ( a, b ) => a?.first_name?.localeCompare( b?.first_name ) );
  //     setusers( [ ...sortedData ] );
  //   } else if ( params === "dsc" )
  //   {
  //     let sortedData = defaultData.sort( ( a, b ) => b?.first_name?.localeCompare( a?.first_name ) );
  //     setusers( [ ...sortedData ] );
  //   }
  // };

  // const [ data, setData ] = useState( orderData );
  const [smOption, setSmOption] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    active: activeStatus[0],
    role: "",
  });

  // useEffect(() => {

  //   console.log("ID ::", "Triggerred 1")
  //   console.log("ID ::", "User ::", userId)

  //   if (userId) fetchOneUser();
  // }, [userId]);

  const [view, setView] = useState({
    add: false,
    details: false,
    edit: false,
  });

  // toggle function to view order details
  const toggle = (type) => {
    setView({
      add: type === "add" ? true : false,
      details: type === "details" ? true : false,
      edit: type === "edit" ? true : false,
    });
  };

  // selects all the order
  const selectorCheck = (e) => {
    const checked = e.currentTarget.checked;
    const newData = users.map((item) => {
      item.check = checked;
      return item;
    });
  
    // If "Select All" is checked, add all items; otherwise, clear selected items
    const selectedItems = checked
      ? newData.map((item) => ({
          id: item.id,
          status: item.lead_status
        }))
      : [];
  
    setSelectedItems(selectedItems);
    setusers([...newData]);
  };

  useEffect(() => {
    const initialStatusChecked = selectedItems.reduce((acc, item) => {
      // Uncheck if the status is in LeadStatusUncheckedReturn
      if (LeadStatusUncheckedReturn.includes(item.status)) {
        acc[item.status] = false;
      } else {
        acc[item.status] = true;
      }
      return acc;
    }, {});
    
    setStatusChecked(initialStatusChecked);
  }, [selectedItems]);
  
  
  const [statusChecked, setStatusChecked] = useState({});
  const [finalSelectedItems, setFinalSelectedItems] = useState([]);
  const statusCounts = selectedItems.reduce((acc, item) => {
    acc[item.status] = (acc[item.status] || 0) + 1;
    return acc;
  }, {});

    // useEffect(() => {
    //   if (users && users.length > 0) {
    //     // Initialize selected items and statusChecked when modal opens
    //     const initialSelectedItems = users.map((item) => ({
    //       id: item.id,
    //       status: item.lead_status,
    //     }));
    //     const initialStatusChecked = Object.keys(statusCounts).reduce((acc, status) => {
    //       acc[status] = true;
    //       return acc;
    //     }, {});
  
    //     setSelectedItems(initialSelectedItems);
    //     setStatusChecked(initialStatusChecked);
    //   }
    // }, [users]);
  const [filteredItems, setFilteredItems] = useState([]);
  const handleStatusChange = (status) => {
    setStatusChecked((prev) => {
      const isChecked = !prev[status];
      let newFinalSelectedItems;
  
      if (!isChecked) {
        // Remove leads with this status from finalSelectedItems
        newFinalSelectedItems = finalSelectedItems.filter((item) => item.status !== status);
      } else {
        // Add leads with this status back to finalSelectedItems
        const leadsToAdd = selectedItems
          .filter((item) => item.status === status)
          .map((item) => ({ id: item.id, status: item.status }));
        newFinalSelectedItems = [...finalSelectedItems, ...leadsToAdd];
      }
  
      setFinalSelectedItems(newFinalSelectedItems);
      return { ...prev, [status]: isChecked };
    });
  };
  
  

  // selects one order

  // Input Validation

  // Add User Info in DB

  const handleErrors = (name, err) => {
    setErrors((prev) => ({
      ...prev,
      [name]: err,
    }));
  };
  // function to load detail data
  const loadDetail = (id, user) => {
    setUser(user);
    // let index = data.findIndex( ( item ) => item.id === id );
    // setFormData( data[ index ] );
  };
  // function to close the form modal
  const onFormCancel = () => {
    setView({ add: false, details: false, edit: false });
  };
  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [openAgent, setOpenAgent] = useState(false);
  const toggleModalAgent = () => {
    setOpenAgent(!openAgent);
  };
  const toggleSearch = () => setonSearch(!onSearch);
  const Agents = [
    { value: "agent 1", label: "agent 1" },
    { value: "agent 2", label: "agent 2" },
    { value: "agent 3", label: "agent 3" },
  ];
  const usersAgent = [
    { value: "user 1", label: "user 1" },
    { value: "user 2", label: "user 2" },
    { value: "user 3", label: "user 3" },
  ];

  let clearBadge =
    startDateGet !== "" ||
    endDateGet !== "" ||
    leadLastStatusGet !== "" ||
    selectedOptions !== "" ||
    leadsAgentGetObj !== "" ||
    leadsLabelGetOption !== "" ||
    roleGet !== "";
    const [checkedAll, setcheckedAll] = useState(selectedItems.length===itemsPerPage)
  useEffect(()=>{
     setcheckedAll(false)
  },[currentPage])

  return (
    <React.Fragment>
      <Head title={title}></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {title}
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>
                  You have total {filCount} {name + "s"}.
                </p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
          <Row className="mt-2">
            <div className="toggle-wrap nk-block-tools-toggle d-flex flex-row-reverse">
              <a
                href="#more"
                className="btn btn-icon btn-trigger toggle-expand me-n1"
                onClick={(ev) => {
                  ev.preventDefault();
                  setSmOption(!smOption);
                }}
              >
                <Icon name="more-v"></Icon>
              </a>
            </div>
          </Row>
          <div className="d-flex justify-content-end float-end">
            <div className="d-flex justify-content-end ">
              <ul className="nk-block-tools d-flex flex-wrap gx-1 gy-2 justify-content-end w-75">
               <li style={{ listStyleType: "none" }}>
                  <Button
                    key={`button-option`}
                    className={`mx-2 border`}
                    onClick={handleNewLeadsSort}
                    color={newLeadsSort === true ? "primary" : "white"}
                  >
                    <div className="d-flex" style={{ gap: "0.5rem" }}>
                    <small>
                      <Icon
                        name={
                          newLeadsSort === true
                            ? "check-c"
                            : "square-c"
                        }
                      />
                    </small>
                      New
                    </div>
                  </Button>
                </li>
                <li style={{ listStyleType: "none" }}>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="transparent"
                      className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                    >
                      {statusGet
                        ? statusGet
                        : filterStatus
                        ? filterStatus
                        : "Active"}
                    </DropdownToggle>
                    <DropdownMenu start>
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <DropdownItem
                            onClick={() => handleStatusOption("Active")}
                          >
                            <span>Active</span>
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li style={{ listStyleType: "none" }}>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="transparent"
                      className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                    >
                      <Icon name="users" />{" "}
                      <span>
                        {roleGet !== "null" && roleGet !== "" && roleGet !== null
                          ? roleGet
                          : filterRole
                          ? filterRole
                          : "Select Role"}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu start>
                      <ul className="link-list-opt no-bdr">
                        {roleGet && (
                          <li>
                            <DropdownItem
                              onClick={(ev) => {
                                handleRoleOption("");
                              }}
                            >
                              <span>All</span>
                            </DropdownItem>
                          </li>
                        )}
                        {Object.keys(newRoles)
                          .filter((role) => allowedRoles.includes(role))
                          .map((role, index) => (
                            <li key={index + role}>
                              <DropdownItem
                                onClick={() => handleRoleOption(role)}
                              >
                                <span>{newRoles[role]}</span>
                              </DropdownItem>
                            </li>
                          ))}
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li style={{ listStyleType: "none" }}>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="transparent"
                      className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                    >
                      {leadsLabelGetOption ||
                        temporaryLabel ||
                        filterLeadLabel ||
                        "Filter by Label"}
                    </DropdownToggle>
                    <DropdownMenu start>
                      <ul className="link-list-opt no-bdr">
                        {leadsLabelGetOption && (
                          <li>
                            <DropdownItem
                              onClick={(ev) => {
                                handleLeadLabelOption("");
                                setFilterLeadSource("");
                                setSelectedOptions("");
                              }}
                            >
                              <span>All</span>
                            </DropdownItem>
                          </li>
                        )}
                        {Object.keys(LeadLabels).map((label) => (
                          <li key={label}>
                            <DropdownItem
                              onClick={() => handleLeadLabelOption(label)}
                            >
                              <span>{LeadLabels[label]}</span>
                            </DropdownItem>
                          </li>
                        ))}
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li>
                  {optLeadSource && (
                    <div className="status-div">
                      <RSelect
                        placeholder="Filter By Leadsource"
                        options={optLeadSource}
                        isMulti
                        // defaultValue={selectedOptLeadSource}
                        value={selectedOptions}
                        onChange={handleLeadSource}
                        className="status-div-rselect"
                        components={{
                          MultiValueLabel: CustomMultiValueLabel,
                        }}
                      />
                    </div>
                  )}
                </li>
                <li style={{ listStyleType: "none" }}>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="transparent"
                      className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                    >
                      {leadLastStatusGet
                        ? leadLastStatusGet
                        : filterLeadStatus
                        ? filterLeadStatus
                        : "Filter by Lead Status"}
                    </DropdownToggle>
                    <DropdownMenu start>
                      <ul className="link-list-opt no-bdr">
                        {leadLastStatusGet && (
                          <li>
                            <DropdownItem
                              onClick={(ev) => {
                                handleLeadStatusOption("");
                              }}
                            >
                              <span>All</span>
                            </DropdownItem>
                          </li>
                        )}
                        {Object.keys(LeadStatus).map((status) => (
                          <li key={status}>
                            <DropdownItem
                              onClick={() =>
                                handleLeadStatusOption(LeadStatus[status])
                              }
                            >
                              <span>{LeadStatus[status]}</span>
                            </DropdownItem>
                          </li>
                        ))}
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                {authuser?.role && authuser?.role !== newRoles?.Agent && (
                  <li className="status-div me-1">
                    <RSelect
                      options={[{ value: "all", label: "All" }, { value: "Unassigned", label: "Not Assigned" }, ...optAgents]}
                      className="status-div"
                      value={
                        leadsAgentGetObj
                          ? JSON.parse(leadsAgentGetObj)
                          : selectedAgent.value === "all"
                          ? ""
                          : selectedAgent
                      }
                      placeholder="Filter By Agent"
                      onChange={(selectedOption) => {
                        if (selectedOption.value === "all") {
                          setFilterAgent("");
                          localStorage.setItem("leadsAgentGetValue", "");
                          setSelectedAgent({
                            value: "",
                            label: "Filter By Agent",
                          });
                          localStorage.removeItem("leadsAgentGetObj");
                        } else {
                          setFilterAgent(selectedOption.value);
                          localStorage.setItem(
                            "leadsAgentGetValue",
                            selectedOption.value
                          );
                          setSelectedAgent(selectedOption);
                          const selectedOptionString =
                            JSON.stringify(selectedOption);
                          localStorage.setItem(
                            "leadsAgentGetObj",
                            selectedOptionString
                          );
                        }
                      }}
                    />
                  </li>
                )}
                <li className="">
                  <div className="form-group">
                    <div className="form-control-wrap datepicker-zindex ">
                      <div className="input-daterange date-picker-range input-group datepicker-zindex ">
                        <DatePicker
                          selected={
                            startDateGet ? new Date(startDateGet) : rangeStart
                          }
                          onChange={(date) => {
                            setRangeStart(date);
                            localStorage.setItem(
                              "leadStartDateGet",
                              date ? date?.toISOString() : ""
                            );
                            localStorage.setItem(
                              "leadEndDateGet",
                              rangeEnd ? rangeEnd.toISOString() : ""
                            );
                          }}
                          selectsStart
                          startDate={
                            startDateGet ? new Date(startDateGet) : rangeStart
                          }
                          endDate={endDateGet ? new Date(endDateGet) : rangeEnd}
                          wrapperClassName="start-m"
                          className="form-control datepicker-zindex"
                        />{" "}
                        <div className="input-group-addon">TO</div>
                        <DatePicker
                          selected={
                            endDateGet ? new Date(endDateGet) : rangeEnd
                          }
                          onChange={(date) => {
                            setRangeEnd(date);
                            localStorage.setItem(
                              "leadStartDateGet",
                              rangeStart ? rangeStart.toISOString() : ""
                            );
                            localStorage.setItem(
                              "leadEndDateGet",
                              date ? date?.toISOString() : ""
                            );
                          }}
                          startDate={
                            startDateGet ? new Date(startDateGet) : rangeStart
                          }
                          endDate={endDateGet ? new Date(endDateGet) : rangeEnd}
                          selectsEnd
                          minDate={
                            startDateGet ? new Date(startDateGet) : rangeStart
                          }
                          wrapperClassName="end-m"
                          className="form-control datepicker-zindex"
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </BlockHead>
        <Block className={''}>
        <Row className={'d-flex mx-0 py-3 w-100'}>
              <Col xs="12" className="d-flex justify-content-end">
                {leadLastStatusGet && (
                  <div className="selected-options d-flex flex-wrap">
                    <Badge
                      className="selected-option bg-primary me-1"
                      color="info"
                    >
                      {leadLastStatusGet}
                      <Icon
                        name="cross-sm"
                        className="ms-1"
                        onClick={() => handleLeadStatusOption("")}
                      />
                    </Badge>
                  </div>
                )}
                {roleGet && (
                  <div className="selected-options d-flex flex-wrap">
                    <Badge
                      className="selected-option bg-primary me-1"
                      color="primary"
                    >
                      {roleGet}
                      <Icon
                        name="cross-sm"
                        className="ms-1"
                        onClick={() => {
                          handleRoleOption("");
                        }}
                      />
                    </Badge>
                  </div>
                )}
                {selectedOptions && (
                  <div className="selected-options d-flex flex-wrap">
                    {selectedOptions.map((option, index) => (
                      <Badge
                        className="selected-option bg-primary me-1"
                        key={index}
                      >
                        {option.label}{" "}
                        <Icon
                          name="cross-sm"
                          className="ms-1"
                          onClick={() => handleBadgeRemoveAndLeadSource(option)}
                        />
                      </Badge>
                    ))}
                  </div>
                )}
                {leadsAgentGetObj && (
                  <div className="selected-options d-flex flex-wrap">
                    <Badge
                      className="selected-option bg-primary me-1"
                      color="primary"
                    >
                      {"Agent: "}
                      {JSON.parse(leadsAgentGetObj).label}
                      <Icon
                        name="cross-sm"
                        className="ms-1"
                        onClick={() => {
                          setFilterAgent("");
                          localStorage.setItem("leadsAgentGetValue", "");
                          setSelectedAgent({
                            value: "",
                            label: "Filter By Agent",
                          });
                          localStorage.removeItem("leadsAgentGetObj");
                        }}
                      />
                    </Badge>
                  </div>
                )}
                {leadsLabelGetOption && (
                  <div className="selected-options d-flex flex-wrap">
                    <Badge className="selected-option bg-primary me-1">
                      {leadsLabelGetOption}
                      <Icon
                        name="cross-sm"
                        className="ms-1"
                        onClick={() => {
                          handleLeadLabelOption("");
                          setFilterLeadSource("");
                          setSelectedOptions("");
                        }}
                      />
                    </Badge>
                  </div>
                )}
                {clearBadge && (
                  <Badge
                    className="selected-option bg-primary"
                    color="danger"
                    onClick={() => {
                      if (leadLastStatusGet) {
                      }
                      if (selectedOptions) {
                      }
                      if (leadsAgentGetObj) {
                      }
                      if (leadsLabelGetOption) {
                      }
                      if (roleGet) {
                      }
                      localStorage.setItem("leadsAgentGetValue", "");
                      localStorage.removeItem("leadsAgentGetObj");
                      localStorage.setItem("leadsSourceGetValue", "");
                      localStorage.setItem("leadFilterLeadSourceGetValue", "");
                      localStorage.setItem("leadStartDateGet", "");
                      localStorage.setItem("leadEndDateGet", "");
                      localStorage.setItem("leadRoleGetValue", "");
                      setFilterAgent("");
                      setSelectedAgent({ value: "", label: "Filter By Agent" });
                      handleLeadStatusOption("");
                      handleLeadLabelOption("");
                      setSelectedOptions("");
                      setFilterLeadSource("");
                      setRangeStart();
                      setRangeEnd();
                      handleRoleOption("");
                    }}
                  >
                    {"Clear All"}
                  </Badge>
                )}
              </Col>
            </Row>
        </Block>
        <Block>
          <div className="card-tools">
            <div className="form-inline flex-nowrap gx-3 gy-3 mt-2">
              <div className="form-wrap">
                {selectedItems.length ? (
                  <RSelect
                    options={filteredOptions}
                    className="w-200px"
                    placeholder={`Bulk Action (${selectedItems.length} selected)`}
                    onChange={(e) => onActionText(e)}
                  />
                ) : null}
              </div>

              {selectedItems?.length>0 && actionText === "assign" && (
                <>
                  <div className="form-wrap" style={{ minWidth: "200px" }}>
                    <RSelect
                      options={optAgents}
                      placeholder={
                        selectedAgent.label ? selectedAgent.label : "Select Agent"
                      }
                      onChange={(selectedOption) =>
                        setSelectedAgent(selectedOption.value)
                      }
                    />
                  </div>
                  <div  style={{ minWidth: "400px" }}>
                  <textarea
                                className="form-control single-line-textarea"
                                placeholder="Write the reason for the reassignment"
                                onChange={(e) =>
                                  setReAssignmentReason(e.target.value)
                                }
                              />
                  </div>       
                  <div className="btn-wrap mt-2">
                    <Button
                      onClick={(e) => onActionClick(e)}
                      disabled={saveLoading || !selectedItems.length}
                    >
                      {saveLoading ? "Updating..." : "Apply"}
                    </Button>
                    <span
                      className="cursor-default ms-2 text-soft fs-12px"
                      onClick={(e) => onActionClick(e)}
                      disabled={saveLoading || !selectedItems.length}
                    >
                      {`${selectedItems?.length} Selected`}
                    </span>
                  </div>
                </>
              )}

              {actionText === "return" && (
                <div className="btn-wrap mt-2">
                  <Button
                    onClick={(e) => { toggleBulkReturnConfirm(); }}
                    disabled={saveLoading || !selectedItems.length}
                  >
                    {saveLoading ? "Returning..." : "Apply"}
                  </Button>
                  <span className="cursor-default ms-2 text-soft fs-12px">
                    {`${selectedItems?.length} Selected`}
                  </span>
                </div>
              )}
            </div>
          </div>
        </Block>
        <Block>
        {/* <div className="bg-primary w-100px p-2 fw-bold text-white mb-2" style={{borderRadius:"7px",visibility:selectedItems?.length>0?"visible":"hidden",transition:"0.5s all-ease",textAlign:"center"}}>{selectedItems?.length} Selected</div> */}
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools"></div>
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleSearch();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${
                            tablesm ? "active" : ""
                          }`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div
                          className={`toggle-content ${
                            tablesm ? "content-active" : ""
                          }`}
                        >
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button
                                className="btn-icon btn-trigger toggle"
                                onClick={() => updateTableSm(false)}
                              >
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>

                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="btn btn-trigger btn-icon dropdown-toggle"
                                >
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    <li
                                      className={
                                        itemsPerPage === 10 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setCurrentPage(1);
                                          setItemsPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        itemsPerPage === 15 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setCurrentPage(1);
                                          setItemsPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        itemsPerPage === 50 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setCurrentPage(1);
                                          setItemsPerPage(50);
                                        }}
                                      >
                                        50
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        itemsPerPage === 100 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setCurrentPage(1);
                                          setItemsPerPage(100);
                                        }}
                                      >
                                        100
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Order</span>
                                    </li>
                                    <li
                                      className={
                                        sort === "DESC" ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSorBy("createdAt");
                                          setSortState("DESC");
                                          // sortFunc( "dsc" );
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={sort === "ASC" ? "active" : ""}
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSorBy("createdAt");
                                          setSortState("ASC");
                                          // sortFunc( "asc" );
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={`card-search search-wrap ${!onSearch && "active"}`}
              >
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active bg-transparent text-secondary"
                      onClick={() => {
                        setSearch("");
                        setFilterSearch("");
                        toggleSearch();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control ps-5  bg-transparent text-secondary"
                      placeholder="Search User by name or email or phone"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearch(e);
                        }
                      }}
                    />
                    <Button
                      onClick={(e) => handleSearch(e)}
                      className="search-submit btn-icon  bg-transparent text-secondary border-0"
                    >
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="nk-tb-list is-separate is-medium mb-3"> */}
            <DataTableBody compact>
              <DataTableHead className="nk-tb-item">
                <DataTableRow className="nk-tb-col-check">
                  <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="pid-all"
                      checked={checkedAll}
                      onChange={(e) => {selectorCheck(e); setcheckedAll(e.target.checked)}}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="pid-all"
                    ></label>
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortByTitle("first_name");
                      setSortByOrder(sortByOrder === "asc" ? "desc" : "asc");
                      // sortFunc( "asc" );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="sub-text">
                      Name
                      <Icon className="ms-1">
                        <span>&darr;</span>
                        <span>&uarr;</span>
                      </Icon>
                    </span>{" "}
                  </div>
                </DataTableRow>
                {authuser?.role && authuser?.role !== newRoles?.Agent && (
                  <DataTableRow size="md">
                    <span className="sub-text">Assigned Agent</span>
                  </DataTableRow>
                )}
                <DataTableRow size="md">
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortByTitle("phone");
                      setSortByOrder(sortByOrder === "asc" ? "desc" : "asc");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="sub-text">
                      Phone{" "}
                      <Icon className="ms-1">
                        <span>&darr;</span>
                        <span>&uarr;</span>
                      </Icon>
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow size="md" className="d-sm-none d-xxl-table-cell">
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortByTitle("created_at");
                      setSortByOrder(sortByOrder === "asc" ? "desc" : "asc");
                      // sortFunc( "asc" );
                    }}
                    style={{ cursor: "pointer" }}
                    className="d-sm-none d-xxl-table-cell"
                  >
                    <span className="sub-text">
                      Created
                      <Icon className="ms-1">
                        <span>&darr;</span>
                        <span>&uarr;</span>
                      </Icon>
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow size="md">
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortByTitle("lead_status");
                      setSortByOrder(sortByOrder === "asc" ? "desc" : "asc");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="sub-text">
                      Lead Activity Status
                      <Icon className="ms-1">
                        <span>&darr;</span>
                        <span>&uarr;</span>
                      </Icon>
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow className="d-sm-none d-xxl-table-cell">
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortByTitle("lead_source");
                      setSortByOrder(sortByOrder === "asc" ? "desc" : "asc");
                    }}
                    style={{ cursor: "pointer" }}
                    className="d-sm-none d-xxl-table-cell"
                  >
                    <span className="d-sm-none d-xxl-table-cell">
                      Lead Source{" "}
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Role</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-tools">
                  <ul className="nk-tb-actions gx-1 my-n1"></ul>
                </DataTableRow>
              </DataTableHead>
              {isLoading ? (
                <LoadingComponent />
              ) : (
                <React.Fragment>
                  {users && users.length > 0 && (
                    <React.Fragment>
                      {users.map((item, index) => {
                        const agent = getUserAgents[item.id]?.agent;
                        return (
                          <React.Fragment key={index + "user"}>
                            <DataTableItem>
                              <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox notext">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    defaultChecked={item.check}
                                    id={index + "oId-all"}
                                    key={Math.random()}
                                    onChange={(e) => onSelectChange(e, item.id, item.lead_status)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={index + "oId-all"}
                                  ></label>
                                </div>
                              </DataTableRow>
                              <DataTableRow>
                                <Link
                                  to={`/user-profile/${item?.id}/user-profile-setting`}
                                  className="d-flex align-items-center"
                                >                                  
                                  <span className="ml-2">
                                    {item?.first_name} {item?.last_name} 
                                  </span>
                                  <NewLeadFlagComponent 
                                    className="d-inline-flex align-items-center justify-content-center p-2" 
                                    lead_created={item?.created_at} 
                                    lead_status={item?.lead_status}
                                    lead_id={item?.id}
                                  />
                                </Link>
                              </DataTableRow>
                              {authuser?.role &&
                                authuser?.role !== newRoles?.Agent && (
                                  <DataTableRow>
                                    {item.currentAgent && Object.keys(item.currentAgent).length ? (
                                      <React.Fragment key={index}>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Link
                                            to={`/user-profile/${item?.currentAgent?.userId?.id}/user-profile`}
                                            style={{
                                              marginLeft: "8px",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div>
                                              <NewAgentAvatar agent={item?.currentAgent} />
                                            </div>
                                            <div style={{ marginLeft: "8px" }}>
                                              {item?.currentAgent?.userId?.first_name}{" "}
                                              {item?.currentAgent?.userId?.last_name}
                                            </div>
                                          </Link>
                                        </div>
                                      </React.Fragment>
                                    ) : (
                                      <Badge color="light">Not Assigned</Badge>
                                    )}
                                  </DataTableRow>
                                )}
                              <DataTableRow className={"text-center"}>
                            {item?.DndStatus?
                            <DndFlagComponent className={"d-flex flex-column align-items-center justify-content-center p-2"} lead_status={item?.lead_status} phoneNumber={item?.phone}/>
                            :
                                  <div style={{display:'flex',alignItems:'center',columnGap:'10px'}}>
                                    <span href="#id">
                                      {item?.phone}
                                    </span>
                                    <SendToPhone leadId={item?.id}>
                                      
                                      <Button outline size='xs'>Send to Phone</Button>
                                    </SendToPhone>
                                  </div>
                            }
                              
                            </DataTableRow>
                              <DataTableRow
                                size="md"
                                className="d-sm-none d-xxl-inline-block"
                              >
                                <span className="d-sm-none d-xxl-inline-block">
                                  {item?.created_at &&
                                    format(
                                      new Date(item?.created_at),
                                      "dd/MM/yyyy hh:mm a"
                                    )}
                                </span>
                              </DataTableRow>
                              <DataTableRow>
                                <span
                                  href="#id"
                                  onClick={(ev) => ev.preventDefault()}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Badge
                                    color="primary-dim"
                                    className="badge text-primary "
                                  >
                                    {item?.lead_status}
                                  </Badge>
                                </span>
                              </DataTableRow>
                              <DataTableRow className="d-sm-none d-xxl-table-cell">
                                <span
                                  href="#id"
                                  className="d-sm-none d-xxl-table-cell"
                                  onClick={(ev) => ev.preventDefault()}
                                >
                                  {item?.lead_source}
                                </span>
                              </DataTableRow>
                              <DataTableRow>
                                <Badge
                                  color={item?.active ? "success" : "danger"}
                                >
                                  {item?.active
                                    ? activeStatus[0]
                                    : activeStatus[1]}
                                </Badge>
                                {/* <span
                              className={ `dot bg-${ item.status === "Delivered" ? "success" : "warning" } d-sm-none` }
                            ></span> */}
                                {/* <Badge
                              className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
                              color={
                                item.status === "Delivered" ? "success" : "warning"
                              }
                            >
                              { item.status }
                            </Badge> */}
                              </DataTableRow>
                              <DataTableRow>
                                <span>{item?.role}</span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools">
                                <ul className="nk-tb-actions gx-1">
                                  {/* { item.status !== "Delivered" && (
                                <li className="nk-tb-action-hidden" onClick={ () => markAsDelivered( item.id ) }>
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={ "delivery" + item.id }
                                    icon="truck"
                                    direction="top"
                                    text="Mark as Delivered"
                                  />
                                </li>
                              ) } */}
                                  <li
                                    className="nk-tb-action-hidden"
                                    onClick={() => {
                                      loadDetail(item?.id, item);
                                      toggle("details");
                                    }}
                                  >
                                    <TooltipComponent
                                      tag="a"
                                      containerClassName="btn btn-trigger btn-icon"
                                      id={"view" + index}
                                      icon="eye"
                                      direction="top"
                                      text="View Details"
                                    />
                                  </li>
                                  <li key={index + item.id}>
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        tag="a"
                                        className="btn btn-icon dropdown-toggle btn-trigger"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </DropdownToggle>
                                      <DropdownMenu end>
                                        <ul className="link-list-opt no-bdr">
                                          <li key={index + Math.random()}>
                                            <a
                                              href={`/user-profile/${item?.id}/user-profile-setting`} // Replace with your desired link
                                            >
                                              <Icon name="eye"></Icon>
                                              <span>User Details</span>
                                            </a>
                                          </li>
                                          <li key={index + item?.id}>
                                            <DropdownItem
                                              tag="a"
                                              href="#dropdown"
                                              onClick={(e) => {
                                                handleEditModal(e, item.id);
                                              }}
                                            >
                                              <Icon name="edit"></Icon>
                                              <span>Edit</span>
                                            </DropdownItem>
                                          </li>
                                          <li key={index + Math.random()}>
                                            <DropdownItem
                                              tag="a"
                                              href="#dropdown"

                                            >
                                              <SendToPhone leadId={item?.id}>
                                                <Icon name="eye"></Icon>
                                                <span>Send to Phone</span>
                                              </SendToPhone>
                                            </DropdownItem>
                                          </li>
                                          {authuser?.roles && authuser?.roles?.includes(newRoles.Agent) ? <li key={`return-${item?.id}`}>
                                            <DropdownItem
                                              tag="a"
                                              href="#dropdown"
                                              onClick={(e) => {
                                                toggleReturnConfirm(item?.id ?? item?._id);
                                              }}
                                            >
                                              <Icon name="undo"></Icon>
                                              <span>Return Lead</span>
                                            </DropdownItem>
                                          </li> : null}
                                        </ul>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </li>
                                </ul>
                              </DataTableRow>
                            </DataTableItem>
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </DataTableBody>
            {/* </div> */}
            {!isLoading && (
              <PreviewAltCard>
                {users?.length > 0 ? (
                  <PaginationComponent
                    itemPerPage={itemsPerPage}
                    totalItems={filCount}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">
                      No {filterStatus} {name + "s"} Found
                    </span>
                  </div>
                )}
              </PreviewAltCard>
            )}
          </DataTable>
        </Block>

        <Modal
          isOpen={view.details}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a href="#cancel" className="close">
              {" "}
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="nk-tnx-details mt-sm-3">
              <div className="nk-modal-head mb-3">
                <h5 className="title">User Details</h5>
              </div>
              <Row className="gy-3 mb-3">
                <Col lg={6}>
                  <span className="sub-text">First Name</span>
                  <span className="caption-text">{user?.first_name}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Last Name</span>
                  <span className="caption-text">{user?.last_name}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Email</span>
                  <span className="caption-text">{user?.email}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Status</span>
                  <span className="caption-text">
                    {user?.active ? activeStatus[0] : activeStatus[1]}
                  </span>
                </Col>
              </Row>
              <div className="nk-modal-head mb-3">
                <h5 className="title">Preferences</h5>
              </div>
              <Row className="gy-3">
                <Col lg={6}>
                  <span className="sub-text">Unit Number</span>
                  <span className="caption-text">
                    {user?.preference?.plotNumber
                      ? user?.preference?.plotNumber
                      : "-"}
                  </span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Rooms</span>
                  <span className="caption-text">
                    {user?.preference?.rooms ? user?.preference?.rooms : "-"}
                  </span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Type of property</span>
                  <span className="caption-text">
                    {user?.preference?.typeOfProperty
                      ? user?.preference?.typeOfProperty
                      : "-"}
                  </span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Building/Area</span>
                  <span className="caption-text">
                    {user?.preference?.area ? user?.preference?.area : "-"}
                  </span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Location</span>
                  <span className="caption-text">
                    {user?.preference?.location
                      ? user?.preference?.location
                      : "-"}
                  </span>
                </Col>
              </Row>
              <div className="col-md-6 justify-content-start d-flex">
                <Button
                  outline
                  color="secondary"
                  className="mt-3"
                  onClick={() =>
                    (window.location.href = `/user-profile/${user?.id}/user-profile`)
                  }
                >
                  More Details
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={view.edit} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a href="#cancel" className="close">
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Edit User</h5>
              <div className="mt-4">
                {/* <form onSubmit={ handleSubmit( onFormSubmit ) }> */}
                <form onSubmit={(e) => editMember(e)}>
                  <Row className="g-3">
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          First Name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            defaultValue={formData?.firstName}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                first_name: e.target.value,
                              })
                            }
                            ref={register({
                              required: "This field is required",
                            })}
                          />
                          {errors.firstName && (
                            <span className="invalid">{errors.firstName}</span>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          Last Name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            defaultValue={formData?.lastName}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                last_name: e.target.value,
                              })
                            }
                            ref={register({
                              required: "This field is required",
                            })}
                          />
                          {errors.lastName && (
                            <span className="invalid">{errors.lastName}</span>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          Email
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            readOnly
                            value={formData?.email}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group mb-2">
                        <label className="form-label" htmlFor="first-name">
                          Phone{" "}
                        </label>
                        <PhoneInput
                          disabled={formData.DndStatus}
                          className="phoneInput"
                          defaultCountry="AE"
                          value={!formData.DndStatus&&formData?.phone}
                          name="phone"
                          placeholder={
                            formData.DndStatus
                              ? "Can't edit DND numbers"
                              : "Enter Your Phone *"
                          }
                          onChange={(value) =>
                            setFormData({ ...formData, phone: value })
                          }
                        />

                        <div className="text-black flex">
                          {" "}
                          {errorX && errorX}
                        </div>
                      </div>
                    </Col>
                    <Col size="12" className="text-end">
                      <Button color="primary" type="submit" className="mt-3">
                        <Icon className="plus"></Icon>
                        <span>Edit User</span>
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ConfirmationModal
          isOpen={returnModalState?.isModalOpen}
          toggleFn={toggleReturnConfirm}
          title={"Confirm Returning Lead"}
          loadingState={returnMutation.status === 'loading'}
          dimButtons
          actionFn={() => {
            returnMutation.mutate({ data: [returnModalState?.selectedLead] });
            toggleReturnConfirm(null);
          }}
        >
          <p style={{ fontWeight: '500', fontSize: '14px', color: 'red', marginBottom:'4px' }}>
            Your action cannot be undone once you return the leads. 
          </p>
          <span>
            Are you sure you want to return this lead?
          </span>
        </ConfirmationModal>
        <ConfirmationModal
          isOpen={isBulkReturnConfirmOpen}
          toggleFn={toggleBulkReturnConfirm}
          title={"Confirm Returning Multiple Leads"}
          loadingState={returnMutation.status === 'loading'}
          dimButtons
          disableConfirm={!selectedItems.some(item => statusChecked[item.status])} 
          actionFn={() => {
            // Filter selectedItems to include only those with a checked status
            const checkedItems = selectedItems.filter(item => statusChecked[item.status]);
            // Extract the IDs from the checked items
            const selectedIds = checkedItems.map(item => item.id);
            returnMutation.mutate({ data: selectedIds });
            toggleBulkReturnConfirm();
          }}
        >
          <p style={{ fontWeight: '500', fontSize: '14px', color: 'red', marginBottom:'4px' }}>
            Your action cannot be undone once you return the leads. 
          </p>
          <span>
            Are you sure you want to return these lead(s)?
          </span><br/>
          <span style={{fontSize: '12px',marginTop:'2px'}}>Uncheck leads if you dont want to proceed return for specific status</span>
          
          {/* Display the status counts */}
          <ul className="bg-gray-200 p-4 rounded-md mt-2">
            {Object.entries(statusCounts).map(([status, count]) => (
              <li key={status} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <input
                  type="checkbox"
                  checked={statusChecked[status] !== false} // Defaults to checked
                  onChange={() => handleStatusChange(status)}
                  style={{ width: '20px', height: '20px', marginRight: '5px', verticalAlign: 'middle' }}
                />
                <span style={{ lineHeight: '20px' }}>{`${count} lead(s) with status "${status}"`}</span>
              </li>
            ))}
          </ul>
        </ConfirmationModal>
      </Content>
    </React.Fragment>
  );
};

export default LeadsListTable;
