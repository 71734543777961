import { useCallback, useEffect, useMemo, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import dealQueries from "../../../queries/dealQueries";
import { useMutation, useQuery } from "react-query";
import DealCommissionsView from "./DealCommissionsView";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { removeNulls } from "./utils";
import { showToast } from "../../../utils/toast/toast";

const DEFAULT_PAGE_SIZE = 25;
const FIFTEEN_MINUTES = 15 * 60 * 1000;
const THIRTY_MINUTES = 30 * 60 * 1000;

const DealCommissionsContainer = () => {
  /**
   * @type {import('axios').AxiosInstance}
   */
  const axios = useAxiosPrivate();
  const location = useLocation();

  const initialQueryParams = useMemo(() => ({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    transactions: {
      paymentMethod: null,
      payeeType: null,
      fromAmount: null,
      toAmount: null,
      fromBalance: null,
      toBalance: null,
      fromDate: null,
      toDate: null,
      fromPaymentDate: null,
      toPaymentDate: null,
      payee: null,
    },
    sort: {
      name: null, // "reference_number" or "creation_date"
      order: 'DESC', // ASC or DESC
    },
    search: null,
    agent: null,
    category: null,
    propertyType: null,
    commissionStatus: [],
    withCommissionStatuses: 1,
    isMortgage: null,
  }), []);


  const decodeBase64 = useCallback((str) => {
    try {
      return JSON.parse(atob(str));
    } catch (e) {
      return initialQueryParams;
    }
  }, [initialQueryParams]);

  const encodeBase64 = (obj) => btoa(JSON.stringify(obj));
  
  /**
   * State for managing query parameters.
   * @type {[import('./types').DealTransactionQueryState, import("react").Dispatch<import("react").SetStateAction<import('./types').DealTransactionQueryState>>]}
   */
  const [queryParams, setQueryParams] = useState(initialQueryParams);


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const base64Query = searchParams.get('q');
    if (base64Query) {
      const parsedQueryParams = decodeBase64(base64Query);
      setQueryParams((prevQueryParams) => _.merge({}, prevQueryParams, parsedQueryParams));
    }
  }, [decodeBase64, location.search]);


  useEffect(() => {
    // ignore all null values from the object, including nested ones
    const newQueryParams = removeNulls(queryParams);
    const encodedQueryString = encodeBase64(newQueryParams);

    window.history.pushState(
      null,
      "",
      `${location.pathname}?q=${encodedQueryString}`
    );
  }, [queryParams, location.pathname]);


  /**
   * @type {import('react-query').QueryConfig<any, Error, any>}
   */
  const { queryKey, queryFn: fetchAllTransactions } =
    dealQueries.Transactions.list(axios, { params: removeNulls(queryParams) });


  const { mutationFn: createCsvMut } =
    dealQueries.Transactions.requestCsv(axios, { params: removeNulls(queryParams) });

  /**
   * @type {import('./types').ListTransactionsQueryResult}
   */
  const { data, isLoading, error } = useQuery(queryKey, fetchAllTransactions, {
    staleTime: FIFTEEN_MINUTES,
    refetchOnWindowFocus: false,
    refetchInterval: THIRTY_MINUTES,
  });

  const createCsvFile = useMutation(createCsvMut, {
    onError: (error) => {
      if (error?.message) {
        showToast(error?.message, 'error');
      } else {
        showToast('Request to generate CSV file failed', 'error');
      }
    },
    onSuccess: (data) => {
      showToast(data?.message, 'info', 5000); // keep the toast open for 5 seconds
    }
  });

  return (
    <DealCommissionsView
      queryData={{ data, isLoading, error }}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      requestCsvMutation={createCsvFile}
    />
  );
};

export default DealCommissionsContainer;
