import React, { useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Link, useNavigate } from "react-router-dom";
import { Step, Steps } from "react-step-builder";
import AddDealStepThree from "./components/AddDealStepThree";
import EditDealStepTwo from "./components/EditDealStepTwo";

const Header = (props) => {
  return (
    <div className="steps clearfix">
      <ul>
        {/* <li className={props.current >= 1 ? "first done" : "first"}>
                    <a
                        href="#wizard-01-h-0"
                        onClick={(ev) => ev.preventDefault()}
                    >
                        <span className="number">01</span>{" "}
                        <h5>Deal Creation</h5>
                    </a>
                </li> */}
        <li className={props.current >= 2 ? "done" : ""}>
          <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
            <span className="number">02</span> <h5>Collect Documents</h5>
          </a>
        </li>
        <li className={props.current === 3 ? "last done" : "last"}>
          <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
            <span className="current-info audible">current step: </span>
            <span className="number">03</span> <h5>Approval</h5>
          </a>
        </li>
      </ul>
    </div>
  );
};
const config = {
  before: Header,
};

const EditDeal = () => {
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      localStorage.removeItem("new_deal"); // on page unmount, we want to remove the deal created so that we do not populate the page with this deal when we want to add a new deal
    };
  });

  return (
    <React.Fragment>
      <Head title="Edit Deal"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="g-3">
            <BlockHeadContent>
              <Link>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                  onClick={(e) => navigate(-1)}
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <Button
                  color="light"
                  outline
                  className="btn-icon bg-white d-inline-flex d-sm-none "
                >
                  <Icon name="arrow-left"></Icon>
                </Button>
              </Link>
              <BlockTitle page className="mt-4">
                Edit Deal
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
              <Steps config={config}>
                {/* <Step component={AddDealStepOne} /> */}
                <Step component={EditDealStepTwo} />
                <Step component={AddDealStepThree} />
              </Steps>
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default EditDeal;
