import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import
{
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    DropdownItem,
    Form,
    Badge,
    Label,
    Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Axios from "axios";
import DatePicker from "react-datepicker";

import { TagsInput } from "react-tag-input-component";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Editor } from '@tinymce/tinymce-react';

import
{
    actionS3Objects,
    BlogStatus,
    tinyKey,
    toIsoString,
    webURL,
} from "../../utils/envConfig";

import
{
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Col,
    PaginationComponent,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    Button,
    RSelect,
    TooltipComponent,
} from "../../components/Component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";


import { QuillComponentNew } from "../../components/partials/rich-editor/QuillComponent";
import { createSlug } from "../../utils/Utils";
import _ from "lodash";


const Blogs = () =>
{
    let navigate = useNavigate();
    const axios = useAxiosPrivate();

    const editorRef = useRef( null );
    const blogStatusOptions = [
        { value: true, label: BlogStatus.Published },
        { value: false, label: BlogStatus.Draft },
    ];
    const [ filterStatus, setFilterStatus ] = useState( null );
    const [ rangeStart, setRangeStart ] = useState( new Date() );
    const [ rangeEnd, setRangeEnd ] = useState();
    const [ rangeDate, setRangeDate ] = useState( {
        start: new Date(),
        end: null,
    } );
    const handleChange = ( content ) =>
    {
        setFormData( ( prevState ) => ( {
            ...prevState,
            propertyDesc: content,
        } ) );
    };
    const onRangeChange = ( dates ) =>
    {
        const [ start, end ] = dates;
        setRangeDate( { start: start, end: end } );
    };
    // const { contextData } = useContext( UserContext );
    const [ edit, setEdit ] = useState( false );
    const [ data, setData ] = useState( [] );
    const [ blogs, setBlogs ] = useState( [] );
    const [loading, setLoading] = useState(false)
    const [ sm, updateSm ] = useState( false );
    const [ tablesm, updateTableSm ] = useState( false );
    const [ onSearch, setonSearch ] = useState( true );
    const [ onSearchText, setSearchText ] = useState( "" );
    const [ imageLoading, setImageLoading ] = useState( true );
    const [ imageChanged, setImageChanged ] = useState( false );
    const [ modal, setModal ] = useState( {
        edit: false,
        add: false,
    } );
    const [ editId, setEditedId ] = useState();

    const [ actionText, setActionText ] = useState( "" );
    const [ currentPage, setCurrentPage ] = useState( 1 );
    const [ itemPerPage, setItemPerPage ] = useState( 10 );
    const [ sort, setSortState ] = useState( "" );

    const folderName = "Blog";

    // State Variables
    const [ title, setTitle ] = useState( "" );
    const [ slug, setSlug ] = useState( "" );
    const [ date, setDate ] = useState( "" );
    const [ imageAlt, setImageAlt ] = useState( "" );
    const [ metaDescription, setMetaDescription ] = useState( "" );
    const [ metaKeywords, setMetaKeywords ] = useState( [] );
    const [ description, setDescription ] = useState( "" );
    const [ status, setStatus ] = useState( null );
    const [ image, setImage ] = useState( "" );
    const [ displayImage, setDisplayImage ] = useState( null );
    const [ isLoading, setIsLoading ] = useState( false );
    const [ blogId, setBlogId ] = useState( null );
    const [ formData, setFormData ] = useState( {
        title: "",
        slug: "",
        propertyDesc: "",
        date: null,
        image_link: null,
        image_alt: null,
        meta_description: null,
        meta_keywords: null,
        status: null,
    } );
    const getUrl = async ( file ) =>
    {
        try {
            const uploadData = JSON.stringify( {
                action: `${ actionS3Objects[ 0 ] }`,
                fileName: file,
            } );
            const resp = await axios.post( `/upload`, uploadData );
            const s3url = resp.data.signedRequest;
            if ( s3url )
            {
                return s3url;
            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect( () =>
    {
        async function fetchBlog ()
        {
            try
            {
                const res = await axios
                    .get( `/blogs/${ blogId }` )
                    .then( ( res ) =>
                    {
                        const blog = res.data;
                        setFormData( ( prevState ) => ( {
                            ...prevState,
                            propertyDesc: blog.description,
                        } ) );
                        const localMetaKeywords =
                            blog.meta_keywords.split( ", " );

                        const localDate = toIsoString(
                            new Date( blog.date ),
                            false
                        );
                        setTitle( blog.title );
                        setSlug( blog.slug );
                        // setDescription(blog.description);
                        setDate( localDate );
                        setImageAlt( blog.image_alt );
                        setMetaDescription( blog.meta_description );
                        setMetaKeywords( localMetaKeywords );
                        setStatus( blog.status === BlogStatus.Published );
                        setImage( blog.image_link );

                        setImageLoading( true );
                        let promise = getUrl( blog.image_link );
                        promise
                            .then( ( url ) => setDisplayImage( url ) )
                            .finally( () => setImageLoading( false ) );
                    } )
                    .catch( ( err ) =>
                    {
                        toast.error( "Blog not available !" );
                    } );
            } catch ( err )
            {
                toast.error( "Server error. Try Again !" );
            }
        }
        blogId && fetchBlog();
    }, [ blogId ] );

    // Sorting data
    const sortFunc = ( params ) =>
    {
        let defaultData = data;
        if ( params === "asc" )
        {
            let sortedData = defaultData.sort( ( a, b ) =>
                a?.title.localeCompare( b?.title )
            );
            setData( [ ...sortedData ] );
        } else if ( params === "dsc" )
        {
            let sortedData = defaultData.sort( ( a, b ) =>
                b?.title.localeCompare( a?.title )
            );
            setData( [ ...sortedData ] );
        }
    };

    useEffect( () =>
    {
        async function fetchBlogs ()
        {
            try
            {
                setLoading(true)
                const res = await axios.get( `/blogs` );
                let newData = res.data?.map( ( item ) =>
                {
                    item.checked = false;
                    return item;
                } );
                setData( [ ...newData ] );
                setBlogs( [ ...newData ] );
            
            } catch ( err )
            {
                // console.log(err)
            }finally{
                setLoading(false)
            }
        }
        fetchBlogs();
    }, [] );

    useEffect( () =>
    {
        if ( onSearchText !== "" )
        {
            const filteredObject = data.filter( ( item ) =>
            {
                return (
                    item?.title
                        ?.toLowerCase()
                        .includes( onSearchText.toLowerCase() ) ||
                    item?.title
                        ?.toLowerCase()
                        .includes( onSearchText.toLowerCase() )
                );
            } );
            setData( [ ...filteredObject ] );
        } else
        {
            setData( [ ...blogs ] );
        }
    }, [ onSearchText ] );

    useEffect( () =>
    {
        if ( filterStatus !== "" )
        {
            const filteredObject = blogs?.filter( ( item ) =>
            {
                return item?.status.includes( filterStatus );
            } );
            setData( [ ...filteredObject ] );
        } else
        {
            setData( [ ...blogs?.map( ( a ) => ( { ...a, checked: false } ) ) ] );
        }
    }, [ filterStatus ] );

    // onChange function for searching name
    const onFilterChange = ( e ) =>
    {
        setSearchText( e.target.value );
    };



    // Input Validation
    const validate = (
        title,
        description,
        displayImage,
        imageAlt,
        metaDescription,
        metaKeywords
    ) =>
    {
        if ( !title || title?.length < 2 )
        {
            toast.error( "Enter valid Title (at least 2 characters)" );
            return false;
        }

        if ( !description || description?.length < 2 )
        {
            toast.error( "Enter valid Description (at least 2 characters)" );
            return false;
        }

        if ( !displayImage || displayImage?.length < 1 )
        {
            toast.error( "Add valid Image" );
            return false;
        }

        if ( !imageAlt || imageAlt?.length < 1 )
        {
            toast.error( "Enter valid Image Alt" );
            return false;
        }

        if ( !metaDescription || metaDescription?.length < 2 )
        {
            toast.error( "Enter valid Meta Description (at least 2 characters)" );
            return false;
        }

        if ( !metaKeywords || metaKeywords?.length < 1 )
        {
            toast.error( "Enter valid Meta Keywords" );
            return false;
        }


        return true;
    };
    const addBlog = async ( e ) =>
    {
        e.preventDefault();
        const localStatus = status ? BlogStatus.Published : BlogStatus.Draft;
        const localDate = new Date( rangeStart ).toISOString();
        const localMetaKeywords = metaKeywords.join( ", " );
        let desc = formData.propertyDesc;
        if (
            validate(
                title,
                desc,
                displayImage,
                imageAlt,
                metaDescription,
                metaKeywords
            )
        )
        {
            setIsLoading( true );
            let fileName = "";
            if ( image.name && modal.add )
            {
                fileName = `${ folderName }/${ uuidv4() }.${ image.name
                    .split( "." )
                    .pop() }`;
                await handleUpload( image, fileName );
            }
            if ( modal.edit )
            {
                fileName = image;
                if ( imageChanged && image.name )
                {
                    fileName = `${ folderName }/${ uuidv4() }.${ image.name
                        .split( "." )
                        .pop() }`;
                    await handleUpload( image, fileName );
                }
            }

            const submitData = {
                title: title,
                slug: slug !== "" ? slug : undefined,
                description: formData.propertyDesc,
                date: localDate,
                image_link: fileName,
                image_alt: imageAlt,
                meta_description: metaDescription,
                meta_keywords: localMetaKeywords,
                status: localStatus,
            };

            try
            {
                if ( modal.add ) await axios.post( `/blogs`, submitData );
                else await axios.patch( `/blogs/${ blogId }`, submitData );
                setIsLoading( false );
                navigate( 0 );
            } catch ( err )
            {
                if ( err.response ) toast.error( err.response.data.message );
                else toast.error( "Server error. Try Again !" );
            } finally
            {
                setIsLoading( false );
            }
        }
    };

    const handleImage = async ( e ) =>
    {
        if ( e.target.files )
        {
            let result = e.target.files[ 0 ];
            let url = URL.createObjectURL( e.target.files[ 0 ] );
            setDisplayImage( url );
            setImage( result );
            setImageChanged( true );
        }
    };

    const handleRemove = ( e ) =>
    {
        e.preventDefault();
        setDisplayImage( null );
        setImage( "" );
    };

    const handleUpload = async ( file, fileName ) =>
    {
        const uploadData = JSON.stringify( {
            action: `${ actionS3Objects[ 1 ] }`,
            fileName: fileName,
        } );
        // console.log(uploadData)
        const resp = await axios.post( `/upload`, uploadData );
        const s3url = resp.data.signedRequest;
        if ( s3url )
        {
            try
            {
                const myHeaders = new Headers( { "Content-Type": "image/*" } );
                await Axios.put( s3url, file, { myHeaders } );
            } catch ( err )
            {
                // Handle Error Here
                toast.error( "Server error. Try Again!" );
            }
        }
    };

    // function to reset the form
    const resetForm = () =>
    {
        setFormData( {
            title: "",
            slug: "",
            propertyDesc: "",
            date: null,
            image_link: null,
            image_alt: null,
            meta_description: null,
            meta_keywords: "",
            status: null,
        } );
        setTitle( "" );
        setSlug( "" );
        setDescription( "" );
        setDate( null );
        setImageAlt( null );
        setMetaDescription( null );
        setMetaKeywords( [] );
        setStatus( null );
        setImage( null );
    };


    // function to close the form modal
    const onFormCancel = () =>
    {
        setModal( { edit: false, add: false } );
        resetForm();
    };

 

    // function that loads the want to editted data
    const onEditClick = ( id ) =>
    {
        setBlogId( id );
        resetForm();
        setEdit( true );
        setModal( { edit: true } );
    };

  

    // function to toggle the search option
    const toggle = () => setonSearch( !onSearch );



    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice( indexOfFirstItem, indexOfLastItem );

    // Change Page
    const paginate = ( pageNumber ) => setCurrentPage( pageNumber );
    const [ selectedFile, setSelectedFile ] = useState( null );
    const [ errorMessage, setErrorMessage ] = useState( "" );

   
  
    return (
        <React.Fragment>
            <Head title="Blog List"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Blogs Lists
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total { data.length } blogs</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={ `btn-icon btn-trigger toggle-expand me-n1 ${ sm ? "active" : ""
                                        }` }
                                    onClick={ () => updateSm( !sm ) }
                                >
                                    <Icon name="menu-alt-r"></Icon>
                                </Button>
                                <div
                                    className="toggle-expand-content"
                                    style={ { display: sm ? "block" : "none" } }
                                >

                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    color="transparent"
                                                    className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                                                >
                                                    { filterStatus
                                                        ? filterStatus
                                                        : "Status" }
                                                </DropdownToggle>
                                                <DropdownMenu start>
                                                    <ul className="link-list-opt no-bdr">
                                                        <li>
                                                            <DropdownItem
                                                                onClick={ () =>
                                                                    setFilterStatus(
                                                                        BlogStatus.Draft
                                                                    )
                                                                }
                                                            >
                                                                <span>
                                                                    { " " }
                                                                    {
                                                                        BlogStatus.Draft
                                                                    }
                                                                </span>

                                                            </DropdownItem>
                                                        </li>
                                                        <li>
                                                            <DropdownItem
                                                                onClick={ () =>
                                                                    setFilterStatus(
                                                                        BlogStatus.Published
                                                                    )
                                                                }
                                                            >
                                                                <span>
                                                                    { " " }
                                                                    {
                                                                        BlogStatus.Published
                                                                    }
                                                                </span>
                                                            </DropdownItem>
                                                        </li>
                                                        <li>
                                                            <DropdownItem
                                                                onClick={ () =>
                                                                    setFilterStatus(
                                                                        ""
                                                                    )
                                                                }
                                                            >
                                                                <span>
                                                                    { " " }
                                                                    {
                                                                        "All"
                                                                    }
                                                                </span>
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </li>
                                        <li className="nk-block-tools-opt">
                                            <Button
                                                color="primary"
                                                className="btn-icon pe-2"
                                                onClick={ () =>
                                                    setModal( {
                                                        add: true,
                                                        edit: false,
                                                    } )
                                                }
                                            >
                                                <Icon name="plus"></Icon> Add
                                                New Blog
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <DataTable className="card-stretch">
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                        
                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={ ( ev ) =>
                                                {
                                                    ev.preventDefault();
                                                    toggle();
                                                } }
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={ `btn-icon btn-trigger toggle ${ tablesm ? "active" : ""
                                                        }` }
                                                    onClick={ () =>
                                                        updateTableSm( true )
                                                    }
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div
                                                    className={ `toggle-content ${ tablesm
                                                        ? "content-active"
                                                        : ""
                                                        }` }
                                                >
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={ () =>
                                                                    updateTableSm(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>

                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    tag="a"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                    end
                                                                    className="dropdown-menu-xs"
                                                                >
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>
                                                                                Show
                                                                            </span>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage ===
                                                                                    10
                                                                                    ? "active"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={ (
                                                                                    ev
                                                                                ) =>
                                                                                {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(
                                                                                        10
                                                                                    );
                                                                                } }
                                                                            >
                                                                                10
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage ===
                                                                                    15
                                                                                    ? "active"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={ (
                                                                                    ev
                                                                                ) =>
                                                                                {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(
                                                                                        15
                                                                                    );
                                                                                } }
                                                                            >
                                                                                15
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>
                                                                                Order
                                                                            </span>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                sort ===
                                                                                    "dsc"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={ (
                                                                                    ev
                                                                                ) =>
                                                                                {
                                                                                    ev.preventDefault();
                                                                                    setSortState(
                                                                                        "dsc"
                                                                                    );
                                                                                    sortFunc(
                                                                                        "dsc"
                                                                                    );
                                                                                } }
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                sort ===
                                                                                    "asc"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={ (
                                                                                    ev
                                                                                ) =>
                                                                                {
                                                                                    ev.preventDefault();
                                                                                    setSortState(
                                                                                        "asc"
                                                                                    );
                                                                                    sortFunc(
                                                                                        "asc"
                                                                                    );
                                                                                } }
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className={ `card-search search-wrap ${ !onSearch && "active"
                                    }` }
                            >
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={ () =>
                                            {
                                                setSearchText( "" );
                                                toggle();
                                            } }
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Search by title"
                                            value={ onSearchText }
                                            onChange={ ( e ) => onFilterChange( e ) }
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTableBody compact>
                            <DataTableHead>
                             
                                <DataTableRow>
                                    <span className="sub-text">Tiltle</span>
                                </DataTableRow>

                                <DataTableRow size="lg">
                                    <span className="sub-text">Status</span>
                                </DataTableRow>

                                <DataTableRow size="lg">
                                    <span className="sub-text">Date </span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text">Created By</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text">
                                        Modified At
                                    </span>
                                </DataTableRow>
                               
                                <DataTableRow className="nk-tb-col-tools text-end"></DataTableRow>
                            </DataTableHead>
                         
                            { currentItems?.length > 0
                                ? currentItems?.map( ( item ) =>
                                {
                                    return (
                                        <DataTableItem key={ item.id }>
                                           
                                            <DataTableRow>
                                                <Link
                                                    onClick={ () =>
                                                        onEditClick(
                                                            item.id
                                                        )
                                                    }
                                                >
                                                    <div className="user-card" >
                                                        { item?.title }
                                                    </div>
                                                </Link>
                                            </DataTableRow>
                                            <DataTableRow size="md">
                                                <span>
                                                    <Badge
                                                        color={
                                                            item?.status ===
                                                                BlogStatus.Published
                                                                ? "success"
                                                                : "danger"
                                                        }
                                                    >
                                                        { item?.status }
                                                    </Badge>
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow size="sm">
                                                <span>
                                                    { new Date( item?.date ).toLocaleString( undefined, {
                                                        day: 'numeric',
                                                        month: 'numeric',
                                                        year: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        hour12: true
                                                    } ) }
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow size="md">
                                                <span>Asad Ali</span>
                                            </DataTableRow>
                                            <DataTableRow size="lg">
                                                <span>
                                                    { new Date( item?.date ).toLocaleString( undefined, {
                                                        day: 'numeric',
                                                        month: 'numeric',
                                                        year: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        hour12: true
                                                    } ) }
                                                </span>
                                            </DataTableRow>
                                        

                                            <DataTableRow className="nk-tb-col-tools">
                                                <ul className="nk-tb-actions gx-1">
                                                    <li
                                                        className="nk-tb-action-hidden"
                                                        onClick={ () =>
                                                            onEditClick(
                                                                item.id
                                                            )
                                                        }
                                                    >
                                                        <TooltipComponent
                                                            tag="a"
                                                            containerClassName="btn btn-trigger btn-icon"
                                                            id={
                                                                "edit" +
                                                                item.id
                                                            }
                                                            icon="edit-alt-fill"
                                                            direction="top"
                                                            text="Edit"
                                                        />
                                                    </li>

                                                    <li>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                tag="a"
                                                                className="dropdown-toggle btn btn-icon btn-trigger"
                                                            >
                                                                <Icon name="more-h"></Icon>
                                                            </DropdownToggle>
                                                            <DropdownMenu end>
                                                                <ul className="link-list-opt no-bdr">
                                                                    <li
                                                                        onClick={ () =>
                                                                            onEditClick(
                                                                                item.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#edit"
                                                                            onClick={ (
                                                                                ev
                                                                            ) =>
                                                                            {
                                                                                ev.preventDefault();
                                                                            } }
                                                                        >
                                                                            <Icon name="edit"></Icon>
                                                                            <span>
                                                                                Edit
                                                                            </span>
                                                                        </DropdownItem>
                                                                    </li>
                                                                </ul>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </li>
                                                </ul>
                                            </DataTableRow>
                                        </DataTableItem>
                                    );
                                } )
                                : null }
                             
                        </DataTableBody>
                        <div className="card-inner">
                            { currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={ itemPerPage }
                                    totalItems={ data.length }
                                    paginate={ paginate }
                                    currentPage={ currentPage }
                                />
                            ) : (
                                <div className="text-center">
                                    <span className="text-silent">
                                        No data found
                                    </span>
                                </div>
                            ) }
                        </div>
                    </DataTable>
                </Block>
                <Modal
                    backdrop="static"
                    isOpen={ modal.add || modal.edit }
                    toggle={ () => setModal( { add: false, edit: false } ) }
                    className="modal-dialog-centered"
                    size="xl"
                >
                    <ModalBody>
                        <a
                            href="#cancel"
                            onClick={ ( ev ) =>
                            {
                                ev.preventDefault();
                                onFormCancel();
                            } }
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">
                                { edit ? "Edit" : "Add New Blog" }
                            </h5>
                            <div className="mt-4">
                                <Form className="row gy-4" onSubmit={ addBlog }>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Title
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="name"
                                                required
                                                value={ title }
                                                onChange={ ( e ) =>
                                                    setTitle( e.target.value )
                                                }
                                                onBlur={(e) => setSlug(_.kebabCase(e.target.value)) }
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label className="form-label">
                                                URL{ " " }
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="email"
                                                value={ slug }
                                                onChange={ ( e ) =>
                                                    setSlug( e.target.value )
                                                }
                                                placeholder="Enter url"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Description
                                            </label>
                                            <Editor
                                                    apiKey={ tinyKey }
                                                    onInit={ ( evt, editor ) => ( editorRef.current = editor ) }
                                                    value={ formData.propertyDesc }
                                                    init={ {
                                                        height: 500,
                                                        menubar: false,
                                                        formats: {
                                                            bold: {
                                                                inline: "span",
                                                                styles: { fontWeight: "bold" },
                                                            },
                                                        },
                                                        link_rel_list: [
                                                            { title: '', value: '' },
                                                            { title: 'No Referrer', value: 'noreferrer' },
                                                            { title: 'External Link', value: 'external' },
                                                            { title: 'No follow', value: 'nofollow' }
                                                        ],
                                                        plugins:
                                                            "anchor autolink charmap codesample emoticon image link lists media searchreplace visualblocks wordcount code preview",
                                                        toolbar:
                                                            "undo redo | blocks fontsize | bold italic underline strikethrough | link image media  | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat code preview",
                                                        tinycomments_mode: "embedded",
                                                        tinycomments_author: "Author name",
                                                    } }
                                                    onEditorChange={ handleChange }
                                                />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Date
                                            </label>
                                            <div className="form-control-wrap">
                                                <div className="input-daterange date-picker-range input-group d-cus-datepicker">
                                                    <DatePicker
                                                        selected={ rangeStart }
                                                        onChange={ setRangeStart }
                                                        selectsStart
                                                        startDate={ rangeStart }
                                                        endDate={ rangeEnd }
                                                        wrapperClassName="start-m"
                                                        className="form-control "
                                                    />{ " " }
                                                </div>
                                            </div>
                                            <div className="form-note">
                                                Date Format{ " " }
                                                <code>mm/dd/yyyy</code>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Status
                                            </label>
                                            <div className="form-control-wrap">
                                                <RSelect
                                                    defaultValue={ status }
                                                    options={ blogStatusOptions }
                                                    onChange={ ( e ) =>
                                                        setStatus( e.value )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Meta Keyword{ " " }
                                            </label>
                                            <TagsInput
                                                value={ metaKeywords }
                                                onChange={ ( metaKeywords ) =>
                                                    setMetaKeywords(
                                                        metaKeywords
                                                    )
                                                }
                                                name="Tags"
                                                placeHolder="Enter Meta Keywords"
                                            />
                                        </div>
                                    </Col>

                                    <Col md="6">
                                        <div className="form-group">
                                            <Label
                                                className="form-label"
                                                htmlFor="fv-message"
                                            >
                                                Meta Description
                                            </Label>
                                            <div className="form-control-wrap">
                                                <textarea
                                                    type="textarea"
                                                    className="form-control form-control-sm"
                                                    rows="5"
                                                    placeholder="Write your message"
                                                    name="metaDesc"
                                                    id="metaDesc"
                                                    value={ metaDescription }
                                                    required
                                                    onChange={ ( e ) =>
                                                        setMetaDescription(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Image Alt{ " " }
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="imageAlt"
                                                value={ imageAlt }
                                                onChange={ ( e ) =>
                                                    setImageAlt( e.target.value )
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Image
                                            </label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="image"
                                                accept="image/jpeg, image/png, image/gif, image/jpg, image/svg, image/webp"
                                                onChange={ handleImage }
                                            />{ " " }
                                        </div>
                                        { errorMessage && (
                                            <div className="error-message">
                                                { errorMessage }
                                            </div>
                                        ) }
                                        { selectedFile && (
                                            <div>
                                                <p>Selected Image:</p>
                                                <div className="position-relative">
                                                    <img
                                                        src={ URL.createObjectURL(
                                                            selectedFile
                                                        ) }
                                                        alt="Selected"
                                                    />
                                                    <button
                                                        className="position-absolute top-0 start-0 bg-danger text-white border-0 p-2"
                                                        onClick={
                                                            // handleImageDelete
                                                            ( e ) => handleRemove( e )
                                                        }
                                                    >
                                                        <Icon
                                                            name="trash"
                                                            style={ {
                                                                fontSize:
                                                                    "1.5rem",
                                                            } }
                                                        ></Icon>
                                                    </button>{ " " }
                                                </div>
                                            </div>
                                        ) }
                                        { displayImage && (
                                            <div>
                                                <div className="position-relative">
                                                    <img
                                                        src={ displayImage }
                                                        alt="Selected"
                                                    />
                                                    <button
                                                        className="position-absolute top-0 start-0 bg-danger text-white border-0 p-2"
                                                        onClick={ ( e ) =>
                                                            // e.preventDefault()
                                                            handleRemove( e )
                                                        }
                                                    >
                                                        <Icon
                                                            name="trash"
                                                            style={ {
                                                                fontSize:
                                                                    "1.5rem",
                                                            } }
                                                        ></Icon>
                                                    </button>{ " " }
                                                </div>
                                            </div>
                                        ) }
                                    </Col>

                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
                                            <li>
                                                <Button
                                                    color="primary"
                                                    size="md"
                                                    type="submit"
                                                >

                                                    { isLoading ? (
                                                        <>
                                                            <Spinner size="sm" color="light" />
                                                        </>
                                                    ) : (
                                                        modal.add
                                                            ? "Add Blog"
                                                            : "Save"
                                                    ) }

                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={ ( ev ) =>
                                                    {
                                                        ev.preventDefault();
                                                        onFormCancel();
                                                    } }
                                                    className="link link-light"
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
        </React.Fragment>
    );
};
export default Blogs;
