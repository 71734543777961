import { Badge } from "reactstrap";

/**
 * @typedef {Object} StatusPillProps
 * @property {string} status - The text to display in the badge
 * @property {React.CSSProperties | undefined} style - CSS Styles to apply to the badge
 * @property {'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'} color - Indicates the color of the badge.
 */

/**
 * StatusPill Component
 * @param {StatusPillProps} props - The props for the StatusPillProps component.
 * @returns {JSX.Element} The rendered StatusPillProps component.
 */
const StatusBadge = ({ status, color, style, ...props }) => {
  const config = {
    bg: `bg-${color}-dim`,
    text: `text-${color}`,
    border: `border border-${color}`
  };

  return (
    <Badge className={`${config.bg} ${config.text} ${config.border} py-1 px-2 d-flex align-items-center justify-content-center`} style={style ?? {}} {...props}>
      <span> {status} </span>
    </Badge>
  );
};

export default StatusBadge;