import React from 'react';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import {
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    PreviewCard
} from '../../components/Component';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import AddNewAgency from '../components/forms/AddAgency';
import { toast } from 'react-toastify';

export default function AddAgency() {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Head title="Add Agency"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween className="g-3">
                        <BlockHeadContent>
                            <Link>
                                <Button
                                    color="light"
                                    outline
                                    className="bg-white d-none d-sm-inline-flex"
                                    onClick={(e) => navigate(-1)}
                                >
                                    <Icon name="arrow-left"></Icon>
                                    <span>Back</span>
                                </Button>
                                <Button
                                    color="light"
                                    outline
                                    className="btn-icon bg-white d-inline-flex d-sm-none "
                                >
                                    <Icon name="arrow-left"></Icon>
                                </Button>
                            </Link>
                            <BlockTitle page className="mt-4">
                                Add New Agency
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <PreviewCard>
                        <AddNewAgency
                            callback={(agency) => {
                                toast.success('Agency Added successfully');
                            }}
                        />
                    </PreviewCard>
                </Block>
            </Content>
        </React.Fragment>
    );
}
