const FluidBlockContainer = ({ children }) => {
  return (
    <div className="container-fluid">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <div className="nk-block">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default FluidBlockContainer;
