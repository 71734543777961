import { Badge } from "reactstrap";
import { CommissionStatus } from "../../../utils/envConfig";
import { currencyFormatter } from "../../explorer/utils";

/**
 * Renders a badge indicating the transaction count based on the commission status.
 *
 * @param {Object} params - The parameters for the function.
 * @param {'Fully Paid'|'Partially Paid'|'Pending'} params.status - The status of the commission.
 * @param {number} params.count - The count of transactions.
 * @param {number} params.total - The total commission for this these transactions.
 * @param {number} params.idx - The index used for the badge key.
 * @returns {JSX.Element} A badge element displaying the status and count.
 */
export const renderTransactionCountBadge = ({ status, count, total, idx }) => {
  const CUSTOM_GREEN = "rgba(34, 169, 133, 1)";
  switch (status) {
    case CommissionStatus.FullyPaid:
      return (
        <span
          key={`commission-pill-${idx}`}
          className="badge badge-dot has-bg bg-success border border-success rounded"
          style={{ color: CUSTOM_GREEN }}
        >
          {status} - {count} (Total: {currencyFormatter({ isDecimal: true, decimalPlaces: 2 }).format(total)})
        </span>
      );
    case CommissionStatus.PartiallyPaid:
      return (
        <span
          key={`commission-pill-${idx}`}
          className="badge badge-dot has-bg bg-warning border border-warning text-warning rounded"
        >
          {status} - {count} (Total: {currencyFormatter({ isDecimal: true, decimalPlaces: 2 }).format(total)})
        </span>
      );
    case CommissionStatus.Pending:
      return (
        <span
          key={`commission-pill-${idx}`}
          className="badge badge-dot has-bg bg-danger border border-danger text-danger rounded"
        >
          {status} Payment - {count} (Total: {currencyFormatter({ isDecimal: true, decimalPlaces: 2 }).format(total)})
        </span>
      );
    default:
      return (
        <Badge key={`commission-pill-${idx}`} color="primary">
          {count} (Total: {currencyFormatter({ isDecimal: true, decimalPlaces: 2 }).format(total)})
        </Badge>
      );
  }
};

export const removeNulls = (obj) => {
  const newObj = {};

  for (const key in obj) {
    if (obj[key] !== null && typeof obj[key] === 'object') {
      newObj[key] = removeNulls(obj[key]);
    } else if (obj[key] !== null) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
};
