import Axios from 'axios';
import { toast } from 'react-toastify';
import phoneLib from 'google-libphonenumber';
import { isValid } from 'date-fns';

// Set Environment
const environment = process.env.REACT_APP_ENV;

// Server URL depending on environment
function getMainSiteURL(environment) {
    switch (environment) {
        case 'local':
            return process.env.REACT_APP_MAIN_SITE_LOCAL_URL;
        case 'dev':
            return process.env.REACT_APP_MAIN_SITE_DEV_URL;
        case 'prod':
            return process.env.REACT_APP_MAIN_SITE_PROD_URL;
        default:
            return process.env.REACT_APP_MAIN_SITE_LOCAL_URL;
    }
}

export const webDomain = getMainSiteURL(environment)?.split('://')[1];

// Server URL depending on environment
function getURL(environment) {
    switch (environment) {
        case 'local':
            return process.env.REACT_APP_LOCAL_URL;
        case 'dev':
            return process.env.REACT_APP_DEV_URL;
        case 'prod':
            return process.env.REACT_APP_PROD_URL;
        default:
            return process.env.REACT_APP_LOCAL_URL;
    }
}
export const server = getURL(environment);

function getS3URL(environment) {
    switch (environment) {
        case "prod":
            return 'https://' + process.env.REACT_APP_PUBLIC_S3_URL_PROD + '/';
        default:
            return 'https://' + process.env.REACT_APP_PUBLIC_S3_URL_DEV + '/';
    }
}
export const s3URL = getS3URL(environment);
export const getPublicDocumentUrl = (fileKey = "") => `${s3URL}${fileKey.startsWith('/') ? fileKey.replace('/', '') : fileKey}`

// HERE API
export const hereApiKey = process.env?.REACT_APP_HERE_MAP_API_KEY;

// Server URL depending on environment
function getWebURL(environment) {
    switch (environment) {
        case 'local':
            return process.env.REACT_APP_LOCAL_WEB_URL;
        case 'dev':
            return process.env.REACT_APP_DEV_WEB_URL;
        case 'prod':
            return process.env.REACT_APP_PROD_WEB_URL;
        default:
            return process.env.REACT_APP_LOCAL_URL;
    }
}
export const webURL = getWebURL(environment);

// API URL depending on environment
function getAPIURL(environment) {
    switch (environment) {
        case 'local':
            return process.env.REACT_APP_LOCAL_API_URL;
        case 'dev':
            return process.env.REACT_APP_DEV_API_URL;
        case 'prod':
            return process.env.REACT_APP_PROD_API_URL;
        default:
            return process.env.REACT_APP_LOCAL_API_URL;
    }
}
export const serverAPI = getAPIURL(environment);

export const formatValue = (n) => {
    return Number(n)
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const formatDecimalValue = (n) => {
    const value = n?.toString()?.split(".")[0]
    const dec = n?.toString()?.split(".")[1] ?? 0
    const formated = Number(value)
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${formated}${dec ? `.${dec}` : ""}`
};
export const formatValueToDecimal = (value, decimals = 2) => {
    if (!value) {
        return;
    }
    return Number(value)?.toFixed(decimals).toString();
};
export const getYoutubeUrl = (url) => {
    let urlRegex = /(https?:\/\/[^ ]*)/;
    let result = url.match(urlRegex)[1].replace(/['"]+/g, '');
    let code = result?.split('/').slice(-1)[0];
    let embeddedUrl = 'https://www.youtube.com/embed/' + code;
    return embeddedUrl;
};
export const getId = (url) => {
    let urlRegex = /(https?:\/\/[^ ]*)/;
    let result = url.match(urlRegex)[1].replace(/['"]+/g, '');
    return result;
};
export const mapKey = process.env.REACT_APP_MAP_KEY; // API Key for Google Maps

export const actionS3Objects = ['getObject', 'putObject', 'deleteObject']; // S3 Bucket Actions

export const activeStatus = ['Active', 'Inactive'];
export const propActiveStatus = [
    'Published',
    'Unpublished',
    'Cancelled',
    'Draft',
    'Private'
];

export const newPropStatus = [
    'Sold',
    'Rented',
    'Reserved',
    'Private',
    'Cancelled',
    'Draft',
    'Pocket'
]; // New statuses for property
// @deprecated
export const roles = ['MasterAdmin', 'Admin', 'User', 'Agent']; // --@deprecated

export const newRoles = {
    MasterAdmin: 'MasterAdmin',
    Admin: 'Admin',
    User: 'User',
    Agent: 'Agent',
    SalesManager: 'SalesManager',
    OfficeAdmin: 'OfficeAdmin',
    // Marketing: "Marketing",
    SEO: 'SEO',
    Owner: 'Owner',
    Investor: 'Investor',
}; // New Roles

export const ListingType = ['Buy', 'Rent', 'Sell', 'Lease', 'Holiday Homes']; // Property Listing Types

export const newListingType = {
    Buy: 'Buy',
    Rent: 'Rent',
    HolidayHomes: 'Holiday Homes'
}; // Property Listing Types for deals

export const DealType = {
    // Buy:  "Buy",
    // Rent:  "Rent",
    // Sell:  "Sell",
    // Lease:  "Lease",
    Listing: 'Listing',
    Offplan: 'Offplan'
    // BuyOffplan:  "Buy - Offplan",
    // SellOffplan:  "Sell - Offplan",
}; // Deal Types

export const CompletionStatus = ['Completed', 'Off Plan']; // Property Completion Status

export const UnitStatusBuy = ['Vacant', 'Reserved', 'Sold', 'Tenanted']; // Unit Status for Buy

export const UnitStatusRent = [
    'Vacant Soon',
    'Vacant Now',
    'Reserved',
    'Rented',
    'Tenanted'
]; // Unit Status for Rent

export const UnitStatusSell = ['Vacant', 'Reserved', 'Sold']; // Unit Status for Sell

export const UnitStatusLease = [
    'Vacant Soon',
    'Vacant Now',
    'Reserved',
    'Rented'
]; // Unit Status for Lease

export const BedRooms = ['Studio', '1', '2', '3', '4', '5', '6', '7+']; // No. of BedRooms
export const NewBedRooms = ['NA', 'Studio', '1', '2', '3', '4', '5', '6', '7+']; // No. of BedRooms with none
export const ResidentialBedRooms = ['Studio', '1', '2', '3', '4', '5', '6', '7+'];

export const ValidCategories = {
    Residential: 'Residential',
    Commercial: 'Commercial'
}

export const BathRooms = ['1', '2', '3', '4', '5', '6', '7+']; // No. of BathRooms
export const NewBathRooms = ['NA', '1', '2', '3', '4', '5', '6', '7+']; // No. of BathRooms with none

export const Finance = ['Available', 'Not available'];

export const RentalPeriod = ['Year', 'Month'];

export const Agent = ['Assigned', 'Not assigned'];

export const Cheques = ['1', '2', '4', '6', '12'];

export const ListStatus = ['Published']; // Property Listing Status

export const Languages = [
    'English',
    'Arabic',
    'Russian',
    'French',
    'Mandarin',
    'Spanish',
    'Farsi',
    'Hindi',
    'Turkish',
    'Kurdish',
    'Urdu',
    'Gujarati',
    'Sindhi',
    'Pashto',
    'Kazakh',
    'Marathi',
    'Afrikaans',
    'Telugu',
    'Swahili',
    'Punjabi',
    'Dutch',
    'Portuguese',
    'Italian'
];

// Titles
export const UserTitles = [
    'First Name',
    'Last Name',
    'Email',
    'Status',
    'Role',
    'Edit',
    'Delete'
];

export const PropTitle = [
    'Refer No',
    'Property Type',
    'Property Title',
    'Unit Status',
    'Agent',
    'Published',
    'Upload',
    'Edit',
    'Delete'
];

export const CategoryTitle = ['Categories', 'Edit', 'Delete'];

export const PropTypeTitle = ['Property Types', 'Edit', 'Delete'];

export const AmenityCategoryTitle = ['Amenity Categories', 'Edit', 'Delete'];

export const AmenityTitle = ['Amenities', 'Amenity Category', 'Edit', 'Delete'];

export const CommunityTitle = ['Communities', 'Edit', 'Delete'];

export const DealTitle = [
    'User',
    'Agent',
    'Property',
    'Type',
    'Status',
    'View',
    'Delete'
];

export const DealTitleAgent = [
    'User',
    'Property',
    'Type',
    'Status',
    'View',
    'Delete'
];

export const DealStatus = {
    Created: 'Created',
    CollectingDocuments: 'Collecting Documents',
    ChooseListing: 'Choose Listing',
    CreateListing: 'Create Listing',
    AssignProperty: 'Assign Property',
    PendingApproval: 'Pending Approval',
    Approved: 'Approved',
    Rejected: 'Rejected',
    Completed: 'Completed',
    Canceled: 'Canceled'
};

export const LeadStatus = {
    Unassigned: 'Unassigned',
    Fresh: 'Fresh',
    FollowUp: 'Follow up',
    Prospect: 'Prospect',
    ForListing: 'For Listing',
    Appointment: 'Appointment',
    Viewing: 'Viewing',
    Negotiating: 'Negotiating',
    Deal: 'Deal',
    Won: 'Won',
    Lost: 'Lost',
    Disqualified: 'Disqualified'
};

export const LeadSource = {
    External: 'External',
    Facebook: 'Facebook',
    Instagram: 'Instagram',
    TikTok: 'TikTok',
    Twitter: 'Twitter',
    Whatsapp: 'Whatsapp',
    Email: 'Email',
    LinkedIn: 'LinkedIn',
    Youtube: 'Youtube',
    Pinterest: 'Pinterest',
    Meta: 'Meta',
    GoogleAds: 'Google Ads',
    NewDevelopment: 'Off-Plan'
};

// Create Lead, filterout leadsouurce
export const HotLeadSourceFilter = [
    'AskAQuestion',
    'GetMatchedAssistance',
    'Register',
    'NewListing',
    'Viewing',
    'Newsletter',
    'Imported',
    'NewDevelopment'
];

export const BuildingTitle = ['Name', 'Delete'];

export const SearchTitle = [
    'Status',
    'Purpose',
    'Community',
    'Property ID',
    'Sort By'
];

export const DealsSearchTitle = ['Type'];

export const ActivityTitle = [
    'Type',
    'Status',
    'Date',
    'Completed Date',
    'Notes',
    'Description',
    'Property'
];

export const emirates = [
    'Dubai',
    'Abu Dhabi',
    'Sharjah',
    'Ajman',
    'Um Al Quwain',
    'Ras Al Khaimah',
    'Fujairah'
];

export const propertyTypes = ['Villa', 'Apartment', 'Townhouse', 'Penthouse'];

export const Emirates = [
    // "Abu Dhabi",
    'Dubai',
    'Sharjah'
    // "Ajman",
    // "Umm Al Quwain",
    // "Ras Al Khaimah",
    // "Fujairah",
];

export const Status = ['Active', 'Inactive'];

export const PropertyListStatus = {
    Published: "Active",
    Unpublished: "Inactive",
    Draft: "Draft",
    Private: "Private",
    Reserved: "Reserved",
    Cancelled: "Cancelled",
    Sold: "Sold",
};

export const DocumentTypes = {
    Passport: 'Passport',
    EID: 'EID',
    TitleDeed: 'Title Deed',
    Ejari: 'Ejari',
    Reservation: 'Reservation',
    Receipt: 'Receipt',
    AdditionalDeal: 'Additional Deal',
    TradeLicense: 'Trade License',
    RERARegistration: 'RERA Registration',
    Other: 'Other'
};

export const TaskStatus = {
    Interested: "Interested",
    NotInterested: "Not Interested",
    NoAnswer: "No Answer",
};

export const ActivityTypes = {
    Assign: 'Assign',
    Appointment: 'Appointment',
    Viewing: 'Viewing',
    AskAQuestion: 'Ask a Question',
    Call: 'Call',
    WhatsApp: 'WhatsApp',
    SMSMessage: 'SMSMessage',
    Email: 'Email',
    GetMatchedAssistance: 'Get Matched Assistance',
    NewListing: 'New Listing',
    NewDevelopment: 'Off-Plan'
};

export const ActivityTypesTask = {
    Appointment: 'Appointment',
    Viewing: 'Viewing',
    Call: 'Call',
    WhatsApp: 'WhatsApp',
    SMSMessage: 'SMSMessage',
    Email: 'Email'
};

export const ActivityStatus = {
    Pending: 'Pending',
    Overdue: 'Overdue',
    Complete: 'Complete',
    Skipped: 'Skipped',
    AutoCompleted: 'Auto-Completed',
};

export const defaultCountry = {
    name: 'United Arab Emirates',
    code: '+971',
    short: 'UAE',
    image: 'https://flagcdn.com/w320/ae.png',
    cca2: 'AE'
};

export const HttpStatus = {
    OK: 200,
    CREATED: 201,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    TOO_MANY_REQUESTS: 429,
    UNPROCESSABLE_ENTITY: 422,
    INTERNAL_SERVER_ERROR: 500
};

export const PropertyCardStatus = {
    Verified: 'Verified',
    Unverified: 'Unverified',
    PocketListing: 'Pocket Listing',
    Invalid: 'Invalid',
    Sold: 'Sold'
};

export const FurnishingTypes = {
    Furnished: "Furnished",
    SemiFurnished: "Semi furnished",
    Unfurnished: "Unfurnished",
};

export const ContractValidity = {
    OneMonth: "1 Month",
    ThreeMonths: "3 Months",
    SixMonths: "6 Months",
};
export const VacancyStates = {
    Vacant: "Vacant",
    Tenanted: "Tenanted",
};

export const ValidNumberOfCheques = {
    One: "1",
    Three: "3",
    Four: "4",
    Six: "6",
};

export const ScreenBreakPoints = {
    xxs: 320,
    xs: 425,
    sm: 576,
    md: 768,
    lg: 992,
    laptop: 1024,
    xl: 1200,
    xxl: 1400,
    xxxl: 1920,
    ultraWide: 2560,
}

export const PayeeType = {
    Buyer: 'Buyer',
    //   Tenant: 'Tenant',
    Seller: 'Seller',
    Agency: 'Agency',
    Developer: 'Developer',
    Others: 'Others'
}

export const PaymentMethod = {
    Cash: 'Cash',
    Cheque: 'Cheque',
    Card: 'Credit/Debit Card',
    WireTransfer: 'Wire Transfer',
    Crypto: 'Crypto'
}

export const CommissionStatus = {
    FullyPaid: 'Fully Paid',
    PartiallyPaid: 'Partially Paid',
    Pending: 'Pending',
}


export const getImage = async (img, axios) => {
    try {
        if (!img) {
            throw new Error('Provide valid image path');
        }
        const axiosInstance = axios ?? Axios;

        const url = axios ? `/upload` : `${serverAPI}/upload`;

        const { data } = await axiosInstance.post(
            url,
            JSON.stringify({
                action: `${actionS3Objects[0]}`,
                // fileName: `${img}`,
                fileName: typeof img === 'string' ? img : img?.thumbnail
            }),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return data;
    } catch (err) {
        throw err;
    }
};

export const uploadToS3 = async (
    file,
    fileName,
    contentType = 'image/*',
    axios
) => {
    try {
        const uploadData = JSON.stringify({
            action: `${actionS3Objects[1]}`,
            fileName
        });

        const axiosInstance = axios ?? Axios;

        const url = axios ? `/upload` : `${serverAPI}/upload`;

        const resp = await axiosInstance.post(url, uploadData);

        const s3url = resp.data.signedRequest;
        if (s3url) {
            const myHeaders = new Headers({
                'Content-Type': contentType
            });

            await Axios.put(s3url, file, { myHeaders });

            return s3url;
        }
    } catch (err) {
        console.log(err);

        toast.error('Server error. Try Again!');

        return null;
    }
};

export const uploadManyToS3 = async (
    data = [{ file: null, type: '', fileName: '' }],
    axios
) => {
    try {
        const requests = data?.map((item) => {
            const uploadData = JSON.stringify({
                action: `${actionS3Objects[1]}`,
                fileName: item?.fileName
            });

            const axiosInstance = axios ?? Axios;

            const url = axios ? `/upload` : `${serverAPI}/upload`;

            const req = axiosInstance.post(url, uploadData);

            return req;
        });

        const responses = await Promise.all(requests);

        const s3Urls = responses.map((item) => item.data?.signedRequest);

        const s3Requests = data.map((item, i) => {
            const myHeaders = new Headers({
                'Content-Type': item?.type
            });

            const req = Axios.put(s3Urls[i], item?.file, { myHeaders });

            return req;
        });

        const s3Responses = await Promise.all(s3Requests);
        return true;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const deleteFromS3 = async (fileName, axios) => {
    try {
        const uploadData = JSON.stringify({
            action: `${actionS3Objects[2]}`,
            fileName
        });

        const axiosInstance = axios ?? Axios;

        const url = axios ? `/upload` : `${serverAPI}/upload`;

        const resp = await axiosInstance.post(url, uploadData);

        return resp.data?.message;
    } catch (err) {
        console.log(err);

        return null;
    }
};

export const deleteManyFromS3 = async (data = [], axios) => {
    try {
        const axiosInstance = axios ?? Axios;
        const url = axios ? `/upload` : `${serverAPI}/upload`;

        await Promise.all(
            data.map(
                async (item) =>
                    await axiosInstance.post(url, {
                        action: actionS3Objects[2],
                        fileName: item
                    })
            )
        );
        return true;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const getClientDocuments = async (axios, clientId) => {
    try {
        if (!axios || typeof axios.get !== 'function' || !clientId) {
            throw new Error(
                typeof axios.get !== 'function'
                    ? 'Axios.get is not a function'
                    : 'All params required'
            );
        }

        const { data: clientDocs } = await axios.get(
            `/documents/user/${clientId}`
        );
        return clientDocs;
    } catch (err) {
        console.log(err);
        return [];
    }
};

export const getDealDocuments = async (axios, dealId) => {
    try {
        if (!axios || typeof axios.get !== 'function' || !dealId) {
            throw new Error(
                typeof axios.get !== 'function'
                    ? 'Axios.get is not a function'
                    : 'All params required'
            );
        }

        const { data: dealDocs } = await axios.get(`/documents/deal/${dealId}`);

        return dealDocs;
    } catch (err) {
        console.log(err);
        return [];
    }
};

export const updateDocument = async (axios, documentId, params) => {
    try {
        if (
            !axios ||
            typeof axios.patch !== 'function' ||
            !documentId ||
            !params
        ) {
            throw new Error(
                typeof axios.patch !== 'function'
                    ? 'Axios.patch is not a function'
                    : 'All params required'
            );
        }

        const { data: doc } = await axios.patch(
            `/documents/${documentId}`,
            params
        );

        return doc;
    } catch (err) {
        console.log(err);
        return {};
    }
};

export const BlogStatus = {
    Draft: 'Draft',
    Published: 'Published'
};

export function toIsoString(date, enableTimezone = true) {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

    let formattedDate =
        date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        'T' +
        pad(date.getHours()) +
        ':' +
        pad(date.getMinutes()) +
        ':' +
        pad(date.getSeconds());
    formattedDate += enableTimezone
        ? dif +
        pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' +
        pad(Math.abs(tzo) % 60)
        : '';
    return formattedDate;
}

export const footerTypes = ['Sea', 'Desert', 'Urban'];
export const tinyKey = '9x41a18hxvw874e7yad00rk8b119yrbxp3v3vr4szpn7srr1';

// REST COUNTRIES API ENDPOINT
export const restCountriesEndPoint = 'https://restcountries.com/v3.1/all';

export const getRestCountries = async () => {
    try {
        const urlPattern =
            /(https?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

        const res = restCountriesEndPoint?.match(urlPattern);

        if (!res) {
            throw new Error('invalid url');
        }

        const { data } = await Axios.get(restCountriesEndPoint);

        const countries = data
            ?.filter((country) => country?.idd?.root)
            ?.map((country) => {
                return {
                    name: country?.name?.common ?? country?.name?.official,
                    code: `${country?.idd?.root}${(country?.idd?.suffixes &&
                            country?.idd?.suffixes?.length) === 1
                            ? country?.idd?.suffixes[0]
                            : ''
                        }`,
                    short: country?.cioc ?? country?.cca3 ?? country?.cca2,
                    image: country?.flags?.png ?? country?.flags?.svg,
                    cca2: country?.cca2
                };
            })
            ?.sort((a, b) => (a?.name < b?.name ? -1 : 1));

        return countries;
    } catch (err) {
        console.log(err);
        return [];
    }
};

export const validatePhoneNumbers = ({ code, phone, cca2 }) => {
    if (!phone || !code || !cca2 || phone.length < 2) {
        return false;
    }

    if (phone?.includes(code) || isNaN(Number(`${code}${phone}`))) {
        return false;
    }

    const phoneUtil = phoneLib.PhoneNumberUtil.getInstance();

    const parsedContact = phoneUtil?.parse(`${code}${phone}`, cca2);

    if (!parsedContact) {
        return false;
    }

    const isValid = phoneUtil.isValidNumberForRegion(parsedContact, cca2);

    return isValid;
};

// export const emailRegex =
//   /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const validateEmail = (value = '') => {
    const isValidEmail = value?.match(emailRegex);

    if (isValidEmail) {
        return true;
    }
    // toast.error('Enter valid Email');
    return false;
};

export const validatePassword = (newPassword, confirmPassword) => {
    const errors = [];

    if (!newPassword) {
        errors.push('Enter Password');
    }

    if (newPassword !== confirmPassword) {
        errors.push("Passwords don't match");
    }

    if (!/(?=.*[A-Z])/.test(newPassword)) {
        errors.push('Password should contain at least 1 uppercase character');
    }

    if (!/(?=.*[a-z])/.test(newPassword)) {
        errors.push('Password should contain at least 1 lowercase character');
    }

    if (!/(?=.*\d)/.test(newPassword)) {
        errors.push('Password should contain at least 1 digit');
    }

    if (!/(?=.*[!@#$%^&*()_\-+={}[\]:;"'<>,.?\/])/.test(newPassword)) {
        errors.push('Password should contain at least 1 special character');
    }

    if (/\s/.test(newPassword)) {
        errors.push('Password should not contain any whitespaces');
    }

    if (!/^.{8,}$/.test(newPassword)) {
        errors.push('Password should be at least 8 characters long');
    }

    if (errors.length > 0) {
        toast.error(errors.join(', '));
        return false;
    } else {
        return true;
    }
};

const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/; //validates if a password has at least one character, one lowercase, one uppercase, one number and is at least 8 characters long

const whitespaceRegex = /^(?!.*\s)/; //validates if password has a whitespace

export const validatePasswordNew = (value = '') => {
    const isValid = passwordRegex.test(value) && whitespaceRegex.test(value);

    return isValid;
};

export const comparePasswords = (password, confirmPassword) => {
    if (password !== confirmPassword) {
        return false;
    }

    return true;
};

export const LeadLabels = {
    HotLead: 'Hot Lead',
    ColdLead: 'Cold Lead'
    // Interested: "Interested",
};

export const formatDate = (date) => {
    if (date instanceof Date) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day
            }`;
    }
};

export const getDayName = (date, locale = 'en-US') => {
    if (!isValid(date)) {
        console.log('Invalid date instance');
        throw new Error('Please pass valid date instance');
    }

    return date.toLocaleDateString(locale, {
        month: 'short',
        day: '2-digit'
    });
};

export const PropertyPaths = {
    listing: 'find-the-properties',
    offPlan: 'off-plan-dubai'
};

export const MarketTypes = {
    Offplan: 'Primary Market',
    Listing: 'Secondary Market'
};

export const ActivityFeedTypes = {
    Deal: 'Deal',
    Client: 'Client',
    Other: 'Other'
};

export const SocketEventType = {
    Message: 'message',
    User: 'user',
    Deal: 'deal',
    Activity: 'activity',
    ConnectionCreated: 'connection_created',
    createTicket: 'create_ticket',
    join_room: 'join_room',
    CsvGenerated: 'csv_generated',
    DealCsv: 'csv_generated_deal',
};

export const defaultUserImage = require('../assets/images/logo-small.png');

export const DealCategory = {
    PrimaryOffplan: 'Primary Off Plan Property',
    SecondaryProperty: 'Secondary Market Property',
    ListingAcquired: 'Listing Acquired'
};

export const DealProgressStatus = {
  Unknown: 'Unknown', // this is for use as a default placeholder only!

  // These are meant to be used with Primary Off-Plan deals
  EOI: 'EOI/Down Payment',
  SPA: 'SPA', // Sale & Purchase Agreement

  // These are meant for use with Secondary Market Deals
  // Sales
  ContractFSigned: 'Contract F Signed',
  FinalOfferLetter: 'Final Offer Letter',
  NOC: 'NOC',
  LiabilityLetter: 'LiabilityLetter',
  ReleaseLetter: 'ReleaseLetter',
  Settlement: 'Settlement',

  // Rentals
  TenancyContract: 'Tenancy Contract',

  // General
  Completed: 'Completed',
  Cancelled: 'Cancelled'
};

export const DealClientType = {
    Alba: 'Alba',
    External: 'External'
};

export const DealPurpose = {
    Rent: 'For Rent',
    Sale: 'For Sale'
};

export const DealRoleType = {
    Buyer: 'Buyer',
    Tenant: 'Tenant',
    Seller: 'Seller',
    Landlord: 'Landlord'
};

export const AgencyType = {
    RealEstateAgency: 'Real Estate Agency',
    Freelancer: 'Freelancer',
    Referral: 'Referral'
};
export const TicketStatuses = {
    Progress: 'In Progress',
    Created: 'Unassigned',
    Resolved: 'Resolved',
    Open: 'Open',
    Archived: 'Archived'
};
export const TicketTypes = {
    MasetrTicket: 'MasterTicket',
    SubTicket: 'SubTicket'
};
export const DepartmentNames = {
    Administrators: 'Administrators',
    OperationManager: 'Operations Manager',
    Photographers: 'Photographers'
};
export const PropertyCardLogTypes = {
    Checkout: 'Leads Checked Out',
    Checkin: 'Leads Checked In',
    Assign: 'Assigned Leads',
    UnAssign: 'Unassigned Leads',
    NewLead: 'New Lead',
    Created: 'Created Card',
    StatusChanged: 'Status Changed',
    UnverifiedLead: 'Unverified Lead'
};

export const TransactionType = {
    Sell: 'Sell',
    Buy: 'Buy'
};
export const paymentPlans = ['post-handover payment plan', 'full payment on delivery']


export const imageUrlRegex = /\.(jpe?g|png|gif|bmp|webp|svg)$/i;
export const pdfUrlRegex = /\.pdf$/i;

// HOLIDAYS
export const HolidayStates = {
    ON_HOLIDAY: 'ON HOLIDAY',
    SCHEDULED: 'HOLIDAY SCHEDULED',
    EXPIRED: 'HOLIDAY EXPIRED'
};

// Property types, exclude beds and bath manatory option while create listing acquired and property list. 
export const propertyTypesExcludeBedsBaths = {
    commercial: 'Commercial',
    retail: 'Retail',
    staffAccommodation: 'Staff Accommodation',
    land: 'Land',
    shop: 'Shop',
    wholeBuilding: 'Whole Building',
    store: 'Store',
    warehouse: 'Warehouse',
    officeSpace: 'Office Space',
    plot: 'Plot'
};

// Property types, exclude building manatory option while create listing acquired and property list. 
export const propertyTypesExcludeBuilding = {
    villa: 'Villa',
};

// DND Allowed status
export const dndAllowedStatuses = [
    LeadStatus.Prospect,
    LeadStatus.ForListing,
    LeadStatus.Appointment,
    LeadStatus.Viewing,
    LeadStatus.Negotiating,
    LeadStatus.Deal,
    LeadStatus.Won
];

//project/company sources
export const sources = {
    albaHomes: 'albaHomes',
    albaCars: 'albaCars',
    albaJobs: 'albaJobs',
};

//lead source types
export const leadSourceTypes = {
    Hot: 'Hot',
    Cold: 'Cold',
};

//leadstatus unchecked as default when return leads from leadslist page 
export const LeadStatusUncheckedReturn = [
    LeadStatus.Prospect,
    LeadStatus.ForListing,
    LeadStatus.Appointment,
    LeadStatus.Viewing,
    LeadStatus.Negotiating,
    LeadStatus.Deal,
    LeadStatus.Won
];
  