import DndNumbersUpload from "./components/DndNumbersUpload";


const DndNumbersUploads = () => {
    return (
        <>
            <DndNumbersUpload title={"Upload DND"} name={"DND"} role={null} />
        </>
    );
};

export default DndNumbersUploads;
