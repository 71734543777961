import { LeadStatus } from "../../utils/envConfig";


/**
 * Get the next possible lead status (state which the lead can move to)
 *
 * @param {LeadStatus[keyof LeadStatus]} currentStatus
 * @returns {LeadStatus[keyof LeadStatus] | ""}
 */
export const getNextPossibleStatus = (currentStatus) => {
  switch (currentStatus) {
    case LeadStatus.Fresh:
      return LeadStatus.Prospect;
    case LeadStatus.FollowUp:
      return LeadStatus.Prospect;
    case LeadStatus.Appointment:
      return LeadStatus.Negotiating;
    case LeadStatus.Viewing:
      return LeadStatus.Negotiating;
    case LeadStatus.Negotiating:
      return LeadStatus.Deal;
    default:
      return "";
  }
};
