import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { init as initUI } from 'ys-webrtc-sdk-ui';
import { init as initCore } from 'ys-webrtc-sdk-core';
import 'ys-webrtc-sdk-ui/lib/ys-webrtc-sdk-ui.css';

const LinkusSDK = forwardRef(({ username, secret, pbxURL, useUI = true }, ref) => {
  const containerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    phone: null,
    pbx: null,
  }));

  useEffect(() => {
    const initializeLinkus = async () => {
      try {
        let phone, pbx, destroy;
        if (useUI) {
          ({ phone, pbx, destroy } = await initUI(containerRef.current, {
            username,
            secret,
            pbxURL,
          }));
        } else {
          ({ phone, pbx, destroy } = await initCore({
            username,
            secret,
            pbxURL,
          }));
        }
        console.log('Linkus SDK initialized:', { phone, pbx });

        if (ref) {
          ref.current = { phone, pbx };
        }

        return () => {
          destroy();
        };
      } catch (error) {
        console.error('Failed to initialize Linkus SDK:', error);
      }
    };

    initializeLinkus();
  }, [username, secret, pbxURL, useUI, ref]);

  return <div ref={containerRef} id="container"></div>;
});

export default LinkusSDK;
