import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { Icon, RSelect } from "../../../../components/Component";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createBuilding, fetchCommunitiesList } from "../../../../redux/actions/property";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { defaultPlaceDistances } from "./constants";

const BuildingCreationModal = ({ isOpen, toggleFn, onBuildingCreated, chosenCommunity }) => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const { createBuildingLoading, error } = useSelector((state) => state.property);

  const [name, setName] = useState("");
  const [selectedCommunity, setSelectedCommunity] = useState(chosenCommunity);
  const [distances, setDistances] = useState(
    new Array(defaultPlaceDistances.length).fill("")
  );

  const communities = useSelector((state) => state.property.communities);

  useEffect(() => {
    if (chosenCommunity) {
      setSelectedCommunity(chosenCommunity)
    }
  }, [chosenCommunity]);

  useEffect(() => {
    fetchCommunitiesList(axios);
  }, [axios]);

  useEffect(() => {
    if (error) {
      console.log(error);
      const serverErrorMessage = error.response.data.message;
      if (serverErrorMessage) {
        toast.error(serverErrorMessage);
      } else {
        toast.error("An error occurred while creating building.");
      }
    }
  }, [error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('selectedCommunity before request', selectedCommunity);
    const payload = {
      name,
      communityId: selectedCommunity?.value,
      distances: defaultPlaceDistances.map((obj, index) => ({
        ...obj,
        distance: !isNaN(parseInt(distances[index])) ? parseInt(distances[index]) : 0,
      })),
    };
    try {
      const newBuilding = await dispatch(createBuilding(axios, payload));
      const chosenCommunity = typeof selectedCommunity === 'object' ? selectedCommunity : null;
      toast.success("Building created successfully.");
      if (onBuildingCreated && typeof onBuildingCreated === "function") {
        // pass the callback back to the parent component with the new building if needed
        onBuildingCreated(newBuilding, chosenCommunity);
      }
    } catch (error) {
        console.log(error);
        toast.error("An error occurred while creating building.");
    } finally {
        if (toggleFn && typeof toggleFn === "function") {
            toggleFn();
        }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleFn}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a href="#cancel" className="close">
          {" "}
          <Icon
            name="cross-sm"
            onClick={(ev) => {
              ev.preventDefault();
              //   resetForm();
              toggleFn();
            }}
          ></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Add Building</h5>
          <div className="mt-4">
            {/* <form onSubmit={(e) => handleSubmit(e)}> */}
              <Row className="g-3">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="customer">
                      Building Name
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        placeholder="Enter Building Name"
                        className="form-control"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col size="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="customer">
                      Community
                    </label>
                    <RSelect
                      onChange={(e) => setSelectedCommunity(e)}
                      options={communities.map((c) => ({
                        label: c.community,
                        value: c.id,
                        ...c
                      }))}
                      // defaultValue={chosenCommunity}
                      value={selectedCommunity}
                      placeholder="Community"
                    />
                  </div>
                </Col>
                <Col sm={12}>
                  <div>
                    <div className="form-group">
                      <label className="form-label">Distances*</label>
                      <div className="d-flex align-items-center">
                        <div
                          className="me-2"
                          style={{
                            width: "100%",
                          }}
                        ></div>
                      </div>
                    </div>
                    {defaultPlaceDistances.map((item, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-between mb-2"
                      >
                        <input
                          type="text"
                          value={item.place}
                          readOnly
                          className="form-control me-2"
                        />
                        <div className="input-group me-2">
                          <div className="input-group">
                            <input
                              type="number"
                              name={item.place}
                              id={item.place}
                              autoComplete={item.place}
                              value={distances[index]}
                              onChange={(e) =>
                                setDistances(
                                  distances?.map((distance, i) =>
                                    i === index
                                      ? parseInt(e.target.value)
                                      : parseInt(distance)
                                  )
                                )
                              }
                              className="form-control"
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">m</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>

                <Col size="12" className="d-flex justify-content-end">
                  <Button color="primary" disabled={createBuildingLoading} onClick={(e) => handleSubmit(e)}>
                    {createBuildingLoading ? "Loading.." : "Add Building"}
                  </Button>
                </Col>
              </Row>
            {/* </form> */}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BuildingCreationModal;
