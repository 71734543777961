import { Button, Modal, ModalBody } from "reactstrap";
import { Icon, RSelect } from "../../../../components/Component";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { HttpStatus } from "../../../../utils/envConfig";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addCommunityTeamCommunity } from "../../../../redux/actions/community-teams";

const AddCommunityModal = ({
  communityTeamId,
  existingCommunities,
  isOpen,
  toggleFn,
  refreshFn,
}) => {

  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const [communityData, setCommunityData] = useState([]);
  const [communityOptions, setCommunityOptions] = useState([]);
  const [selectedCommunity, setSelectedCommunity] = useState(null);

  const { loading, error } = useSelector(state => state.communityTeam);

  const fetchCommunities = async () => {
    try {
      const res = await axios.get(`/community`);
      if (res.status === HttpStatus.OK) {
        setCommunityData(res?.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Couldn't fetch communities.");
      toggleFn();
    }
  };

  useEffect(() => {
    fetchCommunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    // Filter out the communities that are already part of this team before setting the options
    // plus the ones that are already assigned to a different team
    const existingCommunitiesIDs = existingCommunities.map(
      (mem) => mem?.id ?? mem?._id
    );
    setCommunityOptions(
      communityData
        .filter((comm) => !comm.team_id && !existingCommunitiesIDs.includes(comm?.id ?? comm?._id))
        .map((comm) => ({
          label: comm?.community,
          value: comm?.id ?? comm?._id,
        }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityData, existingCommunities]);

  useEffect(() => {
    if (error) {
      toast.error(error.message ?? "Error occurred while adding agent.");
    }
  }, [error]);

  const handleAddingCommunity = async () => {
    const payload = {
      newCommunity: selectedCommunity?.value
    };
    dispatch(addCommunityTeamCommunity(
      axios,
      communityTeamId,
      payload,
      () => toggleFn(),
      () => {
        toast.success("Added successfully!");
        refreshFn();
      }
    ));
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleFn} size="md">
      <ModalBody>
        <div className="d-flex justify-content-between align-items-center mt-2 mb-3">
          <h5 className="text-center">Add Community to Community Team</h5>
          <div className="mb-2">
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                toggleFn();
              }}
              className="close mt-2 text-center"
            >
              <Icon name="cross-sm"></Icon>
            </a>
          </div>
        </div>
        <RSelect
          placeholder="Select Agent"
          value={selectedCommunity}
          options={communityOptions}
          onChange={(option) => {
            setSelectedCommunity(option);
          }}
        />
        <div className="d-flex justify-content-end mt-3">
          <Button color="primary" onClick={handleAddingCommunity} disabled={loading}>
            {loading ? "Adding..." : "Submit"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddCommunityModal;
