/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Icon } from "../../../../components/Component";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { removeCommunityTeamCommunity } from "../../../../redux/actions/community-teams";

const CommunityInfoCard = ({ communityTeamId, community, refreshFn, isAdmin }) => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const [confirmRemoveModal, setConfirmRemoveModal] = useState(false);
  const toggleConfirmModal = () => setConfirmRemoveModal(!confirmRemoveModal);

  const { error } = useSelector((state) => state?.communityTeam);

  const removeCommunity = async () => {
    const payload = {
      communityId: community?.id ?? community?._id,
    };
    dispatch(removeCommunityTeamCommunity(
      axios,
      communityTeamId,
      payload,
      () => refreshFn(),
      () => {
        toast.success("Community removed successfully!");
        toggleConfirmModal();
      }
    ));
  };

  useEffect(() => {
    if (error) {
      toast.error(error.message ?? "Error occurred while removing community.");
    }
  }, [error]);

  return (
    <>
      <div className="card">
        <div className="gallery-body card-inner align-center justify-between flex-wrap">
          <div className="user-info d-flex justify-content-between w-100">
            <div className="lead-text">{community?.community}</div>
            <div className="lead-text">
              {isAdmin ? <span onClick={toggleConfirmModal} className="cursor-pointer">
                <Icon name={"cross"} />
              </span> : null}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={confirmRemoveModal}
        toggleFn={toggleConfirmModal}
        size="sm"
        title={"Confirm Removal"}
        isDeletion={true}
        actionFn={removeCommunity}
      >
        <p>
          Are you sure you want to remove{" "}
          <span className="fw-bold">{community?.community}</span> from the team
          communities?
        </p>
      </ConfirmationModal>
    </>
  );
};

export default CommunityInfoCard;
