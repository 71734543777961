import ListPropertyCardsUI from "./components/ListPropertyCardsUI";

const ListPropertyCards = () => {
  return (
    <>
      <ListPropertyCardsUI />
    </>
  );
};

export default ListPropertyCards;
