import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ListingType,
  UnitStatusBuy,
  UnitStatusRent,
  UnitStatusLease,
  UnitStatusSell,
  newRoles,
  uploadToS3,
} from "../../../utils/envConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Icon, Row, Col, RSelect } from "../../../components/Component";
import { fetchUsers } from "../../../redux/actions/user";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SearchClientSelectNew from "../../../components/SearchClientSelectNew";
import { Card } from "reactstrap";
import uuid from "react-uuid";
import S3Image from "./S3Image";
import { conditionsOptions } from '../../../constants/propertyListConditions'
import { purposeOfMarketOptions } from "../../../constants/purposeOfMarketOptions";


const InfoEdit = (props) => { 
  const values = props.property;
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();

  // State Variables
  const [type, setType] = useState([]);
  const [category, setCategory] = useState([]);
  const [optUsers, setOptUsers] = useState([]);
  const [optDefaultUser, setOptDefaultUser] = useState();
  const [optDefaultCategory, setOptDefaultCategory] = useState();
  const [conditions, setConditions] = useState();
  const [purposeOfMarket, setPurposeOfMarket] = useState();
  const [optDefaultUnitStatus, setOptDefaultUnitStatus] = useState();
  const [uploading, setUploading] = useState();

  const users = useSelector((state) => state.user?.users);
  const [formData, setFormData] = useState({});

  const handleFormData = (input) => async (e) => {
    // input value from the form
    let { value } = e.target;


    if (input === 'qrCodeUrl') {
      setUploading(true)
      const fileName = `qrCodes/${uuid()}.${value
        ?.split('.')
        ?.pop()}`;
      await uploadToS3(e.target.files[0], fileName, e.target?.files[0]?.type, axios)
      value = fileName;
      setUploading(false)
    }

    // updating formData state
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  useEffect(() => {
    dispatch(fetchUsers(axios));
  }, [dispatch]);

  useEffect(() => {
    async function fetchType() {
      try {
        const res = await axios
          .get(`/propType`)
          .then((type) => {
            setType(type.data);
          })
          .catch((err) => {
            toast.error("Property type not available !");
          });
      } catch (err) {
        // console.log(err)
        toast.error("Server error. Try Again !");
      }
    }
    async function fetchCategory() {
      try {
        const res = await axios
          .get(`/category`)
          .then((cat) => {
            setCategory(cat.data);
          })
          .catch((err) => {
            toast.error("Category type not available !");
          });
      } catch (err) {
        // console.log(err)
        toast.error("Server error. Try Again !");
      }
    }
    fetchType();
    fetchCategory();

    if (values.property_owner_id) {
      let options = [];
      options.push({
        value: values.propertyOwner.id,
        label:
          values.propertyOwner.first_name +
          " " +
          values.propertyOwner.last_name,
      });
      setOptDefaultUser(options[0]);
    }

    if (values.category) {
      setOptDefaultCategory({
        label: values.category.category,
        value: values.category.id,
      });
    }
    if (values.unitStatus) {
      setOptDefaultUnitStatus({
        label: values.unitStatus,
        value: values.unitStatus,
      });
    }

    if (values.conditions) {
      setConditions({
        label: values.conditions,
        value: values.conditions,
      });
    }

  }, []);

  useEffect(() => {
    let options = [];
    let result =
      users &&
      users.length > 0 &&
      users
        .filter((user) => user.role === newRoles.Owner)
        .map((a) =>
          options.push({ value: a.id, label: a.first_name + " " + a.last_name })
        );
    setOptUsers(options);
  }, [users]);

  const handleOption = (e) => {
    // console.log( e.value );
    let lg = [];
    lg.push(e);
    setOptDefaultUser(lg);
    if (e) {
      setFormData((prevState) => ({
        ...prevState,
        property_owner_id: e.id,
      }));
    }
  };
  const handleUnitStatus = (e) => {
    let lg = [];
    lg.push(e);
    setOptDefaultUnitStatus(lg);
    if (e) {
      setFormData((prevState) => ({
        ...prevState,
        unitStatus: e.value,
      }));
    }
  };
  const handleCategory = (e) => {
    let lg = [];
    lg.push(e);
    setOptDefaultCategory(lg);
    if (e) {
      setFormData((prevState) => ({
        ...prevState,
        categoryId: e.value,
      }));
    }
  };
  const handleConditions = (e) => {
    setConditions(e);
    if (e) {
      setFormData((prevState) => ({
        ...prevState,
        conditions: e.value,
      }));
    }
  };
  const handlePurposeOfMarket = (e) => {
    setPurposeOfMarket(e);
    if (e) {
      setFormData((prevState) => ({
        ...prevState,
        purposeOfMarket: e.value,
      }));
    }
  };

  const fetchPropertiesByPermitNo = async (link) => {
    try {
      const res = await axios.get(link);
      return res?.data?.filteredCount;
    } catch (err) {
      toast.error('Permit No Validation failed');
    }
  };

  const validateFormData = async (permitNo) => {
        // Checking permit number
        if (formData.permitNo=== undefined) {
          formData.permitNo = permitNo;
          if (!permitNo) {            
            toast.error('Please enter permit number');
            return false;
          }
        }

        // Checking permit number
        if (!formData.permitNo) {
          toast.error('Please enter permit number');
            return false;
        }
      
        // Checking permit number uniqueness
        if (formData.permitNo && formData.permitNo!==values.permitNo) {
          let link = `/filter/propList?status=Active&permitNo=${formData.permitNo}&listingType=${values.listingType}`;
          const count = await fetchPropertiesByPermitNo(link); 
          if (count > 0) {
            toast.error('Permit number should be unique');
            return false; 
          }
        }

    return true;
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const isValid = await validateFormData(values?.permitNo);

    if (isValid) {
      props.propertyUpdate(formData);
      props.setEditInfo(false);
    }
  };

  return (
    <>
      <Card className="card-bordered">
        <Row className="gy-4 m-2">
          <div className="d-flex justify-content-end align-items-end">

            <button
              className="close-btn"
              onClick={() => props.setEditInfo(false)}
              style={{
                top: "1.5rem",
                right: "1rem",
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
            >
              <Icon name="cross" style={{
                fontSize: "1rem", backgroundColor: "#fff", color: "red",
                border: "solid 1px #dbdfea", borderRadius: "0.2rem", padding: "0.3rem"
              }} />
            </button>
          </div>
          <Col md="6">
            <div className="form-group">
              <label className="form-label" htmlFor="first-name">
                Permit Number * (trakheesi){" "}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="first-name"
                  className="form-control"
                  name="permitNo"
                  defaultValue={values.permitNo}
                  onChange={handleFormData("permitNo")}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label" htmlFor="contractA">
                Contract A{" "}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="contractA"
                  className="form-control"
                  name="contractA"
                  defaultValue={values.contractA}
                  onChange={handleFormData("contractA")}
                />
              </div>
            </div>
          </Col>

          <Col sm={6}>
            <div className="form-group">
              <label className="form-label">Property Owner *</label>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <SearchClientSelectNew
                    onlyOwners
                    onChange={handleOption}
                    value={optDefaultUser}
                  // options={ optUsers }
                  />
                </div>
                {/* <Button color="success" className="ms-2" type="button" onClick={ ( ev ) =>
              {
                ev.preventDefault();
                toggleModal();
              } }>
                <Icon name="plus"></Icon>
              </Button> */}
              </div>
            </div>
          </Col>

          <Col md="6">
            <div className="form-group">
              <label className="form-label">Category *</label>
              <RSelect
                onChange={handleCategory}
                value={optDefaultCategory}
                options={category?.map((x) => ({
                  label: x.category,
                  value: x.id,
                }))}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Unit Status *</label>
              {!values.listingType && <RSelect options={[]} />}
              {values.listingType === ListingType[0] && UnitStatusBuy && (
                <RSelect
                  value={optDefaultUnitStatus}
                  onChange={(e) => handleUnitStatus(e)}
                  options={UnitStatusBuy?.map((x) => ({
                    label: x,
                    value: x,
                  }))}
                />
              )}
              {values.listingType === ListingType[1] && UnitStatusRent && (
                <RSelect
                  value={optDefaultUnitStatus}
                  onChange={(e) => handleUnitStatus(e)}
                  options={UnitStatusRent?.map((x) => ({
                    label: x,
                    value: x,
                  }))}
                />
              )}
              {values.listingType === ListingType[2] && UnitStatusSell && (
                <RSelect
                  value={optDefaultUnitStatus}
                  onChange={(e) => handleUnitStatus(e)}
                  options={UnitStatusSell?.map((x) => ({
                    label: x,
                    value: x,
                  }))}
                />
              )}
              {values.listingType === ListingType[3] && UnitStatusLease && (
                <RSelect
                  value={optDefaultUnitStatus}
                  onChange={(e) => handleUnitStatus(e)}
                  options={UnitStatusLease?.map((x) => ({
                    label: x,
                    value: x,
                  }))}
                />
              )}
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label" htmlFor="qr">
                QR Code Image{" "}
              </label>

              <div className="form-control-wrap">
                {values?.qrCodeUrl &&
                  <div style={{ width: '100px', height: '100px', marginBottom: '10px' }}>
                    <S3Image url={values?.qrCodeUrl} />
                  </div>
                }
                <input
                  type="file"
                  id="qr"
                  className="form-control"
                  name="qrCodeUrl"
                  accept="image/png,image/jpeg"
                  onChange={handleFormData("qrCodeUrl")}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Conditions</label>
              <RSelect
                onChange={handleConditions}
                value={conditions}
                options={conditionsOptions.map((x) => ({
                  label: x,
                  value: x,
                }))}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Market Purpose</label>
              <RSelect
                onChange={handlePurposeOfMarket}
                value={purposeOfMarket}
                options={purposeOfMarketOptions.map((x) => ({
                  label: x,
                  value: x,
                }))}
              />
            </div>
          </Col>
        </Row>
        <Row className="m-2">
          <Col sm="12">
            <div className="d-flex justify-content-end align-items-end">
              <Button
                disabled={uploading}
                onClick={handleSave}
                color="success"
                className="w-15 d-flex mt-2 justify-content-center align-items-end mb-2"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default InfoEdit;
