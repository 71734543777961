import { BlockHead, BlockHeadContent } from "../../../../components/Component";
import BorderedListCard from "../../../../components/cards/BorderedListCard";

const SystemInfoView = ({ shownFieldsData }) => {
  return (
    <>
      <BlockHead>
        <BlockHeadContent>
            <h5 className="nk-block-title title">System Info</h5>
        </BlockHeadContent>
      </BlockHead>
      <BorderedListCard data={shownFieldsData} />
    </>
  );
};

export default SystemInfoView;
