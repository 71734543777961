import { useDrag } from "react-dnd";
import { useState } from "react";

const DraggableCard = ({ text }) => {
  const [isDropped, setIsDropped] = useState(false);

  const [{ isDragging }, drag] = useDrag({
    type: "CARD",
    item: { text },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        setIsDropped(true);
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const boxStyling = {
    opacity: isDragging ? 0.5 : 1,
    cursor: "move",
    margin: "0.5rem",
    display: "inline-block",
    borderRadius: "8px",
    backgroundColor: "white",
    boxShadow: "rgb(0 0 0 / 4%) 0px 0px 4.5px 2px",
    padding: "0 0.2rem",
  };


  return !isDropped ? (
    <div ref={drag} style={boxStyling}>
      <div className={`p-1 m-1 text-dark`}>{text}</div>
    </div>
  ) : null;
};

export default DraggableCard;
