import * as types from "../types";

const initialState = [];

export const feeds = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_FEEDS:
            return [...action?.payload];
        case types.NEW_FEED:
            return [action?.payload, ...state];
        default:
            return state;
    }
};
