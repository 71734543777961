/**
 * @typedef {Object} CardsTableHeaderProps
 * @property {string} [className] - Additional CSS class names to apply.
 * @property {React.CSSProperties} [style] - Inline styles to apply.
 * @property {React.ReactNode | null} [children] - The children to render inside the column.
 */

/**
 * CardsTableHeader component renders a table header with various styling options.
 * 
 * @param {CardsTableHeaderProps & React.HTMLAttributes<HTMLDivElement>} props - The properties for the component.
 * @returns {JSX.Element} The rendered table header row.
 */
const CardsTableHeader = ({ className, style, ...props }) => {
    return (
      <div
        className={`nk-tb-item nk-tb-head ${className ? className : ""}`}
        style={style ?? {}}
        {...props}
      >
        {props?.children ?? null}
      </div>
    );
  };
  
  export default CardsTableHeader;
  