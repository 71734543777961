import React, { useEffect, useState } from 'react';
import { newRoles } from '../../utils/envConfig';
import AgentsDashboard from './AgentsDashboard';
import MainDashboard from './MainDashboard';
import { useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
// test
import DatePicker from 'react-multi-date-picker';

export default function Dashboard() {
    const authuser = useSelector((state) => state?.user?.loggedInUser);

    const axios = useAxiosPrivate();

    const [agent, setAgent] = useState(null);

    const getAgent = async () => {
        try {
            if (authuser?.role !== newRoles.Agent) {
                return;
            }

            const { data: agent } = await axios.get(
                `/agent/user/${authuser?.id}`
            );
            setAgent({ ...agent, photo: agent?.user?.photo });
        } catch (err) {
            console.log(err);
            setAgent({ ...authuser, userId: authuser?.id });
        }
    };

    useEffect(() => {
        authuser?.role === newRoles.Agent && getAgent();
    }, [authuser?.role]);

    return authuser?.role === newRoles.MasterAdmin ||
        authuser?.role === newRoles.Admin ||
        authuser?.role === newRoles?.Marketing ||
        authuser?.role === newRoles?.SalesManager ? (
        <MainDashboard />
    ) : authuser?.role === newRoles?.Agent ? (
        <AgentsDashboard agent={{ ...agent }} />
    ) : (
        <>
            <h1>No dashboard for {authuser?.role} role yet</h1>
        </>
    );
}
