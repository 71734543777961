/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Button, Card, Collapse, Spinner } from "reactstrap";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PaginationComponent,
  RSelect,
} from "../../../components/Component";
import CardsTable from "../../../components/table/card-table/CardsTable";
import CardsTableHeader from "../../../components/table/card-table/CardsTableHeader";
import CardsTableColumn from "../../../components/table/card-table/CardsTableColumn";
import { HEADER_FIELDS, STATUS_COLORS } from "./constants";
import TableCheckbox from "../../../components/input/checkbox/TableCheckbox";
import CardsTableRow from "../../../components/table/card-table/CardsTableRow";
import {
  LeadsWithPropCardsApiResponse,
  DataItem,
  Mapping,
  PropertyCardType,
} from "../../../api/property-cards/fetchLeadsWithPropertyCards";
import { QueryState } from "./ExplorerLeadsContainer";
import { Link } from "react-router-dom";
import StatusBadge from "./components/StatusPill";
import { format } from "date-fns";
import ExplorerCommunityFilter from "../../components/filter-components/explorer-specific/ExplorerCommunityFilter";
import BuildingFilter from "../../components/filter-components/BuildingFilter";
import ExplorerPropTypesFilter from "../../components/filter-components/explorer-specific/ExplorerPropTypesFilter";
import useScreenSize from "../../../hooks/useScreenSize";
import { ScreenBreakPoints } from "../../../utils/envConfig";
import {
  getCollapseLineHeight,
  getNameColumnMinWidth,
} from "./styling-utils/styling";
import { useSelector } from "react-redux";
import { useFetchReturnType } from "../../../hooks/useFetch";
import { MutationQueryResult } from "../../../hooks/useMutationQuery";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import Skeleton from "react-loading-skeleton";
import NewLeadFlagComponent from "../../components/new-lead-flag/newLeadFlag";

/**
 * @typedef {Object} Callbacks
 * @property {() => void} communityCallback - Callback function for community filter changes.
 * @property {() => void} buildingCallback - Callback function for building filter changes.
 * @property {() => void} propertyTypeCallback - Callback function for property type filter changes.
 * @property {MutationQueryResult} checkoutLeads - Mutation object to use when assigning leads.
 */

/**
 * @typedef {Object} SelectedState
 * @property {Set<string> | undefined} cards - The list of property cards to checkout.
 * @property {Set<string>} leads - The list of leads to checkout.
 */

/**
 * @typedef {Object} ExplorerLeadsViewProps
 * @property {LeadsWithPropCardsApiResponse} data - The data from the API response.
 * @property {boolean} loading - Indicates if the data is currently loading (initial load).
 * @property {boolean} isFetching - Indicates if the data is currently fetching data (cache or otherwise).
 * @property {"loading" | "success" | "error" | "idle"} status - The status of the data fetching process.
 * @property {boolean} isError - Indicates if there was an error loading the data.
 * @property {unknown} errorVal - The error value if there was an error loading the data.
 * @property {QueryState} queryState - The current state of the query params.
 * @property {function(Partial<QueryState>): void} setQueryState - A function to update the query params.
 * @property {SelectedState} selectedItems - The currently selected items (leads and property cards).
 * @property {function(Partial<SelectedState>): void} setSelectedItems - A function to update the selected items.
 * @property {AxiosInstance} axiosInstance - The Axios instance for making API requests.
 * @property {Dispatch<AnyAction>} dispatch - The Redux dispatch function.
 * @property {Callbacks} callBacks - A function to update the query params.
 * @property {useFetchReturnType} agentData - The authenticated agent's response data.
 */

/**
 * ExplorerLeadsView Component
 * @param {ExplorerLeadsViewProps} props - The props for the ExplorerLeadsView component.
 * @returns {JSX.Element} The rendered ExplorerLeadsView component.
 */
const ExplorerLeadsView = ({
  data,
  isFetching,
  status,
  isError,
  errorVal,
  queryState,
  setQueryState,
  selectedItems,
  setSelectedItems,
  axiosInstance,
  dispatch,
  callBacks,
  agentData,
}) => {
  const isInitialLoading = status === "loading";
  const isRefetching = isFetching && status === "success";
  const isEverythingSelected = data?.data?.length> 0 && selectedItems?.leads?.size === data?.data?.length;
  const isAnyFilterSelected = queryState?.propertyTypes?.length > 0 || queryState?.buildings?.length > 0 || queryState?.communities?.length > 0;

  /**
   * Renders a header based on the provided header data.
   *
   * @param {HEADER_FIELDS} headerData - The data for the header field.
   */
  const renderHeader = (headerData) => {
    if (headerData?.isCheckbox) {
      return (
        <TableCheckbox
          name={headerData?.name}
          id={headerData?.name}
          size={"sm"}
          checked={isEverythingSelected}
          onChange={(event) => {
            event.stopPropagation();
            if (isEverythingSelected) {
              setSelectedItems(_ => ({ leads: new Set(), cards: new Set() }));
            } else {
              setSelectedItems(_ => ({
                leads: new Set(data?.data?.map((item) => item?.lead?._id ?? item?.lead?.id)),
                cards: new Set(data?.data?.map((item) => item?.mappings?.map((card) => card?.propertyCard?.id ?? card?.propertyCard?._id)).flat()),
              }));
            }
          }}
        />
      );
    } else {
      return <span>{headerData?.name}</span>;
    }
  };

  const getBuildingFilterMargins = (screenWidth = 0) => {
    if ((screenWidth >= 1120 && screenWidth < 1200) || (screenWidth >= 1410)) {
      return 'mt-0';
    } else if (screenWidth >= 1200 && screenWidth < 1410) {
      return 'mt-2';
    } else if (screenWidth >= ScreenBreakPoints.md && screenWidth < 1200) {
      return 'mt-2';
    }
    return 'mt-2 mt-md-0';
  }
  const getCommunityFilterWidth = (screenWidth = 0) => {
    if (screenSize?.width <= ScreenBreakPoints.xxs) {
      return "12rem";
    }
    if (screenSize?.width >= ScreenBreakPoints.xs && screenSize?.width <= ScreenBreakPoints.sm) {
      return "19rem";
    }
    if (screenSize?.width <= ScreenBreakPoints.sm) {
      return "16rem";
    }
    return "28rem";
  }

  const [isOpen, setIsOpen] = useState("0");
  const screenSize = useScreenSize();

  const [isBulkConfirmModalOpen, setIsBulkConfirmModalOpen] = useState(false);
  const toggleBulkConfirmationModal = () => setIsBulkConfirmModalOpen(!isBulkConfirmModalOpen);

  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };

  const checkoutManyLeads = async () => {
    const payload = {
      leads: [...selectedItems?.leads?.values()],
    }
    callBacks.checkoutLeads && callBacks.checkoutLeads?.mutate(payload);
    toggleCollapse('0');
    toggleBulkConfirmationModal();
  };

  const pageCount = Math.ceil((data?.filteredCount ?? 0) / queryState?.limit);
  const hasSelectedItems = selectedItems?.leads?.size > 0 || selectedItems?.cards?.size > 0;

  return (
    <React.Fragment>
      <Head title="Explorer Leads"></Head>
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>
                Explorer Leads
                {!isInitialLoading && isRefetching && (
                  <Spinner
                    size={"lg"}
                    type="grow"
                    className="ms-4 fw-light"
                    color="primary"
                  />
                )}
              </BlockTitle>

              <BlockDes>
                {!isInitialLoading && (agentData?.data || isAnyFilterSelected) ? (
                  <p>Found {data?.filteredCount ?? 0} Leads</p>
                ) : (
                  <p className="invisible">Loading...</p>
                )}
              </BlockDes>
              <BlockDes className="fs-6 mt-1">
                {agentData && agentData?.data ? (
                  <p>
                    You have{" "}
                    <strong className="text-primary">
                      {agentData?.data?.creditsBalance <= 0
                        ? 0
                        : agentData?.data?.creditsBalance ?? "-"}
                    </strong>{" "}
                    credits left (Out of{" "}
                    <strong className="text-primary">
                      {agentData?.data?.creditsLimit ?? "-"}
                    </strong>
                    )
                  </p>
                ) : null}
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
          <BlockBetween className={"mt-3 justify-content-end flex-wrap gx-2"}>
            <ExplorerCommunityFilter
              multiSelect
              selected={queryState?.communities}
              axiosInstance={axiosInstance}
              dispatch={dispatch}
              onChangeCallback={callBacks.communityCallback}
            />
            <div
              className={
                screenSize.width >= ScreenBreakPoints.xxs &&
                screenSize.width < 400
                  ? "mt-2"
                  : "mt-0"
              }
            >
              <ExplorerPropTypesFilter
                multiSelect
                selected={queryState?.propertyTypes}
                axiosInstance={axiosInstance}
                dispatch={dispatch}
                onChangeCallback={callBacks.propertyTypeCallback}
              />
            </div>
            <div className={getBuildingFilterMargins(screenSize.width)}>
              <BuildingFilter
                multiSelect
                axiosInstance={axiosInstance}
                dispatch={dispatch}
                selected={queryState?.buildings}
                onChangeCallback={callBacks.buildingCallback}
                selectedCommunities={queryState?.communities ?? []}
                labelFormatString={"$COMMUNITY - $BUILDING"}
                wide
              />
            </div>
          </BlockBetween>
          <BlockBetween className="mt-2">
            <div>
            <Button
              className={`${hasSelectedItems ? "btn-shown" : "btn-hidden"}`}
              color="primary"
              onClick={(event) => {
                event.preventDefault();
                toggleBulkConfirmationModal();
              }}
            >
              Assign All ({selectedItems?.leads?.size} lead(s) selected)
            </Button>
            </div>        
            <div>          
            <Button
              key={`button-option`}
              className={`mx-2 border`}
              color={queryState?.newLeads===true ? "primary" : "white"}
              onClick={() => {
                setQueryState((prev) => ({
                  ...prev,
                  newLeads: !prev.newLeads,
                }));
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
                setIsOpen("0");
              }}
            >
              <div className="d-flex" style={{ gap: "0.5rem" }}>
              <small>
                      <Icon
                        name={
                          queryState.newLeads===true
                            ? "check-c"
                            : "square-c"
                        }
                      />
                    </small>
                New
              </div>
            </Button>
            </div>
          </BlockBetween>          
        </BlockHead>
        {/* ===================== MAIN CONTENT ===================== */}
        <Block>
          <div className="accordion">
            {(agentData && agentData?.data) || isAnyFilterSelected ? (
              <CardsTable isSeparate className="mb-2">
                <CardsTableHeader id="explorer-table-header">
                  {HEADER_FIELDS.map((header, idx) => (
                    <CardsTableColumn
                      key={`explorer-column-${idx}`}
                      breakpoint={header?.breakpoint}
                      isActions={header?.isActions}
                      isCheckbox={header?.isCheckbox}
                      className={
                        header?.isActions
                          ? "text-center"
                          : header?.first
                          ? "text-start"
                          : "text-center"
                      }
                    >
                      {renderHeader(header)}
                    </CardsTableColumn>
                  ))}
                </CardsTableHeader>

                {isInitialLoading
                  ? new Array(queryState?.limit).fill(0).map((_, idx1) => (
                      <CardsTableRow key={`skeleton-row-${idx1}`}>
                        {new Array(HEADER_FIELDS.length)
                          .fill(0)
                          .map((_, idx2) => (
                            <CardsTableColumn
                              key={`skeleton-col-${idx1}-${idx2}`}
                            >
                              <Skeleton />
                            </CardsTableColumn>
                          ))}
                      </CardsTableRow>
                    ))
                  : null}

                {data?.data?.map((item, idx) => (
                  <LeadCardTableItem
                    key={`table-item-${idx}`}
                    isOpen={isOpen}
                    leadInfo={item}
                    toggleCollapse={toggleCollapse}
                    index={(idx + 1).toString()}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    checkoutLeads={callBacks.checkoutLeads}
                    screenSize={screenSize}
                  />
                ))}
              </CardsTable>
            ) : null}
          </div>

          <Card>
            {isError ? (
              <div className="card-inner">
                <p className="text-center text-danger fs-6">
                  Failed to fetch data.
                  <br />
                  {errorVal?.response?.data?.message ?? null}
                </p>
              </div>
            ) : null}

            {(!agentData?.data && !isAnyFilterSelected) ? (
              <React.Fragment>
                <div className="mx-auto d-flex flex-column text-center" style={{ padding: '3rem' }}>
                  <span className="text-silent fw-bold fs-22px m-3">
                    Please select some filters!
                  </span>
                  <span
                    className="text-silent mb-3 pb-1 fs-16px"
                    style={{ maxWidth: "60ch" }}
                  >
                    Use our filters to discover hidden gems tailored just
                    for you.
                    <br />
                    <span className="fw-bold">
                      Start exploring and happy searching!
                    </span>
                  </span>
                  <ExplorerCommunityFilter
                    multiSelect
                    selected={queryState?.communities}
                    axiosInstance={axiosInstance}
                    dispatch={dispatch}
                    onChangeCallback={callBacks.communityCallback} 
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: getCommunityFilterWidth(screenSize?.width ?? 0),
                      }),
                      menu: (provided) => ({
                        ...provided,
                        minWidth: getCommunityFilterWidth(screenSize?.width ?? 0),
                      })
                    }}
                  />
                </div>
              </React.Fragment>
            ) : null}

            {(agentData?.data || isAnyFilterSelected) && !isInitialLoading && !isError ? (
              <div className="card-inner">
                <BlockBetween>
                  {data?.filteredCount ? (
                    <div className="g">
                      <PaginationComponent
                        currentPage={queryState?.page ?? 1}
                        itemPerPage={queryState?.limit}
                        paginate={(pageNum) => {
                          setQueryState((prev) => ({ ...prev, page: pageNum }));
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                          setIsOpen("0");
                          if (isEverythingSelected) {
                            setSelectedItems((_) => ({
                              leads: new Set(),
                              cards: new Set(),
                            }));
                          }
                        }}
                        totalItems={data?.filteredCount ?? 0}
                      />
                    </div>
                  ) : (
                    <p className="lead-text">No results found</p>
                  )}
                  {data?.filteredCount ? (
                    <div className="g">
                      <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                        <div>Page</div>
                        <div style={{ minWidth: "5rem" }}>
                          <RSelect
                            options={new Array(pageCount)
                              .fill(0)
                              .map((_, i) => ({
                                label: (i + 1).toString(),
                                value: i + 1,
                              }))}
                            value={{
                              label: queryState?.page,
                              value: queryState?.page,
                            }}
                            onChange={(val) => {
                              setQueryState((prev) => ({
                                ...prev,
                                page: val.value,
                              }));
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                              });
                              setIsOpen("0");
                            }}
                          />
                        </div>
                        <div>of {pageCount}</div>
                      </div>
                    </div>
                  ) : null}
                </BlockBetween>
              </div>
            ) : null}
          </Card>
        </Block>
        {/* ===================== MAIN CONTENT ===================== */}
        <ConfirmationModal
          isOpen={isBulkConfirmModalOpen}
          toggleFn={toggleBulkConfirmationModal}
          title={"Confirm Assignment"}
          loadingState={callBacks?.checkoutLeads?.status === "loading"}
          size="md"
          dimButtons
          actionFn={checkoutManyLeads}
        >
          <p className="fs-6">
            You have selected {selectedItems?.leads?.size} Lead(s) along with{" "}
            {selectedItems?.cards?.size} possible Property Card(s).
          </p>
          <p className="fs-6">
            Are you sure you want to assign these to yourself?
          </p>
        </ConfirmationModal>
      </Content>
    </React.Fragment>
  );
};


/**
 * @typedef {Object} ScreenSize
 * @property {number} width - The width of the screen.
 * @property {number} height - The height of the screen.
 */

/**
 * @typedef {Object} LeadCardTableItemProps
 * @property {DataItem} leadInfo - One lead's info, property card mappings included.
 * @property {string} isOpen - Signifies whether the row is open or collapsed
 * @property {function(string): void} [toggleCollapse] - A function to toggle the collapse state.
 * @property {string} [index] - The index of the row.
 * @property {SelectedState} selectedItems - The currently selected items (leads and property cards).
 * @property {function(Partial<SelectedState>): void} setSelectedItems - A function to update the selected items.
 * @property {MutationQueryResult} checkoutLeads - Mutation object to use when assigning leads.
 * @property {ScreenSize} [screenSize] - The index of the row.
 * /


/**
 * LeadCardTableItem Component
 * @param {LeadCardTableItemProps} props - The props for the ExplorerLeadsView component.
 * @returns {JSX.Element} The rendered ExplorerLeadsView component.
 */
const LeadCardTableItem = ({
  leadInfo,
  isOpen,
  toggleCollapse = (val) => undefined,
  index = "1",
  selectedItems,
  setSelectedItems,
  checkoutLeads,
  screenSize,
}) => {
  const authUser = useSelector((state) => state?.user?.loggedInUser);
  const isAdmin =
    authUser &&
    (authUser.roles?.includes("MasterAdmin") ||
      authUser.roles?.includes("Admin"));

  const authAgent = useSelector((state) => state?.user?.loggedInAgent);
  
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const toggleConfirmationModal = () => setIsConfirmModalOpen(!isConfirmModalOpen);  

  const checkoutLead = async () => {
    const payload = {
      leads: [leadInfo?.lead?._id ?? leadInfo?.lead?.id],
    }
    console.log(leadInfo);
    checkoutLeads && checkoutLeads?.mutate(payload);
    toggleCollapse('0'); // close the accordion 
    toggleConfirmationModal();
  };

  return (
    <React.Fragment key={`key-${index}-${(leadInfo?.lead?._id ?? leadInfo?.lead?.id)}`}>
      <CardsTableRow
        isHover
        onClick={() => toggleCollapse(index)}
        className={[
          `accordion-head${
            isOpen !== index ? " collapsed" : ""
          } cursor-pointer`,
        ]}
      >
        <CardsTableColumn key={`checkbox-${index}`}>
          <TableCheckbox
            name={`checkbox-${leadInfo?._id}`}
            id={leadInfo?._id}
            size={"sm"}
            className="d-flex"
            checked={selectedItems?.leads?.has((leadInfo?.lead?._id ?? leadInfo?.lead?.id)?.toString())}
            onChange={(event) => {
              event.stopPropagation();
              let newSelectedItems = selectedItems;

              if (newSelectedItems?.leads?.has((leadInfo?.lead?._id ?? leadInfo?.lead?.id)?.toString())) {
                // remove lead and all of its property cards
                newSelectedItems?.leads?.delete((leadInfo?.lead?._id ?? leadInfo?.lead?.id)?.toString());
                leadInfo?.mappings
                  ?.map(item => (item?.propertyCard?.id ?? item?.propertyCard?._id)?.toString())
                  ?.forEach(newSelectedItems?.cards?.delete, newSelectedItems?.cards);
              } else {
                newSelectedItems?.leads?.add((leadInfo?.lead?._id ?? leadInfo?.lead?.id)?.toString());
                // add all property cards of the lead
                leadInfo?.mappings
                  ?.map(item => (item?.propertyCard?.id ?? item?.propertyCard?._id)?.toString())
                  ?.forEach(newSelectedItems?.cards?.add, newSelectedItems?.cards);
              }
              setSelectedItems(_ => newSelectedItems);
            }}
          />
        </CardsTableColumn>
        <CardsTableColumn
          key={`lead-name-${index}`}
          style={{ minWidth: getNameColumnMinWidth(screenSize?.width ?? 0) }}
          className="align-self-start"
        >
          {isAdmin ? (
            <Link
            to={`/user-profile/${leadInfo?.lead?.id}/user-profile-setting`}
            className="d-flex align-items-center"
          >                                  
            <span className="ml-2">
            {leadInfo?.lead?.fullName}
            </span>
            <NewLeadFlagComponent
              className="d-inline-flex align-items-center justify-content-center p-2" 
              lead_created={leadInfo?.lead?.createdAt} 
              lead_status={leadInfo?.lead?.lead_status}
              lead_id={leadInfo?.lead?.id}
            />
          </Link>
          ) : (
            <>            
            <span>{leadInfo?.lead?.fullName}</span>
            <NewLeadFlagComponent
              className="d-inline-flex align-items-center justify-content-center p-2" 
              lead_created={leadInfo?.lead?.createdAt} 
              lead_status={leadInfo?.lead?.lead_status}
              lead_id={leadInfo?.lead?.id}
            />
            </>            
          )}
        </CardsTableColumn>
        <CardsTableColumn
          key={`lead-status-${index}`}
          style={{ minHeight: "4.68rem" }}
          className="text-center d-lg-flex justify-content-lg-center align-items-lg-center"
        >
          <StatusBadge
            style={{ width: "5rem" }}
            status={leadInfo?.lead?.lead_status ?? ""}
            color={STATUS_COLORS[leadInfo?.lead?.lead_status] ?? "primary"}
          />
        </CardsTableColumn>
        <CardsTableColumn className="text-center" breakpoint={"md"}>
          <span>
            {leadInfo?.lead?.lead_source && leadInfo?.lead?.lead_source.length
              ? leadInfo?.lead?.lead_source
              : "-"}
          </span>
        </CardsTableColumn>
        <CardsTableColumn className="text-center" breakpoint={"md"} key={`createdAt-${index}`}>
          {leadInfo?.lead?.createdAt
            ? format(new Date(leadInfo?.lead?.createdAt), "dd/MM/yyyy hh:mm a")
            : "-"}
        </CardsTableColumn>
        <CardsTableColumn className="text-center" breakpoint={"md"} key={`total-cards-${index}`}>
          {leadInfo?.mappings?.length}
        </CardsTableColumn>
        <CardsTableColumn className="text-end" key={`actions-${index}`}>
          {/* <Icon name={"more-h"} /> */}
          <Button
            color={isAdmin ? "light" : "primary"}
            size="sm"
            style={{ width: "6.5rem" }}
            className="btn btn-dim me-1"
            disabled={isAdmin || checkoutLeads?.status === 'loading'}
            onClick={(e) => {
              e.stopPropagation();
              // setIsConfirmModalOpen(true);
              toggleConfirmationModal();
            }}
          >
            {checkoutLeads?.status === 'loading' ? "Loading..." : "Assign to me"}
          </Button>
        </CardsTableColumn>
      </CardsTableRow>

      <CardsTableRow className={isOpen !== index ? "d-none" : ""}>
        <CardsTableColumn className="px-4 position-relative">
          <div
            className="border border-primary ms-1 position-absolute"
            style={{
              height: getCollapseLineHeight(leadInfo?.mappings?.length ?? 0),
              width: ".1rem",
              top: ".5rem",
            }}
          ></div>
        </CardsTableColumn>

        {new Array(3).fill(0).map((item, i) => {
          const mapping = leadInfo?.mappings[i];

          return mapping ? (
            <React.Fragment key={`mapping-${index}-${i}`}>
              <Collapse
                className="accordion-body"
                isOpen={isOpen === index ? true : false}
              >
                <CardsTableRow
                  key={mapping?.lead + mapping?.propertyCard?.id + i}
                >
                  <CardsTableColumn className="px-2">
                    {isAdmin ||
                    (authAgent &&
                      (authAgent?.id ?? authAgent?._id)?.toString() ===
                        mapping?.propertyCard?.currentAgent?.toString()) ||
                    !mapping?.propertyCard?.currentAgent ? (
                      <Link
                        to={`/property-detail/${
                          mapping?.propertyCard?.id ??
                          mapping?.propertyCard?._id
                        }`}
                      >
                        {mapping?.propertyCard?.referenceNumber ?? "-"}
                      </Link>
                    ) : (
                      <span>
                        {mapping?.propertyCard?.referenceNumber ?? "-"}
                      </span>
                    )}
                  </CardsTableColumn>
                  <CardsTableColumn className="px-2">
                    <span>
                      {mapping?.propertyCard?.propertyType ??
                        "Unknown Property Type"}
                    </span>
                  </CardsTableColumn>
                  <CardsTableColumn
                    className={
                      "px-2 text-center " +
                      (screenSize?.width < ScreenBreakPoints.xxl
                        ? "text-truncate"
                        : "")
                    }
                    style={{
                      maxWidth:
                        screenSize?.width < ScreenBreakPoints.xxl
                          ? "18ch"
                          : "40ch",
                      display: "inline-block",
                    }}
                  >
                    <span>
                      {mapping?.propertyCard?.community?.community ??
                        "Unknown Community"}
                    </span>
                    {", "}
                    <span className="">
                      {mapping?.propertyCard?.building?.name ??
                        "Unknown Building"}
                    </span>
                  </CardsTableColumn>
                </CardsTableRow>
              </Collapse>
            </React.Fragment>
          ) : null;
        })}

        <CardsTableRow>
          <CardsTableColumn>
            <Collapse
              className="accordion-body"
              isOpen={isOpen === index ? true : false}
            >
              {isAdmin ? (
                <Link
                  to={`/user-profile/${leadInfo?.lead?.id}/user-profile-setting`}
                >
                  View More (
                  {leadInfo?.mappings?.length > 3
                    ? leadInfo?.mappings?.length - 3
                    : 0}{" "}
                  Cards left)
                </Link>
              ) : (
                <span>
                  {leadInfo?.mappings?.length > 3
                    ? leadInfo?.mappings?.length - 3
                    : 0}{" "}
                  Cards left
                </span>
              )}
            </Collapse>
          </CardsTableColumn>
        </CardsTableRow>
      </CardsTableRow>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        toggleFn={toggleConfirmationModal}
        title={"Confirm Assignment"}
        loadingState={checkoutLeads?.status === 'loading'}
        size="md"
        actionFn={checkoutLead} 
      >
        <p>Are you sure you want to assign this Lead to yourself?</p>
      </ConfirmationModal>
    </React.Fragment>
  );
};

export default ExplorerLeadsView;
