import React, { useRef } from 'react';
import {
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  TooltipComponent
} from '../../../components/Component';
import { Button, Card } from 'reactstrap';

const PropertyListInfo = ({ property }) => {

const permitNumberRef=useRef()

const copyPermitNumber=async(text)=>{
try {
  await navigator.clipboard.writeText(text)
} catch (error) {
  console.log(error);
}
}

  return (
    <>
      <BlockHead>
        <BlockHeadContent>
          <BlockTitle tag="h5">Property Info</BlockTitle>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered">
        <ul className="data-list is-compact">
          <li className="data-item">
            <div className="data-col">
              <div className="data-label">Permit Number ffwfw(trakheesi)</div>
              <div className="data-value">
              {property?.permitNo} 
              </div>
            </div>
          </li>
          <li className="data-item">
            <div className="data-col">
              <div className="data-label">Permit Number (DTCM)</div>
              <div className="data-value">{property?.permitNoDTCM}</div>
            </div>
          </li>
          <li className="data-item">
            <div className="data-col">
              <div className="data-label">Property Owner</div>
              {`${property?.propertyOwner?.first_name} ${property?.propertyOwner?.last_name}`}
            </div>
          </li>
          <li className="data-item">
            <div className="data-col">
              <div className="data-label">Category</div>
              {property?.category?.category}
            </div>
          </li>
          <li className="data-item">
            <div className="data-col">
              <div className="data-label">Unit Status</div>
              <div className="data-value">{property?.unitStatus}</div>
            </div>
          </li>
          <li className="data-item">
            <div className="data-col">
              <div className="data-label">Completion Date</div>
              <div className="data-value">{property?.completionDate}</div>
            </div>
          </li>
        </ul>
      </Card>
    </>
  );
};

export default PropertyListInfo;
