import { useState } from "react";
import { Button, Card, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const RenderAmenitiesModal = ({
    amenities,
    onContinue,
    selectedOptions,
    openAmenitiesModal,
    setErrorFn,
    toggleAmentiesModal,
  }) => {
    const [selectedItems, setSelectedItems] = useState(selectedOptions ?? []);
    return (
      <Card>
        <Modal
          isOpen={openAmenitiesModal}
          toggle={toggleAmentiesModal}
          backdrop="static"
          size="lg"
        >
          <ModalHeader toggle={toggleAmentiesModal}>
            <h6 className="title">Choose Amenities</h6>
          </ModalHeader>
          <ModalBody className="p-4">
            <div className="d-flex flex-wrap w-100">
              {amenities?.length
                ? amenities?.map((amenity) => (
                    <Button
                      key={`amenity-${amenity?.id}`}
                      onClick={(e) => {
                        const selected = selectedItems?.find(
                          (x) => x?.id === amenity?.id
                        );
                        if (selected) {
                          setSelectedItems((prev) =>
                            prev.filter((x) => x?.id !== selected?.id)
                          );
                        } else {
                          setSelectedItems((prev) => [...prev, amenity]);
                        }
                        setErrorFn((prev) => ({
                          ...prev,
                          amenities: null
                        }));
                      }}
                      className={`m-2 bg-transparent p-1 px-2 ${
                        amenity.id ===
                        selectedItems?.find((c) => c?.value === amenity?.id)
                          ?.value
                          ? "text-primary border-primary"
                          : " text-secondary border-secondary"
                      } rounded-5 fw-light`}
                    >
                      {amenity?.label}
                    </Button>
                  ))
                : "No Amenities"}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={(e) => onContinue(selectedItems)}
              className="bg-primary"
            >
              Continue
            </Button>
          </ModalFooter>
        </Modal>
      </Card>
    );
  };
  export default RenderAmenitiesModal