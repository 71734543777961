import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { fetchBuildings } from "../../../redux/actions/property";
import { RSelect } from "../../../components/Component";

const formatBuildingName = (building, formatString = '') => {
  const replacement = {
    '$COMMUNITY': building?.community?.community ?? '',
    '$BUILDING': building?.name ?? '',
  };

  return formatString.replace(/\$COMMUNITY/g, replacement['$COMMUNITY']).replace(/\$BUILDING/g, replacement['$BUILDING']);
}

const BuildingDropdown = ({ multiSelect, onChangeCallback, isDisabled, selected, selectedCommunities, axiosInstance, dispatch, wide, labelFormatString, ...props }) => {
  const { buildings, buildingsLoading } = useSelector((state) => ({
    buildings: state.property?.buildings,
    buildingsLoading: state.property?.buildingsLoading,
  }));
    
  const [buildingFilterOptions, setBuildingFilterOptions] = useState([]);

  useEffect(() => {
    const fetchAndSetBuildings = async () => {
      !buildings?.length && await dispatch(fetchBuildings(axiosInstance));
      
      let buildingSet = [];
      if (!selectedCommunities?.length && buildings?.length) {
        buildingSet = buildings;
      } else {
        const selectedCommunityIds = selectedCommunities?.map(
        (community) => community?.value
        );
    
        buildingSet = buildings?.filter(
        (building) =>
            building?.communityId != null &&
            selectedCommunityIds?.includes(building?.communityId)
        ) ?? [];
      }

      setBuildingFilterOptions(
        Object.values(buildingSet).map((item) => ({
          ...item,
          label: labelFormatString ? formatBuildingName(item, labelFormatString) : `${item?.name} - ${item?.community?.community}` ,
          value: item?.id,
        }))
      );
    };
    
    fetchAndSetBuildings();
  }, [axiosInstance, buildings, dispatch, selectedCommunities, labelFormatString]);

  return (
    <RSelect
      placeholder="Building"
      isDisabled={isDisabled}
      className={`status-div ${wide ? 'building-width-custom' : ''}`}
      isMulti={multiSelect}
      isLoading={buildingsLoading}
      loadingMessage={() => <Spinner />}
      value={selected}
      options={buildingFilterOptions}
      onChange={(selected) => onChangeCallback(selected) }
      styles={{
        option: (provided) => ({
          ...provided,
          whiteSpace: "normal",
          "&:hover": {
            whiteSpace: "normal",
          },
        }),
        menu: (provided) => ({
          ...provided,
          whiteSpace: "normal",
        }),
      }}
      {...props}
    />
  );
};

/**
 * BuildingFilter component for selecting buildings.
 *
 * @param {boolean} props.multiSelect - If true, multiple selections are allowed.
 * @param {function} props.onChangeCallback - Callback function when selection changes.
 * @param {Array} props.selected - The currently selected buildings.
 * @param {Array} props.selectedCommunities - The selected communities to filter buildings.
 * @param {Object} props.axiosInstance - Axios instance for making requests.
 * @param {function} props.dispatch - Redux dispatch function.
 * @param {boolean} props.wide - If true, applies wide styling to the dropdown.
 * @param {string} props.labelFormatString - Format string for building labels, uses $COMMUNITY and $BUILDING for fromatting.
 * @returns {JSX.Element} The BuildingDropdown component.
 */
const BuildingFilter = React.memo(BuildingDropdown);
export default BuildingFilter;