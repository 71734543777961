import React, { useState, useEffect } from "react";
import {
  Col,
  Button,
  Modal,
  ModalBody,
  Input,
  Spinner
} from "reactstrap";
import { Icon, RSelect } from "../../../../components/Component";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import {
  fetchBuildings,
  fetchBuildingsList,
  fetchCommunitiesList,
  fetchPropTypesList,
} from "../../../../redux/actions/property";
import { HttpStatus, newRoles } from "../../../../utils/envConfig";
import { showToast } from "../../../../utils/toast/toast";
import { fetchAgentsList } from "../../../../redux/actions/user";
import BuildingCreationModal from "../../../explorer/components/modals/BuildingCreationModal";
// we need a regex that specifies that string must not start or end with - or _ and must not contain any other special characters apart from - and _ and must not contain any spaces and we cannot have more than one - or _ in a row
const unitIdRegex = /^[a-zA-Z0-9]+([_-]?[a-zA-Z0-9])*$/;

const propertyTypesOptions = [
  { label: "Building", value: "Building" },
  { label: "Store", value: "Store" },
  { label: "Townhouse", value: "Townhouse" },
  { label: "Warehouse", value: "Warehouse" },
  { label: "Office", value: "Office" },
  { label: "Villa", value: "Villa" },
  { label: "Apartment", value: "Apartment" },
  { label: "Plot", value: "Plot" },
];

const PropCardCreateModal = ({ lead, isOpen, toggleFn, toggleConfirmFn, refreshFn }) => {
  const { errors, register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();

  // get the lead's currently assigned agent
  const assignedAgent = lead?.currentAgent;

  // State
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [communityOptions, setCommunityOptions] = useState([]);
  const [agentsOptions, setAgentsOptions] = useState([]);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(
    assignedAgent
      ? {
          label: `${assignedAgent?.userId?.first_name} ${assignedAgent?.userId?.last_name ?? ""}`,
          value: assignedAgent?.id ?? assignedAgent?._id,
          ...assignedAgent,
        }
      : null
  );

  const [unitId, setUnitId] = useState("");
  const [size, setSize] = useState(0);
  const [beds, setBeds] = useState("0");
  const [baths, setBaths] = useState(0);
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [creationLoading, setCreationLoading] = useState(false);

  const authUser = useSelector((state) => state?.user?.loggedInUser);
  const authAgent = useSelector((state) => state?.user?.loggedInAgent);

  const isAdmin =
    authUser.role === newRoles.Admin || authUser.role === newRoles.MasterAdmin;

  const submitForm = async ( data ) =>
  {
    
    data = {
      ...data,
      beds: data.beds,
      baths: parseInt(data.baths),
      size: parseInt(data.size),
      propertyType: selectedPropertyType,
      building: selectedBuilding ? selectedBuilding.value : null,
      community: selectedCommunity,
      currentAgent: (authAgent?.id ?? authAgent?._id) ?? selectedAgent?.value
    };

    try {
      const existsPayload = {
        unitId: data.unitId,
        buildingId: data.building,
        communityId: data.community,
      };
      const existsRes = data.unitId ? await axios.post(`/property-cards/property-exists`, existsPayload) : null ;
      if (existsRes && existsRes?.data?.status === "exist") {
        const assignedAgent = existsRes?.data?.data?.currentAgent;
        if (assignedAgent) { // && assignedAgent.toString() !== authAgent?.id?.toString()
            toggleFn();
            toggleConfirmFn(data, existsRes.data?.data); // toggle confirmation popup, property card exists and is assigned to a different agent
            return;
        }
      }
      setCreationLoading(true);
      const res = await axios.post(`/property-cards`, data);
      if (res?.status === HttpStatus.OK) {
        // if successfully created, link to this lead
        const createdCardId = res?.data?.data?.id;
        const payload = {
          leadId: lead?.id,
          propertyCardId: createdCardId,
          isCurrentOwner: true
        }
        // eslint-disable-next-line no-unused-vars
        const linkRes = await axios.post(`/property-cards/lead-to-card`, payload);
      }
      showToast("Successfully created Property Card!", "success");
      setCreationLoading(false);
      toggleFn();
      refreshFn();
    }
    
    catch ( error )
    {
      console.log(error);
      const serverErrorMessage = error?.response?.data?.message;
      if (serverErrorMessage) {
        showToast(serverErrorMessage, "error");
      } else {
        showToast("Failed to create Property Card, an error occurred.", "error");
      }
      setCreationLoading(false);
    }
  };


  const { allCommunities, communitiesLoading } = useSelector((state) => ({
    allCommunities: state?.property?.communities,
    communitiesLoading: state?.property?.communitiesLoading
  }));
  const { allBuildings, buildingsLoading } = useSelector((state) => ({
    allBuildings: state?.property?.buildings,
    buildingsLoading: state?.property?.buildingsLoading,
  }));
  const allAgents = useSelector((state) => state?.user?.agents);
  const allPropertyTypes = useSelector((state) => state?.property?.propertyTypes);

  useEffect(() => {
    dispatch(fetchCommunitiesList(axios));
    dispatch(fetchPropTypesList(axios));
    dispatch(fetchBuildings(axios));
    dispatch(fetchAgentsList(axios));
  }, [axios, dispatch]);

  useEffect(() => {
    if (allAgents?.length) {
      setAgentsOptions(
        allAgents?.map((agent) => ({
          label: `${agent?.user?.first_name} ${agent?.user?.last_name ?? ''}`,
          value: agent?.id ?? agent?._id,
          ...agent
        }))
      )
    }

    if (allPropertyTypes && allPropertyTypes.length) {
      setPropertyTypeOptions(
        allPropertyTypes?.map((type) => ({
          ...type,
          label: type?.propertyType,
          value: type?.id ?? type?._id,
        }))
      );
    }

    setCommunityOptions(
      allCommunities?.map((community) => ({
        label: community?.community,
        value: community?.id,
      }))
    );

    if (selectedCommunity && !buildingsLoading) {
      setBuildingOptions(
        allBuildings
          ?.filter(
            (building) =>
              building?.communityId &&
              building?.communityId === selectedCommunity
          )
          ?.map((building) => ({
            label: building?.name,
            value: building?.id,
          }))
      );
    } else {
      !buildingsLoading && setBuildingOptions(
        allBuildings?.map((building) => ({
          label: building?.name,
          value: building?.id,
        }))
      );
    }

  }, [allBuildings, allCommunities, selectedCommunity, allAgents, allPropertyTypes, buildingsLoading])

  const [isBuildingModalOpen, setIsBuildingModalOpen] = useState(false);
  const toggleBuildingModal = () => setIsBuildingModalOpen(!isBuildingModalOpen);

  const buildingCreationCallback = async (newBuilding) => {
    dispatch(fetchBuildings(axios));
    setSelectedBuilding({ label: newBuilding?.name, value: newBuilding?.id });
  };

  return (
    <>
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={toggleFn}
      onClick={(e) => e.stopPropagation()}
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggleFn();
          }}
          className="close mt-2"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Add Property Card for this Lead</h5>
            <div className="mt-4">
              
              <form className="row gy-4" onSubmit={handleSubmit(submitForm)}>
              <Col sm="12">
                <div className="form-group">
                  <label className="form-label">Property Type *</label>
                  <RSelect
                    options={propertyTypeOptions}
                    defaultValue={selectedPropertyType}
                    onChange={(e) => {
                      setSelectedPropertyType(e.label);
                    }}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Community *</label>
                  <RSelect
                    options={communityOptions}
                    isLoading={communitiesLoading}
              
                    loadingMessage={() => <Spinner />}
                    defaultValue={selectedCommunity}
                    onChange={(e) => {
                      setSelectedCommunity(e.value);
                    }}
                  />
                </div>
              </Col>
              {isAdmin || !authAgent ? <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Assigned Agent *</label>
                  <RSelect
                    options={agentsOptions}
                    isDisabled={assignedAgent}
                    defaultValue={selectedAgent}
                    onChange={(e) => {
                      setSelectedAgent(e);
                    }}
                  />
                </div>
              </Col> : null}
              <Col sm="12" className="">
                  <div className="form-group">
                    <label className="form-label">Building</label>
                    <div
                      className="d-flex justify-content-between"
                      style={{ gap: "0.5rem" }}
                    >
                      <div className="flex-fill">
                        <RSelect
                          options={buildingOptions}
                          placeholder="Select Building..."
                          isLoading={buildingsLoading}
                          loadingMessage={() => <Spinner />}
                          value={selectedBuilding}
                          onChange={(e) => setSelectedBuilding(e.value ? { label: e.label, value: e.value } : null)}
                        />
                      </div>
                      <Button className="" onClick={toggleBuildingModal}>
                        <Icon name={"plus"} />
                      </Button>
                    </div>
                  </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Unit ID</label>
                  <Input
                    className={`${errors?.unitId && `border border-danger`}`}
                    name="unitId"
                    placeholder="Unit ID"
                    value={unitId}
                    onChange={(e) => {
                      setUnitId(e.target.value);
                    }}
                    innerRef={register}
                  />
                </div>
                {errors.unitId && (
                  <small className="invalid text-danger">
                    {errors.unitId.message}
                  </small>
                )}
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Size (SQFT.)</label>
                  <Input
                    type="number"
                    className={`${errors?.size && `border border-danger`}`}
                    name="size"
                    placeholder="Size"
                    value={size}
                    onChange={(e) => {
                      setSize(e.target.value);
                    }}
                    innerRef={register()}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Number of Beds</label>
                  <Input
                    type="text"
                    className={`${errors?.beds && `border border-danger`}`}
                    name="beds"
                    placeholder="Beds"
                    value={beds}
                    onChange={(e) => {
                      setBeds(e.target.value);
                    }}
                    innerRef={register()}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Number of Baths</label>
                  <Input
                    type="number"
                    className={`${errors?.baths && `border border-danger`}`}
                    name="baths"
                    placeholder="Baths"
                    value={baths}
                    onChange={(e) => {
                      setBaths(e.target.value);
                    }}
                    innerRef={register()}
                  />
                </div>
              </Col>
              <Col sm="12" style={{ display: "flex", justifyContent: "end" }}>
                <Button type="submit" color="primary" disabled={creationLoading}>
                  {creationLoading ? <Spinner color="light" size="sm">Loading...</Spinner> : "Add Property Card"}
                </Button>
              </Col>
              </form>
              
          </div>
        </div>
      </ModalBody>
    </Modal>
    <BuildingCreationModal
    isOpen={isBuildingModalOpen}
    toggleFn={toggleBuildingModal}
    onBuildingCreated={buildingCreationCallback}
  />
  </>
  );
};

export default PropCardCreateModal;
