import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ListingType,
  UnitStatusBuy,
  UnitStatusRent,
  UnitStatusLease,
  UnitStatusSell,
  newRoles,
  serverAPI,
  Emirates,
  Agent,
  validateEmail,
  NewBedRooms,
  NewBathRooms,
  FurnishingTypes,
  ContractValidity,
  newListingType,
  propertyTypesExcludeBedsBaths,
  ResidentialBedRooms,
  CategoryTitle,
  ValidCategories,
  propertyTypesExcludeBuilding
} from '../../../../utils/envConfig';
import he from 'he';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Icon,
  Row,
  Col,
  RSelect,
} from '../../../../components/Component';
import { TagsInput } from 'react-tag-input-component';
import { QuillComponentNew } from '../../../../components/partials/rich-editor/QuillComponent';
import { fetchUsers,fetchAgents } from '../../../../redux/actions/user';

import {Input, Label, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useLoadScript } from '@react-google-maps/api';
import {  fetchCommunitiesList } from '../../../../redux/actions/property';
import Map from '../../../../components/miscellaneous/Map';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SearchClientSelect from '../../../../components/SearchClientSelect';
import {conditionsOptions} from '../../../../constants/propertyListConditions'

const StepOne = (props) => {
  let { handleFormData, values, setFormData, lockType } = props;
  
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const users = useSelector((state) => state.user?.users);
  const community = useSelector((state) => state.property?.communities);
  const agents = useSelector((state) => state.user?.agents);

  const { access_token } =
  useSelector( ( state ) => state?.user?.loggedInUser ) ?? "";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ access_token }`,
  };

  // State Variables
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);
  const [type, setType] = useState([]);
  const [category, setCategory] = useState([]);
  const [tags, setTags] = React.useState(values.metaTags);
  const [optUsers, setOptUsers] = useState([]);
  const [optDefaultUser, setOptDefaultUser] = useState(null);
  const [optDefaultlType, setoptDefaultlType] = useState(null);
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [inputValue, setInputValue] = useState('');

  const [optDefaultCommunity, setOptDefaultCommunity] = useState();
  const [mak, setMak] = useState(values.makaniNo);
  const [pos, setPos] = useState({
    lat: values.lat ? Number(values.lat) : 25.1183446,
    lng: values.lng ? Number(values.lng) : 55.2073704,
  });
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState();
  const [errorX, setErrorX] = useState(null);

  const [selected, setSelected] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [amenityOptions, setAmenityOptions] = useState([]);
  const [amenityFields, setAmenityFields] = useState(values.amenityObjs);
  const [amenityFieldIds, setAmenityFieldIds] = useState(values.amenityIds);
  const [amenityFilters, setAmenityFilters] = useState([]);
  const [amenities, setAmenities] = useState([]);

  const prevAmenityFields = usePrevious(amenityFields);
  const [inputFields, setInputFields] = useState(values.customField);
  const [videosLink, setVideosLink] = useState(values.videos);
  const [mLinks, setMLinks] = useState(values.matterportLink);

  const [checkPropertyType, setCheckPropertyType] = useState(null);

  const apiKey = process.env.REACT_APP_MAP_KEY;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
  });

  const mapStyles = {
    height: '475px',
    width: '100%',
  };

  const filteredListingType = ListingType.filter(
    (type) => type !== 'Sell' && type !== 'Lease'
  );

  const toggleModal = () => {
    setOpen(!open);
  };

  const typeOptions=type?.filter(x=>x?.categoryId===values?.categoryId)?.map(x=>({
    ...x,
    label: x.propertyType,
    value: x.id
  }))
  // Input Validation
  const validate = (firstName, lastName, email) => {
    let isError = false;
    if (firstName.length < 2) {
      toast.error('Enter valid First Name');
      isError = true;
    }

    if (lastName.length < 2) {
      toast.error('Enter valid Last Name');
      isError = true;
    }

    if (!validateEmail(email)) {
      isError = true;
      toast.error('Enter valid email address');
    }

    if (!value || !value.trim()) {
      isError = true;
      toast.error('Enter valid phone number');
    }

    const isPossiblePhone = isPossiblePhoneNumber(value?.trim(), 'AE');

    if (isPossiblePhone) {
      isError = true;
      const isValidNum = isValidPhoneNumber(value?.trim(), 'AE');

      if (isValidNum) {
        isError = false;
      }
    } else {
      isError = true;
      toast.error('Phone number is not possible');
    }
    return isError ? false : true;
  };
  // Add User Info in DB
  const addMember = async (e) => {
    e.preventDefault();
    if (validate(firstName, lastName, email, value)) {
      const formData = {
          first_name: firstName,
          last_name: lastName,
          email,
          phone: value?.trim()?.replaceAll(' ', ''),
          role: newRoles.Owner,
          generate_password: true
      };
      try {
        setLoading(true);
        await axios
          .post(`/users/createOwnerWithRole`, formData)
          .then((user) => {
            setOptDefaultUser({
              value: user.data.id,
              label: user.data.first_name + ' ' + user.data.last_name,
            });
            setInputValue(user.data.first_name + ' ' + user.data.last_name);
            setFormData((prevState) => ({
              ...prevState,
              property_owner_id: user.data.id,
              propertyOwner: user.data,
            }));
            closeModal();
            toggleModal();
            setLoading(false);
          })
          .catch((err) => {
            if (err.response.data.message.includes('duplicate key')) {
              // DONT CHANGE MESSAGE, THE SAME REFERED FROM BACKEND
              toast.error('User not added. Email already exist !');
            } else {
              toast.error(err.response.data.message);
            }
            setLoading(false);
          });
      } catch (err) {
        // console.log(err)
        toast.error('Server error. Try Again !');
      }
    }
  };

  function limit(string = '', limit = 0) {
    return string.substring(0, limit);
  }

  const handleOption = (value) => {
    if (!value?.id) {
      return;
    }

    setOptDefaultUser(value);

    setFormData((prevState) => ({
      ...prevState,
      property_owner_id: value?.id,
      propertyOwner: value,
    }));
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(fetchUsers(axios));
    dispatch(fetchAgents(axios));
  }, [dispatch]);

  useEffect(() => {
    if (amenities && amenities.length > 0) {
      let fil2 = values?.amenityIds
        .map((ele) => {
          const foundAmenity = amenities.find((am) => am.id === ele);
          if (foundAmenity) {
            return {
              label: foundAmenity.amenity,
              value: foundAmenity.id,
            };
          }
          return null;
        })
        .filter(Boolean);

      setSelectedAmenities(fil2);
    }
  }, [values.amenityIds, amenities]);

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const addAmenityListFields = (values) => {
    if (values !== undefined) {
      let newfield = [];
      values.map((v) => {
        if (prevAmenityFields.some((am) => v.value === am.amenityId)) {
          let xyz = prevAmenityFields.filter((am) => v.value === am.amenityId);
          newfield.push({
            amenityId: xyz[0].amenityId,
            priority: xyz[0].priority,
            quantity: xyz[0].quantity,
          });
        } else {
          newfield.push({ amenityId: v.value, priority: '0', quantity: '0' });
        }
      });

      if (values?.length < 5) {
        setError(true);
        toast.error('Please Add at least 5 amenities');
      } else {
        setAmenityFieldIds(values.map((v) => v.value));
        setAmenityFields(newfield);
      }
    }
  };
 
  const handleQuantity = (index, event, input) => {
    setAmenityFields((prevAmenityFields) => {
      const newData = [...prevAmenityFields];
      const matchingItem = newData.find((am) => am.amenityId === input);
      if (matchingItem) {
        matchingItem.quantity = event.target.value;
      }
      return newData;
    });
  };

  const addFields = (e) => {
    e.preventDefault();
    let newfield = { label: '', value: '' };
    setInputFields([...inputFields, newfield]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      customField: inputFields,
    }));
  }, [inputFields]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      amenityObjs: amenityFields,
    }));
  }, [amenityFields]);

  useEffect(() => {
    if (amenityFilters) {
      setSelected(amenityFilters);
    }
  }, [amenityFilters]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      amenityIds: amenityFieldIds,
    }));
  }, [amenityFieldIds]);

  useEffect(() => {
    async function fetchAmenity() {
      try {
        await axios
          .get(`${serverAPI}/amenity`)
          .then((amenities) => {
            let options = [];
            amenities.data.forEach((ele) =>
              options.push({ label: ele.amenity, value: ele.id })
            );
            setAmenityOptions(options);
            let fil = [];
            amenities.data.forEach((ele) =>
              values.amenityIds.map((am) =>
                am === ele.id
                  ? fil.push({
                      label: ele.amenity,
                      value: ele.id,
                    })
                  : null
              )
            );
            setAmenityFilters(fil);
            setAmenityFieldIds(values.amenityIds);
            setAmenities(amenities.data);
          })
          .catch((err) => {
            toast.error('Amenity not available !');
          });
      } catch (err) {
        toast.error('Server error. Try Again !');
      }
    }
    fetchAmenity();
    initializeMap();
  }, []);

  useEffect(() => {
    if (optDefaultCommunity) {
      geocodeAddress(optDefaultCommunity.label);
    }
  }, [optDefaultCommunity]);

  const initializeMap = () => {
    if (optDefaultCommunity) {
      geocodeAddress(optDefaultCommunity.label);
    }
  };

  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const location = results[0].geometry.location;
        setPos({ lat: location.lat(), lng: location.lng() });
      } 
    });
  };
  const handleOptionMap = (selectedOption) => {
    setOptDefaultCommunity(selectedOption);

    if (selectedOption) {
      const geocoder = new window.google.maps.Geocoder();
      const address = `${selectedOption.label}, Dubai, Dubai`;

      geocoder.geocode({ address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const location = results[0].geometry.location;
          const latitude = location.lat();
          const longitude = location.lng();

          setPos({ lat: latitude, lng: longitude });

          // Update the form data after getting the coordinates
          setFormData((prevState) => ({
            ...prevState,
            communityId: selectedOption.value,
            communityName: selectedOption.label,
            lat: latitude,
            lng: longitude,
          }));
        } else {
          // console.error('Geocoding failed:', status);
        }
      });
    }
  };
  useEffect(() => {
    if (values.agentStatus === Agent[1]) {
      setFormData((prevState) => ({
        ...prevState,
        agent_id: null,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        agentStatus: Agent[0],
        agent_id: values.agent_id,
        agent: values.agent
      }));
    }
  }, [values.agentStatus]);
  const handleVideoChange = (event) => {
    let data = [...videosLink];
    data[0] = event.target.value;
    setVideosLink(data);
  };
  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      videos: videosLink,
    }));
  }, [videosLink]);

  const handleMLinkChange = (event) => {
    let data = [...mLinks];
    data[0] = event.target.value;
    setMLinks(data);
  };
  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      matterportLink: mLinks,
    }));
  }, [mLinks]);

  const handleSelectAmenities = (selectedOptions) => {
    setSelectedAmenities(selectedOptions);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(selectedAmenities);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // console.log(items)
    setSelectedAmenities(items);
  };

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      metaTags: tags,
    }));
  }, [tags]);

  useEffect(() => {
    if (values.propertyDesc) {
      let cleanText = he
        ?.decode(values?.propertyDesc)
        ?.replace(/<\/?[^>]+(>|$)/g, '');
      let descText = limit(cleanText, 160);
      setFormData((prevState) => ({
        ...prevState,
        metaDesc: descText,
      }));
    }
  }, [values.propertyDesc]);

  useEffect(() => {
    async function fetchType() {
      try {
        const res = await axios
          .get(`/propType`)
          .then((type) => {
            setType(type.data);
          })
          .catch((err) => {
            toast.error('Property type not available !');
          });
      } catch (err) {
        // console.log(err)
        toast.error('Server error. Try Again !');
      }
    }
    async function fetchCategory() {
      try {
        const res = await axios
          .get(`/category`)
          .then((cat) => {
            setCategory(cat.data);
          })
          .catch((err) => {
            toast.error('Category type not available !');
          });
      } catch (err) {
        // console.log(err)
        toast.error('Server error. Try Again !');
      }
    }
    fetchType();
    fetchCategory();

    if (values.property_owner_id) {
      let options = [];
      const l = values.propertyOwner?.phone?.length ?? 0;
      const phoneNumber = l > 4 ?`${'*'.repeat(l - 4)}${values.propertyOwner?.phone?.slice(-4)}` : '';
      options.push({
        value: values.property_owner_id,
        label:
          `${values.propertyOwner?.first_name} ${values.propertyOwner?.last_name} (${phoneNumber})`,
      });
      setOptDefaultUser(options[0]);
    }

    if (values.listingType) {
      setoptDefaultlType({
        label: values.listingType,
        value: values.listingType,
      });
    }
    
  }, []);

  useEffect(() => {
    let options = [];
      users?.length &&
      users
        .filter((user) => user.role === newRoles.Owner)
        .map((a) =>
          options.push({ value: a.id, label: a.first_name + ' ' + a.last_name })
        );
    setOptUsers(options);
  }, [users]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      lng: pos.lng,
      lat: pos.lat,
    }));
  }, [pos]);
  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      makaniNo: mak,
      // emirate:em,
    }));
  }, [mak]);

  useEffect(() => {
    dispatch(fetchCommunitiesList(axios));
  }, [dispatch,axios]);

  const handleClickedMap = async (e) => {
    setLoading(true);
    setPos({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    try {
      const bodyContent = {
        Lat: e.latLng.lat(),
        Lng: e.latLng.lng(),
        Token: 'l4f76613re4junvvbbd4ufki1j!=+=o6JGpy02dppzxKfVibRYfQ==',
        Remarks: 'MAKANI PHASE 2',
      };
      await axios
        .post(`${serverAPI}/external/makani`, bodyContent)
        .then((data) => {
          setLoading(false);
          toast.success('Location Data Acquired');
          if (data && data.data !== '') {
            setMak(data.data.BuildingInfo.Enterance[0].Makani);
          } else {
            toast.error('makani not available !');
            setMak('');
          }
        })
        .catch((err) => {
          setLoading(false);
          setMak('');
          toast.error('makani not available !');
        });
    } catch (err) {
      toast.error('Server error. Try Again !');
    }
    // console.log(pos)
  };

  useEffect(() => {
    if (values.communityId) {
      let options = [];
      options.push({
        value: values.communityId,
        label: values.communityName,
      });
      setOptDefaultCommunity(options[0]);
    }
  }, []);


  const isExcludedPropertyType = Object.values(propertyTypesExcludeBedsBaths).includes(checkPropertyType);

  const buildingOptional = Object.values(propertyTypesExcludeBuilding).includes(checkPropertyType);
  const submitFormData = async (e) => {

    e.preventDefault();
    addAmenityListFields(selectedAmenities);
  
    // Checking all the required fields
    if (
      values.listingType === '' ||
      values.categoryId === '' ||
      values.propertyTypeId === '' ||
      (checkPropertyType && !isExcludedPropertyType && (values.baths === '' || values.beds === '')) ||
      values.unitStatus === '' ||
      values.propertyDesc === '' ||
      values.propertyTitle === '' ||
      values.furnishing === '' ||
      values.unitStatus === '' ||
      values.contractValidity === '' ||
      values.isOffPlanResale?.value === undefined ||
      (values.isOffPlanResale?.value === 'Yes' && values.amountAlreadyPaid === '')
    ) {
      setError(true);
      toast.error('Please fill all the required fields');
      return;
    }
  
    // Checking required address fields
    if (
      values.unitReraNo === '' ||
      (checkPropertyType && (!buildingOptional) && (values.buildingName === '')) ||
      values.emirate === '' ||
      values.communityId === ''
    ) {
      setError(true);
      toast.error('Please fill all the required fields in Address');
      return;
    }
  
    // Checking location
    if (!values.lat && !values.lng) {
      setError(true);
      toast.error('Please Select a location');
      return;
    }
  
    // Checking owner
    if (!values.property_owner_id) {
      setError(true);
      toast.error('Please Select owner');
      return;
    }
  
    // Checking amenities
    if (amenityFieldIds.length < 5) {
      setError(true);
      return;
    }
  
    // Checking agent assignment
    if (values.agentStatus === Agent[0] && values.agent_id === null) {
      setError(true);
      toast.error('Please assign an Agent');
      return;
    }
  
    // Checking permit number
    if (!values.permitNo) {
      setError(true);
      toast.error('Please enter permit number');
      return;
    }
  
    // Checking permit number uniqueness
    if (values.permitNo) {
      let link = `/filter/propList?status=Active&permitNo=${values.permitNo}&listingType=${values.listingType}`;
      const count = await fetchPropertiesByPermitNo(link); 
      if (count > 0) {
        setError(true);
        toast.error('Permit number should be unique');
        return;
      }
    }
  
    // Proceed to next step if all validations pass
    props.next();
  };
  const fetchPropertiesByPermitNo = async (link) => {
    try {
      const res = await axios.get(link);
      return res?.data?.filteredCount;
    } catch (err) {
      toast.error('Permit No Validation failed');
    }
  };


  const [isOpen, setIsOpen] = useState(false);
  const [colSize, setColSize] = useState(6);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
    setColSize(isOpen ? 3 : 4); // Toggle between 6 and 4
  };

  const isResidential = category?.find(e=>e.id === values.categoryId)?.category === ValidCategories.Residential;

  return (
      <>
          <section>
              <Row className="gy-4">
                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label" htmlFor="first-name">
                              Title *{' '}
                          </label>
                          <div className="form-control-wrap">
                              <input
                                  type="text"
                                  id="first-name"
                                  className="form-control"
                                  name="firstName"
                                  value={values.propertyTitle}
                                  onChange={handleFormData('propertyTitle')}
                              />
                          </div>
                      </div>
                  </Col>
                  <Col sm={6}>
                      <div className="form-group">
                          <label className="form-label">Property Owner *</label>
                          <div className="d-flex align-items-center">
                              <div className="flex-grow-1">
                                  <SearchClientSelect
                                      placeholder="Search clients by name or phone"
                                      // onlyOwners
                                      onChange={handleOption}
                                      value={optDefaultUser}
                                      styles={{
                                          control: (baseStyles, state) => {
                                              return {
                                                  ...baseStyles,
                                                  borderColor: state.isFocused
                                                      ? '#854fff !important'
                                                      : baseStyles?.borderColor,
                                                  '&:hover': {
                                                      borderColor:
                                                          '#854fff !important'
                                                  }
                                              };
                                          }
                                      }}
                                  />
                              </div>
                              <Button
                                  color="success"
                                  className="ms-2"
                                  type="button"
                                  onClick={(ev) => {
                                      ev.preventDefault();
                                      toggleModal();
                                  }}
                              >
                                  <Icon name="plus"></Icon>
                              </Button>
                          </div>
                      </div>

                      <Modal
                          size="md"
                          isOpen={open}
                          toggle={toggleModal}
                          backdrop="static"
                      >
                          <ModalHeader toggle={toggleModal}>
                              Add New Owner
                              <button
                                  className="close"
                                  onClick={toggleModal}
                              ></button>
                          </ModalHeader>
                          <ModalBody>
                              <form onSubmit={(e) => addMember(e)}>
                                  <Col sm="12">
                                      <div className="form-group mb-2">
                                          <label
                                              className="form-label"
                                              htmlFor="first-name"
                                          >
                                              First Name*
                                          </label>
                                          <div className="form-control-wrap">
                                              <input
                                                  type="text"
                                                  id="first-name"
                                                  className="form-control"
                                                  name="firstName"
                                                  required
                                                  value={firstName}
                                                  onChange={(e) =>
                                                      setFirstName(
                                                          e.target.value
                                                      )
                                                  }
                                              />
                                          </div>
                                      </div>
                                  </Col>
                                  <Col sm="12">
                                      <div className="form-group mb-2">
                                          <label
                                              className="form-label"
                                              htmlFor="first-name"
                                          >
                                              Last Name*{' '}
                                          </label>
                                          <div className="form-control-wrap">
                                              <input
                                                  type="text"
                                                  id="first-name"
                                                  className="form-control"
                                                  required
                                                  value={lastName}
                                                  onChange={(e) =>
                                                      setLastName(
                                                          e.target.value
                                                      )
                                                  }
                                              />
                                          </div>
                                      </div>
                                  </Col>
                                  <Col sm="12">
                                      <div className="form-group mb-2">
                                          <label
                                              className="form-label"
                                              htmlFor="first-name"
                                          >
                                              Email*{' '}
                                          </label>
                                          <div className="form-control-wrap">
                                              <input
                                                  type="email"
                                                  id="first-name"
                                                  className="form-control"
                                                  name="email"
                                                  required
                                                  value={email}
                                                  onChange={(e) =>
                                                      setEmail(e.target.value)
                                                  }
                                              />
                                          </div>
                                      </div>
                                  </Col>
                                  <Col sm="12">
                                      <div className="form-group mb-2">
                                          <label
                                              className="form-label"
                                              htmlFor="first-name"
                                          >
                                              Phone*{' '}
                                          </label>
                                          <PhoneInput
                                              className="phoneInput "
                                              defaultCountry="AE"
                                              value={value}
                                              name="phone"
                                              placeholder="Enter Your Phone *"
                                              onChange={(value) =>
                                                  setValue(value)
                                              }
                                              onBlur={(e) => {
                                                  const { value } = e.target;

                                                  const isPossiblePhone =
                                                      isPossiblePhoneNumber(
                                                          value,
                                                          'AE'
                                                      );

                                                  if (isPossiblePhone) {
                                                      const isValidNum =
                                                          isValidPhoneNumber(
                                                              value,
                                                              'AE'
                                                          );

                                                      if (isValidNum) {
                                                          setErrorX(null);
                                                          return;
                                                      }

                                                      setErrorX(
                                                          'Number is not valid'
                                                      );
                                                      return;
                                                  }

                                                  setErrorX(
                                                      'Number is not possbible'
                                                  );
                                              }}
                                          ></PhoneInput>
                                          <div className="text-black flex">
                                              {' '}
                                              {errorX && errorX}
                                          </div>
                                      </div>
                                  </Col>
                                  <Col sm="6">
                                      <Button
                                          type="submit"
                                          className="bg-primary text-white mt-2"
                                      >
                                          {loading ? (
                                              <>
                                                  <Spinner
                                                      size="sm"
                                                      color="light"
                                                  />
                                                  Please Wait
                                              </>
                                          ) : (
                                              'Submit'
                                          )}
                                      </Button>
                                  </Col>
                              </form>
                          </ModalBody>
                      </Modal>
                  </Col>

                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label">Purpose *</label>
                          <RSelect
                              onChange={(e) => {
                                  setoptDefaultlType(e);
                                  setFormData((prevState) => ({
                                      ...prevState,
                                      listingType: e.value
                                  }));
                              }}
                              disabled={lockType}
                              value={optDefaultlType}
                              options={filteredListingType?.map((x) => ({
                                  label:
                                      x === 'Buy'
                                          ? 'For Sale'
                                          : x === 'Rent'
                                          ? 'For Rent'
                                          : x,
                                  value: x
                              }))}
                          />
                      </div>
                  </Col>
                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label">Category *</label>
                          <RSelect
                              value={{ label: values.category, value: values.categoryId }}
                              onChange={(e) => {
                                  setFormData((prevState) => ({
                                      ...prevState,
                                      categoryId: e.value,
                                      category: e.label,
                                  }));
                              }}
                              options={category?.map((x) => ({
                                  label: x.category,
                                  value: x.id
                              }))}
                          />
                      </div>
                  </Col>
                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label">Type * (Choose Category)</label>
                          <RSelect
                              value={{ label: values.propertyType, value: values.propertyTypeId }}
                              onChange={(e) => {
                                setCheckPropertyType(e.label);
                                  setFormData((prevState) => ({
                                      ...prevState,
                                      propertyTypeId: e.value,
                                      propertyType: e.label
                                  }));
                              }}
                              options={typeOptions}
                          />
                      </div>
                  </Col>
                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label">Size *</label>
                          <div className="form-control-wrap">
                              <div className="input-container">
                                  <input
                                      type="text"
                                      id="size"
                                      className="form-control"
                                      name="size"
                                      value={values.size}
                                      onChange={(e) =>
                                          handleFormData('size')(e)
                                      }
                                      onInput={(e) => {
                                          e.target.value =
                                              e.target.value.replace(
                                                  /[^0-9.]/g,
                                                  ''
                                              ); // Allow only numbers and .
                                      }}
                                      maxLength="10" // Limit total characters
                                      style={{ paddingRight: '60px' }}
                                  />

                                  <span
                                      className="input-text"
                                      style={{
                                          position: 'absolute',
                                          right: '10px',
                                          top: '50%',
                                          transform: 'translateY(-50%)'
                                      }}
                                  >
                                      sqft
                                  </span>
                              </div>
                          </div>
                      </div>
                  </Col>
                  <Col sm="6">
                      <div className="form-group">
                          <label className="form-label">Beds {!isExcludedPropertyType && '*'}</label>
                          <br></br>
                          <ul className="custom-control-group">
                              {(isResidential ? ResidentialBedRooms : NewBedRooms)?.map((x, i) => (
                                  <li
                                      onClick={(e) => {
                                          setFormData((prevState) => ({
                                              ...prevState,
                                              beds: x
                                          }));
                                      }}
                                      key={x}
                                  >
                                      <div
                                          className={`custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked ${
                                              values?.beds === x &&
                                              'border border-primary rounded'
                                          }`}
                                      >
                                          <label
                                              className="custom-control-label"
                                              htmlFor="btnOnlyIconCheck1"
                                          >
                                              <span className="ps-2 pe-2">
                                                  {x}
                                              </span>
                                          </label>
                                      </div>
                                  </li>
                              ))}
                          </ul>
                      </div>
                  </Col>
                  <Col sm="6">
                      <div className="form-group">
                          <label className="form-label">Baths {!isExcludedPropertyType && '*'}</label>
                          <br></br>
                          <ul className="custom-control-group">
                              {NewBathRooms?.map((x, i) => (
                                  <li
                                      key={x}
                                      onClick={(e) => {
                                          setFormData((prevState) => ({
                                              ...prevState,
                                              baths: x
                                          }));
                                      }}
                                  >
                                      <div
                                          className={`custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked ${
                                              values?.baths === x &&
                                              'border border-primary rounded'
                                          }`}
                                      >
                                          <label
                                              className="custom-control-label"
                                              htmlFor="btnOnlyIconCheck1"
                                          >
                                              <span className="ps-2 pe-2">
                                                  {x}
                                              </span>
                                          </label>
                                      </div>
                                  </li>
                              ))}
                          </ul>
                      </div>
                  </Col>

                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label">Vacancy * {!values.listingType ? "(Choose a purpose first)" : null}</label>
                          {!values.listingType && <RSelect isDisabled />}
                          {values.listingType === newListingType.Buy &&
                              UnitStatusBuy && (
                                  <RSelect
                                      value={{ label: values.unitStatus, value: values.unitStatus }}
                                      onChange={(e) => {
                                          setFormData((prevState) => ({
                                              ...prevState,
                                              unitStatus: e.value
                                          }));
                                      }}
                                      options={UnitStatusBuy?.map((x) => ({
                                          label: x,
                                          value: x
                                      }))}
                                  />
                              )}
                          {values.listingType === newListingType.Rent &&
                              UnitStatusRent && (
                                  <RSelect
                                      value={{ label: values.unitStatus, value: values.unitStatus }}
                                      onChange={(e) => {
                                          setFormData((prevState) => ({
                                              ...prevState,
                                              unitStatus: e.value
                                          }));
                                      }}
                                      options={UnitStatusRent?.map((x) => ({
                                          label: x,
                                          value: x
                                      }))}
                                  />
                              )}
                          {values.listingType === ListingType[2] &&
                              UnitStatusSell && (
                                  <RSelect
                                      value={{ label: values.unitStatus, value: values.unitStatus }}
                                      onChange={(e) => {
                                          setFormData((prevState) => ({
                                              ...prevState,
                                              unitStatus: e.value
                                          }));
                                      }}
                                      options={UnitStatusSell?.map((x) => ({
                                          label: x,
                                          value: x
                                      }))}
                                  />
                              )}
                          {values.listingType === ListingType[3] &&
                              UnitStatusLease && (
                                  <RSelect
                                      value={{ label: values.unitStatus, value: values.unitStatus }}
                                      onChange={(e) => {
                                          setFormData((prevState) => ({
                                              ...prevState,
                                              unitStatus: e.value
                                          }));
                                      }}
                                      options={UnitStatusLease?.map((x) => ({
                                          label: x,
                                          value: x
                                      }))}
                                  />
                              )}
                      </div>
                  </Col>

                  <Col md={category?.find(e=>e.id === values.categoryId)?.category === 'Commercial' ? "3" : "6"}>
                      <div className="form-group">
                          <label className="form-label" htmlFor="furnishing">
                              Furnishing *{' '}
                          </label>
                          <div className="form-control-wrap">
                              <RSelect
                                options={Object.values(FurnishingTypes).map(x => ({ label: x, value: x }))}
                                placeholder="Choose Furnishing Type"
                                value={{ label: values?.furnishing, value: values?.furnishing }}
                                onChange={(furnishing) => {
                                    setFormData((prev) => ({ ...prev, furnishing: furnishing?.value }));
                                    handleFormData('furnishing');
                                }}
                              />
                          </div>
                      </div>
                  </Col>

                   { category?.find(e=>e.id === values.categoryId)?.category === 'Commercial'&&
                   <Col md="3">
                      <div className="form-group">
                          <label className="form-label">Conditions</label>
                          <RSelect
                              value={{ label: values.conditions, value: values.conditions }}
                              onChange={(e) => {
                                  setFormData((prevState) => ({
                                      ...prevState,
                                      conditions: e.value
                                  }));
                              }}
                              
                              options={conditionsOptions.map((x) => ({
                                  label: x,
                                  value: x
                              }))}
                          />
                      </div>
                  </Col>}

                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label" htmlFor="first-name">
                              Permit Number * (trakheesi){' '}
                          </label>
                          <div className="form-control-wrap">
                              <input
                                  type="text"
                                  id="first-name"
                                  className="form-control"
                                  name="permitNo"
                                  value={values.permitNo}
                                  onChange={handleFormData('permitNo')}
                              />
                          </div>
                      </div>
                  </Col>
                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label" htmlFor="qr">
                              QR Code URL (trakheesi){' '}
                          </label>
                          <div className="form-control-wrap">
                              <input
                                  type="text"
                                  id="qr"
                                  className="form-control"
                                  name="qrCodeUrl"
                                  value={values.qrCodeUrl}
                                  onChange={handleFormData('qrCodeUrl')}
                              />
                          </div>
                      </div>
                  </Col>
                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label" htmlFor="first-name">
                              Contract A{' '}
                          </label>
                          <div className="form-control-wrap">
                              <input
                                  type="text"
                                  id="first-name"
                                  className="form-control"
                                  name="contractA"
                                  value={values.contractA}
                                  onChange={handleFormData('contractA')}
                              />
                          </div>
                      </div>
                  </Col>

                  <Col md="3">
                      <div className="form-group">
                          <label className="form-label" htmlFor="first-name">
                              Contract Validity *{' '}
                          </label>
                          <div className="form-control-wrap">
                            <RSelect
                              options={Object.values(ContractValidity).map(x => ({ label: x, value: x }))}
                              placeholder="Choose Contract Validity"
                              value={{ label: values?.contractValidity, value: values?.contractValidity }}
                              onChange={(validity) => {
                                  setFormData((prev) => ({ ...prev, contractValidity: validity?.value }));
                              }}
                            />
                          </div>
                      </div>
                  </Col>

                  <Col sm="3" className='d-flex align-items-end justify-content-between pb-2'>
                    <div className="custom-control custom-switch">
                    <input
                          type="checkbox"
                          className={`custom-control-input`}
                          id="customSwitch1" 
                          name='vacantOnTransfer'
                          checked={values.vacantOnTransfer}
                          onChange={(e) => {
                            setFormData((prev) => ({ ...prev, vacantOnTransfer: e.target.checked }));
                          }}
                        />
                        <label className="custom-control-label" for="customSwitch1">Vacant On Transfer</label>
                    </div>
                    <div className="custom-control custom-control custom-switch">
                        <input
                          type="checkbox"
                          className={`custom-control-input`}
                          id="customSwitch2" 
                          name='exclusive'
                          checked={values.exclusive}
                          onChange={(e) => {
                            setFormData((prev) => ({ ...prev, exclusive: e.target.checked }))
                          }}
                        />
                        <label className="custom-control-label" for="customSwitch2">Exclusive</label>
                    </div>
                </Col>

                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label" htmlFor="city">
                              Meta Tags
                          </label>
                          <TagsInput
                              value={tags}
                              onChange={(newTags) => setTags(newTags)}
                              name="Tags"
                              placeHolder="Enter Tags"
                          />
                      </div>
                  </Col>
                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label" htmlFor="city">
                              Description *
                          </label>
                          <QuillComponentNew
                              data={values.propertyDesc}
                              setFormData={setFormData}
                          />
                      </div>
                  </Col>
                  <Col md={"6"}>
                      <div className="form-group">
                          <Label className="form-label" htmlFor="fv-message">
                              Meta Description
                          </Label>
                          <div className="form-control-wrap">
                              <textarea
                                  type="textarea"
                                  className="form-control form-control-sm"
                                  rows="5"
                                  placeholder="Write your message"
                                  name="metaDesc"
                                  id="metaDesc"
                                  value={values.metaDesc}
                                  onChange={handleFormData('metaDesc')}
                              />
                          </div>
                      </div>
                  </Col>

                  <Col md="6">
                      <div className="form-group"></div>
                  </Col>
              </Row>
          </section>
          <div className="nk-data data-list mb-4">
              <div className="data-head">
                  <h6 className="overline-title">Amenities</h6>
              </div>
          </div>
          <section>
            <Row>
            <Col sm={12}>
                  <div className=''>
                      <div className="form-group">
                          <label className="form-label">Amenities*</label>
                          <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: '100%' }}>
                                  <RSelect
                                      closeMenuOnSelect={false}
                                      defaultData={[]}
                                      isMulti
                                      onChange={handleSelectAmenities}
                                      options={amenityOptions}
                                      className="w-100"
                                      value={selectedAmenities}
                                  />
                              </div>
                          </div>
                      </div>

                      <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId="lines">
                              {(provided) => (
                                  <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                  >
                                      {selectedAmenities.map(
                                          (amenity, index) => (
                                              <Draggable
                                                  key={index}
                                                  draggableId={index.toString()}
                                                  index={index}
                                              >
                                                  {(provided) => (
                                                      <div
                                                          ref={
                                                              provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          className="d-flex align-items-center mb-2"
                                                      >
                                                          <input
                                                              type="text"
                                                              value={
                                                                  amenity.label
                                                              }
                                                              readOnly
                                                              className="me-2 form-control"
                                                          />
                                                          <div className="input-group me-2">
                                                              <input
                                                                  type="text"
                                                                  defaultValue={
                                                                      amenityFields.find(
                                                                          (
                                                                              am
                                                                          ) =>
                                                                              am.amenityId ===
                                                                              amenity.value
                                                                      )
                                                                          ?.quantity ??
                                                                      '0'
                                                                  }
                                                                  className="form-control"
                                                                  onChange={(
                                                                      event
                                                                  ) =>
                                                                      handleQuantity(
                                                                          index,
                                                                          event,
                                                                          amenity.value
                                                                      )
                                                                  }
                                                              />
                                                          </div>
                                                          <div className="input-group-append">
                                                              <span
                                                                  {...provided.dragHandleProps}
                                                                  className="input-group-text"
                                                              >
                                                                  <Icon name="move"></Icon>
                                                              </span>
                                                          </div>
                                                      </div>
                                                  )}
                                              </Draggable>
                                          )
                                      )}
                                      {provided.placeholder}
                                  </div>
                              )}
                          </Droppable>
                      </DragDropContext>
                  </div>
              </Col>
            </Row>
          </section>
          <div className="nk-data data-list mb-4">
              <div className="data-head">
                  <h6 className="overline-title">Address</h6>
              </div>
          </div>
          <section>
              <Row className="gy-4">
                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label">Community *</label>
                          <RSelect
                              value={optDefaultCommunity}
                              onChange={handleOptionMap}
                              options={community?.map((com) => ({
                                  value: com.id,
                                  label: com.community
                              }))}
                          />
                      </div>
                  </Col>
                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label">Sub Community</label>
                          <div className="form-control-wrap">
                              <input
                                  type="text"
                                  id="first-name"
                                  className="form-control"
                                  name="subCommunity"
                                  value={values.subCommunity}
                                  onChange={handleFormData('subCommunity')}
                              />
                          </div>
                      </div>
                  </Col>
                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label">Title Deed No *</label>
                          <div className="form-control-wrap">
                              <input
                                  type="text"
                                  id="first-name"
                                  className="form-control"
                                  name="unitReraNo"
                                  value={values.unitReraNo}
                                  onChange={handleFormData('unitReraNo')}
                              />
                          </div>
                      </div>
                      <div className="form-group">
                          <label className="form-label">Emirate *</label>
                          <RSelect
                              value={{ label: values.emirate, value: values.emirate }}
                              onChange={(e) => {
                                  setFormData((prevState) => ({
                                      ...prevState,
                                      emirate: e.value
                                  }));
                              }}
                              options={Emirates?.map((e) => ({
                                  label: e,
                                  value: e
                              }))}
                          />
                      </div>
                      <div className="form-group">
                          <label className="form-label">Building Name  {!buildingOptional && '*'}</label>
                          <div className="form-control-wrap">
                              <input
                                  type="text"
                                  id="first-name"
                                  className="form-control"
                                  name="buildingName"
                                  value={values.buildingName}
                                  onChange={handleFormData('buildingName')}
                              />
                          </div>
                      </div>
                      <div className="form-group">
                          <label className="form-label">Street</label>
                          <div className="form-control-wrap">
                              <input
                                  type="text"
                                  id="first-name"
                                  className="form-control"
                                  name="street"
                                  value={values.street}
                                  onChange={handleFormData('street')}
                              />
                          </div>
                      </div>
                      <div className="form-group">
                          <label className="form-label">Makani No</label>
                          <div className="form-control-wrap">
                              <input
                                  type="text"
                                  id="first-name"
                                  className="form-control"
                                  name="makaniNo"
                                  value={values.makaniNo}
                                  onChange={handleFormData('makaniNo')}
                              />
                          </div>
                      </div>
                      <div className="form-group">
                          <label className="form-label">Dewa No</label>
                          <div className="form-control-wrap">
                              <input
                                  type="text"
                                  id="first-name"
                                  className="form-control"
                                  name="dewaNo"
                                  value={values.dewaNo}
                                  onChange={handleFormData('dewaNo')}
                              />
                          </div>
                      </div>
                  </Col>
                  <Col md="6">
                      <div className="form-group">
                          <label className="form-label">Location *</label>
                          {isLoaded && pos && (
                              <Map
                                  pos={pos}
                                  handleClickedMap={handleClickedMap}
                                  mapStyles={mapStyles}
                              />
                          )}
                      </div>
                  </Col>
              </Row>
          </section>
          <div className="nk-data data-list mb-4">
              <div className="data-head">
                  <h6 className="overline-title">Additional Information</h6>
              </div>
          </div>
          <Row className="gy-4">
              {!values?.isAgent && (
                  <Col sm={6}>
                      <div className="form-group">
                          <label className="form-label">Agent Status</label>
                          <RSelect
                              value={{ label: values.agentStatus, value: values.agentStatus }}
                              onChange={(e) => {
                                  setFormData((prevState) => ({
                                      ...prevState,
                                      agentStatus: e.value
                                  }));
                              }}
                              disabled={values?.isAgent}
                              options={Agent?.map((e) => ({
                                  label: e,
                                  value: e
                              }))}
                          />
                      </div>
                  </Col>
              )}

              <Col sm="6">
                  <div className="form-group">
                      <label className="form-label" htmlFor="first-name">
                          Video Link
                      </label>
                      <div className="form-control-wrap">
                          <input
                              type="text"
                              id="first-name"
                              className="form-control"
                              name="video"
                              value={values?.videos[0]}
                              onChange={(event) => handleVideoChange(event)}
                          />
                      </div>
                  </div>
              </Col>
              {values.agentStatus &&
                  values.agentStatus === Agent[0] &&
                  !values?.isAgent && (
                      <Col sm={6}>
                          <div className="form-group">
                              <label className="form-label">Agent *</label>
                              <RSelect
                                  value={values?.agent}
                                  onChange={(e) => {
                                      setFormData((prevState) => ({
                                          ...prevState,
                                          agent_id: e.value,
                                          agent: e
                                      }));
                                  }}
                                  options={agents?.map((a) => ({
                                      ...a,
                                      value: a.id,
                                      label:
                                          a?.user?.first_name +
                                          ' ' +
                                          a?.user?.last_name
                                  }))}
                              />
                          </div>
                      </Col>
                  )}
              <Col sm="6">
                  <div className="form-group">
                      <label className="form-label" htmlFor="first-name">
                          Matterport Link
                      </label>
                      <div className="form-control-wrap">
                          <input
                              type="text"
                              id="first-name"
                              className="form-control"
                              name="mlink"
                              value={values?.mLinks?.[0]}
                              onChange={(event) => handleMLinkChange(event)}
                          />
                      </div>
                  </div>
              </Col>
              <Col sm={values?.isOffPlanResale?.value === 'Yes' ? 3 : 6}>
                <div className="form-group">
                  <label htmlFor="" className="form-label">Off-Plan Resale *</label>
                  <RSelect
                    options={['Yes', 'No'].map((x) => ({ label: x, value: x, }))}
                    placeholder="Is this an Off-Plan Resale?"
                    value={{ label: values?.isOffPlanResale?.label, value: values?.isOffPlanResale?.value }}
                    onChange={(e) => {
                        toggleCollapse();
                        setFormData((prevState) => ({
                            ...prevState,
                            isOffPlanResale: e
                        }));
                    }}
                  />
                </div>
              </Col>
              {values?.isOffPlanResale?.value === 'Yes' ?
              <Col sm={3}>
                <Label>Amount Already Paid *</Label>
                <Input
                    type="number"
                    required
                    name='amountAlreadyPaid'
                    placeholder='Enter the amount already paid'
                    min={0}
                    value={values?.amountAlreadyPaid}
                    onChange={(e) => {
                        setFormData(prev => ({
                            ...prev,
                            amountAlreadyPaid: e.target.value
                        }))
                    }}
                />
              </Col> : null}
              <Col sm={6}>
                  <div className="form-group">
                      <div className="d-flex justify-content-between align-items-center">
                          <label className="form-label">Custom Fields</label>
                          {inputFields?.length === 0 && (
                              <Button
                                  color="success"
                                  type="button"
                                  onClick={(e) => addFields(e)}
                              >
                                  <Icon name="plus" className={''}></Icon>
                              </Button>
                          )}
                      </div>
                      {inputFields ?
                          inputFields?.map((input, index) => {
                              return (
                                  <div className="d-flex align-items-center mb-2">
                                      <div
                                          className="me-2"
                                          style={{ width: '21rem' }}
                                      >
                                          <input
                                              type="text"
                                              className="form-control "
                                              style={{ width: '21rem' }}
                                              name="label"
                                              id="name"
                                              placeholder="Field Name"
                                              value={input.label}
                                              onChange={(event) =>
                                                  handleFormChange(index, event)
                                              }
                                          />
                                      </div>
                                      <input
                                          type="text"
                                          className="form-control me-2"
                                          style={{ width: '21rem' }}
                                          name="value"
                                          placeholder="Value"
                                          value={input.value}
                                          onChange={(event) =>
                                              handleFormChange(index, event)
                                          }
                                      />
                                      <Button
                                          onClick={(e) => removeFields(e)}
                                          color="danger"
                                          type="button"
                                          className="me-2"
                                      >
                                          <Icon name="trash"></Icon>
                                      </Button>

                                      <Button
                                          onClick={(e) => addFields(e)}
                                          color="success"
                                          type="button"
                                      >
                                          <Icon name="plus"></Icon>
                                      </Button>
                                  </div>
                              );
                          }):null}
                  </div>
              </Col>
          </Row>
          <section></section>
          <div className="actions clearfix">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
                  <li>
                      <Button
                          onClick={(e) => submitFormData(e)}
                          color="primary"
                          type="submit"
                      >
                          Next
                      </Button>
                  </li>
              </ul>
          </div>
      </>
  );
};

export default StepOne;
