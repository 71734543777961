import { Link } from "react-router-dom";
import DndFlagComponent from "../../../components/dnd/dndFlag";

const MembersList = ({ members }) => {
    const styling = {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))', // Adjust the minmax values as needed
        gap: '1rem',
    };
    return (
      <div className="">
        <div className="" style={styling}>
            {members?.filter(x=>x!==undefined)?.map((member, idx) => (
                <div key={`member-info-${idx}`} className="d-flex align-items-center justify-content-center" style={{ gap: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <Link to={`/user-profile/${member?.id}/user-profile`} >{(member?.first_name + " " + (member?.last_name ?? "")).trim()}</Link>
                  {member?.DndStatus?
                    <DndFlagComponent className={"d-flex flex-column align-items-center justify-content-center p-2"} lead_status={member?.lead_status} phoneNumber={member?.phone}/>: <span  style={{ minWidth: "10ch", textAlign: "left" }}>{member?.phone ?? ""}</span>}
                </div>
              </div>
            ))}
        </div>
      </div>
    )
}

export default MembersList;