// eslint-disable-next-line no-unused-vars
import { Axios, AxiosError } from "axios";

class AxiosErrorHandler {

    /**
     * Creates an instance of AxiosErrorHandler, supports a custom logger implementation.
     * @param {Object} [logger=console] - The custom logger object with methods log, error, warn, etc.
     */
    constructor(logger = console) {
      this.logger = logger;
    }

    /**
     * Handles errors from Axios requests.
     * @param {AxiosError | unknown} error - The error object from Axios.
     * @throws Will throw an error with a specific message based on the type of error.
     */
    handle(error) {
      if (error.response) {
        // Request made and server responded with a status code not in the range of 2xx
        this.logErrorResponse(error.response);
        throw new Error(this.formatResponseErrorMessage(error.response));
      } else if (error.request) {
        // Request was made but no response was received
        this.logErrorRequest(error.request);
        throw new Error('No response received from the server. Please try again later.');
      } else {
        // Something happened in setting up the request that triggered an Error
        this.logErrorMessage(error.message);
        throw new Error(`Request setup failed: ${error.message}`);
      }
    }

    /**
     * Logs detailed error response information.
     * @param {AxiosError.AxiosResponse} response - The response object from Axios.
     */
    logErrorResponse(response) {
      this.logger.error('Error response:', response.data);
      this.logger.error('Error status:', response.status);
      this.logger.error('Error headers:', response.headers);
    }

    /**
     * Logs detailed error request information.
     * @param {any} request - The request object from Axios.
     */
    logErrorRequest(request) {
      this.logger.error('Error request:', request);
    }

    /**
     * Logs error messages.
     * @param {string} message - The error message.
     */
    logErrorMessage(message) {
      this.logger.error('Error message:', message);
    }

    /**
     * Formats the error message from the response.
     * @param {Object} response - The response object from Axios.
     * @returns {string} - The formatted error message.
     */
    formatResponseErrorMessage(response) {
      return `Request failed with status ${response.status}: ${response.data.message || response.data}`;
    }
}

export default AxiosErrorHandler;
