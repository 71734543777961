import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  ListingType,
  formatValue,
  Agent,
  webURL,
  Status,
  propActiveStatus,
  newRoles,
  newPropStatus,
  TicketTypes,
  TicketStatuses,
  HttpStatus,
  PropertyListStatus,
  s3URL,
} from "../../../../utils/envConfig";
import {
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Card,
  Badge,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";
import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import {
  Icon,
  Button,
  UserAvatar,
  RSelect,
} from "../../../../components/Component";
import { toast } from "react-toastify";
import { fetchAgents } from "../../../../redux/actions/user";
import { useSelector, useDispatch } from "react-redux";
import ConfirmModal from "../../../../components/miscellaneous/ConfirmModal";
import CancelModal from "./CancelModal";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { showToast } from "../../../../utils/toast/toast";
import { ErrorTypes } from "../../../../utils/error-types";
import NewAgentAvatar from "../../../users/components/NewAgentAvatar";

const PropertyCard = ({
  property,
  disableAgentChange = false,
  disableTagsChange = false,
  index
}) => {
  let navigate = useNavigate();
  const agent = useSelector((state) => state.user?.agents);
  const dispatch = useDispatch();
  const authuser = useSelector((state) => state?.user?.loggedInUser);
  const axios = useAxiosPrivate();

  useEffect(() => {
    if (!agent || !agent.length) {
      dispatch(fetchAgents(axios));
    }
  }, [dispatch]);

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);
  const [propId, setPropId] = useState(property?.id);
  const [propReferId, setPropReferId] = useState(property?.referNo);
  const [agentSelected, setAgentSelected] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [price, setPrice] = useState(0);
  const [monthPrice, setMonthPrice] = useState(0);
  const [imgAgentUrl, setAgentImgUrl] = useState(null);
  const [imgAgentLoading, setAgentImgLoading] = useState(true);
  const [imgOwnerUrl, setOwnerImgUrl] = useState(null);
  const [imgOwnerLoading, setOwnerImgLoading] = useState(true);
  const [optEditAgents, setOptEditAgents] = useState([]);
  const [optDefaultEditAgent, setOptDefaultEditAgent] = useState({
    label: "Select Agent",
    value: null,
  });
  const [propAgent, setPropAgent] = useState(undefined);
  const [toggleName, setToggleName] = useState(null);
  const [featured, setFeatured] = useState(property?.featured);
  const [dealOfWeek, setDealOfWeek] = useState(property?.dealOfWeek);
  const [exclusive, setExclusive] = useState(property?.exclusive);
  const [premium, setPremium] = useState(property?.premium);
  const [propStatus, setPropStatus] = useState(property?.status);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    let options = [];
    let result =
      agent &&
      agent?.length > 0 &&
      agent
        ?.filter((agents) => agents?.user?.active === true)
        ?.map((a) =>
          options.push({
            value: a.id,
            label: a?.user?.first_name + " " + a?.user?.last_name,
            image: a?.image
          })
        );
    setOptEditAgents(options);
  }, [agent]);


  useEffect(() => {
    if (property?.images && property.images.length > 0) {

      setImgUrl(s3URL + (property.images[0]?.thumbnail ? property.images[0]?.thumbnail : property.images[0]));
    } else

      if (property?.agent_id) {
        let arr = [];
        if (property?.agent?.user?.photo) {
          setAgentImgUrl(s3URL + property?.agent?.user?.photo)
        } else {
          setAgentImgUrl("./img/profile.png");
          setAgentImgLoading(false);
        }
        arr.push({
          value: property?.agent?.user?.id,
          label:
            "" +
            property?.agent?.user?.first_name +
            " " +
            property?.agent?.user?.last_name,
        });
        setPropAgent(arr);
        setOptDefaultEditAgent(arr);
      } else {
        setAgentImgLoading(false);
      }
    if (property?.property_owner_id) {
      if (property?.propertyOwner?.photo) {
        setOwnerImgUrl(s3URL + property?.propertyOwner?.photo)
      } else {
        setOwnerImgUrl("./img/profile.png");
        setOwnerImgLoading(false);
      }
    } else {
      setAgentImgUrl("./img/profile.png");
      setOwnerImgLoading(false);
    }

    if (
      property.listingType === ListingType[0] ||
      property.listingType === ListingType[2]
    ) {
      setPrice(property.askingPrice);
      setMonthPrice(property.askingPrice / 12);
    } else {
      setPrice(property?.oneCheqPrice);
      setMonthPrice(property.emi);
    }
  }, [property]);

  const handleEditAgentOption = (e) => {
    if (!e) {
      setOptDefaultEditAgent(propAgent ? propAgent : null);
      return;
    } else {
      let lg = [];
      lg.push(e);
      setOptDefaultEditAgent(lg);
      setAgentSelected(e.value);
      setModalMessage(`Assign / Reassign Agent to `);
      setConfirmModal("Agent");
      setShowModal(true);
    }
  };

  const handleToggle = (e, field) => {
    e.preventDefault();
    if (disableTagsChange) return;
    field && setToggleName(field);
    setModalMessage(`Enable / Disable ${field} to `);
    setConfirmModal("Toggle");
    setShowModal(true);
  };
  const handleEditStatus = (e) => {
    e.preventDefault();
    setModalMessage(`${propStatus === Status[0] ? "Unpublish " : "Publish "}`);
    setConfirmModal("Status");
    setShowModal(true);
  };
  const handleEditStatusAll = (e, name) => {
    e.preventDefault();
    setModalMessage(`Update the status of `);
    setConfirmModal(name);
    setShowModal(true);

  };

  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const closeModal = () => {
    setModalMessage(null);
    setShowModal(false);
    setOptDefaultEditAgent(propAgent ? propAgent : optDefaultEditAgent);
  };

  const validate = (agent) => {
    if (!agent) {
      toast.error("Select Agent");
      return false;
    } else {
      return true;
    }
  };

  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const propertyUpdate = async (propertyData, msg) => {
    // Checking permit number uniqueness
    if (propertyData.status === "Active" && propertyData.permitNo) {
      let link = `/filter/propList?status=Active&permitNo=${propertyData.permitNo}&listingType=${propertyData.listingType}`;
      const count = await fetchPropertiesByPermitNo(link);
      if (count > 0) {
        toast.error('Permit number should be unique');
        return false;
      }
    }

    setIsUpdateLoading(true);
    try {
      const res = await axios.patch(
        `/propList/${propId}`,
        propertyData,
      );

      if (res.status === HttpStatus.OK) {
        if (propertyData?.status === PropertyListStatus.Published) {
          // try to publish to property finder
          const pfRes = await axios.post(`/propList/pf-draft/${propId}`);
          if (pfRes.status === HttpStatus.CREATED) {
            showToast('Successfully published the draft on PropertyFinder.', 'success');
          }
        } else if (propertyData?.status === PropertyListStatus.Unpublished ||
          propertyData?.statusArray.includes(PropertyListStatus.Cancelled)) {
          const pfRes = await axios.delete(`/propList/pf-cancel/${propId}`);
          if (pfRes.status === HttpStatus.NO_CONTENT) {
            showToast('Removed from PropertyFinder listings.', 'warning');
          }
        }
      }
      toast.success(msg);
    } catch (err) {
      console.log(err)
      const serverErrorMessage = err.response?.data?.message;
      const serverErrorType = err.response?.data?.type;

      if (err.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
        showToast('A private listing will not be published on property finder.', 'warning');
        return;
      }
      if (serverErrorMessage) {
        const toastType = serverErrorType === ErrorTypes.PropertyFinderError ? 'error' : 'warning';
        showToast(serverErrorMessage, toastType);
      } else {
        showToast('Property Finder : An error occurred.', 'error');
      }
    } finally {
      setIsUpdateLoading(false);
      setTimeout(() => navigate(0), 1000);
    }
  };

  const submitConfirm = async () => {
    let msg = null;
    if (confirmModal === "Toggle") {
      msg = `Enabled / Disabled ${toggleName}`;
      toggleName && toggleName === "Featured"
        ? propertyUpdate(
          {
            featured: !featured,
          },
          msg
        )
        : toggleName === "Deal of Week"
          ? propertyUpdate(
            {
              dealOfWeek: !dealOfWeek,
            },
            msg
          )
          : toggleName === "Premium"
            ? propertyUpdate(
              {
                premium: !premium,
              },
              msg
            )
            : toggleName === "Exclusive" &&
            propertyUpdate(
              {
                exclusive: !exclusive,
              },
              msg
            );
    }
    if (confirmModal === "Status") {
      if (unResolvedTicket) return toast.error(`${unResolvedTicket?.requestType} Ticket Not Resolved`)
      if (
        (property?.status !== propActiveStatus[0] && property?.images.length <= 0)
      ) {
        toast.error("Missing Images for the Property !");
        return
      }
      msg = `Pubished / Unpublished the property`;
      const newStatus = newPropStatus[5]; // The value you want to add or remove

      // Check if newStatus exists in the array
      const index = property?.statusArray.indexOf(newStatus);
      let updatedStatusArray = property?.statusArray;
      if (index !== -1) {
        updatedStatusArray = property?.statusArray.filter(item => item !== newStatus);
        // Now, updatedStatusArray doesn't include the newStatus
      }
      propertyUpdate(
        {
          status: propStatus === Status[0] ? Status[1] : Status[0],
          statusArray: updatedStatusArray,
          permitNo: property?.permitNo,
          listingType: property?.listingType
        }, msg
      );
    }
    if (confirmModal === "Sold") {
      msg = `Marked as sold`;
      const newStatus = property?.listingType === ListingType[0] ? newPropStatus[0] : newPropStatus[1];
      let updatedSoldOrReserved = newStatus
      let updatedSoldDate = new Date();
      // Check if newStatus exists in the string
      if (property?.soldOrReserved === newStatus) {
        // Value exists, so remove it
        updatedSoldOrReserved = ""
        updatedSoldDate = null
        // Now, updatedSoldOrReserved doesn't include the newStatus
      } else {
        // Value doesn't exist, so add it
        updatedSoldOrReserved = `${newStatus}`.trim();
        // Now, updatedSoldOrReserved includes the newStatus
      }

      propertyUpdate(
        {
          soldOrReserved: updatedSoldOrReserved,
          soldDate: updatedSoldDate,
        },
        msg
      );
    }
    if (confirmModal === "Reserved") {
      msg = `Marked as reserved`;
      const newStatus = newPropStatus[2];
      let updatedReservedDate = new Date();
      let updatedSoldOrReserved
      // Check if newStatus exists in the string
      if (property?.soldOrReserved === newStatus) {
        // Value exists, so remove it
        updatedSoldOrReserved = ""
        updatedReservedDate = undefined
        // Now, updatedSoldOrReserved doesn't include the newStatus
      } else {
        // Value doesn't exist, so add it
        updatedSoldOrReserved = `${newStatus}`.trim();
        // Now, updatedSoldOrReserved includes the newStatus
      }
      propertyUpdate(
        {
          soldOrReserved: updatedSoldOrReserved,
          reservedDate: updatedReservedDate,
        },
        msg
      );
    }
    if (confirmModal === "Private") {
      msg = `Property Status updated`;
      const newStatus = newPropStatus[3]; // The value you want to add or remove

      // Check if newStatus exists in the array
      const index = property?.statusArray.indexOf(newStatus);
      let updatedStatusArray = property?.statusArray;
      if (index !== -1) {
        // Value exists, so remove it
        updatedStatusArray = property?.statusArray.filter(item => item !== newStatus);
        // Now, updatedStatusArray doesn't include the newStatus
      } else {
        // Value doesn't exist, so add it
        updatedStatusArray = [...property?.statusArray, newStatus];
        // Now, updatedStatusArray includes the newStatus
      }

      propertyUpdate(
        {
          statusArray: updatedStatusArray,
          status: Status[1],
        },
        msg
      );
    }
    if (confirmModal === "Pocket") {
      msg = `Property Status updated`;
      const newStatus = newPropStatus[6]; // The value you want to add or remove

      // Check if newStatus exists in the array
      const index = property?.statusArray.indexOf(newStatus);
      let updatedStatusArray = property?.statusArray;
      if (index !== -1) {
        // Value exists, so remove it
        updatedStatusArray = property?.statusArray.filter(item => item !== newStatus);
        // Now, updatedStatusArray doesn't include the newStatus
      } else {
        // Value doesn't exist, so add it
        updatedStatusArray = [...property?.statusArray, newStatus];
        // Now, updatedStatusArray includes the newStatus
      }

      propertyUpdate(
        {
          statusArray: updatedStatusArray,
          status: Status[1],
        },
        msg
      );
    }

  };
  const fetchPropertiesByPermitNo = async (link) => {
    try {
      const res = await axios.get(link);
      return res?.data?.filteredCount;
    } catch (err) {
      toast.error('Permit No Validation failed');
    }
  };
  const handleConfirmCancel = async (note) => {

    const newStatus = newPropStatus[4]; // The value you want to add or remove

    // Check if newStatus exists in the array
    const index = property?.statusArray.indexOf(newStatus);
    let updatedStatusArray = property?.statusArray;

    if (index !== -1) {
      // Value exists, so remove it
      return
      // Now, updatedStatusArray doesn't include the newStatus
    } else {
      // Value doesn't exist, so add it
      updatedStatusArray = [...property?.statusArray, newStatus];
      // Now, updatedStatusArray includes the newStatus
    }
    propertyUpdate(
      {
        statusArray: updatedStatusArray,
        status: Status[1],
        cancelNote: note
      },
    );
  }

  // useEffect( () => { }, [ featured, dealOfWeek, premium, exclusive ] );
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [assignedAgent, setAssignedAgent] = useState(null);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };


  const handleAgentSelect = (selectedOption) => {
    setSelectedAgent(selectedOption);
    setOptDefaultEditAgent(selectedOption);
  };
  const handleAssignAgent = () => {
    if (selectedAgent) {

      setAssignedAgent(selectedAgent);
      if (validate(selectedAgent)) {
        const agentData = {
          agentStatus: Agent[0],
          agent_id: selectedAgent.value,
        };
        propertyUpdate(agentData);
      }

    } else {
      setAssignedAgent(null);
    }
  };
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  const [tickets, settickets] = useState(null)
  const [unResolvedTicket, setunResolvedTicket] = useState(null);
  const fetchTickets = async (listingAcquiredId) => {
    try {
      const { data } = await axios.get(`/supportticket?listingAcquiredId=${listingAcquiredId}&type=${TicketTypes.MasetrTicket}`);
      settickets(data?.tickets)

    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchTickets(property?.listingAcquiredId)

  }, [property?.listingAcquiredI])
  useEffect(() => {
    if (tickets?.length)
      setunResolvedTicket(c =>
        tickets?.find(ticket => ticket?.status !== TicketStatuses.Resolved))


  }, [tickets])
  const TooltipComponent = ({ id, direction, text, containerClassName, ...props }) => {
    return (
      <React.Fragment>
        {props.tag ? (
          <props.tag className={containerClassName} id={id}>
            {props.children}
          </props.tag>
        ) : (
          <span id={id}>{props.children}</span>
        )}
        <UncontrolledTooltip autohide={false} placement={direction} target={id}>
          {text}
        </UncontrolledTooltip>
      </React.Fragment>
    );
  };
  return (
    <>
      <div lg="4">
        <Card className="card-bordered mb-4">

          <div style={{ position: "relative", height: "17rem" }}>
            <div className="relative">
              <Link to={`/property-list/${property.id}`}>
                <CardImg
                  top
                  src={imgUrl ? imgUrl : "./img/private-property.svg"}
                  alt=""
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                position: "absolute",
                right: "0.5rem",
                bottom: "1rem",
              }}
            >
              {property?.soldOrReserved && (
                <Badge color="success"

                >{property?.soldOrReserved}</Badge>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                position: "absolute",
                top: "1rem",
                left: "1rem",
              }}
            >
              {dealOfWeek && (
                <Button
                  style={{
                    backgroundColor: "#000",
                    color: "#fff",
                    marginRight: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                  className="btn-custom-label"
                >
                  Deal of the week{" "}
                  {authuser?.role && (authuser?.role !== newRoles?.Agent) &&
                    <Icon
                      name="cross-sm"
                      className="ms-1"
                      // onClick={ () => setDealOfWeek( false ) }

                      onClick={(e) => (propertyUpdate(
                        {
                          dealOfWeek: !dealOfWeek,
                        },
                      ) && (setDealOfWeek(!dealOfWeek)))}  ></Icon>
                  }
                </Button>
              )}
              {featured && (
                <Button
                  color="danger"
                  style={{ marginRight: "0.5rem", marginBottom: "0.5rem" }}
                  className="btn-custom-label"
                >
                  Featured{" "}
                  {authuser?.role && (authuser?.role !== newRoles?.Agent) &&
                    <Icon
                      name="cross-sm"
                      className="ms-1"
                      // onClick={ () => setFeatured( false ) }
                      onClick={(e) => (propertyUpdate(
                        {
                          featured: !featured,
                        },
                      ) && (setFeatured(!featured)))}
                    ></Icon>
                  }
                </Button>
              )}
              {exclusive && (
                <Button
                  style={{
                    backgroundColor: "#e5c188",
                    color: "#000",
                    marginRight: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                  className="btn-custom-label"
                >
                  Exclusive{" "}
                  {authuser?.role && (authuser?.role !== newRoles?.Agent) &&
                    <Icon
                      name="cross-sm"
                      className="ms-1"
                      // onClick={ () => setExclusive( false ) }
                      onClick={(e) => (propertyUpdate(
                        {
                          exclusive: !exclusive,
                        },
                      ) && (setExclusive(!exclusive)))}   ></Icon>
                  }
                </Button>
              )}
              {premium && (
                <Button
                  style={{
                    backgroundColor: "#21356e",
                    color: "#fff",
                    marginRight: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                  className="btn-custom-label"
                >
                  Premium{" "}
                  {authuser?.role && (authuser?.role !== newRoles?.Agent) &&
                    <Icon
                      name="cross-sm"
                      className="ms-1"
                      // onClick={ () => setPremium( false ) }
                      onClick={(e) => (propertyUpdate(
                        {
                          premium: !premium,
                        },
                      ) && (setPremium(!premium)))}
                    ></Icon>
                  }
                </Button>
              )}

            </div>

          </div>

          <CardBody className="card-inner position-relative" >

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <CardText className="d-inline-block custom-margin-bottom">
                <span>Property Id: {property?.referNo}</span>
                {property?.statusArray?.includes(newPropStatus[4]) ?
                  <TooltipComponent id="tooltip-perfomance" direction="top" text={property?.cancelNote}>
                    <Badge color="danger" className="ms-1 cursor-pointer">
                      {newPropStatus[4]}
                    </Badge>
                  </TooltipComponent>
                  // <Badge color="danger" className="ms-1">{ newPropStatus[ 4 ] }</Badge>
                  :
                  property?.statusArray?.includes(newPropStatus[6]) ?
                    <Badge color="danger" className="ms-1">{newPropStatus[6]}</Badge>
                    :
                    property?.statusArray?.includes(newPropStatus[5]) ?
                      <Badge color="info" className="ms-1">{newPropStatus[5]}</Badge>
                      :
                      property?.statusArray?.includes(newPropStatus[3]) ?
                        <Badge color="danger" className="ms-1">{newPropStatus[3]}</Badge>
                        :
                        (property?.status === Status[0] ? (
                          <Badge className="ms-1" color="success">{propActiveStatus[0]}</Badge>
                        ) : (
                          <Badge color="danger" className="ms-1">{propActiveStatus[1]}</Badge>
                        ))
                }


              </CardText>
              {!property?.statusArray?.includes(newPropStatus[4]) &&
                <div>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      tag="a"
                      href="toggle"
                      onClick={(ev) => ev.preventDefault()}
                      className="dropdown-toggle btn btn-sm btn-icon btn-trigger me-n1"
                    >
                      <Icon name="more-h"></Icon>
                    </DropdownToggle>
                    <DropdownMenu end>
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <DropdownItem tag={Link} to={`/property-list/${property.id}`}>
                            <Icon name="edit"></Icon>
                            <span>Edit</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem tag="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${webURL}/find-the-properties/${property.referNo}`}>
                            <Icon name="user"></Icon>
                            <span>View as Client </span>
                          </DropdownItem>
                        </li>
                        <hr></hr>
                        <li style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '0 1.125rem',
                          fontSize: '12px',
                          fontWeight: 500,
                          color: '#526484',
                          transition: 'all 0.4s',
                          lineHeight: '1.3rem',
                          position: 'relative',
                        }}>

                          <UncontrolledDropdown>

                            <DropdownToggle
                              tag="a"
                              href="toggle"
                              onClick={(ev) => ev.preventDefault()}
                              className="dropdown-toggle btn btn-sm btn-icon me-n1 ml-3"
                            >

                              Featured...
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#item"
                                  onClick={(e) => handleToggle(e, "Featured")}
                                >
                                  <span>Featured</span>
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#item"
                                  onClick={(e) => handleToggle(e, "Deal of Week")}
                                >
                                  <span>Deal of the Week</span>
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#item"
                                  onClick={(e) => handleToggle(e, "Exclusive")}
                                >
                                  <span>Exclusive</span>
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#item"
                                  onClick={(e) => handleToggle(e, "Premium")}
                                >
                                  <span>Premium </span>
                                </DropdownItem>
                              </li>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                        <hr></hr>

                        <li>
                          <DropdownItem
                            tag="a"
                            href="#item"
                            onClick={(e) => handleEditStatusAll(e, "Sold")}
                          >
                            <span>
                              {property?.listingType === ListingType[0] ? "Mark Sold" : "Mark Rented"} </span>
                            {(property?.soldOrReserved === newPropStatus[0] || property?.soldOrReserved === newPropStatus[1]) &&
                              <Icon name="check-round-cut"></Icon>
                            }
                          </DropdownItem>
                        </li>
                        {!property?.soldDate &&
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#item"
                              onClick={(e) => handleEditStatusAll(e, "Reserved")}
                            >
                              <span>
                                {"Mark Reserved"}
                                {(property?.soldOrReserved === newPropStatus[2]) &&
                                  <Icon name="check-round-cut"></Icon>}
                              </span>
                            </DropdownItem>
                          </li>}
                        {!property?.soldDate && !property?.statusArray?.includes(newPropStatus[3]) && !property?.statusArray?.includes(newPropStatus[6]) &&

                          <li>
                            <DropdownItem
                              tag="a"
                              href="#item"
                              onClick={(e) => handleEditStatus(e, "")}
                            >
                              <span>
                                {property?.status === Status[0] ? 'Unpublish' : 'Publish'} </span>
                            </DropdownItem>
                          </li>}
                        {!property?.statusArray?.includes(newPropStatus[6]) &&
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#item"
                              onClick={(e) => handleEditStatusAll(e, "Private")}
                            >
                              <span>
                                {"Private"} </span>
                              {(property?.statusArray?.includes(newPropStatus[3])) &&
                                <Icon name="check-round-cut"></Icon>}
                            </DropdownItem>
                          </li>}
                        {/* { !property?.statusArray?.includes( newPropStatus[ 3 ]) &&
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#item"
                          onClick={ ( e ) => handleEditStatusAll( e, "Pocket" ) }
                        >
                          <span>
                            { "Pocket"} </span>
                            {(property?.statusArray?.includes( newPropStatus[ 6 ])) && 
                           <Icon name="check-round-cut"></Icon>}
                        </DropdownItem>
                      </li>} */}
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#item"
                            onClick={(e) => setShowCancelModal(true)}
                          >
                            <span>
                              {"Cancel"} </span>
                          </DropdownItem>
                        </li>

                      </ul>
                    </DropdownMenu>

                  </UncontrolledDropdown>
                </div>
              }
            </div>
            <Link to={`/property-list/${property.id}`}>
              <CardTitle
                tag="h5"
                style={{
                  height: "2rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {property?.propertyTitle}
              </CardTitle>
            </Link>

            <Row>
              <Col sm="9">
                <CardText className="custom-margin-bottom">
                  <span className="custom-p-property ">
                    <Icon name="location"></Icon> {property?.emirate},{" "}
                    {property?.community?.community}{" "}
                  </span>{" "}
                </CardText>
                <CardText className="custom-margin-bottom">
                  <span className="custom-p-property ">
                    <Icon name="building"></Icon> Building : {" "}
                    {property?.buildingName}{" "}
                  </span>{" "}
                </CardText>
                <CardText className="custom-margin-bottom">
                  <span className="custom-margin-bottom" style={{ whiteSpace: "nowrap" }}>
                    <Icon name="money"></Icon>
                    {property?.listingType === ListingType[1] ? " Price per Year: AED " + formatValue(Math.round(price)) : " Price: AED " + formatValue(Math.round(price))}


                  </span>
                </CardText>
                <CardText className="custom-margin-bottom">
                  <span className="custom-margin-bottom" style={{ whiteSpace: "nowrap" }}>
                    <Icon name="money"></Icon>
                    {property?.listingType === ListingType[1] ? " Price per Month: AED " + formatValue(Math.round(monthPrice)) : " Price per Month:"}
                    {/* Price per Month: AED{ " " }
                { monthPrice && formatValue( Math.round( monthPrice ) ) }{ " " } */}
                  </span>
                </CardText>

                {/* <CardText className="custom-margin-bottom">
              <Icon name="user"></Icon> Owner Name :{ " " }
              { `${ property?.propertyOwner?.first_name } ${ property?.propertyOwner?.last_name }` }{ " " }
            </CardText> */}
                <CardText className="custom-margin-bottom">
                  <Icon name="building"></Icon> Purpose : {property?.listingType}
                </CardText>
                <CardText className="custom-margin-bottom">
                  <Icon name="property-add"></Icon> Type : {property?.propertyType?.propertyType}
                </CardText>
                <CardText className="custom-margin-bottom">
                  <Icon name="home"></Icon> Beds : {property?.beds}
                </CardText>
                <CardText className="custom-margin-bottom">
                  <Icon name="home-alt"></Icon> Baths : {property?.baths}
                </CardText>     <CardText className="custom-margin-bottom">
                  <Icon name="building"></Icon> Area :  {property?.size} sqft
                </CardText>
              </Col>
              <Col sm="3" className="d-flex justify-content-end align-items-end">
                <div
                  className="user-avatar-group"
                  onClick={() => {
                    if (authuser?.role && authuser.role !== newRoles?.Agent) {
                      toggleModal();
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {property?.agent ? (
                    <>
                      <div >
                        <Button
                          type="button"
                          id={`id-${index}`}
                        >
                          {/* <UserAvatar
                            key={ property?.agent?.user?.id }
                            className="md"
                            text={ property?.agent?.user?.first_name }
                            image={ imgAgentUrl }
                          ></UserAvatar> */}
                          <NewAgentAvatar
                            agent={property?.agent}
                            size="md"
                          />
                        </Button>

                        <Tooltip

                          isOpen={tooltipOpen}
                          target={`id-${index}`}
                          toggle={toggleTooltip}
                          title='Hello'
                          content="hello"
                        >
                          {property?.agent?.user?.first_name} {property?.agent?.user?.last_name}
                        </Tooltip>
                      </div>
                    </>
                  ) : (
                    "Assign"
                  )}
                </div>
                <Modal isOpen={isOpen} toggle={toggleModal}>
                  <ModalHeader toggle={toggleModal}>Select Agent</ModalHeader>
                  <ModalBody>
                    <RSelect
                      value={optDefaultEditAgent}
                      onChange={handleAgentSelect}
                      options={optEditAgents}
                      isSearchable
                    />
                  </ModalBody>
                  <ModalFooter className="border-0">
                    <Button className="bg-primary text-white" onClick={handleAssignAgent}>
                      Assign
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row>

          </CardBody>
        </Card>
      </div>

      {/* ****** Modal Start ****** */}
      {showModal ? (

        <ConfirmModal
          msg={modalMessage}
          name={"Property ID:" + propReferId}
          open={showModal}
          handleClick={handleModal}
          handleConfirm={submitConfirm}
          handlecloseModal={closeModal}
          loading={isUpdateLoading}
        />
      ) : null}
      {/* ****** Modal End ****** */}
      {/* ****** Modal Start ****** */}
      {showCancelModal ? (
        <CancelModal
          propId={property?.id}
          open={showCancelModal}
          handlecloseModal={setShowCancelModal}
          handleConfirm={handleConfirmCancel}
        />
      ) : null}
      {/* ****** Modal End ****** */}
    </>
  );
};
export default PropertyCard;
