export const GET_COMMUNITY_TEAMS_STARTED = "GET_COMMUNITY_TEAMS_STARTED";
export const GET_COMMUNITY_TEAMS = "GET_COMMUNITY_TEAMS";
export const GET_COMMUNITY_TEAMS_FAILED = "GET_COMMUNITY_TEAMS_FAILED";
export const GET_COMMUNITY_TEAMS_FINISHED = "GET_COMMUNITY_TEAMS_FINISHED";

export const GET_COMMUNITY_TEAM_STARTED = "GET_COMMUNITY_TEAM_STARTED";
export const GET_COMMUNITY_TEAM = "GET_COMMUNITY_TEAM";
export const GET_COMMUNITY_TEAM_FAILED = "GET_COMMUNITY_TEAM_FAILED";
export const GET_COMMUNITY_TEAM_FINISHED = "GET_COMMUNITY_TEAM_FINISHED";

export const ADD_COMMUNITY_TEAM_STARTED = "ADD_COMMUNITY_TEAM_STARTED";
export const ADD_COMMUNITY_TEAM = "ADD_COMMUNITY_TEAM";
export const ADD_COMMUNITY_TEAM_FAILED = "ADD_COMMUNITY_TEAM_FAILED";
export const ADD_COMMUNITY_TEAM_FINISHED = "ADD_COMMUNITY_TEAM_FINISHED";

export const UPDATE_COMMUNITY_TEAM_STARTED = "UPDATE_COMMUNITY_TEAM_STARTED";
export const UPDATE_COMMUNITY_TEAM = "UPDATE_COMMUNITY_TEAM";
export const UPDATE_COMMUNITY_TEAM_FAILED = "UPDATE_COMMUNITY_TEAM_FAILED";
export const UPDATE_COMMUNITY_TEAM_FINISHED = "UPDATE_COMMUNITY_TEAM_FINISHED";

export const DELETE_COMMUNITY_TEAM_STARTED = "DELETE_COMMUNITY_TEAM_STARTED";
export const DELETE_COMMUNITY_TEAM = "DELETE_COMMUNITY_TEAM";
export const DELETE_COMMUNITY_TEAM_FAILED = "DELETE_COMMUNITY_TEAM_FAILED";

export const ADD_TEAM_MEMBER_STARTED = "ADD_TEAM_MEMBER_STARTED";
export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER";
export const ADD_TEAM_MEMBER_FAILED = "ADD_TEAM_MEMBER_FAILED";

export const REMOVE_TEAM_MEMBER_STARTED = "REMOVE_TEAM_MEMBER_STARTED";
export const REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER";
export const REMOVE_TEAM_MEMBER_FAILED = "REMOVE_TEAM_MEMBER_FAILED";

export const ADD_TEAM_COMMUNITY_STARTED = "ADD_TEAM_COMMUNITY_STARTED";
export const ADD_TEAM_COMMUNITY = "ADD_TEAM_COMMUNITY";
export const ADD_TEAM_COMMUNITY_FAILED = "ADD_TEAM_COMMUNITY_FAILED";

export const REMOVE_TEAM_COMMUNITY_STARTED = "REMOVE_TEAM_COMMUNITY_STARTED";
export const REMOVE_TEAM_COMMUNITY = "REMOVE_TEAM_COMMUNITY";
export const REMOVE_TEAM_COMMUNITY_FAILED = "REMOVE_TEAM_COMMUNITY_FAILED";