/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import ErrorHandler from "../error-handlers/axios/defaultErrorHandler";


/**
 * @typedef {Object} CheckoutLeadsPayload
 * @property {string[]} cards - The list of property cards to checkout.
 * @property {string[] | undefined} leads - The list of leads to checkout.
 */

/**
 * @typedef {Object} CheckoutLeadType
 * @property {string} id - The unique identifier for the lead.
 * @property {string | Date} createdAt - The date and time when the lead was created.
 * @property {string | Date} updatedAt - The date and time when the lead was last updated.
 * @property {string} firstName - The first name of the lead.
 * @property {string} lastName - The last name of the lead.
 * @property {string} email - The email address of the lead.
 * @property {string} phone - The phone number of the lead.
 * @property {string} role - The role of the lead.
 * @property {string|null} country - The country of the lead.
 * @property {string|null} city - The city of the lead.
 * @property {string|null} address - The address of the lead.
 * @property {string|null} nationality - The nationality of the lead.
 * @property {string|null} photo - The photo URL of the lead.
 * @property {boolean} active - Whether the lead is active.
 * @property {string|null} providerId - The provider ID of the lead.
 * @property {string|null} provider - The provider of the lead.
 * @property {Array<string>} refreshToken - The refresh tokens of the lead.
 * @property {string} leadSource - The source of the lead.
 * @property {string} leadStatus - The status of the lead.
 * @property {string} lastActivityType - The type of the last activity.
 * @property {string} lastActivityDate - The date and time of the last activity.
 * @property {boolean} lastActivityIsComplete - Whether the last activity is complete.
 * @property {Array.<string>} languages - The languages spoken by the lead.
 * @property {string} createdBy - The ID of the user who created the lead.
 * @property {string|null} firstNote - The note associated with the lead.
 * @property {Array.<string>} role_duplicate - Roles of the user.
 * @property {string} currentAgent - The ID of the current agent assigned to the lead.
 * @property {string|null} userPBXNumbers - The PBX numbers of the user.
 * @property {string|null} extension - The extension number of the lead.
 */

/**
 * @typedef {Object} CheckoutLeadsResponse
 * @property {Array.<CheckoutLeadType>} uniqueLeadsToAssign - The list of unique leads assigned.
 * @property {number} propertyCardsCount - The number of property cards assigned.
 * @property {number} leadsCount - The number of leads assigned.
 */

/**
 * @description Fetching leads with property cards
 * @param {Axios} axiosInstance
 * @param {CheckoutLeadsPayload} payload 
 * @returns {CheckoutLeadsResponse} apiResponse
 */
export const checkoutExplorerLeads = async (axiosInstance, payload) => {
  try {
    const res = await axiosInstance.post(`property-cards/checkout-leads`, payload);

    return res?.data?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
}