import { Button, Card, CardImg, Col, Input, Label, Row, Spinner } from "reactstrap";
import { Block, Icon } from "../../../components/Component";
import Head from "../../../layout/head/Head";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useForm } from "react-hook-form";
import uuid from "react-uuid";
import useDragAndDrop from "../../../utils/hooks/useDragAndDrop";
import InputFile from "./InputFile";
import { uploadManyToS3 } from "../../../utils/envConfig";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";


const AddOffPlanStepFive = (props) => {
    const navigate = useNavigate();
    const axios = useAxiosPrivate();
  let {
    development,
    setDevelopment,
    finalePlanDetails,
    setFinalPlanDetails,
    filesToUpload,
    setFilesToUpload,
    edit=false
  } = props;

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const {
    active: activeHeaderVideo,
    errorMessage: headerVideoStatusMessage,
    dragenter: headerVideoDragEnter,
    dragleave: headerVidoDragLeave,
    drop: headerDropVideo,
    handleFileChange: headerVideoChange,
    files: headerVideoFiles,
    removeImage: removerHeaderVideo,
  } = useDragAndDrop({
    multiple: false,
    inputType: "video",
  });

  const {
    active: activeHeader,
    errorMessage: headerStatusMessage,
    dragenter: headerDragEnter,
    dragleave: headerDragLeave,
    drop: headerDrop,
    handleFileChange: headerImageChange,
    files: headerFiles,
    removeImage: removerHeaderImage,
  } = useDragAndDrop({
    multiple: false,
    inputType: "image",
  });
  const {
    active: brochureActive,
    errorMessage: brochureStatus,
    dragenter: brochureDragEnter,
    dragleave: brochureDragLeave,
    drop: brochureDrop,
    handleFileChange: brochureFileChange,
    files: brochureFiles,
    removeImage: removeBrochureImage,
  } = useDragAndDrop({
    inputType: "pdf",
  });

  const {
    active: topViewActive,
    errorMessage: topViewStatus,
    dragenter: topViewDragEnter,
    dragleave: topViewDragLeave,
    drop: topViewDrop,
    handleFileChange: topViewFileChange,
    files: topViewFiles,
    removeImage: removeTopViewImage,
  } = useDragAndDrop({
    inputType: "image",
  });

  const {
    active: mapActive,
    errorMessage: mapStatus,
    dragenter: mapDragEnter,
    dragleave: mapDragLeave,
    drop: mapDrop,
    handleFileChange: mapFileChange,
    files: mapFiles,
    removeImage: removeMapImage,
  } = useDragAndDrop({
    inputType: "image",
  });
  const {
    active: priceActive,
    errorMessage: priceStatus,
    dragenter: priceDragEnter,
    dragleave: priceDragLeave,
    drop: priceDrop,
    handleFileChange: priceFileChange,
    files: priceFiles,
    removeImage: removePriceImage,
  } = useDragAndDrop({
    inputType: "image",
  });
  const addOtherFilesToFinal = async () => {
    const video = headerVideoFiles[0]?.file;
    const headerImage = headerFiles[0]?.file;
    const topViewImg = topViewFiles[0]?.file;
    const mapImg = mapFiles[0]?.file;
    const priceImg = priceFiles[0]?.file;
    const brochure = brochureFiles[0]?.file;

    if (
      // !video ||
      !headerImage ||
      // !topViewImg ||
      // !mapImg ||
      !priceImg ||
      !brochure
    )
      return toast.error("Please add all required files");

    let filesToUploadNew = [];

    const planDetails = {};

    if (video) {
      const fileName = `offPlans/videos/${uuid()}.${video?.name
        ?.split(".")
        ?.pop()}`;

      filesToUploadNew.push({
        type: "video/*",
        file: video,
        fileName,
      });

      planDetails.video = fileName;
    }

    if (headerImage) {
      const fileName = `offPlans/otherImages/${uuid()}.${headerImage?.name
        ?.split(".")
        ?.pop()}`;

      filesToUploadNew.push({
        type: "image/*",
        file: headerImage,
        fileName,
      });

      planDetails.headerImage = fileName;
    }

    if (topViewImg) {
      const fileName = `offPlans/otherImages/${uuid()}.${topViewImg?.name
        ?.split(".")
        ?.pop()}`;

      filesToUploadNew.push({
        type: "image/*",
        file: topViewImg,
        fileName,
      });

      planDetails.topImage = fileName;
    }

    if (mapImg) {
      const fileName = `offPlans/otherImages/${uuid()}.${mapImg?.name
        ?.split(".")
        ?.pop()}`;

      filesToUploadNew.push({
        type: "image/*",
        file: mapImg,
        fileName,
      });

      planDetails.map = fileName;
    }

    if (priceImg) {
      const fileName = `offPlans/otherImages/${uuid()}.${priceImg?.name
        ?.split(".")
        ?.pop()}`;

      filesToUploadNew.push({
        type: "image/*",
        file: priceImg,
        fileName,
      });

      planDetails.priceImage = fileName;
    }

    if (brochure) {
      const fileName = `offPlans/otherImages/${uuid()}.${brochure?.name
        ?.split(".")
        ?.pop()}`;

      filesToUploadNew.push({
        type: "application/pdf",
        file: brochure,
        fileName,
      });

      planDetails.brochure = fileName;
    }

    setFilesToUpload((prev) => ({ ...prev, others: filesToUploadNew }));
let finalFilesToUpload ={ ...filesToUpload, others: filesToUploadNew }
let finalData ={ ...finalePlanDetails,...planDetails}


    await handleFinalSave(finalFilesToUpload,finalData);
  };

  const handleFinalSave = async (finalFilesToUpload,finalData) => {
    try {
      setSubmitDisabled(true);

      const toBeUploaded = [
        ...finalFilesToUpload.amenities,
        ...finalFilesToUpload.devPhotos,
        ...finalFilesToUpload.others,
        finalFilesToUpload?.devLogo,
      ];


      // if (!development.keywords || !development.metaDescription)
      //     return alert("Please fill all fields");

      const uploadRes = await uploadManyToS3(toBeUploaded,axios);

      if (!uploadRes) throw new Error("Upload failed");
   
 
      const payload = {
        ...finalData,
        metaDescription: development.metaDescription ?? "",
        metaTitle: development?.metaTitle,
      };

      if (development.keywords) {
        payload.keywords = development?.keywords;
      }
   
      console.log(payload)
      const { data } = await axios.post(`/off-plan`, payload);
      toast.success("Successfully Added offplan");
      navigate("/off-plans-list");

    } catch (err) {
      // console.error(err?.response);
      toast.error(err.message);
    } finally {
      setSubmitDisabled(false);
    }
  };
  const { errors, register, handleSubmit } = useForm();



  const [uploadedVideo, setUploadedVideo] = useState([]);
  const handleDropVideo = (acceptedFiles) => {
    setUploadedVideo([...uploadedVideo, ...acceptedFiles]);
  };
  const handleDeleteVideo = (file) => {
    const updatedVideo = uploadedVideo.filter(
      (uploadedFile) => uploadedFile !== file
    );
    setUploadedVideo(updatedVideo);
  };
  ////

  const [uploadedImages, setUploadedImages] = useState([]);
  const handleDrop = (acceptedFiles) => {
    setUploadedImages([...uploadedImages, ...acceptedFiles]);
  };
  const handleDelete = (file) => {
    const updatedImages = uploadedImages.filter(
      (uploadedFile) => uploadedFile !== file
    );
    setUploadedImages(updatedImages);
  };
  ////
  const [uploadedPriceImages, setUploadedPriceImages] = useState([]);
  const handleDropPrice = (acceptedFiles) => {
    setUploadedPriceImages([...uploadedPriceImages, ...acceptedFiles]);
  };
  const handleDeletePrice = (file) => {
    const updatedImages = uploadedPriceImages.filter(
      (uploadedFile) => uploadedFile !== file
    );
    setUploadedPriceImages(updatedImages);
  };
  //////
  const [uploadedBrouchure, setUploadedBrouchure] = useState([]);
  const handleDropBrouchure = (acceptedFiles) => {
    setUploadedBrouchure([...uploadedBrouchure, ...acceptedFiles]);
  };
  const handleDeleteBrouchure = (file) => {
    const updatedImages = uploadedBrouchure.filter(
      (uploadedFile) => uploadedFile !== file
    );
    setUploadedBrouchure(updatedImages);
  };
  const submitForm = async(data) => {
    await addOtherFilesToFinal();

  };
  //////
  return (
    <React.Fragment>
      <Head title="Off Plan"></Head>
      <Block>
        <form onSubmit={handleSubmit(submitForm)}>
          <Row className="gy-4">
            <Col sm="12">
              <Label>Header Video *</Label>
              <div>
                <InputFile
                  active={activeHeaderVideo}
                  dragenter={headerVideoDragEnter}
                  dragleave={headerVidoDragLeave}
                  drop={headerDropVideo}
                  errorMessage={headerVideoStatusMessage}
                  files={headerVideoFiles}
                  handleFileChange={headerVideoChange}
                  inputType="video"
                  removeImage={removerHeaderVideo}
                  inputProps={{ accept: "video/*" }}
                />
                {/* <Dropzone onDrop={ handleDropVideo }>
                                    { ( { getRootProps, getInputProps } ) => (
                                        <section>
                                            <div
                                                { ...getRootProps() }
                                                className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                                            >
                                                <Input { ...getInputProps( { accept: "video/mp4" } ) } />
                                                <div className="dz-message">
                                                    <span className="dz-message-text">
                                                        <span>Drag and drop</span> video here or <span>browse</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </section>
                                    ) }
                                </Dropzone> */}
                {headerVideoFiles.length > 0 && (
                  <section>
                    <Label className="mt-2 mb-2">Uploaded Video</Label>
                    <div className="image-list">                 
                        <LazyLoadComponent key={headerVideoFiles[0]?.file?.name}>
                          <Card
                            className="position-relative"
                            style={{ width: "15rem" }}
                          >
                            <video
                              src={URL.createObjectURL(headerVideoFiles[0]?.file)}
                              alt={headerVideoFiles[0]?.file?.name}
                            />
                            <Button
                              className="position-absolute"
                              style={{ top: "0", left: "0" }}
                              color="danger"
                              onClick={() => removerHeaderVideo(0)}
                            >
                              <Icon name="trash"> </Icon>
                            </Button>
                          </Card>
                        </LazyLoadComponent>
              
                    </div>
                  </section>
                )}
              </div>
            </Col>
            <Col sm="12">
              <Label>Header Image *</Label>
              <div>
                <InputFile
                  active={activeHeader}
                  dragenter={headerDragEnter}
                  dragleave={headerDragLeave}
                  drop={headerDrop}
                  errorMessage={headerStatusMessage}
                  files={headerFiles}
                  handleFileChange={headerImageChange}
                  inputProps={{ accept: "image/*" }}
                  inputType="image"
                  removeImage={removerHeaderImage}
                />
                {/* <Dropzone onDrop={ handleDrop }>
                                    { ( { getRootProps, getInputProps } ) => (
                                        <section>
                                            <div
                                                { ...getRootProps() }
                                                className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                                            >
                                                <input { ...getInputProps( { accept: 'image/*' } ) } />
                                                <div className="dz-message">
                                                    <span className="dz-message-text">
                                                        <span>Drag and drop</span> image here or <span>browse</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </section>
                                    ) }
                                </Dropzone> */}
                {headerFiles?.length > 0 && (
                  <section>
                    <Label className="mt-2 mb-2">Uploaded Image</Label>
                    <div className="image-list">
                   
                        <LazyLoadComponent key={headerFiles[0]?.file.name}>
                          <Card
                            className="position-relative"
                            style={{ width: "15rem" }}
                          >
                            <CardImg
                              src={URL.createObjectURL(headerFiles[0]?.file)}
                              alt={headerFiles[0]?.file.name}
                            />

                            <Button
                              className="position-absolute"
                              style={{ top: "0", left: "0" }}
                              color="danger"
                              onClick={() => removerHeaderImage(0)}
                            >
                              <Icon name="trash"> </Icon>
                            </Button>
                          </Card>
                        </LazyLoadComponent>
          
                    </div>
                  </section>
                )}
              </div>
            </Col>
            <Col sm="12">
              <Label>Price Image *</Label>
              <div>
                {/* <Dropzone onDrop={ handleDropPrice }>
                                    { ( { getRootProps, getInputProps } ) => (
                                        <section>
                                            <div
                                                { ...getRootProps() }
                                                className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                                            >
                                                <input { ...getInputProps( { accept: 'image/*' } ) } />
                                                <div className="dz-message">
                                                    <span className="dz-message-text">
                                                        <span>Drag and drop</span> image here or <span>browse</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </section>
                                    ) }
                                </Dropzone> */}
                <InputFile
                  active={priceActive}
                  dragenter={priceDragEnter}
                  dragleave={priceDragLeave}
                  drop={priceDrop}
                  errorMessage={priceStatus}
                  files={priceFiles}
                  handleFileChange={priceFileChange}
                  inputProps={{ accept: "image/*" }}
                  inputType="image"
                  removeImage={removePriceImage}
                />

                {priceFiles.length > 0 && (
                  <section>
                    <Label className="mt-2 mb-2">Uploaded Image</Label>
                    <div className="image-list">
                     
                        <LazyLoadComponent key={priceFiles[0]?.file.name}>
                          <Card
                            className="position-relative"
                            style={{ width: "15rem" }}
                          >
                            <CardImg
                              src={URL.createObjectURL(priceFiles[0]?.file)}
                              alt={priceFiles[0]?.file.name}
                            />

                            <Button
                              className="position-absolute"
                              style={{ top: "0", left: "0" }}
                              color="danger"
                              onClick={() => removePriceImage(0)}
                            >
                              <Icon name="trash"> </Icon>
                            </Button>
                          </Card>
                        </LazyLoadComponent>
                
                    </div>
                  </section>
                )}
              </div>
            </Col>
            <Col sm="12">
              <Label>Development Brochure</Label>
              <div>
                {/* <Dropzone onDrop={ handleDropBrouchure }>
                                    { ( { getRootProps, getInputProps } ) => (
                                        <section>
                                            <div
                                                { ...getRootProps() }
                                                className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                                            >
                                                <input { ...getInputProps( { accept: 'file/*' } ) } />
                                                <div className="dz-message">
                                                    <span className="dz-message-text">
                                                        <span>Drag and drop</span> image here or <span>browse</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </section>
                                    ) }
                                </Dropzone> */}
                <InputFile
                  active={brochureActive}
                  dragenter={brochureDragEnter}
                  dragleave={brochureDragLeave}
                  drop={brochureDrop}
                  errorMessage={brochureStatus}
                  files={brochureFiles}
                  handleFileChange={brochureFileChange}
                  inputType="pdf"
                  removeImage={removeBrochureImage}
                  inputProps={{ accept: "application/pdf" }}
                />
                {brochureFiles?.length > 0 && (
                  <section>
                    <Label className="mt-2 mb-2">Uploaded Brochure</Label>
                    <div className="image-list">
                    
                        <LazyLoadComponent key={brochureFiles[0]?.file?.name}>
                          <Card
                            className="position-relative"
                            style={{ width: "15rem" }}
                          >
                            {/* <CardImg
                              src={URL.createObjectURL(brochureFiles[0]?.file)}
                              alt={brochureFiles[0]?.file?.name}
                            /> */}
                                 <iframe
                            src={URL.createObjectURL(brochureFiles[0]?.file)}
                            title="PDF Viewer"
                            // width="100%"
                            // height="500px"
                          />

                            <Button
                              className="position-absolute"
                              style={{ top: "0", right: "0" }}
                              color="danger"
                              onClick={() => removeBrochureImage(0)}
                            >
                              <Icon name="trash" > </Icon>
                            </Button>
                          </Card>
                        </LazyLoadComponent>
                    
                    </div>
                  </section>
                )}
              </div>
            </Col>
          </Row>
          <div className="actions clearfix">
          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-between">
              <li>
                <Button color="primary" type="submit">
                {submitDisabled ? (
                          <>
                              <Spinner size="sm" color="light" />
                              
                          </>
                      ) : (
                          "Save"
                      )}
                </Button>
              </li>
              <li>
                <Button color="light" onClick={props.prev} disabled>
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </Block>
    </React.Fragment>
  );
};
export default AddOffPlanStepFive;
