import React, { useEffect, useRef, useState } from 'react';
// import ReactToPrint from "react-to-print";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
    differenceInDays,
    endOfMonth,
    intervalToDuration,
    startOfMonth,
    subDays,
    subMonths
} from 'date-fns';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import {
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon
} from '../../components/Component';
import DatePicker from 'react-datepicker';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';
import ListingPerformaceSecMarket from './components/ListingPerformaceSecMarket';
import ListingPerformacePriMarket from './components/ListingPerformancePriMarket';

import { MarketTypes } from '../../utils/envConfig';

export default function ListingPerformance() {
    const printRef = useRef();

    // dates
    const today = new Date();

    const [dateError, setDateError] = useState('');

    const [smBtn, setSmBtn] = useState();

    const [market, setMarket] = useState(MarketTypes.Listing);

    const [monthEnd, setMonthEnd] = useState(today);
    const [monthStart, setMonthStart] = useState(subDays(today, 29)); //getting data for the past 30 days

    const exportPDF = () => {
        const doc = printRef.current;

        html2canvas(doc).then((canvas) => {
            const data = canvas.toDataURL('application/pdf');
            const pdf = new jsPDF();
            pdf.addImage(data, 'pdf', 0, 0);

            pdf.save('test.pdf');
        });
    };

    useEffect(() => {
        const timeout = dateError
            ? setTimeout(() => {
                  setDateError('');
              }, 3000)
            : undefined;

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [dateError]);

    return (
        <>
            <Head title="Listing Performance Dashboard" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle className="mb-3" page>
                                Listing Performance Dashboard
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                    <div className="d-flex justify-content-end align-items-end">
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <a
                                    href="#toggle"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        setSmBtn(!smBtn);
                                    }}
                                    className="btn btn-icon btn-trigger toggle-expand me-n1"
                                >
                                    <Icon name="menu-alt-r"></Icon>
                                </a>
                                <div
                                    className={`toggle-expand-content ${
                                        smBtn ? 'expanded' : ''
                                    }`}
                                >
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    color="transparent"
                                                    className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                                                >
                                                    {market}
                                                </DropdownToggle>
                                                <DropdownMenu start>
                                                    <ul className="link-list-opt no-bdr">
                                                        <li>
                                                            <DropdownItem
                                                                onClick={() =>
                                                                    setMarket(
                                                                        MarketTypes.Offplan
                                                                    )
                                                                }
                                                            >
                                                                <span>
                                                                    Primary
                                                                    Market
                                                                </span>
                                                            </DropdownItem>
                                                        </li>
                                                        <li>
                                                            <DropdownItem
                                                                onClick={() =>
                                                                    setMarket(
                                                                        MarketTypes.Listing
                                                                    )
                                                                }
                                                            >
                                                                <span>
                                                                    Secondary
                                                                    Market
                                                                </span>
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </li>
                                        <li>
                                            <div className="form-control-wrap">
                                                <button
                                                    className="btn bg-primary text-white m-1"
                                                    onClick={() => {
                                                        setMonthStart(
                                                            startOfMonth(today)
                                                        );
                                                        setMonthEnd(
                                                            endOfMonth(today)
                                                        );
                                                    }}
                                                >
                                                    Current Month
                                                </button>
                                                <button
                                                    className="btn bg-secondary text-white "
                                                    onClick={async () => {
                                                        const lastMonthStart =
                                                            startOfMonth(
                                                                subMonths(
                                                                    today,
                                                                    1
                                                                )
                                                            );
                                                        const lastWeekEnd =
                                                            endOfMonth(
                                                                subMonths(
                                                                    today,
                                                                    1
                                                                )
                                                            );
                                                        setMonthStart(
                                                            lastMonthStart
                                                        );
                                                        setMonthEnd(
                                                            lastWeekEnd
                                                        );
                                                    }}
                                                >
                                                    Last Month
                                                </button>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-group">
                                                <div className="form-control-wrap position-relative">
                                                    <div className="text-danger fs-sm text-center position-absolute start-50 bottom-100 translate-middle-x w-100">
                                                        {dateError}
                                                    </div>
                                                    <div className="input-daterange date-picker-range input-group">
                                                        <DatePicker
                                                            selected={
                                                                monthStart
                                                            }
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }} // prevent user from typing in date
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                const interval =
                                                                    differenceInDays(
                                                                        monthEnd,
                                                                        date
                                                                    );

                                                                if (
                                                                    interval >=
                                                                    31
                                                                ) {
                                                                    setDateError(
                                                                        'Interval cannot be more than 31 days'
                                                                    );
                                                                    return;
                                                                }
                                                                setMonthStart(
                                                                    date
                                                                );
                                                            }}
                                                            selectsStart
                                                            startDate={
                                                                monthStart
                                                            }
                                                            endDate={monthEnd}
                                                            maxDate={monthEnd}
                                                            wrapperClassName="start-m"
                                                            className="form-control"
                                                        />
                                                        <div className="input-group-addon">
                                                            TO
                                                        </div>
                                                        <DatePicker
                                                            selected={monthEnd}
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }} // prevent user from typing in date
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                const interval =
                                                                    differenceInDays(
                                                                        date,
                                                                        monthStart
                                                                    );

                                                                if (
                                                                    interval >=
                                                                    31
                                                                ) {
                                                                    setDateError(
                                                                        'Interval cannot be more than 31 days'
                                                                    );
                                                                    return;
                                                                }

                                                                setMonthEnd(
                                                                    date
                                                                );
                                                            }}
                                                            startDate={
                                                                monthStart
                                                            }
                                                            endDate={monthEnd}
                                                            selectsEnd
                                                            minDate={monthStart}
                                                            wrapperClassName="end-m"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </div>
                </BlockHead>

                {market === MarketTypes.Listing ? (
                    <ListingPerformaceSecMarket
                        monthEnd={monthEnd}
                        monthStart={monthStart}
                    />
                ) : market === MarketTypes.Offplan ? (
                    <ListingPerformacePriMarket
                        monthEnd={monthEnd}
                        monthStart={monthStart}
                    />
                ) : (
                    <div>Please select a market type</div>
                )}
            </Content>
        </>
    );
}
