import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Icon } from "../../../components/Component";
import { useSelector } from "react-redux";
import { newRoles } from "../../../utils/envConfig";

const DraggableList = ({ amenities,setAmenities,propertyUpdate=()=>null }) => {

  const authuser = useSelector( ( state ) => state?.user?.loggedInUser );

    const handleDragEnd = (result) => {
        if (!result.destination) {
          return; // Return early if no valid destination
        }
      
        const { source, destination } = result;
      
        if (source.droppableId !== destination.droppableId || source.index === destination.index) {
          return; // Return early if there's no actual change in the ordering
        }
      
        // Reorder amenities based on the drag and drop operation
        const updatedAmenities = Array.from(amenities);
        const [removed] = updatedAmenities.splice(source.index, 1);
        updatedAmenities.splice(destination.index, 0, removed);
        let newAmenities = updatedAmenities.map((ele, i) => {
          return {
            "amenityId": ele.id,
            "quantity": ele.quantity,
            "priority": `${i}`
          };
        });
        let amenityIds= updatedAmenities.map((ele, i) => {
          return ele.id
        });
        propertyUpdate({amenityObjs:newAmenities,amenityIds:amenityIds});
        setAmenities(updatedAmenities); // Update the state with the new ordering
      };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
    <ul className="data-list is-compact">
      <Droppable droppableId="data-list">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {amenities?.map((item, index) => (
              <Draggable key={index} draggableId={`item-${index}`} index={index}>
                {(provided) => (
                  <li
                  ref={provided.innerRef}
                  {...provided.dragHandleProps}
                  {...(authuser?.role && authuser.role !== newRoles.Agent ? provided.draggableProps : {})}
                    className="data-item"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",                                        
                      ...provided.draggableProps.style // Preserve draggable styles
                    }}
                  >
                    <div style={{ flex: "1", minWidth: "0", maxWidth: "43%" }}>
                      {item.amenity}
                    </div>
                    <div style={{ flex: "1", minWidth: "0", maxWidth: "43%" }}>
                      <div className="data-value">{item.quantity}</div>
                    </div>
                    <div style={{ flex: "1", minWidth: "0", maxWidth: "14%" }}>
                      <Icon name={"move"} />
                    </div>
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </ul>
  </DragDropContext>

  );
};

export default DraggableList;
