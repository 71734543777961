import React from 'react';

const PropertyMeta = ({ property }) => {
  return (
    <div className="product-meta">
      <ul className="d-flex g-3 gx-5">
        <li>
          <div className="fs-14px text-muted">Type</div>
          <div className="fs-16px fw-bold text-secondary">{property?.propertyType.propertyType}</div>
        </li>
        <li>
          <div className="fs-14px text-muted">Purpose</div>
          <div className="fs-16px fw-bold text-secondary">{property?.listingType}</div>
        </li>
        <li>
          <div className="fs-14px text-muted">Beds</div>
          <div className="fs-16px fw-bold text-secondary">{property?.beds}</div>
        </li>
        <li>
          <div className="fs-14px text-muted">Baths</div>
          <div className="fs-16px fw-bold text-secondary">{property?.baths}</div>
        </li>
        <li>
          <div className="fs-14px text-muted">Area</div>
          <div className="fs-16px fw-bold text-secondary">{property.size} sqft</div>
        </li>
        <li>
          <div className="fs-14px text-muted">ID</div>
          <div className="fs-16px fw-bold text-secondary">{property?.referNo}</div>
        </li>
      </ul>
    </div>
  );
};

export default PropertyMeta;
