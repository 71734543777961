import ListPocketListingUI from "./components/ListPocketListingUI";

const ListPocketListing = () => {
  return (
    <>
      <ListPocketListingUI />
    </>
  );
};

export default ListPocketListing;
