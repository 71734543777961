import React from "react";
import AgentsDashboard from "./AgentsDashboard";
import { useState } from "react";
import { useEffect } from "react";

import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
    BlockHeadContent,
    BlockTitle,
    RSelect,
} from "../../components/Component";
import {
    Button,
    Col,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import { fetchAgents } from "../../redux/actions/user";
import { useDispatch } from "react-redux";
import SelectAgentCard from "./components/SelectAgentCard";

export default function AgentBoardByAdmin() {
    const [selectedAgent, setSelectedAgent] = useState(null);

    const [agent, setAgent] = useState(null);

    const [showModal, setShowModal] = useState(false);

    const axios = useAxiosPrivate();

    const authuser = useSelector((state) => state?.user?.loggedInUser);

    const agents = useSelector((state) => state?.user?.agents);

    const [search, setSearch] = useState("");

    const filteredAgents =
        !agents || !agents?.length
            ? []
            : agents?.filter(
                  (agent) =>
                      (agent?.user?.first_name
                          ?.toLowerCase()
                          ?.includes(search?.toLowerCase()) ||
                          agent?.user?.last_name
                              ?.toLowerCase()
                              ?.includes(search?.toLowerCase())) &&
                      agent?.user?.active
              );

    const dispatch = useDispatch();

    const getSelectedAgent = () => {
        const defaultAgent = localStorage.getItem("default_agent");

        const selectedAgent = localStorage.getItem("selected_agent");

        setSelectedAgent(
            selectedAgent
                ? JSON.parse(selectedAgent)
                : defaultAgent
                ? JSON.parse(defaultAgent)
                : null
        );
    };

    useEffect(() => {
        getSelectedAgent();

        !agents?.length && dispatch(fetchAgents(axios));
        return () => {
            localStorage.removeItem("selected_agent");
        };
    }, []);

    const handleDefaultAgent = () => {
        localStorage.setItem(
            "default_agent",
            JSON.stringify({
                ...agent,
                photo: agent?.user?.photo,
            })
        );
        setSelectedAgent({
            ...agent,
            photo: agent?.user?.photo,
        });
        setShowModal(false);
    };

    const handleNormalAgent = () => {
        localStorage.setItem(
            "selected_agent",
            JSON.stringify({
                ...agent,
                photo: agent?.user?.photo,
            })
        );
        setSelectedAgent({
            ...agent,
            photo: agent?.user?.photo,
        });
        setShowModal(false);
    };

    return (
        <>
            {!selectedAgent ? (
                <>
                    <Head title="Agent Dashboard" />
                    <Content>
                        <BlockHeadContent>
                            <BlockTitle page>Please Select An Agent</BlockTitle>
                        </BlockHeadContent>

                        <Row className="g-gs">
                            <Col xs="12">
                                <div className="pb-4 pt-4">
                                    <Input
                                        placeholder="Search An Agent"
                                        className="p-2"
                                        value={search}
                                        onChange={(e) =>
                                            setSearch(e.target.value)
                                        }
                                    />
                                </div>
                            </Col>
                            {filteredAgents?.map((agent) => (
                                <SelectAgentCard
                                    photo={agent?.user?.photo}
                                    subText={agent?.user?.email}
                                    title={`${agent?.user?.first_name} ${agent?.user?.last_name}`}
                                    key={agent?._id ?? agent?.id}
                                    onClickSelect={() => {
                                        setAgent(agent);
                                        setShowModal(true);
                                    }}
                                    phone={agent?.user?.phone}
                                />
                            ))}
                        </Row>

                        <Modal
                            isOpen={showModal}
                            className="modal-dialog-centered"
                            size="lg"
                            toggle={() => setShowModal(false)}
                        >
                            <ModalHeader toggle={() => setShowModal(false)}>
                                Set Agent As Default?
                            </ModalHeader>

                            <ModalBody>
                                <p>
                                    Dashboard will automatically load this
                                    agent's data when you want to view agents
                                    dashboard by default.
                                </p>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="secondary"
                                    onClick={handleNormalAgent}
                                >
                                    Not now
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={handleDefaultAgent}
                                >
                                    Yes. Make default.
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Content>
                </>
            ) : (
                <AgentsDashboard
                    agent={{
                        ...selectedAgent,
                        label: `${selectedAgent?.user?.first_name} ${selectedAgent?.user?.last_name}`,
                        value: `${selectedAgent?.user?.first_name} ${selectedAgent?.user?.last_name}`,
                    }}
                    handleSelectAgent={(agent) => {
                        setSelectedAgent({
                            ...agent,
                            photo: agent?.user?.photo,
                        });

                        localStorage.setItem(
                            "selected_agent",
                            JSON.stringify({
                                ...agent,
                                photo: agent?.user?.photo,
                            })
                        );
                    }}
                    handleNormalAgent={handleNormalAgent}
                />
            )}
            {/* <div
                className={`p-4 rounded-md border border-neutral-300 shadow-md max-w-[300px] mx-auto fixed left-1/2 -translate-x-1/2 z-10 bg-white transition-all duration-300 ease-linear ${
                    showModal ? "top-10" : "-top-full"
                }`}
            >
                <p className="px-2 py-4 text-center font-semibold">
                    Set this agent as default agent?
                </p>
                <div className="flex items-center gap-2">
                    <button
                        className="p-2 bg-red-500 rounded-md text-white flex-1"
                        onClick={handleNormalAgent}
                    >
                        No
                    </button>
                    <button
                        className="p-2 bg-success rounded-md text-white flex-1"
                        onClick={handleDefaultAgent}
                    >
                        Yes
                    </button>
                </div>
            </div> */}
        </>
    );
}
