import ListCommunityTeamsUI from "./components/ListCommunityTeamsUI";

const ListCommunityTeams = () => {
  return (
    <>
      <ListCommunityTeamsUI />
    </>
  );
};

export default ListCommunityTeams;
