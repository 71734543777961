import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Row, Col } from "reactstrap";
import { Icon } from "../../../../components/Component";
const type = "Image"; // Need to pass which type element can be draggable

const Image = ( { image, index, moveImage } ) =>
{
  const ref = useRef( null );

  const [ { isOver }, drop ] = useDrop( {
    accept: type,
    collect: ( monitor ) => ( {
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    } ),
    drop ( item )
    {
      if ( !ref.current )
      {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if ( dragIndex === hoverIndex )
      {
        return;
      }
      moveImage( dragIndex, hoverIndex );
      item.index = hoverIndex;
    },
  } );

  const [ { isDragging }, drag ] = useDrag( () => ( {
    type: type,
    item: { id: image.id, index },
    collect: ( monitor ) =>
    {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  } ) );

  drag( drop( ref ) );

  return (
      <div
        ref={ ref }
        style={ {
          opacity: isDragging ? 0.3 : 1,
          border: isOver ? "red solid 2px" : "",
          height: "15rem",
          width: "15rem"          
        } }
      >
        <img
          alt={ `img - ${ image.id }` }
          src={ image.src }
          style={ {
            height: "100%",
            objectFit: "cover",
          } }
        />
      </div>
  );
};

const ImageList = ( { images, moveImage, deleteImage } ) =>
{
  const renderImage = ( image, index ) =>
  {
    return image ? (
      <>
        <div 
            style={ {
              position:"relative",
              // width:"100%",
            } }
        >       
            <Image
              image={ image }
              index={ index }
              key={ `${ image.id }-image` }
              moveImage={ moveImage }
            />
            <div
              style={ {
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: "1",
                padding: "0.1rem",
                backgroundColor: "#fceceb",
                borderRadius: "10%",
                cursor: "pointer",
              } }
              onClick={ ( e ) => deleteImage( e, index ) }
            >
              <Icon
                name="trash"
                style={ { color: "#e85347", fontSize: "1.3rem" } }
              />
            </div>    
        </div>
      </>
    ) : null;
  };

  return (
<div style={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}}>
        <div 
        style={ {
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)", // Adjust the number of columns as needed
          gap: "1rem",
        } } >
          { images?.map( renderImage ) }
        </div>
        </div>

  );
};

export default ImageList;
