import * as types from "../types/pbx";

const initialState = {
  callReportList: [],
  callReportListLoading: false,
  callReportListError: null
};

export const pbx = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CALL_REPORT_LIST_STARTED:
      return {
        ...state,
        callReportListLoading: true,
        callReportListError: null
      };
    case types.GET_CALL_REPORT_LIST:
      return {
        ...state,
        callReportList: action.payload,
        callReportListLoading: false,
        callReportListError: null
      };
    case types.GET_CALL_REPORT_LIST_FAILED:
      return {
        ...state,
        callReportListLoading: false,
        callReportListError: action.error
      };
    default:
      return state;
  }
}