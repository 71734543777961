import React from 'react';
import CardShimmer from './CardShimmer';
import DealCard from './DealCard';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
export default function DealsPendingGrid({
    deals = [],
    fetching = false,
    title = 'Deals Pending This Month'
}) {
    return (
        <div className="right-section deal-item-grid card-inner">
            <div className="right-section bg-white h-full">
                <div className="kanban-title-content">
                    <h6 className="title">{title}</h6>
                    <Badge className="text-dark" pill color="outline-light">
                        {deals?.length ?? 0}
                    </Badge>
                </div>
                <div className="deal-card pt-3 ">
                    {fetching ? (
                        <CardShimmer numberOfCards={5} />
                    ) : deals?.length ? (
                        deals
                            ?.slice(0, 5)
                            ?.map((deal) => (
                                <DealCard
                                    key={deal?.id ?? deal?._id}
                                    deal={deal}
                                />
                            ))
                    ) : (
                        <div className="flex-1 w-full flex gap-1 items-center justify-center text-neutral-500">
                            <p>No deals pending for this month</p>
                            <Link
                                to={'/list-deal'}
                                className="underline text-sm text-blue-500"
                            >
                                View Deals
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
