/**
 * @typedef {Object} FilterPillsProps
 * @property {import('../types').DealTransactionQueryState} queryParams
 * @property {React.Dispatch<React.SetStateAction<import('../types').DealTransactionQueryState>>} setQueryParams
 */

import { Badge } from 'reactstrap';
import { Icon } from '../../../../components/Component';
import { SORT_NAME_MAPPING } from '../constants';

/**
 * FilterPills component that renders a the pills when the user selects a filter.
 *
 * @param {FilterPillsProps} props - The properties for the component.
 * @returns {JSX.Element}
 */
const FilterPills = ({ queryParams, setQueryParams }) => {
  const isAnyFilterSet =
    queryParams?.agent ||
    queryParams?.search ||
    queryParams?.commissionStatus?.length > 0 ||
    queryParams?.category ||
    queryParams?.isMortgage ||
    queryParams?.sort?.name;

  return (
    <div
      className="d-flex flex-wrap justify-content-end my-3"
      style={{ gap: '.5rem' }}
    >
      {queryParams?.agent ? (
        <Badge color="primary" className='rounded p-1 ps-2'>
          <div
            className="d-flex justify-content-between"
            style={{ gap: '.5rem' }}
            role="button"
            onClick={() => {
              setQueryParams((prev) => ({
                ...prev,
                agent: null
              }));
            }}
          >
            <span style={{ fontSize: '12px' }}>Agent: {queryParams?.agent?.label}</span>
            <span
              className='d-flex align-items-center'
            >
              <Icon name={'cross'} />
            </span>
          </div>
        </Badge>
      ) : null}
      {queryParams?.search ? (
        <Badge color="info" className='rounded p-1 ps-2'>
          <div
            className="d-flex justify-content-between text-dark"
            style={{ gap: '.5rem' }}
            role="button"
            onClick={() => {
              setQueryParams((prev) => ({
                ...prev,
                search: null
              }));
            }}
          >
            <span style={{ fontSize: '12px' }}>Search: {queryParams?.search}</span>
            <span
              className='d-flex align-items-center'
            >
              <Icon name={'cross'} />
            </span>
          </div>
        </Badge>
      ) : null}
      {queryParams?.commissionStatus?.length > 0 ? (
        <Badge color="secondary" className="rounded p-1 ps-2">
          <div
            className="d-flex justify-content-between"
            style={{ gap: '.5rem' }}
            role="button"
            onClick={() => {
              setQueryParams((prev) => ({
                ...prev,
                commissionStatus: []
              }));
            }}
          >
            <span style={{ fontSize: '12px' }}>Status: {queryParams?.commissionStatus.join(' OR ')}</span>
            <span
              className='d-flex align-items-center'
            >
              <Icon name={'cross'} />
            </span>
          </div>
        </Badge>
      ) : null}
      {queryParams?.isMortgage ? (
        <Badge color="secondary" className="rounded p-1 ps-2">
          <div
            className="d-flex justify-content-between"
            style={{ gap: '.5rem' }}
            role="button"
            onClick={() => {
              setQueryParams((prev) => ({
                ...prev,
                isMortgage: null
              }));
            }}
          >
            <span style={{ fontSize: '12px' }}>Mortgage: {queryParams?.isMortgage ? 'Yes' : 'No'}</span>
            <span
              className='d-flex align-items-center'
            >
              <Icon name={'cross'} />
            </span>
          </div>
        </Badge>
      ) : null}
      {queryParams?.category ? (
        <Badge color="indigo" className="rounded p-1 ps-2">
          <div
            className="d-flex justify-content-between"
            style={{ gap: '.5rem' }}
            role="button"
            onClick={() => {
              setQueryParams((prev) => ({
                ...prev,
                category: null
              }));
            }}
          >
            <span style={{ fontSize: '12px' }}>Category: {queryParams?.category}</span>
            <span
              className="d-flex align-items-center"
            >
              <Icon name={'cross'} />
            </span>
          </div>
        </Badge>
      ) : null}
      {queryParams?.sort?.name ? (
        <Badge color="azure" className="rounded p-1 ps-2">
          <div
            className="d-flex align-items-center"
            style={{ gap: '.5rem' }}
            role="button"
            onClick={() => {
              setQueryParams((prev) => ({
                ...prev,
                sort: { name: null, order: 'DESC' }
              }));
            }}
          >
            <span style={{ fontSize: '12px' }}>
              Sorted by: {SORT_NAME_MAPPING[queryParams?.sort?.name]} - In{' '}
              {queryParams?.sort?.order === 'DESC' ? 'Descending' : 'Ascending'}{' '}
              Order
            </span>
            <span className="d-flex align-items-center">
              <Icon name={'cross'} />
            </span>
          </div>
        </Badge>
      ) : null}
      {isAnyFilterSet ? (
        <Badge color="danger" className="rounded p-1 ps-2">
          <div
            className="d-flex align-items-center"
            role="button"
            onClick={() => {
              setQueryParams((prev) => ({
                ...prev,
                agent: null,
                search: null,
                transactions: null,
                commissionStatus: null,
                category: null,
                isMortgage: null,
                sort: { name: null, order: 'DESC' }
              }));
            }}
          >
            <span style={{ fontSize: '12px' }}>Clear</span>
            <Icon name={'cross'} />
          </div>
        </Badge>
      ) : null}
    </div>
  );
};

export default FilterPills;
