/**
 * @typedef {Object} CardsTableColumnProps
 * @property {string} [className] - Additional CSS class names to apply.
 * @property {React.CSSProperties} [style] - Inline styles to apply.
 * @property {boolean} [isCheckbox] - Whether this column is a checkbox.
 * @property {boolean} [isActions] - Whether this column is for actions.
 * @property {Breakpoint | null} [breakpoint] - The responsive breakpoint.
 * @property {React.ReactNode | null} [children] - The children to render inside the column.
 */

/**
 * CardsTableColumn component renders a table column with various styling options.
 * 
 * @param {CardsTableColumnProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered table column.
 */
const CardsTableColumn = ({
  className,
  style,
  isCheckbox,
  isActions,
  breakpoint,
  ...props
}) => {
  return (
    <div
      className={`nk-tb-col ${className ? className : ""} ${
        isCheckbox ? "nk-tb-col-check" : ""
      } ${isActions ? "nk-tb-col-tools" : ""} ${
        breakpoint ? `tb-col-${breakpoint}` : ""
      }`}
      style={style ?? {}}
    >
      {props?.children ?? null}
    </div>
  );
};

export default CardsTableColumn;
