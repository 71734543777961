import { createSelector } from "@reduxjs/toolkit";


const selectCommunityTeam = state => state.communityTeam;

export const communityTeamsSelector = createSelector(
    [selectCommunityTeam],
    (communityTeamsState) => ({
        ...communityTeamsState
    })
)