import React, { useRef, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap';
import { Icon, RSelect } from '../../../components/Component';
import {
    AgencyType,
    DocumentTypes,
    uploadToS3,
    validateEmail
} from '../../../utils/envConfig';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import uuid from 'react-uuid';
import PhoneInput from '../../../components/input/contact-input/PhoneInput';
import EmailInput from '../../../components/input/email-input/EmailInput';

export default function AddAgency({
    callback = (agency) => {},
    toggle = () => {}
}) {
    const axios = useAxiosPrivate();

    const agenciesTypes = Object.values(AgencyType).map((item) => ({
        value: item,
        label: item
    }));

    const [errors, setErrors] = useState({});

    const [newAgency, setNewAgency] = useState();

    const [realEstateAgency, setRealEstateAgency] = useState({});

    const [selectedAgencyType, setSelectedAgencyType] = useState(null);

    const [tradeLicense, setTradeLicense] = useState(null);
    const [freeLancerReraImg, setFreeLancerReraImg] = useState(null);
    const [emiratesId, setEmiratesId] = useState(null);
    const [displayAvatar, setDisplayAvatar] = useState('');
    const [avatar, setAvatar] = useState('');
    const [displayOtherDocuments, setdisplayOtherDocuments] = useState('');
    const [otherDocuments, setotherDocuments] = useState('');
    const otherDocumentsRef = useRef(null);

    const [loading, setLoading] = useState(false);

    const handleAgencyTypeChange = (selectedOption) => {
        setSelectedAgencyType(selectedOption);
        setErrors((prev) => ({ ...prev, type: null }));
    };

    const handleNewAgencyChange = (e) => {
        const { name, value } = e?.target;

        setNewAgency((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleRealEstateAgencyChange = (e) => {
        try {
            const { name, value } = e?.target;
            setRealEstateAgency((prev) => ({
                ...prev,
                [name]: value
            }));
        } catch (err) {}
    };

    const handleFileInputChange = (name) => (event) => {
        const file = event.target.files[0];

        // Display the selected file name or image
        if (file) {
            name === 'agencyLicence'
                ? setTradeLicense(file)
                : name === 'reraRegistration'
                ? setFreeLancerReraImg(file)
                : name === 'emiratesId' && setEmiratesId(file);

            if (file.type.startsWith('image/')) {
                setDisplayAvatar(URL.createObjectURL(file));
            } else if (file.type === 'application/pdf') {
                setDisplayAvatar(file.name);
            } else {
                setDisplayAvatar('');
            }
            setAvatar(file);
        }
    };

    const handleFileInputChangeOtherDocuments = (event) => {
        const files = [...event?.target.files];

        // Display the selected file name or image
        if (files && files?.length) {
            const avatars = files?.map((file) => {
                return file?.type?.startsWith('image/')
                    ? { file: URL.createObjectURL(file), type: 'image' }
                    : { file: file?.name, type: 'other' };
            });

            setotherDocuments(files);
            setdisplayOtherDocuments(avatars);
        }
    };

    const handleRemoveDocuments = (i) => () => {
        const filteredAvatars = displayOtherDocuments?.filter(
            (doc, ind) => ind !== i
        );
        const filteredDocs = otherDocuments?.filter((doc, ind) => ind !== i);

        setdisplayOtherDocuments(filteredAvatars);

        setotherDocuments(filteredDocs);
    };

    const handleRemove = () => {
        setDisplayAvatar('');
        setAvatar(null);
        setTradeLicense(null);
        setFreeLancerReraImg(null);
        setEmiratesId(null);

        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const validateNewAgencyData = () => {
        const errors = {};
        if (selectedAgencyType?.value === AgencyType.RealEstateAgency) {
            if (!realEstateAgency.agencyName) {
                errors.agencyName = 'This field is required';
            }
            if (!realEstateAgency.agencyAddress) {
                errors.agencyAddress = 'This field is required';
            }
            if (!realEstateAgency.agencyPhone?.trim()?.replaceAll(' ', '')) {
                errors.agencyPhone = 'This field is required';
            }
            if (!tradeLicense) {
                errors.tradeLicense = 'This field is required';
            }
        }

        if (
            selectedAgencyType?.value === AgencyType.Referral ||
            selectedAgencyType?.value === AgencyType.Freelancer
        ) {
            if (!newAgency?.contactPerson) {
                errors.contactPerson = 'This field is required';
            }

            if (!newAgency?.contactPhone?.trim()?.replaceAll(' ', '')) {
                errors.contactPhone = 'This field is required';
            }

            if (!validateEmail(newAgency?.contactEmail?.trim())) {
                errors.contactEmail = 'Invalid email address';
            }

            if (!validateEmail(newAgency?.contactEmail?.trim())) {
                errors.contactEmail = 'This field is required';
            }

            if (
                selectedAgencyType?.value === AgencyType.Freelancer &&
                !freeLancerReraImg
            ) {
                errors.freeLancerReraImg = 'This field is required';
            }

            if (
                selectedAgencyType?.value === AgencyType.Referral &&
                !emiratesId
            ) {
                errors.emiratesId = 'This field is required';
            }
        }

        if (!selectedAgencyType?.value) {
            errors.type = 'This field is required';
        }

        return Object.keys(errors).length ? errors : false;
    };

    const addNewAgency = async () => {
        try {
            setLoading(true);

            const invalidData = validateNewAgencyData();

            if (invalidData) {
                setErrors(invalidData);
                return;
            }

            const params = {
                type: selectedAgencyType?.value,
                agency_name: realEstateAgency?.agencyName,
                agency_phone: realEstateAgency?.agencyPhone
                    ?.trim()
                    ?.replaceAll(' ', ''),
                agency_address: realEstateAgency?.agencyAddress,
                first_name: newAgency?.contactPerson,
                last_name: '',
                email: newAgency?.contactEmail?.trim(),
                phone: newAgency?.contactPhone?.trim()?.replaceAll(' ', ''),
                documentType:
                    selectedAgencyType.value === AgencyType.RealEstateAgency
                        ? DocumentTypes.TradeLicense
                        : selectedAgencyType.value === AgencyType.Freelancer
                        ? DocumentTypes.RERARegistration
                        : selectedAgencyType.value === AgencyType.Referral
                        ? DocumentTypes.EID
                        : null,
                document: null, // trade license or rera img or eid
                documents: [] // an array of other documents
            };

            // upload any avalaible documents
            if (selectedAgencyType?.value === AgencyType.RealEstateAgency) {
                const fileName = `/agencies/license-${uuid()}.${tradeLicense?.name
                    ?.split('.')
                    ?.pop()}`;

                await uploadToS3(
                    tradeLicense,
                    fileName,
                    tradeLicense?.type,
                    axios
                );

                params.document = fileName;
            }

            if (selectedAgencyType.value === AgencyType.Freelancer) {
                const fileName = `/agencies/rera-${uuid()}.${freeLancerReraImg?.name
                    ?.split('.')
                    ?.pop()}`;

                await uploadToS3(
                    freeLancerReraImg,
                    fileName,
                    freeLancerReraImg?.type,
                    axios
                );

                params.document = fileName;
            }

            if (selectedAgencyType.value === AgencyType.Referral) {
                const fileName = `/agencies/eid-${uuid()}.${emiratesId?.name
                    ?.split('.')
                    ?.pop()}`;

                await uploadToS3(emiratesId, fileName, emiratesId?.type, axios);

                params.document = fileName;
            }

            if (otherDocuments?.length) {
                const documents = otherDocuments?.map((file) => ({
                    file,
                    type: file?.type ?? 'image/*',
                    fileName: `/agencies/docs-${uuid()}.${file?.name
                        ?.split('.')
                        .pop()}`
                }));

                await Promise.all(
                    documents?.map(
                        async (doc) =>
                            await uploadToS3(
                                doc?.file,
                                doc?.fileName,
                                doc?.type,
                                axios
                            )
                    )
                );

                params.documents = documents?.map((item) => ({
                    name: 'Other',
                    value: item?.fileName
                }));
            }

            // create a new agency with params then update selected agency data
            const { data: agency } = await axios.post('/agency', params);

            callback(agency);
            toggle();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <form>
                <Row className="gy-3">
                    <Col sm="12">
                        <Label>Agency Type</Label>
                        <RSelect
                            options={agenciesTypes}
                            onChange={handleAgencyTypeChange}
                            value={selectedAgencyType}
                            className={errors?.type && 'border border-danger'}
                        />
                        {errors?.type && (
                            <small className="text-danger ff-italic">
                                {errors?.type}
                            </small>
                        )}
                    </Col>
                    {selectedAgencyType &&
                        selectedAgencyType.value ===
                            AgencyType.RealEstateAgency && (
                            <Col sm="12">
                                <Card className="card-bordered  bg-lighter">
                                    <div className="card-inner">
                                        <Row className="gy-1">
                                            <Col sm="12">
                                                <Label>Agency Name</Label>
                                                <Input
                                                    type="text"
                                                    placeholder="Agency Name *"
                                                    name="agencyName"
                                                    value={
                                                        realEstateAgency?.agencyName
                                                    }
                                                    onChange={
                                                        handleRealEstateAgencyChange
                                                    }
                                                    className={
                                                        errors?.agencyName &&
                                                        'border border-danger'
                                                    }
                                                />
                                                {errors?.agencyName && (
                                                    <small className="text-danger ff-italic">
                                                        {errors?.agencyName}
                                                    </small>
                                                )}
                                            </Col>
                                            <Col sm="12">
                                                <Label>Agency Address</Label>
                                                <Input
                                                    type="text"
                                                    placeholder="Agency Address"
                                                    name="agencyAddress"
                                                    value={
                                                        realEstateAgency?.agencyAddress
                                                    }
                                                    onChange={
                                                        handleRealEstateAgencyChange
                                                    }
                                                    className={
                                                        errors?.agencyAddress &&
                                                        'border border-danger'
                                                    }
                                                />
                                                {errors?.agencyAddress && (
                                                    <small className="text-danger ff-italic">
                                                        {errors?.agencyAddress}
                                                    </small>
                                                )}
                                            </Col>
                                            <Col sm="12">
                                                <Label>
                                                    Agency Phone Number
                                                </Label>

                                                <PhoneInput
                                                    defaultCountry="AE"
                                                    name="agencyPhone"
                                                    placeholder="Agency Phone Number"
                                                    value={
                                                        realEstateAgency?.agencyPhone
                                                    }
                                                    onChange={(phone) =>
                                                        setRealEstateAgency(
                                                            (prev) => ({
                                                                ...prev,
                                                                agencyPhone:
                                                                    phone
                                                            })
                                                        )
                                                    }
                                                    onError={(err) => {
                                                        setErrors((prev) => ({
                                                            ...prev,
                                                            agencyPhone: err
                                                        }));
                                                    }}
                                                    className={`phoneInput ${
                                                        errors?.agencyPhone &&
                                                        'border border-danger'
                                                    }`}
                                                />
                                                {errors?.agencyPhone && (
                                                    <small className="text-danger ff-italic">
                                                        {errors?.agencyPhone}
                                                    </small>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        )}
                    <Col sm="12">
                        <Label>
                            Person of Contact Name
                            {(selectedAgencyType?.value ===
                                AgencyType.Freelancer ||
                                selectedAgencyType?.value ===
                                    AgencyType.Referral) &&
                                '*'}
                        </Label>
                        <Input
                            type="text"
                            placeholder={`Person of Contact Name${
                                selectedAgencyType?.value ===
                                    AgencyType.Freelancer ||
                                selectedAgencyType?.value ===
                                    AgencyType.Referral
                                    ? '*'
                                    : ''
                            }`}
                            name="contactPerson"
                            value={newAgency?.contactPerson}
                            onChange={handleNewAgencyChange}
                            className={
                                errors?.contactPerson && 'border border-danger'
                            }
                        />
                        {errors?.contactPerson && (
                            <small className="text-danger ff-italic">
                                {errors?.contactPerson}
                            </small>
                        )}
                    </Col>
                    <Col sm="12">
                        <Label>Person of Contact Phone Number</Label>

                        <PhoneInput
                            className={`phoneInput ${
                                errors?.contactPhone && 'border border-danger'
                            }`}
                            defaultCountry="AE"
                            name="contactPhone"
                            placeholder="Person of Contact Phone Number"
                            value={newAgency?.contactPhone}
                            onChange={(phone) =>
                                setNewAgency((prev) => ({
                                    ...prev,
                                    contactPhone: phone
                                }))
                            }
                            onError={(err) => {
                                setErrors((prev) => ({
                                    ...prev,
                                    contactPhone: err
                                }));
                            }}
                        />
                        {errors?.contactPhone && (
                            <small className="text-danger ff-italic">
                                {errors?.contactPhone}
                            </small>
                        )}
                    </Col>
                    <Col sm="12">
                        <Label>Person of Contact Email</Label>
                        <EmailInput
                            type="text"
                            placeholder="Person of Contact Email"
                            name="contactEmail"
                            value={newAgency?.contactEmail}
                            onChange={handleNewAgencyChange}
                            onError={(err) => {
                                setErrors((prev) => ({
                                    ...prev,
                                    contactEmail: err
                                }));
                            }}
                            className={
                                errors?.contactEmail && 'border border-danger'
                            }
                        />
                        {errors?.contactEmail && (
                            <small className="text-danger ff-italic">
                                {errors?.contactEmail}
                            </small>
                        )}
                    </Col>
                    {selectedAgencyType &&
                        selectedAgencyType.value ===
                            AgencyType.RealEstateAgency && (
                            <Col sm="12">
                                <Col sm="12">
                                    <div className="form-group">
                                        <label className="form-label">
                                            Upload Trade License
                                        </label>
                                        <div className="form-control-wrap">
                                            <div className="form-file">
                                                <Input
                                                    type="file"
                                                    id="customFile"
                                                    name="agencyLicense"
                                                    onChange={handleFileInputChange(
                                                        'agencyLicence'
                                                    )}
                                                    className={
                                                        errors?.tradeLicense &&
                                                        'border border-danger'
                                                    }
                                                />
                                                {errors?.tradeLicense && (
                                                    <small className="text-danger ff-italic">
                                                        {errors?.tradeLicense}
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {displayAvatar && (
                                        <div>
                                            {avatar.type.startsWith(
                                                'image/'
                                            ) ? (
                                                <>
                                                    <p>Selected Image:</p>
                                                    <div className="position-relative">
                                                        <img
                                                            src={displayAvatar}
                                                            alt="Selected"
                                                            style={{
                                                                height: '8rem'
                                                            }}
                                                        />
                                                        <button
                                                            className="btn btn-danger position-absolute top-0 start-0"
                                                            onClick={
                                                                handleRemove
                                                            }
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </>
                                            ) : avatar.type ===
                                              'application/pdf' ? (
                                                <>
                                                    <p>
                                                        Selected PDF File:{' '}
                                                        {displayAvatar}
                                                    </p>
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={handleRemove}
                                                    >
                                                        Delete
                                                    </button>
                                                </>
                                            ) : null}
                                        </div>
                                    )}
                                </Col>
                            </Col>
                        )}
                    {selectedAgencyType &&
                        selectedAgencyType.value === AgencyType.Freelancer && (
                            <Col sm="12">
                                <Col sm="12">
                                    <div className="form-group">
                                        <label className="form-label">
                                            Upload RERA Registration
                                        </label>
                                        <div className="form-control-wrap">
                                            <div className="form-file">
                                                <Input
                                                    type="file"
                                                    id="customFile"
                                                    name="reraRegistration"
                                                    onChange={handleFileInputChange(
                                                        'reraRegistration'
                                                    )}
                                                    className={
                                                        errors?.freeLancerReraImg &&
                                                        'border border-danger'
                                                    }
                                                />
                                                {errors?.freeLancerReraImg && (
                                                    <small className="text-danger ff-italic">
                                                        {
                                                            errors?.freeLancerReraImg
                                                        }
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {displayAvatar && (
                                        <div>
                                            {avatar.type.startsWith(
                                                'image/'
                                            ) ? (
                                                <>
                                                    <p>Selected Image:</p>
                                                    <div className="position-relative">
                                                        <img
                                                            src={displayAvatar}
                                                            alt="Selected"
                                                            style={{
                                                                height: '8rem'
                                                            }}
                                                        />
                                                        <button
                                                            className="btn btn-danger position-absolute top-0 start-0"
                                                            onClick={
                                                                handleRemove
                                                            }
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </>
                                            ) : avatar.type ===
                                              'application/pdf' ? (
                                                <>
                                                    <p>
                                                        Selected PDF File:{' '}
                                                        {displayAvatar}
                                                    </p>
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={handleRemove}
                                                    >
                                                        Delete
                                                    </button>
                                                </>
                                            ) : null}
                                        </div>
                                    )}
                                </Col>
                            </Col>
                        )}
                    {selectedAgencyType &&
                        selectedAgencyType.value === AgencyType.Referral && (
                            <Col sm="12">
                                <Col sm="12">
                                    <div className="form-group">
                                        <label className="form-label">
                                            Upload Emirates ID
                                        </label>
                                        <div className="form-control-wrap">
                                            <div className="form-file">
                                                <Input
                                                    type="file"
                                                    id="customFile"
                                                    name="emiratesId"
                                                    onChange={handleFileInputChange(
                                                        'emiratesId'
                                                    )}
                                                    className={
                                                        errors?.emiratesId &&
                                                        'border border-danger'
                                                    }
                                                />
                                                {errors?.emiratesId && (
                                                    <small className="text-danger ff-italic">
                                                        {errors?.emiratesId}
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {displayAvatar && (
                                        <div>
                                            {avatar.type.startsWith(
                                                'image/'
                                            ) ? (
                                                <>
                                                    <p>Selected Image:</p>
                                                    <div className="position-relative">
                                                        <img
                                                            src={displayAvatar}
                                                            alt="Selected"
                                                            style={{
                                                                height: '8rem'
                                                            }}
                                                        />
                                                        <button
                                                            className="btn btn-danger position-absolute top-0 start-0"
                                                            onClick={
                                                                handleRemove
                                                            }
                                                            type="button"
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </>
                                            ) : avatar.type ===
                                              'application/pdf' ? (
                                                <>
                                                    <p>
                                                        Selected PDF File:{' '}
                                                        {displayAvatar}
                                                    </p>
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={handleRemove}
                                                        type="button"
                                                    >
                                                        Delete
                                                    </button>
                                                </>
                                            ) : null}
                                        </div>
                                    )}
                                </Col>
                            </Col>
                        )}
                    <Col sm="12">
                        <Col sm="12">
                            <div className="form-group">
                                <label className="form-label">
                                    Upload Other Documents
                                </label>
                                <div className="form-control-wrap">
                                    <div className="form-file">
                                        <Input
                                            type="file"
                                            id="customFile"
                                            ref={otherDocumentsRef}
                                            multiple={true}
                                            onChange={
                                                handleFileInputChangeOtherDocuments
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            {displayOtherDocuments &&
                            displayOtherDocuments?.length ? (
                                <div>
                                    <p>Selected Images:</p>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            gap: '1rem',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {displayOtherDocuments?.map(
                                            (avatar, i) =>
                                                avatar?.type === 'image' ? (
                                                    <div className="position-relative">
                                                        <img
                                                            src={avatar?.file}
                                                            alt="Selected"
                                                            style={{
                                                                height: '8rem'
                                                            }}
                                                        />
                                                        <button
                                                            className="btn btn-danger position-absolute top-0 start-0"
                                                            onClick={handleRemoveDocuments(
                                                                i
                                                            )}
                                                            type="button"
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <p>{avatar?.file}</p>
                                                        <button
                                                            className="btn btn-danger"
                                                            onClick={handleRemoveDocuments(
                                                                i
                                                            )}
                                                            type="button"
                                                        >
                                                            Delete
                                                        </button>
                                                    </>
                                                )
                                        )}
                                    </div>
                                </div>
                            ) : null}
                        </Col>
                    </Col>
                    <Col size="12">
                        <Button
                            color="primary"
                            type="button"
                            onClick={addNewAgency}
                            disabled={
                                Object.entries(errors)?.find(
                                    ([key, value]) => value || value?.length
                                ) || loading
                            }
                        >
                            <Icon name={!loading ? 'plus' : 'circle'}></Icon>
                            <span>
                                {!loading ? 'Add Agency' : 'Please Wait'}
                            </span>
                        </Button>
                    </Col>
                </Row>
            </form>
        </React.Fragment>
    );
}
