/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import ErrorHandler from "../error-handlers/axios/defaultErrorHandler";

/**
 * @description Fetching all Property Listings, no matter the status
 * @param {Axios} axiosInstance
 * @param {?import("axios").AxiosRequestConfig} options 
 * @returns {Promise<import("../global-types/property-list-types").PropertyListingType>} apiResponse
 */
export const fetchAllProperties = async (axiosInstance, options) => {
  try {
    const res = await axiosInstance.get('/propList', options);

    return res?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
}