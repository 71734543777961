/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import { fetchDealTransactions } from "../api/deals/transactions/fetchDealTransactions";
import { fetchAllTransactions } from "../api/deals/transactions/fetchAllTransactions";
import { createDealTransaction } from "../api/deals/transactions/createDealTransaction";
import { updateDealTransaction } from "../api/deals/transactions/updateDealTransaction";
import { deleteDealTransaction } from "../api/deals/transactions/deleteDealTransaction";
import { requestCsvFile } from "../api/deals/transactions/requestCSVFile";
import { fetchRecentDeals } from "../api/deals/fetchDealsByUser";
import { requestCsvFileDeals } from "../api/deals/deals-list/requestCSVFileDeals";

export const DEAL_KEYS = {
  all: ['deals'],
  byUser: 'userDeals',
  byAgent: 'agentDeals',
  transactions: {
    baseKey: 'dealTransactions',
    finances: 'finances',
    genCsv: 'generateCsv',
    all: ['dealTransactions'],
    byDeal: (dealId, options) => [...DEAL_KEYS.transactions.all, dealId, options],
    list: (params) => [...DEAL_KEYS.transactions.all, params],
  },
};

/**
 * An object containing queries related to deals.
 *
 * @namespace dealQueries
 */
const dealQueries = {
  /**
     * Fetches the N most recent deals for a specific user.
     *
     * @param {Axios} axios - The Axios instance to use for making requests.
     * @param {string} userId - The ID of the user to fetch deals for.
     * @param {number} mostRecent - The number of deals to fetch at most.
     * @param {?import("axios").AxiosRequestConfig} [options] - Optional query options (including params).
     * @returns {import('react-query').QueryConfig<any, Error, any>} - A React Query configuration object.
     */
  getMostRecentByUser: (axios, userId, mostRecent, options) => ({
    queryKey: [DEAL_KEYS.byUser, userId, mostRecent],
    queryFn: () => fetchRecentDeals(axios, userId, { ...options, params: { ...options?.params, mostRecent, noListings: 'true' } })
  }),
  /**
     * Fetches the N most recent deals for a specific agent.
     *
     * @param {Axios} axios - The Axios instance to use for making requests.
     * @param {string} agentId - The ID of the agent to fetch deals for.
     * @param {number} mostRecent - The number of deals to fetch at most.
     * @param {?import("axios").AxiosRequestConfig} [options] - Optional query options (including params).
     * @returns {import('react-query').QueryConfig<any, Error, any>} - A React Query configuration object.
     */
  getMostRecentByAgent: (axios, agentId, mostRecent, options) => ({
    queryKey: [DEAL_KEYS.byAgent, agentId, mostRecent],
    queryFn: () => fetchRecentDeals(axios, agentId, {
         ...options,
         params: { ...options?.params, mostRecent, noListings: 'true', isAgent: 'true' } 
      })
  }),
  Transactions: {
    /**
     * Fetches commission transactions for a specific deal.
     *
     * @param {Axios} axios - The Axios instance to use for making requests.
     * @param {string} dealId - The ID of the deal to fetch transactions for.
     * @param {?import("axios").AxiosRequestConfig} [options] - Optional query options (including params).
     * @returns {import('react-query').QueryConfig<any, Error, any>} - A React Query configuration object.
     */
    getByDeal: (axios, dealId, options) => ({
      queryKey: [DEAL_KEYS.transactions.baseKey, dealId, options?.params],
      queryFn: () => fetchDealTransactions(axios, dealId, options),
    }),
    /**
     * Fetches all commission transactions for all deals.
     *
     * @param {Axios} axios - The Axios instance to use for making requests.
     * @param {?import("axios").AxiosRequestConfig} [options] - Optional query options (including params).
     * @returns {import('react-query').QueryConfig<any, Error, any>} - A React Query configuration object.
     */
    list: (axios, options) => ({
      queryKey: [DEAL_KEYS.transactions.baseKey, options?.params],
      queryFn: () => fetchAllTransactions(axios, options),
    }),

    financeData: (axios, options) => ({
      queryKey: [DEAL_KEYS.transactions.finances, options?.params],
      queryFn: () => fetchAllTransactions(axios, options),
    }),
    /**
     * Creates a transaction for a specific deal.
     *
     * @param {Axios} axios - The Axios instance to use for making requests.
     * @param {string} dealId - The ID of the deal to create a transaction for.
     * @param {?import("../api/global-types/deal-transactions").DealTransactionDTO} [payload] - The payload sent to create the transaction.
     */
    create: (axios, dealId) => ({
      mutationFn: (payload) => createDealTransaction(axios, dealId, payload),
    }),
    /**
     * Update a transaction for a specific deal.
     *
     * @param {Axios} axios - The Axios instance to use for making requests.
     * @param {string} dealId - The ID of the deal to create a transaction for.
     * @param {string} transactionId - The ID of the transaction being updated.
     * @param {?Partial<import("../api/global-types/deal-transactions").DealTransactionDTO>} [payload] - The payload sent to create the transaction.
     */
    update: (axios, dealId, transactionId) => ({
      mutationFn: (payload) => updateDealTransaction(axios, dealId, transactionId, payload),
    }),
    /**
     * Deletes a transaction.
     *
     * @param {Axios} axios - The Axios instance to use for making requests.
     * @param {string} transactionId - The ID of the transaction being updated.
     */
    delete: (axios, transactionId) => ({
      mutationFn: () => deleteDealTransaction(axios, transactionId),
    }),

    /**
     * Sends a request to generate a CSV file based on the selected query
     *
     * @param {Axios} axios - The Axios instance to use for making requests.
     * @param {?import("axios").AxiosRequestConfig} [options] - Optional query options (including params).
     */
    requestCsv: (axios, options) => ({
      mutationFn: () => requestCsvFile(axios, options),
    })
  },

  DealsList: {
    /**
     * Sends a request to generate a CSV file based on the selected query
     *
     * @param {Axios} axios - The Axios instance to use for making requests.
     * @param {?import("axios").AxiosRequestConfig} [options] - Optional query options (including params).
     */
    requestCsv: (axios, options) => ({
      mutationFn: () => requestCsvFileDeals(axios, options),
    })
  },
};

export default dealQueries;
