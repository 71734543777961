import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AddLeadStepOne from "../../../leads/components/AddLeadStepOne";
const AddClientsModal = ({ isOpen, toggle, onCreated }) => {
  return (
    <Modal size="lg" isOpen={isOpen} backdrop="static">
      <ModalHeader toggle={() => toggle()}>Add New Client</ModalHeader>
      <ModalBody>
        <AddLeadStepOne
          callback={(data) => {
            onCreated(data);
            toggle()
          }}
          redirect={false}
        />
      </ModalBody>
    </Modal>
  );
};

export default AddClientsModal;
