import axios from 'axios';
import * as types from '../types';

export const fetchDnd = (data) => ({
    type: types.GET_DND,
    payload: data,
});

export const fetchDndData = ({ page, limit, query }) => async (dispatch) => {
    try {
        const url = `/dnd/dnd-phone-numbers`;
        const params = {
            page,
            limit,
            query,
        };

        const res = await axios.get(url, { params });
        dispatch(fetchDnd(res.data || {}));
    } catch (error) {
        console.error("Error fetching DND data:", error);
    }
};
