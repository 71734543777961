import React from "react";
import
{
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
    Filler,
    ArcElement,
} from "chart.js";
import { Line, Bar, Pie, PolarArea as Polar, Doughnut } from "react-chartjs-2";

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

// export const LineChartExample = ({ data, legend }) => {
//     return (
//         <Line
//             className="line-chart"
//             data={data}
//             options={{
//                 legend: {
//                     display: legend,
//                     labels: {
//                         boxWidth: 12,
//                         padding: 20,
//                         fontColor: "#6783b8",
//                     },
//                 },
//                 maintainAspectRatio: false,
//                 tooltips: {
//                     enabled: true,
//                     backgroundColor: "#eff6ff",
//                     titleFontSize: 13,
//                     titleFontColor: "#6783b8",
//                     titleMarginBottom: 6,
//                     bodyFontColor: "#9eaecf",
//                     bodyFontSize: 12,
//                     bodySpacing: 4,
//                     yPadding: 10,
//                     xPadding: 10,
//                     footerMarginTop: 0,
//                     displayColors: false,
//                 },
//                 scales: {
//                     yAxes: [
//                         {
//                             display: true,
//                             ticks: {
//                                 beginAtZero: false,
//                                 fontSize: 12,
//                                 fontColor: "#9eaecf",
//                                 padding: 10,
//                             },
//                             gridLines: {
//                                 tickMarkLength: 0,
//                             },
//                         },
//                     ],
//                     xAxes: [
//                         {
//                             display: true,
//                             ticks: {
//                                 fontSize: 12,
//                                 fontColor: "#9eaecf",
//                                 source: "auto",
//                                 padding: 5,
//                             },
//                             gridLines: {
//                                 color: "transparent",
//                                 tickMarkLength: 10,
//                                 offsetGridLines: true,
//                             },
//                         },
//                     ],
//                 },
//             }}
//         />
//     );
// };

// export const BarChartExample = ({ data, stacked }) => {
//     return (
//         <Bar
//             data={data}
//             options={{
//                 legend: {
//                     display: false,
//                     labels: {
//                         boxWidth: 30,
//                         padding: 20,
//                         fontColor: "#6783b8",
//                     },
//                 },
//                 maintainAspectRatio: false,
//                 tooltips: {
//                     enabled: true,
//                     backgroundColor: "#eff6ff",
//                     titleFontSize: 13,
//                     titleFontColor: "#6783b8",
//                     titleMarginBottom: 6,
//                     bodyFontColor: "#9eaecf",
//                     bodyFontSize: 12,
//                     bodySpacing: 4,
//                     yPadding: 10,
//                     xPadding: 10,
//                     footerMarginTop: 0,
//                     displayColors: false,
//                 },
//                 scales: {
//                     yAxes: [
//                         {
//                             display: true,
//                             stacked: stacked ? true : false,
//                             ticks: {
//                                 beginAtZero: true,
//                                 fontSize: 12,
//                                 fontColor: "#9eaecf",
//                                 padding: 5,
//                             },
//                             gridLines: {
//                                 tickMarkLength: 0,
//                             },
//                         },
//                     ],
//                     xAxes: [
//                         {
//                             display: true,
//                             stacked: stacked ? true : false,
//                             ticks: {
//                                 fontSize: 12,
//                                 fontColor: "#9eaecf",
//                                 source: "auto",
//                                 padding: 5,
//                             },
//                             gridLines: {
//                                 color: "transparent",
//                                 tickMarkLength: 10,
//                                 zeroLineColor: "transparent",
//                             },
//                         },
//                     ],
//                 },
//             }}
//         />
//     );
// };

// export const PieChartExample = ({ data }) => {
//     return (
//         <Pie
//             data={data}
//             options={{
//                 legend: {
//                     display: false,
//                 },
//                 rotation: -0.2,
//                 maintainAspectRatio: false,
//                 tooltips: {
//                     enabled: true,
//                     backgroundColor: "#eff6ff",
//                     titleFontSize: 13,
//                     titleFontColor: "#6783b8",
//                     titleMarginBottom: 6,
//                     bodyFontColor: "#9eaecf",
//                     bodyFontSize: 12,
//                     bodySpacing: 4,
//                     yPadding: 10,
//                     xPadding: 10,
//                     footerMarginTop: 0,
//                     displayColors: false,
//                 },
//             }}
//         />
//     );
// };

// export const DoughnutExample = ({ data }) => {
//     return (
//         <Doughnut
//             data={data}
//             options={{
//                 legend: {
//                     display: false,
//                 },
//                 rotation: 1,
//                 cutoutPercentage: 40,
//                 maintainAspectRatio: false,
//                 tooltips: {
//                     enabled: true,
//                     backgroundColor: "#eff6ff",
//                     titleFontSize: 13,
//                     titleFontColor: "#6783b8",
//                     titleMarginBottom: 6,
//                     bodyFontColor: "#9eaecf",
//                     bodyFontSize: 12,
//                     bodySpacing: 4,
//                     yPadding: 10,
//                     xPadding: 10,
//                     footerMarginTop: 0,
//                     displayColors: false,
//                 },
//             }}
//         />
//     );
// };

// export const PolarExample = ({ data }) => {
//     return (
//         <Polar
//             data={data}
//             options={{
//                 legend: {
//                     display: false,
//                 },
//                 maintainAspectRatio: false,
//                 tooltips: {
//                     enabled: true,
//                     backgroundColor: "#eff6ff",
//                     titleFontSize: 13,
//                     titleFontColor: "#6783b8",
//                     titleMarginBottom: 6,
//                     bodyFontColor: "#9eaecf",
//                     bodyFontSize: 12,
//                     bodySpacing: 4,
//                     yPadding: 10,
//                     xPadding: 10,
//                     footerMarginTop: 0,
//                     displayColors: false,
//                 },
//             }}
//         />
//     );
// };

const barChartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: "top",
        },
    },
};

const lineChartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: "top",
        },
    },
};

export const LineChart = ( {
    data = {
        labels: [],
        datasets: [],
    },
    yAxisName = "",
    xAxisName = "",
    options = lineChartOptions,
    className,
} ) =>
{
    return (
        <div>
            <Line

                options={ {
                    scales: {
                        ...options?.scales,
                        y: {
                            grid: {
                                color: "#fff",
                                lineWidth: 0.5,
                                ...options?.scales?.y?.grid,
                            },
                            axis: "y",
                            title: {
                                text: yAxisName,
                                display: true,
                                align: "center",
                                color: "#8094ae",
                                ...options?.scales?.y?.title,
                            },
                            beginAtZero: false,
                            ...options?.scales?.y,
                        },
                        x: {
                            grid: {
                                color: "#fff",
                                lineWidth: 0.0,
                                ...options?.scales?.x?.grid,
                            },
                            axis: "x",
                            title: {
                                text: xAxisName,
                                display: true,
                                align: "center",
                                color: "#8094ae",
                                ...options?.scales?.x?.title,
                            },
                            beginAtZero: false,
                            ticks: {
                                font: {
                                    size: 10,
                                },
                            },
                            ...options?.scales?.x,
                        },
                    },
                    plugins: {
                        ...options.plugins,
                    },
                    maintainAspectRatio: false,
                    ...options,
                } }
                data={ data }
                className={ ` ${ className }` }
            />
        </div>
    );
};

export const BarChart = ( {
    data = {
        labels: [],
        datasets: [],
    },
    options = barChartOptions,
    yAxisName,
    xAxisName,
    className,
    beginAtZero,
} ) => (
    <>
        <Bar
            className={ `${ className }` }
            data={ data }
            options={ {
                scales: {
                    y: {
                        grid: {
                            color: "#fff",
                            ...options?.scales?.y?.grid,
                        },
                        axis: "y",
                        title: {
                            text: yAxisName,
                            display: true,
                            align: "center",
                            color: "#8094ae",
                            ...options?.scales?.y?.title,
                        },
                        beginAtZero: beginAtZero,
                    },
                    x: {
                        grid: {
                            color: "#fff",
                            ...options?.scales?.x?.grid,
                        },
                        axis: "x",
                        title: {
                            text: xAxisName,
                            display: true,
                            align: "center",
                            color: "#8094ae",
                            ...options?.scales?.x?.title,
                        },
                        ticks: {
                            font: {
                                size: 10,
                            },
                        },
                        beginAtZero: beginAtZero,
                    },
                },

                ...options,
            } }
        />
    </>
);

export const PieChart = ( { data, className, options } ) =>
{
    return (
        <div style={ { height: "15rem" , paddingTop:"1rem" } }>


            <Pie
                data={ data }
                options={ {
                    rotation: -0.2,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            align: "center",
                            position: "bottom",
                        },
                    },
                } }
                className={ className }

            /></div>


    );
};
