import { ActivityStatus } from "../../utils/envConfig";

/**
 * Truncates the input text to a specified length, ensuring no words are cut off.
 * Optionally adds ellipses to indicate truncation.
 * 
 * @param {string} text - The text to truncate.
 * @param {number} maxLength - The maximum length of the truncated text.
 * @param {boolean} [useEllipses=true] - Whether to add ellipses ('...') to the end of the truncated text.
 * @returns {string} - The truncated text.
 */
function truncateText(text, maxLength, useEllipses = true) {
    // Validate input
    if (typeof text !== 'string') {
        throw new TypeError('Input text must be a string');
    }
    if (typeof maxLength !== 'number' || maxLength <= 0) {
        throw new TypeError('maxLength must be a positive number');
    }

    // Return the text as is if it doesn't need truncation
    if (text.length <= maxLength) {
        return text;
    }

    // Adjust maxLength to account for ellipses if needed
    const ellipses = useEllipses ? '...' : '';
    const adjustedMaxLength = useEllipses ? maxLength - ellipses.length : maxLength;

    // Find the position to truncate the text without cutting off words
    let truncatedText = text.slice(0, adjustedMaxLength);
    const lastSpaceIndex = truncatedText.lastIndexOf(' ');

    // If there's a space before the max length, truncate at the last space
    if (lastSpaceIndex > 0) {
        truncatedText = truncatedText.slice(0, lastSpaceIndex);
    }

    return truncatedText + ellipses;
}

const getActivityBadgeColor = (status = '') => {
    switch (status) {
      case ActivityStatus.Pending:
        return "danger";
      case ActivityStatus.Overdue:
        return "warning";
      case ActivityStatus.Complete:
        return "success";
      case ActivityStatus.AutoCompleted:
        return "info";
      case ActivityStatus.Skipped:
        return "info";
      default:
        return "secondary"; // Default color if the status doesn't match any of the cases
    }
  };

export { truncateText, getActivityBadgeColor };
