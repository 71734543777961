import React, { useState, useRef } from 'react';
import LinkusSDK from './components/LinkusSDK';
import PBXLogin from './PBXLogin';

const PBXDialer = () => {
  const [credentials, setCredentials] = useState(null);
  const pbxURL = 'https://albacorp.ras.yeastar.com/openapi/v1.0/';
  const linkusRef = useRef(null);

  const handleLoginSuccess = (username, secret) => {
    setCredentials({ username, secret });
  };

  const handleCall = () => {
    const { phone } = linkusRef.current || {};
    if (phone) {
      phone.call({ number: '1001' });
    } else {
      console.error('Phone object not initialized');
    }
  };

  return (
    <div className="App">
      <h1>SDK WITH UI</h1>
      {credentials ? (
        <>
          <LinkusSDK ref={linkusRef} username={credentials.username} secret={credentials.secret} pbxURL={pbxURL} />
          <button onClick={handleCall}>Make Call</button>
        </>
      ) : (
        <PBXLogin onLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
};

export default PBXDialer;
