import * as types from "../types";
const initialState = {
    offplans_list: [],
    offplans:{},
    loading:false
};

export const offplans = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_OFFPLANS_LOADING:
            return {
                ...state,
                loading:payload
            }
        case types.GET_OFFPLANS:
            return {
                ...state,
                offplans_list: payload,
            };
            case types.GET_OFFPLANS_PAGINATED:
                return {
                    ...state,
                    offplans: payload,
                };
    
        default:
            return state;
    }
};
