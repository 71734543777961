import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchPropTypesList } from "../../../../../redux/actions/property";
import {
  ALLOWED_PROPERTY_TYPES,
  PROPERTY_TYPES,
} from "../../../../explorer/constats";
import { getPropTypeRegex } from "../../../../explorer/utils";
import { propertyTypesSelector } from "../../../../../redux/selectors/property";

const withExplorerPropTypesLogic = (WrappedComponent) => {
  return ({ multiSelect, onChangeCallback, selected, axiosInstance, dispatch, ...props }) => {
    const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);

    const { propertyTypes, propTypesLoading } = useSelector(propertyTypesSelector);

    useEffect(() => {
      const fetchAndSetPropertyTypes = async () => {
        if (!propertyTypes || !propertyTypes?.length) {
          await dispatch(fetchPropTypesList(axiosInstance));
        }
        if (propertyTypes && propertyTypes?.length) {
          const options = propertyTypes
            .filter((propType) =>
              ALLOWED_PROPERTY_TYPES.includes(propType?.propertyType)
            )
            .map((propType) => ({
              ...propType,
              label: propType?.propertyType,
              value: getPropTypeRegex(propType?.propertyType),
            }));
          setPropertyTypeOptions([
            {
              label: PROPERTY_TYPES.Commercial,
              value: getPropTypeRegex(PROPERTY_TYPES.Commercial),
            },
            ...options,
            { label: "Other...", value: getPropTypeRegex("Other") },
          ]);
        }
      };

      fetchAndSetPropertyTypes();
    }, [axiosInstance, dispatch, propertyTypes, propertyTypes?.length]);

    return (
      <WrappedComponent
        multiSelect={multiSelect}
        onChangeCallback={onChangeCallback}
        selected={selected}
        propTypesFilterOptions={propertyTypeOptions}
        propTypesLoading={propTypesLoading}
        {...props}
      />
    );
  };
};

export default withExplorerPropTypesLogic;
