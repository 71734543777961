import { useEffect, useState } from 'react';
import SecondaryMarketProperty from './SecondaryMarketProperty';
import PrimaryOffPlanProperty from './PrimaryOffPlanProperty';
import ListingAcquired from './ListingAcquired';
import { Col, Row } from 'reactstrap';
import { RSelect } from '../../../components/Component';
import { useForm } from 'react-hook-form';
import { DealCategory, newRoles } from '../../../utils/envConfig';
import { fetchAgents, fetchUsers } from '../../../redux/actions/user';
import { useSelector } from 'react-redux';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useDispatch } from 'react-redux';
import { fetchDeal } from '../../../redux/actions/deal';
import SecondaryMarketAgent from './SecondaryMarketAgent';
import ListingAcquiredAgent from './ListingAcquiredAgent';
import PrimaryOffPlanPropertyAgent from './PrimaryOffplanPropertyAgent';
const AddDealStepOne = (props) => {
    const [selectedDealType, setSelectedDealType] = useState(null);

    const axios = useAxiosPrivate();
    const users = useSelector((state) => state?.user?.users?.findUsersOutput);
    const loggedInUser = useSelector((state) => state?.user?.loggedInUser);

    const userRole = loggedInUser?.role;

    const dispatch = useDispatch();
    const handleDealTypeChange = (selectedOption) => {
        setSelectedDealType(selectedOption);
    };

    useEffect(() => {
        const deal = localStorage.getItem('new_deal');

        if (deal) {
            dispatch(fetchDeal(axios, deal?.id ?? deal?._id));
        }
    }, [props?.next]);

    const dealTypes = Object.values(DealCategory).map((item) => ({
        value: item,
        label: item
    }));

    const submitForm = (data) => {
        props.next();
    };

    useEffect(() => {
        dispatch(fetchAgents(axios));

        dispatch(fetchUsers(axios));
    }, []);

    const renderFormBasedOnDealType = () => {
        if (selectedDealType === null) {
            return null;
        } else if (selectedDealType.value === DealCategory.SecondaryProperty) {
            return userRole === newRoles.Agent ? (
                <SecondaryMarketAgent
                    dealType={selectedDealType?.value}
                    users={users}
                    next={submitForm}
                />
            ) : (
                <SecondaryMarketProperty
                    dealType={selectedDealType?.value}
                    users={users}
                    next={submitForm}
                />
            );
        } else if (selectedDealType.value === DealCategory.PrimaryOffplan) {
            return userRole === newRoles.Agent ? (
                <PrimaryOffPlanPropertyAgent
                    dealType={selectedDealType?.value}
                    users={users}
                    next={submitForm}
                />
            ) : (
                <PrimaryOffPlanProperty
                    dealType={selectedDealType?.value}
                    users={users}
                    next={submitForm}
                />
            );
        } else if (selectedDealType.value === DealCategory.ListingAcquired) {
            return userRole === newRoles.Agent ? (
                <ListingAcquiredAgent
                    dealType={selectedDealType?.value}
                    users={users}
                    next={submitForm}
                />
            ) : (
                <ListingAcquired
                    dealType={selectedDealType?.value}
                    users={users}
                    next={submitForm}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <form className="content clearfix">
            <Row className="gy-5">
                <Col sm={12} xxl="8" xxxl="6">
                    <div className="form-group">
                        <label className="form-label">
                            <h6>Choose Type of Deal</h6>
                        </label>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <RSelect
                                    options={dealTypes}
                                    onChange={handleDealTypeChange}
                                    value={selectedDealType}
                                />
                            </div>
                        </div>
                    </div>
                    {renderFormBasedOnDealType()}
                </Col>
            </Row>
        </form>
    );
};
export default AddDealStepOne;
