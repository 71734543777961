/**
 * @type {HeaderField[]}
*/
const HEADER_FIELDS = [
  { name: 'global-checkbox', breakpoint: null, isActions: false, isCheckbox: true },
  { name: 'Name', breakpoint: null, isActions: false, isCheckbox: false, first: true },
  { name: 'Lead Status', breakpoint: null, isActions: false, isCheckbox: false },
  { name: 'Lead Source', breakpoint: 'md', isActions: false, isCheckbox: false },
  { name: 'Created At', breakpoint: 'md', isActions: false, isCheckbox: false },
  { name: 'Total Property Cards', breakpoint: 'md', isActions: false, isCheckbox: false },
  { name: '', breakpoint: null, isActions: true, isCheckbox: false },
];

const STATUS_COLORS = {
  Unassigned: 'secondary',
  Fresh: 'primary',
  FollowUp: 'warning',
  Prospect: 'info',
  ForListing: 'info',
  Appointment: 'info',
  Viewing: 'info',
  Negotiating: 'info',
  Deal: 'success',
  Won: 'success',
  Lost: 'danger',
  Disqualified: 'danger'
};

const MIN_COL_WIDTH = '18rem';

export { HEADER_FIELDS, STATUS_COLORS, MIN_COL_WIDTH };