import { fetchAgentByUserId } from "../api/agents/fetchAgentByUserId";

const fetchUser = async ( userId, axios ) =>
{
    try
    {
        const { data } = await axios.get( `/users/${ userId }` );
        return data;
    } catch ( error )
    {
        throw error;
    }
};

export const USER_KEYS = {
    agent: {
      base: 'agent'
    }
};

const usersQueries = {
    user: ( userId, axios ) => ( {
        queryKey: [ 'user', userId ],
        queryFn: () => fetchUser( userId, axios ),
    } ),
    agent: (userId, axios) => ({
        queryKey: [USER_KEYS.agent.base, userId],
        queryFn: () => fetchAgentByUserId(axios, userId),
    })
};

export default usersQueries;