import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";

const PropImgSlider = ( { propImagesUrl, currentSlide, setCurrentSlide } ) =>
{



  const sliderSettingsDefault = {
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    slide: null,
    responsive: [
      { breakpoint: 1539, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 420, settings: { slidesToShow: 1 } },
    ],
    arrows: false,
    swipeToSlide: true,
    focusOnSelect: true,
    className: "slider-init slider-nav",
  };
  const [ sliderKey, setSliderKey ] = useState( 0 );
  const [ nav1, setNav1 ] = useState( null );
  const [ nav2, setNav2 ] = useState( null );

  const slider1 = useRef( null );
  const slider2 = useRef( null );

  useEffect( () =>
  {
    clearCache();
    setNav1( slider1.current );
    setNav2( slider2.current );

    return () =>
    {
      clearCache();
    };
  }, [] );


  // changes slides
  const slideChange = ( index ) =>
  {
    var product = propImagesUrl?.find( ( item ) => item.id === index );
    setCurrentSlide( product );
  };
  function clearCache ()
  {
    slider1.current = null;
    slider2.current = null;
    setSliderKey( 0 ); // increment the key to force remounting
  }

  // console.log( currentSlide );

  return (
    <>
      <Slider
        key={ `slider1-${ sliderKey }` }
        asNavFor={ nav2 }
        ref={ slider1 }
        arrows={ false }
        fade={ true }
        slidesToShow={ 1 }
        slidesToScroll={ 1 }
        initialSlide={ currentSlide?.id ?? 0 }
        className="slider-init"
        prevArrow
        centerMode={ true }
        centerPadding="0"
      >
        <div className="slider-item rounded" key={ currentSlide.id ?? 0 } style={ { position: "relative", height: "20rem" } }>

          <img src={ currentSlide.img ?? '../img/private-property.svg' } className="w-100 cus-prop-img" alt=""
            style={ { objectFit: "cover", objectPosition: "center" } }
          />

        </div>
      </Slider>
      { propImagesUrl?.length > 1 && (
        <Slider
          key={ `slider2-${ sliderKey }` }
          asNavFor={ nav1 }
          ref={ slider2 }
          afterChange={ ( newIndex ) => slideChange( newIndex ) }
          initialSlide={ currentSlide.id }
          { ...sliderSettingsDefault }
        >
          { propImagesUrl?.map( ( item ) =>
          {
            return (
              <div
                className={ `slider-item AED{ currentSlide.id === item.id ? "slick-current" : "" }` }
                key={ item.id }
              >
                <div className="thumb" style={ { position: "relative", height: "3rem" } }>
                  <img src={ item.img } alt="" style={ { position: "absolute", height: "100%", width: "100%", objectFit: "cover", objectPosition: "center" } } />
                </div>
              </div>
            );
          } ) }
        </Slider>
      ) }
    </>
  );
};

export default PropImgSlider;
