import classNames from "classnames";

/**
 * @typedef {Object} DropdownHeadProps
 * @property {string} [className] - Additional CSS class names to apply.
 * @property {React.CSSProperties} [style] - Inline styles to apply.
 * @property {React.ReactNode | null} [children] - The children to render inside the column.
 */

/**
 * DropdownHead component renders a the header of a dropdown menu.
 * 
 * @param {DropdownHeadProps} props - The properties for the component.
 * @returns {JSX.Element}
 */
const DropdownHead = ({ className, style, children }) => {
  const parsedClassName = classNames({
    "dropdown-head": true,
    [`${className}`]: className,
  })
  return (
    <div className={parsedClassName} style={style ?? {}}>
      {children}
    </div>
  );
};

export default DropdownHead;