import React, { Suspense, useCallback, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  RSelect
} from '../../../components/Component';
import EarningsHeader from './components/EarningsHeader';
import { Button } from 'reactstrap';
import LoadingFallback from './components/board-components/LoadingFallback';
import { financialTypeOptions } from './constants';
import { useQueryClient } from 'react-query';

export const delayImport = async (importFn, delay = 2000) => {
  return Promise.all([
    importFn(),
    new Promise((resolve) => setTimeout(resolve, delay))
  ]).then(([moduleExports]) => moduleExports);
};

const OffPlanDealsBoard = React.lazy(() =>
  import('./components/boards/off-plan/OffPlanDealsBoard')
);
const SecondaryRentalsDealsBoard = React.lazy(() =>
  import('./components/boards/secondary-rentals/SecondaryRentalsDealsBoard')
);
const SecondarySalesDealsBoard = React.lazy(() =>
  import('./components/boards/secondary-sales/SecondarySalesDealsBoard')
);

const DealsKanban = () => {
  const queryClient = useQueryClient();

  const [activeBoard, setActiveBoard] = useState('off-plans');
  const [filters, setFilters] = useState({ financialType: null });
  const [localState, setLocalState] = useState({
    financialType: undefined
  });

  const renderActiveBoard = () => {
    switch (activeBoard) {
      case 'off-plans':
        return <OffPlanDealsBoard filters={filters} />;
      case 'secondary-sales':
        return <SecondarySalesDealsBoard filters={filters} />;
      case 'secondary-rentals':
        return <SecondaryRentalsDealsBoard filters={filters} />;
      default:
        return null;
    }
  };

  const isOffPlanBoard = activeBoard === 'off-plans';
  const isSecondarySalesBoard = activeBoard === 'secondary-sales';
  const isSecondaryRentalsBoard = activeBoard === 'secondary-rentals';

  const clearFinancialType = useCallback(() => {
    setLocalState((prev) => ({
      ...prev,
      financialType: null
    }));
    setFilters((prev) => ({
      ...prev,
      financialType: null
    }));
  }, []);

  return (
    <React.Fragment>
      <Head title="Deals Kanban Board"></Head>
      <Content>
        <BlockHead size={'sm'}>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Deals Board</BlockTitle>
              <div className="mt-1">
                <div className="d-flex flex-wrap" style={{ gap: '.5rem' }}>
                  <Button
                    className={`border ${
                      isOffPlanBoard
                        ? 'border-primary bg-primary-dim text-primary'
                        : 'border-light text-dark bg-light'
                    }`}
                    size="sm"
                    onClick={() => {
                      clearFinancialType();
                      setActiveBoard('off-plans');
                    }}
                  >
                    Off-Plans
                  </Button>
                  <Button
                    className={`border ${
                      isSecondarySalesBoard
                        ? 'border-primary bg-primary-dim text-primary'
                        : 'border-light text-dark bg-light'
                    }`}
                    size="sm"
                    onClick={() => {
                      clearFinancialType();
                      setActiveBoard('secondary-sales');
                    }}
                  >
                    Sales
                  </Button>
                  <Button
                    className={`border ${
                      isSecondaryRentalsBoard
                        ? 'border-primary bg-primary-dim text-primary'
                        : 'border-light text-dark bg-light'
                    }`}
                    size="sm"
                    onClick={() => {
                      clearFinancialType();
                      setActiveBoard('secondary-rentals');
                    }}
                  >
                    Rentals
                  </Button>
                  <div className="">
                    <RSelect
                      className="status-div"
                      placeholder="Financial Type"
                      options={financialTypeOptions}
                      value={localState.financialType}
                      isClearable
                      onChange={(option) => {
                        setLocalState((prev) => ({
                          ...prev,
                          financialType: option
                        }));
                        setFilters((prev) => ({
                          ...prev,
                          financialType: option?.value
                        }));
                        // invalidate all queries, since this applies to all boards
                        queryClient.invalidateQueries();
                      }}
                    />
                  </div>
                </div>
              </div>
            </BlockHeadContent>
            <BlockHeadContent>
              <EarningsHeader />
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="nk-kanban" style={{ overflow: 'auto', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
            <Suspense fallback={<LoadingFallback />}>
              {renderActiveBoard()}
            </Suspense>
          </div>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default DealsKanban;
