import * as types from '../types'

const initialState = {
    agencies : []
}

export const agencies = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_AGENCIES:
            return {
                ...state,
                agencies: action.payload,
            };
        
        default:
            return state;
    }
};

