/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import ErrorHandler from "../../error-handlers/axios/defaultErrorHandler";
import qs from 'qs';

/**
 * @description Fetches all commission transactions for a specific deal
 * @param {Axios} axiosInstance - the axios instance for making the HTTP request.
 * @param {import("axios").AxiosRequestConfig?} options
 * @returns {Promise<Record<string, string>>} API Response.
 */
export const requestCsvFile = async (axiosInstance, options) => {
    try {
      const parsedQueryString = qs.stringify(options?.params ?? {}, { arrayFormat: 'brackets' });
      const res = await axiosInstance.get(`/deals/transactions/csv-gen?${parsedQueryString}`);
      return res?.data;
    } catch (error) {
      ErrorHandler.handle(error);
    }
}