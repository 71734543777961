import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { fetchGlobalSettings } from "../../../../../redux/actions/settings";
import LeadSettingsView from "./LeadSettingsView";
import LeadSettingsEdit from "./LeadSettingsEdit";

const LeadSettings = () => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const [isEditState, setIsEditLeadSettings] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const toggleRefresh = () => setRefresh(!refresh);

  useEffect(() => {
    dispatch(fetchGlobalSettings(axios));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const settingsState = useSelector((state) => ({
    globalSettings: state.settings.globalSettings,
    loading: state.settings.loading,
    error: state.settings.error,
  }));

  return settingsState && (isEditState ? (
    <LeadSettingsEdit
      settingsState={settingsState}
      setIsEditLeadSettings={setIsEditLeadSettings}
      refreshFn={toggleRefresh}
    />
  ) : (
    <LeadSettingsView
      settingsState={settingsState}
      setIsEditLeadSettings={setIsEditLeadSettings}
    />
  ));
};

export default LeadSettings;
