// keyframes animation in a style block
const keyframesStyle = `
  @keyframes blink {
    0% { opacity: .5; }
    50% { opacity: 1; }
    100% { opacity: .5; }
  }
`;

const BlinkingBadge = ({ text, ...props }) => {
  return (
    <span {...props}>
      <style>{keyframesStyle}</style>
      <span
        className="badge bg-danger"
        style={{
          animation: "blink 1s infinite",
        }}
      >
        {text ? text : "New"}
      </span>
    </span>
  );
};

export default BlinkingBadge;
