import React, { useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  DataTableTitle,
  Icon,
  PaginationComponent,
  TooltipComponent,
} from "../../../../components/Component";
import { HEADERS } from "./constants";
import LoadingComponent from "../../../components/loader/tableLoader";
import { Col, Container, Modal, Row } from "reactstrap";
import { parseSeconds } from "../../../../utils/Utils";

export const CallReportListView = ({ data, loading, error, page, setPage, perPage, setPerPage, }) => {
  return (
    <>
      <Head title="PBX Call Report List"></Head>
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>PBX Call Report List</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {/* ===================== MAIN CONTENT ===================== */}
        <Block>
          <DataTable className="card-stretch">
            <DataTableTitle>
              <div className="card-tools me-n1">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <button
                      className="btn btn-icon search-toggle toggle-search"
                      data-target="search"
                    >
                      <Icon name="search" />
                    </button>
                  </li>
                </ul>
              </div>
            </DataTableTitle>
            <DataTableBody>
              <DataTableHead className="nk-tb-item">
                {HEADERS.map((header, index) => (
                  <DataTableRow
                    key={`table-header-${index}`}
                    className={header?.style ?? ""}
                  >
                    <span className="">{header?.name}</span>
                  </DataTableRow>
                ))}
              </DataTableHead>
              {!loading
                ? data?.collection?.map((item, index) => (
                    <CallReportTableItem
                      item={item}
                      index={index}
                      key={`call-report-row-${index}`}
                    />
                  ))
                : null}
            </DataTableBody>
            {error ? (
              <div className="text-center p-4 text-danger">
                {error?.message ?? "Failed to retrieve call report list"}
              </div>
            ) : null}
            {loading ? <LoadingComponent centered={"true"} /> : null}
            {!loading ? (
              <div className="card-inner">
                <PaginationComponent
                  currentPage={page}
                  paginate={(selectedPage) => {
                    if (selectedPage === page) return;
                    setPage(selectedPage);
                  }}
                  itemPerPage={perPage}
                  totalItems={data?.total ?? 0}
                />
              </div>
            ) : null}
          </DataTable>
        </Block>
        {/* ===================== MAIN CONTENT ===================== */}
      </Content>
    </>
  );
};

const CallReportTableItem = ({ item, index }) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const toggleDetailsModal = () => setIsDetailsModalOpen(!isDetailsModalOpen);

  return (
    <>
      <DataTableItem>
        <DataTableRow className={"text-center"}>
          <span>{item?.ext_name}</span>
        </DataTableRow>
        <DataTableRow className={"text-center"}>
          <span>{item?.ext_num}</span>
        </DataTableRow>
        <DataTableRow className={"text-center"}>
          <span>
            {item?.mobile && item?.mobile?.length > 0 ? item?.mobile : "-"}
          </span>
        </DataTableRow>
        <DataTableRow className={"text-center tb-col-md"}>
          <span>{item?.answered_calls ?? "-"}</span>
        </DataTableRow>
        <DataTableRow className={"text-center tb-col-md"}>
          <span>{item?.no_answer_calls ?? "-"}</span>
        </DataTableRow>
        <DataTableRow className={"text-center tb-col-md"}>
          <span>{item?.busy_calls ?? "-"}</span>
        </DataTableRow>
        <DataTableRow className={"text-center tb-col-md"}>
          <span>{item?.failed_calls ?? "-"}</span>
        </DataTableRow>
        <DataTableRow className={"text-center tb-col-md"}>
          <span>{item?.voicemail_calls ?? "-"}</span>
        </DataTableRow>
        <DataTableRow className={"text-center tb-col-md"}>
          <span>{parseSeconds(item?.total_holding_time) ?? "-"}</span>
        </DataTableRow>
        <DataTableRow className={"text-center tb-col-md"}>
          <span>{parseSeconds(item?.total_talking_time) ?? "-"}</span>
        </DataTableRow>
        <DataTableRow className={"text-center tb-col-md"}>
          <span>{item?.total_call_count ?? "-"}</span>
        </DataTableRow>
        <DataTableRow className={"text-center d-md-none"}>
          <div onClick={toggleDetailsModal}>
            <TooltipComponent
              tag="span"
              containerClassName="btn btn-trigger btn-icon"
              id={`tooltip-${index}`}
              icon="eye"
              direction="top"
              text="View Details"
            />
          </div>
        </DataTableRow>
      </DataTableItem>
      <Modal isOpen={isDetailsModalOpen} toggle={toggleDetailsModal} size="md">
        <button
          className="btn btn-trigger btn-icon close"
          aria-label="Close"
          onClick={toggleDetailsModal}
        >
          <Icon name={"cross"} className={"mb-2"} />
        </button>
        <div className="p-2">
          <h5 className="title p-2">Call Report Details</h5>
        </div>
        <Container className="mt-2 px-3 py-3 px-4">
          <Row>
            <Col>
              <div>
                <h6 className="title">Answered Calls</h6>
                <span>{item?.answered_calls ?? "-"}</span>
              </div>
            </Col>
            <Col>
              <div>
                <h6 className="title">No Answer Calls</h6>
                <span>{item?.no_answer_calls ?? "-"}</span>
              </div>
            </Col>
            <Col>
              <div>
                <h6 className="title">Busy Calls</h6>
                <span>{item?.busy_calls ?? "-"}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div>
                <h6 className="title">Failed Calls</h6>
                <span>{item?.failed_calls ?? "-"}</span>
              </div>
            </Col>
            <Col>
              <div>
                <h6 className="title">Voicemail Calls</h6>
                <span>{item?.voicemail_calls ?? "-"}</span>
              </div>
            </Col>
            <Col>
              <div>
                <h6 className="title">Holding Time</h6>
                <span>{parseSeconds(item?.total_holding_time) ?? "-"}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3 d-flex justify-content-between">
            <Col className="">
              <div>
                <h6 className="title">Talking Time</h6>
                <span>{parseSeconds(item?.total_talking_time) ?? "-"}</span>
              </div>
            </Col>
            <Col>
              <div>
                <h6 className="title">Total Calls</h6>
                <span>{item?.total_call_count ?? "-"}</span>
              </div>
            </Col>
            <Col>
              <div>
                <h6 className="title">Caller ID</h6>
                <span>
                  {item?.src_name && item?.src_name?.length > 0
                    ? item?.src_name
                    : "-"}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal>
    </>
  );
};
export default CallReportListView;
