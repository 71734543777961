/* eslint-disable jsx-a11y/anchor-is-valid */

const HeaderCard = () => {
  return (
    <div className="card-inner">
      <div className="user-card">
        <h4>Application Settings</h4>
      </div>
    </div>
  );
};

export default HeaderCard;
