import * as types from "../types/community-teams";

/**
 * Initiates a request to fetch all community teams, optionally filtered by a query.
 * Dispatches actions to handle the request start, successful response, and errors.
 *
 * @param {function} axios - Axios instance for making HTTP requests.
 * @param {string} [query] - Optional query string to filter the community teams.
 */
export const fetchAllCommunityTeams = (axios, query) => async (dispatch) => {
  dispatch({ type: types.GET_COMMUNITY_TEAMS_STARTED });
  try {
    const url = query ? `/communityTeams/?${query}` : "/communityTeams";
    const res = await axios.get(url);
    dispatch({
      type: types.GET_COMMUNITY_TEAMS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({ type: types.GET_COMMUNITY_TEAMS_FAILED, error: error });
  }
};

/**
 * Fetches details of a specific community team by teamId.
 * Dispatches actions to indicate the start of the request, a successful response, and errors.
 *
 * @param {function} axios - Axios instance for making HTTP requests.
 * @param {string} teamId - The unique identifier of the community team to fetch.
 */
export const fetchCommunityTeam = (axios, teamId) => async (dispatch) => {
  dispatch({ type: types.GET_COMMUNITY_TEAM_STARTED });
  try {
    const res = await axios.get(`/communityTeams/${teamId}`);
    dispatch({
      type: types.GET_COMMUNITY_TEAM,
      payload: res.data,
    });
  } catch (error) {
    dispatch({ type: types.GET_COMMUNITY_TEAM_FAILED });
  }
};

/**
 * Creates a new community team with the given data.
 * Dispatches actions to indicate the request start, success, and errors.
 * Optionally calls provided callback functions on success or completion.
 *
 * @param {function} axios - Axios instance for making HTTP requests.
 * @param {Object} data - Data for the new community team.
 * @param {function} callback - Optional callback to be called on completion.
 * @param {function} [successCallback=null] - Optional callback to be called on successful addition.
 */
export const addCommunityTeam = (axios, data, callback, successCallback = null) => async (dispatch) => {
  dispatch({ type: types.ADD_COMMUNITY_TEAM_STARTED });
  try {
    const res = await axios.post("/communityTeams", data);
    dispatch({
      type: types.ADD_COMMUNITY_TEAM,
      payload: res.data,
      status: res.status ?? 201
    });
    if (successCallback && typeof successCallback === "function") {
      successCallback();
    }
  } catch (error) {
    dispatch({ type: types.ADD_COMMUNITY_TEAM_FAILED, error: error });
  } finally {
    if (callback && typeof callback === "function") {
      callback();
    }
  }
}

/**
 * Updates an existing community team identified by teamId with the provided data.
 * Dispatches actions to indicate the request start, success, and errors.
 * Optionally calls provided callback functions on success or completion.
 *
 * @param {function} axios - Axios instance for making HTTP requests.
 * @param {string} teamId - The unique identifier of the community team to update.
 * @param {Object} data - Updated data for the community team.
 * @param {function} callback - Optional callback to be called on completion.
 * @param {function} [successCallback=null] - Optional callback to be called on successful update.
 */
export const updateCommunityTeam = (axios, teamId, data, callback, successCallback = null) => async (dispatch) => {
  dispatch({ type: types.UPDATE_COMMUNITY_TEAM_STARTED });
  try {
    const res = await axios.patch(`/communityTeams/${teamId}`, data);
    dispatch({
      type: types.UPDATE_COMMUNITY_TEAM,
      payload: res.data,
      status: res.status ?? 200
    });
    if (successCallback && typeof successCallback === "function") {
      successCallback();
    }
  } catch (error) {
    dispatch({ type: types.UPDATE_COMMUNITY_TEAM_FAILED, error: error });
  } finally {
    if (callback && typeof callback === "function") {
      callback();
    }
  }
}

/**
 * Deletes a community team identified by teamId.
 * Dispatches actions to indicate the request start and completion or errors.
 * Optionally calls a provided callback function on completion.
 *
 * @param {function} axios - Axios instance for making HTTP requests.
 * @param {string} teamId - The unique identifier of the community team to delete.
 * @param {function} callback - Optional callback to be called on completion.
 */
export const deleteCommunityTeam = (axios, teamId, callback) => async (dispatch) => {
  dispatch({ type: types.DELETE_COMMUNITY_TEAM_STARTED });
  try {
    const res = await axios.delete(`/communityTeams/${teamId}`);
    dispatch({
      type: types.DELETE_COMMUNITY_TEAM,
      status: res.status ?? 204
    });
  } catch (error) {
    dispatch({ type: types.DELETE_COMMUNITY_TEAM_FAILED, error: error });
  } finally {
    if (callback && typeof callback === "function") {
      callback();
    }
  }
}

/**
 * Adds a member to a community team identified by teamId.
 * Dispatches actions to indicate the request start, success, and errors.
 * Optionally calls provided callback functions on success or completion.
 *
 * @param {function} axios - Axios instance for making HTTP requests.
 * @param {string} teamId - The unique identifier of the community team.
 * @param {Object} payload - Data for the member to add to the community team.
 * @param {function} callback - Optional callback to be called on completion.
 * @param {function} successCallback - Optional callback to be called on successful addition.
 */
export const addCommunityTeamMember = (axios, teamId, payload, callback, successCallback) => async (dispatch) => {
  dispatch({ type: types.ADD_TEAM_MEMBER_STARTED });
  try {
    const res = await axios.post(`/communityTeams/members/${teamId}`, payload);
    dispatch({
      type: types.ADD_TEAM_MEMBER,
      status: res.status ?? 200
    });
    if (successCallback && typeof successCallback === "function") {
      successCallback();
    }
  } catch (error) {
    dispatch({ type: types.ADD_TEAM_MEMBER_FAILED, error: error });
  } finally {
    if (callback && typeof callback === "function") {
      callback();
    }
  }
}

/**
 * Associates a community with a community team identified by teamId.
 * Dispatches actions to indicate the request start, success, and errors.
 * Optionally calls provided callback functions on success or completion.
 *
 * @param {function} axios - Axios instance for making HTTP requests.
 * @param {string} teamId - The unique identifier of the community team.
 * @param {Object} payload - Data for the community to associate with the community team.
 * @param {function} callback - Optional callback to be called on completion.
 * @param {function} successCallback - Optional callback to be called on successful association.
 */
export const addCommunityTeamCommunity = (axios, teamId, payload, callback, successCallback) => async (dispatch) => {
  dispatch({ type: types.ADD_TEAM_COMMUNITY_STARTED });
  try {
    const res = await axios.post(`/communityTeams/communities/${teamId}`, payload);
    dispatch({
      type: types.ADD_TEAM_COMMUNITY,
      status: res.status ?? 200
    });
    if (successCallback && typeof successCallback === "function") {
      successCallback();
    }
  } catch (error) {
    dispatch({ type: types.ADD_TEAM_COMMUNITY_FAILED, error: error });
  } finally {
    if (callback && typeof callback === "function") {
      callback();
    }
  }
}

/**
 * Removes a member from a community team identified by teamId.
 * Dispatches actions to indicate the request start, success, and errors.
 * Optionally calls provided callback functions on success or completion.
 *
 * @param {function} axios - Axios instance for making HTTP requests.
 * @param {string} teamId - The unique identifier of the community team.
 * @param {Object} payload - Data identifying the member to remove from the community team.
 * @param {function} callback - Optional callback to be called on completion.
 * @param {function} successCallback - Optional callback to be called on successful removal.
 */
export const removeCommunityTeamMember = (axios, teamId, payload, callback, successCallback) => async (dispatch) => {
  dispatch({ type: types.REMOVE_TEAM_MEMBER_STARTED });
  try {
    const res = await axios.delete(`/communityTeams/members/${teamId}`, { data: payload });
    dispatch({
      type: types.REMOVE_TEAM_MEMBER,
      status: res.status ?? 204
    });
    if (successCallback && typeof successCallback === "function") {
      successCallback();
    }
  } catch (error) {
    dispatch({ type: types.REMOVE_TEAM_MEMBER_FAILED, error: error });
  } finally {
    if (callback && typeof callback === "function") {
      callback();
    }
  }
}

/**
 * Disassociates a community from a community team identified by teamId.
 * Dispatches actions to indicate the request start, success, and errors.
 * Optionally calls provided callback functions on success or completion.
 *
 * @param {function} axios - Axios instance for making HTTP requests.
 * @param {string} teamId - The unique identifier of the community team.
 * @param {Object} payload - Data identifying the community to disassociate from the community team.
 * @param {function} callback - Optional callback to be called on completion.
 * @param {function} successCallback - Optional callback to be called on successful disassociation.
 */
export const removeCommunityTeamCommunity = (axios, teamId, payload, callback, successCallback) => async (dispatch) => {
  dispatch({ type: types.REMOVE_TEAM_COMMUNITY_STARTED });
  try {
    const res = await axios.delete(`/communityTeams/communities/${teamId}`, { data: payload });
    dispatch({
      type: types.REMOVE_TEAM_COMMUNITY,
      status: res.status ?? 204
    });
    if (successCallback && typeof successCallback === "function") {
      successCallback();
    }
  } catch (error) {
    dispatch({ type: types.REMOVE_TEAM_COMMUNITY_FAILED, error: error });
  } finally {
    if (callback && typeof callback === "function") {
      callback();
    }
  }
}