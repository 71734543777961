import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Col, Row, Spinner } from 'reactstrap';
import {
    ActivityStatus,
    ActivityTypes,
    DealCategory,
    DealStatus,
    formatDate,
    formatValue,
    getDayName,
    getImage,
    newRoles
} from '../../utils/envConfig';

import { LeadStatus } from '../../utils/envConfig';

import {
    addDays,
    differenceInDays,
    endOfMonth,
    endOfWeek,
    getDay,
    intervalToDuration,
    isValid,
    startOfMonth,
    startOfWeek,
    subDays
} from 'date-fns';
import { useSelector } from 'react-redux';

import Content from '../../layout/content/Content';
import Head from '../../layout/head/Head';
import {
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    RSelect
} from '../../components/Component';
import DataCard from '../../components/partials/default/DataCard';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { DefaultOrderChart } from '../../components/partials/charts/default/DefaultCharts';
// import DealsClosedGrid from '../../components/partials/default/DealsClosedGrid';
// import ClientsAcquired from '../../components/partials/default/ClientsAcquired';
// import DealsPendingGrid from '../../components/partials/default/DealsPendingGrid';
import { defaultUserImage as defaultImage } from '../../utils/envConfig';
import { fetchAgents } from '../../redux/actions/user';
import { useDispatch } from 'react-redux';
import RenderChart from './components/RenderChart';
import MultiBarsBarChart from './components/MultibarsBarChart';
import DealsPendingGrid from '../../components/DealsPendingGrid';
import ClientsAcquired from '../../components/ClientsAcquired';
import DealsClosedGrid from '../../components/DealsClosedGrid';

const defaultLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

export default function AgentsDashboard({
    agent,
    handleSelectAgent = (agent) => {},
    handleNormalAgent = () => {}
}) {
    // REFS
    const printRef = useRef();

    // agent image
    const [image, setImage] = useState(null);

    const axios = useAxiosPrivate();

    const dispatch = useDispatch();

    const authuser = useSelector((state) => state?.user?.loggedInUser);

    const agents = useSelector((state) => state?.user?.agents);

    const [smBtn, setSmBtn] = useState(false);

    // fresh leads
    const [leadsDoneToday, setLeadsDoneToday] = useState(null);
    const [leadsDoneTodayPercent, setLeadsDoneTodayPercent] = useState(null);
    const [dailyLeadsDone, setDailyLeadsDone] = useState(null);

    const [freshLeadsPending, setFreshLeadsPending] = useState(null);
    const [freshLeadsPendingPercent, setFreshLeadsPendingPercent] =
        useState(null);

    // follow-ups
    const [followUpsDone, setFollowUpsDone] = useState(null);
    const [followUpsPending, setFollowUpsPending] = useState(null);
    const [followUpsDonePercent, setFollowUpsDonePercent] = useState(null);
    const [followUpsPendingPercent, setFollowUpsPendingPercent] =
        useState(null);
    const [dailyFollowUps, setDailyFollowUps] = useState(null);

    // viewings
    const [viewingsDone, setViewingsDone] = useState(null);
    const [viewingsDonePercent, setViewingsDonePercent] = useState(null);
    const [viewingsPending, setViewingsPending] = useState(null);
    const [viewingsPendingPercent, setViewingsPendingPercent] = useState(null);

    // deals
    const [dealsClosed, setDealsClosed] = useState(null);
    const [dealsPendingThisMonth, setDealsPendingThisMonth] = useState();
    const [dailyDeals, setDailyDeals] = useState(null);

    const [dealsClosedThisWeek, setDealsClosedThisWeek] = useState(null);
    const [dealsClosedThisWeekPercent, setDealsClosedThisWeekPercent] =
        useState(null);

    const [dealsClosedThisMonth, setDealsClosedThisMonth] = useState(null);
    const [dealsClosedThisMonthPercent, setDealsClosedThisMonthPercent] =
        useState(null);

    // activities
    const [tasksCreatedToday, setTasksCreatedToday] = useState(null);
    const [tasksCreatedTodayPercent, setTasksCreatedTodayPercent] =
        useState(null);
    const [tasksCreatedThisWeek, setTasksCreatedThisWeek] = useState(null);
    const [tasksCreatedThisWeekPercent, setTasksCreatedThisWeekPercent] =
        useState(null);
    // clients
    const [clientsThisWeek, setClientsThisWeek] = useState(null);

    const [leadsCreatedToday, setLeadsCreatedToday] = useState(null);
    const [leadsCreatedTodayPercent, setLeadsCreatedTodayPercent] =
        useState(null);
    const [leadsCreatedThisWeek, setLeadsCreatedThisWeek] = useState(null);
    const [leadsCreatedThisWeekPercent, setLeadsCreatedThisWeekPercent] =
        useState(null);

    // listings
    const [listingsAcquiredToday, setListingsAcquiredToday] = useState(null);
    const [listingsAcquiredTodayPercent, setListingsAcquiredTodayPercent] =
        useState(null);

    const [
        listingsAcquiredThisWeekPercent,
        setListingsAcquiredThisWeekPercent
    ] = useState(null);
    const [listingsAcquiredThisWeek, setListingsAcquiredThisWeek] =
        useState(null);

    const [dailyListings, setDailyListings] = useState(null);

    // overdue tasks
    const [averageOverdueTime, setAverageOverdueTime] = useState(null);
    const [averageOverdueTimePercent, setAverageOverdueTimePercent] =
        useState(null);
    const [overdueTasks, setOverdueTasks] = useState(null);
    const [overdueTasksPercent, setOverdueTasksPercent] = useState(null);
    const [dailyOverdueTasks, setDailyOverdueTasks] = useState(null);

    // others

    const [defaultAgent, setDefaultAgent] = useState(null);

    const today = new Date();

    const [dateError, setDateError] = useState('');

    const [dayStart, setDayStart] = useState(today);
    const [dayEnd, setDayEnd] = useState(today);

    const [weekStart, setWeekStart] = useState(startOfWeek(today));
    const [weekEnd, setWeekEnd] = useState(endOfWeek(today));

    const [monthStart, setMonthStart] = useState(startOfMonth(today));

    const [imageLoading, setImageLoading] = useState(false);

    const updateLabels = () => {
        const dayOfWeek = getDay(weekStart);

        switch (dayOfWeek) {
            case 0:
                return defaultLabels;
            case 1:
                return ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];
            case 2:
                return ['Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun', 'Mon'];
            case 3:
                return ['Wed', 'Thur', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue'];
            case 4:
                return ['Thur', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed'];
            case 5:
                return ['Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thur'];
            case 6:
                return ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri'];

            default:
                return defaultLabels;
        }
    };

    const updateFetchingStatus = () => {
        setLeadsDoneToday(null);
        setFreshLeadsPending(null);
        setFollowUpsDone(null);
        setFollowUpsPending(null);
        setDealsClosed(null);
        setDealsPendingThisMonth(null);
        // setDealsClosedThisWeek(null);
        setDealsClosedThisMonth(null);
        setTasksCreatedToday(null);
        setTasksCreatedThisWeek(null);
        setClientsThisWeek(null);
        setLeadsCreatedToday(null);
        setLeadsCreatedThisWeek(null);
        // setListingsAcquiredToday(null);
        setListingsAcquiredThisWeek(null);
    };

    const resetDealsOnAgentChange = async () => {
        setDealsClosedThisWeek(null);
        setListingsAcquiredToday(null);
    };

    const fetchAgentImage = async () => {
        try {
            setImageLoading(true);
            if (!agent?.photo) {
                setImage(null);
                return;
            }
            const image = await getImage(agent?.photo, axios);
            setImage(image?.signedRequest);
        } catch (err) {
            console.log(err);
        } finally {
            setImageLoading(false);
        }
    };

    const getListingsAcquired = async () => {
        try {
            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(today);

            const toDate = formatDate(today);

            const fromWeek = formatDate(
                startOfWeek(subDays(startOfWeek(today), 1))
            );

            const toWeek = formatDate(subDays(startOfWeek(today), 1));

            const listingsReq = axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&category[]=${DealCategory.ListingAcquired}&status[]=${DealStatus.Completed}&agents[]=${agent?.id}`
            );

            const listingsComReq = axios.get(
                `/reports/deals?from_date=${fromWeek}&to_date=${toWeek}&category[]=${DealCategory.ListingAcquired}&status[]=${DealStatus.Completed}&agents[]=${agent?.id}`
            );

            const [{ data: listingsAcquired }, { data: listingsAcquiredCom }] =
                await Promise.all([listingsReq, listingsComReq]);

            const listingsAcquiredTotal = listingsAcquired?.length;
            const listingsAcquiredComTotal = listingsAcquiredCom?.length;

            const comparisonBase = listingsAcquiredComTotal ?? 0 / 7;

            const percent =
                !listingsAcquiredComTotal && !listingsAcquiredTotal
                    ? 0
                    : !listingsAcquiredComTotal && listingsAcquiredTotal
                    ? 100
                    : Math.round(
                          ((listingsAcquiredTotal - comparisonBase) /
                              comparisonBase) *
                              100
                      );

            setListingsAcquiredToday(formatValue(listingsAcquiredTotal ?? 0));
            setListingsAcquiredTodayPercent(
                isNaN(Number(percent)) ? '0' : percent?.toString()
            );
        } catch (err) {
            console.log(err);
            setListingsAcquiredToday('0');
            setListingsAcquiredTodayPercent('0');
        }
    };

    const getListingsAcquiredThisWeek = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart
            );

            const toDate = formatDate(
                dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd
            );

            const fromWeek = formatDate(
                dayDifference <= 0
                    ? startOfWeek(subDays(weekStart, 1))
                    : weekStart
            );

            const toWeek = formatDate(
                dayDifference <= 0 ? subDays(weekStart, 1) : weekEnd
            );

            const listingsReq = axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&category[]=${DealCategory.ListingAcquired}&status[]=${DealStatus.Completed}&agents[]=${agent?.id}`
            );

            const listingsComReq = axios.get(
                `/reports/deals?from_date=${fromWeek}&to_date=${toWeek}&category[]=${DealCategory.ListingAcquired}&status[]=${DealStatus.Completed}&agents[]=${agent?.id}`
            );

            const [{ data: listingsAcquired }, { data: listingsAcquiredCom }] =
                await Promise.all([listingsReq, listingsComReq]);

            const listingsAcquiredTotal = listingsAcquired?.length;
            const listingsAcquiredComTotal = listingsAcquiredCom?.length;

            const percent =
                !listingsAcquiredComTotal && !listingsAcquiredTotal
                    ? 0
                    : !listingsAcquiredComTotal && listingsAcquiredTotal
                    ? 100
                    : Math.round(
                          ((listingsAcquiredTotal - listingsAcquiredTotal) /
                              listingsAcquiredTotal) *
                              100
                      );

            setListingsAcquiredThisWeek(
                formatValue(listingsAcquired?.length ?? 0)
            );
            setListingsAcquiredThisWeekPercent(
                isNaN(Number(percent)) ? '0' : percent?.toString()
            );
        } catch (err) {
            console.log(err);
            setListingsAcquiredThisWeek('0');
            setListingsAcquiredThisWeekPercent('0');
        }
    };

    const getDailyListingsAcquired = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart
            );

            const toDate = formatDate(
                dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd
            );

            const { data: listingsAcquired } = await axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&category[]=${DealCategory.ListingAcquired}&status[]=${DealStatus.Completed}&daily_data=true&agents[]=${agent?.id}`
            );

            const listings = listingsAcquired?.sort(
                (a, b) => new Date(a?.date) - new Date(b?.date)
            );

            let dateArray = [];
            let currentDate =
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart;

            const stopDate = dayDifference <= 0 ? endOfWeek(dayStart) : dayEnd;

            while (currentDate <= stopDate) {
                dateArray.push(currentDate);
                currentDate = addDays(new Date(currentDate), 1);
            }

            const dailyListiings = dateArray?.map((date) => {
                const fromattedDate = formatDate(date);

                const data = listings?.find(
                    (item) => item?.date === fromattedDate
                );

                return {
                    date: getDayName(new Date(data?.date ?? fromattedDate)),
                    count: data?.count ?? 0
                };
            });

            setDailyListings(dailyListiings);
        } catch (err) {
            console.log(err);
        }
    };

    const getDealsClosedThisWeek = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart
            );

            const toDate = formatDate(
                dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd
            );

            const fromWeek = formatDate(
                dayDifference <= 0
                    ? startOfWeek(subDays(weekStart, 1))
                    : weekStart
            );

            const toWeek = formatDate(
                dayDifference <= 0 ? subDays(weekStart, 1) : weekEnd
            );

            const dealsReq = axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&category[]=${DealCategory.SecondaryProperty}&category[]=${DealCategory.PrimaryOffplan}&status[]=${DealStatus.Completed}&agents[]=${agent?.id}`
            );

            const dealsComReq = axios.get(
                `/reports/deals?from_date=${fromWeek}&to_date=${toWeek}&category[]=${DealCategory.SecondaryProperty}&category[]=${DealCategory.PrimaryOffplan}&status[]=${DealStatus.Completed}&agents[]=${agent?.id}`
            );

            const [{ data: dealsClosed }, { data: dealsClosedCom }] =
                await Promise.all([dealsReq, dealsComReq]);

            const percent =
                dealsClosedCom?.length === 0 && dealsClosed?.length > 0
                    ? 100
                    : dealsClosedCom?.length === 0 && dealsClosed?.length === 0
                    ? 0
                    : Math.round(
                          ((dealsClosed?.length - dealsClosedCom?.length) /
                              dealsClosedCom?.length) *
                              100
                      );

            setDealsClosedThisWeek(formatValue(dealsClosed?.length ?? 0));
            setDealsClosedThisWeekPercent(
                isNaN(Number(percent)) ? '0' : percent?.toString()
            );
        } catch (err) {
            console.log(err);
            setDealsClosedThisWeek('0');
            setDealsClosedThisWeekPercent('0');
        }
    };

    const getDailyDealsClosed = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart
            );

            const toDate = formatDate(
                dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd
            );

            const { data: dealsClosed } = await axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&category[]=${DealCategory.SecondaryProperty}&category[]=${DealCategory.PrimaryOffplan}&status[]=${DealStatus.Completed}&daily_data=true&agents[]=${agent?.id}`
            );

            const deals = dealsClosed?.sort(
                (a, b) => new Date(a?.date) - new Date(b?.date)
            );

            if (deals?.length) {
                let dateArray = [];
                let currentDate =
                    dayDifference <= 0 ? startOfWeek(dayStart) : dayStart;

                const stopDate =
                    dayDifference <= 0 ? endOfWeek(dayStart) : dayEnd;

                while (currentDate <= stopDate) {
                    dateArray.push(currentDate);
                    currentDate = addDays(new Date(currentDate), 1);
                }

                const dailyDeals = dateArray?.map((date) => {
                    const fromattedDate = formatDate(date);

                    const data = deals?.find(
                        (item) => item?.date === fromattedDate
                    );

                    return {
                        date: getDayName(new Date(data?.date ?? fromattedDate)),
                        count: data?.count ?? 0
                    };
                });

                setDailyDeals(dailyDeals);
                return;
            }
            setDailyDeals([]);
        } catch (err) {
            console.log(err);
            setDailyDeals([]);
        }
    };

    const getDealsClosedThisMonth = async () => {
        try {
            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(monthStart);

            const toDate = formatDate(endOfMonth(monthStart));

            const lastMonthEnd = formatDate(
                subDays(startOfMonth(monthStart), 1)
            );

            const lastMonthStart = formatDate(
                startOfMonth(new Date(lastMonthEnd))
            );

            const dealsReq = axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&category[]=${DealCategory.SecondaryProperty}&category[]=${DealCategory.PrimaryOffplan}&status[]=${DealStatus.Completed}&agents[]=${agent?.id}&with_users=true`
            );

            const dealsComReq = axios.get(
                `/reports/deals?from_date=${lastMonthStart}&to_date=${lastMonthEnd}&category[]=${DealCategory.SecondaryProperty}&category[]=${DealCategory.PrimaryOffplan}&status[]=${DealStatus.Completed}&agents[]=${agent?.id}`
            );

            const [{ data: dealsClosed }, { data: dealsClosedCom }] =
                await Promise.all([dealsReq, dealsComReq]);

            const percent =
                dealsClosedCom?.length === 0 && dealsClosed?.length > 0
                    ? 100
                    : dealsClosedCom?.length === 0 && dealsClosed?.length === 0
                    ? 0
                    : Math.round(
                          ((dealsClosed?.length - dealsClosedCom?.length) /
                              dealsClosedCom?.length) *
                              100
                      );

            setDealsClosedThisMonth(formatValue(dealsClosed?.length ?? 0));
            setDealsClosedThisMonthPercent(
                isNaN(Number(percent)) ? '0' : percent?.toString()
            );
            setDealsClosed(dealsClosed);
        } catch (err) {
            console.log(err);
            setDealsClosedThisMonth('0');
            setDealsClosedThisMonthPercent('0');
            setDealsClosed([]);
        }
    };

    const getDealsPendingThisMonth = async () => {
        try {
            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(monthStart);

            const toDate = formatDate(endOfMonth(monthStart));

            const dealStatusQuery = Object.values(DealStatus)
                .filter(
                    (stat) =>
                        stat !== DealStatus.Completed &&
                        stat !== DealStatus.Approved &&
                        stat !== DealStatus.Rejected &&
                        stat !== DealStatus.Canceled
                )
                .map((item) => `status[]=${item}`)
                .join('&');

            const { data: dealsPending } = await axios.get(
                `/reports/deals?from_date=${fromDate}&to_date=${toDate}&category[]=${
                    DealCategory.SecondaryProperty
                }&category[]=${
                    DealCategory.PrimaryOffplan
                }&${dealStatusQuery}&agents[]=${
                    agent?.id ?? agent?._id
                }&with_users=true`
            );

            setDealsPendingThisMonth(dealsPending);
        } catch (err) {
            console.log(err);
            setDealsPendingThisMonth([]);
        }
    };

    const getViewings = async () => {
        try {
            // When fetching data, if we are fetching for a single day (dayStart === dayEnd), then we want to compare this data to average daily data of that week. Else if we're fetching data for more than one day (dayEnd > dayStart || dayEnd - dayStart ~= 7), then we want to compare this data to the total data of the previous week.

            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(dayStart);
            const toDate = formatDate(dayEnd);

            // Structuring request dates for comparison data
            // if dayStart - dayEnd <=0 then we want to fetch comparison data for start of week of dayStart or dayEnd (same result since both dates are same) else we want to fetch data for the previous 7 days (weekStart & weekeend) so we can effectively compare the data with results
            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(subDays(weekStart, 1)))
                    : formatDate(weekStart);

            const toWeek =
                dayDifference <= 0
                    ? formatDate(subDays(weekStart, 1))
                    : formatDate(weekEnd);

            // const role = authuser?.role;

            // get viewings pending comparison data
            const viewingsPendingComReq = axios.get(
                `/reports/viewings?from_date=${fromWeek}&to_date=${toWeek}&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }&activity_status[]=${
                    ActivityStatus.Pending
                }&activity_status[]=${ActivityStatus.Overdue}`
            );

            // get viewings pending data
            const viewingsPendingReq = axios.get(
                `/reports/viewings?from_date=${fromDate}&to_date=${toDate}&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }&activity_status[]=${
                    ActivityStatus.Pending
                }&activity_status[]=${ActivityStatus.Overdue}`
            );

            // get viewings pending comparison data
            const viewingsDoneComReq = axios.get(
                `/reports/viewings?from_date=${fromWeek}&to_date=${toWeek}&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }&activity_status[]=${
                    ActivityStatus.Pending
                }&activity_status[]=${ActivityStatus.Overdue}`
            );

            // get viewings pending data
            const viewingsDoneReq = axios.get(
                `/reports/viewings?from_date=${fromDate}&to_date=${toDate}&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }&activity_status[]=${ActivityStatus.Complete}`
            );

            // No need to fetch the other data if one fails
            const [{ data: viewingsPending }, { data: viewingsPendingCom }] =
                await Promise.all([viewingsPendingReq, viewingsPendingComReq]);

            // No need to fetch the other data if one fails
            const [{ data: viewingsDone }, { data: viewingsDoneCom }] =
                await Promise.all([viewingsDoneReq, viewingsDoneComReq]);

            // if we're fetching just one day data then we need to divide comarison result by 7 (days) in order to get daily average
            const viewingsPendingCount = viewingsPending?.count;
            const comparisonBase =
                dayDifference <= 0
                    ? viewingsPendingCom?.count / 7
                    : viewingsPendingCom?.count;

            // setViewingsPendingPercent
            const viewingsPendingPercent =
                viewingsPendingCount === 0 && comparisonBase === 0
                    ? 0
                    : ((viewingsPendingCount - comparisonBase) /
                          comparisonBase) *
                      100;

            setViewingsPending(viewingsPendingCount);
            setViewingsPendingPercent(
                isNaN(Number(viewingsPendingPercent))
                    ? '0'
                    : Math.round(viewingsPendingPercent).toString()
            );

            // Implementing thesame logic for viewings done

            const viewingsDoneCount = viewingsDone?.count;
            const viewingsDoneBase =
                dayDifference <= 0
                    ? viewingsDoneCom?.count / 7
                    : viewingsDoneCom?.count;

            const viewingsDonePercent =
                viewingsDoneCount === 0 && viewingsDoneBase === 0
                    ? 0
                    : ((viewingsDoneCount - viewingsDoneBase) /
                          viewingsDoneBase) *
                      100;

            setViewingsDone(viewingsDoneCount);
            setViewingsDonePercent(
                isNaN(Number(viewingsDonePercent))
                    ? '0'
                    : Math.round(viewingsDonePercent).toString()
            );
        } catch (err) {
            console.log(err);
            setViewingsPending('0');
            setViewingsPendingPercent('0');
            setViewingsDone('0');
            setViewingsDonePercent('0');
        }
    };

    const getFollowUpsDone = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(dayStart);
            const toDate = formatDate(dayEnd);

            // Structuring request dates for comparison data
            // if dayStart - dayEnd <=0 then we want to fetch comparison data for start of week of dayStart or dayEnd (same result since both dates are same) else we want to fetch data for the previous 7 days (weekStart & weekeend) so we can effectively compare the data with results
            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(subDays(weekStart, 1)))
                    : formatDate(weekStart);

            const toWeek =
                dayDifference <= 0
                    ? formatDate(subDays(weekStart, 1))
                    : formatDate(weekEnd);

            const activityTypesQuery = Object.values(ActivityTypes)
                .filter(
                    (value) =>
                        value !== ActivityTypes.Viewing &&
                        value !== ActivityTypes.Assign
                )
                .map((item) => `activity_type[]=${item}`)
                .join('&');

            const followUpsDoneReq = axios.get(
                `/reports/follow-ups?from_date=${fromDate}&to_date=${toDate}&${activityTypesQuery}&activity_status[]=${
                    ActivityStatus.Complete
                }&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const followUpsDoneComReq = axios.get(
                `/reports/follow-ups?from_date=${fromWeek}&to_date=${toWeek}&${activityTypesQuery}&activity_status[]=${
                    ActivityStatus.Complete
                }&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const [{ data: followUpsDone }, { data: followUpsDoneCom }] =
                await Promise.all([followUpsDoneReq, followUpsDoneComReq]);

            const followUpsDoneCount = followUpsDone?.count;
            const comparisonBase =
                dayDifference <= 0
                    ? followUpsDoneCom?.count / 7
                    : followUpsDoneCom?.count;

            const followUpsDonePercent =
                ((followUpsDoneCount - comparisonBase) / comparisonBase) * 100;

            setFollowUpsDonePercent(
                isNaN(followUpsDonePercent)
                    ? '0'
                    : Math.round(followUpsDonePercent).toString()
            );
            setFollowUpsDone(followUpsDoneCount?.toString());
        } catch (err) {
            console.log(err);
            setFollowUpsDonePercent('0');
            setFollowUpsDone('0');
        }
    };

    const getFollowUpsPending = async () => {
        try {
            // follow ups pending is weekly. Thus when fetching daily data, we need to fetch weekly data for followups pending.
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);
            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayStart))
                    : formatDate(dayEnd);

            // Structuring request dates for comparison data
            // if dayStart - dayEnd <=0 then we want to fetch comparison data for start of week of dayStart or dayEnd (same result since both dates are same) else we want to fetch data for the previous 7 days (weekStart & weekeend) so we can effectively compare the data with results

            const lastWeekEnd = subDays(startOfWeek(dayStart), 1);

            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(lastWeekEnd))
                    : formatDate(weekStart);

            const toWeek =
                dayDifference <= 0
                    ? formatDate(lastWeekEnd)
                    : formatDate(weekEnd);

            const activityTypesQuery = Object.values(ActivityTypes)
                .filter(
                    (value) =>
                        value !== ActivityTypes.Viewing &&
                        value !== ActivityTypes.Assign
                )
                .map((item) => `activity_type[]=${item}`)
                .join('&');

            const followUpsPendingReq = axios.get(
                `/reports/follow-ups?from_date=${fromDate}&to_date=${toDate}&${activityTypesQuery}&activity_status[]=${
                    ActivityStatus.Overdue
                }&activity_status[]=${ActivityStatus.Pending}&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const followUpsPendingComReq = axios.get(
                `/reports/follow-ups?from_date=${fromWeek}&to_date=${toWeek}&${activityTypesQuery}&activity_status[]=${
                    ActivityStatus.Overdue
                }&activity_status[]=${ActivityStatus.Pending}&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const [{ data: followUpsPending }, { data: followUpsPendingCom }] =
                await Promise.all([
                    followUpsPendingReq,
                    followUpsPendingComReq
                ]);

            const followUpsPendingCount = followUpsPending?.count;
            const pendingComparisonBase = followUpsPendingCom?.count;

            const followUpsPendingPercent =
                ((pendingComparisonBase - followUpsPendingCount) /
                    pendingComparisonBase) *
                100;

            setFollowUpsPendingPercent(
                isNaN(followUpsPendingPercent)
                    ? '0'
                    : Math.round(followUpsPendingPercent).toString()
            );
            setFollowUpsPending(followUpsPendingCount?.toString());
        } catch (err) {
            console.log(err);
            setFollowUpsPendingPercent('0');
            setFollowUpsPending('0');
        }
    };

    const getDailyFollowUps = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);
            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayStart))
                    : formatDate(dayEnd);

            const activityTypesQuery = Object.values(ActivityTypes)
                .filter(
                    (value) =>
                        value !== ActivityTypes.Viewing &&
                        value !== ActivityTypes.Assign
                )
                .map((item) => `activity_type[]=${item}`)
                .join('&');

            const { data: dailyFollowUps } = await axios.get(
                `/reports/follow-ups?from_date=${fromDate}&to_date=${toDate}&${activityTypesQuery}&activity_status[]=${
                    ActivityStatus.Complete
                }&daily_data=true&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const followUpsData = dailyFollowUps?.data?.length
                ? dailyFollowUps?.data?.sort(
                      (a, b) => new Date(a?.date) - new Date(b?.date)
                  )
                : [];

            let dateArray = [];
            let currentDate =
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart;

            const stopDate = dayDifference <= 0 ? endOfWeek(dayStart) : dayEnd;

            while (currentDate <= stopDate) {
                dateArray.push(currentDate);
                currentDate = addDays(new Date(currentDate), 1);
            }

            const dailyFollowUpsData = dateArray?.map((date) => {
                const fromattedDate = formatDate(date);

                const data = followUpsData?.find(
                    (item) => item?.date === fromattedDate
                );

                return {
                    date: getDayName(new Date(data?.date ?? fromattedDate)),
                    count: data?.count ?? 0
                };
                // console.log(fromattedDate)
            });

            setDailyFollowUps(dailyFollowUpsData);
        } catch (err) {
            console.log(err);
            setDailyFollowUps([]);
        }
    };

    const getDailyOverdueTasks = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);
            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayStart))
                    : formatDate(dayEnd);

            const { data: dailyOverdueTasks } = await axios.get(
                `/reports/activities?from_date=${fromDate}&to_date=${toDate}&status[]=${
                    ActivityStatus.Overdue
                }&daily=true&date_filter=true&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const overdueData = dailyOverdueTasks?.length
                ? dailyOverdueTasks?.sort(
                      (a, b) => new Date(a?.date) - new Date(b?.date)
                  )
                : [];

            let dateArray = [];
            let currentDate =
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart;

            const stopDate = dayDifference <= 0 ? endOfWeek(dayStart) : dayEnd;

            while (currentDate <= stopDate) {
                dateArray.push(currentDate);
                currentDate = addDays(new Date(currentDate), 1);
            }

            const dailyOverdueData = dateArray?.map((date) => {
                const fromattedDate = formatDate(date);

                const data = overdueData?.find(
                    (item) => item?.date === fromattedDate
                );

                return {
                    date: getDayName(new Date(data?.date ?? fromattedDate)),
                    count: data?.count ?? 0
                };
            });

            setDailyOverdueTasks(dailyOverdueData);
        } catch (err) {
            console.log(err);
            setDailyOverdueTasks([]);
        }
    };

    const getOverdueTime = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(dayStart);

            const toDate = formatDate(dayEnd);

            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(subDays(weekStart, 1)))
                    : formatDate(weekStart);

            const toWeek =
                dayDifference <= 0
                    ? formatDate(subDays(weekStart, 1))
                    : formatDate(weekEnd);

            const overdueTasksReq = axios.get(
                `/reports/activities?from_date=${fromDate}&to_date=${toDate}&status[]=${
                    ActivityStatus.Overdue
                }&with_activities=true&date_filter=true&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const overdueTasksComReq = axios.get(
                `/reports/activities?from_date=${fromWeek}&to_date=${toWeek}&status[]=${
                    ActivityStatus.Overdue
                }&with_activities=true&date_filter=true&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const [{ data: overdueTasks }, { data: overdueTasksCom }] =
                await Promise.all([overdueTasksReq, overdueTasksComReq]);

            // we want to map thru all the items and return the number of days for which its overdue.
            // then we get the average number of overdue days by summing all number of days and dividing by total number of tasks overdue

            const totalOverdueDays = overdueTasks
                ?.map((item) => {
                    const daysOverdue = intervalToDuration({
                        start: new Date(item?.date ?? item?.createdAt),
                        end: today
                    }).days;

                    return daysOverdue;
                })
                ?.reduce((a, b) => a + b, 0);

            const totalOverdueComDays = overdueTasksCom
                ?.map((item) => {
                    const daysOverdue = intervalToDuration({
                        start: new Date(item?.date ?? item?.createdAt),
                        end: today
                    }).days;

                    return daysOverdue;
                })
                ?.reduce((a, b) => a + b, 0);

            const averageOverdueTime =
                overdueTasks?.length > 0
                    ? (totalOverdueDays ?? 0) / overdueTasks?.length
                    : 0;

            const averageOverdueComTime =
                overdueTasksCom?.length > 0
                    ? (totalOverdueComDays ?? 0) / overdueTasksCom?.length
                    : 0;

            const timeComparisonBase =
                dayDifference <= 0
                    ? averageOverdueComTime / 7
                    : averageOverdueComTime;

            const averageOverdueTimePercent =
                !timeComparisonBase && !averageOverdueTime
                    ? 0
                    : !timeComparisonBase && averageOverdueTime
                    ? 100
                    : Math.round(
                          ((averageOverdueTime - timeComparisonBase) /
                              timeComparisonBase) *
                              100
                      );

            //
            const overdueTasksCount = overdueTasks?.length ?? 0;

            const comparisonBase =
                dayDifference <= 0
                    ? (overdueTasksCom?.length ?? 0) / 7
                    : overdueTasksCom?.length;

            const overdueTasksPercent =
                !comparisonBase && !overdueTasksCount
                    ? 0
                    : !comparisonBase && overdueTasksCount
                    ? 100
                    : Math.round(
                          ((overdueTasksCount - comparisonBase) /
                              comparisonBase) *
                              100
                      );

            setOverdueTasks(formatValue(overdueTasksCount));
            setOverdueTasksPercent(
                isNaN(overdueTasksPercent)
                    ? '0'
                    : overdueTasksPercent.toString()
            );

            setAverageOverdueTime(formatValue(averageOverdueTime?.toFixed(1) ?? 0));
            setAverageOverdueTimePercent(
                isNaN(averageOverdueTimePercent)
                    ? '0'
                    : averageOverdueTimePercent?.toString()
            );
        } catch (err) {
            console.log(err);
            setOverdueTasks('0');
            setOverdueTasksPercent('0');
            setAverageOverdueTime('0');
            setAverageOverdueTimePercent('0');
        }
    };

    const getTasksToday = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(dayStart);

            const toDate = formatDate(dayEnd);

            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(subDays(weekStart, 1)))
                    : formatDate(weekStart);

            const toWeek =
                dayDifference <= 0
                    ? formatDate(subDays(weekStart, 1))
                    : formatDate(weekEnd);

            const tasksReq = axios.get(
                `/reports/activities?from_date=${fromDate}&to_date=${toDate}&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const tasksComReq = axios.get(
                `/reports/activities?from_date=${fromWeek}&to_date=${toWeek}&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const [{ data: tasks }, { data: tasksCom }] = await Promise.all([
                tasksReq,
                tasksComReq
            ]);

            const tasksCount = tasks?.count;
            const tasksComCount = tasksCom?.count;

            const comparisonBase =
                dayDifference <= 0 ? tasksComCount / 7 : tasksComCount;

            const percent =
                !comparisonBase && !tasksCount
                    ? 0
                    : !comparisonBase && tasksCount
                    ? 100
                    : Math.round(
                          ((tasks?.count - tasksCom?.count) / tasksCom?.count) *
                              100
                      );

            setTasksCreatedToday(formatValue(tasksCount));

            setTasksCreatedTodayPercent(
                isNaN(percent) ? '0' : percent.toString()
            );
        } catch (err) {
            console.log(err);
            setTasksCreatedToday('0');

            setTasksCreatedTodayPercent('0');
        }
    };

    const getTasksThisWeek = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);

            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayEnd))
                    : formatDate(dayEnd);

            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(subDays(weekEnd, 1)))
                    : formatDate(weekStart);

            const toWeek =
                dayDifference <= 0
                    ? formatDate(subDays(weekEnd, 1))
                    : formatDate(weekEnd);

            const tasksReq = axios.get(
                `/reports/activities?from_date=${fromDate}&to_date=${toDate}&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const tasksComReq = axios.get(
                `/reports/activities?from_date=${fromWeek}&to_date=${toWeek}&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const [{ data: tasks }, { data: tasksCom }] = await Promise.all([
                tasksReq,
                tasksComReq
            ]);

            const percent =
                !tasksCom?.count && !tasks?.count
                    ? 0
                    : !tasksCom?.count && tasks?.count
                    ? 100
                    : Math.round(
                          ((tasks?.count - tasksCom?.count) / tasksCom?.count) *
                              100
                      );

            setTasksCreatedThisWeek(formatValue(tasks?.count));

            setTasksCreatedThisWeekPercent(
                isNaN(percent) ? '0' : percent.toString()
            );
        } catch (err) {
            console.log(err);
            setTasksCreatedThisWeek('0');

            setTasksCreatedThisWeekPercent('0');
        }
    };

    const getLeadsDone = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(dayStart);
            const toDate = formatDate(dayEnd);

            // Structuring request dates for comparison data
            // if dayStart - dayEnd <=0 then we want to fetch comparison data for start of week of dayStart or dayEnd (same result since both dates are same) else we want to fetch data for the previous 7 days (weekStart & weekeend) so we can effectively compare the data with results
            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(subDays(weekStart, 1)))
                    : formatDate(weekStart);

            const toWeek =
                dayDifference <= 0
                    ? formatDate(subDays(weekStart, 1))
                    : formatDate(weekEnd);

            const activityTypesQuery = Object.values(ActivityTypes)
                .filter((value) => value !== ActivityTypes.Assign)
                .map((item) => `activity_type[]=${item}`)
                .join('&');

            const leadsDoneReq = axios.get(
                `/reports/follow-ups?from_date=${fromDate}&to_date=${toDate}&${activityTypesQuery}&activity_status[]=${
                    ActivityStatus.Complete
                }&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const leadsDoneComReq = axios.get(
                `/reports/follow-ups?from_date=${fromWeek}&to_date=${toWeek}&${activityTypesQuery}&activity_status[]=${
                    ActivityStatus.Complete
                }&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const [{ data: leadsDone }, { data: leadsDoneCom }] =
                await Promise.all([leadsDoneReq, leadsDoneComReq]);

            const leadsDoneCount = leadsDone?.count;
            const comparisonBase =
                dayDifference <= 0
                    ? leadsDoneCom?.count / 7
                    : leadsDoneCom?.count;

            const percent =
                !comparisonBase && !leadsDoneCount
                    ? 0
                    : !comparisonBase && leadsDoneCount
                    ? 100
                    : Math.round(
                          ((leadsDoneCount - comparisonBase) / comparisonBase) *
                              100
                      );

            setLeadsDoneToday(formatValue(leadsDoneCount ?? 0));
            setLeadsDoneTodayPercent(
                isNaN(percent) ? '0' : percent?.toString()
            );
        } catch (err) {
            console.log(err);
            setLeadsDoneToday('0');
            setLeadsDoneTodayPercent('0');
        }
    };

    const getFreshLeadsPending = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);
            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayEnd))
                    : formatDate(dayEnd);

            // since we're starting by fetching data of the week, we want to compare with data of last week
            // last weekend is same as subtracting 1 day from start of this week
            // variable naming might be confusing. dayStart and dayEnd holds the the dates that are displayed on screen. Data for these dates are displayed on screen (basically from date and to date). while weekStart and weekEnd holds the dates which we use to do comparisons.
            const lastWeekEnd =
                dayDifference <= 0 ? subDays(weekStart, 1) : weekEnd;

            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(lastWeekEnd))
                    : formatDate(weekStart);

            const toWeek = formatDate(lastWeekEnd);

            const freshLeadsReq = axios.get(
                `/reports/leads?from_date=${fromDate}&to_date=${toDate}&lead_status=${
                    LeadStatus.Fresh
                }&created_by[]=${
                    authuser?.role === newRoles?.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const freshLeadsComReq = axios.get(
                `/reports/leads?from_date=${fromWeek}&to_date=${toWeek}&lead_status=${
                    LeadStatus.Fresh
                }&created_by[]=${
                    authuser?.role === newRoles?.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const [{ data: freshLeads }, { data: freshLeadsCom }] =
                await Promise.all([freshLeadsReq, freshLeadsComReq]);

            const leadsCount = freshLeads?.count;
            const leadsComCount = freshLeadsCom?.count;

            const percent =
                !leadsComCount && !leadsCount
                    ? 0
                    : !leadsComCount && leadsCount
                    ? 100
                    : Math.round(
                          ((leadsCount - leadsComCount) / leadsComCount) * 100
                      );

            setFreshLeadsPending(formatValue(leadsCount));
            setFreshLeadsPendingPercent(
                isNaN(percent) ? '0' : percent?.toString()
            );
        } catch (err) {
            console.log(err);
            setFreshLeadsPending('0');
            setFreshLeadsPendingPercent('0');
        }
    };

    const getLeadsCreatedToday = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(dayStart);
            const toDate = formatDate(dayEnd);

            // since we're starting by fetching data of the week, we want to compare with data of last week
            // variable naming might be confusing. dayStart and dayEnd holds the the dates that are displayed on screen. Data for these dates are displayed on screen (basically from date and to date). while weekStart and weekEnd holds the dates which we use to do comparisons.

            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(subDays(weekStart, 1)))
                    : formatDate(weekStart);

            const toWeek =
                dayDifference <= 0
                    ? formatDate(subDays(weekStart, 1))
                    : formatDate(weekEnd);

            const leadsReq = axios.get(
                `/reports/leads?from_date=${fromDate}&to_date=${toDate}&created_by[]=${
                    authuser?.role === newRoles?.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const leadsComReq = axios.get(
                `/reports/leads?from_date=${fromWeek}&to_date=${toWeek}&created_by[]=${
                    authuser?.role === newRoles?.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const [{ data: leads }, { data: leadsCom }] = await Promise.all([
                leadsReq,
                leadsComReq
            ]);

            const leadsCount = leads?.count;
            const leadsComCount =
                dayDifference <= 0 ? leadsCom?.count / 7 : leadsCom?.count;

            const percent =
                !leadsComCount && !leadsCount
                    ? 0
                    : !leadsComCount && leadsCount
                    ? 100
                    : Math.round(
                          ((leadsCount - leadsComCount) / leadsComCount) * 100
                      );

            setLeadsCreatedToday(formatValue(leadsCount));
            setLeadsCreatedTodayPercent(
                isNaN(percent) ? '0' : percent?.toString()
            );
        } catch (err) {
            console.log(err);
            setLeadsCreatedToday('0');
            setLeadsCreatedTodayPercent('0');
        }
    };

    const getLeadsCreatedThisWeek = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);
            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayEnd))
                    : formatDate(dayEnd);

            // since we're starting by fetching data of the week, we want to compare with data of last week
            // last weekend is same as subtracting 1 day from start of this week
            // variable naming might be confusing. dayStart and dayEnd holds the the dates that are displayed on screen. Data for these dates are displayed on screen (basically from date and to date). while weekStart and weekEnd holds the dates which we use to do comparisons.
            const lastWeekEnd =
                dayDifference <= 0 ? subDays(weekStart, 1) : weekEnd;

            const fromWeek =
                dayDifference <= 0
                    ? formatDate(startOfWeek(lastWeekEnd))
                    : formatDate(weekStart);

            const toWeek = formatDate(lastWeekEnd);

            const leadsReq = axios.get(
                `/reports/leads?from_date=${fromDate}&to_date=${toDate}&created_by[]=${
                    authuser?.role === newRoles?.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const leadsComReq = axios.get(
                `/reports/leads?from_date=${fromWeek}&to_date=${toWeek}&created_by[]=${
                    authuser?.role === newRoles?.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const [{ data: leads }, { data: leadsCom }] = await Promise.all([
                leadsReq,
                leadsComReq
            ]);

            const leadsCount = leads?.count;
            const leadsComCount = leadsCom?.count;

            const percent =
                !leadsComCount && !leadsCount
                    ? 0
                    : !leadsComCount && leadsCount
                    ? 100
                    : Math.round(
                          ((leadsCount - leadsComCount) / leadsComCount) * 100
                      );

            setLeadsCreatedThisWeek(formatValue(leadsCount));
            setLeadsCreatedThisWeekPercent(
                isNaN(percent) ? '0' : percent?.toString()
            );
        } catch (err) {
            console.log(err);
            setLeadsCreatedThisWeek('0');
            setLeadsCreatedThisWeekPercent('0');
        }
    };

    const getDailyLeadsDone = async () => {
        try {
            // calculate the difference in datys
            const dayDifference = dayEnd - dayStart;

            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate =
                dayDifference <= 0
                    ? formatDate(startOfWeek(dayStart))
                    : formatDate(dayStart);
            const toDate =
                dayDifference <= 0
                    ? formatDate(endOfWeek(dayStart))
                    : formatDate(dayEnd);

            const activityTypesQuery = Object.values(ActivityTypes)
                .filter((value) => value !== ActivityTypes.Assign)
                .map((item) => `activity_type[]=${item}`)
                .join('&');

            const { data: dailyLeadsDone } = await axios.get(
                `/reports/follow-ups?from_date=${fromDate}&to_date=${toDate}&${activityTypesQuery}&activity_status[]=${
                    ActivityStatus.Complete
                }&daily_data=true&agent=${
                    authuser?.role === newRoles.Agent
                        ? authuser?.id
                        : agent?.userId
                }`
            );

            const leadsDoneData = dailyLeadsDone?.data?.length
                ? dailyLeadsDone?.data?.sort(
                      (a, b) => new Date(a?.date) - new Date(b?.date)
                  )
                : [];

            let dateArray = [];
            let currentDate =
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart;

            const stopDate = dayDifference <= 0 ? endOfWeek(dayStart) : dayEnd;

            while (currentDate <= stopDate) {
                dateArray.push(currentDate);
                currentDate = addDays(new Date(currentDate), 1);
            }

            const dailyLeadsDoneData = dateArray?.map((date) => {
                const fromattedDate = formatDate(date);

                const data = leadsDoneData?.find(
                    (item) => item?.date === fromattedDate
                );

                return {
                    date: getDayName(new Date(data?.date ?? fromattedDate)),
                    count: data?.count ?? 0
                };
                // console.log(fromattedDate)
            });

            setDailyLeadsDone(dailyLeadsDoneData);
        } catch (err) {
            console.log(err);
            setDailyLeadsDone([]);
        }
    };

    const getClientsCreatedThisWeek = async () => {
        try {
            const dayDifference = dayEnd - dayStart;
            // format dates to required backend structure (yyyy-mm-dd)
            const fromDate = formatDate(
                dayDifference <= 0 ? startOfWeek(dayStart) : dayStart
            );

            const toDate = formatDate(
                dayDifference <= 0 ? endOfWeek(dayEnd) : dayEnd
            );

            const { data: clients } = await axios.get(
                `/reports/deals/clients-created?from_date=${fromDate}&to_date=${toDate}&created_by[]=${agent?.id}`
            );

            setClientsThisWeek(clients?.data);
        } catch (err) {
            console.log(err);
            setClientsThisWeek([]);
        }
    };

    useEffect(() => {
        updateFetchingStatus(); // lets user know we're fetching data

        getFollowUpsDone();
        getFollowUpsPending();
        getDailyFollowUps();
        getDailyOverdueTasks();
        getOverdueTime();
        getTasksToday();
        getTasksThisWeek();
        getViewings();
        // getListingsAcquired();
        getListingsAcquiredThisWeek();
        // getDealsClosedThisWeek();
        getDailyDealsClosed();
        getDailyListingsAcquired();
        getLeadsDone();
        getDailyLeadsDone();
        getFreshLeadsPending();
        getLeadsCreatedToday();
        getLeadsCreatedThisWeek();
        getClientsCreatedThisWeek();
    }, [dayStart, dayEnd, agent]);

    useEffect(() => {
        getDealsClosedThisMonth();
        getDealsPendingThisMonth();
    }, [monthStart, agent]);

    useEffect(() => {
        resetDealsOnAgentChange();
        getListingsAcquired(); // only fetch once page loads
        getDealsClosedThisWeek(); // only fetch once page loads
    }, [agent]);

    useEffect(() => {
        if (authuser?.role !== newRoles.Agent) {
            const defaultAgent = localStorage.getItem('default_agent');
            if (defaultAgent) {
                setDefaultAgent(JSON.parse(defaultAgent));
            }
        }
    }, []);

    useEffect(() => {
        const timeout = dateError
            ? setTimeout(() => {
                  setDateError('');
              }, 3000)
            : undefined;

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [dateError]);

    useEffect(() => {
        !agents?.length && dispatch(fetchAgents(axios));
    }, [dispatch]);

    useEffect(() => {
        fetchAgentImage();

        return () => {};
    }, [agent]);

    const printDoc = () => {
        const doc = printRef.current;

        html2canvas(doc).then((canvas) => {
            const data = canvas.toDataURL('application/pdf');
            const pdf = new jsPDF();
            pdf.addImage(data, 'pdf', 0, 0);

            pdf.save('test.pdf');
        });
    };

    if (!agent || (!agent?.id && !agent?.userId)) {
        return <div>Please Select an agent or sign in as an agent</div>;
    }

    const html = (
        <>
            <Head title="Agent Dashboard | Alba Homes" />
            <Content id="printableId">
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Agent Dashboard</BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                    <div className="d-flex justify-content-end align-items-end mt-4">
                        <div className="toggle-wrap nk-block-tools-toggle">
                            <a
                                href="#toggle"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setSmBtn(!smBtn);
                                }}
                                className="btn btn-icon btn-trigger toggle-expand me-n1"
                            >
                                <Icon name="menu-alt-r"></Icon>
                            </a>
                            <div
                                className={`toggle-expand-content ${
                                    smBtn ? 'expanded' : ''
                                }`}
                            >
                                <ul className="nk-block-tools g-1">
                                    <li>
                                        <div className="form-control-wrap">
                                            <button
                                                className="btn bg-primary text-white"
                                                onClick={() => {
                                                    setDayStart(
                                                        startOfWeek(today)
                                                    );
                                                    setDayEnd(endOfWeek(today));

                                                    const weekend = subDays(
                                                        startOfWeek(today),
                                                        1
                                                    );

                                                    setWeekStart(
                                                        startOfWeek(weekend)
                                                    );
                                                    setWeekEnd(weekend);
                                                    setMonthStart(
                                                        startOfMonth(today)
                                                    );
                                                }}
                                            >
                                                Current Week
                                            </button>
                                        </div>
                                    </li>
                                    <li>
                                        <button
                                            className="btn bg-secondary text-white "
                                            onClick={async () => {
                                                const lastWeekEnd = subDays(
                                                    startOfWeek(today),
                                                    1
                                                );

                                                const lastWeekStart =
                                                    startOfWeek(lastWeekEnd);

                                                const endOf2WeeksAgo = subDays(
                                                    lastWeekStart,
                                                    1
                                                );
                                                const startOf2WeeksAgo =
                                                    startOfWeek(endOf2WeeksAgo);

                                                setDayStart(lastWeekStart);
                                                setDayEnd(lastWeekEnd);

                                                setWeekStart(startOf2WeeksAgo);
                                                setWeekEnd(endOf2WeeksAgo);
                                                setMonthStart(
                                                    startOfMonth(lastWeekStart)
                                                );
                                            }}
                                        >
                                            Last Week
                                        </button>
                                    </li>
                                    <li>
                                        <div className="form-group">
                                            <div className="form-control-wrap position-relative">
                                                <div className="text-danger fs-sm text-center position-absolute start-50 bottom-100 translate-middle-x w-100">
                                                    {dateError}
                                                </div>
                                                <div className="input-daterange date-picker-range input-group">
                                                    <DatePicker
                                                        selected={dayStart}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }} // prevent user from typing in date
                                                        onChange={(date) => {
                                                            if (date > dayEnd) {
                                                                setDateError(
                                                                    'Cannot be greater than end date'
                                                                );
                                                                return;
                                                            }

                                                            const interval =
                                                                differenceInDays(
                                                                    dayEnd,
                                                                    date
                                                                );

                                                            if (interval >= 7) {
                                                                setDateError(
                                                                    'Interval cannot be more than 7 days'
                                                                );
                                                                return;
                                                            }

                                                            setDayStart(date);
                                                            // setDayEnd(addDays(date, 6));
                                                            const weekEnd =
                                                                subDays(
                                                                    date,
                                                                    1
                                                                );
                                                            const weekStart =
                                                                subDays(
                                                                    weekEnd,
                                                                    interval
                                                                );

                                                            setWeekStart(
                                                                weekStart
                                                            );
                                                            setWeekEnd(weekEnd);
                                                            setMonthStart(
                                                                startOfMonth(
                                                                    date
                                                                )
                                                            );
                                                        }}
                                                        selectsStart
                                                        startDate={dayStart}
                                                        endDate={dayEnd}
                                                        maxDate={dayEnd}
                                                        wrapperClassName="start-m"
                                                        className="form-control"
                                                    />
                                                    <div className="input-group-addon">
                                                        TO
                                                    </div>
                                                    <DatePicker
                                                        selected={dayEnd}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }} // prevent user from typing in date
                                                        onChange={(date) => {
                                                            const interval =
                                                                differenceInDays(
                                                                    date,
                                                                    dayStart
                                                                );

                                                            if (interval >= 7) {
                                                                setDateError(
                                                                    'Interval cannot be more than 7 days'
                                                                );
                                                                return;
                                                            }

                                                            // const daystart = subDays(
                                                            //     date,
                                                            //     interval
                                                            // );
                                                            setDayEnd(date);
                                                            // setDayStart(daystart);

                                                            const weekEnd =
                                                                subDays(
                                                                    dayStart,
                                                                    1
                                                                );
                                                            const weekStart =
                                                                subDays(
                                                                    weekEnd,
                                                                    interval
                                                                );

                                                            setWeekStart(
                                                                weekStart
                                                            );
                                                            setWeekEnd(weekEnd);
                                                            setMonthStart(
                                                                startOfMonth(
                                                                    date
                                                                )
                                                            );
                                                        }}
                                                        startDate={dayStart}
                                                        endDate={dayEnd}
                                                        selectsEnd
                                                        minDate={dayStart}
                                                        wrapperClassName="end-m"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        {(authuser?.role === newRoles.Admin ||
                                            authuser?.role ===
                                                newRoles.Marketing ||
                                            authuser?.role ===
                                                newRoles.MasterAdmin ||
                                            authuser?.role ===
                                                newRoles.SalesManager) && (
                                            <div className="status-div postion-relative">
                                                <RSelect
                                                    placeholder="Select agent"
                                                    value={agent}
                                                    onChange={handleSelectAgent}
                                                    options={
                                                        agents && agents?.length
                                                            ? agents?.map(
                                                                  (agent) => ({
                                                                      ...agent,
                                                                      label: `${agent?.user?.first_name} ${agent?.user?.last_name}`,
                                                                      value: `${agent?.user?.first_name} ${agent?.user?.last_name}`
                                                                  })
                                                              )
                                                            : []
                                                    }
                                                />
                                            </div>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </BlockHead>

                <Row className={`g-gs mb-4 ps-2`}>
                    {authuser?.role !== newRoles?.Agent ? (
                        <>
                            <Col
                                sm="2"
                                xxl="2"
                                className="position-relative bg-white"
                            >
                                <div
                                    className="d-flex flex-column justify-content-center align-items-center card-inner "
                                    style={{ paddingTop: '5rem' }}
                                >
                                    <img
                                        src={image ?? defaultImage}
                                        className="rounded-pill w-80"
                                        alt="Selected agent"
                                    />
                                    <div className="mt-2">
                                        <h6 className="text-center ff-base fw-medium">
                                            {`${agent?.user?.first_name} ${agent?.user?.last_name}`}
                                        </h6>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center mt-1 mb-1">
                                        <a
                                            href={`whatsapp://send?phone=${agent?.user?.phone}`}
                                        >
                                            <Icon
                                                name="whatsapp"
                                                className="me-1"
                                                style={{ cursor: 'pointer' }}
                                            ></Icon>
                                        </a>
                                        <a href={`tel:${agent?.user?.phone}`}>
                                            <Icon
                                                name="call"
                                                className="me-1"
                                                style={{ cursor: 'pointer' }}
                                            ></Icon>
                                        </a>
                                        <a
                                            href={`mailto:${agent?.user?.email}`}
                                        >
                                            <Icon
                                                name="mail"
                                                className="me-1"
                                                style={{ cursor: 'pointer' }}
                                            ></Icon>
                                        </a>
                                    </div>
                                    {defaultAgent &&
                                        defaultAgent?.id === agent?.id && (
                                            <div className="position-absolute top-0 end-0 p-2">
                                                <Icon
                                                    name="user-remove-fill"
                                                    style={{
                                                        fontSize: '1.2rem',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        localStorage.removeItem(
                                                            'default_agent'
                                                        );
                                                        handleNormalAgent();
                                                        setDefaultAgent(null);
                                                    }}
                                                    title="Disable Default Agent"
                                                ></Icon>
                                            </div>
                                        )}
                                </div>
                            </Col>
                            <Col sm="5" xxl={'5'}>
                                <DataCard
                                    title={'Deals Closed This Week'}
                                    amount={
                                        dealsClosedThisWeek ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    chart={<DefaultOrderChart />}
                                    percentChange={
                                        dealsClosedThisWeekPercent ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    up={Number(dealsClosedThisWeekPercent) >= 0}
                                    comparisonText="last week"
                                />
                                <DataCard
                                    title={'Deals Closed This Month'}
                                    amount={
                                        dealsClosedThisMonth ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    chart={<DefaultOrderChart />}
                                    percentChange={
                                        dealsClosedThisMonthPercent ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    up={
                                        Number(dealsClosedThisMonthPercent) >= 0
                                    }
                                    comparisonText="last month"
                                />
                            </Col>
                            <Col sm="5" xxl={'5'}>
                                <DataCard
                                    title={`Listings Acquired Today`}
                                    amount={
                                        listingsAcquiredToday ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    chart={<DefaultOrderChart />}
                                    percentChange={
                                        listingsAcquiredTodayPercent ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    up={
                                        Number(listingsAcquiredTodayPercent) >=
                                        0
                                    }
                                    comparisonText={'avg. last week'}
                                />
                                <DataCard
                                    title={`Listings Acquired ${
                                        dayEnd - dayStart <= 0
                                            ? 'This Week'
                                            : 'This  Period'
                                    }`}
                                    amount={
                                        listingsAcquiredThisWeek ? (
                                            listingsAcquiredThisWeek?.length
                                        ) : (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    chart={<DefaultOrderChart />}
                                    percentChange={
                                        listingsAcquiredThisWeekPercent ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    up={
                                        Number(
                                            listingsAcquiredThisWeekPercent
                                        ) >= 0
                                    }
                                    comparisonText="last week"
                                />
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col md="6" xxl={'3'}>
                                <DataCard
                                    title={'Deals Closed This Week'}
                                    amount={
                                        dealsClosedThisWeek ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    chart={<DefaultOrderChart />}
                                    percentChange={
                                        dealsClosedThisWeekPercent ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    up={Number(dealsClosedThisWeekPercent) >= 0}
                                    comparisonText="last week"
                                />
                            </Col>
                            <Col md="6" xxl={'3'}>
                                <DataCard
                                    title={'Deals Closed This Month'}
                                    amount={
                                        dealsClosedThisMonth ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    chart={<DefaultOrderChart />}
                                    percentChange={
                                        dealsClosedThisMonthPercent ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    up={Number(dealsClosedThisMonth) >= 0}
                                    comparisonText="last month"
                                />
                            </Col>
                            <Col md="6" xxl={'3'}>
                                <DataCard
                                    title={`Listings Acquired Today`}
                                    amount={
                                        listingsAcquiredToday ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    chart={<DefaultOrderChart />}
                                    percentChange={
                                        listingsAcquiredTodayPercent ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    up={
                                        Number(listingsAcquiredTodayPercent) >=
                                        0
                                    }
                                    comparisonText={'avg. last week'}
                                />
                            </Col>
                            <Col md="6" xxl={'3'}>
                                <DataCard
                                    title={`Listings Acquired ${
                                        dayEnd - dayStart <= 0
                                            ? 'This Week'
                                            : 'This  Period'
                                    }`}
                                    amount={
                                        listingsAcquiredThisWeek ? (
                                            listingsAcquiredThisWeek?.length
                                        ) : (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    chart={<DefaultOrderChart />}
                                    percentChange={
                                        listingsAcquiredThisWeekPercent ?? (
                                            <Spinner size={'sm'} />
                                        )
                                    }
                                    up={
                                        Number(
                                            listingsAcquiredThisWeekPercent
                                        ) >= 0
                                    }
                                    comparisonText="last week"
                                />
                            </Col>
                        </>
                    )}
                </Row>
                <Row className="g-gs">
                    <Col md="6" xxl="3">
                        <DataCard
                            title={
                                dayEnd - dayStart <= 0
                                    ? 'Viewings Done Today'
                                    : 'Viewings Done This Period'
                            }
                            amount={viewingsDone ?? <Spinner size={'sm'} />}
                            chart={<DefaultOrderChart />}
                            percentChange={
                                viewingsDonePercent ?? <Spinner size={'sm'} />
                            }
                            up={Number(viewingsDonePercent) >= 0}
                            comparisonText={
                                dayEnd - dayStart <= 0
                                    ? 'avg last week'
                                    : 'last week'
                            }
                        />
                        <DataCard
                            title={
                                dayEnd - dayStart <= 0
                                    ? 'Viewings Pending Today'
                                    : 'Viewings Pending This Period'
                            }
                            amount={viewingsPending ?? <Spinner size={'sm'} />}
                            chart={<DefaultOrderChart />}
                            percentChange={
                                viewingsPendingPercent ?? (
                                    <Spinner size={'sm'} />
                                )
                            }
                            up={Number(viewingsPendingPercent) >= 0}
                            comparisonText={
                                dayEnd - dayStart <= 0
                                    ? 'avg this week'
                                    : 'last week'
                            }
                        />
                    </Col>
                    <Col md="6" xxl="3">
                        <DataCard
                            title={
                                dayEnd - dayStart <= 0
                                    ? 'Leads Created Today'
                                    : 'Leads Created This Period'
                            }
                            amount={
                                leadsCreatedToday ?? <Spinner size={'sm'} />
                            }
                            chart={<DefaultOrderChart />}
                            percentChange={
                                leadsCreatedTodayPercent ?? (
                                    <Spinner size={'sm'} />
                                )
                            }
                            up={Number(leadsCreatedTodayPercent) >= 0}
                            comparisonText={
                                dayEnd - dayStart <= 0
                                    ? 'avg last week'
                                    : 'last week'
                            }
                        />
                        <DataCard
                            title={
                                dayEnd - dayStart <= 0
                                    ? 'Leads Created This Week'
                                    : 'Leads Created This Period'
                            }
                            amount={
                                leadsCreatedThisWeek ?? <Spinner size={'sm'} />
                            }
                            chart={<DefaultOrderChart />}
                            percentChange={
                                leadsCreatedThisWeekPercent ?? (
                                    <Spinner size={'sm'} />
                                )
                            }
                            up={Number(leadsCreatedThisWeekPercent) >= 0}
                            comparisonText={'last week'}
                        />
                    </Col>

                    <Col md="6" xxl="3">
                        <DataCard
                            title={
                                dayEnd - dayStart <= 0
                                    ? 'Leads Done Today'
                                    : 'Leads Done This Period'
                            }
                            amount={leadsDoneToday ?? <Spinner size={'sm'} />}
                            chart={<DefaultOrderChart />}
                            percentChange={
                                leadsDoneTodayPercent ?? <Spinner size={'sm'} />
                            }
                            up={Number(leadsDoneTodayPercent) >= 0}
                            comparisonText={
                                dayEnd - dayStart <= 0
                                    ? 'avg last week'
                                    : 'last week'
                            }
                        />
                        <DataCard
                            title={
                                dayStart - dayEnd <= 0
                                    ? 'Fresh Leads Pending This Week'
                                    : 'Fresh Leads Pending This Period'
                            }
                            amount={
                                freshLeadsPending ?? <Spinner size={'sm'} />
                            }
                            percentChange={
                                freshLeadsPendingPercent ?? (
                                    <Spinner size={'sm'} />
                                )
                            }
                            up={Number(freshLeadsPendingPercent) >= 0}
                            chart={<DefaultOrderChart />}
                            comparisonText={'last week'}
                        />
                    </Col>
                    <Col md="6" xxl="3">
                        <DataCard
                            title={
                                dayEnd - dayStart <= 0
                                    ? 'Tasks Created Today'
                                    : 'Tasks Created This Period'
                            }
                            percentChange={
                                tasksCreatedTodayPercent ?? (
                                    <Spinner size={'sm'} />
                                )
                            }
                            amount={
                                tasksCreatedToday ?? <Spinner size={'sm'} />
                            }
                            chart={<DefaultOrderChart />}
                            up={Number(tasksCreatedTodayPercent) >= 0}
                            comparisonText={
                                dayEnd - dayStart <= 0
                                    ? 'avg last week'
                                    : 'last week'
                            }
                        />

                        <DataCard
                            title={
                                dayEnd - dayStart <= 0
                                    ? 'Tasks Created This Week'
                                    : 'Tasks Created This Period'
                            }
                            amount={
                                tasksCreatedThisWeek ?? <Spinner size={'sm'} />
                            }
                            chart={<DefaultOrderChart />}
                            percentChange={
                                tasksCreatedThisWeekPercent ?? (
                                    <Spinner size={'sm'} />
                                )
                            }
                            up={Number(tasksCreatedThisWeekPercent) >= 0}
                            comparisonText="last week"
                        />
                    </Col>

                    <Col md="6" xxl="3">
                        <DataCard
                            title={
                                <>
                                    N<sup>o</sup> Of Overdue Tasks{' '}
                                    {dayEnd - dayStart <= 0
                                        ? 'Today'
                                        : 'This Period'}
                                </>
                            }
                            amount={overdueTasks ?? <Spinner size={'sm'} />}
                            chart={<DefaultOrderChart />}
                            percentChange={
                                overdueTasksPercent ?? <Spinner size={'sm'} />
                            }
                            up={Number(overdueTasksPercent) <= 0}
                            comparisonText={
                                dayEnd - dayStart <= 0
                                    ? 'avg last week'
                                    : 'last week'
                            }
                        />
                    </Col>
                    <Col>
                        <DataCard
                            title={'Average Overdue Time'}
                            percentChange={
                                averageOverdueTimePercent ?? (
                                    <Spinner size={'sm'} />
                                )
                            }
                            amount={
                                averageOverdueTime ? (
                                    `${averageOverdueTime} days`
                                ) : (
                                    <Spinner size={'sm'} />
                                )
                            }
                            chart={<DefaultOrderChart />}
                            up={Number(averageOverdueTimePercent) <= 0}
                            comparisonText={
                                dayEnd - dayStart <= 0
                                    ? 'avg last week'
                                    : 'last week'
                            }
                        />
                    </Col>
                    <Col md="6" xxl="3">
                        <DataCard
                            title={
                                dayEnd - dayStart <= 0
                                    ? 'Follow-ups Done Today'
                                    : 'Follow-ups Done This Period'
                            }
                            amount={
                                followUpsDone ? (
                                    formatValue(followUpsDone ?? 0)
                                ) : (
                                    <Spinner size={'sm'} />
                                )
                            }
                            percentChange={
                                followUpsDonePercent ?? <Spinner size={'sm'} />
                            }
                            up={Number(followUpsDonePercent) >= 0}
                            chart={<DefaultOrderChart />}
                            comparisonText={
                                dayEnd - dayStart <= 0
                                    ? 'avg last week'
                                    : 'last week'
                            }
                        />
                    </Col>
                    <Col md="6" xxl="3">
                        <DataCard
                            title={
                                dayEnd - dayStart <= 0
                                    ? 'Follow-ups Pending This Week'
                                    : 'Follow-ups Pending This Period'
                            }
                            amount={
                                followUpsPending ? (
                                    formatValue(Number(followUpsPending) ?? 0)
                                ) : (
                                    <Spinner size={'sm'} />
                                )
                            }
                            chart={<DefaultOrderChart />}
                            percentChange={
                                followUpsPendingPercent ? (
                                    followUpsPendingPercent
                                ) : (
                                    <Spinner size={'sm'} />
                                )
                            }
                            up={Number(followUpsPendingPercent) >= 0}
                            comparisonText={'last week'}
                        />
                    </Col>

                    <Col sm="6" xxl="6">
                        <RenderChart
                            chartTitle={'Leads Done'}
                            chart={
                                <MultiBarsBarChart
                                    data={{
                                        labels:
                                            dailyLeadsDone &&
                                            dailyLeadsDone?.length
                                                ? dailyLeadsDone?.map(
                                                      (item) => item?.date
                                                  )
                                                : updateLabels(),
                                        datasets: [
                                            {
                                                data: dailyLeadsDone?.map(
                                                    (item) => item?.count
                                                ),
                                                borderColor: '#9d72ff',
                                                backgroundColor: '#9472ff'
                                            }
                                        ]
                                    }}
                                />
                            }
                            chartLabelTitles={
                                <>
                                    <li>
                                        <div className="title">
                                            <span
                                                className="dot dot-lg sq"
                                                style={{
                                                    background: '#9d72ff'
                                                }}
                                            ></span>
                                            <span>
                                                Leads Done{' '}
                                                {dayEnd - dayStart <= 0
                                                    ? 'This Week'
                                                    : 'This Period'}
                                            </span>
                                        </div>
                                    </li>
                                </>
                            }
                            // chartFooter={
                            //     <span className="text-center w-100">
                            //         {`${getDayName(startOfWeek(dayStart))} >>>>>
                            //         ${getDayName(endOfWeek(dayEnd))}`}
                            //     </span>
                            // }
                        />
                    </Col>
                    <Col sm="6" xxl="6">
                        <RenderChart
                            chartTitle={'Follow-ups Done / Overdue Tasks'}
                            chart={
                                <MultiBarsBarChart
                                    data={{
                                        labels:
                                            dailyFollowUps &&
                                            dailyFollowUps?.length
                                                ? dailyFollowUps?.map(
                                                      (item) => item?.date
                                                  )
                                                : dailyOverdueTasks &&
                                                  dailyOverdueTasks?.length
                                                ? dailyOverdueTasks?.map(
                                                      (item) => item?.date
                                                  )
                                                : updateLabels(),
                                        datasets: [
                                            {
                                                data: dailyFollowUps?.map(
                                                    (item) => item?.count
                                                ),
                                                borderColor: '#9d72ff',
                                                backgroundColor: '#9472ff'
                                            },
                                            {
                                                data: dailyOverdueTasks?.map(
                                                    (item) => item?.count
                                                ),
                                                borderColor: '#eb6459',
                                                backgroundColor: '#eb6459'
                                            }
                                        ]
                                    }}
                                />
                            }
                            chartLabelTitles={
                                <>
                                    <li>
                                        <div className="title">
                                            <span
                                                className="dot dot-lg sq"
                                                style={{
                                                    background: '#9d72ff'
                                                }}
                                            ></span>
                                            <span>Follow-ups Done</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="title">
                                            <span
                                                className="dot dot-lg sq"
                                                style={{
                                                    background: '#eb6459'
                                                }}
                                            ></span>
                                            <span>Overdue Tasks</span>
                                        </div>
                                    </li>
                                </>
                            }
                            // chartFooter={
                            //     <span className="text-center w-100">
                            //         {`${getDayName(startOfWeek(dayStart))} >>>>>
                            //         ${getDayName(endOfWeek(dayEnd))}`}
                            //     </span>
                            // }
                        />
                    </Col>

                    <Col sm="6" xxl="6">
                        <RenderChart
                            chartTitle={'Listings Acquired / Deals Closed'}
                            chart={
                                <MultiBarsBarChart
                                    data={{
                                        labels:
                                            dailyDeals && dailyDeals?.length
                                                ? dailyDeals?.map(
                                                      (item) => item?.date
                                                  )
                                                : dailyListings &&
                                                  dailyListings?.length
                                                ? dailyListings?.map(
                                                      (item) => item?.date
                                                  )
                                                : updateLabels(),
                                        datasets: [
                                            {
                                                data: dailyDeals?.map(
                                                    (item) => item?.count
                                                ),
                                                borderColor: '#9BA98E',
                                                backgroundColor: '#9BA98E'
                                            },
                                            {
                                                data: dailyListings?.map(
                                                    (item) => item?.count
                                                ),
                                                borderColor: '#89B9E0',
                                                backgroundColor: '#89B9E0'
                                            }
                                        ]
                                    }}
                                />
                            }
                            chartLabelTitles={
                                <>
                                    <li>
                                        <div className="title">
                                            <span
                                                className="dot dot-lg sq"
                                                style={{
                                                    background: '#9BA98E'
                                                }}
                                            ></span>
                                            <span>Deals Closed</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="title">
                                            <span
                                                className="dot dot-lg sq"
                                                style={{
                                                    background: '#89B9E0'
                                                }}
                                            ></span>
                                            <span>Listings Acquired</span>
                                        </div>
                                    </li>
                                </>
                            }
                            // chartFooter={
                            //     <span className="text-center w-100">
                            //         {`${getDayName(startOfWeek(dayStart))} >>>>>
                            //         ${getDayName(endOfWeek(dayEnd))}`}
                            //     </span>
                            // }
                        />
                    </Col>
                    <Col md="6">
                        <DealsClosedGrid
                            deals={dealsClosed}
                            fetching={!dealsClosed}
                        />
                    </Col>

                    <Col md="6">
                        <ClientsAcquired
                            clients={
                                clientsThisWeek?.map((item) => item?.user) ?? []
                            }
                            fetching={!clientsThisWeek}
                        />
                    </Col>
                    <Col md="6">
                        <DealsPendingGrid
                            deals={dealsPendingThisMonth ?? []}
                            fetching={!dealsPendingThisMonth}
                        />
                    </Col>
                </Row>
            </Content>
        </>
    );

    return <>{html}</>;
}
