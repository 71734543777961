import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
} from "../../../components/Component";
import { Button, Card, CardBody, CardLink, Col, Row } from "reactstrap";
import AddFooterTemplate from "./AddFooterTemplate";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getImage } from "../../../utils/envConfig";
import { toast } from "react-toastify";
import ConfirmModal from "../../../components/miscellaneous/ConfirmModal";

// const currentItems = [
//   { developer: "Damac", createdAt: "2023-07-10", modifiedAt: "2023-07-11" },
//   { developer: "Emmar", createdAt: "2023-07-12", modifiedAt: "2023-07-13" },
// ];

const FooterTemplates = () => {
  const axios = useAxiosPrivate();
  const [onSearch, setonSearch] = useState(true);
  const toggleSearch = () => setonSearch(!onSearch);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearchText, setSearchText] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSortState] = useState("DESC");
  const [sortBy, setSorBy] = useState("createdAt");
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [filCount, setFilCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const [isOpenFooterModal, setIsOpenFooterModal] = useState(false);

  const toggleModalFooter = () => {
    setIsOpenFooterModal(!isOpenFooterModal);
  };

  const [footers, setFooters] = useState([]);
  const [footer, setFooter] = useState({});

  const token = JSON.parse(localStorage.getItem("authuser"))?.access_token;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const handleModal = ( e ) =>
  {
    e.preventDefault();
    setShowModal( true );
  };
  const closeModal = () => {
    setShowModal(false)
  }
  useEffect(() => {
    fetchFooters();
  }, []);

  const fetchFooters = async () => {
    try {
      const { data } = await axios.get(`off-plan/footer-template`);
      const footers = await Promise.all(
        data?.map(async (item) => {
          const image = await getImage(item?.logo, axios);
          return {
            ...item,
            image: image?.signedRequest,
          };
        })
      );

      setFooters(footers);
    } catch (err) {
      // console.log(err);
    }
  };

  const handleDeleteFooter = async () => {
    try {
      setLoading(true)
      const { data } = await axios.delete(
        `off-plan/footer-template/${footer?._id}`,
        { headers }
      );
      setLoading(false);
      setFooters(footers.filter((item) => item?._id !== footer?._id));

      setFooter({});
      closeModal();
      toast.success("Deleted successfully");
 
    } catch (err) {
      // console.log(err);
      toast.error(err?.response?.data?.message);
      setFooter({});
    }
  };
  

  return (
    <React.Fragment>
      <Head title="Off Plan"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Footer Templates</BlockTitle>
            </BlockHeadContent>
            <div className="toggle-expand-content" style={{ display: "block" }}>
              <Button onClick={toggleModalFooter} className="bg-primary">
                <Icon name="plus" />
                <span>Add Footer Template</span>
              </Button>

              <AddFooterTemplate
                isOpen={isOpenFooterModal}
                toggle={toggleModalFooter}
              />
            </div>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <Row>
              {footers?.length>0 &&
                footers?.map((footer) => (
                  <Col sm="4">
                    <Card>
                      <video
                        controls
                        src={footer?.image}
                        alt="Offplan footer"
                        autoPlay
                        muted
                        width="100%"
                      ></video>
                      <CardBody className="card-inner-sm">
                        <div className="d-flex justify-content-center align-items-center border-bottom p-1 rounded pb-2">
                          <h6>{footer?.name}</h6>{" "}
                        </div>
                        <div className="pt-2 d-flex justify-content-between align-items-center">
                          <CardLink
                            onClick={(e) => {
                              e.preventDefault();
                              // setFooter(footer);
                              // handleModal(e);
                              
                            }}
                            className="text-danger"
                          >
                            Delete Template
                          </CardLink>
                          <CardLink
                            onClick={() => {
                              setFooter(footer);
                            }}
                          >
                            Edit Template
                          </CardLink>{" "}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
            </Row>
          </PreviewCard>
                {/* ****** Modal Start ****** */}
      {showModal ? (
        <ConfirmModal
          msg={"Delete "}
          name={"The Footer"}
          open={showModal}
          handleClick={handleModal}
          handleConfirm={handleDeleteFooter}
          handlecloseModal={closeModal}
          action="Delete"
          loading={loading}
        />
      ) : null}
      {/* ****** Modal End ****** */}
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default FooterTemplates;
