import React, { useEffect, useState } from 'react';
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor
} from '@dnd-kit/core';
import {
  SortableContext,
  rectSortingStrategy,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Icon } from '../../../components/Component';

function Image({ image, removeImage, index, desc = true }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: image?.id });
  
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (image?.file && typeof image?.file !== 'string') {
      const url = URL.createObjectURL(image.file);
      setImageUrl(url);
      return () => URL.revokeObjectURL(url);
    } else {
      setImageUrl(image?.file);
    }
  }, [image]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ddd',
    borderRadius: '4px',
    width: '100%',
    height: 'auto',
    position: 'relative'
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <img
        src={imageUrl}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderTopLeftRadius: '0.5rem'
        }}
        alt="Draggable"
        //  style={{ width: "100%", height: "auto" }}
      />

      <button
        type="button"
        onClick={() => removeImage(index)}
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          zIndex: '999',
          padding: '0.5rem'
        }}
        className="bg-danger"
      >
        <Icon
          name="trash"
          className="text-white"
          style={{ cursor: 'pointer' }}
        />
      </button>
    </div>
  );
}

function DnDKitProperty({ images, setImages, removeImage }) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    })
  );
  // const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    const oldIndex = images.findIndex((image) => image.id === active.id);
    const newIndex = images.findIndex((image) => image.id === over.id);

    if (oldIndex === newIndex) return;

    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      const [movedImage] = updatedImages.splice(oldIndex, 1);
      updatedImages.splice(newIndex, 0, movedImage);
      return updatedImages;
    });
  };

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '10px'
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={images} strategy={rectSortingStrategy}>
        <div style={gridContainerStyle}>
          {images.map((image, index) => (
            <Image
              key={image.id}
              index={index}
              image={image}
              removeImage={removeImage}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}

export default DnDKitProperty;
