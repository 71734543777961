import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import
{
  Block,
  BlockDes,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  PreviewAltCard,
  Row,
  DataTable,
  DataTableBody,
  PreviewCard,
} from "../../../components/Component";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, Button, Modal, ModalBody, Badge, ModalHeader, Card, CardBody, Col, } from "reactstrap";
import { useForm } from "react-hook-form";
import ReactTooltip from 'react-tooltip';

import { toast } from "react-toastify";
import { format } from "date-fns";
import 'react-phone-number-input/style.css';
import { useSelector } from "react-redux";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import _ from 'lodash';
import DndFlagComponent from "../dnd/dndFlag";

const LeadsTable = ( { title, initialData = [], name, role = null, hide = false } ) =>
{

  // State Variables
  const [ leadSource, setLeadSource ] = useState( null );
  const [ users, setusers ] = useState( initialData );
  const [ user, setUser ] = useState( null );
  const [ userId, setuserId ] = useState( "" );

  const [ totpages, setTotpages ] = useState( 0 );

  const [ resPerPage, setResPerPage ] = useState( 0 );
  const [ filCount, setFilCount ] = useState( 0 );
  const [ totalCount, settotalCount ] = useState( 0 );
  const [ loading, setLoading ] = useState( false );
  const [ search, setSearch ] = useState( null );
  const [ filterSearch, setFilterSearch ] = useState( null );
  const [ filterRole, setFilterRole ] = useState( role );
  const [ filterStatus, setFilterStatus ] = useState( null );
  const [ filterLeadSource, setFilterLeadSource ] = useState( null );
  const [ isFiltering, setIsFiltering ] = useState( false );
  const [ dateValue, setDateValue ] = useState( { startDate: '', endDate: '' } );
  const [ firstName, setFirstName ] = useState( "" );
  const [ lastName, setLastName ] = useState( "" );
  const [ email, setEmail ] = useState( "" );
  const [ password, setPassword ] = useState( "" );
  const [ cpassword, setCPassword ] = useState( "" );
  const [ errors, setErrors ] = useState( {} );
  const { myerrors, register, handleSubmit } = useForm();
  const [ rangeStart, setRangeStart ] = useState();
  const [ rangeEnd, setRangeEnd ] = useState( new Date() );
  const [ value, setValue ] = useState();
  const [ errorX, setErrorX ] = useState( null );
  const [ tablesm, updateTableSm ] = useState( false );
  const [ onSearch, setonSearch ] = useState( true );
  const [ sort, setSortState ] = useState( "DESC" );
  const [ csvFileName, setCsvFileName ] = useState( '' );
  const [ fileContents, setFileContents ] = useState( [] );
  const [ fileData, setFileData ] = useState( [] );
  const [ showTable, setShowTable ] = useState( true );
  const [ uploadLog, setUploadLog ] = useState( [] );
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ array, setArray ] = useState( [] );
  const [ importLoading, setImportLoading ] = useState( false );
  const [ state, setState ] = useState( {
    csvfile: null,
  } );
  const [ tag, setTag ] = useState( 'Seller' );

  const [ totalUsersCount, setTotalUsersCount ] = useState( 0 );
  const [ createdUsersCount, setCreatedUsersCount ] = useState( 0 );
  const [ updatedUsersCount, setUpdatedUsersCount ] = useState( 0 );
  const [ skippedCount, setSkippedCount ] = useState( 0 );
  const [ notUpdatedCount, setNotUpdatedCount ] = useState( 0 );
  const [ updatedLeads, setUpdatedLeads ] = useState( [] );

  console.log(uploadLog);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const indexOfLastItemLog = currentPage * itemsPerPage;
  const indexOfFirstItemLog = indexOfLastItemLog - itemsPerPage;

  const currentItems = fileData.slice(1).slice(indexOfFirstItem, indexOfLastItem);


  // Constants
  // Validate User Token
  const { access_token } = useSelector( ( state ) => state?.user?.loggedInUser ) || "";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ access_token }`,
  };



  useEffect( () =>
  {
    setLoading( false );
    setLoading( true );
  }, [ filterSearch, filterRole, filterStatus, filterLeadSource, isFiltering, dateValue, itemsPerPage, sort ] );

  useEffect( () =>
  {
    fetchUploadLog();
  }, [] );

  useEffect( () =>
  {
    if ( !itemsPerPage )
    {
      return;
    }
    setLoading( false );
    setLoading( true );
  }, [ currentPage ] );

  useEffect( () =>
  {
    setDateValue( {
      startDate: rangeStart?.toISOString(),
      endDate: rangeEnd?.toISOString()
    }
    );
  }, [ rangeStart, rangeEnd ] );
  const handleSearch = async ( e ) =>
  {
    e.preventDefault();
    if ( search !== filterSearch )
    {
      setusers( null );
      setCurrentPage( 1 );
      if ( search )
      {
        setFilterSearch( search );
        setIsFiltering( !!search );
      } else
      {
        if ( filterRole )
        {
          setIsFiltering( !!filterRole );
        } else
        {
          setIsFiltering( false );
        }
        setFilterSearch( null );
        setSearch( null );
      }
    }
  };


  const handleStatusOption = ( status ) =>
  {
    setusers( null );
    setCurrentPage( 1 );
    if ( status )
    {

      setFilterStatus( status );
      setIsFiltering( !!status );
    } else
    {
      if ( filterSearch )
      {
        setIsFiltering( !!filterSearch );
      } else
      {
        setIsFiltering( false );
      }
      setFilterStatus( null );
    }
  };



  // Change Page


  const toggleSearch = () => setonSearch( !onSearch );

  const axios = useAxiosPrivate();

  const handleFileUpload = ( event ) =>
  {
    const file = event.target.files[ 0 ];

    if ( !file )
    {
      toast.error( "Please choose a file." );
      return;
    }

    setFileData( [] );
    setFileData( [] );
    setFileContents( [] );
    setCsvFileName( '' );
    setFilCount( 0 );

    if ( file.type !== 'text/csv' )
    {
      toast.error( "Only CSV files are accepted." );
      return;
    }

    const reader = new FileReader();
    const fileName = file.name;
    setCsvFileName( fileName );

    reader.onload = ( e ) =>
    {
      const contents = e.target.result;
      const rows = contents.split( '\n' );
      const data = rows.map( ( row ) => row.split( ',' ) );

      setFileContents( contents );
    };

    reader.readAsText( file );
  };


  const handleViewData = () =>
  {
    const rows = fileContents.split( '\n' );
    const data = rows.map( ( row ) => row.split( ',' ) );
    setFileData( data );
    setFilCount( data.slice( 1 ).length );
  };

  const handleClearData = () =>
  {
    setFileData( [] );
    setFileContents( [] );
    setCsvFileName( '' );
    setFilCount( 0 );
    document.getElementById( 'fileInput' ).value = null;
  };

  const handleDownloadTemplate = () =>
  {
    const templateUrl = '/leads_bulk_upload_sample_template.csv';
    const link = document.createElement( 'a' );
    link.href = templateUrl;
    link.download = 'template.csv';
    link.click();
    toast.success( "CSV file downloaded" );
  };

  const [ openAgent, setOpenAgent ] = useState( false );
  const toggleModalAgent = () =>
  {
    setOpenAgent( !openAgent );
  };

  const [ openUpdateUsres, setOpenUpdateUsres ] = useState( false );
  const toggleUpdateUsres = () =>
  {
    setOpenUpdateUsres( !openUpdateUsres );
  };

  const handleOnSubmit = async ( e ) =>
  {
    setSaveLoading( false );
    e.preventDefault();

    // let leadsData = result.data;

    const dataHeaders = fileData[ 0 ];


    const findIndexByItem = ( header, item ) =>
    {
      const sanitizedItems = header.map( h => h.replace( /[\n\r]/g, '' ) );
      return sanitizedItems.indexOf( item );
    };

    const nameIndex = findIndexByItem( dataHeaders, "Name" );
    const emailIndex = findIndexByItem( dataHeaders, "Email (Optional)" );
    const phoneIndex = findIndexByItem( dataHeaders, "Phone (Required)" );
    const plotNumnerIndex = findIndexByItem( dataHeaders, "Unit Number (Optional)" );
    const roomsIndex = findIndexByItem( dataHeaders, "Rooms (Optional)" );
    const typeOfProperty = findIndexByItem( dataHeaders, "Type of property (Optional)" );
    const areaIndex = findIndexByItem( dataHeaders, "Building/Area (Optional)" );
    const locationIndex = findIndexByItem( dataHeaders, "Location (Optional)" );
    const languageIndex = findIndexByItem( dataHeaders, "Language (Optional)" );
    const agentIndex = findIndexByItem( dataHeaders, "Agent (if NULL then Sales Manager)" );
    const leadSourceIndex = findIndexByItem( dataHeaders, "Lead Source (Required)" );
    const notesIndex = findIndexByItem( dataHeaders, "Notes" );


    const fileDatas = fileData.slice( 1, fileData.length );
    _.map( fileDatas, function ( obj )
    {
      if ( obj[ phoneIndex ] !== null && obj[ phoneIndex ] !== undefined )
      {
        let objResult = {
          name: obj[ nameIndex ] ? obj[ nameIndex ]?.replace( /[\n\r]/g, '' ).trim() : null,
          email: obj[ emailIndex ] || null,
          phone: obj[ phoneIndex ] ? "+" + obj[ phoneIndex ]?.replace( /[\n\r]/g, '' ) : "",
          plot_number: obj[ plotNumnerIndex ]?.replace( /[\n\r]/g, '' ) || null,
          rooms: obj[ roomsIndex ]?.replace( /[\n\r]/g, '' ) || null,
          property_type: obj[ typeOfProperty ]?.replace( /[\n\r]/g, '' ) || null,
          area: obj[ areaIndex ]?.replace( /[\n\r]/g, '' ) || null,
          location: obj[ locationIndex ]?.replace( /[\n\r]/g, '' ) || null,
          language: obj[ languageIndex ]?.replace( /[\n\r]/g, '' ) || null,
          agent: obj[ agentIndex ]?.replace( /[\n\r]/g, '' ) || null,
          lead_source: obj[ leadSourceIndex ]?.replace( /[\n\r]/g, '' ) || "",
          firstNote: obj[ notesIndex ]?.replace( /[\n\r]/g, '' ) || "",
        };
        array.push( objResult );
      }
    } );

    if ( array.length > 0 )
    {
      try
      {

        // console.log(array);
        setSaveLoading( true );
        const res = await axios
          .post( `/users/bulk`, { csv_file_name: csvFileName, tag: tag, array: array }, {
            headers,
          } )
          .then( ( res ) =>
          {
            toast.success( "Leads data Uploaded Successfully" );
            setArray( [] );
            setSaveLoading( false );
            setFileData( [] );
            setFileContents( [] );
            setCsvFileName( '' );
            setFilCount( 0 );
            document.getElementById( 'fileInput' ).value = null;
            setImportLoading( false );
            setState( {
              csvfile: null,
            } );
            // navigate(0);
            const totalUsersCount = res?.data?.totalUsersCount;
            const createdUsersCount = res?.data?.createdUsersCount;
            const updatedUsersCount = res?.data?.updatedUsersCount;
            const skippedCount = ( totalUsersCount - createdUsersCount );
            const notUpdatedCount = ( totalUsersCount - createdUsersCount ) - updatedUsersCount;
            const updatedLeads = res?.data?.users;
            setTotalUsersCount( totalUsersCount );
            setCreatedUsersCount( createdUsersCount );
            setUpdatedUsersCount( updatedUsersCount );
            setSkippedCount( skippedCount );
            setNotUpdatedCount( notUpdatedCount );
            setUpdatedLeads( updatedLeads );
            toggleUpdateUsres( totalUsersCount, createdUsersCount, updatedUsersCount, skippedCount, notUpdatedCount );
            fetchUploadLog();
          } )
          .catch( ( err ) =>
          {
            // console.log(err.response.data.message);
            toast.error( err.response.data.message );
            setArray( [] );
            setSaveLoading( false );
            setImportLoading( false );
            setState( {
              csvfile: null,
            } );
            // navigate(0); );
          } );
      } catch ( err )
      {
        setSaveLoading( false );
        toast.error( "Server Error" );
      }
    } else
    {
      toast.error( "Please import the CSV" );
    }
  };

  const url = "uploadlog";
  const fetchUploadLog = async () =>
  {
    try
    {
      let link = `/users/${ url }`;
      const res = await axios.get( link, { headers } );
      const result = res.data;
      setUploadLog( result);

    } catch ( err )
    {
     // console.log( err );
    }
  };

  const logItems = uploadLog.slice(indexOfFirstItemLog, indexOfLastItemLog);

  return (
    <React.Fragment>
      <Head title={ title }></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                { title }
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>


        <Block>
          <Card>
            <CardBody>
              <div className="row mt-3 mb-3 justify-content-end">
                <div className="col-md-6 justify-content-end">
                  <div className="d-flex">
                    <Button color="light">
                      <input type="file" id="fileInput" onChange={ handleFileUpload }></input>
                    </Button>
                    <Button outline={ fileContents.length > 0 } color="danger" className="ml-2" onClick={ handleClearData } disabled={ fileContents.length === 0 }>
                      Clear
                    </Button>
                    <Button outline={ fileContents.length > 0 } color="secondary" className="ml-2" onClick={ handleViewData } disabled={ fileContents.length === 0 }>
                      Load & Preview
                    </Button>
                  </div>
                </div>
                <div className="col-md-6 justify-content-end d-flex">
                  <Button outline color="secondary" onClick={ ( ev ) =>
                  {
                    ev.preventDefault();
                    toggleModalAgent();
                    fetchUploadLog();
                  } }>
                    <Icon name="eye" />
                    View Upload Log
                  </Button>
                  <Button outline color="secondary" onClick={ handleDownloadTemplate } className="ml-2">
                    <Icon name="download" />
                    Download CSV Template
                  </Button>
                </div>
              </div>



            </CardBody>
          </Card>
        </Block>

        <Block>
          <BlockDes className="text-soft">
            <div className="row mt-3 d-flex align-items-center">
              <div className="col-md-6">
                <p>You have total { filCount } { name + "s" }.</p>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <Button
                  color="primary"
                  size="lg"
                  onClick={ handleOnSubmit }
                  disabled={ fileData.length === 0 || saveLoading }
                >
                  { saveLoading ? 'Uploading...' : 'Upload' }
                </Button>
              </div>
            </div>
          </BlockDes>


        <DataTable className="card-stretch mt-3">
          <DataTableBody compact>
            <DataTableHead className="nk-tb-item">
              {fileData.length > 0 &&
                fileData[0].map((header, index) => (
                  <DataTableRow key={index}>
                    <span className="sub-text">{header}</span>
                  </DataTableRow>
                ))}
            </DataTableHead>

            {currentItems.map((row, rowIndex) => (
              <DataTableItem key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <DataTableRow key={cellIndex}>
                    <span href="#id" onClick={(ev) => ev.preventDefault()}>
                      {cell}
                    </span>
                  </DataTableRow>
                ))}
              </DataTableItem>
            ))}
          </DataTableBody>
          <PreviewAltCard>
          {fileData.slice(1)?.length > 0 ? (
            <PaginationComponent
              itemPerPage={itemsPerPage}
              totalItems={fileData.slice(1).length}
              paginate={paginate}
              currentPage={currentPage}
            />
          ) : (
            <div className="text-center">
              <span className="text-silent">Upload CSV file to see the data</span>
            </div>
          )}
        </PreviewAltCard>
        </DataTable>

        
 

        </Block>


        <Modal size="xl" isOpen={ openAgent } toggle={ toggleModalAgent } backdrop="static" className="w-80" >

          <ModalHeader
            close={
              <button className="close" onClick={ toggleModalAgent }>
                <Icon name="cross" />
              </button>
            }
          >
            Upload Log
          </ModalHeader>

          <ModalBody>
            <Row>
            <DataTable className="card-stretch mt-3">
          <DataTableBody compact>
            <DataTableHead className="nk-tb-item">
                  <DataTableRow>
                    <span className="sub-text">FILE NAME</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">UPLOAD DATE</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">TAG</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">TOTAL COUNT</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">NEW</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">SKIPPED</span>
                  </DataTableRow>
            </DataTableHead>

            {logItems?.map((item, idx) => {
              const skipped = (item?.total_count - item?.new_leads)-item?.updated_leads;
              const tooltipContent = `Updated Leads: ${item?.updated_leads} , Not Modified Leads: ${skipped}`;            
              return (
                  <DataTableItem>
                    <DataTableRow>
                    <span className="sub-text">
                            <Badge
                              size="sm"
                              color="outline-danger"
                              className="badge-dim me-1"
                            >
                              { item?.file_name }
                            </Badge>
                          </span>
                    </DataTableRow>
                    <DataTableRow>
                    <span className="sub-text">
                            {" "}
                            {item?.upload_date &&
                              format(new Date(item?.upload_date), "dd/MM/yyyy hh:mm a")}
                          </span>
                    </DataTableRow>
                    <DataTableRow>
                    {" "}
                          {item?.tag}
                    </DataTableRow>
                    <DataTableRow>
                    <Badge color="info">{" "}
                            {item?.total_count}</Badge>
                    </DataTableRow>
                    <DataTableRow>
                    <Badge color="success">
                            {" "}
                            {item?.new_leads}
                          </Badge>
                    </DataTableRow>
                    <DataTableRow>
                      <Badge color="danger" data-tip={tooltipContent}>
                            {" "}
                            {item?.total_count - item?.new_leads}
                          </Badge>
                      <ReactTooltip />
                    </DataTableRow>
                  </DataTableItem>
                );
              })}

          </DataTableBody>
        </DataTable>
        <PreviewAltCard>
          {uploadLog.slice(1)?.length > 0 ? (
            <PaginationComponent
              itemPerPage={itemsPerPage}
              totalItems={uploadLog.slice(1).length}
              paginate={paginate}
              currentPage={currentPage}
            />
          ) : (
            <div className="text-center">
              <span className="text-silent">No Log Found</span>
            </div>
          )}
        </PreviewAltCard>
            </Row>

          </ModalBody>
        </Modal>

        <Modal isOpen={ openUpdateUsres }
          toggle={ () => toggleUpdateUsres( 0, 0, 0, 0 ) }
          backdrop="static"
          className="modal-container"
          size="lg"

        >
          <ModalHeader
            close={
              <button className="close" onClick={ toggleUpdateUsres }>
                <Icon name="cross" />
              </button>
            }
          >
            Bulk Upload Details
          </ModalHeader>

          <ModalBody lassName="modal-body" style={ { height: "38rem", maxHeight: "38rem", overflowX: "hidden" } }>
          <Row className="gy-1 mb-1">
  <Col sm="6">
    Total Leads: <Badge color="info">{" "}
    {totalUsersCount}</Badge>
  </Col>
  <Col sm="6">
    New Leads Created: <Badge color="success">{" "}
    {createdUsersCount}</Badge>
  </Col>
  <Col sm="6">
    Skipped Leads: <Badge color="danger">{" "}
    {skippedCount}</Badge>
  </Col>
</Row>


            <Row className="gy-1 mb-4">
  
              <Col sm="6">   Not Modified Leads: <Badge color="gray">{" "}
    {notUpdatedCount}</Badge>
              </Col> <Col sm="6">
                Updated Existing Leads: <Badge color="dark">{" "}
    {updatedUsersCount}</Badge>
              </Col>
            </Row>

            { updatedLeads.length > 0 && (
              <>
                <b>Updated Leads Details</b>
                <table className="table table-ulogs mt-2">
                  <thead className="table-light">
                    <tr>
                      <th className="tb-col-time">
                        <span className="overline-title">Name</span>
                      </th>
                      <th className="tb-col-time">
                        <span className="overline-title">Phone</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    { updatedLeads.map( ( item, idx ) =>
                    {
                      return (
                        <tr key={ idx }>
                          <td className="tb-col-time">
                            { item?.first_name } { item?.last_name }
                          </td>
                          <td className="tb-col-time">
                          {item?.DndStatus?
                            <DndFlagComponent className={"d-flex flex-column align-items-center justify-content-center pe-2"} lead_status={item?.lead_status} phoneNumber={item?.phone}/>: <span className='text-center'>{item?.phone ?? ""}</span>}
                          </td>
                        </tr>
                      );
                    } ) }
                  </tbody>
                </table>
              </>
            ) }

          </ModalBody>
        </Modal>

      </Content>
    </React.Fragment>
  );
};


export default LeadsTable;