import * as types from "../types";

export const fetchAgencies = (axios) => async (dispatch) => {
    try {
        const { data } = await axios.get("/agency");

        dispatch({
            type: types.GET_AGENCIES,
            payload: data,
        });
    } catch (err) {
        console.log(err);
    }
};
