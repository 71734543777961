import React, { useEffect, useState } from "react";
import
{
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import { Card } from "reactstrap";
import { ListingType, formatValue } from "../../../utils/envConfig";

const Cheques = ( { property, setEditCheque = () => null } ) =>
{
  const [ price, setPrice ] = useState( 0 );
  useEffect( () =>
  {
  if (
    property?.listingType === ListingType[ 0 ] ||
    property?.listingType === ListingType[ 2 ]
  )
  {
    setPrice( property?.askingPrice );
  } else
  {
    setPrice( property?.oneCheqPrice );
  }
}, [ property ] );

  return (
    <>

      <div  onClick={ ( ev ) =>
      {
        ev.preventDefault();
        setEditCheque( true );
      } }>
        <ul className="data-list is-compact">
          { property?.listingType === ListingType[ 1 ] &&
            <>
              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">Rental Period</div>
                  <div className="data-value">{ property?.rentalPeriod }</div>
                </div>
              </li>
              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">Number of Cheques</div>
                  <div className="data-value">{ property?.numberOfCheques ?? "N/A" }</div>
                </div>
              </li>
              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">Security Deposit</div>
                  <div className="data-value">
                  { property?.securityDeposit } % ( { "AED" }{ " " }
                        { formatValue(
                          Math.round(
                            ( price *
                              Number( property?.securityDeposit ) ) /
                            100
                          )
                        ) }
                        )
                  </div>
                </div>
              </li>
              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">1 Cheque Price</div>
                  { property?.oneCheqPrice &&
                    "AED " + formatValue( property?.oneCheqPrice ) }
                </div>
              </li>
              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">2 Cheque Price </div>
                  <div className="data-value">
                    { property?.twoCheqPrice &&
                      "AED " + formatValue( property?.twoCheqPrice ) }
                  </div>
                </div>
              </li>
              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">4 Cheque Price</div>
                  <div className="data-value">
                    { property?.fourCheqPrice &&
                      "AED " + formatValue( property?.fourCheqPrice ) }
                  </div>
                </div>
              </li>
              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">6 Cheque Price</div>
                  <div className="data-value">
                    { property?.sixCheqPrice &&
                      "AED " + formatValue( property?.sixCheqPrice ) }
                  </div>
                </div>
              </li>
              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">12 Cheque Price</div>
                  <div className="data-value">
                    { property?.twelveCheqprice &&
                      "AED " + formatValue( property?.twelveCheqprice ) }
                  </div>
                </div>
              </li>
            </>
          }
          <li className="data-item">
            <div className="data-col">
              <div className="data-label">Financing</div>
              <div className="data-value">
                { property?.rentFinance}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Cheques;
