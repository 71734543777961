import React from "react";
import uuid from "react-uuid";

export default function InputFile({
    maxFilesLength = 1,
    minFilesLength = 1,
    inputType = "image",
    active = "false",
    dragenter = () => null,
    dragleave = () => null,
    drop = () => null,
    handleFileChange = () => null,
    removeImage = (ind) => null,
    files = [],
    errorMessage = "",
    multiple = false,
    inputProps,
}) {
    const inputId = uuid();
    return (
       <>
       {files&&
<div
    style={{
        border: '1px solid',
        borderRadius: '0.375rem',
        gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
        gridTemplateRows: 'minmax(0, 1fr)',
        gridAutoRows: 'minmax(0, 1fr)',
        padding: '1rem',
        backgroundColor:'rgb(245, 246, 250)',
        borderColor:
            active === 'true' ? 'rgb(229, 233, 242)' : active === 'bad' ? 'red' : active === 'more' ? 'rgb(229, 233, 242)' : 'rgb(229, 233, 242)',
        // boxShadow: active === 'true' ? '0 0 0 3px rgba(51, 153, 219, 0.5)' : '',
    }}
    onDragEnter={dragenter}
    onDragLeave={dragleave}
    onDrop={drop}
    onDragOver={dragenter}
>
    <input
        type="file"
        name="fileupload"
        id={inputId}
        onChange={handleFileChange}
        style={{
            outline: 'none',
            border: 'none',
            position: 'absolute',
            visibility: 'hidden',
        }}
        {...inputProps}
        multiple={multiple}
        min={minFilesLength}
        max={maxFilesLength}
    />
    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', justifyContent: 'center' }}>
        {files?.length ? (
            <></>
            // files.map(({ file }, i) => (
            //     <div className="image-list"
              
            //         key={i}
            //     >
            //         {inputType === 'image' ? (
            //                    <Card className="position-relative" style={ { width: "8rem" } } >
            //                    <CardImg src={ typeof file === 'string' ? file : URL.createObjectURL(file)} alt={ file.name } />

            //                    <Button
            //                        className="position-absolute"
            //                        style={ { top: "0", left: "0" } }
            //                        color="danger"
            //                        onClick={() => removeImage(i)}
            //                    >
            //                        <Icon name="trash"> </Icon>
            //                    </Button>

            //                </Card>
            //             // <img
            //             //     src={typeof file === 'string' ? file : URL.createObjectURL(file)}
            //             //     alt="Selected file"
            //             //     style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            //             // />
            //         ) : inputType === 'video' ? (
            //             <video
            //                 src={typeof file === 'string' ? file : URL.createObjectURL(file)}
            //                 style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            //                 controls
            //             ></video>
            //         ) : (
            //             <span
            //                 style={{
            //                     borderRadius: '0.375rem',
            //                     border: '1px solid #ccc',
            //                     padding: '0.5rem',
            //                     cursor: 'pointer',
            //                 }}
            //                 onClick={() => removeImage(i)}
            //             >
            //                 Remove
            //             </span>
            //         )}
            //         {/* {(inputType === 'image' || inputType === 'video') && (
            //             <span
            //                 style={{
            //                     padding: '0.25rem',
            //                     borderRadius: '50%',
            //                     backgroundColor: '#fff',
            //                     cursor: 'pointer',
            //                     border: '1px solid #000',
            //                     position: 'absolute',
            //                     top: '-0.75rem',
            //                     right: '-0.75rem',
            //                     zIndex: '10',
            //                 }}
            //                 onClick={() => removeImage(i)}
            //             >
            //                 <svg
            //                     xmlns="http://www.w3.org/2000/svg"
            //                     fill="none"
            //                     viewBox="0 0 24 24"
            //                     stroke="currentColor"
            //                     style={{ width: '1.5rem', height: '1.5rem' }}
            //                 >
            //                     <path
            //                         strokeLinecap="round"
            //                         strokeLinejoin="round"
            //                         strokeWidth={1.5}
            //                         d="M6 18L18 6M6 6l12 12"
            //                     />
            //                 </svg>
            //             </span>
            //         )} */}
            //     </div>
            // ))
        ) : (
            <label
                htmlFor={inputId}
                style={{
                    width: '100%',
                    height: '75px',
                    margin: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                }}
            >
                 <span style={{ textAlign: 'center',color:'#854fff' }}>   Drag and drop &nbsp;</span> <span style={{color:"rgba(82, 100, 132, 0.6)"}}>image here or&nbsp;</span> <span style={{ textAlign: 'center',color:'#854fff' }}>browse</span>
             
                {/* <img src="/img/uploadfile.png" alt="upload file" style={{ objectFit: 'cover' }} /> */}
            </label>
        )}
    </div>
  
    {errorMessage && <p style={{ textAlign: 'center' }}>{errorMessage}</p>}
    {files?.length!==0 &&  
            <label
               
             htmlFor={inputId}
             style={{
                display: "block",
                textAlign: "center",
                color: "#854fff",
                margin: "0 auto",
            }}
           >
            Upload</label>}
</div>
}
</>
    );
}


