/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import ErrorHandler from "../../error-handlers/axios/defaultErrorHandler";
import '../../global-types/deal-transactions';

/**
 * @description Fetches all commission transactions for a specific deal
 * @param {Axios} axiosInstance - the axios instance for making the HTTP request.
 * @param {string} dealId - the deal ID used to fetch the transactions.
 * @param {?import("axios").AxiosRequestConfig} options .
 * @returns {Promise<import('../../global-types/deal-transactions').DealTransactionsResponse>} API Response.
 */
export const fetchDealTransactions = async (axiosInstance, dealId, options) => {
    try {
      const res = await axiosInstance.get(`/deals/${dealId}/transactions`, options);
      return res?.data;
    } catch (error) {
      ErrorHandler.handle(error);
    }
}
