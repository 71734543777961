import React from 'react'
import { Card } from 'reactstrap';

export default function RenderChart({
    chartTitle,
    chartFilterOptions,
    chartLabelTitles,
    chart,
    chartFooter
}) {
    return (
        <Card>
            <div className="nk-ecwg nk-ecwg8 h-100">
                <div className="card-inner">
                    <div className="card-title-group mb-3">
                        <div className="card-title">
                            <h6 className="title">{chartTitle}</h6>
                        </div>
                        <div className="card-title-group">
                            {chartFilterOptions}
                        </div>
                    </div>
                    <ul className="nk-ecwg8-legends">
                        {chartLabelTitles}
                        {/* {selectedAgents?.slice(0, 3)?.map((agent, i) => (
                            <li>
                                <div className="title">
                                    <span
                                        className="dot dot-lg sq"
                                        style={{
                                            background:
                                                i === 0
                                                    ? '#9d72ff'
                                                    : i === 1
                                                    ? '#eb6459'
                                                    : 'rgb(25, 250, 133)'
                                        }}
                                    ></span>
                                    <span>{agent?.name}</span>
                                </div>
                            </li>
                        ))} */}
                    </ul>
                    <div className="nk-ecwg8-ck">{chart}</div>
                    <div className="chart-label-group ps-5">
                        <div className="chart-label">{chartFooter}</div>
                    </div>
                </div>
            </div>
        </Card>
    );
}
