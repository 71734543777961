import ErrorHandler from "../error-handlers/axios/defaultErrorHandler";

export default async function fetchAgentTeamMembers(axios, leaderId, params) {
  try {
    const page = isNaN(Number(params?.page)) ? 1 : Number(params?.page);
    const limit = isNaN(Number(params?.limit)) ? 12 : Number(params?.limit);
    delete params?.page;
    delete params?.limit;
    const res = await axios.get("/agent/filter", {
      params: {
        options: {
          sort: { createdAt: -1 },
          populate: ["user"],
          page,
          limit,
          skip: (page - 1) * limit,
        },
        filter: {
          leaderId,
        },
      },
    });

    return res?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
}
