
import { newRoles } from "../../utils/envConfig";
import BuysersTable from "../components/table/BuyersTable";

const BuyersList = () => {
    return (
        <>
            <BuysersTable
                title={"List Buyers"}
                name={"Buyer"}
                role={newRoles.Owner}
                hide={true}
            />
        </>
    );
};

export default BuyersList;
