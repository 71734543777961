import { isValid } from 'date-fns';
import * as types from '../types';
import { toast } from 'react-toastify';
import { HolidayStates } from '../../utils/envConfig';
import queryString from 'query-string';

export const fetchStaffHolidays =
    (axios, query = {}) =>
    async (dispatch) => {
        try {
            dispatch({
                type: types.GETTING_STAFF_ON_HOLIDAYS
            });

            const qString = queryString.stringify(query);

            const { data } = await axios.get(`/staff-holidays?${qString}`);

            dispatch({
                type: types.GET_STAFF_ON_HOLIDAYS,
                payload: data?.data
            });
        } catch (err) {
            console.log(err);
            dispatch({
                type: types.GETTING_STAFF_ON_HOLIDAYS_FAILED
            });
        }
    };

export const scheduleHoliday = (axios, data) => async (dispatch) => {
    try {
        if (
            !data?.agent ||
            (!data?.agent?.id && !data?.agent?._id) ||
            !data?.start ||
            !isValid(new Date(data?.start)) ||
            !data?.end ||
            !isValid(new Date(data?.end))
        ) {
            toast.error('Please fill all fields');
            return;
        }

        dispatch({
            type: types.CREATING_STAFF_HOLIDAYS,
            error: null
        });

        const { data: res } = await axios.post('/staff-holidays', {
            ...data,
            agent: data?.agent?.id ?? data?.agent?._id
        });

        dispatch({
            type: types.CREATE_STAFF_HOLIDAYS,
            payload: {
                ...res?.data,
                agent: data?.agent
            },
            error: null
        });

        toast.success('Scheduled holiday successfully!');
    } catch (err) {
        toast.error(err?.response?.data?.message ?? 'Unexpected error occured');
        // ;
        dispatch({
            type: types.CREATE_STAFF_HOLIDAYS,
            error: err
        });
    }
};

export const updatedScheduledHoliday =
    (axios, id, data) => async (dispatch) => {
        try {
            if (
                !data?.agent ||
                (!data?.agent?.id && !data?.agent?._id) ||
                !data?.start ||
                !isValid(new Date(data?.start)) ||
                !data?.end ||
                !isValid(new Date(data?.end))
            ) {
                toast.error('Please fill all fields');
                return;
            }

            dispatch({
                type: types.UPDATE_STAFF_HOLIDAYS,
                error: null,
                updating: true
            });

            const { data: res } = await axios.put(
                `/staff-holidays/${id}`,
                data
            );

            dispatch({
                type: types.UPDATE_STAFF_HOLIDAYS,
                payload: {
                    ...res?.data,
                    agent: data?.agent
                },
                error: null,
                updating: false
            });

            toast.success('Updated holiday successfully!');
        } catch (err) {
            toast.error(
                err?.response?.data?.message ?? 'Unexpected error occured'
            );
            // ;
            dispatch({
                type: types.UPDATE_STAFF_HOLIDAYS,
                error: err,
                updating: false
            });
        }
    };

export const deleteScheduledHoliday = (axios, data) => async (dispatch) => {
    try {
        if (!data || (!data?.id && !data?._id)) {
            return;
        }
        // if state is not HOLIDAY_SCHEDULED, then throw an error
        if (data?.state !== HolidayStates.SCHEDULED) {
            toast.error('Cannot delete already scheduled holiday');
            return;
        }

        dispatch({
            type: types.DELETE_STAFF_HOLIDAYS,
            error: null,
            deleting: true
        });

        const { data: res } = await axios.delete(
            `/staff-holidays/${data?.id ?? data?._id}`
        );

        toast.success('Successfully deleted schedule');

        dispatch({
            type: types.DELETE_STAFF_HOLIDAYS,
            error: null,
            deleting: false,
            payload: data
        });
    } catch (err) {
        toast.error(err?.response?.data?.message ?? 'Unexpected error occured');
        console.log(err);
        dispatch({
            type: types.DELETE_STAFF_HOLIDAYS,
            error: err,
            deleting: false
        });
    }
};
