import classNames from "classnames";
import React from "react";

/**
 * A reusable component for the title card for a table.
 *
 * @param {object} props - The component's props.
 * @param {string} [props.id] - The id attribute of the card element.
 * @param {string} [props.className] - Additional CSS classes to apply to the card element.
 * @param {React.CSSProperties} [props.style] - Inline styles to apply to the card element.
 *
 * @example
 * <CardTitleGroup className="custom-class" style={{ backgroundColor: 'red' }}>
 *   Card content goes here
 * </CardTitleGroup>
 *
 * @returns {JSX.Element} - The rendered card title group component.
 */
const CardTitleGroup = ({ className, style, id, children }) => {
  const cardClass = classNames({
    [`${className}`]: className,
    "card-title-group": true,
  });

  return <div className={cardClass} style={style ?? {}} id={id}>{children}</div>;
};

export default CardTitleGroup;
