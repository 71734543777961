import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RSelect } from './Component';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { filterUsersByRole } from '../redux/actions/user';
import { useDispatch } from 'react-redux';
import { newRoles } from '../utils/envConfig';
import { Spinner } from 'reactstrap';

export default function SearchClientSelect({
    onlyAgents,
    onlyOwners,
    onlyUsers,
    agentId,
    excludeClient,
    ...props
}) {
    const users = useSelector((state) => state?.user?.users?.findUsersOutput);

    const userRole = onlyAgents
        ? newRoles.Agent
        : onlyOwners
        ? newRoles?.Owner
        : onlyUsers
        ? newRoles.User
        : undefined;

    const dispatch = useDispatch();

    const axios = useAxiosPrivate();

    const [clientOptions, setClientOptions] = useState([]);

    const [isTyping, setIsTyping] = useState(false);

    const [text, setText] = useState('');

    const queryUsers = async (value) => {
        try {
            let url = userRole
                ? `/search/user/filter?search=${value}&roles[]=${userRole}&active=true`
                : `/search/user/filter?search=${value}&active=true`;

            if (agentId) {
                url += `&agent_id=${agentId.toString()}`;
            }

            const { data } = await axios.get(url);

            return data;
        } catch (err) {
            console.log(err);
            return {};
        }
    };

    const sendRequest = useCallback(async (value) => {
        try {
            if (!value || !value.length) {
                setClientOptions([]);
                return;
            }

            const clients = await queryUsers(value);

            setClientOptions(
                clients?.findUsersOutput
                    ?.slice(0, 5)
                    ?.filter((item) => item?.id !== excludeClient) // Filter out the excluded client
                    ?.map((item) => ({
                        ...item,
                        value: `${item?.first_name} ${item?.last_name} (${item?.phone})(${item?.email})`,
                        label: `${item?.first_name} ${item?.last_name} (${`****${item?.phone.slice(-4)}`})`
                    }))
            );
        } catch (err) {
            console.log(err);
        } finally {
            setIsTyping(false);
        }
    }, [excludeClient]);

    // memoize the debounce call with useMemo
    const debouncedSendRequest = useMemo(() => {
        return debounce(sendRequest, 1000);
    }, [sendRequest]);

    useEffect(() => {
        const params = { role: userRole };
        if (agentId && typeof agentId === 'string') {
            params.agent_id = agentId;
        }
        dispatch(filterUsersByRole(axios, params));
    }, [dispatch, agentId]);

    return (
        <>
            <RSelect
                options={clientOptions}
                onInputChange={(txt) => {
                    setIsTyping(true);
                    setClientOptions([]);
                    debouncedSendRequest(txt);
                    setText(txt);
                }}
                //   defaultMenuIsOpen={text?.length}
                {...props}
                menuPlacement="auto"
                inputValue={text}
                // openMenuOnFocus={false}
                // openMenuOnClick={false}
                isLoading={isTyping}
                menuIsOpen={text?.length}
                loadingMessage={() => <Spinner size="sm" color="light" />}
                //   noOptionsMessage={() =>
                //     isTyping ? <Spinner size="sm" color="light" /> : 'No options'
                //   }
            />
        </>
    );
}
