import React, { useState } from "react";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../Component";
import { Button, Modal } from "reactstrap";
import { PersonalInformation } from "./Modals/PersonalInformation";


const UserPersonalInfoHeader = ( user, callback = () => { }, ) =>
{
    const [ updateProfileModal, setUpdateProfileModal ] = useState( false );
    const toggleUpdateProfileModal = () =>
    {
        setUpdateProfileModal( !updateProfileModal );
    };
    return (
        <div>
            <Block>
                <BlockHead size="lg">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h4">Personal Information</BlockTitle>
                            <BlockDes>
                                <p>Basic info, like user name, address, phone ...</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <Button
                            className="toggle d-none d-md-inline-flex"
                            color="primary"
                            onClick={ ( ev ) =>
                            {
                                ev.preventDefault();
                                toggleUpdateProfileModal();
                            } }
                        >
                            <Icon name="edit"></Icon>
                            <span>Edit Profile</span>
                        </Button>

                    </BlockBetween>
                </BlockHead>
            </Block>
            <Modal
                size="lg"
                isOpen={ updateProfileModal }
                toggle={ toggleUpdateProfileModal }
            >
                <PersonalInformation
                    toggle={ toggleUpdateProfileModal }
                    user={ user?.user?.user }
                    callback={ () =>
                    {
                    } }
                />
            </Modal>

        </div>
    );
};

export default UserPersonalInfoHeader;
