import React, { useState } from "react";
import { DndContext, closestCenter, useSensor, MouseSensor, TouchSensor, useSensors,PointerSensor } from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Input } from "reactstrap";
import { Icon } from "../../../components/Component";


function Image({ image,removeImage ,index,handleAmenitiesLabelChange,desc=true}) {

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: image.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #ddd",
    borderRadius: "4px",
    width: "100%",
    height: "auto",
    position: "relative",
  };


  return (
    <div 
    
    ref={setNodeRef} style={style} {...attributes} {...listeners}>

     <img            
              src={
                  !image?.file
                      ? null
                      : typeof image?.file === "string"
                      ? image?.file
                      : URL.createObjectURL(image?.file)
              }
              style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderTopLeftRadius: "0.5rem",
              }}        
     alt="Draggable" 
    //  style={{ width: "100%", height: "auto" }} 
     />
             <Input
            type="text"
            name="title"
            defaultValue={desc?image?.title:image?.label}
            onChange={desc?handleAmenitiesLabelChange("title", index):handleAmenitiesLabelChange("label", index)}
            placeholder={desc?"Amenity Title *":"Label *"} 
            className=" border border-light-alt"
        />
          {desc&&   
             <Input
            type="text"
            defaultValue={image?.description}
            name="description"
            onChange={handleAmenitiesLabelChange("description", index)}
            placeholder="Describe Amenity" 
            className=" border border-light-alt"
        />}
     <button
        type="button"
        onClick={ ()=>removeImage(index)}
        style={ {
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "999",
            padding: "0.5rem",
        } }
        className="bg-danger"
    >
        <Icon
            name="trash"
            className="text-white"
            style={ { cursor: "pointer" } }
        />

    </button>
    {/* <div className="position-absolute bg-white w-100" style={ { bottom: "0", } }>
        <Input key={`am+${image.id}`} placeholder="Amenity Title *" className="mt-2 border border-light-alt"></Input>
        <Input key={`des+${image.id}`} placeholder="Describe Amenity" className="mt-1 border border-light-alt"></Input>
    </div> */}
    </div>
  );
}

function DnDKitDemo({images,setImages,removeImage,desc=true}) {
 
  const handleAmenitiesLabelChange = (name, index) => (e) => {
 
    // console.log(e.target.value )
    let newArr = images?.map((item, i) => {
        if (index === i) {
            return { ...item, [name]: e.target.value };
        } else {
            return item;
        }
    });

    setImages(newArr);
};
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  )
  // const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  


  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    const oldIndex = images.findIndex((image) => image.id === active.id);
    const newIndex = images.findIndex((image) => image.id === over.id);

    if (oldIndex === newIndex) return;

    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      const [movedImage] = updatedImages.splice(oldIndex, 1);
      updatedImages.splice(newIndex, 0, movedImage);
      return updatedImages;
    });
  };


  const gridContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={images} strategy={rectSortingStrategy}>
        <div style={gridContainerStyle}>
          {images.map((image,index) => (
            <Image key={image.id} index={index} image={image} removeImage={removeImage} handleAmenitiesLabelChange={handleAmenitiesLabelChange} desc={desc}/>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}

export default DnDKitDemo;
