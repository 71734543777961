import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useForm } from "react-hook-form";
import { Button, Col, Label, Row, Spinner } from "reactstrap";
import { Icon } from "../../../components/Component";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchDeal } from "../../../redux/actions/deal";
import {
  AgencyType,
  DealCategory,
  DealClientType,
  DealPurpose,
  DealType,
  DocumentTypes,
  deleteFromS3,
  getClientDocuments,
  getDealDocuments,
  getImage,
  newRoles,
  updateDocument,
  uploadManyToS3,
  uploadToS3,
} from "../../../utils/envConfig";
import uuid from "react-uuid";
import { async } from "q";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const EditDealStepTwo = (props) => {
  const { id } = useParams();
  // State Variables
  const axios = useAxiosPrivate();

  const navigate = useNavigate();

  const deal = useSelector((state) => state?.deal?.deal);
  const loggedInUser = useSelector((state) => state?.user?.loggedInUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchDeal(axios, id));
    }
  }, [id]);

  const [files, setFiles] = useState([]);

  const [loading, setLoading] = useState(false);

  const [selectedEmiratesIdBuyer, setSelectedEmiratesIdBuyer] = useState(null);

  const [selectedEmiratesIdSeller, setSelectedEmiratesIdSeller] =
    useState(null);
  const [selectedEmiratesIdClient, setSelectedEmiratesIdClient] =
    useState(null);

  const [selectedPassportBuyer, setSelectedPassportBuyer] = useState(null);
  const [selectedPassportSeller, setSelectedPassportSeller] = useState(null);
  const [selectedPassportClient, setSelectedPassportClient] = useState(null);
  const [selectedOtherDocuments, setSelectedOtherDocuments] = useState(null);

  const [selectedReservation, setSelectedReservation] = useState(null);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [selectedTitleDeed, setSelectedTitleDeed] = useState(null);
  const [selectedEjari, setSelectedEjari] = useState(null);
  const [selectedTradeLicenseBuyer, setSelectedTradeLicenseBuyer] =
    useState(null);
  const [selectedTradeLicenseSeller, setSelectedTradeLicenseSeller] =
    useState(null);
  const [selectedReraImageBuyer, setSelectedReraImageBuyer] = useState(null);
  const [selectedReraImageSeller, setSelectedReraImageSeller] = useState(null);

  const [eidBuyer, setEidBuyer] = useState(null);
  const [eidSeller, setEidSeller] = useState(null);
  const [eidClient, setEidClient] = useState(null);
  const [titleDeed, setTitleDeed] = useState(null);
  const [passportBuyer, setPassportBuyer] = useState(null);
  const [passportSeller, setPassportSeller] = useState(null);
  const [passportClient, setPassportClient] = useState(null);
  const [reservation, setReservation] = useState(null);
  const [receipt, setReceipt] = useState(null);
  const [ejari, setEjari] = useState(null);
  const [tradeLicenseBuyer, setTradeLicenseBuyer] = useState(null);
  const [tradeLicenseSeller, setTradeLicenseSeller] = useState(null);
  const [reraImageBuyer, setReraImageBuyer] = useState(null);
  const [reraImageSeller, setReraImageSeller] = useState(null);
  const [otherDocuments, setOtherDocuments] = useState(null);

  const handleDropEmiratesIdBuyer = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file?.type?.includes("image/")) {
      return alert("Please select image only");
    }
    setSelectedEmiratesIdBuyer(URL.createObjectURL(file));
    setEidBuyer(file);
  };

  const handleDropEmiratesIdSeller = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file?.type?.includes("image/")) {
      return alert("Please select image only");
    }
    setSelectedEmiratesIdSeller(URL.createObjectURL(file));
    setEidSeller(file);
  };

  const handleDropEmiratesIdClient = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file?.type?.includes("image/")) {
      return alert("Please select image only");
    }
    setSelectedEmiratesIdClient(URL.createObjectURL(file));
    setEidClient(file);
  };

  const handleDropTradeLicenseBuyer = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file?.type?.includes("image/")) {
      return alert("Please select image only");
    }
    setSelectedTradeLicenseBuyer(URL.createObjectURL(file));
    setTradeLicenseBuyer(file);
  };

  const handleDropTradeLicenseSeller = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file?.type?.includes("image/")) {
      return alert("Please select image only");
    }
    setSelectedTradeLicenseSeller(URL.createObjectURL(file));
    setTradeLicenseSeller(file);
  };

  const handleDropReraImageBuyer = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file?.type?.includes("image/")) {
      return alert("Please select image only");
    }
    setSelectedReraImageBuyer(URL.createObjectURL(file));
    setReraImageBuyer(file);
  };

  const handleDropReraImageSeller = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file?.type?.includes("image/")) {
      return alert("Please select image only");
    }
    setSelectedReraImageSeller(URL.createObjectURL(file));
    setReraImageSeller(file);
  };

  const handleDropPassportBuyer = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file?.type?.includes("image/")) {
      return alert("Please select image only");
    }
    setSelectedPassportBuyer(URL.createObjectURL(file));
    setPassportBuyer(file);
  };

  const handleDropPassportSeller = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file?.type?.includes("image/")) {
      return alert("Please select image only");
    }
    setSelectedPassportSeller(URL.createObjectURL(file));
    setPassportSeller(file);
  };

  const handleDropPassportClient = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file?.type?.includes("image/")) {
      return alert("Please select image only");
    }
    setSelectedPassportClient(URL.createObjectURL(file));
    setPassportClient(file);
  };

  const handleDropOtherDocuments = (acceptedFiles) => {
    const files = [...acceptedFiles].filter((file) =>
      file?.type?.includes("image/")
    );

    setSelectedOtherDocuments(files?.map((file) => URL.createObjectURL(file)));

    setOtherDocuments(files);
  };

  const handleDropReservation = (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file?.type?.includes("image/")) {
      return alert("Please select image only");
    }

    setSelectedReservation(URL.createObjectURL(file));
    setReservation(file);
  };

  const handleDropReceipt = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file?.type?.includes("image/")) {
      return alert("Please select image only");
    }
    setSelectedReceipt(URL.createObjectURL(file));
    setReceipt(file);
  };

  const handleDropTitleDeed = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file?.type?.includes("image/")) {
      return alert("Please select image only");
    }
    setSelectedTitleDeed(URL.createObjectURL(file));
    setTitleDeed(file);
  };

  const handleDropEjari = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file?.type?.includes("image/")) {
      return alert("Please select image only");
    }
    setSelectedEjari(URL.createObjectURL(file));
    setEjari(file);
  };

  // useEffect(() => {
  //     const newDeal = localStorage.getItem("new_deal");

  //     if (newDeal) {
  //         const deal = JSON.parse(newDeal);

  //         dispatch(fetchDeal(axios, deal?.id ?? deal?._id));
  //     }
  // }, []);

  const handleDeleteBuyerEmiratesId = () => {
    setSelectedEmiratesIdBuyer(null);
  };
  const handleDeleteBuyerReraImage = () => {
    setSelectedReraImageBuyer(null);
  };
  const handleDeleteSellerReraImage = () => {
    setSelectedReraImageSeller(null);
  };
  const handleDeleteClientEmiratesId = () => {
    setSelectedEmiratesIdClient(null);
  };
  const handleDeleteBuyerTradeLicense = () => {
    setSelectedTradeLicenseBuyer(null);
  };
  const handleDeleteSellerTradeLicense = () => {
    setSelectedTradeLicenseSeller(null);
  };
  const handleDeleteSellerEmiratesId = () => {
    setSelectedEmiratesIdSeller(null);
  };
  const handleDeleteBuyerPassport = () => {
    setSelectedPassportBuyer(null);
  };
  const handleDeleteSellerPassport = () => {
    setSelectedPassportSeller(null);
  };
  const handleDeleteClientPassport = () => {
    setSelectedPassportClient(null);
  };
  const handleDeleteReservation = () => {
    setSelectedReservation(null);
  };
  const handleDeleteReceipt = () => {
    setSelectedReceipt(null);
  };
  const handleDeleteTitleDeed = () => {
    setSelectedTitleDeed(null);
  };
  const handleDeleteEjari = () => {
    setSelectedEjari(null);
  };

  const fetchAgencySellerImages = async () => {
    try {
      if (!deal?.sellerExternalUser?.document) {
        return;
      }

      const image = await getImage(deal?.sellerExternalUser?.document, axios);

      deal?.sellerExternalUser?.type === AgencyType.RealEstateAgency
        ? setSelectedTradeLicenseSeller(image?.signedRequest)
        : deal?.sellerExternalUser?.type === AgencyType.Freelancer
        ? setSelectedReraImageSeller(image?.signedRequest)
        : deal?.sellerExternalUser?.type === AgencyType.Referral &&
          setSelectedEmiratesIdSeller(image?.signedRequest);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAgencyBuyerImages = async () => {
    try {
      if (!deal?.buyerExternalUser?.document) {
        return;
      }

      const image = await getImage(deal?.buyerExternalUser?.document, axios);

      deal?.buyerExternalUser?.type === AgencyType.RealEstateAgency
        ? setSelectedTradeLicenseBuyer(image?.signedRequest)
        : deal?.buyerExternalUser?.type === AgencyType.Freelancer
        ? setSelectedReraImageBuyer(image?.signedRequest)
        : deal?.buyerExternalUser?.type === AgencyType.Referral &&
          setSelectedEmiratesIdBuyer(image?.signedRequest);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchBuyerImages = async () => {
    try {
      if (!deal?.buyerInternalUserId) {
        return;
      }
      const { data: clientDocs } = await axios.get(
        `/documents/user/${deal?.buyerInternalUserId}`
      );

      if (!clientDocs?.length) {
        return;
      }

      await Promise.all(
        clientDocs?.map(async (doc) => {
          const image = await getImage(doc?.path, axios);

          doc?.type === DocumentTypes.EID
            ? setSelectedEmiratesIdBuyer(image?.signedRequest)
            : doc?.type === DocumentTypes.Passport &&
              setSelectedPassportBuyer(image?.signedRequest);
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSellerImages = async () => {
    try {
      if (!deal?.sellerInternalUserId) {
        return;
      }
      const { data: clientDocs } = await axios.get(
        `/documents/user/${deal?.sellerInternalUserId}`
      );

      if (!clientDocs?.length) {
        return;
      }

      await Promise.all(
        clientDocs?.map(async (doc) => {
          const image = await getImage(doc?.path, axios);

          doc?.type === DocumentTypes.EID
            ? setSelectedEmiratesIdSeller(image?.signedRequest)
            : doc?.type === DocumentTypes.Passport &&
              setSelectedPassportSeller(image?.signedRequest);
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getClientDocs = async () => {
    const userId = deal?.user_id;

    try {
      if (!userId) {
        return;
      }

      const { data: documents } = await axios.get(`/documents/user/${userId}`);

      if (!documents?.length) {
        return;
      }

      const docs = await Promise.all(
        documents?.map(async (doc) => {
          const image = await getImage(doc?.path, axios);

          doc?.type === DocumentTypes.EID
            ? setSelectedEmiratesIdClient(image?.signedRequest)
            : doc?.type === DocumentTypes.Passport &&
              setSelectedPassportClient(image?.signedRequest);
          return image;
        })
      );
    } catch (err) {
      console.log(err);
    }
  };
  const fetchDealDocuments = async () => {
    try {
      const docs = await getDealDocuments(axios, deal?.id);

      docs?.length &&
        (await Promise.all(
          docs?.map(async (doc) => {
            if (doc?.type === DocumentTypes.Receipt) {
              const receipt = await getImage(doc?.path);

              setSelectedReceipt(receipt?.signedRequest);
            }

            if (doc?.type === DocumentTypes.Reservation) {
              const reservation = await getImage(doc?.path);

              setSelectedReservation(reservation?.signedRequest);
            }
            if (doc?.type === DocumentTypes.TitleDeed) {
              const titleDeed = await getImage(doc?.path);

              setSelectedTitleDeed(titleDeed?.signedRequest);
            }
            if (doc?.type === DocumentTypes.Ejari) {
              const ejari = await getImage(doc?.path);

              setSelectedEjari(ejari?.signedRequest);
            }
            if (doc?.type === DocumentTypes.Other && doc?.documents?.length) {
              const otherDocuments = await Promise.all(
                doc?.documents?.map(async (doc) => {
                  const image = await getImage(doc?.value);

                  return image?.signedRequest;
                })
              );

              setSelectedOtherDocuments(otherDocuments);

              setSelectedEjari(ejari?.signedRequest);
            }
          })
        ));

      // setDealDocs(docs);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    deal?.sellerExternalUser?.document && fetchAgencySellerImages();
    deal?.buyerExternalUser?.document && fetchAgencyBuyerImages();
    deal?.buyerInternalUserId && fetchBuyerImages();
    deal?.sellerInternalUserId && fetchSellerImages();
    deal?.user_id && getClientDocs();
    deal?.id && fetchDealDocuments();

    return () => {};
  }, [deal]);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (eidBuyer) {
        // if client is our client (DEALCLIENTTYPE.ALBA), we want to update documents db (if document existed) or create a new document
        // if client is external (DEALCLIENTTYPE.EXTERNAL), we want to update agency docment path.
        // irregardless of the type, we want to delate any previouse document that existed before in S3.
        const filePath = `/user/eid-${uuid()}.${eidBuyer?.name
          ?.split(".")
          ?.pop()}`;

        // irregardless, we want to save the new documnent to S3

        await uploadToS3(
          eidBuyer,
          filePath,
          eidBuyer?.type ?? "image/*",
          axios
        );

        if (
          deal.buyerInternalUserId &&
          deal?.buyerClientType === DealClientType.Alba
        ) {
          const clientDocs = await getClientDocuments(
            axios,
            deal.buyerInternalUserId
          );

          const eid = clientDocs?.find(
            (item) => item?.type === DocumentTypes.EID
          );
          // just update eid if it exists else create a new eid for this user
          if (eid) {
            await updateDocument(axios, eid?.id ?? eid?._id, {
              path: filePath,
            });

            await deleteFromS3(eid?.path, axios);
          } else {
            const docParams = {
              user_id: deal?.buyerInternalUserId,
              path: filePath,
              // deal_id: deal?.id,
              type: DocumentTypes.EID,
            };
            const { data: doc } = await axios.post("/documents", docParams);
          }
        } else if (
          deal.buyerExternalUserId &&
          deal?.buyerClientType === DealClientType.External
        ) {
          // here we want to update an agency since client type is external
          const prevDoc = deal?.buyerExternalUser?.document;

          const { data: res } = await axios.patch(
            `/agency/${deal?.buyerExternalUserId}`,
            { document: filePath }
          );

          await deleteFromS3(prevDoc, axios);
        }
      }

      if (eidSeller) {
        // same conditions of eidBuyer apply here
        const filePath = `/user/eid-${uuid()}.${eidSeller?.name
          ?.split(".")
          ?.pop()}`;

        await uploadToS3(
          eidSeller,
          filePath,
          eidSeller?.type ?? "image/*",
          axios
        );

        if (
          deal.sellerInternalUserId &&
          deal?.sellerclientType === DealClientType.Alba
        ) {
          const clientDocs = await getClientDocuments(
            axios,
            deal.sellerInternalUserId
          );

          const eid = clientDocs?.find(
            (item) => item?.type === DocumentTypes.EID
          );
          // just update eid if it exists else create a new eid for this user
          if (eid) {
            await updateDocument(axios, eid?.id ?? eid?._id, {
              path: filePath,
            });

            await deleteFromS3(eid?.path, axios);
          } else {
            const docParams = {
              user_id: deal?.sellerInternalUserId,
              path: filePath,
              deal_id: deal?.id,
              type: DocumentTypes.EID,
            };
            const { data: doc } = await axios.post("/documents", docParams);
          }
        } else if (
          deal.sellerExternalUserId &&
          deal?.sellerclientType === DealClientType.External
        ) {
          // here we want to update an agency since client type is external
          const prevDoc = deal?.sellerExternalUser?.document;

          const { data: res } = await axios.patch(
            `/agency/${deal?.sellerExternalUserId}`,
            { document: filePath }
          );

          await deleteFromS3(prevDoc, axios);
        }
      }

      if (passportBuyer) {
        const filePath = `/deals/buyer-passport-${uuid()}.${passportBuyer?.name
          ?.split(".")
          ?.pop()}`;

        await uploadToS3(
          passportBuyer,
          filePath,
          passportBuyer?.type ?? "image/*",
          axios
        );

        const clientDocs = await getClientDocuments(
          axios,
          deal.buyerInternalUserId
        );

        const passport = clientDocs?.find(
          (item) => item?.type === DocumentTypes.Passport
        );
        // just update passport if it exists else create a new passport for this user
        if (passport) {
          await updateDocument(axios, passport?.id ?? passport?._id, {
            path: filePath,
          });

          await deleteFromS3(passport?.path, axios);
        } else {
          const docParams = {
            user_id: deal?.buyerInternalUserId,
            path: filePath,
            deal_id: deal?.id,
            type: DocumentTypes.Passport,
          };
          const { data: doc } = await axios.post("/documents", docParams);
        }
      }

      if (passportSeller) {
        const filePath = `/deals/seller-passport-${uuid()}.${passportSeller?.name
          ?.split(".")
          ?.pop()}`;

        await uploadToS3(
          passportSeller,
          filePath,
          passportSeller?.type ?? "image/*",
          axios
        );

        const clientDocs = await getClientDocuments(
          axios,
          deal.sellerInternalUserId
        );

        const passport = clientDocs?.find(
          (item) => item?.type === DocumentTypes.Passport
        );
        // just update passport if it exists else create a new passport for this user
        if (passport) {
          await updateDocument(axios, passport?.id ?? passport?._id, {
            path: filePath,
          });

          await deleteFromS3(passport?.path, axios);
        } else {
          const docParams = {
            user_id: deal?.sellerInternalUserId,
            path: filePath,
            deal_id: deal?.id,
            type: DocumentTypes.EID,
          };
          const { data: doc } = await axios.post("/documents", docParams);
        }
      }

      if (eidClient) {
        const filePath = `/user/eid-${uuid()}.${eidClient?.name
          ?.split(".")
          ?.pop()}`;

        await uploadToS3(
          eidClient,
          filePath,
          eidClient?.type ?? "image/*",
          axios
        );

        const clientDocs = await getClientDocuments(axios, deal?.user_id);

        const eid = clientDocs?.find((doc) => doc?.type === DocumentTypes.EID);

        if (eid) {
          await updateDocument(axios, eid?.id ?? eid?._id, {
            path: filePath,
          });

          await deleteFromS3(eid?.path, axios);
        } else {
          const docParams = {
            user_id: deal?.user_id,
            path: filePath,
            // deal_id: deal?.id,
            type: DocumentTypes.EID,
          };

          const { data } = await axios.post("/documents", docParams);
        }
      }

      if (passportClient) {
        const filePath = `/user/passport-${uuid()}.${passportClient?.name
          ?.split(".")
          ?.pop()}`;

        await uploadToS3(
          passportClient,
          filePath,
          passportClient?.type ?? "image/*",
          axios
        );

        const clientDocs = await getClientDocuments(axios, deal?.user_id);

        const passport = clientDocs?.find(
          (doc) => doc?.type === DocumentTypes.Passport
        );

        if (passport) {
          await updateDocument(axios, passport?.id ?? passport?._id, {
            path: filePath,
          });

          await deleteFromS3(passport?.path, axios);
        } else {
          const docParams = {
            user_id: deal?.user_id,
            path: filePath,
            deal_id: deal?.id,
            type: DocumentTypes.Passport,
          };

          const { data } = await axios.post("/documents", docParams);
        }
      }

      if (tradeLicenseBuyer) {
        // this means we have an external buyer, thus, we want to update agency data by changin the path to de doc.
        // NB: We need to delete the previous doc from s3 as well.
        const filePath = `/deals/buyer-eid-${uuid()}.${tradeLicenseBuyer?.name
          ?.split(".")
          ?.pop()}`;

        const prevDoc = deal?.buyerExternalUser?.document;

        await uploadToS3(
          tradeLicenseBuyer,
          filePath,
          tradeLicenseBuyer?.type ?? "image/*",
          axios
        );

        // update agency data

        const { data } = await axios.patch(
          `/agency/${deal?.buyerExternalUserId}`,
          { document: filePath }
        );

        await deleteFromS3(prevDoc, axios);
      }

      if (tradeLicenseSeller) {
        const filePath = `/agency/trade-license-${uuid()}.${tradeLicenseSeller?.name
          ?.split(".")
          ?.pop()}`;

        const prevDoc = deal?.sellerExternalUser?.document;

        await uploadToS3(
          tradeLicenseSeller,
          filePath,
          tradeLicenseSeller?.type ?? "image/*",
          axios
        );

        // update agency data

        const { data } = await axios.patch(
          `/agency/${deal?.sellerExternalUserId}`,
          { document: filePath }
        );

        await deleteFromS3(prevDoc, axios);

        // upload doc to s3
      }

      if (reraImageBuyer) {
        const filePath = `/agencies/rera-${uuid()}.${reraImageBuyer?.name
          ?.split(".")
          ?.pop()}`;

        const prevDoc = deal?.buyerExternalUser?.document;

        await uploadToS3(
          reraImageBuyer,
          filePath,
          reraImageBuyer?.type ?? "image/*",
          axios
        );

        // update agency data

        const { data } = await axios.patch(
          `/agency/${deal?.buyerExternalUserId}`,
          { document: filePath }
        );

        await deleteFromS3(prevDoc, axios);
      }

      if (reraImageSeller) {
        // this means we have an external buyer, thus, we want to update agency data by changin the path to de doc.
        // NB: We need to delete the previous doc from s3 as well.
        const filePath = `/agencies/rera-${uuid()}.${reraImageSeller?.name
          ?.split(".")
          ?.pop()}`;

        const prevDoc = deal?.sellerExternalUser?.document;

        await uploadToS3(
          reraImageSeller,
          filePath,
          reraImageSeller?.type ?? "image/*",
          axios
        );

        // update agency data

        const { data } = await axios.patch(
          `/agency/${deal?.sellerExternalUserId}`,
          { document: filePath }
        );

        await deleteFromS3(prevDoc, axios);
      }

      if (
        !titleDeed &&
        !reservation &&
        !receipt &&
        !ejari &&
        !selectedEjari &&
        !selectedReceipt &&
        !selectedReservation &&
        !selectedTitleDeed
      ) {
        toast.error("Please upload deal documents");
        return;
      }

      if (titleDeed) {
        const filePath = `/deals/title-deed-${uuid()}.${titleDeed?.name
          ?.split(".")
          ?.pop()}`;

        const docParams = {
          user_id: deal?.buyerInternalUserId,
          path: filePath,
          deal_id: deal?.id,
          type: DocumentTypes.TitleDeed,
        };

        await uploadToS3(
          titleDeed,
          filePath,
          titleDeed?.type ?? "image/*",
          axios
        );
        const { data } = await axios.post("/documents", docParams);

        // upload doc to s3
      }

      if (reservation) {
        const filePath = `/deals/reservation-${uuid()}.${reservation?.name
          ?.split(".")
          ?.pop()}`;

        const docParams = {
          user_id: deal?.buyerInternalUserId,
          path: filePath,
          deal_id: deal?.id,
          type: DocumentTypes.Reservation,
        };

        await uploadToS3(
          reservation,
          filePath,
          reservation?.type ?? "image/*",
          axios
        );
        const { data } = await axios.post("/documents", docParams);

        // upload doc to s3
      }
      if (receipt) {
        const filePath = `/deals/receipt-${uuid()}.${receipt?.name
          ?.split(".")
          ?.pop()}`;

        const docParams = {
          user_id: deal?.buyerInternalUserId,
          path: filePath,
          deal_id: deal?.id,
          type: DocumentTypes.Receipt,
        };

        await uploadToS3(receipt, filePath, receipt?.type ?? "image/*", axios);
        const { data } = await axios.post("/documents", docParams);

        // upload doc to s3
      }
      if (ejari) {
        const filePath = `/deals/ejari-${uuid()}.${ejari?.name
          ?.split(".")
          ?.pop()}`;

        const docParams = {
          user_id: deal?.buyerInternalUserId,
          path: filePath,
          deal_id: deal?.id,
          type: DocumentTypes.Ejari,
        };

        await uploadToS3(ejari, filePath, ejari?.type ?? "image/*", axios);
        const { data } = await axios.post("/documents", docParams);

        // upload doc to s3
      }

      if (otherDocuments && otherDocuments?.length) {
        const files = otherDocuments?.map((doc) => ({
          file: doc,
          fileName: `/deals/other-${uuid()}.${doc?.name?.split(".")?.pop()}`,
          type: doc?.type ?? "image/*",
        }));

        const docParams = {
          user_id: deal?.buyerInternalUserId ?? deal?.sellerInternalUserId,
          path: "null",
          deal_id: deal?.id,
          type: DocumentTypes.Other,
          documents: files?.map((file) => ({
            name: DocumentTypes.Other,
            value: file?.fileName,
          })),
        };

        await uploadManyToS3(files, axios);

        const { data } = await axios.post("/documents", docParams);
      }

      const { data } = await axios.post(`/deals/progress/${deal.id}`, {});

      if (loggedInUser?.role === newRoles.Agent) {
        toast.success("Documents uploaded. \n Navigating to property details.");
        setTimeout(() => {
          navigate(
            deal?.category === DealCategory.ListingAcquired
              ? `inventory-details/${deal.id}/view-inventory`
              : `deal-details/${deal.id}`
          );
        }, 2000);
      } else {
        props.next();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const RenderDealDocuments = () => {
    const dealCategory = deal?.category;

    const dealPurpose = deal?.purpose;

    return dealCategory === DealCategory.SecondaryProperty ? (
      <div>
        {dealPurpose === DealPurpose.Rent ? (
          <Row className="g-gs">
            <Col sm="6">
              <div className="nk-upload-form">
                <Label>
                  <h6>Receipt</h6>
                </Label>
                <Dropzone
                  onDrop={(acceptedFiles) => handleDropReceipt(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps()}
                        className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message">
                          <span className="dz-message-text">
                            <span>Drag and drop</span> image file here or{" "}
                            <span>browse</span>
                          </span>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              {selectedReceipt && (
                <div
                  style={{
                    border: "2px dashed #000;",
                    height: "15rem",
                  }}
                  className="position-relative"
                >
                  <img
                    src={selectedReceipt}
                    alt="Selected"
                    className="position-absolute h-100"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                  <Button
                    type="button"
                    onClick={handleDeleteReceipt}
                    className="position-absolute top-0 left-0 bg-danger"
                  >
                    <Icon name="trash"></Icon>
                  </Button>
                </div>
              )}
            </Col>
            <Col sm="6">
              <div className="nk-upload-form">
                <Label>
                  <h6>Reservation</h6>
                </Label>
                <Dropzone
                  onDrop={(acceptedFiles) =>
                    handleDropReservation(acceptedFiles)
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps()}
                        className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message">
                          <span className="dz-message-text">
                            <span>Drag and drop</span> image file here or{" "}
                            <span>browse</span>
                          </span>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              {selectedReservation && (
                <div
                  style={{
                    border: "2px dashed #000;",
                    height: "15rem",
                  }}
                  className="position-relative"
                >
                  <img
                    src={selectedReservation}
                    alt="Selected"
                    className="position-absolute h-100"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                  <Button
                    type="button"
                    onClick={handleDeleteReservation}
                    className="position-absolute top-0 left-0 bg-danger"
                  >
                    <Icon name="trash"></Icon>
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        ) : dealPurpose === DealPurpose.Sale ? (
          <Row className="g-gs">
            <Col sm="6">
              <div className="nk-upload-form">
                <Label>
                  <h6>Title Deed</h6>
                </Label>
                <Dropzone
                  onDrop={(acceptedFiles) => handleDropTitleDeed(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps()}
                        className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message">
                          <span className="dz-message-text">
                            <span>Drag and drop</span> file here or{" "}
                            <span>browse</span>
                          </span>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              {selectedTitleDeed && (
                <div
                  style={{
                    border: "2px dashed #000;",
                    height: "15rem",
                  }}
                  className="position-relative"
                >
                  <img
                    src={selectedTitleDeed}
                    alt="Selected"
                    className="position-absolute h-100"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                  <Button
                    type="button"
                    onClick={handleDeleteTitleDeed}
                    className="position-absolute top-0 left-0 bg-danger"
                  >
                    <Icon name="trash"></Icon>
                  </Button>
                </div>
              )}
            </Col>
            <Col sm="6">
              <div className="nk-upload-form">
                <Label>
                  <h6>Ejari</h6>
                </Label>
                <Dropzone
                  onDrop={(acceptedFiles) => handleDropEjari(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps()}
                        className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message">
                          <span className="dz-message-text">
                            <span>Drag and drop</span> file here or{" "}
                            <span>browse</span>
                          </span>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              {selectedEjari && (
                <div
                  style={{
                    border: "2px dashed #000;",
                    height: "15rem",
                  }}
                  className="position-relative"
                >
                  <img
                    src={selectedEjari}
                    alt="Selected"
                    className="position-absolute h-100"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                  <Button
                    type="button"
                    onClick={handleDeleteEjari}
                    className="position-absolute top-0 left-0 bg-danger"
                  >
                    <Icon name="trash"></Icon>
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        ) : null}
      </div>
    ) : (
      (dealCategory === DealCategory.PrimaryOffplan ||
        dealCategory === DealCategory.ListingAcquired) && (
        <Row className="g-gs">
          <Col sm="6">
            <div className="nk-upload-form">
              <Label>
                <h6>Title Deed</h6>
              </Label>
              <Dropzone
                onDrop={(acceptedFiles) => handleDropTitleDeed(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                    >
                      <input {...getInputProps()} />
                      <div className="dz-message">
                        <span className="dz-message-text">
                          <span>Drag and drop</span> file here or{" "}
                          <span>browse</span>
                        </span>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {selectedTitleDeed && (
              <div
                style={{
                  border: "2px dashed #000;",
                  height: "15rem",
                }}
                className="position-relative"
              >
                <img
                  src={selectedTitleDeed}
                  alt="Selected"
                  className="position-absolute h-100"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
                <Button
                  type="button"
                  onClick={handleDeleteTitleDeed}
                  className="position-absolute top-0 left-0 bg-danger"
                >
                  <Icon name="trash"></Icon>
                </Button>
              </div>
            )}
          </Col>
          <Col sm="6">
            <div className="nk-upload-form">
              <Label>
                <h6>Ejari</h6>
              </Label>
              <Dropzone
                onDrop={(acceptedFiles) => handleDropEjari(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                    >
                      <input {...getInputProps()} />
                      <div className="dz-message">
                        <span className="dz-message-text">
                          <span>Drag and drop</span> file here or{" "}
                          <span>browse</span>
                        </span>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {selectedEjari && (
              <div
                style={{
                  border: "2px dashed #000;",
                  height: "15rem",
                }}
                className="position-relative"
              >
                <img
                  src={selectedEjari}
                  alt="Selected"
                  className="position-absolute h-100"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
                <Button
                  type="button"
                  onClick={handleDeleteEjari}
                  className="position-absolute top-0 left-0 bg-danger"
                >
                  <Icon name="trash"></Icon>
                </Button>
              </div>
            )}
          </Col>
        </Row>
      )
    );
  };

  const RenderAgencyBuyerDocs = () => {
    const agencyType = deal?.buyerExternalUser?.type;

    return (
      <>
        {agencyType === AgencyType.RealEstateAgency ? (
          <Col sm="12">
            <div className="nk-upload-form">
              <Label>
                <h6>Trade License</h6>
              </Label>

              <Dropzone
                onDrop={(acceptedFiles) =>
                  handleDropTradeLicenseBuyer(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                    >
                      <input {...getInputProps()} />
                      <div className="dz-message">
                        <span className="dz-message-text">
                          <span>Drag and drop</span> image file here or{" "}
                          <span>browse</span>
                        </span>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {selectedTradeLicenseBuyer && (
              <div
                style={{
                  border: "2px dashed #000;",
                  height: "15rem",
                }}
                className="position-relative"
              >
                <img
                  src={selectedTradeLicenseBuyer}
                  alt="Selected"
                  className="position-absolute h-100"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
                <Button
                  type="button"
                  onClick={handleDeleteBuyerTradeLicense}
                  className="position-absolute top-0 left-0 bg-danger"
                >
                  <Icon name="trash"></Icon>
                </Button>
              </div>
            )}
          </Col>
        ) : agencyType === AgencyType.Freelancer ? (
          <Col sm="6">
            <div className="nk-upload-form">
              <Label>
                <h6>RERA Registration</h6>
              </Label>

              <Dropzone
                onDrop={(acceptedFiles) =>
                  handleDropReraImageBuyer(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                    >
                      <input {...getInputProps()} />
                      <div className="dz-message">
                        <span className="dz-message-text">
                          <span>Drag and drop</span> file here or{" "}
                          <span>browse</span>
                        </span>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {selectedReraImageBuyer && (
              <div
                style={{
                  border: "2px dashed #000;",
                  height: "15rem",
                }}
                className="position-relative"
              >
                <img
                  src={selectedReraImageBuyer}
                  alt="Selected"
                  className="position-absolute h-100"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
                <Button
                  type="button"
                  onClick={handleDeleteBuyerReraImage}
                  className="position-absolute top-0 left-0 bg-danger"
                >
                  <Icon name="trash"></Icon>
                </Button>
              </div>
            )}
          </Col>
        ) : agencyType === AgencyType.Referral ? (
          <Col sm="6">
            <div className="nk-upload-form">
              <Label>
                <h6>Emirates ID</h6>
              </Label>

              <Dropzone
                onDrop={(acceptedFiles) =>
                  handleDropEmiratesIdBuyer(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                    >
                      <input {...getInputProps()} />
                      <div className="dz-message">
                        <span className="dz-message-text">
                          <span>Drag and drop</span> file here or{" "}
                          <span>browse</span>
                        </span>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {selectedEmiratesIdBuyer && (
              <div
                style={{
                  border: "2px dashed #000;",
                  height: "15rem",
                }}
                className="position-relative"
              >
                <img
                  src={selectedEmiratesIdBuyer}
                  alt="Selected"
                  className="position-absolute h-100"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
                <Button
                  type="button"
                  onClick={handleDeleteBuyerEmiratesId}
                  className="position-absolute top-0 left-0 bg-danger"
                >
                  <Icon name="trash"></Icon>
                </Button>
              </div>
            )}
          </Col>
        ) : null}
      </>
    );
  };

  const RenderAgencySellerDocs = () => {
    const agencyType = deal?.sellerExternalUser?.type;

    return (
      <>
        {agencyType === AgencyType.RealEstateAgency ? (
          <Col sm="12">
            <div className="nk-upload-form">
              <Label>
                <h6>Trade License</h6>
              </Label>

              <Dropzone
                onDrop={(acceptedFiles) =>
                  handleDropTradeLicenseSeller(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                    >
                      <input {...getInputProps()} />
                      <div className="dz-message">
                        <span className="dz-message-text">
                          <span>Drag and drop</span> image file here or{" "}
                          <span>browse</span>
                        </span>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {selectedTradeLicenseSeller && (
              <div
                style={{
                  border: "2px dashed #000;",
                  height: "15rem",
                }}
                className="position-relative"
              >
                <img
                  src={selectedTradeLicenseSeller}
                  alt="Selected"
                  className="position-absolute h-100"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
                <Button
                  type="button"
                  onClick={handleDeleteSellerTradeLicense}
                  className="position-absolute top-0 left-0 bg-danger"
                >
                  <Icon name="trash"></Icon>
                </Button>
              </div>
            )}
          </Col>
        ) : agencyType === AgencyType.Freelancer ? (
          <Col sm="6">
            <div className="nk-upload-form">
              <Label>
                <h6>RERA Registration</h6>
              </Label>

              <Dropzone
                onDrop={(acceptedFiles) =>
                  handleDropReraImageSeller(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                    >
                      <input {...getInputProps()} />
                      <div className="dz-message">
                        <span className="dz-message-text">
                          <span>Drag and drop</span> file here or{" "}
                          <span>browse</span>
                        </span>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {selectedReraImageSeller && (
              <div
                style={{
                  border: "2px dashed #000;",
                  height: "15rem",
                }}
                className="position-relative"
              >
                <img
                  src={selectedReraImageSeller}
                  alt="Selected"
                  className="position-absolute h-100"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
                <Button
                  type="button"
                  onClick={handleDeleteSellerReraImage}
                  className="position-absolute top-0 left-0 bg-danger"
                >
                  <Icon name="trash"></Icon>
                </Button>
              </div>
            )}
          </Col>
        ) : agencyType === AgencyType.Referral ? (
          <Col sm="6">
            <div className="nk-upload-form">
              <Label>
                <h6>Emirates ID</h6>
              </Label>

              <Dropzone
                onDrop={(acceptedFiles) =>
                  handleDropEmiratesIdSeller(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                    >
                      <input {...getInputProps()} />
                      <div className="dz-message">
                        <span className="dz-message-text">
                          <span>Drag and drop</span> file here or{" "}
                          <span>browse</span>
                        </span>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {selectedEmiratesIdSeller && (
              <div
                style={{
                  border: "2px dashed #000;",
                  height: "15rem",
                }}
                className="position-relative"
              >
                <img
                  src={selectedEmiratesIdSeller}
                  alt="Selected"
                  className="position-absolute h-100"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
                <Button
                  type="button"
                  onClick={handleDeleteSellerEmiratesId}
                  className="position-absolute top-0 left-0 bg-danger"
                >
                  <Icon name="trash"></Icon>
                </Button>
              </div>
            )}
          </Col>
        ) : null}
      </>
    );
  };

  const RenderBuyerDocuments = () => {
    const buyerClientType = deal?.buyerClientType;

    return (
      <>
        {buyerClientType === DealClientType.Alba ? (
          <>
            <Col sm="6">
              <div className="nk-upload-form">
                <Label>
                  <h6>Emirates ID</h6>
                </Label>

                <Dropzone
                  onDrop={(acceptedFiles) =>
                    handleDropEmiratesIdBuyer(acceptedFiles)
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps()}
                        className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message">
                          <span className="dz-message-text">
                            <span>Drag and drop</span> file here or{" "}
                            <span>browse</span>
                          </span>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              {selectedEmiratesIdBuyer && (
                <div
                  style={{
                    border: "2px dashed #000;",
                    height: "15rem",
                  }}
                  className="position-relative"
                >
                  <img
                    src={selectedEmiratesIdBuyer}
                    alt="Selected"
                    className="position-absolute h-100"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                  <Button
                    type="button"
                    onClick={handleDeleteBuyerEmiratesId}
                    className="position-absolute top-0 left-0 bg-danger"
                  >
                    <Icon name="trash"></Icon>
                  </Button>
                </div>
              )}
            </Col>
            <Col sm="6">
              <div className="nk-upload-form">
                <Label>
                  <h6>Passport</h6>
                </Label>
                <Dropzone
                  onDrop={(acceptedFiles) =>
                    handleDropPassportBuyer(acceptedFiles)
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps()}
                        className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message">
                          <span className="dz-message-text">
                            <span>Drag and drop</span> file here or{" "}
                            <span>browse</span>
                          </span>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              {selectedPassportBuyer && (
                <div
                  style={{
                    border: "2px dashed #000;",
                    height: "15rem",
                  }}
                  className="position-relative"
                >
                  <img
                    src={selectedPassportBuyer}
                    alt="Selected"
                    className="position-absolute h-100"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                  <Button
                    type="button"
                    onClick={handleDeleteBuyerPassport}
                    className="position-absolute top-0 left-0 bg-danger"
                  >
                    <Icon name="trash"></Icon>
                  </Button>
                </div>
              )}
            </Col>
          </>
        ) : buyerClientType === DealClientType.External ? (
          <RenderAgencyBuyerDocs />
        ) : null}
      </>
    );
  };

  const RenderSellerDocuments = () => {
    const sellerclientType = deal?.sellerclientType;
    return (
      <>
        {sellerclientType === DealClientType.Alba ? (
          <>
            <Col sm="6">
              <div className="nk-upload-form">
                <Label>
                  <h6>Emirates ID</h6>
                </Label>

                <Dropzone
                  onDrop={(acceptedFiles) =>
                    handleDropEmiratesIdSeller(acceptedFiles)
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps()}
                        className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message">
                          <span className="dz-message-text">
                            <span>Drag and drop</span> file here or{" "}
                            <span>browse</span>
                          </span>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              {selectedEmiratesIdSeller && (
                <div
                  style={{
                    border: "2px dashed #000;",
                    height: "15rem",
                  }}
                  className="position-relative"
                >
                  <img
                    src={selectedEmiratesIdSeller}
                    alt="Selected"
                    className="position-absolute h-100"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                  <Button
                    type="button"
                    onClick={handleDeleteSellerEmiratesId}
                    className="position-absolute top-0 left-0 bg-danger"
                  >
                    <Icon name="trash"></Icon>
                  </Button>
                </div>
              )}
            </Col>
            <Col sm="6">
              <div className="nk-upload-form">
                <Label>
                  <h6>Passport</h6>
                </Label>
                <Dropzone
                  onDrop={(acceptedFiles) =>
                    handleDropPassportSeller(acceptedFiles)
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps()}
                        className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message">
                          <span className="dz-message-text">
                            <span>Drag and drop</span> file here or{" "}
                            <span>browse</span>
                          </span>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              {selectedPassportSeller && (
                <div
                  style={{
                    border: "2px dashed #000;",
                    height: "15rem",
                  }}
                  className="position-relative"
                >
                  <img
                    src={selectedPassportSeller}
                    alt="Selected"
                    className="position-absolute h-100"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                  <Button
                    type="button"
                    onClick={handleDeleteSellerPassport}
                    className="position-absolute top-0 left-0 bg-danger"
                  >
                    <Icon name="trash"></Icon>
                  </Button>
                </div>
              )}
            </Col>
          </>
        ) : (
          <RenderAgencySellerDocs />
        )}
      </>
    );
  };

  const RenderClientDocs = () => {
    return (
      <>
        <Col sm="12">
          <h6>Upload Client Documents</h6>
        </Col>
        <Col sm="6">
          <div className="nk-upload-form">
            <Label>
              <h6>Emirates ID</h6>
            </Label>

            <Dropzone
              onDrop={(acceptedFiles) =>
                handleDropEmiratesIdClient(acceptedFiles)
              }
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps()}
                    className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                  >
                    <input {...getInputProps()} />
                    <div className="dz-message">
                      <span className="dz-message-text">
                        <span>Drag and drop</span> file here or{" "}
                        <span>browse</span>
                      </span>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          {selectedEmiratesIdClient && (
            <div
              style={{
                border: "2px dashed #000;",
                height: "15rem",
              }}
              className="position-relative"
            >
              <img
                src={selectedEmiratesIdClient}
                alt="Selected"
                className="position-absolute h-100"
                style={{
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
              <Button
                type="button"
                onClick={handleDeleteClientEmiratesId}
                className="position-absolute top-0 left-0 bg-danger"
              >
                <Icon name="trash"></Icon>
              </Button>
            </div>
          )}
        </Col>
        <Col sm="6">
          <div className="nk-upload-form">
            <Label>
              <h6>Passport</h6>
            </Label>
            <Dropzone
              onDrop={(acceptedFiles) =>
                handleDropPassportClient(acceptedFiles)
              }
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps()}
                    className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                  >
                    <input {...getInputProps()} />
                    <div className="dz-message">
                      <span className="dz-message-text">
                        <span>Drag and drop</span> file here or{" "}
                        <span>browse</span>
                      </span>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          {selectedPassportClient && (
            <div
              style={{
                border: "2px dashed #000;",
                height: "15rem",
              }}
              className="position-relative"
            >
              <img
                src={selectedPassportClient}
                alt="Selected"
                className="position-absolute h-100"
                style={{
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
              <Button
                type="button"
                onClick={handleDeleteClientPassport}
                className="position-absolute top-0 left-0 bg-danger"
              >
                <Icon name="trash"></Icon>
              </Button>
            </div>
          )}
        </Col>
      </>
    );
  };

  return (
    <form className="content clearfix">
      <Row className="g-gs">
        <Col sm="12">
          <h6>Upload Deal Documents</h6>
        </Col>
        <RenderDealDocuments />

        {/* 
                    Render client documents
                    case : sec market ? render buyer and seller documents
                    case : listing | offplan ? render client documents
                 */}

        {deal?.category === DealCategory.SecondaryProperty ? (
          <>
            <Col sm="12">
              <h6>Upload Buyer/Tenant Documents</h6>
            </Col>
            <RenderBuyerDocuments />

            <Col sm="12">
              <h6>Upload Seller/Landlord Documents</h6>
            </Col>
            <RenderSellerDocuments />
          </>
        ) : deal?.category === DealCategory.ListingAcquired ||
          deal?.category === DealCategory.PrimaryOffplan ? (
          <RenderClientDocs />
        ) : null}

        <div className="nk-upload-form">
          <Label>
            <h6>Other Documents</h6>
          </Label>
          <Dropzone
            onDrop={(acceptedFiles) => handleDropOtherDocuments(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps()}
                  className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                >
                  <input {...getInputProps()} />
                  <div className="dz-message">
                    <span className="dz-message-text">
                      <span>Drag and drop</span> image files here or{" "}
                      <span>browse</span>
                    </span>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <Row>
          {selectedOtherDocuments &&
            selectedOtherDocuments?.length &&
            selectedOtherDocuments?.map((file, i) => (
              <Col key={`other-doc-${i}`} md="6" lg="4" xxl="3">
                <div
                  style={{
                    border: "2px dashed #000;",
                    height: "15rem",
                  }}
                  className="position-relative"
                >
                  <img
                    src={file}
                    alt="Selected"
                    className="position-absolute h-100"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                  <Button
                    onClick={handleDeleteBuyerPassport}
                    type="button"
                    className="position-absolute top-0 left-0 bg-danger"
                  >
                    <Icon name="trash"></Icon>
                  </Button>
                </div>
              </Col>
            ))}
        </Row>
      </Row>
      <div className="actions clearfix">
        <ul>
          <li>
            <Button
              color="primary"
              type="button"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner size="sm" color="light" />
                  Please Wait
                </>
              ) : (
                "Next"
              )}
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};
export default EditDealStepTwo;
