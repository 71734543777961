// LoadingComponent.js
import React from 'react';
import { DataTableItem, DataTableRow } from '../../../components/Component';

const LoadingComponent = ({ centered }) =>
{
  return (

    <DataTableItem className={`d-flex ${centered ? 'justify-content-center' : ''}`}>
      <DataTableRow className="d-flex justify-content-center">
        <div className="loading text-center">
          <div className="loading-animation tri-ring"></div>
        </div>
      </DataTableRow>
    </DataTableItem>
  );
};

export default LoadingComponent;
