import * as types from '../types';

const initialState = {
    data: null,
    error: null,
    fetching: false,
    creating: false,
    updating: false,
    deleting: false
};

export const staffHolidays = (state = initialState, action) => {
    switch (action.type) {
        case types.GETTING_STAFF_ON_HOLIDAYS:
            return {
                ...state,
                fetching: true,
                error: null
            };

        case types.GETTING_STAFF_ON_HOLIDAYS_FAILED:
            return {
                ...state,
                fetching: false,
                data: state?.data ? state?.data : [],
                error: action?.error
            };

        case types.GET_STAFF_ON_HOLIDAYS:
            return {
                ...state,
                data: action.payload ?? {
                    pageNumber: 1,
                    itemsPerPage: 10,
                    result: [],
                    totalCount: null,
                    filteredCount: null
                },
                fetching: false,
                error: action?.error ?? null
            };

        case types.DELETE_STAFF_HOLIDAYS:
            return {
                ...state,
                data: action?.payload
                    ? {
                          ...state?.data,
                          result: state?.data?.result?.filter(
                              (item) =>
                                  (item?.id ?? item?._id) !==
                                  (action?.payload?.id ?? action?.payload?._id)
                          )
                      }
                    : state?.data,
                deleting: action?.deleting ?? false,
                error: action?.error ?? null
            };

        case types.UPDATE_STAFF_HOLIDAYS:
            const payload = action?.payload;

            const index = state.data?.result?.findIndex(
                (item) =>
                    (item?.id ?? item?._id) === (payload?.id ?? payload?._id)
            );

            if (index !== -1) {
                state.data.result[index] = payload;
            }

            return {
                ...state,
                data: state?.data,
                updating: action?.updating ?? false,
                error: action?.error ?? null
            };

        case types.CREATE_STAFF_HOLIDAYS:
            return {
                ...state,
                data: {
                    ...state?.data,
                    result: action?.payload
                        ? [action?.payload, ...state?.data?.result]
                        : state?.data?.result ?? [],
                    filteredCount: (state?.data?.filteredCount??0) + 1,
                    totalCount: (state?.data?.totalCount ?? 0) + 1
                },
                creating: false,
                error: action?.payload ? null : action?.error ?? false
            };

        case types.CREATING_STAFF_HOLIDAYS:
            return {
                ...state,
                creating: true
            };

        default:
            return state;
    }
};
