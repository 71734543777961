import React, { useState } from "react";
import { Row, Col, Button, Modal, ModalBody, Spinner, Label, Input } from "reactstrap";
import { Icon, RSelect } from "../../../../components/Component";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { HttpStatus, PropertyCardStatus, uploadManyToS3 } from "../../../../utils/envConfig";
import uuid from "react-uuid";
import compressImage from "../../../../utils/compression/compress";
import { showToast } from '../../../../utils/toast/toast';

const PocketListingModal = ({
  card,
  isOpen,
  toggleFn,
  refreshFn,
}) => {
  const axios = useAxiosPrivate();
  const [selectedPurpose, setSelectedPurpose] = useState(null);
  const [askingPrice, setAskingPrice] = useState(card?.askingPrice ?? null);
  const [agencyValuation, setAgencyValuation] = useState(card?.agentValutionPrice ?? null);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);

  const updateCard = async () => {
    try {
      if(!selectedPurpose){
       return toast.error("Purpose is a required field.")
      }
      setUpdateLoading(true);
      // upload images and documents to S3 before making the request
      const imagesToUpload = uploadedPhotos?.length
        ? await Promise.all(Array.from(uploadedPhotos).map(async (file) => {
          const compressedFile = await compressImage(file);
          return {
            file: compressedFile,
            fileName: `/pocket-listing/images/${uuid()}-${file?.name}`,
            type: file?.type ?? "images/*",
          };
        }))
        : null;

      const documentsToUpload = uploadedDocuments?.length
        ? Array.from(uploadedDocuments).map((file) => ({
            file: file,
            fileName: `/pocket-listing/docs/${uuid()}-${file?.name}`,
            type: file?.type ?? "application/pdf",
          }))
        : null;

      if (imagesToUpload) {
        await uploadManyToS3(imagesToUpload, axios);
      }
      if (documentsToUpload) {
        await uploadManyToS3(documentsToUpload, axios);
      }

      if (!askingPrice) {
        showToast('Asking Price is a required field.', 'error');
        return;
      }
      const payload = {
        status: PropertyCardStatus.PocketListing,
        purpose: selectedPurpose?.value ?? null,
        agentValutionPrice: agencyValuation ?? null,
        askingPrice: askingPrice ?? null,
      };
      if (imagesToUpload) {
        payload.photos = imagesToUpload?.map((image) => ({ original: image?.fileName }));
      }
      if (documentsToUpload) {
        payload.documents = documentsToUpload?.map((doc) => ({ type: "Other", original: doc?.fileName }));
      }
      const res = await axios.put(`/property-cards/${card?.id ?? card?._id}`, payload);
      if (res.status === HttpStatus.OK) {
        toast.success("Updated card successfully!");
        toggleFn();
        refreshFn();
      }
    } catch (error) {
      const serverErrorMessage = error.response.data.message;
      if (serverErrorMessage) {
        showToast(serverErrorMessage, 'error')
      } else {
        showToast("An error occurred, we couldn't update the card.", 'error')
      }
    } finally {
      setUpdateLoading(false);
    }
  };

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={toggleFn}
      onClick={(e) => e.stopPropagation()}
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggleFn();
          }}
          className="close mt-2"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Convert Property Card to a Pocket Listing</h5>
          <div className="mt-4">
            <Row className="gy-4">
              <div>
              <Col sm="12">
                <Label tag="h5">Purpose</Label>
                <RSelect
                  isRequired={true}
                  placeholder='Select Purpose...'
                  options={['Sell', 'Lease'].map((x) => ({
                    label: x,
                    value: x,
                  }))}
                  value={selectedPurpose}
                  onChange={(e) => {
                    setSelectedPurpose(e);
                  }}
                />
              </Col>
              <Row>
                <Col sm="6" className="mt-4">
                  <Label tag="h5">Asking Price</Label>
                  <Input
                    type="number"
                    placeholder="Asking Price"
                    multiple
                    id="askingPrice"
                    required={true}
                    value={askingPrice}
                    name="askingPrice"
                    onChange={(e) => {
                      setAskingPrice(e.target.value);
                    }}
                    />
                </Col>
                <Col sm="6" className="mt-4">
                  <Label tag="h5">Agency Valuation</Label>
                  <Input
                  type="number"
                  value={agencyValuation}
                  placeholder="Agent Valuation"
                  id="agentValutionPrice"
                  name="agentValutionPrice"
                  onChange={(e) => {
                    setAgencyValuation(e.target.value);
                  }}
                />
                </Col>
              </Row>
              <Row>
                <Col sm="6" className="mt-4">
                  <Label tag="h5">Photos</Label>
                  <Input
                    type="file"
                    placeholder="Upload Photos..."
                    multiple
                    id="photos"
                    name="photos"
                    accept="image/*"
                    onChange={(e) => {
                      if (!e.target.files || e.target.files.length === 0) return;
                      setUploadedPhotos(e.target.files);
                    }}
                    />
                </Col>
                <Col sm="6" className="mt-4">
                  <Label tag="h5">Documents</Label>
                  <Input
                    type="file"
                    placeholder="Upload Documents..."
                    multiple
                    id="documents"
                    name="documents"
                    accept="application/pdf"
                    onChange={(e) => {
                      if (!e.target.files || e.target.files.length === 0) return;
                      setUploadedDocuments(e.target.files);
                    }}
                  />
                </Col>
              </Row>
              </div>
              <Col sm="12" style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  type="submit"
                  color="primary"
                  disabled={updateLoading}
                  onClick={() => updateCard()}
                >
                  {updateLoading ? (
                    <Spinner color="light" size={"sm"} />
                  ) : (
                    "Convert"
                  )}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PocketListingModal;
