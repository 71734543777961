import {
  Button,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
import CardInner from "../../../../components/cards/CardInner";
import CardTitleGroup from "../../../../components/cards/CardTitleGroup";
import CardTools from "../../../../components/cards/CardTools";
import {
  Icon,
  PaginationComponent,
  TooltipComponent,
} from "../../../../components/Component";
import { lazy, Suspense, useEffect, useMemo, useRef, useState } from "react";
import { BREAKPOINT_HEADERS, HEADER_FIELDS, PER_PAGE_OPTIONS } from "./constants";
import { format } from "date-fns";
import TransactionsSkeletonLoader from "./components/TransactionsSkeletonLoader";
import { CommissionStatus, deleteFromS3 } from "../../../../utils/envConfig";
import dealQueries, { DEAL_KEYS } from "../../../../queries/dealQueries";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useMutation, useQueryClient } from "react-query";
import { showToast } from "../../../../utils/toast/toast";
import LoaderModal from "../../../../components/modals/LoaderModal";
import DocumentPreview from "./components/DocumentPreview";
import { sortPdfLast } from "./utils";
import { truncate } from "lodash";

const TransactionEditModal = lazy(() => import('./components/TransactionEditModal'));
const TransactionCreationModal = lazy(() => import('./components/TransactionCreationModal'));
const ConfirmationModal = lazy(() => import('../../../../components/modals/ConfirmationModal'));

const DealTransactionsView = ({
  transactionsState,
  deal,
  queryParams,
  setQueryParams,
  refetchFn
}) => {
  const [searchText, setSearchText] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);
  const searchInputRef = useRef(null);

  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);
  const toggleCreationModal = () => setIsCreationModalOpen(!isCreationModalOpen);


  useEffect(() => {
    if (isSearchOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isSearchOpen]);

  const renderStatusBadge = (status) => {
    const CUSTOM_GREEN = "rgba(34, 169, 133, 1)";
    switch (status) {
      case CommissionStatus.FullyPaid:
        return (
          <span
            className="badge badge-dot has-bg bg-success border border-success rounded"
            style={{ color: CUSTOM_GREEN }}
          >
            {status}
          </span>
        );
      case CommissionStatus.PartiallyPaid:
        return (
          <span className="badge badge-dot has-bg bg-warning border border-warning text-warning rounded">
            {status}
          </span>
        );
      default:
        return (
          <span className="badge badge-dot has-bg bg-danger border border-danger text-danger rounded">
            {CommissionStatus.Pending} Payment
          </span>
        );
    }
  };

  return (
    <>
      <CardInner>
        <CardTitleGroup>
          <CardTitle>
            <h5 className="title">
              Commission Transactions{" "}
              <span className="ms-1">
                {renderStatusBadge(deal?.commissionStatus ?? "")}
              </span>
            </h5>
          </CardTitle>
          <CardTools>
            <div className="form-inline flex-nowrap gx-3"></div>
          </CardTools>
          <CardTools className="me-n1">
            <ul className="btn-toolbar gx-1">
              <li>
                <a
                  href="#search"
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleSearch();
                  }}
                  className="btn btn-icon btn-trigger search-toggle toggle-search"
                >
                  <Icon name="search"></Icon>
                </a>
              </li>

              <li className="btn-toolbar-sep"></li>

              {deal?.commissionStatus !== CommissionStatus.FullyPaid ? (
                <li onClick={toggleCreationModal}>
                  <TooltipComponent
                    tag="a"
                    containerClassName="btn btn-trigger btn-icon"
                    id={"add-transaction-for" + deal?.id}
                    icon="plus"
                    direction="top"
                    text={"Add Transaction"}
                  />
                </li>
              ) : null}

              <li>
                <div className="toggle-wrap">
                  <div>
                    <ul className="btn-toolbar gx-1">
                      <li>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            tag="span"
                            className="btn btn-trigger btn-icon dropdown-toggle"
                          >
                            <Icon name="setting"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end className="dropdown-menu-xs">
                            <ul className="link-check">
                              <li>
                                <span>Show</span>
                              </li>
                              {PER_PAGE_OPTIONS.map((option, idx) => (
                                <li key={`act-show-option-${idx}`}>
                                  <DropdownItem
                                    tag="span"
                                    className={
                                      queryParams?.pageSize === option
                                        ? "active"
                                        : ""
                                    }
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setQueryParams((prev) => ({
                                        ...prev,
                                        pageSize: option,
                                        page: 1,
                                      }));
                                    }}
                                  >
                                    {option}
                                  </DropdownItem>
                                </li>
                              ))}
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </CardTools>
          <div
            className={`card-search search-wrap ${isSearchOpen && "active"}`}
          >
            <div className="card-body p-0">
              <div className="search-content border rounded-lg">
                <Button
                  className="search-back btn-icon toggle-search active bg-transparent text-secondary ms-2"
                  onClick={() => {
                    setQueryParams((prev) => ({
                      ...prev,
                      payee: "",
                    }));
                    setSearchText("");
                    toggleSearch();
                  }}
                >
                  <Icon name="arrow-left"></Icon>
                </Button>
                <input
                  type="text"
                  className="border-transparent form-focus-none form-control ps-5 bg-transparent text-secondary"
                  placeholder="Search Commission Transactions by payee name"
                  value={searchText}
                  ref={searchInputRef}
                  onChange={(e) => {
                    setQueryParams((prev) => ({
                      ...prev,
                      payee: e.target.value,
                      page: 1,
                    }));
                    setSearchText(e.target.value);
                  }}
                />
                <Button className="search-submit btn-icon bg-transparent text-secondary border-0 me-3">
                  <Icon name="search"></Icon>
                </Button>
              </div>
            </div>
          </div>
        </CardTitleGroup>
      </CardInner>
      <CardInner className="">
        <table className="table table-tranx border">
          <thead>
            <tr className="tb-tnx-head d-none d-md-table-row">
              {HEADER_FIELDS.map((header, idx) => (
                <th 
                  className={BREAKPOINT_HEADERS[header] ? "d-none d-md-table-cell" : ""}
                  key={`transaction-header-${idx}-${header}`}
                >
                  <span>{header}</span>
                </th>
              ))}
              <th>
                <span>&nbsp;</span>
              </th>
            </tr>
          </thead>
          <tbody className="">
            {transactionsState?.isLoading ? (
              <TransactionsSkeletonLoader />
            ) : (
              transactionsState?.transactions?.map((transaction, idx) => (
                <DealTransactionItem
                  isLatest={idx === 0}
                  key={`transaction-${idx}`}
                  transaction={transaction}
                  deal={deal}
                  refetchFn={refetchFn}
                />
              ))
            )}
          </tbody>
        </table>
      </CardInner>
      <CardInner>
        {transactionsState?.error ? (
          <p className="text-center text-danger fs-6">
            Failed to fetch transactions.
            <br />
            {transactionsState?.error?.response?.data?.message ?? null}
          </p>
        ) : null}
        {transactionsState?.transactions?.length ? (
          <>
            <PaginationComponent
              itemPerPage={queryParams?.pageSize}
              totalItems={transactionsState?.totalTransactions}
              paginate={(page) => setQueryParams((prev) => ({ ...prev, page }))}
              currentPage={queryParams?.page}
            />
          </>
        ) : (
          !transactionsState?.isLoading && <p className="text-center fw-bold fs-6">
            No Transactions found.
            <br />
          </p>
        )}
      </CardInner>
      {isCreationModalOpen && <Suspense fallback={<LoaderModal />}>
        <TransactionCreationModal 
          isOpen={isCreationModalOpen} 
          toggleFn={toggleCreationModal} 
          deal={deal}
          previousTransactions={transactionsState?.transactions ?? []}
          refetchFn={refetchFn} 
        />
      </Suspense>}
    </>
  );
};

const DealTransactionItem = ({ transaction, isLatest, deal, refetchFn }) => {
  const axios = useAxiosPrivate();
  const queryClient = useQueryClient();
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isDocumentsModalOpen, setIsDocumentsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const toggleUpdateModal = () => setIsUpdateModalOpen(!isUpdateModalOpen);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  const toggleNoteModal = () => {
    if (!transaction?.note || transaction?.note?.length === 0) {
      return;
    }
    setIsNoteModalOpen(!isNoteModalOpen);
  };

  const toggleDocumentsModal = () => {
    if (!transaction?.documents || transaction?.documents?.length === 0) {
      return;
    }
    setIsDocumentsModalOpen(!isDocumentsModalOpen);
  };

  const transactionId = useMemo(() => transaction?.id ?? transaction?._id, [transaction]);

  const { mutationFn } = dealQueries.Transactions.delete(axios, transactionId);
  const deleteTransaction = useMutation(mutationFn, {
    onSettled: () => {
      queryClient.invalidateQueries(DEAL_KEYS.transactions.baseKey);
      toggleDeleteModal();
    },
    onSuccess: () => {
      showToast('Deleted transaction successfully.', 'success');
      refetchFn && typeof refetchFn === 'function' && refetchFn();
    },
    onError: (error) => { 
      console.log(error?.response?.message, "ERROR DATA");
      showToast(error.message, "error");
    },
  });

  const [deleteLoading, setDeleteLoading] = useState(false);
  const deleteDocumentFromStorage = async (docs = []) => {
    try {
      setDeleteLoading(true);
      const promises = docs && docs.length ? docs.map(async (doc) =>  await deleteFromS3(doc, axios)) : [];
      await Promise.all(promises);
    } catch (error) {
      showToast('Failed to delete documents from S3.', 'error');
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleDeleteTransaction = async () => {
    try {
      // delete the transaction documents from S3
      const documentsToDelete = [...(transaction?.documents ?? [])];
      await deleteDocumentFromStorage(documentsToDelete);
    } catch (error) {
      // if deleting documents failed, don't proceed with deleting the transaction yet
      // we don't want to leave unused documents in the S3 bucket.
      toggleDeleteModal();
      return;
    }

    // Actual call to delete the transaction
    deleteTransaction.mutate();
  }

  return (
    <>
      <tr className="tb-tnx-item bg-white">
        <td className="bg-white fw-bold">
          {transaction?.payeeName ?? 'Unknown'}
        </td>
        <td className="bg-white fw-bold">
          {Number(transaction?.amount ?? 0)?.toFixed(4)}
        </td>
        <td className="bg-white d-none d-md-table-cell" style={{ color: '#8094ae' }}>
          {transaction?.paymentDate
            ? format(new Date(transaction?.paymentDate), 'dd/MM/yyyy hh:mm a')
            : 'Unknown'}
        </td>
        <td className="bg-white">{transaction?.payeeType ?? 'Unknown'}</td>
        <td className="bg-white">
          <div className="d-flex flex-column">
            <span className="">{transaction?.paymentMethod ?? 'Unknown'}</span>
            {transaction?.documents && transaction?.documents?.length ? (
              <span className="sub-text text-primary">
                <span
                  role="button"
                  onClick={() => {
                    toggleDocumentsModal();
                  }}
                >
                  View documents
                </span>
              </span>
            ) : null}
          </div>
        </td>
        <td className="bg-white text-wrap d-none d-md-table-cell">{truncate(transaction?.note ?? 'No note.', { length: 25 })}</td>
        <td className={`bg-white fw-bold`}>
          {Number(transaction?.balance ?? 0)?.toFixed(4)}
        </td>
        <td className="bg-white text-end">
          {isLatest ? (
            <UncontrolledDropdown>
              <DropdownToggle
                tag="span"
                className="btn btn-trigger btn-icon dropdown-toggle"
              >
                <Icon name={'more-h'} />
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  {transaction?.note && transaction?.note?.length > 0 ? (
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdown"
                        onClick={toggleNoteModal}
                      >
                        <Icon name="question-alt"></Icon>
                        <span>View Note</span>
                      </DropdownItem>
                    </li>
                  ) : null}
                  <li>
                    <DropdownItem
                      tag="a"
                      className="cursor-pointer"
                      onClick={toggleUpdateModal}
                    >
                      <Icon name="edit"></Icon>
                      <span>Edit Transaction</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      className="cursor-pointer"
                      onClick={toggleDeleteModal}
                    >
                      <Icon name="trash"></Icon>
                      <span>Delete Transaction</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            <span className={`btn btn-icon`} onClick={toggleNoteModal}>
              <TooltipComponent
                tag="a"
                containerClassName={`btn btn-trigger btn-icon text-primary ${
                  !transaction?.note || !transaction?.note?.length
                    ? 'd-none'
                    : ''
                }`}
                id={'view-note-for' + (transaction?.id ?? transaction?._id)}
                icon="question-alt"
                direction="top"
                text={'View Note'}
              />
            </span>
          )}
        </td>
      </tr>
      {isNoteModalOpen && (
        <Suspense fallback={<LoaderModal />}>
          <Modal isOpen={isNoteModalOpen} toggle={toggleNoteModal}>
            <ModalHeader>
              <h5 className="title">Transaction Note</h5>
            </ModalHeader>
            <ModalBody>
              <p>{transaction?.note}</p>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleNoteModal}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </Suspense>
      )}
      {isDocumentsModalOpen && (
        <Suspense fallback={<LoaderModal />}>
          <Modal
            isOpen={isDocumentsModalOpen}
            toggle={toggleDocumentsModal}
            size="xl"
          >
            <ModalHeader className="d-block">
              <div className="d-flex justify-content-between">
                <h5 className="title">Transaction Documents</h5>
                <span className="text-muted cursor-pointer" role="button" onClick={toggleDocumentsModal}>
                  <Icon name={'cross'} />
                </span>
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="d-flex flex-wrap pb-4" style={{ gap: '1rem' }}>
                {transaction?.documents?.length
                  ? transaction?.documents?.sort((a, b) => sortPdfLast(a, b))?.map((doc, idx) => (
                      <div key={`prev-${idx}`} className="flex-grow-1 flex-shrink-1 align-center d-flex justify-content-center">
                        <DocumentPreview
                          key={`doc-preview-${idx}`}
                          width={500}
                          height={500}
                          imageLayout="fill"
                          allowedImageSize={{ maxWidth: '100%', maxHeight: '100%' }}
                          allowedPdfSize={{ maxHeight: 500, maxWidth: 500 }}
                          documentUrl={doc}
                          noDeleteOption
                          className="border rounded"
                        />
                      </div>
                    ))
                  : null}
              </div>
            </ModalBody>
          </Modal>
        </Suspense>
      )}
      {isUpdateModalOpen && (
        <Suspense fallback={<LoaderModal />}>
          <TransactionEditModal
            isOpen={isUpdateModalOpen}
            toggleFn={toggleUpdateModal}
            transaction={transaction}
            deal={deal}
            refetchFn={refetchFn}
          />
        </Suspense>
      )}
      {isDeleteModalOpen && (
        <Suspense fallback={<LoaderModal />}>
          <ConfirmationModal
            isOpen={isDeleteModalOpen}
            toggleFn={toggleDeleteModal}
            actionFn={handleDeleteTransaction}
            loadingState={
              deleteTransaction.status === 'loading' || deleteLoading
            }
            title={'Delete Transaction'}
            dimButtons
            isDeletion
          >
            <p>
              Are you sure you want to delete this transaction? This is
              irreversible.
            </p>
          </ConfirmationModal>
        </Suspense>
      )}
    </>
  );
};

export default DealTransactionsView;
