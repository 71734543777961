import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { defaultUserImage, getImage } from "../../../utils/envConfig";
import { formatDistance } from "date-fns";
import { Button, Form, Input } from "reactstrap";
import { Icon } from "../../../components/Component";

export default function CommentCard ( { comment } )
{
    const [ image, setImage ] = useState( null );

    const [ date, setDate ] = useState( new Date() );

    const [ commentText, setCommentText ] = useState( "" );

    const [ comments, setComments ] = useState();

    const [ commentDetails, setCommentDetails ] = useState();

    const [ replies, setReplies ] = useState( [] );

    const authuser = useSelector( ( state ) => state?.user?.loggedInUser );

    const axios = useAxiosPrivate();

    const fetchImage = async () =>
    {
        try
        {
            if ( !comment?.created_by?.photo )
            {
                setImage( defaultUserImage );
                return;
            }

            const image = await getImage( comment?.created_by?.photo, axios );
            setImage( image?.signedRequest );
        } catch ( err )
        {
            setImage( defaultUserImage );
        }
    };

    const handleCommentSubmit = async ( e ) =>
    {
        e.preventDefault();
        try
        {
            const { data } = await axios.post(
                `/reports/main/live-feed/feed-comment`,
                {
                    comment: commentText,
                    commentId: comment?._id ?? comment?.id,
                }
            );

            setCommentDetails( ( prev ) => ( {
                totalReplies: ( prev?.totalReplies ?? 0 ) + 1,
            } ) );
        } catch ( err )
        {
            console.log( err );
        } finally
        {
            setCommentText( "" );
        }
    };

    const fetchReplies = async () =>
    {
        if ( commentDetails?.totalReplies < 1 )
        {
            return;
        }

        try
        {
            const { data } = await axios.get(
                `/reports/main/live-feed/comments/${ comment?._id }`
            );
            setReplies( data );
        } catch ( err )
        {
            console.log( err );
        }
    };

    const getCommentReplyDetails = async () =>
    {
        try
        {
            const { data } = await axios.get(
                `/reports/main/live-feed/comments/${ comment?._id }/reply-details`
            );

            setCommentDetails( data );
            console.log( data );
        } catch ( err )
        {
            console.log( err );
        }
    };

    useEffect( () =>
    {
        fetchImage();

        getCommentReplyDetails();

        const interval = setInterval( () =>
        {
            setDate( new Date() );
        }, 60000 );

        return () => clearInterval( interval );
    }, [] );

    return (
        <div className="activity-card-cont activity-comment-cont text-dark  ms-4 mt-2 mb-3">
            <div className="content-wrapper">
                <div className="img-cont-comment">
                    <img src={ image } alt="Activity creator" className="img" />
                </div>
                <div className="content comment">

                    <div className="bg-light pt-1 pb-1 ps-4 pe-4 d-inline-block" style={ { borderRadius: "2rem" } } >

                        <div>


                            <span className="creator">
                                { !comment?.created_by || !comment.created_by?.first_name
                                    ? "System"
                                    : comment?.created_by?._id === authuser?.id
                                        ? "You"
                                        : `${ comment?.created_by?.first_name } ${ comment?.created_by?.last_name }` }
                            </span>{ " " } </div>
                        <span>{ comment?.comment }</span>  </div>
                    <div className="ps-4 pt-1">
                        <span className="time-ago text-center lcap text-muted fw-light " style={ { fontSize: "13px" } }>
                            { comment?.createdAt &&
                                formatDistance( new Date( comment?.createdAt ), date, {
                                    includeSeconds: true,
                                    addSuffix: true,
                                } )
                                    ?.replace( "about ", "" )
                                    ?.replace( " minutes", " mins" )
                                    ?.replace( " minute", " min" )
                                    ?.replace( " seconds", " secs" )
                                    ?.replace( " second", " sec" )
                                    ?.replace( " hours", " hrs" )
                                    ?.replace( " hour", " hr" )
                                    .replace( "years", " yrs" )
                                    .replace( "year", " yr" )
                                    .replace( "months", " mths" )
                                    .replace( "month", " mth" )
                                    .replace( "weeks", " wks" )
                                    .replace( "week", " wk" )
                                    .replace( "less than ", "" ) }
                        </span>
                    </div>

                </div>

            </div>

            <div className="card-actions mt-2">
                <Form className="comment-form" onSubmit={ handleCommentSubmit }>
                    <Input
                        placeholder="Reply to comment"
                        className=""
                        value={ commentText }
                        onChange={ ( e ) => setCommentText( e.target.value ) }
                    />
                    { commentText && (
                        <Button className="bg-transparent border-0 comment-submit-btn">
                            <Icon
                                name="send"
                                className={ "text-primary" }
                                style={ { fontSize: 25 } }
                            />
                        </Button>
                    ) }
                </Form>


                <span
                    className="time-ago text-center text-primary"
                    onClick={ fetchReplies }
                >
                    { commentDetails?.totalReplies ?? 0 }{ " " }
                    { commentDetails?.totalReplies > 1 ? "Replies" : "Reply" }
                </span>
            </div>
            <div className="bg-lighter rounded ">


                { replies?.length
                    ? replies?.map( ( comment ) => <CommentCard comment={ comment } /> )
                    : null }
            </div>
        </div>
    );
}
