/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Input } from "reactstrap";
import {
  BlockBetween,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../../../components/Component";
import CardInner from "../../../../../components/cards/CardInner";
import LoadingComponent from "../../../../components/loader/tableLoader";

const ExplorerSettingsView = ({ settingsState, setIsEditState }) => {
  const { globalSettings, loading, error } = settingsState;
  
  return (
    <CardInner size="lg">
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Explorer Settings</BlockTitle>
            <BlockDes>
              <p>Settings for property explorer.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent>
            <Button
              className="d-none d-lg-inline"
              color="primary"
              style={{ marginTop: "-2rem" }}
              onClick={() => setIsEditState(true)}
            >
              Edit
            </Button>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <a
              href="#"
              className="toggle btn btn-icon btn-trigger mt-n1"
              data-target="userAside"
            >
              <Icon name="menu-alt-r" />
            </a>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {error ? (
        <BlockHeadContent>
          <h6 className="text-danger">Failed to get settings</h6>
        </BlockHeadContent>
      ) : loading ? (
        <LoadingComponent />
      ) : (
        <>
          <BlockHead size={"sm"}>
            {/* <BlockHeadContent>
              <h6>Checkout Settings</h6>
            </BlockHeadContent> */}
          </BlockHead>
          <BlockContent>
            <div className="gy-3">
              <div className="g-item">
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ gap: "1rem" }}
                >
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor="limit">
                      Agent Assignment Limit
                    </label>
                    <span class="form-note">The total number allowed for checkout by an agent.</span>
                  </div>
                  <Input
                    name="limit"
                    type="number"
                    value={globalSettings.global.checkoutLimit.limit ?? ""}
                    disabled
                    className="w-50"
                  />
                </div>
              </div>
              <div className="g-item">
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ gap: "1rem" }}
                >
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor="tolerance">
                      Tolerance
                    </label>
                    <span class="form-note">The leeway amount to allow when agent checks out property cards in bulk.</span>
                  </div>
                  <Input
                    type="number"
                    name="tolerance"
                    value={globalSettings.global.checkoutLimit.tolerance ?? ""}
                    disabled
                    className="w-50"
                  />
                </div>
              </div>
              <div className="g-item">
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ gap: "1rem" }}
                >
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor="unassignAfter">
                      Automatically Unassign After
                    </label>
                    <span class="form-note">The time interval to un-assign agent from a property card after assignment.</span>
                  </div>
                  <Input
                    type="text"
                    name="unassignAfter"
                    value={globalSettings.global.checkoutLimit.unassignAfter ?? ""}
                    disabled
                    className="w-50"
                  />
                </div>
              </div>
              <div className="g-item">
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ gap: "1rem" }}
                >
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor="unassignAfter">
                      After Returning, Block For
                    </label>
                    <span class="form-note text-wrap" style={{ maxWidth: '60ch' }}>The time interval to block a lead/property cards from being assigned after it has been returned by an agent.</span>
                  </div>
                  <Input
                    type="text"
                    name="unassignAfter"
                    value={globalSettings.global.blockAfterReturningFor ?? ""}
                    disabled
                    className="w-50"
                  />
                </div>
              </div>
            </div>
          </BlockContent>
          <BlockHead size={"sm"}>
            <BlockHeadContent>
              <h6>Lead-Specific Settings</h6>
            </BlockHeadContent>
          </BlockHead>
          <BlockContent>
            <div className="gy-3">
              <div className="g-item">
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ gap: "1rem" }}
                >
                  <div className="form-group">
                    <label
                      className="form-label mt-3"
                      htmlFor="unassignLeadAfter"
                    >
                      Unassign Agent After
                    </label>
                    <span class="form-note">Specifies the interval for un-assigning an agent from a lead after a period of inactivity.</span>
                  </div>
                  <Input
                    type="text"
                    name="unassignLeadAfter"
                    disabled
                    defaultValue={globalSettings.global.unAssignLeadAfter ?? ""}
                    className="w-50"
                  />
                </div>
              </div>
              <div className="g-item">
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ gap: "1rem" }}
                >
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor="unassignAfter">
                      After Lost, Block For
                    </label>
                    <span class="form-note">The time interval to block a lead from being assigned after it has been marked lost by an agent.</span>
                  </div>
                  <Input
                    type="text"
                    name="unassignAfter"
                    value={globalSettings.global.blockLeadFor ?? ""}
                    disabled
                    className="w-50"
                  />
                </div>
              </div>
            </div>
          </BlockContent>
          {/* Button renders on smaller screens */}
          <Button
            className="mt-4 d-lg-none"
            color="primary"
            onClick={() => setIsEditState(true)}
          >
            Edit
          </Button>
        </>
      )}
    </CardInner>
  );
};

export default ExplorerSettingsView;
