import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { HttpStatus } from "../../../../../utils/envConfig";
import { Button } from "reactstrap";
import PropCardNote from "./PropCardNote";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  Icon,
} from "../../../../../components/Component";
import NoteCreationModal from "./NoteCreationModal";

const PropCardNotesList = ({ propertyCard, isAdminOrAssigned = false }) => {
  const axios = useAxiosPrivate();

  const [refresh, setRefresh] = useState(false);
  const toggleRefresh = () => setRefresh(!refresh);
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);
  const toggleCreationModal = () =>
    setIsCreationModalOpen(!isCreationModalOpen);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    if (propertyCard && (propertyCard.id ?? propertyCard._id)) {
      fetchPropCardNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyCard, refresh]);

  const fetchPropCardNotes = async () => {
    try {
      const res = await axios.get(
        `/property-cards/${propertyCard?.id ?? propertyCard?._id}/notes`
      );
      res.status === HttpStatus.OK && setNotes(res?.data);
    } catch (error) {
      console.log(error);
      toast.error("Couldn't get the Property Card's notes.");
    }
  };
  return (
    <>
      <BlockHead>
        <BlockBetween>
          <BlockHeadContent>
              <h5 className="nk-block-title title" id="notes">
                Property Card Notes
              </h5>
          </BlockHeadContent>
          <BlockHeadContent>
            {isAdminOrAssigned ? <Button color="primary" size="sm" onClick={toggleCreationModal}>
              <div
                className="d-flex align-items-center"
                style={{ gap: "0.3rem" }}
              >
                <span>
                  <Icon name={"plus"} />
                </span>
                <span className="">Add Note</span>
              </div>
            </Button> : null}
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
        {notes.length
          ? notes.map((note, idx) => (
              <PropCardNote
                note={note}
                refreshFn={toggleRefresh}
                isAdminOrAssigned={isAdminOrAssigned}
                key={`note-${idx}`}
              />
            ))
          : <p>There are no notes about this property card.</p>}
      </div>
      <NoteCreationModal
        propertyCardId={propertyCard?.id}
        isOpen={isCreationModalOpen}
        toggleFn={toggleCreationModal}
        refreshFn={toggleRefresh}
      />
    </>
  );
};

export default PropCardNotesList;
