import { createSelector } from "@reduxjs/toolkit";

const selectPropertyState = (state) => state.property;

export const communitiesSelector = createSelector(
    [selectPropertyState],
    propertyState => ({
      communities: propertyState.communities,
      isLoading: propertyState.communitiesLoading,
      error: propertyState.error,
    })
);

export const buildingsSelector = createSelector(
    [selectPropertyState],
    propertyState => ({
      buildings: propertyState.buildings,
      isLoading: propertyState.buildingsLoading,
      error: propertyState.error,
    })
);

export const propertyTypesSelector = createSelector(
    [selectPropertyState],
    propertyState => ({
      propertyTypes: propertyState.propertyTypes,
      propTypesLoading: propertyState.propTypesLoading,
      error: propertyState.error,
    })
);

export const amenitiesSelector = createSelector(
    [selectPropertyState],
    propertyState => ({
      amenities: propertyState.amenities,
      isLoading: propertyState.amenitiesLoading,
      error: propertyState.error,
    })
);