import { toast } from "react-toastify";


const showToast = (message, type, autoClose) => {
  const config = autoClose ? { style: { left: "-30%" }, autoClose: Number(autoClose) } : { style: { left: "-30%" } };
  switch (type) {
    case 'success':
      return toast.success(message, config);
    case 'error':
      return toast.error(message, config);
    case 'warning':
      return toast.warn(message, config);
    default:
      return toast.info(message, config);

  }
}

export { showToast };