import { Card } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PaginationComponent,
  TooltipComponent,
} from "../Component";
import CardInner from "../cards/CardInner";
import CardTitleGroup from "../cards/CardTitleGroup";
import NewAgentAvatar from "../../pages/users/components/NewAgentAvatar";
import { Link, useNavigate } from "react-router-dom";
import useScreenSize from "../../hooks/useScreenSize";
import { ScreenBreakPoints } from "../../utils/envConfig";
import Skeleton from "react-loading-skeleton";

const AgentTeamView = ({
  queryParams,
  setQueryParams,
  agent: profileAgent,
  membersData,
}) => {
  const navigate = useNavigate();
  const { width: screenWidth } = useScreenSize();

  return (
    <div id="teams">
      <Block className={"mt-5"}>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">
                Teams{" "}
                {profileAgent?.isLeader ? (
                  <span className="badge badge-sm ms-2 text-primary bg-primary-dim">
                    Leader
                  </span>
                ) : null}
              </BlockTitle>
              <BlockDes>
                <p>
                  The agent's team members.
                </p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
      </Block>
      <Block>
        <Card className="card-full">
          <CardInner>
            <CardTitleGroup>
              <div className="card-title">
                <h6 className="title">Team Members</h6>
              </div>
            </CardTitleGroup>
          </CardInner>
          <div className="nk-tb-list mt-n2">
            <div className="nk-tb-item nk-tb-head">
              <div className="nk-tb-col tb-col-md">
                <span>Member</span>
              </div>
              <div className="nk-tb-col">
                <span className="ms-2 ms-md-0">Agent</span>
              </div>
              {screenWidth >= ScreenBreakPoints.xs ? (
                <div className="nk-tb-col">
                  <span>Phone</span>
                </div>
              ) : null}
              <div className="nk-tb-col tb-col-md">
                <span>Email</span>
              </div>
              {screenWidth >= ScreenBreakPoints.xs ? (
                <div className="nk-tb-col">
                  <span>&nbsp;</span>
                </div>
              ) : null}
            </div>
            {/* Start of skeleton loader */}
            {membersData?.isLoading ? (
              new Array(5).fill(0).map((_, id1) => (
                <div className="nk-tb-item" key={`skeleton-member-row-${id1}`}>
                  {new Array(5).fill(0).map((_, id2) => (
                    <div className="nk-tb-col" key={`skeleton-cell-${id1}-${id2}`}>
                      <Skeleton />
                    </div>
                  ))}
                </div>
              ))
            ) : null}
            {/* End of skeleton loader */}
            {!membersData?.isError && membersData?.data?.agents?.length
              ? membersData?.data?.agents?.map((agent, id) => (
                  <div className="nk-tb-item" key={`member-row-${id}`}>
                    <div className="nk-tb-col tb-col-md">
                      {agent?.isLeader ? <span className="ms-1 text-black fw-medium">Leader</span> : <span className="ms-1">Agent</span>}
                    </div>
                    <div className="nk-tb-col">
                      <span className="d-flex align-items-center gap-3">
                        <NewAgentAvatar size="sm" agent={agent} />{" "}
                        {screenWidth < ScreenBreakPoints.xs ? (
                          <Link to={`/test-profile/${agent?.userId}`}>
                            {`${agent?.user?.first_name}`}
                          </Link>
                        ) : (
                          `${agent?.user?.first_name}`
                        )}
                      </span>
                    </div>
                    {screenWidth >= ScreenBreakPoints.xs ? (
                      <div className="nk-tb-col">
                        <span>{agent?.user?.phone}</span>
                      </div>
                    ) : null}
                    <div className="nk-tb-col tb-col-md">
                      <span>{agent?.user?.email}</span>
                    </div>
                    {screenWidth >= ScreenBreakPoints.xs ? (
                      <div className="nk-tb-col text-end">
                        <span
                          onClick={() =>
                            navigate(`/test-profile/${agent?.userId}`)
                          }
                        >
                          <TooltipComponent
                            tag="a"
                            containerClassName="btn btn-trigger btn-icon"
                            id={"view-profile-of-" + agent?.id}
                            icon="eye"
                            direction="top"
                            text={"View Profile"}
                          />
                        </span>
                      </div>
                    ) : null}
                  </div>
                ))
              : null}
            {membersData?.isError ? (
              <p className="text-center fs-6 fw-bold text-danger">
                Failed to find team members.
              </p>
            ) : null}
            {!membersData.isLoading && !membersData?.data?.agents?.length ? (
              <p className="text-center fs-6 fw-bold">No team members found</p>
            ) : null}
          </div>
          {membersData?.data?.searchCount ? (
            <CardInner className="d-flex justify-content-between align-items-center" style={{ gap: '1rem' }}>
              <PaginationComponent
                currentPage={queryParams?.page ?? 1}
                itemPerPage={
                  queryParams?.limit ?? membersData?.data?.limit ?? 5
                }
                totalItems={membersData?.data?.searchCount ?? 0}
                paginate={(newPage) =>
                  setQueryParams((prev) => ({ ...prev, page: newPage }))
                }
              />
              <p className="text-secondary fw-semibold">There are {membersData?.data?.searchCount} member(s)</p>
            </CardInner>
          ) : null}
        </Card>
      </Block>
      <Block></Block>
    </div>
  );
};

export default AgentTeamView;
