import React from 'react';
import { Input } from 'reactstrap';
import { emailRegex } from '../../../utils/envConfig';

// const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export default function EmailInput(props) {
    return (
        <Input
            {...props}
            onChange={(e) => {
                const isValid = e.target.value?.length
                    ? emailRegex.test(e.target.value)
                    : true;

                props.onChange(e);
                props?.onError &&
                    props.onError(isValid ? '' : 'Invalid email address');
            }}
        />
    );
}
