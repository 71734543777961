import { useMemo } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import dealQueries from "../../queries/dealQueries";
import ProfileDealsView from "./ProfileDealsView";
import { useQuery } from "react-query";
/**
 * @typedef {Object} Props
 * @property {User} user - The user information to fetch deals for.
 */

const THIRTY_MINUTES = 30 * 60 * 1000;
/**
 * Container component for managing deals table's state.
 * @param {Props} props - The properties object.
 * @returns {JSX.Element} - The rendered container component.
 */
const ProfileDealsUserContainer = ({ user }) => {
  const axios = useAxiosPrivate();
  const userId = useMemo(() => user?.id ?? user?._id, [user]);

  const { queryKey, queryFn: getDeals } = dealQueries.getMostRecentByUser(axios, userId, 5);

  const { data, error, isLoading, isError } = useQuery(
    queryKey,
    getDeals,
    {
      staleTime: THIRTY_MINUTES,
      refetchInterval: THIRTY_MINUTES,
      refetchOnWindowFocus: false,
      enabled: !!userId
    }
  )
  return <ProfileDealsView dealsData={{ data, error, isLoading, isError }} />;
};

export default ProfileDealsUserContainer;
