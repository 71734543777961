import React, { useEffect, useState } from "react";
import { defaultUserImage, getImage } from "../../../utils/envConfig";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

export default function DealClosedByAgentCard({
    count,
    photo,
    amount,
    index,
    countText,
    title,
    subText,
    onClick = (e) => {},
}) {
    const [image, setImage] = useState(defaultUserImage);

    const axios = useAxiosPrivate();

    const fetchImage = async () => {
        try {
            if (!photo) {
                return;
            }

            const image = await getImage(photo, axios);
            setImage(image?.signedRequest);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        photo && fetchImage();
    }, []);

    return (
        <div className="agent-deal-closed-card" onClick={onClick}>
            <div className="flex">
                {/* <div className="me-2">{index}.</div> */}
                <div className="img-cont border">
                    <img src={image} alt="Agent" className="img" />
                </div>
            </div>

            <div className="content-cont">
                <div className="title">{title}</div>
                <small>{subText}</small>
            </div>

            <div className="text-end">
                <div className="title">
                    {count} {countText}
                </div>
                <small>{amount}</small>
            </div>
        </div>
    );
}
