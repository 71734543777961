import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { user } from './user';
import { property } from './property';
import { deal } from './deal';
import { feeds } from './feeds';
import { agencies } from './agencies';
import { offplans } from './offplans';
import { communityTeam } from './community-teams';
import { propertyCard } from './property-cards';
import { settings } from './settings';
import { pbx } from './pbx';

import { notification } from './notifications';
// holidays
import { staffHolidays } from './staffHolidays';
import { dnd } from './dnd';
import { leadSources } from './lead-source';


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user']
};
const rootReducer = combineReducers({
    user,
    property,
    deal,
    feeds,
    agencies,
    offplans,
    communityTeam,
    propertyCard,
    settings,
    notification,
    staffHolidays,
    pbx,
    dnd,
    leadSources,
});

export default persistReducer(persistConfig, rootReducer);
