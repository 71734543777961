import React, { useEffect, useState } from "react";
import { BlockHead, BlockHeadContent, BlockTitle, Icon, TooltipComponent } from "../../../components/Component";
import { Button, Card } from "reactstrap";
import { useLoadScript } from "@react-google-maps/api";
import Map from "../../../components/miscellaneous/Map";
import { useSelector } from "react-redux";
import { newRoles } from "../../../utils/envConfig";

const LocationInfo = ( { property, setEditLocation = null } ) =>
{
  const authuser = useSelector( ( state ) => state?.user?.loggedInUser );

  const { isLoaded } = useLoadScript( {
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
  } );
  const [ pos, setPos ] = useState( {
    lat:  null,
    lng:  null,
  } );

  useEffect(() => {
    // Update pos state based on property values
    setPos({
      lat: Number(property?.lat),
      lng:  Number(property?.lng),
    });
  }, [property]);

  const mapStyles = {
    height: "100%",
    width: "100%",
  };
  return (
    <>
      <BlockHead>
        <BlockHeadContent>
          <BlockTitle tag="h5" className={"m-0"}>
            Location{' '}
            <Button
              onClick={(ev) => {
                ev.preventDefault();
                if (authuser?.role && authuser.role !== newRoles?.Agent) {
                  setEditLocation(true);
                }
              }}
              color="transparent"
              className="text-primary mx-0 px-0"
            >
             <TooltipComponent
              containerClassName={"bg-transparent"}
              direction={"top"}
              icon={"edit"}
              id={`edit-property-${property?.id}-location`}
              text={"Edit Location"}
             />
            </Button>
          </BlockTitle>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered">
        <div
          className="card-content"
          style={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <div className="data-list-wrapper" style={{ flex: '2' }}>
            <ul className="data-list is-compact" style={{ width: '95%' }}>
              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">
                    {' '}
                    <span>Community</span>
                  </div>
                  <div className="data-value" style={{ maxWidth: '200px' }}>
                    {property?.community?.community}
                  </div>
                </div>
              </li>
              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">
                    {' '}
                    <span> Sub Community</span>
                  </div>
                  <div className="data-value" style={{ maxWidth: '200px' }}>
                    {property?.subCommunity}
                  </div>
                </div>
              </li>
              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">
                    {' '}
                    <span> Street</span>
                  </div>
                  <div className="data-value" style={{ maxWidth: '200px' }}>
                    {property?.street}
                  </div>
                </div>
              </li>
              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">
                    {' '}
                    <span> Building Name</span>
                  </div>
                  <div className="data-value" style={{ maxWidth: '200px' }}>
                    {property?.buildingName}
                  </div>
                </div>
              </li>

              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">
                    {' '}
                    <span>Title Deed No</span>
                  </div>
                  <div className="data-value" style={{ maxWidth: '200px' }}>
                    {property?.unitReraNo}
                  </div>
                </div>
              </li>

              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">
                    {' '}
                    <span>Emirate</span>
                  </div>
                  <div className="data-value" style={{ maxWidth: '200px' }}>
                    Dubai
                  </div>
                </div>
              </li>
              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">
                    {' '}
                    <span>Makani No</span>
                  </div>
                  <div className="data-value" style={{ maxWidth: '200px' }}>
                    {property?.makaniNo}
                  </div>
                </div>
              </li>
              <li className="data-item">
                <div className="data-col">
                  <div className="data-label">
                    {' '}
                    <span>Dewa No</span>
                  </div>
                  <div className="data-value">{property?.dewaNo}</div>
                </div>
              </li>
            </ul>
          </div>
          <div className="map-wrapper" style={{ flex: '2' }}>
            {isLoaded && pos && property && (
              <Map pos={pos} handleClickedMap={null} mapStyles={mapStyles} />
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default LocationInfo;
