import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Card } from "reactstrap";
import { toast } from "react-toastify";
import {
    Button,
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Row,
    Col,
    PreviewCard,
} from "../../components/Component";
import { Link, useParams } from "react-router-dom";
import Form from "./components/forms/Form";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const EditProperty = () => {
    const { id } = useParams();
    // State Variables
    const [property, setProperty] = useState("");
    const [propertyId, setPropertyId] = useState("");
    const axios = useAxiosPrivate()

    useEffect(() => {
        async function fetchProperty() {
            try {
                await axios
                    .get(`/propList/${id}`)
                    .then((property) => {
                        setPropertyId(property.data.id);
                        let amIds = property.data.amenityIds.map(
                            (am) => am._id
                        );
                        setProperty({ ...property.data, amenityIds: amIds });
                    })
                    .catch((err) => {
                        toast.error("Property not available !");
                    });
            } catch (err) {
                // console.log(err)
                toast.error("Server error. Try Again !");
            }
        }

        fetchProperty();
    }, [id]);

    return (
        <React.Fragment>
            <Head title="Edit Property"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween className="g-3">
                        <BlockHeadContent>
                            <Link to={"/property-list"}>
                                <Button
                                    color="light"
                                    outline
                                    className="bg-white d-none d-sm-inline-flex"
                                >
                                    <Icon name="arrow-left"></Icon>
                                    <span>Back</span>
                                </Button>
                                <Button
                                    color="light"
                                    outline
                                    className="btn-icon bg-white d-inline-flex d-sm-none "
                                >
                                    <Icon name="arrow-left"></Icon>
                                </Button>
                            </Link>
                            <BlockTitle page className="mt-4">
                                Edit Property
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Row className="gy-5">
                        <Col lg="12">
                            <Card className="card-bordered">
                                <PreviewCard>
                                    <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                                        {property && (
                                            <Form
                                                property={property}
                                                type="edit"
                                            />
                                        )}
                                        {/* <Steps config={ config }>
                        <Step component={ PersonalForm } />
                        <Step component={ UserSettings } />
                        <Step component={ PaymentInfo } />
                        <Step component={ Success } />
                        <Step component={ Preview } />
                      </Steps> */}
                                    </div>
                                </PreviewCard>
                            </Card>
                        </Col>
                    </Row>
                </Block>
            </Content>
        </React.Fragment>
    );
};
export default EditProperty;
