import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { Icon, UserAvatar } from "../../../../components/Component";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { removeCommunityTeamMember } from "../../../../redux/actions/community-teams";
import { fetchAgentByUserID } from "../../../../redux/actions/user";
import { getImage } from "../../../../utils/envConfig";
import { findUpper } from "../../../../utils/Utils";
import DndFlagComponent from "../../../components/dnd/dndFlag";

const AgentCard = ({ user, communityTeamId, refreshFn, isAdmin }) => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const [confirmRemoveModal, setConfirmRemoveModal] = useState(false);
  const toggleConfirmModal = () => setConfirmRemoveModal(!confirmRemoveModal);
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const { error } = useSelector((state) => state?.communityTeam);

  const removeMember = async () => {
    const payload = {
      memberId: user?.id,
    };
    dispatch(removeCommunityTeamMember(
      axios,
      communityTeamId,
      payload,
      () => refreshFn(),
      () => {
        toast.success("Agent removed successfully!");
        toggleConfirmModal();
      }
    ));
  };

  useEffect(() => {
    if (error) {
      toast.error(error.message ?? "Error occurred while removing member.");
    }
  }, [error]);

  useEffect(() => {
    async function fetchImage() {
      try {
        setLoading(true);
        const resData = await getImage(user?.photo ?? "", axios);
        const s3url = resData.signedRequest;
        if (s3url) {
          setAvatar(s3url);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    dispatch(fetchAgentByUserID(axios, user?.id ?? user?._id))
    fetchImage();
  }, [axios, dispatch, user]);

  return (
    <>
      <div className="card" style={{ minWidth: "17rem" }}>
        <div className="d-flex justify-content-end mt-2 px-2">
          {isAdmin ? <UncontrolledDropdown>
            <DropdownToggle
              tag="a"
              href="toggle"
              onClick={(ev) => ev.preventDefault()}
              className="btn btn-icon btn-sm btn-trigger dropdown-toggle me-n1"
            >
              <Icon name="more-h"></Icon>
            </DropdownToggle>
            <DropdownMenu>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem
                    tag="a"
                    href="#dropDownUnlink"
                    onClick={toggleConfirmModal}
                  >
                    <Icon name="cross"></Icon>
                    <span>Remove member</span>
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown> : null}
        </div>
        <div className="card-inner">
          <div className="team">
            <div className="user-card user-card-s2">
                {loading ? <Spinner /> : <UserAvatar
                  className={`lg`}
                  text={findUpper(user?.first_name)}
                  image={avatar}
                ></UserAvatar>}
              <div className="text-nowrap user-info">
                <h6>{`${user?.first_name} ${user?.last_name}`}</h6>
                {user?.role_duplicate?.includes("Agent") ? (
                  <span className="sub-text">Agent</span>
                ) : null}
              </div>
            </div>
            <ul className="d-flex flex-column align-items-center team-info">
              <li>
              <li>
              {user?.DndStatus?
                <DndFlagComponent className={"d-flex flex-column align-items-center justify-content-center p-2"} lead_status={user?.lead_status} phoneNumber={user?.phone}/>: <span>{user?.phone ?? ""}</span>}
              </li>
              </li>
              <li>
                <span>{user?.email}</span>
              </li>
            </ul>
            <div className="team-view">
              {" "}
              <a
                href={`/user-profile/${user?.id}/user-profile-setting`}
                className="btn-block btn btn-dim btn-primary"
              >
                <span>View Details</span>
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={confirmRemoveModal}
        toggleFn={toggleConfirmModal}
        size="sm"
        title={"Confirm Removal"}
        isDeletion={true}
        actionFn={removeMember}
      >
        <p>
          Are you sure you want to remove{" "}
          <span className="fw-bold">{user?.first_name}</span> from the team?
        </p>
      </ConfirmationModal>
    </>
  );
};

export default AgentCard;
