import React from "react";

import { useEffect,useRef } from "react";
const mapurl = "https://maps.googleapis.com/maps/api/js";

function loadAsyncScript(src) {
    return new Promise((ressolve) => {
        const script = document.createElement("script");

        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src,
        });

        script.addEventListener("load", (e) => ressolve(script));

        document.head.appendChild(script);
    });
}

export default function CustomMap({
    community = "",
    setCommunity = (object) => null,
    inputProps,
    containerProps,
    apiKey = undefined,
}) {
    const mapR = useRef(null);
    const autoCompleteRef = useRef(null);

    const initMapScript = () => {
        if (window.google) {
            return Promise.resolve();
        }

        if (!apiKey) {
            return alert("cannot load maps api. Please provide maps api key");
        }

        const mapsKey = apiKey;

        const src = `${mapurl}?key=${mapsKey}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    };

    useEffect(() => {
        initMapScript().then(() => {
            const map = new window.google.maps.Map(mapR.current, {
                center: community?.position ?? {
                    lat: 25.276987,
                    lng: 55.296249,
                },
                zoom: 12,
            });

            map?.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
                autoCompleteRef.current
            );

            const autoComplete = new window.google.maps.places.Autocomplete(
                autoCompleteRef.current
            );

            const infoWindow = new window.google.maps.InfoWindow();

            const marker = new window.google.maps.Marker({
                map: map,
                anchorPoint: new window.google.maps.Point(0, -29),
                position: community.position ?? {
                    lat: 25.276987,
                    lng: 55.296249,
                },
                // icon: iconImage,
            });

            autoComplete.bindTo("bounds", map);

            autoComplete.setFields([
                "address_component",
                "geometry",
                "place_id",
                "name",
            ]);

            autoComplete.addListener("place_changed", () => {
                infoWindow.close();
                marker.setVisible(false);

                const place = autoComplete.getPlace();

                // const placeId = place?.place_id;

                // if (placeId) {
                //     geocoder.geocode({ placeId }, (res, status) => {
                //         console.log(status);
                //         console.log(res[0]?.geometry?.location?.toJSON());
                //         map.setCenter(res[0]?.geometry?.location);
                //     });
                // }

                // console.log(place.place_id);

                if (!place.geometry) {
                    return alert("Please click on a community");
                }

                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);

                    map.setZoom(15);
                }

                const communityPosition = place.geometry.location.toJSON();
                const communityName = place?.name;

                const emirateName =
                    place.address_components[
                        place.address_components.length - 2
                    ]?.long_name;

                setCommunity({
                    emirate: emirateName,
                    name: communityName,
                    position: communityPosition,
                });

                marker.setIcon({
                    // url: iconImage,
                    size: new window.google.maps.Size(71, 71),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(17, 34),
                    scaledSize: new window.google.maps.Size(35, 35),
                });

                marker.setPosition(place.geometry.location);
                marker.setVisible(true);

                infoWindow.setContent(place.name);

                infoWindow.open(map, marker);
            });

            // map.addListener("click", (e) => {
            //     console.log(e?.latLng?.toJSON());
            // });
        });
    }, [autoCompleteRef, mapR]);

    return (
        <div 
        style={{width:"100%",height:"500px"}}
         ref={mapR} {...containerProps}>
            <input
             className="form-control"
             style={{margin:"10px",width:"40%"}}
                type="text"
                placeholder="Search community"
                {...inputProps}
                ref={autoCompleteRef}
                value={community?.name}
                onChange={(e) =>
                    setCommunity({
                        emirate: community?.emirate,
                        name: e.target.value,
                        position: community?.position,
                    })
                }
            />
        </div>
    );
}
