import * as types from '../types'

const initialState = {
    properties:[],
    property:null,
    propertiesAdmin:[],
    dealOfWeek:[],
    featured:[],
    amenities:[],
    communities:[],
    propertyTypes:[],
    categories:[],
    amCategories:[],
    buildings:[],
    building:null,
    status:null,
    ownerProperties : {},
    xmlString: '',
    loading: false,
    buildingsLoading: false,
    createBuildingLoading: false,
    communitiesLoading: false,
    amenitiesLoading: false,
    propTypesLoading: false,
    error: null
}

export const property = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PROPERTIES:
            return {
                ...state,
                properties: action.payload,
                loading: false,
                error: null
            }
        case types.GET_PROPERTIES_ADMIN:
            return {
                ...state,
                propertiesAdmin: action.payload,
                loading: false,
                error: null
            }
        case types.GET_PROPERTY:
            return {
                ...state,
                property: action.payload,
                loading: false,
                error: null
            }
            case types.GET_FEATURED_PROPERTIES:
                return {
                    ...state,
                    featured: action.payload,
                    loading: false,
                    error: null
                }
                case types.GET_DEAL_OF_THE_WEEK:
                    return {
                        ...state,
                        dealOfWeek: action.payload,
                        loading: false,
                        error: null
                    }
        case types.GET_AMENITIES:
            return {
                ...state,
                amenities: action.payload,
                loading: false,
                error: null
            }
        case types.GET_AMENITIES_LIST_STARTED:
            return {
                ...state,
                amenitiesLoading: true,
                error: null
            }
        case types.GET_AMENITIES_LIST:
            return {
                ...state,
                amenities: action.payload,
                amenitiesLoading: true,
                error: null
            }
        case types.GET_AMENITIES_LIST_FAILED:
            return {
                ...state,
                amenitiesLoading: true,
                error: action.error
            }
        case types.GET_COMMUNITIES_LIST_STARTED:
            return {
                ...state,
                communitiesLoading: true,
                error: null
            }
        case types.GET_COMMUNITIES_LIST:
            return {
                ...state,
                communities: action.payload,
                communitiesLoading: false,
                error: null
            }
        case types.GET_COMMUNITIES_LIST_FAILED:
            return {
                ...state,
                communitiesLoading: false,
                error: action.error
            }
        case types.GET_PROP_TYPES:
            return {
                ...state,
                propertyTypes: action.payload,
                loading: false,
                error: null
            }
        case types.GET_PROP_TYPES_STARTED:
            return {
                ...state,
                propTypesLoading: true,
                error: null
            }
        case types.GET_PROP_TYPES_LIST:
            return {
                ...state,
                propertyTypes: action.payload,
                propTypesLoading: false,
                error: null
            }
        case types.GET_PROP_TYPES_FAILED:
            return {
                ...state,
                propTypesLoading: false,
                error: action.error
            }
        case types.GET_CATEGORY:
            return {
                ...state,
                categories: action.payload,
                loading: false,
                error: null
            }
            case types.GET_AM_CATEGORY:
                return {
                    ...state,
                    amCategories: action.payload,
                    loading: false,
                    error: null
                }
            case types.GET_BUILDING_STARTED:
                return {
                    ...state,
                    buildingsLoading: true,
                    error: null
                }
            case types.GET_BUILDING:
                return {
                    ...state,
                    buildings: action.payload,
                    buildingsLoading: false,
                    error: null
                }
            case types.GET_BUILDING_FAILED:
                return {
                    ...state,
                    error: action.error,
                    buildingsLoading: false,
                }
            case types.GET_BUILDINGS_STARTED:
                return {
                    ...state,
                    buildingsLoading: true,
                    error: null
                };
            case types.GET_BUILDINGS:
                return {
                    ...state,
                    buildings: action.payload,
                    loading: false,
                    buildingsLoading: false,
                    error: null,
                }
                case types.GET_BUILDINGS_FAILED:
                    return {
                    ...state,
                    loading: false,
                    buildingsLoading: false,
                    error: action.error
                }
            case types.GET_FILTERED_COMMUNITIES_STARTED:
                return {
                    ...state,
                    loading: false,
                    error: null,
                };
            case types.GET_FILTERED_COMMUNITIES:
                return {
                    ...state,
                    communities: action.payload,
                    loading: false,
                    error: null,
                };
            case types.GET_FILTERED_COMMUNITIES_FAILED:
                return {
                    ...state,
                    error: action.error,
                    loading: false
                };
            case types.GET_FILTERED_AMENITIES_STARTED:
                return {
                    ...state,
                    amenitiesLoading: false,
                    error: null,
                };
            case types.GET_FILTERED_AMENITIES:
                return {
                    ...state,
                    amenities: action.payload,
                    amenitiesLoading: false,
                    error: null,
                };
            case types.GET_FILTERED_AMENITIES_FAILED:
                return {
                    ...state,
                    error: action.error,
                    amenitiesLoading: false
                };
            case types.CREATE_BUILDING_STARTED:
                return {
                    ...state,
                    createBuildingLoading: true,
                    error: null
                }
            case types.CREATE_BUILDING:
                return {
                    ...state,
                    building: action.payload,
                    status: action.status,
                    createBuildingLoading: false,
                    error: null
                }
            case types.CREATE_BUILDING_FAILED:
                return {
                    ...state,
                    createBuildingLoading: false,
                    error: action.error
                }

            case types.GET_OWNER_PROPERTIES:
                return {
                    ...state,
                    ownerProperties: {
                        ...state?.ownerProperties,
                        [action?.payload?.key] : action?.payload?.data
                    },
                    loading: false,
                    error: null,
                };
            case types.GET_XML_STARTED:
                return {
                    ...state,
                    loading: true,
                    error: null,
                };
            case types.GET_XML:
                return {
                    ...state,
                    xmlString: action.payload,
                    loading: false,
                    error: null,
                };
            case types.GET_XML_FAILED:
                return {
                    ...state,
                    error: action.error,
                    loading: false
                };
            case types.GET_SINGLE_XML_STARTED:
                return {
                    ...state,
                    loading: true,
                    error: null,
                };
            case types.GET_SINGLE_XML:
                return {
                    ...state,
                    xmlString: action.payload,
                    loading: false,
                    error: null,
                };
            case types.GET_SINGLE_XML_FAILED:
                return {
                    ...state,
                    error: action.error,
                    loading: false
                };

        default:
            return state
    }
}