const GrayOutOverlay = () => (
  <div
    style={{
      position: "absolute",
      width: "100%",
      height: "100%",
      background: `
        repeating-linear-gradient(
          315deg,
          rgba(21, 21, 21, 0.05),
          rgba(21, 21, 21, 0.05) 8px,
          transparent 6px,
          transparent 9px
        ),
        #fbfbfb6b`,
      zIndex: 1,
      pointerEvents: "none",
    }}
  ></div>
);

const GreenOverlay = () => (
  <div
    style={{
      position: "absolute",
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(180deg, rgba(255,255,255,0) 70%, rgba(189,247,232,0.4) 100%)",
      zIndex: 10,
      pointerEvents: "none",
    }}
  ></div>
);

const DarkenedOverlay = () => (
  <div
    style={{
      position: "absolute",
      width: "100%",
      height: "100%",
      background:
        "rgb(151 151 151 / 21%)",
      zIndex: 10,
      pointerEvents: "none",
    }}
  ></div>
);

export { GreenOverlay, GrayOutOverlay, DarkenedOverlay };
