import React, { useEffect, useMemo, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import {
  Block,
  BlockDes,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  Row,
  Col,
  RSelect,
  PaginationComponent,
} from '../../../components/Component';
import {
  Button,
  Modal,
  ModalBody,
  Input,
} from 'reactstrap';
import {
  sources
} from '../../../utils/envConfig';
import 'react-phone-number-input/style.css';
import { useSelector } from 'react-redux';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useDispatch } from 'react-redux';
import { fetchDnd } from '../../../redux/actions/dnd';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import ConfirmModal from '../../../components/miscellaneous/ConfirmModal';

const DndLeadsListTable = ( { title} ) =>
  {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const authuser = useSelector((state) => state?.user?.loggedInUser);

  // State Variables
  const [addDndModalOpen, setAddDndModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [comment, setComment] = useState('');
  const [excludeSource, setExcludeSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [dndNumber, setDndNumber] = useState("");
  const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const url = '/dnd/dnd-phone-numbers';
            const params = {
                page,
                limit,
                query: searchText,
            };
            try {
                const response = await axios.get(url, { params });
                dispatch(fetchDnd(response.data)); 
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [dispatch, page, limit, searchText,loading]);

    const handleSearchSubmit = (event) => {
        setSearchText(event.target.value.replace(/\+/g, '').trim());
    };


    const dndList = useSelector((state) => state?.dnd?.dndNumbers);

    const filteredData = dndList?.data;
    const filteredCountData = useMemo(() => dndList?.filtered, [dndList]);

    const handlePerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handleModal = (e, phoneNumber, name) => {
        e.preventDefault();
        setDndNumber(phoneNumber);
        setShowModal(true);
    };

    // Input Validation
    const validate = (phoneNumber) => {
        if (!phoneNumber) {
            toast.error("Enter Phone Number");
            return false;
        } else {
            return true;
        }
    };
    // Add Number in DB
    const addDndNumber = async (e) => {
        setAddLoading(true);
        e.preventDefault();
        if (validate(phoneNumber)) {
            const formData = {
                phoneNumber: phoneNumber,
                sourceExcludedOn: excludeSource ?? [], 
                comment: comment ?? '', 
                createdBy: {
                    source: sources.albaHomes, 
                    userId: authuser?.id 
                }
            };
            try {
                await axios.post(`/dnd/dnd-phone-numbers`, formData);
                toast.success("Successfully added DND Number");
                resetFormFields();
                setAddLoading(false);
            } catch (error) {
                if (error.response) {
                    const { data } = error.response;
                    toast.error(data?.message || 'Server error. Please try again.');
                } else if (error.request) {
                    toast.error('No response received from the server. Please try again.');
                } else {
                    toast.error('An unexpected error occurred. Please try again later.');
                }
            }            
        }
        setAddDndModalOpen(false);
        setAddLoading(false);
    };

    const resetFormFields = () => {
        setPhoneNumber('');
        setExcludeSource([]);
        setComment('');
    };

    // Delete DND Number and change dndstatus in user collection
    const submitDel = async (e) => {
        e.preventDefault();
        const deleteData = {
            phoneNumber: dndNumber,
        };
        try {
            await axios.delete('/dnd/dnd-phone-numbers/deleteDnd', { data: deleteData });
            toast.success("Successfully deleted DND Number");
        } catch (error) {
            if (error.response) {
                const { data } = error.response;
                toast.error(data?.message || 'Server error. Please try again.');
            } else if (error.request) {
                toast.error('No response received from the server. Please try again.');
            } else {
                toast.error('An unexpected error occurred. Please try again later.');
            }
        }
        setShowModal(false);
        setLoading(!loading);
    };

    const closeModal = () => {
        setShowModal(false);
    };

  return (
    <React.Fragment>
    <Head title="DND Numbers"></Head>
    <Content>
      <BlockHead size="sm">
        <BlockBetween>
            <BlockHeadContent>
                <BlockTitle>{title}</BlockTitle>
                <BlockDes className="text-soft">
                    <p>You have total {filteredCountData} {'Dnd Numbers'}.</p>
                </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                    <div className="toggle-expand-content">
                        <ul className="nk-block-tools g-3">
                            <li className="nk-block-tools-opt">
                                <Button
                                    className="toggle d-none d-md-inline-flex"
                                    color="primary"
                                    onClick={() => {
                                        setAddDndModalOpen(true);
                                    }}
                                >
                                    <Icon name="plus"></Icon>
                                    <span>Add Number</span>
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
            </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
        <div className="my-3 d-flex flex-row-reverse align-items-center justify-content-between">
            {/* Entries per page config */}
            <div className="d-flex align-items-center">
                <span className="d-none d-sm-inline-block me-2">Show</span>
                <div className="form-control-select">
                    <select
                        name="DataTables_Table_0_length"
                        className="custom-select custom-select-sm form-control form-control-sm"
                        onChange={handlePerPageChange}
                        value={limit}
                    >
                        <option value="1">1</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                </div>
            </div>
            {/* Search bar */}
            <div className="d-flex justify-content-center align-items-center">
                <Input placeholder="Search DND Number..." type="text" name="search" style={{ minWidth: "20rem" }} onChange={handleSearchSubmit}/>
            </div>
        </div>

        <Block>
            {filteredData && (
                <div className="nk-tb-list is-separate is-medium mb-3">
                    <DataTableHead className="nk-tb-item">
                        <DataTableRow>
                            <span className="sub-text">
                            Phone Number
                            </span>
                        </DataTableRow>
                        <DataTableRow>
                            <span className="sub-text">
                            Comment
                            </span>
                        </DataTableRow>
                        <DataTableRow>
                            <span className="sub-text">Delete</span>
                        </DataTableRow>
                    </DataTableHead>
                    {Array.isArray(filteredData) && filteredData.length > 0 ? (
                          filteredData.map((item, index) => (
                              <DataTableItem key={index}>
                                  <DataTableRow>
                                  {item?.phoneNumber}
                                  </DataTableRow>
                                  <DataTableRow>
                                  {item?.comment ?? '-'}
                                  </DataTableRow>
                                  <DataTableRow>
                                  <a className="btn btn-icon"
                                     onClick={(e) =>
                                        handleModal(
                                            e,
                                            item?.phoneNumber,
                                            item?.phoneNumber
                                        )
                                    }>
                                        <Icon
                                            name="trash"
                                            style={{
                                                color: "#e85347",
                                                fontSize: "1.3rem",
                                            }}
                                        />
                                    </a>
                                  </DataTableRow>
                              </DataTableItem>
                          ))
                      ) : (
                        <div>No results available</div>
                      )}

                    {Array.isArray(filteredData) && filteredCountData > 0 ? 
                                    <PaginationComponent
                                        noDown
                                        currentPage={page}
                                        paginate={(page) => {
                                            setPage(page);
                                        }}
                                        itemPerPage={limit}
                                        totalItems={filteredCountData}
                                    /> 
                                    : null
                                }
                </div>
            )}
        </Block>
        <Modal
          isOpen={addDndModalOpen}
          toggle={() => setAddDndModalOpen(false)}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
              <a href="#cancel" className="close">
                  {" "}
                  <Icon
                      name="cross-sm"
                      onClick={(ev) => {
                          ev.preventDefault();
                          setAddDndModalOpen(false);
                      }}
                  ></Icon>
              </a>
              <div className="p-2">
                  <h5 className="title">Add DND Number</h5>
                  <div className="mt-4">
                      {/* <form onSubmit={ handleSubmit( onFormSubmit ) }> */}
                      <form
                          onSubmit={(e) =>
                              addDndNumber(e)
                          }
                      >
                          <Row className="g-3">
                          <Col sm="6">
                            <div className="form-group">
                            <label className="form-label">Phone *</label>
                            <div className="input-group">
                                <PhoneInput
                                className="phoneInput w-100"
                                defaultCountry="AE"
                                name="phone"
                                placeholder="Phone"
                                value={phoneNumber}
                                onChange={(value) => setPhoneNumber(value)}
                                />
                            </div>
                            </div>
                        </Col>
                              <Col md="6">
                                  <div className="form-group">
                                      <label
                                          className="form-label"
                                          htmlFor="customer"
                                      >
                                          Exclude blocking on 
                                      </label>
                                      <div className="form-control-wrap">
                                      <RSelect
                                            options={Object.values(sources).map((x) => ({
                                            label: x,
                                            value: x,
                                            }))}
                                            placeholder="Choose Source"
                                            isMulti
                                            onChange={(selectedOptions) => {
                                                const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                setExcludeSource(selectedValues);
                                            }}
                                        />
                                      </div>
                                  </div>
                              </Col>
                              <Col md="12">
                                  <div className="form-group">
                                      <label
                                          className="form-label"
                                          htmlFor="customer"
                                      >
                                          Comment (If any)
                                      </label>
                                      <div className="form-control-wrap">
                                          <input
                                              type="text"
                                              className="form-control"
                                              name="amenity"
                                              value={comment}
                                              onChange={(e) =>
                                                setComment(
                                                      e.target
                                                          .value
                                                  )
                                              }
                                          />
                                      </div>
                                  </div>
                              </Col>
                              <Col size="12" className="d-flex justify-content-end">
                                  <Button
                                      color="primary"
                                      type="submit"
                                  >
                                      {/* <Icon className="plus"></Icon> */}
                                      <span>
                                      {addLoading
                                              ? "Loading.."
                                              : "Add"}
                                      </span>
                                  </Button>
                              </Col>
                          </Row>
                      </form>
                  </div>
              </div>
          </ModalBody>
        </Modal>
        {showModal ? (
                        <ConfirmModal
                            msg={"Delete "}
                            name={"DND Number: " + dndNumber}
                            open={showModal}
                            handleClick={handleModal}
                            handleConfirm={submitDel}
                            handlecloseModal={closeModal}
                            action='Delete'
                        />
                    ) : null}
</Content>
</React.Fragment>
  );
};

export default DndLeadsListTable;
