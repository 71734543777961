import React, { useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import RSelectAsync from './select/ReactSelectAsync'

export default function SelectAgent({ onSelectAgent = (agent) => { }, defaultOptions = [], selectedAgent, isClearable, ...props }) {
    const axios = useAxiosPrivate()

    const [searchText, setSearchText] = useState('')
    const [hasSearched, setHasSearched] = useState(false)

    const fetchAgents = () => axios.get(`/agent/filter`, {
        params: {
            filter: {
                search: searchText,
                active: 'all'
            }
        }
    })
        .then(res => {

            return res.data?.agents?.map(item => ({
                ...item,
                value: item?.id ?? item?._id,
                label: `${item?.user?.first_name ?? ''} ${item?.user?.last_name ?? ''}`
            }))
        })
        .catch(err => console.error(err))

    const handleInputChange = (value) => {
      setSearchText(value)
      if (value) {
          setHasSearched(true)
      }
    }

    const noOptionsMessage = ({ inputValue }) => {
        if (!hasSearched) {
            return 'Please type to look for agents'
        }
        return inputValue ? 'No matching agents found' : 'Please enter a search term'
    }

    return (
        <RSelectAsync
            loadOptionsFn={fetchAgents}
            onInputChange={handleInputChange}
            onChange={onSelectAgent}
            placeholder='Select Agent'
            className='status-div'
            defaultOptions={defaultOptions}
            value={selectedAgent}
            isClearable={isClearable}
            noOptionsMessage={noOptionsMessage}
            {...props}
        />
    )
}
