/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import ErrorHandler from "../../error-handlers/axios/defaultErrorHandler";

/**
 * @description Deletes a transaction specified by its ID
 * @param {Axios} axiosInstance - the axios instance for making the HTTP request.
 * @param {string} transactionId - the transaction ID being updated.
 * @returns {Promise<import('../../global-types/deal-transactions').TransactionData>} API Response.
 */
export const deleteDealTransaction = async (axiosInstance, transactionId) => {
  try {
    const res = await axiosInstance.delete(
      `/deals/transactions/${transactionId}`
    );
    return res?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};
