export const defaultPlaceDistances = [
  {
    place: "Metro/Bus Stop",
    distance: 0,
  },
  {
    place: "Shopping",
    distance: 0,
  },
  {
    place: "School",
    distance: 0,
  },
  {
    place: "Beach",
    distance: 0,
  },
  {
    place: "Mosques",
    distance: 0,
  },
  {
    place: "Supermarkets",
    distance: 0,
  },
  {
    place: "Pharmacy",
    distance: 0,
  },
  {
    place: "Park",
    distance: 0,
  },
];
