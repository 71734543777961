import { GoogleMap, Marker } from '@react-google-maps/api';



const Map = ({pos,handleClickedMap,mapStyles}) => {
  return (
    <GoogleMap
    mapContainerStyle={mapStyles}
    center={pos}
    zoom={14}
    onClick={handleClickedMap}
  >    
    <Marker position={pos} />
  </GoogleMap>
  );
};

export default Map;
