import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchCommunitiesList } from "../../../../../redux/actions/property";
import { fetchAllCommunityTeams } from "../../../../../redux/actions/community-teams";
import { newRoles } from "../../../../../utils/envConfig";
import { loggedInUserSelector } from "../../../../../redux/selectors/user";
import { communitiesSelector } from "../../../../../redux/selectors/property";
import { communityTeamsSelector } from "../../../../../redux/selectors/community-teams";

const withExplorerCommunityLogic = (WrappedComponent) => {
  return ({ multiSelect, onChangeCallback, selected, axiosInstance, dispatch, styles, ...props }) => {
    const authUser = useSelector(loggedInUserSelector);

    const { communities, isLoading: communitiesLoading } = useSelector(communitiesSelector);

    // const { communityTeams: { results }, communityTeamsLoading } = useSelector((state) => state.communityTeam);
    const teamsData = useSelector(communityTeamsSelector);

    const communities2 = teamsData?.communityTeams?.results
      ?.map((item) => item?.communities)
      ?.flat()
      ?.map((item) => ({
        ...item,
        id: item?._id ?? item?.id,
      }));

    let communityFilterOptions =
      authUser?.role === newRoles.Agent
        ? communities2?.map((item) => ({
            ...item,
            label: item?.community,
            value: item?.id,
          }))
        : communities?.map((comm) => ({
            ...comm,
            value: comm?.id,
            label: comm?.community,
          }));

    useEffect(() => {
      const fetchAndSetCommunities = async () => {
        if (!communities || !communities?.length) {
          await dispatch(fetchCommunitiesList(axiosInstance));
        }
        if (!teamsData?.communityTeams?.results?.length && !teamsData?.isLoading) {
          dispatch(fetchAllCommunityTeams(axiosInstance));
        }
      };
      fetchAndSetCommunities();
    }, [axiosInstance, communities, teamsData?.isLoading, dispatch, teamsData?.communityTeams?.results?.length]);

    return (
      <WrappedComponent
        multiSelect={multiSelect}
        onChangeCallback={onChangeCallback}
        selected={selected}
        communityFilterOptions={communityFilterOptions}
        communitiesLoading={communitiesLoading}
        styles={styles}
        {...props}
      />
    );
  };
};

export default withExplorerCommunityLogic;
