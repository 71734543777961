import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
} from "reactstrap";
import { Icon } from "../../../components/Component";

const ConfirmModal = ({
    isOpen,
    proceed,
    prompt,
    cancel,
    cancelText = "Cancel",
    proceedText = "Continue",
    cancelColor = "danger",
    proceedColor = "primary",
    isLoading = false,
}) => {
    return (
        <Modal isOpen={isOpen} fade>
            <ModalHeader>
                <Icon name={"alert-fill"} />
                <span>Confirm</span>
            </ModalHeader>
            <ModalBody>{prompt}</ModalBody>
            <ModalFooter>
                <Button
                    color={proceedColor}
                    onClick={() => proceed(true)}
                    className="ml-4"
                >
                    {isLoading ? (
                        <>
                            <Spinner /> Please Wait
                        </>
                    ) : (
                        proceedText
                    )}
                </Button>
                <Button color={cancelColor} onClick={() => cancel(false)}>
                    {cancelText}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmModal;
