import PropertiyCardDetailUI from "./components/PropertyCardDetailUI";

const PropertyCardDetailPage = () => {
  return (
    <>
      <PropertiyCardDetailUI />
    </>
  );
};

export default PropertyCardDetailPage;
