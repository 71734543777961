const boxStyling = {
    width: "clamp(18rem, 22rem, 24rem)",
    position: "relative",
  };

  

  const tickStyling = {
    fontSize: "0.8rem",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
    
  };

  export { boxStyling, tickStyling }