import * as types from "../types";

export const fetchGlobalSettings = (axios) => async (dispatch) => {
  dispatch({ type: types.GET_GLOBAL_SETTINGS_STARTED }); // start loading
  try {
    const url = `/settings`;
    const res = await axios.get(url);
    dispatch({
      type: types.GET_GLOBAL_SETTINGS,
      payload: res.data?.data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_GLOBAL_SETTINGS_FAILED,
      error: error,
    });
  } finally {
    dispatch({ type: types.GET_GLOBAL_SETTINGS_FINISHED }); // stop loading
  }
};

// pass callback to run after successful update
export const updateGlobalSettings = (axios, settings, callback) => async (dispatch) => {
  dispatch({ type: types.UPDATE_GLOBAL_SETTINGS_STARTED }); // start loading
  try {
    const url = `/settings`;
    const res = await axios.patch(url, settings);
    dispatch({
        type: types.UPDATE_GLOBAL_SETTINGS,
        payload: res.data?.data,
    });
    if (callback && typeof callback === "function") {
      callback();
    }
  } catch (error) {
    dispatch({
      type: types.UPDATE_GLOBAL_SETTINGS_FAILED,
      error: error,
    });
  } finally {
    dispatch({ type: types.UPDATE_GLOBAL_SETTINGS_FINISHED }); // stop loading
  }
};
