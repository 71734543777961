import React from "react";
import uuid from "react-uuid";

export default function CardShimmer ( { numberOfCards = 3, cardSize } )
{
    return (
        <>
            { Array( numberOfCards )
                .fill( uuid() )
                .map( ( item, i ) => (
                    <div className="listing-card-shimmer bg-light" key={`${item}-${i}`} style={ {
                        height: cardSize ?? 80
                    } }></div>
                ) ) }
        </>
    );
}
