import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
    differenceInDays,
    endOfDay,
    endOfMonth,
    endOfWeek,
    startOfMonth,
    startOfWeek,
    subDays,
    subMonths
} from 'date-fns';
import { LeadSource, PropertyPaths, formatValue } from '../../utils/envConfig';

// import OffplanCard from "../OffplanCard";
// import DeveloperTrafficCard from "../DeveloperTrafficCard";
import { Badge, Col, Input, Row } from 'reactstrap';
import CardShimmer from '../../components/CardShimmer';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import {
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon
} from '../../components/Component';
import DataCard from '../../components/partials/default/DataCard';
import ListingCard from './components/ListingCard';
import DatePicker from 'react-datepicker';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import RenderChart from './components/RenderChart';
import MultiBarsBarChart from './components/MultibarsBarChart';
import LineChart from '../../components/charts/LineChart';
// import { BarChart } from "../charts";

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top'
        },
        title: {
            display: true,
            text: 'Agents Productivity Chart'
        }
    }
};

export default function OffplanMarketingDashboard() {
    const printRef = useRef();

    const axios = useAxiosPrivate();

    // dates
    const today = new Date();

    const [dayStart, setDayStart] = useState(startOfWeek(today));

    const [dayEnd, setDayEnd] = useState(endOfWeek(today));

    const [dateError, setDateError] = useState('');

    const [setSmBtn, smBtn] = useState();

    const [monthEnd, setMonthEnd] = useState(endOfMonth(today));
    const [monthStart, setMonthStart] = useState(startOfMonth(today)); //getting data for the past 30 days

    // views
    const [mostNumberOfViews, setMostNumberOfViews] = useState(null);
    const [trafficByDeveloper, setTrafficByDeveloper] = useState(null);
    const [visitorsByLeadSource, setVisitorsByLeadSource] = useState(null);

    const [offplanLeads, setOffPlanLeads] = useState(null);
    const [leadsPerLeadSource, setLeadsPerLeadSource] = useState(null);
    const [offplanViews, setOffPlanViews] = useState(null);

    // others
    const [fetching, setFetching] = useState(false);

    const getMostNumberOfViews = async () => {
        try {
            setFetching(true);

            const { data } = await axios.get(
                `/reports/properties?from_date=${monthStart}&to_date=${monthEnd}&propertyType=offPlan`
            );

            setMostNumberOfViews(data);
        } catch (err) {
            console.log(err);
        } finally {
            setFetching(false);
        }
    };

    const getTrafficByDeveloper = async () => {
        try {
            setFetching(true);

            const { data } = await axios.get(
                `/reports/offplan/leaderboardByDeveloper?from_date=${monthStart}&to_date=${monthEnd}`
            );

            setTrafficByDeveloper(data);
        } catch (err) {
            console.log(err);
        } finally {
            setFetching(false);
        }
    };

    const getDailyData = async () => {
        try {
            const offplanLeadsReq = axios.get(
                `/reports/marketing/leadsPerLeadSource?from_date=${dayStart}&to_date=${dayEnd}`
            );

            const offplanViewsReq = axios.get(
                `/reports/marketing/analyticsreport?from_date=${dayStart}&to_date=${dayEnd}&leadSource=${LeadSource.NewDevelopment}&filterPath=${PropertyPaths.offPlan}&dimension=pagePath&metric=screenPageViews`
            );

            const [{ data: leadsPerLeadSource }, { data: offplanViews }] =
                await Promise.all([offplanLeadsReq, offplanViewsReq]);

            const offplanLeadsCount = leadsPerLeadSource?.find(
                (item) => item?._id === LeadSource.NewDevelopment
            );

            setOffPlanLeads(offplanLeadsCount?.count ?? 0);
            setLeadsPerLeadSource(leadsPerLeadSource);

            setOffPlanViews(offplanViews);
        } catch (err) {
            console.log(err);
        }
    };

    const getUniqueVisitorsByLeadSource = async () => {
        try {
            const { data } = await axios.get(
                `/reports/marketing/analyticsReport?from_date=${dayStart}&to_date=${dayEnd}&dimension=sessionSource&metric=newUsers`
            );
            setVisitorsByLeadSource(
                data
                    ?.sort(
                        (a, b) =>
                            Number(b?.metricValue) - Number(a?.metricValue)
                    )
                    ?.slice(0, 10)
            );
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getMostNumberOfViews();
        getTrafficByDeveloper();
    }, [monthStart, monthEnd]);

    useEffect(() => {
        getDailyData();
        getUniqueVisitorsByLeadSource();
    }, [dayStart, dayEnd]);

    useEffect(() => {
        const timeout = dateError
            ? setTimeout(() => {
                  setDateError('');
              }, 3000)
            : undefined;

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [dateError]);

    const exportPDF = () => {
        const doc = printRef.current;

        html2canvas(doc).then((canvas) => {
            const data = canvas.toDataURL('application/pdf');
            const pdf = new jsPDF();
            pdf.addImage(data, 'pdf', 0, 0);

            pdf.save('test.pdf');
        });
    };

    return (
        <>
            <Head title="Off-plan Marketing Dashboard" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>
                                Off-plan Marketing Dashboard
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                    <div className="d-flex justify-content-end align-items-end">
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <a
                                    href="#toggle"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        setSmBtn(!smBtn);
                                    }}
                                    className="btn btn-icon btn-trigger toggle-expand me-n1"
                                >
                                    <Icon name="menu-alt-r"></Icon>
                                </a>
                                <div
                                    className={`toggle-expand-content ${
                                        smBtn ? 'expanded' : ''
                                    }`}
                                >
                                    <ul className="nk-block-tools g-1">
                                        <li>
                                            <div className="form-control-wrap">
                                                <button
                                                    className="btn bg-primary text-white m-1"
                                                    onClick={() => {
                                                        setDayStart(
                                                            startOfWeek(today)
                                                        );
                                                        setDayEnd(
                                                            endOfWeek(today)
                                                        );
                                                    }}
                                                >
                                                    Current Week
                                                </button>
                                                <button
                                                    className="btn bg-secondary text-white "
                                                    onClick={async () => {
                                                        const lastWeekEnd =
                                                            subDays(
                                                                startOfWeek(
                                                                    today
                                                                ),
                                                                1
                                                            );

                                                        setDayStart(
                                                            startOfWeek(
                                                                lastWeekEnd
                                                            )
                                                        );
                                                        setDayEnd(lastWeekEnd);
                                                    }}
                                                >
                                                    Last Week
                                                </button>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-group">
                                                <div className="form-control-wrap position-relative">
                                                    <div className="text-danger fs-sm text-center position-absolute start-50 bottom-100 translate-middle-x w-100">
                                                        {dateError}
                                                    </div>
                                                    <div className="input-daterange date-picker-range input-group">
                                                        <DatePicker
                                                            selected={dayStart}
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }} // prevent user from typing in date
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                const interval =
                                                                    differenceInDays(
                                                                        dayEnd,
                                                                        date
                                                                    );

                                                                if (
                                                                    interval >=
                                                                    7
                                                                ) {
                                                                    setDateError(
                                                                        'Interval cannot be more than 7 days'
                                                                    );
                                                                    return;
                                                                }

                                                                setDayStart(
                                                                    date
                                                                );
                                                            }}
                                                            selectsStart
                                                            startDate={dayStart}
                                                            endDate={dayEnd}
                                                            maxDate={dayEnd}
                                                            wrapperClassName="start-m"
                                                            className="form-control"
                                                        />
                                                        <div className="input-group-addon">
                                                            TO
                                                        </div>
                                                        <DatePicker
                                                            selected={dayEnd}
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }} // prevent user from typing in date
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                const interval =
                                                                    differenceInDays(
                                                                        date,
                                                                        dayStart
                                                                    );

                                                                if (
                                                                    interval >=
                                                                    7
                                                                ) {
                                                                    setDateError(
                                                                        'Interval cannot be more than 7 days'
                                                                    );
                                                                    return;
                                                                }

                                                                setDayEnd(date);
                                                            }}
                                                            startDate={dayStart}
                                                            endDate={dayEnd}
                                                            selectsEnd
                                                            minDate={dayStart}
                                                            wrapperClassName="end-m"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </div>
                </BlockHead>

                <Row className="g-gs">
                    <Col md="4">
                        <DataCard
                            amount={offplanLeads ?? 0}
                            title={'Leads From Off-plans'}
                            showComparison={false}
                        />
                    </Col>
                    <Col md="4">
                        <DataCard
                            amount={offplanViews?.metricValue ?? 0}
                            title={'Off-plan Views'}
                            showComparison={false}
                        />
                    </Col>
                    <Col md="4">
                        <DataCard
                            amount={
                                <small
                                    style={{
                                        fontSize: '1rem'
                                    }}
                                >
                                    {leadsPerLeadSource &&
                                    leadsPerLeadSource[0]?._id ? (
                                        <p className="text-sm">
                                            {leadsPerLeadSource[0]?._id} :{' '}
                                            {leadsPerLeadSource[0]?.count}{' '}
                                            lead(s)
                                        </p>
                                    ) : (
                                        <p>N/A</p>
                                    )}
                                </small>
                            }
                            title={'Top Lead Source'}
                            showComparison={false}
                        />
                    </Col>

                    <Col md="6">
                        <RenderChart
                            chart={
                                <MultiBarsBarChart
                                    data={{
                                        labels: visitorsByLeadSource?.map(
                                            (item) => item?.dimension
                                        ),
                                        datasets: [
                                            {
                                                data: visitorsByLeadSource?.map(
                                                    (item) => item?.metricValue
                                                ),
                                                borderColor: '#9d72ff',
                                                backgroundColor: '#9472ff'
                                            }
                                        ]
                                    }}
                                />
                            }
                            chartTitle={'Site Unique Visitors By Lead Source'}
                            chartLabelTitles={
                                <li>
                                    <div className="title">
                                        <span
                                            className="dot dot-lg sq"
                                            style={{
                                                background: '#9d72ff'
                                            }}
                                        ></span>
                                        <span>Site Unique Visitors</span>
                                    </div>
                                </li>
                            }
                        />
                        {/* <BarChart
                            data={{
                                labels: visitorsByLeadSource?.length
                                    ? visitorsByLeadSource?.map(
                                          (item) => item?.dimension
                                      )
                                    : [],
                                datasets: [
                                    {
                                        label: 'Site Visitors By Lead Source',
                                        borderColor: 'rgb(53, 162, 235)',
                                        backgroundColor: 'rgb(53, 162, 235)',
                                        data: visitorsByLeadSource?.length
                                            ? visitorsByLeadSource?.map(
                                                  (item) =>
                                                      Number(item?.metricValue)
                                              )
                                            : []
                                    }
                                ]
                            }}
                            options={{
                                ...options,
                                plugins: {
                                    title: {
                                        display: true,
                                        text: 'Site Unique Visitors By Lead Source'
                                    },
                                    legend: {
                                        display: false
                                    }
                                }
                            }}
                            beginAtZero={true}
                            xAxisName={'Lead Source'}
                            yAxisName={'Number of visitors'}
                            className={'bg-white'}
                        /> */}
                    </Col>
                    <Col md="6">
                        <RenderChart
                            chart={
                                <LineChart
                                    data={{
                                        labels: leadsPerLeadSource?.map(
                                            (item) => item?._id
                                        ),
                                        datasets: [
                                            {
                                                data: leadsPerLeadSource?.map(
                                                    (item) =>
                                                        Number(item?.count)
                                                ),
                                                borderColor:
                                                    'rgb(255, 99, 132)',
                                                backgroundColor:
                                                    'rgb(255, 99, 132, 0.2)',
                                                lineTension: 0.4,
                                                fill: true
                                            }
                                        ],
                                        gridLines: {
                                            x: { color: 'transparent' }
                                        }
                                    }}
                                />
                            }
                            chartTitle={'Leads By Lead Source'}
                            chartLabelTitles={
                                <li>
                                    <div className="title">
                                        <span
                                            className="dot dot-lg sq"
                                            style={{
                                                background: 'rgb(255, 99, 132)'
                                            }}
                                        ></span>
                                        <span>Leads By Lead Source</span>
                                    </div>
                                </li>
                            }
                        />
                        {/* <LineChart
                            data={{
                                labels: leadsPerLeadSource?.length
                                    ? leadsPerLeadSource?.map(
                                          (item) => item?._id
                                      )
                                    : [],
                                datasets: [
                                    {
                                        label: 'Leads By Lead Source',
                                        data: leadsPerLeadSource?.length
                                            ? leadsPerLeadSource?.map((item) =>
                                                  Number(item?.count)
                                              )
                                            : [],
                                        lineTension: 0.4,
                                        borderColor: '#798bff',
                                        backgroundColor: 'transparent',
                                        pointBorderWidth: 2,
                                        pointBackgroundColor: 'white',
                                        pointRadius: 4,
                                        fill: true
                                    }
                                ]
                            }}
                            options={{
                                ...options,
                                plugins: {
                                    title: {
                                        display: true,
                                        text: 'Leads By Lead Source'
                                    },
                                    legend: { display: false }
                                }
                            }}
                            beginAtZero={true}
                            xAxisName={'Lead Source'}
                            yAxisName={'Number of leads'}
                            className={'bg-white'}
                        /> */}
                    </Col>

                    <BlockBetween>
                        <BlockHeadContent>
                            <div></div>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <div
                                    className={`toggle-expand-content ${
                                        smBtn ? 'expanded' : ''
                                    }`}
                                >
                                    <ul className="nk-block-tools g-1">
                                        <li>
                                            <div className="form-control-wrap">
                                                <button
                                                    className="btn bg-primary text-white"
                                                    onClick={() => {
                                                        setMonthStart(
                                                            startOfMonth(today)
                                                        );
                                                        setMonthEnd(
                                                            endOfMonth(today)
                                                        );
                                                        // setCurrentDate(today);
                                                    }}
                                                >
                                                    Current Month
                                                </button>
                                            </div>
                                        </li>
                                        <li>
                                            <button
                                                className="btn bg-secondary text-white"
                                                onClick={() => {
                                                    setMonthEnd(
                                                        endOfMonth(
                                                            subMonths(today, 1)
                                                        )
                                                    );
                                                    setMonthStart(
                                                        startOfMonth(
                                                            subMonths(today, 1)
                                                        )
                                                    );
                                                }}
                                            >
                                                Last Month
                                            </button>
                                        </li>
                                        <li>
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <div className="input-daterange date-picker-range input-group">
                                                        <DatePicker
                                                            selected={
                                                                monthStart
                                                            }
                                                            onChange={
                                                                setMonthStart
                                                            }
                                                            selectsStart
                                                            startDate={
                                                                monthStart
                                                            }
                                                            endDate={monthEnd}
                                                            wrapperClassName="start-m"
                                                            className="form-control"
                                                        />
                                                        <div className="input-group-addon">
                                                            TO
                                                        </div>
                                                        <DatePicker
                                                            selected={monthEnd}
                                                            onChange={
                                                                setMonthEnd
                                                            }
                                                            startDate={
                                                                monthStart
                                                            }
                                                            endDate={monthEnd}
                                                            selectsEnd
                                                            minDate={monthStart}
                                                            wrapperClassName="end-m"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>

                    <Col md="6">
                        <div className="listing-item-grid">
                            <div className="card-inner">
                                <div className="kanban-title-content pb-4">
                                    <h6 className="title">
                                        Traffic Leaderboard Per Development
                                    </h6>
                                    <Badge
                                        className="text-dark badge-xs"
                                        pill
                                        color="outline-light"
                                    >
                                        {mostNumberOfViews &&
                                        mostNumberOfViews?.length
                                            ? formatValue(
                                                  mostNumberOfViews
                                                      ?.map((item) =>
                                                          Number(
                                                              item?.metric
                                                                  ?.metricValue
                                                          )
                                                      )
                                                      ?.reduce(
                                                          (a, b) => a + b,
                                                          0
                                                      )
                                              )
                                            : 0}
                                    </Badge>
                                </div>
                                <div className="listing-items-cont">
                                    {!fetching ? (
                                        mostNumberOfViews &&
                                        mostNumberOfViews?.length ? (
                                            mostNumberOfViews?.map((item) => (
                                                <ListingCard
                                                    amount={formatValue(
                                                        Number(
                                                            item?.metric
                                                                ?.metricValue ??
                                                                0
                                                        )
                                                    )}
                                                    amountText={
                                                        Number(
                                                            item?.metric
                                                                ?.metricValue
                                                        ) === 1
                                                            ? 'view'
                                                            : 'views'
                                                    }
                                                    subText={
                                                        item?.property
                                                            ?.community?.name
                                                    }
                                                    title={item?.property?.slug?.replaceAll(
                                                        '-',
                                                        ' '
                                                    )}
                                                    image={
                                                        item?.property
                                                            ?.developmentPhotos[0]
                                                            ?.file
                                                            ? typeof item
                                                                  ?.property
                                                                  ?.developmentPhotos[0]
                                                                  ?.file ===
                                                              'object'
                                                                ? item?.property
                                                                      ?.developmentPhotos[0]
                                                                      ?.file
                                                                      ?.thumbnail
                                                                : item?.property
                                                                      ?.developmentPhotos[0]
                                                                      ?.file
                                                            : null
                                                    }
                                                />
                                            ))
                                        ) : (
                                            <div className="text-center">
                                                No properties to show
                                            </div>
                                        )
                                    ) : (
                                        <CardShimmer
                                            numberOfCards={
                                                mostNumberOfViews?.length ?? 5
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col md="6">
                        <div className="listing-item-grid">
                            <div className="card-inner">
                                <div className="kanban-title-content pb-4">
                                    <h6 className="title">
                                        Traffic Leaderboard Per Developer
                                    </h6>
                                    <Badge
                                        className="text-dark"
                                        pill
                                        color="outline-light"
                                    >
                                        {trafficByDeveloper &&
                                        trafficByDeveloper?.length
                                            ? formatValue(
                                                  trafficByDeveloper
                                                      ?.map((item) =>
                                                          Number(
                                                              item?.totalVisits
                                                          )
                                                      )
                                                      ?.reduce(
                                                          (a, b) => a + b,
                                                          0
                                                      )
                                              )
                                            : 0}
                                    </Badge>
                                </div>
                                <div className="listing-items-cont">
                                    {!fetching ? (
                                        trafficByDeveloper &&
                                        trafficByDeveloper?.length ? (
                                            trafficByDeveloper?.map((item) => (
                                                <ListingCard
                                                    amount={formatValue(
                                                        Number(
                                                            item?.totalVisits ??
                                                                0
                                                        )
                                                    )}
                                                    amountText={
                                                        Number(
                                                            item?.totalVisits
                                                        ) === 1
                                                            ? 'view'
                                                            : 'views'
                                                    }
                                                    subText={
                                                        item?.developer
                                                            ?.headerTitle
                                                    }
                                                    title={
                                                        item?.developer?.name
                                                    }
                                                    image={
                                                        item?.developer?.logo
                                                    }
                                                />
                                            ))
                                        ) : (
                                            <div className="text-center">
                                                No information to show
                                            </div>
                                        )
                                    ) : (
                                        <CardShimmer
                                            numberOfCards={
                                                mostNumberOfViews?.length ?? 5
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Content>
        </>
    );
}
