import React from "react";

import ClientCard from "./ClientCard";
import CardShimmer from "./CardShimmer";
import { Badge } from "reactstrap";
import { useNavigate } from "react-router-dom";

export default function ClientsAcquired ( {
    date = new Date(),
    clients = [],
    totalClients = 0,
    fetching = false,
    title = "Clients Acquired This Week",
} )
{
    const navigate = useNavigate();

    return (
        <div className="left-section deal-item-grid">
            <div className="right-section bg-white card-inner h-full">
                <div className="kanban-title-content">
                    <h6 className="title">{ title }</h6>
                    <Badge className="text-dark" pill color="outline-light">
                        { clients?.length ?? 0 }
                    </Badge>
                </div>

                <div className="deal-card pt-3">
                    { fetching ? (
                        <CardShimmer numberOfCards={ 5 } />
                    ) : clients && clients?.length ? (
                        clients?.slice( 0, 6 )?.map( ( item ) => (
                            <ClientCard
                                key={ item?.id ?? item?._id }
                                client={ item }
                                onClick={ ( e ) =>
                                {
                                    if(!item?.id&&!item?._id) return
                                    navigate(
                                        `/user-profile/${item?._id ??item?._id}/user-profile-setting`
                                    );
                                } }
                            />
                        ) )
                    ) : (
                        <div className="flex-1 w-full flex gap-1 items-center justify-center text-neutral-500">
                            <p>No clients created this week</p>
                          
                        </div>
                    ) }
                </div>
            </div>
        </div>
    );
}
