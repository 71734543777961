import { Button, Label, Modal, ModalBody, Spinner } from "reactstrap";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { Icon } from "../../../../../components/Component";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { toast } from "react-toastify";
import { HttpStatus } from "../../../../../utils/envConfig";

const NoteEditModal = ({ note, isOpen, toggleFn, refreshFn, size = "md" }) => {
  const axios = useAxiosPrivate();
  const { errors, register, handleSubmit } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const submitEdit = async (formData) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `/property-cards/${note?.propertyCard}/notes/${note?.id}`,
        formData
      );
      if (res.status === HttpStatus.OK) {
        toast.success("Edited note successfully");
        refreshFn();
      }
    } catch (error) {
      console.log(error);
      toast.error("Couldn't update note, an error occurred.");
    } finally {
      toggleFn();
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleFn}
      size={size}
      className="modal-dialog-centered"
    >
      <ModalBody>
        <div className="nk-tnx-details mt-sm-3">
          <a href="#cancel" className="close">
            {" "}
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                toggleFn();
              }}
            ></Icon>
          </a>
          <div className="nk-modal-head mb-3">
            <h5 className="title">Edit Note</h5>
          </div>
        </div>
        <form onSubmit={handleSubmit(submitEdit)}>
          <div className="py-2">
            <Label>Message</Label>
            <div className="form-control-wrap">
              <textarea
                class={`form-control no-resize`}
                id="message"
                name="message"
                ref={register({ required: true })}
              >
                {note?.message ?? ""}
              </textarea>
              {Object.keys(errors).length ? (
                <span className="fw-bold text-danger">
                  Please check the message you entered again
                </span>
              ) : null}
            </div>
          </div>
          <div className="d-flex justify-content-end mt-2">
            <Button color="primary" type="submit" disabled={isLoading}>
              {!isLoading ? "Edit" : <Spinner />}
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default NoteEditModal;
