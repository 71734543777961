import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import queryString from "query-string";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Card,
  DropdownItem,
  Col,
  Label,
  Row,
  Badge,
} from "reactstrap";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PaginationComponent,
} from "../../components/Component";
import DealCard from "./components/DealCard";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { fetchDeals } from "../../redux/actions/deal";
import { RSelect } from "../../components/Component";
import { CommissionStatus, DealCategory, DealStatus, newRoles } from "../../utils/envConfig";
import { fetchAgents } from "../../redux/actions/user";
import LoadingComponent from "../components/loader/tableLoader";
import SelectAgent from "../../components/SelectAgent";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import LoaderModal from "../../components/modals/LoaderModal";
import { showToast } from "../../utils/toast/toast";
import { useMutation } from "react-query";
import dealQueries from "../../queries/dealQueries";

const DealList = ({
  url = "/list-deals",
  agent = null,
  user = null,
  showAgentFilter = true,
}) => {
  const authUser = useSelector( ( state ) => state?.user?.loggedInUser );
  const location = useLocation();
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const { loading, deals: allDeals } = useSelector((state) => state.deal);
  const loggedInUser = useSelector((state) => state?.user?.loggedInUser);

  const query = queryString.parse(location.search);
   
  const dealStatusOptions = Object.values(DealStatus).map((item) => ({
    label: item,
    value: item,
  }));

  const dealCategoryOptions = Object.values(DealCategory).map((item) => ({
    label: item,
    value: item,
  }));

  const exclusiveParams = [ {label: 'Yes', value:'true'},{label: 'No', value:'false'}];

  const agents = useSelector((state) => state?.user?.agents);
  const allowedAgentAccessRoles = [newRoles.Admin, newRoles.MasterAdmin, newRoles.OfficeAdmin, newRoles.SalesManager]

  const [onSearch, setonSearch] = useState(query?.search ? false : true);
  const [onSearchText, setSearchText] = useState(query?.search ?? "");

  const [rangeStart, setRangeStart] = useState(
    query?.from_date ? new Date(query?.from_date) : null
  );
  const [rangeEnd, setRangeEnd] = useState(
    query?.to_date ? new Date(query?.to_date) : null
  );
  const [dateError, setDateError] = useState("");

  const [page, setPage] = useState(query?.page ? Number(query?.page) : 1);
  const [filCount, setFilCount] = useState(allDeals?.filteredCount);
  const [perPage, setPerPage] = useState(
    query?.per_page ? Number(query?.per_page) : 10
  );

  const [selectedStatus, setSelectedStatus] = useState(
    query?.status ? { label: query?.status, value: query?.status } : ""
  );
  
  const [selectedExclusive, setSelectedExclusive] = useState(
    query?.exclusive ? { label: query?.exclusive, value: query?.exclusive } : ""
  );

  const [selectedCategory, setSelectedCategory] = useState(
    query?.category ? { label: query?.category, value: query?.category } : null
  );

  const [selectedAgent, setSelectedAgent] = useState(
    query?.agent
      ? {
          ...agents?.find((agent) => agent?.id === query?.agent),
          label: `${this?.user?.first_name} ${this?.user?.last_name}`,
        }
      : null
  );

  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleCategorySelection = (selectedOptions) => {
    setSelectedCategories(selectedOptions);

    // Check if "All Categories" is selected
    const allCategorySelected = selectedOptions.some(option => option.value === "all");
  
    if (allCategorySelected) {
      setSelectedCategory(null);
      queryDeals({
        category: { value: "null" },
      });
    } else {
      const selectedCategoryValues = selectedOptions.map(option => option.value.toString().trim());
      if(selectedCategoryValues.length > 0){
        queryDeals({
          category: { value: selectedCategoryValues } // Pass the array directly
        });
      }else{
        queryDeals({
          category: { value: "null" },
        });
      }
      
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDateError("");
    }, 3000);

    return () => clearTimeout(timeout);
  }, [dateError]);

  // we want to run this useEffect each time query changes its value
  useEffect(() => {
    const queryString = queryDeals({ perPage }, false);

    setPage(query?.page ? Number(query?.page) : 1);
    setPerPage(
      query?.per_page ? Number(query?.per_page) : allDeals?.searchPerPage ?? 10
    );

    setRangeEnd(query?.to_date ? new Date(query?.to_date) : null);
    setRangeStart(query?.from_date ? new Date(query?.from_date) : null);
    setSearchText(query?.search ?? "");
    setSelectedStatus(
      query?.status ? { label: query?.status, value: query?.status } : null
    );
    setSelectedExclusive(
      query?.exclusive ? { label: query?.exclusive, value: query?.exclusive } : null
    )
    setSelectedCategory(
      query?.category
        ? { label: query?.category, value: query?.category }
        : null
    );

    const agent = query?.agent;

    if (agent) {
      const foundAgent = agents?.find((item) => item?.id === agent);

      setSelectedAgent({
        ...foundAgent,
        label: `${foundAgent?.user?.first_name ?? ""} ${
          foundAgent?.user?.last_name ?? ""
        }`,
      });
    } else {
      setSelectedAgent(null);
    }
    
    dispatch(
      fetchDeals(
        axios,
        location.search?.length ? location?.search : `?${queryString}`
      )
    );
  }, [location.search]);

  useEffect(() => {
    setFilCount(allDeals?.filteredCount);
  }, [allDeals]);

  useEffect(() => {
    !agents?.length &&  allowedAgentAccessRoles.includes(loggedInUser?.role) && dispatch(fetchAgents(axios));
  }, []);

  useEffect(() => {
    const agent = query?.agent;
    if (agent) {
      const foundAgent = agents?.find((item) => item?.id === agent);

      setSelectedAgent({
        ...foundAgent,
        label: `${foundAgent?.user?.first_name ?? ""} ${
          foundAgent?.user?.last_name ?? ""
        }`,
      });
    }
  }, [agents]);

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  function formatToIso(date, toStart = true) {
    if (!(date instanceof Date)) {
      console.log("Invalid date instance");
      return;
    }

    const month = date.getMonth() + 1;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;

    const day = date.getDate();
    const formattedDate = day < 10 ? `0${day}` : `${day}`;

    const year = date.getFullYear();

    const dateStartFormat = "T00:00:00.000Z";
    const dateEndFormat = "T23:59:59.999Z";

    return toStart
      ? `${year}-${formattedMonth}-${formattedDate}${dateStartFormat}`
      : `${year}-${formattedMonth}-${formattedDate}${dateEndFormat}`;
  }

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const toggleConfirm = () => setIsConfirmOpen(!isConfirmOpen);

  const queryDeals = (params, updateRoute = true) => {
    // the query below will consider the params first. If the value is not available in params, then it tries to get from the query. If not available in query, then it returns undefined.
    // If you had already pass an argument, this argument will be available in the query, thus, if u want to remove this item from the query, you need to pass a string of 'null' in the params object so that the cleanup function will remove it before passing to search query.

    const dealParams = {
      per_page: params?.perPage ?? query?.per_page ?? perPage,
      search: params?.search ?? query?.search,
      from_date: params?.fromDate ?? query?.from_date,
      to_date: params?.toDate ?? query?.to_date,
      agent: params?.agent ?? query?.agent ?? agent ?? query?.agent,
      user: user ?? query?.user,
      sales_manager: params?.salesManager ?? query?.sales_manager,
      status: params?.status ?? query?.status,
      exclusive: params?.exclusive ?? query?.exclusive,
      page: params?.page ?? 1,
      category: params?.category?.value ?? query?.category,
    };

    const searchQuery = Object.entries(dealParams)
    ?.filter(([key, value]) => key && value && value !== "null")
    ?.map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join("&");
    if (updateRoute) {
      const navigateParams = {};

      if (location.search.length) {
        navigateParams.replace = true;
      }

      navigate(`${url ?? "/list-deals"}?${searchQuery}`, navigateParams);
    }

    return searchQuery;
  };

  // debaounce update query
  const updateSearchQuery = useCallback(queryDeals, []);

  const debounceSearchDeals = useMemo(() => {
    return debounce(updateSearchQuery, 1000);
  }, [updateSearchQuery]);
  // end debouncing
   const clearFilters=()=>{
    setSelectedCategories([]);
     navigate('/list-deals')
   }

   const dealParams = {
    per_page: filCount,
    search: query?.search,
    from_date: query?.from_date,
    to_date: query?.to_date,
    agent: query?.agent ?? agent ?? query?.agent,
    user: user ?? query?.user,
    sales_manager: query?.sales_manager,
    status: query?.status,
    exclusive: query?.exclusive,
    page: query?.page,
    category: query?.category,
  };
  
   const { mutationFn: createCsvMut } =
   dealQueries.DealsList.requestCsv(axios, { dealParams });
 
   const createCsvFile = useMutation(createCsvMut, {
     onError: (error) => {
       if (error?.message) {
         showToast(error?.message, 'error');
       } else {
         showToast('Request to generate CSV file failed', 'error');
       }
     },
     onSuccess: (data) => {
       showToast(data?.message, 'info', 5000); // keep the toast open for 5 seconds
     }
   });

   const handleExportAsCsv = () => {
    createCsvFile && createCsvFile.mutate(null, {
      onSettled: () => {
        toggleConfirm();
      }
    });
  }

  return (
    <React.Fragment>
      <Head title="Deal List"></Head>
      <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Deals</BlockTitle>
                <BlockDes className="text-soft">
                  <p>You have total {filCount} Deals.</p>
                </BlockDes>
              </BlockHeadContent>
            </BlockBetween>
            <div className="d-flex justify-content-end align-items-end">
              <ul className="nk-block-tools d-flex flex-wrap gx-1 gy-2 justify-content-end w-90">
                {
                  allowedAgentAccessRoles.includes(loggedInUser?.role) && <li>
                  <SelectAgent
                    defaultOptions={[{
                      label: 'All Agents',
                      value: null
                    }, ...(agents??[])?.slice(0, 10)?.map(item => ({
                      ...item,
                      label: `${item?.user?.first_name??''}  ${item?.user?.last_name??''}`,
                      value: item?.id??item?._id
                    }))]}
                    onSelectAgent={(agent)=> {
                      setSelectedAgent(agent)
                      if (!agent?.value) {
                        queryDeals({
                          agent: 'null'
                        })

                        return
                      }

                      if ( query?.agent !== (agent?.id??agent?._id)) {
                        queryDeals({
                          agent: agent?.id??agent?._id
                        })
                      }
                      
                    }}
                    selectedAgent={selectedAgent}
                  />
                </li>
                }
                <li>
                <RSelect
                  placeholder="Filter By Deal Category"
                  options={[
                    {
                      label: "All Categories",
                      value: "all",
                    },
                    ...dealCategoryOptions,
                  ]}
                  isMulti
                  value={selectedCategories}
                  onChange={handleCategorySelection}
                  className="status-div"
                />
                </li>
             
                <li>
                  <RSelect
                    placeholder="Deal Status"
                    className="status-div"
                    value={selectedStatus}
                    options={[
                      { label: "All", value: "all" },
                      ...dealStatusOptions,
                    ]}
                    onChange={(status) => {
                      if (status.value === "all") {
                        setSelectedStatus(null);
                        queryDeals({ status: "null" });
                      } else {
                        setSelectedStatus(
                          status?.value === selectedStatus?.value ? null : status
                        );
                        queryDeals({
                          status:
                            status?.value === selectedStatus?.value
                              ? "null"
                              : status?.value,
                        });
                      }
                    }}
                  />
                </li>

                <li>
                  <RSelect
                    placeholder="Exclusive"
                    className="status-div"
                    value={selectedExclusive}
                    options={[
                      { label: "All", value: "all" },
                      ...exclusiveParams,
                    ]}
                    onChange={(exclusive) => {
                      if (exclusive.value === "all") {
                        setSelectedExclusive(null);
                        queryDeals({ exclusive: "null" });
                      } else {
                        setSelectedExclusive(
                          exclusive?.value === selectedExclusive?.value ? null : exclusive
                        );
                        queryDeals({
                          exclusive:
                          exclusive?.value === selectedExclusive?.value
                              ? "null"
                              : exclusive?.value,
                        });
                      }
                    }}
                  />
                </li> 
                
                <li>
                  <Row>
                    <Col sm={12}>
                      <div className="position-relative form-group">
                        <Label>Deal Date</Label>
                        <div
                          className="top-0 position-absolute text-danger"
                          style={{ right: "0" }}
                        >
                          {dateError}
                        </div>
                        <div className="form-control-wrap">
                          <div className="date-picker-range datepicker-zindex input-daterange input-group">
                            <DatePicker
                              selected={rangeStart}
                              onChange={(date) => {
                                if (rangeEnd && date > rangeEnd) {
                                  setDateError(
                                    "Start date cannot be greater than end date"
                                  );
                                  return;
                                }

                                const fromDate = formatToIso(date)
                                  ?.split("T")
                                  ?.shift();

                                                              setRangeStart(date);

                                                              queryDeals({
                                                                  fromDate
                                                              });
                                                          }}
                                                          selectsStart
                                                          startDate={rangeStart}
                                                          endDate={rangeEnd}
                                                          maxDate={rangeEnd}
                                                          wrapperClassName="start-m"
                                                          className="form-control"
                                                      />
                                                      <div className="input-group-addon">
                                                          TO
                                                      </div>
                                                      <DatePicker
                                                          selected={rangeEnd}
                                                          onChange={(date) => {
                                                              if (
                                                                  rangeStart &&
                                                                  date <
                                                                      rangeStart
                                                              ) {
                                                                  setDateError(
                                                                      'Start date cannot be greater than end date'
                                                                  );
                                                                  return;
                                                              }

                                const toDate = formatToIso(date, false)
                                  ?.split("T")
                                  ?.shift();

                                setRangeEnd(date);
                                queryDeals({
                                  toDate,
                                });
                              }}
                              startDate={rangeStart}
                              endDate={rangeEnd}
                              selectsEnd
                              minDate={rangeStart}
                              wrapperClassName="end-m"
                              className="form-control datepicker-zindex"
                            />
                          </div>
                        </div>
                        <div className="form-note">
                          Date Format <code>mm/dd/yyyy</code>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                {authUser &&
              ( authUser.role === newRoles.Admin ||
              authUser.role === newRoles.MasterAdmin ) &&
            <li>
                <BlockBetween className={'mt-2'}>
            <div className="align-self-end me-3 d-flex">
              <Button className="ms-2" color="primary" onClick={toggleConfirm} disabled={!allDeals?.findDealsOutput?.length}>
                {"Export as CSV"}
              </Button>
            </div>
          </BlockBetween>
                </li>}
              </ul>
            </div>
            <div className="d-flex justify-content-end mt-2">
               {Object.keys(queryString.parse(location.search))?.sort()?.map((key,id)=>{
                  switch (key){
                    case 'per_page':
                      case 'page':
                        case 'user':
                          case 'agent':
                    break
                    case 'from_date':
                    case 'to_date':
                    return (
                      <Badge key={key+id} color="primary" className="mx-1 text-capitalize">{key==='from_date'?'Date Start':"Date End"}: {queryString.parse(location.search)[key]} <Icon onClick={(e)=>{
                  const query=queryString.parse(location.search)
                   delete query?.[key]
                   navigate(`/list-deals?${queryString.stringify(query)}`)
                }} name={"cross-sm"}/></Badge>
                    )
                    case 'category':
                      return (
                        <Badge key={key+id} color="primary" className="mx-1 text-capitalize">{"Category"}: {queryString.parse(location.search)[key]} <Icon onClick={(e)=>{
                    const query=queryString.parse(location.search)
                     delete query?.[key]
                     setSelectedCategories([]);
                     navigate(`/list-deals?${queryString.stringify(query)}`)
                  }} name={"cross-sm"}/></Badge>
                      )
                    default :
                    return  <Badge key={key+id} color="primary" className="mx-1 text-capitalize">{key}: {queryString.parse(location.search)[key]} <Icon onClick={(e)=>{
                  const query=queryString.parse(location.search)
                   delete query?.[key]
                   navigate(`/list-deals?${queryString.stringify(query)}`)
                }} name={"cross-sm"}/></Badge>  
                  } 
               })}
              {location.search?<Badge onClick={clearFilters} className="cursor-pointer" color="danger"><Icon name={"cross-sm"}/>Clear All</Badge>:null}
            </div>
          </BlockHead>

          <Block>
            <Card className="card-stretch">
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h5 className="title">All Deals</h5>
                    </div>
                    <div className="card-tools me-n1">
                      <ul className="btn-toolbar">
                        <li>
                          <Button
                            onClick={toggle}
                            className="search-toggle btn-icon toggle-search"
                          >
                            <Icon name="search"></Icon>
                          </Button>
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="btn btn-icon btn-trigger dropdown-toggle"
                            >
                              <Icon name="setting"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-check">
                                <li>
                                  <span>Show</span>
                                </li>
                                <li className={perPage === 10 ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setPage(1);
                                      setPerPage(10);
                                      queryDeals({
                                        perPage: 10,
                                        page: 1,
                                      });
                                    }}
                                  >
                                    10
                                  </DropdownItem>
                                </li>
                                <li className={perPage === 15 ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setPage(1);
                                      setPerPage(15);
                                      queryDeals({
                                        perPage: 15,
                                        page: 1,
                                      });
                                    }}
                                  >
                                    15
                                  </DropdownItem>
                                </li>
                              </ul>
                          
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </div>
                    <div
                      className={`card-search search-wrap ${
                        !onSearch ? "active" : ""
                      }`}
                    >
                      <div className="search-content">
                        <Button
                          className="search-back btn-icon toggle-search"
                          onClick={() => {
                            setSearchText("");
                            toggle();
                            queryDeals({ search: "" });
                          }}
                        >
                          <Icon name="arrow-left"></Icon>
                        </Button>
                        <input
                          type="text"
                          className="form-control form-focus-none border-transparent"
                          placeholder="Search by Deal Id"
                          value={onSearchText}
                          onChange={(e) => {
                            onFilterChange(e);
                            debounceSearchDeals({
                              search: e.target.value,
                            });
                          }}
                        />
                        <Button className="search-submit btn-icon">
                          <Icon name="search"></Icon>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-0 card-inner">
                  <table className="table table-orders">
                    <thead className="tb-odr-head">
                      <tr className="tb-odr-item">
                      <th><span className="">Deal ID</span></th>
                      <th className="">
                          
                          <span>
                            Created
                          </span>
                        </th>
                        <th>
                          
                          <span>
                            Category
                          </span>
                        </th>
                      
                        <th>
                          <span>
                            Agent
                          </span>
                        </th>
                        <th className="text-center">
                          
                          <span>
                          Property Type
                          </span>
                        </th>
                        <th>
                          
                          <span>
                            Purpose
                          </span>
                        </th>
                        <th className="px-4 text-center">
                          <span>
                            Price
                          </span>
                        </th>
                        <th className="px-4 text-center">
                          <span>
                            Exclusive
                          </span>
                        </th>
                        <th>
                          
                          <span >
                            Community
                          </span>
                        </th>
                        <th>
                          
                          <span>
                          Building
                          </span>
                        </th>
                      
                        <th>
                          <span>
                            Client
                          </span>
                        </th>
                        <th>
                        <span>Status</span>
                        </th>
                      
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="tb-odr-body">
                      {!loading &&
                        allDeals?.findDealsOutput &&
                        [...allDeals?.findDealsOutput]?.map((myDeal, index) => (
                          <DealCard
                            key={myDeal?.id}
                            deal={myDeal}
                            user={
                              myDeal?.user ??
                              myDeal?.buyerInternalUser ??
                              myDeal?.buyerExternalUser
                            }
                            agent={myDeal?.agent}
                            property={myDeal?.property_list}
                            newListing={myDeal?.new_listing_request}
                          />
                        ))}
                      
                    </tbody>
                  </table>
                </div>
                <div className="card-inner">
                {loading&&<LoadingComponent/>}
                  {allDeals?.findDealsOutput?.length > 0 ? (
                    <PaginationComponent
                      noDown
                      currentPage={page ?? 1}
                      paginate={(page) => {
                        setPage(page);
                        queryDeals({ page: page });
                      }}
                      itemPerPage={perPage ?? 10}
                      totalItems={filCount}
                    />
                  ) : (
                    <div className="text-center">
                   
                      <span className="text-silent">No data found</span>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </Block>
        </Content>
        {isConfirmOpen && <Suspense fallback={<LoaderModal />}>
        <ConfirmationModal
          isOpen={isConfirmOpen}
          toggleFn={toggleConfirm}
          title={'Confirm CSV Export'}
          confirmBtnLabel="Export & Download"
          cancelBtnLabel="Cancel"
          dimButtons
          actionFn={handleExportAsCsv}
        >
          <p>
            Export the data as a CSV File? <br />
            <span className="text-soft">Any filters you selected will be applied.</span>
          </p>
        </ConfirmationModal>
      </Suspense>}
    </React.Fragment>
  );
};
export default DealList;
