import React from 'react';
import { Link } from 'react-router-dom';
import { formatValue } from '../../../utils/envConfig';
import { Badge } from 'reactstrap';

export default function DealCard({ deal }) {
//   console.log(deal);
  return (
    <>
      <div className="border-bottom pb-2 pt-2">
        <Link
          to={`/deal-details/${deal?.id ?? deal?._id}`}
          style={{ color: 'black' }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <span className="ff-base fw-medium">Deal ID:</span>{' '}
              {deal?.reference_number}
            </div>
            <div className="ml-auto">
              <Badge className="badge text-primary" color="primary-dim">
                {deal?.status}
              </Badge>
            </div>
          </div>
          <div className="d-flex justify-content-between pt-1">
            <div className="d-sm-none d-xxl-flex">
              <span className="ff-base fw-medium">Category: {''}</span>
              <span>{deal?.category}</span>
            </div>
            <div className="ml-auto">
              <span className="ff-base fw-medium">Value: </span>
              <span>
                AED{' '}
                {deal?.agreed_salePrice
                  ? formatValue(deal?.agreed_salePrice)
                  : 'N/A'}
              </span>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
