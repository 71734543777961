import * as types from '../types';

import {  newRoles, s3URL } from '../../utils/envConfig';

export const userLogin = (userData) => (dispatch) => {
  dispatch({
    type: types.USER_LOGIN,
    payload: userData
  });
};

export const fetchUsers = (axios, link) => async (dispatch) => {
  
  dispatch({ type: types.GET_USERS_STARTED });
  try {
    const url =
      link ??
      `/search/user/filter?page=1&sort_by=createdAt&per_page=10&sort_dir=DESC&active=true`;
    await axios
      .get(url)
      .then((res) => {
        dispatch({
          type: types.GET_USERS,
          payload: res.data ?? {}
        });
      })
      .catch((error) => {
        dispatch({ type: types.GET_USERS_FAILED, error });
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};

export const filterUsersByRole = (axios, params) => async (dispatch) => {
  try {
    const { role, agent_id } = params;

    if (role && !Object.values(newRoles).includes(role)) {
      throw new Error('Invalid user rol');
    }

    if (!axios || !axios.get || typeof axios.get !== 'function') {
      throw new Error('Invalid axios instance.');
    }

    const url = agent_id
      ? `/usersAgents/agent/${agent_id}`
      : role
      ? `/search/user/filter?page=1&sort_by=createdAt&per_page=10&sort_dir=DESC&roles[]=${role}&active=true`
      : `/search/user/filter?page=1&sort_by=createdAt&per_page=10&sort_dir=DESC&active=true`;

    const { data } = await axios.get(url);

    switch (role) {
      case newRoles.Owner:
        dispatch({
          type: types.GET_OWNERS,
          payload: agent_id
            ? {
                findUsersOutput: data
                  ?.filter((agent) => agent?.user?.role === newRoles.Owner)
                  ?.map((item) => ({
                    ...item?.user,
                    agent_id
                  }))
              }
            : data ?? {}
        });
        break;

      case newRoles.Agent:
        dispatch({
          type: types.GET_ONLY_AGENTS,
          payload: agent_id
            ? data
                ?.filter((agent) => agent?.user?.role === newRoles.Agent)
                ?.map((item) => ({ ...item?.user, agent_id }))
            : data ?? {}
        });
        break;

      case newRoles.User:
        dispatch({
          type: types.GET_ONLY_USERS,
          payload: agent_id
            ? data
                ?.filter((agent) => agent?.user?.role === newRoles.User)
                ?.map((item) => ({ ...item?.user, agent_id }))
            : data ?? {}
        });
        break;

      default:
        dispatch({
          type: types.GET_USERS,
          payload: agent_id
            ? data?.map((item) => ({ ...item?.user, agent_id }))
            : data ?? {}
        });
        break;
    }
  } catch (error) {
    console.log(error);
  }
};

export const UpdateUser = (axios, userId, formData) => async (dispatch) => {
  try {
    await axios
      .patch(`/users/${userId}`, formData)
      .then((res) => {
        dispatch({
          type: types.UPDATE_USER,
          payload: res.data
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};

export const fetchAgents = (axios) => async (dispatch) => {
  try {
    const res = await axios.get(`/agent`);

    if (res.data.length) {
      const agentsWithImages = await Promise.all(
        res.data.map(async (agent) => {
          if (!agent?.user?.photo) {
            return {
              ...agent,
              image: null,
              imageUrl: null
            };
          }

         
          return {
            ...agent,
            image: s3URL + agent?.user?.photo,
            imageUrl: agent?.user?.photo
          };
        })
      );

      dispatch({
        type: types.GET_AGENTS,
        payload: agentsWithImages
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchAgentsList = (axios) => async (dispatch) => {
  dispatch({ type: types.GET_AGENTS_LIST_STARTED });
  try {
    const res = await axios.get(`/agent`);
    dispatch({
      type: types.GET_AGENTS_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: types.GET_AGENTS_LIST_FAILED,
      error: err
    });
  }
}

export const fetchUserAgents = (axios) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const res = await axios
      .get(`/usersAgents`)
      .then((useragent) => {
        dispatch({
          type: types.GET_USER_AGENTS,
          payload: useragent.data
        });
      })
      .catch((err, res) => {
        // console.log(err)
      });
  } catch (err) {
    // console.log(err)
  }
};
export const fetchAgentsPaginated = (axios,params={
  options:{
    sort:{createdAt:-1},
    populate:['user'],
    limit:10,
    page:1
  },
 filter:{

 }
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENTS_PAGINATED_STARTED,
    });
    const page=isNaN(Number(params?.options?.page))?1:Number(params?.options?.page)
    const limit=isNaN(Number(params?.options?.limit))?12:Number(params?.options?.limit)
    delete params?.options?.page
    delete params?.options?.limit
    const $options={
      sort:{createdAt:-1},
      populate:['user'],
      ...params?.options,
      limit,
      page,
      skip:(page-1)*limit
    }
    const $filter={
      ...params?.filter
    }
    const $params={
       options:$options,
       filter:$filter
    }
  
   const {data} = await axios.get(`/agent/filter`,{
      params:$params
    })
    dispatch({
      type: types.GET_AGENTS_PAGINATED,
      payload:data,
      loading:false
    });
  } catch (err) {
    dispatch({
      type: types.GET_AGENTS_PAGINATED_ERR,
      payload:null,
      loading:false,
      error:err
    });
    console.log('Error paginating Agents:',err);
  }
};

export const fetchLoggedInAgent = (axios, userId, link = null) => async (dispatch) => {
  dispatch({ type: types.GET_AUTH_AGENT_STARTED });
  try {
    const url = link ?? `/agent/user/${userId}`;
    const res = await axios.get(url);
    dispatch({ type: types.GET_AUTH_AGENT, payload: res.data });
  } catch (error) {
    dispatch({ type: types.GET_AUTH_AGENT_FAILED, error: error });
  } finally {
    dispatch({ type: types.GET_AUTH_AGENT_FINISHED });
  }
}

export const fetchAgentByUserID = (axios, userId, link = null) => async (dispatch) => {
  dispatch({ type: types.GET_AGENT_BY_USER_ID_STARTED });
  try {
    const url = link ?? `/agent/user/${userId}`;
    const {data} = await axios.get(url);
    dispatch({ type: types.GET_AGENT_BY_USER_ID, payload: data });
  } catch (error) {
    dispatch({ type: types.GET_AGENT_BY_USER_ID_FAILED, error: error });
  }
}