import { useMemo } from "react";
import useScreenSize from "../../../hooks/useScreenSize";
import { ScreenBreakPoints } from "../../../utils/envConfig";

const useResponsiveDealStyles = () => {
  const { width: screenWidth } = useScreenSize();

  const xxlCellClass = useMemo(
    () =>
      screenWidth >= ScreenBreakPoints.xxl
        ? "d-table-cell text-center"
        : "d-none",
    [screenWidth]
  );
  const wideScreenCellClass = useMemo(
    () => (screenWidth >= 1800 ? "d-table-cell text-center" : "d-none"),
    [screenWidth]
  );
  const ultraWideScreenCellClass = useMemo(
    () =>
      screenWidth >= ScreenBreakPoints.ultraWide
        ? "d-table-cell text-center"
        : "d-none",
    [screenWidth]
  );
  const skeletonColumnCount = useMemo(() => {
    if (screenWidth < ScreenBreakPoints.md) {
      return 1;
    } else if (screenWidth <= ScreenBreakPoints.laptop) {
      return 4;
    } else if (screenWidth >= ScreenBreakPoints.xxl && screenWidth < 1800) {
      return 6;
    } else if (screenWidth > 1800) {
      return 7;
    }
  }, [screenWidth]);

  return {
    xxlCellClass,
    wideScreenCellClass,
    ultraWideScreenCellClass,
    skeletonColumnCount
  };
};

export default useResponsiveDealStyles;
