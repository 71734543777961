import React, { useEffect, useState } from "react";
import { ModalBody, Col,Input } from "reactstrap";
import { Icon, Button, Row } from "../../../components/Component";
import {
  getImage,
  uploadToS3,
} from "../../../utils/envConfig";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useQuery } from "react-query";
import { showToast } from "../../../utils/toast/toast";

const ONE_HOUR = 60 * 60 * 1000;

export const EditUserProfileImage = ({
    toggle,
    data,
    setData = null,
    taskToBoard,
    user, 
    callback = (data)=>{}
  }) => {

    const [loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState("");
    const folderName = "User";
    const [displayAvatar, setDisplayAvatar] = useState("");
    const axios = useAxiosPrivate();
    

    const { data: resData, isLoading } = useQuery(
      ['userImage', (user?.id ?? user?._id)],
      () => getImage((user?.photo ?? user?.photo) ?? "", axios),
      {
          enabled: !!(user?.id ?? user?._id) && !!(user?.photo),
          staleTime: ONE_HOUR,
          refetchOnWindowFocus: false,
          refetchInterval: ONE_HOUR,
          onError: (err) => {
              console.log(err);
              showToast('Error fetching user image', 'error');
          }
      }
    );
    useEffect(() => {
      function fetchImage() {
        // const resData = await getImage(user?.photo, axios);
        const s3url = resData?.signedRequest;
        setDisplayAvatar(s3url);
      }
    
      fetchImage();
    }, [resData?.signedRequest]);
    
    
    



    const editImage = async (e) => {
      e.preventDefault();
      let fileName = user?.photo;
      if (avatar && avatar.name) {
        setLoading(true);
        fileName = `${folderName}/${uuidv4()}.${avatar.name.split(".").pop()}`;
        await uploadToS3(avatar, fileName, 'image/*', axios);
        const formData = {
          photo: fileName,
        };
        try {
          await axios
            .patch(`/users/${user.id}`, formData)
            toast.success("User image modified successfully");
            setLoading(false);
            toggle();  
            callback && typeof callback === 'function' && callback();
              
        } catch (err) {
          toast.error("User not modified with changes!");
        }
      }
    };
  
    const handleImage = async (e) => {
      if (e.target.files) {
        let result = e.target.files[0];
        let url = URL.createObjectURL(e.target.files[0]);
        setDisplayAvatar(url);
        setAvatar(result);
      }
    };
    return (
      <ModalBody>
              <a href="#cancel" className="close">
                {" "}
                <Icon
                  name="cross-sm"
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggle();
                  }}
                ></Icon>
              </a>
              <div className="p-2">
                <h5 className="title">{"Edit User Image"}</h5> 
                <div className="mt-4">
                  <form onSubmit={(e) => editImage(e)}>
                    <Row className="g-3">
                      <Col sm="12">
                        <div className="form-group">
                          <label className="form-label">Image</label>
                          <div className="form-control-wrap">
                            <div className="form-file">
                              <Input
                                type="file"
                                id="customFile"
                                onChange={(e) => handleImage(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <Col sm="6">
                          {displayAvatar && (
                            <img
                              src={displayAvatar}
                              alt={user?.findUpper}
                              style={{ height: "8rem" }}
                            />
                          )}
                        </Col>
                      </Col>
                      <Col size="12" className="mt-3">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
                      <li>
                      <Button
                        color="primary"
                        size="lg"
                        disabled={loading} 
                      >
                        {loading ? 'Updating...' : 'Update'}
                      </Button>
                      </li>
                    </ul>
                  </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </ModalBody>
    );
  };


