import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RSelect } from "./Component";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { filterUsersByRole } from "../redux/actions/user";
import { useDispatch } from "react-redux";
import { newRoles } from "../utils/envConfig";
import { Spinner } from "reactstrap";

export default function SearchClientSelectNew({
  onlyAgents,
  onlyOwners,
  onlyUsers,
  agentId,
  ...props
}) {
  const users = useSelector((state) => state?.user?.users?.findUsersOutput);

  const loggedInUser = useSelector((state) => state?.user?.loggedInUser);

  const userRole = onlyAgents
    ? newRoles.Agent
    : onlyOwners
    ? newRoles?.Owner
    : onlyUsers
    ? newRoles.User
    : undefined;

  const axios = useAxiosPrivate();

  const [clientOptions, setClientOptions] = useState([]);

  const [isTyping, setIsTyping] = useState(false);

  const queryUsers = async (value) => {
    try {
      const url = userRole
        ? `/search/user/filter?search=${value}&roles[]=${userRole}&active=true`
        : `/search/user/filter?search=${value}&active=true`;

      const { data } = await axios.get(url);

      return data;
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  const sendRequest = useCallback(async (value) => {
    try {
      const clients = await queryUsers(value);

            if (agentId && typeof agentId === "string") {
                if (onlyAgents) {
                    setClientOptions(
                        clients
                            ?.slice(0, 11)
                            ?.filter(
                                (agent) => agent?.user?.role === newRoles.Agent
                            )
                            ?.map((item) => ({
                                ...item?.user,
                                value: `${item?.user?.first_name} ${item?.user?.last_name}`,
                                label: `${item?.user?.first_name} ${item?.user?.last_name}`,
                                agent_id: item?.agent?.id ?? item?.agent_id,
                            }))
                    );
                } else if (onlyUsers) {
                    setClientOptions(
                        clients
                            ?.slice(0, 11)
                            ?.filter(
                                (agent) => agent?.user?.role === newRoles.User
                            )
                            ?.map((item) => ({
                                ...item?.user,
                                value: `${item?.user?.first_name} ${item?.user?.last_name}`,
                                label: `${item?.user?.first_name} ${item?.user?.last_name}`,
                                agent_id: item?.agent?.id ?? item?.agent_id,
                            }))
                    );
                } else if (onlyOwners) {
                    setClientOptions(
                        clients
                            ?.slice(0, 11)
                            ?.filter(
                                (agent) => agent?.user?.role === newRoles.Owner
                            )
                            ?.map((item) => ({
                                ...item?.user,
                                value: `${item?.user?.first_name} ${item?.user?.last_name}`,
                                label: `${item?.user?.first_name} ${item?.user?.last_name}`,
                                agent_id: item?.agent?.id ?? item?.agent_id,
                            }))
                    );
                } else {
                    setClientOptions(
                        clients?.slice(0, 11)?.map((item) => ({
                            ...item?.user,
                            value: `${item?.user?.first_name} ${item?.user?.last_name}`,
                            label: `${item?.user?.first_name} ${item?.user?.last_name}`,
                            agent_id: item?.agent?.id ?? item?.agent_id,
                        }))
                    );
                }
            } else {
                setClientOptions(
                    clients?.findUsersOutput?.slice(0, 5)?.map((item) => ({
                        ...item,
                        value: `${item?.first_name} ${item?.last_name} (${item?.phone})(${item?.email})`,
                        label: `${item?.first_name} ${
                            item?.last_name
                        } (${`****${item?.phone.slice(-4)}`})`
                    }))
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsTyping(false);
        }
    }, []);

  // memoize the debounce call with useMemo
  const debouncedSendRequest = useMemo(() => {
    return debounce(sendRequest, 1000);
  }, [sendRequest]);

  useEffect(() => {
    sendRequest("");
  }, []);

  return (
    <>
      <RSelect
        options={clientOptions}
        onInputChange={(txt) => {
          setIsTyping(true);
          debouncedSendRequest(txt);
        }}
        {...props}
        noOptionsMessage={() =>
          isTyping ? <Spinner size="sm" color="light" /> : "No options"
        }
      />
    </>
  );
}
