import React, { useCallback, useEffect, useMemo, useState } from "react";
import CallReportListView from "./CallReportListView";
import { useDispatch } from "react-redux";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import { fetchCallReportList } from "../../../../redux/actions/pbx";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const CallReportListContainer = () => {
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const query = queryString.parse(location.search);
    const [page, setPage] = useState(query?.page ? Number(query?.page) : 1);
    const [perPage, setPerPage] = useState(query?.limit ? Number(query?.limit) : 20);
    const toggleRefresh = useCallback(() => setRefresh(!refresh), [refresh]);
    const dispatch = useDispatch();
    const axios = useAxiosPrivate();

    const { callReportList, callReportListLoading, callReportListError } = useSelector((state) => ({ ...state?.pbx }));
    
    useEffect(() => {
      const controller = new AbortController();
      const { signal } = controller;
      
      dispatch(fetchCallReportList(axios, signal));

      return () => {
        controller.abort();
      }
    }, [axios, dispatch, refresh]);

    const paginatedData = useMemo(() => {
      if (!callReportList?.data) return [];
      const start = (page - 1) * perPage;
      const end = start + perPage;
      return callReportList?.data ? callReportList?.data?.ext_call_statistics_list?.slice(start, end) : [];
    }, [callReportList?.data, page, perPage]);

    const MemoCallReportListView = React.memo(CallReportListView);
    return (
      <MemoCallReportListView
        data={{collection: paginatedData, total: callReportList?.data?.total_number}}
        loading={callReportListLoading}
        error={callReportListError}
        refreshFn={toggleRefresh}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    );
  }
  
  export default CallReportListContainer;