import React from "react";

export default function CardShimmer({ numberOfCards = 3 }) {
    return (
        <>
            {Array(numberOfCards)
                .fill(1)
                .map((item, i) => (
                    <div
                        className="grid grid-cols-5 items-stretch justify-start gap-2 relative rounded-md overflow-hidden  cursor-pointer hover:bg-neutral-100 transition-all duration-200 ease-linear bg-neutral-200 min-h-[65px] animate-pulse"
                        key={i}
                    >
                        <div className="col-span-1 overflow-hidden bg-white">
                            <div className="w-full h-full object-fill bg-neutral-300" />
                        </div>
                        <div className="col-span-4">
                            <p className="truncate"></p>
                            <p className="text-xs"></p>
                        </div>
                    </div>
                ))}
        </>
    );
}
