import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { defaultUserImage, getImage } from "../utils/envConfig";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function ClientCard({ client, onClick = (e) => {} }) {
  const [image, setImage] = useState(defaultUserImage);
  const axios = useAxiosPrivate();

  const fetchImage = async () => {
    try {
      if (!client?.photo) {
        return;
      }

      const image = await getImage(client?.photo, axios);
      setImage(image?.signedRequest);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    client?.photo && fetchImage();

    return () => {};
  }, [client?.photo]);

  return (
    <div className="card-item-cont" onClick={onClick}>
      <div className="card-inner-sm">
        <div className="d-flex align-items-center">
          <div
            className="deal-type position-relative"
            style={{ width: "6rem", height: "6rem" }}
          >
            <img
              src={image}
              alt="property item"
              className="rounded-pill position-absolute"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </div>
          <div className="ms-2">
            <p className="deal-number mb-0">
              {client?.firstName} {client?.lastName}
            </p>
            <p className="deal-status mb-0">{client?.phone}</p>
            <p className="deal-status mb-0">{client?.email}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
