import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import exportFromJSON from "export-from-json";
import CopyToClipboard from "react-copy-to-clipboard";
import { Col, Modal, ModalBody, Row, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ModalHeader, ModalFooter, Label } from "reactstrap";
import { DataTablePagination, Icon } from "../Component";
import { useSelector } from "react-redux";
import { newRoles } from "../../utils/envConfig";

const Export = ( { data } ) =>
{
  const [ modal, setModal ] = useState( false );

  useEffect( () =>
  {
    if ( modal === true )
    {
      setTimeout( () => setModal( false ), 2000 );
    }
  }, [ modal ] );

  const fileName = "user-data";

  const exportCSV = () =>
  {
    const exportType = exportFromJSON.types.csv;
    exportFromJSON( { data, fileName, exportType } );
  };

  const exportExcel = () =>
  {
    const exportType = exportFromJSON.types.xls;
    exportFromJSON( { data, fileName, exportType } );
  };

  const copyToClipboard = () =>
  {
    setModal( true );
  };

  return (
    <React.Fragment>
      {/* <div className="dt-export-buttons d-flex align-center">
        <div className="dt-export-title d-none d-md-inline-block">Export</div>
        <div className="dt-buttons btn-group flex-wrap">
          <CopyToClipboard text={ JSON.stringify( data ) }>
            <Button className="buttons-copy buttons-html5" onClick={ () => copyToClipboard() }>
              <span>Copy</span>
            </Button>
          </CopyToClipboard>{ " " }
          <button className="btn btn-secondary buttons-csv buttons-html5" type="button" onClick={ () => exportCSV() }>
            <span>CSV</span>
          </button>{ " " }
          <button className="btn btn-secondary buttons-excel buttons-html5" type="button" onClick={ () => exportExcel() }>
            <span>Excel</span>
          </button>{ " " }
        </div>
      </div> */}
      <Modal isOpen={ modal } className="modal-dialog-centered text-center" size="sm">
        <ModalBody className="text-center m-2">
          <h5>Copied to clipboard</h5>
        </ModalBody>
        <div className="p-3 bg-light">
          <div className="text-center">Copied { data.length } rows to clipboard</div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const ExpandableRowComponent = ( { data } ) =>
{
  return (
    <ul className="dtr-details p-2 border-bottom ms-1">
      <li className="d-block d-sm-none">
        <span className="dtr-title">Task Type</span> <span className="dtr-data">{ data.company }</span>
      </li>
      <li className="d-block d-sm-none">
        <span className="dtr-title ">Gender</span> <span className="dtr-data">{ data.gender }</span>
      </li>
      <li>
        <span className="dtr-title">Start Date</span> <span className="dtr-data">{ data.startDate }</span>
      </li>
      <li>
        <span className="dtr-title">Salary</span> <span className="dtr-data">{ data.salary }</span>
      </li>
    </ul>
  );
};

const CustomCheckbox = React.forwardRef( ( { onClick, ...rest }, ref ) => (
  <div className="custom-control custom-control-sm custom-checkbox notext">
    <input
      id={ rest.name }
      type="checkbox"
      className="custom-control-input"
      ref={ ref }
      onClick={ onClick }
      { ...rest }
    />
    <label className="custom-control-label" htmlFor={ rest.name } />
  </div>
) );

const ReactDataTable = ( { data, columns, pagination, actions, className, selectableRows, expandableRows } ) =>
{
  const [ tableData, setTableData ] = useState( data );
  const [ searchText, setSearchText ] = useState( "" );
  const [ rowsPerPageS, setRowsPerPage ] = useState( 10 );
  const [ mobileView, setMobileView ] = useState();

  const authuser = useSelector( ( state ) => state?.user?.loggedInUser );

  useEffect( () =>
  {
    let defaultData = tableData;
    if ( searchText !== "" )
    {
      defaultData = data.filter( ( item ) =>
      {
        return item.name.toLowerCase().includes( searchText.toLowerCase() );
      } );
      setTableData( defaultData );
    } else
    {
      setTableData( data );
    }
  }, [ searchText ] ); // eslint-disable-line react-hooks/exhaustive-deps

  // function to change the design view under 1200 px
  const viewChange = () =>
  {
    if ( window.innerWidth < 960 && expandableRows )
    {
      setMobileView( true );
    } else
    {
      setMobileView( false );
    }
  };

  useEffect( () =>
  {
    window.addEventListener( "load", viewChange );
    window.addEventListener( "resize", viewChange );
    return () =>
    {
      window.removeEventListener( "resize", viewChange );
    };
  }, [] ); // eslint-disable-line react-hooks/exhaustive-deps
  const [ modalNote, setModalNote ] = useState( false );
  const toggleModalNote = () =>
  {
    setModalNote( !modalNote );
  };

  return (
    <div className={ `dataTables_wrapper dt-bootstrap4 no-footer ${ className ? className : "" }` }>
      <Row className={ `justify-between g-2 ${ actions ? "with-export" : "" }` }>
        { authuser?.role && (authuser?.role !== newRoles?.Agent) && 
        <Col>
          <div className="d-flex align-items-center">

            <Button className="bg-primary text-white" onClick={ toggleModalNote }>
              <Icon name="plus-sm"></Icon> Add Note
            </Button>

            <div id="DataTables_Table_0_filter" className="dataTables_filter ml-2">
              <label>
                <UncontrolledDropdown>
                  <DropdownToggle
                    color="transparent"
                    className="dropdown-toggle dropdown-indicator  bg-light text-black"
                  >
                    Filter By Type
                  </DropdownToggle>
                  <DropdownMenu end>
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <DropdownItem tag="a" href="#dropdownitem" onClick={ ( ev ) => ev.preventDefault() }>
                          <span>Assign</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" href="#dropdownitem" onClick={ ( ev ) => ev.preventDefault() }>
                          <span>Appointment</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" href="#dropdownitem" onClick={ ( ev ) => ev.preventDefault() }>
                          <span>Ask a Question</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem tag="a" href="#dropdownitem" onClick={ ( ev ) => ev.preventDefault() }>
                          <span>Viewing</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </label>
            </div>
          </div>
          <Modal isOpen={ modalNote } toggle={ toggleModalNote } backdrop="static" size="lg">
            <ModalHeader
              toggle={ toggleModalNote }
              close={
                <button className="close" onClick={ toggleModalNote }>
                  <Icon name="cross" />
                </button>
              }
            >
              Add Note
            </ModalHeader>
            <ModalBody>
              <Row className="gy-3">
                <Col sm="12">
                  <Label>Note</Label>
                  <textarea className="form-control no-resize"></textarea>
                </Col>

              </Row>

            </ModalBody>
            <ModalFooter className="border-0">
              <Button className="bg-primary text-white">
                Add Note
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
        }
        <Col className="text-end">

          <div className="d-flex align-items-center justify-content-end">
            <span className="d-none d-sm-inline-block me-2">Show</span>
            <div className="form-control-select">
              <select
                name="DataTables_Table_0_length"
                className="custom-select custom-select-sm form-control form-control-sm"
                onChange={ ( e ) => setRowsPerPage( e.target.value ) }
                value={ rowsPerPageS }
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>

          { actions && <Export data={ data } /> }
        </Col>
      </Row>
      <DataTable
        data={ tableData }
        columns={ columns }
        className={ className }
        selectableRows={ selectableRows }
        selectableRowsComponent={ CustomCheckbox }
        expandableRowsComponent={ ExpandableRowComponent }
        expandableRows={ mobileView }
        noDataComponent={ <div className="p-2">There are no records found</div> }
        sortIcon={
          <div>
            <span>&darr;</span>
            <span>&uarr;</span>
          </div>
        }
        pagination={ pagination }
        paginationComponent={ ( { currentPage, rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage } ) => (
          <DataTablePagination
            customItemPerPage={ rowsPerPageS }
            itemPerPage={ rowsPerPage }
            totalItems={ rowCount }
            paginate={ onChangePage }
            currentPage={ currentPage }
            onChangeRowsPerPage={ onChangeRowsPerPage }
            setRowsPerPage={ setRowsPerPage }
          />
        ) }
      ></DataTable>
    </div>
  );
};

export default ReactDataTable;
