import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGlobalSettings } from '../../../redux/actions/settings';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { LeadStatus, newRoles } from '../../../utils/envConfig';
import { toast } from 'react-toastify';

// keyframes animation in a style block
const keyframesStyle = `
  @keyframes blink {
    0% { opacity: .5; }
    50% { opacity: 1; }
    100% { opacity: .5; }
  }
`;

const NewLeadFlagComponent = ({ lead_created, lead_status,lead_id, ...restProps }) => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
 
  
  const authuser = useSelector((state) => state?.user?.loggedInUser);
  const { access_token } = authuser || "";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };

  const [activityCompletedCount, setActivityCompletedCount] = useState(null);


  useEffect(() => {
    dispatch(fetchGlobalSettings(axios));
    fetchActivitiesCompleted();
  }, [dispatch, axios]);

  const settingsState = useSelector((state) => state.settings);
  const { globalSettings } = settingsState;




  const isDateWithinLimit = (createdDate, daysLimit) => {
    if (!createdDate || !daysLimit) return false;
    const createdTime = new Date(createdDate);
    const dateLimit = new Date();
    dateLimit.setDate(dateLimit.getDate() - daysLimit);
    return createdTime >= dateLimit;
  };

  async function fetchActivitiesCompleted() {
    try {
      let link = `/activities/user/${lead_id}?status=Completed`;

      const res = await axios.get(link, {
        headers,
      });
      setActivityCompletedCount(res.data?.totalItems);
    } catch (err) {
      toast.error("Server error. Try Again !");
    }
  }
  

  const createdDate = lead_created;
  const daysLimit = globalSettings?.global?.newLeadFlagDateLimit ?? 7;
  const isWithinLimit = isDateWithinLimit(createdDate, daysLimit);

  let activityCompleted;
  if(authuser.role === newRoles.Agent){
    activityCompleted = 1;
  }else{
    activityCompleted = 2;
  }
  if (!isWithinLimit || (activityCompletedCount >= activityCompleted)) return null;

  return (
    <div {...restProps}>
     <style>{keyframesStyle}</style> 
      <span
        className="badge bg-danger"
        style={{
          animation: 'blink 1s infinite',
        }}
      >
        New
      </span>
    </div>
  );
};

export default NewLeadFlagComponent;
