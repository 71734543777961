import AvatarA from "../../../assets/images/jsTree/property.jpg";
import AvatarB from "../../../assets/images/jsTree/property.jpg";
import AvatarC from "../../../assets/images/jsTree/property.jpg";
import UserAvatar from "../../../components/user/UserAvatar";

export const columnData = {
  task: {
    "task-1": {
      id: "task-1",
      title: "Wafaa Safieh",
      desc: "Ask a Question",
      meta: {
        users: [ { value: "Jay Kodamukkil ", label: "Jay Kodamukkil", theme: "light" } ],
        tags: [

          { value: "Hot Lead", label: "Hot Lead", theme: "danger" },
          { value: "Call", label: "Call", theme: "success" },
        ],
        date: "15 Dec 2020",
        phone: "+971 521 188 240"

      },
    },
    "task-2": {
      id: "task-2",
      title: "Sangar Abubakr Mirawdali",
      desc: "Facebook Campaign",
      meta: {
        users: [ { value: "Cooper Jones", label: "Cooper Jones", theme: "blue" } ],
        tags: [
          { value: "Hot Lead", label: "Hot Lead", theme: "danger" },
          { value: "Email", label: "Email", theme: "dark" },

        ],
        date: "15 Dec 2020",
        phone: "+971 521 188 240",
        comment: "5",
        attachment: "3",
      },
    },
    "task-3": {
      id: "task-3",
      title: "AMIT NARANG",
      desc: "External REF0103",
      board: "Fresh",
      meta: {
        users: [ { value: "Ashraf Raneem", label: "Ashraf Raneem", theme: "primary" } ],
        tags: [
          { value: "Cold Lead", label: "Cold Lead", theme: "info" },
          { value: "Get Matched Assistance", label: "Get Matched Assistance", theme: "light" },

        ],
        due: "2",
        phone: "+971 521 188 240",
        comment: "4",
        attachment: "1",
      },
    },
    "task-4": {
      id: "task-4",
      title: "AMIT NARANG",
      desc: "External REF0103",
      board: "Fresh",
      meta: {
        users: [ { value: "Ashraf Raneem", label: "Ashraf Raneem", theme: "primary" } ],
        tags: [
          { value: "Cold Lead", label: "Cold Lead", theme: "info" },
          { value: "Get Matched Assistance", label: "Get Matched Assistance", theme: "light" },

        ],
        due: "2",
        phone: "+971 521 188 240",
        comment: "4",
        attachment: "1",
      },
    },
    "task-5": {
      id: "task-5",
      title: "AMIT NARANG",
      desc: "External REF0103",
      board: "Fresh",
      meta: {
        users: [ { value: "Ashraf Raneem", label: "Ashraf Raneem", theme: "primary" } ],
        tags: [
          { value: "Cold Lead", label: "Cold Lead", theme: "info" },
          { value: "Get Matched Assistance", label: "Get Matched Assistance", theme: "light" },

        ],
        due: "2",
        phone: "+971 521 188 240",
        comment: "4",
        attachment: "1",
      },
    },
    "task-6": {
      id: "task-6",
      title: "AMIT NARANG",
      desc: "External REF0103",
      board: "Fresh",
      meta: {
        users: [ { value: "Ashraf Raneem", label: "Ashraf Raneem", theme: "primary" } ],
        tags: [
          { value: "Cold Lead", label: "Cold Lead", theme: "info" },
          { value: "Get Matched Assistance", label: "Get Matched Assistance", theme: "light" },

        ],
        due: "2",
        phone: "+971 521 188 240",
        comment: "4",
        attachment: "1",
      },
      createdAt: 'date',
      updatedAt: 'date'
    },
    "task-7": {
      id: "task-7",
      title: "AMIT NARANG",
      desc: "External REF0103",
      board: "Fresh",
      meta: {
        users: [ { value: "Ashraf Raneem", label: "Ashraf Raneem", theme: "primary" } ],
        tags: [
          { value: "Cold Lead", label: "Cold Lead", theme: "info" },
          { value: "Get Matched Assistance", label: "Get Matched Assistance", theme: "light" },

        ],
        due: "2",
        phone: "+971 521 188 240",
        comment: "4",
        attachment: "1",
      },
    },
    "task-8": {
      id: "task-8",
      title: "AMIT NARANG",
      desc: "External REF0103",
      board: "Fresh",
      meta: {
        users: [ { value: "Ashraf Raneem", label: "Ashraf Raneem", theme: "primary" } ],
        tags: [
          { value: "Cold Lead", label: "Cold Lead", theme: "info" },
          { value: "Get Matched Assistance", label: "Get Matched Assistance", theme: "light" },

        ],
        due: "2",
        phone: "+971 521 188 240",
        comment: "4",
        attachment: "1",
      },
    },
    "task-9": {
      id: "task-9",
      title: "AMIT NARANG",
      desc: "External REF0103",
      board: "Fresh",
      meta: {
        users: [ { value: "Ashraf Raneem", label: "Ashraf Raneem", theme: "primary" } ],
        tags: [
          { value: "Cold Lead", label: "Cold Lead", theme: "info" },
          { value: "Get Matched Assistance", label: "Get Matched Assistance", theme: "light" },

        ],
        due: "2",
        phone: "+971 521 188 240",
        comment: "4",
        attachment: "1",
      },
    },
  },

  columns: {
    "column-unassigned": {
      id: "column-unassigned",
      text: "Unassigned",
      theme: "light",
      tasks: [ "task-1", "task-2", "task-3" ],
    },
    "column-fresh": {
      id: "column-fresh",
      text: "Fresh",
      theme: "primary",
      tasks: [ "task-4", "task-5" ],
    },
    "column-followUp": {
      id: "column-followUp",
      text: "Follow Up",
      theme: "warning",
      tasks: [ "task-8", "task-9" ],
    },
    "column-forListing": {
      id: "column-forListing",
      text: "For Listing",
      theme: "success",
      tasks: [ "task-7", "task-6" ],
    },
    "column-appointment": {
      id: "column-appointment",
      text: "Appointment",
      theme: "primary",
      tasks: [ "task-7", "task-6" ],
    },
    "column-viewing": {
      id: "column-viewing",
      text: "Viewing",
      theme: "info",
      tasks: [ "task-7", "task-6" ],
    },
    "column-dealNegotiation": {
      id: "column-dealNegotiation",
      text: "Deal Negotiation",
      theme: "dark",
      tasks: [ "task-7", "task-6" ],
    },
    "column-won": {
      id: "column-won",
      text: "Won",
      theme: "success",
      tasks: [ "task-7", "task-6" ],
    },
    "column-lost": {
      id: "column-lost",
      text: "Lost",
      theme: "danger",
      tasks: [ "task-7", "task-6" ],
    },
    "column-disqualified": {
      id: "column-disqualified",
      text: "Disqualified",
      theme: "secondary",
      tasks: [ "task-7", "task-6" ],
    },

  },
  columnOrder: [ "column-unassigned", "column-fresh", "column-followUp",
    "column-forListing", "column-appointment", "column-viewing",
    "column-dealNegotiation", "column-won", "column-lost", "column-disqualified" ],
};

export const tagSet = [
  {
    value: "Assign",
    label: "Assign",
    theme: "info",
  },
  {
    value: "Call",
    label: "Call",
    theme: "success",
  },
  {
    value: "Appointment",
    label: "Appointment",
    theme: "warning",
  },
  {
    value: "Viewing",
    label: "Viewing",
    theme: "dark",
  },
  {
    value: "Ask A Question",
    label: "Ask A Question",
    theme: "dark",
  },
  {
    value: "Whatsapp",
    label: "Whatsapp",
    theme: "danger",
  },
  {
    value: "SMS Message",
    label: "SMS Message",
    theme: "success",
  },
  {
    value: "Email",
    label: "Email",
    theme: "grey",
  },
];

export const teamList = [
  {
    value: "Property one ", label: <span className="user-card">
      <UserAvatar image={ AvatarA } className="sm bg-light" /><span className="user-name">Property one</span></span>
  },
  {
    value: "Property Two", label: <span className="user-card">
      <UserAvatar image={ AvatarB } className="sm" /><span className="user-name">Property Two</span></span>
  },
  {
    value: "Property Three", label: <span className="user-card">
      <UserAvatar image={ AvatarB } className="sm" /><span className="user-name">Property Three</span></span>
  },
  {
    value: "Property Four", label: <span className="user-card">
      <UserAvatar image={ AvatarC } className="sm" /><span className="user-name">Property Four</span></span>
  }
];

export const themes = [
  { value: "primary", label: "Primary" },
  { value: "secondary", label: "Secondary" },
  { value: "info", label: "Info" },
  { value: "danger", label: "Danger" },
  { value: "warning", label: "Warning" },
  { value: "success", label: "Success" },
  { value: "dark", label: "Dark" },
  { value: "light", label: "Light" },
];
