import React from "react";
import AsyncSelect from "react-select/async";
import { Spinner } from "reactstrap";

const RSelectAsync = ({
  loadOptionsFn,
  placeholder = null,
  defaultOptions = [],
  cacheOptions = true,
  isLoading = false,
  isClearable = false,
  loadingMessage = () => <Spinner />,
  ...props
}) => {
  /**
   * React-select component with async call functionality
   * provide a "loadOptionsFn" prop with the async call needed
   * defaultOptions can be provided as initial state
   */
  return (
    <div className="form-control-select">
      <AsyncSelect
        placeholder={placeholder}
        defaultOptions={defaultOptions}
        cacheOptions
        isClearable={isClearable}
        isLoading={isLoading}
        loadingMessage={loadingMessage}
        loadOptions={loadOptionsFn}
        className={`react-select-container ${
          props.className ? props.className : ""
        }`}
        classNamePrefix="react-select"
        {...props}
      />
    </div>
  );
};

export default RSelectAsync;
