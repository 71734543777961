import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { QueryClientProvider } from "react-query";
import queryClient from "./api/queryClient";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <React.Fragment>
            <Suspense fallback={<div />}>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </Suspense>
        </React.Fragment>
    </Provider>

    //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
