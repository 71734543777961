import { useEffect, useState } from "react";
import { findUpper } from "../../../utils/Utils";
import { getImage } from "../../../utils/envConfig";
import { UserAvatar } from "../../../components/Component";
import { Spinner } from "reactstrap";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useQuery } from "react-query";
import { showToast } from "../../../utils/toast/toast";


const ONE_HOUR = 60 * 60 * 1000;

const NewAgentAvatar = ({ agent, size = "xs", className = "", text = "", style = {}}) => {
    const [avatar, setAvatar] = useState(null);

    const axios = useAxiosPrivate()

    const { data: resData, isLoading } = useQuery(
        ['userImage', (agent?.userId?.id ?? agent?.userId?._id ?? agent?.user?.id ?? agent?.user?._id)],
        () => getImage(agent?.userId?.photo ?? agent?.user?.photo ?? "", axios),
        {
            // eslint-disable-next-line eqeqeq
            enabled: (agent?.user?.id ?? agent?.user?._id ?? agent?.userId?.id ?? agent?.userId?._id ?? agent?.userId) != undefined && (agent?.userId?.photo ?? agent?.user?.photo) != undefined,
            staleTime: ONE_HOUR,
            refetchOnWindowFocus: false,
            refetchInterval: ONE_HOUR,
            onError: (err) => {
                console.log(err);
                showToast('Error fetching user image', 'error');
            }
        }
    )
    useEffect(() => {
        function updateImageAvatar() {
          // const resData = await getImage(agent?.userId?.photo ?? "", axios);
          const s3url = resData?.signedRequest;
          if (s3url) {
              setAvatar(s3url);
          }
        }
        updateImageAvatar();
    }, [agent, axios, resData?.signedRequest]);

    return (
        <>
            {!isLoading ? (
                <UserAvatar
                    style={style}
                    className={`${size} ${className}`}
                    text={findUpper(agent?.userId?.first_name ?? agent?.user?.first_name ?? text)}
                    image={avatar}
                ></UserAvatar>
            ) : (
                <Spinner
                    style={{ width: "1rem", height: "1rem" }}
                />
            )}
        </>
    );
};

export default NewAgentAvatar;
