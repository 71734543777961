
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Icon, PreviewCard } from "../../components/Component";
import Head from "../../layout/head/Head";
import React, { useState } from "react";
import PageContainer from "../../layout/page-container/PageContainer";
import { Button, Form, Spinner } from "reactstrap";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSelector, useDispatch } from "react-redux";
import * as types from "../../redux/types";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const ChangePassword = () =>
{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [ passState, setPassState ] = useState( false );
    const [ newState, setNewState ] = useState( false );
    const [ confirmState, setConfirmState ] = useState( false );
    const user = useSelector((state) => state?.user?.loggedInUser);
    const [loading, setLoading] = useState(false);
    const [cpassword, setCPassword] = useState("");
    const axios = useAxiosPrivate();

    const logOut = async () => {
        try {
            await axios.get("/auth/logout");
        } catch (err) {
            // console.log(err);
        }

        dispatch({
            type: types.LOGOUT,
            payload: {},
        });

        navigate("/auth-login", {
            replace: true,
            state: {
                from: {
                    pathname: location.pathname,
                },
            },
        });
    };
    const [password, setPassword] = useState({
      oldPassword: "",
      newPassword: "",
    });
  
    const handleChangePassword = (e) => {
      const { name, value } = e.target;
      setPassword((prev) => ({ ...prev, [name]: value }));
    };

  
    const validatePassword = (newPassword, oldPassword) => {
      if (!newPassword) {
        toast.error("Please Enter New Password");
        return false;
      }
      if (!oldPassword) {
        toast.error("Please Enter Old Password");
        return false;
      }
      // Test if password contains atleast one uppercase letter
      const hasUppercase = /^(?=.*[A-Z])/.test(newPassword);
      if (!hasUppercase) {
        toast.error("Password should contain atleast 1 uppercase character");
        return false;
      }
  
      // Test if password contains atleast one lowercase letter
      const hasLowercase = /^(?=.*[a-z])/.test(newPassword);
      if (!hasLowercase) {
        toast.error("Password should contain atleast 1 lowercase character");
        return false;
      }
  
      // Test if password contains atleast one digit
      const hasDigit = /^(?=.*\d)/.test(newPassword);
      if (!hasDigit) {
        toast.error("Password should contain atleast 1 number");
        return false;
      }
  
      // Test if password contains atleast one special character
      const hasSpecialChar = /^(?=.*[!@#$%^&*()_\-+={}[\]:;\"'<>,.?\/])/.test(
        newPassword
      );
      if (!hasSpecialChar) {
        toast.error("Password should contain atleast 1 special character");
        return false;
      }
  
      // Test if password does not contain any whitespace characters
      const noWhitespace = /^(?!.*\s)/.test(newPassword);
      if (!noWhitespace) {
        toast.error("Password should not contain any whitespaces");
        return false;
      }
  
      // Test if password is atleast 8 characters long
      const isMinimumLength = /^.{8,}$/.test(newPassword);
      if (!isMinimumLength) {
        toast.error("Password should be atleast 8 characters long");
        return false;
      }
  
      if (cpassword !== newPassword) {
        toast.error("Passwords don't match");
        return false;
      } else {
        return true;
      }
    };

    const handlePasswordChange = async (e) => {
      e.preventDefault();
      if (validatePassword(password?.newPassword, password?.oldPassword)) {
        setLoading(true);
  
        try {
          await axios
            .post(`reset/password/${user.id}`, password, 
            )
            .then((results) => {
              setLoading(false);
              toast.success("Password changed successfully");
              logOut();
            })
            .catch((err) => {
              setLoading(false);
              toast.error(err.response.data.message);
            });
        } catch (err) {
          toast.error("Error");
        }
      }
    };

    return (
        <React.Fragment>
            <Head title="Login" />
            <PageContainer>
                <Block className="nk-block-middle nk-auth-body  wide-xs">
                    <div className="brand-logo pb-4 text-center">

                    </div>
                    <PreviewCard
                        className="card-bordered"
                        bodyClass="card-inner-lg"
                    >
                        
                        <BlockHead>
                            <BlockContent>
                                <BlockTitle tag="h4">Change Password</BlockTitle>

                            </BlockContent>
                        </BlockHead>
                        {/* <Form > */}
                        <div style={{ 
                            fontSize: '14px', 
                            //   fontStyle: 'italic', 
                            color: 'gray', 
                            marginBottom: '10px' 
                            }}>
                            Create a strong password: Minimum 8 characters with uppercase, lowercase, numbers, special characters, no spaces.
                            </div>

                            <div className="form-group">
                                <div className="form-label-group">
                                    <label
                                        className="form-label"
                                        htmlFor="curernt"
                                    >
                                        Current Password
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <a
                                        href="#curernt"
                                        onClick={ ( ev ) =>
                                        {
                                            ev.preventDefault();
                                            setPassState( !passState );
                                        } }
                                        className={ `form-icon lg form-icon-right passcode-switch ${ passState ? "is-hidden" : "is-shown"
                                            }` }
                                    >
                                        <Icon
                                            name="eye"
                                            className="passcode-icon icon-show"
                                        ></Icon>

                                        <Icon
                                            name="eye-off"
                                            className="passcode-icon icon-hide"
                                        ></Icon>
                                    </a>
                                    <input
                                        type={ passState ? "text" : "password" }
                                        id="curernt"
                                        name="oldPassword"

                                        onChange={handleChangePassword}
                                        defaultValue={password.oldPassword}
                                     
                                        required
                                        placeholder="Enter your current password"
                                        className={ `form-control-lg form-control ${ passState ? "is-hidden" : "is-shown"
                                            }` }
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="form-label-group">
                                    <label
                                        className="form-label"
                                        htmlFor="new"
                                    >
                                        New Password
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <a
                                        href="#new"
                                        onClick={ ( ev ) =>
                                        {
                                            ev.preventDefault();
                                            setNewState( !newState );
                                        } }
                                        className={ `form-icon lg form-icon-right passcode-switch ${ newState ? "is-hidden" : "is-shown"
                                            }` }
                                    >
                                        <Icon
                                            name="eye"
                                            className="passcode-icon icon-show"
                                        ></Icon>
                                        <Icon
                                            name="eye-off"
                                            className="passcode-icon icon-hide"
                                        ></Icon>
                                    </a>
                                    <input
                                    onChange={handleChangePassword}
                                    defaultValue={password.newPassword}
                                        type={ newState ? "text" : "password" }
                                        id="new"
                                        name="newPassword"
                                        placeholder="Enter your new password"
                                        className={ `form-control-lg form-control ${ newState ? "is-hidden" : "is-shown"
                                            }` }
                                    />

                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label
                                        className="form-label"
                                        htmlFor="confirm"
                                    >
                                        Confirm New Password
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <a
                                        href="#confirm"
                                        onClick={ ( ev ) =>
                                        {
                                            ev.preventDefault();
                                            setConfirmState( !confirmState );
                                        } }
                                        className={ `form-icon lg form-icon-right passcode-switch ${ confirmState ? "is-hidden" : "is-shown"
                                            }` }
                                    >
                                        <Icon
                                            name="eye"
                                            className="passcode-icon icon-show"
                                        ></Icon>
                                        <Icon
                                            name="eye-off"
                                            className="passcode-icon icon-hide"
                                        ></Icon>
                                    </a>
                                    <input
                                    onChange={(e) => setCPassword(e.target.value)}
                                    value={cpassword}
                                        type={ confirmState ? "text" : "password" }
                                        id="confirm"
                                        name="confirm"
                                        placeholder="Confirm your new password"
                                        className={ `form-control-lg form-control ${ confirmState ? "is-hidden" : "is-shown"
                                            }` }
                                    />

                                </div>
                            </div>
                            <div className="form-group">
                                <Button
                            onClick={(e) => handlePasswordChange(e)}
                                    className="btn-block"
                                    type="submit"
                                    color="primary"
                                >
                                    { loading ? (
                                            <Spinner size="sm" color="light" />
                                            ) : (
                                            "Confirm"
                                            ) }                                    
                                </Button>
                            </div>
                        {/* </Form> */}
                    </PreviewCard>

                </Block>
            </PageContainer>
        </React.Fragment> );
};
export default ChangePassword;