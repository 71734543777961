import * as types from "../types";

// Get all deals - Filter and Pagination
export const fetchDeals = (axios, filters) => async (dispatch) => {
  try {
    const {data} = await axios
      .get(`/search/deal/filter/${filters}`)

    dispatch({
      type : types.DEAL_LOADING,
      payload : true
    })

    dispatch({
        type: types.GET_DEALS,
        payload: data,
    });
  } catch (error) {
    console.log(error);
  }finally{
    dispatch({
        type: types.DEAL_LOADING,
        payload: false,
    });
  }
};

// Get a deal by Id
export const fetchDeal = (axios, dealId) => async (dispatch) => {
  try {
    await axios
      .get(`/deals/${dealId}`)
      .then((res) => {
        dispatch({
          type: types.GET_DEAL,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};

// Add a new deal
export const addDeal = (axios, formData) => async (dispatch) => {
  try {
    await axios
      .post(`/deals`, formData)
      .then((res) => {
        dispatch({
          type: types.ADD_DEAL,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};

// Progress deal by dealId
export const progressDeal = (axios, dealId, formData) => async (dispatch) => {
  try {
    await axios
      .post(`/deals/progress/${dealId}`, formData)
      .then((res) => {
        dispatch({
          type: types.PROGRESS_DEAL,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};
