import InputPhone, { isValidPhoneNumber } from 'react-phone-number-input';

export default function PhoneInput(props) {
    return (
        <InputPhone
            defaultCountry="AE"
            {...props}
            onChange={(phone) => {
                const isValid = phone?.trim()?.replaceAll(' ', '')?.length
                    ? isValidPhoneNumber(phone)
                    : true;

                props.onChange(phone);
                props?.onError &&
                    props.onError(isValid ? '' : 'Invalid phone number');
            }}
        />
    );
}
