import React, { useEffect, useMemo, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import {
  Block,
  BlockDes,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  Row,
  Col,
  RSelect,
  PaginationComponent,
} from '../../../components/Component';
import {
  Button,
  Modal,
  ModalBody,
  Input,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {
    leadSourceTypes
} from '../../../utils/envConfig';
import 'react-phone-number-input/style.css';
import { useSelector } from 'react-redux';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../components/miscellaneous/ConfirmModal';
import { fetchLeadSource } from '../../../redux/actions/lead-source';

const LeadSourcesTable = ( { title} ) =>
  {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();

  // State Variables
  const [page, setPage] = useState(1);
  const [limit, setPerPage] = useState(10);

  //form variables
  const [leadSourceName, setLeadSourceName] = useState("");
  const [leadSourceType, setLeadSourceType] = useState("");
  const [leadSourceIdDelete, setLeadSourceIdDelete] = useState("");
  const [leadSourceNameDelete, setLeadSourceNameDelete] = useState("");
  const [leadSourceIdEdit, setLeadSourceIdEdit ] = useState( "" );

  //filter variables
  const [searchText, setSearchText] = useState("");
  const [activeStatus, setActiveStatus] = useState(true);
  const [filterLeadSourceType, setFilterLeadSourceType] = useState("");
  
  const [ openEdit, setOpenEdit ] = useState( false );

  
  const handleEdit = ( e, item ) =>
    {
        e.preventDefault();
        setOpenEdit( true );
        setAddLoading(false);
        setLeadSourceIdEdit( item.id);
        setLeadSourceName( item.name );
        setLeadSourceType( item.leadSourceType );
    };

  const [addLeadSourceModalOpen, setAddLeadSourceModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const url = '/leadsource/';
            const params = {
                page,
                limit,
                search: searchText,
                active: activeStatus,
                leadSourceType : filterLeadSourceType,
            };
            try {
                const response = await axios.get(url, { params });
                dispatch(fetchLeadSource(response.data)); 
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [dispatch, page, limit, searchText,loading,activeStatus,filterLeadSourceType]);

    const leadSourceList = useSelector((state) => state?.leadSources?.leadSources);

    const filteredData = leadSourceList?.data;
    const filteredCountData = useMemo(() => leadSourceList?.filtered, [leadSourceList]);

    const handleSearchSubmit = (event) => {
        setSearchText(event.target.value);
    };

    const handleStatusOption = (status) => {
        setActiveStatus(status === 'Active');
    };

    const handlePerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    // Input Validation
    const validate = (leadSourceName, leadSourceType) => {
        if (!leadSourceName) {
            toast.error("Enter Lead Source Name");
            return false;
        }
        if (!leadSourceType) {
            toast.error("Enter Lead Source Type");
            return false;
        } else {
            return true;
        }
    };

    // Add Lead Source in DB
    const addLeadSource = async (e) => {
        setAddLoading(true);
        e.preventDefault();
        if (validate(leadSourceName, leadSourceType)) {
            const formData = {
                name: leadSourceName,
                leadSourceType: leadSourceType
            };
            try {
                await axios.post(`leadsource`, formData);
                toast.success("Successfully added Lead Source");
                resetFormFields();                
                setAddLeadSourceModalOpen(false);
            } catch (error) {
                if (error.response) {
                    const { data } = error.response;
                    toast.error(data?.message || 'Server error. Please try again.');
                } else if (error.request) {
                    toast.error('No response received from the server. Please try again.');
                } else {
                    toast.error('An unexpected error occurred. Please try again later.');
                }
            }            
        } 
        setAddLoading(false);
        setLoading(!loading);
    };

    // Edit Lead Source in DB
    const editLeadSource = async (e) => {
        setAddLoading(true);
        e.preventDefault();
        if (validate(leadSourceName, leadSourceType)) {
            const formData = {
                name: leadSourceName,
                leadSourceType: leadSourceType
            };
            try {
                await axios.put(`leadsource/${leadSourceIdEdit}`, formData);
                toast.success("Successfully updated Lead Source");
                resetFormFields();
                setOpenEdit(false);
            } catch (error) {
                if (error.response) {
                    const { data } = error.response;
                    toast.error(data?.message || 'Server error. Please try again.');
                } else if (error.request) {
                    toast.error('No response received from the server. Please try again.');
                } else {
                    toast.error('An unexpected error occurred. Please try again later.');
                }
            }            
        }         
        setLoading(!loading);
        setAddLoading(false);
    };

    const resetFormFields = () => {
        setLeadSourceName('');
        setLeadSourceType('');
    };

    const handleDeleteModal = (e, id, name) => {
        e.preventDefault();
        setLeadSourceIdDelete(id);
        setLeadSourceNameDelete(name);
        setDeleteShowModal(true);
    };

    // Soft Delete Lead Source and change active status to false
    const submitDel = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`/leadsource/remove/${leadSourceIdDelete}`);
            toast.success("Successfully deleted Lead Source");
        } catch (error) {
            if (error.response) {
                const { data } = error.response;
                toast.error(data?.message || 'Server error. Please try again.');
            } else if (error.request) {
                toast.error('No response received from the server. Please try again.');
            } else {
                toast.error('An unexpected error occurred. Please try again later.');
            }
        }
        setDeleteShowModal(false);
        setLoading(!loading);
    };

    const closeModal = () => {
        setDeleteShowModal(false);
    };

    let clearBadge =
    filterLeadSourceType !== "" ||
    searchText !== ""; 

  return (
    <React.Fragment>
    <Head title="Lead Source"></Head>
    <Content>
      <BlockHead size="sm">
        <BlockBetween>
            <BlockHeadContent>
                <BlockTitle>{title}</BlockTitle>
                <BlockDes className="text-soft">
                    <p>You have total {filteredCountData} {'Lead Source'}.</p>
                </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                    <div className="toggle-expand-content">
                        <ul className="nk-block-tools g-3">
                            <li className="nk-block-tools-opt">
                                <Button
                                    className="toggle d-none d-md-inline-flex"
                                    color="primary"
                                    onClick={() => {
                                        setAddLeadSourceModalOpen(true);
                                        setAddLoading(false);
                                        resetFormFields();
                                    }}
                                >
                                    <Icon name="plus"></Icon>
                                    <span>Add Lead Source</span>
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
            </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
        <div className="my-3 d-flex flex-wrap align-items-center justify-content-between">
        {/* Status Dropdown */}
        <UncontrolledDropdown className="me-3">
            <DropdownToggle
            color="transparent"
            className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
            >
            {activeStatus ? "Active" : "Inactive"}
            </DropdownToggle>
            <DropdownMenu>
            <ul className="link-list-opt no-bdr">
                <li>
                <DropdownItem onClick={() => handleStatusOption("Active")}>
                    <span>Active</span>
                </DropdownItem>
                </li>
                <li>
                <DropdownItem onClick={() => handleStatusOption("Inactive")}>
                    <span>Inactive</span>
                </DropdownItem>
                </li>
            </ul>
            </DropdownMenu>
        </UncontrolledDropdown>

        {/* Lead Source Type Dropdown */}
        <UncontrolledDropdown className="me-3">
            <DropdownToggle
            color="transparent"
            className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
            >
            {filterLeadSourceType ? filterLeadSourceType : "Lead Source Type"}
            </DropdownToggle>
            <DropdownMenu start>
            <ul className="link-list-opt no-bdr">
                <li>
                <DropdownItem onClick={() => setFilterLeadSourceType("")}>
                    <span>All</span>
                </DropdownItem>
                </li>
                {Object.keys(leadSourceTypes).map((key) => (
                <li key={key}>
                    <DropdownItem onClick={() => setFilterLeadSourceType(leadSourceTypes[key])}>
                    <span>{leadSourceTypes[key]}</span>
                    </DropdownItem>
                </li>
                ))}
            </ul>
            </DropdownMenu>
        </UncontrolledDropdown>

        {/* Search bar */}
        <div className="flex-grow-1 mx-3">
            <Input 
            placeholder="Search Lead Source..." 
            type="text" 
            name="search" 
            value={searchText}
            style={{ minWidth: "20rem" }} 
            onChange={handleSearchSubmit}
            className="form-control"
            />
        </div>

        {/* Entries per page config */}
        <div className="d-flex align-items-center">
            <span className="d-none d-sm-inline-block me-2">Show</span>
            <div className="form-control-select">
            <select
                name="DataTables_Table_0_length"
                className="custom-select custom-select-sm form-control form-control-sm"
                onChange={handlePerPageChange}
                value={limit}
            >
                <option value="1">1</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
            </select>
            </div>
        </div>
        </div>
        <Row className={'d-flex mx-0 py-3 w-100'}>
              <Col xs="12" className="d-flex justify-content-end">
                {filterLeadSourceType && (
                  <div className="selected-options d-flex flex-wrap">
                    <Badge className="selected-option bg-primary me-1" color="primary">
                      {filterLeadSourceType}
                      <Icon
                        name="cross-sm"
                        className="ms-1"
                        onClick={() => {
                            setFilterLeadSourceType('');
                        }}
                      />
                    </Badge>
                  </div>
                )}
                {searchText && (
                  <div className="selected-options d-flex flex-wrap">
                    <Badge className="selected-option bg-primary me-1" color="info">
                      Search : {searchText}
                      <Icon
                        name="cross-sm"
                        className="ms-1"
                        onClick={() => {
                            setSearchText('');
                        }}
                      />
                    </Badge>
                  </div>
                )}
                {clearBadge && (
                  <Badge
                    className="selected-option bg-primary"
                    color="danger"
                    onClick={() => {
                        setFilterLeadSourceType('');
                        setSearchText('');
                    }}
                  >
                    {"Clear All"}
                  </Badge>
                )}
              </Col>
            </Row>
        <Block>
            {filteredData && (
                <div className="nk-tb-list is-separate is-medium mb-3">
                    <DataTableHead className="nk-tb-item">
                        <DataTableRow>
                            <span className="sub-text">
                            Name
                            </span>
                        </DataTableRow>
                        <DataTableRow>
                            <span className="sub-text">
                            Type
                            </span>
                        </DataTableRow>
                        <DataTableRow>
                            <span className="sub-text">
                            Status
                            </span>
                        </DataTableRow>
                        <DataTableRow>
                            <span className="sub-text">Edit</span>
                        </DataTableRow>
                        <DataTableRow>
                            <span className="sub-text">Delete</span>
                        </DataTableRow>
                    </DataTableHead>
                    {Array.isArray(filteredData) && filteredData.length > 0 ? (
                          filteredData.map((item, index) => (
                              <DataTableItem key={index}>
                                  <DataTableRow>
                                  {item?.name}
                                  </DataTableRow>
                                  <DataTableRow>
                                  <Badge
                                    color={
                                        item?.leadSourceType === leadSourceTypes?.Hot
                                        ? "danger"
                                        : item?.leadSourceType === leadSourceTypes?.Cold
                                        ? "secondary"
                                        : "default"
                                    }
                                    >
                                    {item?.leadSourceType}
                                    </Badge>
                                  </DataTableRow>
                                  <DataTableRow>
                                  {item?.active ? "Active" : "Inactive"}
                                  </DataTableRow>
                                  <DataTableRow>
                                    <a
                                        href="#"
                                        onClick={ ( ev ) =>
                                            handleEdit( ev, item )
                                        }
                                    >
                                        <Icon
                                            name="edit"
                                            style={ {
                                                fontSize: "1.3rem",
                                            } }
                                        ></Icon>
                                    </a>
                                  </DataTableRow>
                                  <DataTableRow>
                                  <a className="btn btn-icon"
                                     onClick={(e) =>
                                        handleDeleteModal(
                                            e,
                                            item?.id,
                                            item?.name
                                        )
                                    }
                                    style={!item?.active ? { pointerEvents: "none", opacity: 0.5 } : {}}>
                                        <Icon
                                            name="trash"
                                            style={!item?.active ? { pointerEvents: "none", opacity: 0.7 } : {}}
                                        />
                                    </a>
                                  </DataTableRow>
                              </DataTableItem>
                          ))
                      ) : (
                        <div>No results available</div>
                      )}

                    {Array.isArray(filteredData) && filteredCountData > 0 ? 
                                    <PaginationComponent
                                        noDown
                                        currentPage={page}
                                        paginate={(page) => {
                                            setPage(page);
                                        }}
                                        itemPerPage={limit}
                                        totalItems={filteredCountData}
                                    /> 
                                    : null
                                }
                </div>
            )}
        </Block>
        <Modal
          isOpen={addLeadSourceModalOpen}
          toggle={() => setAddLeadSourceModalOpen(false)}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
              <a href="#cancel" className="close">
                  {" "}
                  <Icon
                      name="cross-sm"
                      onClick={(ev) => {
                          ev.preventDefault();
                          setAddLeadSourceModalOpen(false);
                      }}
                  ></Icon>
              </a>
              <div className="p-2">
                  <h5 className="title">Add Lead Source</h5>
                  <div className="mt-4">
                      <form
                          onSubmit={(e) =>
                              addLeadSource(e)
                          }
                      >
                        <Row className="g-3">
                        <Col sm="6">
                            <div className="form-group">
                            <label className="form-label">Lead Source Name *</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={leadSourceName}
                                    onChange={(e) =>
                                    setLeadSourceName(
                                            e.target
                                                .value
                                        )
                                    }
                                />
                            </div>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="form-group">
                                <label
                                    className="form-label"
                                    htmlFor="customer"
                                >
                                    Type
                                </label>
                                <div className="form-control-wrap">
                                <RSelect
                                    options={Object.values(leadSourceTypes).map((x) => ({
                                        label: x,
                                        value: x,
                                    }))}
                                    placeholder="Choose Type"
                                    onChange={(selectedOption) => {
                                        const selectedValue = selectedOption ? selectedOption.value : '';
                                        setLeadSourceType(selectedValue);
                                    }}
                                />
                                </div>
                            </div>
                        </Col>
                        <Col size="12" className="d-flex justify-content-end">
                            <Button
                                color="primary"
                                type="submit"
                            >
                                {/* <Icon className="plus"></Icon> */}
                                <span>
                                {addLoading
                                        ? "Loading.."
                                        : "Add"}
                                </span>
                            </Button>
                        </Col>
                        </Row>
                      </form>
                  </div>
              </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={ openEdit }
          toggle={ () => setOpenEdit( false ) }
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
              <a href="#cancel" className="close">
                  {" "}
                  <Icon
                      name="cross-sm"
                      onClick={(ev) => {
                          ev.preventDefault();
                          setOpenEdit(false);
                      }}
                  ></Icon>
              </a>
              <div className="p-2">
                  <h5 className="title">Edit Lead Source</h5>
                  <div className="mt-4">
                      <form
                          onSubmit={(e) =>
                              editLeadSource(e)
                          }
                      >
                          <Row className="g-3">
                          <Col sm="6">
                            <div className="form-group">
                            <label className="form-label">Lead Source Name *</label>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={leadSourceName}
                                    onChange={(e) =>
                                    setLeadSourceName(
                                            e.target
                                                .value
                                        )
                                    }
                                />
                            </div>
                            </div>
                        </Col>
                              <Col md="6">
                                  <div className="form-group">
                                      <label
                                          className="form-label"
                                          htmlFor="customer"
                                      >
                                          Type
                                      </label>
                                      <div className="form-control-wrap">
                                        <RSelect
                                            options={Object.values(leadSourceTypes).map((x) => ({
                                                label: x,
                                                value: x,
                                            }))}
                                            placeholder="Choose Type"
                                            value={{ label: leadSourceType, value: leadSourceType }}
                                            onChange={(selectedOption) => {
                                                const selectedValue = selectedOption ? selectedOption.value : '';
                                                setLeadSourceType(selectedValue);
                                            }}
                                        />
                                      </div>
                                  </div>
                              </Col>
                              <Col size="12" className="d-flex justify-content-end">
                                  <Button
                                      color="primary"
                                      type="submit"
                                  >
                                      {/* <Icon className="plus"></Icon> */}
                                      <span>
                                      {addLoading
                                              ? "Loading.."
                                              : "Update"}
                                      </span>
                                  </Button>
                              </Col>
                          </Row>
                      </form>
                  </div>
              </div>
          </ModalBody>
        </Modal>
        {showDeleteModal ? (
            <ConfirmModal
                msg={"Delete "}
                name={"Lead Source: " + leadSourceNameDelete}
                open={showDeleteModal}
                handleClick={handleDeleteModal}
                handleConfirm={submitDel}
                handlecloseModal={closeModal}
                action='Delete'
            />
        ) : null}
    </Content>
    </React.Fragment>
  );
};

export default LeadSourcesTable;
