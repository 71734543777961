import { Card, CardBody, CardFooter } from "reactstrap";
import { humanizeDate } from "../../../../users/components/property-cards/utils";
import { useState } from "react";
import { Icon } from "../../../../../components/Component";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { HttpStatus } from "../../../../../utils/envConfig";
import ConfirmationModal from "../../../../../components/modals/ConfirmationModal";
import NoteEditModal from "./NoteEditModal";

const PropCardNote = ({ note, refreshFn, isAdminOrAssigned = false }) => {
  const axios = useAxiosPrivate();
  const [isHovered, setIsHovered] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const toggleConfirmDeleteModal = () =>
    setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen);

  const deleteNote = async () => {
    try {
      const res = await axios.delete(
        `/property-cards/${note?.propertyCard}/notes/${note?.id}`
      );
      if (res.status === HttpStatus.NO_CONTENT) {
        toast.success("Deleted note successfully.");
        toggleConfirmDeleteModal();
        refreshFn();
      }
    } catch (error) {
      console.log(error);
      toast.error("Couldn't delete note, an error occurred.");
    }
  };

  return (
    <>
      <Card
        className="flex-shrink-1"
        color="warning-dim"
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        <div
          className="d-flex justify-content-end py-1"
          style={{ width: "100%", height: "20px" }}
        >
          {isHovered && isAdminOrAssigned ? (
            <>
              <span className="cursor-pointer" onClick={toggleEditModal}>
                <Icon name="edit" />
              </span>
              <span
                className="mx-2 text-danger cursor-pointer"
                onClick={toggleConfirmDeleteModal}
              >
                <Icon name="cross" />
              </span>
            </>
          ) : null}
        </div>
        <CardBody className="">
          <p style={{ maxWidth: "40ch" }}>{note?.message}</p>
        </CardBody>
        <CardFooter className="bg-warning-dim" style={{ padding: "0.5rem" }}>
          <div className="">
            <div className="text-end" style={{ fontSize: "75%" }}>
              <span className="fw-bold">
                {note?.createdBy.first_name} {note?.createdBy?.last_name ?? ""}
              </span>
              , on{" "}
              <span>{humanizeDate(note?.createdAt, { addTime: true })}</span>
            </div>
          </div>
        </CardFooter>
      </Card>
      <ConfirmationModal
        isOpen={isConfirmDeleteModalOpen}
        toggleFn={toggleConfirmDeleteModal}
        title={"Confirm Deleting Note"}
        isDeletion={true}
        size="md"
        actionFn={deleteNote}
      >
        <p>Are you sure you want to delete this note?</p>
      </ConfirmationModal>
      <NoteEditModal
        note={note}
        isOpen={isEditModalOpen}
        toggleFn={toggleEditModal}
        refreshFn={refreshFn}
      />
    </>
  );
};

export default PropCardNote;
