import { useMemo, useState } from 'react';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useQuery } from 'react-query';
import { removeNulls } from '../../../finances/deal-commissions/utils';
import Skeleton from 'react-loading-skeleton';
import { Card } from 'reactstrap';
import { currencyFormatter } from '../../../explorer/utils';

const FIFTEEN_MINUTES = 15 * 60 * 1000;
const THIRTY_MINUTES = 30 * 60 * 1000;

const EarningsHeader = () => {
  const axios = useAxiosPrivate();

  const initialQueryParams = useMemo(
    () => ({
      fromDate: null,
      toDate: null,
      transactions: {
        fromPaymentDate: null,
        toPaymentDate: null
      }
    }),
    []
  );

  // eslint-disable-next-line no-unused-vars
  const [queryParams, setQueryParams] = useState(initialQueryParams);

  const { data, isError, isLoading, refetch } = useQuery(
    ['finances', queryParams],
    async () => {
      const res = await axios.get('/deals/finances', {
        params: removeNulls(queryParams)
      });
      return res?.data;
    },
    {
      staleTime: FIFTEEN_MINUTES,
      refetchOnWindowFocus: false,
      refetchInterval: THIRTY_MINUTES,
      retry: 0
    }
  );

  if (isError) {
    return (
      <Card className="py-2 px-3 bg-light-dim">
        <p className="text-danger fs-[10px]">
          Failed to fetch earnings information.
        </p>
        <span
          className="text-center text-primary"
          role="button"
          onClick={refetch}
        >
          Try again
        </span>
      </Card>
    );
  }

  if (isLoading) {
    return (
      <Card className="d-flex flex-column p-3">
        {new Array(3).fill(0).map((_, idx) => (
          <div
            className="d-flex"
            style={{ gap: '1rem' }}
            key={`earnings-skeleton-${idx}`}
          >
            <Skeleton width={180} height={15} />{' '}
            <Skeleton width={80} height={15} />
          </div>
        ))}
      </Card>
    );
  }

  return (
    <Card className="d-flex flex-column p-3">
      <div className="d-flex flex-wrap justify-between" style={{ gap: '1rem' }}>
        <span className='fw-bold'>Total Earnings: </span>{' '}
        <span>{currencyFormatter({ isDecimal: true, decimalPlaces: 4 }).format(data?.earned?.totalEarned)}</span>
      </div>
      <div className="d-flex flex-wrap justify-between" style={{ gap: '1rem' }}>
        <span className='fw-bold'>Received: </span>{' '}
        <span>{currencyFormatter({ isDecimal: true, decimalPlaces: 4 }).format(data?.received?.totalReceived)}</span>
      </div>
      <div className="d-flex flex-wrap justify-between" style={{ gap: '1rem' }}>
        <span className='fw-bold'>Due: </span>{' '}
        <span>{currencyFormatter({ isDecimal: true, decimalPlaces: 4 }).format(data?.balance?.balanceLeft)}</span>
      </div>
    </Card>
  );
};

export default EarningsHeader;
