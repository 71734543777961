export const HEADERS = [
  { name: 'Extension Name', style: 'text-center' },
  { name: 'Extension Number', style: 'text-center' },
  { name: 'Mobile', style: 'text-center' },
//   { name: 'Caller ID', style: '' },
  { name: 'Answered', style: 'text-center tb-col-md' },
  { name: 'No Answer', style: 'text-center tb-col-md' },
  { name: 'Busy', style: 'text-center tb-col-md' },
  { name: 'Failed', style: 'text-center tb-col-md' },
  { name: 'Voicemail', style: 'text-center tb-col-md' },
  { name: 'Holding Time', style: 'text-center tb-col-md' },
  { name: 'Talking Time', style: 'text-center tb-col-md' },
  { name: 'Total Calls', style: 'text-center tb-col-md' },
  { name: 'Details', style: 'text-center d-md-none' },
];