import Skeleton from "react-loading-skeleton";
import { Icon } from "../../../../../components/Component";

const TransactionsSkeletonLoader = ({ rows = 3, cols = 6 }) => {
  return new Array(rows).fill(0).map((_, i) => (
    <tr className="tb-tnx-item bg-white" key={`transaction-skeleton-row-${i}`}>
      {new Array(cols).fill(0).map((_, j) => (
        <td key={`transaction-skeleton-cell-${i}-${j}`} className="bg-white">
          <Skeleton />
        </td>
      ))}
      <td className="bg-white text-end" key={`transaction-skeleton-cell-${cols}`}>
        <Icon name={"more-h"} />
      </td>
    </tr>
  ));
};

export default TransactionsSkeletonLoader;
