import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useRefresh from '../hooks/useRefresh';

const ProtectedRoutes = ({ roles = [] }) => {
    const user = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefresh();
    const location = useLocation();

    useEffect(() => {
        const verifyRefresh = async () => {
            try {
                await refresh();
            } catch (err) {
                console.log(err);
            } finally {
                setIsLoading(false);
            }
        };

        !user?.loggedInUser?.access_token
            ? verifyRefresh()
            : setIsLoading(false);
    }, [user?.loggedInUser?.access_token, refresh]);

    // Check if user has one of the required roles or if roles array is empty
    const hasRequiredRole = roles.length === 0 || roles.includes(user?.loggedInUser?.role);

    if (isLoading) {
        return <div></div>; // Or any loading indicator
    }

    if (!user?.isLoggedIn) {
        // User is not logged in
        return <Navigate to="/auth-login" state={{ from: { pathname: location.pathname ?? '/' } }} />;
    } else if (!hasRequiredRole) {
        // User does not have the required role
        return <Navigate to="/unauthorized" />;
    }

    // User is logged in and has the required role (or no specific role is required)
    return <Outlet />;
};

export default ProtectedRoutes;
