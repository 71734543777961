
import { newRoles } from "../../utils/envConfig";
import LandLordTable from "../components/table/LandLordTable";

const LandLordsList = () => {
    return (
        <>
            <LandLordTable
                title={"List Landlords"}
                name={"Landlord"}
                role={newRoles.Owner}
                hide={true}
            />
        </>
    );
};

export default LandLordsList; 
