import { useState } from 'react';
import Compressor from 'compressorjs';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import cuid from 'cuid';

export function compress(file) {
  return new Promise((resolve, reject) => {
    if (!file || !file?.type?.includes('image/')) {
      resolve(file);
    }
    new Compressor(file, {
      width: 500,
      height: 375,
      success(result) {
        resolve(result);
      },
      error(e) {
        reject(e);
      }
    });
  });
}

export default function useDragAndDrop({
  maxFilesLength = 1, //default only one image
  minFilesLength = 1, //default only one image
  inputType = 'image', //image or video for now, image by default
  multiple = false,
  id
}) {
  const [active, setActive] = useState('false');
  const [errorMessage, setErrorMessage] = useState(``);
  const [files, setFiles] = useState([]);

  const activeClass = () => setActive('true');
  const badFilesClass = () => setActive('bad');
  const inactiveClass = () => setActive('false');
  const needMoreFilesClass = () => setActive('more');
  const preventDefaults = (e) => e.preventDefault();

  const checkFilesType = async (dataFiles) => {
    const imagePattern = /image-*/;
    const videoPattern = /video-*/;
    const pdfPAttern = /application\/pdf/;

    const invalidFile = dataFiles?.find(
      (file) =>
        !file?.type?.match(
          inputType === 'image'
            ? imagePattern
            : inputType === 'video'
            ? videoPattern
            : pdfPAttern
        ) ||
        (inputType === 'image' && file.type === 'image/svg+xml')
    );

    if (invalidFile) {
      if (files.length) return;
      setErrorMessage(
        `Invalid file type selected. Please select only ${inputType} files`
      );

      badFilesClass();

      return;
    }

    // if (multiple && files.length + dataFiles?.length < minFilesLength) {
    //     setErrorMessage(
    //         `Less than ${minFilesLength} ${
    //             inputType + minFilesLength > 1 ? "s" : ""
    //         } selected. Add More`
    //     );
    //     needMoreFilesClass();
    // }

    // if (multiple && files.length + dataFiles.length > maxFilesLength) {
    //     setErrorMessage(
    //         `More than ${maxFilesLength} ${
    //             inputType + maxFilesLength > 1 ? "s" : ""
    //         } selected.`
    //     );

    //     return badFilesClass();
    // }

    // if (!multiple && dataFiles.length > 1) {
    //     setErrorMessage(
    //         `More than ${maxFilesLength} ${
    //             inputType + maxFilesLength > 1 ? "s" : ""
    //         } selected.`
    //     );

    //     return badFilesClass();
    // }

    activeClass();

    const newFiles = await Promise.all(
      dataFiles?.map(async (file, index) => {
        const compressed = await compress(file);

        const img = new Image();
        img.src = URL.createObjectURL(file);

        return new Promise((resolve, reject) => {
          img.onload = () => {
            const width = img.width;
            const height = img.height;

            if (width < 800 || height < 600) {
              toast.error('File size too small');
              reject('File size too small');
              return;
            }

            if (height > width) {
              toast.error('Invalid file dimension');
              reject('Invalid file dimension');
              return;
            }

            const uid = uuidv4();

            resolve({
              file: file,
              index,
              id: cuid(),
              src: URL.createObjectURL(file),
              imgfile: file,
              thumbnailFile: compressed,
              fileName:
                `${id}/images/` +
                uid +
                '-original' +
                '.' +
                `${file.name.split('.').pop()}`,
              thumbnailName:
                `${id}/images/` +
                uid +
                '-thumbnail' +
                '.' +
                `${file.name.split('.').pop()}`,
              realName: file.name
            });
          };

          img.onerror = () => {
            reject('Image loading error');
          };
        });
      })
    );

    try {
      setFiles((prevState) => [...prevState, ...newFiles]);
    } catch (error) {
      // Handle any errors here
    }

    // setErrorMessage(`Added ${dataFiles.length} ${inputType}`);
  };

  const dragenter = (e) => {
    preventDefaults(e);
    const dataTransfer = e.dataTransfer;
    const transferFiles = [...dataTransfer.items];

    const imagePattern = /image-*/;
    const videoPattern = /video-*/;
    const pdfPAttern = /application\/pdf/;

    const invalidFile = transferFiles?.find(
      (file) =>
        !file?.type?.match(
          inputType === 'image'
            ? imagePattern
            : inputType === 'video'
            ? videoPattern
            : pdfPAttern
        ) ||
        (inputType === 'image' && file.type === 'image/svg+xml')
    );

    if (invalidFile) {
      if (files.length) {
        badFilesClass();
        return;
      }

      setErrorMessage(
        `Invalid file types selected. Please add only ${inputType} files`
      );
      badFilesClass();
      return;
    }

    // if (multiple && files.length + transferFiles?.length < minFilesLength) {
    //     setErrorMessage(
    //         `Less than ${minFilesLength} ${
    //             inputType + minFilesLength > 1 ? "s" : ""
    //         } selected. Add More`
    //     );
    //     return needMoreFilesClass();
    // }

    if (multiple && transferFiles.length + files.length > maxFilesLength) {
      setErrorMessage(
        `Exceeded maximum limit of ${maxFilesLength} ${
          inputType + maxFilesLength > 1 ? 's' : ''
        }.`
      );
      badFilesClass();
      return;
    }

    if (!multiple && transferFiles.length > 1) {
      setErrorMessage(
        `More than ${maxFilesLength} ${
          inputType + maxFilesLength > 1 ? 's' : ''
        } selected.`
      );

      badFilesClass();
      return;
    }

    // if(inputType === 'image'){
    //     transferFiles?.map(file => {

    //     })
    // }

    activeClass();
    setErrorMessage('Drop images to upload');
  };

  const dragleave = (e) => {
    preventDefaults(e);
    inactiveClass();
    setErrorMessage(`Drag and drop ${inputType} files`);
  };

  const drop = (e) => {
    preventDefaults(e);
    // inactiveClass();
    const dataTransfer = e.dataTransfer;

    const files = [...dataTransfer.files];

    checkFilesType(files);
  };

  const handleFileChange = (e) => {
    preventDefaults(e);
    inactiveClass();
    const dataFiles = [...e.target.files];

    checkFilesType(dataFiles);
  };

  const removeImage = (index) => {
    const newFiles = files?.filter((file, i) => i !== index);

    setFiles(newFiles);
    // setErrorMessage(
    //     newFiles.length
    //         ? `Added ${newFiles.length} ${inputType + "s"}`
    //         : `Drag and drop ${inputType} files`
    // );
    !newFiles.length && inactiveClass();
  };

  return {
    active,
    errorMessage,
    dragenter,
    dragleave,
    drop,
    handleFileChange,
    files,
    setFiles,
    removeImage
  };
}
