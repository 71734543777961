import React, { useMemo } from "react";
import { useQuery } from "react-query";
import propertyQueries from "../../../queries/propertyQueries";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { RSelect } from "../../Component";
import { Spinner } from "reactstrap";
import { components } from "react-select";
import
{
  newPropStatus,
  propActiveStatus,
  Status,
} from "../../../utils/envConfig";
import { truncateText } from "../utils";

const FIFTEEN_MINUTES = 15 * 60 * 1000;

const PropertyOption = ( props ) =>
{
  const getBadgeInfo = ( property ) =>
  {
    if ( property?.status === Status[ 0 ] )
    {
      // published listing
      return { text: propActiveStatus[ 0 ], color: "success" };
    }
    if ( property?.status === Status[ 1 ] )
    {
      // unpublished listing
      return { text: propActiveStatus[ 1 ], color: "danger" };
    }
    if ( property?.statusArray?.includes( newPropStatus[ 5 ] ) )
    {
      // draft
      return { text: newPropStatus[ 5 ], color: "info" };
    }

    // pocket, cancelled, rented or private
    [ 1, 3, 6, 4 ].forEach( ( idx ) =>
    {
      if ( property?.statusArray?.includes( newPropStatus[ idx ] ) )
      {
        return { text: newPropStatus[ idx ], color: "danger" };
      }
    } );
    return { text: "Unknown Status", color: "secondary" };
  };

  const badgeInfo = getBadgeInfo( props?.data );
  return (
    <components.Option { ...props }>
      <div className="d-flex justify-content-between">
        <span>
          { truncateText( `${ props?.data?.referNo } - ${ props?.data?.beds } BR - ${ props?.data?.propertyType?.propertyType ?? ""
            } - ${ props?.data?.community?.community }`, 72 ) }
        </span>
        <span className={ `badge badge-dim bg-outline-${ badgeInfo?.color }` }>
          { badgeInfo?.text }
        </span>
      </div>
    </components.Option>
  );
};
const PropertyNoOptionsMessage = ( props ) =>
{
  const { error } = props.selectProps;

  return error ? (
    <div className="p-2 text-danger">
      Failed to load properties: { error.message }
    </div>
  ) : (
    <components.NoOptionsMessage { ...props }>
      No options available
    </components.NoOptionsMessage>
  );
};

/**
 * @typedef {Object} Props
 * @property {Record<string, unknown>} defaultOption - The default selected option.
 * @property {function(Record<string, unknown>): void} onChange - The onChange callback for the select component.
 */

/**
 * Component for rendering a dropdown component with property list options.
 * @param {Props} props - The properties object.
 * @returns {JSX.Element} - The rendered container component.
 */
const PropertiesDropDown = ( { defaultOption, onChange } ) =>
{
  const axios = useAxiosPrivate();
  const { queryKey, queryFn: fetchAllProperties } =
    propertyQueries.getAll( axios );
  const { data, isLoading, error } = useQuery( queryKey, fetchAllProperties, { staleTime: FIFTEEN_MINUTES } );
  const propertyOptions = useMemo(
    () =>
      data?.map( ( property ) => ( {
        ...property,
        label: `${ property?.referNo } - ${ property?.beds } BR - ${ property?.propertyType?.propertyType ?? ""
          } - ${ property?.community?.community }`,
        value: property?.id ?? property?._id,
      } ) ),
    [ data ]
  );

  return (
    <React.Fragment>
      <RSelect
        options={ propertyOptions }
        components={ { Option: PropertyOption, NoOptionsMessage: PropertyNoOptionsMessage } }
        isLoading={ isLoading }
        loadingMessage={ () => <Spinner /> }
        error={ error }
        onChange={ onChange }
        defaultValue={ defaultOption }
      />
    </React.Fragment>
  );
};

export default PropertiesDropDown;
