/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import ErrorHandler from "../error-handlers/axios/defaultErrorHandler";

/**
 * @typedef {Object} User
 * @property {string} id - User ID
 * @property {string} first_name - User's first name
 * @property {string} last_name - User's last name
 * @property {string} email - User's email address
 * @property {string} phone - User's phone number
 * @property {string} role - User's role
 * @property {string} [country] - User's country
 * @property {string} [city] - User's city
 * @property {string} address - User's address
 * @property {string} [nationality] - User's nationality
 * @property {string} [photo] - User's photo URL
 * @property {string} [provider_id] - User's provider ID
 * @property {string} [provider] - User's provider
 * @property {boolean} active - Whether the user is active
 * @property {string} lead_source - User's lead source
 * @property {string} lead_status - User's lead status
 * @property {Array<string>} languages - User's languages
 * @property {string} last_activity_type - User's last activity type
 * @property {string} last_activity_date - User's last activity date
 * @property {boolean} last_activity_is_complete - Whether the last activity is complete
 * @property {string} created_at - User's creation date
 * @property {string} updated_at - User's last update date
 * @property {string} modified_by - User's modifier ID
 * @property {Object} preference - User's preferences
 * @property {string} preference.plotNumber - User's preferred plot number
 * @property {string} [preference.rooms] - User's preferred number of rooms
 * @property {string} preference.typeOfProperty - User's preferred type of property
 * @property {string} preference.area - User's preferred area
 * @property {string} preference.location - User's preferred location
 * @property {Array<string>} role_duplicate - User's duplicate roles
 * @property {boolean} DndStatus - Whether the user is in DND mode
 */

/**
 * @typedef {Object} Activity
 * @property {string} id - Activity ID
 * @property {string} user_id - User ID associated with the activity
 * @property {string} createdBy - User ID of the activity creator
 * @property {string} type - Activity type
 * @property {string} description - Activity description
 * @property {string} date - Activity date
 * @property {string} completed_date - Activity completion date
 * @property {boolean} is_scheduled - Whether the activity is scheduled
 * @property {string} status - Activity status
 * @property {User} user - User object associated with the activity
 * @property {Array<string>} preferred_languages - Preferred languages for the activity
 * @property {string} notes - Activity notes
 * @property {Array<string>} tags - Activity tags
 */

/**
 * @typedef {Object} UserActivitiesResponse
 * @property {number} page - Current page number
 * @property {number} pageSize - Number of items per page
 * @property {number} totalItems - Total number of items
 * @property {Array<Activity>} activities - Array of activity objects
 */

/**
 * @description Fetching activities of a user
 * @param {Axios} axiosInstance
 * @param {import("axios").AxiosRequestConfig} options 
 * @returns {Promise<UserActivitiesResponse>} apiResponse
 */
export const fetchUserActivities = async (axiosInstance, userId, options) => {
  try {
    const res = await axiosInstance.get(`/activities/user/${userId}`, options);

    return res?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
} 