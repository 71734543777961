import Skeleton from "react-loading-skeleton";

const DealSkeletonLoader = ({ rows = 5, columns = 5 }) => {
  return new Array(rows).fill(0).map((_, rowId) => (
    <tr key={`row-${rowId}`}>
      {new Array(columns).fill(0).map((_, colId) => (
        <td className="bg-white" key={`cell-${rowId}-${colId}`}>
          <Skeleton />
        </td>
      ))}
    </tr>
  ));
};

export default DealSkeletonLoader;
