import React from "react";
import { DataTableItem, DataTableRow, Icon } from "../../components/Component";
import { BedRooms } from "../../utils/envConfig";
import { useNavigate } from "react-router-dom";

const OffPlanDataTableRow = ({ offplan, index }) => {
  const navigate= useNavigate();
  const beds = offplan?.propertyTypes
    ?.map((item) => item?.beds?.map((item) => item?.name))
    ?.toString();

  const bedsList = [...new Set(beds?.split(","))].sort();

  return (
    <DataTableItem key={index}>
      <DataTableRow>
        <div>
          <span>{offplan.developmentName}</span>
        </div>
      </DataTableRow>
      <DataTableRow>{offplan.startingPrice}</DataTableRow>
      <DataTableRow>
        <span>{offplan?.community?.name}</span>
      </DataTableRow>
      <DataTableRow>
        <span> {offplan?.downpayment}%</span>
      </DataTableRow>
      <DataTableRow>
        <span>{offplan?.completionDate}</span>
      </DataTableRow>
      <DataTableRow>
        <span>{offplan?.developer?.name}</span>
      </DataTableRow>
      <DataTableRow>
        <span>
          {bedsList[bedsList.length - 1] === BedRooms[0]
            ? `${bedsList[bedsList.length - 1]}${
                bedsList[0] !== BedRooms[0] ? bedsList[0] : ""
              } ${
                bedsList?.slice(0, bedsList?.length - 2)?.length > 1
                  ? `- ${bedsList[bedsList.length - 2]}`
                  : ""
              } ${bedsList[0] !== BedRooms[0] ? ", Bedrooms" : ""} `
            : `${bedsList[0]} ${
                bedsList?.slice(0, bedsList?.length - 1)?.length > 1
                  ? `- ${bedsList[bedsList.length - 1]}`
                  : ""
              } Bedrooms `}
          {offplan?.propertyTypes
            ?.map((item) => item?.propertyType)
            ?.join(", ")}
        </span>
      </DataTableRow>
      <DataTableRow>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon
          onClick={()=> navigate(`/edit-off-plan/${ offplan?.id }`)}
            
            name="edit"
            className="ms-2"
            style={{ fontSize: "1.2rem !important" }}
          ></Icon>
        </div>
      </DataTableRow>
    </DataTableItem>
  );
};
export default OffPlanDataTableRow;
