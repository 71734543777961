/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  BlockBetween,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../../../components/Component";
import CardInner from "../../../../../components/cards/CardInner";
import LoadingComponent from "../../../../components/loader/tableLoader";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { updateGlobalSettings } from "../../../../../redux/actions/settings";
import { toast } from "react-toastify";

const ExplorerSettingsEdit = ({ settingsState, setIsEditState, refreshFn }) => {
  const { globalSettings, loading, error } = settingsState;
  const { register, handleSubmit } = useForm();
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const [selectedCheckoutInterval, setSelectedCheckoutInterval] =
    useState("hours");
  const [selectedBlockInterval, setSelectedBlockInterval] = useState("days");
  const [selectedReturnBlockInterval, setSelectedReturnBlockInterval] = useState("days");
  const [selectedUnassignInterval, setSelectedUnassignInterval] =
    useState("days");

  const { updateLoading, updateError } = useSelector((state) => ({
    loading: state.settings.loading,
    error: state.settings.error,
  }));

  const submitForm = async (formData) => {
    try {
      const payload = {
        settings: {
          checkoutLimit: {
            limit: formData.limit,
            tolerance: formData.tolerance,
            unassignAfter: `${formData.unassignAfter} ${selectedCheckoutInterval}`,
          },
          unAssignLeadAfter: `${formData.unassignAgentAfter} ${selectedUnassignInterval}`,
          blockLeadFor: `${formData?.blockLeadFor} ${selectedBlockInterval}`,
          blockAfterReturningFor: `${formData?.blockAfterReturningFor} ${selectedReturnBlockInterval}`,
        },
      };
      // refreshFn will run after the dispatch is successful
      dispatch(updateGlobalSettings(axios, payload, refreshFn));
    } catch (error) {
      if (updateError) {
        toast.error(updateError);
        console.log(updateError);
      } else {
        console.log(error);
        toast.error("An error occurred while updating the settings.");
      }
    } finally {
      setIsEditState(false);
    }
  };

  return (
    <CardInner size="lg">
      <form action="" onSubmit={handleSubmit(submitForm)}>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Explorer Settings</BlockTitle>
              <BlockDes>
                <p>Settings for property explorer.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="success"
                type="submit"
                className="d-none d-lg-inline"
                style={{ marginTop: "-2rem" }}
              >
                {updateLoading ? <Spinner size={"sm"} /> : "Save"}
              </Button>
              <Button
                color="light"
                className="d-none d-lg-inline"
                style={{ marginTop: "-2rem", marginLeft: "0.5rem" }}
                onClick={() => setIsEditState(false)}
              >
                Cancel
              </Button>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none">
              <a
                href="#"
                className="toggle btn btn-icon btn-trigger mt-n1"
                data-target="userAside"
              >
                <Icon name="menu-alt-r" />
              </a>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {error ? (
          <BlockHeadContent>
            <h6 className="text-danger">Failed to get settings</h6>
          </BlockHeadContent>
        ) : loading ? (
          <LoadingComponent />
        ) : (
          <>
            <BlockHead size={"sm"}>
              <BlockHeadContent>
                <h6>Checkout Settings</h6>
              </BlockHeadContent>
            </BlockHead>
            <BlockContent>
              <div className="gy-3">
                <div className="g-item">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ gap: "1rem" }}
                  >
                    <label className="form-label mt-2" htmlFor="limit">
                      Checkout Limit
                    </label>
                    <Input
                      name="limit"
                      type="number"
                      defaultValue={
                        globalSettings.global.checkoutLimit.limit ?? ""
                      }
                      className="w-50"
                      innerRef={register()}
                    />
                  </div>
                </div>
                <div className="g-item">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ gap: "1rem" }}
                  >
                    <label className="form-label mt-2" htmlFor="tolerance">
                      Tolerance
                    </label>
                    <Input
                      type="number"
                      name="tolerance"
                      defaultValue={
                        globalSettings.global.checkoutLimit.tolerance ?? ""
                      }
                      className="w-50"
                      innerRef={register()}
                    />
                  </div>
                </div>
                <div className="g-item">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ gap: "1rem" }}
                  >
                    <label className="form-label mt-2" htmlFor="unassignAfter">
                      Unassign After
                    </label>
                    <div className="form-group flex-grow-1 d-flex justify-content-end" style={{ gap: "0.5rem" }}>
                      <Input
                        type="number"
                        name="unassignAfter"
                        defaultValue={
                          parseInt(
                            globalSettings.global.checkoutLimit.unassignAfter
                          ) ?? ""
                        }
                        className="w-45"
                        innerRef={register()}
                      />
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {selectedCheckoutInterval}
                        </DropdownToggle>
                        <DropdownMenu>
                          <ul className="link-list-opt no-bdr">
                            {["minutes", "hours", "days"].map((interval, idx) => (
                              <li key={`interval-${idx}`}>
                                <DropdownItem
                                  onClick={() =>
                                    setSelectedCheckoutInterval(interval)
                                  }
                                >
                                  <span>{interval}</span>
                                </DropdownItem>
                              </li>
                            ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
                <div className="g-item">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ gap: "1rem" }}
                  >
                    <label className="form-label mt-2" htmlFor="unassignAfter">
                      After Returning, Block For
                    </label>
                    <div className="form-group flex-grow-1 d-flex justify-content-end" style={{ gap: "0.5rem" }}>
                      <Input
                        type="number"
                        name="blockAfterReturningFor"
                        defaultValue={
                          parseInt(
                            globalSettings.global.blockAfterReturningFor
                          ) ?? ""
                        }
                        className="w-45"
                        innerRef={register()}
                      />
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {selectedReturnBlockInterval}
                        </DropdownToggle>
                        <DropdownMenu>
                          <ul className="link-list-opt no-bdr">
                            {["minutes", "hours", "days"].map((interval, idx) => (
                              <li key={`interval-${idx}`}>
                                <DropdownItem
                                  onClick={() =>
                                    setSelectedReturnBlockInterval(interval)
                                  }
                                >
                                  <span>{interval}</span>
                                </DropdownItem>
                              </li>
                            ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
                <div className="g-item">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ gap: "1rem" }}
                  >
                    <label className="form-label mt-2" htmlFor="unassignAfter">
                      After Lost, Block For
                    </label>
                    <div className="form-group flex-grow-1 d-flex justify-content-end" style={{ gap: "0.5rem" }}>
                      <Input
                        type="number"
                        name="blockLeadFor"
                        defaultValue={
                          parseInt(
                            globalSettings.global.blockLeadFor
                          ) ?? ""
                        }
                        className="w-45"
                        innerRef={register()}
                      />
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {selectedBlockInterval}
                        </DropdownToggle>
                        <DropdownMenu>
                          <ul className="link-list-opt no-bdr">
                            {["minutes", "hours", "days"].map((interval, idx) => (
                              <li key={`interval-${idx}`}>
                                <DropdownItem
                                  onClick={() =>
                                    setSelectedBlockInterval(interval)
                                  }
                                >
                                  <span>{interval}</span>
                                </DropdownItem>
                              </li>
                            ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>
            </BlockContent>
            <BlockHead size={"sm"}>
              <BlockHeadContent>
                <h6>Lead Settings</h6>
              </BlockHeadContent>
            </BlockHead>
            <BlockContent>
              <div className="gy-3">
                <div className="g-item">
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "1rem" }}
                  >
                    <label
                      className="form-label mt-2"
                      htmlFor="unassignAgentAfter"
                    >
                      Unassign Agent After
                    </label>
                    <div className="form-group flex-grow-1 d-flex justify-content-end" style={{ gap: "0.5rem" }}>
                      <Input
                        type="number"
                        className="w-45"
                        name="unassignAgentAfter"
                        defaultValue={
                          parseInt(globalSettings.global.unAssignLeadAfter) ?? ""
                        }
                        innerRef={register()}
                      />
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {selectedUnassignInterval}
                        </DropdownToggle>
                        <DropdownMenu>
                          <ul className="link-list-opt no-bdr">
                            {["minutes", "hours", "days"].map((interval, idx) => (
                              <li key={`unassign-interval-${idx}`}>
                                <DropdownItem
                                  onClick={() =>
                                    setSelectedUnassignInterval(interval)
                                  }
                                >
                                  <span>{interval}</span>
                                </DropdownItem>
                              </li>
                            ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>
            </BlockContent>
            <Button color="success" type="submit" className="d-lg-none mt-3">
              Save
            </Button>
            <Button
              color="light"
              className="d-lg-none mt-3"
              style={{ marginLeft: "0.5rem" }}
              onClick={() => setIsEditState(false)}
            >
              Cancel
            </Button>
          </>
        )}
      </form>
    </CardInner>
  );
};

export default ExplorerSettingsEdit;
