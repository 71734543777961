import React from "react";
import UserPersonalInfoHeader from "./UserPersonalInfoHeader";
import UserPersonalInfoDetails from "./UserPersonalInfoDetails";

const UserPersonalInfoContainer = ( user, hasPendingOrOverdue ) =>
{

    return (
        <div id="personalInfo">
            <div className="mt-5">
                <UserPersonalInfoHeader user={user}
                ></UserPersonalInfoHeader>
            </div>
            <div className="mt-5">
                <UserPersonalInfoDetails user={ user } hasPendingOrOverdue={hasPendingOrOverdue}></UserPersonalInfoDetails>
            </div>

        </div>
    );
};

export default UserPersonalInfoContainer;
