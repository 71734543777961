import * as types from "../types";

const initialState = {
  notifications: null,
  loading: true,
  error: "",
};
export const notification = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
        loading: false,
        error: "",
      };
    default:
      return state;
  }
};
