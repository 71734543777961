import React, { useEffect, useMemo, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Dropzone from "react-dropzone";
import { Block, Icon } from "../../../components/Component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Button, Input } from "reactstrap";
import Head from "../../../layout/head/Head";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import InputFile from "./InputFile";
import useDragAndDrop from "../../../utils/hooks/useDragAndDrop";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DnDKitDemo from "./DnDKitDemo";

const useImageResize = (file) => {
  const [resizedImageUrl, setResizedImageUrl] = useState("");
  useEffect(() => {
    const loadImage = async () => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const desiredWidth = 400; // Set the desired width
        const scaleFactor = desiredWidth / image.width;
        const canvasWidth = image.width * scaleFactor;
        const canvasHeight = image.height * scaleFactor;
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight);
        const url = canvas.toDataURL();
        setResizedImageUrl(url);
      };
      image.src = URL.createObjectURL(file);
    };

    loadImage();

    return () => {
      URL.revokeObjectURL(file.src);
    };
  }, [file]);

  return resizedImageUrl;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: "16px",
  margin: `0 0 8px 0`,
  background: isDragging ? "#fff" : "",
  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#e8e9f9" : "",
  padding: "8px",
  width: "100%",
});

const ResizableImage = React.memo(({ file }) => {
  const resizedImageUrl = useImageResize(file);

  return (
    <LazyLoadImage
      className="position-absolute"
      src={resizedImageUrl}
      width="100%"
      height="100%"
      effect="blur"
    />
  );
});

const AddOffPlanStepFour = (props) => {
  let {
    development,
    setDevelopment,
    setFilesToUpload,
    setFinalPlanDetails,
    edit = false,
  } = props;
  const axios = useAxiosPrivate();

  const { errors, register, handleSubmit } = useForm();
  const {
    active: activeDevelopments,
    errorMessage: developmentsStatus,
    dragenter: developmentsDragEnter,
    dragleave: developmentsDragLeave,
    drop: developmentsDropVideo,
    handleFileChange: developmentsFileChange,
    files: developmentsFiles,
    setFiles: setDevelopmentsFiles,
    removeImage: removeDevelopmentImage,
  } = useDragAndDrop({
    multiple: true,
    inputType: "image",
    maxFilesLength: 10,
    minFilesLength: 8,
    labelName: "label",
  });
  const addPhotosToFinal = async () => {
    if (developmentsFiles.length < 8)
      return toast.error("Please add at least 8 photos");
    if (developmentsFiles.length > 10)
      return toast.error("Cannot add more than 10 photos");

    const nullLabels = developmentsFiles.find(
      (file) => file?.label === "" || !file.label
    );

    if (nullLabels) return toast.error("All files must have a label.");

    let filesToUpload = [];

    const photos = await Promise.all(
      developmentsFiles.map(async (file) => {
        const fileName = `offPlans/photos/${uuid()}.${file?.file?.name
          ?.split(".")
          ?.pop()}`;

        filesToUpload.push({
          type: "image/*",
          file: file?.file,
          fileName,
        });

        return {
          ...file,
          file: fileName,
        };
      })
    );

    setFilesToUpload((prev) => ({ ...prev, devPhotos: filesToUpload }));

    setFinalPlanDetails((prev) => ({
      ...prev,
      developmentPhotos: photos,
    }));
    props.next();
  };

  const submitForm = (data) => {
    // props.next();
    addPhotosToFinal();
  };
  const gridContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
  };
  const handlePropertyPhotosLabelChange = (name, index) => (e) => {
    let newArr = developmentsFiles?.map((item, i) => {
      if (index === i) {
        return { ...item, [name]: e.target.value };
      } else {
        return item;
      }
    });

    setDevelopmentsFiles(newArr);
  };
  const moveImage = (dragIndex, hoverIndex) => {
    const draggedImage = developmentsFiles[dragIndex];

    setDevelopmentsFiles((images) => {
      const coppiedStateArray = [...images];

      // remove item by "hoverIndex" useDragAndDropand put "dragItem" instead
      const prevItem = coppiedStateArray.splice(hoverIndex, 1, draggedImage);

      // remove item by "dragIndex" and put "prevItem" instead
      coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

      return coppiedStateArray;
    });
  };

  const [files, setFiles] = useState([]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(files[sInd], source.index, destination.index);
      const newState = [...files];
      newState[sInd] = items;
      setFiles(newState);
    } else {
      const result = move(files[sInd], files[dInd], source, destination);
      const newState = [...files];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setFiles(newState.filter((group) => group.length));
    }
  };

  const handleDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      id: file.name,
      file,
    }));

    const flattenedOldFiles = files.flat(1);

    if (flattenedOldFiles.length + newFiles.length > 10) {
      return toast.error("Cannot add more than 10 files");
    }

    const splittedFiles =
      !files.length && newFiles.length <= 3
        ? [[...newFiles]]
        : !files.length && newFiles.length <= 6
        ? [[...newFiles.slice(0, 3)], [...newFiles.slice(3, newFiles.length)]]
        : !files.length && newFiles.length <= 9
        ? [
            [...newFiles.slice(0, 3)],
            [...newFiles.slice(3, 6)],
            [...newFiles.slice(6, 9)],
          ]
        : [
            [...newFiles.slice(0, 3)],
            [...newFiles.slice(3, 6)],
            [...newFiles.slice(6, 9)],
            [...newFiles.slice(9, newFiles.length)],
          ];

    const toBeUpdated = files.length ? files.concat([newFiles]) : splittedFiles;

    setFiles(toBeUpdated);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  return (
    <React.Fragment>
      <Head title="Off Plan"></Head>
      <Block>
        <form id="add-development-form" onSubmit={handleSubmit(submitForm)}>
          {/* <DragDropContext onDragEnd={ onDragEnd }>
                        <div>
                            <Dropzone onDrop={ handleDrop }>
                                { ( { getRootProps, getInputProps } ) => (
                                    <section>
                                        <div
                                            { ...getRootProps() }
                                            className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                                        >
                                            <input { ...getInputProps() } />
                                            <div className="dz-message">
                                                <span className="dz-message-text">
                                                    <span>Drag and drop</span> file here or <span>browse</span>
                                                </span>
                                            </div>
                                        </div>
                                    </section>
                                ) }
                            </Dropzone>

                            <div style={ { display: "flex" } }>
                                { files.map( ( group, ind ) => (
                                    <Droppable key={ ind } droppableId={ `${ ind }` }>
                                        { ( provided, snapshot ) => (
                                            <div
                                                ref={ provided.innerRef }
                                                style={ getListStyle( snapshot.isDraggingOver ) }
                                                { ...provided.droppableProps }
                                            >
                                                { group.map( ( item, index ) => (
                                                    <Draggable
                                                        key={ item.id }
                                                        draggableId={ item.id }
                                                        index={ index }
                                                    >
                                                        { ( provided, snapshot ) => (
                                                            <div
                                                                ref={ provided.innerRef }
                                                                { ...provided.draggableProps }
                                                                { ...provided.dragHandleProps }
                                                                style={ getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                ) }
                                                            >
                                                                <div
                                                                    style={ {
                                                                        display: "flex",
                                                                        height: "24rem",
                                                                        position: "relative",
                                                                    } }
                                                                >
                                                                    <ResizableImage file={ item.file } />
                                                                    <button
                                                                        type="button"
                                                                        onClick={ () =>
                                                                        {
                                                                            const newState = [ ...files ];
                                                                            newState[ ind ].splice( index, 1 );
                                                                            setFiles(
                                                                                newState.filter( ( group ) => group.length )
                                                                            );
                                                                        } }
                                                                        style={ {
                                                                            position: "absolute",
                                                                            top: "0",
                                                                            left: "0",
                                                                            zIndex: "999",
                                                                            padding: "0.5rem",
                                                                        } }
                                                                        className="bg-danger"
                                                                    >
                                                                        <Icon
                                                                            name="trash"
                                                                            className="text-white"
                                                                            style={ { cursor: "pointer" } }
                                                                        />

                                                                    </button>
                                                                    <div className="position-absolute bg-white w-100" style={ { bottom: "0", zIndex: "999" } }>
                                                                        <Input placeholder="Image Label*" className="mt-2 border border-light-alt"></Input>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) }
                                                    </Draggable>
                                                ) ) }
                                                { provided.placeholder }
                                            </div>
                                        ) }
                                    </Droppable>
                                ) ) }
                            </div>
                        </div>
                    </DragDropContext> */}
          <p className="font-bold text-2xl text-center pb-2">
            Select development photos (minimum 8)
          </p>
          <InputFile
            active={activeDevelopments}
            dragenter={developmentsDragEnter}
            dragleave={developmentsDragLeave}
            drop={developmentsDropVideo}
            errorMessage={developmentsStatus}
            files={developmentsFiles}
            handleFileChange={developmentsFileChange}
            inputType="image"
            removeImage={removeDevelopmentImage}
            multiple
            inputProps={{
              accept: "image/*",
            }}
          />
          {/* {developmentsFiles?.length ? (
                                <DndProvider backend={HTML5Backend}>
                                   <div
                                   
                                    style={gridContainerStyle}
                                    className="group"
                                            >
                                        {developmentsFiles?.map((file, i) => {
                                            file.index = i;
                                            file.id = uuid();

                                            return (
                                                <DevelopmentPhotoItem
                                                index={i}
                                                file={file}
                                                developmentsFiles={
                                                    developmentsFiles
                                                }
                                                setDevelopmentsFiles={
                                                    setDevelopmentsFiles
                                                }
                                                handlePropertyPhotosLabelChange={
                                                    handlePropertyPhotosLabelChange
                                                }
                                                moveImage={moveImage}
                                            />
                                            );
                                        })}
                                    </div>
                                </DndProvider>
                            ) : null} */}
          {developmentsFiles && developmentsFiles.length > 0 && (
            <DnDKitDemo
              images={developmentsFiles}
              setImages={setDevelopmentsFiles}
              removeImage={removeDevelopmentImage}
              desc={false}
              handlePropertyPhotosLabelChange
            />
          )}
          <div className="actions clearfix">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-between">
              <li>
                <Button color="primary" type="submit">
                  Next
                </Button>
              </li>
              <li>
                <Button color="light" onClick={props.prev} disabled>
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </Block>
    </React.Fragment>
  );
};

export default AddOffPlanStepFour;
