import React, { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import PropertyCard from "./PropertyCard";
import propertyCardQueries from "../../queries/propertyCardQueries";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import PropertiesCardsHeader from "./PropertiesCardsHeader";
import { PaginationComponent } from "../Component";
import { ScreenBreakPoints } from "../../utils/envConfig";

const THIRTY_MINUTES = 30 * 60 * 1000;
const itemsPerPage = 6;

/**
 * The container to render property cards in the user profile
 *
 * @param {Object} props - The props for the component.
 * @param {User} props.lead - The user associated with the activities
 * @returns {JSX.Element} - The rendered table component.
 */
const PropertiesCardsContainer = ( { lead } ) =>
{
    const [ currentPage, setCurrentPage ] = useState( 1 );
    const axios = useAxiosPrivate();

    const paginate = useCallback(( pageNumber ) => setCurrentPage( pageNumber ), []);
    const leadId = useMemo(() => (lead?.id ?? lead?._id), [lead]);

    const { queryKey, queryFn } = propertyCardQueries.list( leadId, axios );
    const { data: leadPropertyCardsData, isLoading, error } = useQuery( queryKey, queryFn, {
        refetchOnWindowFocus: false,
        refetchInterval: THIRTY_MINUTES,
        staleTime: THIRTY_MINUTES,
        // eslint-disable-next-line eqeqeq
        enabled: leadId != undefined
    } );

    if ( isLoading ) return <div>Loading...</div>;
    if ( error )
    {
        if ( error.message === 'Property cards not found' )
        {
            return <div>Property cards not found for lead ID: { lead?.id }</div>;
        }
        return <div>Error loading property cards: { error.message }</div>;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = leadPropertyCardsData.slice( indexOfFirstItem, indexOfLastItem );

    let columnsClass = "col-12";
    if ( window.innerWidth >= ScreenBreakPoints.md )
    {
        columnsClass = "col-6";
    }
    if ( window.innerWidth >= ScreenBreakPoints.xxl )
    {
        columnsClass = "col-4";
    }


    return (
        <div id="propertyCards">
            <div className="mt-5">
                <PropertiesCardsHeader lead={ lead } />
            </div>
            <div className="row">
                { currentItems && currentItems.length > 0 &&
                    currentItems.map( ( item, idx ) => (
                        <div key={ `card-${ idx }` } className={ columnsClass }>
                            <PropertyCard data={ item } lead={ lead } />
                        </div>
                    ) )
                }
            </div>
            { currentItems && currentItems.length > 0 &&
                <div className="mt-5">
                    <PaginationComponent
                        itemPerPage={ itemsPerPage }
                        totalItems={ leadPropertyCardsData.length }
                        paginate={ paginate }
                        currentPage={ currentPage }
                    />
                </div> }

        </div>
    );
};

export default PropertiesCardsContainer;
