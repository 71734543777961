import React, { useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import UserAvatar from "../../../../components/user/UserAvatar";
import { deleteCookie, getCookie } from "cookies-next";
import { webDomain } from "../../../../utils/envConfig";
import { useSelector } from "react-redux";
// import { axiosPrivate } from "../../../../utils/Utils";
import { useDispatch } from "react-redux";
import * as types from "../../../../redux/types";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const User = () =>
{
    const [ open, setOpen ] = useState( false );
    const toggle = () => setOpen( ( prevState ) => !prevState );
    const { first_name, last_name, email, id, role } = useSelector(
        ( state ) => state?.user?.loggedInUser
    );
    const axios = useAxiosPrivate();

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();

    const handleSignout = async (e) =>
    {
        e.preventDefault();
        try
        {
            const { data } = await axios.get( "/auth/logout" );

            // console.log( data );

            // dispatch({type : types.LOGOUT, payload : {}})

            // navigate("/auth-login", {
            //     state: {
            //         from: {
            //             pathname: location.pathname ?? "/",
            //         },
            //     },
            //     replace : true
            // });
        } catch ( err )
        {
            // console.log( err );
        }

        dispatch( { type: types.LOGOUT, payload: {} } );

        navigate( "/auth-login", {
            state: {
                from: {
                    pathname: location.pathname ?? "/",
                },
            },
            replace: true,
        } );
        localStorage.removeItem( "persist:root" );
        localStorage.removeItem( "authuser" );
    };

    return (
        <Dropdown isOpen={ open } className="user-dropdown" toggle={ toggle }>
            <DropdownToggle
                tag="a"
                href="#toggle"
                className="dropdown-toggle"
                onClick={ ( ev ) =>
                {
                    ev.preventDefault();
                } }
            >
                <div className="user-toggle">
                    <UserAvatar icon="user-alt" className="sm" />
                    <div className="user-info d-none d-md-block">
                        <div className="user-status">{ role }</div>
                        <div className="user-name dropdown-indicator">
                            { first_name + " " + last_name }
                        </div>
                    </div>
                </div>
            </DropdownToggle>
            <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
                <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                    <div className="user-card sm">
                        <div className="user-avatar">
                            <span>AB</span>
                        </div>
                        <div className="user-info">
                            <span className="lead-text">
                                { first_name + " " + last_name }
                            </span>
                            <span className="sub-text">{ email }</span>
                        </div>
                    </div>
                </div>
                <div className="dropdown-inner">
                    <LinkList>
                        <LinkItem
                            link={ `/user-profile/${ id }/user-profile` }
                            icon="user-alt"
                            onClick={ toggle }
                        >
                            View Profile
                        </LinkItem>
                    </LinkList>
                </div>
                <div className="dropdown-inner">
                    <LinkList>
                        <a href={ `/change-password` }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              >
                            <Icon name="account-setting-alt"></Icon>
                            <span>Change Password</span>
                        </a>
                    </LinkList>
                </div>
                <div className="dropdown-inner">
                    <LinkList>
                        <a href={ `/settings` }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              >
                            <Icon name="opt-dot-alt"></Icon>
                            <span>App Settings</span>
                        </a>
                    </LinkList>
                </div>
                <div className="dropdown-inner">
                <LinkList>
                <a href="#" onClick={(e) => handleSignout(e)}>
                        <Icon name="signout"></Icon>
                        <span>Sign Out</span>
                    </a>
                </LinkList>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};
export default User;
