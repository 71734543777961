import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import
{
  Block,
  BlockDes,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  PreviewAltCard,
  DataTable,
  DataTableBody,
} from "../../../components/Component";
import { Button, Modal, ModalBody, Card, CardBody } from "reactstrap";


import { toast } from "react-toastify";
import 'react-phone-number-input/style.css';
import { useSelector } from "react-redux";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import _ from 'lodash';
import { sources } from "../../../utils/envConfig";

const DndNumbersUpload = ( { title, name } ) =>
{

  // State Variables
  const [ filCount, setFilCount ] = useState( 0 );
  const [ loading, setLoading ] = useState( false );
  const [ dateValue, setDateValue ] = useState( { startDate: '', endDate: '' } );
  const [ rangeStart, setRangeStart ] = useState();
  const [ rangeEnd, setRangeEnd ] = useState( new Date() );
  const [ sort, setSortState ] = useState( "DESC" );
  const [ csvFileName, setCsvFileName ] = useState( '' );
  const [ fileContents, setFileContents ] = useState( [] );
  const [ fileData, setFileData ] = useState( [] );
  const [ saveLoading, setSaveLoading ] = useState( false );

  const authuser = useSelector((state) => state?.user?.loggedInUser);


  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = fileData.slice(1).slice(indexOfFirstItem, indexOfLastItem);

  const [open, setOpen] = useState(false);
  const [createdCount, setCreatedCount] = useState('');
  const [skippedCount, setSkippedCount] = useState('');

  

  useEffect( () =>
  {
    setLoading( false );
    setLoading( true );
  }, [ dateValue, itemsPerPage, sort ] );


  useEffect( () =>
  {
    if ( !itemsPerPage )
    {
      return;
    }
    setLoading( false );
    setLoading( true );
  }, [ currentPage ] );

  useEffect( () =>
  {
    setDateValue( {
      startDate: rangeStart?.toISOString(),
      endDate: rangeEnd?.toISOString()
    }
    );
  }, [ rangeStart, rangeEnd ] );



  const axios = useAxiosPrivate();

  const handleFileUpload = ( event ) =>
  {
    const file = event.target.files[ 0 ];

    if ( !file )
    {
      toast.error( "Please choose a file." );
      return;
    }

    setFileData( [] );
    setFileData( [] );
    setFileContents( [] );
    setCsvFileName( '' );
    setFilCount( 0 );

    if ( file.type !== 'text/csv' )
    {
      toast.error( "Only CSV files are accepted." );
      return;
    }

    const reader = new FileReader();
    const fileName = file.name;
    setCsvFileName( fileName );

    reader.onload = ( e ) =>
    {
      const contents = e.target.result;
      const rows = contents.split( '\n' );
      const data = rows.map( ( row ) => row.split( ',' ) );

      setFileContents( contents );
    };

    reader.readAsText( file );
  };


  const handleViewData = () =>
  {
    const rows = fileContents.split( '\n' );
    const data = rows.map( ( row ) => row.split( ',' ) );
    setFileData( data );
    setFilCount( data.slice( 1 ).length );
  };

  const handleClearData = () =>
  {
    setFileData( [] );
    setFileContents( [] );
    setCsvFileName( '' );
    setFilCount( 0 );
    document.getElementById( 'fileInput' ).value = null;
  };

  const handleDownloadTemplate = () =>
  {
    const templateUrl = '/DND_Format.csv';
    const link = document.createElement( 'a' );
    link.href = templateUrl;
    link.download = 'template.csv';
    link.click();
    toast.success( "CSV file downloaded" );
  };

  const handleOnSubmit = async ( e ) =>
  {
    setSaveLoading( false );
    e.preventDefault();

    const dataHeaders = fileData[ 0 ];


    const findIndexByItem = ( header, item ) =>
    {
      const sanitizedItems = header.map( h => h.replace( /[\n\r]/g, '' ) );
      return sanitizedItems.indexOf( item );
    };

    const phoneIndex = findIndexByItem( dataHeaders, "Phone Number (Required)" );
    const commentsIndex = findIndexByItem( dataHeaders, "Comments (Optional)" );


    const fileDatas = fileData.slice(1, fileData.length);
    const phoneNumbers = fileDatas
    .filter(row => row[phoneIndex])  // Remove rows without a phone number
    .map(obj => ({
      phoneNumber: obj[phoneIndex] ? "+" + obj[phoneIndex]?.replace(/[\n\r]/g, '').trim() : null,
      comment: obj[commentsIndex] ? obj[commentsIndex]?.replace(/[\n\r]/g, '') : "",
      createdBy: {
        source: sources.albaHomes,
        userId: authuser?.id
      }
    }))
    .filter(Boolean);  // Remove any null/undefined entries
    
    const formattedData = {
      phoneNumbers
    };

    if ( formattedData.phoneNumbers.length > 0 )
    {
      try
      {
        setSaveLoading( true );
        try {
            const response = await axios.post(`/dnd/dnd-phone-numbers/multiple`, formattedData);
            setSaveLoading( false );
            toast.success('Successfully uploaded DnD phone numbers');
            setOpen(true);
            setCreatedCount(response?.data?.createdCount);
            setSkippedCount(response?.data?.skippedCount);
            setFileData( [] );
            setFileContents( [] );
            setCsvFileName( '' );
            setFilCount( 0 );
            document.getElementById( 'fileInput' ).value = null;
        } catch (error) {
            if (error.response) {
                const { data } = error.response;
                toast.error(data?.message || 'Server error. Please try again.');
            } else if (error.request) {
                toast.error('No response received from the server. Please try again.');
            } else {
                toast.error('An unexpected error occurred. Please try again later.');
            }
            setSaveLoading( false );
        }

      } catch ( err )
      {
        setSaveLoading( false );
        toast.error( "Server Error" );
      }
    } else
    {
      toast.error( "Please import the CSV" );
    }
  };

  return (
    <React.Fragment>
      <Head title={ title }></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                { title }
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card>
            <CardBody>
              <div className="row mt-3 mb-3 justify-content-end">
                <div className="col-md-7 justify-content-end">
                  <div className="d-flex">
                    <Button color="light">
                      <input type="file" id="fileInput" onChange={ handleFileUpload }></input>
                    </Button>
                    <Button outline={ fileContents.length > 0 } color="danger" className="ml-2" onClick={ handleClearData } disabled={ fileContents.length === 0 }>
                      Clear
                    </Button>
                    <Button outline={ fileContents.length > 0 } color="secondary" className="ml-2" onClick={ handleViewData } disabled={ fileContents.length === 0 }>
                      Load & Preview
                    </Button>
                  </div>
                </div>
                <div className="col-md-5 justify-content-end d-flex">
                  <Button outline color="secondary" onClick={ handleDownloadTemplate } className="ml-2">
                    <Icon name="download" />
                    Download CSV Template
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Block>

        <Block>
          <BlockDes className="text-soft">
            <div className="row mt-3 d-flex align-items-center">
              <div className="col-md-6">
                <p>You have total { filCount } { name + "s" }.</p>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <Button
                  color="primary"
                  size="lg"
                  onClick={ handleOnSubmit }
                  disabled={ fileData.length === 0 || saveLoading }
                >
                  { saveLoading ? 'Uploading...' : 'Upload' }
                </Button>
              </div>
            </div>
          </BlockDes>
        <DataTable className="card-stretch mt-3">
          <DataTableBody compact>
            <DataTableHead className="nk-tb-item">
              {fileData.length > 0 &&
                fileData[0].map((header, index) => (
                  <DataTableRow key={index}>
                    <span className="sub-text">{header}</span>
                  </DataTableRow>
                ))}
            </DataTableHead>
            {currentItems.map((row, rowIndex) => (
              <DataTableItem key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <DataTableRow key={cellIndex}>
                    <span href="#id" onClick={(ev) => ev.preventDefault()}>
                      {cell}
                    </span>
                  </DataTableRow>
                ))}
              </DataTableItem>
            ))}
          </DataTableBody>
          <PreviewAltCard>
          {fileData.slice(1)?.length > 0 ? (
            <PaginationComponent
              itemPerPage={itemsPerPage}
              totalItems={fileData.slice(1).length}
              paginate={paginate}
              currentPage={currentPage}
            />
          ) : (
            <div className="text-center">
              <span className="text-silent">Upload CSV file to see the data</span>
            </div>
          )}
        </PreviewAltCard>
        </DataTable>
        </Block>
        <Modal
          isOpen={open}
          toggle={() => setOpen(false)}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
              <a href="#cancel" className="close">
                  {" "}
                  <Icon
                      name="cross-sm"
                      onClick={(ev) => {
                          ev.preventDefault();
                          setOpen(false);
                      }}
                  ></Icon>
              </a>
              <div className="p-2">
                  <h5 className="title">Uploaded Summary</h5>
                  <div className="mt-4">
                  <p>Created Count : <span class="badge badge-dim bg-secondary">{createdCount}</span></p>
                  <p>Skipped Count : <span class="badge badge-dim rounded-pill bg-secondary">{skippedCount}</span></p>
                  </div>
              </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};


export default DndNumbersUpload;