/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import ErrorHandler from "../error-handlers/axios/defaultErrorHandler";


/**
 * @typedef {Object} ReturnLeadsPayload
 * @property {string[]} data - The list of leads to return.
 */

/**
 * @typedef {Object} ReturnedLeadsType
 * @property {string} id - The unique identifier for the lead.
 * @property {string | Date} createdAt - The date and time when the lead was created.
 * @property {string | Date} updatedAt - The date and time when the lead was last updated.
 * @property {string} firstName - The first name of the lead.
 * @property {string} lastName - The last name of the lead.
 * @property {string} email - The email address of the lead.
 * @property {string} phone - The phone number of the lead.
 * @property {string} role - The role of the lead.
 * @property {string|null} country - The country of the lead.
 * @property {string|null} city - The city of the lead.
 * @property {string|null} address - The address of the lead.
 * @property {string|null} nationality - The nationality of the lead.
 * @property {string|null} photo - The photo URL of the lead.
 * @property {boolean} active - Whether the lead is active.
 * @property {string|null} providerId - The provider ID of the lead.
 * @property {string|null} provider - The provider of the lead.
 * @property {Array.<string>} refreshToken - The refresh tokens of the lead.
 * @property {string} leadSource - The source of the lead.
 * @property {string} leadStatus - The status of the lead.
 * @property {string} lastActivityType - The type of the last activity.
 * @property {string} lastActivityDate - The date and time of the last activity.
 * @property {boolean} lastActivityIsComplete - Whether the last activity is complete.
 * @property {Array.<string>} languages - The languages spoken by the lead.
 * @property {string} createdBy - The ID of the user who created the lead.
 * @property {string|null} firstNote - The first note associated with the lead.
 * @property {Array.<string>} role_duplicate - Roles of the user.
 * @property {Object} currentAgent - The current agent assigned to the lead.
 * @property {string} currentAgent._id - The unique identifier for the current agent.
 * @property {Object} currentAgent.userId - The user information of the current agent.
 * @property {string} currentAgent.userId._id - The unique identifier for the agent's user.
 * @property {string} currentAgent.userId.first_name - The first name of the agent's user.
 * @property {string} currentAgent.userId.last_name - The last name of the agent's user.
 * @property {string} currentAgent.userId.email - The email address of the agent's user.
 * @property {string} currentAgent.userId.phone - The phone number of the agent's user.
 * @property {string|null} currentAgent.userId.photo - The photo URL of the agent's user.
 * @property {string|null} currentAgent.userId.currentAgent - The current agent of the agent's user.
 * @property {number} currentAgent.creditsBalance - The credits balance of the current agent.
 * @property {number} currentAgent.creditsLimit - The credits limit of the current agent.
 * @property {string|null} userPBXNumbers - The PBX numbers of the user.
 * @property {string|null} extension - The extension number of the lead.
 * @property {boolean} DndStatus - The Do Not Disturb status of the lead.
 */

/**
 * @typedef {Object} ReturnLeadsResponse
 * @property {Array.<ReturnedLeadsType>} data - The list of returned leads by this call.
 */

/**
 * @description Fetching leads with property cards
 * @param {Axios} axiosInstance
 * @param {ReturnLeadsPayload} payload 
 * @returns {ReturnLeadsResponse} apiResponse
 */
export const returnLeads = async (axiosInstance, payload) => {
  try {
    const res = await axiosInstance.post(`property-cards/checkin-leads`, payload);

    return res?.data?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
}