import LeadSourcesTable from "./components/LeadSourcesTable";

const LeadSourcesList = () => {
    return (
        <>
            <LeadSourcesTable title={"Lead Sources List"} name={"Lead Sources"} role={null} />
        </>
    );
};

export default LeadSourcesList;
