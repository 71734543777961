import { Modal, ModalBody } from "reactstrap";

const ErrorModal = ({ header, isOpen, toggleFn, size = "md", children }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleFn} size={size}>
      <ModalBody className="d-flex flex-column justify-content-center align-items-center">
        <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger">
        </em>
        <h4 class="nk-modal-title">{header}</h4>
        {children}
      </ModalBody>
    </Modal>
  );
};

export default ErrorModal;
