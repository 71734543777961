import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { Icon, RSelect } from "../../../components/Component";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { validate } from "../utils";
import { useSelector, useDispatch } from "react-redux";
import { addCommunityTeam } from "../../../redux/actions/community-teams";

const TeamCreateModal = ({ isOpen, toggleFn, refreshFn }) => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const [teamName, setTeamName] = useState("");
  const [possibleTeamMembers, setPossibleTeamMembers] = useState([""]);
  const [possibleCommunities, setPossibleCommunities] = useState([""]);

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [selectedTeamLeader, setselectedTeamLeader] = useState(null)
 const teamLeaderOptions = possibleTeamMembers?.map((agent) => ({
   ...agent,
   label: `${agent?.user?.first_name} ${agent?.user?.last_name}`,
   value: agent?.userId,
 }));
  const { loading, error } = useSelector(state => state.communityTeam);

  const fetchDataForFields = async () => {
    const agentRes = await axios.get("/agent");
    const communityRes = await axios.get("/community");
    if (agentRes.data) {
      setPossibleTeamMembers(agentRes.data);
    }
    if (communityRes.data) {
      // only take communities not assigned to teams
      setPossibleCommunities(
        communityRes.data.filter((c) => c?.team_id == null)
      );
    }
  };

  const handleMembersChange = (e) => {
    let values = selectedMembers
      ? selectedMembers
          // eslint-disable-next-line eqeqeq
          .filter((x) => x?.value != undefined)
          .map((x) => x?.value)
      : [];
    e?.forEach((member) => {
      values.push(member.value);
    });
    setSelectedMembers(values);
  };

  const handleCommunitiesChange = (e) => {
    let values = selectedCommunities
      ? selectedCommunities
          // eslint-disable-next-line eqeqeq
          .filter((x) => x?.value != undefined)
          .map((x) => x?.value)
      : [];
    e?.forEach((community) => values.push(community.value));
    setSelectedCommunities(values);
  };

  const handleCreateFormSubmit = async (formEvent) => {
    formEvent.preventDefault();

    if (!validate(teamName, selectedMembers, selectedCommunities,selectedTeamLeader)) {
      return;
    }
    const newTeamData = {
      teamName,
      members: selectedMembers,
      communities: selectedCommunities
    };

    dispatch(
      addCommunityTeam(
        axios,
        newTeamData,
        () => toggleFn(), // run this callback in all cases
        () => {
          toast.success("Team created successfully!");
          refreshFn();
        } // this callback runs only in success case
      )
    );
  };

  useEffect(() => {
    fetchDataForFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error.message ?? "Error occurred while creating community team.");
    }
  }, [error]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleFn}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <div className="nk-tnx-details mt-sm-3">
          <a href="#cancel" className="close">
            {" "}
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                toggleFn();
              }}
            ></Icon>
          </a>
          <div className="nk-modal-head mb-3">
            <h5 className="title">Add Community Team</h5>
          </div>
        </div>
        <div className="p-2">
          <form onSubmit={handleCreateFormSubmit}>
            <Row className="g-3">
              <Col md="4">
                <div className="form-group">
                  <label className="form-label" htmlFor="teamName">
                    Team Name *
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      name="teamName"
                      required
                      value={teamName}
                      onChange={(e) => setTeamName(e.target.value)}
                    />
                  </div>
                </div>
              </Col>
              <Col md="8">
                <div className="form-group">
                  <label className="form-label" htmlFor="teamMembers">
                    Team Members *
                  </label>
                  <div className="form-control-wrap">
                    {possibleTeamMembers.length > 0 && (
                      <RSelect
                        name="teamMembers"
                        placeholder="Select members..."
                        isMulti
                        required
                        options={possibleTeamMembers.map((member) => ({
                          ...member,
                          value: member?.userId,
                          label: `${member?.user?.first_name} ${member?.user?.last_name}`,
                        }))}
                        onChange={(e) => handleMembersChange(e)}
                        className="customInput"
                      />
                    )}
                  </div>
                </div>
              </Col>
             
              <Col md="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="teamCommunities">
                    Communities *
                  </label>
                  <div className="form-control-wrap">
                    {possibleCommunities.length > 0 && (
                      <RSelect
                        name="teamCommunities"
                        placeholder="Select team communities..."
                        isMulti
                        required
                        options={possibleCommunities.map((community) => ({
                          value: community?._id ?? community?.id,
                          label: `${community?.community}`,
                        }))}
                        onChange={(e) => handleCommunitiesChange(e)}
                        className="customInput"
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Col size="12" className="text-end mt-4">
                <Button color="primary" size="lg" disabled={loading}>
                  {loading ? "Adding..." : "Add Team"}
                </Button>
              </Col>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TeamCreateModal;
