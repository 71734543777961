import React, { useState ,useEffect} from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { serverAPI, uploadManyToS3,getImage ,deleteFromS3} from "../../../../utils/envConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Row,
  Col,
  BlockTitle,
} from "../../../../components/Component";
import DragDrop from "./DragDrop";
import { fetchProperty } from "../../../../redux/actions/property";
import ConfirmModal from "../../../../components/miscellaneous/ConfirmModal";
import cuid from "cuid";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PropImageList from "../../../properties/components/PropImage";
import { Card, Modal, Spinner } from "reactstrap";
import InputFile from "../../../off-plans/components/InputFile";
import DnDKitProperty from "../../../off-plans/components/DnDKitProperty";
import useDragAndDrop from "../../../../utils/hooks/useDragAndDropImage";

const backendForDND = HTML5Backend;

const StepFour = (props) => {
  let { values ,type} = props;
  const propId=values.id;

  const dispatch = useDispatch()
  const axios = useAxiosPrivate();
  // let myid = "644a22cdb564d066b9f685d7";
  // let propImages = [];
  const [images, setImages] = useState([]);

  const [imgLoading, setImgLoading] = useState(false);
  const property= useSelector( ( state ) => state.property?.property );
  const [ propImages, setPropImages] = useState([] );
  const [ orderImages, setOrderImages] = useState([]);
  const [propImagesUrl, setPropImagesUrl] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState('');


  const [ tobeDeleted, setToBeDeleted ] = useState( [] );
  const [ tobeAdded, setToBeAdded ] = useState( [] );

  const {
    active: activeImages,
    errorMessage: ImagesStatus,
    dragenter: ImagesDragEnter,
    dragleave: ImagesDragLeave,
    drop: ImagesDrop,
    handleFileChange: ImagesFileChange,
    files: imageFiles ,
    setFiles: setImageFiles,
    removeImage: removeImage,
} = useDragAndDrop({
    multiple: true,
    inputType: "image",
    maxFilesLength: 50,
    minFilesLength: 8,
    labelName: "title",
    id: propId
});
useEffect(() => {

const newImgArray2 = imageFiles.filter((orderImage) => {
  return orderImage.hasOwnProperty("imgfile");


});
setToBeAdded(newImgArray2)

},[imageFiles])

  const handleUpload = async ( file ) =>
  {
    let data = [
      { file: file.imgfile, type: "image/*", fileName: file.fileName },
      {
        file: file.thumbnailFile,
        type: "image/*",
        fileName: file.thumbnailName,
      },
    ];

    await uploadManyToS3( data, axios ); // Uploading thumbnail and original of the image to s3
  };


  const imageUploadNew = async ( images ) =>
  {
    if ( images.length !== 0 )
    {
      setImgLoading( true );
      for ( let i = 0; i < images.length; i++ )
      {
        await handleUpload( images[ i ] );
      }

    }
  };


  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      setImgLoading(true);
  
      const newImgArray = imageFiles?.map((e) => ({
        original: e.original ? e.original : e.fileName,
        thumbnail: e.thumbnail ? e.thumbnail : e.thumbnailName,
      }));
  
      const imgDta = JSON.stringify({ images: newImgArray });

      await updatePropertyImages(tobeDeleted, tobeAdded, imgDta);
      // toast.success("Property images updated successfully.");
   
    } catch (error) {
      // console.error("Error occurred:", error);
      toast.error("Server error. Try Again!");
    } finally {
      setImgLoading(false);

    }
  };
  
  const propertyUpdate = async (propertyData, msg) => {
    try {
        // console.log(propId, propertyData);
        await axios.patch(
            `/propList/${propId}`,
            propertyData
        );
        toast.success(msg);
    } catch (err) {
        // console.log(err)
        toast.error("Server error. Try Again!");
    }
};
  async function updatePropertyImages(tobeDeleted, tobeAdded, imgDta) {
    if (tobeAdded) {
      await imageUploadNew(tobeAdded);
    }
  
    await propertyUpdate(imgDta);
    props.next();
  }
  


  const handleRemove = async (e) => {
    e.preventDefault()
    let rmImg = propImages[index]
    let newImages = [...propImages]
    let urls = [...propImagesUrl]
    if (index > -1) {
        newImages.splice(index, 1);
        urls.splice(index, 1);
    }
    try {
        //Deleting the original and thumbnail image from s3
        const imgDta = JSON.stringify({ "images": newImages });
     
          await deleteFromS3([rmImg?.original, rmImg?.thumbnail], axios)
          .then(
            await axios.patch(`/propList/${propId}`, imgDta).then(()=>{
              toast.success("Image deleted");
              props.next();
            }
            )
          )
      

    } catch (err) {
      // console.log(err)
        // Handle Error Here
        toast.error("Server error. Try Again!")

    }
}

const handleModal = (e, index) => {
  e.preventDefault();
  setIndex(index)
  setShowModal(true)
}

const closeModal = () => {
  setShowModal(false)
}

  useEffect( () =>
  {
    dispatch( fetchProperty(propId,axios) );
  }, [ dispatch,propId ] );



  return (
    <>
      <div className="">
        <BlockTitle tag="h6" className="text-center">
          <Row className="d-flex justify-content-center">
            <Col sm="8">
            
                <div className="nk-upload-form">
                  {/* <h5 className="title mb-3">Upload Images</h5> */}
                  {/* <DragDrop
                    propId={propId}
                    fieldName="images"
                    images={images}
                    setImages={setImages}
                  /> */}
      { propId &&
            <Card style={ {} }>
            <Row className="d-flex justify-content-center">
              <Col sm="6">
                <Button
                  color=""
                  className="w-100  mt-4 text-center d-flex 
                justify-content-center align-items-center"
                  type="button"
                >
                  <div className="nk-upload-form relative">
                    <h5 className="title mb-3">Upload Images</h5>
                    <p>Note: The file size should be at least 800 x 600 px</p>
                    <InputFile
                                    active={activeImages}
                                    dragenter={ImagesDragEnter}
                                    dragleave={ImagesDragLeave}
                                    drop={ImagesDrop}
                                    errorMessage={ImagesStatus}
                                    files={imageFiles}
                                    handleFileChange={ImagesFileChange}
                                    inputType="image"
                                    removeImage={removeImage}
                                    multiple
                                    inputProps={{
                                        accept: "image/*",
                                    }}
                                />
        
           
                  </div>
                </Button>
                {imageFiles && imageFiles.length>0 &&
                        <DnDKitProperty images={imageFiles} setImages={setImageFiles} removeImage={removeImage}/>
                        }
              </Col>
            </Row>
    
            <div className="d-flex justify-content-center align-items-center mt-4">
              <Button
                onClick={ ( e ) => handleSubmit( e ) }
                color="success"
                className="d-flex mb-5
                justify-content-center align-items-center"
              >
                { imgLoading ? (
                  <Spinner size="sm" color="light" />
                ) : (
                  "Save & Next"
                ) }
              </Button>

            </div>
          </Card>
       }

                </div>
         
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            {type === "edit" && (
              <Col sm="12">
                {/* {propImagesUrl && (
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(4, 1fr)", // Adjust the number of columns as needed
                            gap: "1rem",
                          }}
                        >
                          {propImagesUrl.map((item, index) => (
                                <div
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      height: "10rem",
                                    }}
                                    className="mb-2"
                                  >
                                    <img
                                      src={item}
                                      alt=""
                                      style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "fill",
                                        objectPosition: "center",
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      zIndex: "1",
                                      padding: "0.1rem",
                                      backgroundColor: "#fceceb",
                                      borderRadius: "10%",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => handleModal(e, index)}
                                  >
                                    <Icon
                                      name="trash"
                                      style={{
                                        color: "#e85347",
                                        fontSize: "1.3rem",
                                      }}
                                    />
                                  </div>
                                </div>
                          ))}
                        </div>
                )} */}
                {/* <DndProvider backend={backendForDND}>
                  {orderImages && (
                    <PropImageList images={orderImages} moveImage={moveImage} handleModal={handleModal}/>
                  )}
                </DndProvider> */}
              </Col>
            )}
          </Row>
          <div
            className="me-xl-1 me-xxl-5 ms-xl-1 ms-xxl-5  mb-xl-1 mb-xxl-5"
            style={{ position: "relative" }}
          ></div>
      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
          <Button
            onClick={(e)=>e.preventDefault()}
            disabled
            // onClick={() => props.next()}
            color="primary"
          >
            {imgLoading ? "uploading" : "Next"}
          </Button>
          </ul>
        </BlockTitle>
      </div>
      {/* ****** Modal Start ****** */}
      {showModal ? (
        <ConfirmModal
          msg={"Delete "}
          name={"The Image"}
          open={showModal}
          handleClick={handleModal}
          handleConfirm={handleRemove}
          handlecloseModal={closeModal}
          action="Delete"
          loading={loading}
        />
      ) : null}
      {/* ****** Modal End ****** */}
    </>
  );
};

export default StepFour;
