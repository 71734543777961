/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import { PropertyCardType } from "../global-types/types";
import ErrorHandler from "../error-handlers/axios/defaultErrorHandler";


/**
 * @typedef {Object} Distance
 * @property {string} place
 * @property {number} distance
 */

/**
 * @typedef {Object} Mapping
 * @property {string} _id
 * @property {string} lead
 * @property {PropertyCardType} propertyCard
 * @property {number} amount
 * @property {string} createdAt
 * @property {string} createdBy
 * @property {string} date
 * @property {string} transactionType
 * @property {string} updatedAt
 * @property {boolean} wasOwner
 */

/**
 * @typedef {Object} DataItem
 * @property {string} _id
 * @property {Mapping[]} mappings
 * @property {Lead} lead
 */

/**
 * @typedef {Object} LeadsWithPropCardsApiResponse
 * @property {number} filteredCount
 * @property {number} page
 * @property {number} itemsPerPage
 * @property {DataItem[]} data
 */


/**
 * @description Fetching leads with property cards
 * @param {Axios} axiosInstance
 * @param {import("axios").AxiosRequestConfig} options 
 * @returns {Promise<LeadsWithPropCardsApiResponse>} apiResponse
 */
export const fetchLeadsWithPropertyCards = async (axiosInstance, options) => {
  try {
    const res = await axiosInstance.get(`property-cards/lead-with-cards`, options);

    return res?.data?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
}