import React, { useState, useEffect } from "react";
import { BathRooms, ListingType, BedRooms, NewBedRooms, NewBathRooms, FurnishingTypes } from "../../../utils/envConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, RSelect, Icon } from "../../../components/Component";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Button, Card } from "reactstrap";

const TypeEdit = ( props ) =>
{
  const values = props.property;
  const axios = useAxiosPrivate();
  // State Variables
  const [ type, setType ] = useState( [] );
  const [ optDefaultlType, setoptDefaultlType ] = useState();
  const [ optDefaultType, setoptDefaultType ] = useState();
  const [ optDefaultBeds, setoptDefaultBeds ] = useState();
  const [ optDefaultBaths, setoptDefaultBaths ] = useState();
  const [ optDefaultFurnishing, setoptDefaultFurnishing ] = useState();

  const [ formData, setFormData ] = useState( {
    listingType: values?.listingType,
    propertyTypeId: values?.propertyTypeId,
    size: values?.size,
    beds: values?.beds,
    baths: values?.baths,
    furnishing: values?.furnishing,
  } );
  const filteredListingType = ListingType.filter(
    ( type ) => type !== "Sell" && type !== "Lease" && type !== "Holiday Homes"
  );

  const handleFormData = ( input ) => ( e ) =>
  {
    // input value from the form
    const { value } = e.target;

    // updating formData state
    setFormData( ( prevState ) => ( {
      ...prevState,
      [ input ]: value,
    } ) );
  };
  const handlelType = ( e ) =>
  {
    setoptDefaultlType( e );
    if ( e )
    {
      setFormData( ( prevState ) => ( {
        ...prevState,
        listingType: e.value,
      } ) );
    }
  };
  const handleSubmit =async (e)=>{
    e.preventDefault();
    let newFormData={...formData}
    if(formData.listingType===ListingType[0]){
      newFormData.oneCheqPrice=null;
      newFormData.askingPrice=values?.oneCheqPrice?values?.oneCheqPrice:values.askingPrice;
    }
    if(formData.listingType===ListingType[1]){
      newFormData.askingPrice=null;
      newFormData.oneCheqPrice=values?.askingPrice?values?.askingPrice:values?.oneCheqPrice;
    }
    if ( !newFormData.askingPrice && !newFormData.oneCheqPrice )
        {
          toast.error( "Error in Price" );
        } 
        else if(!newFormData.size) {
          toast.error( "Error in Size" );
        }  
        else{
          props.propertyUpdate( newFormData ) && props.setEditType( false );
        }
    
      }
  const handleType = ( e ) =>
  {
    setoptDefaultType( e );
    if ( e )
    {
      setFormData( ( prevState ) => ( {
        ...prevState,
        propertyTypeId: e.value,
      } ) );
    }
  };
  const handleBath = ( e ) =>
  {
    setoptDefaultBaths( e );
    if ( e )
    {
      setFormData( ( prevState ) => ( {
        ...prevState,
        baths: e.value,
      } ) );
    }
  };
  const handleFurnishing = ( e ) =>
  {
    setoptDefaultFurnishing( e );
    if ( e )
    {
      setFormData( ( prevState ) => ( {
        ...prevState,
        furnishing: e.value,
      } ) );
    }
  };
  const handleBed = ( e ) =>
  {
    setoptDefaultBeds( e );
    if ( e )
    {
      setFormData( ( prevState ) => ( {
        ...prevState,
        beds: e.value,
      } ) );
    }
  };

  useEffect( () =>
  {
    async function fetchType ()
    {
      try
      {
        await axios
          .get( `/propType` )
          .then( ( type ) =>
          {
            setType( type.data );
          } )
          .catch( ( err ) =>
          {
            toast.error( "Property type not available !" );
          } );
      } catch ( err )
      {
        // console.log(err)
        toast.error( "Server error. Try Again !" );
      }
    }
    fetchType();
    if ( values.listingType )
    {
      setoptDefaultlType( {
        label: values.listingType,
        value: values.listingType,
      } );
    }
    if ( values.beds )
    {
      setoptDefaultBeds( {
        label: values.beds,
        value: values.beds,
      } );
    }
    if ( values.baths )
    {
      setoptDefaultBaths( {
        label: values.baths,
        value: values.baths,
      } );
    }
    if ( values.furnishing )
    {
      setoptDefaultFurnishing( {
        label: values.furnishing,
        value: values.furnishing,
      } );
    }
    if ( values.propertyType )
    {
      setoptDefaultType( {
        label: values.propertyType.propertyType,
        value: values.propertyType.id,
      } );
    }
  }, [] );

  return (
    <>
      <Card className="card-bordered">
        <div className="card-inner">
          <Row className="gy-2">
            <div className="d-flex justify-content-end">
              <button
                className="close-btn"
                onClick={ () => props.setEditType( false ) }
                style={ {
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                } }
              >
                <Icon
                  name="cross"
                  style={ {
                    fontSize: "1.5rem",
                    backgroundColor: "#fff",
                    color: "red",
                    borderRadius: "0.2rem",
                  } }
                />
              </button>
            </div>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Purpose *</label>
                <RSelect
                  onChange={ ( e ) =>
                  {
                    handlelType( e );
                  } }
                  value={ optDefaultlType }
                  options={ filteredListingType?.map( ( x ) => ( {
                    label: x,
                    value: x,
                  } ) ) }
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Type *</label>
                <RSelect
                  value={ optDefaultType }
                  onChange={ ( e ) =>
                  {
                    handleType( e );
                  } }
                  options={ type?.map( ( x ) => ( {
                    label: x.propertyType,
                    value: x.id,
                  } ) ) }
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Beds *</label>
                <RSelect
                  value={ optDefaultBeds }
                  onChange={ ( e ) =>
                  {
                    handleBed( e );
                  } }
                  options={ NewBedRooms?.map( ( x ) => ( {
                    label: x,
                    value: x,
                  } ) ) }
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Baths *</label>
                <RSelect
                  value={ optDefaultBaths }
                  onChange={ ( e ) =>
                  {
                    handleBath( e );
                  } }
                  options={ NewBathRooms?.map( ( x ) => ( {
                    label: x,
                    value: x,
                  } ) ) }
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Size *</label>
                <div className="form-control-wrap">
                  <div className="input-container">
                    <input
                    required
                      type="text"
                      id="first-name"
                      className="form-control"
                      name="size"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and .
                      }}
                      maxLength="10" // Limit total characters
                      defaultValue={ values.size }
                      onChange={ handleFormData( "size" ) }
                      style={ { paddingRight: "60px" } }
                    />
                    <span
                      className="input-text"
                      style={ {
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      } }
                    >
                      sqft
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Furnishing *</label>
                <div className="form-control-wrap">
                  <div className="input-container">
                    <RSelect
                      value={ optDefaultFurnishing }
                      onChange={ ( e ) =>
                      {
                        handleFurnishing( e );
                      } }
                      options={ Object.values(FurnishingTypes).map( ( x ) => ( {
                        label: x,
                        value: x,
                      } ) ) }
                    />
                  </div>
                </div>
              </div>
            </Col>

          </Row>
          <Row>
            <Col md="12">
              <div className="form-group d-flex justify-content-end mt-4">
                <Button
                  onClick={ ( e ) =>
                  {
                    handleSubmit(e);
                  } }
                  color="success"
                >
                  Save 
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Card>



    </>
  );
};

export default TypeEdit;
