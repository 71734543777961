import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { serverAPI } from "../../../utils/envConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLoadScript } from "@react-google-maps/api";
import { Button, Row, Col, RSelect, Icon } from "../../../components/Component";
import { fetchCommunitiesList } from "../../../redux/actions/property";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

import Map from "../../../components/miscellaneous/Map";
import { Card } from "reactstrap";
import { useNavigate } from "react-router-dom";

const LocationEdit = ( props ) =>
{
  const values = props.property;
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const navigate= useNavigate();

  const apiKey = process.env.REACT_APP_MAP_KEY;
  const { isLoaded } = useLoadScript( {
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
  } );
  const { access_token } = useSelector( ( state ) => state?.user?.loggedInUser );
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ access_token }`,
  };
  const mapStyles = {
    height: "475px",
    width: "100%",
  };
  const community = useSelector( ( state ) => state.property?.communities );
  const [ optDefaultCommunity, setOptDefaultCommunity ] = useState();
  const [ mak, setMak ] = useState( values.makaniNo );
  const [ em, setEm ] = useState( "" );
  const [ loading, setLoading ] = useState( false );
  const [ formData, setFormData ] = useState( {
    buildingName: values?.buildingName,
    street: values?.street,
    communityId: values?.communityId,
    communityName: values?.communityName,
    subCommunity: values?.subCommunity,
    emirate: values?.emirate,
    lat: values?.lat,
    lng: values?.lng,
  } );


  useEffect(() => {
    loadMap();
  }, []);

  useEffect(() => {
    if (optDefaultCommunity) {
      geocodeAddress(optDefaultCommunity.label);
    }
  }, [optDefaultCommunity]);

  const loadMap = () => {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.onload = initializeMap;
      document.body.appendChild(script);
    } else {
      initializeMap();
    }
  };

  const initializeMap = () => {
    if (optDefaultCommunity) {
      geocodeAddress(optDefaultCommunity.label);
    }
  };

  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const location = results[0].geometry.location;
        setPos({ lat: location.lat(), lng: location.lng() });
      } else {
        // console.error('Geocoding failed:', status);
      }
    });
  };
  // handling form input data by taking onchange value and updating our previous form data state
  const handleFormData = ( input ) => ( e ) =>
  {
    // input value from the form
    const { value } = e.target;

    // updating formData state
    setFormData( ( prevState ) => ( {
      ...prevState,
      [ input ]: value,
    } ) );
  };

  useEffect( () =>
  {
    dispatch( fetchCommunitiesList( axios ) );
  }, [ dispatch ] );

  const [ pos, setPos ] = useState( {
    lat: values.lat ? Number( values.lat ) : null,
    lng: values.lng ? Number( values.lng ) : null
  } );

  useEffect( () =>
  {
    setFormData( ( prevState ) => ( {
      ...prevState,
      lng: pos.lng,
      lat: pos.lat,
      makaniNo: mak,
    } ) );
  }, [ pos, mak, em ] );

  const handleClickedMap = async ( e ) =>
  {
    setLoading( true );
    setPos( { lat: e.latLng.lat(), lng: e.latLng.lng() } );
    try
    {
      const bodyContent = {
        Lat: e.latLng.lat(),
        Lng: e.latLng.lng(),
        Token: "l4f76613re4junvvbbd4ufki1j!=+=o6JGpy02dppzxKfVibRYfQ==",
        Remarks: "MAKANI PHASE 2",
      };
      await axios
        .post( `${ serverAPI }/external/makani`, bodyContent, { headers } )
        .then( ( data ) =>
        {
          setLoading( false );
          toast.success( "Location Data Acquired" );
          if ( data && data.data !== "" )
          {
            setMak( data.data.BuildingInfo.Enterance[ 0 ].Makani );
          } else
          {
            toast.error( "makani not available !" );
            setMak( "" );
          }
        } )
        .catch( ( err ) =>
        {
          // console.log(err);
          setLoading( false );
          setMak( "" );
          toast.error( "makani not available !" );
        } );
    } catch ( err )
    {
      toast.error( "Server error. Try Again !" );
    }
    // console.log(pos)
  };

  const handleSubmit =async ()=>{
    if ( !formData.buildingName  )
    {
      toast.error( "Error in building name" );
    }
    else if (!formData.unitReraNo)
    {
      toast.error( "Error in Title Deed Number" );
    }
    else{
    props.propertyUpdate( formData ) && props.setEditLocation( false )
    }

      }
  useEffect( () =>
  {
    if ( values.communityId )
    {
      let options = [];
      options.push( {
        value: values.communityId,
        label: values.communityName,
      } );
      setOptDefaultCommunity( options[ 0 ] );
    }
  }, [] );

  const handleOption = (selectedOption) => {
    setOptDefaultCommunity(selectedOption);

    if (selectedOption) {
      const geocoder = new window.google.maps.Geocoder();
      const address = `${selectedOption.label}, Dubai, Dubai`;

      geocoder.geocode({ address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const location = results[0].geometry.location;
          const latitude = location.lat();
          const longitude = location.lng();

          setPos({ lat: latitude, lng: longitude });

          // Update the form data after getting the coordinates
          setFormData((prevState) => ({
            ...prevState,
            communityId: selectedOption.value,
            communityName: selectedOption.label,
            lat: latitude,
            lng: longitude,
          }));
        } else {
          // console.error('Geocoding failed:', status);
        }
      });
    }
  };

  return (
    <>
      <Card className="card-bordered">
        <div className="card-inner">
          <Row className="gy-2">
            <div className="d-flex justify-content-end align-items-end">
              <button
                className="close-btn"
                onClick={ () => props.setEditLocation( false ) }
                style={ {
                  top: "1.5rem",
                  right: "1rem",
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                } }
              >
                <Icon name="cross" style={ { fontSize: "1rem", backgroundColor: "#fff", color: "red", border: "solid 1px #dbdfea", borderRadius: "0.2rem", padding: "0.3rem" } } />
              </button>
            </div>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Community *</label>
                <RSelect
                  value={ optDefaultCommunity }
                  onChange={ handleOption }
                  options={ community?.map( ( com ) => ( {
                    value: com.id,
                    label: com.community,
                  } ) ) }
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Sub Community</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="first-name"
                    className="form-control"
                    name="subCommunity"
                    defaultValue={ values.subCommunity }
                    onChange={ handleFormData( "subCommunity" ) }
                  />
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Title Deed No *</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="first-name"
                    className="form-control"
                    name="unitReraNo"
                    defaultValue={ values.unitReraNo }
                    onChange={ handleFormData( "unitReraNo" ) }
                  />
                </div>
              </div>
              {/* <div className="form-group">
            <label className="form-label">Emirate *</label>
            <RSelect
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  emirate: e.value,
                }));
              }}
              options={Emirates?.map((e) => ({ label: e, value: e }))}
            />
          </div> */}
              <div className="form-group">
                <label className="form-label">Building Name *</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="first-name"
                    className="form-control"
                    name="buildingName"
                    defaultValue={ values.buildingName }
                    onChange={ handleFormData( "buildingName" ) }
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">Street</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="first-name"
                    className="form-control"
                    name="street"
                    defaultValue={ values.street }
                    onChange={ handleFormData( "street" ) }
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">Makani No</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="first-name"
                    className="form-control"
                    name="makaniNo"
                    defaultValue={ values.makaniNo }
                    onChange={ handleFormData( "makaniNo" ) }
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">Dewa No</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="first-name"
                    className="form-control"
                    name="dewaNo"
                    defaultValue={ values.dewaNo }
                    onChange={ handleFormData( "dewaNo" ) }
                  />
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Location *</label>
                { isLoaded && pos && (
                  <Map
                    pos={ pos }
                    handleClickedMap={ handleClickedMap }
                    mapStyles={ mapStyles }
                  />
                ) }
              </div>
            </Col>

          </Row>
          <Row className="gy-2">
            <div className="d-flex justify-content-end align-items-end">


              <Button
                onClick={ ( e ) =>{
                  e.preventDefault();
                  handleSubmit();
               
                }
                }
                color="success"
                className="mt-4"
              >
                { "Save" }
              </Button>
            </div>
          </Row></div>
      </Card>
    </>
  );
};

export default LocationEdit;
